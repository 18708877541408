var _a;
import { __read } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import Modal from '../../../Modal';
import { TravellerFieldEnum } from '../../../__generated__/globalTypes';
import Counter from '../../../Counter/Counter';
import { Button, Money, Switch } from '../../../index';
import Link from '../../../Link';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { AviaPassengerType } from '../../../__generated__/graphql';
import { servicePopupReducer } from './reducer';
import CloseButton from '../../../Modal/components/CloseButton/CloseButton';
import { getPassengerName } from '../../../utils';
// we may need it later
// @ts-ignore
var PASSENGER_TYPES = (_a = {},
    _a[AviaPassengerType.ADT] = 'Adult',
    _a[AviaPassengerType.CLD] = 'Child',
    _a[AviaPassengerType.INS] = 'Infant with seat',
    _a[AviaPassengerType.INF] = 'Infant',
    _a);
var ServicePopup = function (_a) {
    var servicePayload = _a.servicePayload, passengersState = _a.passengers, onClose = _a.onClose, segments = _a.segments, onSave = _a.onSave, selected = _a.selected, travellersServices = _a.travellersServices;
    var _b = __read(React.useReducer(servicePopupReducer, selected || {}), 2), state = _b[0], dispatch = _b[1];
    var _c = __read(React.useState(false), 2), differentSegments = _c[0], setDifferentSegments = _c[1];
    var theme = useTheme('ExtraServices').ServicePopup;
    var service = servicePayload.service;
    var t = useTranslation('ExtraServices').t;
    React.useEffect(function () {
        dispatch({
            type: 'state',
            payload: selected || {}
        });
    }, [selected]);
    var allowedSegments = React.useMemo(function () {
        if (servicePayload.availableSegments.length === segments.length) {
            return segments;
        }
        return segments.filter(function (segment) { return servicePayload.availableSegments.indexOf(segment.id) >= 0; });
    }, [segments, servicePayload]);
    var passengers = React.useMemo(function () {
        return passengersState.map(function (passenger) {
            var _a, _b;
            var firstName = (_a = passenger.values.find(function (value) { return value.type === TravellerFieldEnum.FirstName; })) === null || _a === void 0 ? void 0 : _a.value, lastName = (_b = passenger.values.find(function (value) { return value.type === TravellerFieldEnum.LastName; })) === null || _b === void 0 ? void 0 : _b.value;
            return {
                name: getPassengerName(firstName, lastName),
                type: passenger.type,
                id: passenger.id
            };
        });
    }, [passengersState]);
    var totalPrice = React.useMemo(function () {
        if (!service) {
            return null;
        }
        var price = {
            amount: 0,
            currency: service.price.currency
        };
        var selectedPassengers = Object.keys(state);
        selectedPassengers.forEach(function (passengerId) {
            if (differentSegments) {
                var segments_1 = Object.keys(state[passengerId]);
                segments_1.forEach(function (segmentId) {
                    price.amount += service.price.amount * state[passengerId][segmentId];
                });
            }
            else {
                price.amount +=
                    service.price.amount * state[passengerId][allowedSegments[0].id] * allowedSegments.length;
            }
        });
        return price;
    }, [service, state, differentSegments]);
    var onSaveHandler = function () {
        var services = [];
        passengers.forEach(function (passenger) {
            var _a;
            if (!differentSegments) {
                services.push({
                    passengerId: passenger.id,
                    segmentIds: servicePayload.availableSegments,
                    count: ((_a = state[passenger.id]) === null || _a === void 0 ? void 0 : _a[segments[0].id]) || 0
                });
            }
            else if (state.hasOwnProperty(passenger.id)) {
                var segments_2 = Object.keys(state[passenger.id]);
                segments_2.forEach(function (segment) {
                    services.push({
                        passengerId: passenger.id,
                        segmentIds: [segment],
                        count: state[passenger.id][segment]
                    });
                });
            }
        });
        onSave(service.id, services);
    };
    var renderPassengers = function (segmentId) {
        return passengers.map(function (passenger) {
            var _a, _b, _c;
            var paidCount = ((_b = (_a = travellersServices[passenger.id][service.id]) === null || _a === void 0 ? void 0 : _a.find(function (service) { return service.segmentIds.includes(segmentId); })) === null || _b === void 0 ? void 0 : _b.confirmedCount) || 0;
            return (React.createElement("div", { className: theme.traveller, key: passenger.id },
                React.createElement("div", { className: theme.traveller__main },
                    React.createElement("div", { className: theme.traveller__number }, parseInt(passenger.id) + 1),
                    React.createElement("div", { className: theme.traveller__name },
                        passenger.name,
                        ", ",
                        passenger.type)),
                React.createElement(Counter, { minValue: paidCount, showOnlyIncreaseButtonWhenValueIsNull: true, value: ((_c = state[passenger.id]) === null || _c === void 0 ? void 0 : _c[segmentId]) || 0, onDecrease: function () {
                        return dispatch({
                            type: 'dec',
                            payload: {
                                passengerId: passenger.id,
                                segmentId: segmentId
                            }
                        });
                    }, onIncrease: function () {
                        return dispatch({
                            type: 'inc',
                            payload: {
                                passengerId: passenger.id,
                                segmentId: segmentId
                            }
                        });
                    } })));
        });
    };
    var renderService = function () {
        return (React.createElement("table", { className: theme.table },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("td", null, t('Service name')),
                    React.createElement("td", null, t('RFISC')),
                    React.createElement("td", null, t('Route')),
                    React.createElement("td", null, t('Price')))),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: theme.service__name }, service.name),
                    React.createElement("td", null, service.rfisc),
                    React.createElement("td", null, segments.length === servicePayload.availableSegments.length
                        ? t('ALL')
                        : servicePayload.availableSegmentLabels.join(', ')),
                    React.createElement("td", { className: theme.service__price },
                        React.createElement(Money, { money: service.price }))))));
    };
    var isPaid = useMemo(function () {
        var _a;
        return (_a = Object.values(travellersServices)) === null || _a === void 0 ? void 0 : _a.some(function (traveller) {
            var _a;
            return (_a = traveller[service.id]) === null || _a === void 0 ? void 0 : _a.some(function (service) { return (service === null || service === void 0 ? void 0 : service.confirmedCount) > 0; });
        });
    }, [travellersServices]);
    return (React.createElement(Modal, { open: !!service, onClose: onClose, maxWidth: "sm", closeButtonRenderer: function () { return React.createElement(CloseButton, { isGray: true, onClick: onClose }); }, classes: { root: theme.modalRoot, paper: theme.modalPaper } },
        React.createElement("div", { className: theme.popup },
            React.createElement("div", null, service && renderService()),
            differentSegments &&
                allowedSegments.map(function (segment) {
                    return (React.createElement("div", { key: segment.id },
                        React.createElement("div", { className: theme.segment },
                            segment.departure.airport.city.name,
                            " - ",
                            segment.arrival.airport.city.name),
                        React.createElement("div", null, renderPassengers(segment.id))));
                }),
            !differentSegments && React.createElement("div", null, renderPassengers(segments[0].id)),
            React.createElement("div", { className: theme.controls },
                React.createElement("div", null, allowedSegments.length > 1 && (React.createElement(Switch, { label: t('Different for other flights?'), isActive: differentSegments, onClick: function () { return setDifferentSegments(!differentSegments); }, switchPosition: "right" }))),
                (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.amount) > 0 && (React.createElement("div", { className: theme.priceWrapper },
                    React.createElement("div", { className: theme.price },
                        t('Total'),
                        ": ",
                        React.createElement(Money, { money: totalPrice })),
                    !isPaid && (React.createElement(Link, { action: function () {
                            return dispatch({
                                type: 'clear'
                            });
                        }, className: theme.clearButton }, t('Clear')))))),
            React.createElement("div", { className: theme.buttons },
                React.createElement(Button, { className: theme.cancel, onClick: onClose, variant: "flat" }, t('Cancel')),
                React.createElement(Button, { className: theme.confirm, onClick: onSaveHandler, variant: "flat" }, t('Confirm'))))));
};
export default ServicePopup;
