import * as React from 'react';
import { useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { useTheme } from '../../../../theme';
import { onChangeInputWithSelectionRange } from '../../utils';
var Enter = function (props, ref) {
    var inputRef = React.useRef();
    var historyCommandFuncRef = React.useRef();
    // const onBlurHandler = useCallback(() => {
    // 	if (!props.disabled) {
    // 		inputRef.current.focus({ preventScroll: true });
    // 	}
    // }, [props.disabled, inputRef]);
    var onKeyDownHandler = function (e) {
        if (e.keyCode === 38 || e.keyCode === 40) {
            if (!historyCommandFuncRef.current) {
                historyCommandFuncRef.current = props.getHistoryCommand();
            }
            var historyCommand_1 = historyCommandFuncRef.current(e.keyCode === 38 ? 'top' : 'down');
            if (historyCommand_1) {
                e.preventDefault();
                inputRef.current.value = historyCommand_1;
                // make async call to move caret after select prev commands
                setTimeout(function () { return inputRef.current.setSelectionRange(historyCommand_1.length, historyCommand_1.length); }, 0);
            }
            return;
        }
        historyCommandFuncRef.current = null;
        if (e.keyCode === 13 && e.target.value) {
            props.onEnterHandler(e.target.value);
            if (inputRef) {
                inputRef.current.value = '';
            }
        }
    };
    var onDocumentKeyDownHandler = function (e) {
        var _a;
        var skipCodes = [91, 17, 67];
        if (skipCodes.includes(e.keyCode)) {
            return;
        }
        if (inputRef.current) {
            inputRef.current.focus();
            if (e.keyCode === 33) {
                e.preventDefault();
                (_a = props.topContainer) === null || _a === void 0 ? void 0 : _a.current.scrollIntoView({ block: 'end' });
                historyCommandFuncRef.current = null;
            }
            else if (e.keyCode === 34) {
                e.preventDefault();
                inputRef.current.scrollIntoView();
                historyCommandFuncRef.current = null;
            }
            setTimeout(function () {
                inputRef.current.focus();
            }, 10);
        }
    };
    React.useEffect(function () {
        if (!props.disabled) {
            setTimeout(function () {
                inputRef.current.focus();
            }, 0);
            setTimeout(function () { return inputRef.current.scrollIntoView(); }, 100);
        }
    }, [props.disabled]);
    React.useEffect(function () {
        var _a;
        if ((_a = props.selectedRequest) === null || _a === void 0 ? void 0 : _a.length) {
            inputRef.current.value = props.selectedRequest;
            setTimeout(function () {
                var _a;
                (_a = props.clearSelectedRequest) === null || _a === void 0 ? void 0 : _a.call(props);
            }, 0);
        }
    }, [props.selectedRequest]);
    var onChangeHandler = useCallback(function (e) {
        e.target.value = e.target.value.replace(/^\s+/g, '');
    }, []);
    React.useEffect(function () {
        if (!props.disabled) {
            document.addEventListener('keydown', onDocumentKeyDownHandler);
        }
        return function () {
            document.removeEventListener('keydown', onDocumentKeyDownHandler);
        };
    }, [props.disabled]);
    // React.useEffect(() => {
    // 	inputRef.current.addEventListener('blur', onBlurHandler);
    //
    // 	return () => inputRef.current.removeEventListener('blur', onBlurHandler);
    // }, [inputRef.current, props.disabled]);
    var theme = useTheme('Terminal').Enter;
    return (React.createElement("div", { className: props.className },
        React.createElement(TextField, { disabled: props.disabled, variant: "outlined", fullWidth: true, classes: {
                root: theme.input
            }, inputRef: inputRef, onKeyDown: onKeyDownHandler, onChange: onChangeInputWithSelectionRange(onChangeHandler), autoFocus: true, spellCheck: false, autoComplete: "none", hidden: props.disabled })));
};
export default React.forwardRef(Enter);
