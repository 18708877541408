import * as React from 'react';
import RequestComponent from '../Request/Request';
import { ExchangeStep } from '../Inner/Inner';
import RequestForm from './RequestForm';
import { useSelector } from 'react-redux';
import { getNewFlightId } from '../../../store/newFlights/selectors';
import { isOpenedExchange } from '../../../store/order/selectors';
var Request = function (_a) {
    var setStep = _a.setStep, successPayment = _a.successPayment, clearSelectedFares = _a.clearSelectedFares, setExchangeSuccess = _a.setExchangeSuccess, setExchangeFail = _a.setExchangeFail, refreshOrder = _a.refreshOrder;
    var newFlight = useSelector(getNewFlightId);
    var openedExchange = useSelector(isOpenedExchange);
    var goToPayment = function () {
        setStep(ExchangeStep.Payment);
    };
    var goToNewRequest = function () {
        setStep(ExchangeStep.Passengers);
    };
    var goToFlights = function () {
        clearSelectedFares();
        setStep(ExchangeStep.NewFlights);
    };
    return newFlight && !successPayment && !openedExchange ? (React.createElement(RequestComponent, { goToPayment: goToPayment, goToFlights: goToFlights, setExchangeFail: setExchangeFail, setExchangeSuccess: setExchangeSuccess, refreshOrder: refreshOrder, goToNewRequest: goToNewRequest })) : (React.createElement(RequestForm, { goToNewRequest: goToNewRequest, goToPayment: goToPayment }));
};
export default Request;
