import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import { currency } from '../../../__fakeData__';
var ExchangeFareCalculation = function () {
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.exchangeFareCalculation },
            React.createElement("label", { className: theme.formControl },
                React.createElement("input", { type: "text", className: cn(theme.formControl__control, theme.formControl__control_light), value: "BQS N4 MOW 100  N4 SVX ZZ SIP 200 NUC 300.00 END IROE 0.90", readOnly: true }),
                React.createElement("div", { className: theme.formControl__label }, t('Fare calculation for exchange ticket')))),
        React.createElement("div", { className: theme.formControl__flexLine },
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement("input", { type: "text", className: cn(theme.formControl__control, theme.formControl__control_light), value: "270", readOnly: true }),
                    React.createElement("div", { className: theme.formControl__label }, t('Fare')))),
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement("input", { type: "text", className: cn(theme.formControl__control, theme.formControl__control_light), value: "EUR", readOnly: true }),
                    React.createElement("div", { className: theme.formControl__label }, t('Currency')))),
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement("input", { type: "text", className: cn(theme.formControl__control, theme.formControl__control_light), value: "5400", readOnly: true }),
                    React.createElement("div", { className: theme.formControl__label },
                        t('Equiv. Fare'),
                        " (",
                        currency,
                        ")"))))));
};
export default ExchangeFareCalculation;
