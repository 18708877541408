import { __read } from "tslib";
import * as React from 'react';
import LoginForm from './Form/Form';
import Verification from './Verification/Verification';
import Welcome from './Welcome/Welcome';
import { LoginType } from '../../../__generated__/globalTypes';
import Header from './Header/Header';
import { Steps } from '../../types';
import Alternative from './Alternative/Alternative';
import Register from './Register/Register';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
initI18n('LoginPage');
var LoginPage = function (_a) {
    var _b = _a.loginType, loginType = _b === void 0 ? LoginType.Email : _b, onSuccess = _a.onSuccess, initialValues = _a.initialValues, _c = _a.variant, variant = _c === void 0 ? 'common' : _c;
    var _d = __read(React.useState(null), 2), altLoginType = _d[0], setAltLoginType = _d[1];
    var _e = __read(React.useState(Steps.Login), 2), step = _e[0], setStep = _e[1];
    var _f = __read(React.useState(''), 2), login = _f[0], setLogin = _f[1];
    var t = useTranslation('LoginPage').t;
    var _g = __read(React.useState(false), 2), isFirstTimeLogin = _g[0], setFirstTimeLogin = _g[1];
    var setConfirmationStep = function () { return setStep(Steps.Confirmation); };
    var setSuccessStep = function () { return setStep(Steps.Success); };
    var setLoginStep = function () { return setStep(Steps.Login); };
    var setAlternativeStep = function () { return setStep(Steps.Alternative); };
    var setRegisterStep = function () { return setStep(Steps.Register); };
    var onBackButtonClick = function () {
        setAltLoginType(null);
        setLoginStep();
    };
    var onAltLoginTypeSelect = function (loginType) {
        setAltLoginType(loginType);
        setLoginStep();
    };
    return (React.createElement("div", null,
        step !== Steps.Success && (React.createElement(Header, { showBackButton: altLoginType ||
                step === Steps.Confirmation ||
                step === Steps.Alternative ||
                step === Steps.Register, onBackButtonClick: onBackButtonClick, title: (step === Steps.Alternative && t('Try another way to sign in')) ||
                (step === Steps.Login && t('Sign in and sign up')) ||
                (step === Steps.Confirmation && t('Sign in')) ||
                (step === Steps.Register && t('Sign up')) })),
        step === Steps.Alternative && React.createElement(Alternative, { loginType: loginType, onSelect: onAltLoginTypeSelect }),
        step === Steps.Login && (React.createElement(LoginForm, { initialValues: initialValues, loginType: altLoginType ? altLoginType : loginType, onAlternativeClick: setAlternativeStep, hideSocial: altLoginType || variant === 'compact', variant: variant, onSuccess: function (login, register) {
                if (!register) {
                    setLogin(login);
                    setConfirmationStep();
                }
                else {
                    setFirstTimeLogin(true);
                    setLogin(login);
                    setRegisterStep();
                }
            } })),
        step === Steps.Register && (React.createElement(Register, { onSuccess: setConfirmationStep, login: login, loginType: loginType })),
        step === Steps.Confirmation && (React.createElement(Verification, { key: Steps.Confirmation, currentStep: step, login: login, onSuccess: setSuccessStep, loginType: altLoginType ? altLoginType : loginType })),
        step === Steps.Success && React.createElement(Welcome, { isFirstTimeLogin: isFirstTimeLogin, onSuccess: onSuccess })));
};
export default LoginPage;
