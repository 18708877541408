import { __assign } from "tslib";
import * as React from 'react';
import { Field } from 'react-final-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from '../../../../theme';
var CheckboxField = function (props) {
    var theme = useTheme('ServicesCard').ServicesCard;
    return (React.createElement(Field, { type: "checkbox", name: "'".concat(props.name, "'") }, function (_a) {
        var meta = _a.meta, input = _a.input;
        return (React.createElement(FormControlLabel, { classes: {
                label: theme.checkbox__label
            }, control: React.createElement(Checkbox, { color: "primary", classes: {
                    colorPrimary: theme.checkbox,
                    checked: theme.checkbox_checked
                }, checked: input.checked, disabled: props.isLoading, inputProps: __assign({}, input) }), label: "EMD: ".concat(props.name) }));
    }));
};
export default CheckboxField;
