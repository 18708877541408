import { __read } from "tslib";
import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import * as ExchangeVariants from '../../../../../__queries__/GetExchangeVariants.graphql';
// adapter for flights list for manual exchange mode
export var getNewFlightsForManualMode = function (exchangeParams, setSearchParams, clearFare, onError, setLegAction) {
    var _a = __read(React.useState(false), 2), loading = _a[0], setLoading = _a[1];
    var _b = __read(React.useState(null), 2), results = _b[0], setResults = _b[1];
    var _c = __read(React.useState(0), 2), currentLeg = _c[0], setCurrentLeg = _c[1];
    var _d = __read(useMutation(ExchangeVariants.GetExchangeVariants), 1), runSearch = _d[0];
    var runNewSearch = function () {
        clearFares();
        setLoading(true);
        runSearch({
            variables: {
                params: exchangeParams
            }
        })
            .then(function (searchResults) {
            setResults(searchResults.data);
            setLoading(false);
        }, function (searchResults) {
            var _a;
            if (onError && ((_a = searchResults === null || searchResults === void 0 ? void 0 : searchResults.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                onError(searchResults.graphQLErrors.map(function (error) { return error === null || error === void 0 ? void 0 : error['message']; }));
            }
            setLoading(false);
        })
            .catch(function (e) {
            setLoading(false);
            onError === null || onError === void 0 ? void 0 : onError();
        });
    };
    React.useEffect(function () {
        runNewSearch();
    }, [exchangeParams]);
    var startNewSearch = function (params) {
        setSearchParams(params);
    };
    var setLeg = function (legId) {
        if (legId < currentLeg) {
            setLegAction(legId);
        }
        setCurrentLeg(legId);
    };
    var clearFares = function () {
        clearFare();
        setCurrentLeg(0);
    };
    return {
        loading: loading,
        results: results,
        currentLeg: currentLeg,
        setLeg: setLeg,
        startNewSearch: startNewSearch,
        clearFares: clearFares
    };
};
