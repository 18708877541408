import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../index';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import { Money } from '../../../Money';
import { Steps } from '../types';
initI18n('ServiceCard');
var Header = function (props) {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h;
    var theme = useTheme('ServicesCardVoid').Header;
    var t = useTranslation('ServiceCardVoid').t;
    var emptyPrice = ((_b = props.summary) === null || _b === void 0 ? void 0 : _b.total)
        ? __assign(__assign({}, (_c = props.summary) === null || _c === void 0 ? void 0 : _c.total), { amount: 0 }) : null;
    return (React.createElement("div", { className: theme.header },
        React.createElement("div", { className: theme.header__content },
            props.step === Steps.Confirm && (React.createElement("div", { className: theme.calculation },
                React.createElement("div", null,
                    t('Refund'),
                    ": ",
                    React.createElement(Money, { money: (_e = (_d = props.summary) === null || _d === void 0 ? void 0 : _d.refund) !== null && _e !== void 0 ? _e : emptyPrice })),
                React.createElement("div", null,
                    t('Void'),
                    ": ",
                    React.createElement(Money, { money: (_g = (_f = props.summary) === null || _f === void 0 ? void 0 : _f.voidSum) !== null && _g !== void 0 ? _g : emptyPrice })),
                React.createElement("div", null,
                    t('Total'),
                    ": ",
                    React.createElement(Money, { money: (_h = props.summary) === null || _h === void 0 ? void 0 : _h.total })))),
            React.createElement(Button, { type: 'submit', variant: 'flat', className: cn(theme.calculateButton, (_a = {},
                    _a[theme.calculateButton_disabled] = !props.isAtLeastOneSelected,
                    _a)), disabled: !props.isAtLeastOneSelected, isLoading: props.isLoading, onClick: props.onSubmit }, props.step === Steps.Select ? t('Calculate') : t('Confirm')))));
};
export default Header;
