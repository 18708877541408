import ym from 'react-yandex-metrika';
import { Currency } from './enums';
export var DATA_LAYER_KEY = 'dataLayer';
export var MotivationGoal;
(function (MotivationGoal) {
    // Seats
    MotivationGoal["ConfirmProposedSeat"] = "motivation-proposedSeat-agree";
    MotivationGoal["RefuseProposedSeat"] = "motivation-proposedSeat-disagree";
    MotivationGoal["ConfirmToChooseSeat"] = "motivation-chooseSeat-agree";
    MotivationGoal["RefuseToChooseSeat"] = "motivation-chooseSeat-disagree";
    // Baggage
    // ConfirmProposedBaggage = 'motivation-proposedBaggage-agree',
    // RefuseProposedBaggage = 'motivation-proposedBaggage-disagree',
    // Meal
    MotivationGoal["ConfirmProposedMeal"] = "motivation-proposedMeal-agree";
    MotivationGoal["RefuseProposedMeal"] = "motivation-proposedMeal-disagree";
})(MotivationGoal || (MotivationGoal = {}));
export var OrderGoal;
(function (OrderGoal) {
    OrderGoal["Book"] = "order-book";
    OrderGoal["BookingError"] = "order-booking-error";
    OrderGoal["Booked"] = "order-booked";
    OrderGoal["Paid"] = "order-paid";
    OrderGoal["PaymentFailed"] = "order-payment-failed";
    OrderGoal["ItineraryReceiptDownload"] = "order-itinerary-receipt-download";
})(OrderGoal || (OrderGoal = {}));
export var UserGoal;
(function (UserGoal) {
    UserGoal["Login"] = "user-login";
    UserGoal["Registration"] = "user-registration";
    UserGoal["ContinueOnDesktop"] = "continue-on-desktop";
})(UserGoal || (UserGoal = {}));
export var CheckinGoal;
(function (CheckinGoal) {
    CheckinGoal["Create"] = "create-checkin";
    CheckinGoal["Start"] = "start-checkin";
    CheckinGoal["CompleteWithoutPayment"] = "checkin-complete-without-payment";
    CheckinGoal["Paid"] = "checkin-paid";
    CheckinGoal["PaymentCancelled"] = "checkin-payment-cancelled";
    CheckinGoal["Cancelled"] = "checkin-cancelled";
    CheckinGoal["AddTraveller"] = "checkin-add-traveller";
    CheckinGoal["RemoveTraveller"] = "checkin-remove-traveller";
    CheckinGoal["ResendTicket"] = "checkin-resend-ticket";
    CheckinGoal["AppleWalletPassDownload"] = "checkin-apple-wallet-pass-download";
    CheckinGoal["BoardingPassDownload"] = "checkin-boarding-pass-download";
})(CheckinGoal || (CheckinGoal = {}));
export var SplitTestGoal;
(function (SplitTestGoal) {
    SplitTestGoal["RefusedToAuthorize"] = "test-refused-to-authorize";
    SplitTestGoal["AgreedToAuthorize"] = "test-agreed-to-authorize";
})(SplitTestGoal || (SplitTestGoal = {}));
export var SplitTest;
(function (SplitTest) {
    // AskUserToAuthorize = 'ask-user-to-authorize', // complete
    SplitTest["OneButtonBaggageSelector"] = "one-button-baggage-selector";
    SplitTest["SkipServicesSteps"] = "skip-services-steps";
    SplitTest["SkipServicesRecommendations"] = "skip-services-recommendations";
})(SplitTest || (SplitTest = {}));
export var ActionType;
(function (ActionType) {
    ActionType["Detail"] = "detail";
    ActionType["Add"] = "add";
    ActionType["Remove"] = "remove";
    ActionType["Purchase"] = "purchase";
})(ActionType || (ActionType = {}));
export var ProductType;
(function (ProductType) {
    ProductType["FareFamily"] = "Fare Family";
    ProductType["Order"] = "Order";
    ProductType["Insurance"] = "Insurance";
    ProductType["Transfer"] = "Transfer";
    ProductType["Baggage"] = "Baggage";
    ProductType["Meal"] = "Meal";
    ProductType["Seat"] = "Seat";
})(ProductType || (ProductType = {}));
export var pushAction = function (action, products, actionField) {
    var _a;
    window[DATA_LAYER_KEY] = window[DATA_LAYER_KEY] || [];
    window[DATA_LAYER_KEY].push({
        ecommerce: (_a = {
                currencyCode: Currency.RUB
            },
            _a[action] = {
                actionField: actionField,
                products: products
            },
            _a)
    });
};
export var viewProduct = function (product) { return pushAction(ActionType.Detail, [product]); };
export var addProduct = function (product) { return pushAction(ActionType.Add, [product]); };
export var addProducts = function (products) { return pushAction(ActionType.Add, products); };
export var removeProduct = function (product) { return pushAction(ActionType.Remove, [product]); };
// export const purchaseProduct = (product: Product, actionField: ActionField) =>
// 	pushAction(ActionType.Purchase, [product], actionField);
export var purchaseProducts = function (products, actionField) {
    return pushAction(ActionType.Purchase, products, actionField);
};
export var convertFareFamilyToProduct = function (fareFamily) {
    var _a;
    return ({
        id: fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.id,
        category: ProductType.FareFamily,
        name: fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.title,
        brand: (_a = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _a === void 0 ? void 0 : _a.name
    });
};
export var reachGoal = function (goal, parameters) {
    if (window && window['ym']) {
        ym('reachGoal', goal, parameters);
    }
};
export var setSplitTest = function (testName, value) {
    var _a;
    if (window && window['ym']) {
        ym('params', (_a = {},
            _a["test-".concat(testName)] = value,
            _a));
    }
};
