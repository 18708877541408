import { __assign, __rest } from "tslib";
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import Modal from '../../../../Modal';
import Button from '../../../../Button';
import CloseButton from '../../../../Modal/components/CloseButton/CloseButton';
var SupportModal = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    var css = useTheme('Terminal').SupportModal;
    var t = useTranslation('Terminal').t;
    var renderCloseButton = useCallback(function (onClick) { return React.createElement(CloseButton, { className: css.close, onClick: onClick, isGray: true }); }, []);
    return (React.createElement(Modal, __assign({ maxWidth: "xs", closeButtonRenderer: renderCloseButton }, props),
        React.createElement("div", { className: css.container },
            t('Your browser does not support the stable version of IndexedDB, which may cause text mode problems.'),
            React.createElement("div", { className: css.controls },
                React.createElement(Button, { className: css.button, onClick: props.onClose }, t('Confirm'))))));
};
export default SupportModal;
