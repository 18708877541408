import * as EventsQueues from './EventsQueues.css';
import * as Queues from './Components/Queues/Queues.css';
import * as QueuesOwnerModal from './Components/QueuesOwnerModal/QueuesOwnerModal.css';
import * as QueueForm from './Components/QueueForm/QueueForm.css';
import * as Header from './Components/Header/Header.css';
export default {
    EventsQueues: EventsQueues,
    Queues: Queues,
    QueuesOwnerModal: QueuesOwnerModal,
    QueueForm: QueueForm,
    Header: Header
};
