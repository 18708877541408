import { __assign, __read } from "tslib";
import * as React from 'react';
import { useMemo, useState } from 'react';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import SeatMap from '../../../../SeatMap/components/Component';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { Money } from '../../../../Money';
import { AviaPassengerType } from '../../../../__generated__/globalTypes';
import { useTheme } from '../../../../theme';
import { setNextStep } from '../../../store/checkout/actions';
import { connect, useSelector } from 'react-redux';
import { getPassengerLabels } from '../../../store/passengers/selectors';
import { getTotalPrice } from '../../../store/priceBreakdown/selectors';
import { getHasAnySelectedSeats, getOrderId, getSeatMap, getSegmentsForServices, getShowSeatRecommendations, getTravellers, getBonusForFlight, isAllSegmentsAvailableOnlyOnCheckin, getPriceInMiles } from '../../../store/order/selectors';
import SuggestionModalContent from '../../../../SuggestionModalContent/SuggestionModalContent';
import Modal from '../../../../Modal';
import { SeatIcon } from '../../../../SuggestionModalContent/icons';
import { fillOrder } from '../../../store/order/actions';
import { SlideBottom } from '../../../../index';
import { MotivationGoal, reachGoal } from '../../../../analytics';
import ServicesToolbar from '../../../../ServicesToolbar';
import { getSeatsPriceToPay } from '../../../store/orderPrice/selectors';
import { setService, removeService } from '../../../store/selectedServices/actions';
import { getPassengerStateCompatible } from '../../../store/selectedServices/selectors';
import { getSeatReduxCallbacks } from '../../../utils';
import { useConfig } from '../../../../context';
var Seats = function (_a) {
    var totalPrice = _a.totalPrice, passengersState = _a.passengersState, setNextStep = _a.setNextStep, goToPrevStep = _a.goToPrevStep, fillOrder = _a.fillOrder, suggestSeatSelection = _a.suggestSeatSelection, setService = _a.setService, removeService = _a.removeService, seatsTotalPrice = _a.seatsTotalPrice;
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').CheckoutStyles;
    var _b = __read(useState(false), 2), showSuggestionModal = _b[0], setShowSuggestionModal = _b[1];
    var segments = useSelector(getSegmentsForServices);
    var hasSelectedSeats = useSelector(getHasAnySelectedSeats);
    var showRecommendations = useSelector(getShowSeatRecommendations);
    var orderId = useSelector(getOrderId);
    var seatMap = useSelector(getSeatMap);
    var travellers = useSelector(getTravellers);
    var bonusForFlight = useSelector(getBonusForFlight);
    var priceInMiles = useSelector(getPriceInMiles);
    var isAllSeatsAvailableOnCheckin = useSelector(isAllSegmentsAvailableOnlyOnCheckin);
    var iataCode = useConfig().global.companyInfo.iataCode;
    var goToNext = function () {
        if (!hasSelectedSeats) {
            setShowSuggestionModal(true);
        }
        else {
            setNextStep();
        }
    };
    var onSuggestionAgree = function () {
        setShowSuggestionModal(false);
        reachGoal(MotivationGoal.ConfirmToChooseSeat);
    };
    var onSuggestionDisagree = function () {
        setNextStep();
        reachGoal(MotivationGoal.RefuseToChooseSeat);
    };
    var seatMapCallbacks = useMemo(function () { return getSeatReduxCallbacks(passengersState, setService, removeService); }, [
        passengersState,
        setService,
        removeService
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ServicesToolbar, { toolbarProps: {
                totalPrice: totalPrice,
                onBackButtonClick: goToPrevStep,
                onButtonClick: suggestSeatSelection ? goToNext : setNextStep,
                additional: seatsTotalPrice.amount ? (React.createElement("div", { className: css.gdsTotalPrice },
                    t('Seats'),
                    ": ",
                    React.createElement(Money, { money: seatsTotalPrice }))) : null,
                miles: bonusForFlight,
                priceInMiles: priceInMiles
            }, mobileToolbarProps: {
                onBack: goToPrevStep,
                onContinue: suggestSeatSelection ? goToNext : setNextStep
            } },
            React.createElement(SeatMap, __assign({ confirmRecommendedSeatsWithoutDialog: iataCode === 'N4' && !isAllSeatsAvailableOnCheckin, orderId: orderId, seatMap: seatMap, passengers: passengersState === null || passengersState === void 0 ? void 0 : passengersState.filter(function (passenger) { return passenger.type !== AviaPassengerType.INF; }), travellers: travellers, segments: segments, fillOrder: fillOrder, showRecommendations: !isAllSeatsAvailableOnCheckin && showRecommendations, onProceed: suggestSeatSelection ? goToNext : setNextStep }, seatMapCallbacks))),
        React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
            React.createElement(Modal, { scroll: "paper", maxWidth: "xs", keepMounted: true, open: showSuggestionModal, onClose: function () { return setShowSuggestionModal(false); } },
                React.createElement(SuggestionModalContent, { icon: SeatIcon, agreeButtonText: t('Select seat'), disagreeButtonText: t('Continue'), text: t("If you will not select a seat now, we will automatically select it for you upon check-in."), onAgree: onSuggestionAgree, onDisagree: onSuggestionDisagree }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: showSuggestionModal, onClose: function () { return setShowSuggestionModal(false); } },
                React.createElement(SuggestionModalContent, { insideSlideBottom: true, icon: SeatIcon, agreeButtonText: t('Select seat'), disagreeButtonText: t('Continue'), text: t("If you will not select a seat now, we will automatically select it for you upon check-in."), onAgree: onSuggestionAgree, onDisagree: onSuggestionDisagree })))));
};
var mapStateToProps = function () {
    return function (state, ownProps) { return (__assign(__assign({}, ownProps), { passengersState: getPassengerStateCompatible(state), totalPrice: getTotalPrice(state), passengerLabels: getPassengerLabels(state), seatsTotalSumFromOrder: getSeatsPriceToPay(state), seatsTotalPrice: getSeatsPriceToPay(state) })); };
};
var mapDispatchToProps = {
    setNextStep: setNextStep,
    fillOrder: fillOrder,
    setService: setService,
    removeService: removeService
};
export default connect(mapStateToProps, mapDispatchToProps)(Seats);
