import * as CheckoutAdditionalService from './CheckoutAdditionalService/CheckoutAdditionalService.css';
import * as CheckoutAdditionalServiceBaggage from './components/Baggage/CheckoutAdditionalServiceBaggage.css';
import * as CheckoutAdditionalServiceMeal from './components/Meal/CheckoutAdditionalServiceMeal.css';
import * as Seats from './components/Seats/Seats.css';
import * as Insurance from './components/Insurance/Insurance.css';
import * as Aeroexpress from './components/Aeroexpress/Aeroexpress.css';
import * as Loyalty from './components/Loyalty/Loyalty.css';
import * as Business from './components/Business/Business.css';
import * as PriorityBoarding from './components/PriorityBoarding/PriorityBoarding.css';
import * as Extras from './components/Extras/Extras.css';
import * as CapsuleHotel from './components/CapsuleHotel/CapsuleHotel.css';
export default {
    CheckoutAdditionalService: CheckoutAdditionalService,
    CheckoutAdditionalServiceBaggage: CheckoutAdditionalServiceBaggage,
    CheckoutAdditionalServiceMeal: CheckoutAdditionalServiceMeal,
    Insurance: Insurance,
    Seats: Seats,
    Aeroexpress: Aeroexpress,
    Loyalty: Loyalty,
    Business: Business,
    Extras: Extras,
    PriorityBoarding: PriorityBoarding,
    CapsuleHotel: CapsuleHotel
};
