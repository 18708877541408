import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExchangeStep } from '../Inner/Inner';
import { useTranslation } from 'react-i18next';
import { passengerIcon } from '../icons';
import { Reasons as ReasonsType } from '../../../store/types';
import CommonSelect from '../CommonSelect/CommonSelect';
import { setReason } from '../../../store/reason/actions';
import { getSelectedReason } from '../../../store/reason/selectors';
var Reasons = function (props) {
    var t = useTranslation('Exchange').t;
    var dispatch = useDispatch();
    var selectedReason = useSelector(getSelectedReason);
    var reasons = [
        { value: t('Voluntary change'), key: ReasonsType.Voluntary },
        { value: t('Unvoluntary change'), key: ReasonsType.Unvoluntary }
    ];
    var onChangeHandler = function (selectedValue) {
        dispatch(setReason(selectedValue.key));
    };
    var onNextHandler = function () {
        props.setStep(ExchangeStep.Segments);
    };
    return (React.createElement(CommonSelect, { name: t('Select type of change'), items: reasons, hideBackButton: true, onChange: onChangeHandler, onNextHandler: onNextHandler, selectedKeys: [{ key: selectedReason }], icon: passengerIcon, controlType: "radio" }));
};
export default Reasons;
