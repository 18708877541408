import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useTheme } from '../../../../theme';
var prevButtonIcon = (React.createElement("svg", { width: "8", height: "12", viewBox: "0 0 8 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z", fill: "#9A9A9A" })));
var Header = function (_a) {
    var showBackButton = _a.showBackButton, onBackButtonClick = _a.onBackButtonClick, title = _a.title;
    var css = useTheme('LoginPage').Header;
    var t = useTranslation('LoginPage').t;
    return (React.createElement("div", { className: css.header },
        React.createElement("span", { className: css.title }, t(title)),
        React.createElement("div", { className: css.buttons }, showBackButton && (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: onBackButtonClick, className: css.prevButton },
                React.createElement("span", null, prevButtonIcon)),
            React.createElement("span", { className: css.step }, t('Back')))))));
};
export default Header;
