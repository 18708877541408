import { __read } from "tslib";
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import cn from 'classnames';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import Coupons from '../Components/Coupons/Coupons';
import Taxes from '../Components/Taxes/Taxes';
import { downIcon, reloadIcon } from '../icons';
import SumCalculation from '../Components/SumCalculation/SumCalculation';
import Notice from '../../../Modules/Exchange/components/Exchange/Notice/Notice';
import { agencyTaxes, airlineTaxes, refundCalculation1, refundCalculation2 } from '../__fakeData__';
import RefundCoupons from '../Components/Refund/RefundCoupons/RefundCoupons';
import EditTaxes from '../Components/EditTaxes/EditTaxes';
import RefundNewFareCalculation from '../Components/Refund/RefundNewFareCalculation/RefundNewFareCalculation';
import SubmitControls from '../Components/SubmitControls/SubmitControls';
import { addDefaultRefProperty, mutators } from '../utils';
initI18n('ManualActions');
var Refund = function (props) {
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    var submit = props.onSubmit, onCancel = props.onCancel;
    var _a = __read(useState([]), 2), selectedCoupons = _a[0], setSelectedCoupons = _a[1];
    var onSumbit = function (values) {
        console.log('submit');
        console.log(values);
        submit === null || submit === void 0 ? void 0 : submit(values);
    };
    var initialValues = useMemo(function () {
        return {
            airlineTaxes: selectedCoupons.length ? addDefaultRefProperty(airlineTaxes) : [],
            agencyTaxes: [],
            selectedCoupons: selectedCoupons,
            usedFare: '270',
            calculatedFareForRefund: '1025',
            cancelationPenalty: '200',
            specialAirlinesSum: '80',
            fareCalculcationTicket: '',
            agencyCommission: {
                type: '%',
                value: 0
            },
            totalAmount: '',
            confirm: false
        };
    }, [selectedCoupons]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.sectionBlock },
            React.createElement("div", { className: theme.sectionBlock__header },
                React.createElement("div", { className: theme.sectionBlock__icon }, downIcon),
                React.createElement("div", { className: theme.sectionBlock__headline }, t('Refund for itinerary'))),
            React.createElement(Coupons, { setSelectedCoupons: setSelectedCoupons, selectedCoupons: selectedCoupons }),
            React.createElement(Taxes, { airlineTaxes: airlineTaxes, agencyTaxes: agencyTaxes }),
            React.createElement(SumCalculation, { calculcations: refundCalculation1 })),
        React.createElement("div", { className: theme.warning },
            React.createElement(Notice, { type: "warning", header: t('Warning'), text: t("This order includes EMD's associated with ticket, check their status.") })),
        React.createElement("div", { className: theme.sectionBlock },
            React.createElement("div", { className: theme.sectionBlock__header },
                React.createElement("div", { className: cn(theme.sectionBlock__icon, theme.sectionBlock__icon_bg_green) }, reloadIcon),
                React.createElement("div", { className: theme.sectionBlock__headline }, t('Refund details'))),
            React.createElement(Form, { onSubmit: onSumbit, initialValues: initialValues, mutators: mutators }, function (_a) {
                var handleSubmit = _a.handleSubmit, mutators = _a.form.mutators;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(RefundCoupons, null),
                    React.createElement(EditTaxes, { mutators: mutators }),
                    React.createElement(RefundNewFareCalculation, null),
                    React.createElement(SumCalculation, { calculcations: refundCalculation2 }),
                    React.createElement(SubmitControls, { onCancel: onCancel, submitText: t('Refund'), summaryLabel: t('Refundable sum') })));
            }))));
};
export default Refund;
