import { __assign, __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { Form } from 'react-final-form';
import MediaQuery from 'react-responsive';
import { useTheme } from '../theme';
import Button from '../../Button/Button';
import { FormStages } from '../types';
import { MOBILE_MIN_WIDTH } from '../../utils';
import { useTranslation } from 'react-i18next';
import Preferences from './Preferences/Preferences';
import { TravellerTypes } from '../../Traveller/types';
import InfoCard from '../../InfoCard';
import { whiteAddIcon } from '../../InfoCard/Icons';
import InfoCardDialog from '../../InfoCard/components/InfoCardDialog/InfoCardDialog';
import { successLoadingIcon as successIcon } from '../Icons';
import { Tooltip } from '../../index';
import TravellerField from './TravellerField/TravellerField';
import { useTravellerFormData } from './hooks';
import { differenceInCalendarYears, parse } from 'date-fns';
import { useState } from 'react';
import { TravellerFieldEnum } from '../../__generated__/graphql';
var TravellerForm = function (props) {
    var _a;
    var css = useTheme().TravellerForm;
    var t = useTranslation('Traveller').t;
    var _b = useTravellerFormData(__assign({}, props)), stage = _b.stage, validateForm = _b.validateForm, fieldValuesObject = _b.fieldValuesObject, onSubmit = _b.onSubmit, setStage = _b.setStage, setOpenedErrorTooltip = _b.setOpenedErrorTooltip, openedErrorTooltip = _b.openedErrorTooltip, fieldSets = _b.fieldSets, documentsList = _b.documentsList, setOpenedModal = _b.setOpenedModal, isOpenedModal = _b.isOpenedModal, getDocuments = _b.getDocuments, documentValidators = _b.documentValidators, updateDocList = _b.updateDocList, onDelete = _b.onDelete, documentsValidations = _b.documentsValidations;
    var renderLoading = function (button) {
        return stage === FormStages.Loading ? (React.createElement("div", { className: css.loader },
            React.createElement("div", { className: css.loader_spinner }))) : stage === FormStages.Success ? (React.createElement("div", { className: cn(css.loader, css.success) },
            React.createElement("div", { className: css.success__icon },
                successIcon,
                " "))) : (button);
    };
    var checkIsChild = function (date) {
        var formatDate = parse(date, 'dd.MM.yyyy', new Date());
        return differenceInCalendarYears(new Date(), formatDate) <= 14;
    };
    var isTravellerCompanion = function (traveller) {
        return traveller.values[0].type !== undefined;
    };
    var _c = __read(useState(function () {
        var _a;
        if (isTravellerCompanion(props.traveller)) {
            var birthDate = (_a = props.traveller.values.find(function (_a) {
                var type = _a.type;
                return type === TravellerFieldEnum.BirthDate;
            })) === null || _a === void 0 ? void 0 : _a.value;
            if (birthDate) {
                return checkIsChild(birthDate);
            }
        }
        return false;
    }), 2), isChild = _c[0], setIsChild = _c[1];
    var onChangeBirthDate = function (value) {
        if (value.indexOf('_') !== -1) {
            return;
        }
        setIsChild(checkIsChild(value));
    };
    return (React.createElement("div", null,
        props.type === TravellerTypes.PersonalDetails && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("h1", { className: css.title }, t('Personal details')))),
        React.createElement(Form, { onSubmit: onSubmit, validate: validateForm, initialValues: props.traveller ? fieldValuesObject : { gender: 'Male' }, render: function (_a) {
                var handleSubmit = _a.handleSubmit, form = _a.form, hasValidationErrors = _a.hasValidationErrors;
                return (React.createElement("form", { onSubmit: function (event) {
                        if (!hasValidationErrors) {
                            handleSubmit(event).then(function (res) {
                                if (res) {
                                    setStage(FormStages.Success);
                                    if (props.type !== TravellerTypes.PersonalDetails) {
                                        setTimeout(function () {
                                            form.reset();
                                            setStage(FormStages.Default);
                                        }, 1000);
                                    }
                                    else {
                                        setTimeout(function () {
                                            setStage(FormStages.Default);
                                        }, 1000);
                                    }
                                }
                                else {
                                    setStage(FormStages.Default);
                                    setOpenedErrorTooltip(true);
                                    setTimeout(function () {
                                        setOpenedErrorTooltip(false);
                                    }, 5000);
                                }
                            });
                        }
                        else {
                            handleSubmit(event);
                        }
                    }, className: css.traveller__form },
                    Object.keys(fieldSets).map(function (fieldSet, index) {
                        var fields = fieldSets[fieldSet];
                        if (fieldSet === 'personInfo') {
                            return (React.createElement("div", { key: index, className: css.row },
                                React.createElement("div", { className: css.item },
                                    React.createElement(TravellerField, { fieldName: fields[0], onChange: onChangeBirthDate }),
                                    React.createElement(TravellerField, { fieldName: fields[1] })),
                                React.createElement("div", { className: css.item },
                                    React.createElement(TravellerField, { fieldName: fields[2], options: props.traveller.values.find(function (field) { return field.name === fields[2]; })
                                            .validationRules[0].options }))));
                        }
                        return (React.createElement("div", { key: index, className: css.row }, fields.map(function (field, index) { return (React.createElement("div", { className: css.item, key: index },
                            React.createElement(TravellerField, { fieldName: field }))); })));
                    }),
                    props.showPreferences && (React.createElement(Preferences, { Meal: ['Vegetarian menu', 'same another'], Seats: ['Window seat', 'Center seat'] })),
                    props.type === TravellerTypes.CompanionEmpty && (React.createElement("div", { className: cn(css.row, css.row__reverse) }, renderLoading(React.createElement(Tooltip, { title: t('An error occurred while executing the request, please try again'), open: openedErrorTooltip },
                        React.createElement(Button, { type: 'submit' }, t('Add')))))),
                    props.type === TravellerTypes.CompanionFilled && (React.createElement("div", { className: cn(css.row, css.row__reverse) }, renderLoading(React.createElement(React.Fragment, null,
                        React.createElement(Button, { className: css.button__delete, onClick: function () { return props.onDelete(); } }, t('Delete')),
                        React.createElement(Tooltip, { title: t('An error occurred while executing the request, please try again'), open: openedErrorTooltip },
                            React.createElement(Button, { type: 'submit' }, t('Save'))))))),
                    props.type === TravellerTypes.PersonalDetails && (React.createElement("div", { className: cn(css.row, css.row__reverse, css.row_actions) }, renderLoading(React.createElement(Tooltip, { title: t('An error occurred while executing the request, please try again'), open: openedErrorTooltip },
                        React.createElement(Button, { type: 'submit' }, t('Save'))))))));
            } }),
        React.createElement("div", { className: cn(css.documents, (_a = {},
                _a[css.not_empty] = documentsList.length,
                _a)) },
            React.createElement("div", null,
                React.createElement("div", null, documentsList.length > 0 ? (documentsList.map(function (document, index) { return (React.createElement(InfoCard, { key: index, onSave: function (values, list) {
                        updateDocList(values, list, index);
                    }, validators: documentsValidations && documentValidators, nationality: fieldValuesObject['nationality'], infoCard: document, onDelete: function () {
                        onDelete(index, 'documents');
                    }, isChild: isChild })); })) : (React.createElement(InfoCard, { onSave: getDocuments, nationality: fieldValuesObject['nationality'], validators: documentsValidations && documentValidators, isEmptyDocument: true, isChild: isChild }))),
                documentsList.length > 0 && (React.createElement("div", null,
                    React.createElement("p", { onClick: function () { return setOpenedModal('document'); }, className: css.add__button },
                        whiteAddIcon,
                        " ",
                        t('Add document'))))),
            React.createElement(InfoCardDialog, { onSave: function (values, list) {
                    getDocuments(values, list);
                    setOpenedModal('none');
                }, validators: documentValidators, nationality: fieldValuesObject['nationality'], open: isOpenedModal === 'document' || isOpenedModal === 'loyalty', onClose: function () { return setOpenedModal('none'); }, isChild: isChild, type: 'document' }))));
};
export default TravellerForm;
