import { __read } from "tslib";
import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import Button from '../../Button';
import Modal from '../../Modal';
import DownloadDocumentsPopup from './DownloadDocumentsPopup/DownloadDocumentsPopup';
import { useIsMobile } from '../../utils';
var DownloadDocuments = function (_a) {
    var className = _a.className, passengers = _a.passengers, renderLoadButton = _a.renderLoadButton, onDownloadDocuments = _a.onDownloadDocuments, isDocumentsDownloading = _a.isDocumentsDownloading;
    var css = useTheme('DownloadDocuments').DownloadDocuments;
    var t = useTranslation('OrderCard').t;
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var openModal = function () { return setIsOpen(true); };
    var closeModal = function () { return setIsOpen(false); };
    var isMobile = useIsMobile();
    return (React.createElement(React.Fragment, null,
        renderLoadButton ? (renderLoadButton(openModal)) : (React.createElement(Button, { className: cn(css.button, className), variant: "text", onClick: openModal }, t('Load documents'))),
        React.createElement(Modal, { maxWidth: "sm", classes: {
                scrollBody: css.modal__scrollBody,
                paperScrollBody: css.modal__paperScrollBody
            }, slideUp: isMobile, open: isOpen, onClose: !isDocumentsDownloading ? closeModal : null, closeButtonRenderer: function (close) { return React.createElement(Button, { onClick: close, className: css.close }); } },
            React.createElement(DownloadDocumentsPopup, { passengers: passengers, onDownloadDocuments: onDownloadDocuments, isDocumentsDownloading: isDocumentsDownloading }))));
};
export default DownloadDocuments;
