import { __assign } from "tslib";
import * as React from 'react';
import { Provider } from 'react-redux';
import { lazy, Suspense } from 'react';
import { createStore } from '../store';
import { initI18n } from '../../utils';
var Results = lazy(function () { return import('./Results/Results'); });
initI18n('Results');
var Component = function (props) {
    var store = createStore();
    return (React.createElement(Provider, { store: store },
        React.createElement(Suspense, { fallback: React.createElement("div", null) },
            React.createElement(Results, __assign({}, props)))));
};
export default Component;
