import * as React from 'react';
import { useTheme } from '../../../theme';
import { baggageIcon, carryonIcon, mealIcon, sportBaggageIcon } from '../../Icons';
import { BaggageType, OrderAdditionalServiceGdsServiceServiceType as ServiceType } from '../../../__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { TABLET_MIDDLE_WIDTH } from '../../../utils';
var Service = function (_a) {
    var service = _a.service;
    var css = useTheme('RegisteredPassenger').Service;
    var t = useTranslation('RegisteredPassenger').t;
    var isDesktop = useMediaQuery({ minWidth: +TABLET_MIDDLE_WIDTH + 1 });
    var services = [];
    for (var includedService in service.includedServices) {
        if (service.type === ServiceType.Meal) {
            services.push("".concat(service.includedServices[includedService], " x ").concat(includedService));
        }
        else {
            services.push("".concat(service.includedServices[includedService] > 1 ? "".concat(service.includedServices[includedService], "x") : '').concat(includedService));
        }
    }
    return (React.createElement("div", { className: css.service },
        React.createElement("div", { className: css.service__icon }, service.type === ServiceType.Meal
            ? mealIcon
            : service.type === BaggageType.CarryOn
                ? carryonIcon
                : service.type === BaggageType.SportingEquipment
                    ? sportBaggageIcon
                    : baggageIcon),
        React.createElement("div", { className: css.service__info },
            (service.type !== ServiceType.Meal || !isDesktop) && (React.createElement("span", { className: css.service__name }, t(service.type))),
            React.createElement("span", null, services.join(', ')))));
};
export default Service;
