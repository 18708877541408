import OrderCoupons from './components/OrderCoupons/OrderCoupons.css';
import OrderTransactions from './components/OrderTransactions/OrderTransactions.css';
import OrderStatus from './components/OrderStatus/OrderStatus.css';
import OrderBooking from './components/OrderBooking/OrderBooking.css';
import OrderControls from './components/OrderControls/OrderControls.css';
export default {
    OrderCoupons: OrderCoupons,
    OrderTransactions: OrderTransactions,
    OrderStatus: OrderStatus,
    OrderBooking: OrderBooking,
    OrderControls: OrderControls
};
