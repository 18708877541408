import { __assign, __read, __spreadArray } from "tslib";
import i18n from 'i18next';
import * as React from 'react';
import { AviaPassengerType, FareFeaturePaymentType, FlightClass } from '../__generated__/globalTypes';
import memes from '../memes';
import { getDateWithoutTimezone } from '../Engine/components/FastSearch/utils';
/**
 * Sum up multiple duration objects.
 */
export var sumDuration = function () {
    var durations = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        durations[_i] = arguments[_i];
    }
    var result = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    };
    durations.forEach(function (duration) {
        if (duration) {
            result.days += duration.days ? parseInt(duration.days.toString()) : 0;
            result.hours += duration.hours ? parseInt(duration.hours.toString()) : 0;
            result.minutes += duration.minutes ? parseInt(duration.minutes.toString()) : 0;
            result.seconds += duration.seconds ? parseInt(duration.seconds.toString()) : 0;
        }
    });
    // Fix seconds
    if (result.seconds >= 60) {
        result.minutes += Math.floor(result.seconds / 60);
        result.seconds = result.seconds % 60;
    }
    // Fix minutes
    if (result.minutes >= 60) {
        result.hours += Math.floor(result.minutes / 60);
        result.minutes = result.minutes % 60;
    }
    // Fix hours
    if (result.hours >= 24) {
        result.days += Math.floor(result.hours / 24);
        result.hours = result.hours % 24;
    }
    return result;
};
/**
 * Get info string for given duration object.
 *
 * ex: '1d 12h 20m'
 */
export var getDurationString = function (duration) {
    var result = [];
    if (duration.days) {
        result.push(duration.days.toString() + i18n.t('d'));
    }
    if (duration.hours) {
        result.push(duration.hours.toString() + i18n.t('h'));
    }
    if (duration.minutes) {
        result.push(duration.minutes.toString() + i18n.t('m'));
    }
    if (duration.seconds) {
        result.push(duration.seconds.toString() + i18n.t('s'));
    }
    return result.join(' ');
};
/**
 * Convert duration object into the number of seconds.
 */
export var getDurationInSeconds = function (inputDuration) {
    // Add missing duration elements (seconds, minutes, etc).
    var duration = sumDuration(inputDuration);
    return duration.seconds + duration.minutes * 60 + duration.hours * 60 * 60 + duration.days * 24 * 60 * 60;
};
/**
 * Get total flight duration.
 */
export var getFlightDuration = function (segments, withoutTransfers) {
    if (withoutTransfers === void 0) { withoutTransfers = false; }
    var duration = {};
    segments.forEach(function (_a) {
        var segment = _a.segment, transferDuration = _a.transferDuration;
        if (!withoutTransfers) {
            duration = sumDuration(duration, transferDuration);
        }
        duration = sumDuration(duration, segment.duration);
    });
    return duration;
};
/**
 * Get total flight transfers duration.
 */
export var getFlightTransfersDuration = function (flight) {
    var duration = {};
    flight.segments.forEach(function (segment) {
        duration = sumDuration(duration, segment.transferDuration);
    });
    return duration;
};
/**
 * Break up money string into pieces grouped by thousands.
 *
 * '3500' -> ['3', '500']
 */
export var splitNumber = function (money) {
    var result = [];
    if (money.length < 4) {
        result.push(money);
    }
    else {
        var firstPart = money.slice(0, -3);
        if (firstPart.length > 3) {
            result = splitNumber(firstPart);
        }
        else {
            result.push(firstPart);
        }
        result.push(money.slice(-3));
    }
    return result;
};
export var splitMoney = function (money, fractionDigits) {
    var stringMoney = ((money ^ 0) === money ? money : money.toFixed(fractionDigits || 2)).toString(10);
    // Do not process values under 10k.
    if (money < 10000) {
        return [stringMoney];
    }
    var parts = stringMoney.split('.');
    var result;
    if (parts.length) {
        if (parts.length === 1) {
            result = splitNumber(stringMoney);
        }
        else {
            var integerParts = splitNumber(parts[0]);
            var lastElement = integerParts.pop();
            // In case of decimal values like: '1123.18'.
            result = __spreadArray(__spreadArray([], __read(integerParts), false), ["".concat(lastElement, ".").concat(parts[1])], false);
        }
    }
    return result;
};
/**
 * Example: 123.0000000001 -> 123.00
 */
export var truncDecimal = function (amount, fractionDigits) { return parseFloat(amount.toFixed(fractionDigits)); };
/**
 * Extract list of min prices on given groups.
 */
export var getMinPrices = function (prices, singleFare, lastSelectedFare, findOriginalPrice) {
    var result = [];
    var minEconomyPrice = null;
    var minBusinessPrice = null;
    var economyPriceInMiles = null;
    var businessPriceInMiles = null;
    prices === null || prices === void 0 ? void 0 : prices.forEach(function (price) {
        var _a, _b;
        if (price.fareFamily && price.prices && price.prices.length && !findOriginalPrice) {
            if (price.fareFamily && price.fareFamily.category === FlightClass.Business && !singleFare) {
                if (!minBusinessPrice || minBusinessPrice.amount > price.prices[0].price.amount) {
                    minBusinessPrice = price.prices[0].price;
                    if (price.prices[0].priceInMiles) {
                        businessPriceInMiles = (_a = price.prices[0]) === null || _a === void 0 ? void 0 : _a.priceInMiles;
                    }
                }
            }
            else {
                if (!minEconomyPrice || minEconomyPrice.amount > price.prices[0].price.amount) {
                    minEconomyPrice = price.prices[0].price;
                    if (price.prices[0].priceInMiles) {
                        economyPriceInMiles = (_b = price.prices[0]) === null || _b === void 0 ? void 0 : _b.priceInMiles;
                    }
                }
            }
        }
    });
    prices === null || prices === void 0 ? void 0 : prices.forEach(function (price) {
        var _a, _b, _c, _d, _e, _f;
        if (price.fareFamily && price.prices && price.prices.length && findOriginalPrice) {
            if (price.fareFamily && price.fareFamily.category === FlightClass.Business && !singleFare) {
                if (!minBusinessPrice || minBusinessPrice.amount > ((_a = price.prices[0].originalPrice) === null || _a === void 0 ? void 0 : _a.amount)) {
                    minBusinessPrice = (_b = price.prices[0]) === null || _b === void 0 ? void 0 : _b.originalPrice;
                    if (price.prices[0].priceInMiles) {
                        businessPriceInMiles = (_c = price.prices[0]) === null || _c === void 0 ? void 0 : _c.priceInMiles;
                    }
                }
            }
            else {
                if (!minEconomyPrice || minEconomyPrice.amount > ((_d = price.prices[0].originalPrice) === null || _d === void 0 ? void 0 : _d.amount)) {
                    minEconomyPrice = (_e = price.prices[0]) === null || _e === void 0 ? void 0 : _e.originalPrice;
                    if (price.prices[0].priceInMiles) {
                        economyPriceInMiles = (_f = price.prices[0]) === null || _f === void 0 ? void 0 : _f.priceInMiles;
                    }
                }
            }
        }
    });
    result.push({
        type: FlightClass.Economy,
        price: minEconomyPrice ? minEconomyPrice : null,
        priceInMiles: economyPriceInMiles
    });
    if (!singleFare) {
        result.push({
            type: FlightClass.Business,
            price: minBusinessPrice ? minBusinessPrice : null,
            priceInMiles: businessPriceInMiles
        });
    }
    return result;
};
export var getFlightMinPrice = function (legs) {
    var minPrice = null;
    legs.forEach(function (leg) {
        var legMinPrices = getMinPrices(leg.pricesForFareGroups);
        legMinPrices.forEach(function (legMinPrice) {
            if (!minPrice || (legMinPrice.price && legMinPrice.price.amount < minPrice.amount)) {
                minPrice = legMinPrice.price;
            }
        });
    });
    return minPrice;
};
/**
 * Extract unique list of airlines on given segments.
 */
export var getAirlinesList = function (segments) {
    var set = new Set();
    var result = [];
    (segments instanceof Array ? segments : [segments]).forEach(function (_a) {
        var segment = _a.segment;
        if (!set.has(segment.marketingAirline.iata)) {
            result.push(segment.marketingAirline);
            set.add(segment.marketingAirline.iata);
        }
        if (!set.has(segment.operatingAirline.iata)) {
            result.push(segment.operatingAirline);
            set.add(segment.operatingAirline.iata);
        }
    });
    return result;
};
/**
 * 1. Remove fare groups with the wrong service class
 * 2. Remove fare groups without any prices
 * 3. Filter each fare group prices according to previously selected fares (array intersection by flight ID).
 *
 * #fixme: test me please
 */
export var filterFareGroups = memes(function (fareGroups, selectedFares, serviceClass, filterPrices) {
    return fareGroups
        .filter(function (group) {
        return group.fareFamily && group.fareFamily.category === serviceClass && (!filterPrices || group.prices);
    })
        .map(function (group) {
        if (group.prices && selectedFares.length) {
            var lastSelectedFare = selectedFares[selectedFares.length - 1];
            var selectedMap_1 = {};
            lastSelectedFare.prices.forEach(function (price) { return (selectedMap_1[price.flight.id] = price.flight.id); });
            return __assign(__assign({}, group), { prices: group.prices.filter(function (price) { return selectedMap_1.hasOwnProperty(price.flight.id); }) });
        }
        else {
            return group;
        }
    });
});
/**
 * Get info string about transfers on given segments.
 *
 * ex: '1h 15m MOW'
 */
export var getTransfersInfo = function (flight) {
    var result = '';
    if (flight.segments.length === 2) {
        result = "".concat(getDurationString(getFlightTransfersDuration(flight)), " ").concat(flight.segments[0].segment.arrival.airport.iata);
    }
    else {
        result = flight.segments
            .slice(0, flight.segments.length - 1)
            .map(function (_a) {
            var segment = _a.segment;
            return segment.arrival.airport.iata;
        })
            .join(', ');
    }
    return result;
};
export var getUniqueValidOptions = function (options, freeOnly, uniqueOnly, showUnavailable) {
    if (freeOnly === void 0) { freeOnly = false; }
    if (uniqueOnly === void 0) { uniqueOnly = false; }
    if (showUnavailable === void 0) { showUnavailable = false; }
    var map = {};
    return options.filter(function (option) {
        if (freeOnly && option.availability !== FareFeaturePaymentType.Free) {
            return false;
        }
        if (option.availability === FareFeaturePaymentType.NotAvailable && !showUnavailable) {
            return false;
        }
        else if (uniqueOnly && map.hasOwnProperty(option.type)) {
            return false;
        }
        else {
            map[option.type] = true;
            return true;
        }
    });
};
// #fixme: rename
export var convertSearchParameters = function (inputParameters, promoCode) {
    return {
        promotionCode: promoCode,
        currency: inputParameters.currency,
        passengers: inputParameters.passengers.map(function (passenger) { return ({
            passengerType: passenger.passengerType,
            count: passenger.count,
            extendedPassengerType: passenger.extendedPassengerType
        }); }),
        segments: inputParameters.segments.map(function (segment) { return ({
            date: segment.date,
            departure: {
                iata: segment.departure.iata
            },
            arrival: {
                iata: segment.arrival.iata
            }
        }); }),
        ffpMode: inputParameters.ffpMode
    };
};
export var createLegs = function (_a) {
    var segments = _a.segments;
    return segments.map(function (segment, index) { return ({
        id: index,
        departure: segment.departure,
        arrival: segment.arrival,
        date: getDateWithoutTimezone(new Date(segment.date)),
        isReturnLeg: segments.length === 2 &&
            index === 1 &&
            segments[0].arrival.iata === segment.departure.iata &&
            segments[0].departure.iata === segment.arrival.iata
    }); });
};
export var SearchPassengersContext = React.createContext([
    {
        count: 1,
        passengerType: AviaPassengerType.ADT,
        extendedPassengerType: null
    }
]);
export var ActualizedPriceContext = React.createContext({
    actualizedPrice: [],
    actualizationInProgress: false
});
