import { __assign } from "tslib";
import { createSelector } from 'reselect';
import i18n from 'i18next';
import { OrderAdditionalServiceGdsServiceServiceType, TravellerFieldEnum as OldTravellerFieldEnum } from '../../../__generated__/globalTypes';
import { getBaggageServices, getIsInsuranceSelected, getIsTransferSelected, getServices } from '../order/selectors';
import { ServiceType } from '../../components/Checkout/OrderPaidServiceCards/types';
import { getPassengerStateCompatible } from '../selectedServices/selectors';
import { getPassengerName } from '../../../utils';
import { TravellerFieldEnum as NewTravellerFieldEnum } from '../../../__generated__/graphql';
var passengerCodes = {
    ADT: 'Adult',
    CLD: 'Child',
    INF: 'Infant',
    INS: 'Infant with seat'
};
export var getPassengerState = function (state) { return state.passengers; };
export var getPassengerLabels = createSelector(getPassengerState, function (passengers) {
    var result = {};
    var passengersMap = {};
    passengers.forEach(function (passenger) {
        if (!passengersMap.hasOwnProperty(passenger.type)) {
            passengersMap[passenger.type] = 0;
        }
        var passengerIndex = ++passengersMap[passenger.type];
        var passengerFirstName = getPassengerFirstName(passenger);
        var passengerLastName = getPassengerLastName(passenger);
        if (passengerFirstName && passengerLastName) {
            result[passenger.id] = getPassengerName(passengerFirstName.value, passengerLastName.value);
        }
        else {
            result[passenger.id] = "".concat(i18n.t('Passenger:' + passengerCodes[passenger.type]), " ").concat(passengerIndex);
        }
    });
    return result;
});
export var getPassengerLastName = function (passenger) {
    return passenger.values.find(function (_a) {
        var type = _a.type, value = _a.value;
        return type === NewTravellerFieldEnum.LastName && value;
    });
};
export var getPassengerFirstName = function (passenger) {
    return passenger.values.find(function (_a) {
        var value = _a.value, type = _a.type;
        return type === NewTravellerFieldEnum.FirstName && value;
    });
};
export var getPassengerNationalities = createSelector(getPassengerState, function (passengers) {
    var result = new Map();
    var nationalityField = passengers[0].values.find(function (field) { return field.type === OldTravellerFieldEnum.Nationality; });
    nationalityField &&
        nationalityField.validationRules &&
        nationalityField.validationRules[0].options.forEach(function (option) {
            if (!result.hasOwnProperty(option.value)) {
                result[option.value] = option.label;
            }
        });
    return result;
});
export var makeHasAnySelectedBaggage = function (passengerId) {
    return createSelector(getPassengerStateCompatible, getBaggageServices, function (passengers, baggageServices) {
        return passengers[passengerId].services.some(function (selectedService) {
            return baggageServices.some(function (baggage) { return baggage.id === selectedService.serviceId; });
        });
    });
};
export var createSelectedBaggage = function (passengers, baggageServices) {
    var result = [];
    passengers.forEach(function (_a) {
        var id = _a.id, services = _a.services;
        var selectedBaggage = {};
        services.forEach(function (selectedService) {
            if (baggageServices.some(function (baggage) { return baggage.id === selectedService.serviceId; })) {
                selectedService.segmentIds.forEach(function (segmentId) {
                    if (!selectedBaggage.hasOwnProperty(selectedService.serviceId)) {
                        selectedBaggage[selectedService.serviceId] = {};
                    }
                    if (selectedBaggage[selectedService.serviceId].hasOwnProperty(segmentId)) {
                        selectedBaggage[selectedService.serviceId][segmentId].count += selectedService.count;
                    }
                    else {
                        selectedBaggage[selectedService.serviceId][segmentId] = __assign({}, selectedService);
                    }
                });
            }
        });
        result[id] = selectedBaggage;
    });
    return result;
};
export var makeHasSelectedService = function (type) {
    return createSelector(getServices, getIsInsuranceSelected, getIsTransferSelected, getPassengerStateCompatible, function (orderServices, isInsuranceSelected, isTransferSelected, passengers) {
        return passengers.some(function (passenger) {
            return passenger.services.some(function (travellerService) {
                switch (type) {
                    case ServiceType.Baggage:
                        return orderServices.find(function (orderService) {
                            return orderService.id === travellerService.serviceId &&
                                orderService.type === OrderAdditionalServiceGdsServiceServiceType.Baggage;
                        });
                    case ServiceType.Meal:
                        return orderServices.find(function (orderService) {
                            return orderService.id === travellerService.serviceId &&
                                orderService.type === OrderAdditionalServiceGdsServiceServiceType.Meal;
                        });
                    case ServiceType.Seats:
                        return orderServices.find(function (orderService) {
                            return orderService.id === travellerService.serviceId &&
                                orderService.type === OrderAdditionalServiceGdsServiceServiceType.Seat;
                        });
                    case ServiceType.Insurance:
                        return isInsuranceSelected;
                    case ServiceType.Transfer:
                        return isTransferSelected;
                    default:
                        return null;
                }
            });
        });
    });
};
export var getSelectedBaggage = createSelector(getPassengerStateCompatible, getBaggageServices, createSelectedBaggage);
