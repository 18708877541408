import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTheme } from '../theme';
import Dialog from '../Service/components/Dialog/Dialog';
import InsuranceInner from './InsuranceInner/InsuranceInner';
import Link from '../Link';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../utils';
import MobileScreen from './MobileScreen/MobileScreen';
import MUIDialog from '@material-ui/core/Dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { Button } from '../index';
import SimpleLoader from '../SimpleLoader';
var initReducer = function (state) {
    return state;
};
var insuranceReducer = function (state, action) {
    if (action.type === 'add') {
        return __assign(__assign({}, state), { selectedInsurances: __spreadArray(__spreadArray([], __read(state.selectedInsurances), false), [
                action.payload
            ], false) });
    }
    else if (action.type === 'remove') {
        return __assign(__assign({}, state), { selectedInsurances: state.selectedInsurances.filter(function (insurance) {
                return insurance.code !==
                    action.payload.code;
            }) });
    }
    else if (action.type === 'reset') {
        return initReducer(action.payload);
    }
    else if (action.type === 'set_document') {
        var payload_1 = action.payload;
        return __assign(__assign({}, state), { passengers: state.passengers.map(function (passenger) { return (__assign(__assign({}, passenger), { value: passenger.id === payload_1.id ? payload_1.value : passenger.value, isValid: passenger.id === payload_1.id ? payload_1.isValid : passenger.isValid })); }) });
    }
    else if (action.type === 'validate_documents') {
        return __assign(__assign({}, state), { passengers: state.passengers.map(function (passenger) { return (__assign(__assign({}, passenger), { isValid: new RegExp(passenger.regex.error).test(passenger.value) || !passenger.regex })); }) });
    }
    return state;
};
export var infoIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { opacity: "0.7" },
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M1.66699 9.99996C1.66699 5.39996 5.40033 1.66663 10.0003 1.66663C14.6003 1.66663 18.3337 5.39996 18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99996ZM10.8337 9.16663V14.1666H9.16699V9.16663H10.8337ZM10.0003 16.6666C6.32532 16.6666 3.33365 13.675 3.33365 9.99996C3.33365 6.32496 6.32532 3.33329 10.0003 3.33329C13.6753 3.33329 16.667 6.32496 16.667 9.99996C16.667 13.675 13.6753 16.6666 10.0003 16.6666ZM10.8337 5.83329V7.49996H9.16699V5.83329H10.8337Z", fill: "currentColor" }))));
var Insurance = function (props) {
    var inititalState = {
        selectedInsurances: props.selectedInsurances.map(function (selected) { return selected.insuranceProgram; }),
        passengers: props.passengers.map(function (passenger) { return (__assign(__assign({}, passenger), { isValid: !passenger.regex || !passenger.value || new RegExp(passenger.regex.pattern).test(passenger.value) })); })
    };
    var t = useTranslation('AdditionalServiceCard').t;
    var _a = __read(React.useState(false), 2), emptyDocumentError = _a[0], setDocumentError = _a[1];
    var _b = __read(React.useReducer(insuranceReducer, inititalState, initReducer), 2), reducer = _b[0], dispatcher = _b[1];
    var theme = useTheme('Insurance').InsuranceStyles;
    React.useEffect(function () {
        dispatcher({
            type: 'reset',
            payload: inititalState
        });
    }, [props.selectedInsurances, props.passengers]);
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var totalPrice = React.useMemo(function () {
        var _a, _b;
        var price = {
            amount: 0,
            currency: (_b = (_a = props.insurances[0]) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.currency
        };
        reducer.selectedInsurances.forEach(function (insurance) {
            var _a;
            price.amount += (_a = insurance.price) === null || _a === void 0 ? void 0 : _a.amount;
        });
        return price;
    }, [reducer]);
    var renderContent = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(InsuranceInner, { passengers: reducer.passengers, insurances: props.insurances, selectedInsurances: reducer.selectedInsurances, dispatcher: dispatcher, documentNeeds: props.documentNeeds, documentError: emptyDocumentError, error: props.error }),
            props.loading && React.createElement(SimpleLoader, null),
            !isMobile && (React.createElement("div", null,
                React.createElement("div", { className: theme.controls },
                    React.createElement("div", { className: theme.buttons },
                        React.createElement(Button, { onClick: onClear, className: theme.dialog__button_clear }, t('Clear')),
                        React.createElement(Button, { onClick: onClose, className: theme.dialog__button_done }, t('Done'))))))));
    };
    var onClose = function () {
        if (!props.documentNeeds ||
            !reducer.selectedInsurances.length ||
            reducer.passengers.every(function (document) { return (document.value && document.isValid) || !document.isRequired; })) {
            props.onSave(reducer);
            setDocumentError(false);
        }
        else {
            setDocumentError(true);
            dispatcher({
                type: 'validate_documents',
                payload: null
            });
        }
    };
    var onClear = function () {
        var clearedState = __assign(__assign({}, reducer), { selectedInsurances: [] });
        dispatcher({
            type: 'reset',
            payload: clearedState
        });
        props.onSave(clearedState);
    };
    return isMobile ? (React.createElement(MUIDialog, { open: props.open, onClose: onClose, fullScreen: true, classes: {
            paper: theme.mobileWrapper
        } },
        React.createElement(MobileScreen, { content: renderContent(), onClose: onClose, rulesURL: props.rulesURL }))) : (React.createElement(Dialog, { dialogType: isMobile ? 'fullscreen' : 'popup', content: renderContent(), dialogHeader: t('Insurance'), totalPrice: totalPrice, bodyScroll: true, dialogClassName: theme.dialog, dialogPopupHeaderClassName: theme.dialog__header, rules: React.createElement(Link, { target: '_blank', action: props.rulesURL, className: theme.rules },
            infoIcon,
            t('Terms of service')), isOpen: props.open, onClose: props.onClose, onClear: onClear, withoutControls: true }));
};
export default Insurance;
