import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Button from '../../../Button/Button';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Modal';
import LoyaltyConnect from './LoyaltyConnect/LoyaltyConnect';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
var LoyaltyStub = function (props) {
    var _a, _b, _c;
    var css = useTheme('Loyalty').LoyaltyStub;
    var t = useTranslation('Loyalty').t;
    var _d = __read(React.useState(false), 2), isConnectOpen = _d[0], setConnectOpen = _d[1];
    var showLoyaltyConnect = function () { return setConnectOpen(true); };
    var closeLoyaltyConnect = function () { return setConnectOpen(false); };
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    return (React.createElement("div", { className: cn(css.stub, (_a = {},
            _a[css.stub__promo] = props.stub === 'promo',
            _a)) },
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: cn((_b = {},
                    _b[css.content__background] = props.stub === 'card',
                    _b[css.content__background__promo] = props.stub === 'promo',
                    _b)) },
                React.createElement("div", { className: css.content },
                    React.createElement("h1", null, props.stub === 'card' ? props.cardName : t('Get promo code')),
                    React.createElement(Button, { onClick: props.stub === 'card' && showLoyaltyConnect }, props.stub === 'card' ? t('Connect') : t('Get'))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", null,
                React.createElement("div", { className: cn((_c = {},
                        _c[css.content__background] = props.stub === 'card',
                        _c[css.content__background__promo] = props.stub === 'promo',
                        _c)) },
                    React.createElement("div", { className: css.content__header }, props.cardName)),
                React.createElement("div", { className: css.content__description },
                    React.createElement("div", { className: css.card__description }, props.stub === 'card' && t('Connect to loyalty program with your card')),
                    React.createElement(Button, { variant: "flat", onClick: showLoyaltyConnect }, t('Connect'))))),
        React.createElement("div", { className: css.buttons }, props.stub === 'card' &&
            props.links.map(function (_a) {
                var url = _a.url, title = _a.title;
                return (React.createElement("a", { href: url, target: "_blank", className: css.miles__link }, t(title)));
            })),
        React.createElement(Modal, { maxWidth: "sm", open: isConnectOpen, fullScreen: isMobile, onClose: closeLoyaltyConnect },
            React.createElement(LoyaltyConnect, { onLoyaltyCardSaved: props.onLoyaltyCardSaved, userInfo: props.userInfo }))));
};
export default LoyaltyStub;
