import { differenceInCalendarDays, isBefore, isToday, isValid, parse } from 'date-fns';
export var tryParseDate = function (input) {
    var regex = /(?<day>[0-9]{2})\.?(?<month>[0-9]{2})\.?(?<year>[0-9]{2,4})?/;
    var parseString = regex.exec(input);
    var date = parseString === null || parseString === void 0 ? void 0 : parseString.groups['day'], month = parseString === null || parseString === void 0 ? void 0 : parseString.groups['month'], year = parseString === null || parseString === void 0 ? void 0 : parseString.groups['year'];
    var newDate = null;
    if (date && month) {
        if (!year) {
            newDate = parse("".concat(date, ".").concat(month), 'dd.MM', new Date());
        }
        else if (year.length === 2) {
            newDate = parse("".concat(date, ".").concat(month, ".").concat(year), 'dd.MM.yy', new Date());
        }
        else if (year.length === 4) {
            newDate = parse("".concat(date, ".").concat(month, ".").concat(year), 'dd.MM.yyyy', new Date());
        }
    }
    if (isValid(newDate) &&
        ((isBefore(new Date(), newDate) && differenceInCalendarDays(newDate, new Date()) < 365) || isToday(newDate))) {
        return newDate;
    }
    return null;
};
