import React, { useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import Modal from '../../../../Modal';
import { ThemeProvider, useTheme } from '../../../../theme';
import { TravellerFieldEnum } from '../../../../__generated__/graphql';
import Passenger from '../../../../Passenger';
import { InputType } from '../../../../Passenger/types';
import { getInputType } from '../../../../Checkout/components/Checkout/Form/Passengers/Passengers';
import { LoginType } from '../../../../__generated__/globalTypes';
import Contacts from '../../../../Contacts';
import { getPassengerName } from '../../../../utils';
var PassengersModal = function (props) {
    var css = useTheme('OrderCard').PassengersModal;
    var initialValues = useMemo(function () {
        return {
            passengers: props.order.travellers.map(function (passenger) {
                var values = {
                    type: passenger.type
                };
                passenger.values.forEach(function (value) { return (values[value.name] = value.value); });
                return values;
            })
        };
    }, []);
    var modalStyles = useMemo(function () { return ({
        Modal: {
            Modal: {
                modal_rounded: css.root,
                closeIcon: css.close
            }
        }
    }); }, []);
    return (React.createElement(ThemeProvider, { value: modalStyles },
        React.createElement(Modal, { isRounded: true, open: props.open, onClose: props.onClose },
            React.createElement("div", { className: css.passengers_wrapper },
                React.createElement(FinalForm, { onSubmit: function () { }, initialValues: initialValues }, function () {
                    var customerPhone = props.order.customer.values.find(function (value) { return value.type === TravellerFieldEnum.Phone; }), customerEmail = props.order.customer.values.find(function (value) { return value.type === TravellerFieldEnum.Email; });
                    return (React.createElement("div", null,
                        !props.hideCustomerContacts && (React.createElement("div", { className: css.contacts },
                            React.createElement(Contacts, { open: true, sendCode: function () { }, toggleOpen: function () { }, submitForm: function () { }, unlockForm: function () { }, mainContacts: [
                                    {
                                        phone: {
                                            defaultValue: customerPhone.value,
                                            type: customerPhone.type,
                                            name: customerPhone.name,
                                            input: InputType.Phone
                                        },
                                        email: {
                                            defaultValue: customerEmail.value,
                                            type: customerEmail.type,
                                            name: customerEmail.name,
                                            input: InputType.Text
                                        }
                                    }
                                ], loginType: LoginType.Email, isInputsReadonly: true }))),
                        props.order.travellers.map(function (traveller, index) {
                            var _a, _b;
                            var firstName = (_a = traveller.values.find(function (_a) {
                                var type = _a.type;
                                return type === TravellerFieldEnum.FirstName;
                            })) === null || _a === void 0 ? void 0 : _a.value;
                            var lastName = (_b = traveller.values.find(function (_a) {
                                var type = _a.type;
                                return type === TravellerFieldEnum.LastName;
                            })) === null || _b === void 0 ? void 0 : _b.value;
                            var fields = traveller.values.map(function (value) {
                                return {
                                    name: value.name,
                                    type: value.type,
                                    input: getInputType(value.type),
                                    placeholder: value.name,
                                    defaultValue: value.value,
                                    validations: value.validationRules,
                                    options: []
                                };
                            });
                            return (React.createElement(Passenger, { id: index, key: traveller.id, isInputsReadonly: true, label: getPassengerName(firstName, lastName), fields: fields, passengerType: traveller.type, passengerSupplierType: traveller.supplierTravellerType }));
                        }),
                        props.passengerAdditional));
                })))));
};
export default PassengersModal;
