import { initI18n } from '@websky/core/src/utils';
import i18nLib from 'i18next';
export var companyInfo = {
    facebook: '',
    instagram: '',
    vk: '',
    baggage: "",
    meal: null,
    seat: null,
    legal: "",
    privacyPolicy: "",
    webskySearchOrder: "",
    insurance: "",
    loyaltyProgramRegistrationUrl: "",
    loyaltyProgramName: '',
    iataCode: 'TERMINAL',
    loyalty: {
        links: [
            {
                title: 'Status',
                url: ""
            },
            {
                title: 'Earn miles',
                url: ""
            },
            {
                title: 'Use miles',
                url: ""
            },
            {
                title: 'FAQ',
                url: ""
            }
        ]
    },
    multiInsurance: true,
    specialDocumentForInsurance: false
};
export var i18n = {
    ru: {
        Title: {
            companyName: 'Websky Leo'
        }
    },
    en: {
        Exchange: {
            'Already has been paid': 'Fare has been paid',
            'If you want to make a exchange for some passengers, return to the PNR and provide the Split previously.': 'If you want to make an exchange for some passengers, return to the PNR and do the Split first.',
            'Payment exchange': 'Payment for Exchange',
            'Change for': 'Old itinerary',
            'New flight': 'New itinerary',
            'Total fee': 'Total fee to new itinerary',
            'The change request was deleted. Want to create a new request?': 'The change request will be deleted. Want to create a new request?'
        },
        Refund: {
            'Unvoluntary refund': 'Involuntary refund',
            'my plans have changed': 'refund initiated by the passenger',
            FlightNumberChange: 'flight number change',
            FlightRetimed: 'flight retimed',
            FlightCancelled: 'flight cancelled',
            PermanentWithdrawalOfService: 'permanent withdrawal of service',
            Illness: 'Illness of a passenger of a member of his/her family travelling together',
            LaborDispute: 'labor dispute',
            Bereavement: 'bereavement',
            UnableToObtainNecessaryDocumentation: 'unable to obtain necessary documentation',
            Strike: 'strike',
            Weather: 'weather conditions',
            NaturalDisaster: 'natural disaster',
            UnacceptableReaccommodation: 'unacceptable reaccommodation',
            'Successful refund. Confirmation and detalization have been sent to your e-mail': 'Refund successful. Confirmation breakdown has been sent to specified email.'
        },
        RetrieveBooking: {
            'Booking ID': 'Ticket number, PNR or booking reference',
            'Security code': 'Passenger last name'
        },
        OrderCard: {
            'Booking ID': 'PNR'
        },
        translation: {
            'Local time': "All timestamps displayed in local time zone. Sale currency is {{currency}}"
        },
        Title: {
            companyName: 'Websky Leo'
        },
        SearchOrder: {
            'Search order': 'To start searching put PNR or Ticket Number and press «Enter»'
        },
        IncludedBaggage: {
            'All flights': 'All route',
            'Need to bring sports, music or baby equipments?': 'Need to add some special equipments'
        },
        AdditionalBaggage: {
            'All flights': 'All route',
            'More bags?': 'More bags',
            'Need to add another 10-30 kg to your total checked baggage?': 'Need to add extra weight to the total checked baggage'
        },
        FlightInfoModalContent: {
            'View full conditions': 'View full text of conditions'
        },
        DocumentRecognition: {
            'How scan': 'How to scan, short instruction'
        },
        Checkout: {
            'View booking': 'View related booking'
        },
        ServiceCardVoid: {
            'If this services were paid by cash, you should pay back to passenger.': 'If these services were paid by cash, you should pay back to passenger by cash.',
            'If payment was made via credit card the sum would be paid back according to an acquiring bank regulation.': 'If payment was made via credit card, the sum would be paid back according to an acquiring bank regulation.'
        }
    },
    kk: {
        Title: {
            companyName: 'Websky Leo'
        }
    }
};
initI18n('Stepbar');
export var defaultSteps = {
    flights: {
        label: i18nLib.t('Stepbar:Flights')
    },
    passengers: {
        label: i18nLib.t('Stepbar:Passengers')
    },
    payment: {
        label: i18nLib.t('Stepbar:Payment')
    }
};
