import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { Cross } from '../../../Icons';
import Button from '../../../Button/Button';
import cn from 'classnames';
import { passengersByPassengersIds, segmentsBySegmentsIds } from '../utils';
var SsrItem = function (_a) {
    var _b;
    var ssrItem = _a.ssrItem, travellers = _a.travellers, segments = _a.segments, onDelete = _a.onDelete;
    var theme = useTheme('Ssr').SsrList;
    var t = useTranslation().t;
    var deleteSsr = function () {
        onDelete(ssrItem);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("tr", { className: cn(theme.ssrLine, (_b = {}, _b[theme.ssr_withText] = !!ssrItem.ssrText, _b)) },
            React.createElement("td", { className: theme.td__code }, ssrItem.ssrCode),
            React.createElement("td", { className: theme.td__passenger }, passengersByPassengersIds(travellers, ssrItem.travellerIds, t)),
            React.createElement("td", { className: theme.td__segments }, segmentsBySegmentsIds(segments, ssrItem.segmentIds, t)),
            React.createElement("td", { className: theme.td__status },
                React.createElement("span", { className: theme.status__code }, ssrItem.supplierStatusCode),
                "\u00A0",
                React.createElement("span", { className: theme.status__text }, ssrItem.status)),
            React.createElement("td", { className: theme.td__deleteButton, onClick: deleteSsr },
                React.createElement(Button, { variant: "text" }, Cross))),
        ssrItem.ssrText && (React.createElement("tr", { className: cn(theme.ssrLine, theme.ssrText) },
            React.createElement("td", { colSpan: 5 }, ssrItem.ssrText)))));
};
export var SsrList = memo(function (props) {
    var theme = useTheme('Ssr').SsrList;
    return (React.createElement("table", { className: theme.table },
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", { className: theme.th__code }),
                React.createElement("th", { className: theme.th__passenger }),
                React.createElement("th", { className: theme.th__segments }),
                React.createElement("th", { className: theme.th__status }),
                React.createElement("th", { className: theme.th__deleteButton }))),
        React.createElement("tbody", null, props.ssr.map(function (ssrItem) { return (React.createElement(SsrItem, { key: "".concat(ssrItem.ssrCode).concat(ssrItem.travellerIds.toString()), ssrItem: ssrItem, travellers: props.travellers, segments: props.segments, onDelete: props.onDelete })); }))));
});
