import { __read, __values } from "tslib";
import { useTranslation } from 'react-i18next';
var PNR_OR_TICKET_NUMBER_REGEX = /(^[0-9БВГДКЛМНПРСТФХЦЖШBVGDKLMNPRSTFXCZW]{6,6}$)|(^[0-9A-ZА-Я]{13,13}$)/;
var ORDER_NUMBER = /^\d{1,13}$/;
var PASSENGER_LAST_NAME_LATIN_REGEX = /^[- a-zA-Z]{1,41}$/;
var PASSENGER_LAST_NAME_RU_REGEX = /^[- а-яА-ЯЁёa-zA-Z]{1,41}$/;
export var isPnrOrTicketNumber = function (value) { return PNR_OR_TICKET_NUMBER_REGEX.test(value); };
export var isOrderNumber = function (value) { return ORDER_NUMBER.test(value); };
export var isCorrectLatingLastname = function (value) { return PASSENGER_LAST_NAME_LATIN_REGEX.test(value); };
export var isCorrectCyrillicLastName = function (value) { return PASSENGER_LAST_NAME_RU_REGEX.test(value); };
export var isSingleWord = function (value) { return value.split(' ').length === 1; };
export var useRetrieveBookingValidation = function () {
    var t = useTranslation('RetrieveBooking').t;
    var validateByOrderNumber = function (value) {
        if (!isOrderNumber(value)) {
            return t('Enter your book code or ticket number');
        }
    };
    var ticketNumberValidation = function (value) {
        if (!isPnrOrTicketNumber(value)) {
            return t('Enter your book code or ticket number');
        }
    };
    var lastNameValidation = function (value) {
        var e_1, _a;
        var warning = t('Enter passenger last name');
        var error = t('Incorrect value');
        var testValue = value.trim();
        if (!isSingleWord(testValue)) {
            if (testValue.split(' ').some(function (word) { return !isCorrectCyrillicLastName(word) || !isCorrectLatingLastname(word); })) {
                return error;
            }
            else {
                return warning;
            }
        }
        try {
            for (var _b = __values(Object.entries([isCorrectLatingLastname, isCorrectCyrillicLastName])), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), testFunc = _d[1];
                if (testFunc(testValue)) {
                    error = undefined;
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return error;
    };
    var ticketNumberFormat = function (value) {
        return value === null || value === void 0 ? void 0 : value.toUpperCase();
    };
    return { ticketNumberValidation: ticketNumberValidation, lastNameValidation: lastNameValidation, validateByOrderNumber: validateByOrderNumber, ticketNumberFormat: ticketNumberFormat };
};
