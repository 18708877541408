import { __assign, __read } from "tslib";
import * as React from 'react';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useServiceCard } from './hooks';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import { Money } from '../../Money';
import Button from '../../Button/Button';
import ExtraServicesModal from '../../ExtraServices/components/ExtraServicesModal';
import ServicePage from '../../Checkout/components/Checkout/ServicePage/ServicePage';
import ServiceCardDialog from '../../Checkout/components/Checkout/OrderPaidServiceCards/components/ServiceCardDialog/ServiceCardDialog';
import { useServicePageProps } from '../../CheckoutAdditionalService/components/hooks';
import { ServiceType } from '../../Checkout/components/Checkout/OrderPaidServiceCards/types';
import WarningModal from '../../WarningModal';
import SimpleLoader from '../../SimpleLoader';
import { useExtras } from '../../CheckoutAdditionalService/components/Extras/hooks';
import { useSelector } from 'react-redux';
import { getSelectedMiscServices } from '../../Checkout/store/order/selectors';
initI18n('ServiceCard');
export var ServiceCardContext = React.createContext({
    exchangeVoidMode: false,
    isLoading: false,
    step: null,
    calculation: null,
    productIdsForVoidExchange: [],
    servicesIds: [],
    setProductsIdsByEmd: function () { }
});
var ServiceCard = function (props) {
    var _a, _b;
    var _c, _d, _e, _f;
    var theme = useTheme('ServicesCard').ServicesCard;
    var t = useTranslation('ServiceCard').t;
    var _g = __read(useState(false), 1), isSelected = _g[0];
    var _h = __read(useState(null), 2), openedModal = _h[0], setOpenedModal = _h[1];
    var selectedMisc = props.isExchangePage ? [] : useSelector(getSelectedMiscServices);
    var _j = useExtras(selectedMisc), isExtrasModalOpen = _j.isExtrasModalOpen, onCloseExtras = _j.onClose, onOpenExtras = _j.onOpen, convertedSelectedServices = _j.selectedServices, services = _j.services, segments = _j.segments, onExtrasConfirm = _j.onExtrasConfirm, isExtrasLoading = _j.isLoading, saveOrderServicesError = _j.saveOrderServicesError, clearError = _j.clearError;
    var _k = useServiceCard(props.type), Content = _k.Content, EmptyContent = _k.EmptyContent, headerProps = _k.headerProps, contentClassName = _k.contentClassName, description = _k.description, totalPrice = _k.totalPrice, minPrice = _k.minPrice;
    var toggleModal = function (type) {
        if (!!type) {
            setOpenedModal(type);
        }
        else {
            setOpenedModal(null);
        }
    };
    var isMisc = props.type === ServiceType.Misc;
    var onClickModalOpen = isMisc ? onOpenExtras : toggleModal.bind(null, props.type);
    var renderHeader = useCallback(function () { return (React.createElement("div", { className: cn(theme.header, headerProps.backgroundClassName) },
        React.createElement("div", { className: theme.header__title }, headerProps.title),
        React.createElement("div", { className: theme.header__icon }, headerProps.icon))); }, [headerProps]);
    {
        /* temporary commented. Uses in renderSelectorButton */
    }
    // const onSelectAll = useCallback(() => {
    // 	props.mutators.setValueAll(productIdsForVoidExchange, true);
    // }, [productIdsForVoidExchange]);
    {
        /* temporary commented. Uses in renderSelectorButton */
    }
    // const onClearAll = useCallback(() => {
    // 	props.mutators.setValueAll(productIdsForVoidExchange, false);
    // }, [productIdsForVoidExchange]);
    {
        /* renderSelectorButton temporary commented */
    }
    // const renderSelectorButton = () => {
    // 	return (
    // 		<>
    // 			{isAtLeastOneSelected && (
    // 				<Button variant="text" className={theme.buttonBase} onClick={onClearAll} disabled={props.isLoading}>
    // 					{t('Clear all')}
    // 				</Button>
    // 			)}
    // 			{!isAtLeastOneSelected && (
    // 				<Button
    // 					variant="text"
    // 					className={theme.buttonBase}
    // 					onClick={onSelectAll}
    // 					disabled={props.isLoading}
    // 				>
    // 					{t('Select all')}
    // 				</Button>
    // 			)}
    // 		</>
    // 	);
    // };
    var renderBottom = useCallback(function () {
        var isPaidServicesExist = !!(totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.amount);
        return (React.createElement("div", { className: theme.summary },
            !props.exchangeVoidMode && (React.createElement("div", { className: theme.buttons },
                React.createElement(Button, { variant: "text", className: theme.buttonBase, onClick: onClickModalOpen }, t('Change')))),
            !props.exchangeVoidMode && isPaidServicesExist && (React.createElement("div", { className: theme.summary__total },
                React.createElement("span", { className: theme.summary__totalTitle }, t('Total')),
                React.createElement(Money, { moneyClassName: theme.summary__price, money: totalPrice })))));
    }, [totalPrice, props.isLoading, props.step]);
    var renderServicesModals = function () {
        var servicePageProps = useServicePageProps();
        return (React.createElement(React.Fragment, null,
            React.createElement(ServicePage, null, function (childrenProps) { return (React.createElement(React.Fragment, null, React.createElement(ServiceCardDialog, __assign({ order: servicePageProps.order, openedModal: openedModal, onClose: toggleModal.bind(null, null), passengers: servicePageProps.passengers, passengerLabels: servicePageProps.passengerLabels }, childrenProps)))); }),
            isMisc && services.length > 0 && (React.createElement(ExtraServicesModal, { open: isExtrasModalOpen, passengers: servicePageProps.passengers, selectedServices: convertedSelectedServices, services: services, segments: segments, onConfirm: onExtrasConfirm, onClose: onCloseExtras }))));
    };
    var isEmpty = Content === null;
    if (props.exchangeVoidMode && !((_d = (_c = props.itemsToRefundVoid) === null || _c === void 0 ? void 0 : _c.productsIds) === null || _d === void 0 ? void 0 : _d.length)) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        isExtrasLoading && React.createElement(SimpleLoader, null),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), errorMessage: saveOrderServicesError, onClose: clearError, onButtonClick: clearError, buttonText: t('OK, thank you'), isOpen: saveOrderServicesError !== null }),
        !props.isExchangePage && renderServicesModals(),
        React.createElement("div", { className: cn(theme.serviceCard, (_a = {}, _a[theme.serviceCard__fullWidth] = props.fullWidth, _a)) },
            renderHeader(),
            isEmpty ? (React.createElement("div", { className: cn(theme.content, theme.content_empty, headerProps.backgroundClassName) },
                React.createElement("div", { className: theme.emptyCard__text }, EmptyContent()),
                React.createElement("div", { className: theme.emptyCard__bottom },
                    React.createElement("div", { className: theme.priceFrom }, minPrice.amount === 0 ? (t('Free')) : (React.createElement(React.Fragment, null,
                        t('from'),
                        " ",
                        React.createElement(Money, { money: minPrice })))),
                    React.createElement(Button, { variant: "common", className: theme.addButton, onClick: onClickModalOpen }, t('Add'))))) : (React.createElement("div", { className: cn(theme.content, (_b = {}, _b[contentClassName] = isSelected, _b)) },
                React.createElement(ServiceCardContext.Provider, { value: {
                        exchangeVoidMode: props.exchangeVoidMode,
                        isLoading: props.isLoading,
                        step: props.step,
                        calculation: props.calculation,
                        productIdsForVoidExchange: (_e = props.itemsToRefundVoid) === null || _e === void 0 ? void 0 : _e.productsIds,
                        servicesIds: (_f = props.itemsToRefundVoid) === null || _f === void 0 ? void 0 : _f.servicesIds,
                        setProductsIdsByEmd: props.setProductsIdsByEmd
                    } }, isSelected ? React.createElement("div", null, description) : Content()),
                renderBottom())))));
};
export default ServiceCard;
