import { getLocalization, isDigits, months } from './helpers';
import { sirenaTransportationOptions } from './sirena/sirenaTransportationOptions';
import { sirenaTransportationOptionsValidation } from './sirena/sirenaTransportationOptionsValidation';
import { amadeusTransportationOptions } from './amadeus/amadeusTransportationOptions';
import { amadeusTransportationOptionsValidation } from './amadeus/amadeusTransportationOptionsValidation';
var SLASH = /\//;
var LINEFEEDS = /\n+/g;
export var isSirena = function (str) {
    var twoLastChars = str.slice(-2);
    var threeLastChars = str.slice(-3);
    if (isDigits(twoLastChars)) {
        if (SLASH.test(threeLastChars)) {
            return false;
        }
        else {
            return true;
        }
    }
    else {
        var localization_1 = getLocalization(threeLastChars);
        if (months.some(function (month) { return month[localization_1] === threeLastChars; })) {
            return true;
        }
        else {
            return false;
        }
    }
};
export var isMultiline = function (str) { return !!str.match(LINEFEEDS); };
export var transportationOptions = function (str) {
    if (isMultiline(str) || isSirena(str)) {
        return sirenaTransportationOptions(str);
    }
    else {
        return amadeusTransportationOptions(str);
    }
};
export var syntaxValidationTO = function (_a) {
    var str = _a.str, citiesIata = _a.citiesIata, passengerTypeCodes = _a.passengerTypeCodes;
    if (isMultiline(str) || isSirena(str)) {
        return sirenaTransportationOptionsValidation({ str: str, citiesIata: citiesIata, passengerTypeCodes: passengerTypeCodes });
    }
    else {
        return amadeusTransportationOptionsValidation({ str: str, citiesIata: citiesIata, passengerTypeCodes: passengerTypeCodes });
    }
};
