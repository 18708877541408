import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import Modal from '../../../../Modal';
import { useToggleable } from '../../../../hooks';
import CloseIcon from '../../../../CloseIcon/components/CloseIcon';
import Button from '../../../../Button';
import Passenger from './Passenger/Passenger';
var OrderCoupons = function (props) {
    var _a, _b;
    var _c = props.dateFormat, dateFormat = _c === void 0 ? 'dd LLL yyyy' : _c, passengers = props.passengers;
    var theme = useTheme('LeoOrderCard').OrderCoupons;
    var t = useTranslation('OrderCard').t;
    var _d = useToggleable(false), isModalOpen = _d.isOpen, openModal = _d.open, closeModal = _d.close;
    return (React.createElement(React.Fragment, null, (_b = (_a = props.couponsButtonRenderer) === null || _a === void 0 ? void 0 : _a.call(props, { onClick: openModal })) !== null && _b !== void 0 ? _b : (React.createElement(Button, { onClick: openModal }, t('Coupons info'))),
        React.createElement(Modal, { open: isModalOpen, onClose: closeModal, classes: { paper: theme.modal__paper }, closeButtonRenderer: function (onClick) { return (React.createElement("div", { className: theme.popup__close },
                React.createElement(CloseIcon, { onClick: onClick }))); } },
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: theme.header }, t('Coupons info')),
                React.createElement("div", { className: theme.passengers }, passengers.map(function (passenger) { return (React.createElement(Passenger, { passengersCount: passengers.length, key: passenger.name, dateFormat: dateFormat, name: passenger.name, emds: passenger.emds, tickets: passenger.tickets })); }))))));
};
export default OrderCoupons;
