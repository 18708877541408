import { __assign, __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useTheme } from '../../../theme';
var ActionButton = function (_a) {
    var _b;
    var _c = _a.size, size = _c === void 0 ? 'common' : _c, white = _a.white, props = __rest(_a, ["size", "white"]);
    var _d = useTheme('Button'), css = _d.ActionButton, whiteButtonStyles = _d.WhiteButton, cssButton = _d.Button;
    var children = props.children, className = props.className, onClick = props.onClick, isLoading = props.isLoading, restProps = __rest(props, ["children", "className", "onClick", "isLoading"]);
    return (React.createElement(ButtonBase, __assign({ type: "button" }, restProps, { className: cn(css.button, (_b = {},
            _b[css.button_small] = size === 'small',
            _b[whiteButtonStyles.button] = !!white,
            _b), className), onClick: restProps.disabled ? null : onClick }), isLoading ? React.createElement("div", { className: cssButton.loader__spinner }) : children));
};
export default ActionButton;
