import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import { forwardRef, useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { useQuery } from '@apollo/react-hooks';
import Autocomplete from '../../Autocomplete';
import { overriddenTheme } from '../theme';
import { parseLocationGroups } from '../utils';
import MobileDialog from './MobileDialog/MobileDialog';
import { getUserLocation, initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import { LocationType } from '../../types';
import { LocationAdditionalGroupType, LocationGroupType } from '../../__generated__/globalTypes';
import OptionComponent from './Option/Option';
import Dropdown from './Dropdown/Dropdown';
import Group from './Group/Group';
import * as LocationsQuery from '../../__queries__/Locations.graphql';
import { ThemeProvider, useTheme } from '../../theme';
initI18n('LocationAutocomplete');
var optionRenderer = function (option, key, onClick) { return (React.createElement(OptionComponent, { key: key, option: option, onClick: onClick })); };
var optionGroupRenderer = function (group, key, optionRenderer) { return React.createElement(Group, { key: key, group: group, optionRenderer: optionRenderer }); };
var LocationAutocomplete = forwardRef(function (props, ref) {
    var theme = useTheme('Autocomplete').Autocomplete;
    var _a = __read(useState(''), 2), query = _a[0], setQuery = _a[1];
    var _b = __read(useState(), 2), lastSuggestion = _b[0], setLastSuggestion = _b[1];
    var _c = __read(useState(false), 2), delayLoading = _c[0], setDelayLoading = _c[1];
    var _d = __read(useState(null), 2), coordinates = _d[0], setCoordinates = _d[1];
    var _e = props.recentSearches, recentSearches = _e === void 0 ? [] : _e, _f = props.locations, locations = _f === void 0 ? {} : _f;
    var refObject = props.ref, restProps = __rest(props, ["ref"]);
    if (!coordinates) {
        getUserLocation(setCoordinates);
    }
    var _g = useQuery(LocationsQuery.Locations, {
        variables: {
            parameters: {
                additionalCities: recentSearches.length ? recentSearches.map(function (location) { return location.iata; }) : [],
                additionalGroups: [
                    LocationAdditionalGroupType.Nearest,
                    LocationAdditionalGroupType.AdditionalCities,
                    LocationAdditionalGroupType.Popular
                ],
                query: query,
                userPosition: coordinates,
                departureFrom: props.type !== LocationType.Departure && locations.departure ? locations.departure.iata : null
            }
        }
    }), data = _g.data, loading = _g.loading;
    useEffect(function () {
        var nearestAirport = data &&
            data.LocationSuggestions &&
            data.LocationSuggestions.find(function (location) { return location.type === LocationGroupType.Nearest; });
        if (props.type === LocationType.Departure && !props.locations.departure && nearestAirport) {
            props.onSelect(nearestAirport.cities[0]);
        }
    }, [props.type]);
    var onInputChange = function (event) { return setQuery(event.target.value); };
    useEffect(function () {
        setDelayLoading(true);
        if (query.length >= 0 && data && data.LocationSuggestions && data.LocationSuggestions.length) {
            setLastSuggestion(data);
        }
        else if (query.length > 0 && (!data || !data.LocationSuggestions || !data.LocationSuggestions.length)) {
            setTimeout(function () {
                setDelayLoading(false);
                setLastSuggestion(null);
            }, 700);
        }
        else {
            setDelayLoading(false);
        }
    }, [data]);
    var onSelect = function (airport) {
        setQuery('');
        if (props.onSelect) {
            props.onSelect(airport);
        }
    };
    var onBlur = function (event) {
        setQuery('');
        props === null || props === void 0 ? void 0 : props.onBlur(event);
    };
    var handleOptions = function (data) {
        return parseLocationGroups(data && data.LocationSuggestions ? data.LocationSuggestions : [], query, props.type);
    };
    var dropdownRenderer = function (dropdownProps) { return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dropdown, __assign({}, dropdownProps, { isOpen: dropdownProps.isOpen, hasInputData: !!query.trim(), onClickOutsideProps: props.onClickOutsideProps, onSelect: onSelect, optionsClassName: theme.locationOptions }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(MobileDialog, __assign({}, dropdownProps, { type: props.type }))))); };
    return (React.createElement(ThemeProvider, { value: { Autocomplete: overriddenTheme } },
        React.createElement(Autocomplete, __assign({ innerRef: ref }, restProps, { isLoading: loading, onSelect: onSelect, options: loading || delayLoading ? handleOptions(lastSuggestion) : handleOptions(data), inputValue: query, onInputChange: onInputChange, optionRenderer: optionRenderer, groupRenderer: optionGroupRenderer, dropdownRenderer: dropdownRenderer, onBlur: onBlur }))));
});
export default LocationAutocomplete;
