import { __assign, __rest } from "tslib";
import React, { useContext } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { OrderStatus as OrderStatusEnum } from '../../../__generated__/graphql';
import OrderStatusText from './OrderStatusText/OrderStatusText';
import { OrderCardContext } from '../OrderCard';
import { useTranslation } from 'react-i18next';
import CommonItem from '../CommonItem/CommonItem';
import { Included } from '../../../Icons';
var OrderStatus = function (_a) {
    var _b;
    var className = _a.className, props = __rest(_a, ["className"]);
    var css = useTheme('OrderCard').OrderStatus;
    var t = useTranslation('OrderCard').t;
    var _c = useContext(OrderCardContext), order = _c.order, type = _c.type, onTransactionsClick = _c.onTransactionsClick;
    var isBookedOrConfirmed = order.status === OrderStatusEnum.Booked || order.status === OrderStatusEnum.Confirmed;
    return (React.createElement(CommonItem, __assign({ icon: isBookedOrConfirmed && Included, type: type, iconClassName: css.icon, text: React.createElement(OrderStatusText, { status: order.status }), buttons: React.createElement("div", { className: css.transactions_wrapper },
            React.createElement("span", { className: css.transactions, onClick: onTransactionsClick }, t('Transactions'))), className: cn(css.root, className, (_b = {},
            _b[css.confirmed] = isBookedOrConfirmed,
            _b[css.type_review] = type === 'review',
            _b[css.type_account] = type === 'account',
            _b)) }, props)));
};
export default OrderStatus;
