import { __rest } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import ButtonBase from '@material-ui/core/ButtonBase';
import Amenities from '../../../Amenities';
import Modal from '../../../Modal';
import AircraftPopupContent from '../../../AircraftPopup/components/Component';
import Weather from '../../../Weather';
import { useMobileSegmentInfo } from '../../hooks';
import { initI18n } from '../../../utils';
initI18n('MobileSegmentInfo');
var leafIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M6.47561 5.43687C3.25652 7.31002 3.4413 10.361 3.55213 11.4899C7.70996 6.55073 13.9292 6.79026 13.9292 6.79026C13.9292 6.79026 5.11326 9.82287 2.52396 15.9041C2.31948 16.3842 3.48341 17.0085 3.74904 16.441C4.5419 14.7497 5.64671 13.4813 5.64671 13.4813C7.27672 14.0896 10.0964 14.8025 12.095 13.3921C14.7497 11.5187 14.4783 7.3656 18.2679 5.34346C19.1531 4.87133 10.8398 2.89717 6.47561 5.43687Z", fill: "#41B74C" })));
var MobileSegmentFullInfo = function (props) {
    var _a;
    var _b, _c, _d;
    var css = useTheme('MobileSegmentInfo').MobileSegmentInfo;
    var t = useTranslation().t;
    var detailsOpen = props.detailsOpen, toggleDetails = props.toggleDetails, mobileSegmentInfoProps = __rest(props, ["detailsOpen", "toggleDetails"]);
    var _e = useMobileSegmentInfo(mobileSegmentInfoProps), allAirlines = _e.allAirlines, openAircraftPopup = _e.openAircraftPopup, isAircraftPopupOpen = _e.isAircraftPopupOpen, firstSegment = _e.firstSegment, lastSegment = _e.lastSegment;
    return (React.createElement("div", { className: cn(css.details, (_a = {}, _a[css.opened] = detailsOpen, _a)) },
        React.createElement("div", { className: css.details__short, onClick: function () { return toggleDetails(!detailsOpen); } },
            React.createElement("div", { className: css.details__summary },
                React.createElement("span", { className: css.details__logo },
                    React.createElement("img", { src: firstSegment.operatingAirline.icon, alt: firstSegment.operatingAirline.name })),
                firstSegment.flightInfo && firstSegment.flightInfo.markers.eco && (React.createElement("span", { className: css.details__eco }, leafIcon)),
                React.createElement("span", null, "".concat(firstSegment.marketingAirline.iata, "-").concat(firstSegment.flightNumber)),
                React.createElement("span", null, allAirlines.join(', ')),
                ((_b = firstSegment.aircraft) === null || _b === void 0 ? void 0 : _b.name) && React.createElement("span", null, firstSegment.aircraft.name)),
            React.createElement("div", { className: css.details__arrow })),
        React.createElement(Collapse, { in: detailsOpen },
            React.createElement("div", { className: css.details__full },
                firstSegment.flightInfo && firstSegment.flightInfo.statistics && (React.createElement("ul", { className: css.statistics }, firstSegment.flightInfo.statistics.map(function (item, index) { return (React.createElement("li", { className: css.statistics__item, key: index },
                    React.createElement("span", { className: css.statistics__item__title }, item.title),
                    React.createElement("span", { className: css.statistics__item__value }, item.value))); }))),
                firstSegment.aircraft && firstSegment.aircraft.id && (React.createElement(ButtonBase, { className: css.aircraft, onClick: function () { return openAircraftPopup(true); } },
                    firstSegment.aircraft && (React.createElement("div", { className: css.aircraft__name }, firstSegment.aircraft.name)),
                    ((_c = firstSegment.aircraft) === null || _c === void 0 ? void 0 : _c.icon) && (React.createElement("img", { className: css.aircraft__image, src: firstSegment.aircraft.icon, alt: firstSegment.aircraft.name })),
                    React.createElement("div", { className: css.aircraft__features },
                        React.createElement(Amenities, { amenities: firstSegment.flightInfo ? firstSegment.flightInfo.amenities : [] }),
                        React.createElement("div", { className: css.aircraft__features__moreInfo }, t('MobileSegmentInfo:More info'))))),
                ((_d = firstSegment.aircraft) === null || _d === void 0 ? void 0 : _d.id) && (React.createElement(Modal, { classes: { root: css.aircraftModal__root }, open: isAircraftPopupOpen, onClose: function () { return openAircraftPopup(false); } },
                    React.createElement(AircraftPopupContent, { id: firstSegment.aircraft.id }))),
                React.createElement("div", null, firstSegment.departure.weather && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css.airport__info__name },
                        firstSegment.departure.airport.name,
                        " (",
                        firstSegment.departure.airport.iata,
                        ")"),
                    React.createElement("div", { className: css.airport__info },
                        React.createElement(Weather, { airport: firstSegment.departure.airport, weatherInfo: firstSegment.departure.weather }))))),
                React.createElement("div", null, lastSegment.arrival.weather && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css.airport__info__name },
                        lastSegment.arrival.airport.name,
                        " (",
                        lastSegment.arrival.airport.iata,
                        ")"),
                    React.createElement("div", { className: css.airport__info },
                        React.createElement(Weather, { airport: lastSegment.arrival.airport, weatherInfo: lastSegment.arrival.weather })))))))));
};
export default MobileSegmentFullInfo;
