import { __read } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { MinFlightPrice } from './MinFlightPrice/MinFlightPrice';
import { getMinPrices } from '../../../Results/utils';
import { SelectedFlightPrice } from './SelectedFlightPrice/SelectedFlightPrice';
var FlightPrice = function (_a) {
    var best = _a.best, singleFlight = _a.singleFlight, isSelected = _a.isSelected, flight = _a.flight, selectedFares = _a.selectedFares, canceled = _a.canceled;
    var _b = __read(useMemo(function () {
        var _a, _b, _c, _d, _e, _f;
        var minPrice = getMinPrices(flight.pricesForFareGroups, true, (_b = (_a = selectedFares[0]) === null || _a === void 0 ? void 0 : _a.fareGroupId) !== null && _b !== void 0 ? _b : null);
        var minOriginalPrice = getMinPrices(flight.pricesForFareGroups, true, (_d = (_c = selectedFares[0]) === null || _c === void 0 ? void 0 : _c.fareGroupId) !== null && _d !== void 0 ? _d : null, true);
        return [
            minPrice[0].price,
            ((_f = (_e = minOriginalPrice[0]) === null || _e === void 0 ? void 0 : _e.price) === null || _f === void 0 ? void 0 : _f.amount) > minPrice[0].price.amount && minOriginalPrice[0].price,
            minPrice[0].priceInMiles
        ];
    }, [flight]), 3), price = _b[0], originalPrice = _b[1], priceInMiles = _b[2];
    var _c = __read(useMemo(function () {
        if (!isSelected) {
            return [null, null];
        }
        var fareFamily = flight.pricesForFareGroups[0].fareFamily;
        return [fareFamily, selectedFares[0]];
    }, [flight, selectedFares, isSelected]), 2), fareFamily = _c[0], selectedFare = _c[1];
    if (selectedFare) {
        return (React.createElement(SelectedFlightPrice, { fareFamily: fareFamily, selectedFare: selectedFare, canceled: canceled, priceInMiles: priceInMiles }));
    }
    else {
        return (React.createElement(MinFlightPrice, { singleFlight: singleFlight, best: best, originalPrice: originalPrice, price: price, priceInMiles: priceInMiles }));
    }
};
export default FlightPrice;
