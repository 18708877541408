import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import CommonItem from '../CommonItem/CommonItem';
import { Date } from '../../../Icons';
import { Button } from '../../../index';
import { useTheme } from '../../../theme';
var OrderControls = function (_a) {
    var exchangeAvailable = _a.exchangeAvailable, refundAvailable = _a.refundAvailable, onClick = _a.onClick, show = _a.show, props = __rest(_a, ["exchangeAvailable", "refundAvailable", "onClick", "show"]);
    var t = useTranslation('OrderCard').t;
    var theme = useTheme('OrderCard').OrderControls;
    if (!show) {
        return null;
    }
    return (React.createElement(CommonItem, __assign({ className: theme.root, icon: Date, text: t('Changed your plans?'), buttons: React.createElement(React.Fragment, null,
            exchangeAvailable && (React.createElement(Button, { onClick: function () { return onClick('exchange'); }, className: theme.button }, t('Exchange'))),
            refundAvailable && (React.createElement(Button, { onClick: function () { return onClick('refund'); }, className: theme.button }, t('Refund')))) }, props)));
};
export default OrderControls;
