import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { SlideBottom } from '../../../index';
import { arrowIcon } from '../../../Passengers/icons';
import { SELECTED_DISHES_CATEGORY_ID, ALL_DISHES_CATEGORY_ID } from '../../constants';
import List from './List/List';
import CloseIcon from '../../../CloseIcon/components/CloseIcon';
var Categories = function (props) {
    var theme = useTheme('MealService').CategoriesStyles;
    var t = useTranslation('MealService').t;
    var _a = __read(useState(false), 2), isOpenSlide = _a[0], setOpenSlide = _a[1];
    var toggleOpenSlide = function () { return setOpenSlide(!isOpenSlide); };
    var mobileButtonTitle = '';
    switch (props.selectedCategory) {
        case ALL_DISHES_CATEGORY_ID:
            mobileButtonTitle = t('All');
            break;
        case SELECTED_DISHES_CATEGORY_ID:
            mobileButtonTitle = t('Selected dishes');
            break;
        default:
            mobileButtonTitle = props.categories[props.selectedCategory];
    }
    var categories = __spreadArray([
        {
            id: ALL_DISHES_CATEGORY_ID,
            title: t('All')
        }
    ], __read(props.categories.map(function (title, index) { return ({ id: index, title: title }); })), false);
    if (props.numOfSelectedMeals > 0) {
        categories.push({
            id: SELECTED_DISHES_CATEGORY_ID,
            title: "".concat(t('Selected dishes'), " (").concat(props.numOfSelectedMeals, ")")
        });
    }
    var mobileOnSelect = useCallback(function (id) {
        props.onSelect(id);
        toggleOpenSlide();
    }, [props.onSelect, isOpenSlide]);
    return (React.createElement("div", { className: theme.wrapper },
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(List, { categories: categories, selectedCategory: props.selectedCategory, onSelect: props.onSelect })),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: cn(theme.category__button), onClick: toggleOpenSlide },
                React.createElement("div", null, mobileButtonTitle),
                React.createElement("div", { className: theme.button__icon }, arrowIcon)),
            React.createElement(SlideBottom, { isOpen: isOpenSlide, onClose: toggleOpenSlide },
                React.createElement("div", { className: theme.mobile__header },
                    React.createElement("span", { className: theme.mobile__title }, t('Select menu')),
                    React.createElement(CloseIcon, { isGray: true, onClick: toggleOpenSlide })),
                React.createElement(List, { categories: categories, selectedCategory: props.selectedCategory, onSelect: mobileOnSelect })))));
};
export default Categories;
