import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
var MessagePagination = function (_a) {
    var message = _a.message;
    var theme = useTheme('Terminal').Message;
    var t = useTranslation('Terminal').t;
    return (React.createElement(React.Fragment, null,
        React.createElement("span", null, message.response),
        React.createElement("div", { className: theme.message__continue },
            React.createElement("span", null),
            React.createElement("div", { className: theme.message__text }, t('Press any key or scroll down')),
            React.createElement("span", null))));
};
export default MessagePagination;
