import { __assign } from "tslib";
import * as React from 'react';
import Slider from 'react-slick';
import { ChevronRightIcon } from '../Icons';
import { useTheme } from '../../../theme';
var AircraftPopupCarousel = function (_a) {
    var items = _a.items;
    var css = useTheme('AircraftPopup').AircraftPopupCarousel;
    var settings = {
        arrows: true,
        dots: true,
        nextArrow: React.createElement("div", null, ChevronRightIcon),
        prevArrow: React.createElement("div", null, ChevronRightIcon)
    };
    return (React.createElement(Slider, __assign({}, settings, { className: css.carousel }), items.map(function (item) { return (React.createElement("img", { src: item, key: item, alt: "".concat(item) })); })));
};
export default AircraftPopupCarousel;
