import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { TABLET_MIDDLE_WIDTH } from '../../../utils';
import { useTheme } from '../../../theme';
import Amenities from '../../../Amenities';
import SeatInfoGroup from './SeatInfoGroup/SeatInfoGroup';
var SeatsInfo = function (props) {
    var theme = useTheme('SeatMap').SeatsInfoStyles;
    var t = useTranslation('SeatMap').t;
    var seatsByClassesMap = React.useMemo(function () {
        var seatsByClasses = new Map();
        props.seatsInfo.forEach(function (seat) {
            seatsByClasses.set(seat.serviceClass, seatsByClasses.has(seat.serviceClass) ? __spreadArray(__spreadArray([], __read(seatsByClasses.get(seat.serviceClass)), false), [seat], false) : [seat]);
        });
        return seatsByClasses;
    }, [props.seatsInfo]);
    var seatsByClasses = React.useMemo(function () {
        var seatsByClasses = [];
        seatsByClassesMap.forEach(function (seats, classType) {
            var _a;
            seatsByClasses.push({
                classType: classType,
                seats: seats,
                image: (_a = seats.find(function (seat) { return !!seat.serviceImage; })) === null || _a === void 0 ? void 0 : _a.serviceImage
            });
        });
        return seatsByClasses;
    }, [seatsByClassesMap]);
    var renderSeatsByClasses = function (mobile) {
        return seatsByClasses.map(function (seats) {
            return (React.createElement("div", null,
                React.createElement("div", { className: theme.classInfo },
                    React.createElement("img", { className: theme.image, src: seats.image, alt: seats.classType }),
                    React.createElement("div", { className: theme.classInfo__payload },
                        React.createElement("div", null, t(seats.classType)),
                        props.amenities && (React.createElement(Amenities, { amenities: props.amenities.filter(function (amenity) { return amenity.available; }) })))),
                React.createElement(SeatInfoGroup, { seatService: seats.seats, fullInfo: mobile, hideImage: true })));
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: TABLET_MIDDLE_WIDTH },
            React.createElement("aside", { className: cn(theme.info) }, renderSeatsByClasses())),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH }, renderSeatsByClasses(true))));
};
export default SeatsInfo;
