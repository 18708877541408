import { __assign } from "tslib";
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { docFields, docFieldsOrder } from '../../constants';
import cn from 'classnames';
import { Button, WarningRoundedFilled } from '../../../../index';
import { InputType } from '../../../../Passenger/types';
import { TravellerFieldEnum } from '../../../../__generated__/graphql';
import { format } from '../../../../utils';
import { useDocTypeFieldOptions, useNationalityFieldOptions } from '../../hooks';
import { Field } from '../Field/Field';
import { useFormState } from 'react-final-form';
var Recognized = function (props) {
    var traveller = props.traveller;
    var nationalityOptions = useNationalityFieldOptions(traveller);
    var docTypeOptions = useDocTypeFieldOptions(traveller);
    var values = useFormState().values;
    var t = useTranslation('DocumentRecognition').t;
    var _a = useTheme('DocRecognition'), contentCss = _a.RecognitionPopupContent, css = _a.Recognized;
    var _b = useMemo(function () {
        var nationalityField;
        var pFields = {};
        var _loop_1 = function (fieldName) {
            var field = docFields[fieldName];
            var newField = __assign({}, field);
            newField.placeholder = t(field.name);
            if (newField.type === TravellerFieldEnum.Nationality) {
                newField.options = nationalityOptions;
            }
            else if (newField.type === TravellerFieldEnum.DocType) {
                newField.options = [];
            }
            var valueField = props.fields.find(function (f) { return f.type === field.type; });
            if (valueField) {
                newField.success = !valueField.needCheck;
                newField.warning = valueField.needCheck;
                var newValue = valueField.value;
                if (newField.input === InputType.Date) {
                    newValue = format(new Date(newValue), 'dd.MM.yyyy');
                }
                else if (newField.input === InputType.Switch) {
                    // потому что Switch компонент принимает значение в виде первой буквы пола
                    newValue = newValue[0];
                }
                // если задавать значение полям через мутацию формы или через formApi.change, то возникает варнинг
                // Warning: Cannot update a component from inside the function body of a different component.
                // хотя поля при этом все равно устанавливаются (кроме пола). Но чтобы не засорять консоль пока делаю через дефолтные значения
                newField.defaultValue = newValue;
            }
            else {
                newField.defaultValue = '';
                newField.success = props.fields.length ? !props.fields.length : undefined;
                newField.warning = props.fields.length ? !!props.fields.length : undefined;
            }
            if (newField.type === TravellerFieldEnum.Nationality) {
                nationalityField = newField;
            }
            pFields[fieldName] = newField;
        };
        for (var fieldName in docFields) {
            _loop_1(fieldName);
        }
        return {
            preparedFields: pFields,
            nationalityField: nationalityField
        };
    }, [props.fields]), preparedFields = _b.preparedFields, nationalityField = _b.nationalityField;
    var hasNeedCheckField = useMemo(function () { return props.fields.some(function (field) { return field.needCheck; }); }, [props.fields]);
    return (React.createElement("div", { className: props.className },
        React.createElement("div", { className: contentCss.title }, t('Recognized title')),
        React.createElement("div", null,
            React.createElement("div", { className: css.fields }, docFieldsOrder.map(function (fieldNames, index) { return (React.createElement("div", { className: css.fieldWrap, key: "field_".concat(index) }, fieldNames.map(function (fieldName) {
                var _a;
                var _b, _c;
                var field = preparedFields[fieldName];
                if (field.type === TravellerFieldEnum.DocType &&
                    (!props.isInputsReadonly || values[TravellerFieldEnum.Nationality])) {
                    var nationality = (_b = values[TravellerFieldEnum.Nationality]) !== null && _b !== void 0 ? _b : nationalityField === null || nationalityField === void 0 ? void 0 : nationalityField.defaultValue;
                    field.options = (_c = docTypeOptions[nationality]) !== null && _c !== void 0 ? _c : docTypeOptions.OTHER;
                }
                return (field && (React.createElement(Field, { key: "".concat(field.name), className: cn(css.field, (_a = {},
                        _a[css.field_gender] = field.type === TravellerFieldEnum.Gender,
                        _a)), disabled: props.isInputsReadonly || field.readonly, field: field })));
            }))); })),
            hasNeedCheckField && (React.createElement("div", { className: css.warningText },
                WarningRoundedFilled,
                React.createElement("span", null, t('Please check the data that has been recognized, if field highlighted in yellow.')))),
            React.createElement("div", { className: cn(contentCss.bottomBlock, css.bottom) },
                React.createElement(Button, { type: 'submit', size: 'common', variant: 'flat', disabled: !props.fields.length }, t('Confirm button text'))))));
};
export default Recognized;
