import { __assign, __read } from "tslib";
import React from 'react';
import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Seats from '../../components/Seats/Seats';
import { useTheme } from '../../../../theme';
import { getGroupedAllSelectedServices } from '../../../../Checkout/store/order/selectors';
import { getOrder } from '../../../../Checkout/store/order/selectors';
import { seatsIcon } from '../../icons';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../../__generated__/globalTypes';
import { calculateMinPrice, calculateShowClearValue, deleteServicesByType, isEmptyServices } from '../../utils';
export var useSeatsServiceCard = function () {
    var seats = useSelector(getGroupedAllSelectedServices).seats;
    var order = useSelector(getOrder);
    var t = useTranslation('ServiceCard').t;
    var theme = useTheme('ServicesCard').Seats;
    var dispatch = useDispatch();
    var _a = useMemo(function () {
        var price = null;
        var servicesIds = [];
        Object.values(seats).forEach(function (segments) {
            Object.entries(segments).forEach(function (_a) {
                var _b;
                var _c = __read(_a, 2), segment = _c[0], services = _c[1];
                (_b = services.additional) === null || _b === void 0 ? void 0 : _b.forEach(function (service) {
                    var _a;
                    if (service.isConfirmed) {
                        return;
                    }
                    if (!price) {
                        price = __assign({}, service.seat.price);
                    }
                    else {
                        price.amount += service.seat.price.amount;
                    }
                    servicesIds.push((_a = service.seat.seatService) === null || _a === void 0 ? void 0 : _a.id);
                });
            });
        });
        return {
            totalPrice: price,
            servicesIds: servicesIds
        };
    }, [seats]), totalPrice = _a.totalPrice, servicesIds = _a.servicesIds;
    var clearAll = useCallback(function () {
        deleteServicesByType(servicesIds, OrderAdditionalServiceGdsServiceServiceType.Seat, dispatch);
    }, [servicesIds]);
    var isShowClearAll = useMemo(function () { return calculateShowClearValue(seats, OrderAdditionalServiceGdsServiceServiceType.Seat); }, [seats]);
    var isEmpty = useMemo(function () { return isEmptyServices(seats); }, [seats]);
    var minPrice = useMemo(function () {
        return calculateMinPrice(order.additionalServices.gdsServices.services, OrderAdditionalServiceGdsServiceServiceType.Seat);
    }, [order.additionalServices.gdsServices.services]);
    return {
        Content: isEmpty
            ? null
            : function () { return React.createElement(Seats, { order: order, services: seats, backgroundClassName: theme.backgroundColor }); },
        EmptyContent: function () { return React.createElement("div", null, t('The top ones get the top seats!')); },
        contentClassName: theme.content,
        description: 'Seats',
        headerProps: {
            title: t('Seats'),
            icon: seatsIcon,
            backgroundClassName: theme.backgroundColor
        },
        totalPrice: totalPrice,
        minPrice: minPrice,
        clearAll: clearAll,
        isShowClearAll: isShowClearAll
    };
};
