import { useQuery } from '@apollo/react-hooks';
import * as LocationsQuery from './../../../__queries__/Locations.graphql';
import * as PassengerTypeInfoQuery from './../../../__queries__/PassengerTypeInfo.graphql';
export var useGraphqlData = function () {
    var getCitiesIata = function () {
        var data = useQuery(LocationsQuery.Locations, {
            variables: {
                parameters: {
                    query: ''
                }
            }
        }).data;
        if (data && data.LocationSuggestions) {
            return data.LocationSuggestions.map(function (_a) {
                var cities = _a.cities;
                return cities.map(function (_a) {
                    var iata = _a.iata;
                    return iata;
                });
            })
                .join(',')
                .split(',');
        }
        else {
            return [];
        }
    };
    var getPassengerTypeCodes = function () {
        var data = useQuery(PassengerTypeInfoQuery.PassengerTypeInfo, {
            variables: {
                parameters: {
                    query: ''
                }
            }
        }).data;
        if (data && data.PassengerTypeCodes) {
            return data.PassengerTypeCodes.map(function (_a) {
                var code = _a.code;
                return code.map(function (_a) {
                    var value = _a.value;
                    return value;
                }).join(',');
            })
                .join(',')
                .split(',');
        }
        else {
            return [];
        }
    };
    return {
        citiesIata: getCitiesIata(),
        passengerTypeCodes: getPassengerTypeCodes()
    };
};
