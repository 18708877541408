import React from 'react';
import cn from 'classnames';
import { Button } from '../../../../index';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
var ClearModal = function (_a) {
    var className = _a.className, onClose = _a.onClose, onClear = _a.onClear;
    var css = useTheme('Terminal').ClearModal;
    var t = useTranslation('Terminal').t;
    return (React.createElement("div", { className: cn(className, css.container) },
        React.createElement("div", { className: css.text }, t('Are you sure you want to clear your request history?')),
        React.createElement("div", { className: css.footer },
            React.createElement(Button, { className: cn(css.button, css.button_cancel), onClick: onClose }, t('Cancel')),
            React.createElement(Button, { className: css.button, onClick: onClear }, t('Ok')))));
};
export default ClearModal;
