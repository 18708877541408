import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { isSameDay } from 'date-fns';
import { useTheme } from '../theme';
import { format, initI18n, MOBILE_MIN_WIDTH } from '../utils';
import { alertIcon } from './Icons';
import { Button } from '../index';
import { useMediaQuery } from 'react-responsive';
initI18n('PaymentTimeLimit');
var PaymentTimeLimit = function (props) {
    var _a;
    var theme = useTheme('PaymentTimeLimit').PaymentTimeLimit;
    var t = useTranslation('PaymentTimeLimit').t;
    var isToday = isSameDay(props.date, new Date());
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    return (React.createElement("div", { className: cn(theme.wrapper, props.className, (_a = {},
            _a[theme.wrapper_outlined] = props.variant === 'outlined',
            _a)) },
        React.createElement("span", { className: theme.info },
            React.createElement("span", { className: theme.icon }, alertIcon),
            props.orderIsBooked ? t('Booking has to be paid') : t('Booking must be completed'),
            "\u00A0",
            isToday
                ? "".concat(t('today before'), " ").concat(format(props.date, 'HH:mm'))
                : "".concat(t('before'), " ").concat(format(props.date, 'dd MMMM, HH:mm'))),
        props.orderIsBooked && props.onCancelOrder && (React.createElement(Button, { onClick: function () {
                props.onCancelOrder();
            }, className: theme.cancelButton, disableTouchRipple: isMobile }, t('Cancel order')))));
};
export default PaymentTimeLimit;
