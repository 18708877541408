import { __values } from "tslib";
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { AviaPassengerType, TravellerFieldEnum } from '../../../../__generated__/globalTypes';
import { Select } from '../Select/Select';
export var allowedDocuments = {
    ru: {
        Passport: true,
        MilitaryIDCard: true,
        DocOfPassportLusing: true,
        DiplomaticPassport: true,
        InternationalPassportNotRU: false,
        InternationalPassportRU: true,
        InternationalPassport: false,
        NationalPassport: false,
        SeamanDocument: true,
        SeniorDocument: true,
        BirthRegDocument: true,
        ResidencePermit: true
    },
    other: {
        Passport: false,
        MilitaryIDCard: true,
        DocOfPassportLusing: true,
        DiplomaticPassport: true,
        InternationalPassportNotRU: true,
        InternationalPassportRU: false,
        InternationalPassport: false,
        NationalPassport: true,
        SeamanDocument: true,
        SeniorDocument: false,
        BirthRegDocument: true,
        ResidencePermit: true
    },
    ruChildren: {
        BirthRegDocument: true,
        InternationalPassportRU: true
    }
};
var DocumentType = function (_a) {
    var e_1, _b;
    var field = _a.field, className = _a.className, error = _a.error, fieldState = _a.fieldState, onChange = _a.onChange, passengerId = _a.passengerId, passengerType = _a.passengerType, label = _a.label, disabled = _a.disabled, variant = _a.variant;
    var formApi = useForm();
    var formValues = useFormState().values;
    var selectedNationality;
    var options = [];
    var onChangeHandler = useCallback(function (value, event) {
        onChange && onChange(field.name, value);
        fieldState.input.onChange(event);
    }, [onChange, fieldState]);
    if (field.type === TravellerFieldEnum.DocType) {
        selectedNationality = formValues.passengers[passengerId].nationality;
        if (!selectedNationality) {
            selectedNationality = 'other';
        }
    }
    if (selectedNationality) {
        var _loop_1 = function (rule) {
            if (!rule.with ||
                rule.with.every(function (_a) {
                    var name = _a.name, value = _a.value;
                    return value === formValues.passengers[passengerId][name];
                })) {
                options = rule.options;
                if (selectedNationality === 'RU' &&
                    (passengerType === AviaPassengerType.CLD ||
                        passengerType === AviaPassengerType.INF ||
                        passengerType === AviaPassengerType.INS)) {
                    var documents_1 = allowedDocuments.ruChildren;
                    options = options.filter(function (option) { return documents_1.hasOwnProperty(option.value) && documents_1[option.value] === true; });
                }
                return "break";
            }
        };
        try {
            for (var _c = __values(field.validations), _d = _c.next(); !_d.done; _d = _c.next()) {
                var rule = _d.value;
                var state_1 = _loop_1(rule);
                if (state_1 === "break")
                    break;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    useEffect(function () {
        var _a;
        if (!fieldState.input.value) {
            return;
        }
        var existOption = options.some(function (option) { return option.value === fieldState.input.value; });
        if (!existOption) {
            formApi.change(field.name, (_a = options[0]) === null || _a === void 0 ? void 0 : _a.value);
        }
    }, [options, fieldState.input.value]);
    return (React.createElement(Select, { value: fieldState.input.value, options: options.map(function (option) { return ({
            key: option.value,
            value: option.label
        }); }), name: field.name, label: label, className: className, onChange: onChangeHandler, onBlur: fieldState.input.onBlur, error: error, translateValue: true, disabled: disabled, variant: variant }));
};
export default DocumentType;
