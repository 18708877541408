import { __assign, __rest } from "tslib";
import * as React from 'react';
import MUITooltip from '@material-ui/core/Tooltip';
import classnames from 'classnames';
import { useTheme } from '../theme';
var Tooltip = function (_a) {
    var _b;
    var title = _a.title, children = _a.children, titleClassName = _a.titleClassName, tooltipClassName = _a.tooltipClassName, popperClassName = _a.popperClassName, _c = _a.placement, placement = _c === void 0 ? 'top' : _c, restProps = __rest(_a, ["title", "children", "titleClassName", "tooltipClassName", "popperClassName", "placement"]);
    var css = useTheme('Tooltip').Tooltip;
    return (React.createElement(MUITooltip, __assign({ title: React.createElement("span", { className: classnames(css.title, titleClassName) }, title), placement: placement, PopperProps: {
            modifiers: {
                flip: {
                    enabled: false
                },
                preventOverflow: {
                    escapeWithReference: true
                }
            }
        }, classes: {
            tooltip: classnames(css.tooltip, tooltipClassName, (_b = {},
                _b[css.tooltip_left] = placement === 'left',
                _b[css.tooltip_bottom] = placement === 'bottom',
                _b)),
            popper: classnames(css.popper, popperClassName)
        } }, restProps), children));
};
export default Tooltip;
