import * as React from 'react';
import { CheckoutSteps } from '../../types';
import FlightsStep from './components/FlightsStep';
import SeatsStep from './components/SeatsStep';
import BaggageStep from './components/BaggageStep';
import MealStep from './components/MealStep';
import PassengersStep from './components/PassengersStep';
import ExtrasStep from './components/ExtrasStep';
var StepContent = function (_a) {
    var step = _a.step, order = _a.order, onChange = _a.onChange, passengers = _a.passengers, onSetOrder = _a.onSetOrder, passengerNationalities = _a.passengerNationalities;
    switch (step.type) {
        case CheckoutSteps.Flights:
            return React.createElement(FlightsStep, { onChange: onChange, order: order, passengers: passengers });
        case CheckoutSteps.Seats:
            return React.createElement(SeatsStep, { passengers: passengers, onChange: onChange, order: order });
        case CheckoutSteps.Baggage:
            return React.createElement(BaggageStep, { passengers: passengers, onChange: onChange, order: order });
        case CheckoutSteps.Meal:
            return React.createElement(MealStep, { passengers: passengers, onChange: onChange, order: order });
        case CheckoutSteps.Passengers:
            return (React.createElement(PassengersStep, { passengers: passengers, onChange: onChange, order: order, nationalities: passengerNationalities }));
        case CheckoutSteps.Extras:
            return React.createElement(ExtrasStep, { onSetOrder: onSetOrder, order: order });
        default:
            return null;
    }
};
export default StepContent;
