import * as React from 'react';
import cn from 'classnames';
import { SeatComfort } from '../../../../__generated__/globalTypes';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import Amenities from '../../../../Amenities';
import { useTranslation } from 'react-i18next';
import { WarningRounded } from '../../../../Icons';
import { useConfig } from '../../../../context';
var SeatInfo = function (_a) {
    var _b;
    var _c;
    var seatService = _a.seatService, hideImage = _a.hideImage, fullInfo = _a.fullInfo, amenities = _a.amenities, seat = _a.seat, preselectedSeat = _a.preselectedSeat;
    var theme = useTheme('SeatMap').SeatsInfoStyles;
    var t = useTranslation('SeatMap').t;
    var iataCode = useConfig().global.companyInfo.iataCode;
    var isTerminal = iataCode === 'TERMINAL';
    var renderFeatures = function () {
        return (seatService.parameters &&
            !!seatService.parameters.length && (React.createElement("div", { className: theme.features }, seatService.parameters.map(function (param, index) {
            return (React.createElement("div", { className: theme.feature, key: index },
                param.title,
                ": ",
                param.value));
        }))));
    };
    return (React.createElement("div", { key: seatService.id, className: cn(theme.seat, (_b = {},
            _b[theme.seat_economy] = seatService.comfort === SeatComfort.Standart,
            _b[theme.seat_preferred] = seatService.comfort === SeatComfort.Preferred,
            _b[theme.seat_business] = seatService.comfort === SeatComfort.Comfort,
            _b)) },
        seatService.serviceImage && !hideImage && (React.createElement("img", { className: theme.image, src: seatService.serviceImage, alt: seatService.name })),
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.title },
                (preselectedSeat === null || preselectedSeat === void 0 ? void 0 : preselectedSeat.number) && React.createElement("span", null, preselectedSeat.number),
                seatService.name,
                React.createElement("div", { className: theme.description }, fullInfo ? renderFeatures() : seatService.description))),
        React.createElement("div", { className: theme.seat__info },
            fullInfo ? React.createElement(Amenities, { amenities: amenities }) : renderFeatures(),
            React.createElement("div", { className: theme.price__wrapper }, seatService.price.amount <= 0 && isTerminal ? (React.createElement("span", { className: theme.price }, t('Free'))) : (React.createElement(React.Fragment, null,
                t('from'),
                React.createElement(Money, { moneyClassName: theme.price, money: seatService.price }))))),
        seat && ((_c = seat.restrictions) === null || _c === void 0 ? void 0 : _c.length) > 0 && (React.createElement("div", { className: theme.note },
            React.createElement("div", { className: theme.note__header },
                WarningRounded,
                " ",
                t('Note')),
            React.createElement("div", null, t('This seat is forbidden for')),
            React.createElement("ul", null, seat.restrictions.map(function (restriction, key) { return (React.createElement("li", { key: key }, t("Restriction_".concat(restriction)))); }))))));
};
export default SeatInfo;
