import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import Contacts from './Contacts/Contacts';
import Passengers from './Passengers/Passengers';
import PassengersToolbar from './PassengersToolbar/PassengersToolbar';
import { DocTypes, TravellerFieldEnum } from '../../../../__generated__/globalTypes';
import { format, parseISO } from 'date-fns';
import { PassengerType } from '../../../../enums';
import scrollToFirstErrorDecorator from './scrollToFirstErrorDecorator';
import { TravellerFields } from '../../../../TravellerForm/types';
import { OverrideComponent } from '../../../../renderProps';
export var Form = memo(function (_a) {
    var currentStep = _a.currentStep, passengers = _a.passengers, customer = _a.customer, onFormSubmit = _a.onFormSubmit, isInternationalFlight = _a.isInternationalFlight, _b = _a.showForm, showForm = _b === void 0 ? true : _b, _c = _a.showContacts, showContacts = _c === void 0 ? true : _c, _d = _a.showServices, showServices = _d === void 0 ? true : _d, _e = _a.showToolbar, showToolbar = _e === void 0 ? true : _e, userInfo = _a.userInfo, refetchUser = _a.refetchUser, _f = _a.inModal, inModal = _f === void 0 ? false : _f;
    var formRef = useRef();
    var _g = __read(useState(passengers), 2), passengerFields = _g[0], setPassengerFields = _g[1];
    var initialValues = {
        customer: [
            {
                email: customer.values.find(function (_a) {
                    var type = _a.type;
                    return type === TravellerFieldEnum.Email;
                }).value,
                phone: customer.values.find(function (_a) {
                    var type = _a.type;
                    return type === TravellerFieldEnum.Phone;
                }).value
            }
        ],
        passengers: passengerFields.map(function (passenger) {
            var values = {
                type: passenger.type
            };
            passenger.values.forEach(function (value) {
                if (value.type === TravellerFieldEnum.Nationality &&
                    !value.validationRules[0].options.find(function (option) { return option.value === value.value; })) {
                    value.value = value.validationRules[0].options[0].value;
                }
                else {
                    values[value.name] = (value === null || value === void 0 ? void 0 : value.value) || null;
                }
            });
            return values;
        })
    };
    var onFill = useCallback(function (passenger, id, fieldValue) {
        var newArray = __spreadArray([], __read(passengerFields), false);
        var values = new Map();
        if (!passenger) {
            return;
        }
        passenger.values.forEach(function (field) {
            values.set(field.name, field.type === TravellerFieldEnum.Gender && field.value ? field.value[0] : field.value);
        });
        var docType;
        if (!fieldValue) {
            if (isInternationalFlight) {
                if (values.get(TravellerFields.Nationality) === 'RU') {
                    docType = DocTypes.InternationalPassportRU;
                }
                else {
                    docType = DocTypes.NationalPassport;
                }
            }
            else {
                if (passengers[id].type === PassengerType.Child) {
                    docType = DocTypes.BirthRegDocument;
                }
                else {
                    if (values.get(TravellerFields.Nationality) === 'RU') {
                        docType = DocTypes.Passport;
                    }
                    else {
                        docType = DocTypes.NationalPassport;
                    }
                }
            }
        }
        else {
            docType = fieldValue;
        }
        var passengerDoc = passenger.documents.find(function (document) { return document.type === docType; });
        if (passengerDoc) {
            passengerDoc.values.forEach(function (doc) {
                var _a, _b;
                if (doc.value) {
                    values.set(doc.name, doc.type === TravellerFieldEnum.DocExpiryDate
                        ? ((_b = (_a = doc.validationRules) === null || _a === void 0 ? void 0 : _a.find(function (rule) { var _a; return ((_a = rule.with[0]) === null || _a === void 0 ? void 0 : _a.value) === passengerDoc.type; })) === null || _b === void 0 ? void 0 : _b.required)
                            ? format(parseISO(doc.value), 'dd.MM.yyyy')
                            : ''
                        : doc.value);
                }
                else {
                    values.set(doc.name, '');
                }
            });
        }
        newArray[id] = __assign(__assign({}, passengerFields[id]), { values: passengerFields[id].values.map(function (field) {
                if ([TravellerFieldEnum.DocExpiryDate, TravellerFieldEnum.DocNumber].includes(field.type)) {
                    return __assign(__assign({}, field), { value: values.get(field.name) });
                }
                return values.get(field.name) ? __assign(__assign({}, field), { value: values.get(field.name) }) : field;
            }), passengerId: passenger.id });
        setPassengerFields(newArray);
    }, [passengerFields]);
    var clearPassengerValuesMutator = function (args, state, utils) {
        var passengerId = String(args[0]);
        var passenger = passengers.find(function (passenger) { return passenger.id === passengerId; });
        passenger.values.forEach(function (value) {
            utils.changeValue(state, "passengers[".concat(passengerId, "].").concat(value.name), function () { return undefined; });
        });
    };
    var setFieldTouchedMutator = function (args, state) {
        var _a = __read(args, 1), fieldPath = _a[0];
        state.fields[fieldPath].touched = true;
    };
    return (React.createElement(FinalForm, { onSubmit: onFormSubmit, decorators: [scrollToFirstErrorDecorator], validateOnBlur: true, initialValues: initialValues, keepDirtyOnReinitialize: true, subscription: { submitErrors: true }, mutators: { clearPassengerValuesMutator: clearPassengerValuesMutator, setFieldTouchedMutator: setFieldTouchedMutator } }, function (_a) {
        var handleSubmit = _a.handleSubmit;
        return (React.createElement("form", { ref: formRef, onSubmit: handleSubmit },
            showContacts && (React.createElement(OverrideComponent, { componentProps: {
                    userInfo: userInfo,
                    refetchUser: refetchUser,
                    submitForm: handleSubmit,
                    customer: customer,
                    passengersCount: passengers.length
                }, component: {
                    CheckoutContactsForm: Contacts
                } })),
            showForm && (React.createElement(Passengers, { isAuthorized: !!(userInfo === null || userInfo === void 0 ? void 0 : userInfo.userProfile), onFill: onFill, fields: passengerFields, showServices: showServices })),
            showToolbar && React.createElement(PassengersToolbar, { formRef: formRef, currentStep: currentStep })));
    }));
});
