import * as React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import Inner from './Inner';
import { useGraphQLClient } from '../../graphql-client';
import { GuardedApolloProvider } from '../../GuardedApolloProvider';
import Analytics from './Analytics';
import { OverrideComponent } from '../../renderProps';
var Engine = function (_a) {
    var before = _a.before, after = _a.after;
    var client = useGraphQLClient();
    return (React.createElement(Router, null,
        React.createElement(Analytics, null),
        React.createElement(GuardedApolloProvider, { client: client },
            before,
            React.createElement(OverrideComponent, { componentProps: null, component: { Inner: Inner } }),
            after)));
};
export default Engine;
