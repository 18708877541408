import { __read } from "tslib";
import * as React from 'react';
import RequestFormComponent from '../RequestForm/RequestForm';
import { connect, useSelector, batch } from 'react-redux';
import { getOrder } from '../../../store/order/selectors';
import { clearFare } from '../../../store/newFlights/actions';
import { setLastExchange } from '../../../store/order/actions';
import { getFlightIdsToExchange } from '../../../../../Modules/Exchange/store/flightsToExchange/selectors';
import { removeFlightIds } from '../../../../../Modules/Exchange/store/flightsToExchange/actions';
import { useMutation } from '@apollo/react-hooks';
import * as RefuseQuery from '../../../../../__queries__/RefuseExchange.graphql';
import { OverrideComponent } from '../../../../../renderProps';
var RequestForm = function (props) {
    var setLastExchange = props.setLastExchange, goToNewRequest = props.goToNewRequest, goToPayment = props.goToPayment, clearFare = props.clearFare, removeFlightIds = props.removeFlightIds;
    var order = useSelector(getOrder);
    var flightIdsToExchange = useSelector(getFlightIdsToExchange);
    var _a = __read(useMutation(RefuseQuery.RefuseExchange), 1), deleteMutation = _a[0];
    var deleteRequest = function (order) {
        return deleteMutation({
            variables: {
                id: order.id
            }
        }).then(function (data) {
            var _a;
            if ((_a = data.data) === null || _a === void 0 ? void 0 : _a.RefuseExare) {
                batch(function () {
                    clearFare();
                    removeFlightIds(flightIdsToExchange);
                    setLastExchange(data.data.RefuseExare);
                });
                goToNewRequest();
                return true;
            }
            return false;
        });
    };
    return (React.createElement(OverrideComponent, { componentProps: {
            order: order.exareInfo.lastExchange,
            deleteRequest: deleteRequest,
            goToNewExchange: goToNewRequest,
            goToPayment: goToPayment,
            checkoutOrder: order
        }, component: {
            ExchangeRequestForm: RequestFormComponent
        } }));
};
export default connect(null, {
    setLastExchange: setLastExchange,
    clearFare: clearFare,
    removeFlightIds: removeFlightIds
})(RequestForm);
