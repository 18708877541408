import * as React from 'react';
export var LockIcon = (React.createElement("svg", { width: "76", height: "76", viewBox: "0 0 76 76", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0)" },
        React.createElement("circle", { cx: "37.9999", cy: "38.5", r: "38", transform: "rotate(-45 37.9999 38.5)", fill: "#D6E9FF" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.4167 34.3334V27.9167C33.4167 25.3854 35.4687 23.3334 38 23.3334C40.5313 23.3334 42.5833 25.3854 42.5833 27.9167V34.3334H33.4167ZM38 19.6667C42.5563 19.6667 46.25 23.3604 46.25 27.9167V34.3334H51.75V56.3334H24.25V34.3334H29.75V27.9167C29.75 23.3604 33.4437 19.6667 38 19.6667Z", fill: "#3290FF" }),
        React.createElement("path", { d: "M39.5854 47.536C39.5854 47.0962 39.4665 46.7475 39.2288 46.49C38.9962 46.2271 38.5982 45.9857 38.0348 45.7658C37.4714 45.5459 36.9856 45.3286 36.5772 45.114C36.1689 44.8941 35.8175 44.6447 35.5228 44.3657C35.2334 44.0814 35.006 43.7488 34.8406 43.368C34.6804 42.9871 34.6002 42.5338 34.6002 42.0081C34.6002 41.1016 34.8793 40.3586 35.4376 39.7793C35.9958 39.1999 36.7375 38.862 37.6627 38.7654V37.0435H38.9031V38.7895C39.818 38.9236 40.5338 39.3206 41.0507 39.9804C41.5676 40.6349 41.826 41.4851 41.826 42.5311H39.5854C39.5854 41.8874 39.4562 41.4073 39.1977 41.0908C38.9445 40.769 38.6034 40.608 38.1744 40.608C37.7505 40.608 37.4223 40.7341 37.1897 40.9862C36.9571 41.233 36.8408 41.5763 36.8408 42.0162C36.8408 42.4239 36.9546 42.7511 37.182 42.9978C37.4094 43.2446 37.8306 43.4967 38.4457 43.7542C39.0659 44.0117 39.5751 44.2558 39.973 44.4864C40.371 44.7117 40.707 44.9692 40.9809 45.2589C41.2549 45.5432 41.4642 45.8704 41.6089 46.2406C41.7536 46.6053 41.826 47.0318 41.826 47.5199C41.826 48.4319 41.5521 49.1721 41.0042 49.7408C40.4563 50.3094 39.7017 50.6446 38.7403 50.7466V52.3478H37.5076V50.7546C36.448 50.6366 35.6262 50.2477 35.0422 49.5879C34.4633 48.9227 34.1738 48.0403 34.1738 46.9406H36.4144C36.4144 47.5789 36.5592 48.0698 36.8486 48.4131C37.1432 48.751 37.5645 48.92 38.1123 48.92C38.5672 48.92 38.9264 48.7966 39.19 48.5499C39.4536 48.2978 39.5854 47.9598 39.5854 47.536Z", fill: "#D6E9FF" }),
        React.createElement("circle", { opacity: "0.2", cx: "37.9999", cy: "38.5", r: "38", transform: "rotate(-45 37.9999 38.5)", fill: "url(#paint0_linear)" })),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear", x1: "37.9999", y1: "38.5", x2: "37.9999", y2: "76.5", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#256CBF", stopOpacity: "0" }),
            React.createElement("stop", { offset: "0.0001", stopColor: "#256CBF" }),
            React.createElement("stop", { offset: "1", stopColor: "#256CBF", stopOpacity: "0" })),
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("path", { d: "M0 38C0 17.0132 17.0132 0 38 0C58.9868 0 76 17.0132 76 38C76 58.9868 58.9868 76 38 76C17.0132 76 0 58.9868 0 38Z", fill: "white" })))));
