var _a;
import * as React from 'react';
import { TravellerFieldEnum } from '../../../../__generated__/globalTypes';
import MediaQuery from 'react-responsive';
import { getPassengerName, TABLET_MIDDLE_WIDTH } from '../../../../utils';
import DesktopPassenger from './DesktopPassenger/DesktopPassenger';
import MobilePassenger from './MobilePassenger/MobilePassenger';
import { useTranslation } from 'react-i18next';
import { AviaPassengerType } from '../../../../__generated__/graphql';
var passengersType = (_a = {},
    _a[AviaPassengerType.ADT] = 'Adult',
    _a[AviaPassengerType.CLD] = 'Child',
    _a[AviaPassengerType.INF] = 'Infant',
    _a[AviaPassengerType.INS] = 'Infant with seat',
    _a);
var Passenger = function (props) {
    var _a, _b, _c, _d, _e;
    var t = useTranslation('SeatMap').t;
    var passengerNameObject = React.useMemo(function () {
        var _a, _b, _c, _d, _e, _f;
        var firstName = (_a = props.passenger.values.find(function (value) { return value.type === TravellerFieldEnum.FirstName; })) === null || _a === void 0 ? void 0 : _a.value, lastName = (_b = props.passenger.values.find(function (value) { return value.type === TravellerFieldEnum.LastName; })) === null || _b === void 0 ? void 0 : _b.value;
        var linkedFirstName = (_d = (_c = props.linkedPassenger) === null || _c === void 0 ? void 0 : _c.values.find(function (_a) {
            var type = _a.type;
            return type === TravellerFieldEnum.FirstName;
        })) === null || _d === void 0 ? void 0 : _d.value;
        var linkedLastName = (_f = (_e = props.linkedPassenger) === null || _e === void 0 ? void 0 : _e.values.find(function (_a) {
            var type = _a.type;
            return type === TravellerFieldEnum.LastName;
        })) === null || _f === void 0 ? void 0 : _f.value;
        if (!firstName) {
            return null;
        }
        return {
            firstName: firstName,
            lastName: lastName,
            linkedFirstName: linkedFirstName,
            linkedLastName: linkedLastName
        };
    }, [props.passenger]);
    var avatarText = React.useMemo(function () {
        if (passengerNameObject) {
            return "".concat(passengerNameObject.firstName.charAt(0).toUpperCase()).concat(passengerNameObject.lastName
                .charAt(0)
                .toUpperCase());
        }
        return (parseInt(props.passenger.id) + 1).toString();
    }, [passengerNameObject]);
    var label = React.useMemo(function () {
        if (passengerNameObject) {
            var linkedTravellerName = passengerNameObject.linkedFirstName
                ? ", ".concat(passengerNameObject.linkedFirstName, " ").concat(passengerNameObject.linkedLastName)
                : '';
            return "".concat(getPassengerName(passengerNameObject.firstName, passengerNameObject.lastName), " ").concat(linkedTravellerName);
        }
        return t(passengersType[props.passenger.type]);
    }, [passengerNameObject]);
    var onClickHandler = function () {
        props.onClick(parseInt(props.passenger.id));
    };
    var onClearHandler = function () {
        props.onClear(parseInt(props.passenger.id));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: TABLET_MIDDLE_WIDTH },
            React.createElement(DesktopPassenger, { passengerName: label, passengerAvatarText: avatarText, seatNumber: (_a = props === null || props === void 0 ? void 0 : props.seat) === null || _a === void 0 ? void 0 : _a.number, price: (_b = props === null || props === void 0 ? void 0 : props.seat) === null || _b === void 0 ? void 0 : _b.price, onClick: onClickHandler, isActive: props.isActive, onClearSeat: onClearHandler, recommend: props.recommend, confirmed: (_c = props === null || props === void 0 ? void 0 : props.seat) === null || _c === void 0 ? void 0 : _c.isConfirmed })),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
            React.createElement(MobilePassenger, { passengerName: label, seatNumber: (_d = props === null || props === void 0 ? void 0 : props.seat) === null || _d === void 0 ? void 0 : _d.number, onClick: onClickHandler, clearEnable: props.clearEnable, onClear: onClearHandler, confirmed: (_e = props === null || props === void 0 ? void 0 : props.seat) === null || _e === void 0 ? void 0 : _e.isConfirmed }))));
};
export default Passenger;
