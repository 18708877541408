import * as React from 'react';
import { useTheme } from '../theme';
import { infoIcon } from './Icons';
import MediaQuery from '../MediaQuery/MediaQuery';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { initI18n } from '../utils';
initI18n('Warnings');
var Warnings = function (_a) {
    var warnings = _a.warnings, children = _a.children, className = _a.className;
    var css = useTheme('Warnings').WarningsStyles;
    var t = useTranslation('Warnings').t;
    return (React.createElement("div", { className: cn(css.wrapper, className) }, children ? (React.createElement("div", { className: css.warning },
        React.createElement("div", { className: css.warning__icon },
            infoIcon,
            React.createElement(MediaQuery, { maxWidth: 'mobile' },
                React.createElement("span", { className: css.icon__text }, t('Clarification')))),
        React.createElement("div", { className: css.warning__text }, children))) : (warnings.map(function (warning, index) { return (React.createElement("div", { key: index, className: css.warning },
        React.createElement("div", { className: css.warning__icon },
            infoIcon,
            React.createElement(MediaQuery, { maxWidth: 'mobile' },
                React.createElement("span", { className: css.icon__text }, t('Clarification')))),
        React.createElement("div", { className: css.warning__text }, warning))); }))));
};
export default Warnings;
