import { __read } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import { useTheme } from '../../../theme';
import { vouchersList } from '../../mocked';
import { Voucher } from '../Voucher/Voucher';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../utils';
import LoyaltyStub from '../LoyaltyStub/LoyaltyStub';
import MediaQuery from 'react-responsive';
import Tabs from '../../../Tabs/Tabs';
import { useState } from 'react';
import LoyaltyInfo from './LoyaltyInfo/LoyaltyInfo';
import MilesHistory from '../../../MilesHistory';
import * as AccountQueries from '../../../__queries__/Account.graphql';
import { useMutation } from '@apollo/react-hooks';
import { parse } from 'date-fns';
initI18n('Loyalty');
var Loyalty = function (props) {
    var _a;
    var t = useTranslation('Loyalty').t, _b = __read(useState(0), 2), selectedTab = _b[0], setSelectedTab = _b[1];
    var css = useTheme('Loyalty').Loyalty;
    var _c = useQuery(AccountQueries.FfpAccountHistory, { skip: !props.ffpInfo }), ffpAccountHistory = _c.data, refetch = _c.refetch;
    var getUserName = React.useMemo(function () {
        var userInfo = props.userInfo;
        if (userInfo.firstName && userInfo.lastName) {
            return "".concat(userInfo.firstName, " ").concat(userInfo.lastName);
        }
        return null;
    }, [props.userInfo]);
    var onLoyaltyCardSaved = function () {
        props.onLoyaltyCardSaved();
        refetch();
    };
    var _d = __read(useMutation(AccountQueries.FfpAccountLogout), 2), FfpAccountLogoutMutation = _d[0], logoutResponse = _d[1].data;
    useEffect(function () {
        var _a, _b;
        if ((_a = logoutResponse === null || logoutResponse === void 0 ? void 0 : logoutResponse.FfpAccountLogout) === null || _a === void 0 ? void 0 : _a.result) {
            (_b = props.onLoyaltyCardSaved) === null || _b === void 0 ? void 0 : _b.call(props);
        }
    }, [logoutResponse]);
    var milesHistory = (_a = ffpAccountHistory === null || ffpAccountHistory === void 0 ? void 0 : ffpAccountHistory.FfpAccountHistory) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) {
        var firstDate = parse(a.onTimeUTC, 'HH:mm dd.MM.yyyy', new Date()), secondDate = parse(b.onTimeUTC, 'HH:mm dd.MM.yyyy', new Date());
        return secondDate.getTime() - firstDate.getTime();
    });
    return (React.createElement("div", null,
        props.ffpInfo && !props.isAuthorizationNeeded ? (React.createElement(React.Fragment, null,
            React.createElement(LoyaltyInfo, { ffpInfo: props.ffpInfo, loyaltyName: props.loyaltyName, userName: getUserName, links: props.links, sureModalCallback: FfpAccountLogoutMutation }),
            (ffpAccountHistory === null || ffpAccountHistory === void 0 ? void 0 : ffpAccountHistory.FfpAccountHistory) && (React.createElement(MilesHistory, { className: css.milesHistory, records: milesHistory })))) : (React.createElement(LoyaltyStub, { stub: 'card', onLoyaltyCardSaved: onLoyaltyCardSaved, cardName: props.loyaltyName, links: props.links, userInfo: props.userInfo })),
        props.vouchers && (React.createElement("div", { className: cn(css.card, css.promo) },
            React.createElement("h1", { className: css.promo__title }, t('Promo-code')),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement(Tabs, { tabs: [
                        { header: t('Active'), value: 0 },
                        { header: t('Expired'), value: 1 }
                    ], onChange: setSelectedTab, selected: selectedTab }),
                selectedTab === 0 && (React.createElement("div", { className: css.vouchers }, vouchersList
                    .filter(function (voucher) { return voucher.expires > new Date(); })
                    .map(function (voucher) { return (React.createElement(Voucher, { voucher: voucher, key: vouchersList.indexOf(voucher) })); }))),
                selectedTab === 1 && (React.createElement("div", { className: css.vouchers }, vouchersList
                    .filter(function (voucher) { return voucher.expires <= new Date(); })
                    .map(function (voucher) { return (React.createElement(Voucher, { voucher: voucher, key: vouchersList.indexOf(voucher) })); })))),
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { className: css.vouchers },
                    vouchersList.map(function (voucher) { return (React.createElement(Voucher, { voucher: voucher, key: vouchersList.indexOf(voucher) })); }),
                    vouchersList.length % 3 === 0 ? null : React.createElement("div", { style: { width: 233, height: 1 } })))))));
};
export default Loyalty;
