import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import Gateway from '../Gateway';
import { useTheme } from '../../../../theme';
import { PaymentType } from '../../../types';
import Button from './Button/Button';
var googlePayIcon = require('../../../images/google-pay.svg');
var allowedCardNetworks = ['MASTERCARD', 'VISA'];
var allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];
var baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: allowedCardNetworks
    }
};
var googlePayBaseConfiguration = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [baseCardPaymentMethod]
};
var tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
        gateway: 'example',
        gatewayMerchantId: 'gatewayMerchantId'
    }
};
var cardPaymentMethod = {
    type: 'CARD',
    tokenizationSpecification: tokenizationSpecification,
    parameters: {
        allowedCardNetworks: allowedCardNetworks,
        allowedAuthMethods: allowedCardAuthMethods,
        billingAddressRequired: true,
        billingAddressParameters: {
            format: 'FULL',
            phoneNumberRequired: true
        }
    }
};
var merchantInfo = {
    merchantId: '01234567890123456789',
    merchantName: 'Example Merchant Name'
};
var GooglePay = memo(function (_a) {
    var price = _a.price, onClick = _a.onClick, onSuccess = _a.onSuccess, onFail = _a.onFail, isPreOpened = _a.isPreOpened;
    var css = useTheme('PaymentForm').GooglePay;
    var t = useTranslation('PaymentForm').t;
    var _b = __read(useState(null), 2), button = _b[0], setButton = _b[1];
    var _c = __read(useState(null), 2), googlePayClient = _c[0], setGooglePayClient = _c[1];
    var handleOnClick = useCallback(function () {
        onClick({
            type: PaymentType.Gpay,
            title: 'Google Pay',
            description: t('In the next step you will be taken to a Google Pay page.'),
            content: React.createElement(Button, { button: button })
        });
    }, [onClick, button]);
    // For some strange reason, Helment+script onLoad requires global function name.
    window['onGooglePayLoaded'] = function () {
        var googlePayClient = new google.payments.api.PaymentsClient({
            environment: 'TEST'
        });
        setGooglePayClient(googlePayClient);
    };
    var onPaymentClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var transactionInfo, paymentDataRequest, paymentData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    transactionInfo = {
                        totalPriceStatus: 'FINAL',
                        totalPrice: price.amount.toString(10),
                        currencyCode: price.currency
                    };
                    paymentDataRequest = __assign(__assign({}, googlePayBaseConfiguration), { allowedPaymentMethods: [cardPaymentMethod], transactionInfo: transactionInfo, merchantInfo: merchantInfo });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, googlePayClient.loadPaymentData(paymentDataRequest)];
                case 2:
                    paymentData = _a.sent();
                    if (onSuccess) {
                        onSuccess();
                    }
                    console.log(paymentData);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    console.error(err_1);
                    if (onFail) {
                        onFail();
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var checkIfGooglePayAvailable = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, button_1, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, googlePayClient.isReadyToPay(googlePayBaseConfiguration)];
                case 1:
                    response = _a.sent();
                    if (response.result) {
                        button_1 = googlePayClient.createButton({
                            buttonColor: 'default',
                            buttonType: 'long',
                            onClick: onPaymentClick
                        });
                        setButton(button_1);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    console.error('Error determining readiness to use Google Pay: ', err_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (googlePayClient) {
            checkIfGooglePayAvailable();
        }
    }, [googlePayClient]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, { async: true },
            React.createElement("script", { src: "https://pay.google.com/gp/p/js/pay.js", onLoad: 'onGooglePayLoaded()' })),
        button && (React.createElement(Gateway, { onClick: handleOnClick, header: React.createElement("img", { src: googlePayIcon, className: css.logo, alt: "Google Pay" }), type: "Google Pay" }))));
});
export default GooglePay;
