import { __assign, __read } from "tslib";
import classnames from 'classnames';
import * as React from 'react';
import { Datepicker } from './Datepicker';
import Dropdown from '../../Dropdown/Dropdown';
import { useTheme } from '../../theme';
export var PopupDatepicker = function (props) {
    var _a = __read(React.useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var css = useTheme('Datepicker').PopupDatepickerStyles;
    var selectDate = function (date) {
        if (props.onSelect) {
            props.onSelect(date, props.isRTMode && props.selectedDates.length === 1);
        }
        if (!props.isRTMode || props.selectedDates.length === 1) {
            setOpen(false);
        }
    };
    var open = function () {
        setOpen(true);
    };
    var close = function () {
        setOpen(false);
    };
    var inputRenderer = function () {
        var _a;
        return (React.createElement("input", { className: classnames(props.inputClassName, (_a = {},
                _a[props.inputFocusedClassName] = isOpen,
                _a)), type: "text", value: props.inputValue, onFocus: open, onChange: function () { }, autoComplete: "off", spellCheck: false, placeholder: props.inputPlaceholder }));
    };
    return (React.createElement("div", { className: classnames(css.popupWrapper, css.popupWrapper_focused, props.className) },
        React.createElement("div", { className: css.input__wrapper },
            props.inputRenderer
                ? props.inputRenderer({
                    onFocus: open,
                    isOpen: isOpen
                })
                : inputRenderer(),
            props.valueRenderer && props.valueRenderer(isOpen)),
        React.createElement(Dropdown, { onClose: close, isOpen: isOpen },
            React.createElement("div", { className: props.popupWrapperClassName },
                props.header && props.header,
                React.createElement(Datepicker, __assign({}, props, { children: null, onSelect: selectDate })),
                props.footer && props.footer))));
};
