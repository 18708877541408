import { __assign } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { getRouteType } from '../../../utils';
import { OrderStatus } from '../../../../__generated__/globalTypes';
import FlightSegment from './FlightSegment';
import { RouteType } from '../../../../QuickSearchForm/types';
import LoyaltyBonus from '../../../../LoyaltyBonus/components/LoyaltyBonus';
var FlightsStep = function (_a) {
    var _b;
    var order = _a.order, onChange = _a.onChange, passengers = _a.passengers;
    var css = useTheme('MobileStepbar').StepContentStyles;
    var t = useTranslation('MobileStepbar').t;
    var isRoundTrip = getRouteType(order.segments) === RouteType.RoundTrip;
    var segments = [];
    order.segmentGroups.forEach(function (segmentGroup, groupIndex) {
        segmentGroup.segments.forEach(function (segment) {
            var _a, _b, _c;
            segments.push({
                travellersCount: passengers.length,
                fareFamilyTitle: (_b = (_a = segmentGroup.fareFamily) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : t('DesktopSummaryFlight:Charter'),
                isReturningSegment: isRoundTrip && groupIndex === order.segmentGroups.length - 1,
                price: segmentGroup.price,
                segment: {
                    segment: segment,
                    transferDuration: (_c = order.segments.find(function (flightSegment) { return flightSegment.segment.id === segment.id; })) === null || _c === void 0 ? void 0 : _c.transferDuration
                }
            });
        });
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.wrapper },
            React.createElement("div", { className: css.content }, segments.map(function (segment, index) { return (React.createElement(FlightSegment, __assign({ key: index }, segment))); })),
            order.status !== OrderStatus.Confirmed && (React.createElement("div", { className: css.footer },
                React.createElement("span", { onClick: onChange }, t('Change'))))),
        ((_b = order.ffpInfo) === null || _b === void 0 ? void 0 : _b.bonusForFlight) && (React.createElement(LoyaltyBonus, { miles: order.ffpInfo.bonusForFlight, className: css.flightBonus }))));
};
export default FlightsStep;
