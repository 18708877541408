import * as React from 'react';
import cn from 'classnames';
import { FareFeaturePaymentType } from '../../../__generated__/globalTypes';
import { checkIcon, dollarIcon, noIcon } from '../../Icons';
import { useTheme } from '../../../theme';
var Cell = function (_a) {
    var _b, _c;
    var cell = _a.cell, isFullFareCondition = _a.isFullFareCondition;
    var css = useTheme('CompareFares').CompareFares;
    if (cell === null) {
        return (React.createElement("div", { className: cn(css.cell, (_b = {},
                _b[css.cell_notFullFareConditions] = !isFullFareCondition,
                _b)) },
            React.createElement("div", { className: css.cellContent }, '')));
    }
    var description = cell.description, value = cell.value, availability = cell.availability;
    return (React.createElement("div", { className: cn(css.cell, (_c = {},
            _c[css.cell_notFullFareConditions] = !isFullFareCondition,
            _c)) },
        isFullFareCondition && (React.createElement("div", { className: css.col },
            React.createElement("div", { className: cn(css.cellContent, css.cellContent_fullDescr) }, description))),
        !isFullFareCondition && (React.createElement("div", { className: css.cellContent },
            value && React.createElement("span", null, value),
            React.createElement("div", { className: css.availabilityIcon },
                !value && availability === FareFeaturePaymentType.Free && React.createElement(React.Fragment, null, checkIcon),
                !value && availability === FareFeaturePaymentType.Pay && React.createElement(React.Fragment, null, dollarIcon),
                !value && availability === FareFeaturePaymentType.NotAvailable && React.createElement(React.Fragment, null, noIcon))))));
};
export default Cell;
