import { useTheme } from '../../../../../theme';
import { useMemo } from 'react';
export var useDatepickerStyles = function () {
    var _a = useTheme('Schedule'), dayStyles = _a.Day, calendarStyles = _a.Calendar;
    var datepickerTheme = useMemo(function () { return ({
        Datepicker: {
            MonthsWrapperStyles: {
                nav_next: calendarStyles.nav_next,
                nav_prev: calendarStyles.nav_prev,
                wrapper: calendarStyles.wrapper
            },
            MonthHeaderStyles: {
                monthHeader: calendarStyles.monthName
            },
            WeeklyHeaderStyles: {
                day: calendarStyles.dayOfWeekName,
                dayOfWeekName: calendarStyles.dayOfWeekName
            },
            DayStyles: {
                day: dayStyles.day,
                day_fromOtherMonth: dayStyles.disabled,
                day_highlighted: dayStyles.day_bg_fill
            }
        }
    }); }, []);
    return datepickerTheme;
};
