import React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import Money from '../../../../Money';
import { BaggageType } from '../../../../__generated__/globalTypes';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { isActualBaggage } from '../../utils';
var BaggageSegment = function (_a) {
    var _b;
    var _c, _d;
    var segment = _a.segment, baggageByRfics = _a.baggageByRfics, mobileSelectedServiceId = _a.mobileSelectedServiceId, baggageTab = _a.baggageTab;
    var from = (_c = segment.departure.airport.city) === null || _c === void 0 ? void 0 : _c.name;
    var to = (_d = segment.arrival.airport.city) === null || _d === void 0 ? void 0 : _d.name;
    var theme = useTheme('UpgradeBaggageDialog').BaggageSegment;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var baggagesWithOtherPrices = React.useMemo(function () {
        if (baggageByRfics.length) {
            var baggageWithOtherPrice = baggageByRfics.find(function (baggages) {
                return baggages.some(function (baggage) { return baggage === null || baggage === void 0 ? void 0 : baggage.price.amount; });
            });
            return !!baggageWithOtherPrice;
        }
        return !!baggageTab;
    }, [baggageByRfics]);
    var mobileSelectedBaggage = React.useMemo(function () {
        var _a;
        if (mobileSelectedServiceId) {
            // ищем багаж с максимальным id
            var actualBaggage_1 = null;
            (_a = baggageByRfics
                .find(function (baggages) { return baggages.find(function (bagggage) { return bagggage.rfics === mobileSelectedServiceId; }); })) === null || _a === void 0 ? void 0 : _a.forEach(function (bag) {
                var isTargetSegment = bag.segmentsId.some(function (segments) {
                    return segments.some(function (segmentId) { return segmentId === segment.id; });
                });
                if (isTargetSegment && isActualBaggage(bag, actualBaggage_1)) {
                    actualBaggage_1 = bag;
                }
            });
            return actualBaggage_1;
        }
        return null;
    }, [mobileSelectedServiceId, segment]);
    return (React.createElement("div", { className: cn(theme.root) },
        React.createElement("div", { className: cn(theme.name, (_b = {},
                _b[theme.name_withPrices] = baggagesWithOtherPrices,
                _b)) },
            from,
            " \u2014 ",
            to),
        (baggagesWithOtherPrices || isMobile) && (React.createElement("div", { className: theme.prices },
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, baggageByRfics.map(function (baggagesWithRfisc, index) {
                // ищем багаж с максимальным id
                var baggage = null;
                baggagesWithRfisc.forEach(function (bag) {
                    var isTargetSegment = bag.segmentsId.some(function (segments) {
                        return segments.some(function (segmentId) { return segmentId === segment.id; });
                    });
                    if (isTargetSegment && isActualBaggage(bag, baggage)) {
                        baggage = bag;
                    }
                });
                if (!baggage) {
                    return React.createElement("div", { key: index, className: theme.price__empty });
                }
                var hidePrice = [BaggageType.BaggageExcess].includes(baggage.type);
                return (React.createElement("div", { key: baggage.id, className: theme.price }, !hidePrice && React.createElement(Money, { money: baggage.price })));
            })),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { className: theme.price },
                    React.createElement(Money, { money: mobileSelectedBaggage === null || mobileSelectedBaggage === void 0 ? void 0 : mobileSelectedBaggage.price })))))));
};
export default BaggageSegment;
