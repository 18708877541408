import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { CartIcon } from '../../Icons';
var StepTotal = function (_a) {
    var _b;
    var step = _a.step, _c = _a.isActive, isActive = _c === void 0 ? false : _c, _d = _a.isPassed, isPassed = _d === void 0 ? false : _d, styles = _a.styles;
    var _e = useTheme('Stepbar'), stepCss = _e.Step, css = _e.StepTotal;
    return (React.createElement("div", { style: styles, className: cn(stepCss.step, css.root, (_b = {},
            _b[stepCss.step_active] = isActive,
            _b[stepCss.step_passed] = isPassed,
            _b[stepCss.step_clickable] = isPassed && !!step.onClick,
            _b[css.active] = isActive,
            _b)), onClick: isPassed && step.onClick ? step.onClick : null },
        React.createElement("span", { className: cn(stepCss.label, css.label) }, step.label),
        React.createElement("span", { className: css.icon }, CartIcon)));
};
export default StepTotal;
