import { Gender } from './../../../__generated__/globalTypes';
import { AviaPassengerType, DocTypes } from '../../../__generated__/globalTypes';
var DIGITS = /\d/g;
var DIGITS_AT_START = /^\d{6}/;
var DIGITS_ALL_OVER_STRING = /^\d+$/;
var PLUS = /\+/;
var LATIN_REGEX = /[a-z]+/;
export var getLocalization = function (str) { return (LATIN_REGEX.test(str.toLowerCase()) ? 'eng' : 'rus'); };
export var defaultYear = new Date().getFullYear();
export var months = [
    { rus: 'янв', eng: 'jan' },
    { rus: 'фев', eng: 'feb' },
    { rus: 'мар', eng: 'mar' },
    { rus: 'апр', eng: 'apr' },
    { rus: 'май', eng: 'may' },
    { rus: 'июн', eng: 'jun' },
    { rus: 'июл', eng: 'jul' },
    { rus: 'ауг', eng: 'aug' },
    { rus: 'сен', eng: 'sep' },
    { rus: 'окт', eng: 'oct' },
    { rus: 'ноя', eng: 'nov' },
    { rus: 'дек', eng: 'dec' }
];
export var isMonthInMMMFormat = function (str) {
    var strWithoutDigits = str.replace(DIGITS, '').toLowerCase();
    return months.some(function (month) { return month.rus === strWithoutDigits || month.eng === strWithoutDigits; });
};
export var getEngMonth = function (str) {
    var month = months.find(function (_a) {
        var rus = _a.rus;
        return rus === str;
    });
    return month ? month.eng : '';
};
export var getMonthFromMM = function (idx) {
    var month = months[idx - 1];
    return month ? month.eng : '';
};
export var sirenaPassengerTypes = {
    ADT: AviaPassengerType.ADT,
    ААА: AviaPassengerType.ADT,
    CHD: AviaPassengerType.CLD,
    РБГ: AviaPassengerType.CLD,
    INF: AviaPassengerType.INF,
    РМГ: AviaPassengerType.INF,
    INS: AviaPassengerType.INS
};
export var amadeusPassengerTypes = {
    PAX: AviaPassengerType.ADT,
    RCH: AviaPassengerType.CLD,
    RYTH: 'YTH',
    INF: AviaPassengerType.INF,
    INS: AviaPassengerType.INS
};
export var isDigits = function (str) {
    return DIGITS_ALL_OVER_STRING.test(str);
};
export var isStartFromDigits = function (str) {
    return DIGITS_AT_START.test(str);
};
export var isConsistPlusChar = function (str) {
    return PLUS.test(str);
};
export var docTypes = {
    ПС: DocTypes.Passport,
    PS: DocTypes.Passport,
    P: DocTypes.Passport,
    ПСП: DocTypes.InternationalPassportRU,
    PSP: DocTypes.InternationalPassportRU,
    ВБ: DocTypes.MilitaryIDCard,
    VB: DocTypes.MilitaryIDCard,
    ВУЛ: DocTypes.CertificateOfConvictedVacation,
    VUL: DocTypes.CertificateOfConvictedVacation,
    ВЖ: DocTypes.ResidencePermit,
    VV: DocTypes.ResidencePermit,
    СР: DocTypes.BirthRegDocument,
    SR: DocTypes.BirthRegDocument,
    СПУ: DocTypes.DocOfPassportLusing,
    SPU: DocTypes.DocOfPassportLusing,
    НП: DocTypes.NationalPassport,
    NP: DocTypes.NationalPassport
};
export var genderTypes = {
    M: Gender.Male,
    М: Gender.Male,
    F: Gender.Female,
    Ж: Gender.Female,
    O: Gender.Other,
    MI: Gender.Male,
    МИ: Gender.Male,
    FI: Gender.Female,
    ЖИ: Gender.Female
};
