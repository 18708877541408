import { __assign } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Field } from 'react-final-form';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import { currency } from '../../../__fakeData__';
import { NumberField } from '../../Fields/Fields';
var RefundNewFareCalculation = function () {
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement("label", { className: theme.formControl },
                React.createElement("input", { type: "text", className: cn(theme.formControl__control, theme.formControl__control_light), value: 'BQS N4 MOW 100  N4 SVX ZZ SIP 200 NUC 300.00 END IROE 0.90', readOnly: true }),
                React.createElement("div", { className: theme.formControl__label }, t('Fare calculation for refunded ticket')))),
        React.createElement("div", { className: theme.exchangeNewFareCalculation },
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement(NumberField, { name: 'usedFare' }),
                    React.createElement("div", { className: theme.formControl__label },
                        t('Used Fare'),
                        " (",
                        currency,
                        ")"))),
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement(NumberField, { name: 'calculatedFareForRefund' }),
                    React.createElement("div", { className: theme.formControl__label }, t('Calculated fare for refund')))),
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement(NumberField, { name: 'cancelationPenalty' }),
                    React.createElement("div", { className: theme.formControl__label },
                        t('Cancelation penalty'),
                        " (",
                        currency,
                        ")"))),
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement(NumberField, { name: 'specialAirlinesSum' }),
                    React.createElement("div", { className: theme.formControl__label },
                        t('Special airlines sum'),
                        " (",
                        currency,
                        ")"))),
            React.createElement("div", { className: cn(theme.formControl__flexLine, theme.flexFullWidth, theme.agencyCommissionLine) },
                React.createElement("div", null,
                    React.createElement("label", { className: theme.formControl },
                        React.createElement(NumberField, { name: 'agencyCommission.value' }),
                        React.createElement("div", { className: theme.formControl__label },
                            t('Agency commission'),
                            " (",
                            currency,
                            ")"))),
                React.createElement("div", null,
                    React.createElement("label", { className: theme.formControl },
                        React.createElement(Field, { name: 'agencyCommission.type', render: function (_a) {
                                var input = _a.input;
                                return (React.createElement(FormControl, { className: theme.formSelect_line, classes: {} },
                                    React.createElement(Select, __assign({}, input, { disableUnderline: true, classes: {
                                            root: theme.formSelect
                                        } }),
                                        React.createElement(MenuItem, { key: '%', value: '%' }, "%"),
                                        React.createElement(MenuItem, { key: 'value', value: 'value' }, t('Value')))));
                            } })))))));
};
export default RefundNewFareCalculation;
