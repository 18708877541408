import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { PromoCodeStatus } from '../types';
import Link from '../../Link';
import Modal from '../../Modal';
import PromoCodeForm from '../Form/PromoCodeForm';
import { useTheme } from '../../theme';
import * as icons from '../../Icons/';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import { Button, SlideBottom } from '../../index';
import MediaQuery from 'react-responsive';
import { useConfig } from '../../context';
initI18n('PromoCode');
/**
 * you can get all needed data from ../hooks.ts ( except onSubmit handler )
 */
var PromoCode = function (props) {
    var _a;
    var t = useTranslation('PromoCode').t;
    var theme = useTheme('PromoCode').PromoCode;
    var allowPromoCodes = useConfig().global.allowPromoCodes;
    var SHOW_MODAL_IF_STATUS = [PromoCodeStatus.Empty, PromoCodeStatus.NotApplied];
    var SHOW_CLEAR_IF_STATUS = [PromoCodeStatus.Applied, PromoCodeStatus.Provided];
    var clearTrigger = props.onClear && (React.createElement("span", { className: theme.clearWrapper },
        React.createElement(Link, { action: props.onClear, className: theme.clear },
            t('reset'),
            " ",
            React.createElement("span", { className: theme.clearIcon }, icons.CloseClear))));
    return allowPromoCodes ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(theme.promoCode, props.className, (_a = {},
                _a[theme.promoCode_applied] = props.status === PromoCodeStatus.Applied,
                _a[theme.promoCode_notApplied] = props.status === PromoCodeStatus.NotApplied,
                _a[theme.promoCode_empty] = props.status === PromoCodeStatus.Empty,
                _a)) },
            React.createElement("div", { className: theme.contentWrp },
                SHOW_MODAL_IF_STATUS.includes(props.status) && (React.createElement("span", { className: theme.content },
                    props.status === PromoCodeStatus.Empty && (React.createElement(Link, { className: theme.trigger, action: props.onOpenForm }, t('Do you have a promo code?'))),
                    props.status === PromoCodeStatus.NotApplied && (React.createElement(Link, { className: theme.trigger, action: props.onOpenForm },
                        React.createElement("span", { className: theme.icon_notApplied }, icons.Warning),
                        t('Promo code not applied'))))),
                SHOW_CLEAR_IF_STATUS.includes(props.status) && (React.createElement("span", { className: theme.content },
                    props.status === PromoCodeStatus.Provided && props.promoCode && (React.createElement("span", null,
                        t('Promo code'),
                        ": ",
                        props.promoCode)),
                    props.status === PromoCodeStatus.Applied && props.promoCode && (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: theme.icon }, icons.Included),
                        t('Promo code applied'))),
                    props.renderAfter ? props.renderAfter(props) : clearTrigger)),
                props.renderAfter && props.renderAfter(props))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: props.isFormOpen, onClose: props.onCloseForm, isRounded: true, maxWidth: 'xs', classes: { paper: theme.modal } },
                React.createElement(PromoCodeForm, { isLoading: props.isLoading, status: props.status, onSubmit: props.onAddPromoCode }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: props.isFormOpen, onClose: props.onCloseForm },
                React.createElement(Button, { onClick: props.onCloseForm, className: theme.closeButton }, icons.CloseClear),
                React.createElement(PromoCodeForm, { isLoading: props.isLoading, status: props.status, onSubmit: props.onAddPromoCode }))))) : null;
};
export default PromoCode;
