import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import Button from '../../../Button';
import { Included } from '../../../Icons';
import { Money } from '../../../Money';
var InsuranceCard = function (_a) {
    var _b;
    var className = _a.className, isSelected = _a.isSelected, name = _a.name, price = _a.price, options = _a.options, onClick = _a.onClick;
    var css = useTheme('InsurancePopup').InsuranceCard;
    var t = useTranslation('Checkout').t;
    return (React.createElement("div", { className: cn(className, css.wrapper, (_b = {},
            _b[css.active] = isSelected,
            _b)) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.header_info },
                React.createElement("span", { className: css.header_rate }, name),
                React.createElement("span", { className: css.header_description }, t('price for all persons'))),
            React.createElement("span", { className: css.header_price },
                React.createElement(Money, { money: price }),
                isSelected && Included)),
        React.createElement("div", { className: css.content },
            React.createElement("ul", null, options.map(function (_a, index) {
                var name = _a.name;
                return (React.createElement("li", { key: index }, name));
            })),
            isSelected ? (React.createElement(Button, { className: css.button_reject, onClick: onClick }, t('Reject'))) : (React.createElement(Button, { className: css.button, onClick: onClick }, t('Select'))))));
};
export default InsuranceCard;
