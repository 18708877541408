import * as React from 'react';
import MediaQuery from 'react-responsive';
import { useTheme } from '../../../../theme';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { getCurrency } from '../../../../cache';
import { useTranslation } from 'react-i18next';
var timeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM11 7H12.5V12.25L17 14.92L16.25 16.15L11 13V7Z", fill: "currentColor" })));
var priceIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM12.9635 13.4069C13.1499 13.6088 13.2432 13.8822 13.2432 14.2271C13.2432 14.5594 13.1398 14.8244 12.9331 15.0221C12.7264 15.2156 12.4448 15.3123 12.0881 15.3123C11.6586 15.3123 11.3283 15.1798 11.0973 14.9148C10.8703 14.6456 10.7568 14.2608 10.7568 13.7603H9C9 14.6225 9.22695 15.3144 9.68085 15.836C10.1388 16.3533 10.7832 16.6583 11.614 16.7508V18H12.5805V16.7445C13.3343 16.6646 13.926 16.4017 14.3556 15.9558C14.7852 15.51 15 14.9295 15 14.2145C15 13.8318 14.9433 13.4974 14.8298 13.2114C14.7163 12.9211 14.5522 12.6646 14.3374 12.4416C14.1226 12.2145 13.8592 12.0126 13.5471 11.836C13.2351 11.6551 12.8359 11.4637 12.3495 11.2618C11.8673 11.0599 11.537 10.8623 11.3587 10.6688C11.1803 10.4753 11.0912 10.2187 11.0912 9.89905C11.0912 9.55415 11.1824 9.28496 11.3647 9.09148C11.5471 8.8938 11.8045 8.79495 12.1368 8.79495C12.4732 8.79495 12.7406 8.92114 12.9392 9.1735C13.1418 9.42166 13.2432 9.79811 13.2432 10.3028H15C15 9.48265 14.7974 8.81598 14.3921 8.30284C13.9868 7.78549 13.4255 7.47424 12.7082 7.36909V6H11.7356V7.35016C11.0101 7.42587 10.4286 7.69085 9.99088 8.14511C9.55319 8.59937 9.33435 9.18191 9.33435 9.89274C9.33435 10.3049 9.39716 10.6604 9.5228 10.959C9.65248 11.2576 9.8308 11.5184 10.0578 11.7413C10.2888 11.96 10.5643 12.1556 10.8845 12.3281C11.2047 12.4963 11.5856 12.6667 12.0274 12.8391C12.4691 13.0116 12.7812 13.2008 12.9635 13.4069Z", fill: "currentColor" })));
var Disclaimer = function (_a) {
    var type = _a.type;
    var css = useTheme('Results').Disclaimer;
    var t = useTranslation().t;
    return (React.createElement("p", { className: css.wrapper },
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            type === 'time' && timeIcon,
            type === 'price' && priceIcon),
        type === 'time' && t('Local time', { currency: getCurrency() }),
        type === 'price' && t('The specified cost is for all passengers and includes taxes and fees')));
};
export default Disclaimer;
