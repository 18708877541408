import { __assign } from "tslib";
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import Input from '../Input';
import PhoneInput from '../PhoneInput';
import { Button } from '../index';
import { initI18n } from '../utils';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { useCustomerContactsValidation } from './hooks';
initI18n('CustomerContactsForm');
var CustomerContactsForm = function (props) {
    var t = useTranslation('CustomerContactsForm').t;
    var theme = useTheme('CustomerContactsForm').CustomerContactsForm;
    var _a = useCustomerContactsValidation(), testEmail = _a.testEmail, testPhone = _a.testPhone;
    return (React.createElement(Form, { onSubmit: props.onContactsSubmit }, function (_a) {
        var handleSubmit = _a.handleSubmit, values = _a.values, hasValidationErrors = _a.hasValidationErrors;
        return (React.createElement("form", { onSubmit: handleSubmit, className: theme.form },
            React.createElement(Field, { name: "email", type: "email", validate: testEmail }, function (field) { return (React.createElement(Input, __assign({ inputClassName: theme.email__input, className: theme.email }, field, { label: t('Email'), absoluteHintPosition: true, TextFieldProps: __assign(__assign({}, field.input), { onChange: function (e) { return field.input.onChange(e); }, error: field.meta.touched && field.meta.error, helperText: field.meta.touched && field.meta.error }) }))); }),
            React.createElement(Field, { name: "phone", validate: testPhone }, function (field) { return (React.createElement(React.Fragment, null,
                React.createElement(PhoneInput, __assign({}, field, { className: theme.phone__input, textFieldsProps: __assign(__assign({}, field.input), { onChange: function (e) { return field.input.onChange(e); }, error: field.meta.touched && field.meta.error, label: t('Phone'), helperText: field.meta.touched && field.meta.error }) })))); }),
            React.createElement(Button, { disabled: hasValidationErrors, isLoading: props.isLoading, className: theme.button, type: 'submit' }, t('Done'))));
    }));
};
export default CustomerContactsForm;
