import * as Form from './components/LoginPage/Form/Form.css';
import * as Verification from './components/LoginPage/Verification/Verification.css';
import * as Welcome from './components/LoginPage/Welcome/Welcome.css';
import * as Header from './components/LoginPage/Header/Header.css';
import * as Alternative from './components/LoginPage/Alternative/Alternative.css';
import * as Register from './components/LoginPage/Register/Register.css';
export default {
    Verification: Verification,
    Form: Form,
    Welcome: Welcome,
    Header: Header,
    Alternative: Alternative,
    Register: Register
};
