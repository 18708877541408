import { Measurement } from '../BaggageService/types';
import { BaggageType, FareFamilyOptionsType, FareFeaturePaymentType } from '../__generated__/globalTypes';
export var parseIncludedBaggage = function (fareFamiliesMap, passengers) {
    var includedBaggage = [];
    fareFamiliesMap.forEach(function (_a) {
        var segmentIds = _a.segmentIds, fareFamily = _a.fareFamily;
        fareFamily.options
            .filter(function (option) {
            return (option.type === FareFamilyOptionsType.CarryOn || option.type === FareFamilyOptionsType.Baggage) &&
                option.availability === FareFeaturePaymentType.Free;
        })
            .forEach(function (option) {
            var splittedValue = option.value.split('x'), value = splittedValue.length === 2 ? splittedValue[1] : option.value;
            includedBaggage.push({
                id: null,
                segmentsId: [segmentIds.map(function (segmentId) { return segmentId; })],
                includedSegments: segmentIds.map(function (segmentId) { return segmentId; }),
                type: option.type === 'CarryOn' ? BaggageType.CarryOn : BaggageType.CheckedBaggage,
                name: option.title,
                included: parseInt(splittedValue[0]),
                price: null,
                value: {
                    amount: parseInt(value),
                    measurement: Measurement.Kilograms
                },
                size: option.size,
                allowedPassengers: passengers,
                canBeAdded: false,
                rfics: ''
            });
        });
    });
    return includedBaggage;
};
export var getPriceToPay = function (selectedServices, services) {
    var priceToPay = {
        amount: 0,
        currency: null
    };
    var isAdded = false;
    services.forEach(function (service) {
        var addedService = selectedServices.filter(function (selected) { return selected.serviceId === service.id; });
        addedService.forEach(function (addedService) {
            isAdded = true;
            priceToPay.amount += service.price.amount * addedService.count * addedService.segmentIds.length;
            priceToPay.currency = service.price.currency;
        });
    });
    return isAdded ? priceToPay : null;
};
export var getMinPrice = function (services) {
    var minPrice = {
        amount: null,
        currency: null
    };
    services.forEach(function (service) {
        var _a;
        var minPriceForService = service.price.amount * ((_a = service.segmentsId) === null || _a === void 0 ? void 0 : _a[0].length);
        if (!minPrice.amount || minPriceForService < minPrice.amount) {
            minPrice.amount = minPriceForService;
            minPrice.currency = service.price.currency;
        }
    });
    return minPrice;
};
