import { __assign } from "tslib";
import { ADD_SERVICE, SET_SELECTED_SERVICES, REMOVE_SERVICE, CLEAR_SERVICE, EQUALIZE_PASSENGER_BAGGAGE, REPLACE_SERVICE } from './actions';
import { getRelatedSegments } from '../../utils';
export var addServiceReducer = function (state, _a) {
    var _b, _c;
    var _d;
    var passengerId = _a.passengerId, service = _a.service, segmentId = _a.segmentId;
    // List of passengers for which given service is ALLOWED to be added.
    var allowedPassengers = new Set(service.allowedPassengers.map(function (group) { return group[0]; }));
    // List of segments for which given service MUST be added.
    // Sometimes service should be added to a multiple segments at the same time.
    // This happens when service is tightly bound to a whole trip itself (to a "leg" in terms of Nemo),
    // rather than to a particular segment.
    var segmentsToAdd = getRelatedSegments(service.allowedSegments, segmentId);
    if (allowedPassengers.has(passengerId) && segmentsToAdd.size > 0) {
        // Deep copy of the state object.
        var result_1 = __assign(__assign({}, state), (_b = {}, _b[passengerId] = __assign(__assign({}, state[passengerId]), (_c = {}, _c[service.id] = __assign({}, (_d = state[passengerId]) === null || _d === void 0 ? void 0 : _d[service.id]), _c)), _b));
        segmentsToAdd.forEach(function (segmentId) {
            var _a;
            var serviceCount = (_a = result_1[passengerId][service.id][segmentId]) !== null && _a !== void 0 ? _a : 0;
            result_1[passengerId][service.id][segmentId] = serviceCount + 1;
        });
        return result_1;
    }
    return state;
};
export var removeServiceReducer = function (state, _a) {
    var _b, _c;
    var _d, _e, _f;
    var passengerId = _a.passengerId, service = _a.service, segmentId = _a.segmentId;
    // Check if we have any selected services.
    var couldBeDecreased = ((_e = (_d = state[passengerId]) === null || _d === void 0 ? void 0 : _d[service.id]) === null || _e === void 0 ? void 0 : _e[segmentId]) > 0;
    if (couldBeDecreased) {
        // List of segments for which given service MUST be decreased.
        // Sometimes service should be decreased for multiple segments at the same time.
        // This happens when service is tightly bound to a whole trip itself (to a "leg" in terms of Nemo),
        // rather than to a particular segment.
        var segmentsToDecrease = getRelatedSegments(service.allowedSegments, segmentId);
        if (segmentsToDecrease.size > 0) {
            // Deep copy of the state object.
            var result_2 = __assign(__assign({}, state), (_b = {}, _b[passengerId] = __assign(__assign({}, state[passengerId]), (_c = {}, _c[service.id] = __assign({}, (_f = state[passengerId]) === null || _f === void 0 ? void 0 : _f[service.id]), _c)), _b));
            segmentsToDecrease.forEach(function (segmentId) {
                var _a, _b;
                var serviceCount = (_b = (_a = result_2[passengerId]) === null || _a === void 0 ? void 0 : _a[service.id]) === null || _b === void 0 ? void 0 : _b[segmentId];
                if (serviceCount > 0) {
                    result_2[passengerId][service.id][segmentId] = serviceCount - 1;
                }
            });
            return result_2;
        }
    }
    return state;
};
export var setSelectedServicesReducer = function (passengers) {
    var result = {};
    passengers.forEach(function (passenger) {
        var _a, _b;
        var services = (_b = (_a = passenger === null || passenger === void 0 ? void 0 : passenger.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services;
        var passengerId = passenger.id;
        if (services) {
            services.forEach(function (_a) {
                var segmentIds = _a.segmentIds, serviceId = _a.serviceId, count = _a.count;
                if (!result.hasOwnProperty(passengerId)) {
                    result[passengerId] = {};
                }
                if (!result[passengerId].hasOwnProperty(serviceId)) {
                    result[passengerId][serviceId] = {};
                }
                segmentIds.forEach(function (segmentId) {
                    if (!result[passengerId][serviceId].hasOwnProperty(segmentId)) {
                        result[passengerId][serviceId][segmentId] = 0;
                    }
                    result[passengerId][serviceId][segmentId] += count;
                });
            });
        }
    });
    return result;
};
export var clearServiceReducer = function (state, _a) {
    var _b, _c;
    var _d, _e, _f;
    var passengerId = _a.passengerId, service = _a.service, segmentId = _a.segmentId;
    // Check if we have any selected services.
    var couldBecleared = ((_e = (_d = state[passengerId]) === null || _d === void 0 ? void 0 : _d[service.id]) === null || _e === void 0 ? void 0 : _e[segmentId]) > 0;
    if (couldBecleared) {
        // List of segments for which given service MUST be decreased.
        // Sometimes service should be decreased for multiple segments at the same time.
        // This happens when service is tightly bound to a whole trip itself (to a "leg" in terms of Nemo),
        // rather than to a particular segment.
        var segmentsToClear = getRelatedSegments(service.allowedSegments, segmentId);
        if (segmentsToClear.size > 0) {
            // Deep copy of the state object.
            var result_3 = __assign(__assign({}, state), (_b = {}, _b[passengerId] = __assign(__assign({}, state[passengerId]), (_c = {}, _c[service.id] = __assign({}, (_f = state[passengerId]) === null || _f === void 0 ? void 0 : _f[service.id]), _c)), _b));
            segmentsToClear.forEach(function (segmentId) {
                result_3[passengerId][service.id][segmentId] = 0;
            });
            return result_3;
        }
    }
    return state;
};
export var equalizePassengerBaggageReducer = function (state, _a) {
    var _b;
    var passengerId = _a.passengerId, baggageList = _a.baggageList, segmentsList = _a.segmentsList;
    if (state.hasOwnProperty(passengerId)) {
        var result_4 = __assign(__assign({}, state), (_b = {}, _b[passengerId] = __assign({}, state[passengerId]), _b));
        var passengerServices = result_4[passengerId];
        var _loop_1 = function (serviceId) {
            if (passengerServices.hasOwnProperty(serviceId) && baggageList.has(serviceId)) {
                var servicesBySegments = passengerServices[serviceId];
                var maxServiceCount_1 = 0;
                // Find the max count of selected services.
                for (var segmentId in servicesBySegments) {
                    if (servicesBySegments.hasOwnProperty(segmentId) &&
                        maxServiceCount_1 < servicesBySegments[segmentId]) {
                        maxServiceCount_1 = servicesBySegments[segmentId];
                    }
                }
                // Set found max service count for all segments.
                segmentsList.forEach(function (segmentId) { return (result_4[passengerId][serviceId][segmentId] = maxServiceCount_1); });
            }
        };
        for (var serviceId in passengerServices) {
            _loop_1(serviceId);
        }
        return result_4;
    }
    return state;
};
export var replaceServiceReducer = function (state, _a) {
    var _b;
    var newService = _a.newService, oldService = _a.oldService, passengerId = _a.passengerId, segmentsList = _a.segmentsList;
    if (state.hasOwnProperty(passengerId)) {
        var hasOldServices_1 = false;
        var result_5 = __assign(__assign({}, state), (_b = {}, _b[passengerId] = __assign({}, state[passengerId]), _b));
        if (result_5[passengerId].hasOwnProperty(oldService.id)) {
            hasOldServices_1 = true;
        }
        if (!result_5[passengerId].hasOwnProperty(newService.id)) {
            result_5[passengerId][newService.id] = {};
        }
        segmentsList.forEach(function (segmentId) {
            // Remove old services.
            if (hasOldServices_1 && result_5[passengerId][oldService.id].hasOwnProperty(segmentId)) {
                result_5[passengerId][oldService.id][segmentId] = 0;
            }
            var segmentIsAllowed = getRelatedSegments(newService.allowedSegments, segmentId).size > 0;
            if (segmentIsAllowed) {
                // Add new services.
                result_5[passengerId][newService.id][segmentId] = 1;
            }
        });
        return result_5;
    }
    return state;
};
export var selectedServicesReducer = function (state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case ADD_SERVICE:
            return addServiceReducer(state, action.payload);
        case REMOVE_SERVICE:
            return removeServiceReducer(state, action.payload);
        case CLEAR_SERVICE:
            return clearServiceReducer(state, action.payload);
        case SET_SELECTED_SERVICES:
            return setSelectedServicesReducer(action.payload);
        case REPLACE_SERVICE:
            return replaceServiceReducer(state, action.payload);
        case EQUALIZE_PASSENGER_BAGGAGE:
            return equalizePassengerBaggageReducer(state, action.payload);
    }
    return state;
};
