import { __read, __spreadArray, __values } from "tslib";
import { API_DATE_FORMAT, format, setToMap } from '../../../utils';
import { addMonths, isAfter, parse, startOfMonth } from 'date-fns';
/**
 *
 * @param schedule FlightsScheduleDataFragment
 * @param flightDirectionIndex current leg index
 *
 * convert schedule response to map which hold flightIndex as key and dates as value
 */
export var legsByDatesMapFactory = function (schedule, flightDirectionIndex) {
    var e_1, _a, e_2, _b;
    var _c, _d;
    var legsByDatesMap = new Map();
    if (!schedule) {
        return legsByDatesMap;
    }
    var flightDirection = schedule.flightDirections[flightDirectionIndex];
    if (!((_c = flightDirection === null || flightDirection === void 0 ? void 0 : flightDirection.legs) === null || _c === void 0 ? void 0 : _c.length)) {
        return legsByDatesMap;
    }
    try {
        for (var _e = __values(flightDirection.legs.entries()), _f = _e.next(); !_f.done; _f = _e.next()) {
            var _g = __read(_f.value, 2), legIndex = _g[0], leg = _g[1];
            if (!(leg === null || leg === void 0 ? void 0 : leg.segments)) {
                continue;
            }
            var departureSegment = leg.segments[0];
            if (!(departureSegment === null || departureSegment === void 0 ? void 0 : departureSegment.segment) || !((_d = departureSegment.segment.datesData) === null || _d === void 0 ? void 0 : _d.length)) {
                continue;
            }
            try {
                for (var _h = (e_2 = void 0, __values(departureSegment.segment.datesData)), _j = _h.next(); !_j.done; _j = _h.next()) {
                    var date = _j.value;
                    setToMap(legsByDatesMap, function (oldArray, currentEntity) {
                        return __spreadArray([], __read(oldArray.concat(currentEntity)), false);
                    }, format(date.date, API_DATE_FORMAT), [legIndex]);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_j && !_j.done && (_b = _h.return)) _b.call(_h);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return legsByDatesMap;
};
// TODO: this is copypaste of legsByDatesMapFactory need more abstract solution ( maybe builder pattern or just composition? )
export var datesByFlightNumberFactory = function (schedule, flightDirectionIndex) {
    var e_3, _a, e_4, _b, e_5, _c;
    var _d, _e;
    var datesByFlightNumberMap = new Map();
    if (!schedule) {
        return datesByFlightNumberMap;
    }
    var flightDirection = schedule.flightDirections[flightDirectionIndex];
    if (!((_d = flightDirection === null || flightDirection === void 0 ? void 0 : flightDirection.legs) === null || _d === void 0 ? void 0 : _d.length)) {
        return datesByFlightNumberMap;
    }
    try {
        for (var _f = __values(flightDirection.legs.entries()), _g = _f.next(); !_g.done; _g = _f.next()) {
            var _h = __read(_g.value, 2), leg = _h[1];
            if (!(leg === null || leg === void 0 ? void 0 : leg.segments)) {
                continue;
            }
            try {
                for (var _j = (e_4 = void 0, __values(leg.segments)), _k = _j.next(); !_k.done; _k = _j.next()) {
                    var segment = _k.value;
                    if (!(segment === null || segment === void 0 ? void 0 : segment.segment) || !((_e = segment.segment.datesData) === null || _e === void 0 ? void 0 : _e.length)) {
                        continue;
                    }
                    try {
                        for (var _l = (e_5 = void 0, __values(segment.segment.datesData)), _m = _l.next(); !_m.done; _m = _l.next()) {
                            var date = _m.value;
                            if (segment.segment.operatingAirlineFlightNumber) {
                                setToMap(datesByFlightNumberMap, function (oldArray, currentEntity) {
                                    return __spreadArray([], __read(oldArray.concat(currentEntity)), false);
                                }, segment.segment.operatingAirlineFlightNumber, [format(date.date, API_DATE_FORMAT)]);
                            }
                            setToMap(datesByFlightNumberMap, function (oldArray, currentEntity) {
                                return __spreadArray([], __read(oldArray.concat(currentEntity)), false);
                            }, segment.segment.flightNumber, [format(date.date, API_DATE_FORMAT)]);
                        }
                    }
                    catch (e_5_1) { e_5 = { error: e_5_1 }; }
                    finally {
                        try {
                            if (_m && !_m.done && (_c = _l.return)) _c.call(_l);
                        }
                        finally { if (e_5) throw e_5.error; }
                    }
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_k && !_k.done && (_b = _j.return)) _b.call(_j);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_g && !_g.done && (_a = _f.return)) _a.call(_f);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return datesByFlightNumberMap;
};
export var legsByMonthFactory = function (schedule, flightDirectionIndex) {
    var e_6, _a;
    var _b, _c;
    var legsByMonths = new Map();
    if (!schedule) {
        return legsByMonths;
    }
    var flightDirection = schedule.flightDirections[flightDirectionIndex];
    if (!((_b = flightDirection === null || flightDirection === void 0 ? void 0 : flightDirection.legs) === null || _b === void 0 ? void 0 : _b.length)) {
        return legsByMonths;
    }
    try {
        for (var _d = __values(flightDirection.legs.entries()), _e = _d.next(); !_e.done; _e = _d.next()) {
            var _f = __read(_e.value, 2), leg = _f[1];
            if (!(leg === null || leg === void 0 ? void 0 : leg.segments)) {
                continue;
            }
            var segment = leg.segments[0];
            if (!(segment === null || segment === void 0 ? void 0 : segment.segment) || !((_c = segment.segment.datesData) === null || _c === void 0 ? void 0 : _c.length)) {
                continue;
            }
            var startDate = parse(segment.segment.scheduleStartDate, API_DATE_FORMAT, new Date());
            var endDate = parse(segment.segment.scheduleEndDate, API_DATE_FORMAT, new Date());
            var incrementedDate = startOfMonth(startDate);
            while (!isAfter(incrementedDate, endDate)) {
                setToMap(legsByMonths, function (oldEntity, currentEntity) {
                    return __spreadArray([], __read(oldEntity), false).concat(currentEntity);
                }, +incrementedDate, [leg]);
                incrementedDate = addMonths(incrementedDate, 1); // increment counter
            }
        }
    }
    catch (e_6_1) { e_6 = { error: e_6_1 }; }
    finally {
        try {
            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
        }
        finally { if (e_6) throw e_6.error; }
    }
    return legsByMonths; // timestamp - legs map
};
export var flightFilterFlightsConverter = function (flights) {
    function getSegments(flight) {
        var e_7, _a;
        var convSegments = [];
        try {
            for (var _b = __values(flight.segments), _c = _b.next(); !_c.done; _c = _b.next()) {
                var segment = _c.value;
                var convSegment = {
                    flightNumber: segment.segment.flightNumber,
                    operatingAirline: {
                        iata: segment.segment.operatingAirline.iata
                    },
                    duration: segment.segment.duration,
                    transferDuration: segment.transferDuration,
                    scheduleStartDate: segment.segment.scheduleStartDate,
                    scheduleEndDate: segment.segment.scheduleEndDate,
                    weekdays: segment.segment.weekdays
                };
                convSegments.push(convSegment);
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_7) throw e_7.error; }
        }
        return convSegments;
    }
    return flights.map(function (flight) {
        var convFlight = {
            segments: getSegments(flight),
            source: flight
        };
        return convFlight;
    });
};
