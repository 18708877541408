import * as Trigger from './components/Trigger/Trigger.css';
import * as RecognitionPopupContent from './components/RecognitionPopupContent/RecognitionPopupContent.css';
import * as Recognized from './components/Recognized/Recognized.css';
import * as ScanDocs from './components/ScanDocs/ScanDocs.css';
import * as TooltipContent from './components/TooltipContent/TooltipContent.css';
import * as NationalityWrapper from './components/Field/Components/NationalityWrapper/NationalityWrapper.css';
export default {
    Trigger: Trigger,
    RecognitionPopupContent: RecognitionPopupContent,
    Recognized: Recognized,
    ScanDocs: ScanDocs,
    TooltipContent: TooltipContent,
    NationalityWrapper: NationalityWrapper
};
