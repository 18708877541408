import * as ServicesList from './components/ServicesList/ServicesList.css';
import * as ServicePopup from './components/ServicePopup/ServicePopup.css';
import * as Header from './components/Header/Header.css';
import * as ServicesContainer from './components/ServicesContainer/ServicesContainer.css';
export default {
    ServicesList: ServicesList,
    ServicePopup: ServicePopup,
    Header: Header,
    ServicesContainer: ServicesContainer
};
