import { __read, __spreadArray } from "tslib";
import { BaggageType } from '../__generated__/globalTypes';
import memes from '../memes';
export var Mode;
(function (Mode) {
    Mode[Mode["Same"] = 0] = "Same";
    Mode[Mode["Different"] = 1] = "Different";
})(Mode || (Mode = {}));
export var MAX_COUNTER_VALUE_FREE = 5;
export var MAX_COUNTER_VALUE_BLOCKED = 1;
export var MIN_COUNTER_VALUE = 0;
export var SERVICES_TYPES_TO_BLOCK = [BaggageType.CheckedBaggage];
export var isSelectedBaggage = function (serviceId, services, selectedBaggage) {
    var filteredBaggagesIds = new Set();
    var selectedBaggages = new Set();
    services.forEach(function (baggage) {
        if (baggage.type === BaggageType.CheckedBaggage) {
            filteredBaggagesIds.add(baggage.id);
        }
    });
    for (var selectedBaggageKey in selectedBaggage) {
        if (filteredBaggagesIds.has(selectedBaggageKey)) {
            selectedBaggages.add(selectedBaggageKey);
        }
    }
    return (selectedBaggages.has(serviceId) && filteredBaggagesIds.has(serviceId)) || selectedBaggages.size < 1;
};
export var serviceIsAvailableOnAllSegments = function (service, segments) {
    // Quick check.
    if (service.segmentsId.length !== segments.length ||
        service.segmentsId.some(function (combination) { return combination.length > 1; })) {
        return false;
    }
    // Long check.
    var serviceSegments = new Set(service.segmentsId.map(function (combination) { return combination[0]; }));
    return segments.every(function (segmentId) { return serviceSegments.has(segmentId); });
};
export var getBaggageWeightGraduation = memes(function (baggageServices) {
    var baggageValues = new Set(baggageServices
        .filter(function (baggage) { return baggage.type === BaggageType.CheckedBaggage; })
        .map(function (baggage) { return baggage.value.amount; }));
    var min = Math.min.apply(Math, __spreadArray([], __read(baggageValues), false));
    var max = Math.max.apply(Math, __spreadArray([], __read(baggageValues), false));
    var average = Math.ceil(__spreadArray([], __read(baggageValues), false).reduce(function (a, b) { return a + b; }, 0) / baggageValues.size);
    return [min, average, max];
});
