import { __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import { useTheme } from '../../../theme';
import { Money as MoneyComponent } from '../../../Money';
import { ArrowIcon, ImageStubIcon } from './Icons';
import Counter from '../../../Counter/Counter';
import { getPassengerAbbreviation } from '../../utils';
import { ActionButton } from '../../../Button';
import { useIsTerminal } from '../../../hooks';
var Details = function (_a) {
    var _b, _c, _d;
    var _e;
    var marker = _a.marker, meal = _a.meal, oldPrice = _a.oldPrice, counters = _a.counters, onDecrease = _a.onDecrease, onIncrease = _a.onIncrease, onClear = _a.onClear, onSubmit = _a.onSubmit, readonly = _a.readonly, maxAvailable = _a.maxAvailable, onAddForAll = _a.onAddForAll;
    var t = useTranslation('Meal').t;
    var css = useTheme('Meal').Details;
    var _f = __read(useState(false), 2), descriptionIsOpen = _f[0], setDescriotionIsOpen = _f[1];
    var _g = __read(useState(0), 2), antiBlinkingTotalPrice = _g[0], setAntiBlinkingTotalPrice = _g[1];
    var isTerminal = useIsTerminal();
    var totalPrice = { amount: 0, currency: '' };
    var clearIsUnavailable = readonly;
    var totalNumberOfMeals = 0;
    counters.forEach(function (counter) {
        var _a, _b;
        if (counter.minAvailable > 0) {
            clearIsUnavailable = true;
        }
        totalNumberOfMeals += counter.count;
        totalPrice.amount = totalPrice.amount + counter.count * ((_a = meal.price) === null || _a === void 0 ? void 0 : _a.amount);
        totalPrice.currency = (_b = meal.price) === null || _b === void 0 ? void 0 : _b.currency;
    });
    var handleAddMealForAllPassengers = useCallback(function () {
        if (!readonly) {
            onAddForAll(meal);
        }
    }, [meal]);
    var toggleDescription = function () { return setDescriotionIsOpen(function (isOpen) { return !isOpen; }); };
    useEffect(function () {
        if ((totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.amount) && totalPrice.amount !== antiBlinkingTotalPrice) {
            setAntiBlinkingTotalPrice(totalPrice.amount);
        }
    }, [totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.amount]);
    var isCounterNotReadonlyAndEmpty = totalNumberOfMeals === 0 && !readonly;
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.image, style: meal.imageURL ? { backgroundImage: "url(".concat(meal.imageURL, ")") } : {} },
            marker && React.createElement("div", { className: css.marker }, marker),
            !meal.imageURL && React.createElement("div", { className: css.imageStub }, ImageStubIcon)),
        React.createElement("div", { className: css.content },
            React.createElement("div", { className: css.row },
                React.createElement("span", { className: css.name }, meal.name),
                !!meal.weight && (React.createElement("span", { className: css.weight },
                    meal.weight,
                    " ",
                    t('g')))),
            React.createElement("div", { className: css.row },
                meal.description && (React.createElement("span", { className: css.trigger, onClick: toggleDescription },
                    t('Show description'),
                    React.createElement("span", { className: cn(css.trigger__icon, (_b = {},
                            _b[css.trigger__icon_rotated] = descriptionIsOpen,
                            _b)) }, ArrowIcon))),
                React.createElement("span", null,
                    !!(oldPrice === null || oldPrice === void 0 ? void 0 : oldPrice.amount) && React.createElement(MoneyComponent, { moneyClassName: css.oldPrice, money: oldPrice }),
                    !!meal.price.amount ? (React.createElement(MoneyComponent, { moneyClassName: css.price, money: meal.price })) : (React.createElement("span", { className: css.price }, t('Free'))))),
            React.createElement(Collapse, { in: descriptionIsOpen, mountOnEnter: true, unmountOnExit: true },
                React.createElement("div", { className: css.description },
                    React.createElement("div", { className: css.description__row }, meal.description),
                    !!((_e = meal.allergens) === null || _e === void 0 ? void 0 : _e.length) && (React.createElement("div", { className: css.description__row },
                        React.createElement("div", { className: css.description__header }, t('Allergens')),
                        React.createElement("div", null, meal.allergens.join(', '))))))),
        React.createElement("div", { className: css.selector },
            React.createElement("ul", { className: css.counters }, counters.map(function (_a) {
                var _b;
                var passenger = _a.passenger, count = _a.count, minAvailable = _a.minAvailable;
                return (React.createElement("li", { key: passenger.id, className: css.counter },
                    React.createElement("span", { className: css.passenger },
                        React.createElement("span", { className: css.passenger__index }, passenger.isIdentified
                            ? getPassengerAbbreviation(passenger.name)
                            : parseInt(passenger.id) + 1),
                        React.createElement("span", null, passenger.name)),
                    React.createElement(Counter, { value: count, showOnlyIncreaseButtonWhenValueIsNull: true, minValue: minAvailable, maxValue: ((_b = meal.price) === null || _b === void 0 ? void 0 : _b.amount) === 0 ? 1 : maxAvailable, onDecrease: function () { return onDecrease(meal, passenger); }, onIncrease: function () { return onIncrease(meal, passenger); }, readonly: readonly })));
            })),
            React.createElement("div", { className: cn(css.footer, (_c = {}, _c[css.footer_empty] = isCounterNotReadonlyAndEmpty && isTerminal, _c)) },
                isCounterNotReadonlyAndEmpty && counters.length > 1 && (React.createElement("span", { className: css.addForAll, onClick: handleAddMealForAllPassengers }, t('Add for all'))),
                totalNumberOfMeals > 0 && (React.createElement("div", { className: css.footer__price },
                    antiBlinkingTotalPrice ? (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: css.totalText },
                            t('Total'),
                            "\u00A0"),
                        React.createElement(MoneyComponent, { moneyClassName: css.total, money: {
                                amount: antiBlinkingTotalPrice,
                                currency: totalPrice.currency
                            } }))) : (React.createElement("span", { className: css.total }, t('Free'))),
                    !clearIsUnavailable && (React.createElement("div", { className: css.clear },
                        React.createElement("span", { onClick: function () { return onClear(meal); } }, t('Clear')))))),
                React.createElement(ActionButton, { onClick: onSubmit, size: "small", className: cn((_d = {}, _d[css.button_empty] = isCounterNotReadonlyAndEmpty && !isTerminal, _d)) }, t('Done'))))));
};
export default Details;
