import { __assign } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import FareGroup from '../FareGroup';
import { useTheme } from '../../../theme';
import { icons, payment } from '../../icons';
import { useOptionIconModifier } from './hooks';
import { LuggageExcessIcon } from './icons';
import Link from '../../../Link';
import * as Icons from '../../../Icons';
import Tooltip from '../../../Tooltip';
import { FareFeaturePaymentType } from '../../../__generated__/graphql';
import { getUniqueValidOptions } from '../../../Results/utils';
import { OverrideComponent } from '../../../renderProps';
var FareGroupGrid = function (props) {
    var _a;
    var _b, _c;
    var css = useTheme('FareGroup').FareGroupGrid;
    var fare = props.fare, upgradeOption = props.upgradeOption;
    var t = useTranslation('FareGroup').t;
    var options = getUniqueValidOptions(fare.options, false, true, (_b = props.showUnavailableOptions) !== null && _b !== void 0 ? _b : false).filter(function (option) { return option.isKeyOption; });
    var notKeyOptions = getUniqueValidOptions(fare.options, false, false, (_c = props.showUnavailableOptions) !== null && _c !== void 0 ? _c : false).filter(function (option) { return !option.isKeyOption; });
    var smallAfterFirst = notKeyOptions.length > 4;
    var smallAfterFirst4 = options.length > 6 && options.length <= 7;
    var smallOptions = options.length > 7;
    var renderOption = useCallback(function (option, index) {
        var _a;
        var _b, _c;
        var isSpecialLuggageOverweightOption = useOptionIconModifier(option).isSpecialLuggageOverweightOption;
        var currentIndex = index + 1;
        var isSmall = false;
        if (smallAfterFirst4 && currentIndex > 4) {
            isSmall = true;
        }
        if (smallAfterFirst) {
            isSmall = true;
        }
        if (smallOptions && currentIndex > 2) {
            isSmall = true;
        }
        return (React.createElement(Tooltip, { title: option.description ? option.description : option.title, key: option.type },
            React.createElement("div", { className: cn(css.option, (_a = {},
                    _a[css.paid] = option.availability === FareFeaturePaymentType.Pay,
                    _a[css.not_available] = option.availability === FareFeaturePaymentType.NotAvailable,
                    _a[css.small] = isSmall,
                    _a)) },
                React.createElement("span", { className: css.icon }, isSpecialLuggageOverweightOption ? LuggageExcessIcon : icons[option.type]),
                React.createElement("span", { className: css.availabilityIcon }, (_b = (isSmall && option.value)) !== null && _b !== void 0 ? _b : payment[option.availability]),
                !isSmall && (React.createElement(React.Fragment, null, (_c = option.value) !== null && _c !== void 0 ? _c : option.title,
                    React.createElement("div", { className: css.size }, option.size))))));
    }, [fare]);
    var renderHeader = useCallback(function () { return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: css.header }, fare.title),
        React.createElement("div", { className: css.container }, options.map(renderOption)))); }, [fare, options]);
    var renderUpgradeOption = useCallback(function () { return (React.createElement("div", { className: css.upgradeOffer, onClick: props.onUpgrade },
        upgradeOption.upgradeOffer,
        React.createElement(Link, { action: function () { return null; }, className: css.upgradeOffer__link },
            t('Choose'),
            " ",
            upgradeOption.canUpgradeTo.fareFamily.title,
            Icons.Chevron))); }, [upgradeOption, props.onUpgrade]);
    var renderRulesButton = useCallback(function (_a) {
        var flightId = _a.flightId;
        return (React.createElement(OverrideComponent, { componentProps: {
                flightId: flightId
            }, component: {
                FareGroupGridRulesButton: function () { return null; }
            } }));
    }, []);
    var renderers = useMemo(function () { return ({
        renderHeader: renderHeader,
        renderRulesButton: renderRulesButton,
        renderUpgradeOption: upgradeOption ? renderUpgradeOption : function () { return null; }
    }); }, [upgradeOption, props.onUpgrade, fare]);
    return (React.createElement(FareGroup, __assign({}, props, { className: cn(props.className, (_a = {}, _a[css.recommended] = fare.isRecommended && !props.isDisabled, _a)), renderProps: renderers })));
};
export default FareGroupGrid;
