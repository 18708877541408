import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import StepTitle from '../StepTitle/StepTitle';
import ReasonForm from '../ReasonForm/ReasonForm';
import StepsButtons from '../StepsButtons/StepsButtons';
import MobileStep from '../MobileStep/MobileStep';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { useTheme } from '../../../theme';
import { Choosed, Refundable } from '../../../Icons';
import { StepType, Unvoluntary } from '../../types';
import { useSteps } from '../Refund/hooks';
import { selectReason } from '../../store/reason/actions';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { getSelectedReason } from '../../store/reason/selectors';
import PromoLoader from '../../../PromoLoader';
import { useIsTerminal } from '../../../hooks';
var Reasons = function (props) {
    var _a;
    var t = useTranslation('Refund').t;
    var _b = useTheme('Refund'), css = _b.Refund, wrapperCss = _b.Wrapper;
    var _c = useSteps(), currentStep = _c.currentStep, canGoToNextStep = _c.canGoToNextStep, goToNextStep = _c.goToNextStep, goToPrevStep = _c.goToPrevStep, isCurrentActiveStepAfter = _c.isCurrentActiveStepAfter, handleMobileCloseClick = _c.handleMobileCloseClick;
    var dispatch = useDispatch();
    var selectedReason = useSelector(getSelectedReason);
    var isReasonsStepActive = currentStep.type === StepType.Reason;
    var renderMobileTitle = useCallback(function () { return (React.createElement(React.Fragment, null, selectedReason === Unvoluntary.Unvoluntary ? (React.createElement("span", null, currentStep.type === StepType.Passengers
        ? t('Choose the reason for refund')
        : t('Voluntary refund'))) : (React.createElement(React.Fragment, null,
        React.createElement("span", null,
            t('Unvoluntary refund'),
            React.createElement("p", { className: css.reasonDescr }, t(selectedReason === null || selectedReason === void 0 ? void 0 : selectedReason.toString()))))))); }, []);
    var reasons = useMemo(function () {
        return [
            {
                title: t('Voluntary refund'),
                reasons: [
                    {
                        title: t('my plans have changed'),
                        checked: selectedReason === Unvoluntary.Unvoluntary.toString(),
                        value: Unvoluntary.Unvoluntary
                    }
                ]
            },
            props.reasonsGroups.length && {
                title: t('Unvoluntary refund'),
                reasons: props.reasonsGroups.map(function (reason) { return ({
                    checked: selectedReason === reason.title,
                    value: reason.value,
                    title: t(reason.title)
                }); })
            }
        ].filter(Boolean);
    }, [selectedReason, props.reasonsGroups]);
    var renderContent = useCallback(function () {
        return isReasonsStepActive && (React.createElement(React.Fragment, null,
            React.createElement(MediaQuery, { minWidth: 'mobile' },
                React.createElement(StepTitle, { className: css.stepTitle, icon: Choosed, title: t('Choose the reason for refund') })),
            React.createElement(ReasonForm, { reasons: reasons, onReasonSelect: function (reason) { return dispatch(selectReason(reason)); } }),
            React.createElement(MediaQuery, { minWidth: 'mobile' },
                React.createElement(StepsButtons, { className: css.buttons, position: 'center', backButton: !useIsTerminal() ? { children: t('Back'), onClick: goToPrevStep } : null, nextButton: {
                        children: t('Continue'),
                        onClick: goToNextStep,
                        disabled: !canGoToNextStep
                    } }))));
    }, [props, isReasonsStepActive]);
    if (props.isLoading && isReasonsStepActive) {
        return React.createElement(PromoLoader, { className: wrapperCss.promoLoader });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: 'mobile' }, renderContent()),
        React.createElement(MobileStep, { icon: Refundable, onCloseClick: handleMobileCloseClick(StepType.Reason), isActive: currentStep.type === StepType.Reason, className: cn(css.mobileStep, (_a = {},
                _a[css.passed] = isCurrentActiveStepAfter(StepType.Reason),
                _a)), renderTitle: renderMobileTitle }, renderContent())));
};
export default Reasons;
