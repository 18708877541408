import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Input from '../../../../Input';
import { Button as Btn } from '../../../../index';
import { emailRegex, phoneRegex } from '../../../../utils';
import { LoginType } from '../../../../__generated__/globalTypes';
import SocialAuth from '../../../../SocialAuth';
import { clearPhoneNumber } from '../../../utils';
import * as Auth from '../../../../__queries__/query.graphql';
import * as Account from '../../../../__queries__/Account.graphql';
import PhoneInput from '../../../../PhoneInput';
import { useTheme } from '../../../../theme';
export var LoginForm = function (_a) {
    var _b;
    var hideSocial = _a.hideSocial, loginType = _a.loginType, onSuccess = _a.onSuccess, onAlternativeClick = _a.onAlternativeClick, initialValues = _a.initialValues, _c = _a.variant, variant = _c === void 0 ? 'common' : _c;
    var theme = useTheme('LoginPage').Form;
    var t = useTranslation('LoginPage').t;
    var _d = __read(useState(false), 2), isLoading = _d[0], setLoading = _d[1];
    var _e = __read(useMutation(Auth.SendSecureCode), 1), sendCode = _e[0];
    var userExistence = useQuery(Account.AccountExistence, {
        skip: true
    });
    var inputProps = {};
    if (loginType === LoginType.Phone) {
        inputProps = {
            inputComponent: PhoneInput
        };
    }
    var onSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var pureLogin, login, isAccountExistence, data;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    pureLogin = values['login'];
                    login = pureLogin;
                    // Remove all redundant symbols from the phone number.
                    if (loginType === LoginType.Phone) {
                        login = clearPhoneNumber(login);
                    }
                    setLoading(true);
                    return [4 /*yield*/, userExistence.refetch({
                            params: {
                                login: login,
                                loginType: loginType
                            }
                        })];
                case 1:
                    isAccountExistence = _c.sent();
                    if (!((_b = (_a = isAccountExistence === null || isAccountExistence === void 0 ? void 0 : isAccountExistence.data) === null || _a === void 0 ? void 0 : _a.AccountExistence) === null || _b === void 0 ? void 0 : _b.result)) return [3 /*break*/, 3];
                    return [4 /*yield*/, sendCode({
                            variables: {
                                params: {
                                    login: login,
                                    loginType: loginType
                                }
                            }
                        })];
                case 2:
                    data = _c.sent();
                    if (data.data.SendSecureCode.result) {
                        onSuccess(pureLogin);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    onSuccess(pureLogin, true);
                    _c.label = 4;
                case 4:
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validate = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var errors;
        return __generator(this, function (_a) {
            errors = {};
            if (!values['login']) {
                errors['login'] = t('Required');
            }
            else if (loginType === LoginType.Email && !emailRegex.test(values['login'])) {
                errors['login'] = t('Please enter a valid email');
            }
            else if (loginType === LoginType.Phone && !phoneRegex.test(values['login'])) {
                errors['login'] = t('Please enter a valid phone number');
            }
            return [2 /*return*/, errors];
        });
    }); };
    var login = (initialValues &&
        ((loginType === LoginType.Email && initialValues.email) ||
            (loginType === LoginType.Phone && initialValues.phone))) ||
        '';
    return (React.createElement("div", { className: cn(theme.wrapper, (_b = {},
            _b[theme.wrapper_noPadding] = variant === 'compact',
            _b)) },
        React.createElement(Form, { initialValues: {
                login: login
            }, validate: validate, onSubmit: onSubmit, render: function (_a) {
                var _b;
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit, className: theme.loginForm },
                    React.createElement(Field, { name: "login", render: function (_a) {
                            var _b;
                            var input = _a.input, meta = _a.meta;
                            return (React.createElement(React.Fragment, null, loginType === LoginType.Email ? (React.createElement(Input, __assign({}, input, { className: theme.input, labelFocusedClassName: cn((_b = {},
                                    _b[theme.error] = meta.error && meta.touched,
                                    _b)), TextFieldProps: {
                                    type: 'email',
                                    label: t('Email address'),
                                    disabled: isLoading,
                                    error: !!meta.error && meta.touched,
                                    helperText: !!meta.error && meta.touched ? meta.error : '',
                                    onChange: function (event) {
                                        input.onChange(event);
                                    }
                                } }, inputProps))) : (React.createElement(PhoneInput, __assign({}, input, { displayInitialValueAsLocalNumber: false, className: cn(theme.phoneInput, theme.input), textFieldsProps: {
                                    type: 'tel',
                                    label: t('Phone number'),
                                    disabled: isLoading,
                                    error: !!meta.error && meta.touched,
                                    helperText: !!meta.error && meta.touched ? meta.error : '',
                                    onChange: function (event) {
                                        input.onChange(event);
                                    }
                                } }, inputProps, { value: input.value })))));
                        } }),
                    React.createElement(Btn, { children: t('Next'), type: "submit", className: cn(theme.loginForm__button, (_b = {},
                            _b[theme.loginForm__button_disabled] = isLoading,
                            _b)), disabled: isLoading })));
            } }),
        !hideSocial && (React.createElement("div", { className: theme.footer },
            React.createElement("div", { className: theme.social },
                React.createElement("span", { className: theme.social__text }, t('use social networks')),
                React.createElement("div", { className: theme.social__buttons },
                    React.createElement(SocialAuth, null))),
            React.createElement("a", { href: "#", className: theme.alternative, onClick: function (e) {
                    e.preventDefault();
                    onAlternativeClick();
                } }, t('or another way to sign in'))))));
};
export default LoginForm;
