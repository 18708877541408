import * as React from 'react';
import { Button } from '../../../../../index';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
var MobilePassenger = function (_a) {
    var _b;
    var passengerName = _a.passengerName, seatNumber = _a.seatNumber, onClick = _a.onClick, clearEnable = _a.clearEnable, confirmed = _a.confirmed;
    var theme = useTheme('SeatMap').Passenger;
    var t = useTranslation('SeatMap').t;
    return (React.createElement("div", { className: theme.item, onClick: onClick },
        React.createElement("div", { className: theme.main },
            seatNumber && React.createElement("div", { className: theme.seat }, seatNumber),
            React.createElement("div", { className: theme.passengerName }, passengerName)),
        !confirmed && (React.createElement(Button, { variant: "flat", className: cn(theme.select, (_b = {}, _b[theme.select_selected] = !!seatNumber, _b)) }, seatNumber ? t(clearEnable ? 'Cancel' : 'Change seat') : t('Take this seat')))));
};
export default MobilePassenger;
