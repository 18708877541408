import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import MediaQuery from 'react-responsive';
import Helmet from 'react-helmet';
import PaymentTimeLimit from '../../../../PaymentTimeLimit';
import { useTheme } from '../../../../theme';
import PaymentForm from '../../../../PaymentForm';
import * as SetOrderStatusQuery from '../../../../__queries__/SetOrderStatus.graphql';
import PromoLoader from '../../../../PromoLoader/components/PromoLoader';
import Modal from '../../../../Modal';
import PaymentResultComponent from '../../../../PaymentResult/PaymentResult';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import MobileToolbar from '../../../../MobileToolbar';
import { OrderStatus } from '../../../../__generated__/globalTypes';
import SlideBottom from '../../../../SlideBottom';
import { useTranslation } from 'react-i18next';
import { PaymentType } from '../../../../PaymentForm/types';
import { PaymentResultStatus } from '../Checkout';
import { usePaymentMethodsQuery } from '@websky/graphql';
var PaymentResult;
(function (PaymentResult) {
    PaymentResult["Success"] = "success";
    PaymentResult["Fail"] = "fail";
})(PaymentResult || (PaymentResult = {}));
export var PAYMENT_KEY_TO_TYPE_MAP = new Map([
    ['card', PaymentType.Card],
    ['credit', PaymentType.Credit],
    ['kaspi', PaymentType.Kaspi],
    ['itransfer', PaymentType.Itransfer],
    ['gpay', PaymentType.Gpay]
]);
var Payment = function (_a) {
    var orderId = _a.orderId, priceToPay = _a.priceToPay, onPaymentFrameLoaded = _a.onPaymentFrameLoaded, timelimit = _a.timelimit, onSuccess = _a.onSuccess, redirectPathname = _a.redirectPathname, goToPrevStep = _a.goToPrevStep, onPaymentMethodsLoaded = _a.onPaymentMethodsLoaded, onBackToOrder = _a.onBackToOrder, orderPaymentResult = _a.orderPaymentResult;
    var _b = __read(useState(), 2), paymentResult = _b[0], setPaymentResult = _b[1];
    var _c = __read(useState(false), 2), testPaymentInProgress = _c[0], setTestPaymentInProgress = _c[1];
    var _d = __read(useState(null), 2), selectedPaymentPage = _d[0], setSelectedPaymentPage = _d[1];
    var theme = useTheme('Checkout').CheckoutStyles;
    var t = useTranslation('Title').t;
    var selectedPaymentType = selectedPaymentPage === null || selectedPaymentPage === void 0 ? void 0 : selectedPaymentPage.type;
    var baseUrl = window.location.href.replace(window.location.hash, "#".concat(redirectPathname));
    var _e = usePaymentMethodsQuery({
        fetchPolicy: 'no-cache',
        variables: {
            withCard: selectedPaymentType === PaymentType.Card,
            withCredit: selectedPaymentType === PaymentType.Credit,
            withGpay: selectedPaymentType === PaymentType.Gpay,
            withItransfer: selectedPaymentType === PaymentType.Itransfer,
            withKaspi: selectedPaymentType === PaymentType.Kaspi,
            parameters: {
                orderId: orderId,
                backRedirectUrl: "".concat(baseUrl, "/successfull"),
                backRedirectUrlOnFail: "".concat(baseUrl, "/failed")
            }
        },
        skip: orderPaymentResult === PaymentResultStatus.Success
    }), data = _e.data, loading = _e.loading;
    var testPaymentEnabled = process.env.TEST_PAYMENT_ENABLED === 'true';
    var _f = __read(useMutation(SetOrderStatusQuery.SetOrderStatus), 1), runTestPayment = _f[0];
    var onTestPayment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errors, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setTestPaymentInProgress(true);
                    return [4 /*yield*/, runTestPayment({
                            variables: {
                                orderId: orderId,
                                status: OrderStatus.Confirmed
                            }
                        })];
                case 1:
                    errors = (_a.sent()).errors;
                    if (errors) {
                        setPaymentResult(PaymentResult.Fail);
                    }
                    else {
                        setPaymentResult(PaymentResult.Success);
                    }
                    setTestPaymentInProgress(false);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setPaymentResult(PaymentResult.Fail);
                    setTestPaymentInProgress(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onPaymentComplete = useCallback(function () {
        onSuccess(true);
    }, [onSuccess]);
    var closePaymentResult = function () {
        if (paymentResult === PaymentResult.Success) {
            onPaymentComplete();
        }
        else {
            setPaymentResult(null);
        }
    };
    useEffect(function () {
        if (!loading && (data === null || data === void 0 ? void 0 : data.PaymentMethods)) {
            // select payment page if only one available
            // filter out null values
            var availablePaymentMethods = Object.entries(data === null || data === void 0 ? void 0 : data.PaymentMethods).filter(function (_a) {
                var _b = __read(_a, 2), paymentMethod = _b[1];
                return typeof paymentMethod === 'object' && !!paymentMethod;
            });
            if (availablePaymentMethods.length === 1) {
                var availablePaymentMethodKeyValue = availablePaymentMethods[0];
                var paymentType = PAYMENT_KEY_TO_TYPE_MAP.get(availablePaymentMethodKeyValue[0]);
                if (paymentType) {
                    setSelectedPaymentPage({ type: paymentType, content: React.createElement(React.Fragment, null), title: '' });
                }
            }
            if (onPaymentMethodsLoaded) {
                if (selectedPaymentPage) {
                    onPaymentMethodsLoaded();
                }
            }
        }
    }, [loading, data]);
    var handleSelectPaymentMethod = useCallback(function (page) {
        if (!selectedPaymentPage) {
            setSelectedPaymentPage(page);
        }
    }, [setSelectedPaymentPage, setSelectedPaymentPage]);
    var renderPaymentResult = useCallback(function () { return (React.createElement(PaymentResultComponent, { isModal: true, result: paymentResult, onClick: paymentResult === PaymentResult.Success ? onPaymentComplete : closePaymentResult })); }, [paymentResult, onPaymentComplete, closePaymentResult]);
    return loading ? (React.createElement(PromoLoader, null)) : (React.createElement("div", { className: cn(theme.checkout, theme.checkout_narrow, theme.checkout_margined) },
        React.createElement(Helmet, null,
            React.createElement("title", null,
                t('Title:companyName'),
                " \u2013 ",
                t('Payment'))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: !!paymentResult, onClose: closePaymentResult, className: theme.payment__slideBottom }, renderPaymentResult())),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: !!paymentResult, onClose: closePaymentResult, classes: {
                    paper: theme.payment__result
                } }, renderPaymentResult())),
        timelimit && (React.createElement("div", { className: theme.timelimit },
            React.createElement(PaymentTimeLimit, { orderId: orderId, orderIsBooked: true, variant: "normal", date: timelimit }))),
        React.createElement(PaymentForm, { paymentPage: selectedPaymentPage, onPaymentMethodSelected: handleSelectPaymentMethod, onTestPayment: !testPaymentInProgress && testPaymentEnabled ? onTestPayment : null, kaspi: data === null || data === void 0 ? void 0 : data.PaymentMethods.kaspi, card: data === null || data === void 0 ? void 0 : data.PaymentMethods.card, googlePay: data === null || data === void 0 ? void 0 : data.PaymentMethods.gpay, credit: data === null || data === void 0 ? void 0 : data.PaymentMethods.credit, itransfer: data === null || data === void 0 ? void 0 : data.PaymentMethods.itransfer, onPaymentFrameLoaded: onPaymentFrameLoaded, priceToPay: priceToPay, onSuccess: function () { return setPaymentResult(PaymentResult.Success); }, onFail: function () { return setPaymentResult(PaymentResult.Fail); }, onBackToOrder: onBackToOrder }),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(MobileToolbar, { onBack: goToPrevStep }))));
};
export default Payment;
