import { __assign } from "tslib";
import * as React from 'react';
import { ExarePriceDetailsPriceElementType } from '../../../__generated__/graphql';
import Money from '../../../Money';
import css from './PriceDetails.css';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import RefundPaper from '../../../Refund/Components/RefundPaper/RefundPaper';
import ElementsPriceDetails from '../../ElementsPriceDetails/ElementsPriceDetails';
var isFareOrServicesNotEmpty = function (refunded) {
    return refunded.elements.some(function (element) {
        return element.type === ExarePriceDetailsPriceElementType.fare ||
            element.type === ExarePriceDetailsPriceElementType.gdsServiceFare;
    });
};
var isRetentionNotEmpty = function (prices) { var _a, _b; return ((_b = (_a = prices.priceDetails.retention) === null || _a === void 0 ? void 0 : _a.elements) === null || _b === void 0 ? void 0 : _b.length) > 0; };
var PriceItem = function (_a) {
    var name = _a.name, price = _a.price, priceClassName = _a.priceClassName;
    return (React.createElement("div", { className: cn(css.price, priceClassName) },
        React.createElement("div", { className: css.price__title }, name),
        React.createElement(Money, { money: __assign(__assign({}, price), { amount: Math.abs(price.amount) }) })));
};
var PriceDetails = function (_a) {
    var prices = _a.prices, componentClassName = _a.className, showDetails = _a.showDetails;
    var t = useTranslation('Refund').t;
    var renderDetails = function () { return (React.createElement(React.Fragment, null,
        React.createElement(ElementsPriceDetails, { elements: prices.priceDetails.refunded }),
        isFareOrServicesNotEmpty(prices.priceDetails.refunded) && React.createElement("div", { className: css.dashedSeparator }),
        React.createElement(PriceItem, { name: t('Total has been paid'), priceClassName: css.priceHeadTitle, price: prices.priceDetails.paid.sum }),
        React.createElement("div", { className: css.dashedSeparator }),
        isRetentionNotEmpty(prices) && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.priceHeadTitle }, t('Retentions')),
            React.createElement(ElementsPriceDetails, { elements: prices.priceDetails.retention }),
            React.createElement("div", { className: css.dashedSeparator }))),
        React.createElement(PriceItem, { name: t('Non refundable sum'), priceClassName: css.priceHeadTitle, price: __assign(__assign({}, prices.priceDetails.paid.sum), { amount: prices.priceDetails.paid.sum.amount - prices.priceToPay.amount * -1 }) }))); };
    return (React.createElement(RefundPaper, { className: componentClassName },
        React.createElement(PriceItem, { priceClassName: css.totalRefund, name: "".concat(t('Refund amount'), ":"), price: prices.priceToPay }),
        showDetails && renderDetails()));
};
export default PriceDetails;
