import { __read } from "tslib";
import { isMonthInMMMFormat, isDigits } from '../helpers';
import { removeFXD } from './amadeusTransportationOptions';
var PASSENGER_TYPE_COUNT = /\w+\/\d+|\w+\//g;
var PREFERENTIAL_SYMBOL = /^R/g;
export var validateIata = function (str, citiesIata) {
    if (!citiesIata.includes(str)) {
        return 'Incorrect city format.';
    }
    return '';
};
export var validatePassengerType = function (str, passengerTypeCodes) {
    var passengers = (str + '/')
        .match(PASSENGER_TYPE_COUNT)
        .map(function (passenger) { return passenger.split('/')[0].replace(PREFERENTIAL_SYMBOL, ''); });
    var passengersWithWrongType = passengers.some(function (passenger) { return !passengerTypeCodes.concat(['PAX', 'CH']).includes(passenger); });
    if (passengersWithWrongType) {
        return 'Incorrect passenger type.';
    }
    else {
        return '';
    }
};
export var validateDate = function (str) {
    var day = str.slice(1, 3);
    var month = str.slice(3, 6);
    if (isDigits(day)) {
        if (+day < 1 && +day > 32) {
            return 'Incorrect day.';
        }
        if (!isMonthInMMMFormat(month)) {
            return 'Incorrect month.';
        }
        return '';
    }
    else {
        return 'Incorrect day.';
    }
};
export var minLength = function (str) {
    if (str.length < 13) {
        return 'Incorrect command.';
    }
    return '';
};
export var amadeusTransportationOptionsValidation = function (_a) {
    var str = _a.str, citiesIata = _a.citiesIata, passengerTypeCodes = _a.passengerTypeCodes;
    var strWothoutFDX = removeFXD(str);
    var _b = __read(strWothoutFDX.split('//'), 2), segmentsInfo = _b[0], passengersInfo = _b[1];
    var validations = [
        minLength(strWothoutFDX),
        passengersInfo ? validatePassengerType(passengersInfo, passengerTypeCodes) : ''
    ].concat(segmentsInfo
        .split('/')
        .map(function (segment, idx) {
        return idx === 0
            ? validateIata(segment.slice(-3), citiesIata)
            : [validateDate(segment), validateIata(segment.slice(-3), citiesIata)].join(' ');
    }));
    return validations.join(' ').trim();
};
