import Terminal from './components/Terminal.css';
import Enter from './components/Enter/Enter.css';
import Message from './components/Message/Message.css';
import Header from './components/Header/Header.css';
import HistoryModal from './components/HistoryModal/HistoryModal.css';
import ClearModal from './components/ClearModal/ClearModal.css';
import UnsavedModal from './components/UnsavedModal/UnsavedModal.css';
import PdfModal from './components/PdfModal/PdfModal.css';
import SendToEmail from './components/SendToEmail/SendToEmail.css';
import SupportModal from './components/SupportModal/SupportModal.css';
export default {
    Terminal: Terminal,
    Enter: Enter,
    Message: Message,
    Header: Header,
    HistoryModal: HistoryModal,
    ClearModal: ClearModal,
    UnsavedModal: UnsavedModal,
    PdfModal: PdfModal,
    SendToEmail: SendToEmail,
    SupportModal: SupportModal
};
