import * as React from 'react';
import { Passenger } from './Passenger/Passenger';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import { getPassengerChangeability } from '../utils';
initI18n('Passengers');
export var Passengers = React.memo(function (_a) {
    var passengers = _a.passengers, addPassenger = _a.addPassenger, removePassenger = _a.removePassenger;
    var css = useTheme('Passengers').PassengersStyles;
    var passengersConfig = {};
    var totalCount = 0;
    passengers.forEach(function (passenger) {
        passengersConfig[passenger.type] = {
            count: passenger.count,
            age: null
        };
        totalCount += passenger.count;
    });
    return (React.createElement("ul", { className: css.list }, passengers.map(function (passenger) {
        var _a = getPassengerChangeability(passenger, passengersConfig, totalCount), canBeIncreased = _a.canBeIncreased, canBeDecreased = _a.canBeDecreased;
        return (React.createElement("li", { key: passenger.type, className: css.item },
            React.createElement(Passenger, { passenger: passenger, addPassenger: addPassenger, removePassenger: removePassenger, decreaseIsEnabled: canBeDecreased, increaseIsEnabled: canBeIncreased })));
    })));
});
export default Passengers;
