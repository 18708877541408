import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import AdditionalServiceWrapper from '../../AdditionalServiceWrapper';
import { useTheme } from '../../theme';
import { getCalculatedBusinessLoungePrice } from '../../Checkout/store/orderPrice/selectors';
import { getBusinessLounge, getSegments } from '../../Checkout/store/order/selectors';
import { useOrderService } from '../../CheckoutAdditionalService/components/hooks';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../__generated__/globalTypes';
import { useIsMobile } from '../../utils';
import Modal from '../../Modal';
import VipServiceSelect from '../../VipServiceSelect/VipServiceSelect';
import { TravellerFieldEnum } from '../../__generated__/graphql';
import { Money } from '../../Money';
import { CloseClear, Included } from '../../Icons';
import { getServicesByRfics } from '../utils';
import { useRenderers } from '../../renderProps';
var AdditionalBusiness = function (_a) {
    var _b;
    var _c;
    var className = _a.className;
    var css = useTheme('AdditionalBusiness').AdditionalBusiness;
    var t = useTranslation('Checkout').t;
    var segments = useSelector(getSegments);
    var businessLoungeServices = useSelector(getBusinessLounge);
    var price = useSelector(getCalculatedBusinessLoungePrice);
    var isMobile = useIsMobile();
    var _d = useOrderService(businessLoungeServices, OrderAdditionalServiceGdsServiceServiceType.BusinessLounge), selectedServices = _d.selectedServices, renderSubheader = _d.renderSubheader, onOpen = _d.onOpen, onClose = _d.onClose, onSaveHandler = _d.onSaveHandler, passengers = _d.passengers, opened = _d.opened, selectedSegments = _d.selectedSegments;
    var businessLoungeByRfics = useMemo(function () { return getServicesByRfics(businessLoungeServices); }, [businessLoungeServices]);
    var getValueByType = function (passenger, type) { var _a; return (_a = passenger.values.find(function (value) { return value.type === type; })) === null || _a === void 0 ? void 0 : _a.value; };
    var onRemovePassenger = function (id) {
        return onSaveHandler(selectedSegments.map(function (_a) {
            var _b = _a, _c = __read(_b, 1), _d = _c[0], passenger = _d.passenger, segment = __rest(_d, ["passenger"]);
            if (segment.passengerId === id) {
                return __assign(__assign({}, segment), { count: 0 });
            }
            return segment;
        }));
    };
    var renderAdditionalServiceCloseButton = useRenderers().renderAdditionalServiceCloseButton;
    return (React.createElement(React.Fragment, null,
        React.createElement(AdditionalServiceWrapper, { className: className, headerClassName: css.header, contentClassName: cn(css.content, (_b = {},
                _b[css.content_active] = (selectedSegments === null || selectedSegments === void 0 ? void 0 : selectedSegments.length) > 0,
                _b)), title: t('Business lounge'), fromPrice: price, totalPrice: price, isSelected: (selectedSegments === null || selectedSegments === void 0 ? void 0 : selectedSegments.length) > 0, onSuccess: onOpen }, (selectedSegments === null || selectedSegments === void 0 ? void 0 : selectedSegments.length) > 0
            ? selectedSegments.map(function (_a, index) {
                var _b, _c;
                var _d = __read(_a, 1), segment = _d[0];
                var firstName = getValueByType(segment.passenger, TravellerFieldEnum.FirstName);
                var lastName = getValueByType(segment.passenger, TravellerFieldEnum.LastName);
                var name = "".concat(firstName, " ").concat(lastName).toLowerCase();
                var isConfirmed = segment.count > 0 && segment.count === segment.confirmedCount;
                var passengerPrice = (_c = (_b = businessLoungeByRfics === null || businessLoungeByRfics === void 0 ? void 0 : businessLoungeByRfics.find(function (services) {
                    return services.find(function (service) { return service.id === segment.serviceId; });
                })) === null || _b === void 0 ? void 0 : _b.find(function (service) { return service.id === segment.serviceId; })) === null || _c === void 0 ? void 0 : _c.price;
                return (React.createElement("div", { className: css.passenger, key: index },
                    React.createElement("span", { className: css.passenger_name }, name),
                    React.createElement("div", { className: css.passenger_info },
                        React.createElement("span", null, segments[0].departure.airport.iata),
                        isConfirmed ? (React.createElement("div", { className: css.passenger_included }, Included)) : (React.createElement(React.Fragment, null,
                            React.createElement(Money, { moneyClassName: css.passenger_money, money: passengerPrice }),
                            React.createElement("div", { className: css.passenger_remove, onClick: function () { return onRemovePassenger(segment.passengerId); } }, CloseClear))))));
            })
            : (_c = businessLoungeServices[0]) === null || _c === void 0 ? void 0 : _c.description),
        React.createElement(Modal, { slideUp: isMobile, open: opened, onClose: onClose, maxWidth: "sm", closeButtonRenderer: renderAdditionalServiceCloseButton },
            React.createElement(VipServiceSelect, { header: t('Business lounge'), passengers: passengers, services: businessLoungeServices, segments: segments, onSave: onSaveHandler, selectedServices: selectedServices, onClose: onClose, subheader: renderSubheader() }))));
};
export default AdditionalBusiness;
