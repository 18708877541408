import { __read } from "tslib";
import * as React from 'react';
import { useContext, useEffect } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import SwipableViews from 'react-swipeable-views';
import { MOBILE_MIN_WIDTH, TABLET_MIDDLE_WIDTH, TABLET_MIN_WIDTH } from '../../../../utils';
import cn from 'classnames';
import Airplane from '../../Airplane/Airplane';
import SeatMap from '../../SeatMap/SeatMap';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { Button, SlideBottom } from '../../../../index';
import memes from '../../../../memes';
import { ModeContext } from '../../../../context';
import { Mode } from '../../../../types';
import Controls from '../Controls/Controls';
import { SeatsRecommendContext } from '../../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import { useIsTerminal } from '../../../../hooks';
var SeatMapServiceContent = React.memo(function (props) {
    var _a, _b;
    var currentSegment = props.segments[props.segmentIndex];
    var unavailableMessageRef = React.useRef();
    var isSeatMapAvailable = memes(function (segmentIndex) { return props.segments[segmentIndex].decks.length > 0; });
    var isMobile = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    var theme = useTheme('SeatMap').ComponentStyles;
    var t = useTranslation('SeatMap').t;
    var mode = useContext(ModeContext);
    var isTerminal = useIsTerminal();
    var _c = __read(React.useState(false), 2), seatsOpen = _c[0], setSeatsOpen = _c[1];
    var passengersContainerRef = React.useRef(null);
    var canShowSeatMap = function (segment) {
        if (typeof segment !== 'undefined' && mode !== Mode.Checkin) {
            return !props.segments[segment].isSeatsAvailableInCheckinOnly;
        }
        return true;
    };
    var availableOnCheckinMessage = (React.createElement("div", { className: theme.unavailable, ref: unavailableMessageRef },
        currentSegment && (React.createElement("img", { alt: "company icon", className: theme.unavailable__img, src: currentSegment.segmentInfo.operatingAirline.icon })),
        React.createElement("p", { className: theme.unavailable__title },
            React.createElement("p", { className: theme.unavailable__header }, t('SeatMap:Less than 24 hours left before the flight')),
            t('SeatMap:You can select seats using the online registration service after issuing tickets')),
        props.segmentIndex + 1 < props.segments.length && (React.createElement(Button, { onClick: props.onSelectNextFlight, className: theme.unavailable__button }, t('SeatMap:Next flight')))));
    var unavailableSeatMapMessage = (React.createElement("div", { className: theme.unavailable, ref: unavailableMessageRef },
        currentSegment && (React.createElement("img", { alt: "company icon", className: theme.unavailable__img, src: currentSegment.segmentInfo.operatingAirline.icon })),
        React.createElement("p", { className: theme.unavailable__title }, t('SeatMap:Unfortunately, seat selection on this flight is not available.')),
        props.segmentIndex + 1 < props.segments.length && (React.createElement(Button, { onClick: props.onSelectNextFlight, className: theme.unavailable__button }, t('SeatMap:Next flight')))));
    useEffect(function () {
        var _a;
        if (!isSeatMapAvailable(props.segmentIndex)) {
            (_a = unavailableMessageRef === null || unavailableMessageRef === void 0 ? void 0 : unavailableMessageRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            });
        }
    });
    var fixPassengersOnScroll = function () {
        if (isMobile) {
            return;
        }
        var element = document.getElementById('passengers');
        if (element) {
            if (element.getBoundingClientRect().top < 8) {
                element.classList.add(theme.header_fixed);
            }
            else if (element.getBoundingClientRect().top < passengersContainerRef.current.getBoundingClientRect().top) {
                element.classList.remove(theme.header_fixed);
            }
        }
    };
    useEffect(function () {
        if (!isMobile) {
            var element = document.getElementById('passengers');
            scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            if (element) {
                element.classList.remove(theme.header_fixed);
                window.addEventListener('scroll', fixPassengersOnScroll);
            }
        }
        return function () { return window.removeEventListener('scroll', fixPassengersOnScroll); };
    }, [props.segmentIndex, isMobile]);
    var seatsRecommendContext = useContext(SeatsRecommendContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, props.renderSegmentTabs()),
        canShowSeatMap(props.segmentIndex) && isSeatMapAvailable(props.segmentIndex) && (React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
            !isTerminal && (React.createElement("div", { id: "header", className: cn(theme.header_mobile, (_a = {},
                    _a[theme.header_mobile_overlay] = seatsRecommendContext.isOpen,
                    _a)) },
                React.createElement(Airplane, { aircraft: currentSegment.segmentInfo.aircraft, routeTime: currentSegment.segmentInfo.duration, sunInfo: currentSegment.sunSide, onSeatsDetailsClick: function () { return setSeatsOpen(true); }, segment: currentSegment }))),
            React.createElement(Controls, { segmentId: props.segmentIndex, segmentsCount: props.segments.length, onProceed: props.onProceed, onSelectNextFlight: props.onSelectNextFlight, onBack: props.onBack }))),
        React.createElement("div", { className: theme.map },
            React.createElement(MediaQuery, { minWidth: TABLET_MIDDLE_WIDTH }, canShowSeatMap(props.segmentIndex) && isSeatMapAvailable(props.segmentIndex) && (React.createElement("div", { className: cn(theme.helpers, (_b = {}, _b[theme.helpers_recommend] = seatsRecommendContext.isOpen, _b)) },
                React.createElement("div", null,
                    !isTerminal && (React.createElement(Airplane, { aircraft: currentSegment.segmentInfo.aircraft, routeTime: currentSegment.segmentInfo.duration, sunInfo: currentSegment.sunSide, onSeatsDetailsClick: function () { return setSeatsOpen(true); }, className: theme.airplane })),
                    React.createElement("div", { ref: passengersContainerRef }, props.renderPassengers())),
                React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
                    React.createElement("div", { className: theme.right }, props.renderSeatsInfo()))))),
            React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                React.createElement(SlideBottom, { isOpen: seatsOpen, onClose: function () { return setSeatsOpen(false); }, className: theme.seatsInfo, header: t('Seat details') },
                    React.createElement("div", null, props.renderSeatsInfo()))),
            props.swipeBetweenSegments ? (React.createElement(SwipableViews, { disabled: true, animateHeight: true, index: props.segmentIndex }, props.segments.map(function (segment, index) { return (React.createElement("div", { key: segment.segmentInfo.id }, isSeatMapAvailable(index) && canShowSeatMap(index) ? (React.createElement(SeatMap, { services: props.services, seatMapRef: props.seatMapRef, isActive: props.segmentIndex === index, segment: segment, onSelect: props.onSelect, passengers: props.passengers, passengerIndex: props.passengerIndex, onClear: props.onClear })) : segment.isSeatsAvailableInCheckinOnly && mode !== Mode.Checkin ? (availableOnCheckinMessage) : (unavailableSeatMapMessage))); }))) : isSeatMapAvailable(props.segmentIndex) && canShowSeatMap(props.segmentIndex) ? (React.createElement(SeatMap, { services: props.services, seatMapRef: props.seatMapRef, isActive: true, segment: props.segments[props.segmentIndex], onSelect: props.onSelect, passengers: props.passengers, passengerIndex: props.passengerIndex })) : props.segments[props.segmentIndex].isSeatsAvailableInCheckinOnly && mode !== Mode.Checkin ? (availableOnCheckinMessage) : (unavailableSeatMapMessage))));
});
export default SeatMapServiceContent;
