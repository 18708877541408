import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import Button from '../../../../../Button';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import WarningModal from '../../../../../WarningModal/components/WarningModal';
import Money from '../../../../../Money';
import SimpleLoader from '../../../../../SimpleLoader';
import { fillOrder } from '../../../../../Checkout/store/order/actions';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { useRefundTransactionMutation, useTicketOrderMutation } from '@websky/graphql';
var paymentMethodsWithReturn = ['cash', 'invoice'];
var Errors = function (props) {
    var theme = useTheme('PaymentErrors').PaymentErrors;
    var t = useTranslation().t;
    var _a = __read(useState(null), 2), sumToRefund = _a[0], setSumToRefund = _a[1];
    var dispatch = useDispatch();
    var _b = __read(useTicketOrderMutation(), 2), ticketOrder = _b[0], isTicketOrderLoading = _b[1].loading;
    var _c = __read(useRefundTransactionMutation(), 2), refundTransaction = _c[0], isRefundLoading = _c[1].loading;
    var isAviaOrderConditional = props.mode === 'aviaOrder' && paymentMethodsWithReturn.includes(props.paymentMethod);
    useEffect(function () {
        var beforeUnloadHandler = function (event) {
            event.preventDefault();
            event.returnValue = '';
            return '';
        };
        if (isAviaOrderConditional && !sumToRefund) {
            window.addEventListener('beforeunload', beforeUnloadHandler);
            return function () {
                window.removeEventListener('beforeunload', beforeUnloadHandler);
            };
        }
        if (sumToRefund) {
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        }
        return function () { };
    }, [sumToRefund]);
    var handleRequestError = function (e) {
        var _a;
        if ((_a = e === null || e === void 0 ? void 0 : e.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
            var errors = e.graphQLErrors.map(function (error) { return error === null || error === void 0 ? void 0 : error.message; });
            props.onError(errors);
        }
    };
    var goToOrder = function () { return (document.location.hash = "#/booking/".concat(props.orderId, "/passengers")); };
    var onTryAgain = function () { return __awaiter(void 0, void 0, void 0, function () {
        var order, e_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, ticketOrder({ variables: { orderId: props.orderId } })];
                case 1:
                    order = _c.sent();
                    if ((_b = (_a = order.data) === null || _a === void 0 ? void 0 : _a.TicketOrder) === null || _b === void 0 ? void 0 : _b.id) {
                        dispatch(fillOrder(order.data.TicketOrder));
                        goToOrder();
                    }
                    else {
                        props.onError([]);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _c.sent();
                    handleRequestError(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onReturnMoney = function () { return __awaiter(void 0, void 0, void 0, function () {
        var refundResult, e_2;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, refundTransaction({
                            variables: {
                                orderId: props.orderId,
                                transactionId: props.transactionId
                            }
                        })];
                case 1:
                    refundResult = _c.sent();
                    if ((_b = (_a = refundResult.data) === null || _a === void 0 ? void 0 : _a.RefundTransaction) === null || _b === void 0 ? void 0 : _b.result) {
                        setSumToRefund(refundResult.data.RefundTransaction.refundSum);
                    }
                    else {
                        props.onError([]);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _c.sent();
                    handleRequestError(e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (isTicketOrderLoading || isRefundLoading) {
        return React.createElement(SimpleLoader, null);
    }
    var renderErrorContent = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.errors }, (_a = props.errors) === null || _a === void 0 ? void 0 : _a.map(function (error) { return (React.createElement("div", { key: error }, error)); })),
            React.createElement("div", null, t('If you leave this page, the pnr may have a problem with modification. You should provide an action in this pop-up window before you go.')),
            React.createElement("div", { className: theme.warningButtons },
                React.createElement(Button, { className: theme.warningButtons__tryAgain, variant: "flat", onClick: onTryAgain }, t('Try again')),
                React.createElement(Button, { className: theme.warningButtons__returnMoney, variant: "flat", onClick: onReturnMoney }, t('Cancel payment')))));
    };
    var renderSumToRefundContent = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                t('Refundable amount after canceling the transaction is'),
                React.createElement("b", null,
                    React.createElement(Money, { money: sumToRefund }))),
            React.createElement("div", { className: theme.warningButtons },
                React.createElement(Button, { className: theme.warningButtons__goToOrder, variant: "flat", onClick: goToOrder }, t('Go to order')))));
    };
    if (!isAviaOrderConditional) {
        return (React.createElement(WarningModal, { title: t('Ops...'), content: t('Unfortunately, something went wrong. Please try again or contact with customer support service'), isOpen: true, errorMessage: props.errors, buttonText: t('Close'), onButtonClick: props.handleCloseWarningModal, onClose: props.handleCloseWarningModal }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Prompt, { when: !sumToRefund, message: t("If you leave, this pnr may have a problem with modification.\nYou should provide the action in the pop-up window\nIf you click OK you leave this page") }),
        React.createElement(WarningModal, { isOpen: true, title: sumToRefund ? t('Return money') : t('Unfortunately, something went wrong'), content: sumToRefund ? renderSumToRefundContent() : renderErrorContent() })));
};
export default Errors;
