import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
initI18n('AdditionalServiceCard');
var Header = function (props) {
    var _a;
    var headClassName = props.headClassName, header = props.header, icon = props.icon, logo = props.logo, _b = props.horizontal, horizontal = _b === void 0 ? false : _b, renderHeader = props.renderHeader;
    var theme = useTheme('AdditionalServiceCard').AdditionalServiceStyles;
    var t = useTranslation('AdditionalServiceCard').t;
    return (React.createElement("div", { className: cn(theme.head, headClassName, (_a = {},
            _a[theme.insurance] = header === 'Insurance',
            _a[theme.transfer] = header === 'Transfer',
            _a[theme.head_horizontal] = horizontal,
            _a)) },
        !horizontal && (React.createElement(React.Fragment, null,
            logo && React.createElement("div", { className: theme.logo__wrapper }, logo),
            renderHeader ? renderHeader() : React.createElement("div", { className: theme.header }, t(header)))),
        React.createElement("div", { className: theme.whiteLines },
            React.createElement("div", { className: theme.whiteLine_wide }),
            React.createElement("div", { className: theme.whiteLine_narrow })),
        icon && React.createElement("div", { className: theme.icon }, icon)));
};
export default Header;
