import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { useConfig } from '../../../context';
import { useToggleable } from '../../../hooks';
export var useInsurance = function (_a) {
    var passengers = _a.passengers, insurances = _a.insurances, orderId = _a.orderId, isSelected = _a.isSelected, saveMutation = _a.saveMutation;
    var _b = useToggleable(false), confirmationPopupIsOpen = _b.isOpen, setConfirmationPopupIsOpen = _b.setOpen;
    var _c = useToggleable(false), downloadPopupOpen = _c.isOpen, setDownloadPopupOpen = _c.setOpen;
    var _d = useConfig().global.companyInfo, insuranceRulesUrl = _d.insurance, multiInsurance = _d.multiInsurance, insuranceDocuments = _d.specialDocumentForInsurance;
    var issuedInsurancesCodes = insurances.selectedInsurances
        .filter(function (selectedInsurance) { return selectedInsurance.policyNumber; })
        .map(function (issuedInsurance) { return issuedInsurance.insuranceProgram.code; });
    var _e = useToggleable(false), openMultiInsurance = _e.isOpen, setInsuranceVisible = _e.setOpen;
    var _f = useToggleable(false), errors = _f.isOpen, setErrors = _f.setOpen;
    var initialState = passengers.map(function (passenger) {
        var _a, _b, _c, _d, _e, _f, _g;
        var insurancePassenger = (_a = insurances.travellerExtraValues) === null || _a === void 0 ? void 0 : _a.find(function (traveller) { return traveller.traveller.id === passenger.id; });
        return {
            name: "null",
            value: ((_c = (_b = insurancePassenger === null || insurancePassenger === void 0 ? void 0 : insurancePassenger.extraValues) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.value) || '',
            id: passenger.id,
            regex: (_e = (_d = insurancePassenger === null || insurancePassenger === void 0 ? void 0 : insurancePassenger.extraValues) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.validationRules[0].regExp[0],
            isRequired: insurancePassenger === null || insurancePassenger === void 0 ? void 0 : insurancePassenger.needKzIinDocument,
            disabled: !!((_g = (_f = insurancePassenger === null || insurancePassenger === void 0 ? void 0 : insurancePassenger.extraValues) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.value)
        };
    });
    var _g = __read(React.useState(initialState), 2), passengerDocuments = _g[0], setPassengerDocuments = _g[1];
    useEffect(function () {
        setPassengerDocuments(initialState);
    }, [insurances]);
    var saveInsurances = function (type, insurances, passengersWithDoc) {
        var programCodes = insurances.map(function (insurance) { return insurance.code; });
        var programsByPassengers = passengers.map(function (passenger) {
            var travellerDocumentNumber = passengersWithDoc
                ? passengersWithDoc.find(function (traveller) { return traveller.id === passenger.id; }).value
                : '';
            return {
                travellerId: passenger.id,
                programCodes: Array.from(new Set(issuedInsurancesCodes.concat(programCodes))),
                travellerDocumentNumber: travellerDocumentNumber
            };
        });
        setPassengerDocuments(passengersWithDoc);
        saveMutation({
            variables: {
                params: {
                    id: orderId,
                    insurances: [
                        multiInsurance
                            ? {
                                insuranceType: type,
                                selectedPrograms: Array.from(new Set(issuedInsurancesCodes.concat(insurances.map(function (insurance) { return insurance.code; })))),
                                selectedProgramsByTravellers: programsByPassengers
                            }
                            : {
                                insuranceType: type,
                                selectedPrograms: insurances.map(function (insurance) { return insurance.code; })
                            }
                    ]
                }
            }
        }).then(function (data) {
            setInsuranceVisible(false);
            if (data.errors) {
                setErrors(true);
            }
        });
    };
    var toggleInsurance = function () {
        if (multiInsurance) {
            setInsuranceVisible(true);
            return;
        }
        if (isSelected) {
            setConfirmationPopupIsOpen(true);
        }
        else {
            saveInsurances(insurances.type, [insurances.insurancePrograms[0]]);
        }
    };
    var closeConfirmationPopup = function () { return setConfirmationPopupIsOpen(false); };
    var closeDownloadPopup = function () { return setDownloadPopupOpen(false); };
    var onInsuranceRemove = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveInsurances(insurances.type, [])];
                case 1:
                    _a.sent();
                    setConfirmationPopupIsOpen(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return {
        // data
        confirmationPopupIsOpen: confirmationPopupIsOpen,
        setConfirmationPopupIsOpen: setConfirmationPopupIsOpen,
        downloadPopupOpen: downloadPopupOpen,
        setDownloadPopupOpen: setDownloadPopupOpen,
        insuranceRulesUrl: insuranceRulesUrl,
        multiInsurance: multiInsurance,
        insuranceDocuments: insuranceDocuments,
        openMultiInsurance: openMultiInsurance,
        setInsuranceVisible: setInsuranceVisible,
        errors: errors,
        setErrors: setErrors,
        passengerDocuments: passengerDocuments,
        setPassengerDocuments: setPassengerDocuments,
        // functions
        saveInsurances: saveInsurances,
        toggleInsurance: toggleInsurance,
        closeConfirmationPopup: closeConfirmationPopup,
        closeDownloadPopup: closeDownloadPopup,
        onInsuranceRemove: onInsuranceRemove
    };
};
