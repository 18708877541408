import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Checkbox, Select, MenuItem } from '@material-ui/core';
import { format, parse } from 'date-fns';
import { useTheme } from '../../theme';
import { currentCurrency } from '../../utils';
import Money from '../../Money/components/Money';
import AdditionalServiceWrapper from '../../AdditionalServiceWrapper';
var AeroexpressPopup = function (_a) {
    var _b;
    var className = _a.className, trips = _a.trips, selectedTrips = _a.selectedTrips, travellers = _a.travellers, isLoading = _a.isLoading, onClose = _a.onClose, onSubmit = _a.onSubmit;
    var css = useTheme('AeroexpressPopup').AeroexpressPopup;
    var t = useTranslation('Checkout').t;
    var _c = __read(useState(function () {
        if (selectedTrips.length) {
            return selectedTrips.map(function (_a) {
                var id = _a.id;
                return id;
            });
        }
        return [];
    }), 2), selectedTripIds = _c[0], setSelectedTripIds = _c[1];
    var aeroexpressPrice = {
        amount: (_b = trips === null || trips === void 0 ? void 0 : trips.map(function (trip) { return trip.price.amount; }).sort()[0]) !== null && _b !== void 0 ? _b : 0,
        currency: currentCurrency
    };
    var pricePerPerson = __assign(__assign({}, aeroexpressPrice), { amount: aeroexpressPrice.amount / ((travellers === null || travellers === void 0 ? void 0 : travellers.length) ? travellers.length : 1) });
    var totalPrice = __assign(__assign({}, aeroexpressPrice), { amount: trips === null || trips === void 0 ? void 0 : trips.reduce(function (amount, trip) {
            var selectedTripPrice = selectedTripIds.includes(trip.id) ? trip.price.amount : 0;
            return amount + selectedTripPrice;
        }, 0) });
    var onSelectTrip = function (id, checked) {
        if (checked) {
            setSelectedTripIds(function (prevState) { return __spreadArray(__spreadArray([], __read(prevState), false), [id], false); });
        }
        else {
            setSelectedTripIds(function (prevState) { return prevState.filter(function (el) { return el !== id; }); });
        }
    };
    var selectAeroexpress = function () {
        onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(selectedTripIds);
    };
    return (React.createElement(AdditionalServiceWrapper, { className: className, headerClassName: css.header, title: t('Aeroexpress'), info: React.createElement(React.Fragment, null,
            t('from'),
            " ",
            React.createElement(Money, { money: pricePerPerson }),
            " / ",
            t('per person')), text: React.createElement("a", { href: 'https://aeroexpress.ru', target: '_blank' }, t('Aeroexpress:View schedule')), onClose: onClose, onSuccess: selectAeroexpress, isLoading: isLoading },
        React.createElement("div", { className: css.trips }, trips.map(function (_a) {
            var id = _a.id, fareClass = _a.class, departureDate = _a.departureDate;
            var isSelected = !!(selectedTripIds === null || selectedTripIds === void 0 ? void 0 : selectedTripIds.find(function (el) { return el === id; }));
            return (React.createElement("div", { className: css.trip_item, key: id },
                React.createElement(FormControlLabel, { value: 'end', label: format(parse(departureDate, 'HH:mm dd.MM.yyyy', new Date()), 'dd.MM.yy'), control: React.createElement(Checkbox, { classes: {
                            root: css.checkbox,
                            checked: css.checkbox_checked
                        }, disableRipple: true, checked: isSelected, onChange: function (_a) {
                            var target = _a.target;
                            return onSelectTrip(id, target.checked);
                        } }) }),
                React.createElement(Select, { className: css.select, classes: {
                        root: css.select_root
                    }, disableUnderline: true, value: 0 },
                    React.createElement(MenuItem, { value: 0 },
                        React.createElement("span", { className: css.trip_class }, fareClass),
                        " -",
                        React.createElement(Money, { money: pricePerPerson }))),
                React.createElement("div", { className: css.trip_item_price }, isSelected && React.createElement(Money, { money: aeroexpressPrice }))));
        })),
        React.createElement("div", { className: css.total },
            t('Total for all passengers'),
            ":",
            React.createElement(Money, { money: totalPrice }))));
};
export default AeroexpressPopup;
