import { __assign, __read } from "tslib";
import * as React from 'react';
import Header from './components/Header/Header';
import FormFields from './components/FormFields/FormFields';
import { useTheme } from '../../theme';
import { getPassengerDataLabel, getPassengerLabel, requiredFillData } from '../utils';
import { useFormState, useForm } from 'react-final-form';
import PopupWrapper from './components/PopupWrapper/PopupWrapper';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../utils';
import { fieldsArrayToMap } from '../../Passenger/utils';
import WindowScope from './components/WindowScope/WindowScope';
import { Collapse } from '@material-ui/core';
import { OverrideComponent } from '../../renderProps';
var PassengerForm = function (props) {
    var theme = useTheme('PassengerForm').PassengerForm;
    var _a = useFormState(), values = _a.values, errors = _a.errors, submitFailed = _a.submitFailed;
    var mutators = useForm().mutators;
    var label = React.useMemo(function () {
        return getPassengerLabel(values.passengers[props.id], props.passengerSupplierType ? props.passengerSupplierType : props.passengerType);
    }, [props.fields, values]);
    var isFieldRequired = React.useMemo(function () { var _a; return requiredFillData((_a = values === null || values === void 0 ? void 0 : values.passengers) === null || _a === void 0 ? void 0 : _a[props.id], props.fields); }, [
        values,
        props.fields
    ]);
    var fieldsMap = React.useMemo(function () {
        return fieldsArrayToMap(props.fields);
    }, [props.fields]);
    var passengerFieldsMap = React.useMemo(function () {
        var _a;
        if (!isFieldRequired) {
            return getPassengerDataLabel((_a = values.passengers) === null || _a === void 0 ? void 0 : _a[props.id], fieldsMap);
        }
        return {};
    }, [isFieldRequired, values, fieldsMap]);
    React.useEffect(function () {
        if (!isFieldRequired) {
            props.onCorrectFilled(props.id);
        }
    }, [isFieldRequired]);
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _b = __read(React.useState(false), 2), open = _b[0], setOpen = _b[1];
    var onCloseHandler = function () { return setOpen(false); };
    var onOpenHandler = function () { return setOpen(true); };
    return (React.createElement("div", { className: theme.form },
        React.createElement(WindowScope, { fieldsMap: fieldsMap, disabled: props.isInputsReadonly || props.isReadonly },
            React.createElement(Header, { open: props.open, passengerId: props.id, label: label, onClick: onOpenHandler, correct: !isFieldRequired, passengerFieldsMap: passengerFieldsMap, renderRight: function () { return (React.createElement(React.Fragment, null,
                    React.createElement(OverrideComponent, { component: { PassengerControls: function () { return null; } }, componentProps: {
                            fields: props.fields,
                            passengerLabel: label,
                            id: props.id,
                            onFill: props.onFill,
                            values: values,
                            errors: errors,
                            isFormSubmitFailed: submitFailed,
                            mutators: __assign({ changePassengerField: window.changePassengerField }, mutators),
                            passengerType: props.passengerType
                        } }))); } }),
            React.createElement(Collapse, { in: props.open },
                React.createElement(PopupWrapper, { inline: !isMobile, open: open, onClose: onCloseHandler, correct: !isFieldRequired },
                    React.createElement(FormFields, { fields: props.fields, passengerId: props.id, passengerType: props.passengerType, label: label, inline: !isMobile, disabled: props.isInputsReadonly || props.isReadonly, onTravellerSelect: props.onFill }))))));
};
export default PassengerForm;
