import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
import Segment from './Segment/Segment';
import SingleFlightSegment from './SingleFlightSegment/SingleFlightSegment';
import FlightPrice from './FlightPrice/FlightPrice';
import DesktopFlightModalContent from '../../DesktopFlight/components/DesktopFlightModalContent/DesktopFlightModalContent';
import { useDesktopFlightWithPopup } from '../../DesktopFlight/components/DesktopFlightPopup/hooks';
import Modal from '../../Modal';
import { useMediaQuery } from 'react-responsive';
import { useMemo } from 'react';
import DesktopFlightModalContentSelected from '../../DesktopFlight/components/DesktopFlightModalContentSelected/DesktopFlightModalContentSelected';
import TotalDuration from './TotalDuration/TotalDuration';
import { useCallback } from 'react';
var DesktopFlightRoute = function (props) {
    var _a, _b, _c;
    var _d, _e;
    var theme = useTheme('DesktopFlightRoute').DesktopFlightRouteStyles;
    var popupCss = useTheme('DesktopFlight').DesktopFlightPopup;
    var isMaxHeightLte500 = useMediaQuery({ maxHeight: 500 });
    var flight = props.flight, isRecommended = props.isRecommended, isSelected = props.isSelected, singleFlight = props.singleFlight, selectedFares = props.selectedFares, canceled = props.canceled, searchParameters = props.searchParameters, priceRenderer = props.renderPrice;
    var _f = useDesktopFlightWithPopup(props), openModal = _f.openModal, isModalOpen = _f.isModalOpen, lastSelectedFareId = _f.lastSelectedFare, closeModal = _f.closeModal, businessFareGroups = _f.businessFareGroups, economyFareGroups = _f.economyFareGroups, milesFareGroups = _f.milesFareGroups, getOnFareSelect = _f.getOnFareSelect, upgradeOptions = _f.upgradeOptions;
    var renderPrice = function (props) {
        return priceRenderer ? priceRenderer(props) : React.createElement(FlightPrice, __assign({}, props));
    };
    var lastSegment = flight.segments[flight.segments.length - 1].segment;
    var renderTotalDuration = useCallback(function () { return React.createElement(TotalDuration, { lastSegment: lastSegment, flight: flight }); }, [
        lastSegment,
        flight
    ]);
    var lastSelectedFare = useMemo(function () {
        var _a;
        return (_a = props.flight.pricesForFareGroups.find(function (fareGroup) { return fareGroup.fareFamily && fareGroup.fareFamily.id === lastSelectedFareId; })) === null || _a === void 0 ? void 0 : _a.fareFamily;
    }, [props.flight.pricesForFareGroups, lastSelectedFareId]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(theme.flight, (_a = {},
                _a[theme.flight_readonly] = props.readonly,
                _a[theme.flight_canceled] = canceled,
                _a)), onClick: !props.readonly ? openModal : undefined },
            React.createElement("div", { className: cn(theme.segments, (_b = {}, _b[theme.segments_withoutPrices] = props.hidePrice, _b)) },
                flight.segments.map(function (segment, key) {
                    return singleFlight ? (React.createElement(SingleFlightSegment, { segment: segment, key: segment.segment.id, first: key === 0, last: key === flight.segments.length - 1, stops: key > 0 ? flight.segments[key - 1].segment.stops : [], singleFlight: singleFlight, totalDuration: key === flight.segments.length - 1 && renderTotalDuration() })) : (React.createElement(Segment, { segment: segment, key: segment.segment.id, first: key === 0, last: key === flight.segments.length - 1, stops: key > 0 ? flight.segments[key - 1].segment.stops : [], singleFlight: singleFlight, totalDuration: key === flight.segments.length - 1 && renderTotalDuration(), nextDay: key >= 1 &&
                            flight.segments[key - 1].segment.departure.date !== segment.segment.arrival.date }));
                }),
                (flight.segments.length > 1 || ((_d = lastSegment.stops) === null || _d === void 0 ? void 0 : _d.length) > 0) && (React.createElement("div", { className: theme.duration }))),
            !props.hidePrice &&
                renderPrice({
                    best: isRecommended,
                    singleFlight: singleFlight,
                    flight: flight,
                    selectedFares: selectedFares,
                    isSelected: isSelected,
                    canceled: props.canceled
                })),
        React.createElement(Modal, { isRounded: true, fullScreen: isMaxHeightLte500, scroll: 'paper', classes: {
                paper: cn(popupCss.paper, (_c = {}, _c[popupCss.paper_fullScreen] = isMaxHeightLte500, _c)),
                scrollBody: popupCss.scrollBody
            }, open: isModalOpen, onClose: closeModal }, isSelected ? (React.createElement(DesktopFlightModalContentSelected, { flightId: (_e = flight.pricesForFareGroups.find(function (price) { return (price === null || price === void 0 ? void 0 : price.fareFamily.id) === (lastSelectedFare === null || lastSelectedFare === void 0 ? void 0 : lastSelectedFare.id); })) === null || _e === void 0 ? void 0 : _e.prices[0].flight.id, lastSelectedFare: lastSelectedFare, flight: flight })) : (React.createElement(DesktopFlightModalContent, { lastSelectedFare: lastSelectedFare, flight: flight, economyFares: economyFareGroups, businessFares: businessFareGroups, milesFares: milesFareGroups, getOnFareSelect: getOnFareSelect, upgradeOptions: upgradeOptions, hidePrice: props.hidePrice, searchParameters: searchParameters })))));
};
export default DesktopFlightRoute;
