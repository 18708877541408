import * as React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useTranslation } from 'react-i18next';
import { LoginType } from '../../../../__generated__/globalTypes';
import { useTheme } from '../../../../theme';
var phoneIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("mask", { id: "mask0", mask: "alpha", maskUnits: "userSpaceOnUse", x: "3", y: "3", width: "18", height: "18" },
        React.createElement("path", { d: "M6.62 10.79C8.06 13.62 10.38 15.93 13.21 17.38L15.41 15.18C15.68 14.91 16.08 14.82 16.43 14.94C17.55 15.31 18.76 15.51 20 15.51C20.55 15.51 21 15.96 21 16.51V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" },
        React.createElement("rect", { width: "24", height: "24", fill: "black", fillOpacity: "0.54" }))));
var emailIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4ZM12 13L20 8V6L12 11L4 6V8L12 13Z", fill: "#777777" })));
var Alternative = function (_a) {
    var loginType = _a.loginType, onSelect = _a.onSelect;
    var t = useTranslation('LoginPage').t;
    var css = useTheme('LoginPage').Alternative;
    var isPhoneMode = loginType === LoginType.Phone;
    return (React.createElement("div", { className: css.wrapper },
        React.createElement(ButtonBase, { className: css.item, onClick: function () { return onSelect(isPhoneMode ? LoginType.Email : LoginType.Phone); } },
            React.createElement("div", { className: css.icon }, isPhoneMode ? emailIcon : phoneIcon),
            t(isPhoneMode ? 'Use email' : 'Use phone'))));
};
export default Alternative;
