import React, { useMemo } from 'react';
import cn from 'classnames';
import Nationality from '../../../../../../Passenger/components/Field/Nationality/Nationality';
import { ThemeProvider, useTheme } from '../../../../../../theme';
export var NationalityWrapper = function (props) {
    var theme = useTheme('DocRecognition').NationalityWrapper;
    var autoCompleteStyles = useMemo(function () {
        var _a, _b;
        return ({
            Autocomplete: {
                Autocomplete: {
                    inner_input: cn((_a = {},
                        _a[theme.success] = props.success,
                        _a[theme.warning] = props.warning,
                        _a)),
                    autocomplete: theme.autocomplete
                }
            },
            Input: {
                Input: {
                    label_shrink: cn((_b = {},
                        _b[theme.labelShrink_success] = props.success,
                        _b[theme.labelShrink_warning] = props.warning,
                        _b))
                }
            }
        });
    }, [props.success, props.warning]);
    var options = useMemo(function () { return props.field.options.map(function (option) { return ({ label: option.value, value: option.key }); }); }, [
        props.field.options
    ]);
    return (React.createElement(ThemeProvider, { value: autoCompleteStyles },
        React.createElement(Nationality, { label: props.label, disabled: props.disabled, options: options, field: props.field, fieldState: props.fieldState })));
};
