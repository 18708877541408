import { __awaiter, __generator, __read, __spreadArray, __values } from "tslib";
import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import * as HostCommandQuery from '../../../__queries__/HostCommand.graphql';
import { NOT_AUTHENTICATED_ERROR_MESSAGE, NOT_AUTHORIZED_TYPE } from '../../sessionErrorLink';
import { useTranslation } from 'react-i18next';
var HostAdapter = function (_a) {
    var Component = _a.component, onClose = _a.onClose;
    var t = useTranslation('Terminal').t;
    var _b = __read(useMutation(HostCommandQuery.HostCommand), 1), request = _b[0];
    var sendRequestHandler = function (data, values) { return __awaiter(void 0, void 0, Promise, function () {
        var response, e_1, message, errors, hasGraphQLError;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request({
                            variables: {
                                params: JSON.stringify({
                                    request: data,
                                    values: values
                                })
                            }
                        })];
                case 1:
                    response = _b.sent();
                    if (response.errors) {
                        return [2 /*return*/, {
                                message: (_a = response.errors[0]) === null || _a === void 0 ? void 0 : _a['debugMessage'],
                                error: true
                            }];
                    }
                    else {
                        return [2 /*return*/, parseMessage(response.data.HostCommand)];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _b.sent();
                    message = void 0;
                    errors = (e_1 === null || e_1 === void 0 ? void 0 : e_1.graphQLErrors) || [];
                    hasGraphQLError = errors.some(function (error) { return error.category === 'graphql'; });
                    if (hasGraphQLError) {
                        message = errors.filter(function (error) { return error.category === 'graphql'; }).map(function (error) { return error.message; });
                    }
                    else {
                        message = errors.map(function (error) { return "GraphQL error: ".concat(error.message); });
                    }
                    if (e_1.message.includes(NOT_AUTHENTICATED_ERROR_MESSAGE) ||
                        e_1.message.includes(NOT_AUTHORIZED_TYPE) ||
                        e_1.message.includes('Access denied')) {
                        setTimeout(function () { return window.location.reload(); });
                    }
                    if (!errors.length) {
                        message = [t('May be some connection problems. Check your network settings.')];
                    }
                    return [2 /*return*/, {
                            message: message.join('\n'),
                            error: true
                        }];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var parseMessage = function (message) {
        var e_2, _a;
        try {
            var parsedJSON = JSON.parse(message);
            var response = {};
            try {
                for (var _b = __values(Object.keys(parsedJSON)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var key = _c.value;
                    switch (key) {
                        case 'text':
                            response['message'] = parsedJSON['text'];
                            break;
                        case 'form':
                            response['message'] = message;
                            response['json'] = true;
                            break;
                        case 'extras':
                            response['message'] = parsedJSON['text'];
                            response['extras'] = parsedJSON['extras'];
                            break;
                        default:
                            throw 'unknown property';
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
            return response;
        }
        catch (e) {
            console.error(e);
            return {
                message: message,
                error: true
            };
        }
    };
    var queryTimeout = function (query, timeout) {
        if (timeout === void 0) { timeout = 120000; }
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                var timer, value, e_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            timer = setTimeout(function () {
                                resolve({
                                    message: t('Request performing took too much time, there may be some connection problems.'),
                                    error: true
                                });
                            }, timeout);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, query.apply(void 0, __spreadArray([], __read(args), false))];
                        case 2:
                            value = _a.sent();
                            resolve(value);
                            return [3 /*break*/, 5];
                        case 3:
                            e_3 = _a.sent();
                            reject(e_3);
                            return [3 /*break*/, 5];
                        case 4:
                            clearTimeout(timer);
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            }); });
        };
    };
    return React.createElement(Component, { sendRequest: queryTimeout(sendRequestHandler), onClose: onClose });
};
export default HostAdapter;
