import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { getSelectedBaggage } from '../Checkout/store/passengers/selectors';
import { ModeContext, useConfig } from '../context';
import { getBaggageServices as getBaggageServicesCheckout, getSegments } from '../Checkout/store/order/selectors';
import { Mode } from '../types';
import { getBaggageServices } from '../Modules/Checkin/store/order/selectors';
import { BaggageType } from '../__generated__/globalTypes';
export var useBaggageServiceBlock = function (passengerId) {
    var selectedBaggage = useSelector(getSelectedBaggage);
    var segments = useSelector(getSegments);
    var mode = useContext(ModeContext);
    var baggageServices;
    if (mode === Mode.Checkin) {
        baggageServices = useSelector(getBaggageServices);
    }
    else {
        baggageServices = useSelector(getBaggageServicesCheckout);
    }
    var excessBaggageServicesIds = baggageServices
        .filter(function (baggage) {
        return baggage.baggageType === BaggageType.HandLuggageExcess ||
            baggage.baggageType === BaggageType.BaggageExcess;
    })
        .map(function (excessBaggage) { return excessBaggage.id; });
    // map of selectedBaggage count on each segment by id
    var selectedBaggageCountBySegments = new Map();
    // map that shows is adding available for segment
    var canAddBaggageBySegments = new Map();
    var maxBaggagePerPassenger = useConfig().global.maxBaggagePerPassenger;
    // init map
    segments.forEach(function (segment) {
        selectedBaggageCountBySegments.set(segment.id, 0);
        canAddBaggageBySegments.set(segment.id, true);
    });
    var passengerBaggage = selectedBaggage[parseInt(passengerId, 10)];
    // update map with already selected baggage excluding excess services
    Object.keys(passengerBaggage)
        .filter(function (passengerBaggageId) { return !excessBaggageServicesIds.includes(passengerBaggageId); })
        .forEach(function (baggageId) {
        var baggageServices = passengerBaggage[baggageId];
        Object.keys(baggageServices).forEach(function (segmentId) {
            var baggage = baggageServices[segmentId];
            var alreadySelectedCount = selectedBaggageCountBySegments.get(segmentId);
            selectedBaggageCountBySegments.set(segmentId, alreadySelectedCount + baggage.count);
        });
    });
    // update availability map
    selectedBaggageCountBySegments.forEach(function (count, segmentId) {
        return canAddBaggageBySegments.set(segmentId, count < maxBaggagePerPassenger);
    });
    return canAddBaggageBySegments;
};
export var useAdditionalBaggageConfirmedMap = function (baggageServices, selectedBaggage) {
    var ADDITIONAL_BAGGAGE_SERVICES_TYPES = [
        BaggageType.SportingEquipment,
        BaggageType.BaggageExcess,
        BaggageType.HandLuggageExcess
    ];
    var additionalBaggages = baggageServices.filter(function (baggage) {
        return ADDITIONAL_BAGGAGE_SERVICES_TYPES.includes(baggage.type);
    });
    var additionalBaggageConfirmedMap = new Map();
    Object.values(selectedBaggage).forEach(function (segmentsBaggageMap) {
        return Object.values(segmentsBaggageMap).forEach(function (selectedBaggageItem) {
            var selectedAdditionalBaggageService = additionalBaggages.find(function (additionalBaggage) { return additionalBaggage.id === selectedBaggageItem.serviceId; });
            if (selectedAdditionalBaggageService &&
                !additionalBaggageConfirmedMap.has(selectedAdditionalBaggageService.type) &&
                selectedBaggageItem.confirmedCount > 0) {
                additionalBaggageConfirmedMap.set(selectedAdditionalBaggageService.type, true);
            }
        });
    });
    var hasConfirmedSportsEquipment = !!additionalBaggageConfirmedMap.get(BaggageType.SportingEquipment);
    var hasConfirmedBaggageExcess = !!additionalBaggageConfirmedMap.get(BaggageType.BaggageExcess);
    var hasConfirmedHandLuggageExcess = !!additionalBaggageConfirmedMap.get(BaggageType.HandLuggageExcess);
    var hasConfirmedPetInCabin = !!additionalBaggageConfirmedMap.get(BaggageType.PetInCabin);
    var hasConfirmedPetInHold = !!additionalBaggageConfirmedMap.get(BaggageType.PetInHold);
    return {
        hasConfirmedSportsEquipment: hasConfirmedSportsEquipment,
        hasConfirmedBaggageExcess: hasConfirmedBaggageExcess,
        hasConfirmedHandLuggageExcess: hasConfirmedHandLuggageExcess,
        hasConfirmedPetInCabin: hasConfirmedPetInCabin,
        hasConfirmedPetInHold: hasConfirmedPetInHold,
        additionalBaggageConfirmedMap: additionalBaggageConfirmedMap
    };
};
