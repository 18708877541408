import { __assign, __read } from "tslib";
import * as React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import FareConditions from './FareConditions';
import { useGraphQLClient } from '../../graphql-client';
import { Locale } from '../../types';
import { getLocale } from '../../utils';
var LOCALE_TO_LANG_MAP = new Map([
    [Locale.English, 'en'],
    [Locale.Russian, 'ru'],
    [Locale.Kazakh, 'ru']
]);
var Component = function (props) {
    var client = useGraphQLClient();
    var engineLang = getLocale();
    var _a = __read(React.useState(function () {
        if (LOCALE_TO_LANG_MAP.has(engineLang)) {
            return LOCALE_TO_LANG_MAP.get(engineLang);
        }
        else {
            return Locale.English;
        }
    }), 2), currentLang = _a[0], changeLangTo = _a[1];
    var handleChange = function (lang) {
        if (LOCALE_TO_LANG_MAP.has(lang)) {
            changeLangTo(lang);
        }
        else {
            changeLangTo(Locale.English);
        }
    };
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(FareConditions, __assign({ onLangChange: handleChange, language: currentLang }, props))));
};
export default Component;
