import { TravellerFieldEnum } from '../../../../__generated__/graphql';
import { getPassengerName } from '../../../../utils';
export var orderTravellerOrderCouponAdapter = function (traveller) {
    var travellerName = traveller.values.find(function (value) { return value.type === TravellerFieldEnum.FirstName; }).value;
    var travellerLastName = traveller.values.find(function (value) { return value.type === TravellerFieldEnum.LastName; }).value;
    var name = getPassengerName(travellerName, travellerLastName);
    return {
        tickets: traveller.tickets,
        emds: traveller.emds,
        name: name
    };
};
