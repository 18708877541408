import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { useTheme } from '../../../../theme';
import { Money } from '../../../../Money';
import { Included } from '../../../../Icons';
import { collectExchangeVoidServices, groupByEmd, groupSumToRefundVoidByEmd, isExchangeVoidServicesEmpty, passengerNameById, segmentBySegmentId } from '../../utils';
import { ServiceCardContext } from '../../ServicesCard';
import CheckboxField from '../CheckboxField/CheckboxField';
import { Steps } from '../../../ServicesCardVoid/types';
import { GdsServiceProductStatus } from '../../../../__generated__/globalTypes';
import AdditionalServicePrice from '../AdditionalServicePrice/AdditionalServicePrice';
import TotalVoidRefund from '../TotalVoidRefund/TotalVoidRefund';
import { AviaPassengerType } from '../../../../__generated__/globalTypes';
var Seats = function (props) {
    var passengers = props.order.travellers;
    var theme = useTheme('ServicesCard').ServicesCard;
    var t = useTranslation('ServiceCard').t;
    var groupedByEmd = useMemo(function () {
        if (props.exchangeVoidMode) {
            return groupByEmd(props.services);
        }
        else {
            return null;
        }
    }, [props.services, props.exchangeVoidMode]);
    useEffect(function () {
        if (props.exchangeVoidMode && props.step === Steps.Select) {
            props.setProductsIdsByEmd(groupedByEmd.productsByEmdMap);
        }
    }, [props.exchangeVoidMode, props.step]);
    var sumToRefundVoidByEmd = useMemo(function () {
        if (props.exchangeVoidMode && props.step === Steps.Confirm) {
            return groupSumToRefundVoidByEmd(props.calculation);
        }
        return null;
    }, [props.calculation, props.step, props.exchangeVoidMode]);
    var renderIncludedService = useCallback(function (service, index) {
        var serviceInfo = service.service;
        return (React.createElement("div", { key: "".concat(serviceInfo.name, "_").concat(index), className: theme.serviceItem },
            React.createElement("div", null,
                segmentBySegmentId(service.segmentIds[0], props.order),
                ", ",
                serviceInfo.name),
            React.createElement("div", { className: theme.serviceItem__check }, Included)));
    }, [props.services, props.order]);
    var renderAdditionalServices = useCallback(function (services, segmentId, passengerId) {
        return services.map(function (service) {
            var _a;
            var isProductForVoidOrExchange = ((_a = service.product) === null || _a === void 0 ? void 0 : _a.status) === GdsServiceProductStatus.Ticketed;
            var isVoidOrExchangeAllowed = props.exchangeVoidMode && isProductForVoidOrExchange;
            var serviceName = "".concat(segmentBySegmentId(segmentId, props.order), ", ").concat(service.row).concat(service.letter);
            return (React.createElement("div", { key: "".concat(service.row).concat(service.letter), className: theme.serviceItem },
                props.exchangeVoidMode && React.createElement("span", null, serviceName),
                !isVoidOrExchangeAllowed && (React.createElement(React.Fragment, null,
                    React.createElement("span", null, serviceName),
                    React.createElement("div", { className: theme.priceWrapper }, service.isConfirmed ? (React.createElement("div", { className: theme.serviceItem__check }, Included)) : (React.createElement(AdditionalServicePrice, { service: service, passengerId: passengerId, price: service.seat.price, type: 'seat' })))))));
        });
    }, [props.services, props.order, props.isLoading, props.step]);
    var linkedAdultInfs = useMemo(function () {
        var linkedAdultInfsMap = new Map();
        var passengersByIdMap = new Map();
        Object.entries(passengers).forEach(function (_a) {
            var _b = __read(_a, 2), id = _b[0], passenger = _b[1];
            passengersByIdMap.set(id, passenger);
        });
        Object.keys(props.services).forEach(function (passengerId) {
            var passenger = passengersByIdMap.get(passengerId);
            if (passenger.type === AviaPassengerType.INF && passenger.linkedTraveller !== undefined) {
                var adult = passengersByIdMap.get(passenger.linkedTraveller.id);
                var link = linkedAdultInfsMap.get(adult.id) || [];
                linkedAdultInfsMap.set(adult.id, __spreadArray(__spreadArray([], __read(link), false), [passenger.id], false));
            }
        });
        return linkedAdultInfsMap;
    }, [props.services]);
    var exchangeVoidServices = useMemo(function () {
        return collectExchangeVoidServices(props.calculation, props.servicesIds);
    }, [props.calculation]);
    var renderPassengerServices = useCallback(function (passengerId, segmentServices) {
        var _a;
        if (!((_a = Object.keys(props.services[passengerId])) === null || _a === void 0 ? void 0 : _a.length)) {
            return null;
        }
        return (React.createElement("div", { key: "".concat(passengerId) },
            React.createElement("div", { className: theme.passengerSegmentWrapper },
                React.createElement("div", { className: theme.passengerSegment },
                    React.createElement("span", { className: theme.passengerSegment__name }, passengerNameById(passengerId, passengers, linkedAdultInfs.get(passengerId)))),
                Object.entries(segmentServices).map(function (_a) {
                    var _b;
                    var _c = __read(_a, 2), segmentId = _c[0], services = _c[1];
                    return (React.createElement("div", { key: segmentId }, ((_b = services.additional) === null || _b === void 0 ? void 0 : _b.length) > 0
                        ? renderAdditionalServices(services.additional, segmentId, passengerId)
                        : services.included.map(renderIncludedService)));
                }))));
    }, [props.services, props.order, props.isLoading, props.step]);
    var renderRefundVoid = function (voidRefundServices, name) {
        var _a;
        if (!((_a = Object.keys(voidRefundServices)) === null || _a === void 0 ? void 0 : _a.length) || props.step !== Steps.Confirm) {
            return null;
        }
        return (React.createElement("div", null,
            React.createElement("div", { className: theme.refundVoid }, name),
            renderGroupByEmdServices(voidRefundServices)));
    };
    var renderGroupByEmdServices = useCallback(function (voidRefundServices) {
        return (React.createElement("div", { className: theme.list }, Object.entries(groupedByEmd === null || groupedByEmd === void 0 ? void 0 : groupedByEmd.servicesByEmd).map(function (_a) {
            var _b = __read(_a, 2), emd = _b[0], emdServices = _b[1];
            if (props.step === Steps.Confirm && !voidRefundServices.hasOwnProperty(emd)) {
                return null;
            }
            return (React.createElement("div", { key: emd },
                props.step === Steps.Select ? (React.createElement(CheckboxField, { name: emd, isLoading: props.isLoading })) : (React.createElement("div", { className: theme.emdRefundVoid },
                    React.createElement("div", null,
                        "EMD: ",
                        emd),
                    React.createElement(Money, { money: sumToRefundVoidByEmd.get(emd) }))),
                Object.entries(emdServices).map(function (_a) {
                    var _b = __read(_a, 2), passengerId = _b[0], segmentsServices = _b[1];
                    return (React.createElement("div", { key: passengerId, className: theme.passengerEmdWrapper }, renderPassengerServices(passengerId, segmentsServices)));
                })));
        })));
    }, [groupedByEmd === null || groupedByEmd === void 0 ? void 0 : groupedByEmd.servicesByEmd, props.isLoading]);
    var isConfirmStepOnExchangeVoidMode = props.exchangeVoidMode && props.step === Steps.Confirm;
    if (isConfirmStepOnExchangeVoidMode && isExchangeVoidServicesEmpty(exchangeVoidServices)) {
        return (React.createElement("div", { className: theme.emptyVoidRefundText }, t('You have not set any services for refund calculating in this category')));
    }
    return (React.createElement("div", null,
        props.exchangeVoidMode && props.step === Steps.Confirm && (React.createElement(React.Fragment, null,
            renderRefundVoid(exchangeVoidServices.servicesForRefund, t('Refund')),
            React.createElement(TotalVoidRefund, { type: 'refund', refundVoidServices: exchangeVoidServices }),
            renderRefundVoid(exchangeVoidServices.servicesForVoid, t('Void')),
            React.createElement(TotalVoidRefund, { type: 'void', refundVoidServices: exchangeVoidServices }))),
        props.exchangeVoidMode && props.step === Steps.Select && renderGroupByEmdServices(null),
        !props.exchangeVoidMode && (React.createElement("div", null, Object.entries(props.services).map(function (_a) {
            var _b = __read(_a, 2), passengerId = _b[0], segmentsServices = _b[1];
            return Object.keys(segmentsServices).length > 0 && (React.createElement("div", { key: passengerId }, renderPassengerServices(passengerId, segmentsServices)));
        })))));
};
var SeatsCardWithConsumer = function (props) {
    return (React.createElement(ServiceCardContext.Consumer, null, function (contextProps) {
        return React.createElement(Seats, __assign({}, props, contextProps));
    }));
};
export default SeatsCardWithConsumer;
