import { TravellerFieldEnum } from '../../__generated__/graphql';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../utils';
import { useMemo } from 'react';
initI18n('Passenger');
var isWithRuNationality = function (withCondition) {
    return withCondition.name === 'nationality' && withCondition.value === 'RU';
};
export var useNationalityFieldOptions = function (traveller) {
    var t = useTranslation('Passenger').t;
    return traveller.values
        .find(function (travellerValue) { return travellerValue.type === TravellerFieldEnum.Nationality; })
        .validationRules[0].options.map(function (option) { return ({ key: option.value, value: t(option.label) }); });
};
export var useDocTypeFieldOptions = function (traveller) {
    var t = useTranslation('Passenger').t;
    var convertDocOptionObject = function (option) { return ({
        key: option.value,
        value: t(option.label)
    }); };
    return useMemo(function () {
        var docTypeValidationRules = traveller.values.find(function (travellerValue) { return travellerValue.type === TravellerFieldEnum.DocType; }).validationRules;
        var optionsByNationality = new Map();
        docTypeValidationRules.forEach(function (validation) {
            var _a;
            if ((_a = validation.with) === null || _a === void 0 ? void 0 : _a.some(isWithRuNationality)) {
                optionsByNationality.set('RU', validation.options.map(convertDocOptionObject));
            }
            else {
                optionsByNationality.set('OTHER', validation.options.map(convertDocOptionObject));
            }
        });
        return {
            RU: optionsByNationality.get('RU'),
            OTHER: optionsByNationality.get('OTHER')
        };
    }, [traveller.values]);
};
