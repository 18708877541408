import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { useServicePageProps } from '../hooks';
import { useUpsaleWarning } from '../../../Checkout/components/Checkout/Upsale/hooks';
import { ServiceType } from '../../../Checkout/components/Checkout/OrderPaidServiceCards/types';
import { useSelector } from 'react-redux';
import { getBaggageMinPrice, getIncludedBaggage, getSegments, getServices, getServicesFallbackURL, shouldRedirectToFallback } from '../../../Checkout/store/order/selectors';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import ServicePage from '../../../Checkout/components/Checkout/ServicePage/ServicePage';
import ServiceCardDialog from '../../../Checkout/components/Checkout/OrderPaidServiceCards/components/ServiceCardDialog/ServiceCardDialog';
import { BaggageType, OrderAdditionalServiceGdsServiceServiceType } from '../../../__generated__/globalTypes';
import { convertBaggageObject } from '../../../Checkout/types';
import { useTranslation } from 'react-i18next';
import { getBaggagePrice } from '../../../Checkout/store/orderPrice/selectors';
import { useTheme } from '../../../theme';
import icons from '../../icons';
import { getPassengerStateCompatible } from '../../../Checkout/store/selectedServices/selectors';
var BaggageAdditionalService = function () {
    var t = useTranslation('Checkout').t;
    var minPrice = useSelector(getBaggageMinPrice);
    var issuedPrice = useSelector(getBaggagePrice);
    var includedBaggage = useSelector(getIncludedBaggage);
    var _a = useTheme('CheckoutAdditionalService'), css = _a.CheckoutAdditionalServiceBaggage, commonStyles = _a.CheckoutAdditionalService;
    var servicePageProps = useServicePageProps();
    var servicesRedirectURL = useSelector(getServicesFallbackURL);
    var shouldRedirect = useSelector(shouldRedirectToFallback);
    var _b = useUpsaleWarning(), showWarning = _b.showWarning, closeWarning = _b.closeWarning, toggleWarningIfOrderConfirmed = _b.toggleWarningIfOrderConfirmed;
    var handleClick = shouldRedirect
        ? toggleWarningIfOrderConfirmed
        : servicePageProps.toggleModal.bind(null, ServiceType.Baggage);
    var segments = useSelector(getSegments);
    var orderServices = useSelector(getServices);
    var passengers = useSelector(getPassengerStateCompatible);
    var servicesBySegments = new Map();
    segments.map(function (segment) {
        passengers.forEach(function (passenger) {
            passenger.services.forEach(function (passengerService) {
                var relatedService = orderServices.find(function (orderService) { return orderService.id === passengerService.serviceId; });
                if (relatedService.type === OrderAdditionalServiceGdsServiceServiceType.Baggage) {
                    if (passengerService.segmentIds.includes(segment.id)) {
                        var convertedService_1 = __assign(__assign({}, convertBaggageObject(relatedService)), { count: passengerService.count });
                        if (servicesBySegments.has(segment)) {
                            var selectedServices = servicesBySegments.get(segment);
                            if (selectedServices.some(function (selectedService) { return selectedService.id === convertedService_1.id; })) {
                                servicesBySegments.set(segment, selectedServices.map(function (service) {
                                    return service.id === convertedService_1.id
                                        ? __assign(__assign({}, service), { count: service.count += convertedService_1.count }) : service;
                                }));
                            }
                            else {
                                servicesBySegments.set(segment, servicesBySegments.get(segment).concat(convertedService_1));
                            }
                        }
                        else {
                            servicesBySegments.set(segment, [convertedService_1]);
                        }
                    }
                }
            });
        });
    });
    // included baggage
    segments.forEach(function (segment) {
        includedBaggage.forEach(function (includedItem) {
            if (!includedItem.includedSegments.includes(segment.id)) {
                return;
            }
            var servicesOnSegment = servicesBySegments.get(segment);
            if (includedItem) {
                if (servicesOnSegment) {
                    servicesBySegments.set(segment, __spreadArray(__spreadArray([], __read(servicesBySegments.get(segment)), false), [
                        __assign(__assign({}, includedItem), { count: passengers.length })
                    ], false));
                }
                else {
                    servicesBySegments.set(segment, [__assign(__assign({}, includedItem), { count: passengers.length })]);
                }
            }
        });
    });
    var isSelected = !!servicesBySegments.size;
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckoutAdditionalService, { addClick: showWarning ? toggleWarningIfOrderConfirmed : handleClick, buttons: [{ text: t('Change'), action: servicePageProps.toggleModal.bind(null, ServiceType.Baggage) }], icon: icons.baggageIcon, className: css.baggage, priceFrom: minPrice, isSelected: isSelected, header: t('Baggage'), description: t('Transportation of impressions no limitations'), priceTotal: issuedPrice.amount ? issuedPrice : null },
            React.createElement("ul", { className: commonStyles.list }, __spreadArray([], __read(servicesBySegments.keys()), false).map(function (segment) { return (React.createElement("li", { className: commonStyles.list__item, key: segment.id },
                React.createElement("span", { className: commonStyles.list__cities },
                    segment.departure.airport.city.name,
                    " - ",
                    segment.arrival.airport.city.name),
                React.createElement("span", { className: commonStyles.list__services }, __spreadArray([], __read(servicesBySegments.get(segment)), false).map(function (baggageItem, index) { return (React.createElement(React.Fragment, null,
                    baggageItem.type === BaggageType.HandLuggageExcess ? (React.createElement(React.Fragment, null, baggageItem.size)) : (React.createElement(React.Fragment, null,
                        baggageItem.count,
                        "x",
                        baggageItem.value.amount,
                        baggageItem.value.measurement)),
                    index + 1 !== servicesBySegments.get(segment).length && React.createElement(React.Fragment, null, ","),
                    "\u00A0")); })))); }))),
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        React.createElement(ServicePage, null, function (childrenProps) { return (React.createElement(ServiceCardDialog, __assign({ order: servicePageProps.order, openedModal: servicePageProps.openedModal, onClose: servicePageProps.toggleModal.bind(null, null), passengers: servicePageProps.passengers, passengerLabels: servicePageProps.passengerLabels }, childrenProps))); })));
};
export default BaggageAdditionalService;
