import * as React from 'react';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
var noticeIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.66602 10.0003C1.66602 5.40033 5.39935 1.66699 9.99935 1.66699C14.5994 1.66699 18.3327 5.40033 18.3327 10.0003C18.3327 14.6003 14.5994 18.3337 9.99935 18.3337C5.39935 18.3337 1.66602 14.6003 1.66602 10.0003ZM10.8327 9.16699V14.167H9.16602V9.16699H10.8327ZM9.99934 16.667C6.32434 16.667 3.33267 13.6753 3.33267 10.0003C3.33267 6.32532 6.32434 3.33366 9.99934 3.33366C13.6743 3.33366 16.666 6.32532 16.666 10.0003C16.666 13.6753 13.6743 16.667 9.99934 16.667ZM10.8327 5.83366V7.50032H9.16602V5.83366H10.8327Z", fill: "#B3B3B3" })));
var timeIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.99102 1.66699C5.39102 1.66699 1.66602 5.40033 1.66602 10.0003C1.66602 14.6003 5.39102 18.3337 9.99102 18.3337C14.5993 18.3337 18.3327 14.6003 18.3327 10.0003C18.3327 5.40033 14.5993 1.66699 9.99102 1.66699ZM9.99935 16.667C6.31602 16.667 3.33268 13.6837 3.33268 10.0003C3.33268 6.31699 6.31602 3.33366 9.99935 3.33366C13.6827 3.33366 16.666 6.31699 16.666 10.0003C16.666 13.6837 13.6827 16.667 9.99935 16.667ZM9.16602 5.83366H10.416V10.2087L14.166 12.4337L13.541 13.4587L9.16602 10.8337V5.83366Z", fill: "#B3B3B3" })));
var Disclaimer = function (_a) {
    var type = _a.type;
    var theme = useTheme('Exchange').Disclaimer;
    var t = useTranslation('Exchange').t;
    return (React.createElement("div", { className: theme.wrapper },
        type === 'time' ? timeIcon : noticeIcon,
        React.createElement("span", null, type === 'price'
            ? t('The cost of changes will be calculated by the operator after choosing the option and sending the request to the operator.')
            : t('Local time indicated'))));
};
export default Disclaimer;
