import { BaggageSize } from './types';
import { useMemo } from 'react';
import { BaggageType } from '../../__generated__/globalTypes';
export var useBaggageSize = function (baggage) {
    return useMemo(function () {
        var weight = baggage.value.amount;
        if (baggage.type === BaggageType.CarryOn || baggage.type === BaggageType.HandLuggageExcess) {
            return weight > 5 ? BaggageSize.large : BaggageSize.small;
        }
        else if (baggage.type === BaggageType.CheckedBaggage) {
            if (weight > 20) {
                return BaggageSize.large;
            }
            else if (weight > 15 && weight <= 20) {
                return BaggageSize.middle;
            }
            else {
                return BaggageSize.small;
            }
        }
        else if (baggage.type === BaggageType.BaggageExcess) {
            if (weight <= 5) {
                return BaggageSize.small;
            }
            else if (weight > 15 && weight <= 30) {
                return BaggageSize.middle;
            }
            else {
                return BaggageSize.large;
            }
        }
        return null;
    }, [baggage]);
};
