import { __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { passengerIcon, removeIcon } from '../Icons';
import { TravellerFieldEnum } from '../../__generated__/globalTypes';
import { decapitalizeFirstLetter, initI18n } from '../../utils';
import MediaQuery from '../../MediaQuery/MediaQuery';
import { hideText } from '../../Passenger/utils';
import LoyaltyNumber from './LoyaltyNumber/LoyaltyNumber';
import Link from '../../Link';
initI18n('SelectedPassenger');
var SelectedPassenger = function (_a) {
    var passengerValues = _a.passenger, onDelete = _a.onDelete, onSaveLoyalty = _a.onSaveLoyalty;
    var _b = __read(React.useState(false), 2), open = _b[0], setOpen = _b[1];
    var onOpen = function () { return setOpen(true); };
    var onClose = function () { return setOpen(false); };
    var css = useTheme('SelectedPassenger').SelectedPassenger;
    var t = useTranslation('SelectedPassenger').t;
    var passenger = {};
    var nationalities = new Map();
    var handleRemove = function () { return onDelete && onDelete(passengerValues); };
    var onSaveLoyaltyHandler = function (code) {
        onSaveLoyalty(code, passengerValues);
        onClose();
    };
    passengerValues.values.forEach(function (value) {
        passenger[decapitalizeFirstLetter(value.type)] = value.value;
        if (value.type === TravellerFieldEnum.Nationality) {
            value.validationRules[0].options.forEach(function (nationality) {
                nationalities.set(nationality.value, nationality.label);
            });
        }
    });
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.passenger },
            React.createElement(MediaQuery, { minWidth: "mobile" },
                React.createElement("span", { className: css.passenger__icon }, passengerIcon)),
            React.createElement("span", { className: css.passenger__name },
                passenger.firstName.toLowerCase(),
                " ",
                passenger.lastName.toLowerCase())),
        React.createElement("div", { className: css.rightSide },
            React.createElement("div", { className: css.passengerInfo },
                React.createElement("div", { className: css.passengerInfo__item },
                    React.createElement("div", { className: css.passengerInfo__field },
                        React.createElement("span", null,
                            t('Birthday'),
                            ":"),
                        React.createElement("span", null, passenger.birthDate)),
                    React.createElement("div", { className: css.passengerInfo__field },
                        React.createElement("span", null,
                            t('Gender'),
                            ":"),
                        React.createElement("span", null, passenger.gender === 'M' ? t('Male') : t('Female')))),
                React.createElement("div", { className: css.passengerInfo__item },
                    React.createElement("div", { className: css.passengerInfo__field },
                        React.createElement("span", null,
                            t('Citizenship'),
                            ":"),
                        React.createElement("span", null, nationalities.get(passenger.nationality))),
                    React.createElement("div", { className: css.passengerInfo__field },
                        React.createElement("span", null,
                            t(passenger.docType),
                            ":"),
                        React.createElement("span", null, passenger.docNumber ? hideText(passenger.docNumber) : '-'))),
                React.createElement("div", { className: css.passengerInfo__item },
                    React.createElement("div", { className: css.passengerInfo__field },
                        React.createElement("span", null,
                            t('Ticket'),
                            ":"),
                        React.createElement("div", null, passengerValues.tickets.map(function (_a) {
                            var number = _a.number;
                            return number;
                        }).join(', '))),
                    passenger.loyaltyNumber && (React.createElement("div", { className: css.passengerInfo__field },
                        React.createElement("span", null,
                            t('Frequent flyer'),
                            ":"),
                        React.createElement("span", null, passenger.loyaltyNumber))),
                    !passenger.loyaltyNumber && (React.createElement(Link, { className: css.loyalty__link, action: onOpen }, t('Add frequent flyer'))))),
            !!onDelete && (React.createElement("div", { className: css.deleteIcon, onClick: handleRemove }, removeIcon)),
            React.createElement(LoyaltyNumber, { open: open, onClose: onClose, passsengerName: "".concat(passenger.firstName.toLowerCase(), " ").concat(passenger.lastName.toLowerCase()), onSaveLoyalty: onSaveLoyaltyHandler }))));
};
export default SelectedPassenger;
