import React, { memo } from 'react';
import Collapse from '@material-ui/core/Collapse';
import cn from 'classnames';
import { useToggleable } from '../../../../../hooks';
import { useTheme } from '../../../../../theme';
import { Chevron } from '../../../../../Icons';
import Document from '../Document/Document';
var Passenger = memo(function (props) {
    var _a;
    var _b = useToggleable(true), isCollapseOpen = _b.isOpen, toggleCollapse = _b.toggle;
    var theme = useTheme('LeoOrderCard').OrderCoupons;
    return (React.createElement("div", null,
        React.createElement("div", { className: cn(theme.passengerName, (_a = {}, _a[theme.passengerName_open] = isCollapseOpen, _a)), onClick: props.passengersCount > 1 ? toggleCollapse : null },
            React.createElement("div", null, props.name),
            props.passengersCount > 1 ? Chevron : null),
        React.createElement(Collapse, { in: isCollapseOpen, mountOnEnter: true },
            React.createElement("div", { className: theme.separator }),
            props.tickets.map(function (document) { return (React.createElement(Document, { key: document.number, dateFormat: props.dateFormat, document: document })); }),
            props.emds.map(function (document) { return (React.createElement(Document, { key: document.number, dateFormat: props.dateFormat, document: document })); }))));
});
export default Passenger;
