import { __assign, __read, __spreadArray } from "tslib";
export var defaultTaxStatus = 'ref';
export var mutators = {
    removeTax: function (_a, state, _b) {
        var _c = __read(_a, 2), deletedTaxName = _c[0], deletedIndex = _c[1];
        var changeValue = _b.changeValue;
        var newValues = state.formState.values[deletedTaxName].filter(function (_, taxIndex) {
            return taxIndex !== deletedIndex;
        });
        changeValue(state, "".concat(deletedTaxName), function () { return newValues; });
    },
    addTax: function (_a, state, _b) {
        var _c = __read(_a, 1), fieldName = _c[0];
        var changeValue = _b.changeValue;
        var addedValue = fieldName === 'airlineTaxes'
            ? {
                code: '',
                amount: '',
                status: defaultTaxStatus
            }
            : {
                code: '',
                amount: ''
            };
        var newValues = __spreadArray(__spreadArray([], __read(state.formState.values[fieldName]), false), [addedValue], false);
        changeValue(state, "".concat(fieldName), function () { return newValues; });
    }
};
export var addDefaultRefProperty = function (airlineTaxes) {
    return airlineTaxes.map(function (tax) {
        return __assign(__assign({}, tax), { status: defaultTaxStatus });
    });
};
export var addDefaultSeatProperty = function (coupons) {
    return coupons.map(function (coupon) {
        return __assign(__assign({}, coupon), { seat: 'ok' });
    });
};
