import { __assign } from "tslib";
import * as React from 'react';
import FlightsSortings from '../FlightsSortings/FlightsSortings';
import FlightsFilters from '../FlightsFilters/FlightsFilters';
import { useTheme } from '../../theme';
import ResultsSegments from '../ResultsSegments/ResultsSegments';
import { initI18n } from '../../utils';
import Schedule from '../Schedule/components/Schedule/Schedule';
export { Props as ControlsProps } from './types';
initI18n('FlightsListControls');
var FlightsListControls = function (props) {
    var css = useTheme('FlightsListControls').FlightsListControls;
    var onStartSearch = props.onStartSearch, searchParameters = props.searchParameters;
    var isAllLegsSelected = props.legs.active === props.legs.legs.length;
    return (React.createElement("div", { className: css.wrapper },
        React.createElement(ResultsSegments, __assign({}, props)),
        !isAllLegsSelected && (React.createElement("div", { className: css.right },
            React.createElement(FlightsSortings, __assign({}, props)),
            React.createElement(FlightsFilters, __assign({}, props)),
            React.createElement(Schedule, { onStartSearch: onStartSearch, searchParameters: searchParameters })))));
};
export default FlightsListControls;
