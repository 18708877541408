import * as React from 'react';
import { Suspense, memo, lazy } from 'react';
import { Provider } from 'react-redux';
import { createStore } from '../store';
import { useGraphQLClient } from '../../graphql-client';
import { fillOrder } from '../store/order/actions';
import { ApolloProvider } from '@apollo/react-hooks';
var CheckoutWrapper = lazy(function () { return import('./CheckoutWrapper'); });
var Component = memo(function (props) {
    var client = useGraphQLClient();
    var store = createStore();
    if (props.order) {
        store.dispatch(fillOrder(props.order));
    }
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(Provider, { store: store },
            React.createElement(Suspense, { fallback: React.createElement("div", null) },
                React.createElement(CheckoutWrapper, { orderId: props.orderId, startNewSearch: props.startNewSearch })))));
});
export default Component;
