import { __assign, __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { getPassengersNames, instanceOfBaggage } from '../../../utils';
import { baggageIcon } from '../../../Icons';
var BaggageStep = function (_a) {
    var _b, _c;
    var onChange = _a.onChange, order = _a.order, passengers = _a.passengers;
    var css = useTheme('MobileStepbar').StepContentStyles;
    var t = useTranslation('MobileStepbar').t;
    var passengerNames = getPassengersNames(passengers);
    var baggage = [];
    (_c = (_b = order.additionalServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(function (service) {
        if (instanceOfBaggage(service)) {
            baggage.push(service);
        }
    });
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.content }, passengers.map(function (traveller) {
            var travellerBaggage = new Map();
            order.segments.forEach(function (segment) {
                var _a;
                var segmentBaggage = [];
                (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.forEach(function (travellerService) {
                    var serviceByTraveller = baggage.find(function (baggage) { return baggage.id === travellerService.serviceId; });
                    if (serviceByTraveller && travellerService.segmentIds.includes(segment.segment.id)) {
                        segmentBaggage.push(__assign(__assign({}, serviceByTraveller), { count: travellerService.count }));
                    }
                });
                travellerBaggage.set(segment, segmentBaggage);
            });
            return (React.createElement("div", { key: traveller.id, className: css.content__item },
                React.createElement("div", { className: cn(css.item__row, css.segment__header) },
                    React.createElement("span", { className: css.content__icon }, baggageIcon),
                    React.createElement("span", { className: css.header__text }, passengerNames[traveller.id])),
                React.createElement("div", null, Array.from(travellerBaggage).every(function (baggages) { return baggages[1].length === 0; }) ? (React.createElement("div", { className: css.item__row },
                    React.createElement("div", { className: css.baggage },
                        React.createElement("span", { className: css.baggage__name }, t('Not selected'))))) : (React.createElement("div", { className: css.item__row }, travellerBaggage.size >= 1 && (React.createElement(React.Fragment, null, Array.from(travellerBaggage).map(function (_a, index) {
                    var _b = __read(_a, 2), segment = _b[0], value = _b[1];
                    if (value.length > 0) {
                        return (React.createElement(React.Fragment, { key: index },
                            React.createElement("div", { className: cn(css.segment__header, css.segment__header_baggage) },
                                segment.segment.departure.airport.iata,
                                " -",
                                ' ',
                                segment.segment.arrival.airport.iata),
                            value.map(function (baggage) { return (React.createElement("div", { className: css.baggage },
                                React.createElement("span", { className: css.baggage__name }, baggage.name),
                                React.createElement("span", { className: css.baggage__weight },
                                    baggage.count,
                                    "x",
                                    baggage.baggageWeight))); })));
                    }
                    return null;
                }))))))));
        })),
        React.createElement("div", { className: css.footer },
            React.createElement("span", { onClick: onChange }, t('MobileStepbar:Change')))));
};
export default BaggageStep;
