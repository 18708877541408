import { createTheme } from '../utils';
import { defaultTheme as defaultAutocompleteTheme } from '../Autocomplete/theme';
import * as Autocomplete from './css/Autocomplete.css';
import * as Option from './components/Option/Option.css';
import * as Group from './components/Group/Group.css';
import * as Dropdown from './components/Dropdown/Dropdown.css';
import * as MobileDialog from './components/MobileDialog/MobileDialog.css';
import * as DirectionsDialog from './components/DirectionsDialog/DirectionsDialog.css';
var merge = require('lodash.merge');
export var overriddenTheme = merge({}, defaultAutocompleteTheme, {
    Autocomplete: Autocomplete
});
export var defaultTheme = {
    Option: Option,
    Group: Group,
    MobileDialog: MobileDialog,
    DirectionsDialog: DirectionsDialog,
    Dropdown: Dropdown
};
export var useTheme = createTheme(defaultTheme).useTheme;
