import * as React from 'react';
import { useTheme } from '../../../../../../../theme';
import { Weekdays } from '@websky/graphql';
export var WeekdaysSchedule = function (props) {
    var _a;
    var theme = useTheme('Schedule').Day;
    var weekdaysMap = new Map((_a = props.leg.segments[0].segment.weekdays) === null || _a === void 0 ? void 0 : _a.map(function (weekday) { return [weekday, null]; }));
    return (React.createElement("div", { className: theme.flightInfo__daysOfWeek }, Object.values(Weekdays).map(function (weekday, index) {
        return (React.createElement("span", { key: weekday, className: weekdaysMap.has(weekday) ? theme.dayWithFlights : null }, index + 1));
    })));
};
