import { __assign } from "tslib";
import * as React from 'react';
import { Form } from 'react-final-form';
import Field from './Field/Field';
import { useTheme } from '../../../../../theme';
import Button from '../../../../../Button/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFirstSelectedPassengerRequestFormInitialData } from '../../../store/passengers/selectors';
import Link from '../../../../../Link';
import { useConfig } from '../../../../../context';
var CreateRequest = function (_a) {
    var send = _a.send, isSending = _a.isSending, autoMode = _a.autoMode;
    var firstSelectedPassengerValues = useSelector(getFirstSelectedPassengerRequestFormInitialData);
    var theme = useTheme('Exchange').CreateRequest;
    var _b = useConfig().global.companyInfo, exarePrivacyPolicyURL = _b.exarePrivacyPolicyURL, exareRulesURL = _b.exareRulesURL;
    var onSubmitHandler = function (values) {
        send(values).then(function (data) { });
    };
    var t = useTranslation('Exchange').t;
    var initialValues = __assign({}, firstSelectedPassengerValues);
    return (React.createElement(Form, { onSubmit: onSubmitHandler, initialValues: initialValues }, function (_a) {
        var form = _a.form, handleSubmit = _a.handleSubmit;
        return (React.createElement("form", { onSubmit: handleSubmit },
            React.createElement("div", { className: theme.container },
                React.createElement("div", { className: theme.header }, t('Create request')),
                React.createElement("div", { className: theme.main },
                    React.createElement(Field, { name: "name", type: "text", label: t('Your name'), className: theme.field }),
                    React.createElement(Field, { name: "email", type: "email", label: t('Email'), className: theme.field }),
                    React.createElement(Field, { name: "phone", type: "phone", label: t('Your phone'), className: theme.field })),
                React.createElement("div", null,
                    React.createElement(Field, { name: "comment", type: "comment", label: t('Your comment'), className: theme.field, required: !autoMode }))),
            React.createElement("div", { className: theme.controls },
                React.createElement(Field, { name: "acceptTerm", type: "checkbox", label: React.createElement("span", null,
                        t('I agree with the'),
                        "\u00A0",
                        exareRulesURL && (React.createElement(Link, { target: '_blank', action: exareRulesURL }, t('Terms of Service'))),
                        exarePrivacyPolicyURL && (React.createElement(React.Fragment, null,
                            "\u00A0",
                            t('And'),
                            "\u00A0",
                            React.createElement(Link, { target: '_blank', action: exarePrivacyPolicyURL }, t('Privacy Policy'))))) }),
                React.createElement(Button, { variant: "flat", className: theme.submit, type: "submit", isLoading: isSending, size: "large" }, t('Create request')))));
    }));
};
export default CreateRequest;
