import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { getCheckinOrder, getExtrasPrice, getTotalPrice } from '../../../../store/order/selectors';
import { useSelector } from 'react-redux';
import AdditionalServiceBanner from '../../../../../../AdditionalServiceBanner/AdditionalServiceBanner';
import MediaQuery from '../../../../../../MediaQuery/MediaQuery';
import MobileToolbar from '../../../../../../MobileToolbar';
import Toolbar from '../../../../../../Toolbar';
import { CheckinStep, useSteps } from '../../../../utils';
import SimpleLoader from '../../../../../../SimpleLoader';
import { useCallback, useState } from 'react';
import AdditionalServices from '../../../../../../AdditionalServices/components/AdditionalServices';
import { useCheckinOrderServices } from '../../../../../../__queries__/Order';
import { useTheme } from '../../../../../../theme';
import { Money } from '../../../../../../Money';
import { useTranslation } from 'react-i18next';
import { convertCheckinOrder } from '../../../../../../MobileStepbar/utils';
var Extras = function (_a) {
    var onProceed = _a.onProceed, onSetOrder = _a.onSetOrder;
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var previousStep = useSteps().previousStep;
    var order = useSelector(getCheckinOrder);
    var totalPrice = useSelector(getTotalPrice);
    var extrasPriceToPay = useSelector(getExtrasPrice);
    var _c = __read(useCheckinOrderServices(), 1), saveCheckinOrderServicesMutation = _c[0];
    var css = useTheme('Checkin').Checkin;
    var t = useTranslation('Checkin').t;
    var onProceedHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, onProceed()];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var saveCheckinOrderServices = useCallback(function (variables) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveCheckinOrderServicesMutation({ variables: variables })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    }); }, []);
    return (React.createElement("div", null,
        React.createElement(AdditionalServiceBanner, { service: "extras" }),
        React.createElement("div", null,
            React.createElement(AdditionalServices, { additionalServices: order.additionalServices, className: css.additionalServices, orderId: order.id, travellers: convertCheckinOrder(order).travellers.map(function (traveller) { return (__assign(__assign({}, traveller), { emds: null, tickets: null })); }), saveOrderServices: saveCheckinOrderServices, onSetOrder: onSetOrder })),
        React.createElement(MediaQuery, { maxWidth: "mobile" },
            React.createElement(MobileToolbar, { onBack: function () { return previousStep(CheckinStep.Extras); }, onContinue: onProceedHandler })),
        React.createElement(MediaQuery, { minWidth: "mobile" },
            React.createElement(Toolbar, { totalPrice: totalPrice, additional: extrasPriceToPay.amount ? (React.createElement("div", { className: css.servicePrice },
                    t('Extras'),
                    ": ",
                    React.createElement(Money, { money: extrasPriceToPay }))) : null, onBackButtonClick: function () { return previousStep(CheckinStep.Extras); }, onButtonClick: onProceedHandler })),
        isLoading && React.createElement(SimpleLoader, null)));
};
export default Extras;
