import { useCallback } from 'react';
import { useConfig } from '../../context';
export var useLeoNavigation = function () {
    var _a = useConfig(), engineURL = _a.Account.engineURL, accountURL = _a.Engine.accountURL;
    var redirectToEngine = useCallback(function () {
        window.location.href = engineURL;
    }, []);
    var redirectToAccount = useCallback(function () {
        window.location.href = accountURL;
    }, []);
    return {
        redirectToAccount: redirectToAccount,
        redirectToEngine: redirectToEngine
    };
};
