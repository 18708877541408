import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Link, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
var Sidebar = function (_a) {
    var routes = _a.routes, selectedRoute = _a.selectedRoute;
    var css = useTheme('Sidebar').Sidebar;
    var t = useTranslation('Account').t;
    return (React.createElement(List, { className: css.sidebar }, routes.map(function (route, index) {
        var match = matchPath(route.pathname, selectedRoute);
        var isSelected = false;
        if (match === null || match === void 0 ? void 0 : match.isExact) {
            isSelected = true;
        }
        var item = (React.createElement(ListItem, { button: true, selected: isSelected, classes: {
                root: css.sidebar__item,
                selected: css.sidebar__item_selected
            } },
            React.createElement(ListItemIcon, { className: css.icon },
                React.createElement(SvgIcon, null, route.icon)),
            React.createElement(ListItemText, { className: css.item__text, primary: t(route.title) })));
        return route.isRedirect ? (React.createElement("a", { key: index, href: route.pathname, target: "_blank", className: css.link }, item)) : (React.createElement(Link, { key: index, to: route.pathname, className: css.link }, item));
    })));
};
export default Sidebar;
