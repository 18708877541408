import * as React from 'react';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Link from '../../../Link';
import { Mail } from '../../../Icons';
import { OrderCardContext } from '../OrderCard';
import { hideText } from '../../../Passenger/utils';
import CommonItem from '../CommonItem/CommonItem';
import ResendTickets from '../../../LEO/ResendTickets';
var OrderTickets = function (props) {
    var t = useTranslation('OrderCard').t;
    var css = useTheme('OrderCard').OrderTickets;
    var order = useContext(OrderCardContext).order;
    var email = useMemo(function () { return order.customer.values.find(function (_a) {
        var name = _a.name;
        return name === 'email';
    }).value; }, [order.id]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ResendTickets, { renderTrigger: function (openModal) { return (React.createElement(CommonItem, { icon: Mail, text: t(!email ? 'Send tickets to the email?' : 'Tickets have been sent to {{email}}', {
                    email: hideText(email)
                }), className: cn(css.wrapper, props.className), buttons: React.createElement(Link, { className: css.link, action: openModal }, t('Resend it')) })); } })));
};
export default OrderTickets;
