import * as React from 'react';
import Modal from '../../../Modal';
import HostAdapter from '../../Terminal/components/HostAdapter';
import TerminalRoot from '../../Terminal/components/Terminal';
import css from './Terminal.css';
var Terminal = function (_a) {
    var isOpen = _a.isOpen, open = _a.open, close = _a.close;
    return (React.createElement(Modal, { open: isOpen, scroll: 'paper', fullScreen: true, onClose: close, closeButtonRenderer: function () { return null; }, classes: {
            root: css.root,
            paper: css.modal,
            scrollPaper: css.scrollPaper
        } },
        React.createElement(HostAdapter, { component: TerminalRoot, onClose: close })));
};
export default Terminal;
