import { __read } from "tslib";
import React, { useEffect } from 'react';
import Modal from '../../Modal';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { useLazyQuery } from '@apollo/react-hooks';
import PnrHistoryQuery from '../../__queries__/PnrHistory.graphql';
import HistoryElement from './HistoryElement/HistoryElement';
import { initI18n } from '../../utils';
import { useTranslation } from 'react-i18next';
initI18n('OrderChangeHistoryModal');
var OrderChangeHistoryModal = function (props) {
    var _a, _b;
    var css = useTheme('OrderChangeHistoryModal').OrderChangeHistoryModal;
    var t = useTranslation('OrderChangeHistoryModal').t;
    var _c = __read(useLazyQuery(PnrHistoryQuery.PnrHistory, {
        variables: {
            orderId: props.orderId
        }
    }), 2), load = _c[0], _d = _c[1], data = _d.data, called = _d.called, loading = _d.loading;
    var header = t('History');
    useEffect(function () {
        if (props.open && !data && !called) {
            load();
        }
    }, [props.open, called]);
    if (!called || loading || !data) {
        header = t('Loading...');
    }
    else if (!((_a = data === null || data === void 0 ? void 0 : data.PnrHistory) === null || _a === void 0 ? void 0 : _a.historyElements)) {
        header = t('History is empty');
    }
    return (React.createElement(Modal, { open: props.open, onClose: props.onClose, isRounded: true, classes: {
            paper: css.modal_paper
        } },
        React.createElement("div", { className: css.root },
            React.createElement("div", { className: css.header }, header),
            ((_b = data === null || data === void 0 ? void 0 : data.PnrHistory) === null || _b === void 0 ? void 0 : _b.historyElements) && (React.createElement("div", { className: css.table },
                React.createElement("div", { className: cn(css.row, css.table_header) },
                    React.createElement("div", { className: css.time }, t('Time')),
                    React.createElement("div", { className: css.action }, t('Action'))),
                data.PnrHistory.historyElements.map(function (el, i) {
                    return React.createElement(HistoryElement, { element: el, key: i });
                }))))));
};
export default OrderChangeHistoryModal;
