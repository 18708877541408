import { __assign, __read, __spreadArray } from "tslib";
import i18n from 'i18next';
import { TravellerFieldEnum } from '@websky/graphql';
import { InputType } from '../../Passenger/types';
export var PAYMENT_COMMENT = 'PAYMENT';
var OTHER_CONTACTS_TITLE = 'Other contacts';
export var escapedT = function (label) {
    return typeof label !== 'undefined'
        ? i18n.t("Contacts:".concat(label), {
            // This is necessary to avoid colon problems in error messages.
            nsSeparator: '|',
            keySeparator: '>'
        })
        : label;
};
var buildContactField = function (value, baseField, isMainContact, index) {
    var additionRules = isMainContact ? {} : { required: false };
    return {
        name: isMainContact ? "customer[".concat(index, "][").concat(baseField.name, "]") : "otherContacts[".concat(index, "][").concat(baseField.name, "]"),
        type: baseField.type,
        input: baseField.type === TravellerFieldEnum.Phone ? InputType.Phone : InputType.Text,
        defaultValue: value !== null && value !== void 0 ? value : '',
        validations: baseField.validationRules.map(function (rule) { return (__assign(__assign({}, rule), additionRules)); })
    };
};
var buildMainContacts = function (contactsByComment, isTerminal, baseEmail, basePhone) {
    var _a, _b;
    var mainEmailsFromContactsArray = (_a = contactsByComment
        .get(PAYMENT_COMMENT)) === null || _a === void 0 ? void 0 : _a.filter(function (contact) { return contact.type === TravellerFieldEnum.Email; });
    var mainPhonesFromContactsArray = (_b = contactsByComment
        .get(PAYMENT_COMMENT)) === null || _b === void 0 ? void 0 : _b.filter(function (contact) { return contact.type === TravellerFieldEnum.Phone; });
    var mainEmails = !(mainEmailsFromContactsArray === null || mainEmailsFromContactsArray === void 0 ? void 0 : mainEmailsFromContactsArray.length) || !isTerminal ? [baseEmail] : mainEmailsFromContactsArray;
    var mainPhones = !(mainPhonesFromContactsArray === null || mainPhonesFromContactsArray === void 0 ? void 0 : mainPhonesFromContactsArray.length) || !isTerminal ? [basePhone] : mainPhonesFromContactsArray;
    return buildEmailPhonePair(mainPhones, mainEmails, baseEmail, basePhone);
};
var buildOtherContacts = function (contactsByComment, baseEmail, basePhone) {
    var otherContacts = new Map();
    contactsByComment.forEach(function (contacts, contactsComment) {
        if (contactsComment === PAYMENT_COMMENT) {
            return;
        }
        var emails = contacts.filter(function (contact) { return contact.type === TravellerFieldEnum.Email; });
        var phones = contacts.filter(function (contact) { return contact.type === TravellerFieldEnum.Phone; });
        var otherContactsByType = buildEmailPhonePair(phones, emails, baseEmail, basePhone);
        otherContacts.set(contactsComment, otherContactsByType);
    });
    return otherContacts;
};
var buildEmailPhonePair = function (phones, emails, baseEmail, basePhone) {
    var pairs = [];
    var maxContactsLenght = Math.max(emails.length, phones.length);
    for (var index = 0; index < maxContactsLenght; index++) {
        var email = emails[index];
        var phone = phones[index];
        pairs.push({
            email: email ? buildContactField(email.value, baseEmail, true, index) : null,
            phone: phone ? buildContactField(phone.value, basePhone, true, index) : null
        });
    }
    return pairs;
};
export var buildContactFields = function (customer, isTerminal) {
    var baseEmail = customer.values.find(function (_a) {
        var type = _a.type;
        return type === TravellerFieldEnum.Email;
    });
    var basePhone = customer.values.find(function (_a) {
        var type = _a.type;
        return type === TravellerFieldEnum.Phone;
    });
    var contactsByComment = new Map();
    customer.contacts.forEach(function (contact) {
        var _a, _b;
        var contactComment = (_a = contact.comment) !== null && _a !== void 0 ? _a : OTHER_CONTACTS_TITLE;
        var contacts = (_b = contactsByComment.get(contactComment)) !== null && _b !== void 0 ? _b : [];
        contactsByComment.set(contactComment, __spreadArray(__spreadArray([], __read(contacts), false), [contact], false));
    });
    var mainContacts = buildMainContacts(contactsByComment, isTerminal, baseEmail, basePhone);
    var otherContacts = buildOtherContacts(contactsByComment, baseEmail, basePhone);
    return {
        mainContacts: mainContacts,
        otherContacts: otherContacts
    };
};
