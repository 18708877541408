import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { useServicePageProps } from '../hooks';
import { useUpsaleWarning } from '../../../Checkout/components/Checkout/Upsale/hooks';
import { ServiceType } from '../../../Checkout/components/Checkout/OrderPaidServiceCards/types';
import { useSelector } from 'react-redux';
import { getMealMinPrice, getSegments, getServices, getServicesFallbackURL, shouldRedirectToFallback } from '../../../Checkout/store/order/selectors';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import ServicePage from '../../../Checkout/components/Checkout/ServicePage/ServicePage';
import ServiceCardDialog from '../../../Checkout/components/Checkout/OrderPaidServiceCards/components/ServiceCardDialog/ServiceCardDialog';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../__generated__/globalTypes';
import { convertMealObject } from '../../../Checkout/types';
import { useTranslation } from 'react-i18next';
import { getMealsPrice } from '../../../Checkout/store/orderPrice/selectors';
import { useTheme } from '../../../theme';
import icons from '../../icons';
import { getPassengerStateCompatible } from '../../../Checkout/store/selectedServices/selectors';
var MealAdditionalService = function () {
    var servicePageProps = useServicePageProps();
    var servicesRedirectURL = useSelector(getServicesFallbackURL);
    var shouldRedirect = useSelector(shouldRedirectToFallback);
    var _a = useUpsaleWarning(), showWarning = _a.showWarning, closeWarning = _a.closeWarning, toggleWarningIfOrderConfirmed = _a.toggleWarningIfOrderConfirmed;
    var handleClick = shouldRedirect
        ? toggleWarningIfOrderConfirmed
        : servicePageProps.toggleModal.bind(null, ServiceType.Meal);
    var t = useTranslation('Checkout').t;
    var minPrice = useSelector(getMealMinPrice);
    var issuedPrice = useSelector(getMealsPrice);
    var _b = useTheme('CheckoutAdditionalService'), css = _b.CheckoutAdditionalServiceMeal, commonStyles = _b.CheckoutAdditionalService;
    var segments = useSelector(getSegments);
    var orderServices = useSelector(getServices);
    var passengers = useSelector(getPassengerStateCompatible);
    var servicesBySegments = new Map();
    segments.forEach(function (segment) {
        passengers.forEach(function (passenger) {
            passenger.services.forEach(function (passengerService) {
                var relatedService = orderServices.find(function (orderService) { return orderService.id === passengerService.serviceId; });
                if (relatedService.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
                    if (passengerService.segmentIds.includes(segment.id)) {
                        var convertedService_1 = __assign(__assign({}, convertMealObject(relatedService)), { count: passengerService.count });
                        if (servicesBySegments.has(segment)) {
                            var selectedServices = servicesBySegments.get(segment);
                            if (selectedServices.some(function (selectedService) { return selectedService.id === convertedService_1.id; })) {
                                servicesBySegments.set(segment, selectedServices.map(function (service) {
                                    return service.id === convertedService_1.id
                                        ? __assign(__assign({}, service), { count: service.count += convertedService_1.count }) : service;
                                }));
                            }
                            else {
                                servicesBySegments.set(segment, servicesBySegments.get(segment).concat(convertedService_1));
                            }
                        }
                        else {
                            servicesBySegments.set(segment, [convertedService_1]);
                        }
                    }
                }
            });
        });
    });
    var isSelected = !!servicesBySegments.size;
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckoutAdditionalService, { className: css.meal, icon: icons.mealIcon, addClick: showWarning ? toggleWarningIfOrderConfirmed : handleClick, buttons: [{ text: t('Change'), action: servicePageProps.toggleModal.bind(null, ServiceType.Meal) }], header: t('Meal'), priceFrom: minPrice, description: t('Feel the taste in flight!'), isSelected: isSelected, priceTotal: issuedPrice.amount ? issuedPrice : null },
            React.createElement("ul", { className: commonStyles.list }, __spreadArray([], __read(servicesBySegments.keys()), false).map(function (segment) { return (React.createElement("li", { className: commonStyles.list__item, key: segment.id },
                React.createElement("span", { className: commonStyles.list__cities },
                    segment.departure.airport.city.name,
                    " - ",
                    segment.arrival.airport.city.name),
                React.createElement("span", { className: commonStyles.list__services }, __spreadArray([], __read(servicesBySegments.get(segment)), false).map(function (mealItem) { return (React.createElement(React.Fragment, null,
                    mealItem.name,
                    " x ",
                    mealItem.count,
                    React.createElement("br", null))); })))); }))),
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        React.createElement(ServicePage, null, function (childrenProps) { return (React.createElement(ServiceCardDialog, __assign({ order: servicePageProps.order, openedModal: servicePageProps.openedModal, onClose: servicePageProps.toggleModal.bind(null, null), passengers: servicePageProps.passengers, passengerLabels: servicePageProps.passengerLabels }, childrenProps))); })));
};
export default MealAdditionalService;
