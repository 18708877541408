import * as React from 'react';
import ServiceItem from './ServiceItem/ServiceItem';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
var ServicesList = function (_a) {
    var services = _a.services, setService = _a.setService, onClear = _a.onClear, segments = _a.segments, travellersServices = _a.travellersServices;
    var theme = useTheme('ExtraServices').ServicesList;
    var t = useTranslation('ExtraServices').t;
    return (React.createElement("table", { className: theme.table },
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("td", null, t('RFISC')),
                React.createElement("td", null, t('Service name')),
                React.createElement("td", null, t('Route')),
                React.createElement("td", null, t('Cat.')),
                React.createElement("td", null, t('Price')),
                React.createElement("td", null, t('Added')))),
        React.createElement("tbody", null, services.map(function (service) { return (React.createElement(ServiceItem, { service: service, key: service.service.id, onClick: setService, onClear: onClear, segments: segments, travellersServices: travellersServices })); }))));
};
export default ServicesList;
