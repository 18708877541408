import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import BaggageCounter from '../../../../BaggageCounter';
import { usePassengerState } from '../../../hooks';
import Tooltip from '../../../../../Tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSelectedServicesByPassengersMap, getMaxBaggagePerPassengerCount, getService, getPassenger } from '../../../store/selector';
import { getBaggageCountInSegment, getMaxBaggageCount, getMinBaggageCount, getMinAvailableBaggagePrice, updateSelectedItems } from '../../../utils';
import { useConfig } from '../../../../../context';
var PassengerBaggageCounter = function (_a) {
    var _b, _c, _d, _e;
    var className = _a.className, passengerId = _a.passengerId, baggages = _a.baggages, onChange = _a.onChange, disabled = _a.disabled, segmentIds = _a.segmentIds, segmentId = _a.segmentId, maxBaggageCount = _a.maxBaggageCount, forAllSegments = _a.forAllSegments;
    var theme = useTheme('UpgradeBaggageDialog').BaggagePassenger;
    var t = useTranslation('IncludedBaggage').t;
    var _f = usePassengerState(passengerId, baggages, segmentIds, segmentId), items = _f.items, selectedItems = _f.selectedItems, selectedCount = _f.selectedCount;
    var services = useSelector(getService);
    var selectedServicesByPassengers = useSelector(getSelectedServicesByPassengersMap);
    var defaultMaxBaggagePerPassenger = useSelector(getMaxBaggagePerPassengerCount);
    var passenger = useSelector(function (state) { return getPassenger(state, passengerId); });
    var baggageLimitConfig = (_c = (_b = useConfig().global) === null || _b === void 0 ? void 0 : _b.baggage) === null || _c === void 0 ? void 0 : _c.baggageLimit;
    var selectedBaggageCountInSegmentForPassenger = React.useMemo(function () { return getBaggageCountInSegment(selectedServicesByPassengers.get(passengerId) || [], segmentId); }, [selectedServicesByPassengers, segmentId]);
    var selectedPassengerBaggageByTypeCount = React.useMemo(function () {
        var selectedPassengerBag = selectedServicesByPassengers.get(passengerId);
        if (selectedPassengerBag) {
            var bagCount = selectedPassengerBag
                .filter(function (i) { return !segmentId || i.segmentIds.includes(segmentId); })
                .reduce(function (count, bag) { return (services.some(function (s) { return s.id === bag.serviceId; }) ? count + bag.count : count); }, 0);
            if ((segmentIds === null || segmentIds === void 0 ? void 0 : segmentIds.length) > 0) {
                bagCount = bagCount / segmentIds.length;
            }
            return bagCount;
        }
        return 0;
    }, [passengerId, segmentId, selectedServicesByPassengers, services]);
    var maxCount = React.useMemo(function () {
        return maxBaggageCount !== null && maxBaggageCount !== void 0 ? maxBaggageCount : getMaxBaggageCount(defaultMaxBaggagePerPassenger, baggageLimitConfig, baggages[0].type, selectedCount, selectedPassengerBaggageByTypeCount, selectedBaggageCountInSegmentForPassenger);
    }, [
        selectedBaggageCountInSegmentForPassenger,
        defaultMaxBaggagePerPassenger,
        selectedCount,
        selectedPassengerBaggageByTypeCount,
        baggages,
        maxBaggageCount
    ]);
    var minCount = React.useMemo(function () { return getMinBaggageCount(passenger, baggages[0].id, segmentId); }, [passenger]);
    var onChangeHandler = function (count) {
        console.log(count);
        var newItems = updateSelectedItems(selectedCount, count, items, selectedItems, baggages);
        if (newItems) {
            onChange(newItems);
        }
    };
    var minPrice = getMinAvailableBaggagePrice(baggages);
    return (React.createElement("div", { className: cn(className, theme.counter_wrapper) },
        React.createElement(Tooltip, { disableHoverListener: (minPrice === null || minPrice === void 0 ? void 0 : minPrice.amount) === 0 || disabled, disableTouchListener: (minPrice === null || minPrice === void 0 ? void 0 : minPrice.amount) === 0 || disabled, title: t('Price for passenger: {{amount}}', {
                amount: forAllSegments
                    ? getMinAvailableBaggagePrice(baggages, passengerId).amount * ((_e = (_d = baggages[0]) === null || _d === void 0 ? void 0 : _d.segmentsId) === null || _e === void 0 ? void 0 : _e.length)
                    : getMinAvailableBaggagePrice(baggages, passengerId).amount,
                nsSeparator: '>'
            }) },
            React.createElement("div", null,
                React.createElement(BaggageCounter, { max: maxCount, min: minCount, value: selectedCount, onChange: onChangeHandler, disabled: disabled })))));
};
export default PassengerBaggageCounter;
