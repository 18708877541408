import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '../../theme';
import Input from '../../Input';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import Button from '../../Button/Button';
import Tooltip from '../../Tooltip';
import ClarifyDocumentModal from './ClarifyDocumentModal/ClarifyDocumentModal';
import { useClarifyDocumentModal } from './ClarifyDocumentModal/hooks';
import { useRetrieveBookingValidation } from '../hooks';
import { useIsTerminal } from '../../hooks';
var planeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8 22H10L15 14H20.5C21.33 14 22 13.33 22 12.5C22 11.67 21.33 11 20.5 11H15L10 3H8L10.5 11L5 11L3.5 9H2L3 12.5L2 16H3.5L5 14L10.5 14L8 22Z", fill: "#9A9A9A" })));
export var infoIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 11V17H11V11H13ZM12 20C7.58999 20 3.99999 16.41 3.99999 12C3.99999 7.59 7.58999 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM13 7V9H11V7H13Z", fill: "#9A9A9A" })));
initI18n('RetrieveBooking');
export var Mode;
(function (Mode) {
    Mode[Mode["Checkout"] = 0] = "Checkout";
    Mode[Mode["Checkin"] = 1] = "Checkin";
})(Mode || (Mode = {}));
var RetrieveBooking = function (_a) {
    var mode = _a.mode, submitWithInitialValues = _a.submitWithInitialValues, className = _a.className, title = _a.title, initialId = _a.initialId, initialSecret = _a.initialSecret, onSubmit = _a.onSubmit, idTitle = _a.idTitle, secretTitle = _a.secretTitle, hideSecretHint = _a.hideSecretHint;
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read(useState(null), 2), submitErrors = _c[0], setSubmitErrors = _c[1];
    var _d = __read(useState(false), 2), isBlockedForPayment = _d[0], setIsBlockedForPayment = _d[1];
    var css = useTheme('RetrieveBooking').RetrieveBooking;
    var t = useTranslation('RetrieveBooking').t;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _e = useClarifyDocumentModal(), isOpen = _e.isOpen, openModal = _e.open, searchParams = _e.searchParams, setSearchParams = _e.setSearchParams, close = _e.close, needToShowClarifyModal = _e.needToShowClarifyModal;
    var _f = useRetrieveBookingValidation(), lastNameValidation = _f.lastNameValidation, ticketNumberValidation = _f.ticketNumberValidation, validateByOrderNumber = _f.validateByOrderNumber, ticketNumberFormat = _f.ticketNumberFormat;
    var isTerminal = useIsTerminal();
    var submitForm = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, onSubmit(values['id'], values['secret'], values['extraSecret'])];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, [onSubmit]);
    var handleSubmit = useCallback(function (values, form) { return __awaiter(void 0, void 0, void 0, function () {
        var result, e_1, errors_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setSubmitErrors(null);
                    setIsLoading(true);
                    if (mode === Mode.Checkin && form.hasOwnProperty('getFieldState')) {
                        // clear warning on submit
                        form.getFieldState('secret').data.warning = null;
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, submitForm(values)];
                case 2:
                    result = _b.sent();
                    if (result) {
                        if (typeof result !== 'boolean') {
                            setIsBlockedForPayment(result.isBlockedForPayment);
                        }
                    }
                    else {
                        setSubmitErrors([]);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    console.warn(e_1);
                    if (needToShowClarifyModal(e_1)) {
                        setSearchParams(values);
                        openModal();
                    }
                    else {
                        errors_1 = [];
                        if (isTerminal) {
                            (_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.graphQLErrors) === null || _a === void 0 ? void 0 : _a.forEach(function (error) {
                                if ((error === null || error === void 0 ? void 0 : error.category) === 'graphql') {
                                    errors_1.push(error === null || error === void 0 ? void 0 : error.message);
                                }
                            });
                        }
                        setSubmitErrors(errors_1);
                    }
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [submitForm]);
    var handleClarifySubmit = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var result, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, submitForm(values)];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, !!result];
                case 2:
                    e_2 = _a.sent();
                    console.warn(e_2);
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [submitForm]);
    var renderTooltip = function (error, meta) {
        if (isTerminal) {
            return null;
        }
        if (meta.data.warning && mode === Mode.Checkin) {
            return (React.createElement(InputAdornment, { position: "start" },
                React.createElement(Tooltip, { tooltipClassName: css.tooltip, title: t(meta.data.warning), open: true }, infoIcon)));
        }
        return (!isMobile &&
            !hideSecretHint && (React.createElement(InputAdornment, { position: "end" },
            React.createElement(Tooltip, { title: t('You can find security code in your booking confirmation email') }, infoIcon))));
    };
    useEffect(function () {
        if (submitWithInitialValues) {
            handleSubmit({ id: initialId, secret: initialSecret }, {});
        }
    }, [submitWithInitialValues]);
    var submitErrorsMessage = (submitErrors === null || submitErrors === void 0 ? void 0 : submitErrors.length)
        ? "".concat(t('Order not found'), ". ").concat(submitErrors.join('. '))
        : t('Order not found');
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("h1", { className: css.title },
            React.createElement("span", { className: css.title__icon }, planeIcon),
            " ",
            title || t('Retrieve booking')),
        React.createElement(ClarifyDocumentModal, { searchParams: searchParams, onSubmit: handleClarifySubmit, open: isOpen, onClose: close }),
        React.createElement(Form, { onSubmit: handleSubmit }, function (_a) {
            var _b;
            var handleSubmit = _a.handleSubmit, valid = _a.valid;
            return (React.createElement("form", { className: css.form, onSubmit: handleSubmit },
                React.createElement("div", { className: css.form__row },
                    React.createElement(Field, { name: "id", initialValue: initialId, parse: ticketNumberFormat, validate: function (value) {
                            if (!value) {
                                return t('Required field');
                            }
                            if (mode === Mode.Checkin) {
                                return ticketNumberValidation(value);
                            }
                            if (mode === Mode.Checkout) {
                                if (!ticketNumberValidation(value) || !validateByOrderNumber(value)) {
                                    return undefined;
                                }
                                else {
                                    return ticketNumberValidation(value) || validateByOrderNumber(value);
                                }
                            }
                        }, render: function (_a) {
                            var input = _a.input, meta = _a.meta;
                            return (React.createElement(Input, { disabled: isLoading, absoluteHintPosition: true, label: idTitle || t('Booking ID'), TextFieldProps: __assign(__assign({}, input), { helperText: ((meta.modified && meta.touched) || meta.submitFailed) &&
                                        (meta.error || meta.submitError), error: ((meta.modified && meta.touched) || meta.submitFailed) &&
                                        (!!meta.error || !!meta.submitError) }) }));
                        } })),
                React.createElement("div", { className: css.form__row },
                    React.createElement(Field, { name: "secret", initialValue: initialSecret, validate: function (value, allValues, meta) {
                            if (!value) {
                                return t('Required field');
                            }
                            if (mode === Mode.Checkin) {
                                var result = lastNameValidation(value);
                                if (!result) {
                                    meta.data.warning = null;
                                    return undefined;
                                }
                                if (result !== t('Enter passenger last name')) {
                                    meta.data.warning = null;
                                    return result;
                                }
                                else {
                                    // pass warning to data object do not treat it as error
                                    meta.data.warning = t('Enter passenger last name');
                                    return undefined;
                                }
                            }
                        }, render: function (_a) {
                            var input = _a.input, meta = _a.meta;
                            return (React.createElement(Input, { disabled: isLoading, absoluteHintPosition: true, label: secretTitle || t('Security code'), endAdornment: renderTooltip(meta.error, meta), TextFieldProps: __assign(__assign({}, input), { helperText: (!!submitErrors && submitErrorsMessage) ||
                                        (isBlockedForPayment &&
                                            t('The order is being paid at the moment, please try again later')) ||
                                        (((meta.modified && meta.touched) || meta.submitFailed) &&
                                            (meta.error || meta.submitError)), error: !!submitErrors ||
                                        isBlockedForPayment ||
                                        (((meta.modified && meta.touched) || meta.submitFailed) &&
                                            (!!meta.error || !!meta.submitError)) }) }));
                        } })),
                React.createElement("div", { className: css.form__row },
                    React.createElement(Button, { className: cn(css.button, (_b = {},
                            _b[css.button_disabled] = !valid,
                            _b)), type: "submit", disabled: !valid, isLoading: isLoading }, t('Open')))));
        })));
};
export default RetrieveBooking;
