import * as React from 'react';
import SegmentCommon from './components/SegmentCommon/SegmentCommon';
import SegmentCompact from './components/SegmentCompact/SegmentCompact';
import { initI18n } from '../utils';
import { FlightClass } from '../__generated__/globalTypes';
initI18n('Segment');
var Segment = function (_a) {
    var variant = _a.variant, segment = _a.segment, _b = _a.serviceClass, serviceClass = _b === void 0 ? FlightClass.Economy : _b;
    return variant === 'common' ? (React.createElement(SegmentCommon, { segment: segment, serviceClass: serviceClass })) : (React.createElement(SegmentCompact, { segment: segment, serviceClass: serviceClass }));
};
export default Segment;
