import { __read } from "tslib";
import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import * as GetOrdersListRequest from '../../../../../__queries__/GetOrdersList.graphql';
import Warnings from '../../../../../Warnings';
import { Link } from '../../../../../index';
import Loader from '../../../../../Loader';
import { OrderStatus } from '../../../../../__generated__/globalTypes';
import OrderStub from '../../../../../OrderSummary/components/OrderStub/OrderStub';
import { useConfig } from '../../../../../context';
import { useTheme } from '../../../theme';
import OrderCard from '../../../../../OrderCard';
var Orders = memo(function () {
    var _a, _b, _c, _d, _e, _f;
    var _g = useQuery(GetOrdersListRequest.GetOrdersList, {
        fetchPolicy: 'no-cache'
    }), user = _g.data, loading = _g.loading;
    var css = useTheme().Account;
    var t = useTranslation('Account').t;
    var _h = useConfig(), engineURL = _h.Account.engineURL, companyInfo = _h.global.companyInfo;
    if (loading) {
        return React.createElement(Loader, null);
    }
    var sortById = function (_a, _b) {
        var idA = _a.id;
        var idB = _b.id;
        var _c = __read([parseInt(idA, 10), parseInt(idB, 10)], 2), numericIdA = _c[0], numericIdB = _c[1];
        if (numericIdB > numericIdA) {
            return 1;
        }
        else if (numericIdB < numericIdA) {
            return -1;
        }
        else {
            return 1;
        }
    };
    var orders = (_d = (_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.CurrentUser) === null || _a === void 0 ? void 0 : _a.ordersList) === null || _b === void 0 ? void 0 : _b.sort(sortById)) === null || _c === void 0 ? void 0 : _c.filter(function (_a) {
        var status = _a.status;
        return status !== OrderStatus.New;
    })) !== null && _d !== void 0 ? _d : [];
    var onOrderClickHandler = function (orderId) {
        window.location.href = "".concat(engineURL, "#/booking/").concat(orderId);
    };
    return (React.createElement("div", null,
        companyInfo.webskySearchOrder && (React.createElement(Warnings, { className: css.warning },
            React.createElement("span", null,
                t('Orders that were booked on a previous version of the site are available'),
                "\u00A0",
                React.createElement(Link, { className: css.link, action: "".concat(companyInfo.webskySearchOrder), target: '_blank' }, t('this link'))))),
        React.createElement("h1", { className: cn(css.route__title, css.orders__title) }, t('Orders')),
        orders.map(function (order) {
            return (React.createElement(OrderCard, { key: order.id, order: order, isCheckinOpen: false, type: 'account', onClick: onOrderClickHandler }));
        }),
        !((_f = (_e = user === null || user === void 0 ? void 0 : user.CurrentUser) === null || _e === void 0 ? void 0 : _e.ordersList) === null || _f === void 0 ? void 0 : _f.length) && React.createElement(OrderStub, { className: css.orderStub })));
});
export default Orders;
