import { __assign } from "tslib";
import { RouteType } from '../QuickSearchForm/types';
import { AviaPassengerType, OrderAdditionalServiceGdsServiceServiceType, TravellerFieldEnum, OrderStatus } from '../__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import { CheckoutSteps } from './types';
export var getRouteType = function (segments) {
    var result = RouteType.ComplexRoute;
    if (segments.length === 1) {
        result = RouteType.OneWay;
    }
    else if (segments[0].segment.departure.airport.iata === segments[segments.length - 1].segment.arrival.airport.iata) {
        result = RouteType.RoundTrip;
    }
    return result;
};
export var getPassengersNames = function (passengers) {
    var t = useTranslation('MobileStepbar').t;
    var passengersMap = {};
    var passengerNames = {};
    passengers.forEach(function (passenger) {
        var name = '';
        var nameParts = [];
        passenger.values.forEach(function (_a) {
            var value = _a.value, type = _a.type;
            if ((type === TravellerFieldEnum.FirstName || type === TravellerFieldEnum.LastName) && value) {
                nameParts.push(value);
            }
        });
        if (!passengersMap.hasOwnProperty(passenger.type)) {
            passengersMap[passenger.type] = 0;
        }
        passengersMap[passenger.type] += 1;
        if (nameParts.length) {
            name = nameParts.join(' ');
        }
        else {
            switch (passenger.type) {
                case AviaPassengerType.ADT:
                    name = "".concat(t('Adult'), " ").concat(passengersMap[passenger.type]);
                    break;
                case AviaPassengerType.CLD:
                    name = "".concat(t('Child'), " ").concat(passengersMap[passenger.type]);
                    break;
                case AviaPassengerType.INF:
                    name = "".concat(t('Infant'), " ").concat(passengersMap[passenger.type]);
                    break;
                case AviaPassengerType.INS:
                    name = "".concat(t('Infant with seat'), " ").concat(passengersMap[passenger.type]);
                    break;
            }
        }
        passengerNames[passenger.id] = name;
    });
    return passengerNames;
};
export var instanceOfBaggage = function (service) {
    return 'baggageType' in service;
};
export var instanceOfMeal = function (service) {
    return 'mealWeight' in service;
};
export var getStepPrice = function (step, order, passengers) {
    var _a, _b, _c, _d;
    if (step === CheckoutSteps.Seats) {
        var seatsPrice_1 = { currency: (_a = order.price.airticket) === null || _a === void 0 ? void 0 : _a.currency, amount: 0 };
        if (passengers) {
            passengers.forEach(function (traveller) {
                traveller.services &&
                    traveller.seats &&
                    traveller.seats.forEach(function (seat) {
                        if (seat && seat.price && !seat.isConfirmed) {
                            seatsPrice_1.currency = seat.price.currency;
                            seatsPrice_1.amount += seat.price.amount;
                        }
                    });
            });
            return seatsPrice_1;
        }
    }
    else if (step === CheckoutSteps.Flights) {
        return order.price.airticket;
    }
    else if (step === CheckoutSteps.Extras) {
        var extrasPrice = { amount: 0, currency: (_b = order.price.airticket) === null || _b === void 0 ? void 0 : _b.currency };
        if (order.price.aeroexpress || order.price.insurance) {
            extrasPrice.currency =
                (order.price.aeroexpress && order.price.aeroexpress.currency) ||
                    (order.price.insurance && order.price.insurance.currency);
            extrasPrice.amount +=
                (order.price.aeroexpress && order.price.aeroexpress.amount) +
                    (order.price.insurance && order.price.insurance.amount);
        }
        return extrasPrice;
    }
    else {
        var stepPrice_1 = { currency: (_c = order.price.airticket) === null || _c === void 0 ? void 0 : _c.currency, amount: 0 };
        var availableServices_1 = {
            Baggage: [],
            Meal: []
        };
        (_d = order.additionalServices.gdsServices) === null || _d === void 0 ? void 0 : _d.services.forEach(function (service) {
            if (service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage) {
                availableServices_1[CheckoutSteps.Baggage].push(service);
            }
            else if (service.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
                availableServices_1[CheckoutSteps.Meal].push(service);
            }
        });
        passengers.forEach(function (passenger) {
            var _a;
            (_a = passenger.services) === null || _a === void 0 ? void 0 : _a.forEach(function (service) {
                var passengersService = availableServices_1 &&
                    availableServices_1[step] &&
                    availableServices_1[step].find(function (availableService) {
                        return availableService.id === service.serviceId;
                    });
                var confirmedCount = service.confirmedCount || 0;
                if (passengersService) {
                    stepPrice_1.amount +=
                        (service.count - confirmedCount) * passengersService.price.amount * service.segmentIds.length;
                    stepPrice_1.currency = passengersService.price.currency;
                }
            });
        });
        return stepPrice_1;
    }
    return null;
};
export var convertCheckoutOrder = function (checkoutOrder) {
    return {
        id: checkoutOrder.id,
        additionalServices: checkoutOrder.additionalServices,
        status: checkoutOrder.status,
        segmentGroups: checkoutOrder.flight.segmentGroups,
        segments: checkoutOrder.flight.segments,
        travellers: checkoutOrder.travellers.map(function (traveller) { return (__assign(__assign({}, traveller), { emds: null, tickets: null })); }),
        price: checkoutOrder.price,
        customer: checkoutOrder.customer
    };
};
export var convertCheckinOrder = function (checkinOrder) {
    return {
        id: checkinOrder.id,
        additionalServices: checkinOrder.additionalServices,
        status: OrderStatus.Confirmed,
        segmentGroups: [],
        travellers: checkinOrder.travellers.map(function (traveller) { return (__assign(__assign({}, traveller), { emds: null, tickets: null })); }),
        segments: checkinOrder.segments.map(function (segment) {
            return ({
                transferDuration: null,
                segment: segment
            });
        }),
        customer: null,
        price: checkinOrder.price
    };
};
