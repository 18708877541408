import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { useCallback } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
var Queues = function (props) {
    var _a;
    var theme = useTheme('EventsQueues').EventsQueues;
    var queuesTheme = useTheme('EventsQueues').Queues;
    var t = useTranslation('EventsQueues').t;
    var renderContent = useCallback(function () {
        return props.queues.map(function (queue) {
            var _a;
            var _b, _c;
            return (React.createElement(ExpansionPanel, { key: queue.number, expanded: ((_b = props.selectedQueue) === null || _b === void 0 ? void 0 : _b.number) === queue.number, classes: {
                    root: queuesTheme.expansion,
                    expanded: queuesTheme.expansion_expanded
                } },
                React.createElement(ExpansionPanelSummary, { id: queue.number.toString(), classes: {
                        root: queuesTheme.summary,
                        content: queuesTheme.content,
                        expanded: queuesTheme.content_expanded
                    }, onClick: function () { return props.queueClickHandler(queue); } },
                    React.createElement("div", { key: queue.number, className: cn(theme.table__line_pointer, queuesTheme.queues__item, queuesTheme.queues__item_summary, (_a = {},
                            _a[theme.table__line_selected] = queue.number === ((_c = props.selectedQueue) === null || _c === void 0 ? void 0 : _c.number),
                            _a)) },
                        React.createElement("div", { className: theme.table__cell }, queue.number),
                        React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_main) }, queue.name),
                        React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_align_right) }, queue.eventCount))),
                React.createElement(ExpansionPanelDetails, { classes: {
                        root: queuesTheme.details
                    } }, queue.categoryInfo.map(function (category) {
                    var _a;
                    return (React.createElement("div", { key: category.number, className: cn(theme.table__line_pointer, queuesTheme.queues__item, (_a = {},
                            _a[theme.table__line_selected] = category.number === props.selectedCategory,
                            _a)), onClick: function () { return props.categoryClickHandler(category.number); } },
                        React.createElement("div", { className: theme.table__cell }, category.number),
                        React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_main) }, category.name),
                        React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_align_right) }, category.eventCount)));
                }))));
        });
    }, [props.selectedQueue, props.queues, props.selectedCategory]);
    return (React.createElement("div", { className: queuesTheme.queues },
        React.createElement("div", { className: cn(queuesTheme.queues__item, queuesTheme.queues__header) },
            React.createElement("div", { className: theme.table__cell }, "#"),
            React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_main) }, t('Name')),
            React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_align_right) }, t('Records'))),
        React.createElement("div", null, ((_a = props.queues) === null || _a === void 0 ? void 0 : _a.length) > 1 ? renderContent() : '')));
};
export default Queues;
