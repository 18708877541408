import * as ScheduleTrigger from './components/ScheduleTrigger/ScheduleTrigger.css';
import * as Day from './components/ScheduleTypes/Calendar/Day/Day.css';
import * as Calendar from './components/ScheduleTypes/Calendar/Calendar.css';
import * as ScheduleModal from './components/ScheduleModal/ScheduleModal.css';
import * as ScheduleTypeSelector from './components/ScheduleTypeSelector/ScheduleTypeSelector.css';
import * as List from './components/ScheduleTypes/List/List.css';
import * as ScheduleFlight from './components/ScheduleFlight/ScheduleFlight.css';
import * as WeekDays from './components/ScheduleFlight/components/WeekDays/WeekDays.css';
import * as FlightDates from './components/ScheduleFlight/components/FlightDates/FlightDates.css';
import * as CalendarPopover from './components/ScheduleFlight/components/CalendarPopover/CalendarPopover.css';
export default {
    ScheduleTrigger: ScheduleTrigger,
    Day: Day,
    Calendar: Calendar,
    ScheduleModal: ScheduleModal,
    ScheduleTypeSelector: ScheduleTypeSelector,
    List: List,
    ScheduleFlight: ScheduleFlight,
    WeekDays: WeekDays,
    FlightDates: FlightDates,
    CalendarPopover: CalendarPopover
};
