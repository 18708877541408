export var FilterAttrs;
(function (FilterAttrs) {
    FilterAttrs["AIRLINE_CODE"] = "AIRLINE_CODE";
    FilterAttrs["FLIGHT_NUM"] = "FLIGHT_NUM";
    FilterAttrs["BEGIN_PERIOD"] = "BEGIN_PERIOD";
    FilterAttrs["END_PERIOD"] = "END_PERIOD";
    FilterAttrs["PERIOD"] = "PERIOD";
    FilterAttrs["WEEK_DAYS"] = "WEEK_DAYS";
    FilterAttrs["NON_STOP"] = "NON_STOP";
})(FilterAttrs || (FilterAttrs = {}));
