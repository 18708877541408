import { __assign } from "tslib";
import * as React from 'react';
import { memo, useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form';
import Input from '../../../Input';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../utils';
import PhoneInput from '../../../PhoneInput';
import { useTheme } from '../../../theme';
import { InputType } from '../../../Passenger/types';
import { validate } from '../../../Passenger/utils';
import { LoginType } from '../../../__generated__/globalTypes';
import FieldsOrder from '../FieldsOrder/FieldsOrder';
import { useMediaQuery } from 'react-responsive';
import { CloseClear } from '../../../Icons';
import { useConfig } from '../../../context';
import { escapedT } from '../utils';
import { IconButton, InputAdornment } from '@material-ui/core';
initI18n('Contacts');
var MainContacts = memo(function (props) {
    var _a, _b, _c;
    var theme = useTheme('Contacts').ContactsStyles;
    var isTerminal = ((_c = (_b = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.iataCode) === 'TERMINAL';
    var t = useTranslation('Contacts').t;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var form = useForm();
    var contactsFields = props.contacts.map(function (contact) {
        return {
            phone: contact.phone
                ? useField(contact.phone.name, {
                    type: contact.phone.input,
                    initialValue: contact.phone.defaultValue,
                    validate: function (value) { return t(escapedT(validate(value, {}, InputType.Phone, contact.phone.validations))); }
                })
                : null,
            email: contact.email
                ? useField(contact.email.name, {
                    type: contact.email.input,
                    initialValue: contact.email.defaultValue,
                    validate: function (value) { return t(escapedT(validate(value, {}, InputType.Text, contact.email.validations))); }
                })
                : null
        };
    });
    var renderClearAdornment = useCallback(function (field) {
        if (!field.value || props.isInputsReadonly) {
            return null;
        }
        return (React.createElement(InputAdornment, { position: 'end', className: theme.clearAdornment }, React.createElement(IconButton, { onClick: function () {
                form.change(field.name, undefined);
                form.mutators.setFieldTouchedMutator(field.name);
            }, size: 'small' }, CloseClear)));
    }, []);
    return (React.createElement(React.Fragment, null, contactsFields.map(function (contacts, index) {
        var _a;
        return (React.createElement("div", { className: cn(theme.fields, (_a = {}, _a[theme.fields__otherContacts] = isTerminal, _a)), key: index },
            React.createElement(FieldsOrder, { reverse: !isTerminal && props.loginType === LoginType.Email },
                React.createElement("div", { className: theme.field }, contacts.phone && (React.createElement(PhoneInput, { disabled: props.isInputsReadonly, className: theme.field__contact, value: contacts.phone.input.value, textFieldsProps: __assign(__assign({}, contacts.phone.input), { variant: isMobile ? 'standard' : 'filled', disabled: props.isEmailChecking || props.isInputsReadonly, label: t('Phone'), type: 'tel', helperText: ((contacts.phone.meta.modified && contacts.phone.meta.touched) ||
                            contacts.phone.meta.submitFailed) &&
                            (contacts.phone.meta.error || contacts.phone.meta.submitError), error: ((contacts.phone.meta.modified && contacts.phone.meta.touched) ||
                            contacts.phone.meta.submitFailed) &&
                            (!!contacts.phone.meta.error || !!contacts.phone.meta.submitError) }), endAdornment: isTerminal ? renderClearAdornment(contacts.phone.input) : null }))),
                React.createElement("div", { className: theme.field }, contacts.email && (React.createElement(Input, { variant: isMobile ? 'standard' : 'filled', absoluteHintPosition: true, inputClassName: theme.field__contact, disabled: props.isEmailChecking || props.isInputsReadonly, autoFocus: !isTerminal && props.loginType === LoginType.Email, TextFieldProps: __assign(__assign({}, contacts.email.input), { label: t('Email'), type: 'email', helperText: ((contacts.email.meta.modified && contacts.email.meta.touched) ||
                            contacts.email.meta.submitFailed) &&
                            (contacts.email.meta.error || contacts.email.meta.submitError), error: ((contacts.email.meta.modified && contacts.email.meta.touched) ||
                            contacts.email.meta.submitFailed) &&
                            (!!contacts.email.meta.error || !!contacts.email.meta.submitError), onChange: function (e) {
                            return props.onChangeHandler(e, contacts.email);
                        } }), endAdornment: isTerminal ? renderClearAdornment(contacts.email.input) : null }))))));
    })));
});
export default MainContacts;
