import { __assign } from "tslib";
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../theme';
import Segment from '../../../DesktopFlightRoute/components/Segment/Segment';
import { format } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { useIsTerminal } from '../../../hooks';
var SegmentInfo = function (props) {
    var _a, _b;
    var css = useTheme('FlightSelect').SegmentInfo;
    var t = useTranslation('FlightSelect').t;
    var segment = props.segment.segment, isSingleSegment = props.isSingleSegment, isSelected = props.isSelected, isShowDateColumn = props.isShowDateColumn, isShowPlaneInfo = props.isShowPlaneInfo;
    var isTerminal = useIsTerminal();
    var marketingAirlineIata = (_a = segment.marketingAirline) === null || _a === void 0 ? void 0 : _a.iata;
    var operatingAirlineIata = (_b = segment.operatingAirline) === null || _b === void 0 ? void 0 : _b.iata;
    var operatingFlightNumber = segment.operatingAirlineFlightNumber;
    var flightNumber = segment.flightNumber;
    var departureDate = segment.departure.date;
    var routeInfoStyles = useMemo(function () {
        var _a;
        return ({
            DesktopFlightRoute: {
                SegmentStyles: {
                    segment: cn(css.segment, (_a = {}, _a[css.multiple] = !isSingleSegment, _a)),
                    route__info: css.routeInfo,
                    plane: css.plane,
                    planeInfo: css.amenities,
                    totalDuration: css.total_duration
                },
                StopsInfoStyles: {
                    stop: css.stop
                }
            },
            MobileSegmentInfo: {
                MobileSegmentFlat: {
                    city: css.city,
                    flightNumber__route: css.route_flight,
                    duration__text: css.duration,
                    row_last: css.row_last,
                    row_first: css.row_first,
                    time_arr: css.time,
                    time_dep: css.time
                }
            }
        });
    }, []);
    var renderFlightInfo = useCallback(function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            isTerminal && isShowDateColumn && (React.createElement("div", { className: css.flight__dateColumn },
                React.createElement("div", null,
                    React.createElement("div", null, format(departureDate, 'dd')),
                    React.createElement("div", null, format(departureDate, 'MMM'))))),
            React.createElement("div", { className: css.flight_number },
                React.createElement("div", { className: css.flight_number__inner },
                    isSelected && !isShowDateColumn && (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: css.flight__date }, format(departureDate, 'dd MMM')))),
                    React.createElement("span", { className: cn((_a = {},
                            _a[css.flightNumber__selected] = isSelected,
                            _a)) },
                        marketingAirlineIata,
                        "-",
                        flightNumber),
                    isTerminal && marketingAirlineIata !== operatingAirlineIata && (React.createElement("div", { className: css.flight__airline },
                        React.createElement("div", null, t('Is operated by')),
                        React.createElement("div", null,
                            operatingAirlineIata,
                            "-",
                            operatingFlightNumber)))))));
    }, [segment]);
    return (React.createElement(ThemeProvider, { value: routeInfoStyles },
        React.createElement(Segment, __assign({ renderFlightInfo: renderFlightInfo, isShowPlaneInfo: isShowPlaneInfo }, props))));
};
export default SegmentInfo;
