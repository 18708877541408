import { __read } from "tslib";
import { useEffect, useState } from 'react';
import { getBaggageServices, getOrder, getOrderId, getRecheckAvailabilityTimout, getTravellers } from './store/order/selectors';
import * as RestoreOrder from '../__queries__/RestoreOrder.graphql';
import { useMutation } from '@apollo/react-hooks';
import { fillOrder } from './store/order/actions';
import { useDispatch, useSelector } from 'react-redux';
import { BAGGAGE_RECOMMENDATION_SHOWN, get, set } from '../cache';
import { setMultipleSelectedServices } from './store/selectedServices/actions';
import { OrderAdditionalServiceGdsServiceServiceType } from '../__generated__/graphql';
import { OrderStatus } from '../__generated__/globalTypes';
export var usePreselectedBaggageSelect = function (selectBaggageWithoutModal) {
    var dispatch = useDispatch();
    var orderId = useSelector(getOrderId);
    var travellers = useSelector(getTravellers);
    var baggageServices = useSelector(getBaggageServices);
    var baggageServicesIds = baggageServices.map(function (service) { return service.id; });
    var localStorageKey = "".concat(BAGGAGE_RECOMMENDATION_SHOWN, "_").concat(orderId);
    var recommendationWasShown = get(localStorageKey);
    var handleSelectAllServices = function () {
        var servicesToSelect = [];
        travellers.forEach(function (traveller) {
            var _a;
            (_a = traveller.preselectedServices.gdsServices.services) === null || _a === void 0 ? void 0 : _a.forEach(function (service) {
                if (!baggageServicesIds.includes(service.serviceId)) {
                    return;
                }
                var orderBaggage = baggageServices.find(function (orderBaggage) { return orderBaggage.id === service.serviceId; });
                servicesToSelect.push({
                    service: {
                        id: service.serviceId,
                        type: OrderAdditionalServiceGdsServiceServiceType.Baggage,
                        confirmedCount: service.confirmedCount,
                        price: orderBaggage.price
                    },
                    segmentIds: service.segmentIds,
                    serviceId: service.serviceId,
                    count: service.count,
                    passengerId: traveller.id,
                    segmentId: service.segmentIds[0]
                });
            });
        });
        if (servicesToSelect.length) {
            dispatch(setMultipleSelectedServices(servicesToSelect));
        }
    };
    useEffect(function () {
        if (!recommendationWasShown) {
            set(localStorageKey, true);
            if (selectBaggageWithoutModal) {
                handleSelectAllServices();
            }
        }
    }, [recommendationWasShown]);
};
export var useAvailabilityRecheck = function (setActualizationOpen) {
    var timeoutToReCheckAvailability = useSelector(getRecheckAvailabilityTimout) * 1000;
    var orderId = useSelector(getOrderId);
    var orderStatus = useSelector(getOrder).status;
    var _a = __read(useState(null), 2), timeout = _a[0], setNewTimeout = _a[1];
    var dispatch = useDispatch();
    var needToCheckAvailability = orderStatus === OrderStatus.New || orderStatus === OrderStatus.Booked;
    var _b = __read(useMutation(RestoreOrder.CheckOrderAvailability, { fetchPolicy: 'no-cache' }), 2), recheckAvailability = _b[0], data = _b[1].data;
    var recheckIfTimoutExceed = function () {
        if (needToCheckAvailability) {
            recheckAvailability({ variables: { id: orderId } });
        }
    };
    useEffect(function () {
        var _a, _b;
        if (!(data === null || data === void 0 ? void 0 : data.CheckOrderAvailability)) {
            return function () { return setActualizationOpen(false); };
        }
        if (((_a = data === null || data === void 0 ? void 0 : data.CheckOrderAvailability) === null || _a === void 0 ? void 0 : _a.status) === OrderStatus.Confirmed) {
            clearTimeout(timeout);
            setNewTimeout(null);
            return function () { return setActualizationOpen(false); };
        }
        if (((_b = data === null || data === void 0 ? void 0 : data.CheckOrderAvailability) === null || _b === void 0 ? void 0 : _b.status) === OrderStatus.Cancelled) {
            clearTimeout(timeout);
            setNewTimeout(null);
            return function () { return setActualizationOpen(false); };
        }
        dispatch(fillOrder(data.CheckOrderAvailability));
        clearTimeout(timeout);
        setNewTimeout(null);
        if (timeoutToReCheckAvailability === 0) {
            setNewTimeout(setTimeout(recheckIfTimoutExceed, timeoutToReCheckAvailability));
        }
        setActualizationOpen(true);
        return function () {
            setActualizationOpen(false);
        };
    }, [timeoutToReCheckAvailability]);
    useEffect(function () {
        if (!timeout) {
            setNewTimeout(setTimeout(recheckIfTimoutExceed, timeoutToReCheckAvailability));
        }
    }, [timeout, timeoutToReCheckAvailability]);
};
