import { __assign } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import MealPage from '../../../../MealPage';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../../__generated__/globalTypes';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import { setNextStep } from '../../../store/checkout/actions';
import { getPassengerLabels } from '../../../store/passengers/selectors';
import { getMeals, getRecommendedMeals, getSegmentsForServices, hasBaggageServices, hasSeatsServices, isConfirmed, getShowMealRecommendations, getOrderId, getTravellers, getServices, getBonusForFlight, getPriceInMiles } from '../../../store/order/selectors';
import { fillOrder } from '../../../store/order/actions';
import { MEAL_RECOMMENDATION_SHOWN, set } from '../../../../cache';
import ServicesToolbar from '../../../../ServicesToolbar';
import { getMealsPriceToPay, getTotalPriceToPay } from '../../../store/orderPrice/selectors';
import { addService, removeService, deleteAllTypesOnSegment, setService } from '../../../store/selectedServices/actions';
import { getPassengerStateCompatible } from '../../../store/selectedServices/selectors';
import { useMemo } from 'react';
import { getMealReduxCallbacks } from '../../../utils';
var Meal = function (_a) {
    var totalPrice = _a.totalPrice, passengersState = _a.passengersState, setNextStep = _a.setNextStep, goToPrevStep = _a.goToPrevStep, hasBaggageStep = _a.hasBaggageStep, hasSeatsStep = _a.hasSeatsStep, isConfirmed = _a.isConfirmed, passengerLabels = _a.passengerLabels, mealTotalSum = _a.mealTotalSum, addService = _a.addService, setService = _a.setService, removeService = _a.removeService, deleteAllTypesOnSegment = _a.deleteAllTypesOnSegment, services = _a.services;
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').CheckoutStyles;
    var segments = useSelector(getSegmentsForServices);
    var meals = useSelector(getMeals);
    var recommendedMeals = useSelector(getRecommendedMeals);
    var showRecommendations = useSelector(getShowMealRecommendations);
    var orderId = useSelector(getOrderId);
    var travellers = useSelector(getTravellers);
    var bonusForFlight = useSelector(getBonusForFlight);
    var priceInMiles = useSelector(getPriceInMiles);
    var selectedMeals = [];
    passengersState.forEach(function (passenger) {
        if (passenger.services) {
            passenger.services.forEach(function (product) {
                var serviceObject = services.find(function (_a) {
                    var id = _a.id;
                    return id === product.serviceId;
                });
                if (serviceObject && serviceObject.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
                    selectedMeals.push({
                        passengerId: passenger.id,
                        segmentId: product.segmentIds[0],
                        mealId: product.serviceId,
                        count: product.count,
                        confirmedCount: product.confirmedCount
                    });
                }
            });
        }
    });
    // Do not show recommendations twice.
    var cacheRecommendationState = function () { return set("".concat(MEAL_RECOMMENDATION_SHOWN, "_").concat(orderId), true); };
    var mealCallbacks = useMemo(function () { return getMealReduxCallbacks(addService, removeService, deleteAllTypesOnSegment); }, []);
    return (React.createElement(ServicesToolbar, { toolbarProps: {
            totalPrice: totalPrice,
            onButtonClick: setNextStep,
            onBackButtonClick: hasSeatsStep || hasBaggageStep ? goToPrevStep : null,
            additional: mealTotalSum.amount ? (React.createElement("div", { className: css.gdsTotalPrice },
                t('Meal'),
                ": ",
                React.createElement(Money, { money: mealTotalSum }))) : null,
            miles: bonusForFlight,
            priceInMiles: priceInMiles
        } },
        React.createElement(MealPage, __assign({ segments: segments, passengers: passengersState.map(function (_a) {
                var id = _a.id;
                return ({
                    id: id,
                    name: passengerLabels[id]
                });
            }), readonly: isConfirmed, showRecommendations: showRecommendations, meals: meals, selectedMeals: selectedMeals, recommendedMeals: recommendedMeals, onMobileProceed: setNextStep, onMobileBack: goToPrevStep, onRecommendationConfirm: function () {
                travellers.map(function (_a) {
                    var preselectedServices = _a.preselectedServices, id = _a.id;
                    preselectedServices.gdsServices.services
                        .filter(function (service) { return meals.some(function (meal) { return meal.id === service.serviceId; }); })
                        .forEach(function (service) {
                        setService({
                            passengerId: id,
                            count: service.count,
                            segmentId: service.segmentIds[0],
                            allowedSegments: [service.segmentIds],
                            serviceId: service.serviceId,
                            service: {
                                id: service.serviceId,
                                type: OrderAdditionalServiceGdsServiceServiceType.Meal
                            }
                        });
                    });
                    cacheRecommendationState();
                });
            }, onRecommendationDisagree: cacheRecommendationState }, mealCallbacks))));
};
var mapStateToProps = function () {
    return function (state, ownProps) { return (__assign(__assign({}, ownProps), { isConfirmed: isConfirmed(state), passengersState: getPassengerStateCompatible(state), totalPrice: getTotalPriceToPay(state), hasSeatsStep: hasSeatsServices(state), hasBaggageStep: hasBaggageServices(state), passengerLabels: getPassengerLabels(state), mealTotalSum: getMealsPriceToPay(state), services: getServices(state) })); };
};
var mapDispatchToProps = {
    setNextStep: setNextStep,
    fillOrder: fillOrder,
    addService: addService,
    setService: setService,
    removeService: removeService,
    deleteAllTypesOnSegment: deleteAllTypesOnSegment
};
export default connect(mapStateToProps, mapDispatchToProps)(Meal);
