import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import Amenities from '../../Amenities';
import { InfoIcon } from './Icons';
import Tooltip from '../../Tooltip';
import Details from '../../DesktopFlightInfo/components/Segment/Details/Details';
import { initI18n } from '../../utils';
initI18n('FlightCard');
var FlightCard = function (_a) {
    var _b, _c;
    var airline = _a.airline, flightNumber = _a.flightNumber, duration = _a.duration, aircraft = _a.aircraft, amenities = _a.amenities, statistics = _a.statistics, onAircarftInfoClick = _a.onAircarftInfoClick, className = _a.className, markers = _a.markers;
    var css = useTheme('FlightCard').FlightCard;
    var t = useTranslation('FlightCard').t;
    var hasAmenities = (amenities === null || amenities === void 0 ? void 0 : amenities.length) > 0;
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.airline },
                React.createElement("img", { className: css.airline__logo, src: airline.icon, alt: airline.name }),
                React.createElement("span", { className: css.airline__name }, airline.name)),
            React.createElement("div", { className: css.flight },
                React.createElement("div", { className: css.flight__block },
                    React.createElement("div", { className: css.flight__title }, t('Flight')),
                    React.createElement("div", { className: css.flight__value }, flightNumber)),
                React.createElement("div", { className: css.flight__block },
                    React.createElement("div", { className: css.flight__title }, t('Duration')),
                    React.createElement("div", { className: css.flight__value }, duration)))),
        React.createElement("div", { className: css.aircraft },
            React.createElement("img", { className: css.aircraft__image, src: aircraft.icon, alt: aircraft.name })),
        React.createElement("div", { className: cn(css.footer, (_b = {},
                _b[css.footer_centered] = !hasAmenities,
                _b)) },
            hasAmenities && React.createElement(Amenities, { className: css.amenities, amenities: amenities, isEco: markers === null || markers === void 0 ? void 0 : markers.eco }),
            React.createElement("div", { className: cn(css.aircraft__name, (_c = {},
                    _c[css.aircraft__name_active] = !!onAircarftInfoClick,
                    _c)), onClick: onAircarftInfoClick ? function () { return onAircarftInfoClick(aircraft.id); } : null },
                aircraft.name,
                !!(statistics === null || statistics === void 0 ? void 0 : statistics.length) && (React.createElement(Tooltip, { title: React.createElement(Details, { items: statistics.map(function (item) { return ({
                            label: item.title,
                            value: item.value
                        }); }) }) },
                    React.createElement("span", { className: css.info }, InfoIcon)))))));
};
export default FlightCard;
