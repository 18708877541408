import React from 'react';
import { useTheme } from '../../../theme';
import { MagnifierIcon } from '../../Icons';
import { useSearchDataDate, useSearchDataPassengers, useSearchDataSegments } from '../../hooks';
import { Airplane, Person, Exchangeable } from '../../../Icons';
var SearchData = function (props) {
    var css = useTheme('Header').SearchData;
    var segments = useSearchDataSegments(props.searchParameters.segments);
    var date = useSearchDataDate(props.searchParameters.segments);
    var passengers = useSearchDataPassengers(props.searchParameters.passengers);
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.icon }, Airplane),
        React.createElement("div", { className: css.section }, segments),
        React.createElement("div", { className: css.icon }, Person),
        React.createElement("div", { className: css.section }, passengers),
        React.createElement("div", { className: css.icon }, Exchangeable),
        React.createElement("div", { className: css.section }, date),
        React.createElement("div", { className: css.icon_wrapper }, MagnifierIcon)));
};
export default SearchData;
