import * as React from 'react';
import { useForm } from 'react-final-form';
import { useEffect } from 'react';
var WindowScope = function (props) {
    var form = useForm();
    var changePassengerField = function (fieldName, passengerId, newValue) {
        if (props.fieldsMap.hasOwnProperty(fieldName) && !props.fieldsMap[fieldName].readonly && !props.disabled) {
            var fieldPath = "passengers[".concat(passengerId, "].").concat(fieldName);
            form.change(fieldPath, newValue);
            form.mutators.setFieldTouchedMutator(fieldPath);
            return true;
        }
        return false;
    };
    useEffect(function () {
        window.changePassengerField = changePassengerField;
        return function () {
            window.changePassengerField = null;
        };
    }, []);
    return React.createElement(React.Fragment, null, props.children);
};
export default WindowScope;
