import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { connect, useSelector } from 'react-redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isBefore, parseISO } from 'date-fns';
import { StepType } from '../../types';
import * as ResetOrderPaymentBlockMutation from '../../../__queries__/ResetOrderPaymentBlock.graphql';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../utils';
import Stepbar from '../../../Stepbar/components/Stepbar';
import MobileStepbar from '../../../MobileStepbar/MobileStepbarWrapper';
import PromoLoader from '../../../PromoLoader/components/PromoLoader';
import { setStep } from '../../store/checkout/actions';
import { getSteps } from '../../store/checkout/selectors';
import { ActualizationStatus, OrderStatus, PaymentStatusEnum } from '../../../__generated__/globalTypes';
import { convertSearchParameters, SearchPassengersContext } from '../../../Results/utils';
import { ThemeProvider, useTheme } from '../../../theme';
import Seats from './routes/Seats';
import Baggage from './routes/Baggage';
import Meal from './routes/Meal';
import Passengers from './routes/Passengers';
import { getIsFareLockSelected, getIsTravellersDataFilled, getOrder, getSearchParameters, isAllSegmentsAvailableOnlyOnCheckin, isBookedOrConfirmed, isCancelled, isConfirmedOrCancelled, isNew, isOrderBlockedForPayment, isRedirectFromMetasearch, getHasAvailableSeats } from '../../store/order/selectors';
import { analyzePurchasedProducts, fillOrder } from '../../store/order/actions';
import { useMutation } from '@apollo/react-hooks';
import PaymentTimeLimitPage from '../../../PaymentTimeLimitPage';
import Payment from './routes/Payment';
import PaymentResult from '../../../PaymentResult';
import QuickSearchForm from '../../../QuickSearchForm';
import ToggleableFlights from './ToggleableFlights/ToggleableFlights';
import Actualization from './Actualization/Actualization';
import { CheckoutSteps, MobileStepbarMode } from '../../../MobileStepbar/types';
import { OrderGoal, reachGoal } from '../../../analytics';
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute';
import { getPriceToPay } from '../../store/orderPrice/selectors';
import { removeServicesStepsByTypes } from '../../utils';
import { useOrder } from '../../../__queries__/Order';
import SimpleLoader from '../../../SimpleLoader';
import Modal from '../../../Modal';
import SlideBottom from '../../../SlideBottom';
import { isServicesSaving } from '../../store/servicesIsSaving/selectors';
// import { useAvailabilityRecheck } from '../../hooks';
import { useConfig } from '../../../context';
import Refund from '../../../Refund';
import Exchange from '../../../Modules/Exchange/components/Module';
import { OverrideComponent } from '../../../renderProps';
import { useIsTerminal, useToggleable } from '../../../hooks';
import Button from '../../../Button/Button';
import Snackbar from '../../../LEO/Snackbar/Snackbar';
var ActualizeOrder = require('../../../__queries__/query.graphql').ActualizeOrder;
initI18n('Checkout');
var checkTimeLimit = function (timelimit) { return isBefore(parseISO(timelimit), new Date()); };
export var PaymentResultStatus;
(function (PaymentResultStatus) {
    PaymentResultStatus["Success"] = "success";
    PaymentResultStatus["Fail"] = "fail";
})(PaymentResultStatus || (PaymentResultStatus = {}));
export var PaymentResultMethodType;
(function (PaymentResultMethodType) {
    PaymentResultMethodType["Modal"] = "modal";
    PaymentResultMethodType["Snackbar"] = "snackbar";
})(PaymentResultMethodType || (PaymentResultMethodType = {}));
var Checkout = function (_a) {
    var _b;
    var setStep = _a.setStep, order = _a.order, fillOrder = _a.fillOrder, match = _a.match, history = _a.history, location = _a.location, searchParameters = _a.searchParameters, startNewSearch = _a.startNewSearch, isReadonly = _a.isReadonly, isConfirmedOrCancelled = _a.isConfirmedOrCancelled, isRedirectFromMetasearch = _a.isRedirectFromMetasearch, isBookedOrConfirmed = _a.isBookedOrConfirmed, analyzePurchasedProducts = _a.analyzePurchasedProducts, toogleHeader = _a.toogleHeader, isHeaderOpen = _a.isHeaderOpen, _c = _a.paymentResultMethodType, paymentResultMethodType = _c === void 0 ? PaymentResultMethodType.Modal : _c;
    var orderId = order.id;
    var t = useTranslation().t;
    var steps = useSelector(getSteps);
    var hasAvailableSeats = useSelector(getHasAvailableSeats);
    var theme = useTheme('Checkout').CheckoutStyles;
    var _d = __read(useState(null), 2), paymentResults = _d[0], setPaymentResults = _d[1];
    var _e = __read(useState(false), 2), paymentResultsModalIsVisible = _e[0], setPaymentResultsModalIsVisible = _e[1];
    var _f = __read(useState(!order.actualizationResult), 2), isLoading = _f[0], setLoading = _f[1];
    var isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    var _g = __read(useState(false), 2), orderIsRefetching = _g[0], setOrderIsRefetching = _g[1];
    var _h = __read(useMutation(ActualizeOrder), 1), actualization = _h[0];
    var _j = useToggleable(false), isRefundInfoVisible = _j.isOpen, openRefundInfo = _j.open, closeRefundInfo = _j.close;
    var _k = __read(useState(isBookedOrConfirmed && checkTimeLimit(order.timelimit)), 2), timelimitHasPast = _k[0], setTimelimitHasPast = _k[1];
    var priceToPay = useSelector(getPriceToPay);
    var _l = __read(useState(false), 2), showActualization = _l[0], setShowActualization = _l[1];
    var _m = __read(useState(null), 2), bookingErr = _m[0], setBookingErr = _m[1];
    var isOrderCancelled = useSelector(isCancelled);
    var isOrderServicesSaving = useSelector(isServicesSaving);
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _o = useOrder(orderId, {
        skip: true,
        fetchPolicy: 'no-cache'
    }), refetchOrder = _o.refetch, refetchOrderLoading = _o.loading;
    var isBlockedForPayment = useSelector(isOrderBlockedForPayment);
    var _p = __read(useMutation(ResetOrderPaymentBlockMutation.ResetOrderPaymentBlock), 2), resetOrderPaymentBlock = _p[0], resetOrderLoading = _p[1].loading;
    var _q = __read(useState(resetOrderLoading), 2), isResetOrderPaymentBlockLoading = _q[0], setResetOrderPaymentBlockLoading = _q[1];
    var isFareLockSelected = useSelector(getIsFareLockSelected);
    var filteredSteps = useMemo(function () {
        if (!hasAvailableSeats) {
            return removeServicesStepsByTypes(steps, [StepType.Seats]);
        }
        else if (isMobile && isRedirectFromMetasearch) {
            return removeServicesStepsByTypes(steps, [StepType.Seats, StepType.Meals, StepType.Baggage]);
        }
        return steps;
    }, [isMobile, isRedirectFromMetasearch, steps]);
    var isAllSegmentsAvailableOnCheckin = useSelector(isAllSegmentsAvailableOnlyOnCheckin);
    // useAvailabilityRecheck(setShowActualization);
    var showIsActualizationResultOk = useConfig().global.showIsActualizationResultOk;
    useEffect(function () {
        setResetOrderPaymentBlockLoading(resetOrderLoading);
    }, [resetOrderLoading]);
    var closePaymentResultsModal = function () {
        setPaymentResultsModalIsVisible(false);
        history.push("".concat(baseUrl, "/passengers"));
        setTimeout(function () { return setPaymentResults(null); }, 300);
    };
    var startActualize = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, actualization({
                            variables: {
                                id: orderId
                            }
                        })];
                case 1:
                    data = _b.sent();
                    if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.ActualizeOrder) {
                        fillOrder(data.data.ActualizeOrder);
                    }
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var refetchOrderAfterPayment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var initialInterval, step, data, isFullyPayedOrder, polling_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    initialInterval = 500;
                    step = 1.5;
                    setOrderIsRefetching(true);
                    return [4 /*yield*/, refetchOrder()];
                case 1:
                    data = _e.sent();
                    isFullyPayedOrder = ((_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.Order) === null || _b === void 0 ? void 0 : _b.status) === OrderStatus.Confirmed &&
                        ((_d = (_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.Order) === null || _d === void 0 ? void 0 : _d.paymentStatus) === PaymentStatusEnum.Full;
                    // Check if order is in the correct status.
                    // Checkin if PaymentStatus === Full too
                    if (isFullyPayedOrder) {
                        fillOrder(data.data.Order);
                        setPaymentResultsModalIsVisible(true);
                        setOrderIsRefetching(false);
                    }
                    // If not - make a polling until the status is correct.
                    // Checkin if PaymentStatus === Full too
                    else {
                        polling_1 = function (interval) { return __awaiter(void 0, void 0, void 0, function () {
                            var data_1, isFullyPayedOrder_1, isPayedPriceFreeze, e_1;
                            var _a, _b, _c, _d, _e, _f, _g;
                            return __generator(this, function (_h) {
                                switch (_h.label) {
                                    case 0:
                                        _h.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, refetchOrder()];
                                    case 1:
                                        data_1 = _h.sent();
                                        isFullyPayedOrder_1 = ((_b = (_a = data_1 === null || data_1 === void 0 ? void 0 : data_1.data) === null || _a === void 0 ? void 0 : _a.Order) === null || _b === void 0 ? void 0 : _b.status) === OrderStatus.Confirmed &&
                                            ((_d = (_c = data_1 === null || data_1 === void 0 ? void 0 : data_1.data) === null || _c === void 0 ? void 0 : _c.Order) === null || _d === void 0 ? void 0 : _d.paymentStatus) === PaymentStatusEnum.Full;
                                        isPayedPriceFreeze = ((_e = data_1 === null || data_1 === void 0 ? void 0 : data_1.data) === null || _e === void 0 ? void 0 : _e.Order.isPriceFreezeProductSelected) &&
                                            !isTravellersDataFilled &&
                                            ((_g = (_f = data_1 === null || data_1 === void 0 ? void 0 : data_1.data) === null || _f === void 0 ? void 0 : _f.Order) === null || _g === void 0 ? void 0 : _g.paymentStatus) === PaymentStatusEnum.Part;
                                        if (isFullyPayedOrder_1 || isPayedPriceFreeze) {
                                            fillOrder(data_1.data.Order);
                                            setPaymentResultsModalIsVisible(true);
                                            setOrderIsRefetching(false);
                                        }
                                        else {
                                            setTimeout(function () { return polling_1(interval * step); }, interval * step);
                                        }
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_1 = _h.sent();
                                        setOrderIsRefetching(false);
                                        console.warn(e_1);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); };
                        setTimeout(function () { return polling_1(initialInterval); }, initialInterval);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var baseUrl = match.url;
    var currentStep = filteredSteps.find(function (step) { return location.pathname === "".concat(baseUrl, "/").concat(step.url); });
    var refundURL = "".concat(baseUrl, "/refund");
    var exchangeURL = "".concat(baseUrl, "/exchange");
    var fallbackUrl = "".concat(baseUrl, "/passengers");
    var isPayment = location.pathname === "".concat(baseUrl, "/payment");
    var isPassengers = location.pathname === "".concat(baseUrl, "/passengers");
    var retrySearch = startNewSearch.bind(null, convertSearchParameters(searchParameters));
    var backToResults = function () {
        if (order.flight.searchId) {
            history.push("/results/".concat(order.flight.searchId));
        }
        else {
            retrySearch();
        }
    };
    var isTerminal = useIsTerminal();
    var goToRefund = function () {
        if (isTerminal) {
            openRefundInfo();
        }
        else {
            history.push(refundURL);
        }
    };
    var goToExchange = function () {
        if (isHeaderOpen && toogleHeader) {
            toogleHeader();
        }
        history.push(exchangeURL);
    };
    var goToPayment = useCallback(function () {
        setPaymentResultsModalIsVisible(false);
        history.push("".concat(baseUrl, "/payment"));
    }, [paymentResults]);
    useEffect(function () {
        var timer;
        if (isBookedOrConfirmed) {
            setTimelimitHasPast(checkTimeLimit(order.timelimit));
            timer = window.setInterval(function () {
                if (checkTimeLimit(order.timelimit)) {
                    clearInterval(timer);
                    setTimelimitHasPast(true);
                }
            }, 2000);
        }
        return function () { return clearInterval(timer); };
    }, [order]);
    useEffect(function () {
        var _a;
        if (!currentStep && order.actualizationResult && !isReadonly) {
            history.replace(order.status === OrderStatus.New ? "".concat(baseUrl, "/").concat(filteredSteps[0].url) : fallbackUrl);
        }
        if (!isBookedOrConfirmed &&
            ((_a = order.actualizationResult) === null || _a === void 0 ? void 0 : _a.status) === ActualizationStatus.NotAvailable &&
            order.status === OrderStatus.New) {
            setShowActualization(true);
        }
        if (!order.actualizationResult) {
            startActualize();
        }
    }, []);
    useEffect(function () {
        if (paymentResults === PaymentResultStatus.Success) {
            analyzePurchasedProducts();
            reachGoal(OrderGoal.Paid);
            refetchOrderAfterPayment();
        }
        else if (paymentResults === PaymentResultStatus.Fail) {
            reachGoal(OrderGoal.PaymentFailed);
            setPaymentResultsModalIsVisible(true);
        }
    }, [paymentResults]);
    var stepbarConfig = {
        Flights: {
            label: t('Stepbar:Flights'),
            type: CheckoutSteps.Flights,
            onClick: backToResults
        }
    };
    filteredSteps.forEach(function (step) {
        stepbarConfig[step.type] = {
            label: t("Stepbar:".concat(step.type)),
            type: CheckoutSteps[step.type],
            onClick: function () { return setStep(step.type); }
        };
    });
    var getCurrentStep = function () { return filteredSteps.findIndex(function (step) { return location.pathname.search(step.url) >= 0; }) + 1; };
    var goToPrevStep = useCallback(function () { return history.goBack(); }, [history]);
    var goToPassengers = function (reload) {
        setStep(StepType.Passengers);
        if (reload) {
            window.location.reload();
        }
    };
    var newToggleAbleFlightsTheme = {
        Checkout: {
            ToggleableFlights: { flight: theme.flight, flights: theme.flights }
        }
    };
    var onPaymentSuccess = useCallback(function (reload) {
        reachGoal(OrderGoal.Paid);
        analyzePurchasedProducts();
        goToPassengers(reload);
    }, []);
    var handlePaymentMethodsLoaded = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, refetchOrder({ id: orderId })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (!(errors === null || errors === void 0 ? void 0 : errors.length) && (data === null || data === void 0 ? void 0 : data.Order)) {
                        fillOrder(data.Order);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [isBlockedForPayment]);
    var handleRefetchCheckoutOrder = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, refetchOrder({ id: orderId })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (!(errors === null || errors === void 0 ? void 0 : errors.length) && (data === null || data === void 0 ? void 0 : data.Order)) {
                        fillOrder(data.Order);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [refetchOrder, orderId]);
    var handleReturnToOrder = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, resetOrderPaymentBlock({ variables: { id: orderId } })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, refetchOrder({ id: orderId })];
                case 2:
                    data = (_a.sent()).data;
                    if (data === null || data === void 0 ? void 0 : data.Order) {
                        fillOrder(data.Order);
                        history.push("".concat(baseUrl, "/passengers"));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [isBlockedForPayment]);
    var openOrder = function (newOrderId) {
        setLoading(true);
        window.location.href = window.location.href.replace(orderId, newOrderId);
        window.location.reload();
    };
    return (React.createElement("div", { className: cn(theme.container, (_b = {},
            _b[theme.container_locked] = !isLoading && timelimitHasPast,
            _b[theme.container_seats] = !timelimitHasPast && currentStep && currentStep.type === StepType.Seats,
            _b)) },
        !isBookedOrConfirmed && (React.createElement(Actualization, { startBooking: function () { return null; }, orderId: orderId, open: showActualization, showOkMessage: showIsActualizationResultOk, onClose: function () { return setShowActualization(false); }, onSetOrder: fillOrder, actualizationInfo: order.actualizationResult, backToResults: backToResults })),
        React.createElement(ProtectedRoute, { isAccessible: isConfirmedOrCancelled, redirectTo: fallbackUrl, path: "".concat(baseUrl, "/refund"), render: function () { return (React.createElement(React.Fragment, null,
                refetchOrderLoading && React.createElement(PromoLoader, null),
                !refetchOrderLoading && (React.createElement(Refund, { order: order, onReloadCheckoutOrder: handleRefetchCheckoutOrder })))); } }),
        isLoading && React.createElement(PromoLoader, null),
        (orderIsRefetching || isOrderServicesSaving || isResetOrderPaymentBlockLoading) && React.createElement(SimpleLoader, null),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: paymentResultsModalIsVisible, onClose: closePaymentResultsModal, className: theme.payment__slideBottom },
                paymentResults === 'success' && (React.createElement(PaymentResult, { isModal: true, result: paymentResults, onClick: closePaymentResultsModal })),
                paymentResults === 'fail' && (React.createElement(PaymentResult, { isModal: true, result: paymentResults, onClick: goToPayment })))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            paymentResultMethodType === 'modal' && (React.createElement(Modal, { open: paymentResultsModalIsVisible, onClose: closePaymentResultsModal, classes: {
                    paper: theme.payment__result
                } },
                paymentResults === 'success' && (React.createElement(PaymentResult, { isModal: true, result: paymentResults, onClick: closePaymentResultsModal })),
                paymentResults === 'fail' && (React.createElement(PaymentResult, { isModal: true, result: paymentResults, onClick: goToPayment })))),
            paymentResultMethodType === 'snackbar' && (React.createElement(Snackbar, { message: paymentResults === 'success'
                    ? t('PaymentResult:Payment successfully complete')
                    : t('PaymentResult:Payment has failed'), open: paymentResultsModalIsVisible, onClose: closePaymentResultsModal, error: paymentResults === 'success' ? false : true }))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: isRefundInfoVisible, onClose: closeRefundInfo, maxWidth: 'sm', isRounded: true },
                React.createElement("div", { className: theme.refundWarning__content },
                    React.createElement("h3", { className: theme.refundWarning__header }, t('Refund')),
                    React.createElement("div", null, order.canSplit
                        ? t('Click Continue if you are going to refund the tickets for all passengers in the PNR.\n If you want to make a refund for some passengers, return to the PNR and do the Split first.')
                        : t('Click Continue if you are going to refund the tickets for all passengers in the PNR.')),
                    React.createElement(Button, { type: "submit", variant: "flat", size: 'common', onClick: function () {
                            history.push(refundURL);
                            closeRefundInfo();
                        }, className: theme.continueBtn, classes: {
                            root: theme.continueBtn__root
                        } }, t('Continue'))))),
        !isLoading && timelimitHasPast && (React.createElement("div", null,
            React.createElement(PaymentTimeLimitPage, { title: t('PaymentTimeLimit:Sorry,'), text: t('PaymentTimeLimit:time to complete booking has expired'), buttonText: t('PaymentTimeLimit:Repeat search'), onClick: backToResults }))),
        !isLoading && !timelimitHasPast && (React.createElement(React.Fragment, null,
            React.createElement(Route, { exact: true, path: baseUrl, render: function () {
                    return order.status === OrderStatus.New ? (React.createElement(Redirect, { to: "".concat(baseUrl, "/").concat(filteredSteps[0].url) })) : (React.createElement(Redirect, { to: fallbackUrl }));
                } }),
            !isConfirmedOrCancelled && !isPayment && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement(Stepbar, { activeStep: getCurrentStep(), steps: stepbarConfig }))),
            !isConfirmedOrCancelled && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement(MobileStepbar, { mode: MobileStepbarMode.Checkout, currentStep: getCurrentStep(), stepbarConfig: stepbarConfig, onSetOrder: fillOrder }))),
            !isNew && (React.createElement(SearchPassengersContext.Provider, { value: searchParameters === null || searchParameters === void 0 ? void 0 : searchParameters.passengers },
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement(QuickSearchForm, { hidePromoCode: true, parameters: searchParameters, SearchFormProps: { onSubmit: startNewSearch } },
                        React.createElement(ThemeProvider, { value: newToggleAbleFlightsTheme },
                            React.createElement(ToggleableFlights, { noBottomMargin: isPassengers, order: order, backToResults: backToResults })))))),
            React.createElement(ProtectedRoute, { path: "".concat(baseUrl, "/seats"), isAccessible: !isOrderCancelled, redirectTo: fallbackUrl, render: function () {
                    return order.isBlockedForPayment ? (React.createElement(Redirect, { to: "".concat(baseUrl, "/payment") })) : (React.createElement(Seats, { suggestSeatSelection: !isAllSegmentsAvailableOnCheckin, goToPrevStep: goToPrevStep }));
                } }),
            React.createElement(Route, { path: "".concat(baseUrl, "/exchange"), render: function (props) {
                    return React.createElement(Exchange, { order: order, onRefetchCheckoutOrder: handleRefetchCheckoutOrder });
                } }),
            React.createElement(ProtectedRoute, { path: "".concat(baseUrl, "/baggage"), isAccessible: !isOrderCancelled, redirectTo: fallbackUrl, render: function () {
                    return order.isBlockedForPayment ? (React.createElement(Redirect, { to: "".concat(baseUrl, "/payment") })) : (React.createElement(Baggage, { goToPrevStep: goToPrevStep }));
                } }),
            React.createElement(ProtectedRoute, { path: "".concat(baseUrl, "/meal"), isAccessible: !isOrderCancelled, redirectTo: fallbackUrl, render: function () {
                    return order.isBlockedForPayment ? (React.createElement(Redirect, { to: "".concat(baseUrl, "/payment") })) : (React.createElement(Meal, { goToPrevStep: goToPrevStep }));
                } }),
            React.createElement(Route, { path: "".concat(baseUrl, "/passengers"), render: function () {
                    return order.isBlockedForPayment && !isFareLockSelected ? (React.createElement(Redirect, { to: "".concat(baseUrl, "/payment") })) : (React.createElement(Passengers, { goToRefund: goToRefund, goToExchange: goToExchange, showRefundButton: !!order.exareInfo.lastRefund || order.exareInfo.refundAvailability, showExchangeButton: !!order.exareInfo.lastExchange || order.exareInfo.exchangeAvailability, setBookingError: setBookingErr, bookingError: bookingErr, order: order, backToResults: backToResults, onSetOrder: fillOrder, currentStep: currentStep, goToPrevStep: goToPrevStep, refetchOrder: startActualize, openOrder: openOrder }));
                } }),
            React.createElement(ProtectedRoute, { exact: true, path: "".concat(baseUrl, "/payment"), isAccessible: filteredSteps.findIndex(function (step) { return step.type === StepType.Payment; }) !== -1, redirectTo: fallbackUrl, render: function (params) { return (React.createElement(OverrideComponent, { componentProps: {
                        onBackToOrder: isBlockedForPayment && handleReturnToOrder,
                        priceToPay: priceToPay,
                        orderId: orderId,
                        timelimit: new Date(order.timelimit),
                        redirectPathname: params.location.pathname,
                        onPaymentMethodsLoaded: handlePaymentMethodsLoaded,
                        onSuccess: onPaymentSuccess,
                        goToPrevStep: goToPrevStep
                    }, component: { CheckoutPaymentWrapper: Payment } })); } }),
            React.createElement(Route, { path: "".concat(baseUrl, "/payment/successfull"), render: function () {
                    setPaymentResults(PaymentResultStatus.Success);
                    return React.createElement(Redirect, { to: fallbackUrl });
                } }),
            React.createElement(Route, { path: "".concat(baseUrl, "/payment/failed"), render: function () {
                    setPaymentResults(PaymentResultStatus.Fail);
                    setPaymentResultsModalIsVisible(true);
                    return React.createElement(Redirect, { to: fallbackUrl });
                } })))));
};
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { order: getOrder(state), isReadonly: isNew(state), isConfirmedOrCancelled: isConfirmedOrCancelled(state), isRedirectFromMetasearch: isRedirectFromMetasearch(state), isBookedOrConfirmed: isBookedOrConfirmed(state), searchParameters: getSearchParameters(state) })); };
var mapDispatchToProps = {
    setStep: setStep,
    fillOrder: fillOrder,
    analyzePurchasedProducts: analyzePurchasedProducts
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Checkout));
