import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../../../../theme';
import cn from 'classnames';
var WEEKDAY_INDEX_TO_WEEKDAY_MAP = new Map([
    [0, 'Monday'],
    [1, 'Tuesday'],
    [2, 'Wednesday'],
    [3, 'Thursday'],
    [4, 'Friday'],
    [5, 'Saturday'],
    [6, 'Sunday']
]);
var WeekDays = function (props) {
    var weekdays = props.weekdays, className = props.className;
    var css = useTheme('Schedule').WeekDays;
    var week = __spreadArray([], __read(Array(7).keys()), false);
    return (React.createElement("div", { className: className }, week.map(function (index) {
        var _a;
        var hasFlights = weekdays.includes(WEEKDAY_INDEX_TO_WEEKDAY_MAP.get(index));
        return (React.createElement("span", { key: index, className: cn(css.day, (_a = {}, _a[css.active] = hasFlights, _a)) }, Number(index) + 1));
    })));
};
export default WeekDays;
