import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import MediaQuery from 'react-responsive';
import DeleteAccountDialog from '../DeleteAccountDialog/DeleteAccountDialog';
import { deleteIcon, facebookIcon, googleIcon, vkIcon } from '../../Icons';
import { InfoField } from '../InfoField/InfoField';
import { Statuses, UserSettings } from '../../types';
import { SocialButton } from '../SocialButton/SocialButton';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../utils';
import { LoginType, OauthServiceType } from '../../../__generated__/globalTypes';
import Loader from '../../../Loader';
import FullscreenDialog from '../../../FullscreenDialog';
import { SlideBottom } from '../../../index';
import { useTheme } from '../../../theme';
import * as AccountQueries from '../../../__queries__/Account.graphql';
import WarningModal from '../../../WarningModal';
import { useConfig } from '../../../context';
initI18n('Account');
var AccountInfo = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    var css = useTheme('Account').Account, t = useTranslation('Account').t, _1 = __read(useState(Statuses.default), 2), phoneStatus = _1[0], setPhoneStatus = _1[1], _2 = __read(useState(Statuses.default), 2), emailStatus = _2[0], setEmailStatus = _2[1], _3 = __read(useState(false), 2), isOpenDeleteDialog = _3[0], setOpenDeleteDialog = _3[1], _4 = __read(useState(false), 2), isOpenedTermsModal = _4[0], setOpenedTermsModal = _4[1], _5 = __read(useState(null), 2), userSettingsError = _5[0], setUserSettingsError = _5[1];
    var showUserPhoneInput = useConfig().Account.showUserPhoneInput;
    var accountInfo = useQuery(AccountQueries.GetAccountInfo);
    var userSettings = useQuery(AccountQueries.GetUserSettings);
    var _6 = __read(useMutation(AccountQueries.SetUserSettings), 1), setSettings = _6[0];
    var oAuth = useQuery(AccountQueries.Oauth, {
        skip: true
    });
    var _7 = __read(useMutation(AccountQueries.RemoveAuthMethod), 1), removeMethod = _7[0];
    var _8 = __read(useMutation(AccountQueries.RemoveAccount), 1), removeAccount = _8[0];
    var refetchAccountInfo = function () {
        accountInfo.refetch().then();
    };
    var onRemoveAccount = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, removeAccount()];
                case 1:
                    res = _a.sent();
                    if (res.errors && res.errors.length) {
                        setUserSettingsError(res.errors[0]);
                    }
                    else if (res.data.RemoveAccount.result) {
                        window.location.reload();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setUserSettingsError(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onRemoveAuthMethod = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, removeMethod({
                            variables: {
                                params: id
                            }
                        })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (errors === null || errors === void 0 ? void 0 : errors.length) {
                        setUserSettingsError(errors[0]);
                    }
                    else if (data.DeleteAuthMethod && data.DeleteAuthMethod.result) {
                        refetchAccountInfo();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _b.sent();
                    setUserSettingsError(error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var oAuthRedirect = function (buttonName) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, oAuth.refetch({
                            params: {
                                service: buttonName === 'Google'
                                    ? OauthServiceType.Google
                                    : buttonName === 'Facebook'
                                        ? OauthServiceType.Facebook
                                        : OauthServiceType.Vkontakte,
                                backRedirectUrl: window.location.href
                            }
                        })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (errors && errors.length) {
                        setUserSettingsError(errors[0]);
                    }
                    else if (data.OauthRedirectUrl.result) {
                        window.open(data.OauthRedirectUrl.message, '_top');
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _b.sent();
                    setUserSettingsError(error_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var setUserSettings = function (settingName, value) { return __awaiter(void 0, void 0, void 0, function () {
        var res, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, setSettings({
                            variables: {
                                params: [
                                    {
                                        name: settingName,
                                        value: value
                                    }
                                ]
                            }
                        })];
                case 1:
                    res = _a.sent();
                    if (res.errors && res.errors.length) {
                        setUserSettingsError(res.errors[0]);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    setUserSettingsError(error_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var clearUserSettingsError = useCallback(function () {
        setUserSettingsError(null);
    }, []);
    var accountPhone = (_c = (_b = (_a = accountInfo.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.authMethods) === null || _c === void 0 ? void 0 : _c.find(function (authM) { return authM.loginType === LoginType.Phone; });
    var accountEmail = (_f = (_e = (_d = accountInfo.data) === null || _d === void 0 ? void 0 : _d.CurrentUser) === null || _e === void 0 ? void 0 : _e.authMethods) === null || _f === void 0 ? void 0 : _f.find(function (authM) { return authM.loginType === LoginType.Email; });
    var isActiveSocialButton = function (buttonName) { var _a, _b, _c; return (_c = (_b = (_a = accountInfo.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.authMethods) === null || _c === void 0 ? void 0 : _c.some(function (authMethod) { return authMethod.loginType === buttonName; }); };
    if (accountInfo.loading) {
        return (React.createElement("div", { className: css.loader__wrapper },
            React.createElement(Loader, null)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.account__content },
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement("h1", { className: css.account__title }, t('Account'))),
            React.createElement("div", { className: css.account__inputs },
                React.createElement("div", null,
                    React.createElement(InfoField, { status: emailStatus, refetchInfo: refetchAccountInfo, value: accountEmail === null || accountEmail === void 0 ? void 0 : accountEmail.value, setStep: function (field, step) {
                            return field === LoginType.Email ? setEmailStatus(step) : setPhoneStatus(step);
                        }, fieldName: LoginType.Email, confirmed: ((_g = accountInfo.data) === null || _g === void 0 ? void 0 : _g.CurrentUser) && (accountEmail === null || accountEmail === void 0 ? void 0 : accountEmail.confirmed), tooltip: {
                            title: !accountEmail
                                ? t('Please enter your email')
                                : !accountEmail.confirmed && t('Please confirm your email'),
                            message: !accountEmail &&
                                t('Contact details are necessary for us to be able to pass on the latest flight information.')
                        }, confirmationMsg: 'We sent verification code to new email' })),
                showUserPhoneInput && (React.createElement("div", { className: css.phone__input },
                    React.createElement(InfoField, { status: phoneStatus, value: ((_h = accountInfo.data) === null || _h === void 0 ? void 0 : _h.CurrentUser) && (accountPhone === null || accountPhone === void 0 ? void 0 : accountPhone.value), fieldName: LoginType.Phone, setStep: function (field, step) {
                            return field === LoginType.Email ? setEmailStatus(step) : setPhoneStatus(step);
                        }, refetchInfo: refetchAccountInfo, confirmed: accountPhone === null || accountPhone === void 0 ? void 0 : accountPhone.confirmed, tooltip: {
                            title: !accountPhone
                                ? t('Please enter your phone number')
                                : !accountPhone.confirmed && t('Please confirm your phone number'),
                            message: !accountPhone &&
                                t('Contact details are necessary for us to be able to pass on the latest flight information.')
                        }, confirmationMsg: 'We sent verification code to the phone' }))))),
        React.createElement("div", { className: css.social__networks },
            React.createElement("p", null, t('Add login through your social network account')),
            React.createElement("div", { className: css.social__buttons },
                React.createElement(SocialButton, { refetch: refetchAccountInfo, icon: googleIcon, id: (_m = (_l = (_k = (_j = accountInfo.data) === null || _j === void 0 ? void 0 : _j.CurrentUser) === null || _k === void 0 ? void 0 : _k.authMethods) === null || _l === void 0 ? void 0 : _l.find(function (method) { return method.loginType === LoginType.Google; })) === null || _m === void 0 ? void 0 : _m.id, onAuthRedirect: oAuthRedirect, onRemoveAuth: onRemoveAuthMethod, buttonName: OauthServiceType.Google, isActive: isActiveSocialButton(OauthServiceType.Google) }),
                React.createElement(SocialButton, { refetch: refetchAccountInfo, icon: facebookIcon, id: (_r = (_q = (_p = (_o = accountInfo.data) === null || _o === void 0 ? void 0 : _o.CurrentUser) === null || _p === void 0 ? void 0 : _p.authMethods) === null || _q === void 0 ? void 0 : _q.find(function (method) { return method.loginType === LoginType.Facebook; })) === null || _r === void 0 ? void 0 : _r.id, onAuthRedirect: oAuthRedirect, onRemoveAuth: onRemoveAuthMethod, buttonName: OauthServiceType.Facebook, isActive: isActiveSocialButton(OauthServiceType.Facebook) }),
                React.createElement(SocialButton, { refetch: refetchAccountInfo, icon: vkIcon, id: (_v = (_u = (_t = (_s = accountInfo.data) === null || _s === void 0 ? void 0 : _s.CurrentUser) === null || _t === void 0 ? void 0 : _t.authMethods) === null || _u === void 0 ? void 0 : _u.find(function (method) { return method.loginType === LoginType.Vkontakte; })) === null || _v === void 0 ? void 0 : _v.id, onAuthRedirect: oAuthRedirect, onRemoveAuth: onRemoveAuthMethod, buttonName: OauthServiceType.Vkontakte, isActive: isActiveSocialButton(OauthServiceType.Vkontakte) }))),
        React.createElement("div", { className: css.account__offers },
            React.createElement(Checkbox, { checked: !userSettings.loading &&
                    ((_x = (_w = userSettings.data) === null || _w === void 0 ? void 0 : _w.UserSettings) === null || _x === void 0 ? void 0 : _x.find(function (set) { return set.name === UserSettings.Subscribe; }).value) ===
                        'true', onChange: function (event) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, setUserSettings(UserSettings.Subscribe, event.target.checked.toString())];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, userSettings.refetch()];
                            case 2:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, classes: {
                    root: css.checkbox,
                    checked: css.checked
                } }),
            React.createElement("p", null, t('I want to receive news and special offers'))),
        React.createElement("div", { className: css.account__footer },
            React.createElement("p", { onClick: function () {
                    setOpenDeleteDialog(true);
                } },
                React.createElement("span", null,
                    React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, deleteIcon),
                    t('Delete my account')))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dialog, { maxWidth: 'md', classes: { paper: css.account__deleteModal }, open: isOpenDeleteDialog },
                React.createElement(DeleteAccountDialog, { onClose: function () {
                        setOpenDeleteDialog(false);
                    }, onRemoveAccount: onRemoveAccount }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: isOpenDeleteDialog, onClose: function () { return setOpenDeleteDialog(false); } },
                React.createElement(DeleteAccountDialog, { onClose: function () { return setOpenDeleteDialog(false); }, onRemoveAccount: onRemoveAccount }))),
        React.createElement(FullscreenDialog, { isOpen: isOpenedTermsModal, onClose: function () { return setOpenedTermsModal(false); }, header: 'Terms Of Use' },
            React.createElement("div", { className: css.terms__modal },
                React.createElement("p", null, (_0 = (_z = (_y = userSettings.data) === null || _y === void 0 ? void 0 : _y.UserSettings) === null || _z === void 0 ? void 0 : _z.find(function (setting) { return setting.name === UserSettings.TermOfUse; })) === null || _0 === void 0 ? void 0 : _0.value))),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), errorMessage: userSettingsError ? userSettingsError.message : '', onClose: clearUserSettingsError, onButtonClick: clearUserSettingsError, buttonText: t('OK, thank you'), isOpen: !!userSettingsError })));
};
export default AccountInfo;
