import * as React from 'react';
import { format } from '../../../../utils';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
var FlightInfo = function (_a) {
    var _b;
    var _c;
    var segment = _a.segment, singleFlight = _a.singleFlight, nextDay = _a.nextDay;
    var theme = useTheme('DesktopFlightRoute').SegmentStyles;
    return (React.createElement("div", { className: cn(theme.flightInfo, (_b = {},
            _b[theme.flightInfo__singleFlight] = singleFlight,
            _b[theme.flightInfo__nextDay] = nextDay,
            _b)) },
        ((_c = segment.marketingAirline) === null || _c === void 0 ? void 0 : _c.icon) && (React.createElement("img", { className: theme.flightInfo__icon, src: segment.marketingAirline.icon })),
        singleFlight && React.createElement("div", { className: theme.flightInfo__airline }, segment.marketingAirline.name),
        !singleFlight && (React.createElement("div", { className: theme.flightInfo__info },
            React.createElement("div", { className: theme.flightInfo__date }, format(segment.departure.date, 'dd MMM')),
            React.createElement("div", { className: theme.flightInfo__airline }, segment.marketingAirline.name)))));
};
export default FlightInfo;
