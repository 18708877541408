import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import Fade from '@material-ui/core/Fade';
import { CloseClear } from '../../index';
import { useEventListener, useToggleable } from '../../hooks';
import { useTheme } from '../../theme';
import Input from '../../Input';
import { useCallback } from 'react';
import TabButton from '../../Button/components/TabButton/TabButton';
var FlightsFilters = function (props) {
    var showOnlyDirectFlight = props.showOnlyDirectFlight, onShowOnlyDirectFlightToggle = props.onShowOnlyDirectFlightToggle, flightNumber = props.flightNumber, onFlightNumberChange = props.onFlightNumberChange;
    var t = useTranslation('FlightsListControls').t;
    var css = useTheme('FlightsFilters').FlightsFilters;
    var _a = useToggleable(false), showInput = _a.isOpen, open = _a.open, close = _a.close;
    var handleClear = useCallback(function () {
        onFlightNumberChange('');
        close();
    }, [flightNumber, onFlightNumberChange, close]);
    var handleButtonClear = useCallback(function (e) {
        e.stopPropagation();
        handleClear();
    }, [handleClear]);
    var handleBlur = useCallback(function () {
        setTimeout(close, 100);
    }, [close]);
    var changeFilterHandler = function (event) {
        var keyboardEvent = event;
        if (document.activeElement.tagName === 'INPUT') {
            return;
        }
        if (keyboardEvent.shiftKey && keyboardEvent.code === 'KeyS') {
            onShowOnlyDirectFlightToggle();
        }
        if (keyboardEvent.shiftKey && keyboardEvent.code === 'KeyF') {
            open();
            keyboardEvent.preventDefault();
        }
        keyboardEvent.stopPropagation();
    };
    useEventListener('keydown', changeFilterHandler);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("span", { className: css.title }, t('Filter')),
        props.isOnlyDirectFlightsAvailable && (React.createElement(TabButton, { className: cn(css.button), inverted: true, active: showOnlyDirectFlight, onClick: onShowOnlyDirectFlightToggle }, t('Nonstop'))),
        React.createElement("div", { className: css.filter_wrapper },
            React.createElement(TabButton, { className: cn(css.button, css.input_trigger), onClick: open, inverted: true, active: showInput || !!flightNumber },
                React.createElement("span", { className: css.filter_text }, !!flightNumber ? flightNumber : t('Flight Num')),
                flightNumber && (React.createElement("span", { onClick: handleButtonClear, className: cn(css.close, css.in_button) }, CloseClear))),
            React.createElement(Fade, { in: showInput, mountOnEnter: true, unmountOnExit: true },
                React.createElement("div", { className: css.input_wrapper },
                    React.createElement(Input, { autoFocus: true, endAdornment: React.createElement(InputAdornment, { position: 'end', className: css.adornment, onClick: handleClear },
                            React.createElement("span", { className: css.close }, CloseClear)), TextFieldProps: {
                            placeholder: t('Enter airline code or flight number'),
                            value: flightNumber,
                            fullWidth: true,
                            classes: { root: css.text_field },
                            onChange: function (e) { return onFlightNumberChange(e.target.value.toUpperCase()); },
                            onBlur: handleBlur
                        } }))))));
};
export default FlightsFilters;
