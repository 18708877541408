import * as React from 'react';
import PromoLoader from '../../../PromoLoader';
import { useTheme } from '../../../theme';
import { useLastRefundQuery } from '../Refund/hooks';
var GetLastRefundTicket = function (_a) {
    var children = _a.children, orderId = _a.orderId;
    var _b = useLastRefundQuery(orderId), lastRefund = _b.data, loading = _b.loading;
    var wrapperCss = useTheme('Refund').Wrapper;
    if (loading) {
        return React.createElement(PromoLoader, { className: wrapperCss.promoLoader });
    }
    return children(lastRefund);
};
export default GetLastRefundTicket;
