import { __assign } from "tslib";
import * as React from 'react';
import { useContext } from 'react';
import { OrderCardContext } from '../OrderCard';
import CommonItem from '../CommonItem/CommonItem';
import { Watch } from '../../../Icons';
import WaitPaymentText from '../OrderStatus/OrderStatusText/WaitPaymentText/WaitPaymentText';
import { OrderStatus } from '../../../__generated__/graphql';
import { useTheme } from '../../../theme';
import cn from 'classnames';
var PaymentStatus = function (props) {
    var _a;
    var _b = useContext(OrderCardContext), order = _b.order, type = _b.type;
    var theme = useTheme('OrderCard').PaymentStatus;
    return (order.status === OrderStatus.Booked && (React.createElement(CommonItem, __assign({ className: cn(theme.root, (_a = {},
            _a[theme.account] = type === 'account',
            _a)), iconClassName: theme.icon, icon: Watch, text: React.createElement(WaitPaymentText, null) }, props))));
};
export default PaymentStatus;
