import { __rest } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import SearchFormPromoCode from '../../../SearchForm/components/SearchForm/Bottom/PromoCode/PromoCode';
import { usePromoCode } from '../../../PromoCode/hooks';
import { PromoCodeStatus } from '../../../PromoCode/types';
var QuickSearchFormPromoCode = function (props) {
    var _a;
    var status = usePromoCode(props.isPromoCodeApplied ? PromoCodeStatus.Applied : PromoCodeStatus.Empty).status;
    var handleSubmit = useCallback(function (searchParams) {
        props.onSubmit(searchParams);
    }, [status, props.onSubmit]);
    var handleClear = useCallback(function (searchParams) {
        var promotionCode = searchParams.promotionCode, searchParamsWithoutPromoCode = __rest(searchParams, ["promotionCode"]);
        props.onSubmit(searchParamsWithoutPromoCode);
    }, [status, props.onSubmit]);
    return (React.createElement(SearchFormPromoCode, { promoCode: props.promoCode, onClose: props.onClose, onOpen: props.onOpen, onAddPromoCode: handleSubmit, status: (_a = props.status) !== null && _a !== void 0 ? _a : status, onClearPromoCode: handleClear, isFormOpen: props.isFormOpen }));
};
export default QuickSearchFormPromoCode;
