import { __read } from "tslib";
import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../Button/Button';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import WarningModal from '../../WarningModal';
import Modal from '../../Modal';
import CloseIcon from '../../CloseIcon/components/CloseIcon';
import { getSplitAvailability } from '../../Checkout/store/order/selectors';
initI18n('Void');
var Void = function (props) {
    var css = useTheme('Void').Void;
    var t = useTranslation('Void').t;
    var _a = __read(useState(false), 2), isVoiding = _a[0], setIsVoiding = _a[1];
    var _b = __read(useState(false), 2), isFail = _b[0], setIsFail = _b[1];
    var _c = __read(useState(false), 2), isWarningOpen = _c[0], setIsWarningOpen = _c[1];
    var isCanSplit = useSelector(getSplitAvailability);
    var voidSuccess = useCallback(function (order) {
        props.closeModal();
        setIsVoiding(false);
        props.onVoidSuccess(order);
    }, [props.onVoidSuccess]);
    var voidFail = useCallback(function () {
        setIsVoiding(false);
        setIsFail(true);
        props.onVoidFail();
    }, [props.onVoidFail]);
    var voidOrderHandler = useCallback(function () {
        setIsVoiding(true);
        props.voidOrder(voidSuccess, voidFail);
    }, [props.voidOrder]);
    useEffect(function () {
        if (props.error) {
            setIsWarningOpen(true);
        }
    }, [props.error]);
    var renderVoidButton = useCallback(function () {
        if (props.renderVoidButton) {
            return props.renderVoidButton({ onClick: props.openModal });
        }
        return (React.createElement(Button, { variant: "outline", onClick: props.openModal, className: css.voidModalBtn }, t('Void')));
    }, [props.renderVoidButton, isVoiding]);
    var getModalText = function () {
        if (isFail) {
            return t('Sorry, the void operation is not available');
        }
        if (isCanSplit) {
            return "".concat(t('Click Сontinue if you are going void the tickets for all passengers in the PNR.'), "\n\t\t\t\t\t").concat(t('If you want to make a void for some passengers, return to the PNR and provide the Split previously.'));
        }
        else {
            return t('Click Сontinue if you are going void the tickets for all passengers in the PNR.');
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(WarningModal, { maxWidth: 'sm', title: t('Oops...'), content: t(props.error), isOpen: isWarningOpen, buttonText: t('Close'), onButtonClick: function () {
                setIsWarningOpen(false);
            } }),
        React.createElement(Modal, { maxWidth: 'sm', open: props.open, onClose: props.closeModal, classes: { paper: css.modal__paper }, closeButtonRenderer: function (onClick) { return (React.createElement("div", { className: css.popup__close },
                React.createElement(CloseIcon, { onClick: onClick }))); } },
            React.createElement("h3", { className: css.header }, t('Void PNR')),
            React.createElement("div", { className: css.text }, getModalText()),
            !isFail && (React.createElement(Button, { type: "submit", variant: "flat", size: 'common', isLoading: isVoiding, onClick: voidOrderHandler, className: css.continueBtn, classes: {
                    root: css.continueBtn__root
                } }, t('Continue')))),
        props.isVoidAvailable && renderVoidButton()));
};
export default Void;
