var _a;
import { __assign } from "tslib";
import { InputType } from '../../Passenger/types';
import { Gender, TravellerFieldEnum } from '../../__generated__/globalTypes';
import { simpleValiation } from '../../Passenger/data';
export var emptyDocFields = [];
export var docFieldsOrder = [
    [TravellerFieldEnum.LastName],
    [TravellerFieldEnum.FirstName],
    [TravellerFieldEnum.MiddleName],
    [TravellerFieldEnum.BirthDate, TravellerFieldEnum.Gender],
    [TravellerFieldEnum.Nationality],
    [TravellerFieldEnum.DocType],
    [TravellerFieldEnum.DocNumber],
    [TravellerFieldEnum.DocExpiryDate]
];
export var docFields = (_a = {},
    _a[TravellerFieldEnum.LastName] = {
        name: TravellerFieldEnum.LastName,
        type: TravellerFieldEnum.LastName,
        input: InputType.Text,
        validations: []
    },
    _a[TravellerFieldEnum.FirstName] = {
        name: TravellerFieldEnum.FirstName,
        type: TravellerFieldEnum.FirstName,
        input: InputType.Text,
        validations: []
    },
    _a[TravellerFieldEnum.MiddleName] = {
        name: TravellerFieldEnum.MiddleName,
        type: TravellerFieldEnum.MiddleName,
        input: InputType.Text,
        validations: []
    },
    _a[TravellerFieldEnum.BirthDate] = {
        name: TravellerFieldEnum.BirthDate,
        type: TravellerFieldEnum.BirthDate,
        input: InputType.Date,
        validations: []
    },
    _a[TravellerFieldEnum.Gender] = {
        name: TravellerFieldEnum.Gender,
        type: TravellerFieldEnum.Gender,
        input: InputType.Switch,
        options: [
            {
                key: 'M',
                value: Gender.Male
            },
            {
                key: 'F',
                value: Gender.Female
            }
        ],
        validations: []
    },
    _a[TravellerFieldEnum.Nationality] = {
        name: TravellerFieldEnum.Nationality,
        type: TravellerFieldEnum.Nationality,
        input: InputType.Autocomplete,
        validations: []
    },
    _a[TravellerFieldEnum.DocType] = {
        name: TravellerFieldEnum.DocType,
        type: TravellerFieldEnum.DocType,
        input: InputType.Select,
        validations: []
    },
    _a[TravellerFieldEnum.DocNumber] = {
        name: TravellerFieldEnum.DocNumber,
        type: TravellerFieldEnum.DocNumber,
        input: InputType.Text,
        validations: []
    },
    _a[TravellerFieldEnum.DocExpiryDate] = {
        name: TravellerFieldEnum.DocExpiryDate,
        type: TravellerFieldEnum.DocExpiryDate,
        input: InputType.Date,
        validations: [
            __assign(__assign({}, simpleValiation), { required: true })
        ]
    },
    _a);
