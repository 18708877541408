import * as React from 'react';
import { useTheme } from '../../../theme';
import Switch from '../../../Switch/components/Switch';
import cn from 'classnames';
import { Money, SlideBottom } from '../../../index';
import { AdditionalTypes } from '../../types';
import Radio from '@material-ui/core/Radio';
import { useTranslation } from 'react-i18next';
var AdditionalMobile = function (props) {
    var _a, _b;
    var theme = useTheme('BaggageService').BaggageServiceStyles;
    var t = useTranslation('BaggageService').t;
    var onSelect = function (baggageId, isSelected) {
        if ((props.type === AdditionalTypes.Overweight && props.hasExcessBaggage) ||
            (!props.hasHandLuggageExcess && props.type === AdditionalTypes.HandLuggageExcess) ||
            props.type !== AdditionalTypes.Overweight) {
            props.onSelect(baggageId, isSelected);
        }
    };
    var availableSegments = props.availableSegments &&
        props.availableSegments.map(function (segment) { return "".concat(segment.departure.name, " \u2014 ").concat(segment.arrival.name); });
    var disabled = false;
    switch (props.type) {
        case AdditionalTypes.Overweight:
            disabled = !props.hasExcessBaggage;
            break;
        case AdditionalTypes.HandLuggageExcess:
            disabled = !props.hasHandLuggageExcess;
            break;
    }
    return (React.createElement(SlideBottom, { isOpen: props.isOpen, onClose: props.onClose },
        React.createElement("div", null,
            React.createElement("div", { className: theme.slide__header },
                React.createElement("h1", null, props.header),
                props.type === AdditionalTypes.Overweight && (React.createElement(Switch, { label: React.createElement(React.Fragment, null), mainClassName: cn((_a = {},
                        _a[theme.slide__header__checkbox_notActive] = !props.hasExcessBaggage,
                        _a[theme.slide__header__checkbox_active] = props.hasExcessBaggage,
                        _a)), switchPosition: "right", isActive: props.hasExcessBaggage, onClick: function () {
                        props.onToggle(!props.hasExcessBaggage);
                    } })),
                props.type === AdditionalTypes.HandLuggageExcess && (React.createElement(Switch, { label: React.createElement(React.Fragment, null), mainClassName: cn((_b = {},
                        _b[theme.slide__header__checkbox_notActive] = !props.hasHandLuggageExcess,
                        _b[theme.slide__header__checkbox_active] = props.hasHandLuggageExcess,
                        _b)), switchPosition: "right", isActive: props.hasHandLuggageExcess, onClick: function () {
                        props.onToggle(!props.hasHandLuggageExcess);
                    } }))),
            React.createElement("div", { className: theme.slide__descr },
                React.createElement("p", null, props.description),
                availableSegments && availableSegments.length > 1 ? (props.isAvailableForAllSegments ? (React.createElement("p", { className: theme.availableSegments }, t('The service is applied to all directions'))) : (React.createElement("p", { className: theme.availableSegments },
                    t('The service is applied to directions'),
                    ": ",
                    availableSegments.join(', ')))) : null),
            React.createElement("div", { className: theme.slide__options }, props.services.map(function (service, index) {
                var _a, _b;
                return (React.createElement("div", { key: index, className: cn(theme.slide__options__item, (_a = {},
                        _a[theme.disabled] = disabled,
                        _a)) },
                    React.createElement("div", null, service.label),
                    React.createElement("div", { className: theme.slide__options__item__price },
                        React.createElement(Money, { money: service.baggage.price, multiplyBy: props.type === AdditionalTypes.Overweight ||
                                props.type === AdditionalTypes.HandLuggageExcess
                                ? service.baggage.segmentsId.length
                                : 1 }),
                        props.selectInputType === 'checkbox' ? (React.createElement("div", { onClick: function (event) {
                                onSelect(service.baggage.id, !service.isSelected);
                                event.stopPropagation();
                            }, className: cn(theme.add__button, theme.add__button_slide, (_b = {},
                                _b[theme.remove__button] = service.isSelected,
                                _b)) })) : (React.createElement(Radio, { color: "primary", checked: service.isSelected, classes: {
                                colorPrimary: theme.checkbox,
                                checked: theme.checkbox_checked
                            }, onClick: function () { return onSelect(service.baggage.id); } })))));
            })))));
};
export default AdditionalMobile;
