import * as React from 'react';
import cn from 'classnames';
import { Money, Tooltip } from '../../../../../index';
import { useTheme } from '../../../../../theme';
import { useCallback } from 'react';
import { useToggleable } from '../../../../../hooks';
var FareInfo = function (props) {
    var _a, _b, _c;
    var _d, _e;
    var fare = props.fare, isSelected = props.isSelected, handleSelect = props.handleSelect, renderTooltipContent = props.renderTooltipContent, fareIndex = props.fareIndex, hidePrice = props.hidePrice;
    var css = useTheme('FlightSelect').FareSelect;
    var _f = useToggleable(false), isOpen = _f.isOpen, open = _f.open, close = _f.close;
    if (!fare.prices || !((_d = fare.prices[0]) === null || _d === void 0 ? void 0 : _d.price)) {
        return null;
    }
    var handleMouseEnter = useCallback(function () {
        open();
    }, [isOpen, isSelected]);
    var handleFareNameClick = useCallback(function (e) {
        if (!isSelected) {
            // do not show modal if the user clicks on a specific tariff
            e.stopPropagation();
            handleSelect(fare);
        }
    }, [isSelected, fare]);
    return (React.createElement("div", { className: css.fare },
        React.createElement("div", { onMouseEnter: handleMouseEnter, onMouseLeave: close },
            React.createElement("span", { className: css.name, onClick: handleFareNameClick },
                React.createElement(Tooltip, { title: renderTooltipContent(fare), placement: 'bottom', tooltipClassName: cn(css.tooltip, (_a = {},
                        _a[css.tooltip_selected] = isSelected,
                        _a)), open: isOpen && !hidePrice },
                    React.createElement("span", { className: cn(css.name__inner, (_b = {},
                            _b[css.bold] = fareIndex === 0 && !isSelected,
                            _b[css.name__inner_selected] = isSelected,
                            _b)) }, fare.fareFamily.title)),
                !hidePrice && (React.createElement("div", null,
                    React.createElement(Money, { money: (_e = fare.prices[0]) === null || _e === void 0 ? void 0 : _e.price, moneyClassName: cn(css.price, (_c = {},
                            _c[css.bold] = fareIndex === 0 && !isSelected,
                            _c[css.price_selected] = isSelected,
                            _c)) })))))));
};
export default FareInfo;
