import { __assign } from "tslib";
import * as React from 'react';
import CheckoutAdditionalService from '../../../../../../../CheckoutAdditionalService';
import { useTranslation } from 'react-i18next';
import CommonContent from '../CommonContent';
import { useTheme } from '../../../../../../../theme';
import cn from 'classnames';
var BaggageRefundCard = function (props) {
    var t = useTranslation('Checkout').t;
    var theme = useTheme('Exchange').CommonContent;
    return (React.createElement(CheckoutAdditionalService, { header: t('Baggage'), description: t('Transportation of impressions no limitations'), isSelected: true, className: cn(theme.serviceCard, theme.serviceCard_baggage), addClick: function () { return null; } },
        React.createElement(CommonContent, __assign({}, props))));
};
export default BaggageRefundCard;
