import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Modal from '../../../../Modal';
import { useToggleable } from '../../../../hooks';
import { useTheme } from '../../../../theme';
var PdfModal = function (_a) {
    var _b;
    var className = _a.className, renderButton = _a.renderButton, documents = _a.documents, onOpen = _a.onOpen;
    var css = useTheme('Terminal').PdfModal;
    var t = useTranslation('Terminal').t;
    var _c = useToggleable(false), isOpen = _c.isOpen, open = _c.open, close = _c.close;
    useEffect(function () {
        onOpen === null || onOpen === void 0 ? void 0 : onOpen(isOpen);
    }, [isOpen]);
    var renderCloseButton = useCallback(function (onClick) { return React.createElement("div", { onClick: onClick, className: css.close }); }, []);
    var pdfUrl = "data:application/pdf;base64,".concat(documents[0].content);
    return (React.createElement(React.Fragment, null, (_b = renderButton === null || renderButton === void 0 ? void 0 : renderButton(open)) !== null && _b !== void 0 ? _b : (React.createElement("span", { className: cn(className, css.button), onClick: open }, t('Show PDF'))),
        React.createElement(Modal, { classes: {
                paper: css.paper
            }, open: isOpen, onClose: close, closeButtonRenderer: renderCloseButton },
            React.createElement("div", { className: css.modal },
                React.createElement("div", { className: css.title }, t('View PDF')),
                React.createElement("div", { className: css.content },
                    React.createElement("iframe", { src: pdfUrl, width: "100%", height: "100%", frameBorder: "0" }))))));
};
export default PdfModal;
