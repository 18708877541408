import { __assign } from "tslib";
import React, { useContext } from 'react';
import { OrderCardContext } from '../../../../OrderCard/components/OrderCard';
import { useTheme } from '../../../../theme';
import OrderBookingComponent from '../../../../OrderCard/components/OrderBooking/OrderBooking';
import RefreshButton from '../../../RefreshButton/RefreshButton';
var OrderBooking = function (props) {
    var css = useTheme('LeoOrderCard').OrderBooking;
    var order = useContext(OrderCardContext).order;
    return (React.createElement(OrderBookingComponent, __assign({}, props, { buttons: React.createElement(RefreshButton, { className: css.refreshBtn, orderLocator: order.locator }), hideSecurityCode: true, hidePrice: true })));
};
export default OrderBooking;
