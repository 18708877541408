import { __extends, __read, __spreadArray } from "tslib";
import { FilterAttrs } from '../types';
import { Weekdays } from '../../../__generated__/graphql';
import { FlightFilterBase } from './FlightFilterBase';
var weekdaysIdx = new Map(Object.entries(Weekdays).map(function (_a, i) {
    var _b = __read(_a, 1), day = _b[0];
    return [day, i];
}));
var WeekDaysFilter = /** @class */ (function (_super) {
    __extends(WeekDaysFilter, _super);
    function WeekDaysFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(WeekDaysFilter.prototype, "attr", {
        get: function () {
            return FilterAttrs.WEEK_DAYS;
        },
        enumerable: false,
        configurable: true
    });
    WeekDaysFilter.prototype.toString = function (filterValues, convert) {
        var conv = function (value) { return (convert ? convert(value) : value); };
        var trimDay = function (str) { return str.substring(0, 3); };
        var value = filterValues[this.attr];
        if (!value) {
            return undefined;
        }
        if (value.length === 1) {
            return conv(trimDay(value[0]));
        }
        var orderedWeekdays = value.sort(function (a, b) { return weekdaysIdx.get(a) - weekdaysIdx.get(b); });
        var _loop_1 = function (i) {
            // если дни недели НЕ идут др за другом
            if (weekdaysIdx.get(orderedWeekdays[i]) - weekdaysIdx.get(orderedWeekdays[i - 1]) !== 1) {
                var str_1 = '';
                orderedWeekdays.forEach(function (day, i) {
                    if (i !== 0) {
                        str_1 += ', ';
                    }
                    str_1 += trimDay(day);
                });
                return { value: conv(str_1) };
            }
        };
        for (var i = 1; i < orderedWeekdays.length - 1; i++) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return conv("".concat(trimDay(orderedWeekdays[0]), " - ").concat(trimDay(orderedWeekdays[orderedWeekdays.length - 1])));
    };
    WeekDaysFilter.prototype.check = function (flight, filterValue) {
        if (!flight || !((filterValue === null || filterValue === void 0 ? void 0 : filterValue.length) > 0)) {
            return true;
        }
        return filterValue.some(function (day) { var _a, _b; return (_b = (_a = __spreadArray([], __read(flight.segments), false)) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.weekdays.includes(day); });
    };
    return WeekDaysFilter;
}(FlightFilterBase));
export { WeekDaysFilter };
