import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import ContactsWidget from '../../../../../Contacts/components/Contacts';
import { unlockForm } from '../../../../store/formIsLocked/actions';
import { isFormLocked } from '../../../../store/formIsLocked/selectors';
import { getOrderId, isReadonly, isRedirectFromMetasearch } from '../../../../store/order/selectors';
import * as AuthQuery from '../../../../../__queries__/query.graphql';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import SlideBottom from '../../../../../SlideBottom/components/SlideBottom';
import { reachGoal, SplitTestGoal } from '../../../../../analytics';
import MobileAuthForm from '../../../../../MobileAuthForm';
import { get, set, TEST_AUTHORIZATION_REFUSED } from '../../../../../cache';
import { fillOrder } from '../../../../store/order/actions';
import { LoginType } from '@websky/graphql';
import { buildContactFields } from '../../../../../Contacts/components/utils';
import { useIsTerminal } from '../../../../../hooks';
// #fixme: a lot of state objects
var Contacts = function (props) {
    var loginType = LoginType.Email;
    var _a = __read(useState(false), 2), customerDataSent = _a[0], setCustomerDataSent = _a[1];
    var _b = __read(useState(false), 2), customerDataSending = _b[0], setCustomerDataSending = _b[1];
    var _c = __read(useState(false), 2), codeSending = _c[0], setCodeSending = _c[1];
    var _d = __read(useState(false), 2), isCodeSentAgain = _d[0], setCodeSentAgain = _d[1];
    var _e = __read(useState(false), 2), codeAgainSending = _e[0], setCodeAgainSending = _e[1];
    var _f = __read(useState(false), 2), customerFormIsOpen = _f[0], setCustomerFormIsOpen = _f[1];
    var _g = __read(useState(''), 2), login = _g[0], setLogin = _g[1];
    var _h = __read(useState(false), 2), isCodeWrong = _h[0], setCodeWrong = _h[1];
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var isTerminal = useIsTerminal();
    var getUserInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, login_1, showCustomerForm;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, ((_a = props.refetchUser) === null || _a === void 0 ? void 0 : _a.call(props))];
                case 1:
                    data = _b.sent();
                    if (data && data.CurrentUser) {
                        props.unlockForm();
                        login_1 = data.CurrentUser.authMethods.find(function (method) { return method.confirmed && method.loginType === loginType; });
                        if (login_1) {
                            setLogin(login_1.value);
                        }
                    }
                    else {
                        showCustomerForm = isMobile && !get("".concat(TEST_AUTHORIZATION_REFUSED, "_").concat(props.orderId)) && !props.isReadonly;
                        if (showCustomerForm) {
                            // Somehow it doesn't work without timeout. The form gets re-rendered.
                            setTimeout(function () { return setCustomerFormIsOpen(true); }, 200);
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var closeCustomerFormWithRefused = function () {
        setCustomerFormIsOpen(false);
        set("".concat(TEST_AUTHORIZATION_REFUSED, "_").concat(props.orderId), true);
        reachGoal(SplitTestGoal.RefusedToAuthorize);
    };
    var closeCustomerFormWithAgreed = function () {
        var _a;
        setCustomerFormIsOpen(false);
        reachGoal(SplitTestGoal.AgreedToAuthorize);
        (_a = props.refetchUser) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    var agreeToAuthorize = function () {
        reachGoal(SplitTestGoal.AgreedToAuthorize);
    };
    var _j = __read(useMutation(AuthQuery.Authenticate), 1), sendCodeRequest = _j[0];
    var _k = __read(useMutation(AuthQuery.SendSecureCode), 1), sendCustomerDataRequest = _k[0];
    useEffect(function () {
        if (!props.isReadonly) {
            getUserInfo();
        }
    }, [props.isReadonly]);
    var sendCustomerData = function (email) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCustomerDataSent(false);
                    setCustomerDataSending(true);
                    return [4 /*yield*/, sendCustomerDataRequest({
                            variables: {
                                params: {
                                    login: email,
                                    loginType: loginType
                                }
                            }
                        })];
                case 1:
                    response = _a.sent();
                    if (response.data.SendSecureCode && response.data.SendSecureCode.result) {
                        setLogin(email);
                    }
                    setCustomerDataSent(true);
                    setCustomerDataSending(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var sendCode = function (code) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCodeSending(true);
                    return [4 /*yield*/, sendCodeRequest({
                            variables: {
                                params: {
                                    login: login,
                                    loginType: loginType,
                                    secureCode: code
                                }
                            }
                        })];
                case 1:
                    response = _a.sent();
                    if (response.data.Authenticate && response.data.Authenticate.token) {
                        getUserInfo();
                    }
                    else {
                        setCodeWrong(true);
                    }
                    setCodeSending(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var sendCodeAgain = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCodeAgainSending(true);
                    return [4 /*yield*/, sendCustomerDataRequest({
                            variables: {
                                params: {
                                    login: login,
                                    loginType: loginType
                                }
                            }
                        })];
                case 1:
                    response = _a.sent();
                    if (response.data.SendSecureCode && response.data.SendSecureCode.result) {
                        setCodeSentAgain(true);
                        setCodeAgainSending(false);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var open = !props.isReadonly || props.isInputsReadonly;
    var _l = useMemo(function () { return buildContactFields(props.customer, isTerminal); }, [
        props.customer
    ]), otherContacts = _l.otherContacts, mainContacts = _l.mainContacts;
    return (React.createElement(React.Fragment, null,
        React.createElement(ContactsWidget, { open: open, isInputsReadonly: props.isInputsReadonly, isEmailChecking: customerDataSending, isAuthorized: !!props.userInfo, sendCode: sendCodeAgain, setContactsData: sendCustomerData, onCodeFieldDone: sendCode, toggleOpen: function () { }, codeSent: customerDataSent, isCodeChecking: codeSending, isCodeWrong: isCodeWrong, isCodeAgainSent: isCodeSentAgain, isCodeSendingAgain: codeAgainSending, submitForm: props.submitForm, unlockForm: props.unlockForm, isFormLocked: props.isFormLocked, onSuccessfulLogin: props.refetchUser, loginType: loginType, mainContacts: mainContacts, otherContacts: otherContacts }),
        !props.userInfo && !props.isRedirectFromMetasearch && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: customerFormIsOpen, onClose: closeCustomerFormWithRefused, noPadding: true },
                React.createElement(MobileAuthForm, { onSuccess: closeCustomerFormWithAgreed, onReject: closeCustomerFormWithRefused, onSocialAuthClick: agreeToAuthorize }))))));
};
var mapStateToProps = function (state) {
    return {
        orderId: getOrderId(state),
        isReadonly: isReadonly(state),
        isRedirectFromMetasearch: isRedirectFromMetasearch(state),
        isFormLocked: isFormLocked(state)
    };
};
var mapDispatchToProps = {
    unlockForm: unlockForm,
    fillOrder: fillOrder
};
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
