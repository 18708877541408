import * as React from 'react';
import FlightInfo from '../Segment/FlightInfo/FlightInfo';
import Point from './Point/Point';
import { useTheme } from '../../../theme';
import RouteArrow from './RouteArrow/RouteArrow';
import { getDurationString } from '../../../Results/utils';
import Amenities from '../../../Amenities';
import cn from 'classnames';
import StopsInfo from '../Segment/StopsInfo/StopsInfo';
var SingleFlightSegment = function (_a) {
    var _b;
    var _c;
    var segmentWithTransfer = _a.segment, first = _a.first, last = _a.last, stops = _a.stops, singleFlight = _a.singleFlight, totalDuration = _a.totalDuration;
    var segment = segmentWithTransfer.segment, transferDuration = segmentWithTransfer.transferDuration;
    var theme = useTheme('DesktopFlightRoute').SingleFlightSegment;
    var renderRoute = function () {
        var departure = segment.departure, arrival = segment.arrival;
        return (React.createElement("div", { className: theme.route },
            React.createElement(Point, { date: departure.date, time: departure.time, airport: departure.airport, thin: !first }),
            React.createElement(RouteArrow, { timeInRoute: getDurationString(segment.duration), thin: first !== last }),
            React.createElement(Point, { date: arrival.date, time: arrival.time, airport: arrival.airport, arrival: true, thin: !last }),
            (transferDuration || stops.length > 0) && (React.createElement(StopsInfo, { stops: stops, stopCityName: segment.arrival.airport.city.name, transferDuration: transferDuration, className: theme.stop })),
            last && React.createElement("div", { className: theme.totalDuration }, totalDuration)));
    };
    return (React.createElement("div", { className: cn(theme.segment, (_b = {},
            _b[theme.segment_notFirst] = !first,
            _b)) },
        React.createElement(FlightInfo, { segment: segment, singleFlight: singleFlight }),
        React.createElement("div", { className: theme.container },
            renderRoute(),
            React.createElement("div", { className: theme.aircraft },
                React.createElement("img", { src: segment.aircraft.icon }),
                React.createElement("div", { className: theme.flightNumber }, "".concat(segment.marketingAirline.iata, "-").concat(segment.flightNumber)),
                React.createElement("div", { className: theme.info },
                    React.createElement("div", { className: theme.info__aircraft }, segment.aircraft.name),
                    ((_c = segment.flightInfo) === null || _c === void 0 ? void 0 : _c.amenities) && (React.createElement("div", { className: theme.info__features },
                        React.createElement(Amenities, { amenities: segment.flightInfo.amenities })))))),
        !first && React.createElement(StopsInfo, { stops: stops, stopCityName: segment.arrival.airport.city.name })));
};
export default SingleFlightSegment;
