import { __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect } from 'react';
export var usePagination = function (initialIndex, items, pageLimit) {
    if (initialIndex === void 0) { initialIndex = 1; }
    if (items === void 0) { items = []; }
    if (pageLimit === void 0) { pageLimit = 10; }
    var _a = __read(React.useState(initialIndex), 2), paginationIndex = _a[0], setPaginationIndex = _a[1];
    useEffect(function () {
        setPaginationIndex(initialIndex);
    }, [items]);
    var totalPages = Math.ceil(items.length / pageLimit);
    var startIndex = (paginationIndex - 1) * pageLimit;
    var currentItems = items.slice(startIndex, startIndex + pageLimit);
    var handleChange = useCallback(function (index) {
        setPaginationIndex(index);
    }, [paginationIndex]);
    return {
        currentIndex: paginationIndex,
        currentItems: currentItems,
        handleChange: handleChange,
        totalPages: totalPages
    };
};
