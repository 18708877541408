import { __read } from "tslib";
import { useCallback, useState } from 'react';
import { Tabs } from '../../types';
import { useSelector } from 'react-redux';
import { getSelectedFares as getExchangeSelectedFares } from '../../../Modules/Exchange/store/newFlights/selectors';
import { getSelectedFares } from '../../../Results/store/selectedFares/selectors';
import { FlightClass } from '../../../__generated__/graphql';
export var useLastSelectedFlight = function () {
    var _a, _b;
    var lastSelectedResultFlights = (_a = useSelector(getSelectedFares)) !== null && _a !== void 0 ? _a : [];
    var lastSelectedExchangeFlights = (_b = useSelector(getExchangeSelectedFares)) !== null && _b !== void 0 ? _b : [];
    if (lastSelectedResultFlights.length) {
        return lastSelectedResultFlights[lastSelectedResultFlights.length - 1];
    }
    if (lastSelectedExchangeFlights.length) {
        return lastSelectedExchangeFlights[lastSelectedExchangeFlights.length - 1].fare;
    }
    return null;
};
export var useDesktopFlightModalContent = function (props) {
    var _a = __read(useState(function () {
        var _a, _b, _c, _d, _e;
        var hasBusinessPrices = (_a = props.businessFares) === null || _a === void 0 ? void 0 : _a.some(function (_a) {
            var prices = _a.prices;
            return prices;
        }), hasEconomyPrices = (_b = props.economyFares) === null || _b === void 0 ? void 0 : _b.some(function (_a) {
            var prices = _a.prices;
            return prices;
        }), hasMilesPrices = (_c = props.milesFares) === null || _c === void 0 ? void 0 : _c.some(function (_a) {
            var prices = _a.prices;
            return prices;
        });
        var availableServices = [];
        if (hasEconomyPrices) {
            availableServices.push({
                flightClass: FlightClass.Economy,
                tab: Tabs.EconomyFares
            });
        }
        if (hasBusinessPrices) {
            availableServices.push({
                flightClass: FlightClass.Business,
                tab: Tabs.BusinessFares
            });
        }
        if (hasMilesPrices) {
            availableServices.push({
                flightClass: FlightClass.Miles,
                tab: Tabs.MilesFares
            });
        }
        var lastSelectedFareCategory = (_d = props.lastSelectedFare) === null || _d === void 0 ? void 0 : _d.category;
        if (!!lastSelectedFareCategory) {
            var existsService = availableServices.find(function (service) { return service.flightClass === lastSelectedFareCategory; });
            if (existsService) {
                return existsService.tab;
            }
        }
        return (_e = availableServices[0]) === null || _e === void 0 ? void 0 : _e.tab;
    }), 2), active = _a[0], onSelect = _a[1];
    var _b = __read(useState(false), 2), isFareCompareOpen = _b[0], setFareCompareOpen = _b[1];
    var openFareCompare = useCallback(function () {
        setFareCompareOpen(true);
    }, [isFareCompareOpen]);
    var closeFareCompare = useCallback(function () {
        setFareCompareOpen(false);
    }, [isFareCompareOpen]);
    var handleEconomyButtonCLick = useCallback(function () {
        onSelect(Tabs.EconomyFares);
    }, [active]);
    var handleBusinessButtonClick = useCallback(function () {
        onSelect(Tabs.BusinessFares);
    }, [active]);
    var handleMilesButtonClick = useCallback(function () {
        onSelect(Tabs.MilesFares);
    }, [active]);
    var handleFlightDetailsButtonClick = useCallback(function () {
        onSelect(Tabs.FlightInfo);
    }, [active]);
    return {
        active: active,
        handleEconomyButtonCLick: handleEconomyButtonCLick,
        handleBusinessButtonClick: handleBusinessButtonClick,
        handleMilesButtonClick: handleMilesButtonClick,
        handleFlightDetailsButtonClick: handleFlightDetailsButtonClick,
        isFareCompareOpen: isFareCompareOpen,
        openFareCompare: openFareCompare,
        closeFareCompare: closeFareCompare
    };
};
