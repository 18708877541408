import * as React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import Bottom from './Bottom/Bottom';
import Controls from './Controls/Controls';
import Segments from './Segments/Segments';
import { resetSubmitForm, setFieldDataMutator, triggerValidation } from '../../utils';
import { redirectToFastSearch, submitForm } from '../../store/actions';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import { useConfig } from '../../../context';
import { useSearchFormValidation } from './hooks';
// const LastSearch = React.lazy(() => import('./LastSearch/LastSearch'));
initI18n('SearchForm');
var SearchForm = React.memo(function (_a) {
    var _b;
    var className = _a.className, submitForm = _a.submitForm, redirectToFastSearch = _a.redirectToFastSearch, onSubmit = _a.onSubmit, renderPromoCode = _a.renderPromoCode, isOpen = _a.isOpen, renderPayloads = _a.renderPayloads;
    var _c = useConfig().SearchForm, engineURL = _c.engineURL, proMode = _c.proMode;
    var css = useTheme('SearchForm').SearchForm;
    var validate = useSearchFormValidation(proMode).validate;
    var handleSubmit = function () {
        if (onSubmit) {
            submitForm(onSubmit);
        }
        else if (engineURL) {
            redirectToFastSearch(engineURL);
        }
    };
    return (React.createElement("div", { className: cn(css.searchForm, className, (_b = {}, _b[css.searchForm_advanced] = proMode, _b)) },
        React.createElement("div", { className: css.wrapper },
            React.createElement(Form, { onSubmit: handleSubmit, mutators: {
                    setFieldData: setFieldDataMutator,
                    resetSubmitForm: resetSubmitForm,
                    triggerValidation: triggerValidation
                }, subscription: {}, validate: validate, validateOnBlur: true }, function (_a) {
                var handleSubmit = _a.handleSubmit, form = _a.form;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: css.row },
                        React.createElement(Segments, { resetStateMutator: form.mutators.resetSubmitForm }),
                        React.createElement(Controls, { renderPayloads: renderPayloads, showValidationErrors: isOpen })),
                    React.createElement(Bottom, { renderPromoCode: renderPromoCode })));
            }))));
});
var mapDispatchToProps = {
    submitForm: submitForm,
    redirectToFastSearch: redirectToFastSearch
};
export default connect(null, mapDispatchToProps)(SearchForm);
