import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Unvoluntary } from '../../types';
import { getPassengerFirstName, getPassengerLastName } from '../../../Checkout/store/passengers/selectors';
import { isRefundPassenger } from '../../utils';
import { useTheme } from '../../../theme';
import { usePassengerNames } from '../Passenger/hooks';
import { getPassengerName as getPassengerNameUtil } from '../../../utils';
var Breadcrumbs = function (props) {
    var passengers = props.passengers, refundInfo = props.refundInfo, renderAfter = props.renderAfter;
    var t = useTranslation('Refund').t;
    var css = useTheme('Refund').Breadcrumbs;
    var getPassengerName = usePassengerNames().getPassengerName;
    var renderPassengers = useCallback(function () {
        if (!passengers.length) {
            return null;
        }
        var passengerName;
        if (isRefundPassenger(passengers[0])) {
            passengerName = passengers.map(getPassengerName).join(', ');
        }
        else {
            passengerName = passengers
                .map(function (passenger) {
                return getPassengerNameUtil(getPassengerFirstName(passenger).value, getPassengerLastName(passenger).value);
            })
                .join(', ');
        }
        return (React.createElement("div", { title: passengerName, className: cn(css.breadcrumb) },
            React.createElement("div", { className: css.text },
                t('For'),
                " ",
                passengerName),
            renderAfter ? renderAfter('passenger') : null));
    }, [passengers]);
    var renderRefundInfo = useCallback(function () {
        if (!refundInfo) {
            return null;
        }
        var reasonStepText = refundInfo.reason === Unvoluntary.Unvoluntary ? t('Voluntary refund') : t('Unvoluntary refund');
        if (refundInfo.reason !== Unvoluntary.Unvoluntary) {
            reasonStepText += ' - ';
        }
        if (refundInfo.reason !== Unvoluntary.Unvoluntary) {
            reasonStepText += t(refundInfo.reason);
        }
        return (React.createElement("div", { title: reasonStepText, className: cn(css.breadcrumb) },
            React.createElement("div", { className: css.text }, reasonStepText),
            renderAfter ? renderAfter('refundInfo') : null));
    }, [refundInfo]);
    return (React.createElement("div", { className: cn(css.breadcrumbs, props.className) },
        renderPassengers(),
        " ",
        renderRefundInfo()));
};
export default Breadcrumbs;
