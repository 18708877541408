import { __extends, __values } from "tslib";
import { FilterAttrs } from '../types';
import { FlightFilterBase } from './FlightFilterBase';
var NoneStopFilter = /** @class */ (function (_super) {
    __extends(NoneStopFilter, _super);
    function NoneStopFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(NoneStopFilter.prototype, "attr", {
        get: function () {
            return FilterAttrs.NON_STOP;
        },
        enumerable: false,
        configurable: true
    });
    NoneStopFilter.prototype.toString = function (filterValues) {
        return !!filterValues[this.attr] ? 'non stop' : '';
    };
    NoneStopFilter.prototype.check = function (flight, filterValue) {
        var e_1, _a;
        if (!flight || !filterValue) {
            return true;
        }
        var count = 0;
        try {
            for (var _b = __values(flight.segments), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = _c.value;
                count++;
                if (count > 1) {
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return count < 2;
    };
    return NoneStopFilter;
}(FlightFilterBase));
export { NoneStopFilter };
