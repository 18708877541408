import * as React from 'react';
import { useCallback } from 'react';
import { useTheme } from '../../../theme';
import ParentCounter from '../../../Counter/Counter';
import cn from 'classnames';
var BaggageCounter = function (props) {
    var _a, _b, _c;
    var css = useTheme('BaggageCounter').BaggageCounter;
    var value = props.value || 0;
    var handlerIncrease = useCallback(function () {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, typeof value === 'number' ? value + 1 : 1);
    }, [props.onChange, value]);
    var handlerDecrease = useCallback(function () {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, typeof value === 'number' ? value - 1 : 1);
    }, [props.onChange, value]);
    return (React.createElement(ParentCounter, { classes: {
            root: cn(css.root, props.className, (_a = {},
                _a[css.disabled] = props.disabled,
                _a)),
            plus: css.plus,
            minus: cn(css.minus, (_b = {},
                _b[css.hidden] = !props.value,
                _b)),
            value: cn(css.value, (_c = {},
                _c[css.hidden] = !props.value,
                _c))
        }, value: value, onIncrease: handlerIncrease, onDecrease: handlerDecrease, maxValue: props.max, minValue: props.min }));
};
export default BaggageCounter;
