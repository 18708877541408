import { __read } from "tslib";
import * as React from 'react';
import { useState, useRef, useMemo } from 'react';
import cn from 'classnames';
import Popover from '@material-ui/core/Popover';
import { Chevron } from '../../../../../Icons/Icons';
import { InputType } from '../../../types';
import Input from '../../../../../Input';
import Datepicker from '../../../../../Datepicker/components/Datepicker';
import { ThemeProvider, useTheme } from '../../../../../theme';
import { formatDate } from '../../SearchOrder/SearchOrder';
var Field = function (props) {
    var _a;
    var css = useTheme('SearchOrder').Field;
    var _b = __read(useState(false), 2), isDatePickerOpen = _b[0], setIsDatePickerOpen = _b[1];
    var _c = __read(useState(null), 2), datePickerAnchor = _c[0], setDatePickerAnchor = _c[1];
    var datePickerRef = useRef(null);
    var toggleDatePicker = function () { return setIsDatePickerOpen(!isDatePickerOpen); };
    var showDatePicker = function () {
        setDatePickerAnchor(datePickerRef.current);
        toggleDatePicker();
    };
    var unfocusActiveElement = function () {
        document.activeElement.blur();
    };
    var value = props.values[props.field.name];
    if (props.field.input === InputType.Text) {
        return (React.createElement(Input, { label: props.field.label, name: props.field.name, value: value, className: cn(css.field, css.field__text), TextFieldProps: {
                onChange: function (e) {
                    props.onChange && props.onChange(props.field.name, e.target.value);
                },
                onBlur: function (e) {
                    props.onBlur && props.onBlur(props.field.name, e.target.value);
                }
            } }));
    }
    if (props.field.input === InputType.Date) {
        var datePickerTheme = useMemo(function () { return ({
            Datepicker: {
                WeeklyHeaderStyles: {
                    dayOfWeekName: css.datepicker__dayOfWeekName
                },
                DayStyles: {
                    day_weekend: css.datepicker__day_weekend,
                    day_today: css.datepicker__day_today,
                    day_fromOtherMonth: css.datepicker__day_fromOtherMonth,
                    day_selected: css.datepicker__day_selected
                },
                MonthHeaderStyles: {
                    monthHeader: css.datepicker__monthHeader,
                    monthHeader__select_year: css.datepicker__monthHeader__year
                },
                MonthsWrapperStyles: {
                    nav_next: css.datepicker__nav_next,
                    nav_prev: css.datepicker__nav_prev,
                    wrapper: css.datepicker__month_wrapepr
                }
            }
        }); }, []);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { ref: datePickerRef, onClick: showDatePicker },
                React.createElement(Input, { name: props.field.name, placeholder: props.field.placeholder, value: value ? formatDate(value) : null, inputDisabledClassName: css.field__disabled, className: cn(css.field, css.field__date, (_a = {}, _a[css.field__date_focused] = isDatePickerOpen, _a)), TextFieldProps: {
                        helperText: props.field.label
                    }, readOnly: true, helperClassName: isDatePickerOpen ? css.hint__focused : null })),
            React.createElement(Popover, { open: isDatePickerOpen, onClose: toggleDatePicker, onExiting: unfocusActiveElement, anchorEl: datePickerAnchor, classes: {
                    root: css.datepickerPopover_root,
                    paper: css.datepickerPopover
                }, anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom'
                } },
                React.createElement(ThemeProvider, { value: datePickerTheme },
                    React.createElement(Datepicker, { onSelect: function (value) {
                            props.onChange && props.onChange(props.field.name, value);
                            toggleDatePicker();
                        }, openDate: value ? new Date(value) : null, selectedDates: value ? [new Date(value)] : [], monthCount: 1, navButtons: [Chevron, Chevron], minDate: props.values[props.field.siblingMinName]
                            ? new Date(props.values[props.field.siblingMinName])
                            : null, maxDate: props.values[props.field.siblingMaxName]
                            ? new Date(props.values[props.field.siblingMaxName])
                            : null })))));
    }
    return null;
};
export default Field;
