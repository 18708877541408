import { __assign } from "tslib";
export var servicePopupReducer = function (state, action) {
    var _a, _b, _c, _d;
    var _e, _f, _g, _h;
    if (action.type === 'inc') {
        var payload = action.payload;
        return __assign(__assign({}, state), (_a = {}, _a[payload.passengerId] = __assign(__assign({}, state[payload.passengerId]), (_b = {}, _b[payload.segmentId] = ((_e = state[payload.passengerId]) === null || _e === void 0 ? void 0 : _e[payload.segmentId])
            ? ((_f = state[payload.passengerId]) === null || _f === void 0 ? void 0 : _f[payload.segmentId]) + 1
            : 1, _b)), _a));
    }
    else if (action.type === 'dec') {
        var payload = action.payload;
        return __assign(__assign({}, state), (_c = {}, _c[payload.passengerId] = __assign(__assign({}, state[payload.passengerId]), (_d = {}, _d[payload.segmentId] = ((_g = state[payload.passengerId]) === null || _g === void 0 ? void 0 : _g[payload.segmentId])
            ? ((_h = state[payload.passengerId]) === null || _h === void 0 ? void 0 : _h[payload.segmentId]) - 1
            : 0, _d)), _c));
    }
    else if (action.type === 'state') {
        return action.payload;
    }
    else if (action.type === 'clear') {
        return {};
    }
    return state;
};
