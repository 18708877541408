import { __read, __spreadArray } from "tslib";
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import * as selectSegmentsMutation from '../../../../../../__queries__/SelectCheckinSegments.graphql';
import { getCheckinOrderId, getSegments } from '../../../../store/order/selectors';
import { fillCheckinOrder } from '../../../../store/order/actions';
import * as CheckinStartMutation from '../../../../../../__queries__/CheckinStart.graphql';
import { setCheckinOrder } from '../../../../store/order/actions';
import { CheckinGoal, reachGoal } from '../../../../../../analytics';
export var useSegmentSelect = function (setLoading, setError, onCheckinStart) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var _b = __read(useState(new Set()), 2), selectedSegments = _b[0], setSelectedSegments = _b[1];
    var dispatch = useDispatch();
    var orderId = useSelector(getCheckinOrderId);
    var segments = useSelector(getSegments);
    // segments[1] = { ...segments[0] };
    // segments[1].id = '30';
    var _c = __read(useMutation(selectSegmentsMutation.SelectCheckinSegments), 2), selectSegments = _c[0], _d = _c[1], selectSegmentsError = _d.error, selectSegmentsData = _d.data, selectSegmentsLoading = _d.loading;
    var _e = __read(useMutation(CheckinStartMutation.CheckinStart), 2), startCheckin = _e[0], _f = _e[1], startCheckinErorr = _f.error, startCheckinLoading = _f.loading, startCheckinData = _f.data;
    useEffect(function () {
        var _a;
        setLoading(startCheckinLoading);
        if (!startCheckinLoading && !((_a = startCheckinErorr === null || startCheckinErorr === void 0 ? void 0 : startCheckinErorr.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) && (startCheckinData === null || startCheckinData === void 0 ? void 0 : startCheckinData.CheckinStart)) {
            dispatch(fillCheckinOrder(startCheckinData === null || startCheckinData === void 0 ? void 0 : startCheckinData.CheckinStart));
            setTimeout(onCheckinStart, 0);
        }
    }, [startCheckinLoading, startCheckinErorr, startCheckinData]);
    useEffect(function () {
        if (startCheckinErorr === null || startCheckinErorr === void 0 ? void 0 : startCheckinErorr.graphQLErrors[0]) {
            setError(startCheckinErorr === null || startCheckinErorr === void 0 ? void 0 : startCheckinErorr.graphQLErrors[0]);
        }
    }, [startCheckinErorr]);
    useEffect(function () {
        var _a;
        setLoading(selectSegmentsLoading);
        if (!selectSegmentsLoading &&
            !((_a = selectSegmentsError === null || selectSegmentsError === void 0 ? void 0 : selectSegmentsError.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) &&
            (selectSegmentsData === null || selectSegmentsData === void 0 ? void 0 : selectSegmentsData.CheckinSelectSegments)) {
            startCheckin({ variables: { id: orderId } });
        }
    }, [selectSegmentsLoading, selectSegmentsError, selectSegmentsData]);
    useEffect(function () {
        if (selectSegmentsError === null || selectSegmentsError === void 0 ? void 0 : selectSegmentsError.graphQLErrors[0]) {
            setError(selectSegmentsError === null || selectSegmentsError === void 0 ? void 0 : selectSegmentsError.graphQLErrors[0]);
        }
    }, [selectSegmentsError]);
    var onSelect = useCallback(function (segment) {
        if (selectedSegments.has(segment.id)) {
            selectedSegments.delete(segment.id);
            setSelectedSegments(new Set(selectedSegments));
        }
        else {
            selectedSegments.add(segment.id);
            setSelectedSegments(new Set(selectedSegments));
        }
    }, []);
    var onStart = function () {
        if (segments.length > 1 && !(selectedSegments === null || selectedSegments === void 0 ? void 0 : selectedSegments.size)) {
            setOpen(true);
        }
        if (segments.length > 1 && selectedSegments.size) {
            setOpen(false);
            selectSegments({ variables: { params: { orderId: orderId, segments: __spreadArray([], __read(selectedSegments), false) } } });
        }
    };
    return { isSegmentsSelectionOpen: isOpen, onSelect: onSelect, setOpen: setOpen, selectedSegments: selectedSegments, setSelectedSegments: setSelectedSegments, onStart: onStart };
};
export var useCheckinCancelModal = function () {
    var _a = __read(useState(), 2), checkinCancelResponse = _a[0], setCheckinCancelResponse = _a[1];
    var _b = __read(useState(false), 2), isCheckinCancelModalOpen = _b[0], setCheckinCancelModalOpen = _b[1];
    var dispatch = useDispatch();
    var showCheckinCancelModalIfNeeded = useCallback(function (checkinCancelResp) {
        if (checkinCancelResp.travellers.every(function (traveller) { return traveller.isConfirmed === false; })) {
            setCheckinCancelResponse(checkinCancelResp);
            setCheckinCancelModalOpen(true);
        }
        else {
            dispatch(setCheckinOrder(checkinCancelResp));
            reachGoal(CheckinGoal.Cancelled);
        }
    }, []);
    var handleAgree = useCallback(function () {
        setCheckinCancelModalOpen(false);
        dispatch(setCheckinOrder(checkinCancelResponse));
        reachGoal(CheckinGoal.Cancelled);
    }, [checkinCancelResponse, isCheckinCancelModalOpen]);
    return { showCheckinCancelModalIfNeeded: showCheckinCancelModalIfNeeded, handleAgree: handleAgree, isCheckinCancelModalOpen: isCheckinCancelModalOpen };
};
