import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { MealRecommendation as MealRecommendationContent } from '../../index';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import Modal from '../../../Modal';
import { fillOrder } from '../../../Checkout/store/order/actions';
import { MotivationGoal, reachGoal } from '../../../analytics';
var Component = function (_a) {
    var recommendedMeals = _a.recommendedMeals, onConfirm = _a.onConfirm, onDisagree = _a.onDisagree, segments = _a.segments, passengers = _a.passengers;
    var _b = __read(useState(true), 2), isOpen = _b[0], setIsOpen = _b[1];
    var close = useCallback(function () { return setIsOpen(false); }, []);
    var passengerIds = new Set();
    var segmentIds = new Set();
    // Resulting recommended meal object.
    var meal;
    recommendedMeals.forEach(function (recommendedMeal) {
        // Find out on which segments this meal is available.
        recommendedMeal.segmentIds.forEach(function (segmentId) { return segmentIds.add(segmentId); });
        // Find out which passengers can acquire this meal.
        passengerIds.add(recommendedMeal.travellerId);
        if (!meal) {
            meal = __assign(__assign({}, recommendedMeal.meal), { price: {
                    amount: 0,
                    currency: recommendedMeal.meal.price.currency
                } });
        }
        // Calculate total price of the preselected meal services.
        meal.price.amount +=
            recommendedMeal.meal.price.amount * recommendedMeal.count * recommendedMeal.segmentIds.length;
    });
    var handleOnDisagree = useCallback(function () {
        close();
        onDisagree();
        reachGoal(MotivationGoal.RefuseProposedMeal);
    }, []);
    var handleOnConfirm = useCallback(function () {
        close();
        onConfirm(recommendedMeals);
        reachGoal(MotivationGoal.ConfirmProposedMeal);
    }, [recommendedMeals, fillOrder]);
    // Warn user if not all passengers are able to acquire this particular meal.
    var availableForPassengers = '';
    if (passengerIds.size !== passengers.length) {
        availableForPassengers = __spreadArray([], __read(passengerIds), false).map(function (passengerId) { return passengers.find(function (_a) {
            var id = _a.id;
            return id === passengerId;
        }).name; })
            .join(', ');
    }
    // Warn user if this meal is available only on some particular segments.
    var availableForSegments = '';
    if (segmentIds.size !== segments.length) {
        availableForSegments = __spreadArray([], __read(segmentIds), false).sort()
            .map(function (segmentId) {
            var segment = segments[segmentId];
            return "".concat(segment.departure.name, " \u2014 ").concat(segment.arrival.name);
        })
            .join(', ');
    }
    return meal ? (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: "mobile" },
            React.createElement(Modal, { maxWidth: "sm", open: isOpen, onClose: handleOnDisagree },
                React.createElement(MealRecommendationContent, { availableForPassengers: availableForPassengers, availableForSegments: availableForSegments, meal: meal, onChange: handleOnDisagree, onConfirm: handleOnConfirm }))),
        React.createElement(MediaQuery, { maxWidth: "mobile" },
            React.createElement(Modal, { fullScreen: true, open: isOpen, onClose: handleOnDisagree },
                React.createElement(MealRecommendationContent, { availableForPassengers: availableForPassengers, availableForSegments: availableForSegments, isMobileFullScreen: true, meal: meal, onChange: handleOnDisagree, onConfirm: handleOnConfirm }))))) : null;
};
export default Component;
