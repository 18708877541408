import * as React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '../../../Button/Button';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
var EmptyDatesOverlay = function (props) {
    var css = useTheme('SearchFormDatepicker').SearchFormDatepickerStyles;
    var t = useTranslation('SearchFormDatepicker').t;
    var onClick = props.onClick;
    return (React.createElement(Backdrop, { open: true, classes: { root: css.overlay } },
        React.createElement("h5", { className: css.overlay__title },
            t('Oops'),
            " :("),
        React.createElement("p", { className: css.overlay__msg }, t('Something went wrong, please check your internet connection or come back later')),
        React.createElement(Button, { classes: { root: css.overlay }, onClick: onClick }, t('Ok'))));
};
export default EmptyDatesOverlay;
