var _a;
import { __read } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import AirplaneInfo from '../AirplaneInfo/AirplaneInfo';
import { SunSides } from '../../../__generated__/globalTypes';
import { getDurationString } from '../../../Results/utils';
import { initI18n, MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../../utils';
import i18n from 'i18next';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import AircraftPopup from '../../../AircraftPopup/components/AircraftPopup';
import Modal from '../../../Modal';
import Link from '../../../Link';
initI18n('SeatMap');
var labels = (_a = {
        '': ''
    },
    _a[SunSides.LeftSide] = i18n.t('SeatMap:90% on the left side'),
    _a[SunSides.RightSide] = i18n.t('SeatMap:90% on the right side'),
    _a);
var Airplane = function (_a) {
    var _b;
    var aircraft = _a.aircraft, sunInfo = _a.sunInfo, routeTime = _a.routeTime, onSeatsDetailsClick = _a.onSeatsDetailsClick, segment = _a.segment, className = _a.className;
    var theme = useTheme('SeatMap').AirplaneStyles;
    var duration = routeTime ? getDurationString(routeTime) : null;
    var _c = __read(React.useState(false), 2), aircraftOpen = _c[0], setAircraftOpen = _c[1];
    var t = useTranslation('SeatMap').t;
    return (React.createElement("div", { className: cn(theme.airplane, className, (_b = {},
            _b[theme.airplane_noInfo] = !(aircraft === null || aircraft === void 0 ? void 0 : aircraft.name) && !(aircraft === null || aircraft === void 0 ? void 0 : aircraft.icon),
            _b)) },
        segment && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: theme.segment },
                segment.segmentInfo.departure.airport.city.name,
                ' — ',
                segment.segmentInfo.arrival.airport.city.name))),
        React.createElement("div", { className: theme.airplane__info },
            (aircraft === null || aircraft === void 0 ? void 0 : aircraft.icon) && React.createElement("img", { src: aircraft.icon, alt: aircraft.name, className: theme.airplane__image }),
            (aircraft === null || aircraft === void 0 ? void 0 : aircraft.name) && (React.createElement("div", { className: theme.airplane__name },
                React.createElement(Link, { action: function () { return setAircraftOpen(true); } }, aircraft.name)))),
        React.createElement("div", { className: theme.details },
            React.createElement(AirplaneInfo, { text: duration, type: "time" }),
            React.createElement(AirplaneInfo, { text: labels[sunInfo], type: "sun" })),
        aircraftOpen && (React.createElement(Modal, { open: true, onClose: function () { return setAircraftOpen(false); } },
            React.createElement(AircraftPopup, { id: aircraft.id }))),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement("div", { className: theme.seatsDetails, onClick: onSeatsDetailsClick }, t('Seat details')))));
};
export default Airplane;
