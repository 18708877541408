import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import CheckoutAdditionalService from '../..';
import { useTheme, ThemeProvider } from '../../../theme';
import Modal from '../../../Modal';
import { getCapsuleHotel, getSegments } from '../../../Checkout/store/order/selectors';
import { getCalculatedCapsuleHotelPrice } from '../../../Checkout/store/orderPrice/selectors';
import { useOrderService } from '../hooks';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../__generated__/globalTypes';
import CapsuleHotelPopup from '../../../CapsuleHotelPopup';
import { MOBILE_MIN_WIDTH } from '../../../utils';
var CapsuleHotel = function () {
    var theme = useTheme('CheckoutAdditionalService').CapsuleHotel;
    var segments = useSelector(getSegments);
    var capsuleHotelServices = useSelector(getCapsuleHotel);
    var price = useSelector(getCalculatedCapsuleHotelPrice);
    var t = useTranslation('Checkout').t;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _a = useOrderService(capsuleHotelServices, OrderAdditionalServiceGdsServiceServiceType.Vip), isSelected = _a.isSelected, selectedServices = _a.selectedServices, onOpen = _a.onOpen, onClose = _a.onClose, setOpened = _a.setOpened, onSaveHandler = _a.onSaveHandler, passengers = _a.passengers, opened = _a.opened;
    var checkoutAdditionalServiceTheme = React.useMemo(function () { return ({
        CheckoutAdditionalService: {
            CheckoutAdditionalService: {
                main: theme.main
            }
        }
    }); }, []);
    if (capsuleHotelServices.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ThemeProvider, { value: checkoutAdditionalServiceTheme },
            React.createElement(CheckoutAdditionalService, { header: t('Capsule hotel'), description: capsuleHotelServices[0].description, className: theme.capsuleHotel, priceFrom: price, priceTotal: price, isSelected: isSelected, addClick: onOpen, descriptionClassName: theme.descriptions, buttons: [
                    {
                        action: function () { return setOpened(true); },
                        text: t('Change')
                    }
                ] }, capsuleHotelServices[0].description)),
        React.createElement(Modal, { classes: {
                root: theme.modal_root,
                paper: theme.paper,
                scrollBody: theme.modal_scrollBody
            }, slideUp: isMobile, open: opened },
            React.createElement(CapsuleHotelPopup, { description: capsuleHotelServices[0].description, price: price, passengers: passengers, services: capsuleHotelServices, segments: segments, selectedServices: selectedServices, onClose: onClose, onSave: onSaveHandler }))));
};
export default CapsuleHotel;
