import { __assign, __rest } from "tslib";
import cn from 'classnames';
import * as React from 'react';
import { useContext } from 'react';
import MediaQuery from 'react-responsive';
import { Field, FormSpy, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import LocationAutocomplete from '../../../../../../LocationAutocomplete';
import { LocationType } from '../../../../../../types';
import { MOBILE_MIN_WIDTH } from '../../../../../../utils';
import Value from './Value/Value';
import Tooltip from '../../../../../../Tooltip/Tooltip';
import { ConfigContext } from '../../../../../../context';
import { useTheme } from '../../../../../../theme';
var IGNORE_CLICKOUTSIDE_CLASS = 'ignore_clickoutside';
var switcherIcon = (React.createElement("svg", { width: "11", height: "15", viewBox: "0 0 11 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.99 15L0 11L3.99 7V10H11V12H3.99V15ZM0 3H7.01V0L11 4L7.01 8L7.01 5H0L0 3Z", fill: "currentColor" })));
var verticalSwitcherIcon = (React.createElement("svg", { width: "14", height: "18", viewBox: "0 0 14 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 3.99L4 0L8 3.99H5V11H3V3.99H0ZM11 7V14.01H14L10 18L6 14.01H9V7H11Z", fill: "white" })));
var lastVisibleError = '';
export default React.memo(function (_a) {
    var type = _a.type, _b = _a.className, className = _b === void 0 ? '' : _b, setLocation = _a.setLocation, locations = _a.locations, onSwap = _a.onSwap, recentSearches = _a.recentSearches, segmentId = _a.segmentId, withMultiCity = _a.withMultiCity, onSwapMultiCity = _a.onSwapMultiCity;
    var _c = useTheme('SearchForm'), Segment = _c.Segment, css = _c.Location;
    var t = useTranslation('SearchForm').t;
    var alternative = useContext(ConfigContext).SearchForm.mobileFormType !== 'common';
    var proMode = useContext(ConfigContext).SearchForm.proMode === true;
    var location = locations[type];
    var additionalProps = {
        outsideClickIgnoreClass: IGNORE_CLICKOUTSIDE_CLASS
    };
    var form = useForm();
    var onSwapButtonClick = function () {
        if (onSwapMultiCity) {
            onSwapMultiCity(segmentId);
        }
        if (onSwap) {
            onSwap();
        }
    };
    var validate = function (value, allValues, meta) {
        var data = meta.data;
        if (!data || !data[type]) {
            return t('Please select {{type}} airport', {
                type: t(type)
            });
        }
        if (data && data.arrival && data.departure && data.arrival.iata === data.departure.iata) {
            return t('Please select different airports');
        }
    };
    var onChange = function (value) {
        setLocation(value, type);
    };
    var getRenderInput = function (fieldState) { return function (isOpen, isLoading, inputProps) {
        var _a, _b, _c;
        var onChange = inputProps.onChange, onFocus = inputProps.onFocus, onBlur = inputProps.onBlur, restProps = __rest(inputProps, ["onChange", "onFocus", "onBlur"]);
        var finalProps = restProps;
        finalProps.name = fieldState.input.name;
        if (inputProps.onBlur) {
            finalProps.onBlur = function (event) {
                onBlur(event);
                fieldState.input.onBlur(event);
            };
        }
        if (inputProps.onFocus) {
            finalProps.onFocus = function (event) {
                onFocus(event);
                form.mutators.resetSubmitForm();
                fieldState.input.onFocus(event);
            };
        }
        if (inputProps.onChange) {
            finalProps.onChange = function (event) {
                onChange(event);
                fieldState.input.onChange(event);
            };
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement("input", __assign({}, finalProps, { className: cn(IGNORE_CLICKOUTSIDE_CLASS, Segment.input, className, css.input, (_a = {},
                        _a[css.input_withoutPlaceholder] = !!location,
                        _a[css.input_departure] = type === LocationType.Departure,
                        _a[css.input_arrival] = type === LocationType.Arrival,
                        _a)), placeholder: t(type === LocationType.Departure ? 'From' : 'To'), autoComplete: "off" }))),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { onClick: inputProps.onFocus, className: cn(Segment.input, className, css.input, (_b = {},
                        _b[css.input_withoutPlaceholder] = !!location,
                        _b[css.input_departure] = type === LocationType.Departure,
                        _b[css.input_arrival] = type === LocationType.Arrival,
                        _b)) }, t(type === LocationType.Departure ? 'From' : 'To'))),
            (onSwap || onSwapMultiCity) && (!isOpen || !isLoading) && (React.createElement("button", { type: "button", className: cn(css.switcher, (_c = {},
                    _c[css.switcher_highlighted] = isOpen,
                    _c[css.switcher_alternative] = alternative,
                    _c)), tabIndex: -1, onClick: onSwapButtonClick },
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, switcherIcon),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, !alternative ? verticalSwitcherIcon : switcherIcon))),
            React.createElement(Value, { type: type, isOpen: isOpen, isLoading: isLoading, location: location, inputValue: inputProps.value, withMultiCity: withMultiCity, withSwapButton: Boolean(onSwap || onSwapMultiCity) })));
    }; };
    return (React.createElement(FormSpy, { subscription: {
            submitFailed: true
        } }, function (formState) { return (React.createElement(Field, { name: "".concat(type, "_").concat(segmentId), validate: validate, subscription: {
            data: true,
            error: true
        } }, function (fieldState) {
        var _a;
        if (location !== null || typeof fieldState.meta.data[type] !== 'undefined') {
            formState.form.mutators.setFieldData(fieldState.input.name, locations);
        }
        if (fieldState.meta.error) {
            lastVisibleError = fieldState.meta.error;
        }
        return (React.createElement(Tooltip, { title: lastVisibleError, open: !!fieldState.meta.error && formState.submitFailed, placement: proMode ? 'bottom' : 'top' },
            React.createElement(LocationAutocomplete, { type: type, locations: locations, className: cn(css.autocomplete, (_a = {},
                    _a[css.autocomplete_alternative] = alternative,
                    _a)), onSelect: onChange, recentSearches: recentSearches, inputRenderer: getRenderInput(fieldState), onClickOutsideProps: additionalProps })));
    })); }));
});
