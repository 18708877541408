import { __read, __spreadArray } from "tslib";
import memes from '../../../../memes';
import { format, isAfter, max, min } from 'date-fns';
import { getDateWithoutTimezone } from '../../../../Engine/components/FastSearch/utils';
export var isFlightsDatesInRightOrder = memes(function (prev, curr, index, segments) {
    var currentSegment = segments[index];
    var nextSegment = segments[index + 1];
    if (nextSegment && !prev) {
        var currentSegmentDate = new Date(currentSegment.date);
        var nextSegmentDate = new Date(nextSegment.date);
        return isAfter(currentSegmentDate, nextSegmentDate);
    }
    return prev;
});
export var isWrongOrder = function (segments, reduceRight) {
    if (reduceRight === void 0) { reduceRight = false; }
    return reduceRight
        ? segments.reduceRight(isFlightsDatesInRightOrder, false)
        : segments.reduce(isFlightsDatesInRightOrder, false);
};
export var sortSegments = memes(function (legId, date, segments) {
    var segmentsCopy = __spreadArray([], __read(segments), false);
    var DATE_FORMAT = 'yyyy-MM-dd';
    if (legId === 0 && isWrongOrder(segmentsCopy)) {
        var maxDate_1 = max(segmentsCopy.map(function (segment) { return getDateWithoutTimezone(segment.date); }));
        segmentsCopy.forEach(function (segment) {
            segment.date = format(maxDate_1, DATE_FORMAT);
        });
    }
    else if (legId > 0 && isWrongOrder(segmentsCopy, true)) {
        var minDate_1 = min(__spreadArray(__spreadArray([], __read(segmentsCopy.map(function (segment) { return getDateWithoutTimezone(segment.date); })), false), [date], false));
        segmentsCopy.forEach(function (segment) {
            segment.date = format(minDate_1, DATE_FORMAT);
        });
    }
    return segmentsCopy;
});
