import * as React from 'react';
export var googleIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M6.98918 13.8776L6.36263 16.2166L4.07258 16.2651C3.3882 14.9957 3 13.5434 3\n\t\t\t\t 12C3 10.5076 3.36295 9.10021 4.00631 7.86099H4.0068L6.04559 8.23477L6.9387 10.2613C6.75177\n\t\t\t\t  10.8063 6.64989 11.3913 6.64989 12C6.64996 12.6607 6.76963 13.2937 6.98918 13.8776Z", fill: "#FBBB00" }),
    React.createElement("path", { d: "M20.8427 10.3187C20.9461 10.8631 21 11.4254 21 12C21 12.6444 20.9322 13.2729\n\t\t\t\t 20.8032 13.8791C20.3651 15.9422 19.2203 17.7437 17.6344 19.0185L17.6339 19.018L15.066\n\t\t\t\t 18.887L14.7025 16.6182C15.7548 16.0011 16.5772 15.0353 17.0104 13.8791H12.1978V10.3187H17.0806H20.8427Z", fill: "#518EF8" }),
    React.createElement("path", { d: "M17.6339 19.018L17.6344 19.0185C16.092 20.2582 14.1328 21 12 21C8.57257 21 5.59269 19.0843\n\t\t\t\t 4.07257 16.2651L6.98917 13.8777C7.74921 15.9061 9.70597 17.3501 12 17.3501C12.986\n\t\t\t\t  17.3501 13.9098 17.0835 14.7024 16.6182L17.6339 19.018Z", fill: "#28B446" }),
    React.createElement("path", { d: "M17.7447 5.07197L14.8291 7.45894C14.0087 6.94615 13.0389 6.64992 12 6.64992C9.65406 6.64992\n\t\t\t\t 7.6607 8.16013 6.93874 10.2613L4.00681 7.86099H4.00632C5.50418 4.97307 8.52165 3 12 3C14.1837 3\n\t\t\t\t  16.186 3.77787 17.7447 5.07197Z", fill: "#F14336" })));
export var facebookIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M15.6876 12.5068H13.2748C13.2748 16.3139 13.2748 21 13.2748 21H9.69952C9.69952 21 9.69952\n\t\t\t\t 16.3593 9.69952 12.5068H8V9.50501H9.69952V7.56342C9.69952 6.17286 10.3686 4 13.3076\n\t\t\t\t  4L15.957 4.01003V6.92389C15.957 6.92389 14.3471 6.92389 14.034 6.92389C13.721\n\t\t\t\t   6.92389 13.276 7.07847 13.276 7.74159V9.5056H16L15.6876 12.5068Z", fill: "white" })));
export var twitterIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M19.1592 8.48531C19.1663 8.63749 19.1702 8.79091 19.1702 8.94432C19.1702 13.6155 15.4563\n\t\t\t\t 19 8.66132 19C6.57557 19 4.63395 18.4166 3 17.4132C3.28892 17.446 3.583 17.4627 3.88097\n\t\t\t\t 17.4627C5.61187 17.4627 7.20381 16.8973 8.46806 15.9496C6.8522 15.9211 5.48777 14.8992 5.01788\n\t\t\t\t 13.4943C5.24281 13.5351 5.47485 13.558 5.71205 13.558C6.0488 13.558 6.37585 13.5159 6.68545\n\t\t\t\t 13.4349C4.99591 13.1107 3.72326 11.6823 3.72326 9.96876C3.72326 9.95391 3.72326 9.93845\n\t\t\t\t 3.7239 9.92422C4.22159 10.1884 4.79102 10.348 5.39599 10.3659C4.4058 9.73307 3.75364 8.65048\n\t\t\t\t 3.75364 7.42438C3.75364 6.77606 3.93526 6.16857 4.25326 5.64646C6.07401 7.78565 8.7964 9.19239\n\t\t\t\t 11.8652 9.34086C11.8019 9.08166 11.7702 8.81256 11.7702 8.5348C11.7702 6.58305 13.4236 5 15.4628\n\t\t\t\t 5C16.5254 5 17.4839 5.42932 18.1587 6.11599C19.0009 5.95762 19.7894 5.6644 20.5049 5.25796C20.227\n\t\t\t\t 6.08382 19.6433 6.77606 18.8787 7.21404C19.6265 7.12867 20.3401 6.93938 21 6.6579C20.5075\n\t\t\t\t 7.36561 19.8812 7.98855 19.1592 8.48531Z", fill: "white" })));
export var cancelIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.47 6.47 2 12 2C17.53 2 22 6.47 22 12C22 17.53 17.53 22 12 22C6.47 22 2 17.53 2 12ZM15.59 17L17 15.59L13.41 12L17 8.41L15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17Z", fill: "#CDCDCD" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "2", y: "2", width: "20", height: "20" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.47 6.47 2 12 2C17.53 2 22 6.47 22 12C22 17.53 17.53 22 12 22C6.47 22 2 17.53 2 12ZM15.59 17L17 15.59L13.41 12L17 8.41L15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var addIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 13H17V11H13V7H11V11H7V13H11V17H13V13Z" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "2", y: "2", width: "20", height: "20" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 13H17V11H13V7H11V11H7V13H11V17H13V13Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var downloadIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z", fill: "#9A9A9A" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "5", y: "3", width: "14", height: "17" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var codeSuccessIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M9.00002 16.2L4.80002 12L3.40002 13.4L9.00002 19L21 6.99998L19.6 5.59998L9.00002 16.2Z", fill: "#55D590" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "3", y: "5", width: "19", height: "14" },
        React.createElement("path", { d: "M9.00002 16.2L4.80002 12L3.40002 13.4L9.00002 19L21 6.99998L19.6 5.59998L9.00002 16.2Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var successIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.66699 10.0003C1.66699 5.40033 5.40033 1.66699 10.0003 1.66699C14.6003 1.66699 18.3337 5.40033 18.3337 10.0003C18.3337 14.6003 14.6003 18.3337 10.0003 18.3337C5.40033 18.3337 1.66699 14.6003 1.66699 10.0003ZM4.16699 10.0003L8.33366 14.167L15.8337 6.66699L14.6587 5.48366L8.33366 11.8087L5.34199 8.82533L4.16699 10.0003Z", fill: "#55D590" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "1", y: "1", width: "18", height: "18" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.66699 10.0003C1.66699 5.40033 5.40033 1.66699 10.0003 1.66699C14.6003 1.66699 18.3337 5.40033 18.3337 10.0003C18.3337 14.6003 14.6003 18.3337 10.0003 18.3337C5.40033 18.3337 1.66699 14.6003 1.66699 10.0003ZM4.16699 10.0003L8.33366 14.167L15.8337 6.66699L14.6587 5.48366L8.33366 11.8087L5.34199 8.82533L4.16699 10.0003Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var closeDialogIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M25.3337 8.54699L23.4537 6.66699L16.0003 14.1203L8.54699 6.66699L6.66699 8.54699L14.1203 16.0003L6.66699 23.4537L8.54699 25.3337L16.0003 17.8803L23.4537 25.3337L25.3337 23.4537L17.8803 16.0003L25.3337 8.54699Z", fill: "white" })));
export var deleteIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.5 4H19V6H5V4H8.5L9.5 3H14.5L15.5 4ZM8 21C6.9 21 6 20.1 6 19V7H18V19C18 20.1 17.1 21 16 21H8Z", fill: "#9A9A9A" })));
export var vkIcon = (React.createElement("svg", { height: "24", viewBox: "0 0 24 24", width: "24", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fill: "white", d: "M21.54736,7H18.25688a.74281.74281,0,0,0-.65452.39156s-1.31237,2.41693-1.73392,3.231C14.73438,12.8125,14,12.125,14,11.10863V7.60417A1.10417,1.10417,0,0,0,12.89583,6.5h-2.474a1.9818,1.9818,0,0,0-1.751.8125s1.25626-.20312,1.25626,1.48958c0,.41974.02162,1.62723.04132,2.64a.72943.72943,0,0,1-1.273.50431,21.54029,21.54029,0,0,1-2.4982-4.54359A.69314.69314,0,0,0,5.5668,7C4.8532,7,3.42522,7,2.57719,7a.508.508,0,0,0-.47969.68481C3.00529,10.17487,6.91576,18,11.37917,18h1.87865A.74219.74219,0,0,0,14,17.25781V16.12342a.7293.7293,0,0,1,1.22868-.5315l2.24861,2.1127A1.08911,1.08911,0,0,0,18.223,18h2.95281c1.42415,0,1.42415-.98824.64768-1.75294-.54645-.53817-2.51832-2.61663-2.51832-2.61663A1.01862,1.01862,0,0,1,19.2268,12.307c.63737-.83876,1.67988-2.21175,2.122-2.79993C21.95313,8.70313,23.04688,7,21.54736,7Z" })));
