import * as React from 'react';
import { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { Button, Money } from '../../../index';
import SeatMapDisclaimer from '../SeatMapDisclaimer/SeatMapDisclaimer';
import Passenger from './Passenger/Passenger';
import Controls from '../SeatMapService/Controls/Controls';
import { SeatsRecommendContext } from '../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import { AviaPassengerType } from '../../../__generated__/globalTypes';
export var filledPersonIcon = (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.666 9.33335C18.666 11.9117 16.5777 14 13.9993 14C11.421 14 9.33268 11.9117 9.33268 9.33335C9.33268 6.75502 11.421 4.66669 13.9993 4.66669C16.5777 4.66669 18.666 6.75502 18.666 9.33335ZM4.66602 19.8334C4.66602 16.73 10.8843 15.1667 13.9993 15.1667C17.1143 15.1667 23.3327 16.73 23.3327 19.8334V23.3334H4.66602V19.8334Z", fill: "currentColor" })));
export var recommend = (React.createElement("svg", { width: "21", height: "20", viewBox: "0 0 21 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.9999 6.66683C18.9166 6.66683 19.6666 7.41683 19.6666 8.3335L19.6583 8.40016L19.6666 8.4085V10.0002C19.6666 10.2168 19.6249 10.4168 19.5499 10.6085L17.0333 16.4835C16.7833 17.0835 16.1916 17.5002 15.4999 17.5002H7.99992C7.08325 17.5002 6.33325 16.7502 6.33325 15.8335V7.50016C6.33325 7.04183 6.51658 6.62516 6.82492 6.32516L12.3083 0.833496L13.1916 1.7085C13.4166 1.9335 13.5583 2.25016 13.5583 2.59183L13.5333 2.8585L12.7416 6.66683H17.9999ZM4.66658 17.5002H1.33325V7.50016H4.66658V17.5002Z", fill: "currentColor" })));
var Passengers = function (props) {
    var _a;
    var theme = useTheme('SeatMap').PassengersStyles;
    var t = useTranslation('SeatMap').t;
    var seatRecommendContext = useContext(SeatsRecommendContext);
    var hasRecommendSeats = (seatRecommendContext === null || seatRecommendContext === void 0 ? void 0 : seatRecommendContext.rowsMap) &&
        Object.keys(seatRecommendContext === null || seatRecommendContext === void 0 ? void 0 : seatRecommendContext.rowsMap).length > 0 &&
        seatRecommendContext.availableSeatsCount > 0;
    var hasRemovableSeats = props.passengers.some(function (passenger) { var _a; return (_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.some(function (selectedSeat) { return !(selectedSeat === null || selectedSeat === void 0 ? void 0 : selectedSeat.isConfirmed) && selectedSeat.segmentId === props.segmentId; }); });
    var renderPassengersForRecommend = function () {
        var rowsMap = seatRecommendContext.rowsMap;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.recommend__wrapper },
                React.createElement("div", { className: theme.recommend__label },
                    recommend,
                    t('Recommend')),
                props.passengers.length > 1 && React.createElement("div", { className: theme.together }, t('Fly together!')),
                React.createElement("div", null, props.passengers.map(function (passenger) {
                    var selectedSeat = rowsMap[props.segmentId].seats[passenger.id];
                    return (React.createElement(Passenger, { passenger: passenger, key: passenger.id, isActive: parseInt(passenger.id) === props.passengerIndex, seat: selectedSeat.seat, seatPrice: selectedSeat.seat.price, onClick: props.onChange, onClear: props.onCancel, recommend: true }));
                })),
                React.createElement("div", { className: theme.recommend__price },
                    t('Total'),
                    ": ",
                    React.createElement(Money, { money: rowsMap[props.segmentId].price }))),
            React.createElement("div", { className: theme.passengers__buttons },
                React.createElement(Button, { variant: "flat", onClick: seatRecommendContext.onDisagree, classes: { root: cn(theme.passengers__button, theme.passengers__button_clear) } }, t('Choose myself')),
                React.createElement(Button, { variant: "flat", classes: {
                        root: cn(theme.passengers__button)
                    }, onClick: seatRecommendContext.onConfirm }, t('Confirm')))));
    };
    var renderPassengers = function () {
        return props.hidePassengers ? null : (React.createElement(React.Fragment, null,
            React.createElement("div", null, props.passengers.map(function (passenger, index) {
                var selectedSeat = passenger.seats && passenger.seats.find(function (seat) { return seat.segmentId === props.segmentId; });
                var linkedPassenger = props.passengers.find(function (id) { return id.linkedTraveller === passenger.id; });
                if (passenger.type === AviaPassengerType.INF) {
                    return React.createElement(React.Fragment, null);
                }
                return (React.createElement(Passenger, { passenger: passenger, linkedPassenger: linkedPassenger, key: passenger.id, isActive: parseInt(passenger.id) === props.passengerIndex, seat: selectedSeat, seatPrice: selectedSeat === null || selectedSeat === void 0 ? void 0 : selectedSeat.price, onClick: props.onChange, onClear: props.onCancel }));
            })),
            React.createElement("div", { className: theme.passengers__footer },
                seatRecommendContext.isEnabled && hasRecommendSeats && (React.createElement("div", { className: theme.recommend, onClick: seatRecommendContext.onOpen },
                    React.createElement("div", { className: theme.recommend__icon }, recommend),
                    t('Show recommended seats'))),
                React.createElement(Controls, { segmentId: props.segmentIndex, segmentsCount: props.segmentsCount, onClear: props.onClear, onProceed: props.onProceed, clearEnable: hasRemovableSeats, onSelectNextFlight: props.onSelectNextFlight }),
                !props.hideDisclaimer && React.createElement(SeatMapDisclaimer, null))));
    };
    return (React.createElement("div", { id: "passengers", className: cn(theme.passengers, (_a = {}, _a[theme.passengers_recommend] = seatRecommendContext.isOpen, _a)) }, seatRecommendContext.isOpen ? renderPassengersForRecommend() : renderPassengers()));
};
export default Passengers;
