import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useCallback } from 'react';
import { useRenderers } from '../../../../renderProps';
var MobileSegmentFlatTime = function (props) {
    var _a, _b;
    var theme = useTheme('MobileSegmentInfo').MobileSegmentFlat;
    var renderInfoUnderTime = useRenderers().renderInfoUnderTime;
    var renderRouteDescriptionInfo = useCallback(function (props) {
        return renderInfoUnderTime ? (renderInfoUnderTime(props)) : (React.createElement("span", { className: cn(theme.city) }, props.city));
    }, [renderInfoUnderTime]);
    return (React.createElement("div", { className: cn(theme.row, theme.row_time, props.className, (_a = {},
            _a[theme.row_first] = props.segmentType === 'first',
            _a[theme.row_last] = props.segmentType === 'last',
            _a)) },
        React.createElement("div", { className: cn(theme.time, theme.time_dep, props.timeClassName) },
            props.departureTime,
            renderRouteDescriptionInfo({
                city: props.departureCity,
                iata: props.departureIATA,
                terminal: props.departureTerminal
            })),
        React.createElement("div", { className: theme.duration__wrapper },
            React.createElement("div", { className: theme.duration },
                React.createElement("div", { className: theme.duration__point }),
                React.createElement("span", null,
                    React.createElement("span", { className: theme.duration__text }, props.durationTime || 'NA'),
                    props.transfer && (React.createElement("span", { className: cn(theme.transfer, (_b = {}, _b[theme.transfer_stops] = props.transfer.count, _b)) }, props.transfer.label)))),
            props.flightNumber && React.createElement("div", { className: theme.flightNumber__route }, props.flightNumber)),
        React.createElement("div", { className: cn(theme.time, theme.time_arr, props.timeClassName) },
            props.arrivalTime,
            props.nextDay > 0 && React.createElement("span", { className: theme.transferCount }, "+".concat(props.nextDay)),
            renderRouteDescriptionInfo({
                city: props.arrivalCity,
                iata: props.arrivalIATA,
                terminal: props.arrivalTerminal
            }))));
};
export default MobileSegmentFlatTime;
