import * as React from 'react';
import { cityIn } from 'lvovich';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { getDurationString } from '../../../../Results/utils';
import cn from 'classnames';
var StopsInfo = function (_a) {
    var stops = _a.stops, stopCityName = _a.stopCityName, transferDuration = _a.transferDuration, className = _a.className;
    var t = useTranslation('DesktopFlightInfo').t;
    var theme = useTheme('DesktopFlightRoute').StopsInfoStyles;
    return (React.createElement("div", { className: cn(theme.stop, className) },
        React.createElement("span", { className: theme.stop__label },
            stops.length > 0 &&
                "".concat(t('DesktopFlightInfo:TechnicalLandingIn', {
                    count: stops.length
                }), " ").concat(stops.map(function (stop) { var _a, _b; return cityIn((_b = (_a = stop.airport) === null || _a === void 0 ? void 0 : _a.city) === null || _b === void 0 ? void 0 : _b.name); }).join(', '), ". "),
            transferDuration && "".concat(t('Stop in'), " ").concat(cityIn(stopCityName), " - ").concat(getDurationString(transferDuration)))));
};
export default StopsInfo;
