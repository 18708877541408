import { __read, __spreadArray } from "tslib";
export var getServicesByRfics = function (services) {
    var servicesByRfics = new Map();
    services.forEach(function (service) {
        var rfics = service.rfisc;
        servicesByRfics.has(rfics)
            ? servicesByRfics.set(rfics, __spreadArray(__spreadArray([], __read(servicesByRfics.get(rfics)), false), [service], false))
            : servicesByRfics.set(rfics, [service]);
    });
    return __spreadArray([], __read(servicesByRfics.values()), false);
};
