import { __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useEffect, useState } from 'react';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import Events from './Components/Events/Events';
import Queues from './Components/Queues/Queues';
import PromoLoader from '../../PromoLoader/components/PromoLoader';
import { parseISO } from 'date-fns';
import { calcDuration, filterEventsByCategory, filterEventsBySearch } from './utils';
import { useSupplierQueuesQuery, useSupplierQueueEventsQuery } from '@websky/graphql';
import QueuesOwnerModal from './Components/QueuesOwnerModal/QueuesOwnerModal';
import { useToggleable } from '../../hooks';
import { usePointOfSales } from '../leoCheckIsAuthenticated/pointOfSalesContext';
import Header from './Components/Header/Header';
initI18n('EventsQueues');
var EventsQueues = function () {
    var _a;
    var theme = useTheme('EventsQueues').EventsQueues;
    var _b = __read(useState(null), 2), selectedQueue = _b[0], setSelectedQueue = _b[1];
    var _c = __read(useState(null), 2), selectedCategory = _c[0], setSelectedCategory = _c[1];
    var _d = __read(useState(null), 2), queues = _d[0], setQueues = _d[1];
    var _e = __read(useState(null), 2), queuesOwner = _e[0], setQueuesOwner = _e[1];
    var _f = __read(useState(false), 2), eventsLoading = _f[0], setEventsLoading = _f[1];
    var _g = __read(useState(null), 2), requestError = _g[0], setRequestError = _g[1];
    var _h = useToggleable(false), isQueuesOwnerOpen = _h.isOpen, closeQueuesOwner = _h.close, openQueuesOwner = _h.open;
    var _j = __read(useState(false), 2), queuesLoading = _j[0], setQueuesLoading = _j[1];
    var _k = __read(useState(null), 2), searchingLocator = _k[0], setSearchingLocator = _k[1];
    var _l = __read(useState(true), 2), initialLoading = _l[0], setInitialLoading = _l[1];
    var _m = __read(useState(false), 2), isRefreshing = _m[0], setIsRefreshing = _m[1];
    var _o = __read(useState([]), 2), eventsForQueues = _o[0], setEventsForQueue = _o[1];
    var posCredentials = usePointOfSales();
    var queuesRefetch = useSupplierQueuesQuery({ skip: true, fetchPolicy: 'no-cache' }).refetch;
    var eventsRefetch = useSupplierQueueEventsQuery({ skip: true, fetchPolicy: 'no-cache' }).refetch;
    useEffect(function () {
        fetchQueuesEvents();
    }, [selectedQueue]);
    useEffect(function () {
        if (posCredentials === null || posCredentials === void 0 ? void 0 : posCredentials.agency) {
            if (posCredentials.isAgencySupervisor) {
                openQueuesOwner();
                setInitialLoading(false);
            }
            else {
                setQueuesOwner({
                    agency: posCredentials.agency,
                    user: posCredentials.id
                });
            }
        }
    }, [posCredentials === null || posCredentials === void 0 ? void 0 : posCredentials.agency]);
    useEffect(function () {
        if (queuesOwner === null || queuesOwner === void 0 ? void 0 : queuesOwner.agency) {
            fetchQueues();
        }
    }, [queuesOwner]);
    var fetchQueues = function () { return __awaiter(void 0, void 0, void 0, function () {
        var queues;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, queuesRefetch({
                        params: {
                            agency: queuesOwner.agency,
                            user: queuesOwner.user
                        }
                    })];
                case 1:
                    queues = _c.sent();
                    if ((_b = (_a = queues === null || queues === void 0 ? void 0 : queues.data) === null || _a === void 0 ? void 0 : _a.SupplierQueues) === null || _b === void 0 ? void 0 : _b.queues) {
                        setQueues(queues.data);
                    }
                    setIsRefreshing(false);
                    setInitialLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchQueuesEvents = useCallback(function () {
        if (selectedQueue === null || selectedQueue === void 0 ? void 0 : selectedQueue.number) {
            setEventsLoading(true);
            var params = {
                agency: queuesOwner.agency,
                user: queuesOwner.user
            };
            Promise.all([
                queuesRefetch({ params: params }),
                eventsRefetch({
                    id: [selectedQueue.number.toString()],
                    supplierQueueOwner: params
                })
            ])
                .then(function (_a) {
                var _b, _c, _d, _e;
                var _f = __read(_a, 2), queues = _f[0], events = _f[1];
                if ((_c = (_b = queues === null || queues === void 0 ? void 0 : queues.data) === null || _b === void 0 ? void 0 : _b.SupplierQueues) === null || _c === void 0 ? void 0 : _c.queues) {
                    setQueues(queues.data);
                }
                if ((_e = (_d = events === null || events === void 0 ? void 0 : events.data) === null || _d === void 0 ? void 0 : _d.SupplierQueueEvents) === null || _e === void 0 ? void 0 : _e.queues) {
                    setEventsForQueue(events.data.SupplierQueueEvents.queues);
                }
            })
                .finally(function () {
                setEventsLoading(false);
                setIsRefreshing(false);
            });
        }
    }, [queuesOwner, selectedQueue]);
    var queueClickHandler = function (queue) {
        setSelectedQueue(queue);
        setSelectedCategory(null);
    };
    useEffect(function () {
        fetchQueuesEvents();
    }, [selectedCategory]);
    var selectedEvents = React.useMemo(function () {
        if (selectedQueue && (eventsForQueues === null || eventsForQueues === void 0 ? void 0 : eventsForQueues.length) > 0) {
            var eventsForQueue_1 = eventsForQueues.find(function (queue) {
                return queue.number === selectedQueue.number;
            });
            if (eventsForQueue_1) {
                var filteredEvents = filterEventsByCategory(eventsForQueue_1, selectedCategory);
                filteredEvents = filterEventsBySearch(filteredEvents, searchingLocator);
                return filteredEvents.map(function (event) { return ({
                    id: "".concat(eventsForQueue_1.number, "_").concat(event.locator, "_").concat(event.supplierEventId),
                    date: parseISO(event.createdAt),
                    pnr: event.locator,
                    comment: event.eventText,
                    deadline: calcDuration(parseISO(event.deadlineForProcessingAt)),
                    deadlineDate: parseISO(event.deadlineForProcessingAt),
                    queueNumber: event.categoryNumber,
                    supplierEventId: event.supplierEventId,
                    category: event.categoryNumber
                }); });
            }
        }
        return [];
    }, [eventsForQueues, selectedQueue, selectedCategory, searchingLocator]);
    var submitQueuesOwnerModal = function (agency, user) { return __awaiter(void 0, void 0, void 0, function () {
        var queues_1, e_1, error;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setQueuesLoading(true);
                    clearFormError();
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, queuesRefetch({
                            params: {
                                agency: agency,
                                user: user
                            }
                        })];
                case 2:
                    queues_1 = _d.sent();
                    if ((_b = (_a = queues_1 === null || queues_1 === void 0 ? void 0 : queues_1.data) === null || _a === void 0 ? void 0 : _a.SupplierQueues) === null || _b === void 0 ? void 0 : _b.queues) {
                        setQueues(queues_1.data);
                        closeQueuesOwner();
                        setQueuesOwner({
                            agency: agency,
                            user: user
                        });
                    }
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _d.sent();
                    error = (_c = e_1 === null || e_1 === void 0 ? void 0 : e_1.graphQLErrors) === null || _c === void 0 ? void 0 : _c[0];
                    if (error) {
                        setRequestError(error.message);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    setQueuesLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var clearFormError = function () {
        setRequestError(null);
    };
    var openChangeQueuesOwner = useCallback(function () {
        clearFormError();
        openQueuesOwner();
    }, []);
    var refreshQueuesEvents = useCallback(function () {
        setIsRefreshing(true);
        if (selectedQueue) {
            fetchQueuesEvents();
        }
        else {
            fetchQueues();
        }
    }, [selectedQueue, queuesOwner]);
    if (initialLoading) {
        return React.createElement(PromoLoader, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(QueuesOwnerModal, { open: isQueuesOwnerOpen, onSubmit: submitQueuesOwnerModal, onClose: closeQueuesOwner, requestError: requestError, onFormChange: clearFormError, isLoading: queuesLoading }),
        React.createElement(Header, { isRefreshing: isRefreshing, openChangeQueuesOwner: openChangeQueuesOwner, queuesOwner: queuesOwner, refresh: refreshQueuesEvents, setSearchOrder: setSearchingLocator }),
        React.createElement("div", { className: theme.wrapper },
            React.createElement("div", { className: theme.grid },
                React.createElement("div", { className: theme.grid__sidebar },
                    React.createElement(Queues, { queues: (_a = queues === null || queues === void 0 ? void 0 : queues.SupplierQueues) === null || _a === void 0 ? void 0 : _a.queues, selectedQueue: selectedQueue, selectedCategory: selectedCategory, queueClickHandler: queueClickHandler, categoryClickHandler: setSelectedCategory })),
                React.createElement("div", { className: theme.grid__content },
                    React.createElement(Events, { isLoading: eventsLoading, events: selectedEvents, onRefetchQueues: fetchQueuesEvents, onEventRemove: fetchQueuesEvents, isSearchActive: !!searchingLocator }))))));
};
export default EventsQueues;
