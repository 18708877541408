import * as React from 'react';
export var MaleIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.1436 3.37515C14.1436 4.56877 13.1768 5.5355 11.9832 5.5355C10.7896 5.5355 9.82289 4.56877 9.82289 3.3752C9.82289 2.18158 10.7897 1.21484 11.9832 1.21484C13.1768 1.21484 14.1435 2.18158 14.1436 3.37515ZM10.363 6.6157H13.6035C14.7917 6.6157 15.7638 7.58785 15.7638 8.77601V14.7169H14.1436V22.8182H9.82289V14.7169H8.20264V8.77601C8.20264 7.58785 9.17479 6.6157 10.363 6.6157Z", fill: "#9A9A9A" })));
export var FemaleIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.1603 3.37515C14.1603 4.56877 13.1935 5.5355 12 5.5355C10.8063 5.5355 9.83961 4.56877 9.83961 3.3752C9.83961 2.18158 10.8063 1.21484 12 1.21484C13.1935 1.21484 14.1603 2.18158 14.1603 3.37515ZM12.0647 6.61565C12.9937 6.61565 13.82 7.20978 14.1117 8.0955L16.8607 16.3372H13.6202V22.8182H10.3797V16.3372H7.13916L9.89359 8.0955C10.1852 7.20973 11.0116 6.61565 11.9405 6.61565H12.0647Z", fill: "#9A9A9A" })));
export var successLoadingIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8.9999 16.2001L4.7999 12.0001L3.3999 13.4001L8.9999 19.0001L20.9999 7.0001L19.5999 5.6001L8.9999 16.2001Z", fill: "white" }),
    React.createElement("mask", { id: "mask0", mask: "alpha", maskUnits: "userSpaceOnUse", x: "3", y: "5", width: "18", height: "15" },
        React.createElement("path", { d: "M8.9999 16.2001L4.7999 12.0001L3.3999 13.4001L8.9999 19.0001L20.9999 7.0001L19.5999 5.6001L8.9999 16.2001Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
