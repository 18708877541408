import { __read } from "tslib";
import * as React from 'react';
import { addMonths } from 'date-fns';
import { useTheme } from '../../../theme';
import { getAllYears, getMonthsList, getTimestamp, isMonthAvailable } from '../../utils';
import { Month } from './Month/Month';
import { MonthHeader } from './Month/MonthHeader/MonthHeader';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
export var MonthsWrapper = function (props) {
    var _a, _b, _c, _d;
    var onFocusedDateChange = props.onFocusedDateChange;
    var _e = __read(React.useState(props.initialDate), 2), focusedDate = _e[0], setFocusedDate = _e[1];
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var headerRef = React.useRef(null);
    React.useEffect(function () {
        setFocusedDate(props.initialDate);
    }, [props.initialDate]);
    React.useEffect(function () {
        if (isMobile && props.getParentRef) {
            var parent = props.getParentRef().current;
            if (parent && headerRef.current && parent.hasOwnProperty('scroll')) {
                parent.scroll({
                    behavior: 'auto',
                    top: headerRef.current.offsetTop
                });
            }
        }
    }, []);
    var theme = useTheme('Datepicker').MonthsWrapperStyles, yearsArray = React.useMemo(function () { return getAllYears(getTimestamp(props.minDate), getTimestamp(props.maxDate)); }, [
        props.minDate,
        props.maxDate
    ]);
    var minSelectedDatesTimestamp = Infinity, maxSelectedDatesTimestamp = 0;
    var minHoveredDatesTimestamp = Infinity, maxHoveredDatesTimestamp = 0;
    var setMonth = function (date) {
        setFocusedDate(date);
        if (onFocusedDateChange) {
            onFocusedDateChange(date);
        }
    };
    var setCurrentDate = function (date, monthOffset) {
        if (monthOffset === void 0) { monthOffset = 0; }
        var newDate = new Date(parseInt(date.toString()));
        if (monthOffset !== 0) {
            newDate = addMonths(newDate, monthOffset);
        }
        setMonth(newDate);
    };
    for (var selectedDate in props.selectedDates) {
        if (props.selectedDates.hasOwnProperty(selectedDate)) {
            var timestamp = parseInt(selectedDate);
            if (timestamp < minSelectedDatesTimestamp) {
                minSelectedDatesTimestamp = timestamp;
            }
            if (timestamp > maxSelectedDatesTimestamp) {
                maxSelectedDatesTimestamp = timestamp;
            }
        }
    }
    for (var hoverDateIndex in props.hoverDates) {
        if (props.hoverDates[hoverDateIndex]) {
            var timestamp = props.hoverDates[hoverDateIndex].getTime();
            if (timestamp < minHoveredDatesTimestamp) {
                minHoveredDatesTimestamp = timestamp;
            }
            if (timestamp > maxHoveredDatesTimestamp) {
                maxHoveredDatesTimestamp = timestamp;
            }
        }
    }
    var renderMonth = function (date, index, addRef) {
        return (React.createElement(Month, { key: index, date: date, selectedDatesMap: props.selectedDates, minDateTimestamp: getTimestamp(props.minDate), maxDateTimestamp: getTimestamp(props.maxDate), minSelectedDateTimestamp: minSelectedDatesTimestamp, maxSelectedDateTimestamp: maxSelectedDatesTimestamp, minHoveredDateTimestamp: minHoveredDatesTimestamp, maxHoveredDateTimestamp: maxHoveredDatesTimestamp, highlightingDates: props.highlightedDates, onSelect: props.onSelect, onHover: props.onHover, monthHeader: React.createElement("div", { ref: addRef && focusedDate.getMonth && focusedDate.getMonth() === date.getMonth()
                    ? headerRef
                    : null },
                React.createElement(MonthHeader, { setCurrentMonth: setCurrentDate, years: yearsArray, monthIndex: index, currentDate: date, maxDate: props.maxDate, minDate: props.minDate })) }));
    };
    var onNavClick = function (type) {
        var monthToAdd = type === 'prev' ? -1 : 1;
        var newDate = addMonths(focusedDate, monthToAdd);
        setMonth(newDate);
        if (props.setSelectedDate) {
            props.setSelectedDate(newDate);
        }
    };
    return (React.createElement("div", { className: theme.wrapper },
        React.createElement("nav", null,
            isMonthAvailable(focusedDate, -1, props.minDate, props.maxDate) && (React.createElement("div", { className: theme.nav_prev, onClick: function () {
                    onNavClick('prev');
                } }, (_b = (_a = props.navButtons) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : '←')),
            isMonthAvailable(focusedDate, props.monthCount, props.minDate, props.maxDate) && (React.createElement("div", { className: theme.nav_next, onClick: function () {
                    onNavClick('next');
                } }, (_d = (_c = props.navButtons) === null || _c === void 0 ? void 0 : _c[1]) !== null && _d !== void 0 ? _d : '→'))),
        React.createElement("div", { className: theme.monthsList },
            React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 }, getMonthsList(focusedDate ? focusedDate : props.minDate, props.monthCount).map(function (date, index) {
                return renderMonth(date, index, false);
            })),
            React.createElement(MediaQuery, { maxWidth: +MOBILE_MIN_WIDTH }, getMonthsList(props.minDate, props.monthCount).map(function (date, index) {
                return renderMonth(date, index, true);
            })))));
};
