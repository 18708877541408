import { __assign } from "tslib";
import { ADD_PASSENGER, REMOVE_PASSENGER, SET_PASSENGERS, UPDATE_PASSENGERS_STATE } from './actions';
export var MAX_PASSENGERS_COUNT = 9;
export var defaultState = {
    ADT: {
        count: 1,
        age: null
    },
    CLD: {
        count: 0,
        age: null
    },
    INF: {
        count: 0,
        age: null
    },
    INS: {
        count: 0,
        age: null
    }
};
export var passengersReducer = function (state, _a) {
    var _b, _c;
    if (state === void 0) { state = defaultState; }
    var payload = _a.payload, type = _a.type;
    switch (type) {
        case ADD_PASSENGER:
            return __assign(__assign({}, state), (_b = {},
                _b[payload] = __assign(__assign({}, state[payload]), { count: state[payload].count + 1 }),
                _b));
        case REMOVE_PASSENGER:
            return __assign(__assign({}, state), (_c = {},
                _c[payload] = __assign(__assign({}, state[payload]), { count: state[payload].count - 1 }),
                _c));
        case SET_PASSENGERS:
            return __assign(__assign({}, state), payload);
        case UPDATE_PASSENGERS_STATE:
            return __assign({}, payload);
        default:
            return state;
    }
};
