import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useFormState } from 'react-final-form';
import { DateField } from '../Date/Date';
import { Collapse } from '@material-ui/core';
var DocExpiryDate = function (_a) {
    var checkFieldIsRequired = _a.checkFieldIsRequired, props = __rest(_a, ["checkFieldIsRequired"]);
    var values = useFormState().values;
    var isRequired = checkFieldIsRequired(values);
    var label = isRequired ? "".concat(props.label, "*") : props.label;
    var disabled = !isRequired || props.disabled;
    return (React.createElement(Collapse, { in: isRequired },
        React.createElement(DateField, __assign({}, props, { disabled: disabled, label: label }))));
};
export default DocExpiryDate;
