import { __values } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../../../theme';
import { Form } from 'react-final-form';
import Field from './Field/Field';
import Link from '../../../../../Link';
import Button from '../../../../../Button/Button';
import { useTranslation } from 'react-i18next';
import { FfpAccountFieldEnum } from '../../../../../__generated__/graphql';
import { InputType } from '../../../../../Passenger/types';
import cn from 'classnames';
var CreateAccount = function (props) {
    var theme = useTheme('Loyalty').LoyaltyConnect;
    var t = useTranslation('Loyalty').t;
    var getFieldType = function (type) {
        switch (type) {
            case FfpAccountFieldEnum.BirthDate:
            case FfpAccountFieldEnum.DocExpiryDate:
            case FfpAccountFieldEnum.DocIssued:
                return InputType.Date;
            case FfpAccountFieldEnum.Phone:
                return InputType.Phone;
            case FfpAccountFieldEnum.Country:
            case FfpAccountFieldEnum.DocType:
                return InputType.Select;
            case FfpAccountFieldEnum.Gender:
                return InputType.Switch;
            default:
                return InputType.Text;
        }
    };
    var getOptions = function (validationRules, values, type) {
        var e_1, _a;
        var options;
        try {
            for (var validationRules_1 = __values(validationRules), validationRules_1_1 = validationRules_1.next(); !validationRules_1_1.done; validationRules_1_1 = validationRules_1.next()) {
                var rule = validationRules_1_1.value;
                if (!rule.with || rule.with.every(function (condition) { return values[condition.name] === condition.value; })) {
                    options = rule.options;
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (validationRules_1_1 && !validationRules_1_1.done && (_a = validationRules_1.return)) _a.call(validationRules_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return options.map(function (option) {
            return {
                key: option.value,
                value: option.label
            };
        });
    };
    var filedsMap = React.useMemo(function () {
        var map = new Map();
        props.initialValues.FfpDefaultAccount.values.forEach(function (value) {
            map.set(value.type, value);
        });
        return map;
    }, [props.initialValues.FfpDefaultAccount.values]);
    var onSubmit = function (data) {
        var params = [];
        var _loop_1 = function (param) {
            if (data.hasOwnProperty(param)) {
                params.push({
                    name: param,
                    value: data[param],
                    type: props.initialValues.FfpDefaultAccount.values.find(function (value) { return value.name === param; }).type
                });
            }
        };
        for (var param in data) {
            _loop_1(param);
        }
        return props.onCreateAccount(params);
    };
    var filedGroup = [
        [FfpAccountFieldEnum.LatinFirstName],
        [FfpAccountFieldEnum.LatinMiddleName],
        [FfpAccountFieldEnum.LatinLastName],
        [FfpAccountFieldEnum.FirstName],
        [FfpAccountFieldEnum.MiddleName],
        [FfpAccountFieldEnum.LastName],
        [FfpAccountFieldEnum.BirthDate, FfpAccountFieldEnum.Gender],
        [FfpAccountFieldEnum.Phone],
        [FfpAccountFieldEnum.Email],
        [FfpAccountFieldEnum.Country],
        [FfpAccountFieldEnum.City],
        [FfpAccountFieldEnum.DocType, FfpAccountFieldEnum.DocNumber],
        [FfpAccountFieldEnum.DocIssued, FfpAccountFieldEnum.DocExpiryDate]
    ];
    var initialValues = {};
    props.initialValues.FfpDefaultAccount.values.forEach(function (value) {
        return (initialValues[value.name] = value.value);
    });
    return (React.createElement("div", { className: theme.loyaltyConnect },
        React.createElement("div", { className: theme.header }, t('Create account')),
        React.createElement("div", null,
            React.createElement(Form, { onSubmit: onSubmit, initialValues: initialValues }, function (_a) {
                var handleSubmit = _a.handleSubmit, submitting = _a.submitting, submitSucceeded = _a.submitSucceeded, submitError = _a.submitError, values = _a.values;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", null,
                        filedGroup.map(function (group) { return (React.createElement("div", { className: theme.group }, group.map(function (fieldType) {
                            var _a;
                            var field = filedsMap.get(fieldType);
                            var type = getFieldType(field.type);
                            return (React.createElement("div", { className: cn(theme.group__field, (_a = {},
                                    _a[theme.group__field_switch] = fieldType === FfpAccountFieldEnum.Gender,
                                    _a)) },
                                React.createElement(Field, { name: field.name, label: field.name, type: type, validationRules: field.validationRules, options: type === InputType.Select &&
                                        getOptions(field.validationRules, values, field.type), translateValue: field.type === FfpAccountFieldEnum.DocType })));
                        }))); }),
                        submitError && React.createElement("div", { className: theme.errors }, submitError),
                        React.createElement("div", { className: theme.buttons__wrapper },
                            React.createElement(Link, { action: props.toLogin }, t('I have a card')),
                            React.createElement("div", { className: theme.buttons__action },
                                submitSucceeded && (React.createElement("div", { className: theme.actions__success }, t('Data was sent to your email'))),
                                React.createElement(Button, { type: "submit", isLoading: submitting, isSuccess: submitSucceeded }, t('Create')))))));
            }))));
};
export default CreateAccount;
