import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Money } from '../../../../../Money';
import { getPrice } from '../../../store/selector';
import { clear } from '../../../store/actions';
var PriceBlock = function (_a) {
    var hideClear = _a.hideClear;
    var dispatch = useDispatch();
    var css = useTheme('UpgradeBaggageDialog').BaggageFooter;
    var price = useSelector(getPrice);
    var t = useTranslation('IncludedBaggage').t;
    var handlerClick = useCallback(function () {
        dispatch(clear());
    }, []);
    if (!price) {
        return null;
    }
    return (React.createElement("div", { className: css.price_block },
        React.createElement("div", { className: css.price_wrapper },
            t('Total'),
            ": ",
            React.createElement(Money, { money: price })),
        !hideClear && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.price_block__delimiter }),
            React.createElement("div", null,
                React.createElement("span", { className: css.clear, onClick: handlerClick }, t('Clear')))))));
};
export default PriceBlock;
