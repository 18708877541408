import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { MobileStepbarMode } from '../../../types';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { insuranceIcon, transferIcon } from '../../../Icons';
import { useTranslation } from 'react-i18next';
import AdditionalServices from '../../../../AdditionalServices/components/AdditionalServices';
import { useCheckinOrderServices, useOrderServices } from '../../../../__queries__/Order';
import { useDispatch } from 'react-redux';
import { fillCheckinOrder } from '../../../../Modules/Checkin/store/order/actions';
import { useMobileStepbarMode } from '../../../hooks';
var ExtrasStep = function (_a) {
    var _b, _c;
    var order = _a.order, onSetOrder = _a.onSetOrder;
    var css = useTheme('MobileStepbar').StepContentStyles;
    var _d = __read(useOrderServices(), 1), saveOrderServicesMutation = _d[0];
    var _e = __read(useCheckinOrderServices(), 1), saveCheckinOrderServicesMutation = _e[0];
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var mode = useMobileStepbarMode();
    var saveOrderServices;
    if (mode === MobileStepbarMode.Checkin) {
        saveOrderServices = useCallback(function (variables) { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, saveCheckinOrderServicesMutation({ variables: variables })];
                    case 1:
                        data = (_a.sent()).data;
                        dispatch(fillCheckinOrder(data.CheckinSaveOrderServices));
                        return [2 /*return*/, data];
                }
            });
        }); }, []);
    }
    else {
        saveOrderServices = useCallback(function (variables) { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, saveOrderServicesMutation({ variables: variables })];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); }, []);
    }
    var stepBarInsurance = (React.createElement(AdditionalServices, { onSetOrder: onSetOrder, saveOrderServices: saveOrderServices, travellers: order.travellers, additionalServices: order.additionalServices, orderId: order.id, inMobileStepbar: "insurance" }));
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.content },
            order.additionalServices.insurances && (React.createElement("div", { className: cn(css.content__item, css.content__item_extras) },
                React.createElement("div", { className: cn(css.item__row, css.extrasItem) },
                    React.createElement("div", null,
                        React.createElement("span", { className: css.content__icon }, insuranceIcon),
                        React.createElement("span", null, t('Checkout:Insurance'))),
                    React.createElement("div", null,
                        React.createElement("span", { className: cn(css.extrasItem__money, (_b = {},
                                _b[css.extrasItem__money_notSelected] = !order.price.insurance,
                                _b)) }, stepBarInsurance))))),
            React.createElement("div", { className: cn(css.content__item, css.content__item_extras) },
                React.createElement("div", { className: cn(css.item__row, css.extrasItem) },
                    React.createElement("div", null,
                        React.createElement("span", { className: css.content__icon }, transferIcon),
                        React.createElement("span", null, t('Checkout:Transfer'))),
                    React.createElement("div", null,
                        React.createElement("span", { className: cn(css.extrasItem__money, (_c = {},
                                _c[css.extrasItem__money_notSelected] = !order.price.aeroexpress,
                                _c)) },
                            React.createElement(AdditionalServices, { onSetOrder: onSetOrder, saveOrderServices: saveOrderServices, travellers: order.travellers, additionalServices: order.additionalServices, orderId: order.id, inMobileStepbar: 'aeroexpress' }))))))));
};
export default ExtrasStep;
