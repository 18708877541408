import { __read, __spreadArray } from "tslib";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { Weekdays } from '../../../../__generated__/graphql';
var weekdaysList = Object.entries(Weekdays).map(function (_a) {
    var _b = __read(_a, 1), v = _b[0];
    return v;
});
var WeekDaysSelector = function (props) {
    var className = props.className, onChange = props.onChange, _a = props.initValue, initValue = _a === void 0 ? undefined : _a;
    var t = useTranslation('FlightsFilter').t;
    var css = useTheme('FlightsFilter').WeekDaysSelector;
    var _b = __read(useState(initValue), 2), days = _b[0], setDays = _b[1];
    var dayClickHandler = function (e) {
        var day = e.currentTarget.id;
        var newValue;
        if (!days) {
            newValue = [day];
        }
        else if (days.includes(day)) {
            newValue = days.filter(function (d) { return d !== day; });
            if (newValue.length === 0) {
                newValue = undefined;
            }
        }
        else {
            newValue = __spreadArray(__spreadArray([], __read(days), false), [day], false);
        }
        setDays(newValue);
        onChange === null || onChange === void 0 ? void 0 : onChange(newValue);
    };
    return (React.createElement("div", { className: cn(css.root, className) },
        React.createElement("label", { className: css.label }, t('Days of the week')),
        React.createElement("div", { className: css.days }, weekdaysList.map(function (day, idx) {
            var _a;
            return (React.createElement("div", { key: day, id: day, className: cn(css.day, (_a = {}, _a[css.selected] = days && days.includes(day), _a)), onClick: dayClickHandler }, idx + 1));
        }))));
};
export default WeekDaysSelector;
