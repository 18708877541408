import { combineReducers } from 'redux';
import { passengersReducer } from './passengers/reducers';
import { checkoutReducer } from './checkout/reducers';
import { formIsLockedReducer } from './formIsLocked/reducers';
import { priceBreakdownReducer } from './priceBreakdown/reducers';
import { orderReducer } from './order/reducers';
import { orderPriceReducer } from './orderPrice/reducers';
import { selectedServicesReducer } from './selectedServices/reducers';
import { servicesIsSavingReducer } from './servicesIsSaving/reducers';
import { sagaRequestErrorsReducer } from './sagaRequestErrors/reducers';
export var rootReducer = combineReducers({
    passengers: passengersReducer,
    checkout: checkoutReducer,
    order: orderReducer,
    formIsLocked: formIsLockedReducer,
    priceBreakdown: priceBreakdownReducer,
    orderPrice: orderPriceReducer,
    selectedServices: selectedServicesReducer,
    servicesIsSaving: servicesIsSavingReducer,
    sagaRequestErrors: sagaRequestErrorsReducer
});
