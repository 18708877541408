import { __assign, __rest } from "tslib";
import React from 'react';
import Switch from '../Switch';
import { useTheme } from '../../../theme';
import cn from 'classnames';
var SwitchV2 = function (_a) {
    var _b, _c, _d;
    var label = _a.label, _e = _a.variant, variant = _e === void 0 ? 'primary' : _e, props = __rest(_a, ["label", "variant"]);
    var css = useTheme('Switch').SwitchV2;
    return (React.createElement(Switch, __assign({}, props, { label: label ? label : '', activeClassName: cn(css.active, props.activeClassName, (_b = {},
            _b[css.primary] = variant === 'primary',
            _b[css.secondary] = variant === 'secondary',
            _b)), mainClassName: cn(css.main, props.activeClassName, (_c = {},
            _c[css.primary] = variant === 'primary',
            _c[css.secondary] = variant === 'secondary',
            _c)), labelClassName: cn(css.label, props.labelClassName, (_d = {},
            _d[css.primary] = variant === 'primary',
            _d[css.secondary] = variant === 'secondary',
            _d)) })));
};
export default SwitchV2;
