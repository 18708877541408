import cn from 'classnames';
import Results from './components/Results.css';
import Refund from './components/Refund/Refund.css';
import Checkout from './components/Checkout/Checkout.css';
import Exchange from './components/Exchange/Exchange.css';
import SearchForm from './components/SearchForm/SearchForm.css';
import Sidebar from './components/Sidebar/Sidebar.css';
import CommonItem from './components/OrderCard/CommonItem/CommonItem.css';
import PaymentStatus from './components/OrderCard/PaymentStatus/PaymentStatus.css';
import OrderBooking from './components/OrderCard/OrderBooking/OrderBooking.css';
import OrderStatus from './components/OrderCard/OrderStatus/OrderStatus.css';
import OrderPassengers from './components/OrderCard/OrderPassengers/OrderPassengers.css';
import OrderTickets from './components/OrderCard/OrderTickets/OrderTickets.css';
import OrderControls from './components/OrderCard/OrderControls/OrderControls.css';
import OrderCard from './components/OrderCard/OrderCard.css';
import Input from './components/Input/Input.css';
import AdditionalBaggage from './components/AdditionalBaggage/AdditionalBaggage.css';
import ServicesCard from './components/ServicesCard/ServicesCard.css';
import OrderPreview from './components/OrderCard/OrderPreview/OrderPreview.css';
import Passenger from './components/SeatMap/Passenger/Passenger.css';
import Meal from './components/Meal/Meal.css';
var theme = {
    Exchange: {
        RequestForm: {
            status_finished: Exchange.status_finished
        },
        ExchangeCheckoutStatus: {
            wrapper: Exchange.exchangeStatus__wrapper
        },
        Inner: {
            manual: Exchange.manual,
            header__inner: Exchange.header,
            exchange: Exchange.inner__exchange
        }
    },
    Meal: {
        Details: {
            addForAll: Meal.addForAll
        }
    },
    Refund: {
        OrderRefundStatus: {
            success: Exchange.success
        },
        Wrapper: {
            header: Refund.wrapper__header
        },
        Breadcrumbs: {
            breadcrumbs: Refund.breadcrumbs
        },
        RequestForm: {
            control: Refund.control
        },
        Refund: {
            buttons: Refund.buttons
        }
    },
    QuickSearchForm: {
        QuickSearchForm: {
            outer: Results.quickSearchForm__outer
        }
    },
    Results: {
        Calendar: {
            header: Results.calendar__header
        }
    },
    FlightSelect: {
        SegmentInfo: {
            total_duration: Results.segment_totalDuration,
            flight_number__inner: Results.flight_number__inner,
            segment: Results.flight__segment,
            flight_number: Results.flightNumber,
            amenities: Results.segment__amenities,
            flightNumber__selected: Results.segment__flightNumberSelected,
            flight__dateColumn: Results.segment__flightDateColumn,
            routeInfo: Results.segment__routeInfo
        },
        FlightSelect: {
            segmentStatus: Results.selected__status,
            fareConditionsModal: Results.flightSelect__fareConditionsModal,
            flight: Results.flightSelect__flight,
            city: Results.flightSelect__city,
            duration: Results.flightSelect__duration
        }
    },
    DesktopFlightRoute: {
        SegmentStyles: {
            route: Results.segment_route,
            route__time: Results.segment_routeTime,
            segment_additional: Results.segmentAdditional,
            totalDuration: Results.segmentTotalDuration
        },
        StopsInfoStyles: {
            stop: Results.stop
        }
    },
    SearchFormDatepicker: {
        DayStyles: {
            day: Results.searchForm_day
        }
    },
    Input: {
        Input: {
            disabled: Checkout.disabled,
            input_filled: Input.input_filled,
            input: Input.input,
            real_input: Input.real_input,
            input_focused: Input.input_focused,
            input_outline: Input.input_outline
        }
    },
    SearchForm: {
        Currency: {
            currency: SearchForm.currency
        },
        Segments: {
            segments: Results.result_segments
        },
        Segment: {
            cell: Results.searchForm_segmentCell
        },
        Datepicker: {
            dates__to: Results.datepicker_datesTo
        },
        SearchForm: {
            wrapper: SearchForm.wrapper,
            searchForm_advanced: SearchForm.searchForm_advanced
        }
    },
    Sidebar: {
        Sidebar: {
            sidebar: Sidebar.sidebar__wrapper,
            link: Sidebar.sidebar__link,
            sidebar__item: Sidebar.sidebar__item
        }
    },
    Checkout: {
        CheckoutStyles: {
            summary: Checkout.summary,
            paidOrderWrapper: Checkout.paidOrderWrapper
        },
        PassengersStyles: {
            passengers: Checkout.passengers
        }
    },
    Contacts: {
        ContactsStyles: {
            contacts: Checkout.contacts,
            field__contact: Checkout.contacts__field__contact,
            field: Checkout.contacts__field
        }
    },
    OrderCard: {
        CommonItem: {
            root: CommonItem.root,
            review: CommonItem.review,
            icon: cn(CommonItem.icon, OrderStatus.icon)
        },
        PaymentStatus: {
            root: PaymentStatus.root
        },
        OrderBooking: {
            root: OrderBooking.root,
            type_review: OrderBooking.type_review,
            orderCode: OrderBooking.orderCode
        },
        OrderStatus: {
            root: OrderStatus.root,
            type_review: OrderStatus.type_review,
            confirmed: OrderStatus.confirmed
        },
        OrderPassengers: {
            root: OrderPassengers.root,
            account: OrderPassengers.account,
            control: OrderPassengers.control
        },
        OrderTickets: {
            wrapper: OrderTickets.wrapper
        },
        OrderControls: {
            root: OrderControls.root
        },
        OrderPreview: {
            root: OrderPreview.root,
            overlay: OrderPreview.overlay,
            inner: OrderPreview.inner
        },
        OrderCard: {
            container: OrderCard.container,
            type_review: OrderCard.type_review
        }
    },
    ExtraServices: {
        ServicesContainer: {
            search: Input.search
        }
    },
    Modal: {
        Modal: {
            closeIcon: Checkout.modal__closeIcon,
            modal_rounded: Checkout.modalRounded
        }
    },
    AdditionalBaggage: {
        AdditionalBaggage: {
            promo_message: AdditionalBaggage.additionalBaggage__promoMessage,
            root: Checkout.additionalBaggage__root
        }
    },
    Baggage: {
        Baggage: {
            name: Checkout.baggageName,
            size: Checkout.baggageSize
        }
    },
    BaggageTotalPriceBlock: {
        BaggageTotalPriceBlock: {
            price: Checkout.baggageTotalPrice__price,
            control: Checkout.baggageTotalPrice__control,
            control__edit: Checkout.baggageTotalPrice__control_edit
        }
    },
    Counter: {
        Counter: {
            button: Checkout.counterButton,
            button_disabled: Checkout.counterButton_disabled
        }
    },
    UpgradeBaggageDialog: {
        BaggageDialogHeader: {
            info_price: Checkout.baggageDialog__InfoPrice
        }
    },
    ServicesCard: {
        ServicesCard: {
            passengerSegmentWrapper: ServicesCard.passengerSegmentWrapper,
            delimiter: ServicesCard.delimiter,
            passengerEmdWrapper: ServicesCard.passengerEmdWrapper
        }
    },
    Autocomplete: {
        Autocomplete: {
            locationOptions: Checkout.locationOptions
        }
    },
    SeatMap: {
        Passenger: {
            item: Passenger.item,
            select: Passenger.select
        },
        ComponentStyles: {
            wrapper: Checkout.seatMap__wrapper,
            segmentTabs: Checkout.seatMap__segmentTabs,
            unavailable__img: Checkout.seatMap__unavailable__img
        }
    },
    Amenities: {
        Amenities: {
            amenities__item: Results.amenities__item
        }
    }
};
export default theme;
