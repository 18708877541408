import * as React from 'react';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { getPassengersCount, getPassengersSummary, getRouteType } from '../../utils';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { RouteType } from '../../types';
import { arrowIcon, arrowIcon_mobile, owIcon, rtIcon } from '../../Icons';
var Summary = function (_a) {
    var _b;
    var parameters = _a.parameters, onClick = _a.onClick, _c = _a.isOpen, isOpen = _c === void 0 ? false : _c, renderPromoCode = _a.renderPromoCode;
    var css = useTheme('QuickSearchForm').Summary;
    var t = useTranslation('QuickSearchForm').t;
    var routeType = getRouteType(parameters.segments);
    var isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
    var displayIataCodes = parameters.segments[0].departure.city.name.length + parameters.segments[0].arrival.city.name.length >= 15 ||
        (parameters.segments.length >= 2 && isMobile);
    return (React.createElement("div", { className: css.summary, onClick: onClick },
        React.createElement("span", { className: css.route },
            (routeType === RouteType.OneWay || routeType === RouteType.RoundTrip) && (React.createElement(React.Fragment, null,
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    displayIataCodes
                        ? parameters.segments[0].departure.iata
                        : parameters.segments[0].departure.city.name,
                    React.createElement("span", { className: css.route__icon }, routeType === RouteType.OneWay ? owIcon : rtIcon),
                    displayIataCodes
                        ? parameters.segments[0].arrival.iata
                        : parameters.segments[0].arrival.city.name),
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    parameters.segments[0].departure.city.name,
                    React.createElement("span", { className: css.route__icon }, routeType === RouteType.OneWay ? owIcon : rtIcon),
                    parameters.segments[0].arrival.city.name))),
            routeType === RouteType.ComplexRoute &&
                parameters.segments.map(function (segment, index) { return (React.createElement(React.Fragment, { key: index },
                    displayIataCodes ? segment.departure.iata : segment.departure.city.name,
                    React.createElement("span", { className: css.route__icon }, owIcon),
                    displayIataCodes
                        ? index === parameters.segments.length - 1 && segment.arrival.iata
                        : index === parameters.segments.length - 1 && segment.arrival.city.name)); })),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement("span", { className: css.passengers },
                " ",
                getPassengersSummary(parameters.passengers).join(', '))),
        React.createElement("span", { className: css.trigger },
            React.createElement("span", null,
                React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 }, t('Modify search'))),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement("span", { className: css.passengers },
                    getPassengersCount(parameters.passengers),
                    ' ',
                    parameters.passengers.length === 1 && !parameters.passengers[0].extendedPassengerType
                        ? t(parameters.passengers[0].passengerType, { count: parameters.passengers[0].count })
                        : t('passenger', { count: getPassengersCount(parameters.passengers) }))),
            React.createElement("span", { className: cn(css.trigger__arrow, (_b = {},
                    _b[css.trigger__arrow_reversed] = isOpen,
                    _b)) },
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, arrowIcon),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, arrowIcon_mobile))),
        renderPromoCode && renderPromoCode()));
};
export default Summary;
