import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { format, initI18n } from '../../../../../../utils';
import { useTheme } from '../../../../../../theme';
import { useToggleable } from '../../../../../../hooks';
import AllFlightsModal from '../AllFlightsModal/AllFlightsModal';
initI18n('Schedule');
var Day = function (props) {
    var onFlightHover = props.onFlightHover, highlightedFlight = props.highlightedFlight, legsToday = props.legsToday, date = props.date;
    var css = useTheme('Schedule').Day;
    var t = useTranslation('Schedule').t;
    var _a = useToggleable(false), isAllFlightsOpen = _a.isOpen, showAllFlights = _a.open, closeAllFlights = _a.close;
    if (!legsToday) {
        return null;
    }
    var hasMoreThanTwoFlights = legsToday.length > 2;
    var handleMouseEnter = useCallback(onFlightHover, [onFlightHover, date, highlightedFlight]);
    var handleMouseOut = useCallback(function () { return onFlightHover(null); }, [onFlightHover, date, highlightedFlight]);
    var renderFlightInfo = useCallback(function (airline, flightNumber) {
        var _a;
        return (React.createElement("a", { href: "#", className: cn(css.name, (_a = {}, _a[css.active] = flightNumber === highlightedFlight, _a)), onMouseOver: function () { return handleMouseEnter(flightNumber); }, onMouseLeave: handleMouseOut, onClick: function (e) {
                e.stopPropagation();
                e.preventDefault();
                props.onSelect(date);
            } },
            airline,
            "-",
            flightNumber));
    }, [highlightedFlight]);
    var renderSegment = useCallback(function (segment) {
        return (React.createElement("div", { className: css.flight },
            React.createElement("div", { className: css.time }, segment.departure.time),
            renderFlightInfo(segment.marketingAirline.iata, segment.flightNumber),
            segment.operatingAirline.iata !== segment.marketingAirline.iata &&
                renderFlightInfo(segment.operatingAirline.iata, segment.operatingAirlineFlightNumber)));
    }, [date, highlightedFlight]);
    var earlyFlight = legsToday[0];
    var lateFlight = legsToday[legsToday.length - 1];
    return (React.createElement("div", { onClick: function (e) { return e.stopPropagation(); } },
        React.createElement("div", null,
            React.createElement(AllFlightsModal, { open: isAllFlightsOpen, onClose: closeAllFlights, legsToday: legsToday, date: date, onSelect: props.onSelect })),
        React.createElement("div", { className: css.date },
            format(date, 'dd'),
            React.createElement("span", null, format(date, 'eeeeee'))),
        React.createElement("div", { className: css.list },
            earlyFlight && renderSegment(earlyFlight.segments[0].segment),
            lateFlight && renderSegment(lateFlight.segments[0].segment)),
        hasMoreThanTwoFlights && !isAllFlightsOpen && (React.createElement("span", { className: css.more, onClick: showAllFlights }, t('+ flights', { count: legsToday.length - 2 })))));
};
export default Day;
