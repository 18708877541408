import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { format } from '../../../../utils';
import { Remove, Transfer } from '../../../../Icons';
import { getDurationString } from '../../../../Results/utils';
import Button from '../../../../Button/Button';
import { Tooltip } from '../../../../index';
import SimpleLoader from '../../../../SimpleLoader';
import { useFindOrderMutation } from '@websky/graphql';
import QueueForm from '../QueueForm/QueueForm';
import { usePointOfSales } from '../../../leoCheckIsAuthenticated/pointOfSalesContext';
var Event = function (_a) {
    var _b;
    var _c;
    var event = _a.event, removeEvent = _a.removeEvent, onChangeQueue = _a.onChangeQueue;
    var theme = useTheme('EventsQueues').EventsQueues;
    var t = useTranslation('EventsQueues').t;
    var _d = usePointOfSales(), isAgencySupervisor = _d.isAgencySupervisor, agency = _d.agency, id = _d.id;
    var trRef = useRef(null);
    var _e = __read(useFindOrderMutation(), 2), findOrder = _e[0], isFindingOrder = _e[1].loading;
    var _f = __read(useState(false), 2), isRemoving = _f[0], setIsRemoving = _f[1];
    var onRemoveHandler = function () {
        setIsRemoving(true);
        removeEvent(event.queueNumber, event.supplierEventId).then(function (removeResult) {
            setIsRemoving(false);
            if (removeResult) {
                trRef.current.classList.add(theme.removedTr);
            }
        });
    };
    var goToOrder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var order, href;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, findOrder({
                        variables: {
                            params: { id: event.pnr }
                        }
                    })];
                case 1:
                    order = _c.sent();
                    if ((_b = (_a = order === null || order === void 0 ? void 0 : order.data) === null || _a === void 0 ? void 0 : _a.FindOrder) === null || _b === void 0 ? void 0 : _b.id) {
                        href = window.location.href.replace(window.location.hash, "#/booking/".concat(order.data.FindOrder.id));
                        window.open(href);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onChangeQueueHandler = useCallback(function (values) {
        return onChangeQueue({
            oldQueueNumber: event.queueNumber,
            newQueueNumber: values.queueNumber,
            supplierEventId: event.supplierEventId,
            supplierQueueOwner: {
                user: values.pos,
                agency: values.agency
            },
            categoryNumber: values.category
        });
    }, [event]);
    var initialValues = useMemo(function () {
        if (agency || id) {
            return {
                agency: agency,
                pos: id
            };
        }
        return {};
    }, [agency, id]);
    return (React.createElement(React.Fragment, null,
        isFindingOrder && React.createElement(SimpleLoader, null),
        React.createElement("tr", { key: event.id, ref: trRef },
            React.createElement("td", null,
                React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_nowrap) }, format(event.date, 'dd.MM.yyyy HH:mm'))),
            React.createElement("td", null,
                React.createElement("div", { className: theme.table__cell }, event.category)),
            React.createElement("td", null,
                React.createElement("div", { className: theme.table__cell },
                    React.createElement(Button, { className: theme.pnr, variant: "text", onClick: goToOrder }, event.pnr))),
            React.createElement("td", null,
                React.createElement("div", { className: theme.table__cell }, event.comment)),
            React.createElement("td", null,
                React.createElement(Tooltip, { interactive: true, title: format(event.deadlineDate, 'dd.MM.yyyy HH:mm') },
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_nowrap, (_b = {},
                            _b[theme.deadline_warning] = ((_c = event.deadline) === null || _c === void 0 ? void 0 : _c.days) <= 0,
                            _b)) }, getDurationString(event.deadline)))),
            React.createElement("td", { className: theme.controls },
                React.createElement("div", null,
                    React.createElement(Button, { className: theme.control, variant: "text", isLoading: isRemoving, onClick: onRemoveHandler }, Remove),
                    React.createElement(QueueForm, { header: t('Change Queue'), onSubmit: onChangeQueueHandler, buttonText: t('Move'), disabledInputs: isAgencySupervisor ? ['agency'] : ['agency', 'pos'], initialValues: initialValues, openModalRenderer: function (_a) {
                            var onClick = _a.onClick;
                            return (React.createElement(Button, { className: theme.control, variant: "text", onClick: onClick }, Transfer));
                        } }))))));
};
export default Event;
