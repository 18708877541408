import * as React from 'react';
import AccountTemplate from '../AccountTemplate/AccountTemplate';
import { useTheme } from '../../../theme';
import Loader from '../../../Loader';
import { useGetAccountInfoQuery } from '@websky/graphql';
import Card from '@material-ui/core/Card';
import { TravellerTypes } from '../../../Traveller/types';
import TravellerForm from '../TravellerForm/TravellerForm';
var Profile = function () {
    var _a = useGetAccountInfoQuery(), currentUser = _a.data, loading = _a.loading;
    var css = useTheme('Account').Account;
    var travellerData = React.useMemo(function () {
        var _a;
        if ((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) === null || _a === void 0 ? void 0 : _a.userProfile) {
            return {
                documents: [],
                values: currentUser.CurrentUser.userProfile.values.map(function (value) {
                    return {
                        name: value.name,
                        value: value.value,
                        validationRules: [
                            {
                                options: [],
                                with: []
                            }
                        ]
                    };
                })
            };
        }
        return null;
    }, [currentUser]);
    return (React.createElement(AccountTemplate, null, !loading ? (React.createElement(Card, null,
        React.createElement(TravellerForm, { currentUser: currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser, traveller: travellerData, onSubmit: null, type: TravellerTypes.PersonalDetails }))) : (React.createElement("div", { className: css.loader },
        React.createElement(Loader, null)))));
};
export default Profile;
