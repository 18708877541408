import * as React from 'react';
import { initI18n } from '../../../../utils';
import { useTheme } from '../../../../theme';
import { Money } from '../../../../Money';
import Button from '../../../../Button/Button';
import CloseIcon from '../../../../CloseIcon/components/CloseIcon';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../Modal';
initI18n('ServiceCardVoid');
var RefundResultModal = function (props) {
    var theme = useTheme('ServicesCardVoid').ServicesCardVoid;
    var t = useTranslation('ServiceCardVoid').t;
    return (React.createElement(Modal, { open: true, maxWidth: 'sm', onClose: props.closeModal, classes: { paper: theme.modal__paper }, closeButtonRenderer: function (onClick) { return (React.createElement("div", { className: theme.popup__close },
            React.createElement(CloseIcon, { onClick: onClick }))); } },
        React.createElement("h3", null, props.refundOrderResult ? (React.createElement(React.Fragment, null,
            React.createElement("span", null,
                t('Refunded amount'),
                ":"),
            React.createElement(Money, { money: props.refundOrderResult }))) : (t('Void/Refund serv.'))),
        React.createElement("div", { className: theme.text }, props.refundOrderResult ? (React.createElement(React.Fragment, null,
            React.createElement("div", null, t('If this services were paid by cash, you should pay back to passenger.')),
            React.createElement("div", null, t('If payment was made via credit card the sum would be paid back according to an acquiring bank regulation.')))) : (React.createElement("span", null, t('According to the terms of the tariff, money for the service is not returned')))),
        React.createElement(Button, { type: "submit", variant: "flat", size: 'common', onClick: props.closeModal, className: theme.okBtn, classes: {
                root: theme.okBtn__root
            } }, t('OK'))));
};
export default RefundResultModal;
