import { __assign, __read, __spreadArray } from "tslib";
import { CLEAR, DIFFERENT_FOR_RETURN_FLIGHT, DISABLE, SET_ALL_SERVICES, SET_SERVICES, SET_BAGGAGE_TAB } from './actions';
import { flatDeep } from '../../../utils';
var defaultState = {
    services: null,
    selectedServices: [],
    differentForReturnFlight: false,
    disabled: false,
    maxBaggageCount: 3,
    maxBaggagePerPassengerCount: null,
    baggageTab: null,
    passengers: null,
    segments: []
};
export var upgradeModalReducer = function (state, action) {
    if (state === void 0) { state = defaultState; }
    if (action.type !== DISABLE && state.disabled) {
        return state;
    }
    switch (action.type) {
        case CLEAR:
            return __assign(__assign({}, state), { selectedServices: state.selectedServices.map(function (selectedService) {
                    var _a, _b, _c, _d;
                    return __assign(__assign({}, selectedService), { 
                        // значение сбрасываем на кол-во оплаченных позиций либо на 0
                        count: state.services.find(function (service) { return service.id === selectedService.serviceId; })
                            ? (_d = (_c = (_b = (_a = state.passengers
                                .find(function (p) { return p.id === selectedService.passengerId; })) === null || _a === void 0 ? void 0 : _a.services.gdsServices) === null || _b === void 0 ? void 0 : _b.services.find(function (s) {
                                return s.serviceId === selectedService.serviceId &&
                                    s.segmentIds.some(function (s) { return selectedService.segmentIds.includes(s); });
                            })) === null || _c === void 0 ? void 0 : _c.confirmedCount) !== null && _d !== void 0 ? _d : 0
                            : selectedService.count });
                }) });
        case DIFFERENT_FOR_RETURN_FLIGHT:
            var newValue = !state.differentForReturnFlight;
            if (newValue) {
                return __assign(__assign({}, state), { differentForReturnFlight: newValue });
            }
            else {
                return __assign(__assign({}, state), { selectedServices: state.selectedServices.map(function (selectedService) {
                        return __assign(__assign({}, selectedService), { count: state.services.find(function (service) { return service.id === selectedService.serviceId; })
                                ? 0
                                : selectedService.count });
                    }), differentForReturnFlight: newValue });
            }
        case SET_SERVICES:
            var services = action.payload.items;
            var type_1 = action.payload.type || 'counter';
            var servicesMap_1 = new Map();
            state.selectedServices.forEach(function (service) {
                var id = "".concat(service.segmentIds.join('-'), "-").concat(service.passengerId).concat(type_1 === 'counter' ? "-".concat(service.serviceId) : '');
                servicesMap_1.has(id)
                    ? servicesMap_1.set(id, __spreadArray(__spreadArray([], __read(servicesMap_1.get(id)), false), [service], false))
                    : servicesMap_1.set(id, [service]);
            });
            services.forEach(function (newService) {
                var id = "".concat(newService.segmentIds.join('-'), "-").concat(newService.passengerId).concat(type_1 === 'counter' ? "-".concat(newService.serviceId) : '');
                var existService = servicesMap_1.has(id) &&
                    servicesMap_1
                        .get(id)
                        .find(function (service) {
                        return service.serviceId === newService.serviceId &&
                            service.passengerId === newService.passengerId &&
                            service.segmentIds.includes(newService.segmentIds[0]);
                    });
                if (type_1 === 'radio') {
                    servicesMap_1.set(id, [newService]);
                }
                else {
                    if (existService) {
                        existService.count = newService.count;
                        existService.segmentIds = newService.segmentIds;
                    }
                    else {
                        servicesMap_1.set(id, [newService]);
                    }
                }
            });
            return __assign(__assign({}, state), { selectedServices: flatDeep(__spreadArray([], __read(servicesMap_1.values()), false)) });
        case SET_BAGGAGE_TAB:
            return __assign(__assign({}, state), { baggageTab: action.payload });
        case SET_ALL_SERVICES:
            return __assign(__assign({}, state), { selectedServices: __spreadArray([], __read(action.payload.map(function (service) { return (__assign({}, service)); })), false) });
        case DISABLE:
            return __assign(__assign({}, state), { disabled: action.payload });
    }
    return state;
};
