import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import HeaderEquipmentItem from '../../../UpgradeBaggageDialog/components/BaggageDialogHeader/HeaderEquipmentItem/HeaderEquipmentItem';
import { TravellerFieldEnum } from '@websky/graphql';
import { getPassengerName } from '../../../../utils';
var BaggageCardItem = function (_a) {
    var className = _a.className, baggage = _a.baggage, passenger = _a.passenger, passengerLabels = _a.passengerLabels, count = _a.count, description = _a.description, direction = _a.direction;
    var css = useTheme('BaggageCard').BaggageCardItem;
    var getValueByType = useCallback(function (passenger, type) {
        var _a;
        return (_a = passenger.values.find(function (value) { return value.type === type; })) === null || _a === void 0 ? void 0 : _a.value;
    }, []);
    var passengerName = useMemo(function () {
        var firstName = getValueByType(passenger, TravellerFieldEnum.FirstName);
        var lastName = getValueByType(passenger, TravellerFieldEnum.LastName);
        if (firstName || lastName) {
            return getPassengerName(firstName, lastName);
        }
        return passengerLabels[passenger.id];
    }, [passenger, passengerLabels]);
    return (React.createElement("div", { className: cn(className, css.container) },
        React.createElement(HeaderEquipmentItem, { className: css.icon, rfics: baggage.rfics, isActive: true }),
        React.createElement("div", { className: css.count },
            "x",
            count),
        React.createElement("span", { className: css.info },
            passenger && React.createElement("span", { className: css.passenger }, passengerName),
            description && React.createElement("span", { className: css.description }, description),
            React.createElement("span", { className: css.direction }, direction))));
};
export default BaggageCardItem;
