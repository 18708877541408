import { __assign } from "tslib";
import * as React from 'react';
import FlightSelect from '../../FlightSelect/components/FlightSelect';
import { useContext } from 'react';
import { ActualizedPriceContext } from '../../Results/utils';
var AdapterForLeoSelectedFlight = function (props) {
    var _a, _b;
    var actualizedPrice = useContext(ActualizedPriceContext).actualizedPrice;
    var prices = actualizedPrice && ((_a = props.selectedFares[props.legId]) === null || _a === void 0 ? void 0 : _a.prices)
        ? props.selectedFares[props.legId].prices.map(function (price) {
            var _a, _b;
            if (price.flight.id && price.flight.id === props.flight.id) {
                return __assign(__assign({}, price), { price: (_b = (_a = actualizedPrice[props.legId]) === null || _a === void 0 ? void 0 : _a.price) !== null && _b !== void 0 ? _b : price.price });
            }
            return price;
        })
        : (_b = props.selectedFares[props.legId]) === null || _b === void 0 ? void 0 : _b.prices;
    return (React.createElement(FlightSelect, { flightId: props.flight.id, flight: {
            segments: props.flight.segments,
            pricesForFareGroups: [
                {
                    fareFamily: props.fareFamily,
                    prices: prices
                }
            ]
        }, selectedFares: props.selectedFares, isSelected: true, singleFlight: false }));
};
export default AdapterForLeoSelectedFlight;
