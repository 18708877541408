import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import React, { useCallback, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useTheme } from '../../theme';
import { Add, Chevron } from '../../Icons';
import Button from '../../Button/Button';
import { useToggleable } from '../../hooks';
import * as Queries from '../../__queries__/query.graphql';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import WarningModal from '../../WarningModal/components/WarningModal';
import { SsrList } from './List/SsrList';
import { fillOrder } from '../../Checkout/store/order/actions';
import { SsrModal } from './SsrModal/SsrModal';
import { ssrDataToSsrInputAdapter } from './utils';
export var Ssr = function (props) {
    var _a;
    var theme = useTheme('Ssr').Ssr;
    var t = useTranslation().t;
    var _b = useToggleable(false), isListOpen = _b.isOpen, toggleList = _b.toggle;
    var _c = useToggleable(false), isModalOpen = _c.isOpen, openModal = _c.open, closeModal = _c.close;
    var _d = __read(useState(null), 2), requestErrors = _d[0], setRequestErrors = _d[1];
    var _e = __read(useState(false), 2), isLoading = _e[0], setIsLoading = _e[1];
    var _f = __read(useState(), 2), deletingSsr = _f[0], setDeletingSsr = _f[1];
    var dispatch = useDispatch();
    var _g = __read(useMutation(Queries.SetSsrData), 1), setSsrData = _g[0];
    var addSsr = function (values) {
        var currentSsrs = ssrDataToSsrInputAdapter(props.ssr);
        saveSsrs(__spreadArray(__spreadArray([], __read(currentSsrs), false), [values], false));
    };
    var deleteSsr = function (deletingSsr) { return __awaiter(void 0, void 0, void 0, function () {
        var currentSsrs;
        return __generator(this, function (_a) {
            currentSsrs = ssrDataToSsrInputAdapter(props.ssr);
            saveSsrs(currentSsrs.filter(function (ssr) { return ssr.id !== deletingSsr.id; }));
            return [2 /*return*/];
        });
    }); };
    var saveSsrs = function (ssrs) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, e_1;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setIsLoading(true);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, setSsrData({
                            variables: {
                                params: {
                                    orderId: props.orderId,
                                    ssrs: ssrs
                                }
                            }
                        })];
                case 2:
                    _a = _c.sent(), data = _a.data, errors = _a.errors;
                    if (!errors && (data === null || data === void 0 ? void 0 : data.SetSsrData)) {
                        dispatch(fillOrder(data.SetSsrData));
                        closeModal();
                    }
                    else {
                        setRequestErrors(errors.map(function (error) { return error === null || error === void 0 ? void 0 : error.message; }));
                    }
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _c.sent();
                    setRequestErrors((_b = e_1 === null || e_1 === void 0 ? void 0 : e_1.graphQLErrors) === null || _b === void 0 ? void 0 : _b.map(function (error) { return error === null || error === void 0 ? void 0 : error.message; }));
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onModalSubmit = function (values) {
        if (deletingSsr) {
            deleteSsr(deletingSsr);
        }
        else {
            addSsr(values);
        }
    };
    var handleAddSsr = function () {
        openModal();
        setDeletingSsr(null);
    };
    var handleDeleteSsr = useCallback(function (ssr) {
        openModal();
        setDeletingSsr(ssr);
    }, []);
    var handleCloseWarningModal = useCallback(function () {
        setRequestErrors(null);
    }, []);
    var ssrCount = props.ssr.length;
    return (React.createElement("div", { className: theme.ssr },
        React.createElement("div", { className: theme.header },
            React.createElement("div", null, t('SSR')),
            React.createElement("div", null,
                React.createElement(Button, { className: theme.addButton, variant: "flat", onClick: handleAddSsr },
                    t('Add'),
                    " ",
                    Add),
                ssrCount > 0 && (React.createElement(Button, { className: cn(theme.showButton, (_a = {}, _a[theme.showButton_opened] = isListOpen, _a)), variant: "flat", onClick: toggleList },
                    t('Show'),
                    " (",
                    ssrCount,
                    ") ",
                    Chevron)))),
        React.createElement(Collapse, { in: isListOpen },
            React.createElement(SsrList, { ssr: props.ssr, travellers: props.travellers, segments: props.segments, onDelete: handleDeleteSsr })),
        isModalOpen && (React.createElement(SsrModal, { travellers: props.travellers, segments: props.segments, onSubmit: onModalSubmit, onClose: closeModal, isLoading: isLoading, deletingSsr: deletingSsr })),
        React.createElement(WarningModal, { title: t('Ops...'), content: t('Unfortunately, something went wrong. Please try again or contact with customer support service'), isOpen: !!requestErrors, errorMessage: requestErrors, buttonText: t('Close'), onButtonClick: handleCloseWarningModal, onClose: handleCloseWarningModal })));
};
