import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
var BaggageDirection = function (_a) {
    var directions = _a.directions, allSegments = _a.allSegments, className = _a.className;
    var css = useTheme('BaggageList').BaggageDirection;
    var t = useTranslation('IncludedBaggage').t;
    var text = useMemo(function () {
        if (allSegments) {
            return t('All flights');
        }
        return directions.map(function (point) { return "".concat(point.departure.iata, " - ").concat(point.arrival.iata); }).join('; ');
    }, [directions]);
    if (text === null) {
        return null;
    }
    return React.createElement("div", { className: cn(css.root, className) }, text);
};
export default BaggageDirection;
