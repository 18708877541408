import * as React from 'react';
import { useContext } from 'react';
import { Mode as WorkingMode } from '../../../types';
import BaggageService from '../../../BaggageService/components/BaggageService';
import { Mode } from '../../../BaggageService/utils';
import { ModeContext, useConfig } from '../../../context';
import { BaggageType as Type } from '../../../__generated__/globalTypes';
import { useBaggageServiceBlock } from '../../../BaggageService/hooks';
var BaggageServiceWrapper = function (props) {
    var _a, _b, _c;
    var baggage = props.baggage;
    var needToHideBaggageForInfants = ['N4', 'DV', 'YC'].includes((_c = (_b = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.iataCode);
    var mode = useContext(ModeContext);
    var baggageAvailabilityBySegment = mode === WorkingMode.Booking ? useBaggageServiceBlock(props.passengerId) : new Map();
    var _d = useConfig().global, maxBaggagePerPassenger = _d.maxBaggagePerPassenger, maxBaggage = _d.maxBaggage;
    var onBaggageIncrease = function (segmentId, serviceId, forAllSegments) {
        var baggageObject = baggage.find(function (baggage) { return baggage.id === serviceId; });
        if (baggageObject) {
            props.onIncrease(baggageObject, segmentId, props.passengerId, forAllSegments);
        }
    };
    var onBaggageDecrease = function (segmentId, serviceId, forAllSegments) {
        var baggageObject = baggage.find(function (baggage) { return baggage.id === serviceId; });
        if (baggageObject) {
            props.onDecrease(baggageObject, segmentId, props.passengerId, forAllSegments);
        }
    };
    var onBaggageReplace = function (segmentId, oldBaggageId, newBaggageId, forAllSegments) {
        var oldBaggage = baggage.find(function (baggage) { return baggage.id === oldBaggageId; });
        var newBaggage = baggage.find(function (baggage) { return baggage.id === newBaggageId; });
        if (oldBaggage && newBaggage) {
            props.onReplace(oldBaggage, newBaggage, segmentId, props.passengerId, forAllSegments);
        }
    };
    var onModeChange = function (mode) {
        // https://youtrack.websky.aero/issue/WEBSKY-890#focus=streamItem-4-4743.0-0
        if ((typeof maxBaggagePerPassenger === 'number' || typeof maxBaggage === 'number') && mode === Mode.Same) {
            props.onClear(props.passengerId, Type.CheckedBaggage);
            props.onClear(props.passengerId, Type.CarryOn);
        }
        else {
            props.onModeChange(props.passengerId);
        }
    };
    return baggage.length ? (React.createElement(BaggageService, { canAddBaggageBySegments: baggageAvailabilityBySegment, segments: props.segments, baggage: baggage.filter(function (baggage) {
            return needToHideBaggageForInfants ? baggage.allowedPassengers.includes(props.passengerId) : true;
        }), selectedBaggage: props.selectedBaggage, fullScreen: props.fullScreen, onBaggageDecrease: onBaggageDecrease, onBaggageIncrease: onBaggageIncrease, onBaggageReplace: onBaggageReplace, totalPrice: props.totalPrice, passengerName: props.passengerName, readonly: props.readonly, onModeChange: onModeChange, simpleBaggageSelector: props.simpleBaggageSelector })) : null;
};
export default BaggageServiceWrapper;
