import * as React from 'react';
import cn from 'classnames';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import FareGroup from '../../FareGroup';
import DesktopFlightInfo from '../../DesktopFlightInfo';
import Header from './Header/Header';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import { useDesktopFlight } from '../hooks';
initI18n('DesktopFlight');
var DesktopFlight = function (props) {
    var _a;
    var css = useTheme('DesktopFlight').Flight;
    var t = useTranslation().t;
    var _b = useDesktopFlight(props), flightBlock = _b.flightBlock, lastSelectedFare = _b.lastSelectedFare, activeTab = _b.activeTab, getFareGroupTabHandler = _b.getFareGroupTabHandler, handleFlightInfoTab = _b.handleFlightInfoTab, filteredPrices = _b.filteredPrices, handleEconomyFareGroupsTab = _b.handleEconomyFareGroupsTab, economyFareGroups = _b.economyFareGroups, businessFareGroups = _b.businessFareGroups, getOnFareSelect = _b.getOnFareSelect;
    return (React.createElement("div", { ref: flightBlock, className: cn(css.flight, (_a = {},
            _a[css.flight_open] = activeTab !== null,
            _a)) },
        React.createElement(Header, { flight: props.flight, getOnFareClickHandler: getFareGroupTabHandler, onInfoClick: handleFlightInfoTab, activeTab: activeTab, singleFare: props.singleFare, prices: filteredPrices, lastSelectedFare: lastSelectedFare }),
        React.createElement(Collapse, { in: activeTab !== null, mountOnEnter: true },
            React.createElement("div", { className: css.flight__content },
                React.createElement(SwipeableViews, { index: activeTab, animateHeight: true },
                    React.createElement(DesktopFlightInfo, { flight: props.flight, onButtonClick: handleEconomyFareGroupsTab, buttonText: t('DesktopFlight:Select fare') }),
                    React.createElement("div", { className: css.fareGroups }, economyFareGroups.map(function (group, index) {
                        var _a, _b;
                        return (React.createElement(FareGroup, { key: index, isDisabled: !group.prices || (lastSelectedFare && lastSelectedFare !== group.fareFamily.id), fare: group.fareFamily, onSelect: getOnFareSelect(group), price: group.prices && group.prices[0] ? group.prices[0].price : null, flightId: (_b = (_a = group.prices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.flight.id }));
                    })),
                    businessFareGroups && (React.createElement("div", { className: css.fareGroups }, businessFareGroups.map(function (group, index) {
                        var _a, _b;
                        return (React.createElement(FareGroup, { key: index, isDisabled: !group.prices ||
                                (lastSelectedFare && lastSelectedFare !== group.fareFamily.id), fare: group.fareFamily, onSelect: getOnFareSelect(group), price: group.prices && group.prices[0] ? group.prices[0].price : null, flightId: (_b = (_a = group.prices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.flight.id }));
                    }))))))));
};
export default DesktopFlight;
