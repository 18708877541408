import { __assign } from "tslib";
import * as React from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import Input from '../../../../../../Input';
import PhoneInput from '../../../../../../PhoneInput';
import { useTheme } from '../../../../../../theme';
import Tooltip from '../../../../../../Tooltip';
import { emailRegex, phoneRegex } from '../../../../../../utils';
var Field = function (props) {
    var t = useTranslation('Exchange').t;
    var theme = useTheme('Exchange').CreateRequest;
    var field = useField(props.name, {
        type: props.type === 'checkbox' ? 'checkbox' : 'text',
        validate: function (value) {
            if (props.type === 'email' && !emailRegex.test(value)) {
                return t('Please enter a valid email');
            }
            else if (props.type === 'phone' && !phoneRegex.test(value)) {
                return t('Please enter a valid phone');
            }
            else if (props.type === 'comment' && !value && props.required) {
                return t('Required');
            }
            else if (props.type === 'checkbox' && !value) {
                return true;
            }
            return undefined;
        }
    });
    if (props.type === 'phone') {
        return (React.createElement(PhoneInput, { value: field.input.value, textFieldsProps: __assign(__assign({}, field.input), { label: props.label, error: !!field.meta.error && field.meta.touched, helperText: field.meta.touched && field.meta.error }), className: props.className }));
    }
    if (props.type === 'checkbox') {
        return (React.createElement(FormControlLabel, { control: React.createElement(Tooltip, { open: field.meta.submitFailed && !field.input.value, title: t('Please, read the rules of service and accept it') },
                React.createElement(Checkbox, { classes: {
                        checked: theme.button_checked,
                        root: theme.button
                    }, checked: field.input.checked, inputProps: __assign({}, field.input) })), label: props.label }));
    }
    return (React.createElement(Input, { label: props.label, value: field.input.value, TextFieldProps: __assign(__assign({}, field.input), { error: !!field.meta.error && field.meta.touched, helperText: field.meta.touched && field.meta.error }), className: props.className, absoluteHintPosition: true }));
};
export default Field;
