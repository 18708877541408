import { __assign, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { useTheme } from '../../theme';
import { LocationType } from '../../../types';
import { useTranslation } from 'react-i18next';
var closeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z", fill: "#9A9A9A" })));
var Transition = function (props) {
    return React.createElement(Slide, __assign({}, props, { direction: "up", children: props.children }));
};
var MobileDialog = function (_a) {
    var _b, _c;
    var isOpen = _a.isOpen, options = _a.options, onClose = _a.onClose, onInputChange = _a.onInputChange, onSelect = _a.onSelect, renderOption = _a.renderOption, renderGroup = _a.renderGroup, type = _a.type;
    var css = useTheme().MobileDialog;
    var _d = __read(useState(''), 2), query = _d[0], setQuery = _d[1];
    var t = useTranslation('LocationAutocomplete').t;
    var onChange = function (event) {
        setQuery(event.target.value);
        if (onInputChange) {
            onInputChange(event);
        }
    };
    return (React.createElement(Dialog, { open: isOpen, fullScreen: true, disableRestoreFocus: true, fullWidth: true, keepMounted: true, TransitionComponent: Transition, classes: {
            root: css.modal
        } },
        React.createElement("div", { className: css.dialog },
            React.createElement("div", { className: cn(css.header, (_b = {},
                    _b[css.header_white] = !!query,
                    _b)) },
                React.createElement("input", { className: css.input, type: "text", placeholder: "".concat(t(type === LocationType.Departure ? 'From' : 'To'), "?"), value: query, onChange: onChange, autoFocus: true, spellCheck: false, autoComplete: "off", autoCorrect: "off" }),
                React.createElement("div", { className: cn(css.hint, (_c = {},
                        _c[css.hint_hidden] = query,
                        _c)) }, t('Enter city or country')),
                React.createElement("div", { className: css.closer, onClick: onClose }, closeIcon)),
            React.createElement("div", { className: css.content }, options.map(function (item, index) {
                return 'value' in item ? renderOption(item, index) : renderGroup(item, index);
            })))));
};
export default MobileDialog;
