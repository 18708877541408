import { __read } from "tslib";
import React, { useCallback, useState } from 'react';
import { useTheme } from '../../../../theme';
import ScanDocs from '../ScanDocs/ScanDocs';
import Recognized from '../Recognized/Recognized';
import { emptyDocFields } from '../../constants';
import { Form } from 'react-final-form';
var RecognitionPopupContent = function (props) {
    var traveller = props.traveller;
    var css = useTheme('DocRecognition').RecognitionPopupContent;
    var _a = __read(useState(emptyDocFields), 2), fields = _a[0], setFields = _a[1];
    var onRecognitionFinished = useCallback(function (fields) {
        setFields(fields);
    }, []);
    return (React.createElement(Form, { onSubmit: function (values) {
            props.onConfirmScanFields(values);
        } }, function (_a) {
        var handleSubmit = _a.handleSubmit, reset = _a.form.reset;
        return (React.createElement("form", { onSubmit: handleSubmit },
            React.createElement("div", { className: css.contentWrapper },
                React.createElement("div", { className: css.content },
                    React.createElement(ScanDocs, { traveller: traveller, isModalOpen: props.isModalOpen, orderId: props.orderId, passengerId: props.passengerId, passengerType: props.passengerType, className: css.scanBlock, onRecognitionFinished: onRecognitionFinished, resetForm: reset }),
                    React.createElement(Recognized, { traveller: traveller, className: css.recognizedBlock, isInputsReadonly: !fields.length, passengerId: props.passengerId, passengerType: props.passengerType, fields: fields })))));
    }));
};
export default RecognitionPopupContent;
