import { __generator } from "tslib";
import { select, takeEvery } from 'redux-saga/effects';
import { SET_NEXT_STEP, SET_PREV_STEP, SET_STEP } from './actions';
import { getNextStep, getPrevStep } from '../../utils';
import { getOrderId } from '../order/selectors';
import { getSteps } from './selectors';
function setNextStep() {
    var state, steps, currentPath, orderId, currentStep, nextStep;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select()];
            case 1:
                state = _a.sent();
                return [4 /*yield*/, select(getSteps)];
            case 2:
                steps = _a.sent(), currentPath = window.location.hash;
                return [4 /*yield*/, select(getOrderId)];
            case 3:
                orderId = _a.sent();
                currentStep = steps.findIndex(function (step) { return currentPath.search(step.url) >= 0; }), nextStep = getNextStep(steps, currentStep, state);
                window.location.hash = "/booking/".concat(orderId, "/").concat(steps[nextStep].url);
                return [2 /*return*/];
        }
    });
}
function setPrevStep() {
    var state, steps, currentPath, orderId, currentStep, prevStep;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select()];
            case 1:
                state = _a.sent();
                return [4 /*yield*/, select(getSteps)];
            case 2:
                steps = _a.sent(), currentPath = window.location.hash;
                return [4 /*yield*/, select(getOrderId)];
            case 3:
                orderId = _a.sent();
                currentStep = steps.findIndex(function (step) { return currentPath.search(step.url) >= 0; }), prevStep = getPrevStep(steps, currentStep, state);
                window.location.hash = "/booking/".concat(orderId, "/").concat(steps[prevStep].url);
                return [2 /*return*/];
        }
    });
}
function setStep(_a) {
    var steps, orderId, nextStep;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(getSteps)];
            case 1:
                steps = _b.sent();
                return [4 /*yield*/, select(getOrderId)];
            case 2:
                orderId = _b.sent();
                nextStep = steps.findIndex(function (step) { return step.type === payload; });
                window.location.hash = "/booking/".concat(orderId, "/").concat(steps[nextStep].url);
                return [2 /*return*/];
        }
    });
}
export default function stepManage() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(SET_NEXT_STEP, setNextStep)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(SET_PREV_STEP, setPrevStep)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(SET_STEP, setStep)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
