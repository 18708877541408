import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import { useField } from 'react-final-form';
import { useQuery } from '@apollo/react-hooks';
import Input from '../../Input';
import { emailRegex, initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import Button from '../../Button/Button';
import Resend from './Resend/Resend';
import SocialAuth from '../../SocialAuth';
import { defaultTheme, Provider } from '../../SocialAuth/theme';
import LoginBlock from './LoginBlock/LoginBlock';
import Tooltip from '../../Tooltip';
import { ThemeProvider, useTheme } from '../../theme';
import { InputType } from '../../Passenger/types';
import { hideText, validate } from '../../Passenger/utils';
import Login from '../../LoginPage/components/LoginPage/LoginPage';
import { LoginType } from '../../__generated__/globalTypes';
import Modal from '../../Modal';
import * as AccountQueries from '../../__queries__/Account.graphql';
import MediaQuery from '../../MediaQuery/MediaQuery';
import { timerIcon } from '../Icons';
import { reachGoal, UserGoal } from '../../analytics';
import SlideBottom from '../../SlideBottom/components/SlideBottom';
import { useMediaQuery } from 'react-responsive';
import { WarningRounded } from '../../Icons';
import OtherContacts from './OtherContacts/OtherContacts';
import { escapedT } from './utils';
import MainContacts from './MainContacts/MainContacts';
import { useIsTerminal } from '../../hooks';
initI18n('Contacts');
var debounceTimeout = 0;
var formatPhoneNumberIntl = require('react-phone-number-input').formatPhoneNumberIntl;
var Contacts = memo(function (props) {
    var _a, _b, _c, _d;
    var _e;
    var theme = useTheme('Contacts').ContactsStyles;
    var _f = __read(useState(false), 2), welcomeIsVisible = _f[0], setWelcomeIsVisible = _f[1];
    var _g = __read(useState(false), 2), formHasBeenSubmitted = _g[0], setFormHasBeenSubmitted = _g[1];
    var _h = __read(useState(false), 2), showCodeInput = _h[0], setShowCodeInput = _h[1];
    var _j = __read(useState(false), 2), isLoginPageOpened = _j[0], setLoginPageOpened = _j[1];
    var _k = __read(useState(null), 2), isUserExist = _k[0], setUserExist = _k[1];
    var isTerminal = useIsTerminal();
    var t = useTranslation('Contacts').t;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var checkAccountExistence = useQuery(AccountQueries.AccountExistence, {
        skip: true
    });
    var phone = useField(props.mainContacts[0].phone.name, {
        type: props.mainContacts[0].phone.type,
        initialValue: props.mainContacts[0].phone.defaultValue,
        validate: function (value) { return t(escapedT(validate(value, {}, InputType.Phone, props.mainContacts[0].phone.validations))); }
    });
    var email = useField(props.mainContacts[0].email.name, {
        type: props.mainContacts[0].email.input,
        initialValue: props.mainContacts[0].email.defaultValue,
        validate: function (value) { return t(escapedT(validate(value, {}, InputType.Text, props.mainContacts[0].email.validations))); }
    });
    // const onLogin = () => {
    // 	!props.isEmailChecking && props.setContactsData(email.input.value, phone.input.value);
    // };
    // const onUnlock = (event: SyntheticEvent) => {
    // 	// Some validation errors.
    // 	if (!phone.meta.valid || !email.meta.valid) {
    // 		props.submitForm(event);
    // 	}
    // 	// Everything is ok, let's try to sign in.
    // 	else {
    // 		onLogin();
    // 	}
    // };
    var codeInputRef = React.useRef(null);
    var onSuccessfulLogin = useCallback(function (isRegistration) {
        if (isRegistration === void 0) { isRegistration = false; }
        setLoginPageOpened(false);
        reachGoal(isRegistration ? UserGoal.Registration : UserGoal.Login);
        if (props.onSuccessfulLogin) {
            props.onSuccessfulLogin();
        }
    }, [props.onSuccessfulLogin]);
    var onCodeFieldKeyDown = function (e) {
        if (e.key === 'Enter') {
            props.onCodeFieldDone(e.target.value);
        }
    };
    var onCodeFieldButton = function () {
        if (!props.isCodeChecking && codeInputRef.current) {
            setFormHasBeenSubmitted(true);
            props.onCodeFieldDone(codeInputRef.current.value);
        }
    };
    React.useEffect(function () {
        setShowCodeInput((props.codeSent ||
            props.isCodeChecking ||
            props.isCodeWrong ||
            props.isCodeSendingAgain ||
            props.isCodeAgainSent) &&
            !props.isEmailChecking);
    }, [
        props.codeSent,
        props.isCodeChecking,
        props.isCodeWrong,
        props.isCodeSendingAgain,
        props.isCodeAgainSent,
        props.isEmailChecking
    ]);
    var runWelcomeTimer = function () {
        setTimeout(function () {
            setWelcomeIsVisible(false);
            setFormHasBeenSubmitted(false);
        }, 2000);
    };
    useEffect(function () {
        if (!welcomeIsVisible && props.isAuthorized && formHasBeenSubmitted) {
            setWelcomeIsVisible(true);
            runWelcomeTimer();
        }
    }, [props.isAuthorized]);
    var onChangeHandler = useCallback(function (event, email) {
        var value = event.target.value;
        if (props.open && !props.isAuthorized && emailRegex.test(event.target.value)) {
            clearTimeout(debounceTimeout);
            debounceTimeout = window.setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                var isAccountExist;
                var _a, _b, _c, _d;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0: return [4 /*yield*/, checkAccountExistence.refetch({
                                params: {
                                    login: value,
                                    loginType: LoginType.Email
                                }
                            })];
                        case 1:
                            isAccountExist = _e.sent();
                            if ((_b = (_a = isAccountExist.data) === null || _a === void 0 ? void 0 : _a.AccountExistence) === null || _b === void 0 ? void 0 : _b.result) {
                                setUserExist('login');
                            }
                            else if (!((_d = (_c = isAccountExist.data) === null || _c === void 0 ? void 0 : _c.AccountExistence) === null || _d === void 0 ? void 0 : _d.result)) {
                                setUserExist('register');
                            }
                            return [2 /*return*/];
                    }
                });
            }); }, 500);
        }
        else {
            setUserExist(null);
        }
        email.input.onChange(event);
    }, []);
    var userInfo = [];
    if (email.input.value) {
        userInfo.push(hideText(email.input.value));
    }
    if (phone.input.value) {
        userInfo.push(formatPhoneNumberIntl("+".concat(phone.input.value)));
    }
    return (React.createElement("div", { className: cn(theme.contacts, (_a = {}, _a[theme.short] = !props.open, _a)) },
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.description__header }, isTerminal ? t('Contact') : t('Your contacts')),
            !props.open && (React.createElement("div", { className: theme.customer__info },
                React.createElement("div", { className: cn(theme.field) }, userInfo.join(', '))))),
        props.open && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.form__container },
                React.createElement("div", { className: theme.form },
                    isTerminal ? (React.createElement("div", { className: theme.contactsSubheader }, t('Main (Payment)'))) : (React.createElement("div", { className: theme.description },
                        React.createElement("div", { className: theme.description__text },
                            WarningRounded,
                            t('Contact details are necessary for us to be able to pass on the latest flight information.')))),
                    React.createElement(MainContacts, { loginType: props.loginType, onChangeHandler: onChangeHandler, contacts: props.mainContacts, isEmailChecking: props.isEmailChecking, isInputsReadonly: props.isInputsReadonly }),
                    isTerminal && ((_e = props.otherContacts) === null || _e === void 0 ? void 0 : _e.size) > 0 && (React.createElement(OtherContacts, { isEmailChecking: props.isEmailChecking, isInputsReadonly: props.isInputsReadonly, isMobile: isMobile, onChangeHandler: onChangeHandler, otherContacts: props.otherContacts }))),
                !showCodeInput && !props.isAuthorized && !isTerminal && (React.createElement(Collapse, { mountOnEnter: true, unmountOnExit: true, in: !!isUserExist && !props.isFormLocked },
                    React.createElement("div", { className: cn(theme.footer, theme.footer_login) },
                        React.createElement(MediaQuery, { maxWidth: "mobile" },
                            React.createElement("div", { className: theme.footer__timerIcon }, timerIcon)),
                        React.createElement("div", { className: theme.footer__inner },
                            React.createElement("div", { className: theme.footer__text },
                                React.createElement(MediaQuery, { minWidth: "mobile" }, t(isUserExist === 'login'
                                    ? 'Want to speed up passenger data entry? Log in and continue.'
                                    : 'Want to speed up passenger data entry? Sign up and continue.')),
                                React.createElement(MediaQuery, { maxWidth: "mobile" },
                                    React.createElement("span", null, t('Want to speed up passenger data entry?')))),
                            React.createElement(Button, { className: cn((_b = {}, _b[theme.loader] = props.isEmailChecking, _b)), onClick: function () { return setLoginPageOpened(true); } }, !props.isEmailChecking &&
                                t(isUserExist === 'login' ? 'Log in' : 'Sign up')),
                            React.createElement(MediaQuery, { maxWidth: "mobile" },
                                React.createElement(SlideBottom, { isOpen: isLoginPageOpened, noPadding: true, onClose: function () { return setLoginPageOpened(false); } },
                                    React.createElement("div", { className: theme.footer__slideBottom },
                                        React.createElement(Login, { variant: "compact", loginType: props.loginType, onSuccess: onSuccessfulLogin, initialValues: {
                                                phone: phone.input.value,
                                                email: email.input.value
                                            } })))),
                            React.createElement(MediaQuery, { minWidth: "mobile" },
                                React.createElement(Modal, { open: isLoginPageOpened, onClose: function () { return setLoginPageOpened(false); }, maxWidth: "sm", classes: {
                                        paper: theme.login__modal
                                    } },
                                    React.createElement(ThemeProvider, { value: {
                                            LoginPage: {
                                                Verification: {
                                                    footer: theme.login__modal__verification__footer
                                                }
                                            }
                                        } },
                                        React.createElement(Login, { loginType: props.loginType, onSuccess: onSuccessfulLogin, initialValues: {
                                                phone: phone.input.value,
                                                email: email.input.value
                                            } }))))),
                        React.createElement(MediaQuery, { minWidth: "mobile" },
                            React.createElement("div", null,
                                React.createElement(Provider, { value: {
                                        SocialAuth: __assign(__assign({}, defaultTheme.SocialAuth), { button: theme.footer__social__button })
                                    } },
                                    React.createElement(SocialAuth, null)))))))),
            (showCodeInput || (welcomeIsVisible && props.isAuthorized)) && (React.createElement("div", { className: cn(theme.footer, (_c = {}, _c[theme.footer_centered] = props.isAuthorized, _c)) },
                showCodeInput && !props.isAuthorized && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: theme.footer__text }, t('We sent SMS with verification code to the phone')),
                    React.createElement("div", { className: theme.footer__action },
                        React.createElement("div", { className: theme.code__wrapper },
                            React.createElement(Input, { className: theme.footer__input, placeholder: "XXXX", disabled: props.isCodeChecking, inputRef: codeInputRef, absoluteHintPosition: true, value: "220519", TextFieldProps: {
                                    onKeyDown: onCodeFieldKeyDown,
                                    autoFocus: true,
                                    helperText: props.isCodeWrong ? t('Invalid code.') : null,
                                    error: props.isCodeWrong
                                } }),
                            React.createElement(Tooltip, { title: "\u0422\u0435\u0441\u0442\u043E\u0432\u044B\u0439 \u0432\u0445\u043E\u0434 \u0432 \u0441\u0438\u0441\u0442\u0435\u043C\u0443", open: true },
                                React.createElement(Button, { onClick: onCodeFieldButton, className: cn((_d = {}, _d[theme.loader] = props.isCodeChecking, _d)) }, !props.isCodeChecking ? t('Next') : ''))),
                        React.createElement(Resend, { sendCode: props.sendCode, codeSending: props.isCodeSendingAgain, codeSent: props.isCodeAgainSent })))),
                welcomeIsVisible && props.isAuthorized && (React.createElement("div", { className: theme.welcome }, t('Welcome!'))))))),
        React.createElement(Collapse, { in: props.isFormLocked && !showCodeInput, unmountOnExit: true },
            React.createElement(LoginBlock, { onClick: function () { return setLoginPageOpened(true); }, onSkipClick: props.unlockForm }))));
});
export default Contacts;
