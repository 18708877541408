import * as React from 'react';
import { useMemo } from 'react';
import CheckoutAdditionalService from '../..';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import ExtraServicesModal from '../../../ExtraServices/components/ExtraServicesModal';
import WarningModal from '../../../WarningModal';
import { convertTravellerSelectedServicesToSelectedServicesSummary } from '../../../ExtraServices/utils';
import SimpleLoader from '../../../SimpleLoader';
import { useSelector } from 'react-redux';
import { getOrder, getSelectedMiscServices } from '../../../Checkout/store/order/selectors';
import { useExtras } from './hooks';
import { getPassengersState } from '../../../Checkout/store/selectedServices/selectors';
var Extras = function () {
    var t = useTranslation('Checkout').t;
    var css = useTheme('CheckoutAdditionalService').Extras;
    var order = useSelector(getOrder);
    var selectedMiscServices = useSelector(getSelectedMiscServices);
    var passengers = useSelector(getPassengersState);
    var selectedServicesSummary = useMemo(function () { return convertTravellerSelectedServicesToSelectedServicesSummary(selectedMiscServices, order); }, [selectedMiscServices, order]);
    var _a = useExtras(), isExtrasModalOpen = _a.isExtrasModalOpen, onClose = _a.onClose, onOpen = _a.onOpen, convertedSelectedServices = _a.selectedServices, services = _a.services, segments = _a.segments, onExtrasConfirm = _a.onExtrasConfirm, isLoading = _a.isLoading, saveOrderServicesError = _a.saveOrderServicesError, clearError = _a.clearError;
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), errorMessage: saveOrderServicesError, onClose: clearError, onButtonClick: clearError, buttonText: t('OK, thank you'), isOpen: saveOrderServicesError !== null }),
        React.createElement(CheckoutAdditionalService, { className: css.extras, header: t('Extras'), description: "", addButtonText: t('Add'), isSelected: !!(selectedServicesSummary === null || selectedServicesSummary === void 0 ? void 0 : selectedServicesSummary.length), addClick: onOpen, buttons: [
                {
                    text: t('Change'),
                    action: onOpen
                }
            ] },
            React.createElement(React.Fragment, null, selectedServicesSummary === null || selectedServicesSummary === void 0 ? void 0 : selectedServicesSummary.map(function (service, index) { return (React.createElement("div", { key: "".concat(service.name, "_").concat(index), className: css.listItem }, "".concat(service.name, " x ").concat(service.count))); }))),
        React.createElement(ExtraServicesModal, { open: isExtrasModalOpen, passengers: passengers, selectedServices: convertedSelectedServices, services: services, segments: segments, onConfirm: onExtrasConfirm, onClose: onClose })));
};
export default Extras;
