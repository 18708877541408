import React from 'react';
import { FlightInfoV2 } from '../../../FlightInfo';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import SelectedFare from '../../../SelectedFare';
var DesktopFlightModalContentSelected = function (_a) {
    var flight = _a.flight, lastSelectedFare = _a.lastSelectedFare, flightId = _a.flightId;
    var theme = useTheme('DesktopFlight').ModalContent;
    var t = useTranslation('FlightInfoModalContent').t;
    return (React.createElement("div", null,
        React.createElement("div", { className: theme.title }, t('You choose')),
        lastSelectedFare && (React.createElement(SelectedFare, { flightId: flightId, className: theme.fareInfo, selectedFare: lastSelectedFare })),
        React.createElement(FlightInfoV2, { flight: flight, selectedFareFamily: lastSelectedFare })));
};
export default DesktopFlightModalContentSelected;
