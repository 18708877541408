import * as React from 'react';
import { Field } from 'react-final-form';
import cn from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useTheme } from '../../../../theme';
var RadioBtn = function (props) {
    var _a, _b;
    var css = useTheme('Payment').Payment;
    var isChecked = props.method === props.value;
    return (React.createElement("div", { className: cn(css.radioItem, (_a = {}, _a[css.radioItem_checked] = isChecked, _a)) },
        React.createElement(FormControlLabel, { value: props.value, control: React.createElement(Field, { name: props.name, type: "radio", render: function (_a) {
                    var input = _a.input;
                    return (React.createElement(Radio, { checked: isChecked, color: "primary", onChange: function (e) {
                            input.onChange(props.value);
                            props.setMethod(props.value);
                        }, classes: {
                            root: css.radioBtn
                        } }));
                } }), label: props.label }),
        React.createElement("div", { className: cn(css.radioBtn__component, (_b = {}, _b[css.radioBtn__component_active] = isChecked, _b)) }, props.component)));
};
export default RadioBtn;
