import { __awaiter, __generator, __read } from "tslib";
import { useState } from 'react';
import { useGetAccountInfoQuery } from '@websky/graphql';
export var useAccountInfo = function () {
    var _a = __read(useState(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var getCurrentUserQuery = useGetAccountInfoQuery({
        skip: true
    });
    var getCurrentUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, getCurrentUserQuery.refetch()];
                case 1:
                    result = _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/, result];
            }
        });
    }); };
    return {
        getCurrentUser: getCurrentUser,
        isLoading: isLoading
    };
};
