export var LocationType;
(function (LocationType) {
    LocationType["Departure"] = "departure";
    LocationType["Arrival"] = "arrival";
})(LocationType || (LocationType = {}));
export var Mode;
(function (Mode) {
    Mode[Mode["Booking"] = 0] = "Booking";
    Mode[Mode["Checkin"] = 1] = "Checkin";
})(Mode || (Mode = {}));
export var Locale;
(function (Locale) {
    Locale["English"] = "en";
    Locale["Russian"] = "ru";
    Locale["German"] = "de";
    Locale["Kazakh"] = "kk";
})(Locale || (Locale = {}));
export var Metasearch;
(function (Metasearch) {
    Metasearch["Aviasales"] = "aviasales";
})(Metasearch || (Metasearch = {}));
export var LOCALES = new Set([Locale.Russian, Locale.English, Locale.German, Locale.Kazakh]);
