var _a;
import { createContext, useContext } from 'react';
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, IntrospectionFragmentMatcher, defaultDataIdFromObject } from 'apollo-boost';
import errorLink from './apolloErrorLink';
import * as Cookie from 'js-cookie';
import { getLocale } from './utils';
import introspectionResult from './fragmentTypes';
import './custom-event-polyfill';
import { getCurrency, GRAPHQL_KEY } from './cache';
import { Currency } from './enums';
import leoNotAuthenticatedErrorLink from './LEO/sessionErrorLink';
var token = Cookie.get(GRAPHQL_KEY);
var client;
var GRAPHQL_URL = (_a = process.env.API_URL) !== null && _a !== void 0 ? _a : 'https://testleo.graphql.mlsd.ru/nemox/graphql/query/nemo';
var GraphQLClientContext;
export var setApiUrl = function (newApiUrl) {
    GRAPHQL_URL = newApiUrl;
};
var dataIdFromObject = function (responseObject) {
    switch (responseObject.__typename) {
        case 'AvailabilityResultSegment':
            var availabilityResultSegmentObj = responseObject;
            return "AvailabilityResultSegment:".concat(availabilityResultSegmentObj.id, ":").concat(availabilityResultSegmentObj.flightNumber);
        default:
            return defaultDataIdFromObject(responseObject);
    }
};
export var createClient = function () {
    var _a, _b, _c;
    var isDemo = document.location.href.includes('demo.websky.tech');
    var needToSendCurrencyInHeaders = !['A9', 'RM', 'TERMINAL'].includes((_c = (_b = (_a = window.__webskyEngineConfig) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.iataCode);
    if (!client) {
        var fragmentMatcher = new IntrospectionFragmentMatcher({
            introspectionQueryResultData: introspectionResult
        });
        var getTokenLink = new ApolloLink(function (operation, forward) {
            var headers = {
                'Accept-Language': getLocale()
            };
            if (needToSendCurrencyInHeaders || operation.operationName === 'FlightsMinPricesInPeriod') {
                headers['X-Currency'] = isDemo ? Currency.EUR : getCurrency();
            }
            if (token) {
                headers.authorization = token ? "Bearer ".concat(token) : '';
            }
            operation.setContext(function () { return ({
                headers: headers
            }); });
            return forward(operation).map(function (response) {
                var headers = operation.getContext().response.headers;
                if (headers) {
                    var currentToken = headers.get('X-Token');
                    if (currentToken) {
                        token = currentToken;
                        Cookie.set(GRAPHQL_KEY, token, {
                            expires: 90,
                            sameSite: 'lax',
                            secure: process.env.NODE_ENV !== 'development'
                        });
                    }
                }
                return response;
            });
        });
        var httpLink = new HttpLink({
            uri: GRAPHQL_URL,
            credentials: 'include'
        });
        client = new ApolloClient({
            link: from([leoNotAuthenticatedErrorLink, errorLink, getTokenLink, httpLink]),
            cache: new InMemoryCache({
                fragmentMatcher: fragmentMatcher,
                dataIdFromObject: dataIdFromObject
            })
        });
    }
    return client;
};
export var useGraphQLClient = function () {
    if (!GraphQLClientContext) {
        GraphQLClientContext = createContext(createClient());
    }
    return useContext(GraphQLClientContext);
};
