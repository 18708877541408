import * as React from 'react';
import { useMemo } from 'react';
import { useDispatch, batch } from 'react-redux';
import { CloseClear } from '../../../../Icons';
import { removeService } from '../../../../Checkout/store/selectedServices/actions';
import { useTheme } from '../../../../theme';
import { Money } from '../../../../Money';
import { useTranslation } from 'react-i18next';
var AdditionalServicePrice = function (props) {
    var t = useTranslation('ServiceCard').t;
    var theme = useTheme('ServicesCard').ServicesCard;
    var dispatch = useDispatch();
    var onClick = useMemo(function () {
        return props.type === 'common'
            ? function () {
                var service = props.service;
                batch(function () {
                    service.segmentIds.forEach(function (segmentId) {
                        dispatch(removeService({
                            passengerId: props.passengerId,
                            serviceId: service.serviceId,
                            segmentId: segmentId
                        }));
                    });
                });
            }
            : function () {
                var service = props.service;
                dispatch(removeService({
                    passengerId: props.passengerId,
                    serviceId: service.seat.number,
                    segmentId: service.segment.id
                }));
            };
    }, [props.service, props.passengerId, props.type]);
    return (React.createElement(React.Fragment, null,
        props.price.amount === 0 ? (React.createElement("div", { className: theme.priceFree }, t('Free'))) : (React.createElement(Money, { moneyClassName: theme.price, money: props.price })),
        React.createElement("div", { onClick: onClick, className: theme.serviceItem__delete }, CloseClear)));
};
export default AdditionalServicePrice;
