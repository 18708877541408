import { __assign, __read } from "tslib";
export var extraServicesReducer = function (state, action) {
    var _a, _b, _c, _d;
    var _e;
    if (action.type === 'set') {
        var payload = action.payload;
        return __assign(__assign({}, state), (_a = {}, _a[payload.serviceId] = __assign(__assign({}, state[payload.serviceId]), (_b = {}, _b[payload.passengerId] = __assign(__assign({}, (_e = state[payload.serviceId]) === null || _e === void 0 ? void 0 : _e[payload.passengerId]), (_c = {}, _c[payload.segmentId] = payload.count, _c)), _b)), _a));
    }
    else if (action.type === 'clear') {
        var serviceId = action.payload.serviceId;
        return __assign(__assign({}, state), (_d = {}, _d[serviceId] = {}, _d));
    }
    else if (action.type === 'clearAll') {
        var newState_1 = JSON.parse(JSON.stringify(state));
        Object.entries(state).forEach(function (_a) {
            var _b = __read(_a, 2), serviceId = _b[0], passengerCounters = _b[1];
            Object.entries(passengerCounters).forEach(function (_a) {
                var _b = __read(_a, 2), passengerId = _b[0], segmentsCount = _b[1];
                Object.entries(segmentsCount).forEach(function (_a) {
                    var _b;
                    var _c = __read(_a, 2), segmentId = _c[0], count = _c[1];
                    var servicePaidInfo = (_b = action.travellersServices[passengerId][serviceId]) === null || _b === void 0 ? void 0 : _b.find(function (s) {
                        return s.segmentIds.includes(segmentId);
                    });
                    newState_1[serviceId][passengerId][segmentId] = (servicePaidInfo === null || servicePaidInfo === void 0 ? void 0 : servicePaidInfo.confirmedCount) || 0;
                });
            });
        });
        return newState_1;
    }
    else if (action.type === 'state') {
        return action.payload;
    }
    return state;
};
