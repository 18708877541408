import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { getPassengerName } from '../../../../utils';
import { Select } from '../Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '../../../../theme';
export var LinkedTraveller = function (props) {
    var t = useTranslation('Passenger').t;
    var formState = useFormState();
    var css = useTheme('Passenger').Select;
    var options = props.options.map(function (option) {
        return {
            key: option.label,
            value: option.value
        };
    });
    var valueRenderer = React.useCallback(function (value) {
        var _a, _b;
        var passenger = formState.values.passengers[value];
        var name = "".concat(t('Adult'), " ").concat(parseInt(value, 10) + 1);
        if (((_a = passenger.firstName) === null || _a === void 0 ? void 0 : _a.trim()) && ((_b = passenger.lastName) === null || _b === void 0 ? void 0 : _b.trim())) {
            name = getPassengerName(passenger.firstName, passenger.lastName);
        }
        return name;
    }, [props.value, formState.values.passengers]);
    return (React.createElement(Select, { renderValue: valueRenderer, renderOption: function (option) {
            return (React.createElement(MenuItem, { key: option.key, value: option.key, className: cn(css.option, props.optionClassName), classes: __assign({}, props.menuItemClasses) }, valueRenderer(option.value)));
        }, value: props.value, options: options, name: props.name, label: props.label, className: props.className, onChange: props.onChange, onBlur: props.onBlur, error: props.error, disabled: props.disabled, selectFirstOptionByDefault: true }));
};
