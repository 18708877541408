import { __assign, __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import Button from '../../Button';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import { Money } from '../../Money';
import { useRenderers } from '../../renderProps';
initI18n('AdditionalServiceWrapper');
var AdditionalServiceWrapper = function (_a) {
    var className = _a.className, textClassName = _a.textClassName, wrapperClassName = _a.wrapperClassName, contentClassName = _a.contentClassName, footerClassName = _a.footerClassName, children = _a.children, text = _a.text, isLoading = _a.isLoading, isDisabled = _a.isDisabled, isSelected = _a.isSelected, fromPrice = _a.fromPrice, totalPrice = _a.totalPrice, onClose = _a.onClose, onSuccess = _a.onSuccess, props = __rest(_a, ["className", "textClassName", "wrapperClassName", "contentClassName", "footerClassName", "children", "text", "isLoading", "isDisabled", "isSelected", "fromPrice", "totalPrice", "onClose", "onSuccess"]);
    var css = useTheme('AdditionalServiceWrapper').AdditionalServiceWrapper;
    var t = useTranslation('Checkout').t;
    var renderAdditionalServiceCloseButton = useRenderers().renderAdditionalServiceCloseButton;
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement(Header, __assign({}, props, { onClose: onClose, renderCloseButton: renderAdditionalServiceCloseButton })),
        React.createElement("div", { className: cn(contentClassName, css.content) },
            children,
            React.createElement("div", { className: css.footer }, isSelected ? (React.createElement(React.Fragment, null,
                React.createElement(Button, { className: cn(css.button, css.button_change), variant: 'text', onClick: onSuccess }, t('Change')),
                totalPrice && (React.createElement("span", { className: css.total },
                    t('Total'),
                    React.createElement(Money, { moneyClassName: css.total_money, money: totalPrice }))))) : (React.createElement(React.Fragment, null,
                fromPrice && (React.createElement("span", { className: css.from },
                    t('from'),
                    React.createElement(Money, { money: fromPrice }))),
                React.createElement(Button, { className: css.button, onClick: onSuccess }, t('Add'))))))));
};
export default AdditionalServiceWrapper;
