import { __assign, __read, __spreadArray } from "tslib";
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../__generated__/globalTypes';
import { merge } from 'lodash'; // TODO: add treeshake support. https://stackoverflow.com/questions/58741044/why-webpack-doesnt-tree-shake-the-lodash-when-using-import-as
var collectServices = function (servicesSegmentIds, traveller, segmentsCount, type, travellerId, additonalServicesIds, collectType) {
    var servicesSegmentsMap = new Map();
    var travellerAllSegmentsMap = new Map();
    var result = {};
    var isAdditional = collectType === 'additional';
    servicesSegmentIds.forEach(function (segmentId) {
        var _a, _b, _c;
        var _d;
        var travellerSegments = [];
        var travellerServices = isAdditional
            ? traveller.services.gdsServices.services
            : traveller.services.brandIncludedServices.services;
        var servicesByType = travellerServices.filter(function (service) {
            if (isAdditional) {
                return additonalServicesIds.includes(service.serviceId);
            }
            return (service.service.type === type);
        });
        servicesByType.forEach(function (service) {
            var _a;
            var key = isAdditional ? service.serviceId : (_a = service === null || service === void 0 ? void 0 : service.service) === null || _a === void 0 ? void 0 : _a.rfisc;
            servicesSegmentsMap.set(key, new Set(__spreadArray(__spreadArray([], __read((servicesSegmentsMap.get(key) || [])), false), __read(service.segmentIds), false)));
        });
        servicesByType.forEach(function (service) {
            var _a;
            var key = isAdditional ? service.serviceId : (_a = service === null || service === void 0 ? void 0 : service.service) === null || _a === void 0 ? void 0 : _a.rfisc;
            var isAllSegments = servicesSegmentsMap.get(key).size === segmentsCount;
            var checkoutService = service;
            if (isAllSegments) {
                travellerAllSegmentsMap.set(key, __spreadArray(__spreadArray([], __read((travellerAllSegmentsMap.get(key) || [])), false), [checkoutService], false));
            }
            else if (service.segmentIds.includes(segmentId)) {
                travellerSegments = __spreadArray(__spreadArray([], __read(travellerSegments), false), [checkoutService], false);
            }
        });
        result[travellerId] = __assign(__assign({}, result[travellerId]), (_a = {}, _a[segmentId] = (_b = {},
            _b[collectType] = travellerSegments,
            _b), _a.allSegments = __assign(__assign({}, (_d = result[travellerId]) === null || _d === void 0 ? void 0 : _d.allSegments), (_c = {}, _c[collectType] = __spreadArray([], __read(travellerAllSegmentsMap.values()), false).map(function (services) { return services[0]; }), _c)), _a));
    });
    return result;
};
export var groupServices = function (additionalServices, traveller, travellerId, type, flightSegments) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var additonalServicesIds = (_b = (_a = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.map(function (service) {
        if (service.type === type) {
            return service.id;
        }
        return null;
    }).filter(Boolean);
    var addionalServicesSegmentIds = new Set();
    (_e = (_d = (_c = traveller.services) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.forEach(function (service) {
        service.segmentIds.forEach(function (id) { return addionalServicesSegmentIds.add(id); });
    });
    var includedServicesSegmentIds = new Set();
    (_h = (_g = (_f = traveller.services) === null || _f === void 0 ? void 0 : _f.brandIncludedServices) === null || _g === void 0 ? void 0 : _g.services) === null || _h === void 0 ? void 0 : _h.forEach(function (service) {
        service.segmentIds.forEach(function (id) { return includedServicesSegmentIds.add(id); });
    });
    var result = {};
    var additional = collectServices(addionalServicesSegmentIds, traveller, flightSegments.length, type, travellerId, additonalServicesIds, 'additional');
    result = __assign(__assign({}, result), additional);
    var included = collectServices(includedServicesSegmentIds, traveller, flightSegments.length, type, travellerId, [], 'included');
    return merge(result, included);
};
export var groupSeats = function (traveller, travellerId) {
    var _a, _b;
    var _c, _d, _e, _f, _g;
    var additionalSeats = (_a = {},
        _a[travellerId] = {},
        _a);
    var segmentAdditionalSeatsMap = new Map();
    (_d = (_c = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _c === void 0 ? void 0 : _c.seats) === null || _d === void 0 ? void 0 : _d.forEach(function (seat) {
        var item = segmentAdditionalSeatsMap.get(seat.segment.id);
        if (item) {
            segmentAdditionalSeatsMap.set(seat.segment.id, __spreadArray(__spreadArray([], __read(item), false), [seat], false));
        }
        else {
            segmentAdditionalSeatsMap.set(seat.segment.id, [seat]);
        }
    });
    // additional seats
    (_f = (_e = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _e === void 0 ? void 0 : _e.seats) === null || _f === void 0 ? void 0 : _f.forEach(function (seat) {
        var _a;
        additionalSeats[travellerId] = __assign(__assign({}, additionalSeats[travellerId]), (_a = {}, _a[seat.segment.id] = {
            additional: segmentAdditionalSeatsMap.get(seat.segment.id) || [],
            included: []
        }, _a));
    });
    var includedServicesSegmentIds = new Set();
    (_g = traveller.services.brandIncludedServices) === null || _g === void 0 ? void 0 : _g.services.filter(function (service) { return service.service.type === OrderAdditionalServiceGdsServiceServiceType.Seat; }).forEach(function (service) {
        service.segmentIds.forEach(function (id) { return includedServicesSegmentIds.add(id); });
    });
    var includedSeats = (_b = {},
        _b[travellerId] = {},
        _b);
    // included seats
    includedServicesSegmentIds.forEach(function (segmentId) {
        var _a;
        var travellerIncludedSegments = [];
        traveller.services.brandIncludedServices.services
            .filter(function (service) { return service.service.type === OrderAdditionalServiceGdsServiceServiceType.Seat; })
            .forEach(function (service) {
            travellerIncludedSegments.push(service);
        });
        includedSeats[travellerId] = __assign(__assign({}, includedSeats[travellerId]), (_a = {}, _a[segmentId] = __assign(__assign({}, includedSeats[travellerId][segmentId]), { included: travellerIncludedSegments }), _a));
    });
    return merge(includedSeats, additionalSeats);
};
