import { createSelector } from 'reselect';
import { ExareStatus } from '../../../../__generated__/graphql';
export var getOrder = function (state) { return state.order; };
export var getPassengers = createSelector([getOrder], function (order) { return (order ? order.travellers : []); });
export var getSegments = createSelector([getOrder], function (order) { return ((order === null || order === void 0 ? void 0 : order.flight) ? order.flight.segmentGroups : []); });
export var getLastExchange = createSelector([getOrder], function (order) { var _a; return (_a = order === null || order === void 0 ? void 0 : order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange; });
export var isOpenedExchange = createSelector([getLastExchange], function (order) {
    if (order &&
        order.status !== ExareStatus.Refused &&
        order.status !== ExareStatus.Rejected &&
        order.status !== ExareStatus.Finished) {
        return true;
    }
    return false;
});
export var isAutoMode = createSelector([getOrder], function (order) {
    return order === null || order === void 0 ? void 0 : order.exareInfo.exchangeAutoMode;
});
export var getCustomerData = createSelector([getOrder], function (order) {
    var values = order === null || order === void 0 ? void 0 : order.customer.values;
    var firstName = values.find(function (value) { return value.name === 'firstName'; }).value, lastName = values.find(function (value) { return value.name === 'lastName'; }).value;
    if (!values) {
        return null;
    }
    return {
        name: firstName ? "".concat(firstName, " ").concat(lastName) : '',
        phone: values.find(function (value) { return value.name === 'phone'; }).value,
        email: values.find(function (value) { return value.name === 'email'; }).value
    };
});
