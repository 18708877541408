import memes from '../../../memes';
export var getRowService = memes(function (seats) {
    return seats[0].isExistent && !seats[0].isAisle && seats[0].seatService;
});
export var nextRowHasOtherServiceClass = memes(function (row, nextRow) {
    if (!nextRow) {
        return false;
    }
    if (row.parts[0].service.seatService === null && nextRow.parts[0].service.seatService === null) {
        return false;
    }
    if (!row.parts[0].service.seatService || !nextRow.parts[0].service.seatService) {
        return true;
    }
    return (row.parts[0].seats[0].rfisc !== nextRow.parts[0].seats[0].rfisc ||
        row.parts[0].service.seatService.comfort !== nextRow.parts[0].service.seatService.comfort);
});
