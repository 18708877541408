import { __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SwipableViews from 'react-swipeable-views';
import { useQuery } from '@apollo/react-hooks';
import cn from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { initI18n } from '../../utils';
import { Button } from '../../index';
import { ThemeProvider, useTheme } from '../../theme';
import * as FareRulesQuery from '../../__queries__/FareRules.graphql';
import { useMemo } from 'react';
import FareCode from './FareCode/FareCode';
import SegmentTabs from '../../SegmentTabs/SegmentTabs';
import { Locale } from '../../types';
import { useIsFullScreenFareModal } from '../../FlightSelect/hooks';
initI18n('FareConditions');
initI18n('MobileStepbar');
var FareConditions = function (_a) {
    var _b, _c;
    var _d, _e;
    var onLangChange = _a.onLangChange, flightId = _a.flightId, orderId = _a.orderId, language = _a.language, showTranslateTab = _a.showTranslateTab, segmentId = _a.segmentId;
    var css = useTheme('FareConditions').FareConditions;
    var t = useTranslation('FareConditions').t;
    var _f = __read(React.useState(Locale.Russian), 2), selectedLang = _f[0], selectLang = _f[1];
    var _g = __read(React.useState(segmentId ? segmentId : ''), 2), segment = _g[0], setSegment = _g[1];
    var firstPassangerInList = 0;
    var isFullScreenFareModal = useIsFullScreenFareModal();
    var _h = useQuery(FareRulesQuery.FareRules, {
        variables: { flightId: flightId !== null && flightId !== void 0 ? flightId : orderId, orderId: orderId, language: language }
    }), data = _h.data, loading = _h.loading, error = _h.error, refetch = _h.refetch;
    if (error) {
        console.log(error);
    }
    var fareRulesBySegmentsPassengersMap = {}, hasErrors = error || (!loading && !data.FareRules);
    if (!loading && !hasErrors) {
        data.FareRules.rulesForSegments.forEach(function (ruleForSegment) {
            ruleForSegment.segments.forEach(function (segment) {
                if (fareRulesBySegmentsPassengersMap.hasOwnProperty(segment.id)) {
                    fareRulesBySegmentsPassengersMap[segment.id].push(ruleForSegment);
                }
                else {
                    fareRulesBySegmentsPassengersMap[segment.id] = [ruleForSegment];
                }
            });
        });
        if (segment === '') {
            // select first available segment if there is default value
            setSegment(Object.keys(fareRulesBySegmentsPassengersMap)[0]);
        }
    }
    var handleLangChange = function (lang) {
        if (lang === selectedLang) {
            return;
        }
        onLangChange(lang);
        selectLang(lang);
    };
    var fareRulesSuccessfullyParsed = useMemo(function () { return Object.keys(fareRulesBySegmentsPassengersMap).length > 0; }, [
        fareRulesBySegmentsPassengersMap
    ]);
    var segmentTabTheme = useMemo(function () { return ({
        SegmentTabs: {
            SegmentTabs: {
                wrapper: css.segmentTabsWrapper,
                title: css.segmentTabsTitle,
                segments: css.segmentTabsSegments,
                inner: css.segmentTabsInner
            },
            SegmentTab: { segment_active: css.segmentTabsTab__active }
        }
    }); }, []);
    var tabsSegments = useMemo(function () {
        return Object.keys(fareRulesBySegmentsPassengersMap).map(function (fareRuleKey) {
            var fareRule = fareRulesBySegmentsPassengersMap[fareRuleKey];
            var segment = fareRule[firstPassangerInList].segments.filter(function (segment) { return segment.id === fareRuleKey; })[0];
            return {
                id: segment.id,
                title: "".concat(segment.departure.airport.city.name, " \u2014 ").concat(segment.arrival.airport.city.name),
                isDisabled: false
            };
        });
    }, [fareRulesBySegmentsPassengersMap]);
    return (React.createElement(Paper, { className: cn(css.paperRoot, (_b = {}, _b[css.paperRoot_full] = isFullScreenFareModal, _b)) },
        React.createElement("header", null,
            !loading && !hasErrors && (React.createElement(ThemeProvider, { value: segmentTabTheme },
                React.createElement(SegmentTabs, { position: 'left', title: t('Fare conditions'), segments: tabsSegments, isCompact: false, scrollToActive: true, sticky: true, onSegmentClick: function (segmentId) {
                        setSegment(segmentId);
                    }, activeSegment: segment, classNameTab: css.segmentTabsTab, isOnlyTitle: !fareRulesSuccessfullyParsed }))),
            React.createElement("div", { className: cn(css.tabsWrp, (_c = {}, _c[css.tabsWrp_error] = hasErrors, _c)) }, !loading && !hasErrors && showTranslateTab && (React.createElement(Tabs, { value: selectedLang, classes: {
                    root: css.translateTabs,
                    flexContainer: css.translateTabsContainer,
                    indicator: cn(css.tabIndicator, css.translateTabIndicator)
                } },
                React.createElement(Tab, { classes: {
                        root: cn(css.tab, css.translateTab),
                        selected: cn(css.tab_active, css.translateTab_selected)
                    }, onClick: function () { return handleLangChange(Locale.English); }, label: t('Original') }),
                React.createElement(Tab, { classes: {
                        root: cn(css.tab, css.translateTab),
                        selected: cn(css.tab_active, css.translateTab_selected)
                    }, onClick: function () { return handleLangChange(Locale.Russian); }, label: t('Translate to russian*') }))))),
        !loading && !hasErrors && (React.createElement(React.Fragment, null,
            React.createElement(SwipableViews, { index: Object.keys(fareRulesBySegmentsPassengersMap).indexOf(segment), onChangeIndex: function (index) {
                    setSegment(Object.keys(fareRulesBySegmentsPassengersMap)[index]);
                } }, Object.keys(fareRulesBySegmentsPassengersMap).map(function (key) { return (React.createElement("div", { key: key, className: css.fareConditionsContent },
                React.createElement("div", null,
                    React.createElement("pre", null, segment && (React.createElement(FareCode, { passengersRules: fareRulesBySegmentsPassengersMap[segment] }))),
                    segment &&
                        fareRulesBySegmentsPassengersMap[segment][firstPassangerInList].rules.map(function (rule) { return React.createElement("pre", { dangerouslySetInnerHTML: { __html: rule.text } }); })))); })),
            !fareRulesSuccessfullyParsed && (React.createElement("div", { className: css.fareConditionsContent },
                React.createElement("div", null, (_e = (_d = data === null || data === void 0 ? void 0 : data.FareRules) === null || _d === void 0 ? void 0 : _d.rulesForSegments) === null || _e === void 0 ? void 0 : _e.map(function (rule) {
                    return rule.rules.map(function (rule) { return React.createElement("pre", { dangerouslySetInnerHTML: { __html: rule.text } }); });
                })))))),
        loading && (React.createElement("div", { className: css.loaderWrp },
            React.createElement(CircularProgress, { classes: { colorPrimary: css.loader } }))),
        hasErrors && (React.createElement("div", { className: css.errorWrp },
            React.createElement("h6", { className: css.errorTitle }, t('Internal error')),
            error && React.createElement("p", { className: css.errorText }, error.message),
            React.createElement(Button, { size: "small", color: "primary", onClick: function () { return refetch({ flightId: flightId, language: language }); } }, t('Try again'))))));
};
export default FareConditions;
