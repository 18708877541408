export var ConsoleType;
(function (ConsoleType) {
    ConsoleType["SEARCH"] = "Search";
    ConsoleType["PASSENGERS"] = "Passengers";
})(ConsoleType || (ConsoleType = {}));
export var TravellerFieldEnumPI;
(function (TravellerFieldEnumPI) {
    TravellerFieldEnumPI["PassengerIndex"] = "PassengerIndex";
})(TravellerFieldEnumPI || (TravellerFieldEnumPI = {}));
export var TravellerFieldAdditionalTypes;
(function (TravellerFieldAdditionalTypes) {
    TravellerFieldAdditionalTypes["PassengerType"] = "PassengerType";
    TravellerFieldAdditionalTypes["PassengerTypeCode"] = "PassengerTypeCode";
    TravellerFieldAdditionalTypes["PrivilegeDocument"] = "PrivilegeDocument";
})(TravellerFieldAdditionalTypes || (TravellerFieldAdditionalTypes = {}));
