import { __assign, __read } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Field } from 'react-final-form';
import cn from 'classnames';
import { useFormState } from 'react-final-form';
import { useTheme } from '../../../../theme';
import { currency } from '../../__fakeData__';
import { minusIcon, plusIcon } from '../../icons';
import { NumberField, NumberStringField, StringField } from '../Fields/Fields';
var EditTaxes = function (props) {
    var _a, _b;
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    var values = useFormState().values;
    var addTax = useCallback(function (tax) {
        props.mutators.addTax(tax);
    }, [props.mutators]);
    var removeTax = useCallback(function (tax, index) {
        props.mutators.removeTax(tax, index);
    }, [props.mutators]);
    var renderAirlineTaxes = useCallback(function () {
        var _a;
        return (_a = Object.entries(values === null || values === void 0 ? void 0 : values.airlineTaxes)) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
            var _b = __read(_a, 2), index = _b[0], tax = _b[1];
            return (React.createElement("tr", { key: "".concat(tax.code, "_").concat(index, "_").concat(tax.amount), className: theme.table_bg_gray },
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(StringField, { name: "airlineTaxes[".concat(index, "].code"), showErrorInTooltip: true })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(NumberField, { name: "airlineTaxes[".concat(index, "].amount"), showErrorInTooltip: true })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit, theme.selectBackground) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(Field, { name: "airlineTaxes[".concat(index, "].status"), render: function (_a) {
                                    var input = _a.input;
                                    return (React.createElement(FormControl, { className: theme.formSelect_table },
                                        React.createElement(Select, __assign({}, input, { disableUnderline: true, classes: {
                                                root: cn(theme.formSelect_table, theme.formSelect)
                                            } }),
                                            React.createElement(MenuItem, { key: 'ref', value: 'ref' }, t('Ref')),
                                            React.createElement(MenuItem, { key: 'nonRef', value: 'nonRef' }, t('Non ref')),
                                            React.createElement(MenuItem, { key: 'new', value: 'new' }, t('New')))));
                                } })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_align_center) },
                        React.createElement("button", { onClick: removeTax.bind(null, 'airlineTaxes', parseInt(index, 10)), type: "button", className: theme.table__button }, minusIcon)))));
        });
    }, [(_a = Object.keys(values === null || values === void 0 ? void 0 : values.airlineTaxes)) === null || _a === void 0 ? void 0 : _a.length]);
    var renderAgencyTaxes = useCallback(function () {
        return Object.entries(values === null || values === void 0 ? void 0 : values.agencyTaxes).map(function (_a, index) {
            var _b = __read(_a, 2), _ = _b[0], tax = _b[1];
            return (React.createElement("tr", { key: "".concat(tax.code, "_").concat(index, "_").concat(tax.amount), className: theme.table_bg_gray },
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(NumberStringField, { name: "agencyTaxes[".concat(index, "].code"), showErrorInTooltip: true })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(NumberField, { name: "agencyTaxes[".concat(index, "].amount"), showErrorInTooltip: true })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_align_center) },
                        React.createElement("button", { onClick: removeTax.bind(null, 'agencyTaxes', index), type: "button", className: theme.table__button }, minusIcon)))));
        });
    }, [(_b = Object.keys(values === null || values === void 0 ? void 0 : values.agencyTaxes)) === null || _b === void 0 ? void 0 : _b.length]);
    return (React.createElement("div", { className: theme.taxes },
        React.createElement("div", { className: theme.taxes__table },
            React.createElement("div", { className: cn(theme.sectionBlock__headlineInner) },
                t('Airline taxes & fees'),
                " (",
                currency,
                ")"),
            React.createElement("table", { className: cn(theme.table, theme.table_border_full, theme.table_border_blue) },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null,
                            React.createElement("div", { className: theme.table__cell }, t('Code'))),
                        React.createElement("th", null,
                            React.createElement("div", { className: theme.table__cell }, t('Amount'))),
                        React.createElement("th", null,
                            React.createElement("div", { className: theme.table__cell }, t('Status'))),
                        React.createElement("th", null,
                            React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_align_center) },
                                React.createElement("button", { onClick: addTax.bind(null, 'airlineTaxes'), type: "button", className: theme.table__button }, plusIcon))))),
                React.createElement("tbody", null, renderAirlineTaxes()))),
        React.createElement("div", { className: theme.taxes__table },
            React.createElement("div", { className: theme.sectionBlock__headlineInner },
                t('Agency taxes'),
                " (",
                currency,
                ")"),
            React.createElement("table", { className: cn(theme.table, theme.table_border_full, theme.table_border_blue) },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null,
                            React.createElement("div", { className: theme.table__cell }, t('Code'))),
                        React.createElement("th", null,
                            React.createElement("div", { className: theme.table__cell }, t('Amount'))),
                        React.createElement("th", null,
                            React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_align_center) },
                                React.createElement("button", { onClick: addTax.bind(null, 'agencyTaxes'), type: "button", className: theme.table__button }, plusIcon))))),
                React.createElement("tbody", null, renderAgencyTaxes())))));
};
export default EditTaxes;
