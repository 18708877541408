import { __assign, __read } from "tslib";
import * as React from 'react';
import { Switch } from '../index';
import { useTheme } from '../theme';
import { ActionButton } from '../Button';
import SimpleLoader from '../SimpleLoader';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../utils';
import { computeServicesMap, getAvailableSegments, getNewSelectedServices } from './utils';
var serviceReducer = function (state, action) {
    var _a, _b, _c, _d;
    if (action.type === 'add') {
        return __assign(__assign({}, state), (_a = {}, _a[action.segmentId] = __assign(__assign({}, state[action.segmentId]), (_b = {}, _b[action.passengerId] = true, _b)), _a));
    }
    else if (action.type === 'remove') {
        return __assign(__assign({}, state), (_c = {}, _c[action.segmentId] = __assign(__assign({}, state[action.segmentId]), (_d = {}, _d[action.passengerId] = false, _d)), _c));
    }
    return state;
};
initI18n('VipServiceSelect');
var VipServiceSelect = function (_a) {
    var passengers = _a.passengers, services = _a.services, segments = _a.segments, header = _a.header, loading = _a.loading, onSave = _a.onSave, selectedServices = _a.selectedServices, onClose = _a.onClose, subheader = _a.subheader;
    var initialReducer = function (selectedServices) {
        var state = {};
        passengers.forEach(function (passenger) {
            services.forEach(function (service) {
                if (selectedServices[passenger.id] && selectedServices[passenger.id][service.id]) {
                    selectedServices[passenger.id][service.id].forEach(function (service) {
                        service.segments.forEach(function (segment) {
                            var _a, _b;
                            state = __assign(__assign({}, state), (_a = {}, _a[segment] = __assign(__assign({}, state[segment]), (_b = {}, _b[passenger.id] = service.number > 0, _b)), _a));
                        });
                    });
                }
            });
        });
        return state;
    };
    var _b = __read(React.useReducer(serviceReducer, selectedServices, initialReducer), 2), state = _b[0], dispatch = _b[1];
    var _c = __read(React.useState(false), 2), differentSegment = _c[0], setDifferentSegment = _c[1];
    var differentSegmentToggle = function () { return setDifferentSegment(!differentSegment); };
    var theme = useTheme('VipServiceSelect').VipServiceSelect;
    var servicesMap = computeServicesMap(services, selectedServices);
    var availableSegments = getAvailableSegments(segments, passengers, servicesMap);
    var onSaveHandler = function () {
        return onSave(getNewSelectedServices(servicesMap, state, !differentSegment));
    };
    var renderPassenger = function (passenger) {
        var _a, _b, _c;
        var isSelected = (_a = state[passenger.segmentId]) === null || _a === void 0 ? void 0 : _a[passenger.passengerId];
        var isConfirmed = !!((_c = (_b = selectedServices === null || selectedServices === void 0 ? void 0 : selectedServices[passenger.passengerId]) === null || _b === void 0 ? void 0 : _b[passenger.serviceId]) === null || _c === void 0 ? void 0 : _c.find(function (selectedVip) {
            var _a;
            return (selectedVip === null || selectedVip === void 0 ? void 0 : selectedVip.segments.includes(passenger.segmentId)) &&
                ((_a = selectedVip.servicePayload) === null || _a === void 0 ? void 0 : _a.confirmedCount) > 0;
        }));
        var onClickHandler = function () {
            dispatch({
                type: isSelected ? 'remove' : 'add',
                passengerId: passenger.passengerId,
                segmentId: passenger.segmentId
            });
        };
        return (React.createElement(Switch, { isDisabled: isConfirmed, switchPosition: "right", key: passenger.passengerId, mainClassName: theme.switch__container, activeClassName: theme.switch__container_active, labelClassName: theme.label, label: React.createElement("div", { className: theme.passenger },
                React.createElement("div", { className: theme.number }, parseInt(passenger.passengerId) + 1),
                passenger.name), isActive: isSelected, onClick: onClickHandler }));
    };
    var t = useTranslation('VipServiceSelect').t;
    return (React.createElement("div", { className: theme.container },
        header && React.createElement("div", { className: theme.header }, header),
        subheader && React.createElement("div", { className: theme.subheader }, subheader),
        !differentSegment && React.createElement("div", null, availableSegments[0].passengers.map(renderPassenger)),
        differentSegment &&
            availableSegments.map(function (segment) {
                return (React.createElement("div", { key: segment.segmentId },
                    React.createElement("div", { className: theme.direction }, segment.direction),
                    segment.passengers.map(renderPassenger)));
            }),
        availableSegments.length > 1 && (React.createElement("div", null,
            React.createElement(Switch, { switchPosition: "right", label: t('Different for other flight?'), labelClassName: theme.onlySegment, isActive: differentSegment, mainClassName: theme.onlySegment__switch, activeClassName: theme.switch__container_active, onClick: differentSegmentToggle }))),
        loading && React.createElement(SimpleLoader, null),
        React.createElement("div", { className: theme.controls },
            React.createElement(ActionButton, { size: "small", className: theme.cancel, onClick: onClose }, t('Cancel')),
            React.createElement(ActionButton, { onClick: onSaveHandler, size: "small", className: theme.confirm }, t('Confirm')))));
};
export default VipServiceSelect;
