import * as Loyalty from './components/Loyalty/Loyalty.css';
import * as voucher from './components/Voucher/Voucher.css';
import * as LoyaltyStub from './components/LoyaltyStub/LoyaltyStub.css';
import * as LoyaltyCard from './components/Loyalty/LoyaltyCard/LoyaltyCard.css';
import * as LoyaltyConnect from './components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect.css';
import * as ForgotPassword from './components/LoyaltyStub/LoyaltyConnect/ForgotPassword/ForgotPassword.css';
export default {
    Loyalty: Loyalty,
    LoyaltyStub: LoyaltyStub,
    voucher: voucher,
    LoyaltyCard: LoyaltyCard,
    LoyaltyConnect: LoyaltyConnect,
    ForgotPassword: ForgotPassword
};
