import { __assign } from "tslib";
import * as React from 'react';
import { Switch, Route as RouteComponent } from 'react-router-dom';
import Profile from './Profile/Profile';
import Settings from './Settings/Settings';
import Login from './Login/Login';
import Logout from './Logout/Logout';
var accountRoutes = [
    {
        path: '/login',
        exact: true,
        component: Login
    },
    {
        path: '/account/profile',
        exact: true,
        component: Profile
    },
    {
        path: '/account/settings',
        exact: true,
        component: Settings
    },
    {
        path: '/account/logout',
        exact: true,
        component: Logout
    }
];
var Account = function (props) {
    return (React.createElement(Switch, null, accountRoutes.map(function (route, index) { return (React.createElement(RouteComponent, __assign({}, route, { key: index }))); })));
};
export default Account;
