import * as React from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
var icon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.4993 7.5H15.8327L9.99935 13.3333L4.16602 7.5H7.49935V2.5H12.4993V7.5ZM4.16602 16.6667V15H15.8327V16.6667H4.16602Z", fill: "#B3B3B3" })));
var DownloadInsurances = function (_a) {
    var selectedInsurances = _a.selectedInsurances;
    var theme = useTheme('AdditionalServices').DownloadInsurances;
    var t = useTranslation('Checkout').t;
    return (React.createElement("div", { className: theme.wrapper }, selectedInsurances
        .filter(function (insurance) { return !!insurance.policyUrl; })
        .map(function (insurance) { return (React.createElement("div", { className: theme.item },
        React.createElement("div", null, insurance.passengerName),
        React.createElement("a", { href: insurance.policyUrl, target: "_blank" },
            t('Download'),
            icon))); })));
};
export default DownloadInsurances;
