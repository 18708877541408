// sort ordering: selected services (price from min to max) then not selected services (price from min to max)
export var sortBySelectedByPrice = function (firstItem, secondItem) {
    if (firstItem.selected === false && secondItem.selected === true) {
        return 1;
    }
    else if (firstItem.selected === secondItem.selected) {
        if (firstItem.priceFrom.amount < secondItem.priceFrom.amount) {
            return -1;
        }
        else {
            return 1;
        }
    }
    else {
        return -1;
    }
};
