import { __assign, __read } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import AdditionalServiceWrapper from '../../AdditionalServiceWrapper';
import { useTheme } from '../../theme';
import Money from '../../Money/components/Money';
import { computeServicesMap, getAvailableSegments, getNewSelectedServices } from '../../VipServiceSelect/utils';
var serviceReducer = function (state, action) {
    var _a, _b, _c, _d;
    if (action.type === 'add') {
        return __assign(__assign({}, state), (_a = {}, _a[action.segmentId] = __assign(__assign({}, state[action.segmentId]), (_b = {}, _b[action.passengerId] = true, _b)), _a));
    }
    else if (action.type === 'remove') {
        return __assign(__assign({}, state), (_c = {}, _c[action.segmentId] = __assign(__assign({}, state[action.segmentId]), (_d = {}, _d[action.passengerId] = false, _d)), _c));
    }
    return state;
};
var CapsuleHotelPopup = function (_a) {
    var className = _a.className, description = _a.description, price = _a.price, slides = _a.slides, passengers = _a.passengers, services = _a.services, segments = _a.segments, selectedServices = _a.selectedServices, onClose = _a.onClose, onSave = _a.onSave;
    var css = useTheme('CapsuleHotelPopup').CapsuleHotelPopup;
    var t = useTranslation('Checkout').t;
    var initialReducer = function (selectedServices) {
        var state = {};
        passengers.forEach(function (passenger) {
            services.forEach(function (service) {
                if (selectedServices[passenger.id] && selectedServices[passenger.id][service.id]) {
                    selectedServices[passenger.id][service.id].forEach(function (service) {
                        service.segments.forEach(function (segment) {
                            var _a, _b;
                            state = __assign(__assign({}, state), (_a = {}, _a[segment] = __assign(__assign({}, state[segment]), (_b = {}, _b[passenger.id] = service.number > 0, _b)), _a));
                        });
                    });
                }
            });
        });
        return state;
    };
    var servicesMap = computeServicesMap(services, selectedServices);
    var availableSegments = getAvailableSegments(segments, passengers, servicesMap);
    var _b = __read(React.useReducer(serviceReducer, selectedServices, initialReducer), 2), state = _b[0], dispatch = _b[1];
    var totalPrice = useMemo(function () {
        var servicesIds = [];
        availableSegments[0].passengers.forEach(function (_a) {
            var _b;
            var passengerId = _a.passengerId, segmentId = _a.segmentId, serviceId = _a.serviceId;
            if ((_b = state[segmentId]) === null || _b === void 0 ? void 0 : _b[passengerId]) {
                servicesIds.push(serviceId);
            }
        });
        return {
            currency: services === null || services === void 0 ? void 0 : services[0].price.currency,
            amount: servicesIds.reduce(function (total, serviceId) { var _a; return total + (((_a = services.find(function (_a) {
                var id = _a.id;
                return serviceId === id;
            })) === null || _a === void 0 ? void 0 : _a.price.amount) || 0); }, 0)
        };
    }, [services, availableSegments]);
    var onSaveHandler = function () { return onSave(getNewSelectedServices(servicesMap, state, true)); };
    var sliderOptions = {
        className: css.slider,
        slidesToShow: 1,
        speed: 600,
        arrows: false,
        infinite: false,
        dots: true,
        dotsClass: css.dots
    };
    return (React.createElement(AdditionalServiceWrapper, { className: className, headerClassName: css.header, textClassName: css.text, title: t('Capsule hotel'), info: price && (React.createElement(React.Fragment, null,
            t('from'),
            React.createElement(Money, { money: price }))), text: React.createElement(React.Fragment, null,
            slides.length > 0 && (React.createElement(Slider, __assign({}, sliderOptions), slides.map(function (image, index) { return (React.createElement("div", { className: css.slide, key: index },
                React.createElement("img", { src: image, alt: "" }))); }))),
            description), onClose: onClose, onSuccess: onSaveHandler },
        availableSegments[0].passengers.map(function (passenger) {
            var _a, _b, _c, _d;
            var isSelected = (_a = state[passenger.segmentId]) === null || _a === void 0 ? void 0 : _a[passenger.passengerId];
            var servicePrice = (_b = services.find(function (_a) {
                var id = _a.id;
                return id === passenger.serviceId;
            })) === null || _b === void 0 ? void 0 : _b.price;
            var isConfirmed = !!((_d = (_c = selectedServices === null || selectedServices === void 0 ? void 0 : selectedServices[passenger.passengerId]) === null || _c === void 0 ? void 0 : _c[passenger.serviceId]) === null || _d === void 0 ? void 0 : _d.find(function (selectedVip) {
                var _a;
                return (selectedVip === null || selectedVip === void 0 ? void 0 : selectedVip.segments.includes(passenger.segmentId)) &&
                    ((_a = selectedVip.servicePayload) === null || _a === void 0 ? void 0 : _a.confirmedCount) > 0;
            }));
            var onClick = function () {
                dispatch({
                    type: isSelected ? 'remove' : 'add',
                    passengerId: passenger.passengerId,
                    segmentId: passenger.segmentId
                });
            };
            return (React.createElement("div", { className: css.passenger, key: passenger.passengerId },
                React.createElement(FormControlLabel, { value: 'end', label: passenger.name, classes: {
                        label: css.checkbox_label
                    }, control: React.createElement(Checkbox, { disabled: isConfirmed, classes: {
                            root: css.checkbox,
                            checked: css.checkbox_checked
                        }, disableRipple: true, checked: isSelected, onChange: onClick }) }),
                React.createElement("div", { className: css.passenger_price }, isSelected && React.createElement(Money, { money: servicePrice }))));
        }),
        React.createElement("div", { className: css.total },
            t('Total'),
            ":",
            React.createElement(Money, { money: totalPrice }))));
};
export default CapsuleHotelPopup;
