import * as CheckoutStyles from './components/Checkout/Checkout.css';
import * as PassengersStyles from './components/Checkout/Form/Passengers/Passengers.css';
import * as PriceChangedStyles from './components/Checkout/PriceChanged/PriceChanged.css';
import * as FlightNotAvailable from './components/Checkout/FlightNotAvailable/FlightNotAvailable.css';
import * as ActualizationStyles from './components/Checkout/Actualization/Actualization.css';
import * as OrderPaid from './components/Checkout/OrderPaid/OrderPaid.css';
import * as OrderPaidMobile from './components/Checkout/OrderPaid/components/OrderPaidMobile/OrderPaidMobile.css';
import * as Transaction from './components/Checkout/OrderPaid/components/Transaction/Transaction.css';
import * as ThanksBanner from './components/Checkout/ThanksBanner/ThanksBanner.css';
import * as ServicesCardsStyles from './components/Checkout/ServicesCards/ServicesCards.css';
import * as FindOrder from './components/Checkout/FindOrder/FindOrder.css';
import * as Upsale from './components/Checkout/Upsale/Upsale.css';
import * as Warning from './components/Checkout/Upsale/Warning/Warning.css';
import * as Subscribe from './components/Checkout/Upsale/Subscribe/Subscribe.css';
import * as Services from './components/Checkout/Form/Services/Services.css';
import * as PassengersToolbar from './components/Checkout/Form/PassengersToolbar/PassengersToolbar.css';
import * as ToggleableFlights from './components/Checkout/ToggleableFlights/ToggleableFlights.css';
import * as OrderPaidServiceCards from './components/Checkout/OrderPaidServiceCards/OrderPaidServiceCards.css';
import * as ServiceCardFooter from './components/Checkout/OrderPaidServiceCards/components/ServiceCardFooter/ServiceCardFooter.css';
import * as AdditionalOrder from './components/Checkout/AdditionalOrder/AdditionalOrder.css';
export default {
    CheckoutStyles: CheckoutStyles,
    PassengersStyles: PassengersStyles,
    PriceChangedStyles: PriceChangedStyles,
    ServicesCardsStyles: ServicesCardsStyles,
    FlightNotAvailable: FlightNotAvailable,
    ActualizationStyles: ActualizationStyles,
    OrderPaid: OrderPaid,
    OrderPaidMobile: OrderPaidMobile,
    Transaction: Transaction,
    ThanksBanner: ThanksBanner,
    FindOrder: FindOrder,
    Upsale: Upsale,
    Warning: Warning,
    Subscribe: Subscribe,
    Services: Services,
    PassengersToolbar: PassengersToolbar,
    ToggleableFlights: ToggleableFlights,
    OrderPaidServiceCards: OrderPaidServiceCards,
    ServiceCardFooter: ServiceCardFooter,
    AdditionalOrder: AdditionalOrder
};
