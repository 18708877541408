import { __assign } from "tslib";
import { createSelector } from 'reselect';
import { currentCurrency } from '../../../utils';
import { getSelectedServices } from '../selectedServices/selectors';
import { getBusinessLounge, getCapsuleHotel } from '../order/selectors';
var zeroPrice = {
    amount: 0,
    currency: currentCurrency
};
var calculatePrice = function (services, selectedServices) {
    var price = services.length > 0
        ? __assign({}, services[0].price) : null;
    var sum = 0;
    services.forEach(function (service) {
        selectedServices.forEach(function (selectedService) {
            var _a, _b;
            var serviceItem = (_b = (_a = selectedService === null || selectedService === void 0 ? void 0 : selectedService.gdsServices) === null || _a === void 0 ? void 0 : _a.setServices) === null || _b === void 0 ? void 0 : _b.find(function (s) { return s.serviceId === service.id; });
            if (serviceItem === null || serviceItem === void 0 ? void 0 : serviceItem.count) {
                sum += service.price.amount * serviceItem.count;
            }
        });
    });
    if (sum > 0) {
        price.amount = sum;
    }
    return price;
};
export var getState = function (state) { return state.orderPrice; };
export var getPrice = createSelector(getState, function (_a) {
    var price = _a.price;
    return price;
});
export var getMealsPrice = createSelector(getPrice, function (_a) {
    var meals = _a.meals;
    return meals !== null && meals !== void 0 ? meals : zeroPrice;
});
export var getSeatsPrice = createSelector(getPrice, function (_a) {
    var seats = _a.seats;
    return seats !== null && seats !== void 0 ? seats : zeroPrice;
});
export var getBaggagePrice = createSelector(getPrice, function (_a) {
    var baggage = _a.baggage;
    return baggage !== null && baggage !== void 0 ? baggage : zeroPrice;
});
export var getTotalPrice = createSelector(getPrice, function (_a) {
    var total = _a.total;
    return total !== null && total !== void 0 ? total : zeroPrice;
});
export var getAeroexpressPrice = createSelector(getPrice, function (_a) {
    var aeroexpress = _a.aeroexpress;
    return aeroexpress !== null && aeroexpress !== void 0 ? aeroexpress : zeroPrice;
});
export var getInsurancePrice = createSelector(getPrice, function (_a) {
    var insurance = _a.insurance;
    return insurance !== null && insurance !== void 0 ? insurance : zeroPrice;
});
export var getBusinessLoungePrice = createSelector(getPrice, function (_a) {
    var businessLounge = _a.businessLounge;
    return businessLounge !== null && businessLounge !== void 0 ? businessLounge : zeroPrice;
});
export var getCalculatedBusinessLoungePrice = createSelector(getBusinessLounge, getSelectedServices, calculatePrice);
export var getCalculatedCapsuleHotelPrice = createSelector(getCapsuleHotel, getSelectedServices, calculatePrice);
export var getPriceToPay = createSelector(getState, function (_a) {
    var priceToPay = _a.priceToPay;
    var priceToPayWithZeroPrice = {};
    Object.keys(priceToPay).forEach(function (key) {
        var _a;
        priceToPayWithZeroPrice[key] = (_a = priceToPay[key]) !== null && _a !== void 0 ? _a : zeroPrice;
    });
    return priceToPayWithZeroPrice;
});
export var getSeatsPriceToPay = createSelector(getPriceToPay, function (_a) {
    var seats = _a.seats;
    return seats !== null && seats !== void 0 ? seats : zeroPrice;
});
export var getChargePriceToPay = createSelector(getPriceToPay, function (_a) {
    var charge = _a.charge;
    return charge !== null && charge !== void 0 ? charge : zeroPrice;
});
export var getAeroexpressPriceToPay = createSelector(getPriceToPay, function (_a) {
    var aeroexpress = _a.aeroexpress;
    return aeroexpress !== null && aeroexpress !== void 0 ? aeroexpress : zeroPrice;
});
export var getInsurancePriceToPay = createSelector(getPriceToPay, function (_a) {
    var insurance = _a.insurance;
    return insurance !== null && insurance !== void 0 ? insurance : zeroPrice;
});
export var getBaggagePriceToPay = createSelector(getPriceToPay, function (_a) {
    var baggage = _a.baggage;
    return baggage !== null && baggage !== void 0 ? baggage : zeroPrice;
});
export var getMealsPriceToPay = createSelector(getPriceToPay, function (_a) {
    var meals = _a.meals;
    return meals !== null && meals !== void 0 ? meals : zeroPrice;
});
export var getAirticketPriceToPay = createSelector(getPriceToPay, function (_a) {
    var airticket = _a.airticket;
    return airticket !== null && airticket !== void 0 ? airticket : zeroPrice;
});
export var getTotalPriceToPay = createSelector(getPriceToPay, function (_a) {
    var total = _a.total;
    return total !== null && total !== void 0 ? total : zeroPrice;
});
