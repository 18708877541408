import * as React from 'react';
import { useTheme } from '../../theme';
var DummySummary = function () {
    var css = useTheme().DummySummary;
    return (React.createElement("div", { className: css.summary },
        React.createElement("div", { className: css.route }),
        React.createElement("div", { className: css.trigger },
            React.createElement("div", { className: css.trigger__inner }))));
};
export default DummySummary;
