import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useState, useMemo, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import AdditionalServiceWrapper from '../../AdditionalServiceWrapper';
import InsuranceCard from './InsuranceCard/InsuranceCard';
import { useTheme } from '../../theme';
import { Money } from '../../Money';
import { MOBILE_MIN_WIDTH } from '../../utils';
import { Chevron } from '../../Icons';
var initReducer = function (state) {
    return state;
};
var insuranceReducer = function (state, action) {
    if (action.type === 'add') {
        return __assign(__assign({}, state), { selectedInsurances: __spreadArray(__spreadArray([], __read(state.selectedInsurances), false), [
                action.payload
            ], false) });
    }
    else if (action.type === 'remove') {
        return __assign(__assign({}, state), { selectedInsurances: state.selectedInsurances.filter(function (insurance) {
                return insurance.code !==
                    action.payload.code;
            }) });
    }
    else if (action.type === 'reset') {
        return initReducer(action.payload);
    }
    else if (action.type === 'set_document') {
        var payload_1 = action.payload;
        return __assign(__assign({}, state), { passengers: state.passengers.map(function (passenger) { return (__assign(__assign({}, passenger), { value: passenger.id === payload_1.id ? payload_1.value : passenger.value, isValid: passenger.id === payload_1.id ? payload_1.isValid : passenger.isValid })); }) });
    }
    else if (action.type === 'validate_documents') {
        return __assign(__assign({}, state), { passengers: state.passengers.map(function (passenger) { return (__assign(__assign({}, passenger), { isValid: new RegExp(passenger.regex.error).test(passenger.value) || !passenger.regex })); }) });
    }
    return state;
};
var InsurancePopup = function (_a) {
    var _b;
    var className = _a.className, description = _a.description, insurances = _a.insurances, selectedInsurances = _a.selectedInsurances, passengers = _a.passengers, onClose = _a.onClose, onSave = _a.onSave, isLoading = _a.isLoading;
    var css = useTheme('InsurancePopup').InsurancePopup;
    var t = useTranslation('Checkout').t;
    var _c = __read(useState(), 2), slider = _c[0], setSlider = _c[1];
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var isSmall = !isMobile && insurances.length < 3;
    var sliderOptions = {
        className: cn(css.slider, (_b = {},
            _b[css.small] = isSmall,
            _b)),
        slidesToShow: isSmall ? insurances.length : 3.14,
        speed: 600,
        infinite: false,
        variableWidth: isSmall,
        arrows: false,
        responsive: [
            {
                breakpoint: MOBILE_MIN_WIDTH,
                settings: {
                    slidesToShow: 1.3
                }
            }
        ]
    };
    var inititalState = {
        selectedInsurances: selectedInsurances.map(function (selected) { return selected.insuranceProgram; }),
        passengers: passengers.map(function (passenger) { return (__assign(__assign({}, passenger), { isValid: !passenger.regex || !passenger.value || new RegExp(passenger.regex.pattern).test(passenger.value) })); })
    };
    var _d = __read(React.useReducer(insuranceReducer, inititalState, initReducer), 2), reducer = _d[0], dispatcher = _d[1];
    useEffect(function () {
        dispatcher({
            type: 'reset',
            payload: inititalState
        });
    }, [selectedInsurances, passengers]);
    var totalPrice = useMemo(function () {
        var _a;
        return {
            currency: (_a = insurances[0].price) === null || _a === void 0 ? void 0 : _a.currency,
            amount: reducer.selectedInsurances.reduce(function (total, _a) {
                var price = _a.price;
                return total + ((price === null || price === void 0 ? void 0 : price.amount) || 0);
            }, 0)
        };
    }, [reducer]);
    var selectedInsurancesCodes = useMemo(function () {
        return reducer.selectedInsurances.map(function (insurance) { return insurance.code; });
    }, [reducer]);
    var onSuccess = function () {
        onSave === null || onSave === void 0 ? void 0 : onSave(reducer);
    };
    var onSelectInsurance = function (isSelected, insurance) { return function () {
        dispatcher({
            type: isSelected ? 'remove' : 'add',
            payload: insurance
        });
    }; };
    return (React.createElement(AdditionalServiceWrapper, { className: className, headerClassName: css.header, textClassName: css.text, wrapperClassName: css.wrapper, title: t('Insurance'), text: description, onClose: onClose, onSuccess: onSuccess, isLoading: isLoading },
        insurances.length > 3 && (React.createElement("div", { className: css.slider_buttons },
            React.createElement("div", { className: cn(css.slider_button, css.slider_button_prev), onClick: function () { return slider.slickPrev(); } }, Chevron),
            React.createElement("div", { className: css.slider_button, onClick: function () { return slider.slickNext(); } }, Chevron))),
        React.createElement(Slider, __assign({ ref: setSlider }, sliderOptions), insurances.map(function (insurance) {
            var isSelected = selectedInsurancesCodes.includes(insurance.code);
            return (React.createElement(InsuranceCard, __assign({ onClick: onSelectInsurance(isSelected, insurance), isSelected: isSelected }, insurance)));
        })),
        totalPrice.amount > 0 && (React.createElement("div", { className: css.total },
            t('Total'),
            ":",
            React.createElement(Money, { money: totalPrice })))));
};
export default InsurancePopup;
