import { __read } from "tslib";
import * as React from 'react';
import LoginChangePassword from './LoginChangePassword';
import { useMutation } from '@apollo/react-hooks';
import * as Auth from '../../__queries__/query.graphql';
import { LoginType } from '../../__generated__/graphql';
var isChangePasswordError = function (error) { var _a; return (_a = error['message']) === null || _a === void 0 ? void 0 : _a.includes('Change password'); };
var LoginWrapper = function (_a) {
    var onSuccessAuth = _a.onSuccessAuth, onChangePasswordError = _a.onChangePasswordError;
    var _b = __read(useMutation(Auth.SendSecureCode), 1), sendCodeRequest = _b[0];
    var _c = __read(useMutation(Auth.Authenticate), 1), authenticate = _c[0];
    var onEmailSend = function (email, password) {
        return sendCodeRequest({
            variables: {
                params: {
                    login: email,
                    loginType: LoginType.Host,
                    password: password
                }
            }
        })
            .then(function (result) { return result.data.SendSecureCode.result === true; }, function (result) {
            var _a, _b;
            if (onChangePasswordError && ((_a = result === null || result === void 0 ? void 0 : result.graphQLErrors) === null || _a === void 0 ? void 0 : _a.find(isChangePasswordError))) {
                onChangePasswordError(email, password);
            }
            if (((_b = result === null || result === void 0 ? void 0 : result.graphQLErrors) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                return false;
            }
            return true;
        })
            .catch(function () { return false; });
    };
    var onAuthenticate = function (login, code) {
        return authenticate({
            variables: {
                params: {
                    login: login,
                    loginType: LoginType.Host,
                    secureCode: code
                }
            }
        })
            .then(function (result) {
            var _a;
            if ((_a = result.data.Authenticate) === null || _a === void 0 ? void 0 : _a.token) {
                onSuccessAuth(result.data.Authenticate.token);
                return true;
            }
            return false;
        })
            .catch(function () {
            return false;
        });
    };
    return React.createElement(LoginChangePassword, { mode: 'login', onEmailSubmit: onEmailSend, onCodeSubmit: onAuthenticate });
};
export default LoginWrapper;
