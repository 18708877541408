import { __assign, __awaiter, __generator, __read } from "tslib";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import Button from '../../../Button/Button';
import Input from '../../../Input';
import { Field, Form } from 'react-final-form';
import Modal from '../../../Modal';
import { PassengersCheckboxes } from './Components/PassengersCheckboxes';
import { FlightsCheckboxes } from './Components/FlightsCheckboxes';
import { buildSsrModalInitValues } from '../utils';
import { ALL_FLIGHTS_FIELD, ALL_PASSENGERS_FIELD, PASSENGER_FIELD_PREFIX, SEGMENT_FIELD_PREFIX } from './types';
export var SsrModal = function (props) {
    var theme = useTheme('Ssr').SsrModal;
    var t = useTranslation().t;
    var isDeletingMode = !!props.deletingSsr;
    var validateForm = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var errors;
        return __generator(this, function (_a) {
            errors = {};
            if (!values['ssr'] || !values['ssr'].trim()) {
                errors['ssr'] = t('Required');
            }
            return [2 /*return*/, errors];
        });
    }); };
    var onSubmit = function (values) {
        var _a;
        var travellerIds = [];
        var segmentIds = [];
        if (values[ALL_PASSENGERS_FIELD]) {
            props.travellers.forEach(function (traveller) {
                travellerIds.push(traveller.id);
            });
        }
        else {
            Object.entries(values).map(function (_a) {
                var _b = __read(_a, 2), field = _b[0], value = _b[1];
                if (field.includes(PASSENGER_FIELD_PREFIX) && value) {
                    travellerIds.push(field.substring(PASSENGER_FIELD_PREFIX.length));
                }
            });
        }
        if (values[ALL_FLIGHTS_FIELD]) {
            props.segments.forEach(function (segment) {
                segmentIds.push(segment.segment.id);
            });
        }
        else {
            Object.entries(values).map(function (_a) {
                var _b = __read(_a, 2), field = _b[0], value = _b[1];
                if (field.includes(SEGMENT_FIELD_PREFIX) && value) {
                    segmentIds.push(field.substring(SEGMENT_FIELD_PREFIX.length));
                }
            });
        }
        props.onSubmit({
            code: values['ssr'].toUpperCase(),
            statusCode: null,
            travellerIds: travellerIds,
            segmentIds: segmentIds,
            description: (_a = values['comments']) !== null && _a !== void 0 ? _a : ''
        });
    };
    var initialValues = buildSsrModalInitValues(isDeletingMode, props.deletingSsr, props.segments, props.travellers);
    return (React.createElement(Modal, { open: true, onClose: props.onClose, isRounded: true, classes: { paper: theme.modal } },
        React.createElement("div", { className: theme.header }, isDeletingMode ? t('Delete SSR') : t('Add SSR')),
        React.createElement(Form, { onSubmit: onSubmit, validate: validateForm, initialValues: initialValues }, function (_a) {
            var handleSubmit = _a.handleSubmit;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(Field, { name: 'ssr' }, function (_a) {
                    var input = _a.input, meta = _a.meta;
                    return (React.createElement(Input, { className: theme.ssr, disabled: isDeletingMode, absoluteHintPosition: true, TextFieldProps: __assign(__assign({}, input), { label: t('SSR'), type: 'input', onChange: input.onChange, error: meta.touched && !!meta.error, helperText: meta.touched && meta.error ? meta.error : null }) }));
                }),
                ((isDeletingMode && props.deletingSsr.ssrText) || !isDeletingMode) && (React.createElement(Field, { name: 'comments' }, function (_a) {
                    var input = _a.input;
                    return (React.createElement(Input, { className: theme.comments, disabled: isDeletingMode, TextFieldProps: __assign(__assign({}, input), { label: t('Comments'), type: 'input', onChange: input.onChange }) }));
                })),
                React.createElement(PassengersCheckboxes, { travellers: props.travellers, isDeletingMode: isDeletingMode }),
                React.createElement(FlightsCheckboxes, { segments: props.segments, isDeletingMode: isDeletingMode }),
                React.createElement("div", { className: theme.buttons },
                    React.createElement(Button, { type: 'submit', variant: 'flat', isLoading: props.isLoading }, isDeletingMode ? t('Delete') : t('Add')))));
        })));
};
