import * as React from 'react';
import { useSelector } from 'react-redux';
import { getFlightsToExchange, getSelectedPassengers } from '../../../../store/selectors';
import Chip from './Chip/Chip';
import { useTheme } from '../../../../../../theme';
import { ExchangeStep } from '../Inner';
import { useLocation } from 'react-router';
import { getPassengerName } from '../../utils';
import { isOpenedExchange } from '../../../../store/order/selectors';
var Selections = function (props) {
    var location = useLocation();
    var selectedPassengers = useSelector(getSelectedPassengers);
    var selectedSegments = useSelector(getFlightsToExchange);
    var openedExchange = useSelector(isOpenedExchange);
    var theme = useTheme('Exchange').Selections;
    var toPassengers = function () { return props.setStep(ExchangeStep.Passengers); };
    var toSegments = function () { return props.setStep(ExchangeStep.Segments); };
    var isPassengerPage = location.pathname.indexOf('passenger') >= 0;
    var isRequestPage = location.pathname.indexOf('request') >= 0;
    var isFlightPage = location.pathname.indexOf('segments') >= 0;
    if (!isRequestPage && !openedExchange) {
        return (React.createElement("div", { className: theme.wrapper },
            !isPassengerPage &&
                selectedPassengers.map(function (passenger) {
                    return (React.createElement(Chip, { label: getPassengerName(passenger), onClick: toPassengers, key: "p_".concat(passenger.id), type: "passenger" }));
                }),
            !isFlightPage &&
                !isPassengerPage &&
                selectedSegments.flightsByIds.map(function (segment) {
                    return (React.createElement(Chip, { label: "".concat(segment.segments[0].departure.airport.city.name, " - ").concat(segment.segments[segment.segments.length - 1].arrival.airport.city.name), onClick: toSegments, key: "s_".concat(segment.groupId), type: "flight" }));
                })));
    }
    return null;
};
export default Selections;
