import { __assign } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { useTheme } from '../../../../theme';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { SwitchV2 } from '../../../../Switch';
import { availableToAddServiceOnSingleSegment, getDifferentForReturnFlight, getDisabled, getOnlyForDifferentReturnFlight } from '../../store/selector';
import { differentForReturnFlight } from '../../store/actions';
import HeaderBaggageItem from './HeaderBaggageItem/HeaderBaggageItem';
import MobileBaggageSelector from './MobileBaggageSelector/MobileBaggageSelector';
import { getBaggageTab, getSelectedServices } from '../../store/selector';
import { setBaggageTab } from '../../store/actions';
import HeaderEquipmentItem from './HeaderEquipmentItem/HeaderEquipmentItem';
import Money from '../../../../Money';
import { getRelatedSegments, isActualBaggage } from '../../utils';
import SwipeableBaggageViews from '../../../BaggageList/components/SwipeableBaggageViews/SwipeableBaggageViews';
var BaggageDialogHeader = function (props) {
    var _a, _b, _c;
    var theme = useTheme('UpgradeBaggageDialog').BaggageDialogHeader;
    var t = useTranslation('IncludedBaggage').t;
    var dispatch = useDispatch();
    var selectedServices = useSelector(getSelectedServices);
    var baggageTab = useSelector(getBaggageTab);
    var disabledFromState = useSelector(getDisabled);
    var isDifferentForReturnFlight = useSelector(getDifferentForReturnFlight);
    var onlyForDifferentReturnFlight = useSelector(getOnlyForDifferentReturnFlight);
    var disabled = disabledFromState || onlyForDifferentReturnFlight;
    var isMultipleSegments = props.segments.length > 1;
    var availableToAddOnSingleSegment = useSelector(availableToAddServiceOnSingleSegment);
    var handlerClickSwitcher = function () { return dispatch(differentForReturnFlight()); };
    var onChangeBaggageTab = function (type) {
        dispatch(setBaggageTab(type));
    };
    var sliderSettings = {
        dots: false,
        speed: 500,
        variableWidth: true,
        infinite: false,
        arrows: false
    };
    var serviceByRfics = React.useMemo(function () {
        if (baggageTab) {
            // ищем багаж с максимальным id
            var actualService_1 = null;
            props.services.forEach(function (services) {
                services.forEach(function (service) {
                    if (service.rfics === baggageTab && isActualBaggage(service, actualService_1)) {
                        actualService_1 = service;
                    }
                });
            });
            return actualService_1;
        }
        return null;
    }, [props.services, baggageTab]);
    var servicePrice = React.useMemo(function () {
        if (serviceByRfics) {
            var price = {
                amount: serviceByRfics.price.amount,
                currency: serviceByRfics.price.currency
            };
            if (!isDifferentForReturnFlight) {
                var relatedSegments = getRelatedSegments(serviceByRfics.segmentsId, null);
                price.amount = price.amount * relatedSegments.size;
            }
            return price;
        }
        return null;
    }, [serviceByRfics, isDifferentForReturnFlight]);
    var renderCategories = useCallback(function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Slider, __assign({ className: theme.categories }, sliderSettings), props.services.map(function (service) {
                var baggageRfics = service[0].rfics;
                var isSelected = !!selectedServices.find(function (_a) {
                    var serviceId = _a.serviceId, count = _a.count;
                    return serviceId === service[0].id && count > 0;
                });
                return (React.createElement(HeaderEquipmentItem, { key: baggageRfics, rfics: baggageRfics, isActive: baggageTab === baggageRfics, isSelected: isSelected, onClick: function () { return onChangeBaggageTab(baggageRfics); } }));
            })),
            React.createElement("div", { className: theme.category__wrapper },
                React.createElement("div", { className: theme.category_info },
                    React.createElement("span", { className: theme.category_title }, serviceByRfics.name),
                    React.createElement("span", { className: theme.category_description }, serviceByRfics.description)),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Money, { money: servicePrice, moneyClassName: theme.category__price })))));
    }, [baggageTab, selectedServices, props.services, isDifferentForReturnFlight]);
    return (React.createElement("div", { className: cn(theme.row) },
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            baggageTab && renderCategories(),
            React.createElement("div", { className: cn(theme.info, (_a = {},
                    _a[theme.equipment] = baggageTab,
                    _a)) },
                props.headerProps.description && (React.createElement("div", { className: theme.titleContainer },
                    React.createElement("div", { className: theme.description }, props.headerProps.description))),
                React.createElement("div", { className: theme.info_price },
                    React.createElement("div", { className: cn(theme.price_label, (_b = {},
                            _b[theme.perFlight] = isDifferentForReturnFlight,
                            _b[theme.price_label_withPrice] = servicePrice,
                            _b)) }, isDifferentForReturnFlight ? (t('Price per flight')) : (React.createElement(React.Fragment, null,
                        t('Price for all flights'),
                        servicePrice && (React.createElement("div", { className: theme.price_amount },
                            React.createElement(Money, { money: servicePrice })))))),
                    isMultipleSegments && availableToAddOnSingleSegment && (React.createElement(SwitchV2, { label: React.createElement("span", { className: theme.label }, t('Choose for each flight')), switchPosition: 'right', isActive: isDifferentForReturnFlight, onClick: handlerClickSwitcher, isDisabled: disabled })))),
            !baggageTab && (React.createElement("div", { className: theme.baggage__container }, props.itemsToView ? (React.createElement(SwipeableBaggageViews, { items: props.services, itemsToView: props.itemsToView, onSwipe: function (viewItems) {
                    props.setChunkedBaggageItems(viewItems);
                } }, function (item) { return (React.createElement(HeaderBaggageItem, { services: item, segments: props.segments, key: item[0].rfics, allSegments: !isDifferentForReturnFlight })); })) : (props.services.map(function (service) {
                return (React.createElement(HeaderBaggageItem, { services: service, segments: props.segments, key: service[0].rfics, allSegments: !isDifferentForReturnFlight }));
            }))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            baggageTab ? (renderCategories()) : (React.createElement(MobileBaggageSelector, { services: props.services, selectedBaggageId: props.mobileSelectedServiceId, setSelectedBaggageId: props.setMobileServiceId, allSegments: !isDifferentForReturnFlight, segments: props.segments })),
            React.createElement("div", { className: theme.toggle__wrapper },
                React.createElement("div", { className: cn(theme.price_label, (_c = {}, _c[theme.perFlight] = isDifferentForReturnFlight, _c)) }, t(isDifferentForReturnFlight ? 'Price per flight' : 'Price for all flights')),
                isMultipleSegments && (React.createElement(SwitchV2, { label: React.createElement("span", { className: theme.label }, t('Choose for each flight')), switchPosition: 'right', isActive: isDifferentForReturnFlight, onClick: handlerClickSwitcher, isDisabled: disabled }))))));
};
export default BaggageDialogHeader;
