import { __assign } from "tslib";
import classnames from 'classnames';
import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMultiCity } from '../../../store/segments/selectors';
import Button from '../../../../Button';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import { getTotalPassengersCount } from '../../../store/passengers/selectors';
import { getSearchParameters } from '../../../store/selectors';
import { useForm } from 'react-final-form';
var StartSearch = React.memo(function (props) {
    var _a;
    var css = useTheme('SearchForm').StartSearch;
    var t = useTranslation('SearchForm').t;
    var proMode = useConfig().SearchForm.proMode;
    var searchParams = useSelector(getSearchParameters);
    var formState = useForm();
    var getSearchParamsHandler = React.useCallback(function () {
        formState.mutators.triggerValidation();
        if (formState.getState().valid) {
            return searchParams;
        }
        return null;
    }, [searchParams, formState]);
    return (React.createElement("div", { className: classnames(css.startSearch, (_a = {},
            _a[css.startSearch_multiCity] = props.isMultiCity,
            _a[css.startSearch_advanced] = proMode,
            _a)) },
        props.renderPayloads && props.renderPayloads(getSearchParamsHandler),
        React.createElement(Button, { type: "submit", size: "large", variant: "flat", disabled: props.passengersCount === 0 }, props.mode === 'icon' ? (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.71 11H12.5L17.49 16L16 17.49L11 12.5V11.71L10.73 11.43C9.59 12.41 8.11 13 6.5 13C2.91 13 0 10.09 0 6.5C0 2.91 2.91 0 6.5 0C10.09 0 13 2.91 13 6.5C13 8.11 12.41 9.59 11.43 10.73L11.71 11ZM2 6.5C2 8.99 4.01 11 6.5 11C8.99 11 11 8.99 11 6.5C11 4.01 8.99 2 6.5 2C4.01 2 2 4.01 2 6.5Z", fill: "white" }))) : (t('Search')))));
});
var mapStateToProps = function (state, props) {
    return __assign(__assign({}, props), { isMultiCity: isMultiCity(state), passengersCount: getTotalPassengersCount(state) });
};
export default connect(mapStateToProps)(StartSearch);
