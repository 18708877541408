var FlightFilterBase = /** @class */ (function () {
    function FlightFilterBase() {
    }
    FlightFilterBase.prototype.getSplitStr = function (filterValues) {
        var value = filterValues[this.attr];
        if (typeof value === 'string') {
            return value === null || value === void 0 ? void 0 : value.replace(/\s/g, '').toLowerCase().split(',');
        }
        else {
            return value;
        }
    };
    FlightFilterBase.prototype.removeWhiteSpace = function (value) {
        return value === null || value === void 0 ? void 0 : value.replace(/\s/g, '').split(',').reduce(function (pred, curr, i) { return (i > 0 ? "".concat(pred, ", ").concat(curr) : curr); });
    };
    FlightFilterBase.prototype.getPreparedFilterValue = function (filterValues) {
        return filterValues[this.attr];
    };
    return FlightFilterBase;
}());
export { FlightFilterBase };
