import * as React from 'react';
import { Switch } from '../../../Passenger/components/Field/Switch/Switch';
import { FemaleIcon, MaleIcon } from '../../Icons';
var GenderSwitch = function (props) {
    return (React.createElement(Switch, { name: 'sex', className: props.className, value: props.value, options: [
            { key: 'Male', value: 'male', icon: MaleIcon },
            { key: 'Female', value: 'female', icon: FemaleIcon }
        ], onChange: function (value) { return props.onChange(value); }, error: props.error || null }));
};
export default GenderSwitch;
