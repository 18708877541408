import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Money from '../../Money';
import Button from '../../Button/Button';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
initI18n('CheckoutAdditionalService');
var CheckoutAdditionalService = function (props) {
    var _a;
    var _b;
    var theme = useTheme('CheckoutAdditionalService').CheckoutAdditionalService;
    var t = useTranslation('CheckoutAdditionalService').t;
    return (React.createElement("div", { className: cn(theme.service, props.className, (_a = {}, _a[theme.service_active] = props.isSelected, _a)) },
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.header__text },
                props.isSelected && props.icon && React.createElement("div", { className: theme.icon }, props.icon),
                props.header),
            React.createElement("div", { className: theme.header__price }, props.isSelected ? (props.priceTotal ? (React.createElement(Money, { money: props.priceTotal })) : null) : props.priceFrom ? (React.createElement(React.Fragment, null,
                t('from'),
                " ",
                React.createElement(Money, { money: props.priceFrom }))) : null)),
        React.createElement("div", { className: theme.main },
            React.createElement("div", { className: cn(theme.content, props.descriptionClassName) }, props.isSelected ? props.children : props.description),
            React.createElement("div", { className: theme.buttons },
                props.isSelected &&
                    ((_b = props.buttons) === null || _b === void 0 ? void 0 : _b.length) &&
                    props.buttons.map(function (button) { return (React.createElement(Button, { key: button.text, isLoading: button.isLoading, onClick: function () { return button.action(); }, variant: "text", size: "common" }, button.text)); }),
                !props.isSelected && props.showAddButton !== false && (React.createElement(Button, { isLoading: props.isLoading, variant: "outline", className: theme.add, onClick: props.addClick }, props.addButtonText ? t(props.addButtonText) : t('Add')))))));
};
export default CheckoutAdditionalService;
