import Disclaimer from '../../../Disclaimer/Disclaimer';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
var SeatMapDisclaimer = function () {
    var t = useTranslation('SeatMap').t;
    var theme = useTheme('SeatMap').SeatMapDisclaimerStyles;
    return (React.createElement(Disclaimer, { wrapperClassName: theme.disclaimer__wrp, className: cn(theme.disclaimer_columnContent, theme.disclaimer) },
        React.createElement("p", null, t('Pay attention!')),
        React.createElement("p", null, t('The airline reserves the right to replace the aircraft.')),
        React.createElement("p", null,
            t('In the event of an aircraft replacement, passengers who have paid for the “seat selection” service will be provided with seats on the plane that are similar in price and comfort, while maintaining the following parameters'),
            ":"),
        React.createElement("p", null, t('- Cost of services;')),
        React.createElement("p", null, t('- Location of the place relative to the window / aisle;')),
        React.createElement("p", null,
            t('- Joint seating, if one was originally selected;'),
            " "),
        React.createElement("p", null, t('- Placement in the front row of the cabin, if one was originally selected.'))));
};
export default SeatMapDisclaimer;
