import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import AdditionalServiceBanner from '../../AdditionalServiceBanner/AdditionalServiceBanner';
import SimpleLoader from '../../SimpleLoader';
import IncludedBaggage from '../../Baggage/IncludedBaggage/components/IncludedBaggage';
import { useDispatch, useSelector } from 'react-redux';
import { getAdditionalBaggage, getBrandIncludedBaggage, getExtraBaggage, getOrder, getOverweightBaggage, getSegments, getSpecialEquipment, getUpgradeBaggage } from '../../Checkout/store/order/selectors';
import { factorySelectedUpgrades } from '../../Baggage/IncludedBaggage/utils';
import AdditionalBaggage from '../../Baggage/AdditionalBaggage/components/AdditionalBaggage';
import { setMultipleSelectedServices } from '../../Checkout/store/selectedServices/actions';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../__generated__/graphql';
import { getServicesSavingState } from '../../Checkout/store/servicesIsSaving/selectors';
import cn from 'classnames';
import SpecialEquipment from '../../Baggage/SpecialEquipment';
import OverweightBaggage from '../../Baggage/OverweightBaggage';
import { OrderStatus } from '../../__generated__/globalTypes';
import ExtraBaggage from '../../Baggage/ExtraBaggage';
import { useConfig } from '../../context';
initI18n('BaggagePage');
var BaggagePage = function (props) {
    var _a, _b, _c;
    var theme = useTheme('BaggagePage').BaggagePage;
    var includedBaggage = useSelector(getBrandIncludedBaggage);
    var order = useSelector(getOrder);
    var segments = useSelector(getSegments);
    var upgradeServices = useSelector(getUpgradeBaggage);
    var extraBaggage = useSelector(getExtraBaggage);
    var additionalBaggage = useSelector(getAdditionalBaggage);
    var sportServices = useSelector(getSpecialEquipment);
    var overweightBaggage = useSelector(getOverweightBaggage);
    var selectedServices = useMemo(function () {
        return factorySelectedUpgrades(props.selectedBaggage);
    }, [props.selectedBaggage]);
    var sortedAdditionalBaggage = React.useMemo(function () { return additionalBaggage.sort(function (a, b) { var _a, _b; return ((_a = a.value) === null || _a === void 0 ? void 0 : _a.amount) - ((_b = b.value) === null || _b === void 0 ? void 0 : _b.amount); }); }, [additionalBaggage]);
    var isTerminal = ((_c = (_b = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.iataCode) === 'TERMINAL';
    var disabled = order.status === OrderStatus.Cancelled || !order.canAddServices;
    var dispatch = useDispatch();
    var servicesSaving = useSelector(getServicesSavingState);
    var setAdditionalBaggage = function (segments) {
        var services = [];
        segments.forEach(function (service) {
            var existsService = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(additionalBaggage), false), __read(upgradeServices), false), __read(sportServices), false), __read(overweightBaggage), false), __read(extraBaggage), false).find(function (baggage) { return service.serviceId === baggage.id; });
            if (existsService) {
                var selectedService = {
                    id: existsService.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Baggage,
                    price: existsService.price,
                    confirmedCount: 0
                };
                services.push({
                    passengerId: service.passengerId,
                    count: service.count,
                    serviceId: service.serviceId,
                    service: selectedService,
                    segmentId: service.segmentIds[0],
                    allowedSegments: [service.segmentIds]
                });
            }
        });
        dispatch(setMultipleSelectedServices(services));
    };
    return (React.createElement("div", { className: theme.baggage },
        props.isLoading && React.createElement(SimpleLoader, null),
        !props.hideBanner && React.createElement(AdditionalServiceBanner, { service: "baggage" }),
        React.createElement("div", { className: theme.items },
            React.createElement("div", { className: theme.newBaggage },
                (includedBaggage.length > 0 || (upgradeServices === null || upgradeServices === void 0 ? void 0 : upgradeServices.length) > 0) && (React.createElement(IncludedBaggage, { order: order, passengers: order.travellers, passengerLabels: props.passengerLabels, segments: segments, includedBaggage: includedBaggage, upgradeServices: upgradeServices, selectedServices: selectedServices, onUpgrade: setAdditionalBaggage })),
                sortedAdditionalBaggage.length > 0 && (React.createElement(AdditionalBaggage, { order: order, additionalBaggage: sortedAdditionalBaggage, passengers: order.travellers, passengerLabels: props.passengerLabels, segments: segments, selectedServices: selectedServices, dynamicBaggagePricing: servicesSaving, onChange: console.log, onConfirm: setAdditionalBaggage, disabled: disabled })),
                isTerminal && extraBaggage.length > 0 && (React.createElement(ExtraBaggage, { order: order, passengers: order.travellers, passengerLabels: props.passengerLabels, segments: segments, extraBaggage: extraBaggage, selectedServices: selectedServices, onConfirm: setAdditionalBaggage, isLoading: servicesSaving })))),
        React.createElement("div", { className: cn(theme.items, theme.additional) },
            overweightBaggage.length > 0 && (React.createElement(OverweightBaggage, { className: theme.additionalBaggage, passengers: order.travellers, passengerLabels: props.passengerLabels, segments: segments, services: overweightBaggage, selectedServices: selectedServices, onUpgrade: setAdditionalBaggage, disabled: disabled })),
            sportServices.length > 0 && (React.createElement(SpecialEquipment, { className: theme.additionalBaggage, passengers: order.travellers, passengerLabels: props.passengerLabels, segments: segments, services: sportServices, selectedServices: selectedServices, onUpgrade: setAdditionalBaggage, disabled: disabled })))));
};
export default BaggagePage;
