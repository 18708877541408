import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import Header from '../../Header';
import { IconCalendar, SearchForm, SeatSelection } from '../../index';
import Logo from '../../Header/components/Logo/Logo';
import Menu from '../../Header/components/Menu/Menu';
import { useLeoNavigation } from '../Account/hooks';
import { useConsole } from '../Console/hooks/useConsole';
import Console from '../Console/Console';
import { ConsoleType } from '../Console/types';
import { useToggleable } from '../../hooks';
import Terminal from './Terminal/Terminal';
import Button from '../../Button/Button';
import { useTheme } from '../../theme';
import Schedule from '../Schedule/components/Schedule/Schedule';
import SeatAvailability from '../SeatAvailability/components/SeatAvailability';
var Page = function (props) {
    var children = props.children, consoleProps = props.consoleProps, _a = props.showConsole, showConsole = _a === void 0 ? true : _a, _b = props.showMenu, showMenu = _b === void 0 ? true : _b, restProps = __rest(props, ["children", "consoleProps", "showConsole", "showMenu"]);
    var _c = useLeoNavigation(), redirectToAccount = _c.redirectToAccount, redirectToEngine = _c.redirectToEngine;
    var _d = useConsole(), isOpen = _d.isOpen, open = _d.open, close = _d.close, command = _d.command, setCommand = _d.setCommand;
    var _e = useToggleable(false), isConsoleOpen = _e.isOpen, openConsole = _e.open, closeConsole = _e.close;
    var _f = __read(React.useState(null), 2), searchParams = _f[0], setSearchParams = _f[1];
    var theme = useTheme('Page').Page;
    var renderSearchFormPayloads = function (getSearchParams) {
        var onScheduleClickHandler = function (openScheduleWindow) { return function () {
            var params = getSearchParams();
            if (params) {
                setSearchParams({
                    segments: params.segments.map(function (segment) { return ({
                        date: segment.date,
                        arrival: {
                            iata: segment.arrival.iata,
                            city: null,
                            name: segment.arrival.iata
                        },
                        departure: {
                            iata: segment.departure.iata,
                            city: null,
                            name: segment.departure.iata
                        }
                    }); }),
                    passengers: params.passengers
                });
                openScheduleWindow();
            }
        }; };
        return (React.createElement("div", { className: theme.payloads },
            React.createElement(Schedule, { searchParameters: searchParams, onStartSearch: props.onSubmit, renderTrigger: function (open, loading) { return (React.createElement(Button, { variant: "flat", isLoading: loading, className: theme.button, onClick: onScheduleClickHandler(open) }, IconCalendar)); } }),
            React.createElement(SeatAvailability, { searchParameters: searchParams, renderTrigger: function (open, loading) { return (React.createElement(Button, { className: theme.button, variant: "flat", isLoading: loading, onClick: onScheduleClickHandler(open) }, SeatSelection)); } })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, __assign({ showMenu: showMenu, renderLogo: function () { return React.createElement(Logo, { onClick: redirectToEngine }); }, renderMenu: function () { return (React.createElement(Menu, { onClickProfile: redirectToAccount, onClickTerminal: open, onClickConsole: openConsole })); }, renderSearchForm: function () { return React.createElement(SearchForm, __assign({ renderPayloads: renderSearchFormPayloads }, restProps)); } }, restProps)),
        React.createElement("div", null,
            showConsole && (React.createElement(Console, __assign({ type: ConsoleType.SEARCH, onStartSearch: function (params) {
                    props.onSubmit(params);
                } }, consoleProps, { placeholder: 'search', isOpen: isOpen, onClose: close, onOpen: open, commandStr: command, setcommandStr: setCommand }))),
            React.createElement(Terminal, { isOpen: isConsoleOpen, open: open, close: closeConsole }),
            children)));
};
export default Page;
