import * as React from 'react';
import classnames from 'classnames';
import { Money } from '../../../Money';
import { format } from '../../../utils';
import { useTheme } from '../../../theme';
export var Day = function (props) {
    var _a, _b, _c, _d;
    var day = format(props.date, 'd MMM'), formattedDate = format(props.date, 'EEEEEE');
    var theme = useTheme('WeekCalendar').DayStyles;
    return (React.createElement("div", { className: classnames(theme.day, (_a = {}, _a[theme.day_notAvailable] = !props.isAvailable, _a), (_b = {}, _b[theme.day_selected] = props.isSelected, _b), (_c = {}, _c[theme.day_best] = props.isCheap, _c), (_d = {}, _d[theme.day_hidePrice] = props.hidePrice, _d)), onClick: function () {
            props.isAvailable && props.onSelect && props.onSelect(props.date);
        } },
        React.createElement("div", { className: theme.date }, day),
        React.createElement("div", { className: theme.date__dow },
            React.createElement("div", { className: theme.date__info },
                React.createElement("span", null, formattedDate))),
        !props.hidePrice && (React.createElement("div", { className: theme.price__wrapper }, props.isAvailable && React.createElement(Money, { moneyClassName: theme.price, money: props.price })))));
};
