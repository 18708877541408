import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
var FlightClass = function (_a) {
    var flightClass = _a.flightClass;
    var css = useTheme('AircraftPopup').FlightClass;
    var t = useTranslation('Aircraft').t;
    return (React.createElement(Card, { key: flightClass.title, elevation: 0, square: true, classes: { root: css.flightClass } },
        React.createElement(Paper, { classes: { root: css.headerWrp } },
            React.createElement(CardMedia, { classes: { root: css.mediaItem }, style: { backgroundImage: "url(".concat(flightClass === null || flightClass === void 0 ? void 0 : flightClass.image, ")") } }),
            React.createElement(CardHeader, { classes: { root: css.header, title: css.title }, title: t(flightClass.title) })),
        React.createElement(CardContent, { classes: { root: css.content } },
            React.createElement(List, { classes: { root: css.list } }, Object.keys(flightClass.parameters).map(function (key) { return (React.createElement(ListItem, { key: key, classes: { root: css.listItem } },
                t(flightClass.parameters[key].title),
                ":\u00A0",
                t(flightClass.parameters[key].value))); })))));
};
export default FlightClass;
