import * as React from 'react';
import { YMInitializer } from 'react-yandex-metrika';
import { memo } from 'react';
import { DATA_LAYER_KEY } from '../../analytics';
import { getLocale } from '../../utils';
var Analytics = memo(function () {
    return (React.createElement(React.Fragment, null, process.env.NODE_ENV === 'production' && (React.createElement(YMInitializer, { version: "2", accounts: [55868656], options: {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            trackHash: true,
            ecommerce: DATA_LAYER_KEY,
            params: {
                locale: getLocale()
            }
        } }))));
});
export default Analytics;
