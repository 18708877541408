import * as React from 'react';
import { useTheme } from '../../../../theme';
import { FieldGroupType, SearchParameterFieldEnum } from '../../types';
import Field from './Field/Field';
import Button from '../../../../Button';
import { useTranslation } from 'react-i18next';
var DropMenu = function (props) {
    var _a;
    var css = useTheme('SearchOrder').DropMenu;
    var t = useTranslation('SearchOrder').t;
    var renderGroups = function () {
        return Object.keys(groups).map(function (groupName) {
            var groupFields = groups[groupName];
            return (React.createElement("div", { className: css.group }, groupFields.map(function (field, index) { return (React.createElement(Field, { key: "".concat(field.name, "_").concat(index), onBlur: props.onBlur, values: props.formValues, onChange: props.onChange, field: field })); })));
        });
    };
    var groups = (_a = {},
        _a[FieldGroupType.MVP] = [],
        _a[FieldGroupType.Other] = [],
        _a);
    props.fields.forEach(function (field) {
        switch (field.name) {
            case SearchParameterFieldEnum.DepartureDateStart:
            case SearchParameterFieldEnum.DepartureDateEnd:
            case SearchParameterFieldEnum.FlightNumber:
            case SearchParameterFieldEnum.LastName:
                groups[FieldGroupType.MVP].push(field);
                break;
            default:
                groups[FieldGroupType.Other].push(field);
        }
    });
    return (React.createElement("div", { className: css.dropMenu },
        renderGroups(),
        React.createElement(Button, { variant: "flat", size: "large", className: css.searchBtn, onClick: props.handleSubmit }, t('Search'))));
};
export default DropMenu;
