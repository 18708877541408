import { useMemo } from 'react';
import { OrderStatus } from './types';
import { format, removeDotsFromDate } from '../utils';
import { getPassengerFirstName, getPassengerLastName } from '../Checkout/store/passengers/selectors';
import { OrderStatus as OrderStatusQraphql } from '../__generated__/graphql';
import { isSameMonth, isSameYear } from 'date-fns';
export var usePassengers = function (order) {
    return useMemo(function () {
        var _a;
        return (_a = order.travellers) === null || _a === void 0 ? void 0 : _a.map(function (traveller) { var _a, _b; return "".concat((_a = getPassengerLastName(traveller)) === null || _a === void 0 ? void 0 : _a.value, " ").concat((_b = getPassengerFirstName(traveller)) === null || _b === void 0 ? void 0 : _b.value); }).join(', ');
    }, [order.travellers]);
};
export var useStatus = function (order, isCheckinAvailable, type) {
    return useMemo(function () {
        if (isCheckinAvailable && type === 'account') {
            return OrderStatus.OnlineCheckIn;
        }
        else if (order.status === OrderStatusQraphql.Booked) {
            return OrderStatus.WaitPayment;
        }
        else if (order.status === OrderStatusQraphql.Confirmed) {
            return OrderStatus.Confirmed;
        }
        return null;
    }, [order.status, isCheckinAvailable]);
};
export var getReadablePeriod = function (order) {
    var dates = [];
    order.flight.segmentGroups.forEach(function (group) {
        var departureDate = new Date(group.segments[0].departure.date);
        dates.push(departureDate);
    });
    return getReadablePeriodFromDates(dates);
};
export var getReadablePeriodFromDates = function (dates) {
    var readableDates = []; // FIFO стек
    var withNotSameYear = false;
    var withNotSameMonth = false;
    var lastDate = dates.shift(); // получаем первую дату
    while (dates.length) {
        // пока стек не опустеет
        // если несколько дат
        var nextDate = dates.shift(); // получаем следующую дату для сравнения
        if (isSameYear(lastDate, nextDate) && !withNotSameYear) {
            if (isSameMonth(lastDate, nextDate) && !withNotSameMonth) {
                // если у дат одинаковые месяцы и год и дата на предудущей итерации у дат был одинаковый месяц и год,
                // то от первой даты записываем только день
                readableDates.push(format(lastDate, 'd'));
            }
            else {
                // если у дат одинаковый год, то от первой даты записываем только день и месяц
                readableDates.push(format(lastDate, 'd MMM'));
                // сохраняем флаг, что у дат разные месяцы (для следующей итерации).
                // если уже withNotSameMonth = true, то инвертируем
                withNotSameMonth = !withNotSameMonth;
            }
        }
        else {
            // если у дат разные года, то записываем первую дату полностью
            readableDates.push(removeDotsFromDate(format(lastDate, 'd MMM, yyyy')));
            withNotSameYear = !withNotSameYear;
            withNotSameMonth = !withNotSameMonth;
        }
        lastDate = nextDate;
    }
    // последнюю (или единственную, если кол-во дат = 1) дату  записываем полностью
    readableDates.push(removeDotsFromDate(format(lastDate, 'd MMM, yyyy')));
    return readableDates.join(' - ');
};
