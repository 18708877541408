import * as React from 'react';
import { ThemeProvider } from '@websky/core/src/theme';
import theme from '../../theme/theme';
import '../../theme/variables.css';
import { RenderersProvider } from '@websky/core/src/renderProps';
import { leoRenderers } from '@websky/core/src/LEO/LeoEngine/renderProps';
import Head from '../Head';
import { Engine } from '@websky/core/src';
import { GuardedApolloProvider } from '@websky/core/src/GuardedApolloProvider';
import { useGraphQLClient } from '@websky/core/src/graphql-client';
var EngineComponent = function () {
    var client = useGraphQLClient();
    return (React.createElement(GuardedApolloProvider, { client: client },
        React.createElement(ThemeProvider, { value: theme },
            React.createElement(RenderersProvider, { value: leoRenderers },
                React.createElement(Head, null),
                React.createElement(Engine, null)))));
};
export default EngineComponent;
