import { __assign, __read, __spreadArray } from "tslib";
import { OrderAdditionalServiceGdsServiceServiceType } from '../__generated__/graphql';
export var getSelectedServices = function (selectedServicesMap, services) {
    var selectedServices = [];
    services.forEach(function (service) {
        if (selectedServicesMap.hasOwnProperty(service.id) && Object.keys(selectedServicesMap[service.id]).length) {
            var passengerIdsForServices = Object.keys(selectedServicesMap[service.id]);
            passengerIdsForServices.forEach(function (passengerId) {
                var segmentIds = Object.keys(selectedServicesMap[service.id][passengerId]);
                segmentIds.forEach(function (segmentId, index) {
                    var serviceCount = selectedServicesMap[service.id][passengerId][segmentId];
                    var lastAddedService = selectedServices.length > 0 ? selectedServices[selectedServices.length - 1] : null;
                    if (index === 0 || (lastAddedService && lastAddedService.count !== serviceCount)) {
                        selectedServices.push({
                            passengerId: passengerId,
                            segmentIds: [segmentId],
                            count: selectedServicesMap[service.id][passengerId][segmentId],
                            serviceId: service.id
                        });
                    }
                    else {
                        lastAddedService.segmentIds.push(segmentId);
                    }
                });
            });
        }
        else {
            var segmentIds_1 = [];
            service.allowedSegments.forEach(function (segments) {
                return segments.forEach(function (segmentId) {
                    if (segmentIds_1.indexOf(segmentId) === -1) {
                        segmentIds_1.push(segmentId);
                    }
                });
            });
            service.allowedPassengers.forEach(function (passengerIds) {
                passengerIds.forEach(function (passengerId) {
                    selectedServices.push({
                        passengerId: passengerId,
                        serviceId: service.id,
                        segmentIds: segmentIds_1,
                        count: 0
                    });
                });
            });
        }
    });
    return selectedServices;
};
export var convertSelectedServicesToState = function (selectedServices, services) {
    var state = {};
    var passengerIds = Object.keys(selectedServices);
    passengerIds.forEach(function (passengerId) {
        var servicesId = Object.keys(selectedServices[passengerId]);
        servicesId
            .filter(function (serviceId) { return !!services.find(function (service) { return service.id === serviceId; }); })
            .forEach(function (serviceId) {
            selectedServices[passengerId][serviceId].forEach(function (selectedService) {
                return selectedService.segments.forEach(function (segmentId) {
                    var _a, _b, _c;
                    var _d;
                    state = __assign(__assign({}, state), (_a = {}, _a[serviceId] = __assign(__assign({}, state === null || state === void 0 ? void 0 : state[serviceId]), (_b = {}, _b[passengerId] = __assign(__assign({}, (_d = state === null || state === void 0 ? void 0 : state[serviceId]) === null || _d === void 0 ? void 0 : _d[passengerId]), (_c = {}, _c[segmentId] = selectedService.number, _c)), _b)), _a));
                });
            });
        });
    });
    return state;
};
export var convertTravellerSelectedServicesToSelectedServicesState = function (travellerSelectedServices, order) {
    var selectedServices = {};
    travellerSelectedServices.forEach(function (travellerService) {
        var passServices = {};
        travellerService.gdsServices.setServices.forEach(function (service) {
            var _a, _b;
            var selectedServices = (_b = (_a = travellerService.gdsServices) === null || _a === void 0 ? void 0 : _a.setServices) === null || _b === void 0 ? void 0 : _b.filter(function (travellerService) { return travellerService.serviceId === service.serviceId; });
            passServices[service.serviceId] = selectedServices.map(function (selectedService) {
                var _a, _b;
                return ({
                    segments: selectedService.segmentIds,
                    servicePayload: (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services.find(function (s) { return s.id === selectedService.serviceId; }),
                    number: selectedService.count
                });
            });
        });
        selectedServices[travellerService.travellerId] = passServices;
    });
    return selectedServices;
};
export var convertSelectedServicesStateToTravellerSelectedServices = function (selectedService, order) {
    var _a, _b, _c;
    var miscServicesIds = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Misc; }).map(function (service) { return service.id; });
    var groupedByPassengerId = selectedService.reduce(function (acc, service) {
        if (!acc[service.passengerId]) {
            acc[service.passengerId] = [service];
        }
        else {
            acc[service.passengerId] = __spreadArray(__spreadArray([], __read(acc[service.passengerId]), false), [service], false);
        }
        return acc;
    }, {});
    var services = Object.keys(groupedByPassengerId).map(function (passengerId) {
        var items = groupedByPassengerId[passengerId];
        var setServicesPayload = [];
        items.forEach(function (service) {
            if (!miscServicesIds.includes(service.serviceId)) {
                // if service is not MISC type, just save as is
                setServicesPayload.push({
                    serviceId: service.serviceId,
                    count: service.count,
                    segmentIds: service.segmentIds
                });
                return;
            }
            service.segmentIds.forEach(function (segmentGroup) {
                var _a, _b, _c;
                var orderTraveller = order.travellers.find(function (orderTraveller) { return orderTraveller.id === service.passengerId; });
                var travellerService = (_b = (_a = orderTraveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services.find(function (travellerService) { return travellerService.serviceId === service.serviceId; });
                var confirmedCount = (_c = travellerService === null || travellerService === void 0 ? void 0 : travellerService.confirmedCount) !== null && _c !== void 0 ? _c : 0;
                setServicesPayload.push({
                    serviceId: service.serviceId,
                    count: service.count < confirmedCount ? confirmedCount : service.count,
                    segmentIds: [segmentGroup]
                });
            });
        });
        return {
            travellerId: passengerId,
            gdsServices: {
                setServices: setServicesPayload
            }
        };
    });
    return services;
};
export var convertSelectedServiceToSelectedServicesSummary = function (selectedService, order) {
    var groupedByServiceId = selectedService
        .filter(function (service) { return service.count > 0; })
        .reduce(function (acc, service) {
        if (!acc[service.serviceId]) {
            acc[service.serviceId] = [service];
        }
        else {
            acc[service.serviceId] = __spreadArray(__spreadArray([], __read(acc[service.serviceId]), false), [service], false);
        }
        return acc;
    }, {});
    return Object.keys(groupedByServiceId).map(function (serviceId) {
        var _a, _b, _c;
        var services = groupedByServiceId[serviceId];
        return {
            name: (_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services.find(function (s) { return s.id === services[0].serviceId; })) === null || _c === void 0 ? void 0 : _c.name,
            count: services.reduce(function (acc, service) { return acc + service.count; }, 0)
        };
    });
};
export var convertTravellerSelectedServicesToSelectedServicesSummary = function (selectedService, order) {
    var services = selectedService.reduce(function (acc, travellerServices) { return __spreadArray(__spreadArray([], __read(acc), false), __read(travellerServices.gdsServices.setServices), false); }, []);
    return convertSelectedServiceToSelectedServicesSummary(services, order);
};
