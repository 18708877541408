import * as Flight from './components/DesktopFlight.css';
import * as Header from './components/Header/Header.css';
import * as ModalContent from './components/DesktopFlightModalContent/DesktopFlightModalContent.css';
import * as DesktopFlightPopup from './components/DesktopFlightPopup/DesktopFlightPopup.css';
export default {
    Flight: Flight,
    Header: Header,
    ModalContent: ModalContent,
    DesktopFlightPopup: DesktopFlightPopup
};
