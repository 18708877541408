import { __read } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import { currency } from '../../../__fakeData__';
import { NumberField } from '../../Fields/Fields';
import { useFormState } from 'react-final-form';
var RefundCoupons = function () {
    var _a;
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    var values = useFormState().values;
    var renderContent = useCallback(function () {
        var _a;
        return (_a = Object.entries(values === null || values === void 0 ? void 0 : values.selectedCoupons)) === null || _a === void 0 ? void 0 : _a.map(function (_a, index) {
            var _b = __read(_a, 2), _ = _b[0], coupon = _b[1];
            return (React.createElement("tr", { key: "".concat(coupon.coupon, "_").concat(index), className: theme.table_bg_gray },
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell }, coupon.coupon)),
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell }, coupon.date)),
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell }, coupon.flight)),
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell },
                        React.createElement("a", { href: "#" }, coupon.fareBasis))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(NumberField, { name: "selectedCoupons[".concat(index, "].penalty"), optional: true, showErrorInTooltip: true }))))));
        });
    }, [(_a = Object.entries(values === null || values === void 0 ? void 0 : values.selectedCoupons)) === null || _a === void 0 ? void 0 : _a.length]);
    return (React.createElement("div", null,
        React.createElement("table", { className: cn(theme.table, theme.table_border_full, theme.table_layout_fix) },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null,
                        React.createElement("div", { className: theme.table__cell }, t('Coupons'))),
                    React.createElement("th", null,
                        React.createElement("div", { className: theme.table__cell }, t('Date'))),
                    React.createElement("th", null,
                        React.createElement("div", { className: theme.table__cell }, t('Flight'))),
                    React.createElement("th", null,
                        React.createElement("div", { className: theme.table__cell }, t('Fare basis'))),
                    React.createElement("th", null,
                        React.createElement("div", { className: theme.table__cell },
                            t('Penalty'),
                            " (",
                            currency,
                            ")")))),
            React.createElement("tbody", null, renderContent()))));
};
export default RefundCoupons;
