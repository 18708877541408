import { __assign } from "tslib";
import * as React from 'react';
import { useField } from 'react-final-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import css from './CheckboxField.css';
var CheckboxField = function (_a) {
    var fieldName = _a.fieldName, readonly = _a.readonly, label = _a.label;
    var input = useField(fieldName, {
        type: 'checkbox'
    }).input;
    return (React.createElement(FormControlLabel, { classes: {
            label: css.label,
            disabled: css.disabled
        }, control: React.createElement(Checkbox, { checked: input.value, inputProps: __assign({}, input) }), disabled: readonly, label: label }));
};
export default CheckboxField;
