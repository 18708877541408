import { useBaggageServiceCard } from './components/Baggage/hooks';
import { useMealServiceCard } from './components/Meal/hooks';
import { useSeatsServiceCard } from './components/Seats/hooks';
import { useMiscServiceCard } from './components/Misc/hooks';
import { ServiceType } from '../../Checkout/components/Checkout/OrderPaidServiceCards/types';
export var useServiceCard = function (type) {
    switch (type) {
        case ServiceType.Baggage:
            return useBaggageServiceCard();
        case ServiceType.Seats:
            return useSeatsServiceCard();
        case ServiceType.Meal:
            return useMealServiceCard();
        case ServiceType.Misc:
            return useMiscServiceCard();
        default:
            return null;
    }
};
