import { __assign, __read } from "tslib";
import * as React from 'react';
import { batch, useDispatch } from 'react-redux';
import { setMultipleSelectedServices, deleteAllTypesOnSegmentWithoutRequest } from '../../../Checkout/store/selectedServices/actions';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../__generated__/globalTypes';
import Details from '../Details/Details';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useIsTerminal } from '../../../hooks';
var DetailsWrapper = function (props) {
    var dispatch = useDispatch();
    var isTerminal = useIsTerminal();
    var CountReducer = function (state, action) {
        var _a, _b;
        if (action.type === 'INCREASE' || action.type === 'DECREASE') {
            var passengerId = action.payload.passengerId;
            if (state[passengerId]) {
                return __assign(__assign({}, state), (_a = {}, _a[passengerId] = action.type === 'INCREASE'
                    ? __assign(__assign({}, state[passengerId]), { count: state[passengerId].count + 1 }) : __assign(__assign({}, state[passengerId]), { count: state[passengerId].count - 1 }), _a));
            }
            return __assign(__assign({}, state), (_b = {}, _b[passengerId] = {
                count: 1
            }, _b));
        }
        if (action.type === 'ADD_FOR_ALL') {
            return Object.values(props.counters).reduce(function (newState, counter) {
                var _a;
                return __assign(__assign({}, newState), (_a = {}, _a[counter.passenger.id] = {
                    count: 1
                }, _a));
            }, {});
        }
        if (action.type === 'CLEAR_ALL') {
            return {};
        }
        if (action.type === 'ACTUALIZE') {
            return action.payload;
        }
        return state;
    };
    var countersToLocalStateAdapter = function (counters) {
        var state = {};
        counters.forEach(function (counter) {
            state[counter.passenger.id] = {
                count: counter.count,
                minAvailable: counter.minAvailable
            };
        });
        return state;
    };
    var _a = __read(useReducer(CountReducer, countersToLocalStateAdapter(props.counters)), 2), stateLocal = _a[0], dispatchLocal = _a[1];
    useEffect(function () {
        dispatchLocal({
            type: 'ACTUALIZE',
            payload: countersToLocalStateAdapter(props.counters)
        });
    }, [props.counters]);
    var onIncreaseLocal = useCallback(function (meal, passenger) {
        dispatchLocal({
            type: 'INCREASE',
            payload: {
                passengerId: passenger.id,
                meal: meal
            }
        });
    }, []);
    var onDescreaseLocal = useCallback(function (meal, passenger) {
        dispatchLocal({
            type: 'DECREASE',
            payload: {
                passengerId: passenger.id,
                meal: meal
            }
        });
    }, []);
    var onAddForAllLocal = useCallback(function () {
        dispatchLocal({
            type: 'ADD_FOR_ALL'
        });
    }, []);
    var onClearLocal = useCallback(function () {
        dispatchLocal({
            type: 'CLEAR_ALL'
        });
        props.onClear(props.meal);
    }, [props.meal]);
    var replaceValuesFromLocalState = function (counters) {
        return counters.map(function (counter) {
            if (stateLocal.hasOwnProperty(counter.passenger.id)) {
                return __assign(__assign({}, counter), { count: stateLocal[counter.passenger.id].count });
            }
            else {
                return counter;
            }
        });
    };
    var submitModal = useCallback(function () {
        var servicesToSelect = [];
        Object.entries(stateLocal).forEach(function (_a) {
            var _b;
            var _c = __read(_a, 2), passengerId = _c[0], mealState = _c[1];
            var onlyUnpaidCount = mealState.count - ((_b = mealState.minAvailable) !== null && _b !== void 0 ? _b : 0);
            for (var index = 0; index < onlyUnpaidCount; index++) {
                servicesToSelect.push({
                    passengerId: passengerId,
                    segmentId: props.segmentId,
                    serviceId: props.meal.id,
                    allowedSegments: props.meal.allowedSegments,
                    service: {
                        id: props.meal.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Meal
                    }
                });
            }
        });
        batch(function () {
            dispatch(deleteAllTypesOnSegmentWithoutRequest({
                segmentId: props.segmentId,
                serviceId: props.meal.id
            }));
            dispatch(setMultipleSelectedServices(servicesToSelect));
        });
        props.onSubmit();
    }, [stateLocal, props.meal]);
    var detailsProps = isTerminal
        ? __assign(__assign({}, props), { onAddForAll: onAddForAllLocal, counters: useMemo(function () { return replaceValuesFromLocalState(props.counters); }, [props.counters, stateLocal]), onDecrease: onDescreaseLocal, onIncrease: onIncreaseLocal, onClear: onClearLocal, onSubmit: submitModal }) : props;
    return React.createElement(Details, __assign({}, detailsProps));
};
export default DetailsWrapper;
