import { __assign } from "tslib";
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PromoCode from '../../../../../PromoCode';
import { clearPromoCode, setPromoCode } from '../../../../store/promoCode/actions';
import { PromoCodeStatus } from '../../../../../PromoCode/types';
import { usePromoCode } from '../../../../../PromoCode/hooks';
import { getPromoCode, hasPromoCode } from '../../../../store/promoCode/selectors';
import { getSearchParameters } from '../../../../store/selectors';
import { useTheme } from '../../../../../theme';
var SearchFormPromoCode = function (props) {
    var _a, _b;
    var dispatch = useDispatch();
    var theme = useTheme('SearchForm').PromoCode;
    var isPromoCodeApplied = useSelector(hasPromoCode);
    var promoCode = useSelector(getPromoCode);
    var _c = usePromoCode(isPromoCodeApplied ? PromoCodeStatus.Applied : PromoCodeStatus.Empty), isLoading = _c.isLoading, setLoading = _c.setLoading, setNotLoading = _c.setNotLoading, openForm = _c.openForm, closeForm = _c.closeForm, status = _c.status, setStatus = _c.setStatus, isFormOpen = _c.isFormOpen;
    useEffect(function () {
        if (props.promoCode) {
            dispatch(setPromoCode(props.promoCode));
        }
    }, [props.promoCode]);
    var handleAddPromoCode = useCallback(function (_a) {
        var promoCode = _a.promoCode;
        setLoading();
        dispatch(setPromoCode(promoCode));
        setTimeout(function () {
            setNotLoading();
            setStatus(PromoCodeStatus.Provided);
        }, 500);
        setTimeout(closeForm, 1000);
    }, [status, isFormOpen]);
    if (props.onAddPromoCode) {
        var searchParams_1 = useSelector(getSearchParameters);
        handleAddPromoCode = useCallback(function (_a) {
            var promoCode = _a.promoCode;
            dispatch(setPromoCode(promoCode));
            props.onAddPromoCode(__assign(__assign({}, searchParams_1), { promotionCode: promoCode }));
        }, [status, isFormOpen]);
    }
    var handleClearPromoCode = useCallback(function () {
        setStatus(PromoCodeStatus.Empty);
        dispatch(clearPromoCode());
    }, [isPromoCodeApplied]);
    if (props.onClearPromoCode) {
        var searchParams_2 = useSelector(getSearchParameters);
        handleClearPromoCode = useCallback(function () {
            dispatch(clearPromoCode());
            props.onClearPromoCode(searchParams_2);
        }, [isPromoCodeApplied]);
    }
    return (React.createElement(PromoCode, { className: theme.promoCode, promoCode: promoCode, onAddPromoCode: handleAddPromoCode, isLoading: isLoading, isFormOpen: (_a = props.isFormOpen) !== null && _a !== void 0 ? _a : isFormOpen, onCloseForm: props.onClose ? props.onClose : closeForm, onOpenForm: props.onOpen ? props.onOpen : openForm, onClear: handleClearPromoCode, status: (_b = props.status) !== null && _b !== void 0 ? _b : status }));
};
export default SearchFormPromoCode;
