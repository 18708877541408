import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import Card from '@material-ui/core/Card';
import { useMutation, useQuery } from '@apollo/react-hooks';
import TravellerForm from '../../TravellerForm';
import { TravellerTypes } from '../../Traveller/types';
import Loader from '../../Loader';
import * as PersonalPageQuery from '../../__queries__/PersonalPage.graphql';
import { useTheme } from '../../theme';
import { OverrideComponent } from '../../renderProps';
var PersonalPage = function () {
    var _a = useQuery(PersonalPageQuery.GetPersonalDetails, { fetchPolicy: 'no-cache' }), data = _a.data, loading = _a.loading, refetchPersonalDetails = _a.refetch;
    var _b = __read(useMutation(PersonalPageQuery.SendPersonalDetails), 1), sendDetails = _b[0];
    var _c = useQuery(PersonalPageQuery.GetDefaultPersonalDetails, { fetchPolicy: 'no-cache' }), defaultData = _c.data, defaultLoading = _c.loading, refetchDefaultPersonalDetails = _c.refetch;
    var css = useTheme('Account').Account;
    if (!defaultLoading && (defaultData === null || defaultData === void 0 ? void 0 : defaultData.DefaultTravellerProfile)) {
        defaultData.DefaultTravellerProfile.documents = [];
    }
    return !loading && !defaultLoading ? (React.createElement(Card, null,
        React.createElement(OverrideComponent, { componentProps: {
                traveller: data && data.Profile
                    ? __assign({}, data.Profile) : defaultData && __assign({}, defaultData.DefaultTravellerProfile),
                onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                    var res;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, sendDetails({
                                    variables: {
                                        params: __assign(__assign({}, values), { citizenship: values['nationality'], nationality: undefined })
                                    }
                                })];
                            case 1:
                                res = _a.sent();
                                if (res.data.UpdateUserProfile) {
                                    refetchPersonalDetails();
                                    refetchDefaultPersonalDetails();
                                }
                                return [2 /*return*/, res.data.UpdateUserProfile];
                        }
                    });
                }); },
                type: TravellerTypes.PersonalDetails
            }, component: { PersonalPageTravellerForm: TravellerForm } }))) : (React.createElement("div", { className: css.loader },
        React.createElement(Loader, null)));
};
export default PersonalPage;
