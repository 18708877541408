import * as React from 'react';
import { ExareStatus, TravellerFieldEnum } from '../../../../../__generated__/graphql';
import { format, MOBILE_MIN_WIDTH } from '../../../../../utils';
import Chip from '../Inner/Selections/Chip/Chip';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import RequestControls from './RequestControls/RequestControls';
import MediaQuery from 'react-responsive';
import Notice from '../Notice/Notice';
import SelectedFlights from '../Request/SelectedFlights/SelectedFlights';
import { OverrideComponent } from '../../../../../renderProps';
import { getNewExchangeFlightPrice } from './utils';
import { Reasons } from '../../../store/types';
import { useIsTerminal } from '../../../../../hooks';
var RequestForm = function (_a) {
    var _b;
    var order = _a.order, deleteRequest = _a.deleteRequest, goToNewExchange = _a.goToNewExchange, goToPayment = _a.goToPayment;
    var theme = useTheme('Exchange').RequestForm;
    var isTerminal = useIsTerminal();
    var t = useTranslation('Exchange').t;
    var passengers = React.useMemo(function () {
        return order.travellers.map(function (traveller) {
            var firstName = traveller.values.find(function (value) { return value.type === TravellerFieldEnum.FirstName; }).value, lastName = traveller.values.find(function (value) { return value.type === TravellerFieldEnum.LastName; }).value;
            return "".concat(firstName, " ").concat(lastName);
        });
    }, [order]);
    var segments = React.useMemo(function () {
        if (!order.flight) {
            return [];
        }
        return order.flight.segments.map(function (segment) {
            var departureAirport = segment.segment.departure.airport;
            var arrivalAirport = segment.segment.arrival.airport;
            if (isTerminal) {
                return "".concat(departureAirport.iata, " - ").concat(arrivalAirport.iata);
            }
            return "".concat(departureAirport.city.name, " - ").concat(arrivalAirport.city.name);
        });
    }, [order]);
    var prices = React.useMemo(function () {
        return getNewExchangeFlightPrice(order);
    }, [order.prices]);
    return (React.createElement("div", null,
        React.createElement("div", { className: theme.container },
            React.createElement("div", { className: theme.request__main },
                React.createElement("div", { className: theme.header__wrapper },
                    React.createElement("div", { className: theme.header },
                        t('Request #'),
                        " ",
                        order.id),
                    React.createElement("div", { className: theme.created },
                        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                            t('Create'),
                            " ",
                            format(order.createDate, 'dd MMMM, yyyy')),
                        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, format(order.createDate, 'dd MMMM, yyyy')))),
                (order.status !== ExareStatus.Finished || isTerminal) && (React.createElement("div", { className: cn(theme.status, (_b = {},
                        _b[theme.status_error] = order.status === ExareStatus.TimelimitExpired,
                        _b[theme.status_finished] = order.status === ExareStatus.Finished,
                        _b[theme.status_reject] = order.status === ExareStatus.Rejected || order.status === ExareStatus.Refused,
                        _b)) }, t(order.status)))),
            React.createElement("div", { className: theme.order },
                React.createElement(Chip, { label: passengers.join(', '), type: "passenger", onClick: function () { }, className: cn(theme.chip), readonly: true }),
                isTerminal && (React.createElement(Chip, { label: order.isCompelled ? t(Reasons.Unvoluntary) : t(Reasons.Voluntary), type: "passenger", onClick: function () { }, className: cn(theme.chip), readonly: true })),
                segments.map(function (segment, id) {
                    return (React.createElement(Chip, { label: segment, type: "flight", onClick: function () { }, key: "segment_".concat(id), className: theme.chip, readonly: true }));
                })),
            order.userComment && (React.createElement("div", { className: theme.comment },
                React.createElement("div", { className: theme.comment__header }, t('Your comments')),
                React.createElement("div", { className: theme.comment__text }, order.userComment))),
            order.flight && (React.createElement(OverrideComponent, { componentProps: {
                    selectedFlights: [order.flight],
                    prices: prices,
                    hideHeader: true,
                    type: 'flat'
                }, component: {
                    ExchangeSelectedFlights: SelectedFlights
                } }))),
        (order.status === ExareStatus.AwaitingPayment || order.status === ExareStatus.TimelimitExpired) && (React.createElement(Notice, { type: "time", header: t('Limited exchange time'), text: t('Offer valid until {{date}}', { date: format(order.timelimit, 'dd MMMM yyyy, HH:mm') }) })),
        order.status === ExareStatus.Rejected && order.managerRejectComment && (React.createElement(Notice, { type: "reject", header: t('Rejection reason'), text: order.managerRejectComment })),
        React.createElement(RequestControls, { order: order, deleteRequest: deleteRequest, goToNewExchange: goToNewExchange, goToPayment: goToPayment })));
};
export default RequestForm;
