import { __assign, __read, __values } from "tslib";
import { FilterAttrs } from './types';
import { AirlineCodeFilter } from './classes/AirlineCodeFilter';
import { FlightNumFilter } from './classes/FlightNumFilter';
import { NoneStopFilter } from './classes/NonStopFilter';
import { PeriodFilter } from './classes/PeriodFilter';
import { WeekDaysFilter } from './classes/WeekDaysFilter';
export var flightFilters = new Map();
flightFilters.set(FilterAttrs.NON_STOP, new NoneStopFilter());
flightFilters.set(FilterAttrs.AIRLINE_CODE, new AirlineCodeFilter());
flightFilters.set(FilterAttrs.FLIGHT_NUM, new FlightNumFilter());
flightFilters.set(FilterAttrs.WEEK_DAYS, new WeekDaysFilter());
flightFilters.set(FilterAttrs.PERIOD, new PeriodFilter());
var getPreparedFilterValues = function (filterValues) {
    var e_1, _a;
    var newFilterValues = {};
    try {
        for (var _b = __values(flightFilters.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
            var filter = _c.value;
            var newFilterValue = filter.getPreparedFilterValue(filterValues);
            if (newFilterValue) {
                newFilterValues[filter.attr] = newFilterValue;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return newFilterValues;
};
export var getFilteredFlights = function (flights, filterValues) {
    var e_2, _a, e_3, _b;
    var filteredFlights = [];
    if (Object.keys(filterValues).length === 0) {
        try {
            for (var flights_1 = __values(flights), flights_1_1 = flights_1.next(); !flights_1_1.done; flights_1_1 = flights_1.next()) {
                var flight = flights_1_1.value;
                filteredFlights.push(flight.source);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (flights_1_1 && !flights_1_1.done && (_a = flights_1.return)) _a.call(flights_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    else {
        var preparedFilterValues = getPreparedFilterValues(filterValues);
        var filterAttrValuePairs = Object.entries(preparedFilterValues);
        var _loop_1 = function (flight) {
            if (filterAttrValuePairs.every(function (_a) {
                var _b = __read(_a, 2), attr = _b[0], value = _b[1];
                return flightFilters.get(attr).check(flight, value);
            })) {
                filteredFlights.push(flight.source);
            }
        };
        try {
            for (var flights_2 = __values(flights), flights_2_1 = flights_2.next(); !flights_2_1.done; flights_2_1 = flights_2.next()) {
                var flight = flights_2_1.value;
                _loop_1(flight);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (flights_2_1 && !flights_2_1.done && (_b = flights_2.return)) _b.call(flights_2);
            }
            finally { if (e_3) throw e_3.error; }
        }
    }
    return filteredFlights;
};
export var setNonStopFilterValue = function (filterValues, value) {
    var newValues = __assign({}, filterValues);
    if (value) {
        newValues.NON_STOP = true;
    }
    else {
        delete newValues.NON_STOP;
    }
    return newValues;
};
