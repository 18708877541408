import { __read, __rest } from "tslib";
import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { fastSearchParse, isFastSearchCorrect } from './utils';
import PromoLoader from '../../../PromoLoader/components/PromoLoader';
import GetAccountInfoGraphql from '../../../__queries__/Account.graphql';
import { useConfig } from '../../../context';
import LoyaltyConnect from '../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import Modal from '../../../Modal';
var FastSearch = function (_a) {
    var _b;
    var fastSearch = _a.fastSearch, startSearch = _a.startSearch, redirect = _a.redirect;
    var _c = __read(useState(true), 2), isValid = _c[0], setIsValid = _c[1];
    var _d = __read(useState(false), 2), authorizationFfp = _d[0], setAuthorizationFfp = _d[1];
    var payByMiles = (_b = useConfig()) === null || _b === void 0 ? void 0 : _b.SearchForm.payByMiles;
    var _e = useQuery(GetAccountInfoGraphql.GetAccountInfo, { skip: !payByMiles }), currentUser = _e.data, loading = _e.loading;
    var userInfo = useMemo(function () {
        var _a, _b, _c, _d;
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) || !((_a = currentUser.CurrentUser) === null || _a === void 0 ? void 0 : _a.userProfile)) {
            return {
                lastName: '',
                firstName: '',
                email: ''
            };
        }
        return {
            lastName: (_b = currentUser.CurrentUser.userProfile.values.find(function (value) { return value.name === 'lastName'; })) === null || _b === void 0 ? void 0 : _b.value,
            firstName: (_c = currentUser.CurrentUser.userProfile.values.find(function (value) { return value.name === 'firstName'; })) === null || _c === void 0 ? void 0 : _c.value,
            email: (_d = currentUser.CurrentUser.userProfile.values.find(function (value) { return value.name === 'email'; })) === null || _d === void 0 ? void 0 : _d.value
        };
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser]);
    useEffect(function () {
        var _a, _b;
        if (isFastSearchCorrect(fastSearch)) {
            var parsedSearchParams = fastSearchParse(fastSearch);
            if (payByMiles) {
                if (parsedSearchParams.ffpMode &&
                    (!((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) === null || _a === void 0 ? void 0 : _a.userFfpInfo) || ((_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) === null || _b === void 0 ? void 0 : _b.isFfpAuthorizationNeeded))) {
                    setAuthorizationFfp(true);
                }
                else {
                    startSearch(parsedSearchParams);
                }
            }
            else {
                var ffpMode = parsedSearchParams.ffpMode, filteredSearchParams = __rest(parsedSearchParams, ["ffpMode"]);
                startSearch(filteredSearchParams);
            }
        }
        else {
            setIsValid(false);
        }
    }, [fastSearch, currentUser]);
    var onCloseAuthorizationFfp = function () {
        setAuthorizationFfp(false);
        setIsValid(false);
    };
    var onSaveAuthorizationFfp = function () {
        setTimeout(function () {
            startSearch(fastSearchParse(fastSearch));
        }, 2000);
    };
    if (payByMiles && !loading && authorizationFfp) {
        return (React.createElement(Modal, { maxWidth: "sm", open: authorizationFfp, onClose: onCloseAuthorizationFfp },
            React.createElement(LoyaltyConnect, { userInfo: userInfo, onLoyaltyCardSaved: onSaveAuthorizationFfp, authType: "authenticate" })));
    }
    return isValid && loading ? React.createElement(PromoLoader, null) : React.createElement(React.Fragment, null, redirect);
};
export default FastSearch;
