import { __read, __spreadArray } from "tslib";
import React, { useMemo } from 'react';
import { useTheme } from '../../../theme';
import { useToggleable } from '../../../hooks';
import { getMinPrice, getPriceToPay } from '../../../BaggagePage/utils';
import { joinServicesByRfics } from '../../UpgradeBaggageDialog/utils';
import { BaggageList } from '../../BaggageList';
import { convertBaggageOnBaggageItem } from '../../AdditionalBaggage/utils';
import Footer from '../../AdditionalBaggage/components/Footer/Footer';
import UpgradeBaggageDialog from '../../UpgradeBaggageDialog/components/UpgradeBaggageDialog';
import CheckedInIconWrapper from './CheckedInIconWrapper';
import { useTranslation } from 'react-i18next';
import { sortBySelectedByPrice } from '../utils';
import { RenderersProvider } from '../../../renderProps';
var baggageItemsToView = 3;
var ExtraBaggage = function (props) {
    var css = useTheme('ExtraBaggage').ExtraBaggage;
    var t = useTranslation('ExtraBaggage').t;
    var _a = useToggleable(false), isOpenModal = _a.isOpen, openModal = _a.open, closeModal = _a.close;
    var priceToPay = useMemo(function () { return getPriceToPay(props.selectedServices, props.extraBaggage); }, [
        props.extraBaggage,
        props.selectedServices
    ]);
    var baggageByRfics = useMemo(function () { return joinServicesByRfics(props.extraBaggage); }, [props.extraBaggage]);
    var items = useMemo(function () {
        var items = baggageByRfics.map(function (baggage) {
            return convertBaggageOnBaggageItem(baggage, props.selectedServices, props.order, props.segments);
        });
        var sortedItems = __spreadArray([], __read(items), false).sort(sortBySelectedByPrice);
        return sortedItems;
    }, [baggageByRfics, props.order]);
    var minPrice = useMemo(function () { return getMinPrice(props.extraBaggage); }, [props.extraBaggage]);
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.header }, t('Extra baggage services')),
        React.createElement(RenderersProvider, { value: { BaggageIcon: function () { return null; } } },
            React.createElement(BaggageList, { items: items, itemsToView: baggageItemsToView })),
        React.createElement("div", { className: css.footer },
            React.createElement(Footer, { price: priceToPay, minPrice: minPrice, onClickEdit: openModal })),
        items.length > 0 && (React.createElement(RenderersProvider, { value: { BaggageIcon: CheckedInIconWrapper } },
            React.createElement(UpgradeBaggageDialog, { open: isOpenModal, onClose: closeModal, onConfirm: props.onConfirm, passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, includedBaggage: [], upgradeBaggage: props.extraBaggage, disabled: props.disabled, selectedServices: props.selectedServices, itemsToView: baggageItemsToView, maxBaggagePerPassengerCount: props.maxBaggagePerPassenger, maxBaggageCount: props.maxBaggage, headerProps: {
                    header: t('Extra baggage services')
                } })))));
};
export default ExtraBaggage;
