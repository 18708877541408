import { __read, __rest } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Money } from '../../Money';
import { initI18n } from '../../utils';
import PriceParts from './PriceParts/PriceParts';
import { useTheme } from '../../theme';
import GooglePay from './Gateway/GooglePay/GooglePay';
import Kaspi from './Gateway/Kaspi/Kaspi';
import Iframe from './Gateway/Iframe/Iframe';
import { PaymentType } from '../types';
import Link from '../../Link';
import Test from './Gateway/Test/Test';
import MediaQuery from '../../MediaQuery/MediaQuery';
import { arrowIcon } from '../Icons';
import Button from '../../Button';
initI18n('PaymentForm');
var mastercard = require('../images/mastercard.svg');
var visa = require('../images/visa.svg');
var mir = require('../images/mir.svg');
var paylate = require('../images/paylate.svg');
var invoicebox = require('../images/invoicebox.svg');
var iconKaspi = require('../images/kaspi.svg');
var PaymentForm = function (_a) {
    var _b, _c;
    var card = _a.card, onPaymentFrameLoaded = _a.onPaymentFrameLoaded, credit = _a.credit, googlePay = _a.googlePay, itransfer = _a.itransfer, kaspi = _a.kaspi, priceToPay = _a.priceToPay, onSuccess = _a.onSuccess, onFail = _a.onFail, onTestPayment = _a.onTestPayment, onBackToOrder = _a.onBackToOrder, onPaymentMethodSelected = _a.onPaymentMethodSelected, paymentPage = _a.paymentPage;
    var _d = __read(useState(paymentPage !== null && paymentPage !== void 0 ? paymentPage : null), 2), page = _d[0], setPage = _d[1];
    var css = useTheme('PaymentForm').PaymentForm;
    var t = useTranslation('PaymentForm').t;
    var isPreOpened = false;
    var isOneMethodOnly = !googlePay && !onTestPayment && !itransfer && !kaspi && ((card && !credit) || (credit && !card));
    isPreOpened = isOneMethodOnly || !!paymentPage;
    var resetPage = function () { return setPage(null); };
    var total = priceToPay.total, priceToPayWithoutTotal = __rest(priceToPay, ["total"]);
    useEffect(function () {
        onPaymentMethodSelected(page);
    }, [page]);
    return (React.createElement("div", { className: cn(css.wrapper, (_b = {},
            _b[css.oneMethod] = isOneMethodOnly,
            _b)) },
        React.createElement("aside", { className: css.details },
            React.createElement("h2", { className: css.details__total },
                t('Total'),
                ": ",
                React.createElement(Money, { money: total })),
            React.createElement("ul", { className: css.details__parts },
                React.createElement(PriceParts, { prices: priceToPayWithoutTotal })),
            !!onBackToOrder && (React.createElement(Button, { className: css.details__button, size: "large", onClick: onBackToOrder }, t('Edit order')))),
        React.createElement("div", { className: css.content },
            React.createElement("h3", { className: css.content__title },
                React.createElement(MediaQuery, { maxWidth: 'mobile' }, page && !isOneMethodOnly && (React.createElement(Link, { className: css.content__title__back, action: resetPage }, arrowIcon))),
                React.createElement("span", null, page ? page.title : t('Choose a convenient payment method')),
                React.createElement(MediaQuery, { minWidth: 'mobile' }, page && !isOneMethodOnly && (React.createElement(Link, { className: css.content__title__back, action: resetPage }, t('Other methods')))),
                isOneMethodOnly && (React.createElement("div", { className: css.cards },
                    React.createElement("img", { src: mastercard, alt: "Mastercard", className: css.mastercard }),
                    React.createElement("img", { src: visa, alt: "VISA", className: css.visa }),
                    React.createElement("img", { src: mir, alt: "\u041C\u0418\u0420", className: css.mir })))),
            page && page.description && React.createElement("p", { className: css.description }, page.description),
            React.createElement("ul", { className: cn(css.gateways, (_c = {},
                    _c[css.gateways_hidden] = !!page,
                    _c)) },
                googlePay && (React.createElement(GooglePay, { price: priceToPay === null || priceToPay === void 0 ? void 0 : priceToPay.total, onClick: setPage, onSuccess: onSuccess, onFail: onFail })),
                card && (React.createElement(Iframe, { type: PaymentType.Card, frameInfo: card, onClick: setPage, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Card, onPaymentFrameLoaded: onPaymentFrameLoaded, children: React.createElement("div", { className: css.cards },
                        React.createElement("img", { src: mastercard, alt: "Mastercard", className: css.mastercard }),
                        React.createElement("img", { src: visa, alt: "VISA", className: css.visa }),
                        React.createElement("img", { src: mir, alt: "\u041C\u0418\u0420", className: css.mir })) })),
                kaspi && (React.createElement(Kaspi, { isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Kaspi, frameInfo: kaspi, headerTitle: 'Kaspi', onClick: setPage },
                    React.createElement("div", { className: css.cards },
                        React.createElement("img", { src: iconKaspi, alt: "Kaspi", className: css.kaspi })))),
                credit && (React.createElement(Iframe, { type: PaymentType.Credit, frameInfo: credit, onClick: setPage, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Credit, gatewayTitle: t('Instalment'), headerTitle: t('Instalment'), children: React.createElement("div", { className: cn(css.cards) },
                        React.createElement("img", { src: paylate, alt: "Paylate", className: css.paylate })) })),
                itransfer && (React.createElement(Iframe, { type: PaymentType.Itransfer, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Itransfer, frameInfo: itransfer, onClick: setPage, headerTitle: t('Invoicebox'), gatewayTitle: t('Invoicebox'), isLoading: true },
                    React.createElement("div", { className: css.cards },
                        React.createElement("img", { src: invoicebox, alt: t('Invoicebox'), className: css.invoicebox })))),
                onTestPayment && React.createElement(Test, { onClick: onTestPayment })),
            page && React.createElement("div", null, page.content))));
};
export default PaymentForm;
