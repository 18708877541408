import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Money } from '../../../../../Money';
import { useTheme } from '../../../../../theme';
import BaggageComponent from '../../../../Baggage';
import { serviceForMultipleSegments } from '../../../hooks';
import { BaggageType } from '../../../../../__generated__/globalTypes';
import { getRelatedSegments, getActualPriceInBaggageGroup } from '../../../utils';
var HeaderBaggageItem = function (_a) {
    var services = _a.services, allSegments = _a.allSegments;
    var theme = useTheme('UpgradeBaggageDialog').BaggageDialogHeader;
    var t = useTranslation('IncludedBaggage').t;
    var price = React.useMemo(function () {
        var _a;
        if (allSegments) {
            var amount_1 = 0;
            services.forEach(function (service) {
                if (service.canBeAdded) {
                    var segments = getRelatedSegments(service.segmentsId);
                    amount_1 += service.price.amount * segments.size;
                }
            });
            return {
                currency: (_a = services[0]) === null || _a === void 0 ? void 0 : _a.price.currency,
                amount: amount_1
            };
        }
        return getActualPriceInBaggageGroup(services);
    }, [services, allSegments]);
    var multipleSegments = React.useMemo(function () { return serviceForMultipleSegments(services[0]); }, [services]);
    var isShort = [BaggageType.BaggageExcess].includes(services[0].type);
    return (React.createElement("div", { className: theme.baggage_wrapper, key: services[0].id },
        React.createElement(BaggageComponent, { baggage: services[0], selected: false, realIconSize: true, onlyIcon: false, short: isShort }),
        React.createElement("div", { className: theme.price_wrapper },
            React.createElement(Money, { money: price })),
        !allSegments && multipleSegments && (React.createElement("div", { className: theme.segmentNotice }, t('Added for all flights')))));
};
export default HeaderBaggageItem;
