var _a;
import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAeroexpress, getAvailableGdsServicesByType, getInsurances, getOrder, getPriorityBoarding, getTravellers } from '../../Checkout/store/order/selectors';
import { setService } from '../../Checkout/store/selectedServices/actions';
import { getPassengerLabels } from '../../Checkout/store/passengers/selectors';
import { useState } from 'react';
import { AviaPassengerType } from '../../__generated__/graphql';
import { getPassengerState } from '../../Checkout/store/passengers/selectors';
import { getPassengerStateCompatible, getSelectedServicesState } from '../../Checkout/store/selectedServices/selectors';
import { Money } from '../../index';
import { getPassengerPricesMap } from '../utils';
export var useServicePageProps = function () {
    var order = useSelector(getOrder);
    var passengers = useSelector(getPassengerStateCompatible);
    var passengerLabels = useSelector(getPassengerLabels);
    var _a = __read(useState(null), 2), openedModal = _a[0], setOpenedModal = _a[1];
    var toggleModal = function (type) {
        if (!!type) {
            setOpenedModal(type);
        }
        else {
            setOpenedModal(null);
        }
    };
    return {
        order: order,
        passengers: passengers,
        passengerLabels: passengerLabels,
        openedModal: openedModal,
        toggleModal: toggleModal
    };
};
export var useCheckoutServicesCardsAvailability = function () {
    var _a, _b;
    var aeroexpress = useSelector(getAeroexpress);
    var insurance = useSelector(getInsurances);
    var priorityBoarding = useSelector(getPriorityBoarding);
    var gdsServices = useSelector(getAvailableGdsServicesByType);
    return __assign({ Insurance: ((_a = insurance === null || insurance === void 0 ? void 0 : insurance.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) > 0, Aeroexpress: ((_b = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.trips) === null || _b === void 0 ? void 0 : _b.length) > 0, PriorityBoarding: (priorityBoarding === null || priorityBoarding === void 0 ? void 0 : priorityBoarding.length) > 0 }, gdsServices);
};
export var PASSENGERS_TYPES = (_a = {},
    _a[AviaPassengerType.ADT] = 'Adult',
    _a[AviaPassengerType.CLD] = 'Child',
    _a[AviaPassengerType.INF] = 'Infant',
    _a[AviaPassengerType.INS] = 'Infant',
    _a);
export var useOrderService = function (orderServices, type) {
    var passengers = useSelector(getPassengerState);
    var travellers = useSelector(getTravellers);
    var dispatch = useDispatch();
    var selectedServices = useSelector(getSelectedServicesState);
    var _a = __read(React.useState(false), 2), opened = _a[0], setOpened = _a[1];
    var onOpen = function () { return setOpened(true); };
    var onClose = function () { return setOpened(false); };
    var isSelected = React.useMemo(function () {
        return passengers.some(function (passenger) {
            return orderServices.some(function (service) { return selectedServices[passenger.id].hasOwnProperty(service.id); });
        });
    }, [selectedServices]);
    var selectedSegments = React.useMemo(function () {
        var selectedSegments = new Map();
        passengers.forEach(function (passenger) {
            orderServices.forEach(function (service) {
                var _a, _b, _c;
                if (((_b = (_a = selectedServices === null || selectedServices === void 0 ? void 0 : selectedServices[passenger.id]) === null || _a === void 0 ? void 0 : _a[service.id]) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                    (_c = selectedServices[passenger.id]) === null || _c === void 0 ? void 0 : _c[service.id].forEach(function (selectedService) {
                        var _a, _b, _c;
                        var passengerId = passenger.id;
                        var confirmedCount = ((_c = (_b = (_a = travellers
                            .find(function (_a) {
                            var id = _a.id;
                            return id === passengerId;
                        })) === null || _a === void 0 ? void 0 : _a.services.gdsServices) === null || _b === void 0 ? void 0 : _b.services.find(function (_a) {
                            var serviceId = _a.serviceId;
                            return serviceId === service.id;
                        })) === null || _c === void 0 ? void 0 : _c.confirmedCount) || 0;
                        var segment = {
                            passengerId: passenger.id,
                            serviceId: service.id,
                            segmentIds: selectedService.segments,
                            count: selectedService.number,
                            confirmedCount: confirmedCount,
                            passenger: passenger
                        };
                        if (selectedSegments.has(passengerId)) {
                            selectedSegments.set(passengerId, __spreadArray(__spreadArray([], __read(selectedSegments.get(passengerId)), false), [segment], false));
                        }
                        else {
                            selectedSegments.set(passengerId, [segment]);
                        }
                    });
                }
            });
        });
        return __spreadArray([], __read(selectedSegments.values()), false);
    }, [selectedServices]);
    var onSaveHandler = function (selectedServices) {
        batch(function () {
            selectedServices.forEach(function (selectedService) {
                dispatch(setService({
                    service: {
                        id: selectedService.serviceId,
                        type: type
                    },
                    serviceId: selectedService.serviceId,
                    segmentId: selectedService.segmentIds[0],
                    passengerId: selectedService.passengerId,
                    count: selectedService.count
                }));
            });
        });
        onClose();
    };
    var passengerPrices = React.useMemo(function () {
        return getPassengerPricesMap(passengers, orderServices);
    }, [passengers, orderServices]);
    var t = useTranslation('Checkout').t;
    var renderSubheader = function () {
        return (React.createElement(React.Fragment, null, passengerPrices.map(function (passenger) { return (React.createElement("span", null,
            t(PASSENGERS_TYPES[passenger.type]),
            ": ",
            React.createElement(Money, { money: passenger.price }))); })));
    };
    return {
        isSelected: isSelected,
        selectedServices: selectedServices,
        renderSubheader: renderSubheader,
        onOpen: onOpen,
        onClose: onClose,
        setOpened: setOpened,
        onSaveHandler: onSaveHandler,
        passengers: passengers,
        opened: opened,
        selectedSegments: selectedSegments
    };
};
