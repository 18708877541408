import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import Passenger from './Passenger/Passenger';
import Button from '../../Button/Button';
import { useTheme } from '../../theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { getIdsForSplitting, getPassengersWithChilds } from './utils';
var passengersReducer = function (state, action) {
    if (action.type === 'add') {
        return __spreadArray(__spreadArray([], __read(state), false), [action.id], false);
    }
    else if (action.type === 'remove') {
        return state.filter(function (id) { return id !== action.id; });
    }
    else if (action.type === 'clear') {
        return [];
    }
    return state;
};
var SplitPassengers = function (_a) {
    var passengers = _a.passengers, onDone = _a.onDone, loading = _a.loading;
    var _b = __read(React.useReducer(passengersReducer, []), 2), state = _b[0], dispatch = _b[1];
    var passengersWithChilds = React.useMemo(function () { return getPassengersWithChilds(passengers); }, [passengers]);
    var t = useTranslation('Checkout').t;
    var onOptionHandler = function (passengerId) {
        var isSelected = state.indexOf(passengerId) !== -1;
        dispatch({
            type: isSelected ? 'remove' : 'add',
            id: passengerId
        });
    };
    var theme = useTheme('SplitPassengers').SplitPassengers;
    var onClearHandler = function () {
        dispatch({
            type: 'clear'
        });
    };
    var onSubmitHandler = function (e) {
        e.preventDefault();
        onDone(getIdsForSplitting(state, passengersWithChilds), true);
    };
    var onOldPnrHandler = function () {
        onDone(getIdsForSplitting(state, passengersWithChilds), false);
    };
    var unselectedPassengersCount = passengersWithChilds.length - state.length;
    return (React.createElement("form", { className: theme.wrapper, onSubmit: onSubmitHandler },
        React.createElement("div", { className: theme.header__container },
            React.createElement("div", { className: theme.header }, t('Split passengers')),
            React.createElement(Button, { variant: "text", onClick: onClearHandler }, t('Clear'))),
        passengersWithChilds.map(function (passenger) {
            var isSelected = state.indexOf(passenger.passenger.id) !== -1;
            return (React.createElement(Passenger, { passengerWithChild: passenger, key: passenger.passenger.id, isSelected: isSelected, isDisabled: unselectedPassengersCount === 1 && !isSelected, onClick: onOptionHandler }));
        }),
        React.createElement("div", { className: theme.controls },
            !loading && (React.createElement(Button, { variant: "flat", className: cn(theme.button, theme.reset), onClick: onOldPnrHandler, name: "old_pnr", type: "button", disabled: !state.length }, t('Split and open old PNR'))),
            React.createElement(Button, { variant: "flat", className: theme.button, name: "new_pnr", type: "submit", isLoading: loading, disabled: !state.length }, t('Split and open new PNR')))));
};
export default SplitPassengers;
