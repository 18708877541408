import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { getUniqueValidOptions } from '../../Results/utils';
import { Money } from '../../Money';
import Tooltip from '../../Tooltip';
import Button from '../../Button';
import { icons, infoIcon, payment } from '../icons';
import CompareFares from '../../CompareFares';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import { useTheme } from '../../theme';
import RecommendedBadge from './RecommendedBadge/RecommendedBadge';
import { FareFeaturePaymentType, FlightClass } from '../../__generated__/globalTypes';
import Dialog from '../../Service/components/Dialog/Dialog';
import Modal from '../../Modal';
import { InfoMessageTypeEnum } from '../../__generated__/graphql';
import * as Icons from '../../Icons';
import Link from '../../Link';
import PriceInMiles from '../../PriceInMiles/PriceInMiles';
import Notification from '../../Notification';
initI18n('FareGroup');
var FareGroup = function (props) {
    var _a;
    var _b, _c, _d, _e, _f;
    var fare = props.fare, onSelect = props.onSelect, price = props.price, _g = props.variant, variant = _g === void 0 ? 'blue' : _g, _h = props.isDisabled, isDisabled = _h === void 0 ? false : _h, _j = props.excludeRepeatableKeyOptions, excludeRepeatableKeyOptions = _j === void 0 ? true : _j, _k = props.showUnavailableOptions, showUnavailableOptions = _k === void 0 ? false : _k, upgradeOption = props.upgradeOption, onUpgrade = props.onUpgrade, flightId = props.flightId, className = props.className;
    var css = useTheme('FareGroup').FareGroup;
    var options = getUniqueValidOptions(fare.options, false, false, showUnavailableOptions);
    var _l = __read(React.useState(false), 2), isModalOpen = _l[0], openModal = _l[1];
    var t = useTranslation('FareGroup').t;
    var handleOnSelect = function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (!isDisabled && onSelect) {
            onSelect();
        }
    };
    var renderOptionItem = function (option, index) {
        var _a;
        return (React.createElement("li", { key: index, className: cn(css.option, (_a = {},
                _a[css.option_unavailable] = option.availability === FareFeaturePaymentType.NotAvailable,
                _a[css.option_free] = option.availability === FareFeaturePaymentType.Free,
                _a)) },
            React.createElement("span", { className: css.option__title }, option.title),
            React.createElement(Tooltip, { title: option.description ? option.description : option.title, tooltipClassName: css.option__tooltip },
                React.createElement("span", { className: css.option__value }, option.value ? option.value : payment[option.availability]))));
    };
    var renderHeader = function (props) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.title },
                fare.title,
                !fare.description && (React.createElement("span", { onClick: function () { return openModal(true); }, className: css.info }, infoIcon)),
                fare.description && (React.createElement(Tooltip, { title: fare.description },
                    React.createElement("span", { onClick: function () { return openModal(true); }, className: css.info }, infoIcon)))),
            React.createElement("div", { className: css.keyFeatures }, options.map(function (option, index) {
                var _a;
                return option.isKeyOption &&
                    icons.hasOwnProperty(option.type) && (React.createElement(Tooltip, { key: index, title: option.description ? "".concat(option.title, " - ").concat(option.description) : option.title },
                    React.createElement("div", { className: cn(css.keyFeature, (_a = {},
                            _a[css.keyFeature_unavailable] = option.availability === FareFeaturePaymentType.NotAvailable,
                            _a[css.keyFeature_paid] = option.availability === FareFeaturePaymentType.Pay,
                            _a)) },
                        React.createElement("span", { className: css.keyFeature__icon }, icons[option.type]),
                        React.createElement("span", { className: css.keyFeature__value }, option.value ? option.value : payment[option.availability]))));
            }))));
    };
    var renderOptions = function (props) { return (React.createElement("ul", { className: css.options }, options.map(function (option, index) {
        return excludeRepeatableKeyOptions
            ? !option.isKeyOption && renderOptionItem(option, index)
            : renderOptionItem(option, index);
    }))); };
    var renderUpgradeOption = function (props) {
        return upgradeOption && (React.createElement("div", { className: css.upgradeOffer, onClick: onUpgrade },
            upgradeOption.upgradeOffer,
            React.createElement(Link, { action: function () { return null; }, className: css.upgradeOffer__link },
                t('Choose'),
                " ",
                upgradeOption.canUpgradeTo.fareFamily.title,
                Icons.Chevron)));
    };
    var renderButton = function (props) {
        var _a;
        return (React.createElement(Button, { className: css.button, disabled: isDisabled, variant: "flat", size: "large", onClick: handleOnSelect },
            t(isDisabled && !props.disabledForMiles ? 'Unavailable' : 'Select'),
            !isDisabled && !props.priceInMiles && React.createElement(Money, { moneyClassName: css.price, money: price }),
            props.priceInMiles > 0 && (React.createElement(PriceInMiles, { moneyClassName: cn(css.price, css.price_miles, (_a = {}, _a[css.disabled] = props.disabledForMiles, _a)), currencyClassName: css.currency, amount: props.priceInMiles })),
            isDisabled && Icons.NotIncluded));
    };
    return (React.createElement("div", { className: cn(className, css.fareGroup, (_a = {},
            _a[css.fareGroup_unavailable] = isDisabled,
            _a[css.fareGroup_Economy] = fare.category === FlightClass.Economy,
            _a[css.fareGroup_Business] = fare.category === FlightClass.Business,
            _a[css.fareGroup_white] = variant === 'white',
            _a)) },
        fare.isRecommended && !isDisabled && React.createElement(RecommendedBadge, null),
        React.createElement("div", null,
            ((_b = props.renderProps) === null || _b === void 0 ? void 0 : _b.renderHeader) ? props.renderProps.renderHeader(props) : renderHeader(props),
            ((_c = props.renderProps) === null || _c === void 0 ? void 0 : _c.renderOptions) ? props.renderProps.renderOptions(props) : renderOptions(props),
            ((_d = props.renderProps) === null || _d === void 0 ? void 0 : _d.renderUpgradeOption)
                ? props.renderProps.renderUpgradeOption(props)
                : renderUpgradeOption(props)),
        ((_e = props.renderProps) === null || _e === void 0 ? void 0 : _e.renderButton) ? props.renderProps.renderButton(props) : renderButton(props),
        ((_f = props.renderProps) === null || _f === void 0 ? void 0 : _f.renderRulesButton) && props.renderProps.renderRulesButton(props),
        props.priceInMiles && !props.disabledForMiles && (React.createElement("div", { className: css.taxes },
            t('Taxes'),
            ":",
            React.createElement(Money, { money: props.price }))),
        props.disabledForMiles && (React.createElement(Notification, { className: css.miles_notification, type: 'context', status: InfoMessageTypeEnum.Info, infoMessage: {
                text: t('Not enough miles on your card'),
                type: InfoMessageTypeEnum.Info,
                code: ''
            } })),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dialog, { isOpen: isModalOpen, dialogType: "fullscreen", dialogHeaderClassName: css.dialog__header, dialogContentClassName: css.dialog__content, dialogHeader: React.createElement("span", null, t('Compare fare families')), onClose: function () { return openModal(false); }, content: React.createElement(CompareFares, { code: fare.airline.iata, flightId: flightId }), mountOnEnter: true })),
        React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
            React.createElement(Modal, { open: isModalOpen, onClose: function () { return openModal(false); } },
                React.createElement(CompareFares, { code: fare.airline.iata, flightId: flightId })))));
};
export default FareGroup;
