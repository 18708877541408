import * as React from 'react';
import cn from 'classnames';
import { mealIcon } from '../../../Icons';
import { useTheme } from '../../../../theme';
import { getPassengersNames, instanceOfMeal } from '../../../utils';
import { useTranslation } from 'react-i18next';
var MealStep = function (_a) {
    var _b, _c;
    var order = _a.order, onChange = _a.onChange, passengers = _a.passengers;
    var css = useTheme('MobileStepbar').StepContentStyles;
    var t = useTranslation().t;
    var passengerNames = getPassengersNames(passengers);
    var meals = [];
    (_c = (_b = order.additionalServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(function (service) {
        if (instanceOfMeal(service)) {
            meals.push(service);
        }
    });
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.content }, order.segments.map(function (segment, segmentIndex) { return (React.createElement("div", { key: segmentIndex, className: css.content__item },
            React.createElement("div", { className: cn(css.item__row, css.segment__header) },
                React.createElement("span", { className: css.content__icon }, mealIcon),
                React.createElement("span", { className: css.header__text },
                    segment.segment.departure.airport.city.name,
                    " (",
                    segment.segment.departure.airport.iata,
                    ") - ",
                    segment.segment.arrival.airport.city.name,
                    " (",
                    segment.segment.arrival.airport.iata,
                    ")")),
            React.createElement("div", null, passengers.map(function (passenger) {
                var _a;
                var passengerMealCount = 0;
                (_a = passenger.services) === null || _a === void 0 ? void 0 : _a.forEach(function (service) {
                    if (meals.find(function (meal) { return meal.id === service.serviceId; }) &&
                        segment.segment.id === service.segmentIds[0]) {
                        passengerMealCount += service.count;
                    }
                });
                return (React.createElement("div", { key: passenger.id, className: css.passenger },
                    React.createElement("span", { className: css.passenger__name }, passengerNames[passenger.id]),
                    React.createElement("span", { className: css.passenger__seat }, passengerMealCount > 0
                        ? t('MealService:serving', { count: passengerMealCount })
                        : t('MobileStepbar:Not selected'))));
            })))); })),
        React.createElement("div", { className: css.footer },
            React.createElement("span", { onClick: onChange }, t('MobileStepbar:Change')))));
};
export default MealStep;
