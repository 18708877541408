import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import DesktopFlightInfo from '../../../DesktopFlightInfo';
import Header from '../Header/Header';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import { useDesktopFlightWithPopup } from './hooks';
import Modal from '../../../Modal';
import DesktopFlightModalContent from '../DesktopFlightModalContent/DesktopFlightModalContent';
initI18n('DesktopFlight');
var DesktopFlightPopup = function (props) {
    var _a;
    var _b;
    var _c = useTheme('DesktopFlight'), css = _c.Flight, popupCss = _c.DesktopFlightPopup;
    var t = useTranslation().t;
    var _d = useDesktopFlightWithPopup(props), flightBlock = _d.flightBlock, lastSelectedFare = _d.lastSelectedFare, activeTab = _d.activeTab, getFareGroupTabHandler = _d.getFareGroupTabHandler, handleFlightInfoTab = _d.handleFlightInfoTab, filteredPrices = _d.filteredPrices, handleEconomyFareGroupsTab = _d.handleEconomyFareGroupsTab, isModalOpen = _d.isModalOpen, closeModal = _d.closeModal, businessFareGroups = _d.businessFareGroups, economyFareGroups = _d.economyFareGroups, milesFareGroups = _d.milesFareGroups, getOnFareSelect = _d.getOnFareSelect, upgradeOptions = _d.upgradeOptions;
    return (React.createElement("div", { ref: flightBlock, className: cn(css.flight, (_a = {},
            _a[css.flight_open] = activeTab !== null,
            _a)) },
        React.createElement(Header, { flight: props.flight, getOnFareClickHandler: getFareGroupTabHandler, onInfoClick: handleFlightInfoTab, activeTab: activeTab, singleFare: props.singleFare, prices: filteredPrices, lastSelectedFare: lastSelectedFare }),
        React.createElement(Collapse, { in: activeTab !== null, mountOnEnter: true },
            React.createElement("div", { className: css.flight__content },
                React.createElement(DesktopFlightInfo, { flight: props.flight, onButtonClick: handleEconomyFareGroupsTab, buttonText: t('DesktopFlight:Select fare') }))),
        React.createElement(Modal, { open: isModalOpen, onClose: closeModal, isRounded: true, classes: {
                paper: popupCss.paper
            } },
            React.createElement(DesktopFlightModalContent, { flight: props.flight, economyFares: economyFareGroups, businessFares: businessFareGroups, milesFares: milesFareGroups, lastSelectedFare: (_b = props.flight.pricesForFareGroups.find(function (priceGroup) { return priceGroup.fareFamily && priceGroup.fareFamily.id === lastSelectedFare; })) === null || _b === void 0 ? void 0 : _b.fareFamily, getOnFareSelect: getOnFareSelect, upgradeOptions: upgradeOptions }))));
};
export default DesktopFlightPopup;
