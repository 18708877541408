import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useTheme } from '../../../../theme';
export var Welcome = function (_a) {
    var onSuccess = _a.onSuccess, isFirstTimeLogin = _a.isFirstTimeLogin;
    var theme = useTheme('LoginPage').Welcome;
    var t = useTranslation('LoginPage').t;
    useEffect(function () {
        setTimeout(function () {
            if (onSuccess) {
                onSuccess(isFirstTimeLogin);
            }
            else {
                window.location.reload();
            }
        }, 1300);
    }, []);
    return (React.createElement("div", { className: theme.LoginWelcome },
        React.createElement("p", { className: theme.welcomeMsg },
            t(isFirstTimeLogin ? 'Welcome' : 'Welcome back'),
            "!"),
        React.createElement("p", { className: theme.descriptionMsg }, t('you have just logged in'))));
};
export default Welcome;
