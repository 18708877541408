import { createSelector } from 'reselect';
import { Unvoluntary } from '../types';
import { ExareReason } from '../../__generated__/graphql';
import { getCustomer } from './customer/selectors';
import { getSelectedPassengersWithChildrenIds, getPassengersWithChildrenIds } from './passengers/selectors';
import { getSelectedReason } from './reason/selectors';
import { getRefundFiles } from './documents/selectors';
import { useConfig } from '../../context';
import { useIsTerminal } from '../../hooks';
export var getState = function (state) { return state; };
export var getRefundMode = createSelector(getState, function (state) { return state.mode; });
export var isAutoRefundMode = createSelector(getSelectedReason, useConfig, function (reason, config) {
    return reason === Unvoluntary.Unvoluntary || useIsTerminal();
});
export var getIsRefundVoluntary = createSelector(getState, function (state) { return !!ExareReason[state.reason]; });
export var createGetCreateRefundParams = function (orderId) {
    return createSelector(getCustomer, getSelectedPassengersWithChildrenIds, getPassengersWithChildrenIds, getSelectedReason, getRefundFiles, useConfig, function (customer, selectedPassengersIds, passengers, selectedReason, refundFiles, config) {
        var _a;
        if (!customer) {
            return null;
        }
        var isVoluntary = selectedReason === Unvoluntary.Unvoluntary;
        return {
            orderId: orderId,
            isCompelled: !isVoluntary,
            passengers: useIsTerminal() ? passengers : selectedPassengersIds,
            email: customer.email,
            name: customer.name,
            phone: customer.phone,
            comment: (_a = customer.comments) !== null && _a !== void 0 ? _a : '',
            files: isVoluntary ? [] : refundFiles,
            reason: isVoluntary ? null : selectedReason
        };
    });
};
