import * as React from 'react';
import cn from 'classnames';
import { Bike, Choosed, Serfing, Ski, SportEquipment } from '../../../../../Icons';
import { useTheme } from '../../../../../theme';
var HeaderEquipmentItem = function (_a) {
    var _b;
    var className = _a.className, rfics = _a.rfics, icon = _a.icon, isActive = _a.isActive, isSelected = _a.isSelected, onClick = _a.onClick;
    var css = useTheme('UpgradeBaggageDialog').HeaderEquipmentItem;
    var typesByRfics = {
        '0EF': Ski,
        '0EG': Ski,
        '0EH': Ski,
        '0EC': Bike,
        '0EI': Serfing,
        '0DD': Ski
    };
    return (React.createElement("div", { className: cn(className, css.wrapper, (_b = {},
            _b[css.active] = isActive,
            _b)), onClick: onClick },
        isSelected && React.createElement("div", { className: css.selected }, Choosed),
        icon || (typesByRfics === null || typesByRfics === void 0 ? void 0 : typesByRfics[rfics]) || SportEquipment));
};
export default HeaderEquipmentItem;
