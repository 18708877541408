import { __read, __spreadArray, __values } from "tslib";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { BaggageType } from '../../../__generated__/globalTypes';
import { Money } from '../../../Money';
import { useTheme } from '../../../theme';
import BaggageCounter from '../BaggageCounter/BaggageCounter';
import { AdditionalTypes } from '../../types';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import AdditionalMobile from '../AdditionalMobile/AdditionalMobile';
import { Currency } from '../../../enums';
import { getBaggageWeightGraduation, isSelectedBaggage, MAX_COUNTER_VALUE_BLOCKED, MAX_COUNTER_VALUE_FREE, SERVICES_TYPES_TO_BLOCK } from '../../utils';
import memes from '../../../memes';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { increaseIcon, selectedIcon } from '../../Icons';
import { useConfig } from '../../../context';
import MobileHandLuggageExcess from './AdditionalBaggage/MobileHandLuggageExcess/MobileHandLuggageExcess';
import { useAdditionalBaggageConfirmedMap } from '../../hooks';
var BaggageList = React.memo(function (props) {
    var _a, _b;
    var _c, _d;
    var theme = useTheme('BaggageService').BaggageServiceStyles;
    var t = useTranslation().t;
    var _e = __read(React.useState(null), 2), isOpenedAdditionalService = _e[0], setOpenedAdditionalService = _e[1];
    var includedBaggageCount = props.baggage.filter(function (baggage) { return baggage.included && baggage.type === BaggageType.CheckedBaggage; }).length;
    var isMobile = useMediaQuery({
        maxWidth: MOBILE_MIN_WIDTH
    });
    var _f = useAdditionalBaggageConfirmedMap(__spreadArray(__spreadArray(__spreadArray([], __read(props.additionalBaggage.baggageExcess), false), __read(props.additionalBaggage.handLuggageExcess), false), __read(props.additionalBaggage.sportsEquipment), false).map(function (baggageItem) { return baggageItem.baggage; }), props.selectedBaggage), hasConfirmedHandLuggageExcess = _f.hasConfirmedHandLuggageExcess, hasConfirmedBaggageExcess = _f.hasConfirmedBaggageExcess, hasConfirmedSportsEquipment = _f.hasConfirmedSportsEquipment;
    var hasBaggageInSelected = function (baggageId) {
        if (props.selectedBaggage.hasOwnProperty(baggageId) && !isMobile) {
            return !!props.selectedBaggage[baggageId];
        }
        else if (isMobile && props.segment) {
            return (props.selectedBaggage.hasOwnProperty(baggageId) &&
                props.selectedBaggage[baggageId] &&
                props.selectedBaggage[baggageId].hasOwnProperty(props.segment.segmentId));
        }
        else {
            return false;
        }
    };
    var getSelectedBaggageCount = function (baggageId) {
        var e_1, _a;
        if (!props.selectedBaggage.hasOwnProperty(baggageId)) {
            return null;
        }
        var count = 0;
        try {
            for (var _b = __values(Object.entries(props.selectedBaggage[baggageId])), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), baggageOnSegment = _d[1];
                count += baggageOnSegment.count;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return count;
    };
    var _g = useConfig().global, maxBaggage = _g.maxBaggage, dynamicBaggagePricing = _g.dynamicBaggagePricing, maxBaggagePerPassenger = _g.maxBaggagePerPassenger;
    var minExcessBaggagePrice = { amount: Infinity, currency: Currency.RUB };
    var minExcessBaggageValue = Infinity;
    props.additionalBaggage.baggageExcess.forEach(function (baggage) {
        if (baggage.baggage.price.amount < minExcessBaggagePrice.amount) {
            minExcessBaggagePrice = baggage.baggage.price;
        }
        if (baggage.baggage.value.amount < minExcessBaggageValue) {
            minExcessBaggageValue = baggage.baggage.value.amount;
        }
    });
    var availableSegments = isOpenedAdditionalService === AdditionalTypes.Overweight &&
        ((_c = props.additionalBaggage.baggageExcess) === null || _c === void 0 ? void 0 : _c[0].baggage.segmentsId.map(function (segmentIds) {
            return props.segments.find(function (segment) { return segmentIds.includes(segment.segmentId); });
        }));
    var getBaggageIconClassName = memes(
    // construct array of min average and max values of baggage
    // than return related class name for each service: sm|md|lg
    function (service) {
        var graduation = getBaggageWeightGraduation(props.baggage);
        var _a = __read(graduation, 3), min = _a[0], average = _a[1], max = _a[2];
        var closest = graduation.reduce(function (prev, curr) {
            return Math.abs(curr - service.value.amount) < Math.abs(prev - service.value.amount) ? curr : prev;
        });
        switch (closest) {
            case min:
                return theme.baggage__icon_sm;
            case average:
                return theme.baggage__icon_md;
            case max:
                return theme.baggage__icon_lg;
            default:
                return theme.baggage__icon_md;
        }
    });
    return (React.createElement(React.Fragment, null,
        props.baggage.map(function (service, index) {
            var _a, _b, _c, _d, _e;
            var _f, _g, _h, _j, _k;
            var isHidden = isMobile &&
                props.segment &&
                !service.segmentsId.some(function (serviceSegments) {
                    return serviceSegments.some(function (serviceSegment) { return serviceSegment === props.segment.segmentId; });
                });
            var isReadonly = props.readonly ||
                !service.segmentsId.some(function (serviceSegments) {
                    return serviceSegments.some(function (serviceSegment) { return serviceSegment === props.segment.segmentId; });
                });
            if (!maxBaggagePerPassenger || dynamicBaggagePricing) {
                isReadonly =
                    isReadonly ||
                        (SERVICES_TYPES_TO_BLOCK.includes(service.type) &&
                            !service.included &&
                            !isSelectedBaggage(service.id, props.baggage, props.selectedBaggage));
            }
            var maxValue = MAX_COUNTER_VALUE_BLOCKED;
            if (maxBaggage) {
                maxValue = maxBaggage;
            }
            if (!service.canBeAdded && ((_f = props.selectedBaggage[service.id]) === null || _f === void 0 ? void 0 : _f[props.segment.segmentId])) {
                maxValue = (_g = props.selectedBaggage[service.id]) === null || _g === void 0 ? void 0 : _g[props.segment.segmentId].count;
            }
            if (maxBaggagePerPassenger && props.canAddBaggageBySegments) {
                if (props === null || props === void 0 ? void 0 : props.canAddBaggageBySegments.get(props.segment.segmentId)) {
                    maxValue = maxBaggage
                        ? Math.min(maxBaggage, maxBaggagePerPassenger) - includedBaggageCount
                        : maxBaggagePerPassenger;
                }
                else {
                    maxValue = 0;
                }
            }
            if (props.simpleBaggageSelector &&
                !!((_k = (_j = (_h = props.selectedBaggage) === null || _h === void 0 ? void 0 : _h[service === null || service === void 0 ? void 0 : service.id]) === null || _j === void 0 ? void 0 : _j[props.segment.segmentId]) === null || _k === void 0 ? void 0 : _k.confirmedCount)) {
                // if the service is confirmed, do not allow to refuse it
                isReadonly = true;
            }
            return !isHidden ? (React.createElement("div", { className: cn(theme.service, (_a = {},
                    _a[theme.baggage_selected] = hasBaggageInSelected(service.id),
                    _a[theme.baggage_included] = service.included,
                    _a[theme.baggage_inDialog] = !props.fullscreen,
                    _a)), key: index },
                React.createElement("div", null,
                    React.createElement("div", { className: theme.service__name }, service.name),
                    React.createElement("div", { className: theme.service__sizes }, service.size)),
                React.createElement("div", { className: cn(theme.service__icon, service.type === BaggageType.CheckedBaggage && getBaggageIconClassName(service), (_b = {},
                        _b[theme.carryon] = service.type === BaggageType.CarryOn,
                        _b[theme.baggage] = service.type === BaggageType.CheckedBaggage,
                        _b[theme.baggage__icon_notSelected] = !hasBaggageInSelected(service.id) && !service.included,
                        _b[theme.baggage__icon_selected] = hasBaggageInSelected(service.id) || service.included,
                        _b[theme.baggage__icon_included] = service.included,
                        _b)) }),
                React.createElement("div", null,
                    React.createElement("div", { className: cn(theme.service__values, (_c = {},
                            _c[theme.baggage__icon_notSelected] = !hasBaggageInSelected(service.id) && !service.included,
                            _c)) },
                        React.createElement(MediaQuery, { maxWidth: "tablet" }, hasBaggageInSelected(service.id)
                            ? !service.included &&
                                (getSelectedBaggageCount(service.id) === 1
                                    ? null
                                    : "".concat(getSelectedBaggageCount(service.id), "x"))
                            : null),
                        React.createElement(MediaQuery, { minWidth: "tablet" }, hasBaggageInSelected(service.id)
                            ? !service.included &&
                                (getSelectedBaggageCount(service.id) === 1
                                    ? null
                                    : "".concat(getSelectedBaggageCount(service.id), "x"))
                            : null),
                        !Number.isNaN(service.value.amount) && (React.createElement(React.Fragment, null,
                            service.value.amount,
                            service.value.measurement))),
                    !props.simpleBaggageSelector && !!service.included && (React.createElement("div", { className: theme.service__price }, t('BaggageService:included'))),
                    !service.included ? (React.createElement(Money, { money: service.price, moneyClassName: cn(theme.service__price, (_d = {},
                            _d[theme.baggage__icon_notSelected] = !hasBaggageInSelected(service.id) && !service.included,
                            _d)), multiplyBy: service &&
                            service.segmentsId &&
                            service.segmentsId.find(function (seg) { return seg.includes(props.segment.segmentId); }) &&
                            service.segmentsId.find(function (seg) { return seg.includes(props.segment.segmentId); }).length })) : (props.simpleBaggageSelector && React.createElement("span", { className: theme.service__price }))),
                props.simpleBaggageSelector && (React.createElement(Button, { variant: "text", className: cn(theme.baggage__addButton, (_e = {},
                        _e[theme.baggage__addButton_add] = !hasBaggageInSelected(service.id) && !service.included,
                        _e[theme.baggage__addButton_included] = service.included,
                        _e[theme.baggage__addButton_inDialog] = !props.fullscreen,
                        _e)), disableTouchRipple: !!service.included, classes: { disabled: theme.baggage__addButton_disabled }, disabled: isReadonly, onClick: function () {
                        if (hasBaggageInSelected(service.id)) {
                            props.onBaggageDecrease(props.segment.segmentId, service.id, props.sameBaggageForEachFlight);
                        }
                        else if (!hasBaggageInSelected(service.id) && !service.included) {
                            props.onBaggageIncrease(props.segment.segmentId, service.id, props.sameBaggageForEachFlight);
                        }
                    } },
                    React.createElement("span", { className: theme.addButton__icon }, service.included || hasBaggageInSelected(service.id) ? selectedIcon : increaseIcon),
                    service.included
                        ? t('BaggageService:included')
                        : hasBaggageInSelected(service.id)
                            ? t('BaggageService:Selected')
                            : t('BaggageService:Add'))),
                React.createElement(MediaQuery, { maxWidth: "tablet" }, !service.included && !props.simpleBaggageSelector && (React.createElement(BaggageCounter, { selectedBaggage: props.selectedBaggage, sameBaggageForEachFlight: props.sameBaggageForEachFlight, onBaggageIncrease: props.onBaggageIncrease, onBaggageDecrease: props.onBaggageDecrease, service: service, segment: props.segment, maxValue: [BaggageType.CheckedBaggage, BaggageType.CarryOn].includes(service.type)
                        ? maxValue
                        : MAX_COUNTER_VALUE_FREE, readonly: isReadonly }))))) : null;
        }),
        React.createElement(MediaQuery, { maxWidth: "tablet" },
            React.createElement(MobileHandLuggageExcess, { readonly: hasConfirmedHandLuggageExcess, services: props.additionalBaggage.handLuggageExcess, selected: props.hasHandLuggageExcess, onSelect: props.onHandLuggageExcessSelect, onToggle: props.onHandLuggageExcessToggle, segments: props.segments }),
            !!props.additionalBaggage.baggageExcess.length && (React.createElement("div", { className: theme.service, onClick: function () {
                    setOpenedAdditionalService(AdditionalTypes.Overweight);
                } },
                React.createElement("div", { className: theme.service__name }, t('BaggageService:Overweight')),
                React.createElement("div", { className: theme.service__sizes }),
                React.createElement("div", { className: cn(theme.service__icon, theme.overweight__disabled, (_a = {},
                        _a[theme.overweight__active] = props.hasBaggageExcess,
                        _a)) }),
                React.createElement("div", { className: theme.service__values }, t("BaggageService:up to {{value}} kg", { value: minExcessBaggageValue })),
                React.createElement("div", { className: theme.service__price },
                    React.createElement(Money, { money: minExcessBaggagePrice, multiplyBy: (_d = props.additionalBaggage.baggageExcess) === null || _d === void 0 ? void 0 : _d[0].baggage.segmentsId.length })),
                !hasConfirmedBaggageExcess && (React.createElement("div", { className: cn(theme.add__button, (_b = {},
                        _b[theme.remove__button] = props.hasBaggageExcess,
                        _b)), onClick: function (event) {
                        props.hasBaggageExcess
                            ? props.onExcessBaggageToggle(false)
                            : setOpenedAdditionalService(AdditionalTypes.Overweight);
                        event.stopPropagation();
                    } })))),
            !!props.additionalBaggage.sportsEquipment.length &&
                props.additionalBaggage.sportsEquipment.map(function (service) {
                    var _a;
                    if (service.isSelected) {
                        return (React.createElement("div", { className: theme.service, onClick: function () { return setOpenedAdditionalService(AdditionalTypes.Sport); } },
                            React.createElement("div", { className: theme.service__name }, t("BaggageService:".concat(service.baggage.name))),
                            React.createElement("div", { className: theme.service__sizes }, service.baggage.size),
                            React.createElement("div", { className: cn(theme.service__icon, theme.sports__icon) }),
                            React.createElement("div", { className: theme.service__values },
                                service.baggage.value.amount,
                                " ",
                                service.baggage.value.measurement),
                            React.createElement("div", { className: theme.service__price },
                                React.createElement(Money, { money: service.baggage.price })),
                            !hasConfirmedSportsEquipment && (React.createElement("div", { onClick: function (event) {
                                    props.onSportsEquipSelect(service.baggage.id, !service.isSelected);
                                    event.stopPropagation();
                                }, className: cn(theme.add__button, (_a = {},
                                    _a[theme.remove__button] = props.additionalBaggage.sportsEquipment.find(function (sport) { return sport.baggage.id === service.baggage.id; }),
                                    _a)) }))));
                    }
                    else {
                        return null;
                    }
                }),
            !!props.additionalBaggage.sportsEquipment.length && (React.createElement("div", { className: theme.slide__sport, onClick: function () { return setOpenedAdditionalService(AdditionalTypes.Sport); } },
                React.createElement("div", { className: theme.slide__sport__content }, t('BaggageService:Add sports equipment')))),
            ReactDOM.createPortal(React.createElement(AdditionalMobile, { isOpen: isOpenedAdditionalService !== null, onClose: function () { return setOpenedAdditionalService(null); }, services: isOpenedAdditionalService === AdditionalTypes.Overweight
                    ? props.additionalBaggage.baggageExcess.sort(function (a, b) { return a.baggage.price.amount - b.baggage.price.amount; })
                    : props.additionalBaggage.sportsEquipment, header: isOpenedAdditionalService === AdditionalTypes.Overweight
                    ? t('BaggageService:Overweight')
                    : t('BaggageService:Sports equipment'), type: isOpenedAdditionalService === AdditionalTypes.Overweight ? AdditionalTypes.Overweight : null, hasExcessBaggage: props.hasBaggageExcess, hasSportEquipment: props.hasSportEquipment, hasHandLuggageExcess: false, availableSegments: availableSegments, isAvailableForAllSegments: availableSegments.length === props.segments.length, description: t('BaggageService:Improve baggage weight you can bring to airplane'), onSelect: isOpenedAdditionalService === AdditionalTypes.Overweight
                    ? props.onBaggageExcessSelect
                    : props.onSportsEquipSelect, onToggle: isOpenedAdditionalService === AdditionalTypes.Overweight
                    ? props.onExcessBaggageToggle
                    : props.onSportsEquipToggle, selectInputType: isOpenedAdditionalService === AdditionalTypes.Overweight ? 'radio' : 'checkbox' }), document.body))));
});
export default BaggageList;
