import React, { useCallback } from 'react';
import { useTheme } from '../../../../theme';
var DisplayCameraError = function (props) {
    var css = useTheme('DocRecognition').ScanDocs;
    var isShowComponent = useCallback(function () {
        return props.errorMsg && props.errorMsg.length > 0;
    }, [props.errorMsg]);
    return isShowComponent() ? (React.createElement("div", { className: css.cameraErrorBlock },
        React.createElement("div", { className: css.cameraErrorText }, props.errorMsg))) : (React.createElement(React.Fragment, null));
};
export default DisplayCameraError;
