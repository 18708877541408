import { __extends } from "tslib";
import * as React from 'react';
import { captureErrorSentry } from '../utils';
import { CheckinFatalError } from '../Modules/Checkin/components/Checkin/CheckinErrorBoundary/CheckinErrors';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            error: null
        };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        if (error instanceof CheckinFatalError) {
            return {
                error: error
            };
        }
        else {
            return {
                error: null
            };
        }
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        if (error instanceof CheckinFatalError) {
            captureErrorSentry("".concat(error === null || error === void 0 ? void 0 : error.name, ": ").concat(error === null || error === void 0 ? void 0 : error.message));
        }
    };
    ErrorBoundary.prototype.render = function () {
        var _a = this.props, children = _a.children, renderFallback = _a.renderFallback;
        return (React.createElement(React.Fragment, null,
            renderFallback(this.state.error),
            !this.state.error && children));
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
