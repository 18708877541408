import * as React from 'react';
import Button from '@material-ui/core/Button';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { initI18n } from '../utils';
initI18n('MobileToolbar');
var MobileToolbar = function (_a) {
    var onBack = _a.onBack, onContinue = _a.onContinue, continueButtonText = _a.continueButtonText;
    var css = useTheme('MobileToolbar').MobileToolbar;
    var t = useTranslation('MobileToolbar').t;
    return (React.createElement("div", { className: css.wrapper },
        !!onBack && (React.createElement(Button, { className: cn(css.button, css.button_back), onClick: onBack }, t('Back'))),
        typeof onContinue !== 'undefined' && (React.createElement(Button, { type: onContinue ? 'button' : 'submit', className: cn(css.button, css.button_continue), onClick: onContinue }, continueButtonText !== null && continueButtonText !== void 0 ? continueButtonText : t('Continue')))));
};
export default MobileToolbar;
