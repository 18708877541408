import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import ReCAPTCHA from 'react-google-recaptcha';
import { getLocale, initI18n } from '../../utils';
import { useTheme } from '../../theme';
import * as ReCaptchaQuery from '../../__queries__/ReCaptcha.graphql';
import * as ReCaptchaVerifyMutation from '../../__queries__/ReCaptchaVerify.graphql';
initI18n('RecaptchaPage');
var botIcon = (React.createElement("svg", { width: "99", height: "99", viewBox: "0 0 99 99", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M78.4796 38.1235V73.7933L99 79.8766V99H0V79.8748L20.5202 73.8088V38.1235H15.7512V41.6496H9.93241V28.7966H15.7512V32.3227H20.9913C23.2839 19.7957 33.707 10.0622 46.5905 8.776V0H52.4093V8.776C65.2928 10.0622 75.7159 19.7957 78.0085 32.3225H83.2486V28.7964H89.0674V41.6496H83.2486V38.1235H78.4796ZM49.4999 14.4318C48.476 14.4318 47.4676 14.4992 46.4784 14.6282V24.8009H52.5214V14.6282C51.5322 14.4992 50.5238 14.4318 49.4999 14.4318ZM40.6595 16.1813C32.2617 19.6515 26.339 27.9093 26.339 37.5212V73.1101H72.6605V37.5212C72.6605 27.9093 66.7378 19.6515 58.3399 16.1813V30.6016H40.6595V16.1813ZM52.5214 93.1992V78.9107H46.4784V93.1992H52.5214ZM23.7285 78.9107L5.81875 84.2049V93.1992H40.6597V78.9107H23.7285ZM58.3401 93.1992H93.1811H93.1813V84.2032L75.3276 78.9107H58.3401V93.1992ZM38.0498 49.9069H60.95C65.7627 49.9069 69.6782 53.8102 69.6782 58.608C69.6782 63.4059 65.7627 67.3092 60.95 67.3092H38.0498C33.2371 67.3092 29.3217 63.4059 29.3217 58.608C29.3217 53.8102 33.2371 49.9069 38.0498 49.9069ZM52.4093 61.5084V55.7076H46.5905V61.5084H52.4093ZM38.0498 55.7076C36.4455 55.7076 35.1404 57.0088 35.1404 58.608C35.1404 60.2075 36.4455 61.5084 38.0498 61.5084H40.7718V55.7076H38.0498ZM58.228 61.5084H60.95C62.5543 61.5084 63.8594 60.2073 63.8594 58.608C63.8594 57.0088 62.5543 55.7076 60.95 55.7076H58.228V61.5084ZM34.2762 39.325L38.3901 35.2238L42.504 39.325L38.3901 43.4261L34.2762 39.325ZM60.5905 35.2261L56.4767 39.3273L60.5905 43.4284L64.7044 39.3273L60.5905 35.2261Z", fill: "#D1D1D1" })));
var RecaptchaPage = function (_a) {
    var _b, _c;
    var onTokenSend = _a.onTokenSend;
    var css = useTheme('RecaptchaPage').RecaptchaPage;
    var t = useTranslation('RecaptchaPage').t;
    var _d = __read(useLazyQuery(ReCaptchaQuery.ReCaptcha), 2), loadSitekey = _d[0], data = _d[1].data;
    var _e = __read(useMutation(ReCaptchaVerifyMutation.ReCaptchaVerify), 1), sendToken = _e[0];
    var onSubmit = function (token) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, sendToken({ variables: { token: token } })];
                case 1:
                    _a.sent();
                    if (onTokenSend) {
                        onTokenSend();
                    }
                    else {
                        setTimeout(function () { return window.location.reload(); }, 200);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        loadSitekey();
    }, []);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.left }, botIcon),
        React.createElement("div", { className: css.right },
            React.createElement("h1", { className: css.title }, t("You're not a bot, are you?")),
            !!((_b = data === null || data === void 0 ? void 0 : data.ReCaptcha) === null || _b === void 0 ? void 0 : _b.siteKey) && (React.createElement(ReCAPTCHA, { sitekey: (_c = data === null || data === void 0 ? void 0 : data.ReCaptcha) === null || _c === void 0 ? void 0 : _c.siteKey, hl: getLocale(), onChange: onSubmit })))));
};
export default RecaptchaPage;
