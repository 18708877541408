import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import StepTitle from '../StepTitle/StepTitle';
import StepsButtons from '../StepsButtons/StepsButtons';
import AddFiles from '../AddFiles/AddFiles';
import MobileStep from '../MobileStep/MobileStep';
import { AttachFile } from '../../../Icons';
import { useTheme } from '../../../theme';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { useSteps } from '../Refund/hooks';
import { StepType } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { getDocuments } from '../../store/documents/selectors';
import { getSelectedReason } from '../../store/reason/selectors';
import { getPassengers, getSelectedPassengers } from '../../store/passengers/selectors';
import { setDocuments } from '../../store/documents/actions';
import cn from 'classnames';
var AddFilesPage = function (props) {
    var _a;
    var css = useTheme('Refund').Refund;
    var t = useTranslation('Refund').t;
    var docsToFilesMap = props.docsToFilesMap;
    var selectedDocuments = useSelector(getDocuments);
    var selectedReason = useSelector(getSelectedReason);
    var selectedPassengers = useSelector(getSelectedPassengers);
    var passengers = useSelector(getPassengers);
    var _b = useSteps(), currentStep = _b.currentStep, canGoToNextStep = _b.canGoToNextStep, goToNextStep = _b.goToNextStep, goToPrevStep = _b.goToPrevStep, isCurrentActiveStepAfter = _b.isCurrentActiveStepAfter, handleMobileCloseClick = _b.handleMobileCloseClick;
    var dispatch = useDispatch();
    var handleSetDocuments = useCallback(function (documents) { return dispatch(setDocuments(documents)); }, [
        passengers
    ]);
    var isActive = currentStep.type === StepType.AttachFiles;
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: 'mobile' }, isActive && (React.createElement(React.Fragment, null,
            React.createElement(StepTitle, { className: css.stepTitle, icon: AttachFile, title: t('Attach the required files') }),
            React.createElement(AddFiles, { selectedDocuments: selectedDocuments, refundReason: selectedReason, docsFilesMap: docsToFilesMap, passengers: selectedPassengers, onFilesLoaded: handleSetDocuments }),
            React.createElement(StepsButtons, { className: css.buttons, position: 'center', backButton: { children: t('Back'), onClick: goToPrevStep }, nextButton: {
                    children: t('Continue'),
                    onClick: goToNextStep,
                    disabled: !canGoToNextStep
                } })))),
        React.createElement(MobileStep, { isActive: currentStep.type === StepType.AttachFiles, onCloseClick: handleMobileCloseClick(StepType.AttachFiles), className: cn(css.mobileStep, (_a = {},
                _a[css.passed] = isCurrentActiveStepAfter(StepType.AttachFiles),
                _a)), icon: AttachFile, renderTitle: function () { return React.createElement("span", null, t('Attach the required files')); } },
            React.createElement(AddFiles, { selectedDocuments: selectedDocuments, refundReason: selectedReason, docsFilesMap: docsToFilesMap, passengers: selectedPassengers, onFilesLoaded: handleSetDocuments }))));
};
export default AddFilesPage;
