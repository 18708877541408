import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { currency } from '../../__fakeData__';
var Taxes = function (props) {
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    var renderAirlineTaxes = useCallback(function () {
        return props.airlineTaxes.map(function (tax) { return (React.createElement("tr", { key: tax.code, className: theme.table_bg_gray },
            React.createElement("td", null,
                React.createElement("div", { className: theme.table__cell }, tax.code)),
            React.createElement("td", null,
                React.createElement("div", { className: theme.table__cell }, tax.amount)))); });
    }, [props.airlineTaxes]);
    var renderAgencyTaxes = useCallback(function () {
        return props.agencyTaxes.map(function (tax) { return (React.createElement("tr", { key: tax.code, className: theme.table_bg_gray },
            React.createElement("td", null,
                React.createElement("div", { className: theme.table__cell }, tax.code)),
            React.createElement("td", null,
                React.createElement("div", { className: theme.table__cell }, tax.amount)))); });
    }, [props.agencyTaxes]);
    return (React.createElement("div", { className: theme.taxes },
        React.createElement("div", { className: theme.taxes__table },
            React.createElement("div", { className: theme.sectionBlock__headlineInner },
                t('Airline taxes & fees'),
                " (",
                currency,
                ")"),
            React.createElement("table", { className: cn(theme.table, theme.table_align_center, theme.table_border_full, theme.table_layout_fix) },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null,
                            React.createElement("div", { className: theme.table__cell }, t('Code'))),
                        React.createElement("th", null,
                            React.createElement("div", { className: theme.table__cell }, t('Amount'))))),
                React.createElement("tbody", null, renderAirlineTaxes()))),
        React.createElement("div", { className: theme.taxes__table },
            React.createElement("div", { className: theme.sectionBlock__headlineInner },
                t('Agency taxes'),
                " (",
                currency,
                ")"),
            React.createElement("table", { className: cn(theme.table, theme.table_align_center, theme.table_border_full) },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null,
                            React.createElement("div", { className: theme.table__cell }, t('Code'))),
                        React.createElement("th", null,
                            React.createElement("div", { className: theme.table__cell }, t('Amount'))))),
                React.createElement("tbody", null, renderAgencyTaxes())))));
};
export default Taxes;
