import * as React from 'react';
import { Form } from 'react-final-form';
import { Button } from '../../../../index';
import Field from '../Field/Field';
import css from '../../LoginChangePassword.css';
import { useTranslation } from 'react-i18next';
import { leoPasswordRegExp as passwordRegExp } from '../../../../utils';
var NewPasswordForm = function (_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var onSubmitHandler = function (values) {
        onSubmit(values['newPassword']);
    };
    var onValidate = function (values) {
        console.log('validate', values);
        var errors = {};
        if (values.newPassword !== values.repeatPassword) {
            errors['newPassword'] = t('Account:Passwords mismatch');
            errors['repeatPassword'] = t('Account:Passwords mismatch');
        }
        if (!passwordRegExp.test(values.newPassword)) {
            errors['newPassword'] = t('Account:Password must contain minimum 8 characters at least one number, one lower and uppercase letter and at least one special char except /');
        }
        return errors;
    };
    return (React.createElement(Form, { onSubmit: onSubmitHandler, validate: onValidate }, function (form) {
        var _a;
        return (React.createElement("form", { className: css.form, onSubmit: form.handleSubmit },
            React.createElement("div", null,
                React.createElement(Field, { name: "newPassword", label: t('Account:New password'), password: true, error: (_a = form.submitErrors) === null || _a === void 0 ? void 0 : _a['error'] }),
                React.createElement(Field, { name: "repeatPassword", password: true, label: t('Account:Repeat password') })),
            React.createElement("div", { className: css.control },
                React.createElement(Button, { type: "submit", variant: "flat", isLoading: form.submitting }, t('LoginPage:Continue')))));
    }));
};
export default NewPasswordForm;
