import React from 'react';
import { useTheme } from '../../../theme';
import { ConsoleIcon, ProfileIcon } from '../../Icons';
import CurrencySelector from '../LocaleSelector/CurrencySelector';
import Link from '../../../Link';
var Menu = function (props) {
    var css = useTheme('Header').Menu;
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.item }, props.renderLocaleSelector ? props.renderLocaleSelector() : React.createElement(CurrencySelector, null)),
        React.createElement("div", { className: css.item },
            React.createElement(Link, { action: props.onClickProfile }, ProfileIcon)),
        React.createElement("div", { className: css.item },
            React.createElement(Link, { action: props.onClickConsole }, ConsoleIcon))));
};
export default Menu;
