import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { getBaggageTab, getDisabled } from '../../store/selector';
import { setServices } from '../../store/actions';
import PassengerBaggageCounter from './PassengerBaggageCounter/PassengerBaggageCounter';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { BaggageType } from '../../../../__generated__/globalTypes';
import PassengerBaggageRadio from './PassengerBaggageRadio/PassengerBaggageRadio';
import { getSegmentsInBaggageGroup } from '../../utils';
var BaggagePassenger = function (props) {
    var theme = useTheme('UpgradeBaggageDialog').BaggagePassenger;
    var dispatch = useDispatch();
    var allSegments = !props.segmentId;
    var baggageTab = useSelector(getBaggageTab);
    var disabledFromState = useSelector(getDisabled);
    var handlerChange = function (items, type) {
        dispatch(setServices({ items: items, type: type }));
    };
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var services = React.useMemo(function () {
        if (isMobile && !baggageTab) {
            return [props.baggageByRfics.find(function (service) { return service[0].rfics === props.mobileSelectedServiceId; })];
        }
        return props.baggageByRfics;
    }, [props.baggageByRfics, props.mobileSelectedServiceId, isMobile, baggageTab]);
    return (React.createElement("div", { className: cn(theme.root) },
        React.createElement("div", { className: cn(theme.row) },
            React.createElement("div", { className: theme.name_wrapper },
                React.createElement("div", { className: theme.number }, props.number),
                React.createElement("div", { className: theme.name }, props.name)),
            React.createElement("div", { className: theme.baggages }, services.map(function (baggagesWithRfics) {
                var _a;
                if (!baggagesWithRfics[0]) {
                    return React.createElement("div", { className: theme.service__empty });
                }
                var segmentIds = allSegments
                    ? __spreadArray([], __read(getSegmentsInBaggageGroup(baggagesWithRfics)), false) : baggagesWithRfics[0].segmentsId.find(function (segmentSet) { return segmentSet.includes(props.segmentId); });
                var isAllowedForPassenger = baggagesWithRfics.some(function (baggage) {
                    return baggage.allowedPassengers.includes(props.passengerId);
                });
                var isAllowedSegment = baggagesWithRfics.some(function (item) {
                    return item.segmentsId.find(function (segmentSet) {
                        return props.segmentId ? segmentSet.includes(props.segmentId) : !!segmentSet.length;
                    });
                });
                var disabled = disabledFromState || !isAllowedForPassenger || !isAllowedSegment;
                var confirmedCount = props.selectedServices
                    .filter(function (service) { return service.serviceId === baggagesWithRfics[0].id; })
                    .reduce(function (acc, service) { return acc + service.confirmedCount; }, 0);
                var maxBaggageCount = !baggagesWithRfics[0].canBeAdded
                    ? (_a = props.selectedServices.find(function (service) { return service.serviceId === baggagesWithRfics[0].id; })) === null || _a === void 0 ? void 0 : _a.count
                    : null;
                if ([BaggageType.BaggageExcess].includes(baggagesWithRfics[0].type)) {
                    return (React.createElement(PassengerBaggageRadio, { key: baggagesWithRfics[0].id, passengerId: props.passengerId, baggages: baggagesWithRfics, onChange: function (items) { return handlerChange(items, 'radio'); }, disabled: disabled || !!confirmedCount, segmentIds: segmentIds, segmentId: props.segmentId }));
                }
                return (React.createElement(PassengerBaggageCounter, { forAllSegments: props.forAllSegments, passengerId: props.passengerId, baggages: baggagesWithRfics, key: baggagesWithRfics[0].id, onChange: handlerChange, disabled: disabled, segmentIds: segmentIds, segmentId: props.segmentId, maxBaggageCount: maxBaggageCount }));
            })))));
};
export default BaggagePassenger;
