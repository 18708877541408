import { __read, __spreadArray } from "tslib";
import memes from '../../../memes';
import { applyMiddleware, createStore as originalCreateStore } from 'redux';
import { upgradeModalReducer } from './reducers';
import { enableBatching } from 'redux-batched-actions';
import { detectDifferentForReturnFlight } from '../utils';
export var createStore = memes(function (services, segments, passengers, selectedServices, maxBaggageCount, maxBaggagePerPassengerCount, baggageTab, disabled) {
    if (disabled === void 0) { disabled = false; }
    var middleware = [];
    if (process.env.NODE_ENV === 'development') {
        middleware.push(require('redux-logger').default);
    }
    var store = originalCreateStore(enableBatching(upgradeModalReducer), {
        services: services,
        selectedServices: selectedServices,
        differentForReturnFlight: detectDifferentForReturnFlight(segments, selectedServices),
        disabled: disabled,
        maxBaggagePerPassengerCount: maxBaggagePerPassengerCount,
        maxBaggageCount: maxBaggageCount,
        baggageTab: baggageTab,
        passengers: passengers,
        segments: segments
    }, applyMiddleware.apply(void 0, __spreadArray([], __read(middleware), false)));
    return store;
});
