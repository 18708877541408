import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import { useCallback, useContext, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as OrderDocument from '../../__queries__/OrderDocument.graphql';
import { getAeroexpress, getServicesFallbackURL, shouldRedirectToFallback } from '../../Checkout/store/order/selectors';
import { useUpsaleWarning } from '../../Checkout/components/Checkout/Upsale/hooks';
import { addProduct, ProductType, removeProduct } from '../../analytics';
import { getAeroexpressProductId } from '../utils';
import { OrderDocumentType } from '../../__generated__/graphql';
import { downloadPDF } from '../../utils';
import { getAeroexpressPriceToPay } from '../../Checkout/store/orderPrice/selectors';
import { ModeContext } from '../../context';
import { Mode } from '../../types';
import { getHasSelectedAeroexpress } from '../../Modules/Checkin/store/order/selectors';
export var useAeroexpressTicketsDownloader = function (orderId) {
    var loadDocument = useQuery(OrderDocument.OrderDocument, { skip: true }).refetch;
    return function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, loadDocument({
                        params: {
                            orderId: orderId,
                            documentType: OrderDocumentType.AeroexpressTicket
                        }
                    })];
                case 1:
                    data = (_a.sent()).data;
                    if (!(data === null || data === void 0 ? void 0 : data.OrderDocument)) return [3 /*break*/, 3];
                    return [4 /*yield*/, downloadPDF(data.OrderDocument, 'aeroexpress-tickets.pdf')];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var useAeroexpress = function (updateServices, orderId) {
    var _a, _b;
    var t = useTranslation('Aeroexpress').t;
    var _c = __read(useState(false), 2), isAeroexpressModalOpen = _c[0], setAeroexpressModalOpen = _c[1];
    var downloadAeroexpress = useAeroexpressTicketsDownloader(orderId);
    var aeroexpress = useSelector(getAeroexpress);
    var hasSelectedServices;
    var mode = useContext(ModeContext);
    if (mode === Mode.Checkin) {
        hasSelectedServices = useSelector(getHasSelectedAeroexpress);
    }
    else {
        hasSelectedServices = ((_a = useSelector(getAeroexpressPriceToPay)) === null || _a === void 0 ? void 0 : _a.amount) > 0;
    }
    var availableServices = aeroexpress.trips;
    var selectedServices = (_b = aeroexpress.selectedTrips) !== null && _b !== void 0 ? _b : [];
    var hasConfirmedServices = selectedServices.some(function (selectedService) { var _a; return (_a = selectedService === null || selectedService === void 0 ? void 0 : selectedService.tickets) === null || _a === void 0 ? void 0 : _a.some(function (ticket) { return !!(ticket === null || ticket === void 0 ? void 0 : ticket.imageUrl); }); });
    var _d = __read(useState(availableServices.reduce(function (loadingState, service) {
        loadingState[service.id] = false;
        return loadingState;
    }, {})), 2), loadingState = _d[0], setLoadingState = _d[1];
    var _e = useUpsaleWarning(), showWarning = _e.showWarning, closeWarning = _e.closeWarning, toggleWarningIfOrderConfirmed = _e.toggleWarningIfOrderConfirmed;
    var shouldRedirect = useSelector(shouldRedirectToFallback);
    var servicesRedirectURL = useSelector(getServicesFallbackURL);
    var onSelect = useCallback(function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var selectedServicesIds, trip;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    selectedServicesIds = selectedServices.map(function (service) { return service.id; });
                    trip = availableServices.find(function (serviceId) { return serviceId.id === id; });
                    setLoadingState(function (prev) {
                        var _a;
                        return (__assign(__assign({}, prev), (_a = {}, _a[id] = true, _a)));
                    });
                    if (!selectedServicesIds.includes(id)) return [3 /*break*/, 2];
                    removeProduct({
                        id: getAeroexpressProductId(trip),
                        category: ProductType.Transfer
                    });
                    selectedServicesIds.splice(selectedServicesIds.findIndex(function (serviceId) { return serviceId === id; }), 1);
                    return [4 /*yield*/, updateServices(selectedServicesIds)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    addProduct({
                        id: getAeroexpressProductId(trip),
                        category: ProductType.Transfer
                    });
                    selectedServicesIds.push(id);
                    return [4 /*yield*/, updateServices(selectedServicesIds)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    setLoadingState(function (prev) {
                        var _a;
                        return (__assign(__assign({}, prev), (_a = {}, _a[id] = false, _a)));
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [selectedServices]);
    var availableServicesCount = availableServices.length;
    var tickets = [];
    selectedServices.forEach(function (service) {
        var _a;
        if (service.tickets && ((_a = service.tickets) === null || _a === void 0 ? void 0 : _a.length)) {
            tickets = tickets.concat(service === null || service === void 0 ? void 0 : service.tickets.map(function (ticket) { return ticket.imageUrl; }));
        }
    });
    var handleOpenModalClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (shouldRedirect) {
                        toggleWarningIfOrderConfirmed();
                        return [2 /*return*/];
                    }
                    if (!(hasConfirmedServices && availableServicesCount === 1)) return [3 /*break*/, 2];
                    return [4 /*yield*/, downloadAeroexpress()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
                case 2:
                    if (availableServicesCount === 1) {
                        onSelect(availableServices[0].id);
                    }
                    else {
                        setAeroexpressModalOpen(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var price = {
        amount: hasSelectedServices
            ? selectedServices.reduce(function (acc, curr) { return acc + curr.price.amount; }, 0)
            : Math.min.apply(Math, __spreadArray([], __read(availableServices.map(function (service) { return service.price.amount; })), false)),
        currency: availableServices[0].price.currency
    };
    var setLoadingStateForAllTo = function (value) {
        setLoadingState(availableServices.reduce(function (loadingState, service) {
            loadingState[service.id] = value;
            return loadingState;
        }, {}));
    };
    var onClear = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoadingStateForAllTo(true);
                    return [4 /*yield*/, updateServices([])];
                case 1:
                    _a.sent();
                    setAeroexpressModalOpen(false);
                    setLoadingStateForAllTo(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var buttonText = t('Add');
    if (hasConfirmedServices) {
        buttonText = t('Download tickets');
    }
    if (hasSelectedServices && !hasConfirmedServices) {
        buttonText = t(availableServicesCount === 1 ? 'Cancel' : 'Change');
    }
    var isLoading = Object.keys(loadingState).reduce(function (isLoading, key) {
        return isLoading ? isLoading : loadingState[key];
    }, false);
    return {
        buttonText: buttonText,
        isLoading: isLoading,
        price: price,
        onClear: onClear,
        handleOpenModalClick: handleOpenModalClick,
        hasSelectedServices: hasSelectedServices,
        showWarning: showWarning,
        servicesRedirectURL: servicesRedirectURL,
        closeWarning: closeWarning,
        tickets: tickets,
        isAeroexpressModalOpen: isAeroexpressModalOpen,
        setAeroexpressModalOpen: setAeroexpressModalOpen,
        loadingState: loadingState,
        onSelect: onSelect,
        handleDownloadTickets: downloadAeroexpress
    };
};
