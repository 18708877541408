import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { planeIcon } from '../../Icons';
import { FlightClass } from '../../../__generated__/globalTypes';
import { format } from '../../../utils';
import { getAbsoluteDate } from '../../../Engine/components/FastSearch/utils';
var SegmentCompact = function (_a) {
    var _b;
    var _c, _d;
    var segment = _a.segment, serviceClass = _a.serviceClass;
    var css = useTheme('Segment').SegmentCompact;
    var t = useTranslation('Segment').t;
    var fareFamily = (_c = segment.fareFamilies[0]) === null || _c === void 0 ? void 0 : _c.fareFamily;
    return (React.createElement("div", { className: cn(css.wrapper, (_b = {},
            _b[css.wrapper_business] = serviceClass === FlightClass.Business,
            _b)) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.segmentInfo },
                React.createElement("div", { className: css.planeIcon }, planeIcon),
                React.createElement("div", null,
                    React.createElement("span", { className: css.segmentInfo__item },
                        segment.departure.airport.city.name,
                        " \u2014 ",
                        segment.arrival.airport.city.name),
                    React.createElement("span", { className: css.segmentInfo__item }, format(segment.departure.date, 'dd MMM, yyyy')),
                    React.createElement("span", { className: css.segmentInfo__item },
                        segment.departure.time.substr(0, 5),
                        " - ",
                        segment.arrival.time.substr(0, 5),
                        ' '))),
            React.createElement("div", { className: css.fareFamily },
                React.createElement("div", { className: css.fareFamily__img, style: {
                        backgroundImage: "url(".concat((_d = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _d === void 0 ? void 0 : _d.icon, ")")
                    } }),
                React.createElement("span", null, fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.title))),
        React.createElement("div", { className: css.footer },
            React.createElement("div", { className: css.flightInfo },
                React.createElement("span", { className: css.flightInfo__item },
                    t('Flight'),
                    ": ",
                    segment.operatingAirline.iata,
                    "-",
                    segment.flightNumber),
                segment.departure.terminal && (React.createElement("span", { className: css.flightInfo__item },
                    t('Terminal'),
                    ": ",
                    segment.departure.terminal)),
                React.createElement("span", { className: css.flightInfo__item },
                    t('Boarding'),
                    ": ",
                    format(getAbsoluteDate(segment.checkinInfo.boardingOpenTime), 'HH:mm'),
                    ' ',
                    "\u2014 ",
                    format(getAbsoluteDate(segment.checkinInfo.boardingCloseTime), 'HH:mm')),
                React.createElement("span", { className: css.flightInfo__item },
                    t('Online check-in'),
                    ": ",
                    format(segment.checkinInfo.webOpenTime, 'dd MMM HH:mm'),
                    " \u2014",
                    ' ',
                    format(segment.checkinInfo.webCloseTime, 'dd MMM HH:mm'))))));
};
export default SegmentCompact;
