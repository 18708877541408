import { __read } from "tslib";
import React, { useMemo } from 'react';
import cn from 'classnames';
import FlightSeat from '../FlightSeat/FlightSeat';
import { useTheme } from '../../../../theme';
import DateSwitcher from '../DateSwitcher/DateSwitcher';
import { useAvailableDates } from '../../../../hooks';
import FlightsFilter from '../../../FlightsFilter';
import { FilterAttrs } from '../../../FlightsFilter/types';
import { getFilteredFlights } from '../../../FlightsFilter/utils';
import { flightFilterFlightsConverter } from '../../adapters';
import { useOrderedFlights } from '../../../FlightsFilter/hooks';
import ResultsError from '../../../../ResultsError';
var FlightsList = function (_a) {
    var className = _a.className, segment = _a.segment, date = _a.date, selectedDates = _a.selectedDates, flights = _a.flights, onChangeDate = _a.onChangeDate, error = _a.error;
    var css = useTheme('SeatAvailability').FlightsList;
    var dates = useAvailableDates(segment).dates;
    // flights filtration
    var _b = __read(React.useState({}), 2), filterValues = _b[0], setFilterValues = _b[1];
    var filteredFlights = useMemo(function () { return getFilteredFlights(flightFilterFlightsConverter(flights), filterValues); }, [
        flights,
        filterValues
    ]);
    // flights sorting
    var processedFlights = useOrderedFlights(flightFilterFlightsConverter(filteredFlights));
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.header },
            React.createElement(DateSwitcher, { className: css.date, date: date, availableDates: dates, onChangeDate: onChangeDate }),
            React.createElement(FlightsFilter, { className: css.filter, allowedFilters: [FilterAttrs.NON_STOP, FilterAttrs.AIRLINE_CODE, FilterAttrs.FLIGHT_NUM], onChange: setFilterValues })),
        !error && processedFlights.length > 0 && (React.createElement("div", { className: css.flights }, processedFlights.map(function (flight, index) { return (React.createElement(FlightSeat, { key: index, flight: flight })); }))),
        error && React.createElement(ResultsError, { className: css.errorMsg, header: error.name, body: error.message })));
};
export default FlightsList;
