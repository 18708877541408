import * as React from 'react';
import { memo } from 'react';
import { Provider } from 'react-redux';
import { useGraphQLClient } from '../../../graphql-client';
import { initI18n } from '../../../utils';
import { createStore } from '../store';
import Checkin from './Checkin/Checkin';
import Analytics from '../../../Engine/components/Analytics';
import { GuardedApolloProvider } from '../../../GuardedApolloProvider';
import { ModeContext } from '../../../context';
import { Mode } from '../../../types';
initI18n('Checkin');
export var Module = memo(function () {
    var client = useGraphQLClient();
    var store = createStore();
    return (React.createElement(GuardedApolloProvider, { client: client },
        React.createElement(Provider, { store: store },
            React.createElement(ModeContext.Provider, { value: Mode.Checkin },
                React.createElement(Analytics, null),
                React.createElement(Checkin, null)))));
});
export default Module;
