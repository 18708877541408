import { __assign } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../../theme';
import MediaQuery from 'react-responsive';
import { TABLET_MIN_WIDTH } from '../../../../utils';
import { MobileSummaryFlight } from '../../../../SummaryFlight';
import { FlightSegmentStatus } from '../../../../__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import { OverrideComponent } from '../../../../renderProps';
import FlightSelect from '../../../../FlightSelect/components/FlightSelect';
var SelectedFlight = function (_a) {
    var flight = _a.flight, confirmed = _a.confirmed, orderId = _a.orderId;
    var theme = useTheme('Checkout').CheckoutStyles;
    var t = useTranslation('Checkout').t;
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
            React.createElement("div", { className: theme.selectedFlight__container }, flight.segmentGroups.map(function (group, index) {
                var _a;
                return (React.createElement(OverrideComponent, { key: index, component: {
                        PassengersFlightListFlight: FlightSelect
                    }, componentProps: {
                        selectedFares: [
                            {
                                fareGroupId: (_a = group.fareGroup) === null || _a === void 0 ? void 0 : _a.id,
                                prices: [
                                    {
                                        flight: {
                                            id: flight.id
                                        },
                                        price: group.price
                                    }
                                ]
                            }
                        ],
                        orderId: orderId,
                        isSelected: true,
                        flight: __assign(__assign({}, flight), { segments: group.segments.map(function (segment) { return flight.segments[segment.id]; }), pricesForFareGroups: [
                                {
                                    fareFamily: __assign({}, group.fareFamily),
                                    prices: [
                                        {
                                            originalPrice: group.price,
                                            price: group.price,
                                            priceInMiles: group.priceInMiles,
                                            flight: {
                                                id: flight.id,
                                                canBePaidByMiles: false,
                                                fares: flight.fares
                                            }
                                        }
                                    ]
                                }
                            ] }),
                        key: index,
                        isPreOpen: false,
                        isPreOpenAvailable: false,
                        canceled: group.segments.some(function (segment) { return segment.status === FlightSegmentStatus.Canceled; })
                    } }));
            }))),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement("div", { className: theme.selectedFlight__container },
                React.createElement("div", { className: theme.selectedFlight__header }, t(flight.segmentGroups.length > 1 ? 'Flights' : 'Flight')),
                flight.segmentGroups.map(function (group, index) { return (React.createElement(MobileSummaryFlight, { flight: {
                        id: flight.id,
                        segments: group.segments.map(function (segment) { return flight.segments[segment.id]; })
                    }, fareFamily: __assign({}, group.fareFamily), useFlatMobileSegment: true, price: group.price, priceInMiles: group.priceInMiles, canceled: group.segments.some(function (segment) { return segment.status === FlightSegmentStatus.Canceled; }) })); })))));
};
export default SelectedFlight;
