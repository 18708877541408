import { __read } from "tslib";
import { FieldType } from './types';
import { validateDate } from '../../Passenger/utils';
var isNumberDefined = function (value) { return value !== null && value !== undefined; };
export var validate = function (value, input) {
    var _a;
    if (input.required && !((_a = value === null || value === void 0 ? void 0 : value.trim()) === null || _a === void 0 ? void 0 : _a.length)) {
        return 'Required';
    }
    else if (!value && !input.required) {
        return undefined;
    }
    if (input.type === FieldType.Text) {
        if (isNumberDefined(input.minLength) && (value === null || value === void 0 ? void 0 : value.trim().length) < input.minLength) {
            return "Incorrect length. Min length is ".concat(input.minLength);
        }
        if (isNumberDefined(input.maxLength) && (value === null || value === void 0 ? void 0 : value.trim().length) > input.maxLength) {
            return "Incorrect length. Max length is ".concat(input.maxLength);
        }
    }
    if (input.type === FieldType.Number) {
        if (isNumberDefined(input.min) && Number(value) < Number(input.min)) {
            return "Incorrect value. Min value is ".concat(input.min);
        }
        if (isNumberDefined(input.max) && Number(value) > Number(input.max)) {
            return "Incorrect value. Max value is ".concat(input.max);
        }
    }
    if (input.type === FieldType.Date) {
        return validateDate(value, { minDate: input.min, maxDate: input.max }, 'yyyy-MM-dd');
    }
    if (input.pattern && !new RegExp(input.pattern).test(value)) {
        return "Incorrect value. Pattern ".concat(input.pattern);
    }
};
export var removeWhiteSpaces = function (formValues) {
    if (typeof formValues !== 'object') {
        return formValues;
    }
    var newValues = {};
    Object.entries(formValues).forEach(function (_a) {
        var _b = __read(_a, 2), k = _b[0], v = _b[1];
        if (typeof v === 'string') {
            newValues[k] = v.trim();
        }
        else {
            newValues[k] = v;
        }
    });
    return newValues;
};
