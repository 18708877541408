import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { groupRefundServicesByPassenger, groupServicesByType } from './utils';
import { useTheme } from '../../../../../theme';
import Notice from '../Notice/Notice';
import Stub from './Services/Stub/Stub';
import { OverrideComponent } from '../../../../../renderProps';
import ExchangeServiceCard from './ExchangeServiceCard/ExchangeServiceCard';
var SelectedServices = function (_a) {
    var order = _a.order, gdsServicesRefund = _a.gdsServicesRefund, isLoading = _a.isLoading;
    var t = useTranslation('Exchange').t;
    var groupServices = React.useMemo(function () {
        var _a, _b;
        if ((_a = gdsServicesRefund === null || gdsServicesRefund === void 0 ? void 0 : gdsServicesRefund.GetGdsServicesRefundInfo) === null || _a === void 0 ? void 0 : _a.gdsServicesRefundInfo) {
            var servicesByType = groupServicesByType((_b = gdsServicesRefund === null || gdsServicesRefund === void 0 ? void 0 : gdsServicesRefund.GetGdsServicesRefundInfo) === null || _b === void 0 ? void 0 : _b.gdsServicesRefundInfo);
            var refundServicesByType_1 = new Map();
            servicesByType.forEach(function (value, key) {
                refundServicesByType_1.set(key, groupRefundServicesByPassenger(value));
            });
            return refundServicesByType_1;
        }
        return null;
    }, [gdsServicesRefund]);
    var getServiceCardByType = function (refundServices, type) {
        return (React.createElement(OverrideComponent, { componentProps: {
                refundServices: refundServices,
                type: type,
                order: order,
                isLoading: isLoading,
                gdsServicesRefund: gdsServicesRefund
            }, component: { ExchangeServiceCard: ExchangeServiceCard } }));
    };
    var renderServicesCard = React.useMemo(function () {
        var services = [];
        groupServices === null || groupServices === void 0 ? void 0 : groupServices.forEach(function (value, key) {
            if (Object.keys(value).length > 0) {
                services.push(getServiceCardByType(value, key));
            }
        });
        if (services.length) {
            return services.map(function (component, key) {
                return React.createElement(React.Fragment, { key: key }, component);
            });
        }
        return null;
    }, [groupServices]);
    var theme = useTheme('Exchange').SelectedServices;
    return (React.createElement(React.Fragment, null,
        isLoading && (React.createElement("div", { className: theme.selectedServices },
            React.createElement(Stub, null),
            React.createElement(Stub, null),
            React.createElement(Stub, null))),
        !isLoading && !!renderServicesCard && (React.createElement("div", null,
            React.createElement(Notice, { type: 'error', header: t('Exchange restrictions'), text: t('All services issued on one electronic document will be canceled, even if you change itinerary partial. Money refund will be carried out by the airline in accordance with the terms of the Public Offer') }),
            React.createElement("div", { className: theme.selectedServices }, renderServicesCard)))));
};
export default SelectedServices;
