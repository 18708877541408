import { __assign, __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import MealService from './MealService/MealService';
import { getPassengerLabels } from '../../../../store/passengers/selectors';
import { AviaPassengerType, OrderAdditionalServiceGdsServiceServiceType } from '../../../../../__generated__/globalTypes';
import { useTheme } from '../../../../../theme';
import SeatService from './SeatService/SeatService';
import { getIncludedBaggage, getIsOddOrderId, getSeatMap, getServicesFallbackURL, shouldRedirectToFallback } from '../../../../store/order/selectors';
import Warning from '../../Upsale/Warning/Warning';
import { seatMapHasAnySeats } from '../../../../../SeatMap/utils';
import { setSplitTest, SplitTest } from '../../../../../analytics';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import BaggageServiceCard from './BaggageServiceCard/BaggageServiceCard';
import { getPassengerStateCompatible } from '../../../../store/selectedServices/selectors';
import { addService, removeService, deleteAllTypesOnSegment, setService } from '../../../../store/selectedServices/actions';
import { isServicesSaving } from '../../../../store/servicesIsSaving/selectors';
import { useConfig } from '../../../../../context';
/**
 * @deprecated
 */
var Services = function (_a) {
    var segments = _a.segments, services = _a.services, seatMap = _a.seatMap, passengers = _a.passengers, passengerLabels = _a.passengerLabels, passengerId = _a.passengerId, runServiceRequest = _a.runServiceRequest, shouldRedirectToFallback = _a.shouldRedirectToFallback, servicesRedirectURL = _a.servicesRedirectURL, addService = _a.addService, removeService = _a.removeService, setService = _a.setService, deleteAllTypesOnSegment = _a.deleteAllTypesOnSegment;
    var _b = __read(useState(false), 2), warningIsOpen = _b[0], setWarningIsOpen = _b[1];
    var isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
    var openWarning = function () { return setWarningIsOpen(true); };
    var closeWarning = function () { return setWarningIsOpen(false); };
    var css = useTheme('Checkout').Services;
    var isOddOrderId = useSelector(getIsOddOrderId);
    var _c = useConfig().global, maxBaggage = _c.maxBaggage, maxBaggagePerPassenger = _c.maxBaggagePerPassenger;
    var simpleBaggageSelector = !maxBaggage && !maxBaggagePerPassenger && isOddOrderId && (segments.length === 1 || isMobile);
    var includedBaggage = useSelector(getIncludedBaggage);
    var isOrderServicesSaving = useSelector(isServicesSaving);
    setSplitTest(SplitTest.OneButtonBaggageSelector, simpleBaggageSelector);
    var passenger = passengers[passengerId];
    var label = passengerLabels[passengerId];
    // Disallow ordering new services after ticketing.
    var onCardClick = shouldRedirectToFallback ? openWarning : null;
    var baggageServices = services.filter(function (service) {
        return (service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage &&
            service.allowedPassengers.find(function (passengers) { return passengers.indexOf(passengerId.toString()) >= 0; }));
    });
    var food = services.filter(function (service) {
        return (service.type === OrderAdditionalServiceGdsServiceServiceType.Meal &&
            service.allowedPassengers.find(function (passengers) { return passengers.indexOf(passengerId.toString()) >= 0; }));
    });
    var baggageIds = baggageServices.map(function (baggage) { return baggage.id; });
    var hasAnyServices = !!baggageServices.length || !!food.length || seatMapHasAnySeats(seatMap);
    var onClear = useCallback(function (passengerId) {
        passenger.services.forEach(function (service) {
            if (baggageIds.includes(service.serviceId)) {
                removeService({
                    passengerId: passengerId,
                    serviceId: service.serviceId,
                    count: service.count,
                    segmentId: service.segmentIds[0],
                    allowedSegments: [service.segmentIds]
                });
            }
        });
    }, [runServiceRequest, baggageServices, passengers]);
    return hasAnyServices ? (React.createElement("div", { className: css.wrapper },
        !!baggageServices.length && (React.createElement(BaggageServiceCard, { className: css.service, onCardClick: onCardClick, simpleBaggageSelector: simpleBaggageSelector, segments: segments, passengerId: passengerId.toString(), passengerName: label, includedBaggage: includedBaggage, onClear: onClear, addService: addService, removeService: removeService, setService: setService, isLoading: isOrderServicesSaving })),
        !!food.length && (React.createElement(MealService, { passengers: passengers, onCardClick: onCardClick, className: css.service, services: food, passengerId: passengerId, segments: segments, setServices: runServiceRequest, selectedProducts: passenger.services.filter(function (selectedService) {
                return food.find(function (food) { return food.id === selectedService.serviceId; });
            }), passengerName: label, addService: addService, removeService: removeService, deleteAllTypesOnSegmentAction: deleteAllTypesOnSegment, isLoading: isOrderServicesSaving })),
        seatMapHasAnySeats(seatMap) && (React.createElement(SeatService, { onCardClick: onCardClick, runServiceRequest: runServiceRequest, passenger: passenger, passengers: passengers.filter(function (passenger) { return passenger.type !== AviaPassengerType.INF; }), seatMap: seatMap, setService: setService, removeService: removeService, isLoading: isOrderServicesSaving })),
        shouldRedirectToFallback && (React.createElement(Warning, { redirectURL: servicesRedirectURL, onClose: closeWarning, open: warningIsOpen })))) : null;
};
var mapStateToProps = function (state, ownProps) {
    return __assign(__assign({}, ownProps), { seatMap: getSeatMap(state), passengers: getPassengerStateCompatible(state), passengerLabels: getPassengerLabels(state), servicesRedirectURL: getServicesFallbackURL(state), shouldRedirectToFallback: shouldRedirectToFallback(state) });
};
var mapDispathToProps = {
    addService: addService,
    removeService: removeService,
    deleteAllTypesOnSegment: deleteAllTypesOnSegment,
    setService: setService
};
export default connect(mapStateToProps, mapDispathToProps)(Services);
