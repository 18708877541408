import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider, useTheme } from '../../../theme';
import { ExareStatus } from '../../../__generated__/graphql';
import Status from '../Status/Status';
import MobileStep from '../MobileStep/MobileStep';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import StepsButtons from '../StepsButtons/StepsButtons';
import RefundInfoModal from '../Modals/RefundInfoModal/RefundInfoModal';
import { Document as DocumentIcon, Person, Refundable } from '../../../Icons';
import { getPassengerFirstName, getPassengerLastName } from '../../../Checkout/store/passengers/selectors';
import { capitalizeFirstLetter, format, initI18n } from '../../../utils';
import { Unvoluntary } from '../../types';
import { useToggleable } from '../../../hooks';
import Notes from '../Notes/Notes';
import { OverrideComponent } from '../../../renderProps';
import PriceDetails from '../../../LEO/Refund/PriceDetails/PriceDetails';
initI18n('Exchange');
var Ticket = function (props) {
    var refundOrder = props.refundOrder, isLoading = props.isLoading, isAutoMode = props.isAutoMode, renderNotesFromProps = props.renderNotes;
    var isModalOpen = useToggleable(false).isOpen;
    var _a = useToggleable(false), rejectModalOpen = _a.isOpen, openRejectModal = _a.open, closeRejectModal = _a.close;
    var t = useTranslation('Refund').t;
    var css = useTheme('Refund').Ticket;
    var handleConfirm = useCallback(function () {
        if (isLoading) {
            return;
        }
        props.onConfirm(refundOrder.id);
    }, [refundOrder, isLoading]);
    var handleReject = useCallback(function () {
        if (isLoading) {
            return;
        }
        props.onReject(refundOrder.id);
    }, [refundOrder, isLoading]);
    var renderHeader = useCallback(function () {
        var _a;
        return (React.createElement(Paper, { square: true, elevation: 0, className: css.header },
            React.createElement("div", { className: css.info },
                React.createElement("h6", { className: css.title },
                    t('Request'),
                    ": #",
                    refundOrder.id),
                React.createElement("p", { className: css.time },
                    React.createElement("span", { className: css.createPrefix },
                        t('Create'),
                        "\u00A0"),
                    format(new Date(refundOrder.createDate), 'dd MMM yyyy HH:mm')),
                React.createElement(Status, { className: css.status, status: refundOrder.status })),
            React.createElement(MediaQuery, { minWidth: 'mobile' },
                React.createElement(ThemeProvider, { value: { Refund: { Breadcrumbs: { breadcrumb: css.breadcrumb } } } },
                    React.createElement(Breadcrumbs, { className: css.breadcrumbs, passengers: refundOrder.travellers, refundInfo: {
                            reason: (_a = refundOrder.reason) !== null && _a !== void 0 ? _a : Unvoluntary.Unvoluntary,
                            isVoluntary: refundOrder.isCompelled
                        } }))),
            React.createElement(MediaQuery, { maxWidth: 'mobile' },
                React.createElement(MobileStep, { className: css.step, isActive: false, renderTitle: function () { return (React.createElement(React.Fragment, null,
                        t('For'),
                        "\u00A0",
                        refundOrder.travellers.map(function (traveller) { return (React.createElement("span", { key: traveller.id },
                            capitalizeFirstLetter(getPassengerFirstName(traveller).value.toLowerCase()),
                            "\u00A0",
                            capitalizeFirstLetter(getPassengerLastName(traveller).value.toLowerCase()),
                            "\u00A0")); }))); }, icon: Person }),
                React.createElement(MobileStep, { className: css.step, isActive: false, renderTitle: function () { return (React.createElement(React.Fragment, null, !refundOrder.isCompelled ? (React.createElement("span", { className: css.reasonHeader }, t('Voluntary refund'))) : (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: css.reasonHeader },
                            t('Unvoluntary refund'),
                            React.createElement("p", { className: css.reasonDescr }, t(refundOrder.reason.toString()))))))); }, icon: Refundable }))));
    }, [refundOrder]);
    var renderPrices = useCallback(function () { return React.createElement(PriceDetails, { className: css.price, prices: refundOrder.prices }); }, [
        refundOrder
    ]);
    var renderComments = useCallback(function () {
        return refundOrder.userComment ? (React.createElement(Paper, { square: true, elevation: 0, className: css.comments },
            React.createElement("h6", { className: css.commentsTitle }, t('Your comments')),
            React.createElement("p", { className: css.commentsText }, refundOrder.userComment))) : null;
    }, [refundOrder]);
    var renderFile = useCallback(function (file) { return (React.createElement("div", { className: css.file, key: Math.random() },
        React.createElement("span", { className: css.fileIcon }, DocumentIcon),
        capitalizeFirstLetter(getPassengerFirstName(refundOrder.travellers.find(function (traveller) { return traveller.id === file.passengerId; })).value.toLowerCase()),
        "\u00A0",
        capitalizeFirstLetter(getPassengerLastName(refundOrder.travellers.find(function (traveller) { return traveller.id === file.passengerId; })).value.toLowerCase()),
        ":\u00A0",
        t(file.type))); }, [refundOrder]);
    var renderFiles = useCallback(function () {
        if (!refundOrder.loadedFiles.length) {
            return null;
        }
        return (React.createElement(Paper, { square: true, elevation: 0, className: css.files },
            React.createElement("h6", { className: css.filesTitle }, t('Files')),
            React.createElement("div", { className: css.filesContent }, refundOrder.loadedFiles.map(renderFile))));
    }, [refundOrder]);
    var renderButtons = useCallback(function () {
        var status = refundOrder.status;
        if (status === ExareStatus.NeedConfirm) {
            return (React.createElement("div", { className: css.buttons },
                React.createElement(StepsButtons, { position: 'right', nextButton: {
                        disabled: isLoading,
                        children: t('Confirm'),
                        className: cn(css.confirm, css.button),
                        onClick: handleConfirm
                    }, backButton: {
                        disabled: isLoading,
                        children: t('Reject'),
                        className: cn(css.button, css.reject),
                        onClick: openRejectModal
                    } })));
        }
        if (status === ExareStatus.InProcess) {
            return (React.createElement("div", { className: css.buttons },
                React.createElement(StepsButtons, { position: 'right', backButton: {
                        children: t('Delete request'),
                        className: cn(css.button, css.reject),
                        onClick: openRejectModal
                    } })));
        }
        if (status === ExareStatus.TimelimitExpired) {
            return (React.createElement("div", { className: css.buttons },
                React.createElement(StepsButtons, { position: 'right', backButton: {
                        children: 'Delete request',
                        className: cn(css.button, css.reject),
                        onClick: openRejectModal
                    } })));
        }
        if (status === ExareStatus.Refused || status === ExareStatus.Rejected) {
            return (React.createElement("div", { className: css.buttons },
                React.createElement(OverrideComponent, { componentProps: {
                        position: 'right',
                        nextButton: {
                            children: t('Add new request'),
                            className: cn(css.confirm, css.button),
                            onClick: props.onNewRequest
                        }
                    }, component: {
                        renderStepsButtons: StepsButtons
                    } })));
        }
        return null;
    }, [refundOrder.status]);
    var renderRejectModal = function () { return (React.createElement(RefundInfoModal, { open: rejectModalOpen, onAgree: handleReject, onDisagree: closeRejectModal, renderText: function () { return t('The request was deleted. Want to create a new request?'); }, renderAgreeText: function () { return t('Yes'); }, renderDisagreeText: function () { return t('No'); } })); };
    var renderModals = useCallback(function () {
        var status = refundOrder.status;
        switch (status) {
            default:
                return null;
        }
    }, [refundOrder, isModalOpen, handleConfirm]);
    var renderNotes = useCallback(function () { return (renderNotesFromProps ? renderNotesFromProps(isAutoMode) : React.createElement(Notes, { isAutoMode: isAutoMode })); }, [isAutoMode]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Paper, { square: true, elevation: 0, className: css.ticket },
            renderHeader(),
            renderComments(),
            renderFiles()),
        React.createElement(Paper, { square: true, elevation: 0, className: cn(css.prices) }, renderPrices()),
        refundOrder.status !== ExareStatus.Rejected && refundOrder.status !== ExareStatus.Refused && renderNotes(),
        renderButtons(),
        renderModals(),
        renderRejectModal()));
};
export default Ticket;
