import { __read } from "tslib";
import * as React from 'react';
import { Switch } from '../../../../../../index';
import { Field } from '../../../../../../Passenger/components/Field/Field';
import { useTheme } from '../../../../../../theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useConfig } from '../../../../../../context';
import Link from '../../../../../../Link';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';
var LoyaltyGroup = function (props) {
    var _a;
    var _b;
    var _c = __read(React.useState(!!((_b = props.fields[0]) === null || _b === void 0 ? void 0 : _b.defaultValue)), 2), open = _c[0], setOpen = _c[1];
    var groupTheme = useTheme('PassengerForm').Group;
    var theme = useTheme('PassengerForm').LoyaltyGroup;
    var t = useTranslation('Passenger').t;
    var config = useConfig().global.companyInfo;
    var form = useForm();
    useEffect(function () {
        if (!!props.fields[0].defaultValue) {
            setOpen(true);
        }
    }, [props.fields]);
    useEffect(function () {
        if (!open) {
            form.change(props.fields[0].name, '');
        }
    }, [open]);
    var onToggle = function () {
        setOpen(!open);
    };
    return (React.createElement("div", { className: cn(groupTheme.container, theme.container_loyalty) },
        React.createElement("div", { className: cn(theme.loyalty_switch, (_a = {}, _a[theme.loyalty_switch_open] = open, _a)) },
            React.createElement(Switch, { label: t('I have a loyalty card'), isActive: open, onClick: onToggle, mainClassName: theme.toggle, labelClassName: theme.switch__label, isDisabled: props.fields[0].readonly || props.disabled }),
            !open && (React.createElement("p", { className: theme.loyalty__desc },
                t("Don't have a card yet?"),
                ' ',
                React.createElement(Link, { target: "_blank", action: config.loyaltyProgramRegistrationUrl }, t('Register now'))))),
        React.createElement("div", { className: theme.loyalty, style: { display: open ? 'block' : 'none' } },
            React.createElement(Field, { field: props.fields[0], passengerId: props.passengerId, passengerType: props.passengerType, disabled: props.fields[0].readonly || props.disabled }))));
};
export default LoyaltyGroup;
