import cn from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { isMultiCity } from '../../../store/segments/selectors';
import { getPassengerTypes } from '../../../store/passengerTypes/selectors';
import Passengers from '../Passengers/Passengers';
import StartSearch from '../StartSearch/StartSearch';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import Currency from '../Currency/Currency';
var AdvancedPassengers = React.lazy(function () { return import('../AdvancedPassengers/AdvancedPassengers'); });
var Controls = React.memo(function (props) {
    var _a;
    var css = useTheme('SearchForm').Controls;
    var proMode = useConfig().SearchForm.proMode;
    return (React.createElement("div", { className: cn(css.controls, (_a = {},
            _a[css.controls_multiCity] = props.isMultiCity,
            _a[css.controls_advanced] = proMode,
            _a)) },
        proMode ? (React.createElement(React.Suspense, { fallback: "Loading..." },
            React.createElement(React.Fragment, null,
                React.createElement(AdvancedPassengers, { passengerTypes: props.passengerTypes, showValidationErrors: props.showValidationErrors }),
                React.createElement(Currency, null)))) : (React.createElement(Passengers, null)),
        React.createElement(StartSearch, { renderPayloads: props.renderPayloads, mode: props.isMultiCity && !proMode ? 'text' : 'icon' })));
});
var mapStateToProps = function (state) {
    return {
        isMultiCity: isMultiCity(state),
        passengerTypes: getPassengerTypes(state)
    };
};
export default connect(mapStateToProps)(Controls);
