import { __read, __spreadArray } from "tslib";
export var getPassengerPricesMap = function (passengers, services) {
    var passengerMap = new Map();
    services.forEach(function (service) {
        service.allowedPassengers.forEach(function (passenger) {
            var passengerType = passengers.find(function (passengerState) { return passengerState.id === passenger[0]; }).type;
            if (!passengerMap.has(passengerType)) {
                passengerMap.set(passengerType, service.price);
            }
        });
    });
    return __spreadArray([], __read(passengerMap.keys()), false).map(function (type) {
        return {
            type: type,
            price: passengerMap.get(type)
        };
    });
};
