import { __assign } from "tslib";
import * as React from 'react';
import { createRef, useEffect, useMemo } from 'react';
import Paper from '@material-ui/core/Paper';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { FlightClass } from '../../../__generated__/graphql';
import { Button } from '../../../index';
import { useDesktopFlightModalContent, useLastSelectedFlight } from './hooks';
import { Tabs } from '../../types';
import { useTheme } from '../../../theme';
import { FlightInfoV2 } from '../../../FlightInfo';
import { usePagination } from '../../../Pagination/hooks';
import { initI18n, MOBILE_MIN_WIDTH, TABLET_MIDDLE_WIDTH } from '../../../utils';
import Pagination from '../../../Pagination';
import FareGroupStub from '../FareGroupStub/FareGroupStub';
import Modal from '../../../Modal';
import CompareFares from '../../../CompareFares/components/CompareFares';
import Link from '../../../Link';
import * as icons from '../../../Icons';
import FareGroupGrid from '../../../FareGroup/components/FareGroupGrid/FareGroupGrid';
import { findIndexFirstAvailableFareGroup, isFareGroupAvailable, roundAvailableSliderIndex } from './utils';
initI18n('FlightInfoModalContent');
var DesktopFlightModalContent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useDesktopFlightModalContent(props), active = _h.active, handleFlightDetailsButtonClick = _h.handleFlightDetailsButtonClick, handleBusinessButtonClick = _h.handleBusinessButtonClick, handleEconomyButtonCLick = _h.handleEconomyButtonCLick, handleMilesButtonClick = _h.handleMilesButtonClick, isFareCompareOpen = _h.isFareCompareOpen, openFareCompare = _h.openFareCompare, closeFareCompare = _h.closeFareCompare;
    var t = useTranslation('FlightInfoModalContent').t;
    var lastSelectedFare = useLastSelectedFlight();
    var isTablet = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var isLte340 = useMediaQuery({ maxWidth: 340 });
    var slidesToShow = isTablet ? (isMobile ? (isLte340 ? 1.07 : 1.2) : 2) : 3;
    var slidesToScroll = slidesToShow >= 2 ? slidesToShow : 1;
    var _j = useMemo(function () {
        var firstEconomyAvailableFareGroup = findIndexFirstAvailableFareGroup(props.economyFares, lastSelectedFare);
        var firstBusinessAvailableFareGroup = findIndexFirstAvailableFareGroup(props.businessFares, lastSelectedFare);
        var firstEconomyAvailableSliderIndex = roundAvailableSliderIndex(firstEconomyAvailableFareGroup, slidesToScroll);
        var firstBusinessAvailableSliderIndex = roundAvailableSliderIndex(firstBusinessAvailableFareGroup, slidesToScroll);
        return {
            firstEconomyAvailableSliderIndex: firstEconomyAvailableSliderIndex,
            firstBusinessAvailableSliderIndex: firstBusinessAvailableSliderIndex
        };
    }, [props.economyFares, props.businessFares, lastSelectedFare, slidesToScroll]), firstEconomyAvailableSliderIndex = _j.firstEconomyAvailableSliderIndex, firstBusinessAvailableSliderIndex = _j.firstBusinessAvailableSliderIndex;
    var _k = usePagination(firstEconomyAvailableSliderIndex), economySliderIndex = _k.currentIndex, handleEconomySliderIndexChange = _k.handleChange;
    var _l = usePagination(firstBusinessAvailableSliderIndex), businessSliderIndex = _l.currentIndex, handleBusinessSliderIndexChange = _l.handleChange;
    var _m = usePagination(0), milesSliderIndex = _m.currentIndex, handleMilesSliderIndexChange = _m.handleChange;
    var economySliderRef = createRef();
    var businessSliderRef = createRef();
    var milesSliderRef = createRef();
    useEffect(function () {
        // return to previous item when user change active tab
        handleEconomySliderIndexChange(firstEconomyAvailableSliderIndex);
        handleBusinessSliderIndexChange(firstBusinessAvailableSliderIndex);
    }, [active]);
    var theme = useTheme('DesktopFlight').ModalContent;
    var sliderSettings = {
        slidesToShow: slidesToShow,
        infinite: false,
        speed: 250,
        slidesToScroll: slidesToScroll,
        swipe: isTablet
    };
    var economySliderSettings = __assign(__assign({}, sliderSettings), { initialSlide: firstEconomyAvailableSliderIndex, beforeChange: function (currentSlide, nextSlide) {
            handleEconomySliderIndexChange(nextSlide);
        } });
    var businessSliderSettings = __assign(__assign({}, sliderSettings), { className: 'slider-business', initialSlide: firstBusinessAvailableSliderIndex, beforeChange: function (currentSlide, nextSlide) {
            handleBusinessSliderIndexChange(nextSlide);
        } });
    var milesSliderSettings = __assign(__assign({}, sliderSettings), { className: 'slider-miles', slidesToShow: isTablet ? (isMobile ? (isLte340 ? 1.07 : 1.2) : 2) : 3, beforeChange: function (currentSlide, nextSlide) {
            handleMilesSliderIndexChange(nextSlide);
        } });
    var renderFareGroup = function (group, index) {
        var _a;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var isEconomyFareGroup = group.fareFamily.category === FlightClass.Economy;
        var isAvailable = isFareGroupAvailable(group, lastSelectedFare);
        var disabledForPriceInMiles = ((_c = (_b = group.prices) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.priceInMiles) > 0 && !((_f = (_e = (_d = group.prices) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.flight) === null || _f === void 0 ? void 0 : _f.canBePaidByMiles);
        return (React.createElement(FareGroupGrid, { className: cn((_a = {}, _a[theme.fareGroup_withoutUpgradeOption] = !!props.lastSelectedFare, _a)), showUnavailableOptions: true, onUpgrade: isEconomyFareGroup &&
                props.upgradeOptions.has(group.fareFamily.id) &&
                props.getOnFareSelect(props.upgradeOptions.get(group.fareFamily.id).canUpgradeTo), upgradeOption: isEconomyFareGroup && props.upgradeOptions.get(group.fareFamily.id), variant: 'white', key: index, isDisabled: !isAvailable || disabledForPriceInMiles, fare: group.fareFamily, onSelect: props.getOnFareSelect(group), price: !props.hidePrice && group.prices && group.prices[0] ? group.prices[0].price : null, flightId: (_h = (_g = group.prices) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.flight.id, priceInMiles: (_k = (_j = group.prices) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.priceInMiles, disabledForMiles: disabledForPriceInMiles }));
    };
    var sortByPrice = function (a, b) {
        var _a, _b, _c, _d, _e;
        if (!((_b = (_a = a.prices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.price.amount) || !((_e = (_d = (_c = b.prices) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.price) === null || _e === void 0 ? void 0 : _e.amount)) {
            return 0;
        }
        return a.prices[0].price.amount - b.prices[0].price.amount;
    };
    var renderSliderIndex = function (slidesCount, index, slidesToScroll) {
        var pagesCount = Math.ceil(slidesCount / slidesToScroll);
        if (isMobile) {
            pagesCount = slidesCount;
        }
        if ((isTablet && slidesCount > 2) || slidesCount > 3) {
            return (React.createElement("span", { className: theme.pagination__index },
                Math.ceil(index / slidesToScroll) + 1,
                " / ",
                pagesCount));
        }
        else {
            return null;
        }
    };
    var flightId = useMemo(function () {
        var _a, _b, _c, _d;
        return (_d = (_c = (_b = (_a = props.economyFares) === null || _a === void 0 ? void 0 : _a.find(function (fare) { return !!fare.fareFamily && !!fare.prices; })) === null || _b === void 0 ? void 0 : _b.prices) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.flight.id;
    }, [props.economyFares]);
    var isEconomyExists = React.useMemo(function () {
        return props.economyFares.some(function (economyFare) { return economyFare.prices; });
    }, [props.economyFares]);
    var isBusinessFaresExists = React.useMemo(function () {
        return props.businessFares.some(function (businessFare) { return businessFare.prices; });
    }, [props.businessFares]);
    var isMilesExists = React.useMemo(function () {
        return props.milesFares.some(function (milesFare) { return milesFare.prices; });
    }, [props.milesFares]);
    var getFareCode = function (fares) { var _a; return (_a = fares.find(function (fare) { return !!fare.fareFamily && !!fare.prices; })) === null || _a === void 0 ? void 0 : _a.fareFamily.id; };
    var compareFareCode = useMemo(function () {
        switch (active) {
            case Tabs.EconomyFares:
                return getFareCode(props.economyFares);
            case Tabs.BusinessFares:
                return getFareCode(props.businessFares);
            case Tabs.MilesFares:
                return getFareCode(props.milesFares);
            default:
                return null;
        }
    }, [props.economyFares, props.businessFares, props.milesFares, active]);
    return (React.createElement(Paper, { elevation: 0, className: theme.paper },
        React.createElement("div", { className: theme.header },
            React.createElement("h3", { className: theme.title },
                active === Tabs.FlightInfo && t('Select your bundle'),
                (active === Tabs.BusinessFares || active === Tabs.EconomyFares || active === Tabs.MilesFares) &&
                    t('Select your bundle')),
            React.createElement("div", { className: theme.buttons },
                (isEconomyExists || (!isMilesExists && !isBusinessFaresExists)) && (React.createElement(Button, { className: cn(theme.fareGroup__button, (_a = {},
                        _a[theme.fareGroup__button_active] = active === Tabs.EconomyFares,
                        _a)), onClick: handleEconomyButtonCLick }, t('Economy'))),
                isBusinessFaresExists && (React.createElement(Button, { className: cn(theme.fareGroup__button, theme.fareGroup__button_business, (_b = {},
                        _b[theme.fareGroup__button_active] = active === Tabs.BusinessFares,
                        _b)), onClick: handleBusinessButtonClick }, t('Business'))),
                isMilesExists && (React.createElement(Button, { className: cn(theme.fareGroup__button, theme.fareGroup__button, (_c = {},
                        _c[theme.fareGroup__button_active] = active === Tabs.MilesFares,
                        _c)), onClick: handleMilesButtonClick }, t('Premium'))),
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Button, { className: cn(theme.fareGroup__button, theme.fareGroup__button_right, (_d = {},
                            _d[theme.fareGroup__button_active] = active === Tabs.FlightInfo,
                            _d)), onClick: handleFlightDetailsButtonClick }, t('Flight info'))))),
        React.createElement("div", null,
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", null,
                    active === Tabs.EconomyFares && (React.createElement("div", { className: theme.slider__wrp },
                        React.createElement(FareGroupStub, { className: cn((_e = {},
                                _e[theme.fareGroup_withoutUpgradeOption] = !!props.lastSelectedFare,
                                _e)), category: FlightClass.Economy }),
                        React.createElement(Slider, __assign({}, economySliderSettings), props.economyFares.sort(sortByPrice).map(renderFareGroup)),
                        renderSliderIndex(props.economyFares.length, economySliderIndex, slidesToScroll))),
                    active === Tabs.BusinessFares && (React.createElement("div", { className: theme.slider__wrp },
                        React.createElement(FareGroupStub, { className: cn((_f = {},
                                _f[theme.fareGroup_withoutUpgradeOption] = !!props.lastSelectedFare,
                                _f)), category: FlightClass.Business }),
                        React.createElement(Slider, __assign({}, businessSliderSettings), props.businessFares.sort(sortByPrice).map(renderFareGroup)),
                        renderSliderIndex(props.businessFares.length, businessSliderIndex, slidesToScroll))),
                    active === Tabs.MilesFares && (React.createElement("div", { className: theme.slider__wrp },
                        React.createElement(FareGroupStub, { className: cn(theme.fareGroup_stub_business, (_g = {},
                                _g[theme.fareGroup_withoutUpgradeOption] = !!props.lastSelectedFare,
                                _g)), category: FlightClass.Miles }),
                        React.createElement(Slider, __assign({}, milesSliderSettings), props.milesFares.sort(sortByPrice).map(renderFareGroup)),
                        renderSliderIndex(props.milesFares.length, milesSliderIndex, milesSliderSettings.slidesToShow))),
                    active !== Tabs.FlightInfo && (React.createElement("div", { className: theme.compareFaresWrp },
                        React.createElement(Link, { className: theme.compareFaresLink, action: openFareCompare },
                            t('View full conditions'),
                            React.createElement("span", null, icons.Chevron))))),
                active === Tabs.FlightInfo && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: theme.selectedFareWrp }),
                    React.createElement(FlightInfoV2, { flight: props.flight, selectedFareFamily: props.lastSelectedFare })))),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                active === Tabs.EconomyFares && (React.createElement("div", { className: theme.slider__wrp },
                    React.createElement(Slider, __assign({}, economySliderSettings, { ref: economySliderRef }), props.economyFares.map(renderFareGroup)),
                    React.createElement(Pagination, { className: theme.pagination, itemsCount: props.economyFares.length, currentIndex: economySliderIndex, onChange: function (index) { return economySliderRef.current.slickGoTo(index); } }))),
                active === Tabs.BusinessFares && (React.createElement("div", { className: theme.slider__wrp },
                    React.createElement(Slider, __assign({}, businessSliderSettings, { ref: businessSliderRef }), props.businessFares.map(renderFareGroup)),
                    React.createElement(Pagination, { className: theme.pagination, itemsCount: props.businessFares.length, currentIndex: businessSliderIndex, onChange: function (index) { return businessSliderRef.current.slickGoTo(index); } }))),
                active === Tabs.MilesFares && (React.createElement("div", { className: theme.slider__wrp },
                    React.createElement(Slider, __assign({}, milesSliderSettings, { ref: milesSliderRef }), props.milesFares.map(renderFareGroup)),
                    React.createElement(Pagination, { className: theme.pagination, itemsCount: props.milesFares.length, currentIndex: milesSliderIndex, onChange: function (index) { return milesSliderRef.current.slickGoTo(index); } }))),
                active !== Tabs.FlightInfo && (React.createElement("div", { className: theme.compareFaresWrp },
                    React.createElement(Link, { className: theme.compareFaresLink, action: openFareCompare },
                        t('View full conditions'),
                        React.createElement("span", null, icons.Chevron)))),
                React.createElement("div", { className: theme.flightInfo },
                    React.createElement(FlightInfoV2, { flight: props.flight, selectedFareFamily: props.lastSelectedFare })))),
        React.createElement(Modal, { open: isFareCompareOpen, onClose: closeFareCompare },
            React.createElement(CompareFares, { code: compareFareCode, flightId: flightId }))));
};
export default DesktopFlightModalContent;
