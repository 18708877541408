import { __read } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Autocomplete } from '../../../../index';
var Nationality = function (_a) {
    var _b;
    var label = _a.label, field = _a.field, fieldState = _a.fieldState, error = _a.error, onBlur = _a.onBlur, disabled = _a.disabled, variant = _a.variant, options = _a.options;
    var _c = __read(useState(options), 2), filteredOptions = _c[0], setFilteredOptions = _c[1];
    var _d = __read(useState((_b = options.find(function (option) { return option.value === fieldState.input.value; })) === null || _b === void 0 ? void 0 : _b.label), 2), labelValue = _d[0], setLabelValue = _d[1];
    useEffect(function () {
        var _a;
        setLabelValue((_a = options.find(function (option) { return option.value === fieldState.input.value; })) === null || _a === void 0 ? void 0 : _a.label);
    }, [fieldState.input.value]);
    useEffect(function () {
        if (!fieldState.input.value) {
            setLabelValue('');
        }
    }, [fieldState.input.value]);
    return (React.createElement(Autocomplete, { name: field.name, label: label, variant: variant, inputValue: labelValue, onSelect: function (value) {
            fieldState.input.onChange(value);
            setLabelValue(options.find(function (option) { return option.value === value; }).label);
            if (onBlur) {
                onBlur();
            }
        }, onInputChange: function (event) {
            var value = event.target.value;
            setLabelValue(value);
            setFilteredOptions(options.filter(function (option) { return option.label.toLowerCase().includes(value.toLowerCase()); }));
            if (onBlur) {
                onBlur();
            }
        }, onBlur: function (event) {
            var _a;
            var value = event.target.value;
            if (!filteredOptions.length || !value) {
                // reset to the previous value
                setLabelValue((_a = options.find(function (option) { return option.value === fieldState.input.value; })) === null || _a === void 0 ? void 0 : _a.label);
            }
        }, error: error, options: filteredOptions, disabled: disabled }));
};
export default Nationality;
