import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { useUpsaleWarning } from '../../../Checkout/components/Checkout/Upsale/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getInsurances, getOrderId, getServicesFallbackURL, shouldRedirectToFallback } from '../../../Checkout/store/order/selectors';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import { useOrderServices } from '../../../__queries__/Order';
import { useCallback, useEffect } from 'react';
import { fillOrder } from '../../../Checkout/store/order/actions';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '../../../AdditionalServices/components/Insurance/DialogContent/DialogContent';
import { SlideBottom, Money } from '../../../index';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import icons from '../../icons';
import { getInsurancePrice } from '../../../Checkout/store/orderPrice/selectors';
import { getPassengerState } from '../../../Checkout/store/passengers/selectors';
import WarningModal from '../../../WarningModal';
import DownloadInsurances from '../../../AdditionalServices/components/Insurance/DownloadInsurances/DownloadInsurances';
import { useInsurance } from './hooks';
import InsurancePopup from '../../../InsurancePopup';
import Modal from '../../../Modal';
var InsuranceAdditionalService = function () {
    var _a, _b, _c, _d, _e;
    // i18n & styles
    var t = useTranslation('Checkout').t;
    var cardStyles = useTheme('CheckoutAdditionalService').Insurance;
    var css = useTheme('AdditionalServices').Insurance;
    // hooks
    var insurances = useSelector(getInsurances);
    var servicesRedirectURL = useSelector(getServicesFallbackURL);
    var orderId = useSelector(getOrderId);
    var shouldRedirect = useSelector(shouldRedirectToFallback);
    var issuedPrice = useSelector(getInsurancePrice);
    var passengers = useSelector(getPassengerState);
    var dispatch = useDispatch();
    // custom hooks
    var _f = useUpsaleWarning(), showWarning = _f.showWarning, closeWarning = _f.closeWarning, toggleWarningIfOrderConfirmed = _f.toggleWarningIfOrderConfirmed;
    var _g = __read(useOrderServices(), 2), saveOrderServicesMutation = _g[0], _h = _g[1], data = _h.data, loading = _h.loading, error = _h.error;
    // computed variables
    var minPrice = (_a = insurances === null || insurances === void 0 ? void 0 : insurances.insurancePrograms[0]) === null || _a === void 0 ? void 0 : _a.price;
    var isSelected = ((_b = insurances === null || insurances === void 0 ? void 0 : insurances.selectedInsurances) === null || _b === void 0 ? void 0 : _b.length) > 0;
    var _j = useInsurance({
        isSelected: isSelected,
        saveMutation: saveOrderServicesMutation,
        insurances: insurances,
        orderId: orderId,
        passengers: passengers
    }), 
    // data
    confirmationPopupIsOpen = _j.confirmationPopupIsOpen, downloadPopupOpen = _j.downloadPopupOpen, setDownloadPopupOpen = _j.setDownloadPopupOpen, insuranceRulesUrl = _j.insuranceRulesUrl, multiInsurance = _j.multiInsurance, openMultiInsurance = _j.openMultiInsurance, setInsuranceVisible = _j.setInsuranceVisible, errors = _j.errors, setErrors = _j.setErrors, passengerDocuments = _j.passengerDocuments, 
    // functions
    saveInsurances = _j.saveInsurances, toggleInsurance = _j.toggleInsurance, closeConfirmationPopup = _j.closeConfirmationPopup, closeDownloadPopup = _j.closeDownloadPopup, onInsuranceRemove = _j.onInsuranceRemove;
    useEffect(function () {
        var _a;
        if (loading === false && (data === null || data === void 0 ? void 0 : data.SaveOrderServices) && !((_a = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length)) {
            dispatch(fillOrder(data === null || data === void 0 ? void 0 : data.SaveOrderServices));
        }
    }, [loading, data, error]);
    var buttons = [];
    if ((_c = insurances.selectedInsurances[0]) === null || _c === void 0 ? void 0 : _c.policyUrl) {
        buttons.push({
            action: function () {
                if (insurances.selectedInsurances.length > 1) {
                    setDownloadPopupOpen(true);
                }
                else {
                    window.open(insurances.selectedInsurances[0].policyUrl, '_blank');
                }
            },
            text: t('Download')
        });
    }
    else {
        buttons.push({
            action: !shouldRedirect ? toggleInsurance : toggleWarningIfOrderConfirmed,
            text: t(multiInsurance ? 'Change' : 'Cancel'),
            isLoading: loading
        });
    }
    if (insuranceRulesUrl && !loading) {
        buttons.push({
            action: function () { return window.open(insuranceRulesUrl); },
            text: t('AdditionalServiceCard:Terms of service')
        });
    }
    var selectedInsurances = React.useMemo(function () {
        var insurancesMap = new Map();
        insurances.selectedInsurances.forEach(function (insurance) {
            insurancesMap.set(insurance.insuranceProgram.code, insurance);
        });
        return __spreadArray([], __read(insurancesMap.values()), false);
    }, [insurances]);
    var renderSelectedInsurance = function (insurance, index) { return (React.createElement("div", { key: index, className: cardStyles.selectedInsurance },
        React.createElement("span", { className: cardStyles.name }, insurance.insuranceProgram.name),
        React.createElement(Money, { money: insurance.insuranceProgram.price }))); };
    var renderInsuranceCardContent = useCallback(function () {
        var _a;
        if (selectedInsurances.length) {
            if (selectedInsurances.length === 1) {
                return React.createElement("div", null, selectedInsurances[0].insuranceProgram.description);
            }
            else {
                return selectedInsurances.map(renderSelectedInsurance);
            }
        }
        else {
            return React.createElement("span", null, (_a = insurances.insurancePrograms[0]) === null || _a === void 0 ? void 0 : _a.description);
        }
    }, [selectedInsurances, insurances]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckoutAdditionalService, { icon: icons.insuranceIcon, className: cardStyles.insurance, isLoading: loading, addClick: !shouldRedirect ? toggleInsurance : toggleWarningIfOrderConfirmed, header: t('Insurance'), isSelected: isSelected, priceFrom: minPrice, priceTotal: issuedPrice, description: (_d = insurances.insurancePrograms[0]) === null || _d === void 0 ? void 0 : _d.description, buttons: buttons }, renderInsuranceCardContent()),
        multiInsurance && (React.createElement(React.Fragment, null,
            React.createElement(Modal, { classes: {
                    paper: css.paper
                }, open: openMultiInsurance },
                React.createElement(InsurancePopup, { description: (_e = insurances.insurancePrograms[0]) === null || _e === void 0 ? void 0 : _e.description, insurances: insurances.insurancePrograms, selectedInsurances: selectedInsurances, passengers: passengerDocuments, onClose: function () { return setInsuranceVisible(false); }, onSave: function (state) {
                        saveInsurances(insurances.type, state.selectedInsurances, state.passengers);
                    }, isLoading: loading })),
            React.createElement(WarningModal, { title: 'Some error', content: 'error', isOpen: errors, onClose: function () { return setErrors(false); }, onButtonClick: function () { return setErrors(false); } }))),
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(Dialog, { open: confirmationPopupIsOpen, onClose: closeConfirmationPopup, classes: { paper: css.popup } },
                React.createElement(DialogContent, { closeConfirmationPopup: closeConfirmationPopup, onInsuranceRemove: onInsuranceRemove, refusingIsInProgress: loading })),
            React.createElement(Dialog, { open: downloadPopupOpen, onClose: closeDownloadPopup, classes: { paper: css.downloadPopup } },
                React.createElement(DownloadInsurances, { selectedInsurances: insurances.selectedInsurances }))),
        React.createElement(MediaQuery, { maxWidth: 'mobile' },
            React.createElement(SlideBottom, { onClose: closeConfirmationPopup, isOpen: confirmationPopupIsOpen },
                React.createElement("div", { className: css.slide__content },
                    React.createElement(DialogContent, { closeConfirmationPopup: closeConfirmationPopup, onInsuranceRemove: onInsuranceRemove, refusingIsInProgress: loading }))),
            React.createElement(SlideBottom, { isOpen: downloadPopupOpen, onClose: closeDownloadPopup },
                React.createElement(DownloadInsurances, { selectedInsurances: insurances.selectedInsurances }))),
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning })));
};
export default InsuranceAdditionalService;
