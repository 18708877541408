import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { initI18n } from '../../../utils';
import Button from '../../../Button';
initI18n('ServiceRecommendation');
var Base = function (_a) {
    var _b;
    var onConfirm = _a.onConfirm, onChange = _a.onChange, isMobileFullScreen = _a.isMobileFullScreen, isFreeService = _a.isFreeService, children = _a.children;
    var t = useTranslation('ServiceRecommendation').t;
    var css = useTheme('ServiceRecommendation').Base;
    return (React.createElement("div", { className: cn(css.wrapper, (_b = {}, _b[css.wrapper_mobileFullScreen] = isMobileFullScreen, _b)) },
        children,
        React.createElement("div", { className: css.footer },
            React.createElement(Button, { size: "large", variant: "flat", className: cn(css.button, css.button_cancel), onClick: onChange }, t('Change')),
            React.createElement(Button, { size: "large", variant: "flat", className: css.button, onClick: onConfirm }, t('Confirm')))));
};
export default Base;
