import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../utils';
import { Status } from '../types';
import { useState } from 'react';
import { useTheme } from '../../theme';
initI18n('Timer');
var sentIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z", fill: "#55D590" }),
    React.createElement("mask", { id: "mask0", mask: "alpha", maskUnits: "userSpaceOnUse", x: "2", y: "2", width: "20", height: "20" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
var waitIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52\n\t\t\t\t2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42\n\t\t\t\t20 12 20ZM11 7H12.5V12.25L17 14.92L16.25 16.15L11 13V7Z", fill: "#9A9A9A" })));
var Timer = function (_a) {
    var resendCode = _a.resendCode;
    var t = useTranslation('Timer').t;
    var css = useTheme('Timer').Timer;
    var _b = __read(useState(Status.Ready), 2), status = _b[0], setStatus = _b[1];
    var startTimer = function () {
        var seconds = 60;
        setStatus(Status.Ticking);
        var timer = setInterval(function () {
            seconds = seconds - 1;
            if (seconds <= 0) {
                clearInterval(timer);
                setStatus(Status.Ready);
                return;
            }
            if (document.getElementById('seconds')) {
                document.getElementById('seconds').innerText = seconds.toString();
            }
        }, 1000);
    };
    var sendCode = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setStatus(Status.Sending);
                    return [4 /*yield*/, resendCode()];
                case 1:
                    _a.sent();
                    setStatus(Status.Sent);
                    setTimeout(function () {
                        startTimer();
                    }, 2500);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: css.timer },
        status === Status.Ready && (React.createElement("span", { className: css.sliding },
            React.createElement("span", null, t("Didn't get the code?")),
            React.createElement("span", { className: css.link, onClick: sendCode }, t('Resend it')))),
        status === Status.Ticking && (React.createElement("span", { className: cn(css.wait, css.sliding) },
            waitIcon,
            t('You can get new code in'),
            " ",
            React.createElement("span", { id: "seconds" }, "60"))),
        status === Status.Sending && React.createElement("span", { className: css.sliding },
            t('Sending message'),
            "..."),
        status === Status.Sent && (React.createElement("span", { className: css.sliding },
            React.createElement("span", { className: css.sent },
                sentIcon,
                " ",
                t('Code sent'))))));
};
export default Timer;
