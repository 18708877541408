import React from 'react';
import { useTranslation } from 'react-i18next';
export var FareCode = function (_a) {
    var passengersRules = _a.passengersRules;
    var t = useTranslation('FareConditions').t;
    if (passengersRules.length === 0) {
        return null;
    }
    var codes = passengersRules.reduce(function (acc, passengerRules) {
        var _a, _b;
        if ((_b = (_a = passengerRules === null || passengerRules === void 0 ? void 0 : passengerRules.rules) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.fareCode) {
            acc.push(passengerRules.rules[0].fareCode);
        }
        return acc;
    }, []);
    return (React.createElement(React.Fragment, null,
        t('Fare code'),
        ": ",
        codes.join(', ')));
};
export default FareCode;
