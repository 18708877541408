import { Measurement } from '../BaggageService/types';
export var StepType;
(function (StepType) {
    StepType["Seats"] = "Seats";
    StepType["Baggage"] = "Baggage";
    StepType["Meals"] = "Meal";
    StepType["Passengers"] = "Passengers";
    StepType["Payment"] = "Payment";
})(StepType || (StepType = {}));
export var convertMealObject = function (service) { return ({
    id: service.id,
    name: service.name,
    description: service.description,
    price: service.price,
    imageURL: service.image,
    icon: service.icon,
    category: service.category,
    weight: service.mealWeight,
    allergens: service.allergens ? [service.allergens] : null,
    segmentsId: service.allowedSegments.map(function (group) { return group[0]; }),
    allowedPassengers: service.allowedPassengers.map(function (group) { return group[0]; }),
    canBeAdded: service.canBeAdded,
    allowedSegments: service.allowedSegments
}); };
export var convertBaggageObject = function (service) { return ({
    id: service.id,
    allowedPassengers: service.allowedPassengers.map(function (group) { return group[0]; }),
    segmentsId: service.allowedSegments,
    type: service.baggageType,
    value: {
        amount: parseInt(service.baggageWeight),
        measurement: Measurement.Kilograms
    },
    size: service.size,
    included: 0,
    price: {
        amount: service.price.amount,
        currency: service.price.currency
    },
    name: service.name,
    description: service.description,
    canBeAdded: service.canBeAdded,
    rfics: service.rfisc
}); };
