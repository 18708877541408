import * as React from 'react';
export var descSortIcon = (React.createElement("svg", { fill: "none", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5 5h2v14H5V5zm6 3H9v11h2V8zm2 3h2v8h-2v-8zm4 3h2v5h-2v-5z", fill: "currentColor" })));
export var ascSortIcon = (React.createElement("svg", { fill: "none", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19 5h-2v14h2V5zm-6 3h2v11h-2V8zm-2 3H9v8h2v-8zm-4 3H5v5h2v-5z", fill: "currentColor" })));
export var goToBeginIcon = (React.createElement("svg", { fill: "none", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 25 24" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.32 7.41L17.91 6l-6 6 6 6 1.41-1.41L14.74 12l4.58-4.59zm-6.41 0L11.5 6l-6 6 6 6 1.41-1.41L8.33 12l4.58-4.59z", fill: "currentColor" })));
export var goToPrevIcon = (React.createElement("svg", { fill: "none", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 25 24" },
    React.createElement("path", { d: "M15.91 7.41L14.5 6l-6 6 6 6 1.41-1.41L11.33 12l4.58-4.59z", fill: "currentColor" })));
export var goToNextIcon = (React.createElement("svg", { fill: "none", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 25 24" },
    React.createElement("path", { d: "M9.09 7.41L10.5 6l6 6-6 6-1.41-1.41L13.67 12 9.09 7.41z", fill: "currentColor" })));
export var goToEndIcon = (React.createElement("svg", { fill: "none", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 25 24" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.5 7.41L6.91 6l6 6-6 6-1.41-1.41L10.08 12 5.5 7.41zm6.41 0L13.32 6l6 6-6 6-1.41-1.41L16.49 12l-4.58-4.59z", fill: "currentColor" })));
