import { __assign, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useTheme } from '../../../../theme';
import { Money } from '../../../../Money';
import { Included } from '../../../../Icons';
import { collectExchangeVoidServices, groupByEmd, groupSumToRefundVoidByEmd, isExchangeVoidServicesEmpty, passengerNameById, segmentBySegmentId } from '../../utils';
import { useTranslation } from 'react-i18next';
import { Steps } from '../../../ServicesCardVoid/types';
import CheckboxField from '../CheckboxField/CheckboxField';
import AdditionalServicePrice from '../AdditionalServicePrice/AdditionalServicePrice';
import TotalVoidRefund from '../TotalVoidRefund/TotalVoidRefund';
import { ServiceCardContext } from '../../ServicesCard';
import { GdsServiceProductStatus } from '../../../../__generated__/globalTypes';
var CommonCard = function (props) {
    var theme = useTheme('ServicesCard').ServicesCard;
    var t = useTranslation('ServiceCard').t;
    var groupedByEmd = useMemo(function () {
        if (props.exchangeVoidMode) {
            return groupByEmd(props.services);
        }
        return null;
    }, [props.services, props.exchangeVoidMode]);
    useEffect(function () {
        if (props.exchangeVoidMode && props.step === Steps.Select) {
            props.setProductsIdsByEmd(groupedByEmd.productsByEmdMap);
        }
    }, [props.exchangeVoidMode, props.step]);
    var sumToRefundVoidByEmd = useMemo(function () {
        if (props.exchangeVoidMode && props.step === Steps.Confirm) {
            return groupSumToRefundVoidByEmd(props.calculation);
        }
        return null;
    }, [props.calculation, props.step, props.exchangeVoidMode]);
    var orderServicesByIdMap = useMemo(function () {
        var orderServicesByIdMap = new Map();
        props.order.additionalServices.gdsServices.services.forEach(function (service) {
            orderServicesByIdMap.set(service.id, service);
        });
        return orderServicesByIdMap;
    }, [props.order.additionalServices.gdsServices.services]);
    var exchangeVoidServices = useMemo(function () {
        return collectExchangeVoidServices(props.calculation, props.servicesIds);
    }, [props.calculation]);
    var additionalServiceByPaidStatusRenderer = useCallback(function (name, count, price, service, passengerId, isPaid) {
        return (React.createElement("div", { className: theme.serviceItem },
            React.createElement("div", { className: theme.serviceItem__name },
                props.step !== Steps.Confirm && "".concat(name, ", x").concat(count),
                props.step === Steps.Confirm && "".concat(name)),
            !props.exchangeVoidMode && (React.createElement("div", { className: theme.priceWrapper }, isPaid ? (React.createElement("div", { className: theme.serviceItem__check }, Included)) : (React.createElement(AdditionalServicePrice, { price: price, service: service, passengerId: passengerId, type: 'common' }))))));
    }, [props.services, props.order, props.step, props.isLoading]);
    var renderAdditionalService = useCallback(function (service, passengerId) {
        var serviceInfo = orderServicesByIdMap.get(service.serviceId);
        var price = __assign(__assign({}, serviceInfo.price), { amount: serviceInfo.price.amount * service.count });
        var unpaidProductsCount = 0;
        var unpaidPrice = service.products.reduce(function (acc, product) {
            if (product.status === GdsServiceProductStatus.Booked) {
                unpaidProductsCount++;
                if (acc) {
                    return __assign(__assign({}, acc), { amount: Number(acc.amount) + Number(product.price.amount) });
                }
                else {
                    return product.price;
                }
            }
            return acc;
        }, null);
        return (React.createElement("div", { key: "".concat(service.serviceId) },
            // paid services or void/exchange
            service.confirmedCount > 0 &&
                additionalServiceByPaidStatusRenderer(serviceInfo.name, service.confirmedCount, price, service, passengerId, true),
            // unpaid services
            !props.exchangeVoidMode &&
                unpaidProductsCount > 0 &&
                additionalServiceByPaidStatusRenderer(serviceInfo.name, unpaidProductsCount, unpaidPrice, service, passengerId, false)));
    }, [props.services, props.order, props.step, props.isLoading]);
    var renderIncludedService = useCallback(function (service, index) {
        var serviceInfo = service.service;
        return (React.createElement("div", { key: "".concat(serviceInfo.name).concat(index), className: theme.serviceItem },
            React.createElement("div", null,
                serviceInfo.name,
                ",",
                serviceInfo.baggageWeight
                    ? " ".concat(service === null || service === void 0 ? void 0 : service.count, "x").concat(serviceInfo.baggageWeight)
                    : " x".concat(service === null || service === void 0 ? void 0 : service.count)),
            React.createElement("div", { className: theme.serviceItem__check }, Included)));
    }, [props.services]);
    var renderSegmentServices = useCallback(function (passengerId, segmentServices) {
        var segmentsIds = Object.keys(segmentServices);
        var hasSelectedServices = Object.values(segmentServices).some(function (services) { var _a, _b; return ((_a = services.included) === null || _a === void 0 ? void 0 : _a.length) || ((_b = services.additional) === null || _b === void 0 ? void 0 : _b.length); });
        if (!hasSelectedServices) {
            return null;
        }
        return (React.createElement("div", { key: passengerId, className: theme.passengerSegmentWrapper }, segmentsIds.map(function (segmentId) {
            var _a, _b, _c, _d;
            var services = segmentServices[segmentId];
            if (!((_a = services === null || services === void 0 ? void 0 : services.included) === null || _a === void 0 ? void 0 : _a.length) && !((_b = services === null || services === void 0 ? void 0 : services.additional) === null || _b === void 0 ? void 0 : _b.length)) {
                return null;
            }
            return (React.createElement("div", { className: theme.passengerSegmentWrapperItem, key: "".concat(passengerId).concat(segmentId) },
                React.createElement("div", null,
                    React.createElement("div", { className: theme.passengerSegment },
                        React.createElement("span", { className: theme.passengerSegment__name }, passengerNameById(passengerId, props.order.travellers)),
                        React.createElement("span", null, segmentId === 'allSegments'
                            ? t('All route')
                            : segmentBySegmentId(segmentId, props.order))),
                    !props.exchangeVoidMode && ((_c = services === null || services === void 0 ? void 0 : services.included) === null || _c === void 0 ? void 0 : _c.map(renderIncludedService)), (_d = services === null || services === void 0 ? void 0 : services.additional) === null || _d === void 0 ? void 0 :
                    _d.map(function (services) {
                        return renderAdditionalService(services, passengerId);
                    }))));
        })));
    }, [props.services, props.order, props.isLoading, exchangeVoidServices, props.exchangeVoidMode]);
    var renderGroupByEmdServices = useCallback(function (voidRefundServices) {
        return (React.createElement("div", { className: theme.list }, Object.entries(groupedByEmd === null || groupedByEmd === void 0 ? void 0 : groupedByEmd.servicesByEmd).map(function (_a) {
            var _b = __read(_a, 2), emd = _b[0], emdServices = _b[1];
            if (props.step === Steps.Confirm && !voidRefundServices.hasOwnProperty(emd)) {
                return null;
            }
            return (React.createElement("div", { key: emd },
                props.step === Steps.Select ? (React.createElement(CheckboxField, { name: emd, isLoading: props.isLoading })) : (React.createElement("div", { className: theme.emdRefundVoid },
                    React.createElement("div", null,
                        "EMD: ",
                        emd),
                    React.createElement(Money, { money: sumToRefundVoidByEmd.get(emd) }))),
                Object.entries(emdServices).map(function (_a) {
                    var _b = __read(_a, 2), passengerId = _b[0], segmentsServices = _b[1];
                    return (React.createElement("div", { key: passengerId, className: theme.passengerEmdWrapper }, renderSegmentServices(passengerId, segmentsServices)));
                })));
        })));
    }, [groupedByEmd === null || groupedByEmd === void 0 ? void 0 : groupedByEmd.servicesByEmd, props.isLoading, props.step]);
    var renderRefundVoid = function (voidRefundServices, name) {
        var _a;
        if (!((_a = Object.keys(voidRefundServices)) === null || _a === void 0 ? void 0 : _a.length) || props.step !== Steps.Confirm) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.refundVoid }, name),
            renderGroupByEmdServices(voidRefundServices)));
    };
    var isConfirmStepOnExchangeVoidMode = props.exchangeVoidMode && props.step === Steps.Confirm;
    if (isConfirmStepOnExchangeVoidMode && isExchangeVoidServicesEmpty(exchangeVoidServices)) {
        return (React.createElement("div", { className: theme.emptyVoidRefundText }, t('You have not set any services for refund calculating in this category')));
    }
    return (React.createElement("div", null,
        isConfirmStepOnExchangeVoidMode && (React.createElement(React.Fragment, null,
            renderRefundVoid(exchangeVoidServices.servicesForRefund, t('Refund')),
            React.createElement(TotalVoidRefund, { type: 'refund', refundVoidServices: exchangeVoidServices }),
            renderRefundVoid(exchangeVoidServices.servicesForVoid, t('Void')),
            React.createElement(TotalVoidRefund, { type: 'void', refundVoidServices: exchangeVoidServices }))),
        props.exchangeVoidMode && props.step === Steps.Select && renderGroupByEmdServices(null),
        !props.exchangeVoidMode &&
            Object.entries(props.services).map(function (_a) {
                var _b = __read(_a, 2), passengerId = _b[0], segmentsServices = _b[1];
                return renderSegmentServices(passengerId, segmentsServices);
            })));
};
var CommonCardWithConsumer = function (props) {
    return (React.createElement(ServiceCardContext.Consumer, null, function (contextProps) {
        return React.createElement(CommonCard, __assign({}, props, contextProps));
    }));
};
export default CommonCardWithConsumer;
