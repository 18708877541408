import { __generator } from "tslib";
import { select, takeLatest, call, put } from 'redux-saga/effects';
import { getSelectedServices } from './selectors';
import { ADD_SERVICE, REMOVE_SERVICE, SET_SERVICE, DELETE_ALL_TYPES_ON_SEGMENT, SET_MULTIPLE_SELECTED_SERVICES, SAVE_SELECTED_SERVICES } from './actions';
import { createClient } from '../../../graphql-client';
var SaveOrderServices = require('../../../__queries__/query.graphql').SaveOrderServices;
import * as OrderPrice from '../../../__queries__/OrderPrice.graphql';
import { isBookedOrConfirmed, getOrderId, getOrder } from '../order/selectors';
import { fillOrder } from '../order/actions';
import { setOrderPrice } from '../orderPrice/actions';
import { setServicesIsSaving, setServicesSaved } from '../servicesIsSaving/actions';
import { setSagaRequestErrors } from '../sagaRequestErrors/actions';
function priceBreakdownRequest(services, orderId) {
    var client = createClient();
    return client.mutate({
        mutation: OrderPrice.OrderPriceQuery,
        variables: {
            params: {
                id: orderId,
                services: services
            }
        }
    });
}
function saveSelectedServicesRequest(services, orderId) {
    var client = createClient();
    return client.mutate({
        mutation: SaveOrderServices,
        variables: {
            params: {
                id: orderId,
                services: services
            }
        }
    });
}
function saveServiceWorker() {
    var selectedServices, orderId, isBookedOrConfirmedOrder, orderPrice, request, e_1, previousOrderState, errors;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getSelectedServices)];
            case 1:
                selectedServices = _a.sent();
                return [4 /*yield*/, select(getOrderId)];
            case 2:
                orderId = _a.sent();
                return [4 /*yield*/, select(isBookedOrConfirmed)];
            case 3:
                isBookedOrConfirmedOrder = _a.sent();
                if (!!isBookedOrConfirmedOrder) return [3 /*break*/, 7];
                return [4 /*yield*/, call(priceBreakdownRequest, selectedServices, orderId)];
            case 4:
                orderPrice = _a.sent();
                if (!(orderPrice.data.OrderPrice && !orderPrice.errors)) return [3 /*break*/, 6];
                return [4 /*yield*/, put(setOrderPrice(orderPrice.data.OrderPrice))];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6: return [3 /*break*/, 9];
            case 7: return [4 /*yield*/, put(setServicesIsSaving())];
            case 8:
                _a.sent();
                _a.label = 9;
            case 9:
                _a.trys.push([9, 15, , 21]);
                return [4 /*yield*/, call(saveSelectedServicesRequest, selectedServices, orderId)];
            case 10:
                request = _a.sent();
                if (!(request.data.SaveOrderServices && !request.errors)) return [3 /*break*/, 12];
                return [4 /*yield*/, put(fillOrder(request.data.SaveOrderServices))];
            case 11:
                _a.sent();
                _a.label = 12;
            case 12:
                if (!isBookedOrConfirmedOrder) return [3 /*break*/, 14];
                return [4 /*yield*/, put(setServicesSaved())];
            case 13:
                _a.sent();
                _a.label = 14;
            case 14: return [3 /*break*/, 21];
            case 15:
                e_1 = _a.sent();
                return [4 /*yield*/, select(getOrder)];
            case 16:
                previousOrderState = _a.sent();
                return [4 /*yield*/, put(fillOrder(previousOrderState))];
            case 17:
                _a.sent();
                return [4 /*yield*/, put(setServicesSaved())];
            case 18:
                _a.sent();
                errors = e_1 === null || e_1 === void 0 ? void 0 : e_1.graphQLErrors;
                if (!((errors === null || errors === void 0 ? void 0 : errors.length) > 0)) return [3 /*break*/, 20];
                return [4 /*yield*/, put(setSagaRequestErrors(errors.map(function (error) { return error === null || error === void 0 ? void 0 : error.message; })))];
            case 19:
                _a.sent();
                _a.label = 20;
            case 20: return [3 /*break*/, 21];
            case 21: return [2 /*return*/];
        }
    });
}
export function saveServicesSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest([
                    ADD_SERVICE,
                    REMOVE_SERVICE,
                    SET_SERVICE,
                    DELETE_ALL_TYPES_ON_SEGMENT,
                    SET_MULTIPLE_SELECTED_SERVICES,
                    SAVE_SELECTED_SERVICES
                ], saveServiceWorker)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
