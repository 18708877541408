import { __assign } from "tslib";
import { createSelector } from 'reselect';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../__generated__/globalTypes';
import { groupSeats, groupServices } from './utils';
export var getPassengersState = function (state) { return state.passengers; };
export var getSelectedServicesState = function (state) { return state.selectedServices; };
export var getSelectedServices = createSelector([getSelectedServicesState, getPassengersState], function (state, passengers) {
    var passengersServices = [];
    passengers.forEach(function (passenger) {
        if (state[passenger.id]) {
            var passengerServices = state[passenger.id];
            var seatsServices_1 = [], gdsServices_1 = [];
            var _loop_1 = function (serviceId) {
                if (passengerServices.hasOwnProperty(serviceId) && passengerServices[serviceId] !== null) {
                    var services = passengerServices[serviceId];
                    services.forEach(function (service) {
                        switch (service.servicePayload.type) {
                            case OrderAdditionalServiceGdsServiceServiceType.Seat:
                                var seatServicePayload_1 = service.servicePayload;
                                if (service.number > 0) {
                                    service.segments.forEach(function (segmentId) {
                                        seatsServices_1.push({
                                            segmentId: segmentId,
                                            deckId: seatServicePayload_1.deckId,
                                            letter: seatServicePayload_1.letter,
                                            row: parseInt(seatServicePayload_1.row)
                                        });
                                    });
                                }
                                break;
                            case OrderAdditionalServiceGdsServiceServiceType.Baggage:
                            case OrderAdditionalServiceGdsServiceServiceType.Meal:
                            case OrderAdditionalServiceGdsServiceServiceType.BusinessLounge:
                            case OrderAdditionalServiceGdsServiceServiceType.Vip:
                            case OrderAdditionalServiceGdsServiceServiceType.Misc:
                                gdsServices_1.push({
                                    segmentIds: service.segments,
                                    serviceId: serviceId,
                                    count: service.number
                                });
                                break;
                        }
                    });
                }
            };
            for (var serviceId in passengerServices) {
                _loop_1(serviceId);
            }
            passengersServices.push({
                travellerId: passenger.id,
                gdsServices: {
                    setServices: gdsServices_1
                },
                seats: seatsServices_1
            });
        }
    });
    return passengersServices;
});
export var getHasSelectedSeats = createSelector(getSelectedServices, function (selectedServices) {
    return selectedServices === null || selectedServices === void 0 ? void 0 : selectedServices.some(function (travellerServices) { var _a; return ((_a = travellerServices.seats) === null || _a === void 0 ? void 0 : _a.length) > 0; });
});
export var hasOrderSelectedServices = createSelector([getSelectedServices, getHasSelectedSeats], function (selectedServices, hasSelectedSeats) {
    return hasSelectedSeats || (selectedServices === null || selectedServices === void 0 ? void 0 : selectedServices.some(function (service) { var _a, _b; return ((_b = (_a = service.gdsServices) === null || _a === void 0 ? void 0 : _a.setServices) === null || _b === void 0 ? void 0 : _b.length) > 0; }));
});
export var groupAllSelectedServices = function (additionalServices, travellers, flightSegments) {
    var seats = {};
    var meals = {};
    var baggage = {};
    var misc = {};
    Object.keys(travellers).forEach(function (travellerId) {
        var traveller = travellers[travellerId];
        var groupedMeal = groupServices(additionalServices, traveller, travellerId, OrderAdditionalServiceGdsServiceServiceType.Meal, flightSegments);
        meals = __assign(__assign({}, meals), groupedMeal);
        var groupedBaggage = groupServices(additionalServices, traveller, travellerId, OrderAdditionalServiceGdsServiceServiceType.Baggage, flightSegments);
        baggage = __assign(__assign({}, baggage), groupedBaggage);
        var groupedSeats = groupSeats(traveller, travellerId);
        seats = __assign(__assign({}, seats), groupedSeats);
        var groupedMisc = groupServices(additionalServices, traveller, travellerId, OrderAdditionalServiceGdsServiceServiceType.Misc, flightSegments);
        misc = __assign(__assign({}, misc), groupedMisc);
    });
    return {
        seats: seats,
        meals: meals,
        baggage: baggage,
        misc: misc
    };
};
export var getPassengerStateCompatible = createSelector([getPassengersState, getSelectedServicesState], function (passengers, selectedServices) {
    var passengerState = passengers.map(function (passenger) {
        var passengerServices = selectedServices[passenger.id];
        var passengersServices = [], seats = [];
        var _loop_2 = function (serviceId) {
            if (passengerServices.hasOwnProperty(serviceId) && passengerServices[serviceId]) {
                var services = passengerServices[serviceId];
                services.forEach(function (service) {
                    if (service.number > 0) {
                        switch (service.servicePayload.type) {
                            case OrderAdditionalServiceGdsServiceServiceType.Seat:
                                var seatServicePayload_2 = service.servicePayload;
                                service.segments.forEach(function (segmentId) {
                                    seats.push(__assign(__assign({}, seatServicePayload_2), { segmentId: segmentId }));
                                });
                                break;
                            case OrderAdditionalServiceGdsServiceServiceType.Baggage:
                            case OrderAdditionalServiceGdsServiceServiceType.Meal:
                                passengersServices.push({
                                    segmentIds: service.segments,
                                    serviceId: serviceId,
                                    count: service.number,
                                    confirmedCount: service.servicePayload.confirmedCount
                                });
                                break;
                        }
                    }
                });
            }
        };
        for (var serviceId in passengerServices) {
            _loop_2(serviceId);
        }
        return {
            id: passenger.id,
            type: passenger.type,
            values: passenger.values,
            services: passengersServices,
            linkedTraveller: passenger.linkedTraveller,
            seats: seats
        };
    });
    return passengerState;
});
