import * as React from 'react';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { LocationType } from '../../../../../../../types';
import { MOBILE_MIN_WIDTH } from '../../../../../../../utils';
import { ConfigContext } from '../../../../../../../context';
import { useTheme } from '../../../../../../../theme';
var Value = function (_a) {
    var isOpen = _a.isOpen, isLoading = _a.isLoading, location = _a.location, inputValue = _a.inputValue, type = _a.type, withMultiCity = _a.withMultiCity, withSwapButton = _a.withSwapButton;
    var css = useTheme('SearchForm').Value;
    var t = useTranslation('SearchForm').t;
    var alternative = React.useContext(ConfigContext).SearchForm.mobileFormType !== 'common';
    var renderFocusedWrapper = function () {
        var _a;
        return (React.createElement("div", { className: cn(css.wrapper, css.wrapper_focused, (_a = {},
                _a[css.wrapper_padded] = isLoading,
                _a)) },
            !inputValue && (React.createElement("span", { className: css.placeholder }, t(type === LocationType.Departure
                ? 'From, enter city or country'
                : 'To, enter city or country'))),
            isLoading && React.createElement("span", { className: css.loader })));
    };
    var renderValue = function () {
        var _a;
        return (React.createElement("div", { className: cn(css.wrapper, (_a = {},
                _a[css.wrapper_alternative] = alternative,
                _a[css.withMultiCity] = withMultiCity,
                _a)) },
            React.createElement("span", { className: css.value }, location.name),
            React.createElement("span", { className: css.code }, location.iata)));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            location && renderValue(),
            isOpen && renderFocusedWrapper()),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, location && renderValue())));
};
export default Value;
