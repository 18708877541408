import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import LoyaltyConnect from '../../../../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import Modal from '../../../../../../Modal';
import { useAccountInfo } from '../../../../../utils';
var LoyaltyCard = function (props) {
    var _a = __read(React.useState(false), 2), isLoyaltyConnectOpen = _a[0], setLoyaltyConnectOpen = _a[1];
    var _b = useAccountInfo(), currentUser = _b.currentUser, refetch = _b.refetch;
    var userInfo = React.useMemo(function () {
        var _a, _b, _c, _d;
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) || !((_a = currentUser.CurrentUser) === null || _a === void 0 ? void 0 : _a.userProfile)) {
            return {
                lastName: '',
                firstName: '',
                email: ''
            };
        }
        return {
            lastName: (_b = currentUser.CurrentUser.userProfile.values.find(function (value) { return value.name === 'lastName'; })) === null || _b === void 0 ? void 0 : _b.value,
            firstName: (_c = currentUser.CurrentUser.userProfile.values.find(function (value) { return value.name === 'firstName'; })) === null || _c === void 0 ? void 0 : _c.value,
            email: (_d = currentUser.CurrentUser.userProfile.values.find(function (value) { return value.name === 'email'; })) === null || _d === void 0 ? void 0 : _d.value
        };
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser]);
    var onLoyaltyConnectCloseHandler = function () {
        props.setLoyalty('');
        setLoyaltyConnectOpen(false);
    };
    var onLoyaltyConnectCardSavedHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoyaltyConnectOpen(false);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, refetch()];
                case 2:
                    data = (_b.sent()).data;
                    if ((_a = data === null || data === void 0 ? void 0 : data.CurrentUser) === null || _a === void 0 ? void 0 : _a.userFfpInfo) {
                        props.setLoyalty(data.CurrentUser.userFfpInfo.cardNumber, true);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    console.log(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var requestLoyaltyCard = function () {
        var _a, _b;
        if (((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) === null || _a === void 0 ? void 0 : _a.userFfpInfo) && !((_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) === null || _b === void 0 ? void 0 : _b.isFfpAuthorizationNeeded)) {
            props.setLoyalty(currentUser.CurrentUser.userFfpInfo.cardNumber);
        }
        else {
            setLoyaltyConnectOpen(true);
        }
    };
    return (React.createElement(React.Fragment, null,
        props.children(requestLoyaltyCard),
        React.createElement(Modal, { maxWidth: "sm", open: isLoyaltyConnectOpen, onClose: onLoyaltyConnectCloseHandler },
            React.createElement(LoyaltyConnect, { userInfo: userInfo, onLoyaltyCardSaved: onLoyaltyConnectCardSavedHandler, authType: "authenticate" }))));
};
export default LoyaltyCard;
