import { __assign } from "tslib";
import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import Input from '../../Input';
import { Button } from '../../index';
import { useTheme } from '../../theme';
import * as icons from '../../Icons';
import { PromoCodeStatus } from '../types';
var PromoCodeForm = function (props) {
    var t = useTranslation('PromoCode').t;
    var theme = useTheme('PromoCode').PromoCodeForm;
    return (React.createElement(Form, { onSubmit: props.onSubmit }, function (_a) {
        var _b, _c;
        var handleSubmit = _a.handleSubmit;
        return (React.createElement("form", { onSubmit: handleSubmit, className: theme.form },
            React.createElement(Field, { name: 'promoCode', validate: function (value) {
                    if (!value || !value.trim()) {
                        return t('Required');
                    }
                } }, function (_a) {
                var input = _a.input, meta = _a.meta;
                return (React.createElement(Input, { absoluteHintPosition: true, TextFieldProps: __assign(__assign({}, input), { label: t('Enter a promo code'), type: 'input', onChange: input.onChange, error: meta.touched && !meta.valid, helperText: meta.touched && !meta.valid ? meta.error : null }) }));
            }),
            React.createElement("div", { className: cn(theme.statusMessage, (_b = {},
                    _b[theme.statusMessage_applied] = props.status === PromoCodeStatus.Applied,
                    _b[theme.statusMessage_notApplied] = props.status === PromoCodeStatus.NotApplied,
                    _b)) },
                React.createElement(Button, { type: 'submit', className: cn(theme.button, (_c = {},
                        _c[theme.button_empty] = props.status === PromoCodeStatus.Empty,
                        _c[theme.button_applied] = props.status === PromoCodeStatus.Applied,
                        _c)), isLoading: props.isLoading },
                    props.status === PromoCodeStatus.Empty && icons.ArrowForward,
                    props.status === PromoCodeStatus.Applied && icons.Choosed,
                    props.status === PromoCodeStatus.NotApplied && icons.ArrowForward),
                !props.isLoading && (React.createElement(React.Fragment, null, props.status === PromoCodeStatus.Applied && t('Promo code applied'))))));
    }));
};
export default PromoCodeForm;
