import { __rest } from "tslib";
import * as React from 'react';
import { forwardRef } from 'react';
import PhoneInputComponent from 'react-phone-number-input';
var formatPhoneNumberIntl = require('react-phone-number-input').formatPhoneNumberIntl;
var parsePhoneNumberFromString = require('libphonenumber-js').parsePhoneNumberFromString;
import Input from '../Input/Input';
import { useTheme } from '../theme';
var Context = React.createContext({
    textFieldsProps: {},
    originalValue: null
});
var InputWrapper = forwardRef(function (_a, ref) {
    var onFocus = _a.onFocus, onKeyDown = _a.onKeyDown, props = __rest(_a, ["onFocus", "onKeyDown"]);
    var onChange = function (e) { return props.onChangeValue(e.target.value); };
    // вместо onChange используется onChangeValue, так как пропс onChange обертывается и не тригерится
    // если значение в инпуте некорректное,
    // это приводит к несоответствию значения поля в стейте final form и значению в инпуте
    var _b = React.useContext(Context), textFieldsProps = _b.textFieldsProps, originalValue = _b.originalValue;
    var css = useTheme('PhoneInput').PhoneInput;
    var parsedPhone;
    if (originalValue.startsWith('8') &&
        originalValue.replace(/\(|\)\s/, '').length === 11 &&
        parsePhoneNumberFromString(originalValue, 'RU')) {
        parsedPhone = formatPhoneNumberIntl(parsePhoneNumberFromString(originalValue, 'RU').number);
    }
    else if (originalValue.startsWith('9') && parsePhoneNumberFromString(originalValue, 'RU')) {
        parsedPhone = parsePhoneNumberFromString(originalValue, 'RU').formatNational();
    }
    else {
        parsedPhone = formatPhoneNumberIntl(originalValue) || formatPhoneNumberIntl("+".concat(originalValue));
    }
    return (React.createElement(Input, { absoluteHintPosition: true, className: css.input, inputClassName: textFieldsProps.className, TextFieldProps: {
            onFocus: onFocus,
            onKeyDown: onKeyDown,
            onChange: onChange,
            // Do not spread `context.textFieldsProps` there,
            // or `react-phone-number-input` won't work.
            name: textFieldsProps.name,
            onBlur: textFieldsProps.onBlur,
            label: textFieldsProps.label,
            error: textFieldsProps.error,
            helperText: textFieldsProps.helperText,
            placeholder: textFieldsProps.placeholder,
            type: 'tel'
        }, disabled: props.disabled, value: parsedPhone ? parsedPhone : props.value, tabIndex: textFieldsProps.tabIndex, endAdornment: props.endAdornment }));
});
var PhoneInput = function (_a) {
    var className = _a.className, value = _a.value, _b = _a.textFieldsProps, textFieldsProps = _b === void 0 ? {} : _b, _c = _a.displayInitialValueAsLocalNumber, displayInitialValueAsLocalNumber = _c === void 0 ? true : _c, disabled = _a.disabled, endAdornment = _a.endAdornment;
    return (React.createElement(Context.Provider, { value: {
            textFieldsProps: textFieldsProps,
            originalValue: value // передаем через контекст чтобы значение не форматировалось
        } },
        React.createElement(PhoneInputComponent, { country: 'RU', className: className, onChangeValue: textFieldsProps.onChange, onFocus: textFieldsProps.onFocus, onBlur: textFieldsProps.onBlur, showCountrySelect: false, limitMaxLength: true, displayInitialValueAsLocalNumber: displayInitialValueAsLocalNumber, value: value || '', inputComponent: InputWrapper, disabled: disabled, endAdornment: endAdornment })));
};
export default PhoneInput;
