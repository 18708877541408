import { __assign, __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import ButtonMUI from '@material-ui/core/ButtonBase';
import { useTheme } from '../../../theme';
var BaseButton = function (_a) {
    var _b;
    var _c = _a.size, size = _c === void 0 ? 'common' : _c, props = __rest(_a, ["size"]);
    var css = useTheme('Button').BaseButton;
    var children = props.children, className = props.className, onClick = props.onClick, restProps = __rest(props, ["children", "className", "onClick"]);
    return (React.createElement(ButtonMUI, __assign({ type: "button" }, restProps, { className: cn(css.button, (_b = {},
            _b[css.button_small] = size === 'small',
            _b), className), onClick: restProps.disabled ? null : onClick }), children));
};
export default BaseButton;
