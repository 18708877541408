import React from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
var TooltipContent = function () {
    var t = useTranslation('DocumentRecognition').t;
    var css = useTheme('DocRecognition').TooltipContent;
    return (React.createElement("div", { className: css.tooltip },
        React.createElement("div", { className: css.imageWrapper },
            React.createElement("div", { className: css.image })),
        React.createElement("div", { className: css.text }, t('Scan tooltip text'))));
};
export default TooltipContent;
