import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from '../../../../theme';
import MediaQuery from '../../../../MediaQuery/MediaQuery';
import { doneIcon } from '../../../Icons';
import { SlideBottom } from '../../../../index';
import { useState } from 'react';
import { useResendItineraryRecieptMutation } from '@websky/graphql';
import { useCallback } from 'react';
import { ResendTicketsStages } from '../../../../ResendTicketsForm/types';
import ResendTicketsForm from '../../../../ResendTicketsForm';
import { getOrder } from '../../../store/order/selectors';
var ThanksBanner = function (_a) {
    var _b;
    var orderId = _a.orderId;
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').ThanksBanner;
    var _c = __read(useState(false), 2), isOpenedResendModal = _c[0], setOpenedResendModal = _c[1];
    var _d = __read(useState(ResendTicketsStages.Default), 2), resendStage = _d[0], setResendStage = _d[1];
    var order = useSelector(getOrder);
    var _e = __read(useResendItineraryRecieptMutation(), 1), resendEmailQuery = _e[0];
    var toggleResendModal = function () {
        setOpenedResendModal(!isOpenedResendModal);
    };
    var resendEmail = useCallback(function (_a) {
        var email = _a.email;
        return __awaiter(void 0, void 0, void 0, function () {
            var resendedEmail, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setResendStage(ResendTicketsStages.Loading);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, resendEmailQuery({
                                variables: {
                                    params: {
                                        email: email,
                                        orderId: orderId
                                    }
                                }
                            })];
                    case 2:
                        resendedEmail = _b.sent();
                        if (resendedEmail.errors && resendedEmail.errors.length) {
                            setResendStage(ResendTicketsStages.Error);
                        }
                        else if (resendedEmail.data && resendedEmail.data.ResendItineraryReciept) {
                            setResendStage(ResendTicketsStages.Success);
                            setTimeout(function () {
                                setOpenedResendModal(false);
                            }, 1500);
                            setTimeout(function () {
                                setResendStage(ResendTicketsStages.Default);
                            }, 2000);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        setResendStage(ResendTicketsStages.Error);
                        console.error(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.wrapper },
            React.createElement("h1", { className: css.title }, t('Thank you for your booking!')),
            React.createElement(MediaQuery, { maxWidth: "mobile" },
                React.createElement("span", null,
                    t('Thank you for your choice. All need documents has been sent to your e-mail address.'),
                    ' ',
                    React.createElement("span", { className: css.resendButton, onClick: toggleResendModal }, t('Resend'))),
                React.createElement("div", { className: css.doneIcon }, doneIcon))),
        React.createElement(SlideBottom, { isOpen: isOpenedResendModal, onClose: toggleResendModal },
            React.createElement(ResendTicketsForm, { defaultEmail: (_b = order.customer.values.find(function (_a) {
                    var name = _a.name;
                    return name === 'email';
                })) === null || _b === void 0 ? void 0 : _b.value, status: resendStage, onSubmit: resendEmail, onCancel: toggleResendModal }))));
};
export default ThanksBanner;
