import * as React from 'react';
import { render } from 'react-dom';
import { enableSentry } from '@websky/core/src/utils';
import { withConfig } from '@websky/core/src/config-hoc';
import merge from 'lodash.merge';
enableSentry();
import Engine from './Engine';
import { companyInfo, defaultSteps, i18n } from '../../config';
import { Locale } from '@websky/core/src/types';
import { Currency, CurrencySymbols } from '@websky/core/src/enums';
export var init = function (mount, config) {
    var Component = withConfig(Engine, merge({}, {
        global: {
            companyInfo: companyInfo,
            maxBaggagePerPassenger: 4,
            maxMealPerPassenger: 10,
            i18n: i18n,
            locale: Locale.English
        },
        Engine: {
            defaultSteps: defaultSteps,
            searchFormURL: './',
            checkInURL: './online-check-in',
            accountURL: './account',
            showRefundRedirectButton: true
        },
        SearchForm: {
            selectableCurrencies: [
                {
                    code: Currency.EUR,
                    symbol: CurrencySymbols.EUR
                },
                {
                    code: Currency.USD,
                    symbol: CurrencySymbols.USD
                },
                {
                    code: Currency.RUB,
                    symbol: CurrencySymbols.RUB
                }
            ],
            proMode: true,
            showPriceGraph: false,
            enableComplexRoute: false,
            showPricesInDatepicker: false,
            payByMiles: false
        }
    }, config));
    render(React.createElement(Component, null), mount);
};
