import { __assign } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { Field } from 'react-final-form';
import InputNumber from '../../../InputNumber';
import Input from '../../../Input';
import { useTheme } from '../../../theme';
import { initI18n } from '../../../utils';
import { FieldType } from '../types';
import { DateMaskedInput } from '../../../DateMaskedInput/DateMaskedInput';
import Select from '../../Select/Select';
import { validate } from '../utils';
import { onChangeInputWithSelectionRange } from '../../Terminal/utils';
initI18n('TerminalForm');
var Row = function (props) {
    var theme = useTheme('TerminalForm').TerminalForm;
    var onChangeInput = function (field) { return function (e) {
        field.input.onChange(__assign(__assign({}, e), { target: __assign(__assign({}, e.target), { value: e.target.value.toUpperCase() }) }));
        field.input.onBlur();
    }; };
    var renderInput = function (input) {
        switch (input.type) {
            case FieldType.Text:
                return (React.createElement(Field, { name: input.id, validate: function (value) { return validate(value, input); } }, function (field) { return (React.createElement(Input, { inputClassName: theme.textInput__input, inputPropsClassName: theme.textInput__real, labelShrinkClassName: theme.textInput__shrink, className: theme.textInput, label: input.hint, absoluteHintPosition: true, TextFieldProps: __assign(__assign({}, field.input), { onChange: onChangeInputWithSelectionRange(onChangeInput(field)), error: field.meta.touched && field.meta.error, helperText: field.meta.touched && field.meta.error }) })); }));
            case FieldType.Date:
                return (React.createElement(Field, { name: input.id, validate: function (value) { return validate(value, input); } }, function (field) { return (React.createElement(Input, { inputClassName: theme.textInput__input, inputPropsClassName: theme.textInput__real, labelShrinkClassName: theme.textInput__shrink, className: theme.textInput, label: input.hint, absoluteHintPosition: true, TextFieldProps: __assign(__assign({}, field.input), { onChange: onChangeInputWithSelectionRange(onChangeInput(field)), error: field.meta.touched && field.meta.error, helperText: field.meta.touched && field.meta.error }), inputProps: {
                        mask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
                    }, inputComponent: DateMaskedInput })); }));
            case FieldType.Number:
                return (React.createElement(Field, { name: input.id, allowNull: true, validate: function (value) { return validate(value, input); } }, function (field) { return (React.createElement(InputNumber, { className: theme.numberInput, value: field.input.value, min: input.min !== null && input.min !== undefined ? Number(input.min) : undefined, max: input.max !== null && input.max !== undefined ? Number(input.max) : undefined, placeholder: input.hint, absoluteLabelPosition: true, inputNumberFocusedClassName: theme.numberInput_focused, inputNumberClassName: theme.numberInput__input, controlsClassName: theme.numberInput__controls, TextFieldProps: __assign(__assign({}, field.input), { onChange: onChangeInputWithSelectionRange(onChangeInput(field)), error: field.meta.touched && field.meta.error, helperText: field.meta.touched && field.meta.error }) })); }));
            case FieldType.List:
                var options_1 = useMemo(function () {
                    return props.listData
                        .find(function (list) { return list.id === input.id; })
                        .items.map(function (item) { return ({
                        key: item.value,
                        value: item.title || item.value
                    }); });
                }, [props.listData]);
                return (React.createElement(Field, { name: input.id, validate: function (value) { return validate(value, input); } }, function (field) { return (React.createElement(Select, { label: input.hint, name: input.id, options: options_1, onChange: function (e) {
                        field.input.onChange(e);
                        field.input.onBlur();
                    }, value: field.input.value, className: theme.selectInput, inputClassName: theme.selectInput__real, inputLabelClassName: theme.selectInput__label, inputLabelClassNameShrink: theme.selectInput__label_shrink, menuItemClasses: {
                        selected: theme.selectInput__option_selected
                    }, optionClassName: theme.selectInput__option, error: field.meta.touched && field.meta.error })); }));
            default:
                return null;
        }
    };
    return (React.createElement("div", { className: theme.row },
        props.row.title && React.createElement("div", { className: theme.row__title }, props.row.title),
        props.row.inputs && (React.createElement("div", { className: theme.row_inputs }, props.row.inputs.map(function (input) { return (React.createElement("div", { key: input.id },
            React.createElement("div", { className: theme.inputTitle }, input.title),
            renderInput(input))); })))));
};
export default Row;
