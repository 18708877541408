import Header from './components/Header.css';
import Menu from './components/Menu/Menu.css';
import Routes from './components/Routes/Routes.css';
import LocaleSelector from './components/LocaleSelector/CurrencySelector.css';
import Logo from './components/Logo/Logo.css';
import SearchData from './components/SearchData/SearchData.css';
export default {
    Header: Header,
    Menu: Menu,
    Routes: Routes,
    LocaleSelector: LocaleSelector,
    Logo: Logo,
    SearchData: SearchData
};
