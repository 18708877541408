import { __assign, __rest } from "tslib";
import React, { useContext } from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { usePassengers } from '../../hooks';
import { OrderCardContext } from '../OrderCard';
import { Passengers } from '../../../Icons';
import { useToggleable } from '../../../hooks';
import PassengersModal from './PassengersModal/PassengersModal';
import CommonItem from '../CommonItem/CommonItem';
import { OverrideComponent } from '../../../renderProps';
var OrderPassengers = function (_a) {
    var _b;
    var className = _a.className, buttons = _a.buttons, props = __rest(_a, ["className", "buttons"]);
    var css = useTheme('OrderCard').OrderPassengers;
    var t = useTranslation('OrderCard').t;
    var _c = useContext(OrderCardContext), order = _c.order, type = _c.type;
    var passengers = usePassengers(order);
    var _d = useToggleable(false), isOpen = _d.isOpen, open = _d.open, close = _d.close;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    return (React.createElement(React.Fragment, null,
        React.createElement(CommonItem, __assign({ icon: (type === 'review' || isMobile) && Passengers, type: type, text: React.createElement(React.Fragment, null,
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    t('Passengers'),
                    ": "),
                passengers), className: cn(css.root, className, (_b = {},
                _b[css.account] = type === 'account',
                _b)), buttons: React.createElement(React.Fragment, null,
                type === 'review' && (React.createElement("span", { className: css.control, onClick: open }, t('Details'))),
                buttons) }, props)),
        type === 'review' && (React.createElement(OverrideComponent, { componentProps: {
                order: order,
                open: isOpen,
                onClose: close
            }, component: {
                OrderCardPassengerModal: PassengersModal
            } }))));
};
export default OrderPassengers;
