import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { OverrideComponent } from '../../../../../../renderProps';
import SelectedFlights from '../SelectedFlights/SelectedFlights';
import Notice from '../../Notice/Notice';
import CreateRequestWrapper from '../../CreateRequest/CreateRequestWrapper';
import SelectedServices from '../../SelectedServices/SelectedServices';
import { useCalculateGdsServiceRefund } from '../../hooks';
var Inner = function (props) {
    var t = useTranslation('Exchange').t;
    var _a = useCalculateGdsServiceRefund(props.selectedPassengers), data = _a.data, loading = _a.loading;
    return (React.createElement("div", null,
        React.createElement(OverrideComponent, { componentProps: {
                selectedFlights: props.selectedFlights.map(function (flight) { return flight.fare.flightInfo; }),
                prices: props.prices,
                hideHeader: true,
                type: 'flat'
            }, component: {
                ExchangeSelectedFlights: SelectedFlights
            } }),
        React.createElement(SelectedServices, { order: props.order, gdsServicesRefund: data, isLoading: loading }),
        React.createElement(Notice, { type: "notice", header: t('Note'), text: t('The possibility and cost of changes will be calculated by the operator after sending the application. The answer will be sent to your E-mail and is also available on the order page.') }),
        React.createElement(Notice, { type: "reject", header: t('Exchange restrictions'), text: t('After submitting the request, the seats you have selected will be canceled.') }),
        React.createElement(CreateRequestWrapper, { onOrderCreated: props.setExchange, isLoading: loading })));
};
export default Inner;
