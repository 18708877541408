import { isAfter } from 'date-fns';
export var isSelectedOnlyReturnDate = function (selectedDates) { return !selectedDates[0] && Boolean(selectedDates[1]); };
export var isSelectedOnlyDepartureDate = function (selectedDates) { return selectedDates[0] && !selectedDates[1]; };
export var isDatesInReversedOrder = function (selectedDates) {
    return selectedDates[0] && selectedDates[1] && isAfter(selectedDates[0], selectedDates[1]);
};
export var swapConfigDates = function (config) {
    return {
        departure: config.return,
        return: config.departure
    };
};
