import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import { useMemo } from 'react';
import { useState, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
var SaveOrderServices = require('../../../__queries__/query.graphql').SaveOrderServices;
import { fillOrder } from '../../../Checkout/store/order/actions';
import { convertSelectedServicesStateToTravellerSelectedServices, convertTravellerSelectedServicesToSelectedServicesState } from '../../../ExtraServices/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getMiscServices, getOrder, getSegments, getSelectedMiscServices } from '../../../Checkout/store/order/selectors';
export var useExtras = function (selectedMisc) {
    var dispatch = useDispatch();
    var order = useSelector(getOrder);
    var selectedMiscServices = selectedMisc !== null && selectedMisc !== void 0 ? selectedMisc : useSelector(getSelectedMiscServices);
    var services = useSelector(getMiscServices);
    var segments = useSelector(getSegments);
    var _a = __read(useState(false), 2), isExtrasModalOpen = _a[0], setIsExtrasModalOpen = _a[1];
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read(useState(null), 2), saveOrderServicesError = _c[0], setSaveOrderServicesError = _c[1];
    var _d = __read(useMutation(SaveOrderServices), 1), saveOrderServicesMethod = _d[0];
    var addNotMiscServices = function (services, order) {
        var withPaidServices = __spreadArray([], __read(services), false);
        var servicesById = new Map();
        services.forEach(function (service) {
            servicesById.set(service.serviceId, service);
        });
        order.travellers.forEach(function (traveller) {
            var _a, _b;
            (_b = (_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.forEach(function (service) {
                if (!servicesById.has(service.serviceId)) {
                    withPaidServices.push({
                        passengerId: traveller.id,
                        serviceId: service.serviceId,
                        segmentIds: service.segmentIds,
                        count: service.count
                    });
                }
            });
        });
        return withPaidServices;
    };
    var saveOrderServices = function (services, order) { return __awaiter(void 0, void 0, void 0, function () {
        var convertedServices, _a, data, errors, error_1;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    convertedServices = convertSelectedServicesStateToTravellerSelectedServices(addNotMiscServices(services, order), order);
                    setIsLoading(true);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, saveOrderServicesMethod({
                            variables: {
                                params: {
                                    id: order.id,
                                    services: convertedServices
                                }
                            }
                        })];
                case 2:
                    _a = _c.sent(), data = _a.data, errors = _a.errors;
                    if (errors === null || errors === void 0 ? void 0 : errors.length) {
                        setSaveOrderServicesError((_b = errors[0]) === null || _b === void 0 ? void 0 : _b.message);
                    }
                    else {
                        dispatch(fillOrder(data.SaveOrderServices));
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _c.sent();
                    setSaveOrderServicesError('');
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onExtrasConfirm = useCallback(function (services) {
        saveOrderServices(services, order);
        setIsExtrasModalOpen(false);
    }, [order]);
    var convertedSelectedServices = useMemo(function () { return convertTravellerSelectedServicesToSelectedServicesState(selectedMiscServices, order); }, [selectedMiscServices, order]);
    var clearError = function () {
        setSaveOrderServicesError(null);
    };
    return {
        isExtrasModalOpen: isExtrasModalOpen,
        onClose: function () {
            setIsExtrasModalOpen(false);
        },
        onOpen: function () {
            setIsExtrasModalOpen(true);
        },
        selectedServices: convertedSelectedServices,
        services: services,
        segments: segments,
        onExtrasConfirm: onExtrasConfirm,
        isLoading: isLoading,
        saveOrderServicesError: saveOrderServicesError,
        clearError: clearError
    };
};
