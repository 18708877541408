import * as React from 'react';
import { useSelector } from 'react-redux';
import { getTravellers } from '../../../Checkout/store/order/selectors';
import { TravellerFieldEnum } from '../../../__generated__/globalTypes';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import { getPassengerName as getPassengerNameUtil } from '../../../utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
var Passenger = function (_a) {
    var _b = _a.passengerWithChild, passenger = _b.passenger, childs = _b.childs, onClick = _a.onClick, isSelected = _a.isSelected, isDisabled = _a.isDisabled;
    var getPassengerName = React.useCallback(function (passenger) {
        var firstName = passenger.values.find(function (value) { return value.type === TravellerFieldEnum.FirstName; }).value, lastName = passenger.values.find(function (value) { return value.type === TravellerFieldEnum.LastName; }).value;
        return getPassengerNameUtil(firstName, lastName);
    }, [passenger, childs]);
    var travellers = useSelector(getTravellers);
    var theme = useTheme('SplitPassengers').SplitPassengers;
    var onClickHandler = function () {
        onClick(passenger.id);
    };
    var passengerData = (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.passenger },
            React.createElement("span", { className: theme.passengerName }, getPassengerName(passenger)),
            React.createElement("span", { className: theme.passengerType }, travellers.find(function (traveller) { return traveller.id === passenger.id; }).supplierTravellerType),
            React.createElement("span", null, passenger.values.find(function (value) { return value.type === TravellerFieldEnum.BirthDate; }).value)),
        childs.map(function (child) { return (React.createElement("div", { key: child.id, className: cn(theme.passenger, theme.child) },
            React.createElement("span", { className: theme.passengerName }, getPassengerName(child)),
            React.createElement("span", { className: theme.passengerType }, travellers.find(function (traveller) { return traveller.id === child.id; }).supplierTravellerType),
            React.createElement("span", null, child.values.find(function (value) { return value.type === TravellerFieldEnum.BirthDate; }).value))); })));
    return (React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { classes: {
                root: theme.checkbox,
                checked: theme.checkbox_checked,
                colorSecondary: theme.checkbox_color
            }, onClick: onClickHandler, disabled: isDisabled }), classes: { root: theme.item, label: theme.label, disabled: theme.label_disabled }, label: passengerData, checked: isSelected }));
};
export default Passenger;
