import { __assign } from "tslib";
import { currentCurrency, setToMap } from '../../../utils';
import { isPassengerFareFragment } from './typeguards';
var sumPrices = function (oldPrice, currentPrice) {
    var newPrice = __assign({}, oldPrice);
    newPrice.amount += currentPrice.amount;
    return newPrice;
};
export var calculateTotalTaxes = function (passengerFares) {
    var taxesMap = new Map();
    var totalPrice = { amount: 0, currency: currentCurrency };
    var tariffPrice = { amount: 0, currency: currentCurrency };
    if (isPassengerFareFragment(passengerFares)) {
        passengerFares.forEach(function (passengerFare) {
            passengerFare.taxes.forEach(function (tax) {
                setToMap(taxesMap, sumPrices, tax.code, __assign(__assign({}, tax.price), { amount: tax.price.amount * passengerFare.count }));
            });
            totalPrice.amount += passengerFare.baseFare.amount * passengerFare.count;
            tariffPrice.amount += passengerFare.totalFare.amount;
        });
    }
    else {
        // exchange fares
        passengerFares.forEach(function (passengerFare) {
            passengerFare.taxes.forEach(function (tax) {
                setToMap(taxesMap, sumPrices, tax.code, __assign(__assign({}, tax.price), { amount: tax.price.amount * passengerFare.count }));
            });
            totalPrice.amount += passengerFare.baseFare.amount * passengerFare.count;
            tariffPrice.amount += passengerFare.totalFare.amount;
        });
    }
    setToMap(taxesMap, null, 'Tariff', totalPrice);
    taxesMap.forEach(function (price, key) {
        if (key === 'Total') {
            return;
        }
        setToMap(taxesMap, sumPrices, 'Total', price);
    });
    return taxesMap;
};
