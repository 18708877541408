import * as React from 'react';
import { useCallback } from 'react';
import ScheduleTypeSelector from './ScheduleTypeSelector';
import { Exchangeable, List } from '../../../../Icons';
export var useScheduleTypeSelector = function (type, setType) {
    var buttons = [
        {
            icon: List,
            type: 'list'
        },
        {
            icon: Exchangeable,
            type: 'calendar'
        }
    ];
    var renderScheduleTypeSelector = useCallback(function () {
        return React.createElement(ScheduleTypeSelector, { type: type, buttons: buttons, onScheduleTypeChange: setType });
    }, [buttons, type]);
    return {
        type: type,
        setType: setType,
        buttons: buttons,
        renderScheduleTypeSelector: renderScheduleTypeSelector
    };
};
