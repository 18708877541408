import { __read, __spreadArray } from "tslib";
import React, { useMemo } from 'react';
import { createStore } from '../store';
import { Provider } from 'react-redux';
import BaggageDialogHeader from './BaggageDialogHeader/BaggageDialogHeader';
import BaggageBody from './BaggageBody/BaggageBody';
import Modal from '../../../Modal';
import { ThemeProvider, useTheme } from '../../../theme';
import BaggageFooter from './BaggageFooter/BaggageFooter';
import { useCallback } from 'react';
import { joinServicesByRfics, sortBySelectedByPrice } from '../utils';
import { BaggageType } from '../../../__generated__/globalTypes';
import { setAllServices } from '../store/actions';
import { useConfig } from '../../../context';
var closeIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { y: "0.000488281", width: "32", height: "32", rx: "16", fill: "#F6F6F6" }),
    React.createElement("path", { d: "M21.8337 11.3422L20.6587 10.1672L16.0003 14.8255L11.342 10.1672L10.167 11.3422L14.8253 16.0005L10.167 20.6588L11.342 21.8338L16.0003 17.1755L20.6587 21.8338L21.8337 20.6588L17.1753 16.0005L21.8337 11.3422Z", fill: "#808080" })));
var UpgradeBaggageDialog = function (props) {
    var _a;
    var _b = __read(React.useState(props.upgradeBaggage[0].rfics), 2), mobileServiceRfics = _b[0], setMobileServiceRfics = _b[1];
    var baggagesByRfics = React.useMemo(function () {
        var items = joinServicesByRfics(props.upgradeBaggage);
        if (props.itemsToView) {
            var sortedItems = __spreadArray([], __read(items), false).sort(function (firstItem, secondItem) {
                return sortBySelectedByPrice(firstItem, secondItem, props.selectedServices);
            });
            return sortedItems;
        }
        return items;
    }, [props.upgradeBaggage, props.selectedServices]);
    var _c = __read(React.useState(baggagesByRfics), 2), chunkedBaggageItems = _c[0], setChunkedBaggageItems = _c[1];
    var _d = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.global, maxBaggage = _d.maxBaggage, maxBaggagePerPassenger = _d.maxBaggagePerPassenger;
    var store = React.useMemo(function () {
        var _a, _b, _c;
        return createStore(props.upgradeBaggage, props.segments, props.passengers, props.selectedServices, (_a = props.maxBaggageCount) !== null && _a !== void 0 ? _a : (maxBaggage || 40), (_b = props.maxBaggagePerPassengerCount) !== null && _b !== void 0 ? _b : (maxBaggagePerPassenger || 3), props.baggageType === BaggageType.SportingEquipment ? (_c = __spreadArray([], __read(baggagesByRfics.values()), false)) === null || _c === void 0 ? void 0 : _c[0][0].rfics : null, props.disabled);
    }, [
        props.upgradeBaggage,
        props.segments,
        props.passengers,
        props.selectedServices,
        props.maxBaggageCount,
        props.maxBaggagePerPassengerCount,
        props.open,
        props.disabled
    ]);
    React.useEffect(function () {
        if (props.open) {
            store.dispatch(setAllServices(props.selectedServices));
        }
    }, [props.open, props.selectedServices, store]);
    var theme = useTheme('UpgradeBaggageDialog').UpgradeBaggageDialog;
    var handlerConfirm = useCallback(function () {
        props.onConfirm(store.getState().selectedServices);
        props.onClose();
    }, [props.onConfirm, store.getState()]);
    var moneyStyles = useMemo(function () { return ({ Money: { Money: { money: theme.money } } }); }, []);
    return (React.createElement(Provider, { store: store },
        React.createElement(ThemeProvider, { value: moneyStyles },
            React.createElement(Modal, { classes: { root: theme.modal__root }, open: props.open, onClose: props.onClose, isRounded: true, PaperProps: { className: theme.modal__paper }, closeButtonRenderer: function (onClick) {
                    return (React.createElement("div", { className: theme.modalHeader },
                        props.headerProps.header,
                        React.createElement("div", { className: theme.close, onClick: onClick }, closeIcon)));
                } },
                React.createElement("div", { className: theme.root },
                    React.createElement(BaggageDialogHeader, { services: baggagesByRfics, headerProps: props.headerProps, segments: props.segments, setMobileServiceId: setMobileServiceRfics, mobileSelectedServiceId: mobileServiceRfics, setChunkedBaggageItems: setChunkedBaggageItems, itemsToView: props.itemsToView }),
                    React.createElement(BaggageBody, { upgradeBaggages: props.itemsToView ? chunkedBaggageItems : baggagesByRfics, passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, mobileSelectedServiceId: mobileServiceRfics }),
                    React.createElement(BaggageFooter, { onCancel: props.onClose, onConfirm: handlerConfirm }))))));
};
export default UpgradeBaggageDialog;
