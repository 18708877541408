import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Link from '../../../Link';
import { useLeoNavigation } from '../../../LEO/Account/hooks';
import { useConfig } from '../../../context';
var Routes = function () {
    var _a, _b;
    var css = useTheme('Header').Routes;
    var history = useHistory();
    var location = useLocation();
    var redirectToEngine = useLeoNavigation().redirectToEngine;
    var engineURL = useConfig().Account.engineURL;
    var handleGoToQueues = function () { return history.push('/queues'); };
    var handleGoToStart = redirectToEngine;
    var isQueuesPage = location.pathname.includes('queues');
    var isAccountPage = location.pathname.includes('account');
    var handleGoToQueuesFromAccount = function () {
        document.location.href = "".concat(engineURL, "/#/queues");
    };
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: cn(css.item, (_a = {}, _a[css.item__active] = !isQueuesPage && !isAccountPage, _a)) },
            React.createElement(Link, { action: handleGoToStart, className: css.link }, "Booking")),
        React.createElement("div", { className: cn(css.item, (_b = {}, _b[css.item__active] = isQueuesPage, _b)) },
            React.createElement(Link, { action: isAccountPage ? handleGoToQueuesFromAccount : handleGoToQueues, className: css.link }, "Queues"))));
};
export default Routes;
