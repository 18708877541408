import { __awaiter, __generator } from "tslib";
var IndexedDBWrapper = /** @class */ (function () {
    function IndexedDBWrapper() {
        this._store = null;
        this._db = null;
        // instance config
        this.storeName = null;
        this.storeParams = null;
    }
    // utils/helpers
    IndexedDBWrapper._isIDBRequestGuard = function (request) {
        // only IBDTransaction has this property
        return request instanceof IDBRequest && !(request instanceof IDBOpenDBRequest);
    };
    IndexedDBWrapper._isIDBOpenDBRequest = function (request) {
        return request instanceof IDBOpenDBRequest;
    };
    IndexedDBWrapper._promisifyRequest = function (request) {
        return new Promise(function (resolve, reject) {
            if (IndexedDBWrapper._isIDBRequestGuard(request)) {
                request.onsuccess = function () { return resolve(request.result); };
                request.onerror = function () { return reject(request.error); };
            }
            else if (IndexedDBWrapper._isIDBOpenDBRequest(request)) {
                request.onsuccess = function () { return resolve(request.result); };
                request.onerror = function () { return reject(request.error); };
            }
            else {
                var transaction_1 = request;
                transaction_1.oncomplete = function () { return resolve(transaction_1); };
                transaction_1.onabort = function () { return reject(transaction_1.error); };
            }
        });
    };
    IndexedDBWrapper.prototype._getCursor = function (callback) {
        var cursor = this._store.openCursor();
        cursor.onsuccess = function () {
            if (!cursor.result) {
                return;
            }
            callback(cursor.result);
            cursor.result.continue();
        };
        return IndexedDBWrapper._promisifyRequest(this._store.transaction);
    };
    // end helpers
    IndexedDBWrapper.prototype._prepareStoreAndCreateTransaction = function () {
        return __awaiter(this, void 0, Promise, function () {
            var dbFactory, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        dbFactory = function () { return __awaiter(_this, void 0, void 0, function () {
                            var request;
                            var _this = this;
                            return __generator(this, function (_a) {
                                request = indexedDB.open(IndexedDBWrapper.DB_NAME);
                                request.onupgradeneeded = function () { return request.result.createObjectStore(_this.storeName, _this.storeParams); };
                                return [2 /*return*/, IndexedDBWrapper._promisifyRequest(request)];
                            });
                        }); };
                        if (!!this._db) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, dbFactory()];
                    case 1:
                        _a._db = _b.sent();
                        _b.label = 2;
                    case 2:
                        this._store = this._db.transaction(this.storeName, 'readwrite').objectStore(this.storeName);
                        return [2 /*return*/];
                }
            });
        });
    };
    IndexedDBWrapper.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._prepareStoreAndCreateTransaction()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    IndexedDBWrapper.prototype.get = function (key) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._prepareStoreAndCreateTransaction()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, IndexedDBWrapper._promisifyRequest(this._store.get(key))];
                }
            });
        });
    };
    IndexedDBWrapper.prototype.set = function (value, key) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._prepareStoreAndCreateTransaction()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, IndexedDBWrapper._promisifyRequest(this._store.put(value, key))];
                }
            });
        });
    };
    IndexedDBWrapper.prototype.clear = function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._prepareStoreAndCreateTransaction()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, IndexedDBWrapper._promisifyRequest(this._store.clear())];
                }
            });
        });
    };
    IndexedDBWrapper.prototype.delete = function (key) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._prepareStoreAndCreateTransaction()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, IndexedDBWrapper._promisifyRequest(this._store.delete(key))];
                }
            });
        });
    };
    IndexedDBWrapper.prototype.entries = function () {
        return __awaiter(this, void 0, Promise, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._prepareStoreAndCreateTransaction()];
                    case 1:
                        _a.sent();
                        items = [];
                        return [2 /*return*/, this._getCursor(function (cursor) { return items.push([cursor.key, cursor.value]); }).then(function () { return items; })];
                }
            });
        });
    };
    // global config
    IndexedDBWrapper.DB_NAME = 'Websky';
    return IndexedDBWrapper;
}());
export { IndexedDBWrapper };
