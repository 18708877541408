import { __assign } from "tslib";
import * as React from 'react';
import { default as BaseRequestForm } from '../../../Modules/Exchange/components/Exchange/RequestForm/RequestForm';
import SelectedFlights from '../SelectedFlights/SelectedFlights';
import { getNewExchangeFlightPrice } from '../../../Modules/Exchange/components/Exchange/RequestForm/utils';
import Notice from '../../../Modules/Exchange/components/Exchange/Notice/Notice';
import RequestControls from '../../../Modules/Exchange/components/Exchange/RequestForm/RequestControls/RequestControls';
import PricesDetails from '../../PricesDetails/PricesDetails';
import * as priceDetailsTheme from '../../PricesDetails/PriceDetails.css';
import { useTranslation } from 'react-i18next';
import { ExareStatus } from '../../../__generated__/graphql';
import { format, initI18n } from '../../../utils';
import { useCallback, useMemo } from 'react';
import { ThemeProvider } from '../../../theme';
import * as css from './RequestForm.css';
import { RenderersProvider } from '../../../renderProps';
import { useSelector } from 'react-redux';
import { getLastExchange, getOrder } from '../../../Modules/Exchange/store/order/selectors';
import DetalizationLine from '../../PricesDetails/DetalizationLine/DetalizationLine';
import { useCalculateGdsServiceRefund } from '../../../Modules/Exchange/components/Exchange/hooks';
export var FINAL_REQUEST_STATUSES = [
    ExareStatus.Finished,
    ExareStatus.Rejected,
    ExareStatus.Refused,
    ExareStatus.TimelimitExpired
];
initI18n('Exchange');
var RequestFrom = function (props) {
    var deleteRequest = props.deleteRequest, goToNewExchange = props.goToNewExchange, goToPayment = props.goToPayment;
    var order = useSelector(getLastExchange);
    var prices = React.useMemo(function () {
        return getNewExchangeFlightPrice(order);
    }, [order.prices]);
    var deleteRequestHandler = function () {
        return deleteRequest(order);
    };
    var rootOrder = useSelector(getOrder);
    var selectedPassengers = order.travellers.map(function (traveller) { return traveller.id; });
    var _a = useCalculateGdsServiceRefund(selectedPassengers), data = _a.data, loading = _a.loading;
    var t = useTranslation('Exchange').t;
    var baseRequestFormTheme = useMemo(function () { return ({
        Exchange: {
            SelectedFlights: {
                charge: css.charge
            },
            RequestControls: {
                container: css.buttons__container
            }
        }
    }); }, []);
    var renderSelectedFlights = useCallback(function () {
        if (FINAL_REQUEST_STATUSES.includes(order.status)) {
            return (React.createElement(ThemeProvider, { value: baseRequestFormTheme },
                React.createElement(RenderersProvider, { value: {
                        ExchangeSelectedFlights: function (props) { return React.createElement(SelectedFlights, __assign({}, props, { type: 'reviewTicket' })); }
                    } },
                    React.createElement(BaseRequestForm, __assign({}, props)))));
        }
        else {
            return (React.createElement(SelectedFlights, { variant: 'newTicket', selectedFlights: [order.flight], prices: prices, hideHeader: true, type: 'flat', goToFlights: deleteRequestHandler, showRefundServicesInfo: true, selectedPassengers: selectedPassengers, gdsServicesRefund: data, isLoading: loading }));
        }
    }, [order.status, data, loading]);
    var actualPrices = __assign(__assign({}, order.prices), { priceDetails: __assign(__assign({}, order.prices.priceDetails), { paid: __assign(__assign({}, order.prices.priceDetails.paid), { sum: __assign(__assign({}, order.prices.priceDetails.paid.sum), { amount: rootOrder.price.airticket.amount }) }) }) });
    return (React.createElement("div", null,
        renderSelectedFlights(),
        React.createElement(PricesDetails, { className: priceDetailsTheme.whiteBg, prices: actualPrices, renderAdditionalFields: function () { return (React.createElement(DetalizationLine, { total: true, className: priceDetailsTheme.item_first, price: order.prices.priceToPay, title: t('Residual amount') })); } }),
        (order.status === ExareStatus.AwaitingPayment || order.status === ExareStatus.TimelimitExpired) && (React.createElement(Notice, { type: "time", header: t('Limited exchange time'), text: t('Offer valid until {{date}}', { date: format(order.timelimit, 'dd MMMM yyyy, HH:mm') }) })),
        React.createElement(RequestControls, { order: order, deleteRequest: deleteRequest, goToNewExchange: goToNewExchange, goToPayment: goToPayment, isLoading: loading })));
};
export default RequestFrom;
