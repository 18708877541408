import { __read } from "tslib";
import { useEffect, useState, useCallback, useMemo } from 'react';
import { getMessagePagination } from './utils';
export var usePaginationMessages = function (message, isLastMessage, hasMessageValues) {
    var _a = __read(useState(window.innerHeight), 2), height = _a[0], setHeight = _a[1];
    var _b = __read(useState(0), 2), currentPage = _b[0], setCurrentPage = _b[1];
    var _c = useMemo(function () { return getMessagePagination(message, hasMessageValues); }, [height, message, hasMessageValues]), messageByLines = _c.messageByLines, linesPerPage = _c.linesPerPage, totalPage = _c.totalPage, hasPagination = _c.hasPagination;
    var offsetStart = currentPage * linesPerPage;
    var offsetEnd = offsetStart + linesPerPage;
    var isFirstPage = offsetEnd === linesPerPage;
    var isLastPage = Math.ceil(offsetEnd / linesPerPage) >= totalPage;
    var onResize = useCallback(function () {
        setHeight(window.innerHeight);
    }, [setHeight]);
    useEffect(function () {
        if (isLastMessage) {
            window.addEventListener('resize', onResize);
        }
        return function () {
            window.removeEventListener('resize', onResize);
        };
    }, [isLastMessage]);
    var nextPage = useCallback(function () {
        if (!isLastPage) {
            setCurrentPage(function (state) { return state + 1; });
        }
    }, [isLastPage]);
    var prevPage = useCallback(function () {
        if (!isFirstPage) {
            setCurrentPage(function (state) { return state - 1; });
        }
    }, [isLastPage]);
    var closePagination = useCallback(function () {
        setCurrentPage(totalPage);
    }, [totalPage]);
    return {
        messagesByPages: messageByLines.slice(0, offsetEnd).join('\n'),
        offsetStart: offsetStart,
        offsetEnd: offsetEnd,
        isFirstPage: isFirstPage,
        isLastPage: isLastPage,
        nextPage: nextPage,
        prevPage: prevPage,
        closePagination: closePagination,
        hasPagination: hasPagination
    };
};
