import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import css from './ManualLink.css';
import Link from '../../Link';
var ManualLink = function (_a) {
    var type = _a.type;
    var match = useRouteMatch(), baseUrl = match.url;
    var t = useTranslation(type === 'exchange' ? 'Exchange' : 'Refund').t;
    var isManualPage = location.href.includes('manual');
    var history = useHistory();
    var handleClick = function () {
        if (isManualPage) {
            history.goBack();
        }
        else {
            history.push("".concat(baseUrl, "/manual"));
        }
    };
    return (React.createElement(Link, { className: css.link, action: handleClick }, isManualPage ? t('go to automate') : t('go to manual')));
};
export default ManualLink;
