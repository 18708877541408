import * as React from 'react';
import cn from 'classnames';
import Card from '@material-ui/core/Card';
import SwipeableViews from 'react-swipeable-views';
import * as icons from './icons';
import { useTheme } from '../../../theme';
import { useMobileSegmentFlatInfo } from './hooks';
import Pagination from '../../../Pagination';
import Modal from '../../../Modal';
import AircraftPopupContent from '../../../AircraftPopup/components/Component';
import { differenceInDays, parseISO } from 'date-fns';
import MobileSegmentFlatTime from './MobileSegmentFlatTime/MobileSegmentFlatTime';
export var MobileSegmentType;
(function (MobileSegmentType) {
    MobileSegmentType[MobileSegmentType["Recommended"] = 0] = "Recommended";
    MobileSegmentType[MobileSegmentType["Selected"] = 1] = "Selected";
})(MobileSegmentType || (MobileSegmentType = {}));
var MobileSegmentFlat = function (props) {
    var _a;
    var type = props.type;
    var theme = useTheme('MobileSegmentInfo').MobileSegmentFlat;
    var _b = useMobileSegmentFlatInfo(props), departureDate = _b.departureDate, departureTime = _b.departureTime, departureCity = _b.firstSegmentCity, durationTime = _b.durationTime, transferString = _b.transferString, arrivalTime = _b.arrivalTime, isNextDayArrival = _b.isNextDayArrival, lastSegment = _b.lastSegment, arrivalCity = _b.lastSegmentCity, firstSegment = _b.firstSegment, aircrafts = _b.aircrafts, isAircraftPopupOpen = _b.isAircraftPopupOpen, openAircraftPopup = _b.openAircraftPopup, paginationIndex = _b.currentIndex, handleIndexChange = _b.handleChange, openAircraftModal = _b.openAircraftModal, transferInfo = _b.transferInfo;
    var companyName = (_a = firstSegment === null || firstSegment === void 0 ? void 0 : firstSegment.operatingAirline) === null || _a === void 0 ? void 0 : _a.name;
    return (React.createElement(Card, { elevation: 0, className: theme.card },
        React.createElement("div", { className: cn(theme.row, theme.row_title) },
            React.createElement("span", { className: cn(theme.title, theme.title_date) }, departureDate),
            React.createElement("span", { className: cn(theme.title, theme.title_company) }, companyName)),
        React.createElement(MobileSegmentFlatTime, { departureTime: departureTime, departureCity: departureCity, durationTime: durationTime, arrivalTime: arrivalTime, arrivalCity: arrivalCity, nextDay: isNextDayArrival &&
                differenceInDays(parseISO(lastSegment.arrival.date), parseISO(firstSegment.departure.date)), transfer: {
                count: transferInfo.transfersCount,
                label: transferString
            } }),
        React.createElement("div", { className: theme.icons },
            type === MobileSegmentType.Recommended && icons.recommendedIcon,
            type === MobileSegmentType.Selected && icons.selectedIcon),
        props.showAirplaneInfo && aircrafts.length && !type && (React.createElement("div", { className: theme.aircraft },
            React.createElement(SwipeableViews, { slideClassName: theme.slide, index: paginationIndex, onChangeIndex: handleIndexChange }, aircrafts.map(function (aircraft) { return (React.createElement(React.Fragment, null,
                React.createElement("div", { key: aircraft.imageUrl },
                    React.createElement("img", { className: theme.image, alt: aircraft.aircraftName, src: aircraft.imageUrl }),
                    React.createElement("div", { className: theme.flightNumber }, aircraft.flightNumber),
                    React.createElement("a", { className: theme.aircraftName, onClick: openAircraftModal }, aircraft.aircraftName)),
                (aircraft === null || aircraft === void 0 ? void 0 : aircraft.id) && (React.createElement(Modal, { classes: { root: theme.modal }, open: isAircraftPopupOpen, onClose: function () { return openAircraftPopup(false); } },
                    React.createElement(AircraftPopupContent, { id: firstSegment.aircraft.id }))))); })),
            aircrafts.length > 1 && (React.createElement(Pagination, { onChange: handleIndexChange, className: theme.pagination, itemsCount: aircrafts.length, currentIndex: paginationIndex }))))));
};
export default MobileSegmentFlat;
