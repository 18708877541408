import { __assign } from "tslib";
import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import { currency } from '../../../__fakeData__';
import { CheckboxField, NumberField, StringField, AnySymbolField } from '../../Fields/Fields';
var ExchangeNewFareCalculation = function () {
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("label", { className: theme.formControl },
                React.createElement("input", { type: "text", className: cn(theme.formControl__control, theme.formControl__control_light), value: "BQS N4 MOW 100  N4 SVX ZZ SIP 200 NUC 300.00 END IROE 0.90", readOnly: true }),
                React.createElement("div", { className: theme.formControl__label }, t('Fare calculation')))),
        React.createElement("div", { className: theme.exchangeNewFareCalculation },
            React.createElement("div", { className: cn(theme.formControl__flexLine, theme.flexFullWidth) },
                React.createElement("div", null,
                    React.createElement("label", { className: theme.formControl },
                        React.createElement(NumberField, { name: 'fare' }),
                        React.createElement("div", { className: theme.formControl__label }, t('Fare')))),
                React.createElement("div", null,
                    React.createElement("label", { className: theme.formControl },
                        React.createElement(StringField, { name: 'currency' }),
                        React.createElement("div", { className: theme.formControl__label }, t('Currency'))))),
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement(NumberField, { name: 'equivFare' }),
                    React.createElement("div", { className: theme.formControl__label },
                        t('Equiv. Fare'),
                        " (",
                        currency,
                        ")"))),
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement(AnySymbolField, { name: 'equivFareCodes' }),
                    React.createElement("div", { className: theme.formControl__label }, t('Equiv. Fare Codes')))),
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement(NumberField, { name: 'ticketingFee' }),
                    React.createElement("div", { className: theme.formControl__label },
                        t('Ticketing fee'),
                        " (",
                        currency,
                        ")"))),
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement(NumberField, { name: 'specialAirlinesADC' }),
                    React.createElement("div", { className: theme.formControl__label },
                        t('Special airlines ADC'),
                        " (",
                        currency,
                        ")"))),
            React.createElement("div", { className: cn(theme.formControl__flexLine, theme.flexFullWidth) },
                React.createElement("div", null,
                    React.createElement("label", { className: theme.formControl },
                        React.createElement(NumberField, { name: 'agencyCommission.value' }),
                        React.createElement("div", { className: theme.formControl__label },
                            t('Agency commission'),
                            " (",
                            currency,
                            ")"))),
                React.createElement("div", null,
                    React.createElement("label", { className: theme.formControl },
                        React.createElement(Field, { name: 'agencyCommission.type', render: function (_a) {
                                var input = _a.input;
                                return (React.createElement(FormControl, { className: theme.formSelect_line, classes: {} },
                                    React.createElement(Select, __assign({}, input, { disableUnderline: true, classes: {
                                            root: theme.formSelect
                                        } }),
                                        React.createElement(MenuItem, { key: '%', value: '%' }, "%"),
                                        React.createElement(MenuItem, { key: 'value', value: 'value' }, t('Value')))));
                            } })))),
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement(NumberField, { name: 'exchangePenalty' }),
                    React.createElement("div", { className: theme.formControl__label },
                        t('Exchange penalty'),
                        " (",
                        currency,
                        ")"))),
            React.createElement("div", { className: theme.formControl__flexLine },
                React.createElement("div", { className: theme.formControl },
                    React.createElement("label", { className: theme.formCheckbox },
                        React.createElement(CheckboxField, { optional: true, name: 'collectPenalty', label: t("Collect penalty on ticket (if you don't check this option it will be collected on EMD)") })),
                    React.createElement("div", { className: theme.formControl__label }, "\u00A0"))),
            React.createElement("div", null,
                React.createElement("label", { className: theme.formControl },
                    React.createElement(NumberField, { name: 'tourCode' }),
                    React.createElement("div", { className: theme.formControl__label }, t('Tour Code')))))));
};
export default ExchangeNewFareCalculation;
