import { __assign } from "tslib";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { Field, Form } from 'react-final-form';
import Button from '../../../../Button/Button';
import Modal from '../../../../Modal';
import { usePointOfSales } from '../../../leoCheckIsAuthenticated/pointOfSalesContext';
import Input from '../../../Input/Input';
var QueuesOwnerModal = function (props) {
    var theme = useTheme('EventsQueues').QueuesOwnerModal;
    var t = useTranslation('EventsQueues').t;
    var posCredentials = usePointOfSales();
    if (!(posCredentials === null || posCredentials === void 0 ? void 0 : posCredentials.agency)) {
        return null;
    }
    var onSubmit = function (values) {
        var _a;
        props.onSubmit(values.agency.trim(), (_a = values.pos) === null || _a === void 0 ? void 0 : _a.trim());
    };
    var initValues = {
        agency: posCredentials.agency,
        pos: posCredentials.id
    };
    return (React.createElement(Modal, { keepMounted: true, scroll: "paper", open: props.open, isRounded: true, classes: { paper: theme.modal__paper }, onClose: props.isLoading ? null : props.onClose },
        React.createElement("div", { className: theme.header }, t('Select queues owner')),
        React.createElement(Form, { onSubmit: onSubmit, initialValues: initValues }, function (_a) {
            var handleSubmit = _a.handleSubmit;
            return (React.createElement("form", { onSubmit: handleSubmit, onChange: props.onFormChange, className: theme.form },
                React.createElement(Field, { name: "agency", validate: function (value) { return (!(value === null || value === void 0 ? void 0 : value.trim()) ? t('Required') : null); } }, function (field) { return (React.createElement(Input, { label: t('IATA ID Group / Agency'), className: theme.field, variant: 'outlined', disabled: posCredentials.isAgencySupervisor, TextFieldProps: __assign(__assign({}, field.input), { error: field.meta.error && field.meta.submitFailed, helperText: field.meta.error && field.meta.submitFailed && t(field.meta.error) }) })); }),
                React.createElement(Field, { name: "pos" }, function (field) { return (React.createElement(Input, { label: t('IATA ID Group / POS'), variant: 'outlined', className: theme.field, TextFieldProps: __assign(__assign({}, field.input), { error: field.meta.error && field.meta.submitFailed, helperText: field.meta.error && field.meta.submitFailed && t(field.meta.error) }) })); }),
                React.createElement("div", { className: theme.error }, props.requestError),
                React.createElement("div", { className: theme.controls },
                    React.createElement(Button, { isLoading: props.isLoading, type: "submit", variant: "flat", className: theme.showQueuesBtn }, t('Show queues')))));
        })));
};
export default QueuesOwnerModal;
