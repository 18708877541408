import MonthsWrapper from './MonthsWrapper/MonthsWrapper.css';
import MonthHeader from './MonthsWrapper/Month/MonthHeader/MonthHeader.css';
import WeeklyHeader from './MonthsWrapper/Month/WeeklyHeader/WeeklyHeader.css';
import Day from './MonthsWrapper/Month/Day/Day.css';
export default {
    MonthsWrapper: MonthsWrapper,
    MonthHeader: MonthHeader,
    WeeklyHeader: WeeklyHeader,
    Day: Day
};
