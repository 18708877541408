import * as React from 'react';
import cn from 'classnames';
import { parse, isEqual } from 'date-fns';
import { API_DATE_FORMAT, format } from '../../../../../../utils';
import { useTheme } from '../../../../../../theme';
var DATE_FORMAT = 'd LLL';
var FlightDates = function (props) {
    var startSchedule = props.startSchedule, endSchedule = props.endSchedule, dateFormat = props.dateFormat, className = props.className;
    var css = useTheme('Schedule').FlightDates;
    var startScheduleParsed = parse(startSchedule, API_DATE_FORMAT, new Date());
    var endScheduleParsed = parse(endSchedule, API_DATE_FORMAT, new Date());
    if (isEqual(startScheduleParsed, endScheduleParsed)) {
        return React.createElement("div", { className: cn(css.dates, className) }, format(startScheduleParsed, dateFormat !== null && dateFormat !== void 0 ? dateFormat : DATE_FORMAT));
    }
    return (React.createElement("div", { className: cn(css.dates, className) },
        format(startScheduleParsed, dateFormat !== null && dateFormat !== void 0 ? dateFormat : DATE_FORMAT),
        " -",
        ' ',
        format(endScheduleParsed, dateFormat !== null && dateFormat !== void 0 ? dateFormat : DATE_FORMAT)));
};
export default FlightDates;
