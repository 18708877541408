import { __read, __spreadArray } from "tslib";
import { applyMiddleware } from 'redux';
import { createStore as originalCreateStore } from 'redux';
import { rootReducer } from './reducers';
import memes from '../../../memes';
export var createStore = memes(function (initialState) {
    var middlewares = [];
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(require('redux-logger').default);
    }
    var store = originalCreateStore(rootReducer, initialState, applyMiddleware.apply(void 0, __spreadArray([], __read(middlewares), false)));
    return store;
});
