export var Currency;
(function (Currency) {
    Currency["USD"] = "USD";
    Currency["RUB"] = "RUB";
    Currency["EUR"] = "EUR";
    Currency["KZT"] = "KZT";
})(Currency || (Currency = {}));
export var CurrencySymbols;
(function (CurrencySymbols) {
    CurrencySymbols["USD"] = "$";
    CurrencySymbols["RUB"] = "\u20BD";
    CurrencySymbols["EUR"] = "\u20AC";
    CurrencySymbols["KZT"] = "\u20B8";
})(CurrencySymbols || (CurrencySymbols = {}));
export var PassengerType;
(function (PassengerType) {
    PassengerType["Adult"] = "ADT";
    PassengerType["Child"] = "CLD";
    PassengerType["Infant"] = "INF";
    PassengerType["InfantWithSeat"] = "INS";
    PassengerType["YoungAdult"] = "\u041C\u041B\u0410";
    PassengerType["RetireeM"] = "SCM";
    PassengerType["RetireeF"] = "SCF";
})(PassengerType || (PassengerType = {}));
export var NationalityType;
(function (NationalityType) {
    NationalityType["AU"] = "AU";
    NationalityType["AT"] = "AT";
    NationalityType["AZ"] = "AZ";
    NationalityType["AL"] = "AL";
    NationalityType["DZ"] = "DZ";
    NationalityType["AS"] = "AS";
    NationalityType["AI"] = "AI";
    NationalityType["AO"] = "AO";
    NationalityType["AD"] = "AD";
    NationalityType["AQ"] = "AQ";
    NationalityType["AR"] = "AR";
    NationalityType["AM"] = "AM";
    NationalityType["AW"] = "AW";
    NationalityType["AF"] = "AF";
    NationalityType["BS"] = "BS";
    NationalityType["BD"] = "BD";
    NationalityType["BB"] = "BB";
    NationalityType["BH"] = "BH";
    NationalityType["BY"] = "BY";
    NationalityType["BZ"] = "BZ";
    NationalityType["BE"] = "BE";
    NationalityType["BJ"] = "BJ";
    NationalityType["BM"] = "BM";
    NationalityType["BG"] = "BG";
    NationalityType["BO"] = "BO";
    NationalityType["BQ"] = "BQ";
    NationalityType["BW"] = "BW";
    NationalityType["BR"] = "BR";
    NationalityType["IO"] = "IO";
    NationalityType["BN"] = "BN";
    NationalityType["BF"] = "BF";
    NationalityType["BI"] = "BI";
    NationalityType["BT"] = "BT";
    NationalityType["VU"] = "VU";
    NationalityType["VA"] = "VA";
    NationalityType["GB"] = "GB";
    NationalityType["HU"] = "HU";
    NationalityType["VE"] = "VE";
    NationalityType["VG"] = "VG";
    NationalityType["VI"] = "VI";
    NationalityType["VN"] = "VN";
    NationalityType["GA"] = "GA";
    NationalityType["HT"] = "HT";
    NationalityType["GY"] = "GY";
    NationalityType["GM"] = "GM";
    NationalityType["GH"] = "GH";
    NationalityType["GP"] = "GP";
    NationalityType["GT"] = "GT";
    NationalityType["GN"] = "GN";
    NationalityType["GW"] = "GW";
    NationalityType["DE"] = "DE";
    NationalityType["GG"] = "GG";
    NationalityType["GI"] = "GI";
    NationalityType["HN"] = "HN";
    NationalityType["HK"] = "HK";
    NationalityType["GD"] = "GD";
    NationalityType["GL"] = "GL";
    NationalityType["GR"] = "GR";
    NationalityType["GE"] = "GE";
    NationalityType["GU"] = "GU";
    NationalityType["DK"] = "DK";
    NationalityType["JE"] = "JE";
    NationalityType["DJ"] = "DJ";
    NationalityType["DM"] = "DM";
    NationalityType["DO"] = "DO";
    NationalityType["EG"] = "EG";
    NationalityType["ZM"] = "ZM";
    NationalityType["EH"] = "EH";
    NationalityType["ZW"] = "ZW";
    NationalityType["IL"] = "IL";
    NationalityType["IN"] = "IN";
    NationalityType["ID"] = "ID";
    NationalityType["JO"] = "JO";
    NationalityType["IQ"] = "IQ";
    NationalityType["IR"] = "IR";
    NationalityType["IE"] = "IE";
    NationalityType["IS"] = "IS";
    NationalityType["ES"] = "ES";
    NationalityType["IT"] = "IT";
    NationalityType["YE"] = "YE";
    NationalityType["CV"] = "CV";
    NationalityType["KZ"] = "KZ";
    NationalityType["KH"] = "KH";
    NationalityType["CM"] = "CM";
    NationalityType["CA"] = "CA";
    NationalityType["QA"] = "QA";
    NationalityType["KE"] = "KE";
    NationalityType["CY"] = "CY";
    NationalityType["KI"] = "KI";
    NationalityType["CN"] = "CN";
    NationalityType["CC"] = "CC";
    NationalityType["CO"] = "CO";
    NationalityType["KM"] = "KM";
    NationalityType["CG"] = "CG";
    NationalityType["CD"] = "CD";
    NationalityType["KP"] = "KP";
    NationalityType["CR"] = "CR";
    NationalityType["CU"] = "CU";
    NationalityType["KW"] = "KW";
    NationalityType["KG"] = "KG";
    NationalityType["CW"] = "CW";
    NationalityType["LA"] = "LA";
    NationalityType["LV"] = "LV";
    NationalityType["LS"] = "LS";
    NationalityType["LR"] = "LR";
    NationalityType["LB"] = "LB";
    NationalityType["LY"] = "LY";
    NationalityType["LT"] = "LT";
    NationalityType["LI"] = "LI";
    NationalityType["LU"] = "LU";
    NationalityType["MU"] = "MU";
    NationalityType["MR"] = "MR";
    NationalityType["MG"] = "MG";
    NationalityType["YT"] = "YT";
    NationalityType["MO"] = "MO";
    NationalityType["MK"] = "MK";
    NationalityType["UM"] = "UM";
    NationalityType["MW"] = "MW";
    NationalityType["MY"] = "MY";
    NationalityType["ML"] = "ML";
    NationalityType["MV"] = "MV";
    NationalityType["MT"] = "MT";
    NationalityType["MA"] = "MA";
    NationalityType["MQ"] = "MQ";
    NationalityType["MH"] = "MH";
    NationalityType["MX"] = "MX";
    NationalityType["FM"] = "FM";
    NationalityType["MZ"] = "MZ";
    NationalityType["MD"] = "MD";
    NationalityType["MC"] = "MC";
    NationalityType["MN"] = "MN";
    NationalityType["MS"] = "MS";
    NationalityType["MM"] = "MM";
    NationalityType["NA"] = "NA";
    NationalityType["NR"] = "NR";
    NationalityType["NP"] = "NP";
    NationalityType["NE"] = "NE";
    NationalityType["NG"] = "NG";
    NationalityType["AN"] = "AN";
    NationalityType["NL"] = "NL";
    NationalityType["NI"] = "NI";
    NationalityType["NU"] = "NU";
    NationalityType["NZ"] = "NZ";
    NationalityType["NC"] = "NC";
    NationalityType["NO"] = "NO";
    NationalityType["HM"] = "HM";
    NationalityType["AE"] = "AE";
    NationalityType["OM"] = "OM";
    NationalityType["BV"] = "BV";
    NationalityType["IM"] = "IM";
    NationalityType["NF"] = "NF";
    NationalityType["CX"] = "CX";
    NationalityType["KY"] = "KY";
    NationalityType["CK"] = "CK";
    NationalityType["PK"] = "PK";
    NationalityType["PW"] = "PW";
    NationalityType["PS"] = "PS";
    NationalityType["PA"] = "PA";
    NationalityType["PG"] = "PG";
    NationalityType["PY"] = "PY";
    NationalityType["PE"] = "PE";
    NationalityType["PN"] = "PN";
    NationalityType["PL"] = "PL";
    NationalityType["PT"] = "PT";
    NationalityType["PR"] = "PR";
    NationalityType["RE"] = "RE";
    NationalityType["RU"] = "RU";
    NationalityType["RW"] = "RW";
    NationalityType["RO"] = "RO";
    NationalityType["WS"] = "WS";
    NationalityType["SM"] = "SM";
    NationalityType["SA"] = "SA";
    NationalityType["SZ"] = "SZ";
    NationalityType["SH"] = "SH";
    NationalityType["MP"] = "MP";
    NationalityType["SC"] = "SC";
    NationalityType["BL"] = "BL";
    NationalityType["MF"] = "MF";
    NationalityType["SN"] = "SN";
    NationalityType["VC"] = "VC";
    NationalityType["LC"] = "LC";
    NationalityType["RS"] = "RS";
    NationalityType["SG"] = "SG";
    NationalityType["SX"] = "SX";
    NationalityType["SY"] = "SY";
    NationalityType["SK"] = "SK";
    NationalityType["SI"] = "SI";
    NationalityType["US"] = "US";
    NationalityType["SB"] = "SB";
    NationalityType["SO"] = "SO";
    NationalityType["SD"] = "SD";
    NationalityType["SR"] = "SR";
    NationalityType["SL"] = "SL";
    NationalityType["TJ"] = "TJ";
    NationalityType["TH"] = "TH";
    NationalityType["TW"] = "TW";
    NationalityType["TZ"] = "TZ";
    NationalityType["TL"] = "TL";
    NationalityType["TG"] = "TG";
    NationalityType["TK"] = "TK";
    NationalityType["TO"] = "TO";
    NationalityType["TV"] = "TV";
    NationalityType["TN"] = "TN";
    NationalityType["TM"] = "TM";
    NationalityType["TR"] = "TR";
    NationalityType["UG"] = "UG";
    NationalityType["UZ"] = "UZ";
    NationalityType["UA"] = "UA";
    NationalityType["UY"] = "UY";
    NationalityType["FO"] = "FO";
    NationalityType["FJ"] = "FJ";
    NationalityType["PH"] = "PH";
    NationalityType["FI"] = "FI";
    NationalityType["FK"] = "FK";
    NationalityType["FR"] = "FR";
    NationalityType["GF"] = "GF";
    NationalityType["PF"] = "PF";
    NationalityType["TF"] = "TF";
    NationalityType["HR"] = "HR";
    NationalityType["CF"] = "CF";
    NationalityType["TD"] = "TD";
    NationalityType["ME"] = "ME";
    NationalityType["CZ"] = "CZ";
    NationalityType["CL"] = "CL";
    NationalityType["CH"] = "CH";
    NationalityType["SE"] = "SE";
    NationalityType["LK"] = "LK";
    NationalityType["EC"] = "EC";
    NationalityType["GQ"] = "GQ";
    NationalityType["AX"] = "AX";
    NationalityType["SV"] = "SV";
    NationalityType["ER"] = "ER";
    NationalityType["EE"] = "EE";
    NationalityType["ET"] = "ET";
    NationalityType["GS"] = "GS";
    NationalityType["ZA"] = "ZA";
    NationalityType["KR"] = "KR";
    NationalityType["SS"] = "SS";
    NationalityType["JM"] = "JM";
    NationalityType["JP"] = "JP";
})(NationalityType || (NationalityType = {}));
