import * as React from 'react';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';
import * as theme from './Settings.css';
import AccountTemplate from '../AccountTemplate/AccountTemplate';
import ChangePassword from './ChangePassword/ChangePassword';
var Settings = function () {
    var t = useTranslation('Account').t;
    return (React.createElement(AccountTemplate, null,
        React.createElement("div", { className: theme.header }, t('Settings')),
        React.createElement(Card, { className: theme.card },
            React.createElement("div", { className: theme.cardHeader }, t('Change password')),
            React.createElement(ChangePassword, null))));
};
export default Settings;
