import { filterFareGroups } from '../Results/utils';
import { useMediaQuery } from 'react-responsive';
export var useFareGroupsMap = function (flight, previouslySelectedFares) {
    var fareGroupMap = new Map();
    var nonEmptyFlightClassesCount = 0;
    flight.pricesForFareGroups.forEach(function (priceForFareGroup) {
        var _a, _b;
        if (!priceForFareGroup.fareFamily) {
            return;
        }
        if (((_a = priceForFareGroup.fareFamily) === null || _a === void 0 ? void 0 : _a.category) && !priceForFareGroup.fareFamily) {
            return;
        }
        if (!fareGroupMap.has(priceForFareGroup.fareFamily.category)) {
            fareGroupMap.set(priceForFareGroup.fareFamily.category, filterFareGroups(flight.pricesForFareGroups, previouslySelectedFares, (_b = priceForFareGroup.fareFamily.category) !== null && _b !== void 0 ? _b : null, true));
        }
        if (priceForFareGroup.prices && fareGroupMap.has(priceForFareGroup.fareFamily.category)) {
            nonEmptyFlightClassesCount += 1;
        }
    });
    return { fareGroupMap: fareGroupMap, nonEmptyFlightClassesCount: nonEmptyFlightClassesCount };
};
export var useIsFullScreenFareModal = function () { return useMediaQuery({ maxHeight: 800 }); };
