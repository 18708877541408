import { __read } from "tslib";
import { format, parse } from 'date-fns';
import { AviaPassengerType } from '../../../../__generated__/globalTypes';
import { amadeusPassengerTypes, defaultYear } from '../helpers';
var PASSENGER_TYPE_COUNT = /\w+\/\d+|\w+\//g;
var FXD = /^FXD/g;
var DIGITS = /\d+/g;
var DIGITS_ON_START = /^\d+/g;
export var removeFXD = function (str) { return str.replace(FXD, ''); };
export var getFullDate = function (str) {
    try {
        return format(new Date("".concat(str).concat(defaultYear)), 'yyyy-MM-dd');
    }
    catch (e) {
        var withoutLastTwoLetters = str.slice(0, str.length - 2);
        var lastTwoLetters = str.slice(str.length - 2, str.length);
        var monthWithDay = "".concat(withoutLastTwoLetters).concat(lastTwoLetters);
        var date = parse(monthWithDay, 'dLLL', new Date());
        return format(date, 'yyyy-MM-dd');
    }
};
export var getSegments = function (str) {
    var segments = str.split('/');
    var iata0 = segments.splice(0, 1)[0].replace(DIGITS, '');
    var iatas = [iata0].concat(segments.map(function (s) { return s.slice(6, 9); }));
    return segments.map(function (segment, idx) { return ({
        date: getFullDate(segment.slice(1, 6)),
        departure: {
            iata: iatas[idx].toUpperCase()
        },
        arrival: {
            iata: iatas[idx + 1].toUpperCase()
        }
    }); });
};
export var getPassengers = function (str, passengersCount) {
    var passengers = (str + '/').match(PASSENGER_TYPE_COUNT);
    var result = passengers.map(function (passenger) {
        var _a = __read(passenger.split('/'), 2), type = _a[0], count = _a[1];
        return {
            passengerType: amadeusPassengerTypes[type],
            count: count ? +count : 1
        };
    });
    var passengerCountWithoutINF = result.reduce(function (acc, _a) {
        var passengerType = _a.passengerType, count = _a.count;
        return (passengerType !== AviaPassengerType.INF ? acc + count : acc);
    }, 0);
    if (passengerCountWithoutINF === passengersCount) {
        return result;
    }
    else {
        return result.concat({
            passengerType: AviaPassengerType.ADT,
            count: 1
        });
    }
};
export var amadeusTransportationOptions = function (str) {
    var strWithoutFDX = removeFXD(str);
    var passengersCountOnStart = strWithoutFDX.match(DIGITS_ON_START);
    var passengersCount = passengersCountOnStart ? +passengersCountOnStart[0] : 1;
    var _a = __read(strWithoutFDX.split('//'), 2), segmentsInfo = _a[0], passengerInfo = _a[1];
    return {
        passengers: getPassengers(passengerInfo ? passengerInfo : "PAX/".concat(passengersCount), passengersCount),
        segments: getSegments(segmentsInfo)
    };
};
