import React, { useContext, useMemo } from 'react';
import { OrderCardContext } from '../../../OrderCard';
import { isSameDay, parseISO } from 'date-fns';
import { format } from '../../../../../utils';
import { useTranslation } from 'react-i18next';
var WaitPaymentText = function () {
    var translateFunc = useTranslation('OrderCard').t;
    var order = useContext(OrderCardContext).order;
    var now = useMemo(function () { return new Date(); }, []);
    var isToday = useMemo(function () {
        return isSameDay(parseISO(order.timelimit), now);
    }, [order.timelimit, now]);
    var t = function (translateKey) { return translateFunc(translateKey, { nsSeparator: '|' }); };
    if (isToday) {
        return (React.createElement(React.Fragment, null,
            t('The order must be paid today'),
            " ",
            format(order.timelimit, 'HH:mm')));
    }
    else {
        return (React.createElement(React.Fragment, null,
            t('The order must be paid at'),
            " ",
            format(order.timelimit, 'HH:mm, dd.MM.yyyy')));
    }
};
export default WaitPaymentText;
