import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { currency } from '../../__fakeData__';
import { CheckboxField, NumberField } from '../Fields/Fields';
var SubmitControls = function (props) {
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    var onCancel = props.onCancel;
    return (React.createElement("div", { className: theme.sectionBlock__footer },
        React.createElement("div", { className: theme.formSubmit },
            React.createElement("div", null,
                React.createElement("label", { className: theme.formCheckbox },
                    React.createElement(CheckboxField, { name: 'confirm', label: t('I confirm, that the calculation was made correctly'), toolTip: t('Сonfirmation is required') }))),
            React.createElement("div", null,
                React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(NumberField, { name: 'totalAmount' }),
                            React.createElement("div", { className: theme.formControl__label },
                                props.summaryLabel,
                                " (",
                                currency,
                                ")")))),
                React.createElement("div", { className: theme.formSubmit__buttons },
                    React.createElement("div", null,
                        React.createElement("button", { type: "button", className: cn(theme.formButton, theme.formButton_bg_gray) }, t('Cancel'))),
                    React.createElement("div", null,
                        React.createElement("button", { onClick: onCancel, type: "submit", className: theme.formButton }, props.submitText)))))));
};
export default SubmitControls;
