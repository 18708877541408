import { Locale, LOCALES } from './types';
import { Currency } from './enums';
export var RECENT_SEARCHES_KEY = 'RECENT_SEARCHES_KEY';
export var SEARCH_FORM_STATE_KEY = 'SEARCH_FORM_STATE_KEY';
export var LOCALE_KEY = 'LOCALE_KEY';
export var CURRENCY_KEY = 'CURRENCY_KEY';
export var SEATS_RECOMMENDATION_SHOWN = 'SEATS_RECOMMENDATION_SHOWN';
export var BAGGAGE_RECOMMENDATION_SHOWN = 'BAGGAGE_RECOMMENDATION_SHOWN ';
export var MEAL_RECOMMENDATION_SHOWN = 'MEAL_RECOMMENDATION_SHOWN';
export var TEST_AUTHORIZATION_REFUSED = 'TEST_AUTHORIZATION_REFUSED';
export var GRAPHQL_KEY = 'hashed_value';
export var set = function (key, value) {
    if (typeof localStorage !== 'undefined') {
        if (value) {
            try {
                localStorage.setItem(key, JSON.stringify(value));
            }
            catch (e) {
                console.warn(e);
            }
        }
        else {
            try {
                localStorage.removeItem(key);
            }
            catch (e) {
                console.warn(e);
            }
        }
    }
};
export var get = function (key, defaultValue) {
    var result = defaultValue;
    if (typeof localStorage !== 'undefined') {
        var string = localStorage.getItem(key);
        if (string) {
            try {
                result = JSON.parse(string);
            }
            catch (e) {
                console.warn(e);
            }
        }
    }
    return result;
};
export var getLocaleFromLocalStorage = function () {
    var locale = get(LOCALE_KEY);
    return LOCALES.has(locale) ? locale : Locale.Russian;
};
export var getCurrency = function () {
    var _a, _b, _c, _d;
    return get(CURRENCY_KEY, ((_d = (_c = (_b = (_a = window.__webskyEngineConfig) === null || _a === void 0 ? void 0 : _a.SearchForm) === null || _b === void 0 ? void 0 : _b.selectableCurrencies) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.code) || Currency.RUB);
};
export var setLocaleToLocalStorage = function (locale) {
    set(LOCALE_KEY, locale);
};
export var setCurrency = function (currency) {
    var oldCurrency = getCurrency();
    set(CURRENCY_KEY, currency);
    dispatchEvent(new StorageEvent('storage', { key: CURRENCY_KEY, oldValue: oldCurrency, newValue: currency }));
};
