import { __assign, __read } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { getOrderId, getTravellers } from '../../Checkout/store/order/selectors';
import { useSelector } from 'react-redux';
import Trigger from '../DocumentRecognition/components/Trigger/Trigger';
import { mrzResultToPassengerValuesAdapter } from '../DocumentRecognition/adapters';
import { decapitalizeFirstLetter } from '../../utils';
import { TravellerFieldEnum } from '@websky/graphql';
import { fieldIsRequired } from '../../Passenger/utils';
var PassengerControls = function (props) {
    var passengerType = props.passengerType, id = props.id;
    var orderId = useSelector(getOrderId);
    var travellers = useSelector(getTravellers);
    var currentTraveller = travellers.find(function (traveller) { return traveller.id === props.id.toString(10); });
    var handleSetRecognizedFields = useCallback(function (values) {
        var passengerFields = mrzResultToPassengerValuesAdapter(values, currentTraveller);
        var decapitalizedFirstKeyLetterValues = Object.entries(values).reduce(function (decapitalized, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], value = _c[1];
            return __assign(__assign({}, decapitalized), (_b = {}, _b[decapitalizeFirstLetter(key)] = value, _b));
        }, {});
        var isExpiryRequired = fieldIsRequired(decapitalizedFirstKeyLetterValues, currentTraveller.values.find(function (value) { return value.type === TravellerFieldEnum.DocExpiryDate; }).validationRules);
        props.mutators.clearPassengerValuesMutator(id);
        passengerFields.forEach(function (passengerField) {
            if ((passengerField.type === TravellerFieldEnum.DocExpiryDate && isExpiryRequired) ||
                passengerField.type !== TravellerFieldEnum.DocExpiryDate) {
                props.mutators.changePassengerField(passengerField.name, props.id, passengerField.value);
            }
        });
    }, [props.id, props.mutators.changePassengerField]);
    return (React.createElement(Trigger, { traveller: currentTraveller, onConfirmScanFields: handleSetRecognizedFields, orderId: orderId, passengerType: passengerType, passengerId: id, isDisabled: props.isInputsReadonly }));
};
export default PassengerControls;
