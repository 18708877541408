import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../../../../../theme';
import { getDurationString } from '../../../../../../../Results/utils';
import { useTranslation } from 'react-i18next';
export var StopsInfo = function (props) {
    var theme = useTheme('Schedule').Day;
    var t = useTranslation('Schedule').t;
    var transfers = props.leg.segments.reduce(function (transfers, segment) {
        if (segment.transferDuration) {
            return __spreadArray(__spreadArray([], __read(transfers), false), [
                {
                    duration: segment.transferDuration,
                    cityName: segment.segment.arrival.airport.city.name,
                    iata: segment.segment.arrival.airport.iata
                }
            ], false);
        }
        return transfers;
    }, []);
    return (React.createElement(React.Fragment, null, transfers.map(function (transfer) { return (React.createElement("div", { className: theme.transferInfo, key: transfer.iata },
        t('Stop in'),
        " ",
        transfer.cityName,
        " (",
        transfer.iata,
        ") ",
        getDurationString(transfer.duration))); })));
};
