import * as React from 'react';
import { useCallback } from 'react';
import Passenger from '../Passenger/Passenger';
import RefundPaper from '../RefundPaper/RefundPaper';
import { useTheme } from '../../../theme';
var Passengers = function (props) {
    var css = useTheme('Refund').Passengers;
    var renderPassenger = useCallback(function (passenger) { return (React.createElement(Passenger, { cardProps: { className: css.passenger }, key: passenger.id, passenger: passenger, checked: passenger.selected, onPassengerSelect: props.onPassengerSelect })); }, [props.passengers]);
    return React.createElement(RefundPaper, { className: css.passengers }, props.passengers.map(renderPassenger));
};
export default Passengers;
