import { TravellerFieldEnum } from '../../../../__generated__/graphql';
import { API_DATE_FORMAT, format } from '../../../../utils';
import { parse } from 'date-fns';
import i18n from 'i18next';
export var getPassengerName = function (passenger) {
    var firstName = passenger.values.find(function (value) { return value.type === TravellerFieldEnum.FirstName; }).value, lastName = passenger.values.find(function (value) { return value.type === TravellerFieldEnum.LastName; }).value;
    return "".concat(firstName, " ").concat(lastName);
};
export var exareVariantPriceConverter = function (price) {
    return price;
};
// Todo: bruh
export var searchParamsConverter = function (resultsSearchParams) {
    return resultsSearchParams;
};
export var exchangeVariantPriceInfoConverter = function (priceInfo) {
    return priceInfo;
};
export var lowestPricesConverter = function (datesWithLowestPrices) {
    return datesWithLowestPrices;
};
export var getSelectedFlightsInfo = function (flights) {
    var segmentsInfo = flights.map(function (flight) {
        var _a, _b;
        if ((_b = (_a = flight === null || flight === void 0 ? void 0 : flight.fare) === null || _a === void 0 ? void 0 : _a.flightInfo) === null || _b === void 0 ? void 0 : _b.segments) {
            return flight.fare.flightInfo.segments
                .map(function (segment) {
                var _a, _b;
                var flightDate = format(parse(segment.segment.departure.date, API_DATE_FORMAT, new Date()), 'dd.MM.yyyy');
                return i18n.t('Exchange:{{segmentId}}. {{depDate}}, {{airlineCode}}{{flightNumber}}. {{depIata}}({{depTime}}) -> {{arrIata}}({{arrTime}}). Тариф {{tariff}}', {
                    segmentId: parseInt(segment.segment.id) + 1,
                    depDate: flightDate,
                    airlineCode: segment.segment.marketingAirline.iata,
                    flightNumber: segment.segment.flightNumber,
                    depIata: (_b = (_a = segment.segment.departure) === null || _a === void 0 ? void 0 : _a.airport) === null || _b === void 0 ? void 0 : _b.iata,
                    depTime: segment.segment.departure.time,
                    arrIata: segment.segment.arrival.airport.iata,
                    arrTime: segment.segment.arrival.time,
                    tariff: flight.fare.fareName
                });
            })
                .join('\n');
        }
        return '';
    });
    return segmentsInfo.join('\n');
};
