import { __read, __spreadArray } from "tslib";
import { useMemo } from 'react';
import { useGetGdsServicesRefundInfoQuery } from '@websky/graphql';
import { useSelector } from 'react-redux';
import { getOrder, isOpenedExchange } from '../../store/order/selectors';
import { getFlightIdsToExchange } from '../../store/flightsToExchange/selectors';
import { getIsAllFlights } from '../../store/selectors';
import { getProductsToExchange } from './SelectedServices/utils';
import { getNewFlightId } from '../../store/newFlights/selectors';
import { getSelectedReason } from '../../store/reason/selectors';
import { Reasons } from '../../store/types';
export var useCalculateGdsServiceRefund = function (selectedPassengers) {
    var selectedSegmentIds = useSelector(getFlightIdsToExchange);
    var isAllFlightToExchange = useSelector(getIsAllFlights);
    var order = useSelector(getOrder);
    var isNewFlight = !!useSelector(getNewFlightId);
    var selectedReason = useSelector(getSelectedReason);
    var openedExchange = useSelector(isOpenedExchange);
    var services = [];
    selectedPassengers.forEach(function (passengerId) {
        var _a, _b, _c, _d, _e;
        var traveller = order.travellers.find(function (traveller) { return traveller.id === passengerId; });
        if ((_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) {
            services.push.apply(services, __spreadArray([], __read((_d = (_c = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services), false));
        }
        if ((_e = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _e === void 0 ? void 0 : _e.seats) {
            traveller.services.seats.forEach(function (seat) {
                services.push({
                    segmentIds: [seat.segment.id],
                    products: [seat.product]
                });
            });
        }
    });
    var selectedProductIds = useMemo(function () {
        return getProductsToExchange(services, isAllFlightToExchange ? [] : selectedSegmentIds.map(function (flight) { return flight.key; }));
    }, [services, selectedSegmentIds, isAllFlightToExchange]);
    var skip = false;
    if (!selectedProductIds.length) {
        skip = true;
    }
    else if (selectedReason === Reasons.Voluntary &&
        !(selectedSegmentIds.length > 0 && isNewFlight && openedExchange)) {
        skip = true;
    }
    else if (!(selectedSegmentIds.length > 0 && isNewFlight)) {
        skip = true;
    }
    var _a = useGetGdsServicesRefundInfoQuery({
        variables: {
            params: {
                orderId: order.id,
                productIds: selectedProductIds
            }
        },
        skip: skip,
        fetchPolicy: 'no-cache'
    }), data = _a.data, loading = _a.loading;
    return {
        data: data,
        loading: loading
    };
};
