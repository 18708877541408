import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BaggageCard from '../../BaggageCard';
import { Overweight as OverweightIcon } from '../../../Icons';
import { useTheme } from '../../../theme';
import { getCurrency } from '../../../cache';
import { getOverweightMinPrice } from '../../../Checkout/store/order/selectors';
import { BaggageType } from '../../../__generated__/globalTypes';
import UpgradeBaggageDialog from '../../UpgradeBaggageDialog/components/UpgradeBaggageDialog';
import { useToggleable } from '../../../hooks';
import { useSelectedServicesBySegments } from '../../UpgradeBaggageDialog/hooks';
import BaggageCardItem from '../../BaggageCard/components/BaggageCardItem/BaggageCardItem';
var OverweightBaggage = function (_a) {
    var className = _a.className, passengers = _a.passengers, passengerLabels = _a.passengerLabels, segments = _a.segments, services = _a.services, selectedServices = _a.selectedServices, isLoading = _a.isLoading, onUpgrade = _a.onUpgrade, disabled = _a.disabled;
    var css = useTheme('OverweightBaggage').OverweightBaggage;
    var t = useTranslation('AdditionalBaggage').t;
    var _b = useToggleable(false), isOpen = _b.isOpen, open = _b.open, close = _b.close;
    var overweightMinPrice = useSelector(getOverweightMinPrice);
    var _c = useSelectedServicesBySegments(services, segments, selectedServices, passengers), selectedItems = _c.selectedItems, hasSelected = _c.hasSelected;
    var totalPrice = useMemo(function () {
        return {
            amount: selectedItems.reduce(function (total, service) {
                var _a, _b;
                return +total + +(((_b = (_a = service.service) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.amount) || 0) * service.count;
            }, 0),
            currency: getCurrency()
        };
    }, [selectedItems]);
    return (React.createElement(React.Fragment, null,
        React.createElement(BaggageCard, { className: className, classNameIcon: css.icon, icon: OverweightIcon, title: t('Overweight'), description: t('Need to add another 10-30 kg to your total checked baggage?'), fromPrice: overweightMinPrice, totalPrice: totalPrice, hasSelected: hasSelected, onEdit: open, onAdd: open }, hasSelected && (React.createElement("div", { className: css.flights }, selectedItems.map(function (service, index) {
            var isAllSegments = service.segments.length === segments.length;
            var direction = isAllSegments
                ? t('All flights')
                : "".concat(service.segments[0].departure.airport.iata, " \u2013 ").concat(service.segments[0].arrival.airport.iata);
            var description = service.service.value
                ? "".concat(t('Up to'), " ").concat(service.service.value.amount, " ").concat(service.service.value.measurement)
                : '';
            return (React.createElement(BaggageCardItem, { key: index, className: css.flight, baggage: service.service, count: service.count, passenger: service.passenger, direction: direction, passengerLabels: passengerLabels, description: description }));
        })))),
        React.createElement(UpgradeBaggageDialog, { open: isOpen, onClose: close, onConfirm: onUpgrade, passengers: passengers, passengerLabels: passengerLabels, segments: segments, includedBaggage: [], upgradeBaggage: services, selectedServices: selectedServices, headerProps: {
                header: t('Overweight'),
                description: ''
            }, disabled: isLoading || disabled, baggageType: BaggageType.BaggageExcess })));
};
export default OverweightBaggage;
