import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { blueAddIcon, loyaltyIcon, planetIcon, whiteAddIcon } from '../Icons';
import { initI18n } from '../../utils';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import InfoCardDialog from './InfoCardDialog/InfoCardDialog';
import { useTheme } from '../../theme';
initI18n('InfoCard');
var InfoCard = function (props) {
    var _a;
    var _b;
    var css = useTheme('InfoCard').InfoCard, _c = __read(useState(!props.infoCard), 1), dummy = _c[0], _d = __read(useState(false), 2), isOpenedDocumentModal = _d[0], setOpenedDocumentModal = _d[1], _e = __read(useState(false), 2), isOpenedLoyaltyModal = _e[0], setOpenedLoyaltyModal = _e[1];
    var t = useTranslation('InfoCard').t, tDocuments = useTranslation('Passenger').t;
    function isDocumentInfoCard(infoCard) {
        return infoCard && infoCard.hasOwnProperty('docType');
    }
    var addToList = function (values, list) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (list === 'documents' && isDocumentInfoCard(values)) {
                props.onSave(values, list);
            }
            return [2 /*return*/];
        });
    }); };
    return (React.createElement("div", { className: cn(css.card, (_a = {},
            _a[css.card_loyalty] = !isDocumentInfoCard(props.infoCard) && !props.isEmptyDocument,
            _a[css.card_dummy] = dummy,
            _a)) },
        React.createElement("div", { className: css.header },
            React.createElement("div", null, isDocumentInfoCard(props.infoCard) || props.isEmptyDocument ? planetIcon : loyaltyIcon),
            React.createElement("div", { className: css.document },
                React.createElement("h1", { className: css.subtitle }, isDocumentInfoCard(props.infoCard) && tDocuments(props.infoCard.docType)))),
        React.createElement("div", { className: css.content },
            React.createElement("div", { className: css.content__item },
                React.createElement("p", { className: css.content__item__name }, t('Number')),
                React.createElement("p", { className: css.content__item__value }, ((_b = props.infoCard) === null || _b === void 0 ? void 0 : _b['docNumber']) || '0000000000')),
            (isDocumentInfoCard(props.infoCard) && props.infoCard.docExpiryDate) || props.isEmptyDocument ? (React.createElement("div", { className: css.content__item },
                React.createElement("p", { className: css.content__item__name }, t('Expiry date')),
                React.createElement("p", { className: css.content__item__value }, (isDocumentInfoCard(props.infoCard) && props.infoCard.docExpiryDate) || t('dd.mm.yyyy')))) : null),
        React.createElement("div", { className: css.footer }, props.infoCard ? (React.createElement("p", null,
            React.createElement(Button, { className: css.footer__button, classes: { label: css.button__label }, onClick: function () { return props.onDelete(); } }, t('Delete')),
            React.createElement(Button, { className: css.footer__button, classes: { label: css.button__label }, onClick: function () {
                    if (isDocumentInfoCard(props.infoCard)) {
                        setOpenedDocumentModal(true);
                    }
                    else {
                        setOpenedLoyaltyModal(true);
                    }
                } }, t('Edit')))) : (React.createElement("p", null,
            React.createElement(Button, { className: css.footer__button, classes: { label: css.button__label }, onClick: function () {
                    if (props.isEmptyDocument) {
                        setOpenedDocumentModal(true);
                    }
                    else {
                        setOpenedLoyaltyModal(true);
                    }
                } },
                t('Add'),
                React.createElement("span", null, isDocumentInfoCard(props.infoCard) || props.isEmptyDocument
                    ? blueAddIcon
                    : whiteAddIcon))))),
        React.createElement(InfoCardDialog, { onClose: function () {
                return props.isEmptyDocument || isDocumentInfoCard(props.infoCard)
                    ? setOpenedDocumentModal(false)
                    : setOpenedLoyaltyModal(false);
            }, type: 'document', open: props.isEmptyDocument || isDocumentInfoCard(props.infoCard)
                ? isOpenedDocumentModal
                : isOpenedLoyaltyModal, document: isDocumentInfoCard(props.infoCard) && props.infoCard, validators: (isDocumentInfoCard(props.infoCard) || props.isEmptyDocument) &&
                props.validators, nationality: props.nationality, onSave: function (values) {
                if (isDocumentInfoCard(values)) {
                    addToList(values, 'documents');
                    setOpenedDocumentModal(false);
                }
            }, isChild: props.isChild })));
};
export default InfoCard;
