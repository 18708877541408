import { __read } from "tslib";
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { initI18n } from '../../utils';
import { Money } from '../../Money';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import Collapse from '@material-ui/core/Collapse';
import { CheckoutSteps } from '../types';
import Step from './Step/Step';
import { expandIcon } from '../Icons';
import { getStepPrice, instanceOfBaggage, instanceOfMeal } from '../utils';
import { OrderStatus } from '../../__generated__/globalTypes';
import MobileStepbarContextProvider from '../context';
initI18n('MobileStepbar');
var MobileStepbar = function (_a) {
    var _b, _c;
    var _d, _e, _f;
    var mode = _a.mode, totalPrice = _a.totalPrice, steps = _a.steps, currentStep = _a.currentStep, order = _a.order, passengers = _a.passengers, onSetOrder = _a.onSetOrder, passengerNationalities = _a.passengerNationalities;
    var _g = __read(useState(0), 2), maxStepIndex = _g[0], setMaxStepIndex = _g[1];
    var _h = __read(useState(0), 2), currentStepIndex = _h[0], setCurrentStepIndex = _h[1];
    var css = useTheme('MobileStepbar').MobileStepbarStyles;
    var t = useTranslation().t;
    var _j = __read(React.useState(false), 2), open = _j[0], setOpen = _j[1];
    var hasSelectedServices = (_b = {},
        _b[CheckoutSteps.Baggage] = false,
        _b[CheckoutSteps.Meal] = false,
        _b[CheckoutSteps.Seats] = passengers.some(function (passenger) { return passenger.seats && passenger.seats.length > 0; }),
        _b);
    (_e = (_d = order.additionalServices.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.forEach(function (service) {
        var hasService = passengers.some(function (passenger) { var _a; return (_a = passenger.services) === null || _a === void 0 ? void 0 : _a.find(function (passengerService) { return passengerService.serviceId === service.id; }); });
        if (hasService && instanceOfBaggage(service)) {
            hasSelectedServices[CheckoutSteps.Baggage] = true;
        }
        else if (hasService && instanceOfMeal(service)) {
            hasSelectedServices[CheckoutSteps.Meal] = true;
        }
    });
    var stepsArray = useMemo(function () {
        var result = [];
        Object.values(steps)
            .filter(function (step) { return !step.isHidden; })
            .filter(function (step) { return step.type !== 'StepTotal'; })
            .forEach(function (step) {
            result.push(step);
            if (step.type === CheckoutSteps.Passengers &&
                (order.status === OrderStatus.Booked || order.status === OrderStatus.Confirmed)) {
                result.push({ label: t("Stepbar:".concat(CheckoutSteps.Extras)), type: CheckoutSteps.Extras });
            }
        });
        return result;
    }, [steps, order.status]);
    useEffect(function () {
        setCurrentStepIndex(currentStep);
        if (order.status === OrderStatus.Booked &&
            (stepsArray[currentStep].type === CheckoutSteps.Passengers ||
                stepsArray[currentStep].type === CheckoutSteps.Extras)) {
            setCurrentStepIndex(function (prevState) { return prevState + 1; });
        }
        if (currentStep > maxStepIndex) {
            setMaxStepIndex(currentStep);
        }
        if (order.status === OrderStatus.Booked && !(currentStep < maxStepIndex)) {
            setMaxStepIndex(function (prevIndex) { return prevIndex + 1; });
        }
    }, [currentStep]);
    var getStepsPrice = function (step) {
        var _a;
        var stepsPrices = (_a = {},
            _a[CheckoutSteps.Baggage] = { currency: null, amount: null },
            _a[CheckoutSteps.Passengers] = { currency: null, amount: null },
            _a[CheckoutSteps.Meal] = { currency: null, amount: null },
            _a[CheckoutSteps.Seats] = { currency: null, amount: null },
            _a[CheckoutSteps.Flights] = { currency: null, amount: null },
            _a[CheckoutSteps.Extras] = { currency: null, amount: null },
            _a[CheckoutSteps.Payment] = { currency: null, amount: null },
            _a);
        for (var step_1 in stepsPrices) {
            stepsPrices[step_1] = getStepPrice(CheckoutSteps[step_1], order, passengers);
        }
        switch (step) {
            case CheckoutSteps.Flights:
                return stepsPrices.Flights;
            case CheckoutSteps.Seats:
                return stepsPrices.Seats;
            case CheckoutSteps.Baggage:
                return stepsPrices.Baggage;
            case CheckoutSteps.Meal:
                return stepsPrices.Meal;
            case CheckoutSteps.Extras:
                return stepsPrices.Extras;
            default:
                return { currency: null, amount: null };
        }
    };
    var toggle = function () { return setOpen(!open); };
    return (React.createElement(MobileStepbarContextProvider, { value: mode },
        React.createElement("div", { className: cn(css.stepbar) },
            React.createElement("div", { className: css.header, onClick: toggle },
                React.createElement("div", null, currentStepIndex >= 0 &&
                    ((_f = stepsArray[currentStepIndex]) === null || _f === void 0 ? void 0 : _f.label) &&
                    "".concat(currentStepIndex + 1, "/").concat(stepsArray.length, ": ").concat(t("Stepbar:".concat(stepsArray[currentStepIndex].label)))),
                React.createElement("div", { className: css.price },
                    t('MobileStepbar:Total'),
                    ": ",
                    React.createElement(Money, { money: totalPrice }),
                    React.createElement("span", { className: cn(css.expand, (_c = {},
                            _c[css.expand_open] = open,
                            _c)) }, expandIcon))),
            React.createElement(Collapse, { in: open }, stepsArray.map(function (step, index) { return (React.createElement(Step, { totalPrice: getStepsPrice(step.type), step: step, isPassed: index < maxStepIndex, key: index, order: order, hasSelectedServices: hasSelectedServices[step.type], passengers: passengers, index: index + 1, isActive: index === maxStepIndex || step.alwaysActive, onStepSelect: toggle, onSetOrder: onSetOrder, passengerNationalities: passengerNationalities })); })))));
};
export default MobileStepbar;
