import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { format, initI18n } from '../utils';
import { FlightClass, TemperatureUnits } from '../__generated__/globalTypes';
import { weatherIcons } from '../Weather/icons';
import { getTemperaturePrefix } from '../Weather/utils';
import { getAbsoluteDate } from '../Engine/components/FastSearch/utils';
var planeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M16 22H14L9 14H3.5C2.67 14 2 13.33 2 12.5C2 11.67 2.67 11 3.5 11H9L14 3H16L13.5 11L19 11L20.5 9H22L21 12.5L22 16H20.5L19 14L13.5 14L16 22Z", fill: "currentColor" })));
initI18n('Segment');
var MobileSegment = function (_a) {
    var _b;
    var _c, _d;
    var _e = _a.serviceClass, serviceClass = _e === void 0 ? FlightClass.Economy : _e, segment = _a.segment;
    var css = useTheme('MobileSegment').MobileSegment;
    var t = useTranslation('Segment').t;
    var fareFamily = (_c = segment.fareFamilies[0]) === null || _c === void 0 ? void 0 : _c.fareFamily;
    return (React.createElement("div", { className: cn(css.wrapper, (_b = {},
            _b[css.wrapper_business] = serviceClass === FlightClass.Business,
            _b)) },
        React.createElement("div", { className: css.flightInfo },
            React.createElement("div", { className: css.flightInfo__iatas },
                React.createElement("span", null, segment.departure.airport.iata),
                React.createElement("span", { className: css.planeIcon }, planeIcon),
                React.createElement("span", null, segment.arrival.airport.iata)),
            React.createElement("div", { className: css.flightInfo__cities },
                React.createElement("span", null,
                    segment.departure.airport.city.name,
                    ",",
                    React.createElement("br", null),
                    " ",
                    segment.departure.airport.title),
                React.createElement("span", null,
                    segment.arrival.airport.city.name,
                    ",",
                    React.createElement("br", null),
                    " ",
                    segment.arrival.airport.title)),
            React.createElement("div", { className: css.flightInfo__dates },
                React.createElement("span", null, format(segment.departure.date, new Date(segment.departure.date).getFullYear() - new Date().getFullYear() < 1
                    ? 'dd MMMM'
                    : 'dd MMM, yyyy')),
                React.createElement("span", null, format(segment.arrival.date, new Date(segment.arrival.date).getFullYear() - new Date().getFullYear() < 1
                    ? 'dd MMMM'
                    : 'dd MMM, yyyy'))),
            React.createElement("div", { className: css.flightInfo__time },
                React.createElement("span", null, segment.departure.time.substr(0, 5)),
                React.createElement("span", null, segment.arrival.time.substr(0, 5))),
            (segment.departure.weather || segment.arrival.weather) && (React.createElement("div", { className: css.flightInfo__weather },
                React.createElement("span", { className: css.weather }, segment.departure.weather && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: css.weather__icon }, weatherIcons[segment.departure.weather && segment.departure.weather.type]),
                    getTemperaturePrefix(segment.departure.weather.temperature),
                    Math.abs(Math.round(segment.departure.weather.temperature)),
                    "\u00B0",
                    segment.departure.weather.measurement === TemperatureUnits.Fahrenheit && 'F'))),
                React.createElement("span", { className: css.weather }, segment.arrival.weather && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: css.weather__icon }, weatherIcons[segment.arrival.weather && segment.arrival.weather.type]),
                    getTemperaturePrefix(segment.arrival.weather.temperature),
                    Math.abs(Math.round(segment.arrival.weather.temperature)),
                    "\u00B0",
                    segment.arrival.weather.measurement === TemperatureUnits.Fahrenheit && 'F')))))),
        React.createElement("div", { className: css.fareGroup },
            React.createElement("div", { className: css.fareGroup__img, style: {
                    backgroundImage: "url(".concat((_d = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _d === void 0 ? void 0 : _d.icon, ")")
                } }),
            React.createElement("div", { className: css.fareGroup__info },
                React.createElement("span", null, fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.title),
                React.createElement("span", { className: css.flightNumber },
                    t('Flight'),
                    ": ",
                    segment.operatingAirline.iata,
                    "-",
                    segment.flightNumber))),
        React.createElement("div", { className: css.arrivalInfo },
            segment.departure.terminal && (React.createElement("div", { className: css.arrivalInfo__place }, segment.departure.terminal && (React.createElement("div", { className: css.arrivalInfo__item },
                React.createElement("span", { className: css.arrivalInfo__title }, t('Terminal')),
                React.createElement("span", null, segment.departure.terminal))))),
            React.createElement("div", { className: css.arrivalInfo__item },
                React.createElement("span", { className: css.arrivalInfo__title }, t('Boarding')),
                React.createElement("span", null,
                    format(getAbsoluteDate(segment.checkinInfo.boardingOpenTime), 'HH:mm'),
                    " \u2014",
                    ' ',
                    format(getAbsoluteDate(segment.checkinInfo.boardingCloseTime), 'HH:mm'))))));
};
export default MobileSegment;
