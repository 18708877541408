import React from 'react';
import { LogoIcon } from '../../Icons';
import { useTheme } from '../../../theme';
import Link from '../../../Link';
var Logo = function (props) {
    var css = useTheme('Header').Logo;
    return (React.createElement("div", { className: css.root },
        React.createElement(Link, { action: props.onClick }, LogoIcon)));
};
export default Logo;
