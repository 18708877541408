import React from 'react';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { format } from '../../../../../utils';
import { Money } from '../../../../../Money';
var Transaction = function (_a) {
    var transaction = _a.transaction, dateFormat = _a.dateFormat;
    var theme = useTheme('LeoOrderCard').OrderTransactions;
    var t = useTranslation('OrderCard').t;
    return (React.createElement(React.Fragment, null,
        React.createElement("tr", { className: theme.table__nextDate },
            React.createElement("td", { className: theme.column__date }, transaction.paymentDate ? format(parseISO(transaction.paymentDate), dateFormat) : ''),
            React.createElement("td", { className: theme.column__product }, "".concat(t('Transaction id'), " ").concat(transaction.id)),
            React.createElement("td", { className: theme.column__payment }, transaction.title),
            React.createElement("td", { className: theme.column__status }, t(transaction.paymentStatus)),
            React.createElement("td", null),
            React.createElement("td", { className: theme.column__totalSum },
                React.createElement(Money, { money: transaction.paidMoney, showCurrency: true }))),
        transaction.products.map(function (product) {
            return (React.createElement("tr", { key: "".concat(transaction.id).concat(product.price).concat(product.type) },
                React.createElement("td", { className: theme.column__date }),
                React.createElement("td", { className: theme.column__product }, product.name || t(product.type)),
                React.createElement("td", { className: theme.column__payment }),
                React.createElement("td", { className: theme.column__status }),
                React.createElement("td", { className: theme.column__sum },
                    React.createElement(Money, { money: product.price, showCurrency: true })),
                React.createElement("td", null)));
        })));
};
export default Transaction;
