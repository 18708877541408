import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import ServiceCard from '../../ServicesCard/ServicesCard';
import { ServiceType } from '../../../Checkout/components/Checkout/OrderPaidServiceCards/types';
import { Steps } from '../../ServicesCardVoid/types';
var ExchangeServiceCard = function (_a) {
    var _b;
    var type = _a.type, isLoading = _a.isLoading, gdsServicesRefund = _a.gdsServicesRefund;
    var productsServicesByTypeMap = React.useMemo(function () {
        var _a, _b;
        var productsServicesByTypeMap = new Map();
        (_b = (_a = gdsServicesRefund === null || gdsServicesRefund === void 0 ? void 0 : gdsServicesRefund.GetGdsServicesRefundInfo) === null || _a === void 0 ? void 0 : _a.gdsServicesRefundInfo) === null || _b === void 0 ? void 0 : _b.forEach(function (service) {
            var _a, _b, _c, _d;
            var type = service.service.type;
            var typeServices = (_b = (_a = productsServicesByTypeMap.get(type)) === null || _a === void 0 ? void 0 : _a.servicesIds) !== null && _b !== void 0 ? _b : [];
            var typeProducts = (_d = (_c = productsServicesByTypeMap.get(type)) === null || _c === void 0 ? void 0 : _c.productsIds) !== null && _d !== void 0 ? _d : [];
            typeServices.push(service.service.id);
            typeProducts.push.apply(typeProducts, __spreadArray([], __read(service.gdsServiceProductsRefundInfo.map(function (product) { return product.product.id; })), false));
            productsServicesByTypeMap.set(type, {
                productsIds: typeProducts,
                servicesIds: typeServices
            });
        });
        return productsServicesByTypeMap;
    }, [(_b = gdsServicesRefund === null || gdsServicesRefund === void 0 ? void 0 : gdsServicesRefund.GetGdsServicesRefundInfo) === null || _b === void 0 ? void 0 : _b.gdsServicesRefundInfo]);
    var propsForCard = {
        fullWidth: true,
        exchangeVoidMode: true,
        mutators: [],
        values: {},
        step: Steps.Confirm,
        isLoading: isLoading,
        calculation: gdsServicesRefund === null || gdsServicesRefund === void 0 ? void 0 : gdsServicesRefund.GetGdsServicesRefundInfo,
        setProductsIdsByEmd: function () { },
        isExchangePage: true
    };
    var productsServices = productsServicesByTypeMap.get(type);
    return (React.createElement(ServiceCard, __assign({ type: type === OrderAdditionalServiceGdsServiceServiceType.Seat ? ServiceType.Seats : ServiceType[type], itemsToRefundVoid: {
            productsIds: productsServices.productsIds,
            servicesIds: productsServices.servicesIds
        } }, propsForCard)));
};
export default ExchangeServiceCard;
