import { __assign } from "tslib";
import { AviaPassengerType } from '../__generated__/graphql';
import { useMemo } from 'react';
import { format, removeDotsFromDate } from '../utils';
import { useToggleable } from '../hooks';
var isOpenJaw = function (segment, nextSegment) {
    if (!nextSegment) {
        return false;
    }
    return segment.arrival.iata !== nextSegment.departure.iata;
};
var concatIataItems = function (iataList) {
    return iataList.reduce(function (concatedList, iata, index) {
        var _a;
        if ((_a = iataList[index - 1]) === null || _a === void 0 ? void 0 : _a.isOpenJaw) {
            return "".concat(concatedList, ", ").concat(iata.value);
        }
        return index === 0 ? iata.value : "".concat(concatedList, " \u2013 ").concat(iata.value);
    }, '');
};
export var useHeader = function () {
    var _a = useToggleable(false), isOpen = _a.isOpen, open = _a.open, close = _a.close, toggle = _a.toggle;
    return {
        isOpen: isOpen,
        open: open,
        close: close,
        toggle: toggle
    };
};
export var useSearchDataSegments = function (segments) {
    return useMemo(function () {
        var iataList = [];
        if (segments.length === 1) {
            iataList.push({ value: segments[0].departure.iata });
            iataList.push({ value: segments[0].arrival.iata });
        }
        else {
            segments.forEach(function (segment, index) {
                iataList.push({ value: segment.departure.iata });
                if (isOpenJaw(segment, segments[index + 1])) {
                    iataList.push({ value: segment.arrival.iata, isOpenJaw: true });
                }
            });
            var lastSegment = segments[segments.length - 1];
            iataList.push({ value: lastSegment.arrival.iata });
        }
        return concatIataItems(iataList);
    }, [segments]);
};
export var useSearchDataDate = function (segments) {
    return useMemo(function () {
        var now = new Date(Date.now());
        var from = new Date(segments[0].date);
        var to = new Date(segments[segments.length - 1].date);
        var innerFormat = function (date, dateFormat, options) {
            return removeDotsFromDate(format(date, dateFormat, options));
        };
        if (segments.length === 1) {
            if (now.getFullYear() === from.getFullYear()) {
                return innerFormat(from, 'dd MMM');
            }
            else {
                return innerFormat(from, 'dd MMM yyyy');
            }
        }
        else {
            if (from.getFullYear() !== to.getFullYear()) {
                return "".concat(innerFormat(from, 'dd MMM yyyy'), " \u2013 ").concat(innerFormat(to, 'dd MMM yyyy'));
            }
            else if (from.getMonth() !== to.getMonth()) {
                if (now.getFullYear() === from.getFullYear()) {
                    return "".concat(innerFormat(from, 'dd MMM'), " \u2013 ").concat(innerFormat(to, 'dd MMM'));
                }
                else {
                    return "".concat(innerFormat(from, 'dd MMM'), " \u2013 ").concat(innerFormat(to, 'dd MMM yyyy'));
                }
            }
            else {
                if (now.getFullYear() === from.getFullYear()) {
                    return "".concat(innerFormat(from, 'dd'), " \u2013 ").concat(innerFormat(to, 'dd MMM'));
                }
                else {
                    return "".concat(innerFormat(from, 'dd'), " \u2013 ").concat(innerFormat(to, 'dd MMM yyyy'));
                }
            }
        }
    }, [segments]);
};
var calculateSumByTypes = function (passengers) {
    var _a;
    var initValue = (_a = {},
        _a[AviaPassengerType.ADT] = 0,
        _a[AviaPassengerType.CLD] = 0,
        _a[AviaPassengerType.INF] = 0,
        _a[AviaPassengerType.INS] = 0,
        _a);
    return passengers.reduce(function (sum, passenger) {
        var _a;
        return __assign(__assign({}, sum), (_a = {}, _a[passenger.passengerType] = sum[passenger.passengerType] + (passenger === null || passenger === void 0 ? void 0 : passenger.count) || 0, _a));
    }, initValue);
};
export var useSearchDataPassengers = function (passengers) {
    return useMemo(function () {
        var sumByTypes = calculateSumByTypes(passengers);
        var adult = sumByTypes[AviaPassengerType.ADT];
        var child = sumByTypes[AviaPassengerType.CLD];
        var infantWithoutSeat = sumByTypes[AviaPassengerType.INF];
        var infantWithSeat = sumByTypes[AviaPassengerType.INS];
        return [adult, child, infantWithoutSeat + infantWithSeat].filter(function (el) { return !!el; }).join(' + ');
    }, [passengers]);
};
