import { __assign, __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useTheme } from '../theme';
var successIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M9.00039 16.1996L4.80039 11.9996L3.40039 13.3996L9.00039 18.9996L21.0004 6.99961L19.6004 5.59961L9.00039 16.1996Z", fill: "white" })));
export var Button = function (props) {
    var _a, _b;
    var _c = props.variant, variant = _c === void 0 ? 'common' : _c, _d = props.size, size = _d === void 0 ? 'common' : _d, _e = props.isLoading, isLoading = _e === void 0 ? false : _e, _f = props.isSuccess, isSuccess = _f === void 0 ? false : _f, children = props.children, className = props.className, disabled = props.disabled, rest = __rest(props, ["variant", "size", "isLoading", "isSuccess", "children", "className", "disabled"]);
    var css = useTheme('Button').Button;
    if (isLoading || isSuccess) {
        return (React.createElement("div", { className: cn((_a = {},
                _a[css.loader] = isLoading,
                _a[css.loader_outline] = variant === 'outline',
                _a[css.success] = isSuccess,
                _a), className) },
            isLoading && React.createElement("div", { className: css.loader__spinner }),
            isSuccess && successIcon));
    }
    return (React.createElement(ButtonBase, __assign({ className: cn(css.button, (_b = {},
            _b[css.button_outline] = variant === 'outline',
            _b[css.button_flat] = variant === 'flat',
            _b[css.button_text] = variant === 'text',
            _b[css.button_small] = size === 'small',
            _b[css.button_large] = size === 'large',
            _b[css.button_disabled] = disabled,
            _b), className), disabled: disabled }, rest), children));
};
Button.displayName = 'Button';
export default Button;
