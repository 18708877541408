import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { HandLuggageExcess, LargeBaggageExcessIcon, LargeBaggageIcon, LargeCarryOnIcon, MiddleBaggageExcessIcon, MiddleBaggageIcon, SmallBaggageExcessIcon, SmallBaggageIcon, SmallCarryOnIcon } from '../../Icons';
import { useBaggageSize } from '../../hooks';
import { BaggageSize } from '../../types';
import { BaggageType } from '../../../../__generated__/globalTypes';
import { useTheme } from '../../../../theme';
var defaultIcons = new Map([
    [
        BaggageType.CheckedBaggage,
        new Map([
            [BaggageSize.small, SmallBaggageIcon],
            [BaggageSize.middle, MiddleBaggageIcon],
            [BaggageSize.large, LargeBaggageIcon]
        ])
    ],
    [
        BaggageType.CarryOn,
        new Map([
            [BaggageSize.small, SmallCarryOnIcon],
            [BaggageSize.large, LargeCarryOnIcon]
        ])
    ],
    [
        BaggageType.HandLuggageExcess,
        new Map([
            [BaggageSize.small, SmallCarryOnIcon],
            [BaggageSize.large, HandLuggageExcess]
        ])
    ],
    [
        BaggageType.BaggageExcess,
        new Map([
            [BaggageSize.small, SmallBaggageExcessIcon],
            [BaggageSize.middle, MiddleBaggageExcessIcon],
            [BaggageSize.large, LargeBaggageExcessIcon]
        ])
    ]
]);
var BaggageIcon = function (_a) {
    var _b;
    var _c;
    var baggage = _a.baggage, className = _a.className, realSize = _a.realSize, onlyIcon = _a.onlyIcon, _d = _a.iconsMap, iconsMap = _d === void 0 ? defaultIcons : _d;
    var t = useTranslation().t;
    var css = useTheme('Baggage').BaggageIcon;
    var size = useBaggageSize(baggage);
    var isCarryOn = [BaggageType.CarryOn, BaggageType.HandLuggageExcess].includes(baggage.type);
    return (React.createElement("div", { className: cn(css.root, className, (_b = {},
            _b[css.checked_baggage] = baggage.type === BaggageType.CheckedBaggage,
            _b[css.carry_on] = isCarryOn,
            _b[css.small] = size === BaggageSize.small,
            _b[css.middle] = size === BaggageSize.middle,
            _b[css.large] = size === BaggageSize.large,
            _b[css.real_size] = realSize,
            _b[css === null || css === void 0 ? void 0 : css[baggage.type]] = css === null || css === void 0 ? void 0 : css[baggage.type],
            _b)) },
        React.createElement("div", { className: css.wrapper }, (_c = iconsMap.get(baggage.type)) === null || _c === void 0 ? void 0 :
            _c.get(size),
            !onlyIcon && (React.createElement("div", { className: cn(css.size) },
                React.createElement("div", { className: css.value }, baggage.value.amount),
                React.createElement("div", { className: css.measurement }, t(baggage.value.measurement)))))));
};
export default BaggageIcon;
