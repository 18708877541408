import { __assign } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import { AnySymbolField, NumberField } from '../../Fields/Fields';
import { useFormState } from 'react-final-form';
var ExchangeCoupons = function () {
    var _a;
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    var values = useFormState().values;
    var renderCoupons = useCallback(function () {
        var _a;
        return (_a = Object.entries(values === null || values === void 0 ? void 0 : values.selectedCoupons)) === null || _a === void 0 ? void 0 : _a.map(function (coupon, index) {
            return (React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(AnySymbolField, { name: "selectedCoupons[".concat(index, "].coupon"), showErrorInTooltip: true })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(AnySymbolField, { name: "selectedCoupons[".concat(index, "].date"), showErrorInTooltip: true })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(AnySymbolField, { name: "selectedCoupons[".concat(index, "].flight"), showErrorInTooltip: true })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(AnySymbolField, { name: "selectedCoupons[".concat(index, "].fareBasis"), readOnly: true, showErrorInTooltip: true })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(AnySymbolField, { name: "selectedCoupons[".concat(index, "].nvb"), showErrorInTooltip: true })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(AnySymbolField, { name: "selectedCoupons[".concat(index, "].nva"), showErrorInTooltip: true })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(NumberField, { name: "selectedCoupons[".concat(index, "].baggage.count"), showErrorInTooltip: true })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(Field, { name: "selectedCoupons[".concat(index, "].baggage.unit"), render: function (_a) {
                                    var input = _a.input;
                                    return (React.createElement(FormControl, { className: theme.formSelect_table },
                                        React.createElement(Select, __assign({}, input, { disableUnderline: true, classes: {
                                                root: cn(theme.formSelect_table, theme.formSelect)
                                            } }),
                                            React.createElement(MenuItem, { key: 'kg', value: 'kg' }, t('kg')),
                                            React.createElement(MenuItem, { key: 'pcs', value: 'pcs' }, t('pcs')))));
                                } })))),
                React.createElement("td", null,
                    React.createElement("div", { className: cn(theme.table__cell, theme.table__cell_edit) },
                        React.createElement("label", { className: theme.formControl },
                            React.createElement(Field, { name: "selectedCoupons[".concat(index, "].seat"), defaultValue: 'ok', render: function (_a) {
                                    var input = _a.input;
                                    return (React.createElement(FormControl, { className: theme.formSelect_table },
                                        React.createElement(Select, __assign({}, input, { disableUnderline: true, classes: {
                                                root: cn(theme.formSelect_table, theme.formSelect)
                                            } }),
                                            React.createElement(MenuItem, { key: 'ok', value: 'ok' }, t('OK')),
                                            React.createElement(MenuItem, { key: 'no', value: 'no' }, t('NO')))));
                                } }))))));
        });
    }, [(_a = Object.entries(values === null || values === void 0 ? void 0 : values.selectedCoupons)) === null || _a === void 0 ? void 0 : _a.length]);
    return (React.createElement("div", null,
        React.createElement("table", { className: cn(theme.table, theme.table_layout_fix, theme.table_border_full, theme.table_border_blue) },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null,
                        React.createElement("div", { className: theme.table__cell }, t('Coupons'))),
                    React.createElement("th", null,
                        React.createElement("div", { className: theme.table__cell }, t('Date'))),
                    React.createElement("th", null,
                        React.createElement("div", { className: theme.table__cell }, t('Flight'))),
                    React.createElement("th", null,
                        React.createElement("div", { className: theme.table__cell }, t('Fare basis'))),
                    React.createElement("th", null,
                        React.createElement("div", { className: theme.table__cell }, t('NVB'))),
                    React.createElement("th", null,
                        React.createElement("div", { className: theme.table__cell }, t('NVA'))),
                    React.createElement("th", { colSpan: 2 },
                        React.createElement("div", { className: theme.table__cell }, t('Baggage'))),
                    React.createElement("th", null,
                        React.createElement("div", { className: theme.table__cell }, t('Seat'))))),
            React.createElement("tbody", null, renderCoupons()))));
};
export default ExchangeCoupons;
