import { __read } from "tslib";
import * as React from 'react';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import cn from 'classnames';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import Coupons from '../Components/Coupons/Coupons';
import Taxes from '../Components/Taxes/Taxes';
import { downIcon, reloadIcon } from '../icons';
import ExchangeFareCalculation from '../Components/Exchange/ExchangeFareCalculation/ExchangeFareCalculation';
import SumCalculation from '../Components/SumCalculation/SumCalculation';
import Notice from '../../../Modules/Exchange/components/Exchange/Notice/Notice';
import ExchangeCoupons from '../Components/Exchange/ExchangeCoupons/ExchangeCoupons';
import EditTaxes from '../Components/EditTaxes/EditTaxes';
import ExchangeNewFareCalculation from '../Components/Exchange/ExchangeNewFareCalculation/ExchangeNewFareCalculation';
import SubmitControls from '../Components/SubmitControls/SubmitControls';
import { agencyTaxes, airlineTaxes, exchangeCalculation1, exchangeCalculation2 } from '../__fakeData__';
import { addDefaultRefProperty, addDefaultSeatProperty, mutators } from '../utils';
initI18n('ManualActions');
var Exchange = function () {
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    var _a = __read(useState([]), 2), selectedCoupons = _a[0], setSelectedCoupons = _a[1];
    var onSumbit = function (values) {
        console.log('submit');
        console.log(values);
    };
    var initialValues = useMemo(function () {
        return {
            airlineTaxes: selectedCoupons.length ? addDefaultRefProperty(airlineTaxes) : [],
            selectedCoupons: addDefaultSeatProperty(selectedCoupons),
            agencyTaxes: [],
            fare: '270',
            currency: 'EUR',
            equivFare: '5400',
            equivFareCodes: 'UAH | RUB | RUB',
            ticketingFee: '80',
            specialAirlinesADC: '855',
            agencyCommission: {
                type: '%',
                value: 0
            },
            exchangePenalty: '200',
            tourCode: '77777',
            totalAmount: '',
            collectPenalty: false,
            confirm: false
        };
    }, [selectedCoupons]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.sectionBlock },
            React.createElement("div", { className: theme.sectionBlock__header },
                React.createElement("div", { className: theme.sectionBlock__icon }, downIcon),
                React.createElement("div", { className: theme.sectionBlock__headline }, t('Previous itinerary'))),
            React.createElement(Coupons, { setSelectedCoupons: setSelectedCoupons, selectedCoupons: selectedCoupons }),
            React.createElement(Taxes, { airlineTaxes: airlineTaxes, agencyTaxes: agencyTaxes }),
            React.createElement(ExchangeFareCalculation, null),
            React.createElement(SumCalculation, { calculcations: exchangeCalculation1 })),
        React.createElement("div", { className: theme.warning },
            React.createElement(Notice, { type: "warning", header: t('Warning'), text: t("This order includes EMD's associated with ticket, check their status before confirming Exchange! Services may not be available on new flights.") })),
        React.createElement("div", { className: theme.sectionBlock },
            React.createElement("div", { className: theme.sectionBlock__header },
                React.createElement("div", { className: cn(theme.sectionBlock__icon, theme.sectionBlock__icon_bg_green) }, reloadIcon),
                React.createElement("div", { className: theme.sectionBlock__headline }, t('New itinerary'))),
            React.createElement(Form, { onSubmit: onSumbit, initialValues: initialValues, mutators: mutators }, function (_a) {
                var handleSubmit = _a.handleSubmit, mutators = _a.form.mutators;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(ExchangeCoupons, null),
                    React.createElement(EditTaxes, { mutators: mutators }),
                    React.createElement(ExchangeNewFareCalculation, null),
                    React.createElement(SumCalculation, { calculcations: exchangeCalculation2 }),
                    React.createElement(SubmitControls, { submitText: t('Exchange'), summaryLabel: t('Residual amount or ADC') })));
            }))));
};
export default Exchange;
