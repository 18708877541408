import { __assign } from "tslib";
import * as React from 'react';
import { useField } from 'react-final-form';
import Input from '../../../../../Input';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
var Fields = function (props) {
    var t = useTranslation('Loyalty').t;
    var theme = useTheme('Loyalty').LoyaltyConnect;
    var numberField = useField('loyaltyNumber', {
        type: 'text',
        validate: function (value) {
            if (!value || (value && value.length < 4)) {
                return t('Please enter your card number');
            }
            return undefined;
        }
    });
    var passwordField = useField('loyaltyPassword', {
        type: 'password',
        validate: function (value) {
            if (!value || (value && value.length < 4)) {
                return t('Enter password');
            }
            return undefined;
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.group__field },
            React.createElement(Input, { label: t('Card number'), absoluteHintPosition: true, value: numberField.input.value, TextFieldProps: __assign(__assign({}, numberField.input), { helperText: ((numberField.meta.modified && numberField.meta.touched) ||
                        numberField.meta.submitFailed) &&
                        numberField.meta.error, error: ((numberField.meta.modified && numberField.meta.touched) ||
                        numberField.meta.submitFailed) &&
                        !!numberField.meta.error }) })),
        React.createElement("div", { className: theme.group__field },
            React.createElement(Input, { label: t('Password'), value: passwordField.input.value, absoluteHintPosition: true, TextFieldProps: __assign(__assign({}, passwordField.input), { helperText: ((passwordField.meta.error && passwordField.meta.touched) ||
                        passwordField.meta.submitFailed) &&
                        (passwordField.meta.error || passwordField.meta.submitError), error: ((passwordField.meta.error && passwordField.meta.touched) ||
                        passwordField.meta.submitFailed) &&
                        (!!passwordField.meta.error || !!passwordField.meta.submitError) }) }))));
};
export default Fields;
