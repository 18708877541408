import * as React from 'react';
import Amenities from '../../../Amenities';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import FlightInfo from './FlightInfo/FlightInfo';
import RouteInfo from './RouteInfo/RouteInfo';
import MediaQuery from 'react-responsive';
import { TABLET_MIN_WIDTH } from '../../../utils';
import StopsInfo from './StopsInfo/StopsInfo';
var Segment = function (_a) {
    var _b;
    var segmentWithTransfer = _a.segment, first = _a.first, last = _a.last, stops = _a.stops, totalDuration = _a.totalDuration, nextDay = _a.nextDay, renderFlightInfo = _a.renderFlightInfo, isShowPlaneInfo = _a.isShowPlaneInfo;
    var segment = segmentWithTransfer.segment, transferDuration = segmentWithTransfer.transferDuration;
    var theme = useTheme('DesktopFlightRoute').SegmentStyles;
    var renderPlane = function () {
        var _a;
        if (!segment.aircraft) {
            return null;
        }
        return ((_a = segment.aircraft) === null || _a === void 0 ? void 0 : _a.icon) ? (React.createElement("div", { className: theme.plane },
            React.createElement("img", { src: segment.aircraft.icon }))) : null;
    };
    var renderPlaneInfo = function () {
        var _a, _b;
        return (React.createElement("div", { className: theme.planeInfo },
            ((_a = segment.aircraft) === null || _a === void 0 ? void 0 : _a.name) && React.createElement("div", null, segment.aircraft.name),
            ((_b = segment === null || segment === void 0 ? void 0 : segment.flightInfo) === null || _b === void 0 ? void 0 : _b.amenities) && (React.createElement("div", null,
                React.createElement(Amenities, { amenities: segment.flightInfo.amenities })))));
    };
    return (React.createElement("div", { className: cn(theme.segment, (_b = {},
            _b[theme.segment_additional] = !first,
            _b)) },
        renderFlightInfo ? (renderFlightInfo({ segment: segment, nextDay: nextDay, singleFlight: false })) : (React.createElement(FlightInfo, { segment: segment, nextDay: nextDay })),
        React.createElement("div", { className: theme.route__info },
            React.createElement(RouteInfo, { segment: segment, first: first, last: last }),
            (transferDuration || stops.length > 0) && (React.createElement(StopsInfo, { stops: stops, stopCityName: segment.arrival.airport.city.name, transferDuration: transferDuration })),
            last && React.createElement("div", { className: theme.totalDuration }, totalDuration)),
        React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH }, segment.aircraft && (React.createElement(React.Fragment, null,
            renderPlane(),
            isShowPlaneInfo && renderPlaneInfo())))));
};
export default Segment;
