import { __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import SummaryFlight from '../../../../SummaryFlight/SummaryFlight';
import { OrderStatus } from '../../../../__generated__/globalTypes';
import { Button } from '../../../../index';
import { useTheme } from '../../../../theme';
import { expandIcon } from '../../../Icons';
import { planeIcon } from '../../../../Segment/Icons';
var ToggleableFlights = function (_a) {
    var _b;
    var order = _a.order, backToResults = _a.backToResults, noBottomMargin = _a.noBottomMargin;
    var _c = __read(useState(false), 2), isOpen = _c[0], setIsOpen = _c[1];
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').ToggleableFlights;
    var isBookedOrConfirmed = order.status === OrderStatus.Booked || order.status === OrderStatus.Confirmed;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.wrapper__button },
            React.createElement(Button, { onClick: function () { return setIsOpen(!isOpen); }, className: css.button },
                React.createElement("span", { className: css.planeIcon }, planeIcon),
                t('Selected options'),
                React.createElement("span", { className: cn(css.button__icon, (_b = {},
                        _b[css.button__icon_active] = isOpen,
                        _b)) }, expandIcon))),
        React.createElement(Collapse, { mountOnEnter: true, in: isOpen, className: css.content },
            React.createElement("div", { className: css.flights }, order.flight.segmentGroups.map(function (group, index) {
                var _a;
                return (React.createElement(SummaryFlight, { key: index, orderId: order.id, flight: {
                        id: order.flight.id,
                        segments: group.segments.map(function (segment) { return order.flight.segments[segment.id]; })
                    }, price: group.price, fareFamily: group.fareFamily, className: cn(css.flight, (_a = {},
                        _a[css.flight_noMargin] = order.status !== OrderStatus.New || noBottomMargin,
                        _a)), onChange: isBookedOrConfirmed ? null : backToResults, isReturnFlight: index === 1 }));
            })))));
};
export default ToggleableFlights;
