import { __assign, __awaiter, __generator } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import { StepType } from '../../../../types';
import { setNextStep } from '../../../../store/checkout/actions';
import Toolbar from '../../../../../Toolbar';
import { useTheme } from '../../../../../theme';
import { isFormLocked } from '../../../../store/formIsLocked/selectors';
import MobileToolbar from '../../../../../MobileToolbar';
import { getServicesFallbackURL, getTotalInsurancePrice, isBooked, isBookedOrConfirmed, isCancelled, isConfirmedOrCancelled, getBonusForFlight, isNew, getPaymentIsRequired, getIsContactsInfoIsEmpty, getOrderId, getIsTravellersDataFilled, getPriceInMiles } from '../../../../store/order/selectors';
import { Money as MoneyComponent } from '../../../../../index';
import { useUpsaleWarning } from '../../Upsale/hooks';
import Warning from '../../Upsale/Warning/Warning';
import { getTotalPrice, getTotalPriceToPay } from '../../../../store/orderPrice/selectors';
import PassengersToolbarCheckbox from './PassengersToolbarCheckbox';
import { useCustomerContactsForm } from '../../../../../CustomerContactsForm/hooks';
import { useOrder } from '../../../../../__queries__/Order';
import { fillOrder } from '../../../../store/order/actions';
import CustomerContactsModal from '../../../../../CustomerContactsForm/Modal/CustomerContactsModal';
import { useUpdateTravellers } from '../../../../../FareLock/hooks';
import { getPassengerState } from '../../../../store/passengers/selectors';
import { OverrideComponent } from '../../../../../renderProps';
import { useIsTerminal } from '../../../../../hooks';
var PassengersToolbar = function (_a) {
    var formRef = _a.formRef, isNew = _a.isNew, formIsLocked = _a.formIsLocked, isConfirmedOrCancelled = _a.isConfirmedOrCancelled, isBookedOrConfirmed = _a.isBookedOrConfirmed, isBooked = _a.isBooked, totalInsurancePrice = _a.totalInsurancePrice, totalPrice = _a.totalPrice, currentStep = _a.currentStep, setNextStep = _a.setNextStep, history = _a.history;
    var t = useTranslation('Checkout').t;
    var checkoutTheme = useTheme('Checkout').CheckoutStyles;
    var totalPriceToPay = useSelector(getTotalPriceToPay);
    var servicesRedirectURL = useSelector(getServicesFallbackURL);
    var _b = useUpsaleWarning(), toggleWarningIfOrderConfirmed = _b.toggleWarningIfOrderConfirmed, showWarning = _b.showWarning, closeWarning = _b.closeWarning;
    var isOrderCancelled = useSelector(isCancelled);
    var bonusForFlight = useSelector(getBonusForFlight);
    var priceInMilesForFlight = useSelector(getPriceInMiles);
    var isPaymentRequired = useSelector(getPaymentIsRequired);
    var isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    var isContactsInfoEmpty = useSelector(getIsContactsInfoIsEmpty);
    var passengersValues = useSelector(getPassengerState);
    var orderId = useSelector(getOrderId);
    var handleSubmit;
    var updateTravellers;
    try {
        updateTravellers = useUpdateTravellers();
        handleSubmit = updateTravellers.handleSubmit;
    }
    catch (e) { }
    var isToolbarButtonDisabled = false;
    if (typeof updateTravellers !== 'undefined' && !updateTravellers.valid) {
        isToolbarButtonDisabled = true;
    }
    if (isTravellersDataFilled) {
        isToolbarButtonDisabled = false;
    }
    var isTerminal = useIsTerminal();
    var dispatch = useDispatch();
    var getOrder = useOrder(orderId, { skip: true }).refetch;
    var _c = useCustomerContactsForm(), saveClientInfo = _c.saveClientInfo, closeContactsModal = _c.closeContactsModal, openContactsModal = _c.openContactsModal, isContactsModalOpen = _c.isContactsModalOpen, isClientInfoSaving = _c.loading;
    var goToPayment = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isToolbarButtonDisabled) {
                        formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
                        return [2 /*return*/];
                    }
                    if (!handleSubmit) return [3 /*break*/, 2];
                    return [4 /*yield*/, handleSubmit(passengersValues.map(function (traveller) {
                            var _a;
                            return ({
                                id: traveller.id,
                                values: (_a = traveller.values) === null || _a === void 0 ? void 0 : _a.map(function (value) { return ({
                                    type: value.type,
                                    name: value.name,
                                    value: value.value
                                }); })
                            });
                        }))];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    if (showWarning) {
                        return [2 /*return*/, toggleWarningIfOrderConfirmed()];
                    }
                    if (isPaymentRequired && isContactsInfoEmpty) {
                        openContactsModal();
                        return [2 /*return*/];
                    }
                    setNextStep();
                    return [2 /*return*/];
            }
        });
    }); }, [totalPriceToPay, passengersValues]);
    var saveClientInfoAndUpdateOrder = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var getOrderResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveClientInfo({ variables: __assign(__assign({}, data), { orderId: orderId }) })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, getOrder({ id: orderId })];
                case 2:
                    getOrderResult = (_a.sent()).data;
                    if (getOrderResult === null || getOrderResult === void 0 ? void 0 : getOrderResult.Order) {
                        dispatch(fillOrder(getOrderResult.Order));
                    }
                    closeContactsModal();
                    setNextStep();
                    return [2 /*return*/];
            }
        });
    }); };
    var goBack = useCallback(function () {
        history.goBack();
    }, [history]);
    var showToolbar = !formIsLocked && currentStep.type !== StepType.Payment && !isOrderCancelled && totalPriceToPay.amount > 0;
    return (React.createElement(React.Fragment, null, showToolbar && (React.createElement(React.Fragment, null,
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Toolbar, { isLoading: updateTravellers === null || updateTravellers === void 0 ? void 0 : updateTravellers.loading, actionsRight: isBooked, hideNextButton: !totalPriceToPay.amount, totalPrice: totalPriceToPay, priceInMiles: priceInMilesForFlight, onButtonClick: isBookedOrConfirmed ? goToPayment : null, miles: !isConfirmedOrCancelled && bonusForFlight, onBackButtonClick: isTerminal ? goBack : null, additional: React.createElement(React.Fragment, null,
                    isBooked && totalInsurancePrice && totalInsurancePrice.amount && (React.createElement("div", { className: checkoutTheme.gdsTotalPrice },
                        t('Insurance'),
                        ": ",
                        React.createElement(MoneyComponent, { money: totalInsurancePrice }))),
                    isNew && (React.createElement(OverrideComponent, { componentProps: {}, component: { PassengersToolbarCheckbox: PassengersToolbarCheckbox } }))) })),
        React.createElement(CustomerContactsModal, { onContactsSubmit: saveClientInfoAndUpdateOrder, isLoading: isClientInfoSaving, open: isContactsModalOpen, onClose: closeContactsModal }),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            isNew && React.createElement(OverrideComponent, { componentProps: {}, component: { PassengersToolbarCheckbox: PassengersToolbarCheckbox } }),
            React.createElement(MobileToolbar, { onBack: goBack, onContinue: isBookedOrConfirmed && totalPriceToPay.amount
                    ? goToPayment
                    : isBookedOrConfirmed
                        ? undefined
                        : null }))))));
};
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { isNew: isNew(state), isBookedOrConfirmed: isBookedOrConfirmed(state), isConfirmedOrCancelled: isConfirmedOrCancelled(state), isBooked: isBooked(state), formIsLocked: isFormLocked(state), totalInsurancePrice: getTotalInsurancePrice(state), totalPrice: getTotalPrice(state) })); };
var mapDispatchToProps = {
    setNextStep: setNextStep
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PassengersToolbar));
