import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import FareGroup from '../../../FareGroup';
import { FlightClass } from '../../../__generated__/graphql';
import { flightClass } from '../../../AircraftPopup/components/FlightClass/FlightClass.css';
import { useTranslation } from 'react-i18next';
var seatImages = new Map([
    [FlightClass.Economy, 'https://cdn.websky.aero/content/ZZ/images/Economy.png'],
    [FlightClass.Business, 'https://cdn.websky.aero/content/ZZ/images/Business.png'],
    [FlightClass.Miles, 'https://cdn.websky.aero/content/ZZ/images/Economy.png']
]);
var fareOptions = new Map([
    [
        FlightClass.Business,
        [
            React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.84621 9.91304C5.84621 6.64735 8.60136 4 12 4C15.3987 4 18.1539 6.64735 18.1539 9.91304L18.1538 13.6087H5.84621V9.91304ZM19.6923 15.087V10.4059H22V15.3334C22 16.711 21.0194 17.8685 19.6923 18.1967V21H18.1539V18.2899H5.84621V21H4.30773V18.1967C2.98063 17.8685 2 16.711 2 15.3333H2.00004V10.4059H4.30773V15.087H19.6923Z", fill: "currentColor" })),
            React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20 4V5.77778L12.8889 13.7778V18.2222H17.3333V20H6.66667V18.2222H11.1111V13.7778L4 5.77778V4H20ZM6.36445 5.77778L7.93778 7.55556H16.0622L17.6444 5.77778H6.36445Z", fill: "currentColor" })),
            React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.35 5.64011C4.45 5.00011 4.23 3.76011 4.86 2.85011C5.49 1.95011 6.74 1.73011 7.65 2.36011C8.55 3.00011 8.77 4.24011 8.14 5.15011C7.5 6.05011 6.26 6.27011 5.35 5.64011ZM16 19.0001H8.93C7.45 19.0001 6.19 17.9201 5.97 16.4601L4 7.00011H2L3.99 16.7601C4.37 19.2001 6.47 21.0001 8.94 21.0001H16V19.0001ZM11.35 15.0001H16.23L22 19.5001L20.5 21.0001L16.68 18.0001H9.83C8.39 18.0001 7.16 16.9801 6.88 15.5601L5.53 9.64011C5.32 8.42011 6.14 7.25011 7.37 7.03011H7.39C7.72 6.97011 8.06 7.00011 8.38 7.09011C8.65 7.17011 8.91 7.29011 9.14 7.47011L10.78 8.74011C12.03 9.72011 13.84 10.3001 15.47 9.99011V12.1201C13.6 12.4401 11.9 11.7901 10.32 10.9001L11.35 15.0001Z", fill: "currentColor" }))
        ]
    ],
    [
        FlightClass.Economy,
        [
            React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.4545 5.63636V2H15.6364V5.63636H11.0909L11.5 10.5C14.8364 11.2727 17.4545 13.6545 17.4545 17.5L17.4727 22H19.0182C19.7818 22 20.4091 21.4091 20.5 20.6636L22 5.63636H17.4545ZM2 20.4774C2 21.3216 2.41972 22 2.94204 22H15.058C15.5803 22 16 21.3216 16 20.4774V19H2V20.4774ZM2 17C2 13.6722 5.5 12 9 12C12.5 12 16 13.6722 16 17H2Z", fill: "currentColor" })),
            React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21 8C22.1 8 23 8.9 23 10L22.99 10.08L23 10.09V12C23 12.26 22.95 12.5 22.86 12.73L19.84 19.78C19.54 20.5 18.83 21 18 21H9C7.9 21 7 20.1 7 19V9C7 8.45 7.22 7.95 7.59 7.59L14.17 1L15.23 2.05C15.5 2.32 15.67 2.7 15.67 3.11L15.64 3.43L14.69 8H21ZM5 21H1V9H5V21Z", fill: "currentColor" }))
        ]
    ],
    [
        FlightClass.Miles,
        [
            React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.4545 5.63636V2H15.6364V5.63636H11.0909L11.5 10.5C14.8364 11.2727 17.4545 13.6545 17.4545 17.5L17.4727 22H19.0182C19.7818 22 20.4091 21.4091 20.5 20.6636L22 5.63636H17.4545ZM2 20.4774C2 21.3216 2.41972 22 2.94204 22H15.058C15.5803 22 16 21.3216 16 20.4774V19H2V20.4774ZM2 17C2 13.6722 5.5 12 9 12C12.5 12 16 13.6722 16 17H2Z", fill: "currentColor" })),
            React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21 8C22.1 8 23 8.9 23 10L22.99 10.08L23 10.09V12C23 12.26 22.95 12.5 22.86 12.73L19.84 19.78C19.54 20.5 18.83 21 18 21H9C7.9 21 7 20.1 7 19V9C7 8.45 7.22 7.95 7.59 7.59L14.17 1L15.23 2.05C15.5 2.32 15.67 2.7 15.67 3.11L15.64 3.43L14.69 8H21ZM5 21H1V9H5V21Z", fill: "currentColor" }))
        ]
    ]
]);
var fareDescription = new Map([
    [FlightClass.Economy, ['comfortable salon']],
    [FlightClass.Business, ['comfortable seating', 'access to airport lounge', 'meals are included in the price']],
    [FlightClass.Miles, ['premium fare']]
]);
var FareGroupStub = function (props) {
    var theme = useTheme('DesktopFlight').ModalContent;
    var t = useTranslation('FlightInfoModalContent').t;
    var renderFareStubHeader = function (category) { return (React.createElement(React.Fragment, null,
        React.createElement("img", { src: seatImages.get(category), alt: flightClass, className: theme.fareGroup_stub__img }),
        React.createElement("div", { className: theme.fareGroup_stub__content },
            React.createElement("div", { className: theme.stubOptions }, fareOptions.get(props.category).map(function (icon) { return icon; })),
            React.createElement("ul", { className: theme.stubDescription }, fareDescription.get(props.category).map(function (descr) { return (React.createElement("li", { className: theme.stubDescription__item }, t(descr))); }))))); };
    return (React.createElement(FareGroup, { className: cn(theme.fareGroup_stub, props.className), variant: 'white', fare: {
            marker: null,
            promo: null,
            title: null,
            category: props.category,
            id: '0',
            airline: { iata: '', name: '', icon: '', logo: { fullUrl: '' } },
            infoImage: null,
            options: [],
            description: null,
            isRecommended: false
        }, price: null, renderProps: {
            renderButton: function () { return null; },
            renderHeader: function () { return renderFareStubHeader(props.category); },
            renderOptions: function () { return null; },
            renderUpgradeOption: function () { return null; }
        } }));
};
export default FareGroupStub;
