import { __read } from "tslib";
/**
 BirthDate: "08.05.2011"
 DocExpiryDate: "07.05.2019"
 DocNumber: "650135576"
 DocType: "InternationalPassportRU"
 FirstName: "MARIIA"
 Gender: "F"
 LastName: "KONIAKHINA"
 MiddleName: ""
 Nationality: "RU"
 */
export var mrzResultToPassengerValuesAdapter = function (values, orderTraveller) {
    return Object.entries(values).map(function (_a) {
        var _b = __read(_a, 2), fieldType = _b[0], currentValue = _b[1];
        var name = orderTraveller.values.find(function (orderTravellerValue) { return orderTravellerValue.type === fieldType; }).name;
        var type = orderTraveller.values.find(function (orderTravellerValue) { return orderTravellerValue.type === fieldType; }).type;
        var value = typeof currentValue === 'number' ? currentValue.toString(10) : currentValue;
        return {
            value: value,
            name: name,
            type: type
        };
    });
};
