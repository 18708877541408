import { __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../../../Link';
import cn from 'classnames';
import { InfoDetails } from '../../../Icons';
import Modal from '../../../Modal';
import FareConditions from '../../../FareConditions/components/Component';
import { useCallback, useState } from 'react';
import * as css from './RulesButton.css';
var RulesButton = function (_a) {
    var _b;
    var flightId = _a.flightId;
    var t = useTranslation('FareGroup').t;
    var _c = __read(useState(false), 2), isFareConditionsOpen = _c[0], setIsFareConditionsOpen = _c[1];
    var closeButtonRenderer = useCallback(function (onClick) { return React.createElement("div", { className: css.close, onClick: onClick }); }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Link, { action: function () {
                if (flightId) {
                    setIsFareConditionsOpen(true);
                }
            }, className: cn(css.showRules, (_b = {}, _b[css.showRules_disabled] = !flightId, _b)) },
            InfoDetails,
            React.createElement("span", null, t('Rules'))),
        React.createElement(Modal, { open: isFareConditionsOpen, onClose: function () {
                setIsFareConditionsOpen(false);
            }, closeButtonRenderer: closeButtonRenderer },
            React.createElement(FareConditions, { flightId: flightId }))));
};
export default RulesButton;
