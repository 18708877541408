import { __assign } from "tslib";
import * as React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { createStore } from '../store';
import SearchForm from './SearchForm/SearchForm';
import { useGraphQLClient } from '../../graphql-client';
import { useConfig } from '../../context';
var Component = function (props) {
    var client = useGraphQLClient();
    var proMode = useConfig().SearchForm.proMode;
    var store = createStore(props.defaultParameters, proMode);
    return (React.createElement(Provider, { store: store },
        React.createElement(ApolloProvider, { client: client },
            React.createElement(SearchForm, __assign({}, props)))));
};
export default Component;
