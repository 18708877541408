import { __assign, __read, __spreadArray, __values } from "tslib";
import { Currency } from '../enums';
import memes from '../memes';
import { AviaPassengerType, OrderAdditionalServiceGdsServiceServiceType } from '../__generated__/globalTypes';
import { compose } from 'redux';
import { isOccupiedByOtherPassenger } from './components/SeatMap/Seat/utils';
var getSegmentTitle = function (segment) {
    var isTerminal = window.__webskyEngineConfig.global.companyInfo.iataCode === 'TERMINAL';
    return isTerminal
        ? "".concat(segment.segmentInfo.departure.airport.iata, " \u2014 ").concat(segment.segmentInfo.arrival.airport.iata)
        : "".concat(segment.segmentInfo.departure.airport.city.name, " \u2014 ").concat(segment.segmentInfo.arrival.airport.city.name);
};
export var getTabs = function (segments, passengers, servicesMap) {
    return segments.map(function (segment, index) {
        var seatsCount = 0;
        var seatsPrice = {
            amount: 0,
            currency: null
        };
        passengers.forEach(function (passenger) {
            if (!passenger.seats) {
                return;
            }
            var seats = passenger.seats.filter(function (seat) { return seat.segmentId === segment.segmentInfo.id; });
            seats.forEach(function (seat) {
                var _a, _b, _c, _d, _e, _f;
                seatsCount++;
                if (seat === null || seat === void 0 ? void 0 : seat.rfisc) {
                    seatsPrice.amount += (_d = (_c = (_b = (_a = servicesMap[seat.segmentId]) === null || _a === void 0 ? void 0 : _a[passenger.id]) === null || _b === void 0 ? void 0 : _b[seat.rfisc]) === null || _c === void 0 ? void 0 : _c.price.amount) !== null && _d !== void 0 ? _d : 0;
                }
                else {
                    seatsPrice.amount += (_e = seat === null || seat === void 0 ? void 0 : seat.price) === null || _e === void 0 ? void 0 : _e.amount;
                }
                if (!seatsPrice.currency) {
                    seatsPrice.currency = (_f = seat === null || seat === void 0 ? void 0 : seat.price) === null || _f === void 0 ? void 0 : _f.currency;
                }
            });
        });
        return {
            value: index,
            isAvailable: segment.decks.length > 0,
            header: getSegmentTitle(segment),
            seatsCount: seatsCount,
            price: seatsCount > 0 ? seatsPrice : null
        };
    });
};
export var getSeatServicesForSegments = function (segment) {
    var uniqueServiceIdsSet = new Set();
    var uniqueServices = [];
    if (segment && segment.decks.length) {
        segment.decks[0].rows.forEach(function (row) {
            row.parts.forEach(function (part) {
                part.seats.forEach(function (seat) {
                    if (seat.seatService && !uniqueServiceIdsSet.has(seat.seatService.id)) {
                        var seatService = seat.service;
                        if (seatService) {
                            uniqueServices.push(seatService);
                            uniqueServiceIdsSet.add(seatService.id);
                        }
                    }
                });
            });
        });
        return uniqueServices;
    }
    return [];
};
var getSeatsonOtherSegments = function (traveller, segment) {
    return traveller.seats
        ? traveller.seats.filter(function (travellerSeat) { return travellerSeat.segmentId !== segment.segmentsInfo.id; })
        : [];
};
export var updateSeatServices = function (passengers, segment, passengerId, seat) {
    var _a, _b;
    var services = [];
    var currentPassenger = passengers.find(function (passenger) { return passenger.id === passengerId; });
    var otherSelectedSeats = getSeatsonOtherSegments(currentPassenger, segment).map(function (seat) { return ({
        isConfirmed: seat.isConfirmed,
        segment: {
            id: seat.segmentId
        },
        letter: seat.letter,
        row: parseInt(seat.number, 10),
        seat: __assign({}, seat)
    }); });
    var isSameSeat;
    if (seat) {
        isSameSeat =
            ((_b = (_a = currentPassenger === null || currentPassenger === void 0 ? void 0 : currentPassenger.seats) === null || _a === void 0 ? void 0 : _a.find(function (selectedSeat) { return selectedSeat.segmentId === segment.segmentsInfo.id; })) === null || _b === void 0 ? void 0 : _b.number) === seat.number;
    }
    var selectedSeat = isSameSeat || !seat
        ? null
        : {
            isConfirmed: false,
            segment: { id: segment.segmentsInfo.id },
            letter: seat.letter,
            row: parseInt(seat.number, 10),
            seat: __assign({}, seat),
            product: null
        };
    if (selectedSeat) {
        var isSeatOccupiedByOtherPassenger = isOccupiedByOtherPassenger(seat, passengers, currentPassenger.id, segment.segmentsInfo.id);
        if (isSeatOccupiedByOtherPassenger) {
            var passengerWithThisSeat = passengers.find(function (passenger) {
                var _a;
                return (_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.some(function (passengerSeat) {
                    return "".concat(passengerSeat.row).concat(passengerSeat.letter) === seat.number &&
                        passengerSeat.segmentId === segment.segmentsInfo.id;
                });
            });
            var otherPassengerSeats = getSeatsonOtherSegments(passengerWithThisSeat, segment);
            services.push({
                passengerId: passengerWithThisSeat.id,
                seats: __spreadArray([], __read(otherPassengerSeats.map(function (seat) { return ({
                    isConfirmed: seat.isConfirmed,
                    segment: {
                        id: seat.segmentId
                    },
                    letter: seat.letter,
                    row: parseInt(seat.number, 10),
                    seat: __assign({}, seat)
                }); })), false)
            });
        }
    }
    services.push({
        passengerId: currentPassenger.id,
        seats: selectedSeat ? __spreadArray(__spreadArray([], __read(otherSelectedSeats), false), [selectedSeat], false) : __spreadArray([], __read(otherSelectedSeats), false)
    });
    return services;
};
export var getSelectedServices = function (traveller, segment, passenger, seat) {
    var _a, _b;
    var seats = getSeatsonOtherSegments(traveller, segment);
    var confirmedSeats = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.seats) === null || _a === void 0 ? void 0 : _a.filter(function (seat) { return seat.segmentId === segment.segmentsInfo.id && (seat === null || seat === void 0 ? void 0 : seat.isConfirmed); })) !== null && _b !== void 0 ? _b : [];
    if (seat) {
        seats.push({
            segmentId: segment.segmentsInfo.id,
            row: seat.number,
            letter: seat.letter,
            price: seat.price,
            id: seat.seatService.id,
            type: OrderAdditionalServiceGdsServiceServiceType.Seat,
            rfisc: seat.rfisc,
            deckId: null
            // !fixme
            // isConfirmed: false
        });
    }
    return __spreadArray(__spreadArray([], __read(seats), false), __read(confirmedSeats), false);
};
export var seatMapHasAnySeats = function (seatMap) {
    return seatMap && seatMap.segments.some(function (segment) { return segment.decks.length > 0; });
};
export var getTotalPrice = function (selectedSeatsMap) {
    var totalPrice = {
        amount: 0,
        currency: Currency.RUB
    };
    selectedSeatsMap.forEach(function (segment) {
        segment.forEach(function (passenger) {
            if (passenger.seat) {
                totalPrice.amount += passenger.seat.price.amount;
                if (totalPrice.currency !== passenger.seat.price.currency) {
                    totalPrice.currency = passenger.seat.price.currency;
                }
            }
        });
    });
    return totalPrice;
};
export var segmentHasAvailableSeats = memes(function (segment, services) {
    var e_1, _a, e_2, _b, e_3, _c;
    var segmentId = segment.segmentsInfo.id;
    var seatServices = services.gdsServices.services.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Seat; });
    var hasAvailableSeats = false;
    try {
        for (var _d = __values(Object.entries(segment.decks)), _e = _d.next(); !_e.done; _e = _d.next()) {
            var _f = __read(_e.value, 2), deck = _f[1];
            try {
                for (var _g = (e_2 = void 0, __values(Object.entries(deck.rows))), _h = _g.next(); !_h.done; _h = _g.next()) {
                    var _j = __read(_h.value, 2), row = _j[1];
                    var _loop_1 = function (seat) {
                        if (hasAvailableSeats) {
                            return { value: true };
                        }
                        if (!seat.seatService) {
                            // free seat
                            hasAvailableSeats = seat.isAvailable && seat.isExistent && !seat.isAisle;
                            return "continue";
                        }
                        var relatedService = seatServices.find(function (service) { return service.id === seat.seatService.id; });
                        hasAvailableSeats =
                            seat.isAvailable &&
                                seat.isExistent &&
                                !seat.isAisle &&
                                relatedService.allowedSegments.some(function (allowedSegment) { return allowedSegment.includes(segmentId); });
                    };
                    try {
                        for (var _k = (e_3 = void 0, __values(Object.entries(row.seats))), _l = _k.next(); !_l.done; _l = _k.next()) {
                            var _m = __read(_l.value, 2), seat = _m[1];
                            var state_1 = _loop_1(seat);
                            if (typeof state_1 === "object")
                                return state_1.value;
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_l && !_l.done && (_c = _k.return)) _c.call(_k);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return hasAvailableSeats;
});
export var checkAllSeatsAreSelected = function (passengers, segmentsCount) {
    return passengers.every(function (passenger) {
        var _a;
        return passenger.type === AviaPassengerType.INF || ((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.length) >= segmentsCount;
    });
};
var getSeatServiceById = memes(function (services, id) {
    if (id === void 0) { id = null; }
    return services.find(function (service) { return service.id === id; });
});
export var addServicesToSeats = memes(function (seatMap) {
    return __assign(__assign({}, seatMap), { segments: seatMap.segments.map(function (segment) { return (__assign(__assign({}, segment), { decks: segment.decks.map(function (deck) { return (__assign(__assign({}, deck), { rows: deck.rows.map(function (row) { return (__assign(__assign({}, row), { seats: row.seats.map(function (seat) {
                        var _a;
                        return (__assign(__assign({}, seat), { service: getSeatServiceById((_a = seatMap.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services, seat.seatService && seat.seatService.id) }));
                    }) })); }) })); }) })); }) });
});
export var isAisle = function (seat) {
    return !seat.isExistent && seat.isAisle;
};
export var splitRows = memes(function (rows) {
    return rows.map(function (row, rowIndex) {
        var _a, _b;
        var parts = [
            {
                seats: [],
                service: {
                    minPrice: (_a = row.seats[0]) === null || _a === void 0 ? void 0 : _a.price,
                    seatService: (_b = row.seats[0]) === null || _b === void 0 ? void 0 : _b.seatService
                }
            }
        ];
        var currentParts = parts[parts.length - 1];
        row.seats.forEach(function (seat, seatIndex) {
            var prev = seatIndex > 0 ? row.seats[seatIndex - 1] : null;
            if ((prev && isAisle(prev)) || isAisle(seat)) {
                var length = parts.push({
                    seats: [seat],
                    service: {
                        isAisle: isAisle(seat),
                        seatService: seat.seatService,
                        minPrice: seat.price
                    }
                });
                currentParts = parts[length - 1];
            }
            else {
                currentParts.seats.push(seat);
                if (!currentParts.service.seatService && seat.seatService) {
                    currentParts.service.seatService = seat.seatService;
                    currentParts.service.minPrice = seat.price;
                }
                if (currentParts.service.minPrice.amount > seat.price.amount && seat.isExistent) {
                    currentParts.service.minPrice = seat.price;
                }
            }
        });
        return __assign(__assign({}, row), { parts: parts });
    });
});
export var splitSeatMapRows = memes(function (seatMap) {
    return __assign(__assign({}, seatMap), { segments: seatMap.segments.map(function (segment) { return (__assign(__assign({}, segment), { decks: segment.decks.map(function (deck) { return (__assign(__assign({}, deck), { rows: splitRows(deck.rows) })); }) })); }) });
});
export var getSegments = function (seatMap) {
    return seatMap.segments.map(function (segment) { return ({
        isSeatsAvailableInCheckinOnly: segment.isSeatsAvailableInCheckinOnly,
        services: getSeatServicesForSegments(segment),
        sunSide: segment.sunSide,
        segmentInfo: segment.segmentsInfo,
        decks: segment.decks
    }); });
};
export var seatMapAdapter = compose(splitSeatMapRows, addServicesToSeats);
