import { __read } from "tslib";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { MobileSegmentFlat } from '../../MobileSegmentInfo';
import SlideBottom from '../../SlideBottom/components/SlideBottom';
import MobileFlightInfo from '../../MobileFlightInfo';
import { format, initI18n } from '../../utils';
import Money from '../../Money';
import { MobileSegmentType } from '../../MobileSegmentInfo/components/MobileSegmentFlat/MobileSegmentFlat';
import PriceInMiles from '../../PriceInMiles/PriceInMiles';
initI18n('MobileSummaryFlight');
var closeIcon = (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z", fill: "currentColor" })));
var MobileSummaryFlight = function (_a) {
    var _b, _c, _d, _e;
    var flight = _a.flight, isReturnFlight = _a.isReturnFlight, fareFamily = _a.fareFamily, onChange = _a.onChange, price = _a.price, _f = _a.useFlatMobileSegment, useFlatMobileSegment = _f === void 0 ? false : _f, canceled = _a.canceled, priceInMiles = _a.priceInMiles;
    var t = useTranslation('MobileSummaryFlight').t;
    var css = useTheme('MobileSummaryFlight').MobileSummaryFlight;
    var _g = __read(React.useState(false), 2), isOpen = _g[0], setIsOpen = _g[1];
    return (React.createElement("div", { className: cn(css.wrapper, (_b = {}, _b[css.wrapper_flat] = useFlatMobileSegment, _b)) },
        !useFlatMobileSegment && (React.createElement("div", { className: css.header },
            t(isReturnFlight ? 'Returning' : 'Departing'),
            ' ',
            React.createElement("span", { className: css.leg__date },
                "\u2022 ",
                format(flight.segments[0].segment.departure.date, 'd LLL, eeee')))),
        React.createElement("div", { className: cn(css.body, (_c = {}, _c[css.body_flat] = useFlatMobileSegment, _c[css.body_canceled] = canceled, _c)), onClick: function () { return setIsOpen(true); } },
            React.createElement(MobileSegmentFlat, { segments: flight.segments, type: MobileSegmentType.Selected })),
        React.createElement("div", { className: cn(css.footer, (_d = {}, _d[css.footer_flat] = useFlatMobileSegment, _d)) },
            !useFlatMobileSegment && (React.createElement(React.Fragment, null,
                React.createElement("span", null, fareFamily.title),
                React.createElement("span", { className: css.footer__price },
                    React.createElement(Money, { money: price }),
                    React.createElement("span", { className: css.closer, onClick: onChange }, closeIcon)))),
            useFlatMobileSegment && (React.createElement(React.Fragment, null,
                React.createElement("span", null, fareFamily.title),
                canceled ? (React.createElement("span", { className: css.canceled }, t('Canceled'))) : (React.createElement("span", { className: cn((_e = {}, _e[css.footer__price_flat] = useFlatMobileSegment, _e)) },
                    priceInMiles > 0 ? (React.createElement(PriceInMiles, { amount: priceInMiles, moneyClassName: css.price__miles, currencyClassName: css.currency })) : (React.createElement(Money, { money: price })),
                    priceInMiles > 0 && (React.createElement("div", { className: css.miles__taxes },
                        t('taxes and fees'),
                        ": ",
                        React.createElement(Money, { money: price })))))))),
        ReactDOM.createPortal(React.createElement(SlideBottom, { className: css.slideBottom__container, isOpen: isOpen, onClose: function () { return setIsOpen(false); }, scrollable: true },
            React.createElement(MobileFlightInfo, { flight: flight })), document.body)));
};
export default MobileSummaryFlight;
