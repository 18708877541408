import { __read } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Redirect, Route as RouteComponent, Switch, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import MediaQuery from 'react-responsive';
import Card from '@material-ui/core/Card';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { initI18n, MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../../../utils';
import Sidebar from '../../../../Sidebar';
import AccountInfo from '../../../../Account';
import Loader from '../../../../Loader';
import { Aircraft, Group, LogoutIcon, Person, Settings, Star } from '../../Icons';
import TravelCompanions from '../../../../TravelCompanions';
import PersonalPage from '../../../../PersonalPage';
import { LoginType } from '../../../../__generated__/globalTypes';
import * as AccountQueries from '../../../../__queries__/Account.graphql';
import { useConfig } from '../../../../context';
import LoginPage from '../../../../LoginPage';
import MobileMenu from '../../../../Sidebar/components/MobileMenu/MobileMenu';
import { reachGoal, UserGoal } from '../../../../analytics';
import Logout from './Logout/Logout';
import Loyalty from '../../../../Loyalty';
import Orders from './Orders/Orders';
initI18n('Account');
export var routes = [
    {
        title: i18n.t('Orders'),
        pathname: '/account',
        icon: Aircraft
    },
    {
        title: i18n.t('Personal details'),
        pathname: '/account/profile',
        icon: Person
    },
    {
        title: i18n.t('Settings'),
        pathname: '/account/settings',
        icon: Settings
    },
    {
        title: i18n.t('Travel companions'),
        pathname: '/account/companions',
        icon: Group
    },
    {
        title: i18n.t('Loyalty program'),
        pathname: '/account/loyalty',
        icon: Star
    },
    {
        title: i18n.t('Logout'),
        pathname: '/account/logout',
        icon: LogoutIcon
    }
    // {
    // 	title: i18n.t('Client support'),
    // 	pathname: '/account/support',
    // 	icon: Support
    // }
];
var LoginStages;
(function (LoginStages) {
    LoginStages[LoginStages["LoggedIn"] = 0] = "LoggedIn";
    LoginStages[LoginStages["Loading"] = 1] = "Loading";
    LoginStages[LoginStages["NotLoggedIn"] = 2] = "NotLoggedIn";
})(LoginStages || (LoginStages = {}));
var Account = function (_a) {
    var _b;
    var location = _a.location;
    var _c = __read(useState(LoginStages.Loading), 2), isLoggedIn = _c[0], setLoggedIn = _c[1];
    var css = useTheme().Account;
    var t = useTranslation('Account').t;
    var currentUser = useQuery(AccountQueries.GetAccountInfo);
    var _d = useConfig(), engineURL = _d.Account.engineURL, companyInfo = _d.global.companyInfo;
    useEffect(function () {
        var _a, _b;
        if ((_b = (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.authMethods) {
            setLoggedIn(LoginStages.LoggedIn);
        }
        else if (currentUser.loading) {
            setLoggedIn(LoginStages.Loading);
        }
        else {
            setLoggedIn(LoginStages.NotLoggedIn);
        }
    }, [currentUser]);
    var getUserInfo = function () {
        var userValues = {
            email: '',
            firstName: '',
            lastName: ''
        };
        if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.data) && !currentUser.loading && currentUser.data.CurrentUser) {
            currentUser.data.CurrentUser.userProfile.values.forEach(function (value) {
                if (value.name === 'lastName' || value.name === 'firstName' || value.name === 'email') {
                    userValues[value.name] = value.value;
                }
            });
        }
        return userValues;
    };
    var onLoyaltyCardSaved = function () {
        setTimeout(function () {
            currentUser.refetch();
        }, 2000);
    };
    routes.find(function (route) {
        var _a, _b;
        if (route.pathname === '/account/loyalty') {
            route.title = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyaltyProgramName;
            if ((_a = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyalty) === null || _a === void 0 ? void 0 : _a.redirectTo) {
                route.pathname = (_b = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyalty) === null || _b === void 0 ? void 0 : _b.redirectTo;
                route.isRedirect = true;
            }
        }
    });
    return (React.createElement(React.Fragment, null,
        isLoggedIn === LoginStages.LoggedIn && (React.createElement("div", { className: css.wrapper },
            React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
                React.createElement(Sidebar, { routes: routes, selectedRoute: location.pathname })),
            React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                React.createElement(MobileMenu, { routes: routes, selectedRoute: location.pathname, userInfo: getUserInfo() })),
            React.createElement("div", { className: cn(css.active__route, (_b = {},
                    _b[css.account__fullscreen] = location.pathname === '/account/settings' || location.pathname === '/account/profile',
                    _b)) },
                React.createElement(Switch, null,
                    React.createElement(RouteComponent, { path: "/account", exact: true, render: function () { return React.createElement(Orders, null); } }),
                    React.createElement(RouteComponent, { path: "/account/profile", exact: true, render: function () { return (React.createElement(React.Fragment, null,
                            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                                React.createElement("h1", { className: css.route__title }, t('Personal details'))),
                            React.createElement(PersonalPage, null))); } }),
                    React.createElement(RouteComponent, { path: "/account/settings", exact: true, render: function () { return (React.createElement(React.Fragment, null,
                            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                                React.createElement("h1", { className: css.route__title }, t('Account')),
                                React.createElement(Card, null,
                                    React.createElement(AccountInfo, null))),
                            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                                React.createElement(AccountInfo, null)))); } }),
                    React.createElement(RouteComponent, { path: "/account/logout", exact: true, render: function () {
                            if (isLoggedIn === LoginStages.LoggedIn) {
                                return React.createElement(Logout, null);
                            }
                            else if (isLoggedIn === LoginStages.NotLoggedIn) {
                                return React.createElement(Redirect, { to: "/account" });
                            }
                            else {
                                return React.createElement(Loader, null);
                            }
                        } }),
                    React.createElement(RouteComponent, { path: "/account/loyalty", exact: true, render: function () {
                            var _a, _b, _c, _d, _e;
                            return (React.createElement(React.Fragment, null,
                                React.createElement("h1", { className: css.route__title }, companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyaltyProgramName),
                                React.createElement(Loyalty, { isAuthorizationNeeded: (_b = (_a = currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.isFfpAuthorizationNeeded, links: (_c = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyalty) === null || _c === void 0 ? void 0 : _c.links, ffpInfo: (_e = (_d = currentUser.data) === null || _d === void 0 ? void 0 : _d.CurrentUser) === null || _e === void 0 ? void 0 : _e.userFfpInfo, loyaltyName: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyaltyProgramName, onLoyaltyCardSaved: onLoyaltyCardSaved, userInfo: getUserInfo() })));
                        } }),
                    React.createElement(RouteComponent, { path: "/account/companions", exact: true, render: function () { return (React.createElement(React.Fragment, null,
                            React.createElement("h1", { className: css.route__title }, t('Travel companions')),
                            React.createElement(TravelCompanions, null))); } }),
                    React.createElement(RouteComponent, { path: "/", render: function () {
                            window.location.href = "".concat(engineURL, "#/404");
                            return null;
                        } }))))),
        isLoggedIn === LoginStages.NotLoggedIn && (React.createElement("div", { className: css.loginWrapper },
            React.createElement(LoginPage, { loginType: LoginType.Email, onSuccess: function (isRegistration) {
                    reachGoal(isRegistration ? UserGoal.Registration : UserGoal.Login);
                    window.location.reload();
                } })))));
};
export default withRouter(Account);
