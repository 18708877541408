import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import Button from '../../../../Button/Button';
import { Person, Refresh, Search } from '../../../../Icons';
import { usePointOfSales } from '../../../leoCheckIsAuthenticated/pointOfSalesContext';
import Input from '../../../../Input';
import InputAdornment from '@material-ui/core/InputAdornment';
var Header = memo(function (props) {
    var _a, _b;
    var theme = useTheme('EventsQueues').Header;
    var t = useTranslation('EventsQueues').t;
    var posCredentials = usePointOfSales();
    return (React.createElement("div", { className: theme.header },
        React.createElement("div", { className: theme.owner },
            React.createElement("div", { className: theme.person },
                React.createElement("div", { className: theme.person__icon }, Person),
                ((_a = props.queuesOwner) === null || _a === void 0 ? void 0 : _a.agency) && (React.createElement("div", { className: theme.person__name },
                    props.queuesOwner.agency,
                    " / ",
                    props.queuesOwner.user))),
            (posCredentials === null || posCredentials === void 0 ? void 0 : posCredentials.isAgencySupervisor) && (React.createElement(Button, { size: "common", variant: "outline", className: theme.changeOwnerButton, onClick: props.openChangeQueuesOwner }, t('Change'))),
            ((_b = props.queuesOwner) === null || _b === void 0 ? void 0 : _b.agency) && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: theme.delimiter }),
                React.createElement(Button, { className: theme.refreshButton, variant: "outline", onClick: props.refresh, isLoading: props.isRefreshing }, Refresh)))),
        React.createElement(Input, { variant: 'outlined', placeholder: t('Search order'), className: theme.locatorSearch, inputClassName: theme.locatorSearch__input, inputFocusedClassName: theme.locatorSearch__input_focused, startAdornment: React.createElement(InputAdornment, { position: 'start', className: theme.searchIcon }, Search), TextFieldProps: {
                onChange: function (event) {
                    props.setSearchOrder(event.target.value);
                }
            } })));
});
export default Header;
