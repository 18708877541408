export var InputType;
(function (InputType) {
    InputType["Text"] = "text";
    InputType["Date"] = "date";
    InputType["Select"] = "select";
    InputType["Switch"] = "radio";
    InputType["Autocomplete"] = "autocomplete";
    InputType["Phone"] = "phone";
})(InputType || (InputType = {}));
export var FieldGroupType;
(function (FieldGroupType) {
    FieldGroupType["MVP"] = "mvp";
    FieldGroupType["Other"] = "other";
})(FieldGroupType || (FieldGroupType = {}));
export var SearchParameterFieldEnum;
(function (SearchParameterFieldEnum) {
    SearchParameterFieldEnum["SearchString"] = "searchString";
    SearchParameterFieldEnum["DepartureDateStart"] = "departureDateStart";
    SearchParameterFieldEnum["DepartureDateEnd"] = "departureDateEnd";
    SearchParameterFieldEnum["FlightNumber"] = "flightNumber";
    SearchParameterFieldEnum["LastName"] = "lastName";
})(SearchParameterFieldEnum || (SearchParameterFieldEnum = {}));
export var DateFields = [SearchParameterFieldEnum.DepartureDateStart, SearchParameterFieldEnum.DepartureDateEnd];
