import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import BaggagePage from '../../../../BaggagePage/components/BaggagePage';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import { setNextStep } from '../../../store/checkout/actions';
import { getPassengerLabels, getSelectedBaggage } from '../../../store/passengers/selectors';
import { getSegmentsForServices, hasSeatsServices, isConfirmed, getIsOddOrderId, getBaggage, getIncludedBaggage, getBonusForFlight, getPriceInMiles } from '../../../store/order/selectors';
import { setSplitTest, SplitTest } from '../../../../analytics';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import ServicesToolbar from '../../../../ServicesToolbar';
import { getBaggagePriceToPay, getTotalPriceToPay } from '../../../store/orderPrice/selectors';
import { addService, removeService, setService } from '../../../store/selectedServices/actions';
import { getPassengerStateCompatible } from '../../../store/selectedServices/selectors';
import { useMemo } from 'react';
import { getBaggageReduxCallbacks } from '../../../utils';
import { useConfig } from '../../../../context';
import { usePreselectedBaggageSelect } from '../../../hooks';
var Baggage = function (_a) {
    var totalPrice = _a.totalPrice, passengers = _a.passengers, setNextStep = _a.setNextStep, goToPrevStep = _a.goToPrevStep, isConfirmed = _a.isConfirmed, hasSeatsStep = _a.hasSeatsStep, passengerLabels = _a.passengerLabels, baggageTotalSum = _a.baggageTotalSum, addService = _a.addService, removeService = _a.removeService, setService = _a.setService;
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').CheckoutStyles;
    var isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
    var segments = useSelector(getSegmentsForServices);
    var isOddOrderId = useSelector(getIsOddOrderId);
    var selectedBaggages = useSelector(getSelectedBaggage);
    var baggage = useSelector(getBaggage);
    var includedBaggage = useSelector(getIncludedBaggage);
    var bonusForFlight = useSelector(getBonusForFlight);
    var priceInMiles = useSelector(getPriceInMiles);
    var _b = useConfig().global, maxBaggage = _b.maxBaggage, maxBaggagePerPassenger = _b.maxBaggagePerPassenger, iataCode = _b.companyInfo.iataCode;
    usePreselectedBaggageSelect(iataCode === 'N4');
    var simpleBaggageSelector = !maxBaggage && !maxBaggagePerPassenger && isOddOrderId && (segments.length === 1 || isMobile);
    setSplitTest(SplitTest.OneButtonBaggageSelector, simpleBaggageSelector);
    var baggageCallbacks = useMemo(function () { return getBaggageReduxCallbacks(passengers, baggage, segments, addService, removeService, setService); }, [passengers, baggage, segments, addService, removeService, setService]);
    return (React.createElement(ServicesToolbar, { toolbarProps: {
            totalPrice: totalPrice,
            onButtonClick: setNextStep,
            onBackButtonClick: hasSeatsStep ? goToPrevStep : null,
            additional: baggageTotalSum.amount ? (React.createElement("div", { className: css.gdsTotalPrice },
                t('Baggage'),
                ": ",
                React.createElement(Money, { money: baggageTotalSum }))) : null,
            miles: bonusForFlight,
            priceInMiles: priceInMiles
        }, mobileToolbarProps: {
            onContinue: setNextStep,
            onBack: goToPrevStep
        } },
        React.createElement(BaggagePage, __assign({ simpleBaggageSelector: simpleBaggageSelector, baggage: __spreadArray(__spreadArray([], __read(includedBaggage), false), __read(baggage), false), segments: segments, passengers: passengers, readonly: isConfirmed, passengerLabels: passengerLabels, selectedBaggage: selectedBaggages }, baggageCallbacks))));
};
var mapStateToProps = function () {
    return function (state, ownProps) { return (__assign(__assign({}, ownProps), { isConfirmed: isConfirmed(state), passengers: getPassengerStateCompatible(state), totalPrice: getTotalPriceToPay(state), hasSeatsStep: hasSeatsServices(state), passengerLabels: getPassengerLabels(state), baggageTotalSum: getBaggagePriceToPay(state) })); };
};
var mapDispatchToProps = {
    setNextStep: setNextStep,
    addService: addService,
    removeService: removeService,
    setService: setService
};
export default connect(mapStateToProps, mapDispatchToProps)(Baggage);
