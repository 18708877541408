import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from 'react-responsive';
import { getTravellerName, getTravellerRetryLink } from '../../../../../utils';
import Passenger from '../../../../../../../RegisteredPassenger/RegisteredPassenger';
import { getAllServices, getCheckinOrderId } from '../../../../../store/order/selectors';
import { CheckinDocumentType } from '../../../../../../../__generated__/globalTypes';
import { downloadPDF, downloadPkpass, MOBILE_MIN_WIDTH } from '../../../../../../../utils';
import * as CheckinDocumentQuery from '../../../../../../../__queries__/CheckinDocument.graphql';
import * as SendDocument from '../../../../../../../__queries__/CheckinSendDocument.graphql';
import { CheckinGoal, reachGoal } from '../../../../../../../analytics';
var RegisteredPassenger = memo(function (_a) {
    var _b, _c, _d;
    var passenger = _a.passenger, onCheckinCancel = _a.onCheckinCancel, segments = _a.segments;
    var isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
    var _e = __read(useState(), 2), barcode = _e[0], setBarcode = _e[1];
    var orderId = useSelector(getCheckinOrderId);
    var services = useSelector(getAllServices);
    var loadBoardingPass = useQuery(CheckinDocumentQuery.CheckinDocument, {
        skip: true
    });
    var _f = __read(useMutation(SendDocument.CheckinSendDocument), 1), sendDocument = _f[0];
    var getBarcode = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, loadBoardingPass.refetch({
                            params: {
                                orderId: orderId,
                                travellerId: passenger.id,
                                documentType: CheckinDocumentType.QrCode
                            }
                        })];
                case 1:
                    data = (_a.sent()).data;
                    // Show error warning modal
                    if (data === null || data === void 0 ? void 0 : data.CheckinDocument) {
                        setBarcode(data.CheckinDocument);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onBoardingPassDownload = useCallback(function (segmentId) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, loadBoardingPass.refetch({
                            params: {
                                orderId: orderId,
                                segmentId: segmentId !== null && segmentId !== void 0 ? segmentId : segments[0].id,
                                travellerId: passenger.id,
                                documentType: CheckinDocumentType.BoardingPass
                            }
                        })];
                case 1:
                    data = (_a.sent()).data;
                    // Show error warning modal
                    if (data === null || data === void 0 ? void 0 : data.CheckinDocument) {
                        reachGoal(CheckinGoal.BoardingPassDownload);
                        downloadPDF(data.CheckinDocument, "boarding_pass_".concat(passenger.id, "_").concat(segmentId !== null && segmentId !== void 0 ? segmentId : segments[0].id, ".pdf"));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    console.error(e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [orderId]);
    var onTicketsResend = useCallback(function (email, segmentId) { return __awaiter(void 0, void 0, void 0, function () {
        var e_3;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, sendDocument({
                            variables: {
                                params: {
                                    documentType: CheckinDocumentType.BoardingPass,
                                    email: email,
                                    orderId: orderId,
                                    segmentId: segmentId !== null && segmentId !== void 0 ? segmentId : (_a = segments[0]) === null || _a === void 0 ? void 0 : _a.id,
                                    travellerId: passenger.id
                                }
                            }
                        })];
                case 1:
                    _b.sent();
                    reachGoal(CheckinGoal.ResendTicket);
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _b.sent();
                    console.error(e_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [orderId]);
    var onAppleWalletPassDownload = useCallback(function (segmentId) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, loadBoardingPass.refetch({
                            params: {
                                orderId: orderId,
                                segmentId: segmentId !== null && segmentId !== void 0 ? segmentId : segments[0].id,
                                travellerId: passenger.id,
                                documentType: CheckinDocumentType.AppleWallet
                            }
                        })];
                case 1:
                    data = (_a.sent()).data;
                    // Show error warning modal
                    if (data === null || data === void 0 ? void 0 : data.CheckinDocument) {
                        reachGoal(CheckinGoal.AppleWalletPassDownload);
                        downloadPkpass(data.CheckinDocument, "boarding_pass_".concat(passenger.id, ".pkpass"));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_4 = _a.sent();
                    console.error(e_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [orderId]);
    useEffect(function () {
        if (!barcode && isMobile) {
            getBarcode();
        }
    }, [barcode]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Passenger, { retryLink: getTravellerRetryLink(passenger), segments: segments, name: getTravellerName(passenger), services: (_d = (_c = (_b = passenger === null || passenger === void 0 ? void 0 : passenger.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services.map(function (service) { return (__assign(__assign({}, services === null || services === void 0 ? void 0 : services.find(function (_a) {
                var id = _a.id;
                return id === service.serviceId;
            })), { count: service.count })); })) !== null && _d !== void 0 ? _d : [], seats: passenger.services.seats, onBoardingPassDownload: onBoardingPassDownload, onAppleWalletPassDownload: onAppleWalletPassDownload, onTicketsResend: onTicketsResend, barcode: barcode, onCheckinCancel: onCheckinCancel.bind(null, passenger.id), isCheckedIn: passenger.isConfirmed })));
});
export default RegisteredPassenger;
