import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MealPage from '../../../../../../MealPage';
import { getCheckinOrderId, getMeals, getMealServices, getMealsPrice, getPassengersNames, getRecommendedMeals, getSegmentsForServices, getTotalPrice, hasBaggageServices, showMealRecommendations } from '../../../../store/order/selectors';
import { CheckinStep, useSteps } from '../../../../utils';
import { Money } from '../../../../../../Money';
import { useTheme } from '../../../../../../theme';
import { MEAL_RECOMMENDATION_SHOWN, set } from '../../../../../../cache';
import { addService, clearService, removeService, saveServices } from '../../../../store/selectedServices/actions';
import { getSelectedMeals } from '../../../../store/selectedServices/selectors';
import ServicesToolbar from '../../../../../../ServicesToolbar';
var Meal = function () {
    var orderId = useSelector(getCheckinOrderId);
    var totalPrice = useSelector(getTotalPrice);
    var mealsPriceToPay = useSelector(getMealsPrice);
    var originalMealServices = useSelector(getMealServices);
    var meals = useSelector(getMeals);
    var isBaggageServiceAvailable = useSelector(hasBaggageServices);
    var selectedMeals = useSelector(getSelectedMeals);
    var recommendedMeals = useSelector(getRecommendedMeals);
    var showRecommendations = useSelector(showMealRecommendations);
    var passengersNames = useSelector(getPassengersNames);
    var segments = useSelector(getSegmentsForServices);
    var dispatch = useDispatch();
    var _a = useSteps(), goToNextStep = _a.goToNextStep, previousStep = _a.previousStep;
    var t = useTranslation('Checkin').t;
    var css = useTheme('Checkin').Checkin;
    // Do not show recommendations twice.
    var cacheRecommendationState = function () { return set("".concat(MEAL_RECOMMENDATION_SHOWN, "_").concat(orderId), true); };
    var onClear = useCallback(function (meal, segmentId) {
        var service = originalMealServices.find(function (_a) {
            var id = _a.id;
            return id === meal.id;
        });
        passengersNames.forEach(function (_a) {
            var id = _a.id;
            dispatch(clearService({
                service: service,
                segmentId: segmentId,
                passengerId: id
            }));
        });
        dispatch(saveServices());
    }, [passengersNames, originalMealServices]);
    var onMealAdd = useCallback(function (meal, segmentId, passengerId) {
        dispatch(addService({
            service: originalMealServices.find(function (_a) {
                var id = _a.id;
                return id === meal.id;
            }),
            segmentId: segmentId,
            passengerId: passengerId
        }));
        dispatch(saveServices());
    }, [originalMealServices]);
    var onMealRemove = useCallback(function (meal, segmentId, passengerId) {
        dispatch(removeService({
            service: originalMealServices.find(function (_a) {
                var id = _a.id;
                return id === meal.id;
            }),
            segmentId: segmentId,
            passengerId: passengerId
        }));
        dispatch(saveServices());
    }, [originalMealServices]);
    var onProceed = useCallback(function () { return goToNextStep(CheckinStep.Meal); }, [goToNextStep]);
    return (React.createElement(ServicesToolbar, { toolbarProps: {
            totalPrice: totalPrice,
            onButtonClick: onProceed,
            onBackButtonClick: isBaggageServiceAvailable ? function () { return previousStep(CheckinStep.Meal); } : null,
            additional: mealsPriceToPay.amount ? (React.createElement("div", { className: css.servicePrice },
                t('Meal'),
                ": ",
                React.createElement(Money, { money: mealsPriceToPay }))) : null
        } },
        React.createElement(MealPage, { showRecommendations: showRecommendations, meals: meals, passengers: passengersNames, segments: segments, selectedMeals: selectedMeals, recommendedMeals: recommendedMeals, onClear: onClear, onMealAdd: onMealAdd, onMealRemove: onMealRemove, onMobileProceed: onProceed, onMobileBack: isBaggageServiceAvailable ? function () { return previousStep(CheckinStep.Meal); } : null, onRecommendationConfirm: function () {
                // #TODO
                cacheRecommendationState();
            }, onRecommendationDisagree: cacheRecommendationState })));
};
export default Meal;
