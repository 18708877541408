import * as ServicesCard from './ServicesCard.css';
import * as Baggage from './components/Baggage/Baggage.css';
import * as Seats from './components/Seats/Seats.css';
import * as Meal from './components/Meal/Meal.css';
import * as Misc from './components/Misc/Misc.css';
import * as Selector from './components/Selector/Selector.css';
export default {
    ServicesCard: ServicesCard,
    Baggage: Baggage,
    Seats: Seats,
    Meal: Meal,
    Misc: Misc,
    Selector: Selector
};
