import React, { useEffect } from 'react';
import { useTheme } from '../../../../theme';
import SwipeableViews from 'react-swipeable-views';
import { useSwipeableBaggageViews } from './hooks';
import { Chevron } from '../../../../Icons';
import cn from 'classnames';
var SwipeableBaggageViews = function (props) {
    var css = useTheme('BaggageList').BaggageList;
    var _a = useSwipeableBaggageViews(props.items, props.itemsToView), viewIndex = _a.viewIndex, isCanSwipeLeft = _a.isCanSwipeLeft, isCanSwipeRight = _a.isCanSwipeRight, swipeLeft = _a.swipeLeft, swipeRight = _a.swipeRight, chunkedItems = _a.chunkedItems;
    useEffect(function () {
        var _a;
        (_a = props.onSwipe) === null || _a === void 0 ? void 0 : _a.call(props, chunkedItems[viewIndex]);
    }, [viewIndex]);
    return (React.createElement("div", { className: cn(css.root, css.root_swipeable) },
        React.createElement(SwipeableViews, { index: viewIndex }, chunkedItems.map(function (items, i) { return (React.createElement("div", { key: i, className: css.swipeableViewItem }, items.map(function (item, i) { return props.children(item); }))); })),
        isCanSwipeLeft && (React.createElement("div", { className: css.left, onClick: swipeLeft }, Chevron)),
        isCanSwipeRight && (React.createElement("div", { className: css.right, onClick: swipeRight }, Chevron))));
};
export default SwipeableBaggageViews;
