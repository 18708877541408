import React from 'react';
import PdfModal from '../../PdfModal/PdfModal';
var MessageDocuments = function (_a) {
    var message = _a.message, isLastMessage = _a.isLastMessage, documents = _a.documents, onOpenPdf = _a.onOpenPdf;
    return (React.createElement("span", null, message.response
        .split('\n')
        .filter(function (ticket) { return ticket; })
        .map(function (ticket, index) {
        var ticketNumber = ticket.replace(/[^\d]+/g, '');
        var ticketDocument = documents === null || documents === void 0 ? void 0 : documents.find(function (document) {
            var _a;
            if (document.emds) {
                return document.emds.includes(ticketNumber);
            }
            return (_a = document.tickets) === null || _a === void 0 ? void 0 : _a.includes(ticketNumber);
        });
        return (React.createElement(React.Fragment, { key: index },
            ticket,
            isLastMessage && ticketDocument && (React.createElement(React.Fragment, null,
                ' ......... ',
                React.createElement(PdfModal, { documents: [ticketDocument], onOpen: onOpenPdf }))),
            React.createElement("br", null)));
    })));
};
export default MessageDocuments;
