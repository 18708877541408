import { __assign } from "tslib";
import * as React from 'react';
import { Form } from 'react-final-form';
import cn from 'classnames';
import * as theme from './TravellerForm.css';
import { useTravellerFormData } from '../../../TravellerForm/components/hooks';
import { FormStages } from '../../../TravellerForm/types';
import { TravellerTypes } from '../../../Traveller/types';
import TravellerField from '../../../TravellerForm/components/TravellerField/TravellerField';
import Preferences from '../../../TravellerForm/components/Preferences/Preferences';
import { useTheme } from '../../../TravellerForm/theme';
import CheckboxField from './CheckboxField/CheckboxField';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
initI18n('PointOfSales');
var TravellerForm = function (props) {
    var _a;
    var css = useTheme().TravellerForm;
    /**
     * leo profile has only this fields according to https://youtrack.websky.aero/agiles/103-44/current?issue=WEBSKY-1380
     */
    var leoProfile = __assign(__assign({}, props.traveller), { values: props.traveller.values.filter(function (value) {
            switch (value.name) {
                case 'firstName':
                case 'lastName':
                case 'phone':
                case 'email':
                    return true;
                default:
                    return false;
            }
        }) });
    if ((_a = props.currentUser) === null || _a === void 0 ? void 0 : _a.pointOfSale) {
        Object.keys(props.currentUser.pointOfSale).forEach(function (key) {
            leoProfile.values.push({
                name: key,
                value: props.currentUser.pointOfSale[key],
                validationRules: []
            });
        });
    }
    var _b = useTravellerFormData(__assign(__assign({}, props), { traveller: leoProfile, fieldSets: {
            salesPoint: ['agency', 'id', 'hostLogin', 'isAgencySupervisor'],
            operator: ['firstName', 'lastName', 'middleName', 'email', 'phone']
        } })), validateForm = _b.validateForm, fieldValuesObject = _b.fieldValuesObject, onSubmit = _b.onSubmit, setStage = _b.setStage, setOpenedErrorTooltip = _b.setOpenedErrorTooltip, fieldSets = _b.fieldSets;
    var t = useTranslation('PointOfSales').t;
    var leoFields = __assign({}, fieldSets);
    return (React.createElement(Form, { onSubmit: onSubmit, validate: validateForm, initialValues: props.traveller ? fieldValuesObject : { gender: 'Male' }, render: function (_a) {
            var handleSubmit = _a.handleSubmit, form = _a.form, hasValidationErrors = _a.hasValidationErrors, errors = _a.errors;
            return (React.createElement("form", { onSubmit: function (event) {
                    if (!hasValidationErrors) {
                        handleSubmit(event).then(function (res) {
                            if (res) {
                                setStage(FormStages.Success);
                                if (props.type !== TravellerTypes.PersonalDetails) {
                                    setTimeout(function () {
                                        form.reset();
                                        setStage(FormStages.Default);
                                    }, 1000);
                                }
                                else {
                                    setTimeout(function () {
                                        setStage(FormStages.Default);
                                    }, 1000);
                                }
                            }
                            else {
                                setStage(FormStages.Default);
                                setOpenedErrorTooltip(true);
                                setTimeout(function () {
                                    setOpenedErrorTooltip(false);
                                }, 5000);
                            }
                        });
                    }
                    else {
                        console.log(errors);
                        handleSubmit(event);
                    }
                }, className: cn(css.traveller__form, theme.wrapper) },
                Object.keys(leoFields).map(function (fieldSet, index) {
                    var fields = leoFields[fieldSet];
                    if (fieldSet === 'personInfo') {
                        return (React.createElement("div", { key: index, className: css.row },
                            React.createElement("div", { className: css.item },
                                React.createElement(TravellerField, { readonly: true, fieldName: fields[0] }),
                                React.createElement(TravellerField, { readonly: true, fieldName: fields[1] })),
                            React.createElement("div", { className: css.item },
                                React.createElement(TravellerField, { readonly: true, fieldName: fields[2], options: props.traveller.values.find(function (field) { return field.name === fields[2]; })
                                        .validationRules[0].options }))));
                    }
                    return (React.createElement("div", { key: index, className: cn(css.row, theme.row) },
                        React.createElement("div", { className: theme.header }, t(fieldSet)),
                        fields.map(function (field, index) { return (React.createElement("div", { className: css.item, key: index }, field !== 'isAgencySupervisor' ? (React.createElement(TravellerField, { readonly: true, fieldName: field, label: fieldSet === 'salesPoint' ? t(field) : null })) : (React.createElement(CheckboxField, { fieldName: field, readonly: true, label: fieldSet === 'salesPoint' ? t(field) : null })))); })));
                }),
                props.showPreferences && (React.createElement(Preferences, { Meal: ['Vegetarian menu', 'same another'], Seats: ['Window seat', 'Center seat'] }))));
        } }));
};
export default TravellerForm;
