import { __assign } from "tslib";
import * as React from 'react';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import Baggage from '../Services/Baggage/Baggage';
import Meal from '../Services/Meal/Meal';
import Seat from '../Services/Seat/Seat';
import Misc from '../Services/Misc/Misc';
var ExchangeServiceCard = function (_a) {
    var order = _a.order, type = _a.type, refundServices = _a.refundServices;
    var props = {
        passengers: order.travellers,
        services: refundServices,
        flight: order.flight
    };
    switch (type) {
        case OrderAdditionalServiceGdsServiceServiceType.Baggage:
            return React.createElement(Baggage, __assign({}, props));
        case OrderAdditionalServiceGdsServiceServiceType.Meal:
            return React.createElement(Meal, __assign({}, props));
        case OrderAdditionalServiceGdsServiceServiceType.Seat:
            return React.createElement(Seat, __assign({}, props));
        case OrderAdditionalServiceGdsServiceServiceType.Misc:
            return React.createElement(Misc, __assign({}, props));
    }
    return null;
};
export default ExchangeServiceCard;
