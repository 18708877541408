import { __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import * as Queries from '../../__queries__/query.graphql';
import { LoginType } from '../../__generated__/graphql';
import LoginChangePassword from '../LoginChangePassword/LoginChangePassword';
var ForcedPasswordChange = function (props) {
    var _a = __read(useState(), 2), newPassword = _a[0], setNewPasword = _a[1];
    var _b = __read(useMutation(Queries.ChangePassword), 1), changePasswordMutation = _b[0];
    var _c = __read(useMutation(Queries.SendSecureCode), 1), sendCodeRequest = _c[0];
    var onPasswordSubmit = function (password) {
        setNewPasword(password);
    };
    var resendCode = function (_, __) {
        return sendCodeRequest({
            variables: {
                params: {
                    login: props.credentials.login,
                    loginType: LoginType.Host,
                    password: props.credentials.password
                }
            }
        })
            .then(function (result) { return result.data.SendSecureCode.result === true; })
            .catch(function () { return false; });
    };
    var onCodeSubmit = function (_, code) {
        return changePasswordMutation({
            variables: {
                login: props.credentials.login,
                code: code,
                oldPassword: props.credentials.password,
                newPassword: newPassword
            }
        })
            .then(function (result) {
            if (result.data.ChangePassword.result) {
                props.onChangeSuccess();
                return true;
            }
            return false;
        })
            .catch(function () {
            return false;
        });
    };
    return (React.createElement(LoginChangePassword, { mode: 'passwordChange', onEmailSubmit: resendCode, onPasswordSubmit: onPasswordSubmit, onCodeSubmit: onCodeSubmit }));
};
export default ForcedPasswordChange;
