import { __assign, __awaiter, __generator } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import TravellerForm from '../../TravellerForm';
import { addIcon } from '../../Account/Icons';
import { personIcon } from '../../OrderSummary/icons';
import { TravellerTypes } from '../types';
import { TravellerFields } from '../../TravellerForm/types';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
initI18n('Traveller');
var Traveller = function (props) {
    var _a;
    var css = useTheme('Traveller').Traveller, t = useTranslation('Traveller').t;
    var expandIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z", fill: "#9A9A9A" })));
    var fieldValueByName = function (fieldName) {
        return props.traveller.values.find(function (field) { return field.name === fieldName; }).value;
    };
    return (React.createElement(ExpansionPanel, { className: css.wrapper, expanded: props.expanded, onChange: props.handleChange },
        React.createElement(ExpansionPanelSummary, { expandIcon: expandIcon, className: css.header },
            React.createElement("h1", { className: cn(css.title__traveller, (_a = {},
                    _a[css.add] = props.traveller.id === '0',
                    _a)) },
                React.createElement("span", null,
                    props.traveller && props.traveller.id !== '0' ? personIcon : addIcon,
                    " "),
                props.traveller && props.traveller.id !== '0' ? (React.createElement("p", null,
                    React.createElement("span", null,
                        fieldValueByName(TravellerFields.FirstName) || '',
                        ' ',
                        fieldValueByName(TravellerFields.LastName) || ''),
                    React.createElement("span", null, fieldValueByName(TravellerFields.BirthDate) || ''))) : (React.createElement("p", null,
                    React.createElement("span", null, t('Add companion')))))),
        React.createElement(ExpansionPanelDetails, { className: css.content__traveller },
            React.createElement(TravellerForm, { onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, props.onSubmit(__assign({}, values))];
                            case 1: return [2 /*return*/, _a.sent()];
                        }
                    });
                }); }, onDelete: function () {
                    return props.onDelete(props.traveller.id);
                }, type: props.traveller.id !== '0' ? TravellerTypes.CompanionFilled : TravellerTypes.CompanionEmpty, traveller: props.traveller && props.traveller.id !== '0'
                    ? props.traveller
                    : __assign(__assign({}, props.traveller), { documents: [] }) }))));
};
export default Traveller;
