import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import cn from 'classnames';
import Slide from '@material-ui/core/Slide';
import { useTheme } from '../../theme';
import { closeIcon } from '../icons';
var SlideTransition = function (props) {
    return React.createElement(Slide, __assign({ direction: "up" }, props, { children: props.children }));
};
var Modal = function (_a) {
    var _b, _c;
    var isRounded = _a.isRounded, props = __rest(_a, ["isRounded"]);
    var _d = __read(useState(!props.mountOnEnter), 2), dialogIsMounted = _d[0], setDialogIsMounted = _d[1];
    var css = useTheme('Modal').Modal;
    var defaultProps = {
        scroll: 'body',
        maxWidth: 'lg'
    };
    if (props.slideUp) {
        defaultProps.TransitionComponent = SlideTransition;
    }
    useEffect(function () {
        if (props.open && !dialogIsMounted) {
            setDialogIsMounted(true);
        }
    }, [props.open, dialogIsMounted]);
    var dialogClasses = props.classes ? props.classes : {};
    return (React.createElement(Dialog, __assign({}, defaultProps, props, { classes: __assign(__assign({}, dialogClasses), { paper: cn(css.modal, (_b = {}, _b[css.modal_mobileFullScreen] = props.fullScreen, _b[css.modal_rounded] = isRounded, _b), dialogClasses.paper), scrollBody: cn(css.scrollBody, dialogClasses.scrollBody) }) }),
        props.onClose && !props.closeButtonRenderer && (React.createElement("span", { className: cn(css.closeIcon, (_c = {}, _c[css.closeIcon_mobileFullScreen] = props.fullScreen, _c)), onClick: props.onClose }, closeIcon)),
        props.onClose && props.closeButtonRenderer && props.closeButtonRenderer(props.onClose),
        dialogIsMounted && props.children));
};
export default Modal;
