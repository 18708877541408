import { __assign } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import Dialog from '../../../Modules/Exchange/components/Exchange/Dialog/Dialog';
import { getOrder } from '../../../Modules/Exchange/store/order/selectors';
import FlightSelect from '../../../FlightSelect/components/FlightSelect';
// import { calculateTotalTaxes } from '../../../FlightSelect/components/FareSelect/utils';
// import { Money } from '../../../types';
import { initI18n } from '../../../utils';
import SelectedServices from '../../../Modules/Exchange/components/Exchange/SelectedServices/SelectedServices';
var upIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", null,
        React.createElement("circle", { cx: "16", cy: "16", r: "16", fill: "#5FCA43" }),
        React.createElement("path", { d: "M9.33203 15.9999L10.507 17.1749L15.1654 12.5249V22.6666H16.832V12.5249L21.482 17.1833L22.6654 15.9999L15.9987 9.33325L9.33203 15.9999Z", fill: "white" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("path", { d: "M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z", fill: "white" })))));
var downIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", null,
        React.createElement("circle", { cx: "16", cy: "16", r: "16", fill: "#3290FF" }),
        React.createElement("path", { d: "M22.6654 15.9999L21.4904 14.8249L16.832 19.4749V9.33325H15.1654V19.4749L10.5154 14.8166L9.33203 15.9999L15.9987 22.6666L22.6654 15.9999Z", fill: "white" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("path", { d: "M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z", fill: "white" })))));
initI18n('Exchange');
var SelectedFlights = function (props) {
    var _a, _b;
    var variant = props.variant;
    var theme = useTheme('Exchange').SelectedFlights;
    var rootOrder = useSelector(getOrder);
    var t = useTranslation('Exchange').t;
    var renderFlight = useCallback(function (segmentGroup, selectedFlight) {
        var _a;
        var isOrderFlight = !selectedFlight;
        // let totalOrderSelectedFlight: Money;
        // let totalSelectedFlightPrice: Money;
        //
        // if (isOrderFlight) {
        // 	try {
        // 		totalOrderSelectedFlight = calculateTotalTaxes(rootOrder.flight.fares[0].passengerFares).get(
        // 			'Total'
        // 		);
        // 	} catch (e) {
        // 		console.warn(`can't collect total prices`, e);
        // 		totalOrderSelectedFlight = segmentGroup.price;
        // 	}
        // } else {
        // 	totalSelectedFlightPrice = selectedFlight
        // 		? calculateTotalTaxes(selectedFlight.fares[0].passengerFares).get('Total')
        // 		: props.prices.priceToPay;
        // }
        return (React.createElement(FlightSelect, { flight: __assign(__assign({}, (isOrderFlight ? rootOrder.flight : props.selectedFlights[0])), { segments: isOrderFlight
                    ? segmentGroup.segments.map(function (segment) { return rootOrder.flight.segments[segment.id]; })
                    : segmentGroup.segments.map(function (segment) { return selectedFlight.segments[segment.id]; }), pricesForFareGroups: [
                    {
                        fareFamily: __assign({}, segmentGroup.fareFamily),
                        prices: [
                            isOrderFlight
                                ? {
                                    originalPrice: segmentGroup.price,
                                    price: segmentGroup.price,
                                    flight: {
                                        id: rootOrder.flight.id,
                                        fares: rootOrder.flight.fares,
                                        canBePaidByMiles: false
                                    }
                                }
                                : {
                                    originalPrice: segmentGroup.price,
                                    price: segmentGroup.price,
                                    flight: {
                                        id: selectedFlight.id,
                                        fares: selectedFlight.fares,
                                        canBePaidByMiles: false
                                    }
                                }
                        ]
                    }
                ] }), selectedFares: [
                {
                    fareGroupId: (_a = segmentGroup.fareGroup) === null || _a === void 0 ? void 0 : _a.id,
                    prices: [
                        {
                            flight: {
                                id: isOrderFlight ? rootOrder.flight.id : selectedFlight.id
                            },
                            price: isOrderFlight ? segmentGroup.price : props.prices.priceToPay
                        }
                    ]
                }
            ], key: segmentGroup.groupId, isSelected: true }));
    }, [rootOrder.flight.segmentGroups, props.selectedFlights, props.prices]);
    return (React.createElement(React.Fragment, null,
        variant === 'newTicket' && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.header },
                React.createElement("div", { className: theme.header__name },
                    downIcon,
                    t('Change for'),
                    ' ')),
            React.createElement("div", { className: theme.flight__container },
                React.createElement("div", { className: theme.flights }, rootOrder.flight.segmentGroups.map(function (group) {
                    return renderFlight(group, null);
                }))),
            props.showRefundServicesInfo && (React.createElement(SelectedServices, { order: rootOrder, gdsServicesRefund: props.gdsServicesRefund, isLoading: props.isLoading })),
            React.createElement("div", { className: theme.header },
                React.createElement("div", { className: theme.header__name },
                    upIcon,
                    t('New flight'),
                    ' '),
                React.createElement(Dialog, { text: t('The change request was deleted. Want to create a new request?'), acceptText: t('Yes'), onAccept: function () { return props.goToFlights(); }, cancelText: t('No'), onCancel: function () { }, isRounded: true }, function (_a) {
                    var open = _a.open;
                    return (React.createElement("div", { className: theme.change, onClick: props.autoConfirmBackTo && props.goToFlights ? props.goToFlights : open }, t('Back to select')));
                })))),
        React.createElement("div", { className: theme.flight__container },
            React.createElement("div", { className: theme.flights }, (_b = (_a = props.renderSelectedFlights) === null || _a === void 0 ? void 0 : _a.call(props)) !== null && _b !== void 0 ? _b : props.selectedFlights.map(function (selectedFlight) {
                return selectedFlight.segmentGroups.map(function (group) { return renderFlight(group, selectedFlight); });
            })))));
};
export default SelectedFlights;
