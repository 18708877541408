import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { getPassengers } from '../../../store/order/selectors';
import { getSelectedPassengerIds } from '../../../store/passengers/selectors';
import { addPassengerIds, removePassengerIds, setPassengerIds } from '../../../store/passengers/actions';
import { ExchangeStep } from '../Inner/Inner';
import CommonSelect from '../CommonSelect/CommonSelect';
import { passengerIcon } from '../icons';
import { useTranslation } from 'react-i18next';
import { getPassengerName } from '../utils';
import { OverrideComponent } from '../../../../../renderProps';
import { useIsTerminal } from '../../../../../hooks';
var Passengers = function (_a) {
    var setStep = _a.setStep, addPassengers = _a.addPassengers, removePassengers = _a.removePassengers, setPassengers = _a.setPassengers;
    var passengers = useSelector(getPassengers);
    var selectedPassengerIds = useSelector(getSelectedPassengerIds);
    var isTerminal = useIsTerminal();
    var selectedPassengerIdsExchange = React.useMemo(function () {
        return selectedPassengerIds.map(function (id) { return ({ key: id }); });
    }, [selectedPassengerIds]);
    var t = useTranslation('Exchange').t;
    var passengersAsItems = React.useMemo(function () {
        var passengersWithoutLink = passengers
            .filter(function (passenger) { return !passenger.linkedTraveller; })
            .map(function (passenger) { return ({
            value: getPassengerName(passenger),
            key: passenger.id,
            ids: [passenger.id]
        }); });
        passengers
            .filter(function (passengers) { return passengers.linkedTraveller; })
            .map(function (passenger) {
            var parent = passengersWithoutLink.find(function (parent) { return parent.key === passenger.linkedTraveller.id; });
            parent.value += ", ".concat(getPassengerName(passenger));
            parent.ids.push(passenger.id);
        });
        return passengersWithoutLink;
    }, [passengers]);
    var onChangeHandler = function (value, selected) {
        var passenger = passengersAsItems.find(function (passenger) { return passenger.key === value.key; });
        if (selected) {
            addPassengers(passenger.ids);
        }
        else {
            removePassengers(passenger.ids);
        }
    };
    var onNextHandler = function () {
        if (isTerminal) {
            setStep(ExchangeStep.Reasons);
        }
        else {
            setStep(ExchangeStep.Segments);
        }
    };
    return (React.createElement(OverrideComponent, { componentProps: {
            name: t('Select passengers'),
            items: passengersAsItems,
            onChange: onChangeHandler,
            onNextHandler: onNextHandler,
            selectedKeys: selectedPassengerIdsExchange,
            icon: passengerIcon,
            setItems: setPassengers
        }, component: {
            ExchangePassengersCommonSelect: CommonSelect
        } }));
};
export default connect(null, {
    addPassengers: addPassengerIds,
    removePassengers: removePassengerIds,
    setPassengers: setPassengerIds
})(Passengers);
