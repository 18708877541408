import * as React from 'react';
import { memo, useCallback, useMemo } from 'react';
import { Autocomplete } from '../../../../index';
import { TravellerFieldEnum } from '../../../../__generated__/globalTypes';
import { ThemeProvider, useTheme } from '../../../../theme';
// let debounceTimeout = 0;
var fieldValueByType = function (travellerProfile, type) {
    return travellerProfile.values.find(function (field) { return field.type === type; }).value;
};
var LastName = memo(function (_a) {
    var label = _a.label, field = _a.field, fieldState = _a.fieldState, onTravellerSelect = _a.onTravellerSelect, onChange = _a.onChange, className = _a.className, error = _a.error, loadTravellers = _a.loadTravellers, onBlur = _a.onBlur, disabled = _a.disabled, travellerProfiles = _a.travellerProfiles, refetchTravellers = _a.refetchTravellers, variant = _a.variant, autoFocus = _a.autoFocus;
    var inputCss = useTheme('Input').Input;
    var options = (travellerProfiles === null || travellerProfiles === void 0 ? void 0 : travellerProfiles.TravellerProfiles)
        ? travellerProfiles.TravellerProfiles.filter(function (traveller) {
            return !traveller.values.some(function (value) {
                return (value.type === TravellerFieldEnum.LastName ||
                    value.type === TravellerFieldEnum.FirstName) &&
                    !value.value;
            });
        })
        : [];
    var onChangeHandler = useCallback(function (event) {
        var value = event.target.value;
        if (loadTravellers) {
            // clearTimeout(debounceTimeout);
            // debounceTimeout = window.setTimeout(() => travellerProfiles.refetch({ search: value }), 500);
            refetchTravellers(value);
            if (onChange) {
                onChange(field.name, value);
            }
        }
        fieldState.input.onChange(value);
        onBlur && onBlur();
    }, [onChange, fieldState]);
    var autocompleteTheme = useMemo(function () { return ({
        Autocomplete: {
            Autocomplete: {
                disabled: inputCss.disabled,
                inner_input: inputCss.input
            }
        }
    }); }, []);
    return (React.createElement(ThemeProvider, { value: autocompleteTheme },
        React.createElement(Autocomplete, { name: field.name, label: label, variant: variant, inputValue: fieldState.input.value, onSelect: function (travellerId) {
                onTravellerSelect &&
                    onTravellerSelect(travellerProfiles.TravellerProfiles.find(function (traveller) { return traveller.id === travellerId; }));
                if (onBlur) {
                    onBlur();
                }
            }, onInputChange: onChangeHandler, className: className, error: error, options: options
                ? options.map(function (traveller) { return ({
                    label: "".concat(fieldValueByType(traveller, TravellerFieldEnum.FirstName), " ").concat(fieldValueByType(traveller, TravellerFieldEnum.LastName)),
                    value: traveller.id
                }); })
                : [], disabled: disabled, autoFocus: autoFocus })));
});
export default LastName;
