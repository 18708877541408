import * as PaymentForm from './components/PaymentForm.css';
import * as PriceParts from './components/PriceParts/PriceParts.css';
import * as Gateway from './components/Gateway/Gateway.css';
import * as GooglePay from './components/Gateway/GooglePay/GooglePay.css';
import * as Card from './components/Gateway/Iframe/Iframe.css';
export default {
    PaymentForm: PaymentForm,
    PriceParts: PriceParts,
    Gateway: Gateway,
    GooglePay: GooglePay,
    Card: Card
};
