import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { format, addDays, subDays } from 'date-fns';
import { useQuery } from '@apollo/react-hooks';
import { useCallback, useState } from 'react';
import WeekCalendarComponent from '../../../../../WeekCalendar';
import DummyWeekCalendar from '../DummyWeekCalendar/DummyWeekCalendar';
import * as MinPricesQuery from '../../../../../__queries__/MinPrices.graphql';
import { sortSegments } from '../utils';
import { API_DATE_FORMAT } from '../../../../../utils';
import { getDateWithoutTimezone } from '../../../../../Engine/components/FastSearch/utils';
import { useConfig } from '../../../../../context';
var WeekCalendar = function (_a) {
    var _b;
    var searchParameters = _a.searchParameters, legId = _a.legId, startNewSearch = _a.startNewSearch, hidePrice = _a.hidePrice, alternativePrices = _a.alternativePrices, _c = _a.daysCount, daysCount = _c === void 0 ? 5 : _c, minDate = _a.minDate;
    // This date will be in the center of the calendar.
    var centralDate = getDateWithoutTimezone(searchParameters.segments[legId].date);
    var _d = __read(useState(false), 2), isLoading = _d[0], setIsLoading = _d[1];
    var _e = __read(useState([]), 2), prices = _e[0], setPrices = _e[1];
    var calendarDaysCount = (_b = useConfig()) === null || _b === void 0 ? void 0 : _b.Engine.calendarDaysCount;
    var daysOffset = Math.floor(calendarDaysCount / 2);
    var _f = __read(useState(searchParameters.segments.map(function (segment) { return (__assign(__assign({}, segment), { date: format(subDays(getDateWithoutTimezone(segment.date), daysOffset), API_DATE_FORMAT) })); })), 2), segments = _f[0], setSegments = _f[1];
    var refetch = useQuery(MinPricesQuery.MinPrices, { skip: true }).refetch;
    var loadMinPrices = function (variables) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, refetch(variables)];
                case 2:
                    data = (_a.sent()).data;
                    if (data && data.FlightsMinPricesInPeriod && data.FlightsMinPricesInPeriod.datesWithLowestPrices.length) {
                        setPrices(data.FlightsMinPricesInPeriod.datesWithLowestPrices);
                    }
                    else {
                        console.log('Error while loading new prices');
                    }
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        setSegments(function () {
            var segments = searchParameters.segments.map(function (segment) { return (__assign(__assign({}, segment), { date: format(subDays(getDateWithoutTimezone(segment.date), daysOffset), API_DATE_FORMAT) })); });
            if (legId > 0) {
                loadMinPrices({
                    params: __assign(__assign({}, searchParameters), { segments: segments, daysCount: calendarDaysCount, directionId: legId })
                });
            }
            else if (alternativePrices) {
                setPrices(alternativePrices);
            }
            return segments;
        });
    }, [legId, alternativePrices]);
    var loadNewDates = useCallback(function (type) { return __awaiter(void 0, void 0, void 0, function () {
        var addOrSubstractDays;
        return __generator(this, function (_a) {
            addOrSubstractDays = type === 'increase' ? addDays : subDays;
            setSegments(function (oldValue) {
                var segments = oldValue.map(function (segment) { return (__assign(__assign({}, segment), { date: format(addOrSubstractDays(getDateWithoutTimezone(segment.date), daysCount), API_DATE_FORMAT) })); });
                loadMinPrices({
                    params: __assign(__assign({}, searchParameters), { segments: segments, daysCount: calendarDaysCount, directionId: legId })
                });
                return segments;
            });
            return [2 /*return*/];
        });
    }); }, [segments]);
    var onDateClick = useCallback(function (date) {
        var segments = __spreadArray([], __read(searchParameters.segments), false);
        segments[legId] = __assign(__assign({}, segments[legId]), { date: format(date, API_DATE_FORMAT) });
        startNewSearch(__assign(__assign({}, searchParameters), { segments: sortSegments(legId, date, segments) }));
    }, [searchParameters, legId]);
    return isLoading || !alternativePrices ? (React.createElement(DummyWeekCalendar, null)) : (React.createElement(WeekCalendarComponent, { prices: prices, centralDate: centralDate, onSelect: onDateClick, onRangeChange: loadNewDates, hidePrice: hidePrice, isMilesSearch: searchParameters === null || searchParameters === void 0 ? void 0 : searchParameters.ffpMode, minDate: minDate, daysCount: daysCount }));
};
export default WeekCalendar;
