import { __assign } from "tslib";
import * as React from 'react';
import { connect } from 'react-redux';
import { useCallback } from 'react';
import { fillCheckinOrder } from '../../store/order/actions';
import { useCheckinOrder } from '../../../../__queries__/Order';
import OrderGuard from '../../../../OrderGuard/OrderGuard';
import Order from './Order/Order';
import { getCheckinOrder } from '../../store/order/selectors';
import CheckinErrorBoundary from './CheckinErrorBoundary/CheckinErrorBoundary';
import { getPassengerLastName } from '../../../../Checkout/store/passengers/selectors';
var OrderWrapper = function (_a) {
    var _b, _c, _d, _e;
    var orderId = _a.orderId, order = _a.order, fillCheckinOrder = _a.fillCheckinOrder;
    var onOrderAccessGranted = useCallback(function () { return window.location.reload(); }, []);
    var lastName;
    var ticketNumber;
    if (order && order.travellers.length) {
        lastName = (_b = getPassengerLastName(order.travellers[0])) === null || _b === void 0 ? void 0 : _b.value;
        ticketNumber = (_e = (_d = (_c = order.travellers[0]) === null || _c === void 0 ? void 0 : _c.tickets) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.number;
    }
    return (React.createElement(OrderGuard, { orderId: orderId, orderLoaded: !!order, onOrderLoad: fillCheckinOrder, onOrderAccessGranted: onOrderAccessGranted, loadOrder: useCheckinOrder },
        React.createElement(CheckinErrorBoundary, { lastName: lastName, ticketNumber: ticketNumber },
            React.createElement(Order, null))));
};
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { order: getCheckinOrder(state) })); };
var mapDispatchToProps = {
    fillCheckinOrder: fillCheckinOrder
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderWrapper);
