import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { CloseClear } from '../../../../Icons';
var WrapperModal = function (props) {
    var css = useTheme('Refund').Wrapper;
    var t = useTranslation('Refund').t;
    return (React.createElement("div", { className: cn(css.wrapper, props.className) },
        React.createElement("div", { className: css.header },
            props.renderTitle ? props.renderTitle() : React.createElement("h3", { className: css.title }, t('Refund ticket')),
            props.steps,
            React.createElement("i", { onClick: props.onClose, className: css.close }, CloseClear)),
        props.children));
};
export default WrapperModal;
