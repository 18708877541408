import * as React from 'react';
import { Button } from '../../../../index';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getOrderId } from '../../../store/order/selectors';
var checkIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.9993 29.3334C23.3631 29.3334 29.3327 23.3639 29.3327 16.0001C29.3327 8.63628 23.3631 2.66675 15.9993 2.66675C8.63555 2.66675 2.66602 8.63628 2.66602 16.0001C2.66602 23.3639 8.63555 29.3334 15.9993 29.3334ZM8.15086 15.7016L12.6054 19.8807L23.8478 9.33342L25.3327 10.7264L12.6054 22.6667L6.66602 17.0946L8.15086 15.7016Z", fill: "#5FCA43" })));
var AdditionalOrder = function (_a) {
    var orderId = _a.orderId, isNew = _a.isNew;
    var currentOrderId = useSelector(getOrderId);
    var newOrderId = orderId;
    var onOpenHandler = function () {
        window.location.href = window.location.href.replace(currentOrderId, newOrderId);
        window.location.reload();
    };
    var theme = useTheme('Checkout').AdditionalOrder;
    var t = useTranslation('Checkout').t;
    return (React.createElement("div", { className: theme.container },
        React.createElement("div", { className: theme.content },
            checkIcon,
            React.createElement("span", null,
                t(isNew ? 'Succesfully create new booking' : 'Additional booking'),
                " #",
                orderId)),
        React.createElement(Button, { onClick: onOpenHandler, variant: "outline", className: theme.button }, t('View booking'))));
};
export default AdditionalOrder;
