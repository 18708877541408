import { __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { CheckoutSteps } from '../../types';
import { useTheme } from '../../../theme';
import { checkIcon, expandIcon } from '../../Icons';
import cn from 'classnames';
import { Money } from '../../../Money';
import Collapse from '@material-ui/core/Collapse';
import i18n from 'i18next';
import StepContent from '../StepContent/StepContent';
var Step = function (_a) {
    var _b, _c;
    var step = _a.step, index = _a.index, isPassed = _a.isPassed, isActive = _a.isActive, totalPrice = _a.totalPrice, onStepSelect = _a.onStepSelect, order = _a.order, passengers = _a.passengers, onSetOrder = _a.onSetOrder, hasSelectedServices = _a.hasSelectedServices, passengerNationalities = _a.passengerNationalities;
    var css = useTheme('MobileStepbar').StepStyles;
    var _d = __read(useState(false), 2), isOpen = _d[0], setIsOpen = _d[1];
    var toggleOpen = function () {
        if ((isActive || isPassed || (totalPrice && totalPrice.currency && totalPrice.amount)) &&
            step.type !== CheckoutSteps.Payment) {
            setIsOpen(!isOpen);
        }
    };
    return (React.createElement("div", { className: cn(css.step, (_b = {},
            _b[css.step_disabled] = !isPassed && !isActive,
            _b)) },
        React.createElement("div", { className: css.step__header, onClick: toggleOpen },
            React.createElement("div", { className: css.step__name },
                React.createElement("span", { className: css.name__index }, isPassed ? checkIcon : index),
                React.createElement("span", { className: css.name__label }, step.label)),
            React.createElement("div", { className: css.step__price },
                totalPrice &&
                    totalPrice.currency &&
                    (totalPrice.amount > 0 ? (React.createElement(Money, { money: totalPrice })) : hasSelectedServices ? (React.createElement("span", null, i18n.t('MobileStepbar:Free'))) : (React.createElement("span", null, "\u2014"))),
                (isActive || isPassed) && step.type !== CheckoutSteps.Payment && (React.createElement("span", { className: cn(css.price__expand, (_c = {},
                        _c[css.price__expand_opened] = isOpen,
                        _c)) }, expandIcon)))),
        React.createElement(Collapse, { in: isOpen },
            React.createElement("div", { className: css.step__content },
                React.createElement(StepContent, { step: step, order: order, onChange: function () {
                        setIsOpen(false);
                        onStepSelect();
                        step.onClick();
                    }, passengers: passengers, onSetOrder: onSetOrder, passengerNationalities: passengerNationalities })))));
};
export default Step;
