import * as React from 'react';
import { useSelector } from 'react-redux';
import { getPassengers } from '../../../Modules/Exchange/store/order/selectors';
var Passengers = function (props) {
    var passengers = useSelector(getPassengers);
    React.useEffect(function () {
        props.setItems(passengers.map(function (passenger) { return passenger.id; }));
        props.onNextHandler();
    }, [passengers]);
    return null;
};
export default Passengers;
