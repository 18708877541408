import * as React from 'react';
export var planetIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM15.97 8H18.92C17.96 6.35 16.43 5.07 14.59 4.44C15.19 5.55 15.65 6.75 15.97 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4 12C4 12.69 4.1 13.36 4.26 14H7.64C7.56 13.34 7.5 12.68 7.5 12C7.5 11.32 7.56 10.66 7.64 10H4.26C4.1 10.64 4 11.31 4 12ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM5.08 8H8.03C8.35 6.75 8.81 5.55 9.41 4.44C7.57 5.07 6.04 6.34 5.08 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM9.5 12C9.5 12.68 9.57 13.34 9.66 14H14.34C14.43 13.34 14.5 12.68 14.5 12C14.5 11.32 14.43 10.65 14.34 10H9.66C9.57 10.65 9.5 11.32 9.5 12ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.5 12C16.5 12.68 16.44 13.34 16.36 14H19.74C19.9 13.36 20 12.69 20 12C20 11.31 19.9 10.64 19.74 10H16.36C16.44 10.66 16.5 11.32 16.5 12Z", fill: "#9A9A9A" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "2", y: "2", width: "20", height: "20" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM15.97 8H18.92C17.96 6.35 16.43 5.07 14.59 4.44C15.19 5.55 15.65 6.75 15.97 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4 12C4 12.69 4.1 13.36 4.26 14H7.64C7.56 13.34 7.5 12.68 7.5 12C7.5 11.32 7.56 10.66 7.64 10H4.26C4.1 10.64 4 11.31 4 12ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM5.08 8H8.03C8.35 6.75 8.81 5.55 9.41 4.44C7.57 5.07 6.04 6.34 5.08 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM9.5 12C9.5 12.68 9.57 13.34 9.66 14H14.34C14.43 13.34 14.5 12.68 14.5 12C14.5 11.32 14.43 10.65 14.34 10H9.66C9.57 10.65 9.5 11.32 9.5 12ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.5 12C16.5 12.68 16.44 13.34 16.36 14H19.74C19.9 13.36 20 12.69 20 12C20 11.31 19.9 10.64 19.74 10H16.36C16.44 10.66 16.5 11.32 16.5 12Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var loyaltyIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.1803 3.86304L12 0L9.81971 3.86304L6 1.6077L6.04333 6.04333L1.6077 6L3.86304 9.81971L0 12L3.86304 14.1803L1.6077 18L6.04333 17.9567L6 22.3923L9.81971 20.137L12 24L14.1803 20.137L18 22.3923L17.9567 17.9567L22.3923 18L20.137 14.1803L24 12L20.137 9.81971L22.3923 6L17.9567 6.04333L18 1.6077L14.1803 3.86304ZM12.4635 13.4069C12.6499 13.6088 12.7432 13.8822 12.7432 14.2271C12.7432 14.5594 12.6398 14.8244 12.4331 15.0221C12.2264 15.2156 11.9448 15.3123 11.5881 15.3123C11.1586 15.3123 10.8283 15.1798 10.5973 14.9148C10.3703 14.6456 10.2568 14.2608 10.2568 13.7603H8.5C8.5 14.6225 8.72695 15.3144 9.18085 15.836C9.6388 16.3533 10.2832 16.6583 11.114 16.7508V18H12.0805V16.7445C12.8343 16.6646 13.426 16.4017 13.8556 15.9558C14.2852 15.51 14.5 14.9295 14.5 14.2145C14.5 13.8318 14.4433 13.4974 14.3298 13.2114C14.2163 12.9211 14.0522 12.6646 13.8374 12.4416C13.6226 12.2145 13.3592 12.0126 13.0471 11.836C12.7351 11.6551 12.3359 11.4637 11.8495 11.2618C11.3673 11.0599 11.037 10.8623 10.8587 10.6688C10.6803 10.4753 10.5912 10.2187 10.5912 9.89905C10.5912 9.55415 10.6824 9.28496 10.8647 9.09148C11.0471 8.8938 11.3045 8.79495 11.6368 8.79495C11.9732 8.79495 12.2406 8.92114 12.4392 9.1735C12.6418 9.42166 12.7432 9.79811 12.7432 10.3028H14.5C14.5 9.48265 14.2974 8.81598 13.8921 8.30284C13.4868 7.78549 12.9255 7.47424 12.2082 7.36909V6H11.2356V7.35016C10.5101 7.42587 9.92857 7.69085 9.49088 8.14511C9.05319 8.59937 8.83435 9.18191 8.83435 9.89275C8.83435 10.3049 8.89716 10.6604 9.0228 10.959C9.15248 11.2576 9.3308 11.5184 9.55775 11.7413C9.78875 11.96 10.0643 12.1556 10.3845 12.3281C10.7047 12.4963 11.0856 12.6667 11.5274 12.8391C11.9691 13.0116 12.2812 13.2008 12.4635 13.4069Z", fill: "white" })));
export var blueAddIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 13H17V11H13V7H11V11H7V13H11V17H13V13Z" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "2", y: "2", width: "20", height: "20" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 13H17V11H13V7H11V11H7V13H11V17H13V13Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var whiteAddIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 13H17V11H13V7H11V11H7V13H11V17H13V13Z", fill: "white" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "2", y: "2", width: "20", height: "20" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 13H17V11H13V7H11V11H7V13H11V17H13V13Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
