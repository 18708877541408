var _a;
import i18n from 'i18next';
import { AviaPassengerType } from '../__generated__/globalTypes';
import { RouteType } from './types';
import { SPECIAL_CATEGORIES } from '../SearchForm/utils';
var labels = (_a = {},
    _a[AviaPassengerType.ADT] = 'Adult',
    _a[AviaPassengerType.CLD] = 'Child',
    _a[AviaPassengerType.INF] = 'Infant',
    _a);
export var getRouteType = function (segments) {
    var result = RouteType.ComplexRoute;
    if (segments.length === 1) {
        result = RouteType.OneWay;
    }
    else if (segments.length === 2 && segments[0].departure.iata === segments[1].arrival.iata) {
        result = RouteType.RoundTrip;
    }
    return result;
};
export var getPassengersCount = function (passengers) {
    return passengers.reduce(function (result, passenger) { return result + passenger.count; }, 0);
};
export var getPassengersSummary = function (passengers) {
    var parsedPassengers = [];
    var numOfInfants = 0;
    var numOfSpecials = 0;
    var specialPassengerType = null;
    passengers.forEach(function (passenger) {
        // Count all types of infants.
        if (passenger.passengerType === AviaPassengerType.INS || passenger.passengerType === AviaPassengerType.INF) {
            numOfInfants += passenger.count;
        }
        else if (SPECIAL_CATEGORIES.includes(passenger.extendedPassengerType)) {
            numOfSpecials += passenger.count;
            specialPassengerType = passenger.extendedPassengerType;
        }
        else {
            parsedPassengers.push(passenger);
        }
    });
    if (numOfSpecials) {
        parsedPassengers.push({
            passengerType: AviaPassengerType.ADT,
            extendedPassengerType: specialPassengerType,
            count: numOfSpecials
        });
    }
    if (numOfInfants) {
        parsedPassengers.push({
            passengerType: AviaPassengerType.INF,
            extendedPassengerType: null,
            count: numOfInfants
        });
    }
    return parsedPassengers
        .filter(function (passenger) { return passenger.count > 0; })
        .map(function (passenger) {
        return "".concat(passenger.count, " ").concat(i18n.t("QuickSearchForm:".concat(passenger.extendedPassengerType
            ? passenger.extendedPassengerType
            : labels[passenger.passengerType]), {
            count: passenger.count
        }));
    });
};
