import * as React from 'react';
import PaymentComponent from '../Payment/Payment';
import { useSelector } from 'react-redux';
import { getLastExchange } from '../../../store/order/selectors';
import { ExchangeStep } from '../Inner/Inner';
import { OverrideComponent } from '../../../../../renderProps';
import { usePaymentMethodsQuery } from '@websky/graphql';
var Payment = function (props) {
    var order = useSelector(getLastExchange);
    var lastExchangeOrder = useSelector(getLastExchange);
    var goToOrder = function () {
        props.setStep(ExchangeStep.Request);
    };
    var baseUrl = window.location.href;
    var _a = usePaymentMethodsQuery({
        variables: {
            withKaspi: true,
            withItransfer: true,
            withGpay: true,
            withCredit: true,
            withCard: true,
            parameters: {
                orderId: lastExchangeOrder.id,
                backRedirectUrl: "".concat(baseUrl, "/success"),
                backRedirectUrlOnFail: "".concat(baseUrl, "/failed")
            }
        }
    }), data = _a.data, loading = _a.loading;
    React.useEffect(function () {
        props.setLoading(loading);
    }, [loading]);
    return !loading ? (React.createElement(OverrideComponent, { componentProps: {
            exchangeOrder: order,
            successPayment: props.successPayment,
            goToOrder: goToOrder,
            paymentMethods: data === null || data === void 0 ? void 0 : data.PaymentMethods,
            failedPayment: props.failedPayment
        }, component: { ExchangePayment: PaymentComponent } })) : (React.createElement(React.Fragment, null));
};
export default Payment;
