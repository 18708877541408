import React, { useMemo } from 'react';
import cn from 'classnames';
import { Money } from '../../../../../Money/components/Money';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import { currentCurrency } from '../../../../../utils';
var MobileHandLuggageExcessCard = function (_a) {
    var _b, _c;
    var services = _a.services, onClick = _a.onClick, selected = _a.selected, onToggle = _a.onToggle, readonly = _a.readonly;
    var css = useTheme('BaggageService').BaggageServiceStyles;
    var t = useTranslation('BaggageService').t;
    var minPrice = useMemo(function () {
        var value = { amount: Infinity, currency: currentCurrency };
        services.forEach(function (baggage) {
            if (baggage.baggage.price.amount < value.amount) {
                value = baggage.baggage.price;
            }
        });
        return value;
    }, [services]);
    return (React.createElement("div", { className: css.service, onClick: onClick },
        React.createElement("div", { className: css.service__name }, t('Increasing the size of carry-on baggage')),
        React.createElement("div", { className: css.service__sizes }),
        React.createElement("div", { className: cn(css.service__icon, css.hand_luggage_excess__disabled, (_b = {},
                _b[css.hand_luggage_excess__active] = selected,
                _b)) }),
        React.createElement("div", { className: css.service__values }, services[0].baggage.size),
        React.createElement("div", { className: css.service__price },
            React.createElement(Money, { money: minPrice, multiplyBy: services[0].baggage.segmentsId.length })),
        !readonly && (React.createElement("div", { className: cn(css.add__button, (_c = {},
                _c[css.remove__button] = selected,
                _c)), onClick: function (event) {
                onToggle();
                event.stopPropagation();
            } }))));
};
export default MobileHandLuggageExcessCard;
