import React, { useMemo } from 'react';
import { useTheme } from '../../../theme';
import { format } from '../../../utils';
var HistoryElement = function (props) {
    var css = useTheme('OrderChangeHistoryModal').OrderChangeHistoryModal;
    var _a = useMemo(function () {
        if (!props.element.dateTime) {
            return { date: undefined, time: undefined };
        }
        return {
            date: format(props.element.dateTime, 'dd.MM.yyyy'),
            time: format(props.element.dateTime, 'HH:mm:ss')
        };
    }, [props.element.dateTime]), date = _a.date, time = _a.time;
    return (React.createElement("div", { className: css.row },
        React.createElement("div", { className: css.time }, "".concat(time, "\n").concat(date, "\n(").concat(props.element.changeSource, ")")),
        React.createElement("div", { className: css.action }, props.element.historyRemarks.map(function (el, i) {
            return (React.createElement(React.Fragment, { key: i },
                el,
                React.createElement("br", null)));
        }))));
};
export default HistoryElement;
