import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import Tooltip from '../../../../Tooltip';
import { useTheme } from '../../../../theme';
import { Button } from '../../../../index';
import * as Auth from '../../../../__queries__/query.graphql';
import Link from '../../../../Link';
import { useConfig } from '../../../../context';
var lastVisibleError = '';
var Register = function (_a) {
    var onSuccess = _a.onSuccess, login = _a.login, loginType = _a.loginType;
    var css = useTheme('LoginPage').Register;
    var t = useTranslation('LoginPage').t;
    var _b = __read(useMutation(Auth.SendSecureCode), 1), sendCode = _b[0];
    var companyInfo = useConfig().global.companyInfo;
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, sendCode({
                        variables: {
                            params: {
                                login: login,
                                loginType: loginType
                            }
                        }
                    })];
                case 1:
                    data = _a.sent();
                    if (data.data.SendSecureCode.result) {
                        onSuccess();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var validate = function (values) {
        var errors = {};
        if (!values['checkbox']) {
            errors['checkbox'] = 'Please, read the rules of service and accept it';
            lastVisibleError = errors['checkbox'];
        }
        return errors;
    };
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.content }, t('You are logged in for the first time, please read the terms and conditions and give consent')),
        React.createElement("div", { className: css.actions },
            React.createElement(Form, { onSubmit: onSubmit, validate: validate, render: function (_a) {
                    var handleSubmit = _a.handleSubmit;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(Field, { name: "checkbox", type: "checkbox", render: function (_a) {
                                var input = _a.input, meta = _a.meta;
                                return (React.createElement(FormControlLabel, { className: css.checkbox, control: React.createElement(Tooltip, { open: meta.submitFailed && !input.value, title: t(lastVisibleError), placement: "top-start" },
                                        React.createElement(Checkbox, { color: "primary", classes: {
                                                colorPrimary: css.checkbox,
                                                checked: css.checkbox_checked
                                            }, checked: input.checked, onChange: input.onChange })), label: React.createElement("span", { className: css.label },
                                        t('I agree to the'),
                                        ' ',
                                        React.createElement(Link, { target: "_blank", className: css.link, action: "".concat(companyInfo.privacyPolicy) }, t('terms of service'))) }));
                            } }),
                        React.createElement(Button, { type: "submit" }, t('Next'))));
                } }))));
};
export default Register;
