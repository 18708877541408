import { __assign } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { isBookedOrConfirmed } from '../../../../store/order/selectors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '../../../../../Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from '../../../../../index';
import { useConfig } from '../../../../../context';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { useEffect } from 'react';
var lastVisibleError = '';
var PassengersToolbarCheckbox = function () {
    var isOrderBookedOrConfirmed = useSelector(isBookedOrConfirmed);
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').PassengersToolbar;
    var _a = useField('terms', {
        type: 'checkbox',
        initialValue: false,
        validate: function (value) {
            if (!value) {
                return t('Please, read the rules of service and accept it');
            }
        }
    }), input = _a.input, meta = _a.meta;
    useEffect(function () {
        // safari bug, when checkbox onBlur didn't fired
        // and form didn't update it's state
        input.onBlur();
    }, [input.value]);
    if (meta.error) {
        lastVisibleError = meta.error;
    }
    var companyInfo = useConfig().global.companyInfo;
    return (React.createElement(React.Fragment, null, !isOrderBookedOrConfirmed && (React.createElement(FormControlLabel, { classes: {
            root: css.root,
            label: css.label
        }, control: React.createElement(Tooltip, { open: meta.submitFailed && !input.value, title: lastVisibleError, placement: "top-start" },
            React.createElement(Checkbox, { color: "primary", classes: {
                    colorPrimary: css.checkbox,
                    checked: css.checkbox_checked
                }, checked: input.checked, inputProps: __assign({}, input) })), label: React.createElement("span", null,
            t('I accept the'),
            ' ',
            companyInfo.legal ? (React.createElement(React.Fragment, null,
                React.createElement(Link, { target: "_blank", action: "".concat(companyInfo.legal) }, t('terms of agreement')),
                companyInfo.privacyPolicy && (React.createElement(React.Fragment, null,
                    "\u00A0",
                    React.createElement("span", null, t('and')),
                    "\u00A0",
                    React.createElement(Link, { target: "_blank", action: "".concat(companyInfo.privacyPolicy) }, t('processing of my personal data')))))) : (t('terms of agreement'))) }))));
};
export default PassengersToolbarCheckbox;
