import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Service from '../../../../../../Service/components/Service';
import MealServiceComponent from '../../../../../../MealService/components/MealService';
import MealComponent from '../../../../../../Meal';
import { convertMealObject } from '../../../../../types';
import { useTheme } from '../../../../../../theme';
import { useMemo } from 'react';
import { getMealReduxCallbacks } from '../../../../../utils';
var foodIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24.0002 1.33334V6.66667H30.6668L28.4668 28.7067C28.3335 29.8 27.4135 30.6667 26.2935 30.6667H24.0268L24.0002 20C24.0002 14.36 19.8668 10.88 14.9735 9.74667L14.6668 6.66667H21.3335V1.33334H24.0002ZM2.68016 30.6533C1.9335 30.6533 1.3335 30.0533 1.3335 29.3067V28H21.3468V29.3067C21.3468 30.0533 20.7468 30.6533 20.0002 30.6533H2.68016ZM11.3335 11.9867C6.3335 11.9867 1.3335 14.6667 1.3335 20H21.3335C21.3335 14.6667 16.3335 11.9867 11.3335 11.9867ZM21.3335 25.3333V22.6667H1.3335V25.3333H21.3335Z", fill: "white" }),
    React.createElement("mask", { id: "mask0", mask: "alpha", maskUnits: "userSpaceOnUse", x: "1", y: "1", width: "30", height: "30" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24.0002 1.33334V6.66667H30.6668L28.4668 28.7067C28.3335 29.8 27.4135 30.6667 26.2935 30.6667H24.0268L24.0002 20C24.0002 14.36 19.8668 10.88 14.9735 9.74667L14.6668 6.66667H21.3335V1.33334H24.0002ZM2.68016 30.6533C1.9335 30.6533 1.3335 30.0533 1.3335 29.3067V28H21.3468V29.3067C21.3468 30.0533 20.7468 30.6533 20.0002 30.6533H2.68016ZM11.3335 11.9867C6.3335 11.9867 1.3335 14.6667 1.3335 20H21.3335C21.3335 14.6667 16.3335 11.9867 11.3335 11.9867ZM21.3335 25.3333V22.6667H1.3335V25.3333H21.3335Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
/**
 * @deprecated
 */
var MealService = function (props) {
    var hasProduct = false;
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').Services;
    var selectedMeals = props.segments.map(function (segment) { return ({
        meals: {},
        segmentId: segment.segmentId
    }); });
    var totalPrice = {
        amount: 0,
        currency: null
    };
    var minServicePrice = null;
    var newFood = props.services.map(function (meal) {
        var selectedMeal = props.selectedProducts.filter(function (product) { return product.serviceId === meal.id; });
        var newMealModel = convertMealObject(meal);
        if (selectedMeal.length) {
            var uniqueSegmentIds_1 = new Set(selectedMeal
                .map(function (selectedMeal) { return selectedMeal.segmentIds.map(function (id) { return id; }); })
                .reduce(function (a, b) { return a.concat(b); }, []));
            selectedMeal.forEach(function (selectedMeal) {
                // Fill selected meals map object.
                uniqueSegmentIds_1.forEach(function (segmentId) {
                    var selectedMealsOnCurrentSegment = selectedMeals.find(function (meal) {
                        return selectedMeal.segmentIds.includes(meal.segmentId);
                    });
                    if (!selectedMealsOnCurrentSegment) {
                        selectedMeals.push({
                            meals: {},
                            segmentId: segmentId
                        });
                    }
                });
                selectedMeals.find(function (segment) { return selectedMeal.segmentIds.includes(segment.segmentId); }).meals[selectedMeal.serviceId] = {
                    meal: newMealModel,
                    count: selectedMeal.count
                };
                totalPrice.amount += meal.price.amount * selectedMeal.count;
                totalPrice.currency = meal.price.currency;
            });
            hasProduct = true;
        }
        if (!minServicePrice || meal.price.amount < minServicePrice.amount) {
            minServicePrice = meal.price;
        }
        return newMealModel;
    });
    var mealCallbacks = useMemo(function () { return getMealReduxCallbacks(props.addService, props.removeService, props.deleteAllTypesOnSegmentAction); }, [props.addService, props.removeService, props.deleteAllTypesOnSegmentAction]);
    var onMealAdd = function (meal, segmentId, passengerId) {
        mealCallbacks.onMealAdd(meal, segmentId, passengerId);
    };
    var onMealRemove = function (meal, segmentId, passengerId) {
        mealCallbacks.onMealRemove(meal, segmentId, passengerId);
    };
    var onMealClear = function (meal, segmentId) {
        mealCallbacks.onClear(meal, segmentId);
    };
    var mealServiceIds = props.services.map(function (service) { return service.id; });
    var selectedMeal = props.selectedProducts
        .filter(function (product) { return mealServiceIds.includes(product.serviceId); })
        .map(function (meal) { return ({
        mealId: meal.serviceId,
        passengerId: props.passengerId.toString(),
        confirmedCount: meal.confirmedCount,
        count: meal.count,
        segmentId: meal.segmentIds[0]
    }); });
    var onClear = function () {
        props.selectedProducts.forEach(function (service) {
            props.removeService({
                passengerId: props.passengerId.toString(),
                serviceId: service.serviceId,
                segmentId: service.segmentIds[0],
                allowedSegments: [service.segmentIds],
                count: service.count
            });
        });
    };
    return (React.createElement(Service, { icon: foodIcon, minPrice: minServicePrice, label: t('Meal'), onCancel: onClear, dialogType: "popup", dialogHeader: "".concat(t('Meal'), ", ").concat(props.passengerName), hasProducts: hasProduct, price: totalPrice, dialogControlsClassName: css.mealService__controls, canAddProducts: true, className: props.className, onClick: props.onCardClick, isLoading: props.isLoading },
        React.createElement(MealServiceComponent, { meals: newFood, segments: props.segments, selectedMeals: selectedMeal, onMobileBack: function () { return null; }, onMobileProceed: function () { return null; }, mealRenderer: function (meal, segmentId) {
                return (React.createElement(MealComponent, { key: "".concat(meal.id, "_").concat(segmentId), meal: meal, segmentId: segmentId, counters: [], onAddForAll: function (meal) {
                        return props.passengers.forEach(function (_a) {
                            var passengerId = _a.id;
                            return onMealAdd(meal, segmentId, props.passengerId.toString(10));
                        });
                    }, onIncrease: function () { return onMealAdd(meal, segmentId, props.passengerId.toString()); }, onDecrease: function () { return onMealRemove(meal, segmentId, props.passengerId.toString()); }, onClear: function () { return onMealClear(meal, segmentId); } }));
            } })));
};
export default MealService;
