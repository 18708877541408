import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import { capitalizeFirstLetter } from '../../../../utils';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import { isMoneyGuard } from './utils';
var excludedKeys = ['total', 'airticket'];
var Services = function (props) {
    var css = useTheme('Payment').Payment;
    var t = useTranslation('Payment').t;
    var servicesPrices = props.servicesPrices;
    return (React.createElement("div", { className: css.services }, Object.keys(servicesPrices || {})
        .filter(function (serviceKey) { return isMoneyGuard(servicesPrices[serviceKey]) && !excludedKeys.includes(serviceKey); })
        .map(function (serviceKey, index) { return (React.createElement("div", { key: "".concat(serviceKey, "_").concat(index) },
        React.createElement("span", null,
            t("PaymentForm:".concat(capitalizeFirstLetter(serviceKey))),
            ":"),
        React.createElement(Money, { money: servicesPrices[serviceKey], moneyClassName: css.money }))); })));
};
export default memo(Services);
