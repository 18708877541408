import { combineReducers } from 'redux';
import { passengersReducer } from './passengers/reducers';
import { segmentsReducer } from './segments/reducers';
import { redeemMilesReducer } from './redeemMiles/reducers';
import { vouchersReducer } from './vouchers/reducers';
import { recentSearchesReducer } from './recentSearches/reducers';
import { passengerTypesReducer } from './passengerTypes/reducers';
import promoCodeReducer from './promoCode/reducers';
export var rootReducer = combineReducers({
    segments: segmentsReducer,
    passengers: passengersReducer,
    redeemMiles: redeemMilesReducer,
    voucher: vouchersReducer,
    recentSearches: recentSearchesReducer,
    promoCode: promoCodeReducer,
    passengerTypes: passengerTypesReducer
});
