import * as React from 'react';
import { ExareStatus } from '../../../../../../__generated__/graphql';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../Button/Button';
import { useTheme } from '../../../../../../theme';
import Dialog from '../../Dialog/Dialog';
import { initI18n } from '../../../../../../utils';
import { useIsTerminal } from '../../../../../../hooks';
initI18n('Exchange');
var RequestControls = function (_a) {
    var order = _a.order, deleteRequest = _a.deleteRequest, goToNewExchange = _a.goToNewExchange, goToPayment = _a.goToPayment, isLoading = _a.isLoading;
    var t = useTranslation('Exchange').t;
    var isTerminal = useIsTerminal();
    var theme = useTheme('Exchange').RequestControls;
    if (order.status === ExareStatus.TimelimitExpired ||
        order.status === ExareStatus.InProcess ||
        order.status === ExareStatus.AwaitingPayment) {
        return (React.createElement("div", { className: theme.container },
            React.createElement("div", { className: theme.text }, t('If you change your mind, or want to create a new request with different parameters, delete this request')),
            !isLoading && (React.createElement(Dialog, { text: t('The change request was deleted. Want to create a new request?'), acceptText: t('Yes'), onAccept: function () { return deleteRequest(order); }, cancelText: t('No'), onCancel: function () { }, isRounded: isTerminal }, function (_a) {
                var open = _a.open;
                return (React.createElement(Button, { variant: "flat", size: "large", className: theme.refuse, onClick: open }, t('Delete request')));
            })),
            order.status === ExareStatus.AwaitingPayment && (React.createElement(React.Fragment, null,
                React.createElement("div", null),
                React.createElement(Button, { variant: "flat", size: "large", className: theme.button, onClick: goToPayment, isLoading: isLoading }, t('Payment exchange'))))));
    }
    return (React.createElement("div", { className: theme.container },
        React.createElement("div", null),
        React.createElement(Button, { variant: "flat", size: "large", className: theme.button, onClick: goToNewExchange }, t('Add new request'))));
};
export default RequestControls;
