import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Passengers from './Passengers/Passengers';
import Stepbar from '../../../../../Stepbar';
import { getCheckinHasCompleted, getCheckinIsBlockedForPayment, getCheckinOrder, getIsContactsInfoEmpty, getTotalPriceToPay, hasBaggageServices, hasInsurances, hasMealServices, hasSeatMap, hasTransfers, isExpired } from '../../../store/order/selectors';
import { CheckinStep, useCheckinAvailabilityInfo, useSteps } from '../../../utils';
import Meal from './routes/Meal';
import Baggage from './routes/Baggage';
import Payment from './routes/Payment';
import Extras from './routes/Extras';
import PaymentResult from '../../../../../PaymentResult';
import PaymentTimeLimitPage from '../../../../../PaymentTimeLimitPage';
import * as CheckinCompleteMutation from '../../../../../__queries__/CheckinComplete.graphql';
import * as GetCheckinOrderQuery from '../../../../../__queries__/GetCheckinOrder.graphql';
import { setCheckinOrder } from '../../../store/order/actions';
import { useTheme } from '../../../../../theme';
import { CheckinGoal, reachGoal } from '../../../../../analytics';
import ProtectedRoute from '../../../../../ProtectedRoute/ProtectedRoute';
import { CheckinOrderStatus } from '../../../../../__generated__/globalTypes';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import MobileStepbar from '../../../../../MobileStepbar/components/MobileStepbar';
import { convertCheckinOrder } from '../../../../../MobileStepbar/utils';
import { CheckoutSteps, MobileStepbarMode } from '../../../../../MobileStepbar/types';
import CustomerContactsModal from '../../../../../CustomerContactsForm/Modal/CustomerContactsModal';
import { useCustomerContactsForm } from '../../../../../CustomerContactsForm/hooks';
import { getIsServicesSaving } from '../../../store/servicesLoading/selectors';
import SimpleLoader from '../../../../../SimpleLoader';
import PreselectedSeats from './PreselectedSeats/PreselectedSeats';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../../../__generated__/graphql';
var PaymentResultStatus;
(function (PaymentResultStatus) {
    PaymentResultStatus["Success"] = "success";
    PaymentResultStatus["Fail"] = "fail";
})(PaymentResultStatus || (PaymentResultStatus = {}));
var Order = function () {
    var t = useTranslation('Checkin').t;
    var css = useTheme('Checkin').Order;
    var isCheckinCompleted = useSelector(getCheckinHasCompleted);
    var isBlockedForPayment = useSelector(getCheckinIsBlockedForPayment);
    var hasBaggage = useSelector(hasBaggageServices);
    var hasMeals = useSelector(hasMealServices);
    var hasSeats = useSelector(hasSeatMap);
    var isCheckinExpired = useSelector(isExpired);
    var order = useSelector(getCheckinOrder);
    var hasInsurance = useSelector(hasInsurances);
    var hasTransfer = useSelector(hasTransfers);
    var totalPriceToPay = useSelector(getTotalPriceToPay);
    var isContactsInfoEmpty = useSelector(getIsContactsInfoEmpty);
    var isServicesSaving = useSelector(getIsServicesSaving);
    var history = useHistory();
    var _a = __read(React.useState(null), 2), paymentResult = _a[0], setPaymentResult = _a[1];
    var _b = __read(React.useState(false), 2), orderRefetching = _b[0], setOrderRefetching = _b[1];
    var _c = useCustomerContactsForm(), saveClientInfo = _c.saveClientInfo, closeContactsModal = _c.closeContactsModal, openContactsModal = _c.openContactsModal, isContactsModalOpen = _c.isContactsModalOpen, isClientInfoSaving = _c.loading;
    var _d = useSteps(), goToNextStep = _d.goToNextStep, setStep = _d.setStep, getNextStep = _d.getNextStep;
    var pathname = history.location.pathname;
    var handleReturn = useCallback(function () {
        history.push('/');
    }, []);
    var showExpireModal = useCheckinAvailabilityInfo(600000).showExpireModal;
    var getOrder = useQuery(GetCheckinOrderQuery.GetCheckinOrder, { skip: true, fetchPolicy: 'no-cache' }).refetch;
    var _e = __read(useMutation(CheckinCompleteMutation.CheckinComplete), 1), completeCheckin = _e[0];
    var saveClientInfoAndLoadOrder = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var getOrderResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveClientInfo({ variables: __assign(__assign({}, data), { orderId: order.id }) })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, getOrder({ id: order.id })];
                case 2:
                    getOrderResult = (_a.sent()).data;
                    if (getOrderResult === null || getOrderResult === void 0 ? void 0 : getOrderResult.CheckinOrder) {
                        setOrder(getOrderResult.CheckinOrder);
                    }
                    closeContactsModal();
                    goToNextStep(CheckinStep.Extras);
                    return [2 /*return*/];
            }
        });
    }); };
    var dispatch = useDispatch();
    var setOrder = function (order) {
        dispatch(setCheckinOrder(order));
    };
    var steps = {
        findBooking: {
            label: t('Passengers'),
            onClick: !isCheckinCompleted ? function () { return setStep(CheckinStep.Passengers); } : null,
            type: CheckoutSteps.Passengers
        },
        baggage: {
            isHidden: !hasBaggage,
            label: t('Baggage'),
            onClick: !isCheckinCompleted ? function () { return setStep(CheckinStep.Baggage); } : null,
            type: CheckoutSteps.Baggage
        },
        meal: {
            isHidden: !hasMeals,
            label: t('Meal'),
            onClick: !isCheckinCompleted ? function () { return setStep(CheckinStep.Meal); } : null,
            type: CheckoutSteps.Meal
        },
        seats: {
            label: t('Seats'),
            onClick: !isCheckinCompleted ? function () { return setStep(CheckinStep.Seats); } : null,
            type: CheckoutSteps.Seats
        },
        extras: {
            isHidden: !hasInsurance && !hasTransfer,
            label: t('Extras'),
            onClick: !isCheckinCompleted ? function () { return setStep(CheckinStep.Extras); } : null,
            type: CheckoutSteps.Extras
        },
        payment: {
            label: t('Payment'),
            onClick: !isCheckinCompleted ? function () { return setStep(CheckinStep.Payment); } : null,
            type: CheckoutSteps.Payment
        },
        boardingPass: {
            label: t('Boarding pass')
        }
    };
    var _f = __read(useState(Object.keys(steps).findIndex(function (stepKey) { return pathname.includes(stepKey); })), 2), activeStep = _f[0], setActiveStep = _f[1];
    useEffect(function () {
        var filteredSteps = {};
        if (!isCheckinCompleted && isBlockedForPayment && !pathname.includes(CheckinStep.Payment)) {
            setStep(CheckinStep.Payment);
        }
        Object.keys(steps).forEach(function (stepKey) {
            if (steps.hasOwnProperty(stepKey) && !steps[stepKey].isHidden) {
                filteredSteps[stepKey] = steps[stepKey];
            }
        });
        if (!isCheckinCompleted && /^\/(\d+)\/?$/.test(pathname)) {
            setActiveStep(0); // passengers
        }
        else if (isCheckinCompleted) {
            setActiveStep(Object.keys(filteredSteps).length - 1); // boarding pass
        }
        else {
            setActiveStep(Object.keys(filteredSteps).findIndex(function (stepKey) { return pathname.includes(stepKey); }));
        }
    }, [pathname, steps]);
    var onProceed = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var paymentIsRequired, nextStep, _a, data, errors, _b, data_1, errors_1, e_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (pathname.includes(CheckinStep.Seats)) {
                        nextStep = getNextStep(CheckinStep.Seats);
                        // if next returned step is payment and order.paymentIsRequired === false
                        // do not redirect to payment, instead run completeCheckin mutation
                        if (nextStep.includes('payment') && (order === null || order === void 0 ? void 0 : order.paymentIsRequired) === false) {
                        }
                        else {
                            // run completeCheckin only if contacts info isn't empty
                            if (!isContactsInfoEmpty) {
                                goToNextStep(CheckinStep.Seats);
                                return [2 /*return*/];
                            }
                        }
                    }
                    return [4 /*yield*/, getOrder({
                            id: order.id
                        })];
                case 1:
                    _a = _c.sent(), data = _a.data, errors = _a.errors;
                    if (errors) {
                        throw errors;
                    }
                    else if (data.CheckinOrder) {
                        if (data.CheckinOrder.status !== CheckinOrderStatus.Started) {
                            throw new Error('Wrong order status!');
                        }
                        setOrder(data.CheckinOrder);
                        paymentIsRequired = data.CheckinOrder.paymentIsRequired;
                    }
                    if (!paymentIsRequired) return [3 /*break*/, 2];
                    if (isContactsInfoEmpty) {
                        openContactsModal();
                        return [2 /*return*/];
                    }
                    goToNextStep(CheckinStep.Extras);
                    return [2 /*return*/];
                case 2:
                    _c.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, completeCheckin({
                            variables: {
                                id: order.id
                            }
                        })];
                case 3:
                    _b = _c.sent(), data_1 = _b.data, errors_1 = _b.errors;
                    if (errors_1) {
                        console.error(errors_1);
                    }
                    else if (data_1 === null || data_1 === void 0 ? void 0 : data_1.CheckinComplete) {
                        reachGoal(CheckinGoal.CompleteWithoutPayment);
                        setOrder(data_1.CheckinComplete);
                        setStep(CheckinStep.Passengers);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _c.sent();
                    console.error(e_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [order.id, pathname]);
    var onCheckinStart = useCallback(function () {
        setStep(CheckinStep.Baggage);
    }, [setStep]);
    var getTravellers = useMemo(function () {
        return order.travellers.map(function (traveller) {
            var _a, _b;
            return ({
                id: traveller.id,
                type: traveller.type,
                values: traveller.values,
                services: traveller.services.gdsServices ? traveller.services.gdsServices.services : [],
                seats: (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.map(function (seat) {
                    var _a, _b, _c, _d, _e;
                    return ({
                        id: (_a = seat.seat) === null || _a === void 0 ? void 0 : _a.number,
                        type: OrderAdditionalServiceGdsServiceServiceType.Seat,
                        deckId: '0',
                        letter: seat === null || seat === void 0 ? void 0 : seat.letter,
                        row: seat.row.toString(),
                        number: (_b = seat.seat) === null || _b === void 0 ? void 0 : _b.number,
                        rfisc: (_c = seat.seat) === null || _c === void 0 ? void 0 : _c.rfisc,
                        price: (_d = seat.seat) === null || _d === void 0 ? void 0 : _d.price,
                        segmentId: (_e = seat.segment) === null || _e === void 0 ? void 0 : _e.id,
                        isConfirmed: seat.isConfirmed
                    });
                })
            });
        });
    }, [order]);
    var refetchOrderAfterPayment = function () {
        var initialInterval = 500;
        var step = 1.5;
        setOrderRefetching(true);
        var polling = function (interval) { return __awaiter(void 0, void 0, void 0, function () {
            var newOrder, e_2;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getOrder({
                                id: order.id
                            })];
                    case 1:
                        newOrder = _c.sent();
                        if (((_b = (_a = newOrder.data) === null || _a === void 0 ? void 0 : _a.CheckinOrder) === null || _b === void 0 ? void 0 : _b.status) === CheckinOrderStatus.Confirmed) {
                            setOrder(newOrder.data.CheckinOrder);
                            setOrderRefetching(false);
                        }
                        else {
                            setTimeout(function () { return polling(interval * step); }, interval);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _c.sent();
                        setOrderRefetching(false);
                        console.warn(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        setTimeout(function () { return polling(initialInterval); }, initialInterval);
    };
    useEffect(function () {
        if (paymentResult === PaymentResultStatus.Success) {
            reachGoal(CheckinGoal.Paid);
            if (isCheckinCompleted) {
                goToNextStep(CheckinStep.Passengers, true);
            }
            else {
                refetchOrderAfterPayment();
            }
        }
        else if (paymentResult === PaymentResultStatus.Fail) {
            reachGoal(CheckinGoal.PaymentCancelled);
            if (isCheckinCompleted) {
                goToNextStep(CheckinStep.Passengers, true);
            }
        }
    }, [paymentResult]);
    return (React.createElement("div", null,
        (isServicesSaving || orderRefetching) && React.createElement(SimpleLoader, null),
        !showExpireModal && !isCheckinExpired && (React.createElement(React.Fragment, null,
            !isCheckinCompleted && (React.createElement(React.Fragment, null,
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Stepbar, { steps: steps, activeStep: activeStep })),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    React.createElement(MobileStepbar, { mode: MobileStepbarMode.Checkin, totalPrice: totalPriceToPay, steps: steps, currentStep: activeStep, order: convertCheckinOrder(order), passengers: getTravellers, onSetOrder: function () { } })))),
            React.createElement(CustomerContactsModal, { onContactsSubmit: saveClientInfoAndLoadOrder, isLoading: isClientInfoSaving, open: isContactsModalOpen, onClose: closeContactsModal }),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/:id(\\d+)", exact: true, render: function () { return React.createElement(Passengers, { onCheckinStart: onCheckinStart }); } }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/baggage", isAccessible: !isCheckinCompleted && hasBaggage, redirectTo: isCheckinCompleted
                        ? getNextStep(CheckinStep.Passengers)
                        : !hasBaggage
                            ? getNextStep(CheckinStep.Baggage)
                            : '', render: function () { return React.createElement(Baggage, null); } }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/meal", isAccessible: !isCheckinCompleted && hasMeals, redirectTo: isCheckinCompleted
                        ? getNextStep(CheckinStep.Passengers)
                        : !hasMeals
                            ? getNextStep(CheckinStep.Meal)
                            : '', render: function () { return React.createElement(Meal, null); } }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/seats", isAccessible: !isCheckinCompleted && !!hasSeats, redirectTo: getNextStep(CheckinStep.Passengers), render: function () { return React.createElement(PreselectedSeats, { onProceed: onProceed }); } }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/extras", isAccessible: !isCheckinCompleted, redirectTo: getNextStep(CheckinStep.Passengers), render: function () { return React.createElement(Extras, { onProceed: onProceed, onSetOrder: setOrder }); } }),
                React.createElement(Route, { exact: true, path: "/:id(\\d+)/payment", render: function () {
                        if (isCheckinCompleted) {
                            goToNextStep(CheckinStep.Passengers, true);
                            return null;
                        }
                        setPaymentResult(null);
                        return React.createElement(Payment, null);
                    } }),
                React.createElement(Route, { path: "/:id(\\d+)/payment/successfull", render: function () {
                        setPaymentResult(PaymentResultStatus.Success);
                        return (React.createElement(PaymentResult, { result: "success", onClick: function () {
                                setStep(CheckinStep.Passengers);
                            } }));
                    } }),
                React.createElement(Route, { path: "/:id(\\d+)/payment/failed", render: function () {
                        setPaymentResult(PaymentResultStatus.Fail);
                        return React.createElement(PaymentResult, { result: "fail", onClick: function () { return setStep(CheckinStep.Payment); } });
                    } })))),
        (showExpireModal || isCheckinExpired) && (React.createElement("div", { className: css.timelimit__wrp },
            React.createElement(PaymentTimeLimitPage, { title: t('Sorry,'), text: t('online check-in is closed for this flight'), buttonText: t('OK'), onClick: handleReturn })))));
};
export default Order;
