import { __read, __spreadArray, __values } from "tslib";
import { useMemo } from 'react';
import { FilterAttrs } from './types';
import * as dateFns from 'date-fns';
import { useTranslation } from 'react-i18next';
import { dateFormat } from './const';
import { flightFilters } from './utils';
import { useTheme } from '../../theme';
var AIRLINE_CODE_REGEX = /^(\s*[a-zA-Z0-9]+\s*(,\s*[a-zA-Z0-9]+\s*)*)*$/;
var FLIGHT_NUM_REGEX = /^(\s*\d+\s*(,\s*\d+\s*)*)*$/;
var DATE_REGEX = /\d\d.\d\d.\d\d/;
var ERROR_TEXT = 'Incorrect value';
export var useFieldValidators = function () {
    var t = useTranslation('FlightsFilter').t;
    var errText = t(ERROR_TEXT);
    return useMemo(function () {
        var backDate = new Date();
        var validateStr = function (value, regExp) {
            if (value && !regExp.test(value)) {
                return errText;
            }
            return undefined;
        };
        var validateDate = function (value) { return validateStr(value, DATE_REGEX); };
        var validateForm = function (values) {
            var _a;
            var beginDateStr = values[FilterAttrs.BEGIN_PERIOD];
            var endDateStr = values[FilterAttrs.END_PERIOD];
            if (!validateDate(beginDateStr) && !validateDate(endDateStr)) {
                var beginDate = dateFns.parse(beginDateStr, dateFormat, backDate);
                var endDate = dateFns.parse(endDateStr, dateFormat, backDate);
                if (beginDate > endDate) {
                    return _a = {},
                        _a[FilterAttrs.BEGIN_PERIOD] = errText,
                        _a[FilterAttrs.END_PERIOD] = errText,
                        _a;
                }
            }
            return undefined;
        };
        return {
            validateAirlineCode: function (value) { return validateStr(value, AIRLINE_CODE_REGEX); },
            validateFlightNum: function (value) { return validateStr(value, FLIGHT_NUM_REGEX); },
            validateDate: function (value) { return validateStr(value, DATE_REGEX); },
            validateForm: validateForm
        };
    }, []);
};
export var useFiltersActivity = function (filterValues) {
    return useMemo(function () {
        return {
            nonStopFilterActived: !!filterValues[FilterAttrs.NON_STOP],
            extFilterActived: Object.entries(filterValues).some(function (item) { return item[0] !== FilterAttrs.NON_STOP && item[1]; })
        };
    }, [filterValues]);
};
export var useFilterDescription = function (filterValues, active) {
    var t = useTranslation('FlightsFilter').t;
    return useMemo(function () {
        var e_1, _a;
        if (!active) {
            return t('Filters');
        }
        var desc = '';
        var isFirst = true;
        try {
            for (var _b = __values(flightFilters.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var filter = _c.value;
                if (filter.attr === FilterAttrs.NON_STOP) {
                    continue;
                }
                var filterDesc = filter.toString(filterValues, t);
                if (!!filterDesc) {
                    if (!isFirst) {
                        desc += ', ';
                    }
                    desc += filterDesc;
                    isFirst = false;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return desc;
    }, [filterValues, active]);
};
export var useDataPickerTheme = function () {
    var css = useTheme('FlightsFilter');
    var dataPickerTheme = useMemo(function () {
        return {
            Datepicker: {
                MonthsWrapperStyles: {
                    wrapper: css.MonthsWrapper.wrapper,
                    nav_prev: css.MonthsWrapper.nav_prev,
                    nav_next: css.MonthsWrapper.nav_next
                },
                MonthHeaderStyles: {
                    monthHeader__select_month: css.MonthHeader.monthHeader__select_month,
                    monthHeader__select_year: css.MonthHeader.monthHeader__select_year
                },
                WeeklyHeaderStyles: {
                    day: css.WeeklyHeader.day,
                    dayOfWeekName: css.WeeklyHeader.dayOfWeekName
                },
                DayStyles: {
                    day: css.Day.day,
                    day_selected: css.Day.day_selected,
                    day_fromOtherMonth: css.Day.day_fromOtherMonth
                }
            }
        };
    }, []);
    return dataPickerTheme;
};
export var useOrderedFlights = function (flights) {
    return useMemo(function () {
        var e_2, _a, e_3, _b, e_4, _c, e_5, _d;
        if (!flights) {
            return [];
        }
        // group flights
        var directfligths = [];
        var notDirectfligths = [];
        var fligthsWithOutDuration = [];
        try {
            for (var flights_1 = __values(flights), flights_1_1 = flights_1.next(); !flights_1_1.done; flights_1_1 = flights_1.next()) {
                var flight = flights_1_1.value;
                var segmentsCount = 0;
                var withDuration = true;
                try {
                    for (var _e = (e_3 = void 0, __values(flight.segments)), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var segment = _f.value;
                        segmentsCount++;
                        if (!segment.duration) {
                            withDuration = false;
                        }
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
                if (!withDuration) {
                    fligthsWithOutDuration.push(flight);
                }
                else if (segmentsCount === 1) {
                    directfligths.push(flight);
                }
                else {
                    notDirectfligths.push(flight);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (flights_1_1 && !flights_1_1.done && (_a = flights_1.return)) _a.call(flights_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        // calc flights durations
        var flightsDurations = new Map();
        try {
            for (var notDirectfligths_1 = __values(notDirectfligths), notDirectfligths_1_1 = notDirectfligths_1.next(); !notDirectfligths_1_1.done; notDirectfligths_1_1 = notDirectfligths_1.next()) {
                var flight = notDirectfligths_1_1.value;
                var flightDuration = new Date(0);
                try {
                    for (var _g = (e_5 = void 0, __values(flight.segments)), _h = _g.next(); !_h.done; _h = _g.next()) {
                        var segment = _h.value;
                        if (segment.duration) {
                            flightDuration = dateFns.add(flightDuration, segment.duration);
                        }
                        if (segment.transferDuration) {
                            flightDuration = dateFns.add(flightDuration, segment.transferDuration);
                        }
                    }
                }
                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                finally {
                    try {
                        if (_h && !_h.done && (_d = _g.return)) _d.call(_g);
                    }
                    finally { if (e_5) throw e_5.error; }
                }
                flightsDurations.set(flight, flightDuration.getTime());
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (notDirectfligths_1_1 && !notDirectfligths_1_1.done && (_c = notDirectfligths_1.return)) _c.call(notDirectfligths_1);
            }
            finally { if (e_4) throw e_4.error; }
        }
        // sort flights
        var getFlightNum = function (flight) {
            var e_6, _a;
            try {
                for (var _b = __values(flight.segments), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var s = _c.value;
                    return Number.parseInt(s.flightNumber);
                }
            }
            catch (e_6_1) { e_6 = { error: e_6_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_6) throw e_6.error; }
            }
            return Number.MAX_VALUE;
        };
        var orderedDirectfligths = directfligths.sort(function (a, b) { return getFlightNum(a) - getFlightNum(b); });
        var orderedNotDirectfligths = notDirectfligths.sort(function (a, b) { return flightsDurations.get(a) - flightsDurations.get(b); });
        return __spreadArray(__spreadArray(__spreadArray([], __read(orderedDirectfligths.map(function (flight) { return flight.source; })), false), __read(orderedNotDirectfligths.map(function (flight) { return flight.source; })), false), __read(fligthsWithOutDuration.map(function (flight) { return flight.source; })), false);
    }, [flights]);
};
