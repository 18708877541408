import * as React from 'react';
export var personIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8ZM14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8ZM12 13C9.33 13 4 14.34 4 17V20H20V17C20 14.34 14.67 13 12 13ZM6 17.01V18H18V17C17.8 16.29 14.7 15 12 15C9.3 15 6.2 16.29 6 17.01Z", fill: "#9A9A9A" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "4", y: "4", width: "16", height: "16" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8ZM14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8ZM12 13C9.33 13 4 14.34 4 17V20H20V17C20 14.34 14.67 13 12 13ZM6 17.01V18H18V17C17.8 16.29 14.7 15 12 15C9.3 15 6.2 16.29 6 17.01Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var personsIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.5 8.5C12.5 10.43 10.93 12 9 12C7.07 12 5.5 10.43 5.5 8.5C5.5 6.57 7.07 5 9 5C10.93 5 12.5 6.57 12.5 8.5ZM10.5 8.5C10.5 7.67 9.83 7 9 7C8.17 7 7.5 7.67 7.5 8.5C7.5 9.33 8.17 10 9 10C9.83 10 10.5 9.33 10.5 8.5ZM9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM9 15.75C7.21 15.75 5.18 16.42 4.34 17H13.66C12.82 16.42 10.79 15.75 9 15.75ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM18.5 8.5C18.5 10.43 16.93 12 15 12C14.46 12 13.96 11.87 13.5 11.65C14.13 10.76 14.5 9.67 14.5 8.5C14.5 7.33 14.13 6.24 13.5 5.35C13.96 5.13 14.46 5 15 5C16.93 5 18.5 6.57 18.5 8.5Z", fill: "#9A9A9A" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "2", y: "5", width: "20", height: "14" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.5 8.5C12.5 10.43 10.93 12 9 12C7.07 12 5.5 10.43 5.5 8.5C5.5 6.57 7.07 5 9 5C10.93 5 12.5 6.57 12.5 8.5ZM10.5 8.5C10.5 7.67 9.83 7 9 7C8.17 7 7.5 7.67 7.5 8.5C7.5 9.33 8.17 10 9 10C9.83 10 10.5 9.33 10.5 8.5ZM9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM9 15.75C7.21 15.75 5.18 16.42 4.34 17H13.66C12.82 16.42 10.79 15.75 9 15.75ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM18.5 8.5C18.5 10.43 16.93 12 15 12C14.46 12 13.96 11.87 13.5 11.65C14.13 10.76 14.5 9.67 14.5 8.5C14.5 7.33 14.13 6.24 13.5 5.35C13.96 5.13 14.46 5 15 5C16.93 5 18.5 6.57 18.5 8.5Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var downloadIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.9998 12H25.3332L15.9998 21.3333L6.6665 12H11.9998V4H19.9998V12ZM6.6665 26.6667V24H25.3332V26.6667H6.6665Z", fill: "currentColor" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "6", y: "4", width: "20", height: "23" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.9998 12H25.3332L15.9998 21.3333L6.6665 12H11.9998V4H19.9998V12ZM6.6665 26.6667V24H25.3332V26.6667H6.6665Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var expiryIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.9915 1.66699C5.3915 1.66699 1.6665 5.40033 1.6665 10.0003C1.6665 14.6003 5.3915 18.3337 9.9915 18.3337C14.5998 18.3337 18.3332 14.6003 18.3332 10.0003C18.3332 5.40033 14.5998 1.66699 9.9915 1.66699ZM9.99984 16.667C6.3165 16.667 3.33317 13.6837 3.33317 10.0003C3.33317 6.31699 6.3165 3.33366 9.99984 3.33366C13.6832 3.33366 16.6665 6.31699 16.6665 10.0003C16.6665 13.6837 13.6832 16.667 9.99984 16.667ZM9.1665 5.83366H10.4165V10.2087L14.1665 12.4337L13.5415 13.4587L9.1665 10.8337V5.83366Z", fill: "currentColor" })));
