import { __assign } from "tslib";
import * as React from 'react';
import { Field } from '../../../../../Passenger/components/Field/Field';
import { FieldsGroupType } from '../utils';
import LoyaltyGroup from './LoyaltyGroup/LoyaltyGroup';
import { TravellerFieldEnum } from '../../../../../__generated__/globalTypes';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
var Group = function (props) {
    var _a;
    if (props.groupType === FieldsGroupType.Loyalty) {
        return React.createElement(LoyaltyGroup, __assign({}, props));
    }
    var theme = useTheme('PassengerForm').Group;
    return (React.createElement("div", { className: cn(theme.container, (_a = {},
            _a[theme.identity] = props.groupType === FieldsGroupType.Identity,
            _a)) }, props.fields.map(function (field, index) {
        var _a;
        return (React.createElement(Field, { disabled: field.readonly || props.disabled, key: "".concat(field.name, "_").concat(index), field: field, passengerId: props.passengerId, onTravellerSelect: props.onTravellerSelect, passengerType: props.passengerType, className: cn(theme.passengerField, (_a = {},
                _a[theme.field_sex] = field.type === TravellerFieldEnum.Gender,
                _a[theme.field_birthdate] = field.type === TravellerFieldEnum.BirthDate,
                _a)), loadTravellers: true }));
    })));
};
export default Group;
