import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Button from '../../../Button/Button';
import { useTheme } from '../../../theme';
import { useConfig } from '../../../context';
var OrderStub = function (_a) {
    var className = _a.className;
    var css = useTheme('OrderSummary').OrderStub;
    var t = useTranslation('OrderSummary').t;
    var engineURL = useConfig().Account.engineURL;
    var onClick = function () {
        if (engineURL) {
            window.location.href = engineURL;
        }
    };
    return (React.createElement("div", { className: cn(className, css.stub__background) },
        React.createElement("div", { className: css.stub__content },
            React.createElement("div", null,
                React.createElement("p", null, t('Currently there are no active bookings made through your private profile.')),
                React.createElement(Button, { onClick: onClick }, t('Search flight'))))));
};
export default OrderStub;
