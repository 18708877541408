import * as React from 'react';
import { useMemo } from 'react';
import Money from '../../../../Money';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
var deleteIcon = (React.createElement("svg", { width: "20", height: "21", viewBox: "0 0 20 21", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M15.8337 5.60811L14.6587 4.43311L10.0003 9.09144L5.34199 4.43311L4.16699 5.60811L8.82533 10.2664L4.16699 14.9248L5.34199 16.0998L10.0003 11.4414L14.6587 16.0998L15.8337 14.9248L11.1753 10.2664L15.8337 5.60811Z", fill: "currentColor" })));
var ServiceItem = function (_a) {
    var _b;
    var serviceWithPayload = _a.service, onClick = _a.onClick, onClear = _a.onClear, segments = _a.segments, travellersServices = _a.travellersServices;
    var theme = useTheme('ExtraServices').ServicesList;
    var service = serviceWithPayload.service, count = serviceWithPayload.count, availableSegmentLabels = serviceWithPayload.availableSegmentLabels, availablePassengerTypeLabels = serviceWithPayload.availablePassengerTypeLabels;
    var isPaid = useMemo(function () {
        var _a;
        return (_a = Object.values(travellersServices)) === null || _a === void 0 ? void 0 : _a.some(function (traveller) {
            var _a;
            return (_a = traveller[serviceWithPayload.service.id]) === null || _a === void 0 ? void 0 : _a.some(function (service) { return (service === null || service === void 0 ? void 0 : service.confirmedCount) > 0; });
        });
    }, [travellersServices]);
    var t = useTranslation('ExtraServices').t;
    var onClearHandler = function (e) {
        e.stopPropagation();
        onClear(service.id);
    };
    return (React.createElement("tr", { onClick: function () { return onClick(serviceWithPayload); }, className: cn((_b = {}, _b[theme.selected] = count > 0, _b)) },
        React.createElement("td", { className: theme.service__rfisc }, service.rfisc),
        React.createElement("td", { className: theme.service__name }, service.name),
        React.createElement("td", null, availableSegmentLabels.length === segments.length ? t('ALL') : availableSegmentLabels.join(', ')),
        React.createElement("td", null, availablePassengerTypeLabels.join(', ')),
        React.createElement("td", { className: theme.service__price },
            React.createElement(Money, { money: service.price })),
        React.createElement("td", null, count > 0 && (React.createElement("div", { className: theme.action },
            count,
            !isPaid && React.createElement("span", { onClick: onClearHandler }, deleteIcon))))));
};
export default ServiceItem;
