import { __read } from "tslib";
export var clear = function (_a, state, _b) {
    var changeValue = _b.changeValue;
    Object.keys(state.fields).forEach(function (fieldName) {
        changeValue(state, fieldName, function () { return undefined; });
    });
};
export var setValueAll = function (_a, state, _b) {
    var _c = __read(_a, 2), productIds = _c[0], value = _c[1];
    var changeValue = _b.changeValue;
    productIds.forEach(function (id) {
        changeValue(state, "'".concat(id, "'"), function () { return value; });
    });
};
export var checkIsAtLeastOneSelected = function (values) {
    return Object.values(values).some(function (value) { return value.length > 0 || value === true; });
};
