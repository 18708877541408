import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Reason from '../Reason/Reason';
import { Unvoluntary } from '../../types';
import { useTheme } from '../../../theme';
import RefundPaper from '../RefundPaper/RefundPaper';
import { ExareReason } from '../../../__generated__/graphql';
var ReasonForm = function (props) {
    var css = useTheme('Refund').ReasonForm;
    var renderReason = useCallback(function (reason) { return (React.createElement(Reason, { key: reason.value, checked: reason.checked, value: reason.value, label: reason.title, className: css.reason })); }, [props.reasons]);
    var renderGroup = useCallback(function (reasonGroup) {
        return (React.createElement(FormControl, { key: reasonGroup.title, component: "fieldset", className: css.groupWrapper },
            React.createElement(FormLabel, { className: css.groupTitle, classes: { focused: cn(css.groupTitle, css.active) } }, reasonGroup.title),
            React.createElement(RadioGroup, { onChange: function (e) { return props.onReasonSelect(ExareReason[e.target.value] || Unvoluntary[e.target.value]); }, name: reasonGroup.title, className: css.reasons }, reasonGroup.reasons.map(renderReason))));
    }, [props.reasons]);
    return React.createElement(RefundPaper, { className: cn(props.className) },
        " ",
        props.reasons.map(renderGroup));
};
export default ReasonForm;
