import { __assign, __read } from "tslib";
import { AviaPassengerType } from './../../../../__generated__/globalTypes';
import { TravellerFieldAdditionalTypes } from './../../types';
import { docTypes, genderTypes } from '../helpers';
import { format } from 'date-fns';
import { TravellerFieldEnum } from '../../../../__generated__/globalTypes';
import { TravellerFieldEnumPI } from '../../types';
var LINEFEED = /\n/;
var SLASH = /\//g;
var SLASH_AND_SYMBOLS_AFTER = /\/.+$/g;
var LONG_MINUS_CHARS = /–/gm;
var MINUS_CHAR_GLOBAL = /\-/g;
var MINUS_AND_SYMBOLS_AFTER = /\-.+$/g;
var getNameAndType = function (type) {
    return {
        name: type
            .split('')
            .map(function (e, idx) { return (idx === 0 ? e.toLowerCase() : e); })
            .join(''),
        type: type
    };
};
export var prettifyName = function (str) {
    var result = str;
    if (result.match(MINUS_CHAR_GLOBAL)) {
        result = result.replace(MINUS_AND_SYMBOLS_AFTER, '');
    }
    if (result.match(SLASH)) {
        result = result.replace(SLASH_AND_SYMBOLS_AFTER, '');
    }
    return result;
};
export var getBirthDate = function (str) {
    var yearFromStr = str.slice(-2);
    var year = +"20".concat(yearFromStr) > new Date().getFullYear() ? "19".concat(yearFromStr) : "20".concat(yearFromStr);
    return format(new Date("".concat(str.replace(' ', '').slice(0, 5)).concat(year)), 'dd.MM.yyyy');
};
export var getDate = function (str) {
    return format(new Date(str), 'dd.MM.yyyy');
};
export var getValueDependsOnType = function (str, type) {
    return __assign({ value: str ? str.trim() : null }, getNameAndType(type));
};
export var getPassengerIndex = function (str, type) {
    var passengerIndex = str.split(SLASH)[1];
    return __assign({ value: passengerIndex ? passengerIndex.replace('P', '') : null }, getNameAndType(type));
};
export var getPassengerType = function (str, type) {
    var passengerTypeDependOnGender = {
        M: AviaPassengerType.ADT,
        F: AviaPassengerType.ADT,
        O: AviaPassengerType.ADT,
        MI: AviaPassengerType.INF,
        FI: AviaPassengerType.INF
    };
    return __assign({ value: passengerTypeDependOnGender ? passengerTypeDependOnGender[str] : null }, getNameAndType(type));
};
// <КОД_КОММ> (КОД_СЕГМ) (Служ_код)-(ДОК)-(Код страны)-(Номер_док)-(Нац)-<ДАТ_РОЖД>-(ПОЛ)-(ДАТ_ИСТ)>-(Ф-И-О)-(Индикатор владельца паспорта)/(Ассоциация пассажира)
export var amadeusPassengersData = function (str) {
    var strWihoutLongMinus = str.replace(LONG_MINUS_CHARS, '-');
    var data = strWihoutLongMinus.split(LINEFEED) || [strWihoutLongMinus];
    return data.map(function (command) {
        var commands = command.split('-');
        commands.splice(0, 1);
        var _a = __read(commands, 10), docType = _a[0], // (ДОК)
        country = _a[1], // (Код страны)
        docNumber = _a[2], // (Номер_док)
        nationality = _a[3], // (Нац)
        birthDate = _a[4], // <ДАТ_РОЖД>
        gender = _a[5], // (ПОЛ)
        expiryDate = _a[6], // (ДАТ_ИСТ)>
        lastName = _a[7], // -Ф-
        firstName = _a[8], // -И-(Индикатор владельца паспорта)/(Ассоциация пассажира) || -И/(Ассоциация пассажира)
        lastElement = _a[9] // -O-(Индикатор владельца паспорта)/(Ассоциация пассажира) || (Индикатор владельца паспорта)/(Ассоциация пассажира)
        ;
        var middleName = lastElement ? prettifyName(lastElement) : '';
        middleName = middleName.length > 3 ? middleName : '';
        return [
            getValueDependsOnType(prettifyName(firstName), TravellerFieldEnum.FirstName),
            getValueDependsOnType(lastName, TravellerFieldEnum.LastName),
            getValueDependsOnType(middleName, TravellerFieldEnum.MiddleName),
            getValueDependsOnType(getBirthDate(birthDate), TravellerFieldEnum.BirthDate),
            getValueDependsOnType(genderTypes[gender], TravellerFieldEnum.Gender),
            getPassengerType(gender, TravellerFieldAdditionalTypes.PassengerType),
            getValueDependsOnType(nationality, TravellerFieldEnum.Nationality),
            getValueDependsOnType(docTypes[docType.trim()], TravellerFieldEnum.DocType),
            getValueDependsOnType(docNumber, TravellerFieldEnum.DocNumber),
            getValueDependsOnType(getDate(expiryDate), TravellerFieldEnum.DocExpiryDate),
            getValueDependsOnType(country, TravellerFieldEnum.Country),
            getPassengerIndex(command, TravellerFieldEnumPI.PassengerIndex)
        ];
    });
};
