import * as React from 'react';
import cn from 'classnames';
import MultiCity from './MultiCity/MultiCity';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import SearchFormPromoCode from './PromoCode/PromoCode';
import PayByMiles from './PayByMiles/PayByMiles';
var Bottom = React.memo(function (props) {
    var _a = useTheme('SearchForm'), css = _a.Bottom, SearchForm = _a.SearchForm;
    var _b = useConfig().SearchForm, enableComplexRoute = _b.enableComplexRoute, proMode = _b.proMode, payByMiles = _b.payByMiles;
    return (React.createElement("div", { className: cn(SearchForm.row, css.bottom) },
        enableComplexRoute && !proMode && React.createElement(MultiCity, null),
        props.renderPromoCode ? props.renderPromoCode() : React.createElement(SearchFormPromoCode, null),
        payByMiles && React.createElement(PayByMiles, null)));
});
export default Bottom;
