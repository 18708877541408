import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import TabButton from '../../../../Button/components/TabButton/TabButton';
import { useTheme } from '../../../../theme';
import { Exchangeable } from '../../../../Icons';
import CircularProgress from '@material-ui/core/CircularProgress';
var ScheduleTrigger = function (props) {
    var t = useTranslation('FlightsListControls').t;
    var css = useTheme('Schedule').ScheduleTrigger;
    var onClick = props.onClick, isLoading = props.isLoading;
    return (React.createElement(TabButton, { className: cn(css.button, css.schedule), inverted: true, onClick: onClick },
        t('Schedule'),
        isLoading ? React.createElement(CircularProgress, { className: css.loader, size: 15, color: 'inherit' }) : Exchangeable));
};
export default ScheduleTrigger;
