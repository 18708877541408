import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { useTheme } from '../theme';
import { initI18n } from '../utils';
initI18n('PaymentResult');
var PaymentResult = function (_a) {
    var _b, _c, _d, _e;
    var result = _a.result, onClick = _a.onClick, _f = _a.isModal, isModal = _f === void 0 ? false : _f;
    var css = useTheme('PaymentResult').PaymentResult;
    var t = useTranslation('PaymentResult').t;
    return (React.createElement("div", { className: cn(css.wrapper, (_b = {},
            _b[css.wrapper_inModal] = isModal,
            _b)) },
        React.createElement("div", { className: cn(css.header, (_c = {},
                _c[css.success] = result === 'success',
                _c[css.failed] = result === 'fail',
                _c)) },
            React.createElement("div", { className: cn(css.paymentIcon, (_d = {},
                    _d[css.paymentIcon_success] = result === 'success',
                    _d[css.paymentIcon_failed] = result === 'fail',
                    _d)) }),
            React.createElement("div", { className: cn(css.dividerIcon, (_e = {},
                    _e[css.dividerIcon_success] = result === 'success',
                    _e[css.dividerIcon_failed] = result === 'fail',
                    _e)) })),
        React.createElement("div", { className: css.content },
            React.createElement("h1", { className: css.title }, result === 'success' ? t('Payment successfully complete') : t('Payment has failed')),
            React.createElement(Button, { onClick: function () { return onClick(true); }, className: css.redirectButton }, result === 'success' ? t('View booking') : t('Try again')))));
};
export default PaymentResult;
