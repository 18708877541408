import { StepType } from '../../types';
export var initialCheckoutOrderState = {
    steps: [
        {
            url: 'seats',
            type: StepType.Seats
        },
        {
            url: 'baggage',
            type: StepType.Baggage
        },
        {
            url: 'meal',
            type: StepType.Meals
        },
        {
            url: 'passengers',
            type: StepType.Passengers
        },
        {
            url: 'payment',
            type: StepType.Payment,
            conditions: {
                bookedOnly: true
            }
        }
    ]
};
export var checkoutReducer = function (state) {
    if (state === void 0) { state = initialCheckoutOrderState; }
    return state;
};
