import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useTheme } from '../../../../theme';
import RetrieveBooking from '../../../../RetrieveBooking';
import * as FindOrderQuery from '../../../../__queries__/FindOrder.graphql';
import { Mode } from '../../../../RetrieveBooking/components/RetrieveBooking';
import { useParams } from 'react-router-dom';
var FindOrder = function (_a) {
    var initialId = _a.initialId, onSuccess = _a.onSuccess, callSubmit = _a.callSubmit;
    var css = useTheme('Checkout').FindOrder;
    var _b = __read(useMutation(FindOrderQuery.FindOrder), 1), unlockOrder = _b[0];
    var _c = useParams(), id = _c.id, secret = _c.secret;
    var onSubmit = useCallback(function (id, secret) {
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, data, errors, result, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, unlockOrder({
                                variables: {
                                    params: {
                                        id: id,
                                        secret: secret
                                    }
                                }
                            })];
                    case 1:
                        _a = _b.sent(), data = _a.data, errors = _a.errors;
                        result = !errors && data && data.FindOrder;
                        resolve(!!result);
                        if (result) {
                            onSuccess(result);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        reject(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    }, [initialId]);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement(RetrieveBooking, { mode: Mode.Checkout, initialId: id !== null && id !== void 0 ? id : initialId, initialSecret: secret, submitWithInitialValues: id && secret && callSubmit, onSubmit: onSubmit })));
};
export default FindOrder;
