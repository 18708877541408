import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { UserLoyaltyCardLevels } from '../../../../__generated__/globalTypes';
var LoyaltyCard = function (props) {
    var _a;
    var css = useTheme('Loyalty').LoyaltyCard;
    return (React.createElement("div", { className: cn(css.lightjet__card, (_a = {},
            _a[css.gold] = props.currentLevel === UserLoyaltyCardLevels.gold,
            _a[css.silver] = props.currentLevel === UserLoyaltyCardLevels.silver,
            _a[css.platinum] = props.currentLevel === UserLoyaltyCardLevels.platinum,
            _a)) },
        React.createElement("h1", { className: css.lightjet__title },
            React.createElement("span", null, props.loyaltyProgrammName),
            " ",
            React.createElement("span", { className: css.lightjet__divider }),
            " ",
            props.currentLevel),
        React.createElement("div", { className: css.lightjet__owner },
            React.createElement("h3", null, props.cardNumber),
            props.loyaltyCardOwnerName && React.createElement("h3", null, props.loyaltyCardOwnerName))));
};
export default LoyaltyCard;
