import * as React from 'react';
import { Button } from '../../../../index';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
var Controls = function (props) {
    var t = useTranslation('SeatMap').t;
    var theme = useTheme('SeatMap').Controls;
    return (React.createElement("div", { className: theme.controls },
        props.segmentId > 0 && props.onBack && (React.createElement(Button, { variant: "flat", className: cn(theme.button, theme.back), onClick: props.onBack }, t('Back'))),
        props.segmentId + 1 !== props.segmentsCount ? (React.createElement(Button, { variant: "flat", className: theme.button, onClick: props.onSelectNextFlight }, t('Next flight'))) : (React.createElement(Button, { variant: "flat", className: theme.button, onClick: props.onProceed }, t('Continue')))));
};
export default Controls;
