import { __read } from "tslib";
import { useCallback, useMemo, useState } from 'react';
export var useSwipeableBaggageViews = function (items, itemsToView) {
    var _a = __read(useState(0), 2), viewIndex = _a[0], setViewIndex = _a[1];
    var chunkedItems = useMemo(function () {
        var chunkedItems = [];
        var pointer = 0;
        while (pointer < items.length) {
            chunkedItems.push(items.slice(pointer, pointer + itemsToView));
            pointer += itemsToView;
        }
        return chunkedItems;
    }, [items]);
    var isCanSwipeLeft = viewIndex > 0;
    var isCanSwipeRight = viewIndex < chunkedItems.length - 1;
    var swipeLeft = useCallback(function () {
        if (isCanSwipeLeft) {
            setViewIndex(function (viewIndex) {
                return viewIndex - 1;
            });
        }
    }, [isCanSwipeLeft]);
    var swipeRight = useCallback(function () {
        if (isCanSwipeRight) {
            setViewIndex(function (viewIndex) {
                return viewIndex + 1;
            });
        }
    }, [isCanSwipeRight]);
    return {
        viewIndex: viewIndex,
        chunkedItems: chunkedItems,
        isCanSwipeLeft: isCanSwipeLeft,
        isCanSwipeRight: isCanSwipeRight,
        swipeLeft: swipeLeft,
        swipeRight: swipeRight
    };
};
