import { __assign } from "tslib";
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { BaggageItem } from '../../../BaggageList';
import { convertBaggageOnBaggageItem } from '../../../AdditionalBaggage/utils';
var Upgrade = function (props) {
    var cssIncluded = useTheme('IncludedBaggage').IncludedBaggage;
    var t = useTranslation('IncludedBaggage').t;
    if (!props.upgradeServices[0]) {
        return null;
    }
    var upgradeItems = useMemo(function () {
        return props.upgradeServices.map(function (services) {
            return convertBaggageOnBaggageItem(services, props.selectedServices, props.order, props.segments);
        });
    }, [props.upgradeServices, props.selectedServices, props.segments]);
    return (React.createElement("div", { className: cn(props.className) },
        React.createElement("div", { className: cssIncluded.header }, t('Upgrade')),
        React.createElement("div", { className: cssIncluded.body }, upgradeItems.map(function (item) {
            return React.createElement(BaggageItem, __assign({ realIconSize: true, key: item.baggage[0].id }, item));
        }))));
};
export default Upgrade;
