import { __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Money as MoneyComponent } from '../../../Money';
import { initI18n, useIsDesktop, useIsMobile } from '../../../utils';
import { useTheme } from '../../../theme';
import Modal from '../../../Modal';
import DetailsWrapper from '../DetailsWrapper/DetailsWrapper';
import CloseIcon from '../../../CloseIcon/components/CloseIcon';
import { ImageStubIcon } from '../Details/Icons';
initI18n('Meal');
var Meal = function (_a) {
    var _b;
    var meal = _a.meal, marker = _a.marker, oldPrice = _a.oldPrice, _c = _a.counters, counters = _c === void 0 ? [] : _c, onIncrease = _a.onIncrease, onDecrease = _a.onDecrease, onClear = _a.onClear, readonly = _a.readonly, maxAvailable = _a.maxAvailable, fullSize = _a.fullSize, onAddForAll = _a.onAddForAll, segmentId = _a.segmentId;
    var theme = useTheme('Meal').Meal;
    var t = useTranslation('Meal').t;
    var totalNumberOfMeals = counters.reduce(function (total, counter) { return counter.count + total; }, 0);
    var _d = __read(useState(false), 2), modalIsVisible = _d[0], setModalIsVisible = _d[1];
    var isMobile = useIsMobile();
    var isDesktop = useIsDesktop();
    var openModal = function () { return setModalIsVisible(true); };
    var closeModal = function () { return setModalIsVisible(false); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(theme.meal, (_b = {},
                _b[theme.meal_fullSize] = fullSize && isDesktop,
                _b)), onClick: openModal },
            React.createElement("div", { className: theme.header },
                React.createElement("div", { className: theme.image, style: meal.icon ? { backgroundImage: "url(".concat(meal.icon, ")") } : {} },
                    (!fullSize || !isDesktop) && marker && React.createElement("div", { className: theme.marker }, marker),
                    !meal.icon && React.createElement("div", { className: theme.imageStub }, ImageStubIcon)),
                (!fullSize || !isDesktop) && (React.createElement("div", { className: theme.title },
                    React.createElement("div", { className: theme.name }, meal.name),
                    meal.weight && (React.createElement("div", { className: theme.weight },
                        meal.weight,
                        " ",
                        t('g')))))),
            React.createElement("div", { className: theme.footer },
                fullSize && isDesktop && marker && React.createElement("div", { className: theme.marker }, marker),
                fullSize && isDesktop && (React.createElement("div", { className: theme.title },
                    React.createElement("div", { className: theme.name }, meal.name),
                    meal.weight && (React.createElement("div", { className: theme.weight },
                        meal.weight,
                        " ",
                        t('g'))))),
                React.createElement("div", { className: theme.footer__icons },
                    !!(oldPrice === null || oldPrice === void 0 ? void 0 : oldPrice.amount) && React.createElement(MoneyComponent, { moneyClassName: theme.oldPrice, money: oldPrice }),
                    !!meal.price.amount ? (React.createElement(MoneyComponent, { moneyClassName: theme.price, money: meal.price })) : (React.createElement("span", { className: theme.price }, t('Free'))),
                    totalNumberOfMeals > 0 && React.createElement("div", { className: theme.count },
                        "x",
                        totalNumberOfMeals)))),
        React.createElement(Modal, { open: modalIsVisible, maxWidth: "sm", fullScreen: isMobile, scroll: isMobile ? 'paper' : 'body', slideUp: isMobile, onClose: closeModal, classes: {
                root: theme.popup__root,
                paper: theme.popup
            }, closeButtonRenderer: function (onClick) { return (React.createElement("div", { className: theme.popup__close },
                React.createElement(CloseIcon, { onClick: onClick }))); } },
            React.createElement(DetailsWrapper, { segmentId: segmentId, onAddForAll: onAddForAll, marker: marker, meal: meal, oldPrice: oldPrice, counters: counters, onDecrease: onDecrease, onIncrease: onIncrease, onClear: onClear, readonly: readonly, maxAvailable: maxAvailable, onSubmit: closeModal }))));
};
export default Meal;
