import { __assign } from "tslib";
import * as React from 'react';
import GenderSwitch from '../GenderSwitch/GenderSwitch';
import { Field } from 'react-final-form';
import { useTheme } from '../../theme';
import Input from '../../../Input';
import { DateMaskedInput } from '../../../DateMaskedInput/DateMaskedInput';
import { TravellerFields } from '../../types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import PhoneInput from '../../../PhoneInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
var TravellerField = function (props) {
    var css = useTheme().TravellerForm;
    var t = useTranslation('Traveller').t;
    if (props.fieldName === TravellerFields.Gender) {
        return (React.createElement(Field, { name: props.fieldName, render: function (_a) {
                var input = _a.input, meta = _a.meta;
                return (React.createElement(GenderSwitch, { className: css.sex, error: meta.touched && t(meta.error), onChange: function (value) {
                        input.onChange(value);
                    }, value: input.value }));
            } }));
    }
    if (props.fieldName === TravellerFields.Nationality) {
        return (React.createElement(Field, { name: props.fieldName, render: function (_a) {
                var input = _a.input;
                return (React.createElement(FormControl, { fullWidth: true },
                    React.createElement(InputLabel, { classes: {
                            root: css.select,
                            focused: css.select__label__focused
                        } }, t(props.fieldName)),
                    React.createElement(Select, __assign({ disabled: props.readonly, inputProps: {
                            name: props.fieldName
                        } }, input, { className: cn(css.form__item, css.select__input), name: "country", value: input.value, onChange: function (event) {
                            input.onChange(event);
                        } }), props.options &&
                        props.options.length &&
                        props.options.map(function (option) { return (React.createElement(MenuItem, { className: css.select__item, key: option.value, value: option.value }, option.label)); }))));
            } }));
    }
    if (props.fieldName === TravellerFields.BirthDate) {
        return (React.createElement(Field, { name: props.fieldName, type: "text", render: function (_a) {
                var input = _a.input, meta = _a.meta;
                return (React.createElement(Input, __assign({}, input, { disabled: props.readonly, className: css.birthday__input, inputComponent: DateMaskedInput, TextFieldProps: {
                        label: t(props.fieldName),
                        error: meta.error && meta.touched,
                        helperText: meta.touched && t(meta.error),
                        onChange: function (event) {
                            var _a;
                            (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, event.target.value);
                            input.onChange(event);
                        }
                    } })));
            } }));
    }
    if (props.fieldName === TravellerFields.Phone) {
        return (React.createElement(Field, { name: "phone", type: "text", render: function (_a) {
                var input = _a.input, meta = _a.meta;
                return (React.createElement("div", { className: css.phone__input },
                    React.createElement(PhoneInput, { value: input.value, disabled: props.readonly, textFieldsProps: {
                            label: t(props.fieldName),
                            error: meta.error && meta.touched,
                            helperText: meta.touched && t(meta.error),
                            onChange: function (event) {
                                input.onChange(event);
                            }
                        } })));
            } }));
    }
    return (React.createElement(Field, { name: props.fieldName, type: "text", render: function (_a) {
            var _b;
            var input = _a.input, meta = _a.meta;
            return (React.createElement(Input, __assign({ disabled: props.readonly }, input, { TextFieldProps: {
                    label: (_b = props.label) !== null && _b !== void 0 ? _b : t(props.fieldName),
                    error: meta.error && meta.touched,
                    helperText: meta.touched && t(meta.error),
                    onChange: function (event) {
                        input.onChange(event);
                    }
                } })));
        } }));
};
export default TravellerField;
