import { __read } from "tslib";
import * as React from 'react';
import { useHistory } from 'react-router';
import Page from '../Page/Page';
import Login from '../LoginChangePassword/LoginWrapper';
import { useAuthToken } from '../LoginChangePassword/hooks';
import { useState } from 'react';
import { useSnackbar } from '../Snackbar/hooks';
import ForcedPasswordChange from '../ForcedPasswordChange/ForcedPasswordChange';
import Snackbar from '../Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';
var LoginPage = function () {
    var setToken = useAuthToken().setToken;
    var history = useHistory();
    var t = useTranslation('LoginPage').t;
    var _a = __read(useState(false), 2), isChangePasswordError = _a[0], setIsChangePasswordError = _a[1];
    var _b = __read(useState(), 2), credentials = _b[0], setCredentials = _b[1];
    var _c = useSnackbar(), openSnackbar = _c.open, closeSnackbar = _c.close, isSnackbarOpen = _c.isOpen, snackbarMessage = _c.message;
    var onChangePasswordError = function (login, password) {
        setIsChangePasswordError(true);
        setCredentials({
            login: login,
            password: password
        });
    };
    var onPasswordChanged = function () {
        setIsChangePasswordError(false);
        openSnackbar(t('Your password has been changed successfully. Please login to your account again.'));
    };
    return (React.createElement(Page, { renderSearchForm: function () { return null; }, renderRoutes: function () { return null; }, showConsole: false, showMenu: false },
        !isChangePasswordError && (React.createElement(Login, { onSuccessAuth: function (token) {
                setToken(token);
                setTimeout(function () { return history.push('/'); }, 1000);
            }, onChangePasswordError: onChangePasswordError })),
        isChangePasswordError && (React.createElement(ForcedPasswordChange, { credentials: credentials, onChangeSuccess: onPasswordChanged })),
        React.createElement(Snackbar, { open: isSnackbarOpen, onClose: closeSnackbar, message: snackbarMessage })));
};
export default LoginPage;
