import { isConsistPlusChar, getMonthFromMM } from './../helpers';
import { isValid, parse } from 'date-fns';
import { isMultiline } from '../transportationOptions';
import { removeJW } from './sirenaTransportationOptions';
import { getEngMonth, isDigits, getLocalization } from '../helpers';
var LINEFEED = /\n/;
var SLASH = /\//;
var DIGITS = /\d/g;
export var validateIata = function (str, citiesIata) {
    if (str) {
        var cities = str.slice(0, 6);
        var city1_1 = str.slice(0, 3);
        var city2_1 = str.slice(3, 6);
        if (getLocalization(cities) === 'eng' && !citiesIata.some(function (city) { return city === city1_1 || city === city2_1; })) {
            return 'Incorrect city format.';
        }
    }
    return '';
};
export var validatePassengerType = function (passengersInfo, passengerTypeCodes) {
    var passengers = [passengersInfo.replace(DIGITS, '')];
    if (isConsistPlusChar(passengersInfo)) {
        passengers = passengersInfo.replace(DIGITS, '').split('+');
    }
    var passengersWithWrongType = passengers.some(function (passenger) { return !passengerTypeCodes.includes(passenger); });
    if (passengersInfo && passengersWithWrongType) {
        return 'Incorrect passenger type.';
    }
    else {
        return '';
    }
};
export var validateDate = function (str) {
    var strWithCorrectMonth = str;
    if (!isDigits(strWithCorrectMonth)) {
        var month = strWithCorrectMonth.slice(2, 5);
        if (getLocalization(month) === 'rus') {
            var engMonth = getEngMonth(month);
            if (engMonth) {
                strWithCorrectMonth = strWithCorrectMonth.replace(month, getEngMonth(month));
            }
            else {
                return 'Incorrect date.';
            }
        }
    }
    else {
        if (strWithCorrectMonth.length === 5) {
            return 'Incorrect date.';
        }
        var monthMM = strWithCorrectMonth.slice(2, 4);
        var month = getMonthFromMM(+monthMM);
        if (month) {
            strWithCorrectMonth = "".concat(strWithCorrectMonth.slice(0, 2)).concat(month);
        }
        else {
            return 'Incorrect date.';
        }
    }
    var possibleDates = [
        parse(strWithCorrectMonth, 'ddMMM', new Date()),
        parse(strWithCorrectMonth, 'ddMMMyy', new Date()),
        parse(strWithCorrectMonth, 'ddMMMyyyy', new Date())
    ];
    if (!possibleDates.some(isValid)) {
        return 'Incorrect date.';
    }
    return '';
};
export var minLength = function (str) {
    if (str.length < 10) {
        return 'Incorrect command.';
    }
    return '';
};
export var sirenaTransportationOptionsValidation = function (_a) {
    var str = _a.str, citiesIata = _a.citiesIata, passengerTypeCodes = _a.passengerTypeCodes;
    var strWithoutJW = removeJW(str).trim();
    var passengersInfo = '';
    var validations = [];
    if (isMultiline(strWithoutJW)) {
        var dates_1 = [];
        var cities_1 = [];
        var lines = strWithoutJW.split(LINEFEED);
        passengersInfo = lines.splice(0, 1)[0];
        lines.forEach(function (line) {
            cities_1.push(line.trim().slice(0, 6));
            dates_1.push(line.trim().slice(6));
        });
        validations = [minLength(strWithoutJW), validatePassengerType(passengersInfo, passengerTypeCodes)]
            .concat(dates_1.map(validateDate))
            .concat(cities_1.map(function (date) { return validateIata(date, citiesIata); }));
    }
    else {
        var dates = strWithoutJW.slice(6).split(SLASH);
        validations = [minLength(strWithoutJW), validateIata(strWithoutJW, citiesIata)].concat(dates.map(validateDate));
    }
    return validations.join(' ').trim();
};
