import * as React from 'react';
import { useTheme } from '../../../../../../../theme';
import { useTranslation } from 'react-i18next';
import { format } from '../../../../../../../utils';
import { getAbsoluteDate } from '../../../../../../../Engine/components/FastSearch/utils';
export var infoIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 13V7H13V13H11ZM13 17V15H11V17H13Z", fill: "currentColor" })));
var Timelimit = function (_a) {
    var timelimit = _a.timelimit;
    var css = useTheme('Checkin').Passengers;
    var t = useTranslation('Checkin').t;
    return (React.createElement("div", { className: css.timelimit },
        React.createElement("span", { className: css.timelimit__icon }, infoIcon),
        t('Online check-in will close at'),
        " ",
        format(getAbsoluteDate(timelimit), 'HH:mm, dd MMMM')));
};
export default Timelimit;
