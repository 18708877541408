import * as Additional from './components/SearchForm/Bottom/Additional/Additional.css';
import * as Bottom from './components/SearchForm/Bottom/Bottom.css';
import * as MultiCity from './components/SearchForm/Bottom/MultiCity/MultiCity.css';
import * as Passengers from './components/SearchForm/Passengers/Passengers.css';
import * as SearchForm from './components/SearchForm/SearchForm.css';
import * as Datepicker from './components/SearchForm/Segments/Segment/Datepicker/Datepicker.css';
import * as Segment from './components/SearchForm/Segments/Segment/Segment.css';
import * as Actions from './components/SearchForm/Segments/Actions/Actions.css';
import * as Segments from './components/SearchForm/Segments/Segments.css';
import * as StartSearch from './components/SearchForm/StartSearch/StartSearch.css';
import * as Location from './components/SearchForm/Segments/Segment/Location/Location.css';
import * as Value from './components/SearchForm/Segments/Segment/Location/Value/Value.css';
import * as Controls from './components/SearchForm/Controls/Controls.css';
import * as PromoCode from './components/SearchForm/Bottom/PromoCode/PromoCode.css';
import * as AdvancedPassengers from './components/SearchForm/AdvancedPassengers/AdvancedPassengers.css';
import * as PassengersCategories from './components/SearchForm/AdvancedPassengers/PassengersCategories/PassengersCategories.css';
import * as LastSearch from './components/SearchForm/LastSearch/LastSearch.css';
import * as PayByMiles from './components/SearchForm/Bottom/PayByMiles/PayByMiles.css';
import * as Currency from './components/SearchForm/Currency/Currency.css';
export var theme = {
    SearchForm: SearchForm,
    Bottom: Bottom,
    Additional: Additional,
    MultiCity: MultiCity,
    Passengers: Passengers,
    StartSearch: StartSearch,
    Segments: Segments,
    Segment: Segment,
    Datepicker: Datepicker,
    Location: Location,
    Controls: Controls,
    Value: Value,
    Actions: Actions,
    PromoCode: PromoCode,
    AdvancedPassengers: AdvancedPassengers,
    PassengersCategories: PassengersCategories,
    LastSearch: LastSearch,
    PayByMiles: PayByMiles,
    Currency: Currency
};
