import React from 'react';
import { useTheme } from '../../../../theme';
import BaggageTotalPriceBlock from '../../../BaggageTotalPriceBlock';
var Footer = function (_a) {
    var onClickEdit = _a.onClickEdit, price = _a.price, minPrice = _a.minPrice;
    var css = useTheme('AdditionalBaggage').Footer;
    return (React.createElement("div", { className: css.root },
        React.createElement(BaggageTotalPriceBlock, { total: price, min: minPrice, onClickButton: onClickEdit })));
};
export default Footer;
