import { __assign, __read } from "tslib";
import * as React from 'react';
import { useState, useRef, useMemo } from 'react';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import cn from 'classnames';
import { Field, Form } from 'react-final-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { SearchParameterFieldEnum, InputType, DateFields } from '../../types';
import Input from '../../../../Input';
import { Search, CloseClear, ArrowDropDown } from '../../../../index';
import { useTheme } from '../../../../theme';
import { initI18n } from '../../../../utils';
import DropMenu from '../DropMenu/DropMenu';
initI18n('SearchOrder');
var getParamByField = function (fieldName) {
    switch (fieldName) {
        case SearchParameterFieldEnum.DepartureDateStart:
        case SearchParameterFieldEnum.DepartureDateEnd:
            return 'Departure date';
        case SearchParameterFieldEnum.FlightNumber:
            return 'Flight number';
        case SearchParameterFieldEnum.LastName:
            return 'Last name';
        default:
            return '';
    }
};
export var formatDate = function (date) {
    var dateObj = new Date(date);
    var day = dateObj.getDate() < 10 ? "0".concat(dateObj.getDate()) : dateObj.getDate();
    var month = dateObj.getMonth() < 10 ? "0".concat(dateObj.getMonth()) : dateObj.getMonth();
    var year = dateObj.getFullYear();
    return "".concat(day, ".").concat(month, ".").concat(year);
};
var buildParamsString = function (formValues) {
    var params = Object.keys(formValues)
        .filter(function (formKey) { return formKey !== SearchParameterFieldEnum.SearchString && !(formKey in DateFields); })
        .map(function (formKey) { return (formValues[formKey].length > 0 ? "".concat(getParamByField(formKey), ": ").concat(formValues[formKey]) : null); })
        .filter(Boolean);
    var dates = [
        formValues[SearchParameterFieldEnum.DepartureDateStart],
        formValues[SearchParameterFieldEnum.DepartureDateEnd]
    ];
    var existDates = dates.filter(Boolean);
    if (existDates.length > 0) {
        params.push("".concat(getParamByField(SearchParameterFieldEnum.DepartureDateStart), ": ").concat(existDates.map(formatDate).join(' - ')));
    }
    if (params.length === 0) {
        return null;
    }
    return params.join(', ');
};
var SearchOrder = function (props) {
    var css = useTheme('SearchOrder').SearchOrder;
    var t = useTranslation('SearchOrder').t;
    var _a = __read(useState(false), 2), isDropMenuOpen = _a[0], setIsDropMenuOpen = _a[1];
    var _b = __read(useState(null), 2), dropMenuAnchor = _b[0], setDropMenuAnchor = _b[1];
    var _c = __read(useState({}), 2), formValues = _c[0], setFormValues = _c[1];
    var searchStringRef = useRef(null);
    var defaultFields = useMemo(function () {
        return [
            {
                name: 'departureDateStart',
                siblingMaxName: 'departureDateEnd',
                label: i18n.t('SearchOrder:Departure date'),
                input: InputType.Date,
                placeholder: i18n.t('SearchOrder:Start')
            },
            {
                name: 'departureDateEnd',
                siblingMinName: 'departureDateStart',
                input: InputType.Date,
                placeholder: i18n.t('SearchOrder:End')
            },
            {
                name: 'flightNumber',
                label: i18n.t('SearchOrder:Flight number'),
                placeholder: i18n.t('SearchOrder:Flight number'),
                input: InputType.Text
            },
            {
                name: 'lastName',
                label: i18n.t('SearchOrder:Passenger last name'),
                placeholder: i18n.t('SearchOrder:Passenger last name'),
                input: InputType.Text
            }
        ];
    }, []);
    var toggleDropMenu = function () { return setIsDropMenuOpen(!isDropMenuOpen); };
    var showDropMenu = function () {
        setDropMenuAnchor(searchStringRef.current);
        toggleDropMenu();
    };
    var clearSearchString = function () {
        setFormValues({});
    };
    var onSubmit = function () {
        props.onStartSearch(formValues);
    };
    var onFieldChange = function (fieldName, value) {
        setFormValues(function (values) {
            var _a;
            return __assign(__assign({}, values), (_a = {}, _a[fieldName] = value, _a));
        });
    };
    var renderStartAdornment = function () { return (React.createElement(InputAdornment, { position: 'start', className: css.searchIcon }, Search)); };
    var renderEndAdornment = function () {
        var _a;
        var _b;
        return (React.createElement(InputAdornment, { position: 'end', className: css.searchStringActions },
            (searchStringParams || ((_b = formValues['searchString']) === null || _b === void 0 ? void 0 : _b.length) > 0) && (React.createElement(IconButton, { className: css.clearIcon, onClick: clearSearchString, size: 'small' }, CloseClear)),
            React.createElement(IconButton, { className: cn(css.arrowDownIcon, (_a = {}, _a[css.reverseArrow] = isDropMenuOpen, _a)), onClick: showDropMenu, size: 'small' }, ArrowDropDown)));
    };
    var searchStringParams = buildParamsString(formValues);
    return (React.createElement(Paper, { elevation: 0, className: css.paper },
        React.createElement(Form, { onSubmit: onSubmit, validate: function (values) {
                var errors = {};
                if (!values['searchString'] && !searchStringParams) {
                    errors['searchString'] = t('Required');
                }
                return errors;
            }, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Field, { name: "searchString", render: function (_a) {
                            var input = _a.input, meta = _a.meta;
                            return (React.createElement("div", { className: css.searchStringWrapper, ref: searchStringRef },
                                React.createElement(Input, { variant: 'outlined', disabled: !!searchStringParams, value: searchStringParams || formValues['searchString'] || '', name: "searchString", placeholder: t('Search order'), inputClassName: css.searchString, startAdornment: renderStartAdornment(), endAdornment: renderEndAdornment(), TextFieldProps: {
                                        error: !!meta.error && meta.touched,
                                        helperText: !!meta.error && meta.touched ? meta.error : '',
                                        onChange: function (event) {
                                            input.onChange(event);
                                            var target = event.target;
                                            setFormValues(function (values) {
                                                var _a;
                                                return __assign(__assign({}, values), (_a = {}, _a[target.name] = target.value, _a));
                                            });
                                        }
                                    } })));
                        } }),
                    React.createElement(Popover, { open: isDropMenuOpen, elevation: 1, onClose: toggleDropMenu, anchorEl: dropMenuAnchor, classes: {
                            paper: css.dropMenu__paper
                        }, anchorOrigin: {
                            horizontal: 'left',
                            vertical: 'bottom'
                        } },
                        React.createElement("form", { onSubmit: handleSubmit },
                            React.createElement(DropMenu, { fields: defaultFields, formValues: formValues, onBlur: onFieldChange, onChange: onFieldChange, handleSubmit: handleSubmit })))));
            } })));
};
export default SearchOrder;
