import { createSelector } from 'reselect';
var getState = function (state) { return state; };
export var getService = createSelector(getState, function (state) { return state.services; });
export var getSelectedServices = createSelector(getState, function (state) { return state.selectedServices; });
export var getPassengers = createSelector(getState, function (state) { return state.passengers; });
export var getSegments = createSelector(getState, function (state) { return state.segments; });
export var createGetSelected = function (segmentId, travellerId, servicesIds) {
    return createSelector(getSelectedServices, function (items) {
        return items.filter(function (el) {
            return (el.passengerId === travellerId &&
                servicesIds.includes(el.serviceId) &&
                (!segmentId || el.segmentIds.includes(segmentId)));
        });
    });
};
export var getSelectedBaggage = createSelector([
    function (state) { return state; },
    function (_, segmentId, travellerId, servicesIds) { return ({
        segmentId: segmentId,
        travellerId: travellerId,
        servicesIds: servicesIds
    }); }
], function (state, params) {
    return state.selectedServices.filter(function (el) {
        return (el.passengerId === params.travellerId &&
            params.servicesIds.includes(el.serviceId) &&
            (!params.segmentId || el.segmentIds.includes(params.segmentId)));
    });
});
export var availableToAddServiceOnSingleSegment = createSelector(getService, function (services) {
    var service = services[0];
    for (var i = 1; i < services.length; i++) {
        var currService = services[i];
        if (currService.segmentsId.length !== service.segmentsId.length) {
            return false;
        }
        var _loop_1 = function (n) {
            if (currService.segmentsId[n].length !== service.segmentsId[n].length) {
                return { value: false };
            }
            if (!currService.segmentsId[n].every(function (id) { return service.segmentsId[n].includes(id); })) {
                return { value: false };
            }
        };
        for (var n = 0; n < currService.segmentsId.length; n++) {
            var state_1 = _loop_1(n);
            if (typeof state_1 === "object")
                return state_1.value;
        }
    }
    return !!services.find(function (service) {
        return !!service.segmentsId.find(function (segmentGroup) { return segmentGroup.length === 1; });
    });
});
export var getOnlyForDifferentReturnFlight = createSelector([getService, getSegments], function (services, segments) {
    return services.some(function (service) { return service.segmentsId.flat().length < segments.length; });
});
export var getDifferentForReturnFlight = createSelector([getState, availableToAddServiceOnSingleSegment, getOnlyForDifferentReturnFlight], function (state, availableSingleSegment, onlyForDifferentReturnFlight) {
    if (onlyForDifferentReturnFlight) {
        return true;
    }
    if (availableSingleSegment) {
        return state.differentForReturnFlight;
    }
    return true;
});
export var getPrice = createSelector(getService, getSelectedServices, function (services, items) {
    var totalAmount = 0;
    items.forEach(function (item) {
        var serviceItem = services.find(function (service) { return item.serviceId === service.id; });
        if (serviceItem) {
            totalAmount += serviceItem.price.amount * item.count * item.segmentIds.length;
        }
    });
    if (totalAmount === 0) {
        return null;
    }
    return {
        amount: totalAmount,
        currency: services[0].price.currency
    };
});
export var getDisabled = createSelector(getState, function (state) { return state.disabled; });
export var getMaxBaggageCount = createSelector(getState, function (state) { return state.maxBaggageCount; });
export var getMaxBaggagePerPassengerCount = createSelector(getState, function (state) { return state.maxBaggagePerPassengerCount; });
export var getSelectedServicesByPassengersMap = createSelector(getState, function (state) {
    var passengerMap = new Map();
    state.selectedServices.forEach(function (service) {
        if (passengerMap.has(service.passengerId)) {
            passengerMap.get(service.passengerId).push(service);
        }
        else {
            passengerMap.set(service.passengerId, [service]);
        }
    });
    return passengerMap;
});
export var getBaggageTab = createSelector(getState, function (state) { return state.baggageTab; });
export var getPassenger = createSelector(function (state) { return state.passengers; }, function (_, passengerId) { return passengerId; }, function (passengers, passengerId) { return passengers.find(function (p) { return p.id === passengerId; }); });
