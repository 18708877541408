import * as React from 'react';
import { getDurationString } from '../../../../Results/utils';
import MobileSegmentFlatTime from '../../../../MobileSegmentInfo/components/MobileSegmentFlat/MobileSegmentFlatTime/MobileSegmentFlatTime';
import { useTheme } from '../../../../theme';
import { diffInMinutesBetweenTimes } from './utils';
var RouteInfo = function (_a) {
    var segment = _a.segment, first = _a.first, last = _a.last;
    var departure = segment.departure, arrival = segment.arrival, duration = segment.duration;
    var theme = useTheme('DesktopFlightRoute').SegmentStyles;
    var durationTime;
    if (duration) {
        durationTime = getDurationString(duration);
    }
    var flightNumber = "".concat(segment.marketingAirline.iata, " ").concat(segment.flightNumber);
    var diffInMinutes = diffInMinutesBetweenTimes(arrival.time, departure.time);
    var nextDay = diffInMinutes >= 0 ? duration.days : duration.days + 1;
    return (React.createElement(MobileSegmentFlatTime, { departureIATA: departure.airport.iata, arrivalIATA: arrival.airport.iata, arrivalTerminal: arrival.terminal, departureTerminal: departure.terminal, departureTime: departure.time, departureCity: departure.airport.city.name, departureInfo: "".concat(departure.airport.iata, ", ").concat(departure.terminal ? "Terminal ".concat(departure.terminal) : ''), durationTime: durationTime, arrivalTime: segment.arrival.time, arrivalCity: arrival.airport.city.name, arrivalInfo: "".concat(arrival.airport.iata, ", ").concat(arrival.terminal ? "Terminal ".concat(arrival.terminal) : ''), className: theme.route, timeClassName: theme.route__time, flightNumber: flightNumber, segmentType: first ? (last ? 'only' : 'first') : 'last', nextDay: nextDay }));
};
export default RouteInfo;
