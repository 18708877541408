import { __awaiter, __generator } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useQuery } from '@apollo/react-hooks';
import { OauthServiceType } from '../../__generated__/globalTypes';
import { useTheme } from '../../theme';
import * as Auth from '../../__queries__/query.graphql';
import { useConfig } from '../../context';
var googleIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M6.98918 13.8775L6.36263 16.2165L4.07258 16.2649C3.3882 14.9956 3 13.5432 3 11.9999C3 10.5074 3.36295 9.10006 4.00631 7.86084H4.0068L6.04559 8.23462L6.9387 10.2612C6.75177 10.8061 6.64989 11.3911 6.64989 11.9999C6.64996 12.6605 6.76963 13.2935 6.98918 13.8775Z", fill: "#FBBB00" }),
    React.createElement("path", { d: "M20.8426 10.3188C20.946 10.8633 20.9999 11.4255 20.9999 12.0002C20.9999 12.6445 20.9321 13.273 20.8031 13.8793C20.365 15.9424 19.2202 17.7438 17.6343 19.0187L17.6338 19.0182L15.0659 18.8872L14.7024 16.6184C15.7547 16.0012 16.5771 15.0354 17.0103 13.8793H12.1978V10.3188H17.0805H20.8426Z", fill: "#518EF8" }),
    React.createElement("path", { d: "M17.6341 19.0178L17.6346 19.0183C16.0922 20.258 14.133 20.9998 12.0002 20.9998C8.57275 20.9998 5.59288 19.0841 4.07275 16.2649L6.98935 13.8774C7.74939 15.9059 9.70616 17.3499 12.0002 17.3499C12.9862 17.3499 13.91 17.0833 14.7026 16.618L17.6341 19.0178Z", fill: "#28B446" }),
    React.createElement("path", { d: "M17.7447 5.07197L14.8291 7.45894C14.0087 6.94615 13.039 6.64992 12 6.64992C9.65409 6.64992 7.66073 8.16013 6.93877 10.2613L4.00684 7.86099H4.00635C5.50421 4.97307 8.52168 3 12 3C14.1838 3 16.186 3.77787 17.7447 5.07197Z", fill: "#F14336" })));
var facebookIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M15.6876 12.5068H13.2748C13.2748 16.3139 13.2748 21 13.2748 21H9.69952C9.69952 21 9.69952 16.3593 9.69952 12.5068H8V9.50501H9.69952V7.56342C9.69952 6.17286 10.3686 4 13.3076 4L15.957 4.01003V6.92389C15.957 6.92389 14.3471 6.92389 14.034 6.92389C13.721 6.92389 13.276 7.07847 13.276 7.74159V9.5056H16L15.6876 12.5068Z", fill: "white" })));
/*const twitterIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.1592 8.48531C19.1663 8.63749 19.1702 8.79091 19.1702 8.94432C19.1702 13.6155 15.4563 19 8.66132 19C6.57557 19 4.63395 18.4166 3 17.4132C3.28892 17.446 3.583 17.4627 3.88097 17.4627C5.61187 17.4627 7.20381 16.8973 8.46806 15.9496C6.8522 15.9211 5.48777 14.8992 5.01788 13.4943C5.24281 13.5351 5.47485 13.558 5.71205 13.558C6.0488 13.558 6.37585 13.5159 6.68545 13.4349C4.99591 13.1107 3.72326 11.6823 3.72326 9.96876C3.72326 9.95391 3.72326 9.93845 3.7239 9.92422C4.22159 10.1884 4.79102 10.348 5.39599 10.3659C4.4058 9.73307 3.75364 8.65048 3.75364 7.42438C3.75364 6.77606 3.93526 6.16857 4.25326 5.64646C6.07401 7.78565 8.7964 9.19239 11.8652 9.34086C11.8019 9.08166 11.7702 8.81256 11.7702 8.5348C11.7702 6.58305 13.4236 5 15.4628 5C16.5254 5 17.4839 5.42932 18.1587 6.11599C19.0009 5.95762 19.7894 5.6644 20.5049 5.25796C20.227 6.08382 19.6433 6.77606 18.8787 7.21404C19.6265 7.12867 20.3401 6.93938 21 6.6579C20.5075 7.36561 19.8812 7.98855 19.1592 8.48531Z"
            fill="white"
        />
    </svg>
);*/
var vkIcon = (React.createElement("svg", { height: "24", viewBox: "0 0 24 24", width: "24", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fill: "white", d: "M21.54736,7H18.25688a.74281.74281,0,0,0-.65452.39156s-1.31237,2.41693-1.73392,3.231C14.73438,12.8125,14,12.125,14,11.10863V7.60417A1.10417,1.10417,0,0,0,12.89583,6.5h-2.474a1.9818,1.9818,0,0,0-1.751.8125s1.25626-.20312,1.25626,1.48958c0,.41974.02162,1.62723.04132,2.64a.72943.72943,0,0,1-1.273.50431,21.54029,21.54029,0,0,1-2.4982-4.54359A.69314.69314,0,0,0,5.5668,7C4.8532,7,3.42522,7,2.57719,7a.508.508,0,0,0-.47969.68481C3.00529,10.17487,6.91576,18,11.37917,18h1.87865A.74219.74219,0,0,0,14,17.25781V16.12342a.7293.7293,0,0,1,1.22868-.5315l2.24861,2.1127A1.08911,1.08911,0,0,0,18.223,18h2.95281c1.42415,0,1.42415-.98824.64768-1.75294-.54645-.53817-2.51832-2.61663-2.51832-2.61663A1.01862,1.01862,0,0,1,19.2268,12.307c.63737-.83876,1.67988-2.21175,2.122-2.79993C21.95313,8.70313,23.04688,7,21.54736,7Z" })));
var SocialAuth = function (_a) {
    var _b, _c, _d;
    var _e;
    var _f = _a.variant, variant = _f === void 0 ? 'common' : _f, onClick = _a.onClick;
    var css = useTheme('SocialAuth').SocialAuth;
    var getRedirectUrl = useQuery(Auth.SocialAuth, {
        skip: true
    });
    var iataCode = (_e = useConfig()) === null || _e === void 0 ? void 0 : _e.global.companyInfo.iataCode;
    var isActiveVk = !['ZZ'].includes(iataCode);
    var redirectToService = function (service) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (onClick) {
                        // Useful for analytics goals.
                        onClick(service);
                    }
                    return [4 /*yield*/, getRedirectUrl.refetch({
                            params: { service: service, backRedirectUrl: window.location.href }
                        })];
                case 1:
                    data = (_a.sent()).data;
                    if (data.OauthRedirectUrl.message) {
                        window.open(data.OauthRedirectUrl.message, '_top');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: css.wrapper },
        React.createElement(ButtonBase, { className: cn(css.button, css.button_google, (_b = {},
                _b[css.button_square] = variant === 'square',
                _b)), onClick: function () { return redirectToService(OauthServiceType.Google); } }, googleIcon),
        React.createElement(ButtonBase, { className: cn(css.button, css.button_light, (_c = {},
                _c[css.button_square] = variant === 'square',
                _c), css.button_facebook), onClick: function () { return redirectToService(OauthServiceType.Facebook); } }, facebookIcon),
        isActiveVk && (React.createElement(ButtonBase, { className: cn(css.button, css.button_light, (_d = {},
                _d[css.button_square] = variant === 'square',
                _d), css.button_twitter), onClick: function () { return redirectToService(OauthServiceType.Vkontakte); } }, vkIcon))));
};
export default SocialAuth;
