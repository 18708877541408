import { __assign, __read, __values } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Service from '../../../../../../Service';
import SeatMap from '../../../../../../SeatMap';
import { Currency } from '../../../../../../enums';
import { useTheme } from '../../../../../../theme';
import { Money as MoneyComponent } from '../../../../../../index';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../../../../__generated__/globalTypes';
import { useMemo } from 'react';
import { getSeatReduxCallbacks } from '../../../../../utils';
var seatIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M13.5353 27.4097L15.062 24.4413H10.0846L5.71387 9.80194V4.23912C5.71387 3.16051 6.61332 2.28613 7.72286 2.28613C8.83239 2.28613 9.73185 3.16051 9.73185 4.23912V9.80194L12.9756 19.4047H22.2821C23.7128 19.4047 24.8726 20.5322 24.8726 21.923C24.8726 23.3138 23.7128 24.4413 22.2821 24.4413H17.6409L16.1142 27.4097H21.8271V29.652H14.9609L14.9286 29.7147L14.7996 29.652H7.65763V27.4097H13.5353Z", fill: "white" })));
var SeatService = function (_a) {
    var e_1, _b, e_2, _c, e_3, _d, e_4, _e;
    var _f, _g, _h;
    var runServiceRequest = _a.runServiceRequest, passenger = _a.passenger, passengers = _a.passengers, seatMap = _a.seatMap, isLoading = _a.isLoading, onCardClick = _a.onCardClick, setService = _a.setService, removeService = _a.removeService;
    var t = useTranslation('Checkout').t;
    var theme = useTheme('Checkout').Services;
    var minPrice = null;
    var priceForPassenger = {
        amount: 0,
        currency: Currency.RUB
    };
    var totalPrice = {
        amount: 0,
        currency: Currency.RUB
    };
    if ((_g = (_f = seatMap === null || seatMap === void 0 ? void 0 : seatMap.additionalServices) === null || _f === void 0 ? void 0 : _f.gdsServices) === null || _g === void 0 ? void 0 : _g.services) {
        seatMap.additionalServices.gdsServices.services
            .filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Seat; })
            .forEach(function (service) {
            if (!minPrice || minPrice.amount > service.price.amount) {
                minPrice = service.price;
            }
        });
        try {
            for (var _j = __values(Object.entries(seatMap.segments)), _k = _j.next(); !_k.done; _k = _j.next()) {
                var _l = __read(_k.value, 2), segment = _l[1];
                try {
                    for (var _m = (e_2 = void 0, __values(Object.entries(segment.decks))), _o = _m.next(); !_o.done; _o = _m.next()) {
                        var _p = __read(_o.value, 2), deck = _p[1];
                        try {
                            for (var _q = (e_3 = void 0, __values(Object.entries(deck.rows))), _r = _q.next(); !_r.done; _r = _q.next()) {
                                var _s = __read(_r.value, 2), row = _s[1];
                                try {
                                    for (var _t = (e_4 = void 0, __values(Object.entries(row.seats))), _u = _t.next(); !_u.done; _u = _t.next()) {
                                        var _v = __read(_u.value, 2), seat = _v[1];
                                        if (seat.seatService === null && seat.isAvailable && seat.isExistent) {
                                            // seat which seatService === null -> free seat
                                            minPrice.amount = 0;
                                        }
                                    }
                                }
                                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                                finally {
                                    try {
                                        if (_u && !_u.done && (_e = _t.return)) _e.call(_t);
                                    }
                                    finally { if (e_4) throw e_4.error; }
                                }
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_r && !_r.done && (_d = _q.return)) _d.call(_q);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_o && !_o.done && (_c = _m.return)) _c.call(_m);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_k && !_k.done && (_b = _j.return)) _b.call(_j);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    if (passenger.seats) {
        passenger.seats.forEach(function (seat) {
            priceForPassenger.amount += seat.price.amount;
            priceForPassenger.currency = seat.price.currency;
        });
    }
    passengers.forEach(function (passenger) {
        if (passenger.seats) {
            passenger.seats.forEach(function (seat) {
                totalPrice.amount += seat.price.amount;
                totalPrice.currency = seat.price.currency;
            });
        }
    });
    var onClear = function () {
        if (runServiceRequest) {
            runServiceRequest([{ passengerId: passenger.id.toString(), seats: [] }]);
        }
        else {
            passenger.seats.forEach(function (seat) {
                removeService({
                    passengerId: passenger.id,
                    serviceId: seat.row,
                    segmentId: seat.segmentId
                });
            });
        }
    };
    var seatMapCallbacks = useMemo(function () { return getSeatReduxCallbacks(passengers, setService, removeService); }, [
        passengers,
        setService,
        removeService
    ]);
    return (React.createElement(Service, { icon: seatIcon, label: t('Seats'), dialogType: "fullscreen", dialogHeader: t('Seats'), canAddProducts: true, price: priceForPassenger, onCancel: onClear, dialogHeaderPrice: totalPrice, minPrice: minPrice, hasProducts: priceForPassenger && ((_h = passenger.seats) === null || _h === void 0 ? void 0 : _h.length) > 0, onClick: onCardClick, isLoading: isLoading, renderActionContent: passenger.seats &&
            passenger.seats.length && (React.createElement("div", { className: theme.action__text_inline },
            React.createElement("ul", { className: theme.service__seatsList }, passenger.seats.map(function (seat, seatIndex) { return (React.createElement("li", { className: theme.service__seatsListItem, key: "".concat(seat.row).concat(seat.letter) }, "".concat(seat.row).concat(seat.letter),
                seatIndex + 1 !== passenger.seats.length && React.createElement(React.Fragment, null, ","))); })),
            React.createElement(MoneyComponent, { moneyClassName: theme.totalPrice, money: priceForPassenger }))) },
        React.createElement(SeatMap, __assign({ passengers: passengers, showRecommendations: false, seatMap: seatMap, fullScreen: false, preSelectedPassenger: +passenger.id, onProceed: null }, seatMapCallbacks))));
};
export default SeatService;
