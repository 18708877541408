import { __awaiter, __generator, __read } from "tslib";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { getOrder, isConfirmedOrCancelled } from '../../store/order/selectors';
import { CheckinGoal, OrderGoal, reachGoal } from '../../../analytics';
import { downloadPDF } from '../../../utils';
import { useConfig } from '../../../context';
import { getOrderCheckinRedirectURL, getPassengerCheckinRedirectURL } from '../../utils';
import { OrderDocumentType } from '../../../__generated__/globalTypes';
import * as OrderDocumentQuery from '../../../__queries__/OrderDocument.graphql';
import * as CheckinRulesQuery from '../../../__queries__/CheckinRules.graphql';
import * as CheckinCreateOrderMutation from '../../../__queries__/CheckinCreateOrder.graphql';
import * as CheckinInfoQuery from '../../../__queries__/CheckinInfo.graphql';
import { useIsTerminal, useToggleable } from '../../../hooks';
import { ResendTicketsStages } from '../../../ResendTicketsForm/types';
import * as CheckoutQueries from '../../../__queries__/query.graphql';
export var useDownloadOrderDocument = function () {
    var order = useSelector(getOrder);
    var _a = useToggleable(false), loading = _a.isOpen, setLoading = _a.open, setNotLoading = _a.close;
    var _b = useQuery(OrderDocumentQuery.OrderDocument, {
        skip: true
    }), refetchDocuments = _b.refetch, data = _b.data;
    var handleDownload = useCallback(function (documentType, travellerId) { return __awaiter(void 0, void 0, void 0, function () {
        var params, data_1, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (loading) {
                        return [2 /*return*/];
                    }
                    setLoading();
                    reachGoal(OrderGoal.ItineraryReceiptDownload);
                    params = {
                        orderId: order.id,
                        documentType: documentType !== null && documentType !== void 0 ? documentType : OrderDocumentType.ItineraryReceipt
                    };
                    if (travellerId) {
                        params.travellerId = travellerId;
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, refetchDocuments({ params: params })];
                case 2:
                    data_1 = _b.sent();
                    if ((_a = data_1 === null || data_1 === void 0 ? void 0 : data_1.data) === null || _a === void 0 ? void 0 : _a.OrderDocument) {
                        downloadPDF(data_1.data.OrderDocument, 'tickets.pdf');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _b.sent();
                    console.error(e_1);
                    return [3 /*break*/, 5];
                case 4:
                    setNotLoading();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [order.status]);
    return {
        handleDownload: handleDownload,
        document: data === null || data === void 0 ? void 0 : data.OrderDocument,
        loading: loading
    };
};
export var useCheckinStart = function () {
    var _a;
    var order = useSelector(getOrder);
    var isOrderConfirmedOrCancelled = useSelector(isConfirmedOrCancelled);
    var _b = useToggleable(false), isRulesModalOpen = _b.isOpen, open = _b.open, close = _b.close;
    var _c = __read(useState(), 2), checkinOrderId = _c[0], setCheckinOrderId = _c[1];
    var _d = __read(useState(false), 2), checkinCreationInProgress = _d[0], setCheckinCreationInProgress = _d[1];
    var _e = useConfig().Engine, checkInEnabled = _e.checkInEnabled, checkInURL = _e.checkInURL;
    var isTerminal = useIsTerminal();
    var checkinInfo = useQuery(CheckinInfoQuery.CheckinInfo, {
        skip: !isOrderConfirmedOrCancelled || isTerminal,
        variables: {
            params: {
                aviaOrderId: order.id
            }
        }
    }).data;
    var isCheckinAvailable = useMemo(function () {
        var _a, _b;
        return (_b = (_a = checkinInfo === null || checkinInfo === void 0 ? void 0 : checkinInfo.CheckinInfo) === null || _a === void 0 ? void 0 : _a.isAvailable) !== null && _b !== void 0 ? _b : false;
    }, [checkinInfo]);
    var _f = __read(useMutation(CheckinCreateOrderMutation.CheckinCreateOrder), 1), createCheckin = _f[0];
    var rulesData = useQuery(CheckinRulesQuery.CheckinRules, {
        variables: {
            id: order.id
        },
        skip: !checkInEnabled || !checkInURL || !isCheckinAvailable
    });
    var goToCheckin = function () { return __awaiter(void 0, void 0, void 0, function () {
        var checkinOrderCreated, _a, data, errors, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setCheckinCreationInProgress(true);
                    setCheckinOrderId('');
                    checkinOrderCreated = false;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createCheckin({
                            variables: {
                                params: {
                                    aviaOrderId: order.id
                                }
                            }
                        })];
                case 2:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (!errors && data && data.CheckinCreateOrder) {
                        setCheckinOrderId(data.CheckinCreateOrder.id);
                        checkinOrderCreated = true;
                        if (checkInEnabled && checkInURL) {
                            open();
                        }
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _b.sent();
                    console.warn('Could not create check-in order. Proceed with passenger credentials.');
                    return [3 /*break*/, 4];
                case 4:
                    setCheckinCreationInProgress(false);
                    if (!checkinOrderCreated) {
                        window.open(getPassengerCheckinRedirectURL(checkInURL, order), '_blank');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var acceptRules = useCallback(function () {
        if (checkinOrderId) {
            reachGoal(CheckinGoal.Create);
            window.open(getOrderCheckinRedirectURL(checkInURL, checkinOrderId), '_blank');
        }
        close();
    }, [checkinOrderId, isRulesModalOpen, close]);
    return {
        isCheckinAvailable: isCheckinAvailable,
        acceptRules: acceptRules,
        goToCheckin: goToCheckin,
        checkinRules: (_a = rulesData === null || rulesData === void 0 ? void 0 : rulesData.data) === null || _a === void 0 ? void 0 : _a.CheckinRules,
        isRulesModalOpen: isRulesModalOpen,
        checkinCreationInProgress: checkinCreationInProgress,
        closeCheckinRulesModal: close
    };
};
export var useResendTickets = function (orderId) {
    var CLOSE_POPUP_AFTER_SUCCESSFUL_RESEND_AFTER = 1500;
    var RETURN_DEFAULT_STATUS_AFTER_SUCCESSFUL_RESEND_AFTER = 2000;
    var _a = __read(useState(ResendTicketsStages.Default), 2), resendStage = _a[0], setResendStage = _a[1];
    var _b = useToggleable(false), isOpen = _b.isOpen, open = _b.open, close = _b.close;
    var _c = __read(useMutation(CheckoutQueries.ResendItineraryReciept), 2), resendEmail = _c[0], _d = _c[1], data = _d.data, loading = _d.loading, error = _d.error;
    var handleResendEmail = useCallback(function (email) {
        setResendStage(ResendTicketsStages.Loading);
        resendEmail({
            variables: {
                params: {
                    email: email,
                    orderId: orderId
                }
            }
        });
    }, [isOpen, resendStage, orderId, resendEmail]);
    useEffect(function () {
        var _a;
        var resendResult = data === null || data === void 0 ? void 0 : data.ResendItineraryReciept;
        if (error || ((_a = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length)) {
            setResendStage(ResendTicketsStages.Error);
            return;
        }
        if (!loading && !error && resendResult) {
            setResendStage(ResendTicketsStages.Success);
            setTimeout(close, CLOSE_POPUP_AFTER_SUCCESSFUL_RESEND_AFTER);
            setTimeout(function () {
                setResendStage(ResendTicketsStages.Default);
            }, RETURN_DEFAULT_STATUS_AFTER_SUCCESSFUL_RESEND_AFTER);
        }
    }, [data, loading, error]);
    return {
        openModal: open,
        closeModal: close,
        isModalOpen: isOpen,
        resendStage: resendStage,
        handleResendEmail: handleResendEmail,
        loading: loading
    };
};
