import { __assign, __read, __spreadArray } from "tslib";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPassenger, getSelectedBaggage } from './store/selector';
import { getNotSavedBaggages } from './utils';
export var usePassengerState = function (passengerId, baggages, segmentIds, segmentId) {
    var _a;
    var servicesIds = baggages.map(function (baggage) { return baggage.id; });
    var selectedItems = useSelector(function (state) {
        return getSelectedBaggage(state, segmentId, passengerId, servicesIds);
    });
    var passenger = useSelector(function (state) { return getPassenger(state, passengerId); });
    var items = [];
    baggages.forEach(function (bag) {
        bag.segmentsId
            .filter(function (segIds) { return !segmentId || segIds.includes(segmentId); })
            .forEach(function (segIds) {
            items.push({
                serviceId: bag.id,
                passengerId: passengerId,
                count: 0,
                segmentIds: segIds
            });
        });
    });
    var isSelected = !!selectedItems.length;
    var selectedCount = (_a = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.reduce(function (count, item) { return count + item.count; }, 0)) !== null && _a !== void 0 ? _a : 0;
    var notSavedBaggages = getNotSavedBaggages(baggages, passenger, segmentId);
    return { items: items, selectedItems: selectedItems, notSavedBaggages: notSavedBaggages, isSelected: isSelected, selectedCount: selectedCount };
};
export var serviceForMultipleSegments = function (service) {
    var _a;
    return ((_a = service.segmentsId[0]) === null || _a === void 0 ? void 0 : _a.length) > 1;
};
export var useSelectedServicesBySegments = function (services, segments, selectedServices, passengers) {
    var selectedItems = useMemo(function () {
        var selectedServicesMap = new Map();
        services.forEach(function (service) {
            selectedServices.forEach(function (_a) {
                var serviceId = _a.serviceId, segmentIds = _a.segmentIds, passengerId = _a.passengerId, count = _a.count;
                if (service.id === serviceId) {
                    var id = "".concat(serviceId, "-").concat(passengerId);
                    var segment = segments.find(function (segment) { return segmentIds.includes(segment.id); });
                    var passenger = passengers.find(function (passenger) { return passenger.id === passengerId; });
                    var existService = selectedServicesMap.get(id);
                    if (existService) {
                        selectedServicesMap.set(id, __assign(__assign({}, existService), { count: existService.count + count, segments: __spreadArray(__spreadArray([], __read(existService.segments), false), [segment], false) }));
                    }
                    else {
                        var selectedService = {
                            service: service,
                            segments: [segment],
                            passengerId: passengerId,
                            passenger: passenger,
                            count: count
                        };
                        selectedServicesMap.set(id, selectedService);
                    }
                }
            });
        });
        return __spreadArray([], __read(selectedServicesMap.values()), false);
    }, [services, segments, selectedServices, passengers]);
    return {
        selectedItems: selectedItems,
        hasSelected: selectedItems.length > 0
    };
};
