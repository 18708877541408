import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useTheme } from '../../../../theme';
import { Button } from '../../../../index';
import { HistoryIcon, RemoveIcon } from './icons';
import Modal from '../../../../Modal';
import HistoryModal from '../HistoryModal/HistoryModal';
import ClearModal from '../ClearModal/ClearModal';
import { useFindOrderMutation } from '@websky/graphql';
import UnsavedModal from '../UnsavedModal/UnsavedModal';
var Header = function (props) {
    var _a, _b;
    var css = useTheme('Terminal').Header;
    var t = useTranslation('Terminal').t;
    var history = useHistory();
    var _c = __read(useFindOrderMutation(), 2), findOrder = _c[0], loading = _c[1].loading;
    var openHistoryModal = function () { return props.setHistoryModal(true); };
    var closeHistoryModal = function () { return props.setHistoryModal(false); };
    var openClearModal = function () { return props.setClearModal(true); };
    var closeClearModal = function () { return props.setClearModal(false); };
    var renderCloseButton = useCallback(function (close) { return React.createElement(Button, { className: css.modal__close, onClick: close }); }, []);
    var onClearHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        var values, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, ((_a = props.onClear) === null || _a === void 0 ? void 0 : _a.call(props))];
                case 1:
                    values = _b.sent();
                    if (values === null || values === void 0 ? void 0 : values.length) {
                        closeClearModal();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _b.sent();
                    console.log(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onSelectRequestHandler = function (request) {
        if (!props.isLocked) {
            props.onSelectRequest(request);
            closeHistoryModal();
        }
    };
    var onGoToOrder = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, oldPath, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, findOrder({ variables: { params: { id: props.extras.recloc } } })];
                case 1:
                    data = (_a.sent()).data;
                    oldPath = location.href.split('#')[0];
                    if (data === null || data === void 0 ? void 0 : data.FindOrder) {
                        window.open("".concat(oldPath, "#/booking/").concat(data.FindOrder.id, "/passengers"));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    console.log(e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [findOrder, props.extras, props.onClose, history]);
    var isNewPnrExtras = props.extras && /^NEWPNR/.test(props.extras.recloc);
    var hasUnsavedChanges = ((_a = props.extras) === null || _a === void 0 ? void 0 : _a.activeChanges) > 0;
    var renderPnrButton = useCallback(function (onClick) { return (React.createElement(Button, { variant: "text", onClick: onClick, disabled: isNewPnrExtras, isLoading: loading, className: css.header__button, classes: {
            disabled: css.header__buttonDisabled
        } }, isNewPnrExtras ? t('NEW PNR') : props.extras.recloc)); }, [onGoToOrder, isNewPnrExtras, loading]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.header_title },
                t('Terminal'),
                ((_b = props.extras) === null || _b === void 0 ? void 0 : _b.recloc) && (React.createElement(UnsavedModal, { hasUnsaved: hasUnsavedChanges, renderButton: renderPnrButton, onContinue: onGoToOrder }))),
            React.createElement("div", { className: css.buttons },
                React.createElement(Button, { className: css.icon, onClick: openHistoryModal }, HistoryIcon),
                React.createElement(Button, { className: css.icon, onClick: openClearModal }, RemoveIcon),
                React.createElement(UnsavedModal, { hasUnsaved: hasUnsavedChanges, onContinue: props.onClose }))),
        React.createElement(Modal, { maxWidth: "sm", open: props.isHistoryModal, onClose: closeHistoryModal, closeButtonRenderer: renderCloseButton },
            React.createElement(HistoryModal, { messages: props.messages, onSelect: onSelectRequestHandler })),
        React.createElement(Modal, { maxWidth: "xs", open: props.isClearModal, onClose: closeClearModal, closeButtonRenderer: renderCloseButton },
            React.createElement(ClearModal, { onClose: closeClearModal, onClear: onClearHandler }))));
};
export default Header;
