import * as React from 'react';
import { memo } from 'react';
import MealComponent from '../../Meal';
import { useConfig } from '../../context';
import memes from '../../memes';
var reduceSelectedMealsCount = memes(function (total, selectedMeal, meal, passenger, segmentId) {
    return meal.id === selectedMeal.mealId &&
        segmentId === selectedMeal.segmentId &&
        passenger.id === selectedMeal.passengerId
        ? total + selectedMeal.count
        : total;
});
export var MealWrapper = memo(function (_a) {
    var selectedMeals = _a.selectedMeals, meal = _a.meal, segmentId = _a.segmentId, passengers = _a.passengers, onMealAdd = _a.onMealAdd, onMealRemove = _a.onMealRemove, onClear = _a.onClear, _b = _a.readonly, readonly = _b === void 0 ? false : _b, fullSize = _a.fullSize;
    var maxMealPerPassenger = useConfig().global.maxMealPerPassenger;
    return (React.createElement(MealComponent, { key: "".concat(meal.id, "_").concat(segmentId), meal: meal, segmentId: segmentId, fullSize: fullSize, onAddForAll: function (meal) {
            return passengers.forEach(function (_a) {
                var passengerId = _a.id;
                return onMealAdd(meal, segmentId, passengerId);
            });
        }, onIncrease: function (meal, passenger) { return onMealAdd(meal, segmentId, passenger.id); }, onDecrease: function (meal, passenger) { return onMealRemove(meal, segmentId, passenger.id); }, onClear: function (meal) { return onClear(meal, segmentId); }, readonly: readonly || !meal.canBeAdded, counters: passengers
            .filter(function (passenger) { return meal.allowedPassengers.some(function (passengerId) { return passengerId === passenger.id; }); })
            .map(function (passenger) {
            var _a;
            return ({
                passenger: {
                    id: passenger.id,
                    name: passenger.name
                },
                count: selectedMeals.reduce(function (total, selectedMeal) {
                    return reduceSelectedMealsCount(total, selectedMeal, meal, passenger, segmentId);
                }, 0),
                minAvailable: ((_a = selectedMeals.find(function (selectedMeal) {
                    return selectedMeal.mealId === meal.id && selectedMeal.passengerId === passenger.id;
                })) === null || _a === void 0 ? void 0 : _a.confirmedCount) || 0
            });
        }), maxAvailable: maxMealPerPassenger }));
});
