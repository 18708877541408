import * as React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Choice from '../Choice/Choice';
import Button from '../../../../../Button/Button';
import { useTheme } from '../../../../../theme';
export var CommonSelect = function (props) {
    var theme = useTheme('Exchange').CommonSelect;
    var t = useTranslation('Exchange').t;
    var history = useHistory();
    var onBackHandler = function () {
        history.goBack();
    };
    return (React.createElement("div", { className: theme.container },
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.icon }, props.icon),
            props.name),
        React.createElement(Choice, { items: props.items, onChange: props.onChange, selectedKeys: props.selectedKeys, controlType: props.controlType }),
        React.createElement("div", { className: theme.buttons },
            !props.hideBackButton && (React.createElement(Button, { variant: "flat", onClick: onBackHandler, classes: {
                    root: theme.back
                } }, t('Back'))),
            React.createElement(Button, { onClick: props.onNextHandler, variant: "flat", classes: {
                    root: theme.button
                }, disabled: !props.selectedKeys.length }, t('Next')))));
};
export default CommonSelect;
