import { __assign } from "tslib";
import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import cn from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '../theme';
import { useIsEdgeBrowser } from '../hooks';
var loaderIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { width: "24", height: "24", fill: "white" }),
    React.createElement("circle", { opacity: "0.3", cx: "12", cy: "12", r: "9", stroke: "url(#paint0_angular)", strokeWidth: "2" }),
    React.createElement("defs", null,
        React.createElement("radialGradient", { id: "paint0_angular", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(12 12) rotate(90) scale(10)" },
            React.createElement("stop", { stopOpacity: "0" }),
            React.createElement("stop", { offset: "1" })))));
var Input = function (props) {
    var _a, _b, _c;
    var _d, _e, _f, _g, _h, _j, _k;
    var theme = useTheme('Input').Input;
    var variant = props.variant || 'filled';
    return (React.createElement(TextField, __assign({ defaultValue: props.defaultValue, error: props.error, fullWidth: true, placeholder: props.placeholder, label: props.label, disabled: props.disabled, variant: variant, inputProps: __assign({ autoComplete: useIsEdgeBrowser() ? 'autocomplete_off' : 'off', autoCorrect: 'off', autoCapitalize: 'off', autoFocus: props.autoFocus, spellCheck: false, tabIndex: props.tabIndex ? props.tabIndex : null, className: cn(theme.real_input, props.inputPropsClassName) }, props === null || props === void 0 ? void 0 : props.inputProps), FormHelperTextProps: {
            classes: {
                root: props.helperClassName,
                error: cn(theme.hint, (_a = {}, _a[theme.hint_error] = props.absoluteHintPosition, _a))
            }
        }, value: props.value, name: props.name }, props.TextFieldProps, { className: cn(theme.root, props.className, (_d = props === null || props === void 0 ? void 0 : props.TextFieldProps) === null || _d === void 0 ? void 0 : _d.className), InputLabelProps: {
            shrink: props.labelShrink,
            classes: __assign({ root: cn(theme.textField__root, props.labelClassName, (_b = {},
                    _b[theme.textField_standard] = variant === 'standard',
                    _b[props.inputReadonlyClassName] = props.readOnly,
                    _b)), focused: cn(theme.textField, theme.textField_focused, props.labelFocusedClassName), error: cn(theme.label_error, props.labelErrorClassName), shrink: cn(theme.label_shrink, props.labelShrinkClassName), outlined: theme.textField_outlined, filled: theme.textField_filled, disabled: cn(theme.label_disabled, props.labelDisabledClassName) }, (_f = (_e = props.TextFieldProps) === null || _e === void 0 ? void 0 : _e.InputLabelProps) === null || _f === void 0 ? void 0 : _f.classes)
        }, InputProps: __assign(__assign({ startAdornment: (_g = props.startAdornment) !== null && _g !== void 0 ? _g : null, endAdornment: props.isLoading ? (React.createElement(InputAdornment, { position: "end", className: theme.loader }, loaderIcon)) : (props.endAdornment), inputComponent: props.inputComponent, inputRef: props.inputRef, disableUnderline: variant !== 'standard' }, (_h = props === null || props === void 0 ? void 0 : props.TextFieldProps) === null || _h === void 0 ? void 0 : _h.InputProps), { classes: __assign({ disabled: cn(theme.disabled, props.inputDisabledClassName), focused: cn(theme.input_focused, props.inputFocusedClassName), root: cn(theme.input, props.inputClassName, (_c = {},
                    _c[theme.input_filled] = variant === 'filled',
                    _c[theme.input_outline] = variant === 'outlined',
                    _c[theme.input_standard] = variant === 'standard',
                    _c)), underline: theme.underline, notchedOutline: variant === 'outlined' ? theme.input_outline : null, error: cn(theme.input_error, props.inputErrorClassName) }, (_k = (_j = props.TextFieldProps) === null || _j === void 0 ? void 0 : _j.InputProps) === null || _k === void 0 ? void 0 : _k.classes) }) })));
};
export default Input;
