import { __read, __spreadArray, __values } from "tslib";
import { detectDirection as detectDirectionAdditionalBaggage } from '../AdditionalBaggage/utils';
export var convertBaggageOnBaggageItem = function (selectedItems, order) {
    var directions = getDirections(selectedItems, order);
    var servicesMap = new Map(selectedItems.map(function (service) { return [service.baggage.allowedPassengers[0], service]; }));
    var totalCount = __spreadArray([], __read(servicesMap.values()), false).reduce(function (count, service) { return +count + +service.count; }, 0);
    return {
        baggage: [selectedItems[0].baggage],
        selected: true,
        priceFrom: null,
        servicesBySegments: [
            {
                count: totalCount,
                directions: directions,
                allSegments: directions.length === order.flight.segments.length
            }
        ]
    };
};
export var calcCount = function (selectedItems) {
    return selectedItems[0].confirmedCount;
};
export var getDirections = function (selectedItems, order) {
    var segmentIds = new Set();
    selectedItems.forEach(function (item) {
        var segments = item.segmentIds;
        segments.forEach(function (id) { return segmentIds.add(id); });
    });
    var segmentRoutes = [];
    segmentIds.forEach(function (id) {
        var orderSegment = order.flight.segments.find(function (segment) { return segment.segment.id === id; });
        segmentRoutes.push({
            arrival: orderSegment.segment.arrival.airport,
            departure: orderSegment.segment.departure.airport
        });
    });
    return segmentRoutes;
};
export var factorySelectedUpgrades = function (selectedServices) {
    var e_1, _a, e_2, _b, e_3, _c;
    var _d, _e, _f;
    var result = [];
    var addedKeys = new Map();
    try {
        for (var _g = __values(Object.entries(selectedServices)), _h = _g.next(); !_h.done; _h = _g.next()) {
            var _j = __read(_h.value, 2), passengerId = _j[0], passengerSelectedServices = _j[1];
            try {
                for (var _k = (e_2 = void 0, __values(Object.entries(passengerSelectedServices))), _l = _k.next(); !_l.done; _l = _k.next()) {
                    var _m = __read(_l.value, 2), serviceId = _m[0], segments = _m[1];
                    try {
                        for (var _o = (e_3 = void 0, __values(Object.entries(segments))), _p = _o.next(); !_p.done; _p = _o.next()) {
                            var _q = __read(_p.value, 1), segmentId = _q[0];
                            var segmentIds = (_d = selectedServices[passengerId][serviceId][segmentId]) === null || _d === void 0 ? void 0 : _d.segmentIds;
                            var key = "".concat(passengerId, "-").concat(serviceId, "-").concat(segmentIds.join('-'));
                            if (!addedKeys.has(key)) {
                                result.push({
                                    passengerId: passengerId,
                                    segmentIds: (_e = selectedServices[passengerId][serviceId][segmentId]) === null || _e === void 0 ? void 0 : _e.segmentIds,
                                    serviceId: serviceId,
                                    count: (_f = selectedServices[passengerId][serviceId][segmentId]) === null || _f === void 0 ? void 0 : _f.count
                                });
                                addedKeys.set(key, key);
                            }
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_p && !_p.done && (_c = _o.return)) _c.call(_o);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_l && !_l.done && (_b = _k.return)) _b.call(_k);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_h && !_h.done && (_a = _g.return)) _a.call(_g);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return result;
};
export var detectUpgradeDirection = function (service, selectedServices, order) {
    return detectDirectionAdditionalBaggage(service, selectedServices, order);
};
