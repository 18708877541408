import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { Field as FinalFormField } from 'react-final-form';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
export var BaseField = function (props) {
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    return (React.createElement(FinalFormField, { name: props.name, validate: function (value) {
            if (props.optional) {
                return !(value === undefined) ? props.validation(value, props.errorText) : undefined;
            }
            else {
                return !(value === undefined)
                    ? props.validation(value, props.errorText)
                    : t('Required');
            }
        }, render: function (_a) {
            var input = _a.input, meta = _a.meta;
            return (React.createElement(React.Fragment, null,
                React.createElement(Tooltip, { open: meta.touched && meta.error && props.showErrorInTooltip, arrow: true, title: meta.touched && meta.error ? meta.error : '' },
                    React.createElement("input", { type: "text", className: theme.formControl__control, value: input.value, onChange: input.onChange, readOnly: props.readOnly })),
                !props.showErrorInTooltip && (React.createElement("div", { className: theme.fieldErrorText }, meta.touched && meta.error))));
        } }));
};
export var StringField = function (props) {
    var t = useTranslation('ManualActions').t;
    var stringValidation = function (value, errorText) {
        if (!/^[A-Za-z]+$/.test(value)) {
            return errorText;
        }
        return undefined;
    };
    return React.createElement(BaseField, __assign({}, props, { validation: stringValidation, errorText: t('Value must be a string') }));
};
export var NumberField = function (props) {
    var t = useTranslation('ManualActions').t;
    var numberValidation = function (value, errorText) {
        if (!/^[+-]?([0-9]*[.])?[0-9]+$/.test(value)) {
            console.log('validation error');
            return errorText;
        }
        console.log('ok');
        return undefined;
    };
    return React.createElement(BaseField, __assign({}, props, { validation: numberValidation, errorText: t('Value must be a number') }));
};
export var NumberStringField = function (props) {
    var t = useTranslation('ManualActions').t;
    var integerStringValidation = function (value, errorText) {
        if (!/^[A-Za-z0-9]+$/.test(value)) {
            return errorText;
        }
        return undefined;
    };
    return (React.createElement(BaseField, __assign({}, props, { validation: integerStringValidation, errorText: t('Value must contain only digits and letters') })));
};
export var AnySymbolField = function (props) {
    var t = useTranslation('ManualActions').t;
    var requiredValidation = function (value, errorText) {
        if (!value) {
            return errorText;
        }
        return undefined;
    };
    return React.createElement(BaseField, __assign({}, props, { validation: requiredValidation, errorText: t('Required') }));
};
export var CheckboxField = function (props) {
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    return (React.createElement(FinalFormField, { name: props.name, type: 'checkbox', validate: function (value) {
            return !props.optional && !value ? t('Required') : undefined;
        }, render: function (_a) {
            var _b;
            var input = _a.input, meta = _a.meta;
            var type = input.type, restInputProps = __rest(input, ["type"]);
            return (React.createElement(FormControlLabel, { control: React.createElement(Tooltip, { open: meta.submitFailed && !input.value && !props.optional, arrow: true, title: props.toolTip },
                    React.createElement(Checkbox, __assign({ classes: {
                            root: theme.formCheckbox__mark,
                            checked: theme.formCheckbox__mark_checked
                        }, color: "primary" }, restInputProps))), label: React.createElement("span", { className: cn(theme.formCheckbox__text, (_b = {},
                        _b[theme.formCheckbox__text_checked] = input.value,
                        _b)) }, props.label) }));
        } }));
};
