import { __assign, __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { Datepicker } from '../../../../../../Datepicker';
import Popover from '@material-ui/core/Popover';
import { ThemeProvider, useTheme } from '../../../../../../theme';
import { useDatepickerStyles } from './hooks';
import { parse } from 'date-fns';
import { API_DATE_FORMAT } from '../../../../../../utils';
import { ChevronRightIcon } from '../../../../../../AircraftPopup/components/Icons';
var CalendarPopover = function (props) {
    var _a;
    var datepickerProps = props.datepickerProps, flightDates = props.flightDates, currentSearchSegments = props.currentSearchSegments, popoverProps = __rest(props, ["datepickerProps", "flightDates", "currentSearchSegments"]);
    var css = useTheme('Schedule').CalendarPopover;
    var datepickerStyles = useDatepickerStyles();
    var highlightedDates = flightDates.map(function (date) { return parse(date, API_DATE_FORMAT, new Date()); });
    var departureDates = currentSearchSegments === null || currentSearchSegments === void 0 ? void 0 : currentSearchSegments.filter(function (segment, index) { return index % 2 === 0; }).map(function (segment) { return parse(segment.date, API_DATE_FORMAT, new Date()); });
    var arrivalDates = currentSearchSegments === null || currentSearchSegments === void 0 ? void 0 : currentSearchSegments.filter(function (segment, index) { return index % 2 === 1; }).map(function (segment) { return parse(segment.date, API_DATE_FORMAT, new Date()); });
    return (React.createElement(React.Fragment, null,
        React.createElement(Popover, __assign({ classes: { paper: css.paper } }, popoverProps),
            React.createElement(ThemeProvider, { value: datepickerStyles },
                React.createElement(Datepicker, __assign({ navButtons: [
                        React.createElement("div", { className: cn(css.nav, css.prev) }, ChevronRightIcon),
                        React.createElement("div", { className: css.nav }, ChevronRightIcon)
                    ], monthCount: 3, minDate: new Date(), highlightedDates: (_a = {},
                        _a[css.hasFlight] = highlightedDates,
                        _a[css.departure] = departureDates,
                        _a[css.arrival] = arrivalDates,
                        _a) }, datepickerProps))))));
};
export default CalendarPopover;
