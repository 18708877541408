import * as React from 'react';
import Modal from '../../Modal';
import { useTheme } from '../../theme';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../utils';
import { SlideBottom } from '../../index';
import SimpleLoader from '../../SimpleLoader';
var SelectSegments = function (props) {
    var segments = props.segments, title = props.title, renderButton = props.renderButton, isOpen = props.isOpen, onClose = props.onClose, renderSegment = props.renderSegment, isLoading = props.isLoading;
    var css = useTheme('SelectSegments').SelectSegments;
    var content = React.useMemo(function () {
        return (React.createElement("div", { className: css.content },
            React.createElement("h3", { className: css.title }, title),
            renderSegment
                ? segments.map(renderSegment)
                : segments.map(function (segment) { return (React.createElement("div", { key: segment.id, className: css.segment },
                    React.createElement("div", { className: css.routes },
                        React.createElement("span", { className: css.iata },
                            segment.departure.airport.iata,
                            " - ",
                            segment.arrival.airport.iata),
                        React.createElement("span", { className: css.cities },
                            segment.departure.airport.name,
                            " - ",
                            segment.arrival.airport.name)),
                    renderButton(segment))); })));
    }, [segments, renderButton, isOpen, title]);
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: isOpen, onClose: onClose, maxWidth: 'md' }, content)),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: isOpen, onClose: onClose }, content))));
};
export default SelectSegments;
