import { __extends } from "tslib";
import { IndexedDBWrapper } from '../../indexedDBWrapper';
var TerminalDB = /** @class */ (function (_super) {
    __extends(TerminalDB, _super);
    function TerminalDB() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.storeName = 'terminal';
        _this.storeParams = {
            autoIncrement: true
        };
        return _this;
    }
    return TerminalDB;
}(IndexedDBWrapper));
export { TerminalDB };
