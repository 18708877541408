import * as React from 'react';
import Refund from '../ManualActions/Refund/Refund';
import { useRouteMatch } from 'react-router';
import { Route, useHistory } from 'react-router-dom';
import Wrapper from '../../Refund/Components/Wrapper/Wrapper';
import { useTheme } from '../../theme';
var RefundAdditionalRoutes = function (props) {
    var match = useRouteMatch();
    var baseUrl = match.url;
    var history = useHistory();
    var css = useTheme('Refund').Refund;
    var handleSubmit = function () {
        var checkoutURL = baseUrl.replace(/\/refund/g, '');
        history.push(checkoutURL);
    };
    return (React.createElement(Route, { path: '/booking/:orderId/refund/new/manual' },
        React.createElement(Wrapper, { onClose: props.onClose, className: css.wrapper },
            React.createElement("div", { style: { padding: '0 50px' } },
                React.createElement(Refund, { onSubmit: handleSubmit, onCancel: handleSubmit })))));
};
export default RefundAdditionalRoutes;
