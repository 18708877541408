import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { Form } from 'react-final-form';
import Fields from '../Fields/Fields';
import Link from '../../../../../Link';
import Button from '../../../../../Button/Button';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { useMutation } from '@apollo/react-hooks';
import * as PersonalPage from '../../../../../__queries__/PersonalPage.graphql';
import * as Queries from '../../../../../__queries__/query.graphql';
import { useConfig } from '../../../../../context';
import { LoginType } from '../../../../../__generated__/graphql';
var Login = function (props) {
    var t = useTranslation('Loyalty').t;
    var css = useTheme('Loyalty').LoyaltyConnect;
    var _a = __read(useMutation(PersonalPage.SendPersonalDetails), 1), updateUserProfile = _a[0];
    var _b = __read(useMutation(Queries.Authenticate), 1), authenticate = _b[0];
    var companyInfo = useConfig().global.companyInfo;
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_1, res;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!(props.queryType === 'authenticate')) return [3 /*break*/, 5];
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, authenticate({
                            variables: {
                                params: {
                                    loginType: LoginType.FFP,
                                    login: data['loyaltyNumber'],
                                    secureCode: data['loyaltyPassword']
                                }
                            }
                        })];
                case 2:
                    res = _c.sent();
                    if (!!((_a = res.data) === null || _a === void 0 ? void 0 : _a.Authenticate) && !res.errors) {
                        props === null || props === void 0 ? void 0 : props.onLoyaltyCardSaved();
                    }
                    else {
                        return [2 /*return*/, {
                                loyaltyPassword: t('Incorrect card number or password')
                            }];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _c.sent();
                    return [2 /*return*/, {
                            loyaltyPassword: t('Incorrect card number or password')
                        }];
                case 4: return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, updateUserProfile({
                        variables: {
                            params: {
                                loyaltyCards: [
                                    {
                                        airlineCode: companyInfo.iataCode,
                                        cardNumber: data['loyaltyNumber'],
                                        pinCode: data['loyaltyPassword']
                                    }
                                ]
                            }
                        }
                    })];
                case 6:
                    res = _c.sent();
                    if ((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.UpdateUserProfile) {
                        props === null || props === void 0 ? void 0 : props.onLoyaltyCardSaved();
                        return [2 /*return*/, undefined];
                    }
                    return [2 /*return*/, {
                            loyaltyPassword: t('Incorrect card number or password')
                        }];
                case 7: return [2 /*return*/, undefined];
            }
        });
    }); };
    return (React.createElement("div", { className: css.loyaltyConnect },
        React.createElement("div", { className: css.header }, t('Log in to LightJet Club')),
        React.createElement("div", null,
            React.createElement(Form, { onSubmit: onSubmit }, function (_a) {
                var handleSubmit = _a.handleSubmit, submitting = _a.submitting, submitSucceeded = _a.submitSucceeded, submitErrors = _a.submitErrors;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: css.group },
                        React.createElement(Fields, null)),
                    React.createElement("div", { className: css.buttons__wrapper },
                        React.createElement("div", { className: css.actions },
                            React.createElement(Link, { action: props.toResetPassword }, t('Forgot password?')),
                            React.createElement(Link, { action: props.toCreateAccount }, t('Not a member yet? Join for free!'))),
                        React.createElement(Button, { type: "submit", isLoading: submitting, isSuccess: submitSucceeded }, t('Connect')))));
            }))));
};
export default Login;
