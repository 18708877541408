import { format } from 'date-fns';
import { isMultiline } from '../transportationOptions';
import { AviaPassengerType } from '../../../../__generated__/globalTypes';
import { isDigits, sirenaPassengerTypes, isConsistPlusChar, isMonthInMMMFormat, getEngMonth, defaultYear, getLocalization } from '../helpers';
var JW = /^ОЦ|^JW/gm;
var PLUS_ON_START = /^\+/gm;
var SLASH = /\//;
var LINEFEED = /\n/;
var defaultPassenger = [
    {
        passengerType: AviaPassengerType.ADT,
        count: 1
    }
];
export var removeJW = function (str) { return str.replace(JW, '').replace(PLUS_ON_START, ''); };
export var getFullDate = function (str) {
    var day = str.slice(0, 2);
    var strWithCorrectMonth = str;
    if (isMonthInMMMFormat(str)) {
        strWithCorrectMonth = strWithCorrectMonth.toLowerCase();
        if (getLocalization(strWithCorrectMonth) === 'rus') {
            var month = strWithCorrectMonth.slice(2, 5);
            strWithCorrectMonth = strWithCorrectMonth.replace(month, getEngMonth(month));
        }
        if (strWithCorrectMonth.length === 9 || strWithCorrectMonth.length === 7) {
            return format(new Date(strWithCorrectMonth), 'yyyy-MM-dd');
        }
        else {
            return "".concat(defaultYear, "-").concat(format(new Date(strWithCorrectMonth), 'MM-dd'));
        }
    }
    else {
        var month = strWithCorrectMonth.slice(2, 4);
        if (strWithCorrectMonth.length > 4) {
            var year = strWithCorrectMonth.slice(4);
            year = year.length === 2 ? "20".concat(year) : year;
            return "".concat(year, "-").concat(month, "-").concat(day);
        }
        else {
            return "".concat(defaultYear, "-").concat(month, "-").concat(day);
        }
    }
};
export var getSegments = function (str) {
    var dateSubStr = str.slice(6);
    var iatas = [str.slice(0, 3).toUpperCase(), str.slice(3, 6).toUpperCase()];
    var firstSegment = function (fullDate) { return ({
        date: getFullDate(fullDate),
        departure: {
            iata: iatas[0]
        },
        arrival: {
            iata: iatas[1]
        }
    }); };
    if (SLASH.test(str)) {
        var dates = dateSubStr.split('/');
        return [
            firstSegment(dates[0]),
            {
                date: getFullDate(dates[1]),
                departure: {
                    iata: iatas[1]
                },
                arrival: {
                    iata: iatas[0]
                }
            }
        ];
    }
    else {
        return [firstSegment(dateSubStr)];
    }
};
export var getPassengers = function (str) {
    if (isConsistPlusChar(str)) {
        var passengers = str.split('+');
        return passengers.map(function (passenger) {
            var passengerCount = passenger.slice(0, passenger.length - 3);
            return {
                passengerType: sirenaPassengerTypes[passenger.slice(-3)] || passenger.slice(-3),
                count: isDigits(passengerCount) ? +passengerCount : 1
            };
        });
    }
    else {
        if (str.length > 2) {
            var passengerCount = str.slice(0, str.length - 3);
            return [
                {
                    passengerType: sirenaPassengerTypes[str.slice(-3)] || str.slice(-3),
                    count: isDigits(passengerCount) ? +passengerCount : 1
                }
            ];
        }
        return defaultPassenger;
    }
};
export var sirenaTransportationOptions = function (str) {
    var strWithoutJW = removeJW(str).trim();
    if (isMultiline(strWithoutJW)) {
        var lines = strWithoutJW.split(LINEFEED);
        var passengersInfo = lines.splice(0, 1);
        return {
            passengers: getPassengers(passengersInfo[0].trim()),
            segments: lines.reduce(function (acc, line) { return acc.concat(getSegments(line.trim())); }, [])
        };
    }
    else {
        return {
            passengers: defaultPassenger,
            segments: getSegments(strWithoutJW)
        };
    }
};
