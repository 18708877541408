import { __assign, __awaiter, __generator } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import SeatMapService from './SeatMapService/SeatMapService';
import { useGraphQLClient } from '../../graphql-client';
import { getSelectedServices, updateSeatServices } from '../utils';
import SimpleLoader from '../../SimpleLoader';
var SeatMapComponentStore = React.memo(function (_a) {
    var className = _a.className, orderId = _a.orderId, passengers = _a.passengers, seatMap = _a.seatMap, runServiceRequest = _a.runServiceRequest, onProceed = _a.onProceed, _b = _a.preSelectedPassenger, preSelectedPassenger = _b === void 0 ? 0 : _b, segments = _a.segments, fillOrder = _a.fillOrder, showRecommendations = _a.showRecommendations, travellers = _a.travellers, _c = _a.fullScreen, fullScreen = _c === void 0 ? true : _c, setSeatCallback = _a.setSeatCallback, setClearSeatCallback = _a.setClearSeatCallback, onSegmentClear = _a.onSegmentClear, isLoading = _a.isLoading, confirmRecommendedSeatsWithoutDialog = _a.confirmRecommendedSeatsWithoutDialog;
    var seatSelectHandler = useCallback(function (passenger, seat, segmentId) { return __awaiter(void 0, void 0, void 0, function () {
        var currentPassenger;
        return __generator(this, function (_a) {
            currentPassenger = passengers.find(function (orderPassenger) { return orderPassenger.id === passenger.toString(10); });
            if (runServiceRequest) {
                runServiceRequest(updateSeatServices(passengers, seatMap.segments[segmentId], currentPassenger.id, seat));
            }
            else {
                setSeatCallback(seat, currentPassenger.id, segmentId.toString());
            }
            return [2 /*return*/];
        });
    }); }, [runServiceRequest, passengers, seatMap]);
    var clearSeatHandler = useCallback(function (passenger, seat, segmentId) { return __awaiter(void 0, void 0, void 0, function () {
        var currentPassenger;
        return __generator(this, function (_a) {
            currentPassenger = passengers.find(function (orderPassenger) { return orderPassenger.id === passenger.toString(10); });
            if (runServiceRequest) {
                runServiceRequest(updateSeatServices(passengers, seatMap.segments[segmentId], currentPassenger.id, seat));
            }
            else {
                setClearSeatCallback(seat, currentPassenger.id, segmentId.toString());
            }
            return [2 /*return*/];
        });
    }); }, [runServiceRequest, passengers, seatMap]);
    // do not make two request per passenger in order to clear segment seats
    var clearOnSegment = useCallback(function (segmentIndex) {
        if (runServiceRequest) {
            var passengersList_1 = [];
            var currentSegmentId_1 = seatMap.segments[segmentIndex].segmentsInfo.id;
            passengers.forEach(function (passenger, passengerIndex) {
                passengersList_1.push({
                    passengerId: passenger.id,
                    seats: getSelectedServices(passenger, seatMap.segments[segmentIndex], passengerIndex, null)
                        .filter(function (seat) { return (seat === null || seat === void 0 ? void 0 : seat.isConfirmed) || seat.segmentId !== currentSegmentId_1; })
                        .map(function (seat) { return ({
                        row: parseInt(seat.row),
                        letter: seat.letter,
                        isConfirmed: seat.isConfirmed,
                        segment: {
                            id: seat.id
                        },
                        seat: null,
                        product: null
                    }); })
                });
            });
            runServiceRequest(passengersList_1);
        }
        else {
            onSegmentClear(segmentIndex);
        }
    }, [runServiceRequest, passengers, seatMap]);
    return seatMap ? (React.createElement(React.Fragment, null,
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement(SeatMapService, { className: className, clearOnSegment: clearOnSegment, passengers: passengers, seatMaps: seatMap, preSelectedPassenger: preSelectedPassenger, onProceed: onProceed, onSelect: seatSelectHandler, fullScreen: fullScreen, onCancelSeat: clearSeatHandler, travellers: travellers, seatRecommendEnabled: showRecommendations && !!segments && !!travellers && !!fillOrder, segments: segments, orderId: orderId, runServiceRequest: runServiceRequest, setSeatCallback: setSeatCallback, confirmRecommendedSeatsWithoutDialog: confirmRecommendedSeatsWithoutDialog }))) : null;
});
var SeatMapWrapperWithGql = function (props) {
    var client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(SeatMapComponentStore, __assign({}, props))));
};
export default SeatMapWrapperWithGql;
