import { __assign, __read } from "tslib";
import * as React from 'react';
import { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import cn from 'classnames';
import { useTheme } from '../theme';
import { Chevron } from '../Icons';
var InputNumber = function (props) {
    var _a, _b, _c;
    var theme = useTheme('InputNumber').InputNumber;
    var _d = __read(useState(props.value), 2), value = _d[0], setValue = _d[1];
    var _e = __read(useState(false), 2), isFocused = _e[0], setIsFocused = _e[1];
    var refEl = useRef(null);
    var onArrowClick = function (type) {
        if ((type === 'inc' && props.hasOwnProperty('max') && value === props.max) ||
            (type === 'dec' && props.hasOwnProperty('min') && value === props.min)) {
            return;
        }
        var newValue = value;
        if (value === undefined || value === null) {
            newValue = 0;
        }
        else {
            newValue = type === 'inc' ? +value + 1 : +value - 1;
        }
        setValue(newValue);
        var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
        nativeInputValueSetter.call(refEl.current, newValue.toString());
        var inputEvent = new Event('input', { bubbles: true, cancelable: true });
        refEl.current.dispatchEvent(inputEvent);
        var blurEvent = new Event('blur', { bubbles: true, cancelable: true });
        refEl.current.dispatchEvent(blurEvent);
    };
    var handleChange = function (event) {
        var _a, _b;
        if (event.target.value === '') {
            setValue(null);
        }
        else {
            setValue(+event.target.value);
        }
        if ((_a = props.TextFieldProps) === null || _a === void 0 ? void 0 : _a.onChange) {
            (_b = props.TextFieldProps) === null || _b === void 0 ? void 0 : _b.onChange(event);
        }
    };
    return (React.createElement("div", { className: cn(theme.root, props.wrapperClassName) },
        React.createElement("div", { className: cn(theme.fixedLabel, props.fixedLabelClassName, (_a = {},
                _a[theme.absoluteLabelPosition] = props.absoluteLabelPosition,
                _a)) }, props.label),
        React.createElement("div", { className: cn(theme.inputNumber, props.inputNumberClassName, (_b = {},
                _b[props.inputNumberFocusedClassName] = isFocused,
                _b)) },
            React.createElement(TextField, __assign({ fullWidth: true, disabled: props.disabled, placeholder: props.placeholder, InputLabelProps: {
                    classes: {
                        root: cn(theme.textField__root, props.labelClassName),
                        focused: cn(theme.textField, props.labelFocusedClassName),
                        error: theme.label_error,
                        shrink: cn(theme.label_shrink)
                    }
                }, InputProps: {
                    classes: {
                        disabled: theme.disabled,
                        root: cn(theme.input, props.inputClassName),
                        focused: theme.input_focused
                    },
                    inputRef: refEl,
                    disableUnderline: true
                }, inputProps: {
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    autoCapitalize: 'off',
                    autoFocus: props.autoFocus,
                    spellCheck: false,
                    tabIndex: props.tabIndex ? props.tabIndex : null,
                    className: theme.real_input,
                    min: props.min,
                    max: props.max
                }, FormHelperTextProps: {
                    classes: {
                        error: theme.hint_error
                    }
                } }, props.TextFieldProps, { className: props.className, value: value, name: props.name, type: "number", onChange: handleChange, onKeyPress: function (event) {
                    if ((props.disableNegativeNumbers && (event === null || event === void 0 ? void 0 : event.key) === '-') ||
                        (event === null || event === void 0 ? void 0 : event.key) === '+' ||
                        (props.disableFloatNumbers && (event === null || event === void 0 ? void 0 : event.key) === '.')) {
                        event.preventDefault();
                    }
                }, onFocus: function (event) {
                    var _a, _b;
                    setIsFocused(true);
                    (_b = (_a = props.TextFieldProps) === null || _a === void 0 ? void 0 : _a.onFocus) === null || _b === void 0 ? void 0 : _b.call(_a, event);
                }, onBlur: function (event) {
                    var _a, _b;
                    setIsFocused(false);
                    (_b = (_a = props.TextFieldProps) === null || _a === void 0 ? void 0 : _a.onBlur) === null || _b === void 0 ? void 0 : _b.call(_a, event);
                } })),
            React.createElement("div", { className: cn(theme.controls, props.controlsClassName) },
                React.createElement("div", { className: cn(theme.btnInc, (_c = {},
                        _c[theme.disabled] = props.max && value >= props.max,
                        _c)), onClick: function () { return onArrowClick('inc'); } }, Chevron),
                React.createElement("div", { className: theme.btnDec, onClick: function () { return onArrowClick('dec'); } }, Chevron)))));
};
export default InputNumber;
