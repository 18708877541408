import * as React from 'react';
import { initI18n } from '../../utils';
import MealService from '../../MealService';
import MealRecommendation from '../../ServiceRecommendation/components/Meal/Component';
import { MealWrapper } from './MealWrapper';
initI18n('MealPage');
var MealPage = function (_a) {
    var meals = _a.meals, segments = _a.segments, selectedMeals = _a.selectedMeals, recommendedMeals = _a.recommendedMeals, passengers = _a.passengers, onMealAdd = _a.onMealAdd, onMealRemove = _a.onMealRemove, onClear = _a.onClear, onMobileProceed = _a.onMobileProceed, onMobileBack = _a.onMobileBack, onRecommendationConfirm = _a.onRecommendationConfirm, onRecommendationDisagree = _a.onRecommendationDisagree, _b = _a.readonly, readonly = _b === void 0 ? false : _b, _c = _a.showRecommendations, showRecommendations = _c === void 0 ? false : _c;
    return (React.createElement(React.Fragment, null,
        showRecommendations && (React.createElement(MealRecommendation, { segments: segments, passengers: passengers, recommendedMeals: recommendedMeals, onConfirm: onRecommendationConfirm, onDisagree: onRecommendationDisagree })),
        React.createElement(MealService, { meals: meals, segments: segments, selectedMeals: selectedMeals, onMobileProceed: onMobileProceed, onMobileBack: onMobileBack, mealRenderer: function (meal, segmentId, isSingleMeal) { return (React.createElement(MealWrapper, { fullSize: isSingleMeal, key: meal.id, meal: meal, segmentId: segmentId, selectedMeals: selectedMeals, passengers: passengers, onMealAdd: onMealAdd, onMealRemove: onMealRemove, onClear: onClear, readonly: readonly })); } })));
};
export default MealPage;
