import { __assign, __read } from "tslib";
import * as React from 'react';
import { useState, useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isBefore, parseISO } from 'date-fns';
import Button from '../../Button/Button';
import Input from '../../Input';
import PaymentTimeLimitPage from '../../PaymentTimeLimitPage';
import PaymentTimeLimit from '../../PaymentTimeLimit';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import Taxes from './Components/Taxes/Taxes';
import Services from './Components/Services/Services';
import RadioBtn from './Components/RadioBtn/RadioBtn';
initI18n('Payment');
var isValueEmpty = function (value) { return !value || (value === null || value === void 0 ? void 0 : value.trim().length) === 0; };
var isLengthMoreThan = function (value, maxLength) { return (value === null || value === void 0 ? void 0 : value.length) > maxLength; };
var isTimelimitExpired = function (timelimit) { return !timelimit || isBefore(parseISO(timelimit), new Date()); };
var Payment = function (props) {
    var renderSummary = props.renderSummary;
    var css = useTheme('Payment').Payment;
    var t = useTranslation('Payment').t;
    var timelimit = props.timelimit, flight = props.flight, priceToPay = props.priceToPay;
    var _a = __read(useState('cash'), 2), method = _a[0], setMethod = _a[1];
    var history = useHistory();
    var goBack = useCallback(function () {
        history.goBack();
    }, [history]);
    var validate = function (values) {
        var errors = {};
        var MAX_INVOICE_LENGTH = 42;
        if (values['method'] === 'invoice') {
            if (isValueEmpty(values['invoiceNumbers'])) {
                errors['invoiceNumbers'] = t('Required');
            }
            if (isLengthMoreThan(values['invoiceNumbers'], MAX_INVOICE_LENGTH)) {
                errors['invoiceNumbers'] = t('Value cannot be more than {{count}} characters', {
                    count: MAX_INVOICE_LENGTH
                });
            }
        }
        return errors;
    };
    if (timelimit && isTimelimitExpired(timelimit)) {
        return (React.createElement(PaymentTimeLimitPage, { title: t('Sorry,'), text: t('the time for ordering has run out'), buttonText: t('OK'), onClick: goBack }));
    }
    return (React.createElement("div", { className: css.wrapper },
        timelimit && (React.createElement(PaymentTimeLimit, { orderIsBooked: true, className: css.timeLimit__wrapper, variant: 'outlined', date: new Date(timelimit), orderId: props.orderId })),
        React.createElement(Form, { initialValues: { method: 'cash' }, onSubmit: props.onPaymentStart, validate: validate, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: css.payment },
                        React.createElement("div", { className: css.paymentMethods },
                            React.createElement("h3", null, t('Payment methods')),
                            React.createElement(RadioBtn, { setMethod: setMethod, value: 'cash', name: 'method', label: t('Cash'), method: method }),
                            React.createElement(RadioBtn, { setMethod: setMethod, value: 'invoice', name: 'method', label: t('Invoice'), method: method, component: React.createElement(Field, { name: "invoiceNumbers", type: "text", render: function (_a) {
                                        var input = _a.input, meta = _a.meta;
                                        return (React.createElement(Input, { name: "invoiceNumbers", inputPropsClassName: css.invoice__realInput, className: css.invoice__input, label: t('Payment order numbers'), absoluteHintPosition: true, TextFieldProps: __assign(__assign({}, input), { error: meta.touched && meta.error, helperText: meta.touched && meta.error }) }));
                                    } }) }),
                            React.createElement(RadioBtn, { name: 'method', setMethod: setMethod, value: 'pos', label: t('POS'), method: method })),
                        React.createElement("div", { className: css.summary },
                            React.createElement("div", null, renderSummary ? (renderSummary({
                                flight: flight,
                                totalPriceToPay: priceToPay === null || priceToPay === void 0 ? void 0 : priceToPay.total,
                                servicesPrices: priceToPay
                            })) : (React.createElement(React.Fragment, null,
                                React.createElement(Taxes, { flight: flight, totalPriceToPay: priceToPay === null || priceToPay === void 0 ? void 0 : priceToPay.total }),
                                React.createElement(Services, { servicesPrices: priceToPay })))),
                            React.createElement("div", { className: css.buttons },
                                React.createElement(Button, { variant: "text", className: css.pnrBtn, onClick: goBack }, t('Change PNR')),
                                React.createElement(Button, { type: "submit", variant: "flat", className: css.payBtn }, t('Pay')))))));
            } })));
};
export default Payment;
