import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import cn from 'classnames';
import { useAddPnrToSupplierQueueMutation } from '@websky/graphql';
import OrderStatusComponent from '../../../../OrderCard/components/OrderStatus/OrderStatus';
import { OrderCardContext } from '../../../../OrderCard/components/OrderCard';
import { useTheme } from '../../../../theme';
import { dateValidate } from '../../../EventsQueues/utils';
import { usePointOfSales } from '../../../leoCheckIsAuthenticated/pointOfSalesContext';
import { useToggleable } from '../../../../hooks';
import QueueForm from '../../../EventsQueues/Components/QueueForm/QueueForm';
import OrderChangeHistoryModal from '../../../../OrderChangeHistoryModal/components/OrderChangeHistoryModal';
import OrderTransactions from '../OrderTransactions/OrderTransactions';
var deadlineMaskFormat = 'dd.MM.yyyy';
var deadlineFormat = "yyyy-MM-dd'T'HH:mm:ssXXX";
var OrderStatus = function (props) {
    var css = useTheme('LeoOrderCard').OrderStatus;
    var t = useTranslation('OrderCard').t;
    var _a = useToggleable(false), isOrderHistoryOpen = _a.isOpen, openOrderHistory = _a.open, closeOrderHistory = _a.close;
    var _b = useContext(OrderCardContext), order = _b.order, type = _b.type;
    var posCredentials = usePointOfSales();
    var isShowTransactions = order.paymentInfo.transactionsInfo.length > 0 && type === 'review';
    var _c = __read(useAddPnrToSupplierQueueMutation(), 1), addToQueueMutation = _c[0];
    var onAddToQueueHandler = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1, error;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, addToQueueMutation({
                            variables: {
                                params: {
                                    queueNumber: values.queueNumber,
                                    categoryNumber: values.category,
                                    locator: order.locator,
                                    deadLine: values.deadline
                                        ? format(parse(values.deadline, deadlineMaskFormat, new Date()), deadlineFormat)
                                        : null,
                                    text: values.comment,
                                    supplierQueueOwner: {
                                        user: values.pos,
                                        agency: values.agency
                                    }
                                }
                            }
                        })];
                case 1:
                    data = _c.sent();
                    if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.AddPnrToSupplierQueue) {
                        return [2 /*return*/, {
                                status: 'success',
                                error: null
                            }];
                    }
                    return [2 /*return*/, {
                            status: 'error',
                            error: null
                        }];
                case 2:
                    e_1 = _c.sent();
                    error = (_b = e_1 === null || e_1 === void 0 ? void 0 : e_1.graphQLErrors) === null || _b === void 0 ? void 0 : _b[0];
                    return [2 /*return*/, {
                            status: 'error',
                            error: error.message
                        }];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [addToQueueMutation]);
    var inputFields = [
        { label: t('Comment*'), name: 'comment', isRequired: true },
        {
            label: t('Deadline'),
            name: 'deadline',
            isRequired: false,
            validate: function (value) { return dateValidate(value, deadlineMaskFormat); }
        }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(OrderStatusComponent, __assign({}, props, { buttons: React.createElement("div", { className: css.historyTransactions },
                React.createElement("span", { className: css.history, onClick: openOrderHistory }, t('History')),
                isShowTransactions && (React.createElement(OrderTransactions, { transactionsList: order.paymentInfo.transactionsInfo, transactionButtonRenderer: function (_a) {
                        var onClick = _a.onClick;
                        return (React.createElement("span", { className: cn(css.transactions, css.leoSeparator), onClick: onClick }, t('Transactions')));
                    } })),
                React.createElement(QueueForm, { header: t('Add to queue'), onSubmit: onAddToQueueHandler, inputs: inputFields, buttonText: t('Add to queue'), openModalRenderer: function (_a) {
                        var onClick = _a.onClick;
                        return (React.createElement("span", { className: cn(css.addToQueue, css.leoSeparator), onClick: onClick }, t('Add to queue')));
                    }, initialValues: {
                        agency: posCredentials === null || posCredentials === void 0 ? void 0 : posCredentials.agency,
                        pos: posCredentials === null || posCredentials === void 0 ? void 0 : posCredentials.id
                    } })) })),
        React.createElement(OrderChangeHistoryModal, { orderId: order.id, open: isOrderHistoryOpen, onClose: closeOrderHistory, key: order.id })));
};
export default OrderStatus;
