import { __read } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import Switch from '../../Switch/components/Switch';
import { Money } from '../../Money';
import { initI18n } from '../../utils';
import { BaggageType } from '../../__generated__/globalTypes';
import Additional from './Additional/Additional';
import { useTheme } from '../../theme';
import BaggageCounter from './BaggageCounter/BaggageCounter';
import BaggageList from './BaggageList/BaggageList';
import MediaQuery from '../../MediaQuery/MediaQuery';
import Tabs from '../../Tabs/Tabs';
import { passengerIcon } from '../Icons';
import { isSelectedBaggage, MAX_COUNTER_VALUE_BLOCKED, MAX_COUNTER_VALUE_FREE, Mode, serviceIsAvailableOnAllSegments, SERVICES_TYPES_TO_BLOCK } from '../utils';
import { useConfig } from '../../context';
import { useAdditionalBaggageConfirmedMap } from '../hooks';
initI18n('BaggageService');
var BaggageService = function (props) {
    var _a, _b, _c, _d;
    var theme = useTheme('BaggageService').BaggageServiceStyles;
    var _e = __read(React.useState(false), 2), sameBaggageForEachFlight = _e[0], setSameBaggageForEachFlight = _e[1];
    var _f = __read(React.useState(0), 2), selectedSegment = _f[0], setSelectedSegment = _f[1];
    var includedBaggageCount = props.baggage.filter(function (baggage) { return baggage.included && baggage.type === BaggageType.CheckedBaggage; }).length;
    var _g = useConfig().global, maxBaggage = _g.maxBaggage, maxBaggagePerPassenger = _g.maxBaggagePerPassenger, dynamicBaggagePricing = _g.dynamicBaggagePricing, companyInfo = _g.companyInfo;
    var isTerminal = (companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.iataCode) === 'TERMINAL';
    var initialBlockPosition;
    var t = useTranslation().t;
    var _h = useAdditionalBaggageConfirmedMap(props.baggage, props.selectedBaggage), hasConfirmedHandLuggageExcess = _h.hasConfirmedHandLuggageExcess, hasConfirmedBaggageExcess = _h.hasConfirmedBaggageExcess, hasConfirmedSportsEquipment = _h.hasConfirmedSportsEquipment;
    var requiredSegments = props.segments.map(function (segment) { return segment.segmentId; });
    var baggage = [], sportsEquipment = [], baggageExcess = [], handLuggageExcess = [];
    var hasSelectedSportsEquipment = false, hasSelectedBaggageExcess = false, isAllServicesAvailableForAllSegments = true, hasServicesWhichCanBeAdded = false, hasSelectedHandLuggageExcess = false;
    props.baggage.forEach(function (service) {
        if (service.canBeAdded) {
            hasServicesWhichCanBeAdded = true;
        }
        props.segments.forEach(function (segment) {
            if (isAllServicesAvailableForAllSegments) {
                isAllServicesAvailableForAllSegments = service.segmentsId.some(function (serviceSegments) {
                    return serviceSegments.some(function (serviceSegment) { return serviceSegment === segment.segmentId; });
                });
            }
        });
        if (service.type === BaggageType.SportingEquipment) {
            var isSelected = props.selectedBaggage.hasOwnProperty(service.id);
            // Make sure that service is available on all segments of the flight.
            var canBeAdded = isSelected || serviceIsAvailableOnAllSegments(service, requiredSegments);
            if (canBeAdded) {
                sportsEquipment.push({
                    baggage: service,
                    label: service.name,
                    isSelected: isSelected
                });
            }
            if (isSelected) {
                hasSelectedSportsEquipment = true;
            }
        }
        else if (service.type === BaggageType.BaggageExcess) {
            var isSelected = props.selectedBaggage.hasOwnProperty(service.id);
            // Make sure that service is available on all segments of the flight.
            var canBeAdded = isSelected || serviceIsAvailableOnAllSegments(service, requiredSegments);
            if (canBeAdded) {
                baggageExcess.push({
                    baggage: service,
                    label: t('BaggageService:up to {{value}} kg', { value: service.value.amount }),
                    isSelected: isSelected
                });
            }
            if (isSelected) {
                hasSelectedBaggageExcess = true;
            }
        }
        else if (service.type === BaggageType.HandLuggageExcess) {
            var isSelected = props.selectedBaggage.hasOwnProperty(service.id);
            // Make sure that service is available on all segments of the flight.
            var canBeAdded = isSelected || serviceIsAvailableOnAllSegments(service, requiredSegments);
            if (canBeAdded) {
                handLuggageExcess.push({
                    baggage: service,
                    label: service.size,
                    isSelected: isSelected
                });
            }
            if (isSelected) {
                hasSelectedHandLuggageExcess = true;
            }
        }
        else {
            baggage.push(service);
        }
    });
    baggage.sort(function (a, b) {
        if (a.type === b.type && a.included === b.included) {
            return 0;
        }
        if (a.type === BaggageType.CarryOn && b.type !== BaggageType.CarryOn) {
            return -1;
        }
        if (a.type !== BaggageType.CarryOn && b.type === BaggageType.CarryOn) {
            return 1;
        }
        if (a.type === BaggageType.CheckedBaggage && b.type !== BaggageType.CheckedBaggage) {
            return -1;
        }
        return 0;
    });
    baggage.sort(function (a, b) {
        var _a, _b;
        if (a.type === b.type) {
            if (a.value.amount >= b.value.amount) {
                if (((_a = b === null || b === void 0 ? void 0 : b.price) === null || _a === void 0 ? void 0 : _a.amount) > ((_b = a === null || a === void 0 ? void 0 : a.price) === null || _b === void 0 ? void 0 : _b.amount)) {
                    return -1;
                }
                if (a.included && !b.included) {
                    return -1;
                }
                else {
                    return 1;
                }
            }
            else {
                return -1;
            }
        }
        else {
            return 0;
        }
    });
    var onSportClick = function (baggageId, isSelected) {
        if (hasConfirmedSportsEquipment) {
            return;
        }
        if (isSelected) {
            props.onBaggageIncrease('0', baggageId, true);
        }
        else {
            props.onBaggageDecrease('0', baggageId, true);
        }
    };
    var onBaggageExcessSelect = function (serviceId) {
        if (hasConfirmedBaggageExcess) {
            return;
        }
        var currentSelectedService = baggageExcess.find(function (baggage) {
            return props.selectedBaggage.hasOwnProperty(baggage.baggage.id);
        });
        if (currentSelectedService && currentSelectedService.baggage.id === serviceId) {
            return;
        }
        if (currentSelectedService) {
            props.onBaggageReplace('0', currentSelectedService.baggage.id, serviceId, true);
        }
        else {
            props.onBaggageIncrease('0', serviceId, true);
        }
    };
    var onSportsBaggageToggle = function (isOpen) {
        if (hasConfirmedSportsEquipment) {
            return;
        }
        // Remove all selected sport equipment baggage services.
        if (!isOpen && hasSelectedSportsEquipment) {
            sportsEquipment.forEach(function (item) {
                if (props.selectedBaggage[item.baggage.id]) {
                    props.onBaggageDecrease('0', item.baggage.id, true);
                }
            });
        }
    };
    var onHandLuggageExcessSelect = function (serviceId) {
        if (hasConfirmedHandLuggageExcess) {
            return;
        }
        var currentSelectedService = handLuggageExcess.find(function (baggage) {
            return props.selectedBaggage.hasOwnProperty(baggage.baggage.id);
        });
        if (currentSelectedService && currentSelectedService.baggage.id === serviceId) {
            return;
        }
        if (currentSelectedService) {
            props.onBaggageReplace('0', currentSelectedService.baggage.id, serviceId, true);
        }
        else {
            props.onBaggageIncrease('0', serviceId, true);
        }
    };
    var onExcessBaggageToggle = function (isOpen) {
        if (hasConfirmedBaggageExcess) {
            return;
        }
        if (isOpen && (baggageExcess === null || baggageExcess === void 0 ? void 0 : baggageExcess[0])) {
            onBaggageExcessSelect(baggageExcess[0].baggage.id);
        }
        else if (hasSelectedBaggageExcess) {
            // Remove all selected excess baggage services.
            baggageExcess.forEach(function (baggage) {
                if (baggage.isSelected) {
                    props.onBaggageDecrease('0', baggage.baggage.id, true);
                }
            });
        }
    };
    var onHandLuggageExcessToggle = function (isOpen) {
        if (hasConfirmedHandLuggageExcess) {
            return;
        }
        if (isOpen && (handLuggageExcess === null || handLuggageExcess === void 0 ? void 0 : handLuggageExcess[0])) {
            onHandLuggageExcessSelect(handLuggageExcess[0].baggage.id);
        }
        else if (hasSelectedHandLuggageExcess) {
            // Remove all selected excess baggage services.
            handLuggageExcess.forEach(function (baggage) {
                if (baggage.isSelected) {
                    props.onBaggageDecrease('0', baggage.baggage.id, true);
                }
            });
        }
    };
    var onSwitch = function () {
        var type = !sameBaggageForEachFlight;
        setSameBaggageForEachFlight(type);
        props.onModeChange(type ? Mode.Same : Mode.Different);
    };
    var onScroll = function () {
        var element = document.getElementById('header');
        if (element) {
            if (window.scrollY < initialBlockPosition) {
                element.classList.remove(theme.header_fixed);
            }
            else if (element.getBoundingClientRect().top < 10) {
                element.classList.add(theme.header_fixed);
            }
        }
    };
    useEffect(function () {
        var element = document.getElementById('header');
        if (element) {
            initialBlockPosition = element.getBoundingClientRect().top - 3;
            // window.addEventListener('scroll', onScroll);
        }
        return function () { return window.removeEventListener('scroll', onScroll); };
    }, []);
    return (React.createElement("div", { className: cn(theme.baggage, (_a = {},
            _a[theme.baggage_full] = props.fullScreen,
            _a[theme.baggage_fullSizeItems] = props.baggage.length < 5,
            _a[theme.baggage_dialog] = !props.fullScreen,
            _a)) },
        React.createElement("div", { className: theme.content },
            React.createElement("div", { className: cn(theme.services, (_b = {},
                    _b[theme.services_simple] = props.simpleBaggageSelector,
                    _b)) },
                (props.fullScreen || (!props.simpleBaggageSelector && !props.readonly)) && (React.createElement("div", { className: theme.services__switcher },
                    React.createElement(React.Fragment, null, props.fullScreen && (React.createElement("div", { className: theme.services__main },
                        props.passengerName && (React.createElement("div", { className: theme.header__passenger },
                            React.createElement("span", { className: theme.header__passenger__icon }, passengerIcon),
                            ' ',
                            React.createElement("span", { className: theme.header__passenger__name, title: props.passengerName }, props.passengerName))),
                        props.totalPrice && props.totalPrice.amount > 0 && (React.createElement(Money, { moneyClassName: theme.header__price, money: props.totalPrice }))))),
                    props.segments.length > 1 && !props.readonly && (React.createElement(React.Fragment, null,
                        React.createElement(MediaQuery, { maxWidth: "tablet" },
                            React.createElement("div", { id: "header", className: theme.header_mobile },
                                isAllServicesAvailableForAllSegments && (React.createElement(Switch, { label: t('BaggageService:Set the same baggage for each flight'), switchPosition: "right", isActive: sameBaggageForEachFlight, mainClassName: cn(theme.checkbox, (_c = {},
                                        _c[theme.checkbox_notActive] = !sameBaggageForEachFlight,
                                        _c[theme.checkbox_active] = sameBaggageForEachFlight,
                                        _c)), onClick: onSwitch })),
                                React.createElement(Collapse, { in: !sameBaggageForEachFlight },
                                    React.createElement("div", { className: theme.segments },
                                        React.createElement(Tabs, { scrollButtons: "off", tabClassName: theme.segments__tab, tabs: props.segments.map(function (segment) {
                                                return {
                                                    header: "".concat(segment.departure.name, " \u2014 ").concat(segment.arrival.name),
                                                    value: +segment.segmentId
                                                };
                                            }), selected: +selectedSegment, onChange: setSelectedSegment, tabsAlign: "left", variant: 'scrollable' }))))),
                        React.createElement(MediaQuery, { minWidth: "tablet" }, isAllServicesAvailableForAllSegments && hasServicesWhichCanBeAdded && (React.createElement(Switch, { label: t('BaggageService:Set the same baggage for each flight'), switchPosition: "right", isActive: sameBaggageForEachFlight, mainClassName: cn(theme.checkbox, (_d = {},
                                _d[theme.checkbox_notActive] = !sameBaggageForEachFlight,
                                _d[theme.checkbox_active] = sameBaggageForEachFlight,
                                _d)), onClick: onSwitch }))))))),
                React.createElement("div", { className: theme.baggages__wrapper },
                    React.createElement(BaggageList, { canAddBaggageBySegments: props.canAddBaggageBySegments, baggage: baggage, onBaggageDecrease: props.onBaggageDecrease, onBaggageIncrease: props.onBaggageIncrease, hasBaggageExcess: hasSelectedBaggageExcess, hasSportEquipment: hasSelectedSportsEquipment, hasHandLuggageExcess: hasSelectedHandLuggageExcess, additionalBaggage: { baggageExcess: baggageExcess, sportsEquipment: sportsEquipment, handLuggageExcess: handLuggageExcess }, readonly: props.readonly, fullscreen: props.fullScreen, sameBaggageForEachFlight: sameBaggageForEachFlight, segment: props.segments[selectedSegment], segments: props.segments, selectedBaggage: props.selectedBaggage, onExcessBaggageToggle: onExcessBaggageToggle, onBaggageExcessSelect: onBaggageExcessSelect, onSportsEquipToggle: onSportsBaggageToggle, onSportsEquipSelect: onSportClick, onHandLuggageExcessToggle: onHandLuggageExcessToggle, onHandLuggageExcessSelect: onHandLuggageExcessSelect, simpleBaggageSelector: props.simpleBaggageSelector }))),
            (props.segments.length > 1 || !props.simpleBaggageSelector) && (React.createElement(MediaQuery, { minWidth: "tablet" },
                React.createElement("div", null, props.segments.map(function (segment, index) { return (React.createElement("div", { className: theme.segment, key: index },
                    React.createElement("div", { className: theme.segment__direction },
                        React.createElement(MediaQuery, { maxWidth: 'tablet' },
                            segment.departure.iata,
                            " \u2014 ",
                            segment.arrival.iata),
                        React.createElement(MediaQuery, { minWidth: 'tablet' }, isTerminal
                            ? "".concat(segment.departure.iata, " \u2014 ").concat(segment.arrival.iata)
                            : "".concat(segment.departure.name, " \u2014 ").concat(segment.arrival.name))),
                    React.createElement("div", { className: theme.values__wrapper }, baggage.map(function (service, index) {
                        var _a;
                        var _b, _c;
                        var isReadonly = props.readonly ||
                            !service.segmentsId.some(function (serviceSegments) {
                                return serviceSegments.some(function (serviceSegment) { return serviceSegment === segment.segmentId; });
                            });
                        if (!maxBaggagePerPassenger || dynamicBaggagePricing) {
                            isReadonly =
                                isReadonly ||
                                    (SERVICES_TYPES_TO_BLOCK.includes(service.type) &&
                                        !isSelectedBaggage(service.id, props.baggage, props.selectedBaggage));
                        }
                        var maxValue = MAX_COUNTER_VALUE_BLOCKED;
                        if (maxBaggage) {
                            maxValue = maxBaggage;
                        }
                        if (maxBaggagePerPassenger && props.canAddBaggageBySegments) {
                            if (props === null || props === void 0 ? void 0 : props.canAddBaggageBySegments.get(segment.segmentId)) {
                                maxValue = maxBaggage
                                    ? Math.min(maxBaggage, maxBaggagePerPassenger) -
                                        includedBaggageCount
                                    : maxBaggagePerPassenger;
                            }
                            else {
                                maxValue = 0;
                            }
                        }
                        if (!service.canBeAdded &&
                            ((_b = props.selectedBaggage[service.id]) === null || _b === void 0 ? void 0 : _b[segment.segmentId])) {
                            maxValue = (_c = props.selectedBaggage[service.id]) === null || _c === void 0 ? void 0 : _c[segment.segmentId].count;
                        }
                        return (React.createElement("div", { className: cn(theme.segment__value, (_a = {},
                                _a[theme.segment__value_inDialog] = !props.fullScreen,
                                _a)), key: index }, service.included ? (React.createElement("div", { className: theme.segment__value_readonly }, !service.includedSegments ||
                            service.includedSegments.indexOf(segment.segmentId) >= 0
                            ? service.included
                            : 0)) : (React.createElement(BaggageCounter, { selectedBaggage: props.selectedBaggage, service: service, segment: segment, sameBaggageForEachFlight: sameBaggageForEachFlight, readonly: isReadonly, maxValue: [
                                BaggageType.CarryOn,
                                BaggageType.CheckedBaggage
                            ].includes(service.type)
                                ? maxValue
                                : MAX_COUNTER_VALUE_FREE, onBaggageIncrease: props.onBaggageIncrease, onBaggageDecrease: props.onBaggageDecrease }))));
                    })))); })))),
            React.createElement(MediaQuery, { minWidth: "tablet" }, (baggageExcess.length > 0 || sportsEquipment.length > 0 || handLuggageExcess.length > 0) && (React.createElement("div", { className: theme.additional },
                handLuggageExcess.length > 0 && (React.createElement(Additional, { services: handLuggageExcess.sort(function (a, b) { return a.baggage.price.amount - b.baggage.price.amount; }), header: t('BaggageService:Increasing the size of carry-on baggage'), description: t('BaggageService:If the dimensions of your carry-on baggage exceed the permitted dimensions of 40x30x20 cm, you can increase its size up to 55x40x20 cm'), onSelect: onHandLuggageExcessSelect, fullScreen: props.fullScreen, hasProducts: hasSelectedHandLuggageExcess, onToggle: onHandLuggageExcessToggle, headerClassName: theme.hand_luggage_excess, readonly: props.readonly || hasConfirmedHandLuggageExcess, variant: props.fullScreen ? 'solid' : 'outline', numOfSegments: props.segments.length })),
                baggageExcess.length > 0 && (React.createElement(Additional, { services: baggageExcess.sort(function (a, b) { return a.baggage.price.amount - b.baggage.price.amount; }), header: t('BaggageService:Overweight'), description: t('BaggageService:Improve baggage weight you can bring to airplane'), onSelect: onBaggageExcessSelect, fullScreen: props.fullScreen, hasProducts: hasSelectedBaggageExcess, onToggle: onExcessBaggageToggle, headerClassName: theme.overweight, readonly: props.readonly || hasConfirmedBaggageExcess, variant: props.fullScreen ? 'solid' : 'outline', numOfSegments: props.segments.length })),
                sportsEquipment.length > 0 && (React.createElement(Additional, { services: sportsEquipment.sort(function (a, b) { return a.baggage.price.amount - b.baggage.price.amount; }), header: t('BaggageService:Sports equipment'), description: t('BaggageService:Your can bring your ski, snowboard, surf equipment, bicycle, etc. packed in a pouch'), onSelect: onSportClick, hasProducts: hasSelectedSportsEquipment, onToggle: onSportsBaggageToggle, type: "checkbox", readonly: props.readonly || hasConfirmedSportsEquipment, variant: props.fullScreen ? 'solid' : 'outline', numOfSegments: props.segments.length }))))))));
};
export default BaggageService;
