export var validateLogin = function (login) {
    if (!login || login.length > 256) {
        return 'Enter correct login';
    }
    return undefined;
};
export var validatePassword = function (password) {
    if (!password) {
        return 'Enter password';
    }
    return undefined;
};
