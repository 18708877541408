import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useContext } from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { nextRowHasOtherServiceClass } from '../utils';
import ExitRow from '../ExitRow/ExitRow';
import Parts from './Parts/Parts';
import { ModeContext } from '../../../../context';
import { Mode } from '../../../../types';
var Row = React.memo(function (_a) {
    var _b;
    var row = _a.row, props = __rest(_a, ["row"]);
    var theme = useTheme('SeatMap').SeatMapStyles;
    var isLastRow = props.lastRow === props.rowIndex;
    var isCheckinMode = useContext(ModeContext) === Mode.Checkin;
    var isSamePartsType = React.useMemo(function () {
        var firstClass = row.parts.find(function (part) { var _a; return (_a = part.service.seatService) === null || _a === void 0 ? void 0 : _a.comfort; });
        return !row.parts.find(function (part) {
            var _a, _b, _c;
            return !part.service.isAisle &&
                part.service.seatService &&
                ((_a = part.service.seatService) === null || _a === void 0 ? void 0 : _a.comfort) !== ((_c = (_b = firstClass === null || firstClass === void 0 ? void 0 : firstClass.service) === null || _b === void 0 ? void 0 : _b.seatService) === null || _c === void 0 ? void 0 : _c.comfort);
        });
    }, [row]);
    return (React.createElement(React.Fragment, null,
        !isLastRow && row.exitRow && React.createElement(ExitRow, null),
        React.createElement("div", { className: cn(theme.row, (_b = {},
                _b[theme.row_otherClass] = props.newClassType,
                _b[theme.row_last] = props.rowIndex === props.lastRow,
                _b[theme.row_nextRowHasOtherClass] = nextRowHasOtherServiceClass(row, props.nextRow),
                _b)), "with-wings": row.wing ? 'true' : 'false' }, row &&
            row.parts &&
            row.parts.map(function (part, partIndex) {
                var _a, _b, _c, _d, _e;
                var nextPart = row.parts[partIndex + 1];
                var currentPartService = (_b = (_a = part === null || part === void 0 ? void 0 : part.service) === null || _a === void 0 ? void 0 : _a.seatService) === null || _b === void 0 ? void 0 : _b.comfort;
                return (React.createElement(Parts, __assign({}, props, { key: partIndex, partIndex: partIndex, nextPartService: (_c = nextPart === null || nextPart === void 0 ? void 0 : nextPart.service) === null || _c === void 0 ? void 0 : _c.seatService, prevPartService: partIndex > 0 && ((_e = (_d = row.parts[partIndex - 1]) === null || _d === void 0 ? void 0 : _d.service) === null || _e === void 0 ? void 0 : _e.seatService), part: part, isCheckinMode: isCheckinMode, partService: currentPartService, serviceId: currentPartService, newClass: props.newClassType, onSelect: props.onSelect, onClear: props.onClear, isStatic: isSamePartsType })));
            })),
        isLastRow && row.exitRow && React.createElement(ExitRow, null)));
});
export default Row;
