import * as React from 'react';
import Money from '../../../Money';
import { Button } from '../../../index';
import { useTheme } from '../../../theme';
import Link from '../../../Link';
import { useTranslation } from 'react-i18next';
var Header = function (_a) {
    var onConfirm = _a.onConfirm, onClear = _a.onClear, services = _a.services, onClose = _a.onClose;
    var theme = useTheme('ExtraServices').Header;
    var t = useTranslation('ExtraServices').t;
    var totalPrice = React.useMemo(function () {
        var price = {
            amount: 0,
            currency: services[0].service.price.currency
        };
        services.forEach(function (service) {
            price.amount += service.service.price.amount * service.count;
        });
        return price;
    }, [services]);
    return (React.createElement("div", { className: theme.container },
        React.createElement("div", { className: theme.payload },
            totalPrice.amount > 0 && (React.createElement("div", { className: theme.summary },
                React.createElement(Link, { className: theme.clear, action: onClear }, t('Clear')),
                React.createElement("div", { className: theme.total },
                    t('Total'),
                    ": ",
                    React.createElement(Money, { money: totalPrice })))),
            React.createElement(Button, { className: theme.confirm, variant: "flat", onClick: onConfirm }, t('Confirm')))));
};
export default Header;
