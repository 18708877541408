import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { Link } from '../index';
import { useTheme } from '../theme';
import * as icons from './icons';
import { useConfig } from '../context';
import { PromoMessageIcons } from './types';
import { initI18n } from '../utils';
import { useTranslation } from 'react-i18next';
initI18n('PromoMessage');
var PromoMessage = function (props) {
    var theme = useTheme('PromoMessage').PromoMessage;
    var t = useTranslation('PromoMessage').t;
    var icon;
    switch (props.icon) {
        case PromoMessageIcons.TimeIsMoney:
            icon = icons.TimeIsMoneyIcon;
            break;
        default:
            break;
    }
    return (React.createElement("div", { className: cn(props.className, theme.wrapper) },
        React.createElement("div", { className: theme.inner },
            React.createElement("div", { className: theme.icon }, icon),
            React.createElement("h6", { className: theme.title }, t(props.title)),
            React.createElement("p", { className: theme.content },
                t(props.content),
                React.createElement(Link, { className: theme.link, target: '_blank', action: props.link.url }, t(props.link.text))))));
};
export var PromoMessages = function () {
    var _a, _b, _c;
    var config = useConfig();
    return React.createElement(React.Fragment, null, (_c = (_b = (_a = config.Engine) === null || _a === void 0 ? void 0 : _a.promoMessages) === null || _b === void 0 ? void 0 : _b.map(function (promoMessage) { return React.createElement(PromoMessage, __assign({}, promoMessage)); })) !== null && _c !== void 0 ? _c : null);
};
export default PromoMessage;
