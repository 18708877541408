import * as React from 'react';
import { useTheme } from '../../theme';
import Button from '../../Button/Button';
import Modal from '../../Modal';
import cn from 'classnames';
export var isArrayGuard = function (errorMessage) {
    return Array.isArray(errorMessage);
};
var WarningModal = function (_a) {
    var title = _a.title, content = _a.content, className = _a.className, errorMessage = _a.errorMessage, buttonText = _a.buttonText, isOpen = _a.isOpen, onClose = _a.onClose, onButtonClick = _a.onButtonClick, _b = _a.maxWidth, maxWidth = _b === void 0 ? 'sm' : _b;
    var css = useTheme('WarningModal').WarningModal;
    return (React.createElement(Modal, { maxWidth: maxWidth, open: isOpen, onClose: onClose, classes: {
            paper: cn(css.paper, className)
        } },
        React.createElement("div", { className: css.header }, title),
        React.createElement("div", { className: css.content },
            content,
            errorMessage && (React.createElement("div", { className: css.content__error }, isArrayGuard(errorMessage)
                ? errorMessage.map(function (error) { return React.createElement("div", null, error); })
                : errorMessage)),
            buttonText && (React.createElement("div", { className: css.buttons },
                React.createElement(Button, { onClick: onButtonClick }, buttonText))))));
};
export default WarningModal;
