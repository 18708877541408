import * as React from 'react';
import cn from 'classnames';
import { useCallback } from 'react';
import Button from '../../../../Button';
import { useTheme } from '../../../../theme';
var ScheduleTypeSelector = function (props) {
    var buttons = props.buttons, onScheduleTypeChange = props.onScheduleTypeChange, type = props.type;
    var css = useTheme('Schedule').ScheduleTypeSelector;
    var renderButton = useCallback(function (props) {
        var _a;
        return (React.createElement(Button, { key: props.title, onClick: function () { return onScheduleTypeChange(props.type); }, title: props.title, className: cn(css.button, (_a = {}, _a[css.active] = type === props.type, _a)) }, props.icon));
    }, [buttons, onScheduleTypeChange, type]);
    return React.createElement("div", { className: css.wrapper }, buttons.map(renderButton));
};
export default ScheduleTypeSelector;
