import { __read } from "tslib";
import * as React from 'react';
import Modal from '../../../Modal';
import SplitPassengers from '../SplitPassengers';
import { useMutation } from '@apollo/react-hooks';
import * as SplitOrder from '../../../__queries__/SplitOrder.graphql';
import WarningModal from '../../../WarningModal';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import Button from '../../../Button/Button';
var SplitPassengersWrapper = function (_a) {
    var _b;
    var passengers = _a.passengers, orderId = _a.orderId, onSuccess = _a.onSuccess, openOrder = _a.openOrder, renderTrigger = _a.renderTrigger;
    var _c = __read(React.useState(false), 2), open = _c[0], setOpen = _c[1];
    var _d = __read(React.useState(false), 2), isLoading = _d[0], setLoading = _d[1];
    var _e = __read(React.useState(null), 2), error = _e[0], setError = _e[1];
    var _f = __read(useMutation(SplitOrder.SplitOrder), 1), mutation = _f[0];
    var t = useTranslation('Checkout').t;
    var theme = useTheme('SplitPassengers').SplitPassengersWrapper;
    var onOpen = function () { return setOpen(true); };
    var onError = function (error) {
        setError(error);
        setOpen(false);
        setLoading(false);
    };
    var onDone = function (passengerIds, openNewOrder) {
        setLoading(true);
        mutation({
            variables: {
                orderId: orderId,
                travellerIds: passengerIds
            }
        })
            .then(function (data) {
            var _a;
            if (((_a = data.errors) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                onError(data.errors[0].message);
            }
            else if (data.data.SplitOrder) {
                setLoading(false);
                if (openNewOrder && openOrder) {
                    openOrder(data.data.SplitOrder);
                }
                else {
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                    setOpen(false);
                }
            }
        })
            .catch(function (e) {
            var _a, _b, _c;
            onError((_c = (_b = (_a = e === null || e === void 0 ? void 0 : e.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b['message']) !== null && _c !== void 0 ? _c : '');
        });
    };
    return (React.createElement(React.Fragment, null, (_b = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger(onOpen)) !== null && _b !== void 0 ? _b : (React.createElement(Button, { className: theme.splitButton, onClick: onOpen }, t('Split order'))),
        React.createElement(Modal, { isRounded: true, open: open, onClose: function () { return setOpen(false); }, maxWidth: "sm" },
            React.createElement(SplitPassengers, { passengers: passengers, onDone: onDone, loading: isLoading })),
        error !== null && (React.createElement(WarningModal, { title: t('Error while split order'), content: error, isOpen: true, onClose: function () { return setError(null); } }))));
};
export default SplitPassengersWrapper;
