import * as React from 'react';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import Snackbar from '../../../../../LEO/Snackbar/Snackbar';
import { CloseClear } from '../../../../../Icons';
import Dialog from '../Dialog/Dialog';
var PaymentResult;
(function (PaymentResult) {
    PaymentResult["Success"] = "success";
    PaymentResult["Failed"] = "failed";
})(PaymentResult || (PaymentResult = {}));
export var PaymentResultMethodType;
(function (PaymentResultMethodType) {
    PaymentResultMethodType["Modal"] = "modal";
    PaymentResultMethodType["Snackbar"] = "snackbar";
})(PaymentResultMethodType || (PaymentResultMethodType = {}));
var PaymentResultMessage = function (_a) {
    var paymentStatus = _a.paymentStatus, _b = _a.paymentResultMethodType, paymentResultMethodType = _b === void 0 ? PaymentResultMethodType.Modal : _b, setPaymentStatus = _a.setPaymentStatus;
    var theme = useTheme('Exchange').Inner;
    var t = useTranslation('Exchange').t;
    return (React.createElement("div", null,
        paymentResultMethodType === PaymentResultMethodType.Snackbar &&
            ((paymentStatus === PaymentResult.Success && (React.createElement(Snackbar, { message: t('Successful payment'), open: true, onClose: function () { return setPaymentStatus(null); }, error: false }))) ||
                (paymentStatus === PaymentResult.Failed && (React.createElement(Snackbar, { message: t('Failed payment'), open: true, onClose: function () { return setPaymentStatus(null); }, error: true })))),
        paymentResultMethodType === PaymentResultMethodType.Modal &&
            ((paymentStatus === PaymentResult.Success && (React.createElement(Dialog, { text: t('Successful payment'), acceptText: t('OK'), onAccept: function () { return setPaymentStatus(null); }, open: true }))) ||
                (paymentStatus === PaymentResult.Failed && (React.createElement(Dialog, { className: theme.dialog_failed, icon: CloseClear, text: t('Failed payment'), acceptText: t('OK'), onAccept: function () { return setPaymentStatus(null); }, open: true }))))));
};
export default PaymentResultMessage;
