import * as React from 'react';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Money } from '../../../Money';
import Link from '../../../Link';
import { useTheme } from '../../../theme';
import { initI18n } from '../../../utils';
initI18n('Aeroexpress');
var CardContent = function (_a) {
    var selectedServices = _a.selectedServices, travellers = _a.travellers;
    var t = useTranslation('Aeroexpress').t;
    var theme = useTheme('Aeroexpress').CardContent;
    var ticketsCount = selectedServices === null || selectedServices === void 0 ? void 0 : selectedServices.reduce(function (acc, selectedTrip) {
        var _a;
        return acc + ((_a = selectedTrip === null || selectedTrip === void 0 ? void 0 : selectedTrip.tickets) === null || _a === void 0 ? void 0 : _a.length);
    }, 0);
    return (React.createElement("div", { className: theme.aeroexpress__content },
        React.createElement("div", { className: theme.description },
            selectedServices.length ? (selectedServices.map(function (selectedService) { return (React.createElement("div", { className: theme.selectedService, key: selectedService.id },
                React.createElement("div", { className: theme.selectedService__content },
                    React.createElement("div", { className: theme.selectedService__date },
                        format(parse(selectedService.departureDate, 'HH:mm dd.MM.yyyy', new Date()), 'dd.MM.yyyy'),
                        "\u00A0",
                        t(selectedService.toStation.direction === 'ToAirport'
                            ? 'To airport'
                            : 'From airport')),
                    React.createElement("div", { className: theme.selectedService__info },
                        t('seat', { count: ticketsCount }),
                        ",\u00A0",
                        React.createElement("span", { className: theme.selectedService__serviceClass }, t(selectedService.class)))),
                React.createElement(Money, { moneyClassName: theme.selectedService__price, money: selectedService.price }))); })) : (React.createElement("div", { className: theme.description_not_selected }, t('No worries about traffic jams, arrival at the airport strictly on schedule'))),
            React.createElement("div", { className: theme.schedule },
                React.createElement(Link, { action: 'https://aeroexpress.ru', target: "_blank" }, t('View schedule'))))));
};
export default CardContent;
