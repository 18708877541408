import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import Input from '../../../../Input';
import { Button as Btn } from '../../../../index';
import { LoginType } from '../../../../__generated__/globalTypes';
import Timer from '../../../../Timer';
import { clearPhoneNumber } from '../../../utils';
import * as Auth from '../../../../__queries__/query.graphql';
import { useTheme } from '../../../../theme';
import MediaQuery from '../../../../MediaQuery/MediaQuery';
var successIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8.9999 16.2001L4.7999 12.0001L3.3999 13.4001L8.9999 19.0001L20.9999 7.0001L19.5999 5.6001L8.9999 16.2001Z", fill: "white" }),
    React.createElement("mask", { id: "mask0", mask: "alpha", maskUnits: "userSpaceOnUse", x: "3", y: "5", width: "18", height: "15" },
        React.createElement("path", { d: "M8.9999 16.2001L4.7999 12.0001L3.3999 13.4001L8.9999 19.0001L20.9999 7.0001L19.5999 5.6001L8.9999 16.2001Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var Verification = function (props) {
    var t = useTranslation('LoginPage').t;
    var theme = useTheme('LoginPage').Verification, _a = __read(React.useState(false), 2), isLoading = _a[0], setIsLoading = _a[1], _b = __read(React.useState(false), 2), isIncorrectCode = _b[0], setIncorrectCode = _b[1], _c = __read(React.useState(false), 2), isCodeSuccess = _c[0], setSuccessCode = _c[1];
    var _d = __read(useMutation(Auth.SendSecureCode), 1), sendCode = _d[0];
    var _e = __read(useMutation(Auth.Authenticate), 1), authenticate = _e[0];
    var resendCode = function () {
        return sendCode({
            variables: {
                params: {
                    login: props.loginType === LoginType.Phone ? clearPhoneNumber(props.login) : props.login,
                    loginType: props.loginType
                }
            }
        });
    };
    var submitCode = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, authenticate({
                            variables: {
                                params: {
                                    login: props.loginType === LoginType.Phone ? clearPhoneNumber(props.login) : props.login,
                                    loginType: props.loginType,
                                    secureCode: values['code']
                                }
                            }
                        })];
                case 2:
                    _a.sent();
                    setIncorrectCode(false);
                    setSuccessCode(true);
                    setTimeout(function () { return props.onSuccess(); }, 1300);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    setIncorrectCode(true);
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validate = function (values) {
        var errors = {};
        if (!values['code']) {
            errors['code'] = t('Required');
        }
        return errors;
    };
    return (React.createElement("div", { className: theme.LoginVerification },
        React.createElement("span", { className: theme.LoginVerification__text },
            t('We sent verification code to the'),
            " ",
            props.login),
        React.createElement(Form, { onSubmit: submitCode, validate: validate, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit, className: theme.LoginVerification__form },
                    React.createElement("div", { className: theme.LoginVerification__field },
                        React.createElement(Field, { name: "code", type: "text", render: function (_a) {
                                var _b;
                                var input = _a.input, meta = _a.meta;
                                return (React.createElement(Input, __assign({}, input, { labelFocusedClassName: cn((_b = {},
                                        _b[theme.error] = isIncorrectCode || (meta.error && meta.touched),
                                        _b)), TextFieldProps: {
                                        label: t('Enter verification code'),
                                        onChange: function (event) {
                                            input.onChange(event);
                                        },
                                        error: isIncorrectCode || (!!meta.error && meta.touched ? meta.error : null),
                                        helperText: meta.error && meta.touched
                                            ? meta.error
                                            : isIncorrectCode
                                                ? t('Please, check the code')
                                                : null
                                    }, placeholder: "XXXXXX", className: theme.LoginVerification__input })));
                            } })),
                    !isLoading && !isCodeSuccess ? (React.createElement(Btn, { children: t('Next'), type: "submit", disabled: isLoading, className: theme.submit_button })) : !isCodeSuccess ? (React.createElement(React.Fragment, null,
                        React.createElement(MediaQuery, { maxWidth: 'mobile' },
                            React.createElement(Btn, { className: theme.submit_button },
                                React.createElement("div", { className: theme.loader_spinner }))),
                        React.createElement(MediaQuery, { minWidth: 'mobile' },
                            React.createElement("div", { className: theme.loader },
                                React.createElement("div", { className: theme.loader_spinner }))))) : (React.createElement("div", { className: cn(theme.loader, theme.success) },
                        React.createElement("div", { className: theme.success__icon },
                            successIcon,
                            " ")))));
            } }),
        React.createElement("div", { className: theme.footer },
            React.createElement(Timer, { resendCode: resendCode }))));
};
export default Verification;
