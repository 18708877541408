import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { BabyCarriage } from '../../../Icons';
import { useToggleable } from '../../../hooks';
import UpgradeBaggageDialog from '../../UpgradeBaggageDialog/components/UpgradeBaggageDialog';
import BaggageCard from '../../BaggageCard';
import { BaggageType } from '../../../__generated__/globalTypes';
import { getCurrency } from '../../../cache';
import { getSportsEquipmentMinPrice } from '../../../Checkout/store/order/selectors';
import { useSelectedServicesBySegments } from '../../UpgradeBaggageDialog/hooks';
import BaggageCardItem from '../../BaggageCard/components/BaggageCardItem/BaggageCardItem';
var SpecialEquipment = function (_a) {
    var className = _a.className, passengers = _a.passengers, passengerLabels = _a.passengerLabels, segments = _a.segments, services = _a.services, selectedServices = _a.selectedServices, onUpgrade = _a.onUpgrade, isLoading = _a.isLoading, disabled = _a.disabled;
    var css = useTheme('SpecialEquipment').SpecialEquipment;
    var t = useTranslation('IncludedBaggage').t;
    var minPrice = useSelector(getSportsEquipmentMinPrice);
    var _b = useToggleable(false), isOpen = _b.isOpen, open = _b.open, close = _b.close;
    var _c = useSelectedServicesBySegments(services, segments, selectedServices, passengers), selectedItems = _c.selectedItems, hasSelected = _c.hasSelected;
    var totalPrice = useMemo(function () {
        return {
            amount: selectedItems.reduce(function (total, service) {
                var _a, _b;
                return +total + +(((_b = (_a = service.service) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.amount) || 0) * service.count;
            }, 0),
            currency: getCurrency()
        };
    }, [selectedItems]);
    return (React.createElement(React.Fragment, null,
        React.createElement(BaggageCard, { className: className, icon: BabyCarriage, title: t('Equipment'), description: t('Need to bring sports, music or baby equipments?'), hasSelected: hasSelected, fromPrice: minPrice, totalPrice: totalPrice, onAdd: open, onEdit: open }, hasSelected && (React.createElement("div", { className: css.flights }, selectedItems.map(function (service, index) {
            var isAllSegments = service.segments.length === segments.length;
            var direction = isAllSegments
                ? t('All flights')
                : "".concat(service.segments[0].departure.airport.iata, " \u2013 ").concat(service.segments[0].arrival.airport.iata);
            return (React.createElement(BaggageCardItem, { key: index, className: css.flight, baggage: service.service, count: service.count, passenger: service.passenger, direction: direction, passengerLabels: passengerLabels, description: service.service.name }));
        })))),
        React.createElement(UpgradeBaggageDialog, { open: isOpen, onClose: close, onConfirm: onUpgrade, passengers: passengers, passengerLabels: passengerLabels, segments: segments, includedBaggage: [], upgradeBaggage: services, selectedServices: selectedServices, headerProps: {
                header: t('Equipment'),
                description: ''
            }, disabled: isLoading || disabled, baggageType: BaggageType.SportingEquipment })));
};
export default SpecialEquipment;
