/* tslint:disable max-classes-per-file */
import { __extends } from "tslib";
var CheckinFatalError = /** @class */ (function (_super) {
    __extends(CheckinFatalError, _super);
    function CheckinFatalError(m) {
        var _this = _super.call(this, m) || this;
        _this.name = 'CheckinFatalError';
        Object.setPrototypeOf(_this, CheckinFatalError.prototype);
        return _this;
    }
    return CheckinFatalError;
}(Error));
export { CheckinFatalError };
var CheckinEmptySeatMapError = /** @class */ (function (_super) {
    __extends(CheckinEmptySeatMapError, _super);
    function CheckinEmptySeatMapError(m) {
        var _this = _super.call(this, m) || this;
        _this.name = 'CheckinEmptySeatMapError';
        Object.setPrototypeOf(_this, CheckinEmptySeatMapError.prototype);
        return _this;
    }
    return CheckinEmptySeatMapError;
}(CheckinFatalError));
export { CheckinEmptySeatMapError };
