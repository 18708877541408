import { __read } from "tslib";
import * as React from 'react';
import { Form } from 'react-final-form';
import { useMutation } from '@apollo/react-hooks';
import * as SendResetFfpPasswordCodeMutationQuery from '../../../../../__queries__/SendResetFfpPasswordCode.graphql';
import * as SendNewFfpPasswordMutationQuery from '../../../../../__queries__/SendNewFfpPassword.graphql';
import Fields from './Fields/Fields';
import Button from '../../../../../Button/Button';
import { useTheme } from '../../../../../theme';
import Link from '../../../../../Link';
import Resend from '../../../../../Contacts/components/Resend/Resend';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../../../context';
export var ForgotPasswordState;
(function (ForgotPasswordState) {
    ForgotPasswordState[ForgotPasswordState["Email"] = 1] = "Email";
    ForgotPasswordState[ForgotPasswordState["Code"] = 2] = "Code";
    ForgotPasswordState[ForgotPasswordState["Success"] = 3] = "Success";
})(ForgotPasswordState || (ForgotPasswordState = {}));
var successIcon = (React.createElement("svg", { width: "36", height: "36", viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { width: "36", height: "36", rx: "18", fill: "#5FCA43" }),
    React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M17.5535 22.7305L27.8743 12.4097L26.1066 10.6419L15.7858 20.9627L10.7357 15.9126L8.9679 17.6804L14.018 22.7305L14.0179 22.7306L15.7856 24.4984L15.7858 24.4982L15.7862 24.4987L17.554 22.7309L17.5535 22.7305Z", fill: "white" })));
var errorIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12ZM13 13V7.00001H11V13H13ZM12 4.00001C7.58999 4.00001 3.99999 7.59001 3.99999 12C3.99999 16.41 7.58999 20 12 20C16.41 20 20 16.41 20 12C20 7.59001 16.41 4.00001 12 4.00001ZM13 17V15H11V17H13Z", fill: "#B31B1B" })));
var ForgotPassword = function (props) {
    var _a = __read(React.useState(ForgotPasswordState.Email), 2), state = _a[0], setState = _a[1];
    var _b = __read(React.useState(''), 2), error = _b[0], setError = _b[1];
    var _c = __read(React.useState(''), 2), verifiedEmail = _c[0], setEmail = _c[1];
    var _d = __read(React.useState(false), 2), codeSent = _d[0], setCodeSended = _d[1];
    var iataCode = useConfig().global.companyInfo.iataCode;
    var _e = __read(useMutation(SendResetFfpPasswordCodeMutationQuery.FFPResetPasswordCode), 2), resetPassword = _e[0], resetPasswordLoading = _e[1].loading;
    var _f = __read(useMutation(SendNewFfpPasswordMutationQuery.FFPNewFFPPassword), 1), sendCode = _f[0];
    var t = useTranslation('Loyalty').t;
    var onSubmit = function (values) {
        setError('');
        if (state === ForgotPasswordState.Email) {
            return sendCodeRequest(values['email'])
                .then(function (data) {
                var _a;
                if ((_a = data.data) === null || _a === void 0 ? void 0 : _a.SendResetFfpPasswordCode) {
                    setEmail(values['email']);
                    setState(ForgotPasswordState.Code);
                    setCodeSended(true);
                    return undefined;
                }
                else {
                    return t('Profile not found. Check spelling your e-mail address');
                }
            })
                .catch(function (e) {
                setError(t('Profile not found. Check spelling your e-mail address'));
            });
        }
        else if (state === ForgotPasswordState.Code) {
            return sendCode({
                variables: {
                    cardNumber: values['cardNumber'],
                    airlineCode: iataCode,
                    code: values['code']
                }
            })
                .then(function (data) {
                var _a;
                if ((_a = data.data) === null || _a === void 0 ? void 0 : _a.SendNewFfpPassword) {
                    setState(ForgotPasswordState.Success);
                    setTimeout(function () {
                        props.toLogin();
                    }, 2000);
                    return undefined;
                }
                else {
                    return t('You entered an incorrect code');
                }
            })
                .catch(function (e) {
                setError(t('You entered an incorrect code'));
            });
        }
        return undefined;
    };
    var initialValues = React.useMemo(function () {
        if (state === ForgotPasswordState.Email) {
            return {
                email: props.email
            };
        }
        else {
            return {
                code: '',
                cardNumber: ''
            };
        }
    }, [state]);
    var sendCodeRequest = function (email) {
        return resetPassword({
            variables: {
                email: email,
                airlineCode: iataCode
            }
        });
    };
    var theme = useTheme('Loyalty').ForgotPassword;
    var getHeader = function () {
        if (state === ForgotPasswordState.Email) {
            return t('Forgot password?');
        }
        else if (state === ForgotPasswordState.Code) {
            return t('Enter code');
        }
        else {
            return t('Password reset');
        }
    };
    var renderInner = function () {
        return (React.createElement("div", { className: theme.wrapper },
            React.createElement("div", { className: theme.header }, getHeader()),
            state === ForgotPasswordState.Success && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: theme.success },
                    successIcon,
                    t('A new password has been sent to your e-mail')),
                React.createElement("div", { className: theme.controls },
                    React.createElement(Link, { action: props.toLogin }, t('Back to login'))))),
            React.createElement(Form, { onSubmit: onSubmit, initialValues: initialValues }, function (_a) {
                var submitError = _a.submitError, submitting = _a.submitting, handleSubmit = _a.handleSubmit, valid = _a.valid;
                return (React.createElement("form", { onSubmit: handleSubmit }, state !== ForgotPasswordState.Success && (React.createElement("div", null,
                    (submitError || error) && (React.createElement("div", { className: theme.error },
                        errorIcon,
                        " ",
                        submitError || error)),
                    React.createElement("div", { className: theme.step },
                        React.createElement("span", null,
                            t('Step'),
                            " ",
                            state,
                            "/2",
                            state === ForgotPasswordState.Code && ': '),
                        state === ForgotPasswordState.Code &&
                            t('The verification code has been sent to the email')),
                    React.createElement(Fields, { state: state }),
                    React.createElement("div", { className: theme.controls },
                        React.createElement("div", { className: theme.controls__left },
                            React.createElement(Link, { action: props.toLogin }, t('Back')),
                            state === ForgotPasswordState.Code && (React.createElement(Resend, { codeSent: codeSent, codeSending: resetPasswordLoading, sendCode: function () {
                                    setCodeSended(false);
                                    sendCodeRequest(verifiedEmail).then(function (data) {
                                        setCodeSended(true);
                                        return data;
                                    });
                                }, className: theme.resend, labelClassName: theme.resend__label }))),
                        React.createElement(Button, { type: "submit", isLoading: submitting }, t('Continue')))))));
            })));
    };
    return renderInner();
};
export default ForgotPassword;
