import { useQuery } from '@apollo/react-hooks';
import { addDays, format, parse, startOfMonth } from 'date-fns';
import { API_DATE_FORMAT } from '../../utils';
import * as ScheduleQuery from '../../__queries__/Schedule.graphql';
var daysToAdd = 41;
export var useSchedule = function (searchParams, isOpen, selectedDate) {
    var _a;
    var startDateBeginOfMonth = selectedDate
        ? startOfMonth(selectedDate)
        : startOfMonth(parse(searchParams.segments[0].date, API_DATE_FORMAT, 0));
    var startDate = format(startDateBeginOfMonth, API_DATE_FORMAT);
    var endDate = format(addDays(startDateBeginOfMonth, daysToAdd), API_DATE_FORMAT);
    var _b = useQuery(ScheduleQuery.FlightsSchedule, {
        fetchPolicy: 'no-cache',
        skip: !isOpen,
        variables: {
            params: searchParams.segments.map(function (segment) { return ({
                departure: segment.departure.iata,
                arrival: segment.arrival.iata
            }); }),
            period: {
                startDate: startDate,
                endDate: endDate
            }
        }
    }), data = _b.data, error = _b.error, loading = _b.loading;
    return {
        schedule: data === null || data === void 0 ? void 0 : data.FullFlightsSchedule,
        segments: (_a = data === null || data === void 0 ? void 0 : data.FullFlightsSchedule) === null || _a === void 0 ? void 0 : _a.searchParameters.segments,
        error: error,
        loading: loading
    };
};
