import { createAction } from 'redux-actions';
export var ADD_SERVICE = 'ADD_SERVICE';
export var ADD_SERVICE_WITHOUT_REQUEST = 'ADD_SERVICE_WITHOUT_REQUEST';
export var REMOVE_SERVICE = 'REMOVE_SERVICE';
export var REMOVE_SERVICE_WITHOUT_REQUEST = 'REMOVE_SERVICE_WITHOUT_REQUEST';
export var SET_SERVICE = 'SET_SERVICE';
export var SET_ALL_SELECTED_SERVICES = 'SET_ALL_SELECTED_SERVICES';
export var DELETE_ALL_TYPES_ON_SEGMENT = 'DELETE_ALL_TYPES_ON_SEGMENT';
export var DELETE_ALL_TYPES_ON_SEGMENT_WITHOUT_REQUEST = 'DELETE_ALL_TYPES_ON_SEGMENT_WITHOUT_REQUEST';
export var CLEAR_ALL_SERVICES = 'CLEAR_ALL_SERVICES';
export var SET_MULTIPLE_SELECTED_SERVICES = 'SET_MULTIPLE_SELECTED_SERVICES';
export var SAVE_SELECTED_SERVICES = 'SAVE_SELECTED_SERVICES';
export var addService = createAction(ADD_SERVICE);
export var addServiceWithoutRequest = createAction(ADD_SERVICE_WITHOUT_REQUEST);
export var removeService = createAction(REMOVE_SERVICE);
export var removeServiceWithoutRequest = createAction(REMOVE_SERVICE_WITHOUT_REQUEST);
export var setService = createAction(SET_SERVICE);
export var setMultipleSelectedServices = createAction(SET_MULTIPLE_SELECTED_SERVICES);
export var setAllSelectedServices = createAction(SET_ALL_SELECTED_SERVICES);
export var deleteAllTypesOnSegment = createAction(DELETE_ALL_TYPES_ON_SEGMENT);
export var deleteAllTypesOnSegmentWithoutRequest = createAction(DELETE_ALL_TYPES_ON_SEGMENT_WITHOUT_REQUEST);
export var saveSelectedServices = createAction(SAVE_SELECTED_SERVICES);
