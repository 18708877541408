import * as React from 'react';
import { Form } from 'react-final-form';
import { Button } from '../../../../index';
import Field from '../Field/Field';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { validateLogin, validatePassword } from '../../utils';
var EmailForm = function (_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation('LoginPage').t;
    var css = useTheme('LeoLogin').LeoLogin;
    var onSubmitHandler = function (values) {
        console.log(values['password']);
        return onSubmit(values['email'], values['password']).then(function (result) {
            if (!result) {
                return { error: t('Cannot log in. Please, check login and password') };
            }
            return undefined;
        });
    };
    return (React.createElement(Form, { onSubmit: onSubmitHandler }, function (form) {
        var _a;
        return (React.createElement("form", { className: css.form, onSubmit: form.handleSubmit },
            React.createElement("div", null,
                React.createElement(Field, { name: "email", label: t('Login'), validate: validateLogin, error: (_a = form.submitErrors) === null || _a === void 0 ? void 0 : _a['error'] }),
                React.createElement(Field, { name: "password", password: true, label: t('Password'), validate: validatePassword })),
            React.createElement("div", { className: css.control },
                React.createElement(Button, { type: "submit", variant: "flat", isLoading: form.submitting }, t('Continue')))));
    }));
};
export default EmailForm;
