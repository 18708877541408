import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as theme from './AccountTemplate.css';
import Page from '../../Page/Page';
import { useAuthToken } from '../../LoginChangePassword/hooks';
import Sidebar from '../../../Sidebar';
import { routes } from '../../../Modules/Account/components/Account/Account';
var AccountTemplate = function (props) {
    var hitory = useHistory();
    var token = useAuthToken().token;
    var filteredRoutes = routes.filter(function (route) {
        return route.pathname === '/account/profile' ||
            route.pathname === '/account/logout' ||
            route.pathname === '/account/settings';
    });
    if (!token) {
        hitory.push('/login');
    }
    return (React.createElement(Page, { renderSearchForm: function () { return null; } },
        React.createElement("div", { className: theme.wrapper },
            React.createElement(Sidebar, { routes: filteredRoutes, selectedRoute: location.pathname }),
            React.createElement("div", { className: theme.content__wrp }, props.children))));
};
export default AccountTemplate;
