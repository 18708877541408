import { __assign, __rest } from "tslib";
import * as React from 'react';
import Input from '../../../../Input/Input';
import MaskedInput from 'react-text-mask';
export var DateMaskedInput = function (props) {
    var inputRef = props.inputRef, other = __rest(props, ["inputRef"]);
    return (React.createElement(MaskedInput, __assign({}, other, { placeholder: "__.__.____", ref: function (ref) { return inputRef(ref ? ref.inputElement : null); }, mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/] })));
};
export var DateField = function (props) {
    return (React.createElement(Input, { label: props.label, value: props.value, name: props.name, className: props.className, TextFieldProps: {
            onChange: props.onChange,
            onBlur: props.onBlur,
            error: !!props.error,
            helperText: props.error,
            disabled: props.disabled,
            type: 'tel'
        }, variant: props.variant, inputComponent: DateMaskedInput, absoluteHintPosition: props.absoluteHintPosition }));
};
