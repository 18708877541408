import React from 'react';
import { useTheme } from '../../../theme';
import DescriptionBlock from '../DescriptionBlock/DescriptionBlock';
import SwitchBlock from '../SwitchBlock/SwitchBlock';
import { initI18n } from '../../../utils';
import { useTranslation } from 'react-i18next';
import Money from '../../../Money';
initI18n('FareLock');
var fareLock = function (_a) {
    var onChange = _a.onChange, isActive = _a.isActive, price = _a.price, addedToOrder = _a.addedToOrder, confirmed = _a.confirmed, freezeUntil = _a.freezeUntil;
    var css = useTheme('FareLock').FareLock;
    var t = useTranslation('FareLock').t;
    var title = t('Fixation the cost of the air ticket');
    if (addedToOrder) {
        title = t('Check your selection and pay fare lock');
    }
    if (confirmed) {
        title = t('Confirmed fare lock');
    }
    return (React.createElement("div", { className: css.root },
        React.createElement(DescriptionBlock, { freezeUntil: freezeUntil, showConfirmedIcon: confirmed, className: css.description_block, title: function () { return (React.createElement(React.Fragment, null,
                title,
                confirmed && price && (React.createElement(React.Fragment, null,
                    ": ",
                    React.createElement(Money, { money: price }))))); }, description: addedToOrder
                ? t('If you make the decision to fly on the selected date within the next {{hours}} {{plural}}, you will be able to pay the full amount of the booking at the fare you locked in. Please, be advised that Fare lock fee is non-refundable.', {
                    hours: freezeUntil,
                    plural: t('hours', { count: freezeUntil })
                })
                : t('No need to worry about the price increase or about the fact that all tickets will be sold out, now you can fix the ticket price for {{hours}} {{plural}}', {
                    hours: freezeUntil,
                    plural: t('hours', { count: freezeUntil })
                }) }),
        !addedToOrder && (React.createElement(SwitchBlock, { price: price, isActive: isActive, onChange: onChange, className: css.switch_block }))));
};
export default fareLock;
