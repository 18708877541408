import { __assign } from "tslib";
import * as React from 'react';
import { useField } from 'react-final-form';
import Input from '../../../../../../Input';
import GenderSwitch from '../../../../../../TravellerForm/components/GenderSwitch/GenderSwitch';
import { DateField } from '../../../../../../Passenger/components/Field/Date/Date';
import { Select } from '../../../../../../Passenger/components/Field/Select/Select';
import { InputType } from '../../../../../../Passenger/types';
import { validate } from '../../../../../../Passenger/utils';
import { useTranslation } from 'react-i18next';
import PhoneInput from '../../../../../../PhoneInput';
var Field = function (props) {
    var t = useTranslation('Passenger').t;
    var field = useField(props.name, {
        type: 'text',
        validate: function (value, allValues) {
            return t(validate(value, allValues, props.type, props.validationRules));
        }
    });
    var isError = field.meta.error && field.meta.touched;
    var error = field.meta.error && field.meta.touched && field.meta.error;
    if (props.type === InputType.Phone) {
        return (React.createElement(PhoneInput, { textFieldsProps: {
                onChange: field.input.onChange,
                name: field.input.name,
                error: isError,
                helperText: error,
                label: t(props.label)
            } }));
    }
    if (props.type === InputType.Switch) {
        return React.createElement(GenderSwitch, { onChange: field.input.onChange, error: error });
    }
    if (props.type === InputType.Date) {
        return (React.createElement(DateField, { label: t(props.label), value: props.value, onChange: field.input.onChange, error: error, absoluteHintPosition: true }));
    }
    if (props.type === InputType.Select) {
        return (React.createElement(Select, { options: props.options, name: props.name, label: t(props.label), onChange: function (key, e) {
                field.input.onChange(e);
            }, onBlur: field.input.onBlur, value: field.input.value, error: error, translateValue: props.translateValue }));
    }
    return (React.createElement(Input, { label: t(props.label), value: props.value, name: field.input.name, TextFieldProps: __assign(__assign({}, field.input), { error: isError, helperText: error }), absoluteHintPosition: true }));
};
export default Field;
