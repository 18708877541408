import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useScheduleModal } from './hooks';
import ModalHeader from '../ModalHeader/ModalHeader';
import { useScheduleTypeSelector } from '../ScheduleTypeSelector/hooks';
import FullScreenModal from '../../../FullScreenModal/FullScreenModal';
import { useTranslation } from 'react-i18next';
var ScheduleModal = function (props) {
    var renderSchedule = props.renderSchedule, currentLeg = props.currentLeg, setCurrentLeg = props.setCurrentLeg, segments = props.segments, closeButtonRenderer = props.closeButtonRenderer, modalProps = __rest(props, ["renderSchedule", "currentLeg", "setCurrentLeg", "segments", "closeButtonRenderer"]);
    var _a = useScheduleModal(), type = _a.type, setType = _a.setType;
    var renderScheduleTypeSelector = useScheduleTypeSelector(type, setType).renderScheduleTypeSelector;
    var t = useTranslation('Schedule').t;
    return (React.createElement(FullScreenModal, __assign({ title: t('Schedule'), headerType: 'collapsed' }, modalProps),
        React.createElement(ModalHeader, { segments: segments, onSegmentChange: function (currentLeg) { return setCurrentLeg(parseInt(currentLeg, 10)); }, renderScheduleTypeSelector: renderScheduleTypeSelector }, function () { return React.createElement(React.Fragment, null, renderSchedule(type)); })));
};
export default ScheduleModal;
