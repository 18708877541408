/* tslint:disable */
import { __assign, __read, __spreadArray } from "tslib";
import { createSelector } from 'reselect';
import { AviaPassengerType, OrderStatus, PaymentStatusEnum } from '../../../__generated__/globalTypes';
import { convertBaggageObject, convertMealObject } from '../../types';
import { get, MEAL_RECOMMENDATION_SHOWN } from '../../../cache';
import { Measurement } from '../../../BaggageService/types';
import { getGdsServiceMinPrice } from '../../utils';
import { isOdd } from '../../../utils';
import { getHasSelectedSeats, getPassengerStateCompatible, getSelectedServices, groupAllSelectedServices } from '../selectedServices/selectors';
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '../../../__generated__/graphql';
import { BaggageType } from '@websky/graphql';
import { useConfig } from '../../../context';
import { parseIncludedBaggage } from '../../../BaggagePage/utils';
var getState = function (state) { return state; };
export var getOrder = createSelector(getState, function (state) { return state.order; });
export var getAdditionalServices = createSelector(getOrder, function (order) { var _a; return (_a = order === null || order === void 0 ? void 0 : order.additionalServices) !== null && _a !== void 0 ? _a : null; });
export var getShowServicesBeforeBooking = createSelector(getOrder, function (order) { var _a; return (_a = order.showServicesBeforeBooking) !== null && _a !== void 0 ? _a : true; });
export var getServices = createSelector(getOrder, function (order) { var _a, _b; return (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) !== null && _b !== void 0 ? _b : []; });
export var getRecheckAvailabilityTimout = createSelector(getOrder, function (order) { return order.timeoutToReCheckAvailability; });
export var getActualizationResult = createSelector(getOrder, function (order) { return order.actualizationResult; });
export var getTravellers = createSelector(getOrder, function (order) { var _a; return (_a = order === null || order === void 0 ? void 0 : order.travellers) !== null && _a !== void 0 ? _a : null; });
export var getBonusForFlight = createSelector(getOrder, function (order) { var _a; return (_a = order.ffpInfo) === null || _a === void 0 ? void 0 : _a.bonusForFlight; });
export var getPriceInMiles = createSelector(getOrder, function (order) { var _a; return (_a = order.price) === null || _a === void 0 ? void 0 : _a.totalInMiles; });
export var getExchangeAvailability = createSelector(getOrder, function (order) { var _a; return !!((_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.exchangeAvailability); });
export var getSplitAvailability = createSelector(getOrder, function (order) { return order.canSplit; });
export var getAvailableServices = createSelector(getServices, function (services) {
    var availableGdsServices = {};
    services.forEach(function (service) {
        if (!availableGdsServices.hasOwnProperty(service.type)) {
            availableGdsServices[service.type] = service.type;
        }
    });
    return availableGdsServices;
});
export var getSegmentGroups = createSelector(getOrder, function (order) { var _a; return (_a = order.flight) === null || _a === void 0 ? void 0 : _a.segmentGroups; });
export var getFareFamiliesMap = createSelector(getSegmentGroups, function (segmentGroups) {
    var fareFamiliesMap = new Map();
    segmentGroups.forEach(function (segmentGroup) {
        var fareFamily = segmentGroup.fareFamily;
        if (!fareFamily) {
            return;
        }
        if (!fareFamiliesMap.has(fareFamily.id)) {
            fareFamiliesMap.set(fareFamily.id, {
                fareFamily: fareFamily,
                segmentIds: []
            });
        }
        segmentGroup.segments.forEach(function (segment) { return fareFamiliesMap.get(fareFamily.id).segmentIds.push(segment.id); });
    });
    return fareFamiliesMap;
});
export var isOrderBlockedForPayment = createSelector(getOrder, function (order) { return order.isBlockedForPayment; });
export var getOldOrderPrice = createSelector(getOrder, function (order) { var _a; return (_a = order === null || order === void 0 ? void 0 : order.actualizationResult) === null || _a === void 0 ? void 0 : _a.oldPrice; });
export var getNewOrderPrice = createSelector(getOrder, function (order) { var _a; return (_a = order === null || order === void 0 ? void 0 : order.actualizationResult) === null || _a === void 0 ? void 0 : _a.newPrice; });
export var getFareLockService = createSelector(getServices, function (services) {
    return services.find(function (service) { return service.isPriceFreeze; });
});
export var getIsFareLockSelected = createSelector(getTravellers, getFareLockService, function (travellers, fareLockService) {
    return travellers === null || travellers === void 0 ? void 0 : travellers.some(function (traveller) { var _a, _b, _c; return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) { return (service === null || service === void 0 ? void 0 : service.serviceId) === (fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.id); }); });
});
export var getIsFareLockConfirmed = createSelector(getTravellers, getFareLockService, function (travellers, fareLockService) {
    return travellers === null || travellers === void 0 ? void 0 : travellers.some(function (traveller) {
        var _a, _b, _c;
        return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) { return (service === null || service === void 0 ? void 0 : service.serviceId) === (fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.id) && (service === null || service === void 0 ? void 0 : service.confirmedCount); });
    });
});
export var getIsPromoCodeAppliedToFlight = createSelector(getOldOrderPrice, getNewOrderPrice, function (oldPrice, newPrice) { return (newPrice === null || newPrice === void 0 ? void 0 : newPrice.amount) < (oldPrice === null || oldPrice === void 0 ? void 0 : oldPrice.amount); });
var _getIncludedBaggage = createSelector(getFareFamiliesMap, getTravellers, useConfig, function (fareFamiliesMap, travellers, config) {
    var _a, _b;
    if (['N4', 'DV', 'YC'].includes((_b = (_a = config === null || config === void 0 ? void 0 : config.global) === null || _a === void 0 ? void 0 : _a.companyInfo) === null || _b === void 0 ? void 0 : _b.iataCode)) {
        // filter out all included baggage for INF and INS
        var travellersWithoutInfants = travellers.filter(function (traveller) { return traveller.type !== AviaPassengerType.INF && traveller.type !== AviaPassengerType.INS; });
        return fareFamiliesMap
            ? parseIncludedBaggage(fareFamiliesMap, travellersWithoutInfants.map(function (_a) {
                var id = _a.id;
                return id;
            }))
            : [];
    }
    return fareFamiliesMap
        ? parseIncludedBaggage(fareFamiliesMap, travellers.map(function (_a) {
            var id = _a.id;
            return id;
        }))
        : [];
});
export var getBrandIncludedBaggage = createSelector(getTravellers, function (travellers) {
    var includedBaggages = [];
    travellers.forEach(function (traveller) {
        var _a, _b;
        return (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.brandIncludedServices) === null || _b === void 0 ? void 0 : _b.services.filter(function (services) { return services.service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage; }).forEach(function (service) {
            var baggageService = service.service;
            var baggage = {
                id: service.serviceId,
                segmentsId: baggageService.allowedSegments,
                includedSegments: service.segmentIds,
                type: baggageService.baggageType,
                name: baggageService.name,
                description: baggageService.description,
                value: {
                    amount: parseInt(baggageService.baggageWeight.split('x')[0]),
                    measurement: Measurement.Kilograms
                },
                size: baggageService.size,
                included: service.confirmedCount,
                price: baggageService.price,
                rfics: baggageService.rfisc,
                canBeAdded: false,
                allowedPassengers: [traveller.id]
            };
            includedBaggages.push(__assign(__assign({}, service), { baggage: baggage }));
        });
    });
    return includedBaggages;
});
export var getAnimalServices = createSelector(getServices, function (services) {
    var availableBaggageTypes = [BaggageType.PetInCabin, BaggageType.PetInHold, BaggageType.PetInHandCarrier];
    var servicesMap = new Map();
    services.forEach(function (service) {
        if (availableBaggageTypes.includes(service === null || service === void 0 ? void 0 : service.baggageType)) {
            servicesMap.set(service.rfisc, service);
        }
    });
    return __spreadArray([], __read(servicesMap.values()), false);
});
export var hasSeatsServices = createSelector(getServices, function (services) {
    return services.some(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Seat; });
});
export var hasBaggageServices = createSelector(getServices, function (services) {
    return services.some(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage; });
});
export var getSeatMap = createSelector(getOrder, function (order) { return order.flight.seatMap; });
export var getOrderId = createSelector(getOrder, function (order) { return order.id; });
export var getIsOddOrderId = createSelector(getOrderId, isOdd);
export var isNew = createSelector(getOrder, function (order) { return order.status === OrderStatus.New; });
export var getIsNotNew = createSelector(getOrder, function (order) { return order.status !== OrderStatus.New; });
export var getIsTravellersDataFilled = createSelector(getOrder, function (order) { return order.isTravellersDataFilled; });
export var isReadonly = createSelector(getIsTravellersDataFilled, function (isTravellersDataFilled) { return isTravellersDataFilled; });
export var isRedirectFromMetasearch = createSelector(getOrder, function (order) { return order.redirectFromMetasearch; });
export var getOrderStatus = createSelector(getOrder, function (order) { return order.status; });
export var getHasAvailableSeats = createSelector(getSeatMap, function (seatMap) { var _a; return (_a = seatMap === null || seatMap === void 0 ? void 0 : seatMap.segments) === null || _a === void 0 ? void 0 : _a.some(function (_a) {
    var availableSeatsCount = _a.availableSeatsCount;
    return availableSeatsCount > 0;
}); });
export var isAllSegmentsAvailableOnlyOnCheckin = createSelector(getSeatMap, function (seatMap) {
    return seatMap.segments.every(function (segment) { return segment.isSeatsAvailableInCheckinOnly === true; });
});
export var isBookedOrConfirmed = createSelector(getOrder, function (order) { return order.status === OrderStatus.Booked || order.status === OrderStatus.Confirmed; });
export var getTotalInsurancePrice = createSelector(getOrder, function (state) { return state.price.insurance; });
export var isBooked = createSelector(getOrder, function (order) { return order.status === OrderStatus.Booked; });
export var isConfirmed = createSelector(getOrder, function (order) { return order.status === OrderStatus.Confirmed; });
export var getIsNew = createSelector(getOrder, function (order) { return order.status === OrderStatus.New; });
export var isConfirmedOrCancelled = createSelector(getOrder, function (order) { return order.status === OrderStatus.Confirmed || order.status === OrderStatus.Cancelled; });
export var getIsPriceFreezeProductSelected = createSelector(getOrder, function (order) { return order.isPriceFreezeProductSelected; });
export var isCancelled = createSelector(getOrder, function (order) { return order.status === OrderStatus.Cancelled; });
export var isFFPUsed = createSelector(getOrder, function (order) { return !!order.ffpInfo; });
export var getMilesForOrder = createSelector(getOrder, function (order) { var _a; return (_a = order.ffpInfo) === null || _a === void 0 ? void 0 : _a.bonusForFlight; });
export var getAvailableGdsServicesByType = createSelector(getOrder, getSeatMap, getHasSelectedSeats, function (order, seatMap, hasSelectedSeats) {
    var _a;
    var _b, _c, _d, _e;
    var availableServices = {};
    (_d = (_c = (_b = order.additionalServices) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.forEach(function (service) {
        // TODO: check that service.canBeAdded = true when php fix it
        if (!availableServices.hasOwnProperty(service.type)) {
            availableServices[service.type] = true;
        }
    });
    return __assign(__assign({}, availableServices), (_a = {},
        _a[OrderAdditionalServiceGdsServiceServiceType.Seat] = ((_e = seatMap === null || seatMap === void 0 ? void 0 : seatMap.segments) === null || _e === void 0 ? void 0 : _e.some(function (segment) { return segment.availableSeatsCount > 0 || segment.isSeatsAvailableInCheckinOnly; })) || hasSelectedSeats,
        _a));
});
export var getPaymentIsRequired = createSelector(getOrder, function (order) { return order.paymentStatus !== PaymentStatusEnum.Full; });
export var getCustomer = createSelector(getOrder, function (order) { return order === null || order === void 0 ? void 0 : order.customer; });
export var getIsContactsInfoIsEmpty = createSelector(getCustomer, function (customer) { return customer.contactInfoIsEmpty; });
export var isRoundTrip = createSelector(getOrder, function (order) {
    var segmentGroups = order.flight.segmentGroups;
    if (segmentGroups.length !== 2) {
        return false;
    }
    var outbound = segmentGroups[0];
    var outboundDeparture = outbound.segments[0].departure.airport.iata;
    var outboundArrival = outbound.segments[outbound.segments.length - 1].arrival.airport.iata;
    var inbound = segmentGroups[1];
    var inboundDeparture = inbound.segments[0].departure.airport.iata;
    var inboundArrival = inbound.segments[inbound.segments.length - 1].arrival.airport.iata;
    return outboundArrival === inboundDeparture && outboundDeparture === inboundArrival;
});
export var getSegmentsForServices = createSelector(getOrder, function (order) {
    return order.flight.segments.map(function (segment) {
        var _a, _b;
        return ({
            segmentId: segment.segment.id,
            arrival: {
                name: segment.segment.arrival.airport.city.name,
                iata: segment.segment.arrival.airport.iata
            },
            departure: {
                name: segment.segment.departure.airport.city.name,
                iata: segment.segment.departure.airport.iata
            },
            amenities: (_b = (_a = segment.segment) === null || _a === void 0 ? void 0 : _a.flightInfo) === null || _b === void 0 ? void 0 : _b.amenities
        });
    });
});
export var getSegments = createSelector(getOrder, function (order) {
    return order.flight.segments.map(function (segment) { return segment.segment; });
});
export var getBaggageMinPrice = createSelector(getOrder, function (order) {
    return getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Baggage);
});
export var getUpgradeBaggageMinPrice = createSelector(getOrder, function (order) {
    return getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Baggage, BaggageType.HandLuggageExcess);
});
export var getSportsEquipmentMinPrice = createSelector(getOrder, function (order) {
    return getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Baggage, BaggageType.SportingEquipment);
});
export var getOverweightMinPrice = createSelector(getOrder, function (order) {
    return getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Baggage, BaggageType.BaggageExcess);
});
export var getSeatsMinPrice = createSelector(getOrder, function (order) {
    return getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Seat);
});
export var getMealMinPrice = createSelector(getOrder, function (order) {
    return getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Meal);
});
export var getCurrencyFromOrder = createSelector(getOrder, function (order) { var _a; return (_a = order.price.total) === null || _a === void 0 ? void 0 : _a.currency; });
// #todo: add tests
export var getSearchParameters = createSelector(getOrder, getCurrencyFromOrder, function (_a, currency) {
    var flight = _a.flight, travellers = _a.travellers;
    var passengersMap = {};
    var passengers = [];
    travellers.forEach(function (_a) {
        var type = _a.type;
        if (passengersMap.hasOwnProperty(type)) {
            passengersMap[type]++;
        }
        else {
            passengersMap[type] = 1;
        }
    });
    for (var passengerType in passengersMap) {
        if (passengersMap.hasOwnProperty(passengerType)) {
            passengers.push({
                passengerType: passengerType,
                extendedPassengerType: null,
                count: passengersMap[passengerType]
            });
        }
    }
    return {
        currency: currency,
        promotionCode: null,
        passengers: passengers,
        segments: flight.segmentGroups.map(function (group) {
            var firstSegment = group.segments[0];
            var lastSegment = group.segments[group.segments.length - 1];
            return {
                date: firstSegment.departure.date,
                departure: {
                    name: firstSegment.departure.airport.name,
                    iata: firstSegment.departure.airport.iata,
                    city: {
                        name: firstSegment.departure.airport.city.name
                    }
                },
                arrival: {
                    name: lastSegment.arrival.airport.name,
                    iata: lastSegment.arrival.airport.iata,
                    city: {
                        name: lastSegment.arrival.airport.city.name
                    }
                }
            };
        }),
        ffpMode: false
    };
});
// check that canAddServices is boolean ( because in CheckinOrder there is no such props and shouldRedirectToFallback always returns true )
export var shouldRedirectToFallback = createSelector(getOrder, function (_a) {
    var canAddServices = _a.canAddServices, canRemoveServices = _a.canRemoveServices;
    return typeof canAddServices === 'boolean' &&
        typeof canRemoveServices === 'boolean' &&
        (!canAddServices || !canRemoveServices);
});
export var getServicesFallbackURL = createSelector(getOrder, function (_a) {
    var servicesFallbackURL = _a.servicesFallbackURL;
    return servicesFallbackURL;
});
export var getAeroexpress = createSelector(getOrder, function (order) { var _a, _b; return (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.transfer) === null || _b === void 0 ? void 0 : _b.aeroexpress; });
export var getInsurances = createSelector(getOrder, function (order) { var _a; return (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances; });
export var getMealServices = createSelector(getServices, function (services) {
    return services.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Meal; });
});
export var getBaggageServices = createSelector(getServices, function (services) {
    return services.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage; });
});
export var getMiscServices = createSelector(getServices, function (services) {
    return services.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Misc; });
});
export var getMeals = createSelector(getMealServices, function (meals) { return meals.map(convertMealObject); });
export var getBaggage = createSelector(getBaggageServices, function (baggage) { return baggage.map(convertBaggageObject); });
export var getBusinessLounge = createSelector(getServices, function (services) {
    return services.filter(function (service) { return service.subType === GdsServiceVipSubType.BusinessLounge; });
});
export var getCapsuleHotel = createSelector(getServices, function (services) {
    return services.filter(function (service) { return service.subType === GdsServiceVipSubType.CapsuleHotel; });
});
export var getPriorityBoarding = createSelector(getServices, function (services) {
    return services.filter(function (service) {
        return service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
            service.subType ===
                GdsServiceVipSubType.PriorityBoarding;
    });
});
export var getAdditionalBaggage = createSelector(getBaggage, function (items) {
    return items.filter(function (item) { return item.type === BaggageType.CheckedBaggage; });
});
var getFakeBaggage = createSelector(getBaggageServices, function (services) {
    return services.filter(function (service) { return service.isFake; }).map(convertBaggageObject);
});
export var getIncludedBaggage = createSelector(_getIncludedBaggage, getFakeBaggage, function (included, fake) {
    return included.concat(fake);
});
export var getUpgradeBaggage = createSelector(getBaggage, getTravellers, function (baggageItems, travellers) {
    var travellersByIdMap = new Map(travellers.map(function (traveller) {
        var _a, _b;
        if (!((_b = (_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.length)) {
            return [traveller.id, null];
        }
        var travellerGdsServicesById = new Map(traveller.services.gdsServices.services.map(function (service) { return [
            service.serviceId,
            service.count > service.confirmedCount
        ]; }));
        return [traveller.id, travellerGdsServicesById];
    }));
    var isCanBeAddedOrRemoved = function (item) {
        var canBeRemoved = item.allowedPassengers.some(function (passengerId) { var _a; return (_a = travellersByIdMap.get(passengerId)) === null || _a === void 0 ? void 0 : _a.get(item.id); });
        return item.canBeAdded || canBeRemoved;
    };
    return baggageItems.filter(function (item) {
        return item.type === BaggageType.HandLuggageExcess && isCanBeAddedOrRemoved(item);
    });
});
export var getExtraBaggage = createSelector(getBaggage, function (items) {
    return items.filter(function (item) { return !item.type; });
});
export var getSpecialEquipment = createSelector(getBaggage, function (items) {
    return items.filter(function (item) {
        return [
            BaggageType.SportingEquipment,
            BaggageType.PetInCabin,
            BaggageType.PetInHold,
            BaggageType.MusicalInstrument
        ].includes(item.type);
    });
});
export var getOverweightBaggage = createSelector(getBaggage, function (items) {
    return items.filter(function (item) { return item.type === BaggageType.BaggageExcess; });
});
export var getRecommendedMeals = createSelector(getMeals, getTravellers, function (meals, travellers) {
    var result = [];
    travellers.forEach(function (traveller) {
        traveller.preselectedServices.gdsServices.services.forEach(function (service) {
            var meal = meals.find(function (meal) { return meal.id === service.serviceId; });
            if (meal) {
                result.push({
                    meal: meal,
                    travellerId: traveller.id,
                    segmentIds: service.segmentIds,
                    count: service.count
                });
            }
        });
    });
    return result;
});
export var getHasAnySelectedSeats = createSelector(getPassengerStateCompatible, function (passengers) {
    return passengers.some(function (_a) {
        var seats = _a.seats;
        return (seats === null || seats === void 0 ? void 0 : seats.length) > 0;
    });
});
export var getHasAnySelectedMeals = createSelector(getPassengerStateCompatible, getRecommendedMeals, function (passengers, recommendedMeals) {
    return passengers.some(function (_a) {
        var services = _a.services;
        return services === null || services === void 0 ? void 0 : services.some(function (service) { return recommendedMeals.some(function (_a) {
            var meal = _a.meal;
            return meal.id === service.serviceId;
        }); });
    });
});
export var getSkipRecommendations = createSelector(isRedirectFromMetasearch, getIsOddOrderId, function (isFromMeta, orderIdIsOdd) { return isFromMeta && orderIdIsOdd; });
export var getIsTransferSelected = createSelector(getOrder, function (order) { var _a, _b, _c, _d; return ((_d = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.transfer) === null || _b === void 0 ? void 0 : _b.aeroexpress) === null || _c === void 0 ? void 0 : _c.selectedTrips) === null || _d === void 0 ? void 0 : _d.length) > 0; });
export var getIsInsuranceSelected = createSelector(getOrder, function (order) { var _a, _b, _c; return ((_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances) === null || _b === void 0 ? void 0 : _b.selectedInsurances) === null || _c === void 0 ? void 0 : _c.length) > 0; });
export var getShowSeatRecommendations = createSelector(getSkipRecommendations, getOrderId, getTravellers, getHasAnySelectedSeats, function (skipRecommendations, orderId, travellers, hasAnySelectedSeats) {
    return !skipRecommendations &&
        // Check if we have something to recommend.
        travellers.some(function (traveller) { return traveller.preselectedServices.seats.length; });
});
export var getShowMealRecommendations = createSelector(getSkipRecommendations, getOrderId, getRecommendedMeals, getHasAnySelectedMeals, function (skipRecommendations, orderId, recommendedMeals, hasAnySelectedMeals) {
    return !skipRecommendations &&
        // Check if user hasn't selected any meals.
        !hasAnySelectedMeals &&
        // Check if we have something to recommend.
        !!recommendedMeals.length &&
        // Check if user hasn't declined our recommendations yet.
        !get("".concat(MEAL_RECOMMENDATION_SHOWN, "_").concat(orderId));
});
export var getSelectedMiscServices = createSelector([getSelectedServices, getOrder], function (services, order) {
    var miscServicesIds = order.additionalServices.gdsServices.services
        .filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Misc; })
        .map(function (service) { return service.id; });
    return services.map(function (service) {
        var _a;
        if (!((_a = service === null || service === void 0 ? void 0 : service.gdsServices) === null || _a === void 0 ? void 0 : _a.setServices)) {
            return service;
        }
        return __assign(__assign({}, service), { gdsServices: __assign(__assign({}, service.gdsServices), { setServices: service.gdsServices.setServices.filter(function (service) {
                    return miscServicesIds.includes(service.serviceId);
                }) }) });
    });
});
export var getGroupedAllSelectedServices = createSelector([getAdditionalServices, getTravellers, getSegments], groupAllSelectedServices);
