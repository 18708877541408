import { __read, __spreadArray } from "tslib";
import { ADD_FLIGHT_IDS, REMOVE_FLIGHT_IDS } from './actions';
export var ALL_FLIGHT = 'ALL_FLIGHT';
var initialState = [];
export var flightsToExchangeReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action.type === ADD_FLIGHT_IDS) {
        return __spreadArray([], __read(action.payload), false);
    }
    if (action.type === REMOVE_FLIGHT_IDS) {
        return state.filter(function (_a) {
            var key = _a.key;
            return !action.payload.find(function (flight) { return flight.key === key; });
        });
    }
    return state;
};
