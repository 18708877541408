import * as React from 'react';
import { useCallback, useEffect } from 'react';
import Gateway from '../Gateway';
import { PaymentType } from '../../../types';
import { useTranslation } from 'react-i18next';
import * as css from './Kaspi.css';
var CopyIcon = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 448 512" },
        React.createElement("path", { fill: "currentColor", d: "M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z" })));
};
var Kaspi = function (_a) {
    var frameInfo = _a.frameInfo, headerTitle = _a.headerTitle, onClick = _a.onClick, children = _a.children, isPreOpened = _a.isPreOpened;
    var t = useTranslation('PaymentForm').t;
    var paymentCode = frameInfo === null || frameInfo === void 0 ? void 0 : frameInfo.paymentCode;
    var copyPaymentCode = useCallback(function () {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(paymentCode);
    }, [paymentCode]);
    var onClickGateway = function () {
        onClick({
            title: headerTitle,
            type: PaymentType.Kaspi,
            content: (React.createElement("div", { className: css.root }, paymentCode ? (React.createElement(React.Fragment, null,
                t('Payment code'),
                ":",
                React.createElement("span", { className: css.copy, onClick: copyPaymentCode },
                    paymentCode,
                    React.createElement(CopyIcon, null)))) : (t('Failed to get billing code'))))
        });
    };
    useEffect(function () {
        if (isPreOpened) {
            onClickGateway();
        }
    }, [isPreOpened]);
    return React.createElement(Gateway, { onClick: onClickGateway, header: children, type: 'Kaspi' });
};
export default Kaspi;
