import { __assign } from "tslib";
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme } from '../../../../theme';
import { Field } from 'react-final-form';
import cn from 'classnames';
export var AllItemsCheckbox = function (_a) {
    var name = _a.name, label = _a.label, isDeletingMode = _a.isDeletingMode;
    var theme = useTheme('Ssr').SsrModal;
    return (React.createElement(React.Fragment, null,
        React.createElement(Field, { name: name, type: "checkbox" }, function (_a) {
            var input = _a.input;
            return (React.createElement(React.Fragment, null,
                React.createElement(FormControlLabel, { className: cn(theme.checkboxLabel, theme.checkboxLabel_bold), control: React.createElement(Checkbox, { disabled: isDeletingMode, color: "primary", classes: input.checked ? { checked: theme.checkboxLabel_checked } : null, checked: input.checked, inputProps: __assign({}, input) }), label: label })));
        })));
};
