import { __assign } from "tslib";
import * as React from 'react';
import Page from '../Page/Page';
import SearchData from '../../Header/components/SearchData/SearchData';
import Routes from '../../Header/components/Routes/Routes';
import Results from '../../Results/';
import { useHeader } from '../../Header/hooks';
var LeoResults = function (props) {
    var _a = useHeader(), isOpen = _a.isOpen, toggle = _a.toggle;
    return (React.createElement(Page, { renderRoutes: function () {
            var _a;
            return ((_a = props.searchResults) === null || _a === void 0 ? void 0 : _a.searchParameters) ? (React.createElement("div", { onClick: toggle },
                React.createElement(SearchData, { searchParameters: props.searchResults.searchParameters }))) : (React.createElement(Routes, null));
        }, onSubmit: props.startNewSearch, isOpen: isOpen }, props.searchResults && React.createElement(Results, __assign({}, props))));
};
export default LeoResults;
