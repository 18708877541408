import { __read } from "tslib";
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import EmailForm from './components/EmailForm/EmailForm';
import CodeForm from './components/CodeForm/CodeForm';
import { useTheme } from '../../theme';
import { useTranslation } from 'react-i18next';
import NewPasswordForm from './components/NewPasswordForm/NewPasswordForm';
import { KeyIcon, LeonardoIcon } from './icons';
var State;
(function (State) {
    State[State["Password"] = 0] = "Password";
    State[State["Code"] = 1] = "Code";
})(State || (State = {}));
var LoginChangePassword = function (_a) {
    var onCodeSubmit = _a.onCodeSubmit, onEmailSubmit = _a.onEmailSubmit, mode = _a.mode, onPasswordSubmit = _a.onPasswordSubmit;
    var _b = __read(React.useState(State.Password), 2), state = _b[0], setState = _b[1];
    var _c = __read(React.useState(''), 2), login = _c[0], setLogin = _c[1];
    var _d = __read(React.useState(''), 2), password = _d[0], setPassword = _d[1];
    var _e = __read(React.useState(false), 2), isCodeSending = _e[0], setCodeSending = _e[1];
    var _f = __read(React.useState(false), 2), isCodeSent = _f[0], setCodeSent = _f[1];
    var t = useTranslation('LoginPage').t;
    var css = useTheme('LeoLogin').LeoLogin;
    var onEmailSubmitHandler = function (email, password) {
        setCodeSending(true);
        return onEmailSubmit(email, password).then(function (data) {
            setCodeSending(false);
            setCodeSent(true);
            if (data) {
                setLogin(email);
                setPassword(password);
                setState(State.Code);
                return true;
            }
            return false;
        });
    };
    var onPasswordSubmitHandler = function (password) {
        setCodeSent(true);
        onPasswordSubmit(password);
        setState(State.Code);
    };
    var onCodeSubmitHandler = function (code) {
        return onCodeSubmit(login, code);
    };
    var onResendCode = function () {
        setCodeSending(true);
        setCodeSent(false);
        onEmailSubmit(login, password).then(function () {
            setCodeSending(false);
            setCodeSent(true);
        });
    };
    var goToPassword = function () {
        setState(State.Password);
    };
    return (React.createElement("div", { className: css.container },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.header__icon }, mode === 'passwordChange' ? KeyIcon : LeonardoIcon),
            React.createElement("div", { className: css.header__login }, mode === 'passwordChange' ? t('Change password') : t('Sign in')),
            React.createElement("div", { className: css.header__step }, t('Step {{step}}/{{total}}', { step: state + 1, total: 2 }))),
        React.createElement(SwipeableViews, { className: css.swipeableContainer, index: state, slideStyle: { overflow: 'hidden' }, slideClassName: css.swipeableContainer__slide },
            mode === 'passwordChange' ? (React.createElement(NewPasswordForm, { onSubmit: onPasswordSubmitHandler })) : (React.createElement(EmailForm, { onSubmit: onEmailSubmitHandler })),
            React.createElement(CodeForm, { onCodeSubmit: onCodeSubmitHandler, resendCode: onResendCode, codeSent: isCodeSent, codeSending: isCodeSending, onGoBack: mode === 'login' ? goToPassword : null }))));
};
export default LoginChangePassword;
