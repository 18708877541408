import { __assign, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import cn from 'classnames';
import CloseIcon from '../../CloseIcon/components/CloseIcon';
import Input from '../../Input';
import { syntaxValidationTO, transportationOptions } from './utils/transportationOptions';
import { useGraphqlData } from './hooks/useGraphqlData';
import { useTheme } from '../../theme';
import { ConsoleType } from './types';
import { passengersData, syntaxValidationPD } from './utils/passengersData';
import { useEventListener } from '../../hooks';
var useStyles = makeStyles(function () { return ({
    dialog: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        maxWidth: 'unset',
        borderRadius: 0,
        margin: 0
    },
    root: {
        width: '100%',
        margin: '10px 0',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: 0
            }
        }
    }
}); });
export var Console = function (_a) {
    var _b;
    var type = _a.type, isOpen = _a.isOpen, onClose = _a.onClose, onOpen = _a.onOpen, _c = _a.placeholder, placeholder = _c === void 0 ? 'Type search command using Sirena or Amadeus sintax. For example, FXDMOW/D28MARKHV or JWBCNPAR2212' : _c, onStartSearch = _a.onStartSearch, commandStr = _a.commandStr, setcommandStr = _a.setcommandStr;
    var _d = __read(useState(''), 2), parsingError = _d[0], setparsingError = _d[1];
    var _e = __read(useState(false), 2), isMultiline = _e[0], setisMultiline = _e[1];
    var _f = __read(useState(0), 2), selectionStart = _f[0], setselectionStart = _f[1];
    var theme = useTheme('Console').Console;
    var classes = useStyles();
    var _g = useGraphqlData(), citiesIata = _g.citiesIata, passengerTypeCodes = _g.passengerTypeCodes;
    var onChangeHandler = function (event) {
        setcommandStr(event.target.value.trim().toUpperCase());
    };
    var onOpenHandler = function (event) {
        var keyboardEvent = event;
        if (keyboardEvent.altKey && keyboardEvent.code === 'KeyZ' && keyboardEvent.ctrlKey) {
            onOpen();
        }
        keyboardEvent.stopPropagation();
    };
    useEventListener('keydown', onOpenHandler);
    var onKeyDownHendler = function (event) {
        var _a;
        setselectionStart(0);
        if (event.key === 'Enter' && event.ctrlKey) {
            if (!isMultiline) {
                setisMultiline(true);
                event.stopPropagation();
            }
            var currentSelectionStart = event.target.selectionStart;
            setselectionStart(currentSelectionStart + 1);
            setcommandStr("".concat(commandStr.slice(0, currentSelectionStart), "\n").concat(commandStr.slice(currentSelectionStart, commandStr.length)));
            return;
        }
        if (event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            var commandResultHandlers = (_a = {},
                _a[ConsoleType.SEARCH] = {
                    commandResultHandler: transportationOptions,
                    syntaxValidation: syntaxValidationTO
                },
                _a[ConsoleType.PASSENGERS] = {
                    commandResultHandler: passengersData,
                    syntaxValidation: syntaxValidationPD
                },
                _a);
            var consoleHandlers = commandResultHandlers[type];
            var syntaxValidation = consoleHandlers.syntaxValidation;
            var error = syntaxValidation({ str: commandStr, citiesIata: citiesIata, passengerTypeCodes: passengerTypeCodes });
            if (error) {
                setparsingError(error);
            }
            else {
                setparsingError('');
                var commandResultHandler = consoleHandlers.commandResultHandler;
                onStartSearch(commandResultHandler(commandStr));
            }
        }
    };
    var textAreaCaretHandler = function (target) {
        if (target) {
            target.focus();
            if (!!selectionStart) {
                target.setSelectionRange(selectionStart, selectionStart);
            }
        }
    };
    return (React.createElement("div", null, isOpen && (React.createElement(Dialog, { classes: { paper: classes.dialog }, open: isOpen, onClose: function () {
            onClose();
            setselectionStart(0);
        } },
        React.createElement(Toolbar, null,
            !isMultiline && (React.createElement(Input, { placeholder: placeholder, isLoading: false, className: theme.ConsoleInput, inputRef: function (input) { return input && input.focus(); }, TextFieldProps: {
                    onChange: onChangeHandler,
                    onKeyDown: onKeyDownHendler,
                    value: commandStr,
                    error: !!parsingError,
                    helperText: parsingError
                } })),
            React.createElement(TextField, __assign({}, {
                classes: { root: classes.root },
                className: cn(theme.ConsoleTextField, (_b = {},
                    _b[theme.ConsoleTextField_hidden] = !isMultiline,
                    _b)),
                multiline: true,
                value: commandStr,
                // inputRef: input => input && input.focus(),
                inputRef: textAreaCaretHandler,
                variant: 'outlined',
                placeholder: placeholder,
                onChange: onChangeHandler,
                onKeyDown: onKeyDownHendler,
                error: !!parsingError,
                helperText: parsingError
            })),
            React.createElement("div", { className: theme.ConsoleCloseIcon },
                React.createElement(CloseIcon, { onClick: onClose })))))));
};
export default Console;
