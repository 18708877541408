import { __values } from "tslib";
import { emailRegex, phoneRegex } from '../../../utils';
var requiredField = function (value) { return (typeof value === 'undefined' ? 'Required' : undefined); };
var onlyLetters = function (value) { return (value.match(/\d/gi) ? 'Only letters' : undefined); };
export var useRequestFormValidation = function () {
    var validateName = function (value) {
        var e_1, _a;
        var testResult = undefined;
        try {
            for (var _b = __values([requiredField, onlyLetters]), _c = _b.next(); !_c.done; _c = _b.next()) {
                var testFunc = _c.value;
                testResult = testFunc(value);
                if (testResult) {
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return testResult;
    };
    var validateComments = function (value) {
        return undefined;
    };
    var validateEmail = function (value) {
        var e_2, _a;
        var testResult = undefined;
        try {
            for (var _b = __values([requiredField]), _c = _b.next(); !_c.done; _c = _b.next()) {
                var testFunc = _c.value;
                testResult = testFunc(value);
                if (testResult) {
                    break;
                }
                if (!emailRegex.test(value)) {
                    return 'Wrong email';
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return testResult;
    };
    var validatePhone = function (value) {
        var e_3, _a;
        var testResult = undefined;
        try {
            for (var _b = __values([requiredField]), _c = _b.next(); !_c.done; _c = _b.next()) {
                var testFunc = _c.value;
                testResult = testFunc(value);
                if (testResult) {
                    break;
                }
                if (!phoneRegex.test(value)) {
                    return 'Wrong phone';
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return testResult;
    };
    var validateAgreeButton = requiredField;
    return {
        validateName: validateName,
        validateEmail: validateEmail,
        validatePhone: validatePhone,
        validateComments: validateComments,
        validateAgreeButton: validateAgreeButton
    };
};
