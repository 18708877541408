import { __assign, __awaiter, __generator } from "tslib";
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import * as OrderQuery from './query.graphql';
import * as CheckinOrderQuery from './GetCheckinOrder.graphql';
import * as CheckinSaveOrderServices from './CheckinSaveOrderServices.graphql';
import * as CheckinInfoQuery from './CheckinInfo.graphql';
import * as UpdateOrderMutation from './UpdateOrder.graphql';
import { fillCheckinOrder } from '../Modules/Checkin/store/order/actions';
export var useOrder = function (orderId, options) {
    return useQuery(OrderQuery.Order, __assign({ variables: __assign({ id: orderId }, options.variables) }, options));
};
export var useUpdateOrderMutation = function () {
    return useMutation(UpdateOrderMutation.UpdateOrder);
};
export var useCheckinOrder = function (orderId, options) {
    return useQuery(CheckinOrderQuery.GetCheckinOrder, __assign({ variables: __assign({ id: orderId }, options.variables) }, options));
};
export var useRefetchCheckinOrder = function (orderId) {
    var refetch = useCheckinOrder(orderId, { skip: true, fetchPolicy: 'no-cache' }).refetch;
    var dispatch = useDispatch();
    return function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, refetch({
                        id: orderId
                    })];
                case 1:
                    response = _b.sent();
                    if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.CheckinOrder) {
                        dispatch(fillCheckinOrder(response.data.CheckinOrder));
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
            }
        });
    }); };
};
export var useCheckinInfo = function (id, secret, options) {
    return useQuery(CheckinInfoQuery.CheckinInfo, __assign({ variables: {
            params: {
                id: id,
                secret: secret
            }
        } }, options));
};
export var isOrderResponse = function (response) {
    return response.hasOwnProperty('Order');
};
export var isCheckinOrderResponse = function (response) {
    return response.hasOwnProperty('CheckinOrder');
};
export var useOrderServices = function () {
    return useMutation(OrderQuery.SaveOrderServices);
};
export var useCheckinOrderServices = function () {
    return useMutation(CheckinSaveOrderServices.CheckinSaveOrderServices);
};
export var isOrderServicesResponse = function (response) {
    return response.hasOwnProperty('SaveOrderServices');
};
export var isCheckinOrderServicesResponse = function (response) {
    return response.hasOwnProperty('CheckinSaveOrderServices');
};
