import * as React from 'react';
import Group from './Group/Group';
import { useTheme } from '../../../../theme';
import { getGroupsMap } from './utils';
var FormFields = function (props) {
    var theme = useTheme('PassengerForm').FormFields;
    var fieldGroups = React.useMemo(function () {
        return getGroupsMap(props.fields, props.passengerId);
    }, [props.fields]);
    return (React.createElement("div", null,
        !props.inline && React.createElement("div", { className: theme.label }, props.label),
        React.createElement("div", { className: theme.container }, fieldGroups.map(function (group) {
            return (React.createElement(Group, { fields: group.fields, groupType: group.type, passengerId: props.passengerId, passengerType: props.passengerType, disabled: props.disabled, onTravellerSelect: props.onTravellerSelect }));
        }))));
};
export default FormFields;
