import { __assign, __awaiter, __extends, __generator, __read, __spreadArray, __values } from "tslib";
import * as React from 'react';
import { useContext } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { de, enGB, kk, ru } from 'date-fns/locale';
import { format as originalFormat, parseISO } from 'date-fns';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import flattenDeep from 'lodash.flattendeep';
import { Locale, LOCALES } from './types';
import { Currency } from './enums';
import { getCurrency, getLocaleFromLocalStorage, setLocaleToLocalStorage } from './cache';
import memes from './memes';
import { useMediaQuery } from 'react-responsive';
export var API_DATE_FORMAT = 'yyyy-MM-dd';
export var SHOW_RECAPTCHA = 'SHOW_RECAPTCHA';
export var TABLET_MIN_WIDTH = 1024;
export var TABLET_MIDDLE_WIDTH = 768;
export var MOBILE_MIN_WIDTH = 600;
export var getInfo = function (options) {
    if (options === void 0) { options = {}; }
    return ({
        info: __assign({ inline: true, source: true, maxPropsIntoLine: 1, maxPropArrayLength: 2 }, options)
    });
};
export var isDemo = document.location.href.includes('demo.websky.tech');
export var createWithThemeDecorator = function (Consumer) {
    return function (Component) {
        var NewComponent = /** @class */ (function (_super) {
            __extends(class_1, _super);
            function class_1() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            class_1.prototype.render = function () {
                var _this = this;
                return React.createElement(Consumer, null, function (context) { return React.createElement(Component, __assign({}, _this.props, { theme: context })); });
            };
            return class_1;
        }(React.Component));
        NewComponent.displayName = Component.displayName;
        return NewComponent;
    };
};
export var createTheme = function (defaultTheme) {
    var Context = React.createContext(defaultTheme);
    return {
        Context: Context,
        Consumer: Context.Consumer,
        Provider: Context.Provider,
        withTheme: createWithThemeDecorator(Context.Consumer),
        useTheme: function () { return useContext(Context); }
    };
};
export var sum = function () {
    var money = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        money[_i] = arguments[_i];
    }
    var result = { amount: 0, currency: Currency.USD };
    money.forEach(function (obj) {
        if (result.amount !== 0) {
            result.amount += obj.amount;
        }
        else {
            result = __assign({}, obj);
        }
    });
    return result;
};
export var getBrowserLocale = function () {
    var _a;
    var locale = typeof navigator !== 'undefined'
        ? navigator.languages
            ? navigator.languages[0]
            : (_a = navigator.language) !== null && _a !== void 0 ? _a : navigator['userLanguage']
        : Locale.Russian;
    return locale.split('-')[0].replace(/"/g, '');
};
var defaultResources = {};
var currentLocale = getLocaleFromLocalStorage();
export var currentCurrency = getCurrency();
// No cached locale - pull from local storage.
if (!currentLocale) {
    currentLocale = getLocaleFromLocalStorage();
}
// No cached locale - pull from local storage.
if (!currentCurrency) {
    currentCurrency = getCurrency();
}
// No local storage locale - pull from browser settings.
if (!currentLocale) {
    var browserLocale = getBrowserLocale();
    if (LOCALES.has(browserLocale)) {
        currentLocale = browserLocale;
        setLocaleToLocalStorage(currentLocale);
    }
}
// Load default resources.
LOCALES.forEach(function (locale) {
    if (!defaultResources[locale]) {
        defaultResources[locale] = {};
    }
    if (!defaultResources[locale].translation) {
        defaultResources[locale].translation = require("../locales/".concat(locale, "/translation.json"));
    }
});
i18n.use(initReactI18next).init({
    resources: defaultResources,
    lng: currentLocale,
    fallbackLng: Locale.Russian,
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});
export var getLocale = function () { return i18n.language; };
export var changeLocale = function (locale) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!LOCALES.has(locale)) return [3 /*break*/, 2];
                return [4 /*yield*/, i18n.changeLanguage(locale)];
            case 1:
                _a.sent();
                setLocaleToLocalStorage(locale);
                currentLocale = locale;
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); };
export var initI18n = memes(function (moduleName) {
    LOCALES.forEach(function (locale) {
        try {
            var resources = require("../locales/".concat(locale, "/").concat(moduleName, ".json"));
            i18n.addResourceBundle(locale, moduleName, resources, true, false);
        }
        catch (e) { }
    });
});
export var extendI18n = function (newResources) {
    for (var locale in newResources) {
        if (newResources.hasOwnProperty(locale)) {
            for (var moduleName in newResources[locale]) {
                if (newResources[locale].hasOwnProperty(moduleName)) {
                    i18n.addResourceBundle(locale, moduleName, newResources[locale][moduleName], true, true);
                }
            }
        }
    }
};
var userLocation;
export var getUserLocation = function (onSuccess, onError) {
    if (userLocation) {
        onSuccess(userLocation);
    }
    else if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (_a) {
            var coords = _a.coords;
            if (coords.longitude && coords.latitude) {
                userLocation = {
                    longitude: coords.longitude.toString(),
                    latitude: coords.latitude.toString()
                };
                onSuccess(userLocation);
            }
        }, function (error) { return onError && onError(error.message); });
    }
    else if (onError) {
        onError('GeoLocation API is not available for that kind of browsers');
    }
};
export var format = function (date, format, options) {
    var localeObject;
    switch (currentLocale) {
        case Locale.Russian:
            localeObject = ru;
            break;
        case Locale.English:
            localeObject = enGB;
            break;
        case Locale.German:
            localeObject = de;
            break;
        case Locale.Kazakh:
            localeObject = kk;
            break;
    }
    var parsedDate = typeof date === 'string' ? parseISO(date) : date;
    var formattedDate = originalFormat(parsedDate, format, __assign(__assign({}, options), { locale: localeObject }));
    return formattedDate.endsWith('.') ? formattedDate.substr(0, formattedDate.length - 1) : formattedDate;
};
export var removeDotsFromDate = function (dateString) {
    return dateString.replace('.', '');
};
export var getPassengerName = function (firstName, lastName) {
    return firstName && lastName
        ? "".concat(capitalizeFirstLetter(lastName.toLowerCase()), " ").concat(capitalizeFirstLetter(firstName.toLowerCase()))
        : '';
};
export var debounce = function (func, wait) {
    var timeout;
    return function () {
        var newArgs = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            newArgs[_i] = arguments[_i];
        }
        var later = function () {
            timeout = null;
            func.apply(void 0, __spreadArray([], __read(newArgs), false));
        };
        clearTimeout(timeout);
        timeout = window.setTimeout(later, wait);
    };
};
export var mergeCSS = function (originalTheme, newTheme) {
    var result = JSON.parse(JSON.stringify(originalTheme));
    for (var moduleName in newTheme) {
        if (newTheme.hasOwnProperty(moduleName) && originalTheme.hasOwnProperty(moduleName)) {
            var components = newTheme[moduleName];
            for (var componentName in components) {
                if (components.hasOwnProperty(componentName) &&
                    originalTheme[moduleName].hasOwnProperty(componentName)) {
                    var cssClasses = components[componentName];
                    for (var cssClassKey in cssClasses) {
                        if (cssClasses.hasOwnProperty(cssClassKey) &&
                            originalTheme[moduleName][componentName].hasOwnProperty(cssClassKey)) {
                            result[moduleName][componentName][cssClassKey] += " ".concat(cssClasses[cssClassKey]);
                        }
                    }
                }
            }
        }
    }
    return result;
};
export var decapitalizeFirstLetter = memes(function (string) {
    return string.charAt(0).toLowerCase() + string.substr(1);
});
var capitalizeFirstLetterSplittedBy = function (string, separator) {
    if (string.includes(separator)) {
        return string
            .split(separator)
            .map(function (word) { return word.charAt(0).toUpperCase() + word.substr(1); })
            .join(separator);
    }
    return string;
};
export var capitalizeFirstLetter = memes(function (string) {
    var newString = string.charAt(0).toUpperCase() + string.substr(1);
    newString = capitalizeFirstLetterSplittedBy(newString, ' ');
    newString = capitalizeFirstLetterSplittedBy(newString, '-');
    return newString;
});
export var downloadFile = function (href, name) {
    var element = document.createElement('a');
    element.setAttribute('href', href);
    element.setAttribute('download', name);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};
export var downloadPDF = function (base64, name) {
    downloadFile('data:application/pdf;base64,' + base64, name);
};
export var downloadPkpass = function (base64, name) {
    downloadFile('data:application/vnd.apple.pkpass;base64,' + base64, name);
};
export var enableSentry = function () {
    if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN) {
        Sentry.init({
            dsn: process.env.SENTRY_DSN,
            blacklistUrls: ['https://bron12.bar/'],
            release: "websky-core@".concat(process.env.RELEASE),
            ignoreErrors: [
                "TypeError: can't redefine non-configurable property \"userAgent\"",
                "TypeError: Cannot read property 'Config' of undefined",
                /UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:\d/,
                "TypeError: $.viewportW is not a function",
                "Cannot read property 'target' of undefined",
                "undefined is not an object (evaluating 'i.currentObservable.query.refetch')",
                'err.kn(502)',
                'err.kn(501)',
                /err\.kn\(5\d\d\)$/gi,
                "Uncaught (in promise) Error: err.kn(501)",
                "TypeError: Failed to fetch",
                "TypeError: undefined is not an object (evaluating 'e.reason.stack.split')",
                "TypeError: Origin https://nordwindairlines.ru is not allowed by Access-Control-Allow-Origin.",
                "Cannot read property 'enabled' of undefined",
                "Cannot set property 'enabled' of undefined",
                "undefined is not an object (evaluating 't.enabled')",
                "Cannot read property 'id' of undefined",
                "Cannot read property 'focus' of undefined",
                "Unexpected token 'else'",
                "SyntaxError: illegal character",
                't is undefined',
                'Non-Error promise rejection captured with value: 500 ',
                "Cannot read property 'split' of undefined",
                "Unexpected token '<'",
                "Unexpected identifier",
                "Network error: \u0421\u0435\u0442\u0435\u0432\u043E\u0435 \u0441\u043E\u0435\u0434\u0438\u043D\u0435\u043D\u0438\u0435 \u043F\u043E\u0442\u0435\u0440\u044F\u043D\u043E.",
                "Network error: Failed to fetch",
                "Failed to fetch",
                "NetworkError when attempting to fetch resource.",
                "Origin https://nordwindairlines.ru is not allowed by Access-Control-Allow-Origin.",
                "undefined is not an object (evaluating 'e.reason.stack.split')",
                "Network error: Type error",
                "Network error: NetworkError when attempting to fetch resource.",
                "SyntaxError: The string did not match the expected pattern.",
                "Unexpected end of input",
                "Loading chunk",
                "window.__hs is not a function. (In 'window.__hs()', 'window.__hs' is undefined)",
                "Unable to get property 'enabled' of undefined or null reference",
                "a[b].target.className.indexOf is not a function. (In 'a[b].target.className.indexOf(bc)', 'a[b].target.className.indexOf' is undefined)",
                "a[b].target.className.indexOf is not a function. (In 'a[b].target.className.indexOf(ac)', 'a[b].target.className.indexOf' is undefined)",
                "ReferenceError: 'yaCounter56829106' is not defined",
                /yaCounter56829106 is not defined/,
                "Can't find variable: yaCounter56829106",
                /Can't find variable: VK/,
                /VK is not defined/,
                "'VK' is not defined",
                "Cannot read property 'disconnect' of null",
                /starting at object with constructor 'SVGSymbolElement'/,
                "ERRORS_COLLECTOR_STORAGE is not defined",
                "Can't find variable: ERRORS_COLLECTOR_STORAGE",
                "Cannot read property 'list' of undefined",
                "Network error: \u041D\u0435 \u0443\u0434\u0430\u043B\u043E\u0441\u044C \u0437\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u044C \u043E\u043F\u0435\u0440\u0430\u0446\u0438\u044E. \u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u043E\u0442\u043E\u043A\u043E\u043B\u0430",
                "undefined is not an object (evaluating 't.id')",
                "SecurityError: Failed to read the 'cssRules' property from 'CSSStyleSheet': Cannot access rules",
                "Network error: \u041D\u0435 \u0443\u0434\u0430\u043B\u043E\u0441\u044C \u0438\u0437\u0432\u043B\u0435\u0447\u044C",
                "undefined is not an object (evaluating 'PAGE_CONFIG.notifications.list')",
                "missing ) after argument list",
                "NotFoundError: The object can not be found here.",
                "Illegal invocation",
                "Cannot read property 'resizeTo' of null",
                "Invalid or unexpected token",
                "PAGE_CONFIG.notifications is undefined"
            ]
        });
    }
};
export var captureEventSentry = function (event) {
    if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN) {
        Sentry.captureMessage(event.detail.message, Sentry.Severity.Error);
    }
};
export var captureErrorSentry = function (message, level) {
    if (level === void 0) { level = Severity.Error; }
    if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN) {
        Sentry.captureMessage(message, level);
    }
};
export var removeTrailingSlash = function (string) {
    var stringLength = string.length;
    return string.charAt(stringLength - 1) === '/' ? string.substr(0, stringLength - 1) : string;
};
export var setsAreEqual = function (a, b) {
    var e_1, _a;
    if (a.size !== b.size) {
        return false;
    }
    try {
        for (var a_1 = __values(a), a_1_1 = a_1.next(); !a_1_1.done; a_1_1 = a_1.next()) {
            var index = a_1_1.value;
            if (!b.has(index)) {
                return false;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (a_1_1 && !a_1_1.done && (_a = a_1.return)) _a.call(a_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return true;
};
export var isOdd = function (int) { return (typeof int === 'string' ? parseInt(int, 10) : int) % 2 === 1; };
export var useIsMobile = function () {
    return useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
};
export var setToMap = function (map, ifExist, // here we must decide what we gonna do if value exists
key, entity) {
    if (map.has(key)) {
        map.set(key, ifExist(map.get(key), entity));
    }
    else {
        map.set(key, entity);
    }
};
export var useIsDesktop = function () {
    return useMediaQuery({ minWidth: TABLET_MIN_WIDTH });
};
export var scrollTo = function (top) {
    if (window.hasOwnProperty('scrollTo')) {
        window.scrollTo({
            top: top,
            behavior: 'smooth'
        });
    }
};
export var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export var phoneRegex = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
var excludeChars = '(?!.*[\\/])';
var letters = '(?=.*?[A-Z])(?=.*?[a-z])';
var numbers = '(?=.*?[0-9])';
var specialChars = '(?=.*?[~@#$%^&*+=`|{}:;!.?\\"()\\[\\]\\-\',\\/<>_\\s])';
export var leoPasswordRegExp = new RegExp("^".concat(excludeChars).concat(letters).concat(numbers).concat(specialChars, ".{8,}$"));
export var checkIsTerminal = function () { return window.__webskyEngineConfig.global.companyInfo.iataCode === 'TERMINAL'; };
// source of list https://github.com/DamonOehlman/detect-browser/blob/546e6f1348375d8a486f21da07b20717267f6c49/src/index.ts#L134
export var USER_AGENT_RULES = [
    ['aol', /AOLShield\/([0-9\._]+)/],
    ['edge', /Edge\/([0-9\._]+)/],
    ['edge-ios', /EdgiOS\/([0-9\._]+)/],
    ['yandexbrowser', /YaBrowser\/([0-9\._]+)/],
    ['kakaotalk', /KAKAOTALK\s([0-9\.]+)/],
    ['samsung', /SamsungBrowser\/([0-9\.]+)/],
    ['silk', /\bSilk\/([0-9._-]+)\b/],
    ['miui', /MiuiBrowser\/([0-9\.]+)$/],
    ['beaker', /BeakerBrowser\/([0-9\.]+)/],
    ['edge-chromium', /EdgA?\/([0-9\.]+)/],
    ['chromium-webview', /(?!Chrom.*OPR)wv\).*Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
    ['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
    ['phantomjs', /PhantomJS\/([0-9\.]+)(:?\s|$)/],
    ['crios', /CriOS\/([0-9\.]+)(:?\s|$)/],
    ['firefox', /Firefox\/([0-9\.]+)(?:\s|$)/],
    ['fxios', /FxiOS\/([0-9\.]+)/],
    ['opera-mini', /Opera Mini.*Version\/([0-9\.]+)/],
    ['opera', /Opera\/([0-9\.]+)(?:\s|$)/],
    ['opera', /OPR\/([0-9\.]+)(:?\s|$)/],
    ['pie', /^Microsoft Pocket Internet Explorer\/(\d+\.\d+)$/],
    ['pie', /^Mozilla\/\d\.\d+\s\(compatible;\s(?:MSP?IE|MSInternet Explorer) (\d+\.\d+);.*Windows CE.*\)$/],
    ['netfront', /^Mozilla\/\d\.\d+.*NetFront\/(\d.\d)/],
    ['ie', /Trident\/7\.0.*rv\:([0-9\.]+).*\).*Gecko$/],
    ['ie', /MSIE\s([0-9\.]+);.*Trident\/[4-7].0/],
    ['ie', /MSIE\s(7\.0)/],
    ['bb10', /BB10;\sTouch.*Version\/([0-9\.]+)/],
    ['android', /Android\s([0-9\.]+)/],
    ['ios', /Version\/([0-9\._]+).*Mobile.*Safari.*/],
    ['safari', /Version\/([0-9\._]+).*Safari/],
    ['facebook', /FB[AS]V\/([0-9\.]+)/],
    ['instagram', /Instagram\s([0-9\.]+)/],
    ['ios-webview', /AppleWebKit\/([0-9\.]+).*Mobile/],
    ['ios-webview', /AppleWebKit\/([0-9\.]+).*Gecko\)$/],
    ['curl', /^curl\/([0-9\.]+)$/],
    [
        'searchbot',
        /alexa|bot|crawl(er|ing)|facebookexternalhit|feedburner|google web preview|nagios|postrank|pingdom|slurp|spider|yahoo!|yandex/
    ]
];
export var flatDeep = function (arrays) {
    return flattenDeep(arrays);
};
