import { __assign } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import cn from 'classnames';
import differencInCalendarDays from 'date-fns/differenceInCalendarDays';
import { useTheme } from '../../../../theme';
import { format } from '../../../../utils';
import { usePaginationMessages } from '../../hooks';
import { MessageType } from '../../types';
import MessageDefault from './MessageDefault/MessageDefault';
import MessagePagination from './MessagePagination/MessagePagination';
import MessageDocuments from './MessageDocuments/MessageDocuments';
var Message = function (props) {
    var _a, _b, _c;
    var _d, _e;
    var theme = useTheme('Terminal').Message;
    var parsedValues = React.useMemo(function () {
        if (!props.message.values) {
            return null;
        }
        try {
            return Object.keys(props.message.values)
                .map(function (key) {
                return {
                    key: key,
                    value: props.message.values[key]
                };
            })
                .filter(function (item) { return !!item.value; });
        }
        catch (e) {
            console.error(e);
            return [];
        }
    }, [(_d = props.message) === null || _d === void 0 ? void 0 : _d.values]);
    var _f = usePaginationMessages(props.message, props.isLastMessage, !!(parsedValues === null || parsedValues === void 0 ? void 0 : parsedValues.length)), messagesByPages = _f.messagesByPages, isLastPage = _f.isLastPage, nextPage = _f.nextPage, closePagination = _f.closePagination, hasPagination = _f.hasPagination;
    var isPaginationMessage = hasPagination && props.isLastMessage && !isLastPage;
    var onKeyDownHandler = function (e) {
        if ([13, 81, 34].includes(e.keyCode)) {
            closePagination();
        }
        else {
            nextPage();
        }
    };
    var wheelHandler = true;
    var onWheelHandler = function (e) {
        if (e.deltaY > 0 && wheelHandler) {
            nextPage();
            wheelHandler = false;
            setTimeout(function () {
                wheelHandler = true;
            }, 2000);
        }
    };
    useEffect(function () {
        if (isPaginationMessage) {
            document.addEventListener('keydown', onKeyDownHandler);
            document.addEventListener('wheel', onWheelHandler, { passive: false });
        }
        return function () {
            document.removeEventListener('keydown', onKeyDownHandler);
            document.removeEventListener('wheel', onWheelHandler);
        };
    }, [isPaginationMessage]);
    useEffect(function () {
        var _a;
        if (props.isLastMessage) {
            (_a = props.onChangePagination) === null || _a === void 0 ? void 0 : _a.call(props, isPaginationMessage);
        }
    }, [isPaginationMessage]);
    var isResponseDateDifferentFromRequest = differencInCalendarDays(props.message.responseDate, props.message.requestDate) > 0;
    var day = React.useMemo(function () {
        return format(props.message.requestDate, 'dd.MM.yyyy');
    }, [props.message.requestDate]);
    var responseDay = React.useMemo(function () {
        if (!props.message.responseDate) {
            return '';
        }
        return format(props.message.responseDate, 'dd.MM.yyyy');
    }, [props.message.responseDate, props.message.requestDate]);
    var requestTime = React.useMemo(function () {
        return format(props.message.requestDate, 'HH:mm:ss');
    }, [props.message.requestDate]);
    var responseTime = React.useMemo(function () {
        if (!props.message.responseDate) {
            return '...';
        }
        return format(props.message.responseDate, 'HH:mm:ss');
    }, [props.message.responseDate]);
    var durationTime = React.useMemo(function () {
        if (props.message.requestDate && props.message.responseDate) {
            return props.message.responseDate.getTime() - props.message.requestDate.getTime();
        }
        return '';
    }, [props.message.responseDate, props.message.requestDate]);
    var isDocumentsType = (_e = props.documents) === null || _e === void 0 ? void 0 : _e.some(function (document) { var _a, _b; return ((_a = document.emds) === null || _a === void 0 ? void 0 : _a.length) || ((_b = document.tickets) === null || _b === void 0 ? void 0 : _b.length); });
    var messageType = isPaginationMessage
        ? MessageType.Pagination
        : isDocumentsType
            ? MessageType.Documents
            : MessageType.Default;
    var MessageResponse = (_a = {},
        _a[MessageType.Default] = MessageDefault,
        _a[MessageType.Pagination] = MessagePagination,
        _a[MessageType.Documents] = MessageDocuments,
        _a)[messageType];
    return (React.createElement("div", { className: cn(props.className, theme.message, (_b = {},
            _b[theme.message_pagination] = isPaginationMessage,
            _b)) },
        React.createElement("div", { className: theme.payload },
            React.createElement("div", { className: theme.request },
                props.message.request,
                parsedValues && (React.createElement("p", null, parsedValues.map(function (_a) {
                    var key = _a.key, value = _a.value;
                    return (React.createElement("span", { key: key },
                        key.toUpperCase(),
                        "=",
                        value));
                })))),
            props.message.response && (React.createElement("div", { className: cn(theme.response, (_c = {},
                    _c[theme.error] = props.message.errorInResponse,
                    _c)) },
                React.createElement(MessageResponse, __assign({}, props, { message: __assign(__assign({}, props.message), { response: isPaginationMessage ? messagesByPages : props.message.response }) }))))),
        React.createElement("div", { className: theme.timepoints },
            React.createElement("span", { className: theme.request_date }, day),
            React.createElement("span", { className: theme.request_date }, requestTime),
            React.createElement("span", { className: theme.request_date }, format(props.message.requestDate, 'OOOO')),
            isResponseDateDifferentFromRequest ? React.createElement("span", null, responseDay) : React.createElement("span", null),
            React.createElement("span", null, responseTime),
            React.createElement("span", null, durationTime && "".concat(durationTime, "ms")))));
};
export default Message;
