export var FormStages;
(function (FormStages) {
    FormStages[FormStages["Default"] = 0] = "Default";
    FormStages[FormStages["Loading"] = 1] = "Loading";
    FormStages[FormStages["Success"] = 2] = "Success";
})(FormStages || (FormStages = {}));
export var TravellerFields;
(function (TravellerFields) {
    TravellerFields["FirstName"] = "firstName";
    TravellerFields["LastName"] = "lastName";
    TravellerFields["MiddleName"] = "middleName";
    TravellerFields["BirthDate"] = "birthDate";
    TravellerFields["Gender"] = "gender";
    TravellerFields["Nationality"] = "nationality";
    TravellerFields["Phone"] = "phone";
    TravellerFields["Email"] = "email";
})(TravellerFields || (TravellerFields = {}));
