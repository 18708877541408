export var getPassengersWithChilds = function (passengers) {
    var passengersWithChilds = [];
    passengers.forEach(function (passenger) {
        if (!passenger.linkedTraveller) {
            passengersWithChilds.push({
                passenger: passenger,
                childs: getChildsForPassenger(passengers, passenger.id)
            });
        }
    });
    return passengersWithChilds;
};
var getChildsForPassenger = function (passengers, passengerIdForSearch) {
    return passengers.filter(function (passenger) { return passenger.linkedTraveller === passengerIdForSearch; });
};
export var getIdsForSplitting = function (state, passengers) {
    var ids = [];
    state.forEach(function (id) {
        var passenger = passengers.find(function (passenger) { return passenger.passenger.id === id; });
        ids.push(id);
        if (passenger.childs.length) {
            passenger.childs.forEach(function (child) { return ids.push(child.id); });
        }
    });
    return ids;
};
