import * as React from 'react';
import Passenger from '../../../../Passengers/Passenger/Passenger';
import { Button } from '../../../../../../index';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../../theme';
var SeatServicePassengers = function (props) {
    var theme = useTheme('SeatMap').SeatServiceSlide;
    var t = useTranslation('SeatMap').t;
    return (React.createElement("div", null,
        props.passengers.map(function (passenger) {
            var _a;
            var seat = passenger.seats && passenger.seats.find(function (seat) { return seat.segmentId === props.segmentId; });
            return (React.createElement(Passenger, { passenger: passenger, seat: seat, onClick: props.onSeatSelect, clearEnable: (seat === null || seat === void 0 ? void 0 : seat.number) === ((_a = props.seat) === null || _a === void 0 ? void 0 : _a.number) }));
        }),
        React.createElement("div", { className: theme.clearContainer },
            React.createElement(Button, { onClick: props.onClear, className: theme.clear, variant: "outline" }, t('Clear all')))));
};
export default SeatServicePassengers;
