import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Loader from '../../../Loader';
import * as LogoutQuery from '../../../__queries__/Logout.graphql';
import { useAuthToken } from '../../LoginChangePassword/hooks';
var Logout = function () {
    var _a = __read(useMutation(LogoutQuery.Logout), 1), logoutMutation = _a[0];
    var history = useHistory();
    var logout = function () { return __awaiter(void 0, void 0, void 0, function () {
        var setToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setToken = useAuthToken().setToken;
                    setToken(null);
                    return [4 /*yield*/, logoutMutation()];
                case 1:
                    _a.sent();
                    history.push('/');
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        logout();
    }, []);
    return React.createElement(Loader, null);
};
export default Logout;
