import React from 'react';
import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CommonCard from '../../components/CommonCard/CommonCard';
import { useTheme } from '../../../../theme';
import { getGroupedAllSelectedServices, getSegments } from '../../../../Checkout/store/order/selectors';
import { getOrder } from '../../../../Checkout/store/order/selectors';
import { baggageIcon } from '../../icons';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../../__generated__/globalTypes';
import { calculateMinPrice, calculateShowClearValue, calculateSummary, deleteServicesByType, isEmptyServices } from '../../utils';
export var useBaggageServiceCard = function () {
    var baggage = useSelector(getGroupedAllSelectedServices).baggage;
    var order = useSelector(getOrder);
    var t = useTranslation('ServiceCard').t;
    var segments = useSelector(getSegments);
    var theme = useTheme('ServicesCard').Baggage;
    var dispatch = useDispatch();
    var _a = useMemo(function () { return calculateSummary(baggage, order, segments); }, [
        baggage,
        order,
        segments
    ]), totalPrice = _a.totalPrice, servicesIds = _a.servicesIds;
    var clearAll = useCallback(function () {
        deleteServicesByType(servicesIds, OrderAdditionalServiceGdsServiceServiceType.Baggage, dispatch);
    }, [servicesIds]);
    var isShowClearAll = useMemo(function () { return calculateShowClearValue(baggage, OrderAdditionalServiceGdsServiceServiceType.Baggage); }, [baggage]);
    var isEmpty = useMemo(function () { return isEmptyServices(baggage); }, [baggage]);
    var minPrice = useMemo(function () {
        return calculateMinPrice(order.additionalServices.gdsServices.services, OrderAdditionalServiceGdsServiceServiceType.Baggage);
    }, [order.additionalServices.gdsServices.services]);
    return {
        Content: isEmpty
            ? null
            : function () { return React.createElement(CommonCard, { order: order, services: baggage, backgroundClassName: theme.backgroundColor }); },
        EmptyContent: function () { return React.createElement("div", null, t('Transportation of impressions no limitations!')); },
        contentClassName: theme.content,
        description: 'Baggage',
        headerProps: {
            title: t('Baggage'),
            icon: baggageIcon,
            backgroundClassName: theme.backgroundColor
        },
        totalPrice: totalPrice,
        minPrice: minPrice,
        clearAll: clearAll,
        isShowClearAll: isShowClearAll
    };
};
