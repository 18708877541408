import { RefundMode, StepType, Unvoluntary } from './types';
import { getPassengerFirstName, getPassengerLastName } from '../Checkout/store/passengers/selectors';
import { initialState as stepsInitialState } from './store/steps/reducer';
import { TravellerFieldEnum } from '../__generated__/graphql';
import { getPassengerName } from '../utils';
import { useIsTerminal } from '../hooks';
export var useCheckoutOrderRefundAdapter = function (order) {
    var passengers = {};
    order.travellers.forEach(function (traveller) {
        var _a, _b, _c, _d;
        if (traveller.linkedTraveller) {
            return;
        }
        passengers[traveller.id] = {
            id: traveller.id,
            name: getPassengerName(getPassengerFirstName(traveller).value, getPassengerLastName(traveller).value),
            children: order.travellers
                .filter(function (orderTraveller) { var _a; return ((_a = orderTraveller.linkedTraveller) === null || _a === void 0 ? void 0 : _a.id) === traveller.id; })
                .map(function (orderTraveller) { return ({
                name: getPassengerName(getPassengerFirstName(orderTraveller).value, getPassengerLastName(orderTraveller).value),
                id: orderTraveller.id
            }); }),
            selected: useIsTerminal(),
            phone: (_b = (_a = traveller.values) === null || _a === void 0 ? void 0 : _a.find(function (value) { return value.type === TravellerFieldEnum.Phone; })) === null || _b === void 0 ? void 0 : _b.value,
            email: (_d = (_c = traveller.values) === null || _c === void 0 ? void 0 : _c.find(function (value) { return value.type === TravellerFieldEnum.Email; })) === null || _d === void 0 ? void 0 : _d.value
        };
    });
    var documents = {};
    order.travellers.forEach(function (traveller) {
        if (!traveller.linkedTraveller) {
            documents[traveller.id] = [];
        }
    });
    return {
        passengers: passengers,
        reason: order.exareInfo.refundAutoMode ? Unvoluntary.Unvoluntary : null,
        steps: stepsInitialState.filter(function (step) {
            if (!useIsTerminal()) {
                return true;
            }
            else {
                return step.type !== StepType.AttachFiles;
            }
        }),
        mode: order.exareInfo.refundAutoMode ? RefundMode.Auto : RefundMode.Manual,
        customer: null,
        documents: documents,
        refundInfo: {},
        aviaOrder: order
    };
};
