import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { format } from '../../../../utils';
import { parseISO } from 'date-fns';
var Point = function (_a) {
    var _b;
    var date = _a.date, time = _a.time, airport = _a.airport, arrival = _a.arrival, thin = _a.thin;
    var theme = useTheme('DesktopFlightRoute').PointStyles;
    return (React.createElement("div", { className: cn(theme.point, (_b = {},
            _b[theme.point_arrival] = arrival,
            _b[theme.point_thin] = thin,
            _b)) },
        React.createElement("div", { className: theme.stop },
            React.createElement("div", { className: theme.airport },
                React.createElement("div", { className: theme.airport__city }, airport.city.name),
                airport.title !== airport.name && React.createElement("div", { className: theme.airport__name }, airport.title)),
            React.createElement("div", { className: theme.airport__iata }, airport.iata)),
        React.createElement("div", { className: theme.date__container },
            React.createElement("div", { className: theme.time }, time),
            React.createElement("div", { className: theme.date }, format(parseISO(date), 'dd MMM, EEEEEE')))));
};
export default Point;
