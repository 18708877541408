import { TravellerVisaFieldEnum } from '../__generated__/globalTypes';
import { InputType } from '../Passenger/types';
export var getInputType = function (visaInputType) {
    switch (visaInputType) {
        case TravellerVisaFieldEnum.ApplicableCountry:
            return InputType.Select;
        case TravellerVisaFieldEnum.BirthPlace:
        case TravellerVisaFieldEnum.IssuePlace:
        case TravellerVisaFieldEnum.Number:
            return InputType.Text;
        case TravellerVisaFieldEnum.IssueDate:
            return InputType.Date;
        default:
            return InputType.Text;
    }
};
