import { __read } from "tslib";
import React, { useState } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import Input from '../../../../Input';
var HistoryModal = function (_a) {
    var className = _a.className, messages = _a.messages, onSelect = _a.onSelect;
    var css = useTheme('Terminal').HistoryModal;
    var t = useTranslation('Terminal').t;
    var _b = __read(useState(''), 2), search = _b[0], setSearch = _b[1];
    var hasMessages = messages.length > 0;
    var filteredList = messages.reverse().filter(function (message) { return message.request.indexOf(search.toUpperCase()) !== -1; });
    var onSelectHandler = function (request) { return function () {
        onSelect(request);
    }; };
    return (React.createElement("div", { className: cn(className, css.container) },
        React.createElement("div", { className: css.title }, t('Request history')),
        React.createElement(Input, { name: "search", className: css.input, placeholder: t('Search'), inputClassName: css.input__wrapper, inputPropsClassName: css.input__input, inputDisabledClassName: css.input__disabled, disabled: !hasMessages, variant: 'outlined', TextFieldProps: {
                onChange: function (event) {
                    setSearch(event.target.value);
                }
            } }),
        React.createElement("div", { className: css.list }, hasMessages ? (React.createElement(React.Fragment, null, filteredList.length > 0 ? (filteredList.map(function (message) { return (React.createElement("div", { className: css.list__item, key: message.id, onClick: onSelectHandler(message.request) }, message.request)); })) : (React.createElement("div", { className: css.list__empty },
            t('No results were found for'),
            " \u00AB",
            search,
            "\u00BB")))) : (React.createElement("div", { className: css.list__empty }, t('The requests you recently typing will displayed here'))))));
};
export default HistoryModal;
