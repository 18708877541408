import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import PromoLoader from '../PromoLoader/components/PromoLoader';
import FindOrder from '../Checkout/components/Checkout/FindOrder/FindOrder';
import { isCheckinOrderResponse, isOrderResponse } from '../__queries__/Order';
export default function OrderGuard(_a) {
    var _this = this;
    var children = _a.children, onOrderLoad = _a.onOrderLoad, loadOrder = _a.loadOrder, orderId = _a.orderId, orderLoaded = _a.orderLoaded, onOrderAccessGranted = _a.onOrderAccessGranted;
    var _b = __read(useState(true), 2), userHasAccess = _b[0], setUserHasAccess = _b[1];
    var _c = loadOrder(orderId, { skip: true, fetchPolicy: 'no-cache' }), refetch = _c.refetch, loading = _c.loading;
    var load = function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, data, errors, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setUserHasAccess(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, refetch()];
                case 2:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (errors) {
                        throw errors;
                    }
                    else if (data && isOrderResponse(data)) {
                        onOrderLoad(data.Order);
                    }
                    else if (data && isCheckinOrderResponse(data)) {
                        onOrderLoad(data.CheckinOrder);
                    }
                    else {
                        setUserHasAccess(false);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    console.error(error_1);
                    setUserHasAccess(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var orderAccessGrantedHandler = useCallback(function (order) {
        onOrderAccessGranted(order);
        setUserHasAccess(true);
    }, [onOrderAccessGranted]);
    useEffect(function () {
        if (!orderLoaded) {
            load();
        }
    }, [orderLoaded]);
    if (!userHasAccess) {
        return React.createElement(FindOrder, { initialId: orderId, onSuccess: orderAccessGrantedHandler });
    }
    return !orderLoaded || loading ? React.createElement(PromoLoader, { innerPromoLoader: true }) : React.createElement(React.Fragment, null, children);
}
