var _a;
import { __read } from "tslib";
import * as React from 'react';
import CheckoutAdditionalService from '../..';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder, getPriorityBoarding, getSegments } from '../../../Checkout/store/order/selectors';
import { useTranslation } from 'react-i18next';
import { getSelectedServicesState } from '../../../Checkout/store/selectedServices/selectors';
import { getPassengerState } from '../../../Checkout/store/passengers/selectors';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import Modal from '../../../Modal';
import VipServiceSelect from '../../../VipServiceSelect/VipServiceSelect';
import { Money, SlideBottom } from '../../../index';
import { useOrderServices } from '../../../__queries__/Order';
import { getPassengerPricesMap } from '../../utils';
import { AviaPassengerType } from '../../../__generated__/graphql';
import { useEffect } from 'react';
import { fillOrder } from '../../../Checkout/store/order/actions';
import { useTheme } from '../../../theme';
var PASSENGERS_TYPES = (_a = {},
    _a[AviaPassengerType.ADT] = 'Adult',
    _a[AviaPassengerType.CLD] = 'Child',
    _a[AviaPassengerType.INF] = 'Infant',
    _a[AviaPassengerType.INS] = 'Infant',
    _a);
var PriorityBoarding = function (props) {
    var t = useTranslation('Checkout').t;
    var _a = __read(React.useState(false), 2), opened = _a[0], setOpened = _a[1];
    var onOpen = function () { return setOpened(true); };
    var onClose = function () { return setOpened(false); };
    var order = useSelector(getOrder);
    var dispatch = useDispatch();
    var passengers = useSelector(getPassengerState);
    var segments = useSelector(getSegments);
    var priorityServices = useSelector(getPriorityBoarding);
    var selectedServices = useSelector(getSelectedServicesState);
    var theme = useTheme('CheckoutAdditionalService').PriorityBoarding;
    var selectedPriorityPrice = React.useMemo(function () {
        var price = {
            amount: 0,
            currency: null
        };
        passengers.forEach(function (passenger) {
            priorityServices.forEach(function (service) {
                if (selectedServices[passenger.id].hasOwnProperty(service.id)) {
                    selectedServices[passenger.id][service.id].forEach(function (selectedService) {
                        price.amount += selectedService.segments.length * service.price.amount;
                        price.currency = service.price.currency;
                    });
                }
            });
        });
        return price;
    }, [selectedServices, priorityServices]);
    var minPrice = React.useMemo(function () {
        var price = priorityServices[0].price;
        priorityServices.forEach(function (service) {
            if (service.price.amount < price.amount) {
                price = service.price;
            }
        });
        return price;
    }, [priorityServices]);
    var _b = __read(useOrderServices(), 2), saveServicesMutation = _b[0], _c = _b[1], loading = _c.loading, data = _c.data, error = _c.error;
    useEffect(function () {
        var _a;
        if (loading === false && (data === null || data === void 0 ? void 0 : data.SaveOrderServices) && !((_a = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length)) {
            dispatch(fillOrder(data === null || data === void 0 ? void 0 : data.SaveOrderServices));
        }
    }, [loading, data, error]);
    var onSaveHandler = function (selectedServices) {
        saveServicesMutation({
            variables: {
                params: {
                    id: order.id,
                    services: selectedServices.map(function (service) { return ({
                        travellerId: service.passengerId,
                        gdsServices: {
                            setServices: [
                                {
                                    segmentIds: service.segmentIds,
                                    count: service.count,
                                    serviceId: service.serviceId
                                }
                            ]
                        }
                    }); })
                }
            }
        }).then(onClose);
    };
    var passengerPrices = React.useMemo(function () {
        return getPassengerPricesMap(passengers, priorityServices);
    }, [passengers, priorityServices]);
    var renderSubheader = function () {
        return (React.createElement(React.Fragment, null, passengerPrices.map(function (passenger) { return (React.createElement("span", null,
            t(PASSENGERS_TYPES[passenger.type]),
            ": ",
            React.createElement(Money, { money: passenger.price }))); })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckoutAdditionalService, { header: t('Priority boarding'), description: priorityServices[0].description, isSelected: selectedPriorityPrice.amount > 0, addClick: onOpen, priceTotal: selectedPriorityPrice, priceFrom: minPrice, className: theme.priority, buttons: [
                {
                    action: function () { return setOpened(true); },
                    isLoading: loading,
                    text: t('Change')
                }
            ] }, priorityServices[0].description),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: opened, onClose: onClose, maxWidth: "sm" },
                React.createElement(VipServiceSelect, { header: t('Priority boarding'), passengers: passengers, services: priorityServices, segments: segments, loading: loading, onSave: onSaveHandler, selectedServices: selectedServices, onClose: onClose, subheader: renderSubheader() }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: opened, onClose: onClose },
                React.createElement(VipServiceSelect, { header: t('Priority boarding'), passengers: passengers, services: priorityServices, segments: segments, loading: loading, onSave: onSaveHandler, selectedServices: selectedServices, onClose: onClose, subheader: renderSubheader() })))));
};
export default PriorityBoarding;
