import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import cn from 'classnames';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { CompareState } from '../types';
import { chevronIcon } from '../Icons';
import { initI18n, TABLET_MIN_WIDTH } from '../../utils';
import { Link } from '../../index';
import Button from '../../Button/Button';
import ModalHeading from '../../ModalHeading';
import Cell from './Cell/Cell';
import { useTheme } from '../../theme';
import * as FareGroupsCompareTableQuery from '../../__queries__/FareGroupsCompareTable.graphql';
import MediaQuery from '../../MediaQuery/MediaQuery';
initI18n('CompareFares');
var CompareFares = function (_a) {
    var _b, _c;
    var notes = _a.notes, code = _a.code, selectedFare = _a.selectedFare, flightId = _a.flightId, orderId = _a.orderId;
    var compareFaresSlidesCount, fullCompareFaresSlidesCount, isImagesPresentInResponse;
    var headerRef = React.useRef();
    var _d = useQuery(FareGroupsCompareTableQuery.FareGroupsCompareTable, {
        variables: {
            iata: code,
            flightId: flightId,
            orderId: orderId
        }
    }), compareTable = _d.data, loading = _d.loading, error = _d.error;
    var css = useTheme('CompareFares').CompareFares;
    if (compareTable) {
        isImagesPresentInResponse =
            !!compareTable.FareGroupsCompareTable && !!compareTable.FareGroupsCompareTable.header[0].image;
    }
    var _e = __read(React.useState(CompareState.CompareFares), 2), compareState = _e[0], onCompareStateChange = _e[1];
    if (!loading && !error) {
        var fareGroupsCount = compareTable.FareGroupsCompareTable.header.length;
        compareFaresSlidesCount = fareGroupsCount < 5 ? fareGroupsCount : 5;
        fullCompareFaresSlidesCount = fareGroupsCount < 3 ? fareGroupsCount : 3;
    }
    var isFullFareConditions = compareState === CompareState.FullFareConditions;
    var t = useTranslation('CompareFares').t;
    var sliders = [];
    var handleCompareStateChange = function (e) {
        var _a;
        e.preventDefault();
        sliders.forEach(function (slider) { return slider.slickGoTo(0); });
        (_a = headerRef === null || headerRef === void 0 ? void 0 : headerRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
        onCompareStateChange(isFullFareConditions ? CompareState.CompareFares : CompareState.FullFareConditions);
    };
    var next = function () {
        sliders.forEach(function (slider) { return slider.slickNext(); });
    };
    var prev = function () {
        sliders.forEach(function (slider) { return slider.slickPrev(); });
    };
    var NextArrow = function (_a) {
        var currentSlide = _a.currentSlide, slideCount = _a.slideCount, props = __rest(_a, ["currentSlide", "slideCount"]);
        return (React.createElement("div", __assign({ className: cn(css.chevron, css.chevron_next) }, props, { onClick: next }), chevronIcon));
    };
    var PrevArrow = function (props) { return (React.createElement("div", __assign({ className: cn(css.chevron, css.chevron_next) }, props, { onClick: prev }), chevronIcon)); };
    var sliderSettings = {
        arrows: false,
        speed: 250,
        swipe: false,
        slidesToShow: isFullFareConditions ? fullCompareFaresSlidesCount : compareFaresSlidesCount,
        infinite: false,
        responsive: [
            {
                breakpoint: TABLET_MIN_WIDTH,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    var contentTableRef = React.useRef();
    var selectedFareRef = React.useRef();
    var topSliderSettings = __assign(__assign({}, sliderSettings), { arrows: true, prevArrow: React.createElement(PrevArrow, null), nextArrow: React.createElement(NextArrow, null) });
    setTimeout(function () {
        var _a;
        var contentTableHeight = (_a = contentTableRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight;
        var slickList = document.querySelector('.slick-list');
        if (contentTableHeight && selectedFareRef.current && slickList.clientHeight) {
            slickList.style.paddingBottom = "".concat(contentTableHeight, "px");
            slickList.style.marginBottom = "-".concat(contentTableHeight, "px");
            selectedFareRef.current.style.height = "".concat(contentTableHeight, "px");
        }
    }, 0);
    return (React.createElement(Paper, { classes: { root: css.compareFares } },
        React.createElement(MediaQuery, { minWidth: "tablet" },
            React.createElement(ModalHeading, { heading: t('Compare fare families') })),
        React.createElement("div", { ref: headerRef, className: css.content },
            loading && (React.createElement("div", { className: css.loaderWrp },
                React.createElement(CircularProgress, { classes: { colorPrimary: css.loader } }))),
            error && (React.createElement("div", null,
                React.createElement("h6", { className: css.errorTitle }, t('Internal error')),
                React.createElement("p", { className: css.errorText }, error.message),
                React.createElement(Button, { size: "small", color: "primary" }, t('Label')))),
            !loading && !error && (React.createElement(React.Fragment, null,
                React.createElement("div", { id: "content__table", ref: contentTableRef },
                    React.createElement("div", { className: css.row },
                        React.createElement("div", { className: cn(css.title, css.headerTitle) },
                            React.createElement("div", { className: css.arrows })),
                        React.createElement("div", { className: cn(css.row, css.slider, (_b = {},
                                _b[css.slider_withoutImages] = !isImagesPresentInResponse,
                                _b)) },
                            React.createElement("div", { className: css.slider },
                                React.createElement(Slider, __assign({ ref: function (ref) { return sliders.push(ref); } }, topSliderSettings), compareTable.FareGroupsCompareTable.header.map(function (fare) {
                                    var _a;
                                    return (React.createElement("div", null,
                                        React.createElement("div", { className: css.headingWrp },
                                            fare.title === selectedFare && (React.createElement("div", { className: css.selectedFare, ref: selectedFareRef })),
                                            React.createElement("div", { className: cn(css.fareHeadingItem, (_a = {},
                                                    _a[css.fareHeadingItem_isKey] = fare.serviceClass === 'Business',
                                                    _a)) },
                                                React.createElement("span", null, t(fare.title)),
                                                React.createElement("span", { className: css.fareHeadingItem__serviceClass }, t(fare.serviceClass))),
                                            !isFullFareConditions && (React.createElement("img", { className: css.image, src: fare.image, alt: t(fare.title) })))));
                                }))))),
                    React.createElement("div", { className: css.col }, compareTable.FareGroupsCompareTable.groups.map(function (group) {
                        return (React.createElement("div", { className: css.col },
                            React.createElement("div", { className: css.groupTitle }, t(group.title)),
                            group.rows.map(function (row) {
                                var _a;
                                var _b;
                                return (React.createElement("div", { className: cn(css.tableRow, (_a = {},
                                        _a[css.tableRow_fullDescr] = isFullFareConditions,
                                        _a)) },
                                    React.createElement("div", { className: css.title }, row.title),
                                    React.createElement("div", { className: css.slider },
                                        React.createElement(Slider, __assign({ ref: function (ref) { return sliders.push(ref); } }, sliderSettings), (_b = row.cells) === null || _b === void 0 ? void 0 : _b.map(function (cell) { return (React.createElement(Cell, { cell: cell, isFullFareCondition: isFullFareConditions })); })))));
                            })));
                    }))),
                isFullFareConditions && notes && (React.createElement("div", null,
                    React.createElement("h6", { className: css.notesHeading }, t(notes.heading)),
                    React.createElement("ul", { className: css.notesList }, notes.notesList.map(function (note, index) { return (React.createElement("li", { key: index, className: css.notesListItem },
                        index + 1,
                        ". ",
                        t(note))); })))),
                React.createElement(Link, { action: handleCompareStateChange, className: css.link },
                    !isFullFareConditions && (React.createElement("span", null,
                        t('View full fare conditions'),
                        React.createElement("span", { className: css.linkIcon }, chevronIcon))),
                    isFullFareConditions && (React.createElement("span", null,
                        React.createElement("span", { className: cn(css.linkIcon, (_c = {}, _c[css.linkIcon_return] = isFullFareConditions, _c)) }, chevronIcon),
                        t('Go back')))))))));
};
export default CompareFares;
