import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { coupons } from '../../__fakeData__';
var Coupons = function (props) {
    var theme = useTheme('ManualActions').ManualActions;
    var t = useTranslation('ManualActions').t;
    var onChangeCheckBox = function (e, coupon) {
        var updatedCoupons = [];
        if (e.target.checked) {
            updatedCoupons = __spreadArray(__spreadArray([], __read(props.selectedCoupons), false), [coupon], false);
        }
        else {
            updatedCoupons = props.selectedCoupons.filter(function (selectedCoupon) {
                return selectedCoupon.coupon !== coupon.coupon;
            });
        }
        props.setSelectedCoupons(updatedCoupons);
    };
    var renderContent = useCallback(function () {
        return coupons.map(function (coupon) {
            var _a;
            var isSelectedCoupon = props.selectedCoupons.some(function (selectedCoupon) { return selectedCoupon.coupon === coupon.coupon; });
            return (React.createElement("tr", { key: coupon.coupon, className: cn(theme.table_bg_gray, (_a = {}, _a[theme.table_bg_blue] = isSelectedCoupon, _a)) },
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell }, coupon.coupon)),
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell }, coupon.date)),
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell }, coupon.flight)),
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell },
                        React.createElement("a", { href: "#" }, coupon.fareBasis))),
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell }, coupon.nvb)),
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell }, coupon.nva)),
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell }, "".concat(coupon.baggage.count, " ").concat(coupon.baggage.unit))),
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell }, coupon.status)),
                React.createElement("td", null,
                    React.createElement("div", { className: theme.table__cell },
                        React.createElement("label", { className: theme.formCheckbox },
                            React.createElement("input", { type: "checkbox", className: theme.formCheckbox__input, onChange: function (e) {
                                    onChangeCheckBox(e, coupon);
                                } }),
                            React.createElement("span", { className: theme.formCheckbox__mark }))))));
        });
    }, [coupons, props.selectedCoupons]);
    return (React.createElement("table", { className: cn(theme.table, theme.table_align_center, theme.table_border_horizontal) },
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", null,
                    React.createElement("div", { className: theme.table__cell }, t('Coupons'))),
                React.createElement("th", null,
                    React.createElement("div", { className: theme.table__cell }, t('Date'))),
                React.createElement("th", null,
                    React.createElement("div", { className: theme.table__cell }, t('Flight'))),
                React.createElement("th", null,
                    React.createElement("div", { className: theme.table__cell }, t('Fare basis'))),
                React.createElement("th", null,
                    React.createElement("div", { className: theme.table__cell }, t('NVB'))),
                React.createElement("th", null,
                    React.createElement("div", { className: theme.table__cell }, t('NVA'))),
                React.createElement("th", null,
                    React.createElement("div", { className: theme.table__cell }, t('Baggage'))),
                React.createElement("th", null,
                    React.createElement("div", { className: theme.table__cell }, t('Status'))),
                React.createElement("th", null,
                    React.createElement("div", { className: theme.table__cell }, t('Exch'))))),
        React.createElement("tbody", null, renderContent())));
};
export default Coupons;
