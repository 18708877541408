import { __assign } from "tslib";
import { useContext } from 'react';
import * as React from 'react';
import merge from 'lodash.merge';
import { Locale, Mode } from './types';
import { extendI18n, changeLocale } from './utils';
import { setApiUrl } from './graphql-client';
export var defaultConfig = {
    SearchForm: {
        enableComplexRoute: true,
        mobileFormType: 'alternative',
        engineURL: '',
        payByMiles: false
    },
    Engine: {
        searchFormURL: '',
        checkInURL: '',
        mealWarning: '',
        promoLoaders: [],
        checkInEnabled: false,
        showRefundRedirectButton: false,
        calendarDaysCount: 7,
        showAvailableFaresOnly: false
    },
    Account: {
        engineURL: '',
        showUserPhoneInput: true
    },
    global: {
        showIsActualizationResultOk: true,
        allowPromoCodes: false,
        locale: Locale.Russian,
        i18n: {},
        companyInfo: {
            loyaltyProgramName: 'LightJet Club',
            iataCode: 'ZZ',
            exarePrivacyPolicyURL: 'https://google.com',
            exareRulesURL: 'https://google.com',
            loyaltyProgramRegistrationUrl: '#',
            loyaltyProgramAccountUrl: null,
            loyalty: {
                links: [
                    {
                        title: 'LightJet levels',
                        url: 'https://websky.tech'
                    },
                    {
                        title: 'How to spend miles',
                        url: 'https://websky.tech'
                    },
                    {
                        title: 'How to earn miles',
                        url: 'https://websky.tech'
                    },
                    {
                        title: 'FAQ',
                        url: 'https://websky.tech'
                    }
                ]
            },
            multiInsurance: true,
            specialDocumentForInsurance: true
        }
    }
};
export var applyConfig = function (config) {
    var _a, _b, _c;
    if ((_a = config === null || config === void 0 ? void 0 : config.global) === null || _a === void 0 ? void 0 : _a.locale) {
        changeLocale(config.global.locale);
    }
    if ((_b = config === null || config === void 0 ? void 0 : config.global) === null || _b === void 0 ? void 0 : _b.i18n) {
        extendI18n(config.global.i18n);
    }
    if ((_c = config === null || config === void 0 ? void 0 : config.global) === null || _c === void 0 ? void 0 : _c.apiUrl) {
        setApiUrl(config.global.apiUrl);
    }
    var mergedConfig = merge({}, defaultConfig, config);
    window.__webskyEngineConfig = Object.freeze(__assign({}, mergedConfig));
    return mergedConfig;
};
export var ConfigContext = React.createContext(defaultConfig);
export var useConfig = function () { return useContext(ConfigContext); };
export var ModeContext = React.createContext(Mode.Booking);
