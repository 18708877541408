import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import Page from '../Page/Page';
import SearchOrder from '../SearchOrder';
import { useMutation } from '@apollo/react-hooks';
import * as FindOrderQuery from '../../__queries__/FindOrder.graphql';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useToggleable } from '../../hooks';
import PromoLoader from '../../PromoLoader';
import WarningModal from '../../WarningModal/components/WarningModal';
var IndexPage = function (props) {
    var history = useHistory();
    var _a = useToggleable(false), isLoading = _a.isOpen, setLoading = _a.setOpen;
    var _b = __read(useState(null), 2), requestErrors = _b[0], setRequestErrors = _b[1];
    var t = useTranslation().t;
    var _c = __read(useMutation(FindOrderQuery.FindOrder), 2), findOrder = _c[0], loading = _c[1].loading;
    var getGraphQLErrorsMessages = function (graphQLErrors) {
        var findOrderErrors = [];
        graphQLErrors === null || graphQLErrors === void 0 ? void 0 : graphQLErrors.forEach(function (error) {
            findOrderErrors.push(error === null || error === void 0 ? void 0 : error.message);
        });
        return findOrderErrors;
    };
    var findByLocator = function (formState) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!formState) {
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, findOrder({
                            variables: {
                                params: {
                                    id: formState['searchString']
                                }
                            }
                        })];
                case 2:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (!errors && (data === null || data === void 0 ? void 0 : data.FindOrder)) {
                        history.push("/booking/".concat(data.FindOrder.id, "/passengers"));
                    }
                    else {
                        console.error('Error!', errors);
                        setRequestErrors(getGraphQLErrorsMessages(errors));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    console.error('Error!', error_1);
                    setRequestErrors(getGraphQLErrorsMessages(error_1 === null || error_1 === void 0 ? void 0 : error_1.graphQLErrors));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCloseWarningModal = useCallback(function () {
        setRequestErrors(null);
    }, []);
    useEffect(function () {
        setLoading(loading);
    }, [loading]);
    return (React.createElement(React.Fragment, null, isLoading ? (React.createElement(PromoLoader, null)) : (React.createElement(React.Fragment, null,
        React.createElement(WarningModal, { title: t('Ops...'), content: t('Unfortunately, something went wrong. Please try again or contact with customer support service'), isOpen: !!requestErrors, errorMessage: requestErrors, buttonText: t('Close'), onButtonClick: handleCloseWarningModal, onClose: handleCloseWarningModal }),
        React.createElement(Page, { onSubmit: props.onSubmit, isOpen: true },
            React.createElement(SearchOrder, { onStartSearch: findByLocator }))))));
};
export default IndexPage;
