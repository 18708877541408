export var ActualizationStatus;
(function (ActualizationStatus) {
    ActualizationStatus["Success"] = "Success";
    ActualizationStatus["PriceIncreased"] = "PriceIncreased";
    ActualizationStatus["NotAvailable"] = "NotAvailable";
})(ActualizationStatus || (ActualizationStatus = {}));
/** AeroexpressClassEnum */
export var AeroexpressClass;
(function (AeroexpressClass) {
    AeroexpressClass["standart"] = "standart";
    AeroexpressClass["child"] = "child";
    AeroexpressClass["buisness"] = "buisness";
    AeroexpressClass["roundTrip"] = "roundTrip";
    AeroexpressClass["full"] = "full";
    AeroexpressClass["full_advance"] = "full_advance";
    AeroexpressClass["family"] = "family";
    AeroexpressClass["business"] = "business";
    AeroexpressClass["roundtrip"] = "roundtrip";
    AeroexpressClass["family_roundtrip"] = "family_roundtrip";
    AeroexpressClass["couple"] = "couple";
    AeroexpressClass["couple_roundtrip"] = "couple_roundtrip";
})(AeroexpressClass || (AeroexpressClass = {}));
/** Aeroexpress Direction */
export var AeroexpressDirection;
(function (AeroexpressDirection) {
    AeroexpressDirection["ToAirport"] = "ToAirport";
    AeroexpressDirection["FromAirport"] = "FromAirport";
    AeroexpressDirection["Roundtrip"] = "Roundtrip";
})(AeroexpressDirection || (AeroexpressDirection = {}));
export var AircraftInfoParameterType;
(function (AircraftInfoParameterType) {
    AircraftInfoParameterType["AircraftType"] = "AircraftType";
    AircraftInfoParameterType["Length"] = "Length";
    AircraftInfoParameterType["Wingspan"] = "Wingspan";
    AircraftInfoParameterType["CabinWidth"] = "CabinWidth";
    AircraftInfoParameterType["PassengerCapacity"] = "PassengerCapacity";
    AircraftInfoParameterType["MaxWeight"] = "MaxWeight";
    AircraftInfoParameterType["CruisingSpeed"] = "CruisingSpeed";
    AircraftInfoParameterType["MaxFlightAltitude"] = "MaxFlightAltitude";
    AircraftInfoParameterType["MaxFlightRange"] = "MaxFlightRange";
})(AircraftInfoParameterType || (AircraftInfoParameterType = {}));
export var AmenityType;
(function (AmenityType) {
    AmenityType["FreeMeal"] = "FreeMeal";
    AmenityType["FreeSnack"] = "FreeSnack";
    AmenityType["Alcohol"] = "Alcohol";
    AmenityType["MainsPower"] = "MainsPower";
    AmenityType["USBPower"] = "USBPower";
    AmenityType["WiFi"] = "WiFi";
    AmenityType["MediaScreen"] = "MediaScreen";
    AmenityType["Headphones"] = "Headphones";
    AmenityType["ExtraLegroom"] = "ExtraLegroom";
})(AmenityType || (AmenityType = {}));
/** Paid service coupon status */
export var AviaCouponStatus;
(function (AviaCouponStatus) {
    AviaCouponStatus["Open"] = "Open";
    AviaCouponStatus["Used"] = "Used";
    AviaCouponStatus["Void"] = "Void";
    AviaCouponStatus["CheckIn"] = "CheckIn";
    AviaCouponStatus["Printed"] = "Printed";
    AviaCouponStatus["Refunded"] = "Refunded";
    AviaCouponStatus["Exchanged"] = "Exchanged";
    AviaCouponStatus["Registered"] = "Registered";
    AviaCouponStatus["Landed"] = "Landed";
    AviaCouponStatus["Stoped"] = "Stoped";
    AviaCouponStatus["PaperDocument"] = "PaperDocument";
    AviaCouponStatus["Unavailable"] = "Unavailable";
    AviaCouponStatus["ExchangedToPaper"] = "ExchangedToPaper";
    AviaCouponStatus["Closed"] = "Closed";
    AviaCouponStatus["AirportControl"] = "AirportControl";
})(AviaCouponStatus || (AviaCouponStatus = {}));
/** Paid service coupon type */
export var AviaCouponType;
(function (AviaCouponType) {
    AviaCouponType["EMD"] = "EMD";
    AviaCouponType["ET"] = "ET";
})(AviaCouponType || (AviaCouponType = {}));
/** Avia passenger type */
export var AviaPassengerType;
(function (AviaPassengerType) {
    AviaPassengerType["ADT"] = "ADT";
    AviaPassengerType["CLD"] = "CLD";
    AviaPassengerType["INF"] = "INF";
    AviaPassengerType["INS"] = "INS";
})(AviaPassengerType || (AviaPassengerType = {}));
export var BaggageType;
(function (BaggageType) {
    BaggageType["CarryOn"] = "CarryOn";
    BaggageType["CheckedBaggage"] = "CheckedBaggage";
    BaggageType["SportingEquipment"] = "SportingEquipment";
    BaggageType["PetInCabin"] = "PetInCabin";
    BaggageType["PetInHold"] = "PetInHold";
    BaggageType["BaggageExcess"] = "BaggageExcess";
    BaggageType["HandLuggageExcess"] = "HandLuggageExcess";
    BaggageType["MusicalInstrument"] = "MusicalInstrument";
    BaggageType["FishingEquipment"] = "FishingEquipment";
    BaggageType["SurfingEquipment"] = "SurfingEquipment";
    BaggageType["Ski"] = "Ski";
    BaggageType["Bike"] = "Bike";
    BaggageType["PetInHandCarrier"] = "PetInHandCarrier";
})(BaggageType || (BaggageType = {}));
/** Booking Status */
export var BookingStatusEnum;
(function (BookingStatusEnum) {
    BookingStatusEnum["PendingConfirmation"] = "PendingConfirmation";
    BookingStatusEnum["Waiting"] = "Waiting";
    BookingStatusEnum["Booked"] = "Booked";
    BookingStatusEnum["Cancelled"] = "Cancelled";
    BookingStatusEnum["Ticket"] = "Ticket";
    BookingStatusEnum["Needconfirm"] = "Needconfirm";
    BookingStatusEnum["Reject"] = "Reject";
})(BookingStatusEnum || (BookingStatusEnum = {}));
export var CheckinAccessType;
(function (CheckinAccessType) {
    CheckinAccessType["PNRLocator"] = "PNRLocator";
    CheckinAccessType["TicketNumber"] = "TicketNumber";
})(CheckinAccessType || (CheckinAccessType = {}));
export var CheckinDocumentType;
(function (CheckinDocumentType) {
    CheckinDocumentType["BoardingPass"] = "BoardingPass";
    CheckinDocumentType["BoardingPassCustom"] = "BoardingPassCustom";
    CheckinDocumentType["QrCode"] = "QrCode";
    CheckinDocumentType["AppleWallet"] = "AppleWallet";
    CheckinDocumentType["ItineraryReceipt"] = "ItineraryReceipt";
    CheckinDocumentType["AeroexpressTicket"] = "AeroexpressTicket";
})(CheckinDocumentType || (CheckinDocumentType = {}));
export var CheckinOrderStatus;
(function (CheckinOrderStatus) {
    CheckinOrderStatus["Expired"] = "Expired";
    CheckinOrderStatus["New"] = "New";
    CheckinOrderStatus["Started"] = "Started";
    CheckinOrderStatus["Confirmed"] = "Confirmed";
})(CheckinOrderStatus || (CheckinOrderStatus = {}));
export var DeviceType;
(function (DeviceType) {
    DeviceType["ios"] = "ios";
    DeviceType["android"] = "android";
    DeviceType["browser"] = "browser";
})(DeviceType || (DeviceType = {}));
/** Doc Types */
export var DocTypes;
(function (DocTypes) {
    DocTypes["Passport"] = "Passport";
    DocTypes["NationalPassport"] = "NationalPassport";
    DocTypes["InternationalPassportRU"] = "InternationalPassportRU";
    DocTypes["BirthRegDocument"] = "BirthRegDocument";
    DocTypes["MilitaryIDCard"] = "MilitaryIDCard";
    DocTypes["ResidencePermit"] = "ResidencePermit";
    DocTypes["DocOfPassportLusing"] = "DocOfPassportLusing";
    DocTypes["CertificateOfConvictedVacation"] = "CertificateOfConvictedVacation";
    DocTypes["ReleaseCertificate"] = "ReleaseCertificate";
    DocTypes["ServicePassport"] = "ServicePassport";
    DocTypes["FederalParliamentDeputy"] = "FederalParliamentDeputy";
    DocTypes["DiplomaticPassport"] = "DiplomaticPassport";
    DocTypes["InternationalPassport"] = "InternationalPassport";
    DocTypes["SeamanDocument"] = "SeamanDocument";
})(DocTypes || (DocTypes = {}));
export var ExarePriceDetailsPriceElementType;
(function (ExarePriceDetailsPriceElementType) {
    ExarePriceDetailsPriceElementType["chargeForUsedPart"] = "chargeForUsedPart";
    ExarePriceDetailsPriceElementType["agentChargeForExare"] = "agentChargeForExare";
    ExarePriceDetailsPriceElementType["taxesAdditionalCharge"] = "taxesAdditionalCharge";
    ExarePriceDetailsPriceElementType["fareAdditionalCharge"] = "fareAdditionalCharge";
    ExarePriceDetailsPriceElementType["chargeForTariffConditions"] = "chargeForTariffConditions";
    ExarePriceDetailsPriceElementType["airlineChargeForReturn"] = "airlineChargeForReturn";
    ExarePriceDetailsPriceElementType["airlineChargeForCancellation"] = "airlineChargeForCancellation";
    ExarePriceDetailsPriceElementType["taxesRetention"] = "taxesRetention";
    ExarePriceDetailsPriceElementType["agencyCharge"] = "agencyCharge";
    ExarePriceDetailsPriceElementType["subagencyCharge"] = "subagencyCharge";
    ExarePriceDetailsPriceElementType["paymentCharge"] = "paymentCharge";
    ExarePriceDetailsPriceElementType["agencyRounding"] = "agencyRounding";
    ExarePriceDetailsPriceElementType["chargeServiceAlphaInsurance"] = "chargeServiceAlphaInsurance";
    ExarePriceDetailsPriceElementType["chargeServiceSogazInsurance"] = "chargeServiceSogazInsurance";
    ExarePriceDetailsPriceElementType["chargeServiceErvInsurance"] = "chargeServiceErvInsurance";
    ExarePriceDetailsPriceElementType["chargeServiceAeroexpress"] = "chargeServiceAeroexpress";
    ExarePriceDetailsPriceElementType["chargeServiceServicePack"] = "chargeServiceServicePack";
    ExarePriceDetailsPriceElementType["chargeServiceSirenaInsurance"] = "chargeServiceSirenaInsurance";
    ExarePriceDetailsPriceElementType["additionalTaxesCharge"] = "additionalTaxesCharge";
    ExarePriceDetailsPriceElementType["alreadyRefundedMoney"] = "alreadyRefundedMoney";
    ExarePriceDetailsPriceElementType["fare"] = "fare";
    ExarePriceDetailsPriceElementType["sum"] = "sum";
    ExarePriceDetailsPriceElementType["gdsServiceFare"] = "gdsServiceFare";
    ExarePriceDetailsPriceElementType["other"] = "other";
})(ExarePriceDetailsPriceElementType || (ExarePriceDetailsPriceElementType = {}));
export var ExareReason;
(function (ExareReason) {
    ExareReason["FlightCancellationOrDelay"] = "FlightCancellationOrDelay";
    ExareReason["Rerouting"] = "Rerouting";
    ExareReason["FlightOperationOutOfSchedule"] = "FlightOperationOutOfSchedule";
    ExareReason["SeatUnavailability"] = "SeatUnavailability";
    ExareReason["EnduringSecurityScreening"] = "EnduringSecurityScreening";
    ExareReason["FailedEnsureConnectionOfFlights"] = "FailedEnsureConnectionOfFlights";
    ExareReason["Illness"] = "Illness";
    ExareReason["FailedToProvideServices"] = "FailedToProvideServices";
    ExareReason["FailedToIssueTicketAppropriately"] = "FailedToIssueTicketAppropriately";
    ExareReason["FlightNumberChange"] = "FlightNumberChange";
    ExareReason["FlightRetimed"] = "FlightRetimed";
    ExareReason["FlightCancelled"] = "FlightCancelled";
    ExareReason["PermanentWithdrawalOfService"] = "PermanentWithdrawalOfService";
    ExareReason["LaborDispute"] = "LaborDispute";
    ExareReason["Bereavement"] = "Bereavement";
    ExareReason["UnableToObtainNecessaryDocumentation"] = "UnableToObtainNecessaryDocumentation";
    ExareReason["Strike"] = "Strike";
    ExareReason["Weather"] = "Weather";
    ExareReason["NaturalDisaster"] = "NaturalDisaster";
    ExareReason["UnacceptableReaccommodation"] = "UnacceptableReaccommodation";
    ExareReason["OtherCases"] = "OtherCases";
})(ExareReason || (ExareReason = {}));
export var ExareReasonDocs;
(function (ExareReasonDocs) {
    ExareReasonDocs["VisaDenial"] = "VisaDenial";
    ExareReasonDocs["MedicalCertificate"] = "MedicalCertificate";
    ExareReasonDocs["DeathCertificate"] = "DeathCertificate";
    ExareReasonDocs["FlightProblems"] = "FlightProblems";
    ExareReasonDocs["Other"] = "Other";
})(ExareReasonDocs || (ExareReasonDocs = {}));
export var ExareSeatReleaseMode;
(function (ExareSeatReleaseMode) {
    ExareSeatReleaseMode["Off"] = "Off";
    ExareSeatReleaseMode["AnyManual"] = "AnyManual";
    ExareSeatReleaseMode["CompelledManual"] = "CompelledManual";
    ExareSeatReleaseMode["AnyCompelled"] = "AnyCompelled";
})(ExareSeatReleaseMode || (ExareSeatReleaseMode = {}));
export var ExareStatus;
(function (ExareStatus) {
    ExareStatus["InProcess"] = "InProcess";
    ExareStatus["NeedConfirm"] = "NeedConfirm";
    ExareStatus["AwaitingPayment"] = "AwaitingPayment";
    ExareStatus["Finished"] = "Finished";
    ExareStatus["Rejected"] = "Rejected";
    ExareStatus["Refused"] = "Refused";
    ExareStatus["TimelimitExpired"] = "TimelimitExpired";
})(ExareStatus || (ExareStatus = {}));
/** Fare Family Options Type */
export var FareFamilyOptionsType;
(function (FareFamilyOptionsType) {
    FareFamilyOptionsType["CarryOn"] = "CarryOn";
    FareFamilyOptionsType["Baggage"] = "Baggage";
    FareFamilyOptionsType["SeatRegistration"] = "SeatRegistration";
    FareFamilyOptionsType["OnlineRegistration"] = "OnlineRegistration";
    FareFamilyOptionsType["VipService"] = "VipService";
    FareFamilyOptionsType["Miles"] = "Miles";
    FareFamilyOptionsType["Meal"] = "Meal";
    FareFamilyOptionsType["Refundable"] = "Refundable";
    FareFamilyOptionsType["Exchangeable"] = "Exchangeable";
    FareFamilyOptionsType["SportsEquipment"] = "SportsEquipment";
})(FareFamilyOptionsType || (FareFamilyOptionsType = {}));
/** Fare feature payment type */
export var FareFeaturePaymentType;
(function (FareFeaturePaymentType) {
    FareFeaturePaymentType["Pay"] = "Pay";
    FareFeaturePaymentType["Free"] = "Free";
    FareFeaturePaymentType["NotAvailable"] = "NotAvailable";
})(FareFeaturePaymentType || (FareFeaturePaymentType = {}));
export var FfpAccountFieldEnum;
(function (FfpAccountFieldEnum) {
    FfpAccountFieldEnum["LatinFirstName"] = "LatinFirstName";
    FfpAccountFieldEnum["LatinLastName"] = "LatinLastName";
    FfpAccountFieldEnum["LatinMiddleName"] = "LatinMiddleName";
    FfpAccountFieldEnum["FirstName"] = "FirstName";
    FfpAccountFieldEnum["LastName"] = "LastName";
    FfpAccountFieldEnum["MiddleName"] = "MiddleName";
    FfpAccountFieldEnum["Phone"] = "Phone";
    FfpAccountFieldEnum["Email"] = "Email";
    FfpAccountFieldEnum["DocNumber"] = "DocNumber";
    FfpAccountFieldEnum["DocExpiryDate"] = "DocExpiryDate";
    FfpAccountFieldEnum["BirthDate"] = "BirthDate";
    FfpAccountFieldEnum["DocType"] = "DocType";
    FfpAccountFieldEnum["Gender"] = "Gender";
    FfpAccountFieldEnum["DocIssued"] = "DocIssued";
    FfpAccountFieldEnum["DocIssuedBy"] = "DocIssuedBy";
    FfpAccountFieldEnum["City"] = "City";
    FfpAccountFieldEnum["Country"] = "Country";
})(FfpAccountFieldEnum || (FfpAccountFieldEnum = {}));
/** Avia search parameters */
export var FlightClass;
(function (FlightClass) {
    FlightClass["Economy"] = "Economy";
    FlightClass["Business"] = "Business";
    FlightClass["First"] = "First";
    FlightClass["Miles"] = "Miles";
})(FlightClass || (FlightClass = {}));
export var FlightSegmentStatus;
(function (FlightSegmentStatus) {
    FlightSegmentStatus["Confirmed"] = "Confirmed";
    FlightSegmentStatus["Canceled"] = "Canceled";
    FlightSegmentStatus["Requested"] = "Requested";
    FlightSegmentStatus["WaitList"] = "WaitList";
})(FlightSegmentStatus || (FlightSegmentStatus = {}));
export var GdsServiceProductStatus;
(function (GdsServiceProductStatus) {
    GdsServiceProductStatus["New"] = "New";
    GdsServiceProductStatus["Booked"] = "Booked";
    GdsServiceProductStatus["Canceled"] = "Canceled";
    GdsServiceProductStatus["Ticketed"] = "Ticketed";
    GdsServiceProductStatus["Rejected"] = "Rejected";
    GdsServiceProductStatus["Requested"] = "Requested";
    GdsServiceProductStatus["Problematic"] = "Problematic";
})(GdsServiceProductStatus || (GdsServiceProductStatus = {}));
export var GdsServiceVipSubType;
(function (GdsServiceVipSubType) {
    GdsServiceVipSubType["PriorityBoarding"] = "PriorityBoarding";
    GdsServiceVipSubType["PriceFreeze"] = "PriceFreeze";
    GdsServiceVipSubType["BusinessLounge"] = "BusinessLounge";
    GdsServiceVipSubType["UnknownSubtype"] = "UnknownSubtype";
    GdsServiceVipSubType["CapsuleHotel"] = "CapsuleHotel";
    GdsServiceVipSubType["ExtraSeat"] = "ExtraSeat";
    GdsServiceVipSubType["BaggageDelivery"] = "BaggageDelivery";
})(GdsServiceVipSubType || (GdsServiceVipSubType = {}));
/** Human gender */
export var Gender;
(function (Gender) {
    Gender["Male"] = "Male";
    Gender["Female"] = "Female";
    Gender["Other"] = "Other";
})(Gender || (Gender = {}));
/** Info message type */
export var InfoMessageTypeEnum;
(function (InfoMessageTypeEnum) {
    InfoMessageTypeEnum["Promo"] = "Promo";
    InfoMessageTypeEnum["Info"] = "Info";
    InfoMessageTypeEnum["Warning"] = "Warning";
})(InfoMessageTypeEnum || (InfoMessageTypeEnum = {}));
/** Inquiry type */
export var InquiryType;
(function (InquiryType) {
    InquiryType["FlightConfirmation"] = "FlightConfirmation";
    InquiryType["TicketPrice"] = "TicketPrice";
    InquiryType["FlightDelayCancel"] = "FlightDelayCancel";
    InquiryType["FlightPriceInRf"] = "FlightPriceInRf";
    InquiryType["PriceOnTerritoryOfRf"] = "PriceOnTerritoryOfRf";
    InquiryType["GeneralRouteLength"] = "GeneralRouteLength";
})(InquiryType || (InquiryType = {}));
/** InsuranceTypeEnum */
export var InsuranceType;
(function (InsuranceType) {
    InsuranceType["AlphaInsurance"] = "AlphaInsurance";
    InsuranceType["SirenaInsurance"] = "SirenaInsurance";
})(InsuranceType || (InsuranceType = {}));
/** Additional group type to add in location suggestions response */
export var LocationAdditionalGroupType;
(function (LocationAdditionalGroupType) {
    LocationAdditionalGroupType["AdditionalCities"] = "AdditionalCities";
    LocationAdditionalGroupType["Nearest"] = "Nearest";
    LocationAdditionalGroupType["Popular"] = "Popular";
})(LocationAdditionalGroupType || (LocationAdditionalGroupType = {}));
/** Locations group type */
export var LocationGroupType;
(function (LocationGroupType) {
    LocationGroupType["AdditionalCities"] = "AdditionalCities";
    LocationGroupType["Nearest"] = "Nearest";
    LocationGroupType["Popular"] = "Popular";
    LocationGroupType["Country"] = "Country";
})(LocationGroupType || (LocationGroupType = {}));
/** Location match type */
export var LocationMatchType;
(function (LocationMatchType) {
    LocationMatchType["Default"] = "Default";
    LocationMatchType["City"] = "City";
    LocationMatchType["Country"] = "Country";
    LocationMatchType["Airport"] = "Airport";
    LocationMatchType["IataCode"] = "IataCode";
    LocationMatchType["AlternateName"] = "AlternateName";
    LocationMatchType["ProbableName"] = "ProbableName";
})(LocationMatchType || (LocationMatchType = {}));
/** Login type */
export var LoginType;
(function (LoginType) {
    LoginType["Phone"] = "Phone";
    LoginType["Email"] = "Email";
    LoginType["Facebook"] = "Facebook";
    LoginType["Google"] = "Google";
    LoginType["Vkontakte"] = "Vkontakte";
    LoginType["AppleID"] = "AppleID";
    LoginType["FFP"] = "FFP";
    LoginType["Host"] = "Host";
})(LoginType || (LoginType = {}));
/** NemoAirportServices product category */
export var NasProductCategory;
(function (NasProductCategory) {
    NasProductCategory["lounge"] = "lounge";
    NasProductCategory["fast_track"] = "fast_track";
    NasProductCategory["vip"] = "vip";
})(NasProductCategory || (NasProductCategory = {}));
/** NemoAirportServices product direction */
export var NasProductDirection;
(function (NasProductDirection) {
    NasProductDirection["Departure"] = "Departure";
    NasProductDirection["Arrival"] = "Arrival";
})(NasProductDirection || (NasProductDirection = {}));
/** NemoAirportServices product type */
export var NasProductType;
(function (NasProductType) {
    NasProductType["Lounge"] = "Lounge";
})(NasProductType || (NasProductType = {}));
/** Oauth service type */
export var OauthServiceType;
(function (OauthServiceType) {
    OauthServiceType["Facebook"] = "Facebook";
    OauthServiceType["Google"] = "Google";
    OauthServiceType["Vkontakte"] = "Vkontakte";
    OauthServiceType["AppleID"] = "AppleID";
})(OauthServiceType || (OauthServiceType = {}));
export var OrderAdditionalServiceGdsServiceServiceType;
(function (OrderAdditionalServiceGdsServiceServiceType) {
    OrderAdditionalServiceGdsServiceServiceType["Baggage"] = "Baggage";
    OrderAdditionalServiceGdsServiceServiceType["Meal"] = "Meal";
    OrderAdditionalServiceGdsServiceServiceType["Seat"] = "Seat";
    OrderAdditionalServiceGdsServiceServiceType["Vip"] = "Vip";
    OrderAdditionalServiceGdsServiceServiceType["Misc"] = "Misc";
    OrderAdditionalServiceGdsServiceServiceType["BusinessLounge"] = "BusinessLounge";
})(OrderAdditionalServiceGdsServiceServiceType || (OrderAdditionalServiceGdsServiceServiceType = {}));
export var OrderAdditionalServiceType;
(function (OrderAdditionalServiceType) {
    OrderAdditionalServiceType["GdsServices"] = "GdsServices";
})(OrderAdditionalServiceType || (OrderAdditionalServiceType = {}));
export var OrderDocumentType;
(function (OrderDocumentType) {
    OrderDocumentType["ItineraryReceipt"] = "ItineraryReceipt";
    OrderDocumentType["ItineraryReceiptData"] = "ItineraryReceiptData";
    OrderDocumentType["AeroexpressTicket"] = "AeroexpressTicket";
    OrderDocumentType["NASTicket"] = "NASTicket";
    OrderDocumentType["EMD"] = "EMD";
    OrderDocumentType["All"] = "All";
})(OrderDocumentType || (OrderDocumentType = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["New"] = "New";
    OrderStatus["Booked"] = "Booked";
    OrderStatus["Confirmed"] = "Confirmed";
    OrderStatus["Cancelled"] = "Cancelled";
})(OrderStatus || (OrderStatus = {}));
/** Age category type */
export var PassTypeAgeCategoryType;
(function (PassTypeAgeCategoryType) {
    PassTypeAgeCategoryType["Adult"] = "Adult";
    PassTypeAgeCategoryType["Child"] = "Child";
    PassTypeAgeCategoryType["Infant"] = "Infant";
})(PassTypeAgeCategoryType || (PassTypeAgeCategoryType = {}));
export var PaymentProvider;
(function (PaymentProvider) {
    PaymentProvider["MoneyCarePro"] = "MoneyCarePro";
    PaymentProvider["PayLate"] = "PayLate";
})(PaymentProvider || (PaymentProvider = {}));
/** PaymentStatusEnum */
export var PaymentStatusEnum;
(function (PaymentStatusEnum) {
    PaymentStatusEnum["None"] = "None";
    PaymentStatusEnum["Part"] = "Part";
    PaymentStatusEnum["Full"] = "Full";
    PaymentStatusEnum["Penalty"] = "Penalty";
    PaymentStatusEnum["PenaltyPart"] = "PenaltyPart";
    PaymentStatusEnum["PenaltyFull"] = "PenaltyFull";
})(PaymentStatusEnum || (PaymentStatusEnum = {}));
/** PaymentTransactionProductType */
export var PaymentTransactionProductType;
(function (PaymentTransactionProductType) {
    PaymentTransactionProductType["MainService"] = "MainService";
    PaymentTransactionProductType["Aeroexpress"] = "Aeroexpress";
    PaymentTransactionProductType["GdsService"] = "GdsService";
    PaymentTransactionProductType["Insurance"] = "Insurance";
    PaymentTransactionProductType["Charge"] = "Charge";
    PaymentTransactionProductType["Refund"] = "Refund";
})(PaymentTransactionProductType || (PaymentTransactionProductType = {}));
/** SeatComfortEnum */
export var SeatComfort;
(function (SeatComfort) {
    SeatComfort["Standart"] = "Standart";
    SeatComfort["Standard"] = "Standard";
    SeatComfort["Preferred"] = "Preferred";
    SeatComfort["Comfort"] = "Comfort";
})(SeatComfort || (SeatComfort = {}));
/** Seat map permissions */
export var SeatMapPermissions;
(function (SeatMapPermissions) {
    SeatMapPermissions["WithAnimals"] = "WithAnimals";
})(SeatMapPermissions || (SeatMapPermissions = {}));
/** Seat Map Restrictions */
export var SeatMapRestrictions;
(function (SeatMapRestrictions) {
    SeatMapRestrictions["SeriouslyIllPeople"] = "SeriouslyIllPeople";
    SeatMapRestrictions["PassengersOnStretchers"] = "PassengersOnStretchers";
    SeatMapRestrictions["VisuallyOrHearingImpaired"] = "VisuallyOrHearingImpaired";
    SeatMapRestrictions["NeedOxygenEquipment"] = "NeedOxygenEquipment";
    SeatMapRestrictions["PassengersUnder18"] = "PassengersUnder18";
    SeatMapRestrictions["WithAnimals"] = "WithAnimals";
    SeatMapRestrictions["PregnantWomen"] = "PregnantWomen";
    SeatMapRestrictions["DoNotSpeakTheLanguage"] = "DoNotSpeakTheLanguage";
    SeatMapRestrictions["RequiringAccompany"] = "RequiringAccompany";
    SeatMapRestrictions["Infant"] = "Infant";
    SeatMapRestrictions["EscortedChildren"] = "EscortedChildren";
    SeatMapRestrictions["ElderlyPerson"] = "ElderlyPerson";
})(SeatMapRestrictions || (SeatMapRestrictions = {}));
/** Seat type enum */
export var SeatType;
(function (SeatType) {
    SeatType["extraSeat"] = "extraSeat";
    SeatType["common"] = "common";
    SeatType["cabinBaggage"] = "cabinBaggage";
})(SeatType || (SeatType = {}));
/** Setting Value Type Enum */
export var SettingValueTypeEnum;
(function (SettingValueTypeEnum) {
    SettingValueTypeEnum["Integer"] = "Integer";
    SettingValueTypeEnum["String"] = "String";
    SettingValueTypeEnum["Bool"] = "Bool";
})(SettingValueTypeEnum || (SettingValueTypeEnum = {}));
/** SSR status type */
export var SsrStatus;
(function (SsrStatus) {
    SsrStatus["Confirmed"] = "Confirmed";
    SsrStatus["NeedConfirmation"] = "NeedConfirmation";
    SsrStatus["NotConfirmed"] = "NotConfirmed";
    SsrStatus["Canceled"] = "Canceled";
    SsrStatus["Flew"] = "Flew";
    SsrStatus["OnRequest"] = "OnRequest";
    SsrStatus["Rejected"] = "Rejected";
})(SsrStatus || (SsrStatus = {}));
/** Sun Sides */
export var SunSides;
(function (SunSides) {
    SunSides["LeftSide"] = "LeftSide";
    SunSides["RightSide"] = "RightSide";
})(SunSides || (SunSides = {}));
/** Temperature unit */
export var TemperatureUnits;
(function (TemperatureUnits) {
    TemperatureUnits["Celsius"] = "Celsius";
    TemperatureUnits["Fahrenheit"] = "Fahrenheit";
    TemperatureUnits["Kelvin"] = "Kelvin";
})(TemperatureUnits || (TemperatureUnits = {}));
/** Timeline Step */
export var TimelineStep;
(function (TimelineStep) {
    TimelineStep["ToAirport"] = "ToAirport";
    TimelineStep["Airport"] = "Airport";
    TimelineStep["Boarding"] = "Boarding";
    TimelineStep["Flight"] = "Flight";
    TimelineStep["Landing"] = "Landing";
    TimelineStep["FromAirport"] = "FromAirport";
    TimelineStep["ToFromAirport"] = "ToFromAirport";
})(TimelineStep || (TimelineStep = {}));
export var TravellerFieldEnum;
(function (TravellerFieldEnum) {
    TravellerFieldEnum["LastName"] = "LastName";
    TravellerFieldEnum["FirstName"] = "FirstName";
    TravellerFieldEnum["MiddleName"] = "MiddleName";
    TravellerFieldEnum["BirthDate"] = "BirthDate";
    TravellerFieldEnum["Gender"] = "Gender";
    TravellerFieldEnum["Nationality"] = "Nationality";
    TravellerFieldEnum["DocType"] = "DocType";
    TravellerFieldEnum["DocNumber"] = "DocNumber";
    TravellerFieldEnum["DocExpiryDate"] = "DocExpiryDate";
    TravellerFieldEnum["Phone"] = "Phone";
    TravellerFieldEnum["Email"] = "Email";
    TravellerFieldEnum["LoyaltyAirline"] = "LoyaltyAirline";
    TravellerFieldEnum["LoyaltyNumber"] = "LoyaltyNumber";
    TravellerFieldEnum["KzIinDocument"] = "KzIinDocument";
    TravellerFieldEnum["Country"] = "Country";
    TravellerFieldEnum["LinkedTraveller"] = "LinkedTraveller";
    TravellerFieldEnum["PromoCode"] = "PromoCode";
})(TravellerFieldEnum || (TravellerFieldEnum = {}));
export var TravellerVisaFieldEnum;
(function (TravellerVisaFieldEnum) {
    TravellerVisaFieldEnum["ApplicableCountry"] = "ApplicableCountry";
    TravellerVisaFieldEnum["BirthPlace"] = "BirthPlace";
    TravellerVisaFieldEnum["IssueDate"] = "IssueDate";
    TravellerVisaFieldEnum["IssuePlace"] = "IssuePlace";
    TravellerVisaFieldEnum["Number"] = "Number";
})(TravellerVisaFieldEnum || (TravellerVisaFieldEnum = {}));
/** User loyalty card levels */
export var UserLoyaltyCardLevels;
(function (UserLoyaltyCardLevels) {
    UserLoyaltyCardLevels["red"] = "red";
    UserLoyaltyCardLevels["silver"] = "silver";
    UserLoyaltyCardLevels["gold"] = "gold";
    UserLoyaltyCardLevels["platinum"] = "platinum";
    UserLoyaltyCardLevels["nickel"] = "nickel";
    UserLoyaltyCardLevels["base"] = "base";
})(UserLoyaltyCardLevels || (UserLoyaltyCardLevels = {}));
/** Weather types */
export var WeatherTypes;
(function (WeatherTypes) {
    WeatherTypes["Thunderstorm"] = "Thunderstorm";
    WeatherTypes["Drizzle"] = "Drizzle";
    WeatherTypes["Rain"] = "Rain";
    WeatherTypes["Snow"] = "Snow";
    WeatherTypes["Clear"] = "Clear";
    WeatherTypes["Clouds"] = "Clouds";
    WeatherTypes["Unknown"] = "Unknown";
})(WeatherTypes || (WeatherTypes = {}));
export var Weekdays;
(function (Weekdays) {
    Weekdays["Monday"] = "Monday";
    Weekdays["Tuesday"] = "Tuesday";
    Weekdays["Wednesday"] = "Wednesday";
    Weekdays["Thursday"] = "Thursday";
    Weekdays["Friday"] = "Friday";
    Weekdays["Saturday"] = "Saturday";
    Weekdays["Sunday"] = "Sunday";
})(Weekdays || (Weekdays = {}));
/** WidgetType */
export var WidgetType;
(function (WidgetType) {
    WidgetType["EveryTravel"] = "EveryTravel";
})(WidgetType || (WidgetType = {}));
