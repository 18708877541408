import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTheme } from '../../../../theme';
import Pagination from '../Pagination/Pagination';
import { ascSortIcon, descSortIcon } from '../../icons';
import { usePagination } from '../../hooks';
import { sortItems } from '../../utils';
import Event from '../Event/Event';
import { useDeleteEventFromSupplierQueueMutation, useMoveSupplierQueueEventMutation } from '@websky/graphql';
var Events = memo(function (props) {
    var theme = useTheme('EventsQueues').EventsQueues;
    var t = useTranslation('EventsQueues').t;
    var _a = __read(useDeleteEventFromSupplierQueueMutation(), 1), removeEventMutation = _a[0];
    var _b = __read(useMoveSupplierQueueEventMutation(), 1), moveSupplierQueueMutation = _b[0];
    var _c = __read(useState('deadlineDate'), 2), sortField = _c[0], setSortField = _c[1];
    var _d = __read(useState({
        date: 'desc',
        deadlineDate: 'asc'
    }), 2), sorts = _d[0], setSorts = _d[1];
    var sortedEvents = useMemo(function () { return sortItems(props.events, sorts[sortField], sortField); }, [
        props.events,
        sortField,
        sorts
    ]);
    var onChangeQueueHandler = useCallback(function (parameters) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1, error;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, moveSupplierQueueMutation({
                            variables: { parameters: parameters }
                        })];
                case 1:
                    data = (_b.sent()).data;
                    if (data === null || data === void 0 ? void 0 : data.MoveSupplierQueueEvent) {
                        props.onRefetchQueues();
                        return [2 /*return*/, {
                                status: 'success',
                                error: null
                            }];
                    }
                    return [2 /*return*/, {
                            status: 'error',
                            error: null
                        }];
                case 2:
                    e_1 = _b.sent();
                    error = (_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0];
                    return [2 /*return*/, {
                            status: 'error',
                            error: error.message
                        }];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [moveSupplierQueueMutation, props.onRefetchQueues]);
    var removeEvent = function (queueNumber, supplierEventId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve) { return __awaiter(void 0, void 0, void 0, function () {
                    var data, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, removeEventMutation({
                                        variables: {
                                            params: {
                                                queueNumber: queueNumber,
                                                supplierEventId: supplierEventId
                                            }
                                        }
                                    })];
                            case 1:
                                data = (_a.sent()).data;
                                if (data === null || data === void 0 ? void 0 : data.DeleteEventFromSupplierQueue) {
                                    props.onRefetchQueues();
                                    resolve(true);
                                }
                                else {
                                    resolve(false);
                                }
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                console.error(e_2);
                                resolve(false);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); })];
        });
    }); };
    var pageLimit = 25;
    var _e = usePagination(1, sortedEvents, pageLimit), currentIndex = _e.currentIndex, currentEvents = _e.currentItems, handleChange = _e.handleChange, totalPages = _e.totalPages;
    var renderContent = useCallback(function () {
        return currentEvents.map(function (event) { return (React.createElement(Event, { key: event.id, event: event, onChangeQueue: onChangeQueueHandler, removeEvent: removeEvent })); });
    }, [currentEvents, props.onRefetchQueues]);
    var renderBottom = function () {
        if (sortedEvents === null || sortedEvents === void 0 ? void 0 : sortedEvents.length) {
            return null;
        }
        var text = props.isSearchActive
            ? t('No results found matching your criteria')
            : t('There are no any active events in this queue');
        return React.createElement("div", { className: theme.emptyEvents }, text);
    };
    var setSortOrder = function (field) {
        var _a;
        setSortField(field);
        setSorts(__assign(__assign({}, sorts), (_a = {}, _a[field] = sorts[field] === 'asc' ? 'desc' : 'asc', _a)));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.events },
            React.createElement("table", { className: theme.table },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: theme.small },
                            React.createElement("button", { type: "button", className: theme.table__cell, onClick: setSortOrder.bind(null, 'date') },
                                t('Date'),
                                sorts.date === 'asc' ? ascSortIcon : descSortIcon)),
                        React.createElement("th", { className: theme.small },
                            React.createElement("div", { className: theme.table__cell }, t('Category'))),
                        React.createElement("th", { className: theme.small },
                            React.createElement("div", { className: theme.table__cell }, t('PNR'))),
                        React.createElement("th", null,
                            React.createElement("div", { className: theme.table__cell }, t('Comment'))),
                        React.createElement("th", { className: theme.small },
                            React.createElement("button", { className: theme.table__cell, onClick: setSortOrder.bind(null, 'deadlineDate') },
                                t('Deadline'),
                                sorts.deadlineDate === 'asc' ? ascSortIcon : descSortIcon)),
                        React.createElement("th", { className: theme.small }))),
                React.createElement("tbody", null, renderContent())),
            renderBottom(),
            props.isLoading && (React.createElement(LinearProgress, { variant: "indeterminate", classes: {
                    colorPrimary: theme.loaderColorPrimary,
                    barColorPrimary: theme.loaderBarColorPrimary
                } }))),
        (currentEvents === null || currentEvents === void 0 ? void 0 : currentEvents.length) > pageLimit && (React.createElement(Pagination, { currentIndex: currentIndex, totalPages: totalPages, handleChange: handleChange }))));
});
export default Events;
