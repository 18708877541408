import { onError } from 'apollo-link-error';
import { useAuthToken } from './LoginChangePassword/hooks';
export var NOT_AUTHENTICATED_EVENT = 'NOT_AUTHENTICATED_EVENT';
export var NOT_AUTHENTICATED_ERROR_MESSAGE = 'Not authenticated';
export var NOT_AUTHORIZED_TYPE = 'Type:AUTH';
/* IMPORTANT: before change location - check <Account /> && <Engine /> login routes! */
var LOGIN_ROUTE_PATH = '#/login';
var hashPartOfURL = /(#.+)/;
export var leoNotAuthenticatedEventHandler = function () {
    // clear token from localstorage before redirect
    var setToken = useAuthToken().setToken;
    setToken(null);
    window.location.href = window.location.href.replace(hashPartOfURL, LOGIN_ROUTE_PATH);
};
var isNotAuthenticatedError = function (errorObject) {
    var message = errorObject.message;
    return message === NOT_AUTHENTICATED_ERROR_MESSAGE || message.includes(NOT_AUTHORIZED_TYPE);
};
var leoNotAuthenticatedErrorLink = onError(function (_a) {
    var graphQLErrors = _a.graphQLErrors;
    if (graphQLErrors === null || graphQLErrors === void 0 ? void 0 : graphQLErrors.find(isNotAuthenticatedError)) {
        document.dispatchEvent(new CustomEvent(NOT_AUTHENTICATED_EVENT));
    }
});
export default leoNotAuthenticatedErrorLink;
