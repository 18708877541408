import { __read } from "tslib";
import * as React from 'react';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cityIn } from 'lvovich';
import Link from '../../../Link';
import { getDurationString } from '../../../Results/utils';
import Tooltip from '../../../Tooltip';
import Details from './Details/Details';
import TransferInfo from '../../../TransferInfo';
import Modal from '../../../Modal';
import AircraftPopupContent from '../../../AircraftPopup';
import { TemperatureUnits } from '../../../__generated__/globalTypes';
import { weatherIcons } from '../../../MobileSegmentInfo/icons';
import { useTheme } from '../../../theme';
import Amenities from '../../../Amenities';
import Point from './Point/Point';
var leafIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M6.47561 5.43687C3.25652 7.31002 3.4413 10.361 3.55213 11.4899C7.70996 6.55073 13.9292 6.79026 13.9292 6.79026C13.9292 6.79026 5.11326 9.82287 2.52396 15.9041C2.31948 16.3842 3.48341 17.0085 3.74904 16.441C4.5419 14.7497 5.64671 13.4813 5.64671 13.4813C7.27672 14.0896 10.0964 14.8025 12.095 13.3921C14.7497 11.5187 14.4783 7.3656 18.2679 5.34346C19.1531 4.87133 10.8398 2.89717 6.47561 5.43687Z", fill: "#41B74C" })));
var infoIcon = (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.33301 8.00004C1.33301 4.32004 4.31967 1.33337 7.99968 1.33337C11.6797 1.33337 14.6663 4.32004 14.6663 8.00004C14.6663 11.68 11.6797 14.6667 7.99968 14.6667C4.31967 14.6667 1.33301 11.68 1.33301 8.00004ZM8.66634 7.33337V11.3334H7.33301V7.33337H8.66634ZM7.99966 13.3334C5.05967 13.3334 2.66633 10.94 2.66633 8.00004C2.66633 5.06004 5.05967 2.66671 7.99966 2.66671C10.9397 2.66671 13.333 5.06004 13.333 8.00004C13.333 10.94 10.9397 13.3334 7.99966 13.3334ZM8.66634 4.6667V6.00004H7.33301V4.6667H8.66634Z", fill: "#313131" })));
var Segment = memo(function (_a) {
    var _b, _c, _d, _e, _f;
    var segment = _a.segment, transferDuration = _a.transferDuration, nextSegment = _a.nextSegment, showAirportModal = _a.showAirportModal, showFlightInfoTooltip = _a.showFlightInfoTooltip;
    var css = useTheme('DesktopFlightInfo').Segment;
    var t = useTranslation().t;
    var _g = __read(useState(false), 2), isAircraftPopupOpen = _g[0], openAircraftPopup = _g[1];
    var openAircraftPopupHander = useCallback(function (e) {
        e.preventDefault();
        openAircraftPopup(true);
    }, []);
    return (React.createElement("div", null,
        React.createElement("div", { className: css.segment },
            React.createElement("div", { className: css.points },
                React.createElement(Point, { location: segment.departure }),
                React.createElement("div", { className: css.duration },
                    t('DesktopFlightInfo:Duration'),
                    ": ",
                    getDurationString(segment.duration),
                    segment.departure.date !== segment.arrival.date && !((_b = segment.stops) === null || _b === void 0 ? void 0 : _b.length) && (React.createElement("span", { className: css.nextDayArrival }, t('DesktopFlightInfo:Arrival the next day'))),
                    !!((_c = segment.stops) === null || _c === void 0 ? void 0 : _c.length) && (React.createElement("span", { className: css.nextDayArrival },
                        t('DesktopFlightInfo:TechnicalLandingIn', {
                            count: (_d = segment.stops) === null || _d === void 0 ? void 0 : _d.length
                        }),
                        ' ', (_e = segment.stops) === null || _e === void 0 ? void 0 :
                        _e.map(function (stop) { var _a, _b; return cityIn((_b = (_a = stop === null || stop === void 0 ? void 0 : stop.airport) === null || _a === void 0 ? void 0 : _a.city) === null || _b === void 0 ? void 0 : _b.name); }).join(', ')))),
                React.createElement(Point, { location: segment.arrival })),
            React.createElement("div", { className: css.weather },
                segment.departure.weather && (React.createElement("span", { className: css.weather__row },
                    React.createElement("span", { className: css.weather__icon }, weatherIcons[segment.departure.weather.type]
                        ? weatherIcons[segment.departure.weather.type]
                        : null),
                    React.createElement("span", { className: css.weather__temperature },
                        Math.round(segment.departure.weather.temperature) > 0
                            ? '+'
                            : Math.round(segment.departure.weather.temperature) < 0
                                ? '-'
                                : '',
                        Math.abs(Math.round(segment.departure.weather.temperature)),
                        "\u00B0",
                        segment.departure.weather.measurement === TemperatureUnits.Fahrenheit && 'F'))),
                segment.arrival.weather && (React.createElement("span", { className: css.weather__row },
                    React.createElement("span", { className: css.weather__icon }, weatherIcons[segment.arrival.weather.type]
                        ? weatherIcons[segment.arrival.weather.type]
                        : null),
                    React.createElement("span", { className: css.weather__temperature },
                        Math.round(segment.arrival.weather.temperature) > 0
                            ? '+'
                            : Math.round(segment.arrival.weather.temperature) < 0
                                ? '-'
                                : '',
                        Math.abs(Math.round(segment.arrival.weather.temperature)),
                        "\u00B0",
                        segment.arrival.weather.measurement === TemperatureUnits.Fahrenheit && 'F')))),
            React.createElement("div", { className: css.flight },
                React.createElement("div", { className: css.airline },
                    segment.operatingAirline.icon && (React.createElement("span", { className: css.logo },
                        React.createElement("img", { src: segment.operatingAirline.icon, alt: segment.operatingAirline.name }))),
                    segment.operatingAirline.name,
                    " \u2022 ",
                    segment.marketingAirline.iata,
                    '-',
                    segment.flightNumber,
                    ' ',
                    segment.flightInfo && segment.flightInfo.markers.eco && (React.createElement(Tooltip, { title: t('Eco-friendly') },
                        React.createElement("span", { className: css.flight__ecoIcon }, leafIcon))),
                    segment.flightInfo && showFlightInfoTooltip && !!segment.flightInfo.statistics.length && (React.createElement(Tooltip, { title: React.createElement(Details, { items: segment.flightInfo.statistics.map(function (item) { return ({
                                label: item.title,
                                value: item.value
                            }); }) }) },
                        React.createElement("span", { className: css.flight__infoIcon }, infoIcon)))),
                React.createElement("div", null, segment.aircraft && (React.createElement(React.Fragment, null,
                    React.createElement(Link, { className: css.aircraft__name__link, action: openAircraftPopupHander }, segment.aircraft.name),
                    React.createElement("span", { className: css.aircraft__name }, segment.aircraft.name)))),
                React.createElement(Amenities, { amenities: segment.flightInfo ? segment.flightInfo.amenities : [] })),
            ((_f = segment.aircraft) === null || _f === void 0 ? void 0 : _f.icon) && (React.createElement("div", { className: css.plane },
                React.createElement(Link, { action: openAircraftPopupHander },
                    React.createElement("img", { className: css.plane__image, src: segment.aircraft.icon, alt: segment.aircraft.name }),
                    ' ')))),
        transferDuration && nextSegment && (React.createElement(TransferInfo, { className: css.transfer, segment: segment, nextSegment: nextSegment, transferDuration: transferDuration })),
        segment.aircraft && (React.createElement(Modal, { maxWidth: "md", open: isAircraftPopupOpen, onClose: function () { return openAircraftPopup(false); } },
            React.createElement(AircraftPopupContent, { id: segment.aircraft.id })))));
});
export default Segment;
