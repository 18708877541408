import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import Modal from '../../../Modal';
import { SlideBottom } from '../../../index';
import { ResendTicketsStages } from '../../../ResendTicketsForm/types';
import ResendTicketsForm from '../../../ResendTicketsForm';
var SendToEmail = function (_a) {
    var onResendTickets = _a.onResendTickets, changeLoadingStatus = _a.changeLoadingStatus;
    var css = useTheme('RegisteredPassenger').SendToEmail;
    var t = useTranslation('RegisteredPassenger').t;
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var _c = __read(useState(ResendTicketsStages.Default), 2), resendStage = _c[0], setResendStage = _c[1];
    var toggleModal = function () {
        setIsOpen(function (prevState) { return !prevState; });
    };
    var resendTicketsHandler = function (_a) {
        var email = _a.email;
        return __awaiter(void 0, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        changeLoadingStatus && setResendStage(ResendTicketsStages.Loading);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        changeLoadingStatus && setResendStage(ResendTicketsStages.Loading);
                        return [4 /*yield*/, onResendTickets(email)];
                    case 2:
                        _b.sent();
                        setTimeout(function () {
                            toggleModal();
                            changeLoadingStatus && setResendStage(ResendTicketsStages.Default);
                        }, 1500);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        changeLoadingStatus && setResendStage(ResendTicketsStages.Error);
                        setTimeout(function () {
                            changeLoadingStatus && setResendStage(ResendTicketsStages.Default);
                        }, 2500);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.wrapper },
            React.createElement("span", { className: css.text, onClick: toggleModal }, t('Send to email')),
            React.createElement(MediaQuery, { minWidth: "mobile" },
                React.createElement(Modal, { open: isOpen, onClose: toggleModal, maxWidth: "sm", classes: { scrollBody: css.modal } },
                    React.createElement(ResendTicketsForm, { status: resendStage, onSubmit: resendTicketsHandler, onCancel: toggleModal }))),
            React.createElement(MediaQuery, { maxWidth: "mobile" },
                React.createElement(SlideBottom, { isOpen: isOpen, onClose: toggleModal },
                    React.createElement(ResendTicketsForm, { status: resendStage, onSubmit: resendTicketsHandler, onCancel: toggleModal }))))));
};
export default SendToEmail;
