import { __assign, __rest } from "tslib";
import React, { useMemo } from 'react';
import { Datepicker as DatepickerComponent } from '../../../../../Datepicker';
import Dropdown from '../../../../../Dropdown/Dropdown';
import { ThemeProvider, useTheme } from '../../../../../theme';
var Datepicker = function (_a) {
    var _b;
    var className = _a.className, isOpen = _a.isOpen, availableDates = _a.availableDates, onClose = _a.onClose, props = __rest(_a, ["className", "isOpen", "availableDates", "onClose"]);
    var css = useTheme('SeatAvailability').Datepicker;
    var datepickerTheme = useMemo(function () { return ({
        Datepicker: {
            MonthsWrapperStyles: {
                nav_next: css.months__navNext,
                nav_prev: css.months__navPrev,
                wrapper: css.months__wrapper
            },
            MonthHeaderStyles: {
                monthHeader: css.monthHeader__wrapper,
                monthHeader__select_year: css.monthHeader__selectYear,
                monthHeader__select_month: css.monthHeader__selectMonth
            },
            WeeklyHeaderStyles: {
                day: css.weeklyHeader__day
            },
            DayStyles: {
                day: css.day__day,
                day__inside: css.day__dayInside,
                day_fromOtherMonth: css.day__dayFromOtherMonth,
                day_selected: css.day__daySelected,
                day_today: css.day__dayToday
            }
        }
    }); }, []);
    return (React.createElement(ThemeProvider, { value: datepickerTheme },
        React.createElement(Dropdown, { isOpen: isOpen, onClose: onClose },
            React.createElement("div", { className: className },
                React.createElement(DatepickerComponent, __assign({}, props, { highlightedDates: (_b = {},
                        _b[css.day__available] = availableDates,
                        _b) }))))));
};
export default Datepicker;
