import * as React from 'react';
import { Form } from 'react-final-form';
import { Button, Link } from '../../../../index';
import Field from '../Field/Field';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import Resend from '../../../../Contacts/components/Resend/Resend';
var CodeForm = function (_a) {
    var onCodeSubmit = _a.onCodeSubmit, resendCode = _a.resendCode, codeSent = _a.codeSent, codeSending = _a.codeSending, onGoBack = _a.onGoBack;
    var t = useTranslation('LoginPage').t;
    var css = useTheme('LeoLogin').LeoLogin;
    var onSubmitHandler = function (values) {
        return onCodeSubmit(values['code']).then(function (result) {
            if (!result) {
                return { error: t('Please, check the code') };
            }
            return undefined;
        });
    };
    var validation = function (value) {
        if (!value) {
            return t('Please, check the code');
        }
        return undefined;
    };
    return (React.createElement(Form, { onSubmit: onSubmitHandler }, function (form) {
        var _a;
        return (React.createElement("form", { className: css.form, onSubmit: form.handleSubmit },
            React.createElement("div", null,
                React.createElement(Field, { name: 'code', label: t('Verification code'), validate: validation, error: (_a = form.submitErrors) === null || _a === void 0 ? void 0 : _a['error'] })),
            React.createElement("div", { className: css.control },
                React.createElement(Button, { type: "submit", isLoading: form.submitting }, t('Continue'))),
            React.createElement(Resend, { sendCode: resendCode, codeSending: codeSending, codeSent: codeSent, labelClassName: css.resend__label, className: css.resend }),
            onGoBack && (React.createElement(Link, { className: css.goToPassword, action: onGoBack }, t('Re-enter login and password')))));
    }));
};
export default CodeForm;
