import * as React from 'react';
import { useTheme } from '../../../theme';
import { arrowIcon } from '../../Icons';
var Gateway = function (_a) {
    var type = _a.type, header = _a.header, description = _a.description, onClick = _a.onClick;
    var css = useTheme('PaymentForm').Gateway;
    return (React.createElement("li", { className: css.wrapper, onClick: onClick },
        React.createElement("div", { className: css.header },
            header,
            React.createElement("span", { className: css.header__arrow },
                React.createElement("span", null, type),
                " ",
                React.createElement("span", null, arrowIcon))),
        description && React.createElement("p", { className: css.description }, description)));
};
export default Gateway;
