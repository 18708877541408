import * as React from 'react';
import cn from 'classnames';
import Wings from './Wings/Wings';
import { useTheme } from '../../../theme';
import Row from './Row/Row';
import { nextRowHasOtherServiceClass } from './utils';
import { useContext } from 'react';
import { SeatsRecommendContext } from '../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
var SeatMap = React.memo(function (props) {
    var _a, _b;
    var theme = useTheme('SeatMap').SeatMapStyles;
    var seatMapRef = React.useRef(null);
    var rows = props.segment.decks[0].rows;
    var isSeatMapTooWide = rows[0] && rows[0].seats && rows[0].seats.length >= 10;
    var seatRecommendContext = useContext(SeatsRecommendContext);
    return (React.createElement("div", { className: theme.container, ref: props.seatMapRef },
        seatRecommendContext.isOpen && React.createElement("div", { className: theme.map__overlay }),
        React.createElement("div", { className: cn(theme.seatMap__wrapper, (_a = {}, _a[theme.seatMap__wrapper_withoutWings] = false, _a)), ref: seatMapRef },
            React.createElement("div", { className: cn(theme.seatMap, (_b = {},
                    _b[theme.seatMap_wide] = isSeatMapTooWide,
                    _b)) },
                React.createElement(Wings, null, rows.map(function (row, rowIndex) { return (React.createElement(Row, { services: props.services, key: row.number, isSeatMapWide: isSeatMapTooWide, onSelect: props.onSelect, passengers: props.passengers, nextRow: rows[rowIndex + 1], passengerId: props.passengers.find(function (passenger) { return passenger.id === props.passengerIndex.toString(10); }).id, segmentId: props.segment.segmentInfo.id, lastRow: rows.length - 1, rowIndex: rowIndex, row: row, newClassType: rowIndex === 0 || nextRowHasOtherServiceClass(rows[rowIndex - 1], row), passengerIndex: props.passengerIndex, onClear: props.onClear })); }))))));
}, function (prevProps, nextProps) { return !nextProps.isActive; });
export default SeatMap;
