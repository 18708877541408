import * as React from 'react';
import { useContext } from 'react';
import ResendTicketsForm from '../../ResendTicketsForm';
import { OrderCardContext } from '../../OrderCard/components/OrderCard';
import { useResendTickets } from '../../Checkout/components/Checkout/hooks';
import MediaQuery from '../../MediaQuery/MediaQuery';
import Modal from '../../Modal';
import { SlideBottom } from '../../index';
var ResendTickets = function (props) {
    var _a;
    var order = useContext(OrderCardContext).order;
    var _b = useResendTickets(order.id), resendStage = _b.resendStage, handleResendEmail = _b.handleResendEmail, closeModal = _b.closeModal, isModalOpen = _b.isModalOpen, openModal = _b.openModal;
    return (React.createElement(React.Fragment, null, (_a = props.renderTrigger) === null || _a === void 0 ? void 0 :
        _a.call(props, openModal),
        React.createElement(MediaQuery, { minWidth: "mobile" },
            React.createElement(Modal, { open: isModalOpen, closeButtonRenderer: function () { return null; }, maxWidth: "sm" },
                React.createElement(ResendTicketsForm, { onCancel: closeModal, defaultEmail: order.customer.values.find(function (_a) {
                        var name = _a.name;
                        return name === 'email';
                    }).value, onSubmit: function (_a) {
                        var email = _a.email;
                        return handleResendEmail(email);
                    }, status: resendStage }))),
        React.createElement(MediaQuery, { maxWidth: "mobile" },
            React.createElement(SlideBottom, { isOpen: isModalOpen, onClose: closeModal },
                React.createElement(ResendTicketsForm, { onCancel: closeModal, defaultEmail: order.customer.values.find(function (_a) {
                        var name = _a.name;
                        return name === 'email';
                    }).value, onSubmit: function (_a) {
                        var email = _a.email;
                        return handleResendEmail(email);
                    }, status: resendStage })))));
};
export default ResendTickets;
