import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { filterGroupsForDirectionsDialog } from '../../utils';
import DropdownComponent from '../../../Dropdown/Dropdown';
import DirectionsDialog from '../DirectionsDialog/DirectionsDialog';
import Button from '../../../Button/Button';
import { LocationGroupType } from '../../../__generated__/globalTypes';
import { useTheme } from '../../theme';
var Dropdown = function (props) {
    var css = useTheme().Dropdown;
    var t = useTranslation('LocationAutocomplete').t;
    var _a = __read(useState(false), 2), dialogIsOpen = _a[0], setDialogIsOpen = _a[1];
    var onDirectionsClose = function () { return setDialogIsOpen(false); };
    var onDirectionsOpen = function () { return setDialogIsOpen(true); };
    // Check if we do have some additional groups that we could split from the whole list of options.
    var splitOptions = !props.hasInputData &&
        props.options.length > 5 &&
        props.options.some(function (item) {
            return 'type' in item &&
                (item.type === LocationGroupType.Nearest ||
                    item.type === LocationGroupType.Popular ||
                    item.type === LocationGroupType.AdditionalCities);
        });
    var desktopOptions = __spreadArray([], __read(props.options), false);
    if (splitOptions) {
        desktopOptions = props.options.filter(function (item) {
            return 'type' in item &&
                (item.type === LocationGroupType.Nearest ||
                    item.type === LocationGroupType.Popular ||
                    item.type === LocationGroupType.AdditionalCities);
        });
    }
    return (React.createElement(DropdownComponent, __assign({}, props.onClickOutsideProps, { keepMounted: true, isOpen: props.options.length && props.isOpen, onClose: props.onClose }),
        React.createElement("div", { className: cn(css.options, props.optionsClassName) },
            desktopOptions.map(function (item, index) {
                return 'value' in item ? props.renderOption(item, index) : props.renderGroup(item, index);
            }),
            splitOptions && (React.createElement("div", { className: css.allDirections },
                React.createElement(Button, { className: css.allDirections__button, onMouseDown: onDirectionsOpen, variant: "flat", size: "small" }, t('show all directions')),
                React.createElement(DirectionsDialog, { isOpen: dialogIsOpen, onClose: onDirectionsClose, onSelect: props.onSelect, groups: filterGroupsForDirectionsDialog(props.options) }))))));
};
export default Dropdown;
