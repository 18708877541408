import * as React from 'react';
import { memo, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Money } from '../../Money';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import { useTheme } from '../../theme';
import Button from '../../Button/Button';
import Header from './Header/Header';
import Link from '../../Link';
import { useConfig } from '../../context';
import { useMediaQuery } from 'react-responsive';
initI18n('AdditionalServiceCard');
var AdditionalServiceCard = memo(function (_a) {
    var _b, _c, _d;
    var service = _a.service, onClick = _a.onClick, buttonText = _a.buttonText, isLoading = _a.isLoading, _e = _a.horizontal, horizontal = _e === void 0 ? false : _e;
    var theme = useTheme('AdditionalServiceCard').AdditionalServiceStyles;
    var t = useTranslation('AdditionalServiceCard').t;
    var companyInfo = useConfig().global.companyInfo;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var ownContent = useMemo(function () { return (React.createElement(React.Fragment, null,
        horizontal && !isMobile ? (React.createElement("div", { className: theme.title }, t(service.header))) : (React.createElement("div", { className: theme.subheader }, t(service.subheader))),
        service.content,
        service.header === 'Insurance' && (!horizontal || isMobile) && (React.createElement(Link, { action: "".concat(companyInfo.insurance), target: "_blank" }, t('Terms of service'))))); }, [horizontal, isMobile, service.content, service.header]);
    return (React.createElement("div", { className: cn(theme.additional, (_b = {},
            _b[theme.additional_horizontal] = horizontal && !isMobile,
            _b)) },
        React.createElement(Header, { header: service.header, renderHeader: service.renderHeader, logo: service.logo, headClassName: service.headClassName, icon: service.icon, horizontal: horizontal && !isMobile }),
        React.createElement("div", { className: theme.content }, service.renderContent ? service.renderContent() : ownContent),
        React.createElement("div", { className: theme.footer },
            service.renderPrice ? (React.createElement("span", { className: theme.price }, service.renderPrice({ price: service.price, active: service.active }))) : (React.createElement(Money, { moneyClassName: cn(theme.price, (_c = {},
                    _c[theme.price_notSelected] = !service.active,
                    _c)), money: service.price })),
            React.createElement(Button, { variant: "common", className: cn(theme.buttonBase, (_d = {},
                    _d[theme.remove] = service.active,
                    _d)), isLoading: isLoading, onClick: onClick }, buttonText ? t(buttonText) : service.active ? t('Remove') : t('Add')))));
});
export default AdditionalServiceCard;
