import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { Button, SlideBottom } from '../../../../../index';
import SeatServicePassengers from './SeatServicePassengers/SeatServicePassengers';
import SeatInfo from '../../../SeatsInfo/SeatInfo/SeatInfo';
import cn from 'classnames';
var SeatServiceSlide = function (_a) {
    var _b;
    var seat = _a.seat, isOpen = _a.isOpen, onClose = _a.onClose, onSelect = _a.onSelect, passengers = _a.passengers, segmentId = _a.segmentId, onClear = _a.onClear;
    var theme = useTheme('SeatMap').SeatServiceSlide;
    var t = useTranslation('SeatMap').t;
    var service = seat.service;
    var isSeatSelected = React.useMemo(function () {
        var _a;
        if (passengers.length === 1) {
            return !!((_a = passengers[0].seats) === null || _a === void 0 ? void 0 : _a.find(function (selectedSeat) { return selectedSeat.segmentId === segmentId && selectedSeat.number === seat.number; }));
        }
        return false;
    }, [seat]);
    var onSelectHandler = function (passengerIndex) {
        if (passengers.length > 1) {
            onSelect(seat, passengerIndex);
        }
        else {
            onSelect(seat, 0);
        }
    };
    return (React.createElement(SlideBottom, { isOpen: isOpen, className: theme.serviceInfo__slider, onClose: onClose, header: passengers.length > 1 ? t('Select passenger') : null },
        React.createElement("div", null,
            React.createElement(SeatInfo, { seatService: service, seat: seat }),
            passengers.length > 1 && (React.createElement(SeatServicePassengers, { passengers: passengers, segmentId: segmentId, onSeatSelect: onSelectHandler, seat: seat, onClear: onClear })),
            passengers.length === 1 && (React.createElement(Button, { variant: "flat", onClick: function () { return onSelectHandler(0); }, className: cn(theme.single, (_b = {}, _b[theme.single_cancel] = isSeatSelected, _b)) }, isSeatSelected ? t('Cancel') : t('Take this seat'))))));
};
export default SeatServiceSlide;
