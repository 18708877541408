import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllRequiredSeatsAreSelected, getCheckinOrderId, getHasAvailableSeats, getSeatMap, getTotalPrice, hasBaggageServices, hasMealServices } from '../../../../store/order/selectors';
import SeatMap from '../../../../../../SeatMap';
import { AviaPassengerType } from '../../../../../../__generated__/globalTypes';
import { useTheme } from '../../../../../../theme';
import { CheckinStep, useSteps } from '../../../../utils';
import { Money } from '../../../../../../Money';
import ServicesToolbar from '../../../../../../ServicesToolbar';
import { CheckinEmptySeatMapError } from '../../CheckinErrorBoundary/CheckinErrors';
import { setCheckinOrder } from '../../../../store/order/actions';
var Seats = function (_a) {
    var onProceed = _a.onProceed, travellers = _a.travellers, setSeatCallback = _a.setSeatCallback, passengers = _a.passengers, setClearSeatCallback = _a.setClearSeatCallback, isLoading = _a.isLoading, setIsLoading = _a.setIsLoading, seatsPriceToPay = _a.seatsPriceToPay, onSegmentClear = _a.onSegmentClear;
    var orderId = useSelector(getCheckinOrderId);
    var totalPrice = useSelector(getTotalPrice);
    var seatMap = useSelector(getSeatMap);
    var hasAvailableSeats = useSelector(getHasAvailableSeats);
    var hasBaggage = useSelector(hasBaggageServices);
    var hasMeals = useSelector(hasMealServices);
    var dispatch = useDispatch();
    var allRequiredSeatsAreSelected = useSelector(getAllRequiredSeatsAreSelected);
    var canGoBack = hasBaggage || hasMeals;
    var css = useTheme('Checkin').Checkin;
    var t = useTranslation('Checkin').t;
    // const { runServiceRequest } = useServiceRequest();
    var previousStep = useSteps().previousStep;
    var setOrder = function (order) {
        dispatch(setCheckinOrder(order));
    };
    useEffect(function () {
        if (!allRequiredSeatsAreSelected && !hasAvailableSeats) {
            throw new CheckinEmptySeatMapError('Empty seat map and travellers without seats');
        }
    }, [allRequiredSeatsAreSelected]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ServicesToolbar, { toolbarProps: {
                totalPrice: totalPrice,
                onButtonClick: onProceed,
                onBackButtonClick: canGoBack ? function () { return previousStep(CheckinStep.Seats); } : null,
                additional: seatsPriceToPay.amount ? (React.createElement("div", { className: css.servicePrice },
                    t('Seats'),
                    ": ",
                    React.createElement(Money, { money: seatsPriceToPay }))) : null
            }, mobileToolbarProps: {
                onContinue: onProceed,
                onBack: canGoBack ? function () { return previousStep(CheckinStep.Seats); } : null
            } },
            React.createElement(SeatMap, { orderId: orderId, seatMap: seatMap, passengers: passengers === null || passengers === void 0 ? void 0 : passengers.filter(function (passenger) { return passenger.type !== AviaPassengerType.INF; }), segments: seatMap.segments.map(function (segment) {
                    return {
                        segmentId: segment.segmentsInfo.id,
                        departure: segment.segmentsInfo.departure,
                        arrival: segment.segmentsInfo.arrival
                    };
                }), travellers: travellers, showRecommendations: true, fillOrder: setOrder, setSeatCallback: setSeatCallback, setClearSeatCallback: setClearSeatCallback, onProceed: onProceed, onSegmentClear: onSegmentClear }))));
};
export default Seats;
