import * as React from 'react';
import RequestForm from '../RequestForm/RequestForm';
import PromoLoader from '../../../PromoLoader';
import { useSteps } from '../Refund/hooks';
import { StepType } from '../../types';
import Notes from '../Notes/Notes';
import StepsButtons from '../StepsButtons/StepsButtons';
import { useCallback } from 'react';
import { saveCustomer } from '../../store/customer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { isAutoRefundMode } from '../../store/selectors';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { OverrideComponent } from '../../../renderProps';
import PriceDetails from '../../../LEO/Refund/PriceDetails/PriceDetails';
var RequestFormPage = function (props) {
    var _a = useTheme('Refund'), css = _a.Refund, wrapperCss = _a.Wrapper;
    var t = useTranslation('Refund').t;
    var isLoading = props.isLoading, prices = props.prices;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var dispatch = useDispatch();
    var _b = useSteps(), currentStep = _b.currentStep, goToNextStep = _b.goToNextStep, goToPrevStep = _b.goToPrevStep;
    var isAutoMode = useSelector(isAutoRefundMode);
    var handleSaveCustomer = useCallback(function (_a) {
        var comments = _a.comments, email = _a.email, name = _a.name, phone = _a.phone;
        dispatch(saveCustomer({ comments: comments, name: name, email: email, phone: phone }));
        goToNextStep();
    }, [goToNextStep]);
    var isActive = currentStep.type === StepType.CreateTicket;
    if (isLoading && isActive) {
        return React.createElement(PromoLoader, { className: wrapperCss.promoLoader });
    }
    var requestFormProps = {
        onSubmit: handleSaveCustomer,
        renderPrice: !isLoading && prices ? function () { return React.createElement(PriceDetails, { className: css.prices, prices: prices, showDetails: true }); } : null,
        renderNotes: function () { return React.createElement(Notes, { isAutoMode: isAutoMode }); },
        renderButtons: function (valid, handleSubmit) { return (React.createElement(StepsButtons, { className: css.buttons, position: 'center', backButton: isMobile ? null : { children: t('Back'), onClick: goToPrevStep }, nextButton: {
                children: t('Continue'),
                onClick: handleSubmit,
                disabled: !valid
            } })); }
    };
    return (React.createElement(React.Fragment, null, isActive && (React.createElement(OverrideComponent, { componentProps: requestFormProps, component: { RefundRequestForm: RequestForm } }))));
};
export default RequestFormPage;
