import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import MediaQuery from '../../MediaQuery/MediaQuery';
import cn from 'classnames';
import WarningModal from '../../WarningModal';
import Insurance from '../Insurance';
import { CloseClear as clearIcon } from '../../Icons';
import AdditionalService from '../../AdditionalServiceCard/components/AdditionalService';
import { DisplayMode } from '../../AdditionalServices/components/types';
import { alfaInsuranceLogo, UmbrellaIcon } from '../../AdditionalServices/components/Insurance/logo';
import { getCheckinOrderId, getConfirmedInsurances, getMinInsurancePrice } from '../../Modules/Checkin/store/order/selectors';
import DialogContent from '../../AdditionalServices/components/Insurance/DialogContent/DialogContent';
import DownloadInsurances from '../../AdditionalServices/components/Insurance/DownloadInsurances/DownloadInsurances';
import { Money, SlideBottom } from '../../index';
import { useInsurance } from '../../CheckoutAdditionalService/components/Insurance/hooks';
import { fillCheckinOrder } from '../../Modules/Checkin/store/order/actions';
import { useCheckinOrderServices } from '../../__queries__/Order';
import { useTheme } from '../../theme';
import CircularProgress from '@material-ui/core/CircularProgress';
import { initI18n } from '../../utils';
initI18n('Checkin');
var CheckinMultiInsurance = function (props) {
    var _a, _b;
    var orderId = useSelector(getCheckinOrderId);
    var displayMode = props.displayMode, insurances = props.insurances, travellers = props.travellers;
    var css = useTheme('Insurance').CheckinInsurance;
    var t = useTranslation('Checkin').t;
    var dispatch = useDispatch();
    if (!((_a = insurances === null || insurances === void 0 ? void 0 : insurances.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) && !((_b = insurances === null || insurances === void 0 ? void 0 : insurances.selectedInsurances) === null || _b === void 0 ? void 0 : _b.length)) {
        return null;
    }
    var insuranceMinPrice = useSelector(getMinInsurancePrice);
    var confirmedInsurances = useSelector(getConfirmedInsurances);
    var hasConfirmedInsurances = !!confirmedInsurances.length;
    var hasSelectedInsurances = useMemo(function () { var _a; return ((_a = insurances.selectedInsurances) === null || _a === void 0 ? void 0 : _a.length) > 0; }, [insurances]);
    var programCodesToProductsCountMap = new Map();
    insurances.selectedInsurances.forEach(function (selectedInsurance) {
        var programCode = selectedInsurance.insuranceProgram.code;
        if (programCodesToProductsCountMap.has(programCode)) {
            programCodesToProductsCountMap.set(programCode, programCodesToProductsCountMap.get(programCode) + 1);
        }
        else {
            programCodesToProductsCountMap.set(programCode, 1);
        }
    });
    var _c = __read(useCheckinOrderServices(), 2), saveCheckinOrderService = _c[0], _d = _c[1], data = _d.data, loading = _d.loading, error = _d.error;
    var _e = useInsurance({
        saveMutation: saveCheckinOrderService,
        insurances: insurances,
        orderId: orderId,
        passengers: travellers
    }), 
    // data
    confirmationPopupIsOpen = _e.confirmationPopupIsOpen, downloadPopupOpen = _e.downloadPopupOpen, setDownloadPopupOpen = _e.setDownloadPopupOpen, insuranceRulesUrl = _e.insuranceRulesUrl, multiInsurance = _e.multiInsurance, insuranceDocuments = _e.insuranceDocuments, openMultiInsurance = _e.openMultiInsurance, setInsuranceVisible = _e.setInsuranceVisible, errors = _e.errors, setErrors = _e.setErrors, passengerDocuments = _e.passengerDocuments, 
    // functions
    saveInsurances = _e.saveInsurances, closeConfirmationPopup = _e.closeConfirmationPopup, closeDownloadPopup = _e.closeDownloadPopup, onInsuranceRemove = _e.onInsuranceRemove;
    useEffect(function () {
        var _a;
        if (loading === false && (data === null || data === void 0 ? void 0 : data.CheckinSaveOrderServices) && !((_a = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length)) {
            dispatch(fillCheckinOrder(data === null || data === void 0 ? void 0 : data.CheckinSaveOrderServices));
        }
    }, [loading, data, error]);
    var renderSelectedInsurance = useCallback(function (insurance) { return (React.createElement("div", { key: insurance.insuranceProgram.code, className: css.insurance },
        React.createElement("h3", { className: css.title },
            insurance.insuranceProgram.name,
            programCodesToProductsCountMap.has(insurance.insuranceProgram.code) && (React.createElement(React.Fragment, null,
                "\u00A0x",
                programCodesToProductsCountMap.get(insurance.insuranceProgram.code)))),
        React.createElement(Money, { money: insurance.insuranceProgram.price }))); }, [insurances.selectedInsurances]);
    var renderServiceContent = useCallback(function () {
        if (insurances.selectedInsurances.length) {
            var uniqueSelectedInsurances = __spreadArray([], __read(programCodesToProductsCountMap.keys()), false).map(function (uniqueInsuranceCode) {
                return insurances.selectedInsurances.find(function (insurance) { return insurance.insuranceProgram.code === uniqueInsuranceCode; });
            });
            return (React.createElement("div", null,
                uniqueSelectedInsurances.map(renderSelectedInsurance),
                uniqueSelectedInsurances.length === 1 && (React.createElement("p", null, uniqueSelectedInsurances[0].insuranceProgram.description))));
        }
        return React.createElement("div", null, t('Inflight insurance or baggage insurance is your travel confidence.'));
    }, [insurances.selectedInsurances]);
    var renderInsuranceModal = useCallback(function () {
        if (!multiInsurance) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Insurance, { insurances: insurances.insurancePrograms, selectedInsurances: insurances.selectedInsurances, open: openMultiInsurance, rulesURL: insuranceRulesUrl, onSave: function (state) {
                    saveInsurances(insurances.type, state.selectedInsurances, state.passengers);
                }, onClose: function () { return setInsuranceVisible(false); }, documentNeeds: insuranceDocuments, passengers: passengerDocuments, error: !!error, loading: loading }),
            React.createElement(WarningModal, { title: 'Some error', content: 'error', isOpen: errors, onClose: function () { return setErrors(false); }, onButtonClick: function () { return setErrors(false); } })));
    }, [travellers, insurances, openMultiInsurance, loading, openMultiInsurance]);
    var renderMobileStepbarInsurance = useCallback(function () {
        return (React.createElement("div", { className: css.priceWrapper, onClick: function () {
                return hasSelectedInsurances
                    ? saveInsurances(insurances.type, [], passengerDocuments)
                    : setInsuranceVisible(true);
            } },
            !hasSelectedInsurances && (React.createElement(React.Fragment, null,
                t('From'),
                React.createElement(Money, { moneyClassName: cn(css.price, css.insideStepBar), money: insuranceMinPrice }))),
            hasSelectedInsurances && (React.createElement("span", { className: css.iconsWrapper }, loading ? (React.createElement(CircularProgress, { size: 18, classes: { colorPrimary: css.loadingIconColor }, className: css.loadingIcon })) : (clearIcon)))));
    }, [openMultiInsurance, displayMode, props.isMobileStepbar, loading, insurances]);
    var renderInsuranceTriggerCard = useCallback(function () {
        if (!props.isMobileStepbar) {
            return (React.createElement(AdditionalService, { horizontal: displayMode === DisplayMode.Horizontal, service: {
                    logo: React.createElement("div", { className: css.logo }, alfaInsuranceLogo),
                    header: 'Insurance',
                    price: hasSelectedInsurances ? null : insuranceMinPrice,
                    subheader: null,
                    icon: UmbrellaIcon,
                    active: openMultiInsurance,
                    renderContent: renderServiceContent
                }, isLoading: loading, buttonText: hasConfirmedInsurances
                    ? t('Download')
                    : hasSelectedInsurances
                        ? t('Aeroexpress:Change')
                        : t('Select'), onClick: function () { return (hasConfirmedInsurances ? setDownloadPopupOpen(true) : setInsuranceVisible(true)); } }));
        }
        else {
            return renderMobileStepbarInsurance();
        }
    }, [props.isMobileStepbar, displayMode, insuranceMinPrice, openMultiInsurance, loading]);
    return (React.createElement(React.Fragment, null,
        renderInsuranceTriggerCard(),
        renderInsuranceModal(),
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(Dialog, { open: confirmationPopupIsOpen, onClose: closeConfirmationPopup, classes: { paper: css.popup } },
                React.createElement(DialogContent, { closeConfirmationPopup: closeConfirmationPopup, onInsuranceRemove: onInsuranceRemove, refusingIsInProgress: loading })),
            React.createElement(Dialog, { open: downloadPopupOpen, onClose: closeDownloadPopup, classes: { paper: css.downloadPopup } },
                React.createElement(DownloadInsurances, { selectedInsurances: insurances.selectedInsurances }))),
        React.createElement(MediaQuery, { maxWidth: 'mobile' },
            React.createElement(SlideBottom, { onClose: closeConfirmationPopup, isOpen: confirmationPopupIsOpen },
                React.createElement("div", null,
                    React.createElement(DialogContent, { closeConfirmationPopup: closeConfirmationPopup, onInsuranceRemove: onInsuranceRemove, refusingIsInProgress: loading }))),
            React.createElement(SlideBottom, { isOpen: downloadPopupOpen, onClose: closeDownloadPopup },
                React.createElement(DownloadInsurances, { selectedInsurances: insurances.selectedInsurances })))));
};
export default CheckinMultiInsurance;
