import { addDays, addHours, differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import i18n from 'i18next';
import { isValid, parse } from 'date-fns';
export var sortItems = function (items, sortOrder, sortField) {
    return ((items === null || items === void 0 ? void 0 : items.sort(function (queue, queueNext) {
        if (queue[sortField] > queueNext[sortField]) {
            return sortOrder === 'asc' ? 1 : -1;
        }
        if (queue[sortField] < queueNext[sortField]) {
            return sortOrder === 'asc' ? -1 : 1;
        }
        return 0;
    })) || []);
};
export var calcDuration = function (date) {
    var today = new Date();
    var diffInDays = differenceInDays(date, today);
    var tmpDate = date;
    if (Math.abs(diffInDays) > 0) {
        tmpDate = addDays(tmpDate, diffInDays * -1);
    }
    var diffInHours = differenceInHours(tmpDate, today);
    if (Math.abs(diffInHours) > 0) {
        tmpDate = addHours(tmpDate, diffInHours * -1);
    }
    return {
        days: diffInDays,
        hours: diffInHours,
        minutes: differenceInMinutes(tmpDate, today)
    };
};
export var inputRequiredValidate = function (value) { return (!(value === null || value === void 0 ? void 0 : value.trim()) ? i18n.t('Required') : null); };
export var requiredNumberValidate = function (value) {
    return !new RegExp('^[0-9]+$').test(value) ? i18n.t('EventsQueues:Required number') : null;
};
export var numberValidate = function (value) {
    return (value === null || value === void 0 ? void 0 : value.trim()) && !new RegExp('^[0-9]+$').test(value) ? i18n.t('EventsQueues:Enter number') : null;
};
export var dateValidate = function (value, maskFormat) {
    if (!value) {
        return null;
    }
    var date = parse(value, maskFormat, new Date());
    if (!isValid(date)) {
        return i18n.t('EventsQueues:Enter date in format', { format: maskFormat });
    }
    return null;
};
export var filterEventsByCategory = function (eventsForQueue, selectedCategory) {
    return selectedCategory
        ? eventsForQueue.events.filter(function (category) { return category.categoryNumber.toString() === selectedCategory; })
        : eventsForQueue.events;
};
export var filterEventsBySearch = function (events, searchingLocator) {
    var _a;
    var searching = (_a = searchingLocator === null || searchingLocator === void 0 ? void 0 : searchingLocator.trim()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    return searching ? events.filter(function (event) { return event.locator.toLowerCase().includes(searching); }) : events;
};
