import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import { InsuranceType, OrderAdditionalServiceGdsServiceServiceType } from '../../__generated__/globalTypes';
import Subscribe from '../../Checkout/components/Checkout/Upsale/Subscribe/Subscribe';
import Aeroexpress from '../../Aeroexpress';
import { AdditionalServiceType } from '../types';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { isCheckinOrderServicesResponse, isOrderServicesResponse } from '../../__queries__/Order';
import { DisplayMode } from './types';
import CheckinMultiInsurance from '../../Insurance/CheckinInsurance/CheckinInsurance';
import Insurance from './Insurance/Insurance';
import { ModeContext, useConfig } from '../../context';
import { Mode } from '../../types';
import Business from './Business/Business';
import { makeGetGdsServicesByType } from '../../Modules/Checkin/store/order/selectors';
import { useSelector } from 'react-redux';
// #fixme: connect to redux
export default function AdditionalServices(_a) {
    var _b;
    var _this = this;
    var _c, _d, _e, _f;
    var displayMode = _a.displayMode, _g = _a.isUpsaleVisible, isUpsaleVisible = _g === void 0 ? false : _g, orderId = _a.orderId, additionalServices = _a.additionalServices, onSetOrder = _a.onSetOrder, travellers = _a.travellers, inMobileStepbar = _a.inMobileStepbar, className = _a.className, saveOrderServices = _a.saveOrderServices;
    var theme = useTheme('AdditionalServices').AdditionalServicesStyles;
    var mode = useContext(ModeContext);
    var _h = __read(useState(false), 2), isLoading = _h[0], setIsLoading = _h[1];
    var updateServices = function (type, codes) { return __awaiter(_this, void 0, void 0, function () {
        var variables, data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    variables = {
                        params: {
                            id: orderId
                        }
                    };
                    if (type === AdditionalServiceType.Insurance) {
                        variables.params.insurances = [
                            {
                                insuranceType: additionalServices.insurances
                                    ? additionalServices.insurances.type
                                    : InsuranceType.SirenaInsurance,
                                selectedPrograms: codes
                            }
                        ];
                    }
                    if (type === AdditionalServiceType.Transfer) {
                        variables.params.aeroexpress = {
                            selectedTrips: codes
                        };
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, saveOrderServices(variables)];
                case 2:
                    data = _a.sent();
                    if (data && isOrderServicesResponse(data)) {
                        onSetOrder(data.SaveOrderServices);
                    }
                    else if (data && isCheckinOrderServicesResponse(data)) {
                        onSetOrder(data.CheckinSaveOrderServices);
                    }
                    else {
                        throw new Error('Не удалось добавить сервис');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var updateInsurances = updateServices.bind(null, AdditionalServiceType.Insurance);
    var updateTransfer = updateServices.bind(null, AdditionalServiceType.Transfer);
    var multiInsurance = useConfig().global.companyInfo.multiInsurance;
    var renderInsurance = useCallback(function () {
        var _a;
        var singleInsurance = (_a = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms.slice(0, 1).map(function (insurance, index) { return (React.createElement(Insurance, { displayMode: !inMobileStepbar && displayMode, key: index, insurance: insurance, selectedInsurances: additionalServices.insurances && additionalServices.insurances.selectedInsurances
                ? additionalServices.insurances.selectedInsurances
                : [], onClick: updateInsurances, isMobileStepbar: inMobileStepbar === 'insurance' })); });
        var multiInsuranceElement = (React.createElement(CheckinMultiInsurance, { travellers: travellers, insurances: additionalServices.insurances, displayMode: !inMobileStepbar && displayMode, isMobileStepbar: inMobileStepbar === 'insurance' }));
        if (inMobileStepbar === 'insurance') {
            if (multiInsurance && mode === Mode.Checkin) {
                return multiInsuranceElement;
            }
            else {
                return React.createElement("span", null, singleInsurance);
            }
        }
        else {
            if (multiInsurance) {
                return multiInsuranceElement;
            }
            else {
                return singleInsurance;
            }
        }
    }, [additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.insurances, inMobileStepbar, multiInsurance]);
    var businessLoungeSelector = makeGetGdsServicesByType(OrderAdditionalServiceGdsServiceServiceType.BusinessLounge);
    var businessLoungeServices = useSelector(businessLoungeSelector);
    return (React.createElement(React.Fragment, null,
        inMobileStepbar === 'insurance' && renderInsurance(),
        inMobileStepbar === 'aeroexpress' && !!((_c = additionalServices.transfer) === null || _c === void 0 ? void 0 : _c.aeroexpress.trips.length) && (React.createElement(Aeroexpress, { isMobileStepbar: true, updateServices: updateTransfer, isLoading: isLoading })),
        !inMobileStepbar && (React.createElement("div", { className: cn(theme.services, className, (_b = {},
                _b[theme.services_horizontal] = displayMode === DisplayMode.Horizontal,
                _b)) },
            !!((_f = (_e = (_d = additionalServices.transfer) === null || _d === void 0 ? void 0 : _d.aeroexpress) === null || _e === void 0 ? void 0 : _e.trips) === null || _f === void 0 ? void 0 : _f.length) && (React.createElement(Aeroexpress, { displayMode: displayMode, updateServices: updateTransfer, isLoading: isLoading })),
            renderInsurance(),
            (businessLoungeServices === null || businessLoungeServices === void 0 ? void 0 : businessLoungeServices.length) && React.createElement(Business, null),
            isUpsaleVisible && React.createElement(Subscribe, null)))));
}
