import { __read } from "tslib";
import * as React from 'react';
import { useTheme } from '../theme';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import Slide from '@material-ui/core/Slide';
import { IconButton } from '@material-ui/core';
import { CloseClear } from '../../Icons';
export var SlideBottom = function (props) {
    var _a, _b;
    var theme = useTheme().SlideBottomStyles, _c = __read(useState(false), 2), isVisible = _c[0], setVisibile = _c[1];
    var onClick = useCallback(function () {
        props.onClose();
        window.document.body.style.overflow = 'auto';
    }, [props.onClose]);
    useEffect(function () {
        var bodyComponent = window.document.body;
        if (props.isOpen) {
            setVisibile(true);
            bodyComponent.style.overflow = 'hidden';
        }
        else {
            if (isVisible) {
                bodyComponent.style.overflow = 'auto';
                setTimeout(function () {
                    setVisibile(false);
                }, 250);
            }
        }
        return function () {
            window.document.body.style.overflow = 'auto';
        };
    }, [props.isOpen]);
    return (isVisible && (React.createElement("div", { className: classnames(theme.mainWrapper, props.wrapperClassName, (_a = {},
            _a[theme.wrapper_closed] = !props.isOpen,
            _a)) },
        React.createElement("div", { className: classnames(theme.wrapper, props.overlayClassName), onClick: onClick }),
        React.createElement(Slide, { direction: "up", in: props.isOpen },
            React.createElement("div", { className: classnames(theme.container, props.className, (_b = {},
                    _b[theme.container_noPadding] = props.noPadding,
                    _b[theme.container_scrollable] = props.scrollable,
                    _b)) },
                props.header && (React.createElement("div", { className: classnames(theme.header, props.headerClassName) },
                    props.header,
                    React.createElement(IconButton, { onClick: props.onClose }, CloseClear))),
                props.children)))));
};
export default SlideBottom;
