import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Helmet from 'react-helmet';
import Results from '../../Results';
import SearchForm from '../../SearchForm';
import Checkout from '../../Checkout';
import ResultsError from '../../ResultsError';
import PromoLoader from '../../PromoLoader';
import FastSearch from './FastSearch/FastSearch';
import MetaRedirect from './MetaRedirect/MetaRedirect';
import * as ResultsQuery from '../../__queries__/Results.graphql';
import { useConfig } from '../../context';
import FindOrder from '../../Checkout/components/Checkout/FindOrder/FindOrder';
import Page404 from '../../Page404/Page404';
import { ProductType, viewProduct } from '../../analytics';
import CreateOrder from './CreateOrder/CreateOrder';
import { initI18n } from '../../utils';
import FareLock from '../../FareLock/';
import { useFareLock } from '../../FareLock/hooks';
import RestoreOrder from './RestoreOrder/RestoreOrder';
import { OverrideComponent, useRenderers } from '../../renderProps';
import leoCheckIsAuthenticated from '../../LEO/leoCheckIsAuthenticated/leoCheckIsAuthenticated';
import { useIsTerminal } from '../../hooks';
initI18n('Title');
var flightsNotFoundError = 'Flights not found';
var Inner = function (_a) {
    var history = _a.history;
    var t = useTranslation().t;
    var _b = __read(useState(false), 2), isRedirectFromMeta = _b[0], setIsRedirectFromMeta = _b[1];
    var _c = __read(useState(null), 2), order = _c[0], setOrder = _c[1];
    var _d = __read(useState(), 2), results = _d[0], setResults = _d[1];
    var _e = __read(useState(null), 2), error = _e[0], setError = _e[1];
    var _f = __read(useState(false), 2), isLoading = _f[0], setIsLoading = _f[1];
    var searchFormURL = useConfig().Engine.searchFormURL;
    var _g = __read(useMutation(ResultsQuery.RunSearch, { errorPolicy: 'all' }), 1), searchMutation = _g[0];
    var resultsQuery = useQuery(ResultsQuery.SearchResults, {
        fetchPolicy: 'no-cache',
        skip: true
    });
    var renderAdditionalRoutes = useRenderers().renderInnerRoutes;
    var isTerminal = useIsTerminal();
    var handleOrderLoaded = useCallback(function (order) {
        setOrder(order);
    }, [order, setOrder]);
    var _h = useFareLock(order, handleOrderLoaded), price = _h.price, handleChangeFareLock = _h.handleChangeFareLock, isSelected = _h.isSelected, fareLockService = _h.fareLockService, loading = _h.loading;
    var handleRenderFareLock = useCallback(function () {
        return order && fareLockService ? (React.createElement(FareLock, { freezeUntil: fareLockService.freezeUntil, price: price, isActive: isSelected, onChange: handleChangeFareLock })) : null;
    }, [price, isSelected, handleChangeFareLock, order]);
    var dismissMetaWarning = useCallback(function () {
        setIsRedirectFromMeta(false);
    }, []);
    var loadResults = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var resultsData, error_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    setOrder(null);
                    setIsLoading(true);
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, resultsQuery.refetch({ id: id })];
                case 2:
                    resultsData = _e.sent();
                    if (!((_d = (_c = (_b = (_a = resultsData.data) === null || _a === void 0 ? void 0 : _a.SearchResult) === null || _b === void 0 ? void 0 : _b.searchParameters) === null || _c === void 0 ? void 0 : _c.segments) === null || _d === void 0 ? void 0 : _d.length)) {
                        throw new Error('empty segments length');
                    }
                    if (resultsData.errors) {
                        throw resultsData.errors[0].originalError;
                    }
                    else {
                        setResults(resultsData.data.SearchResult);
                        setIsLoading(false);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _e.sent();
                    setError({
                        name: t('It looks like something went wrong'),
                        message: "".concat(t('Please reload the page or start a new search'))
                    });
                    console.error(error_1);
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var startSearch = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_2, message, notFoundFlightsError;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setIsLoading(true);
                    setError(null);
                    history.replace('/results');
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, searchMutation({
                            variables: {
                                params: params
                            }
                        })];
                case 2:
                    data = _c.sent();
                    if (data.errors) {
                        throw new Error(data.errors[0].message);
                    }
                    else if (data.data.RunGeneralSearch && data.data.RunGeneralSearch.id) {
                        history.push('/results/' + data.data.RunGeneralSearch.id);
                        loadResults(data.data.RunGeneralSearch.id);
                    }
                    else {
                        throw new Error('No results ID');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _c.sent();
                    message = t('Please reload the page or start a new search');
                    if (isTerminal) {
                        notFoundFlightsError = (_a = error_2 === null || error_2 === void 0 ? void 0 : error_2.graphQLErrors) === null || _a === void 0 ? void 0 : _a.find(function (error) { var _a; return (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.includes(flightsNotFoundError); });
                        if (notFoundFlightsError) {
                            message = notFoundFlightsError.message;
                        }
                        else {
                            message = (_b = error_2 === null || error_2 === void 0 ? void 0 : error_2.graphQLErrors) === null || _b === void 0 ? void 0 : _b.map(function (error) { return error === null || error === void 0 ? void 0 : error.message; }).join('. ');
                        }
                    }
                    setError({
                        name: t('It looks like something went wrong'),
                        message: message
                    });
                    console.error(error_2);
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, []);
    var onOrderRecieved = function (order, replace) {
        if (replace === void 0) { replace = false; }
        if (order) {
            setOrder(order);
        }
        if (replace) {
            history.replace('/booking/' + order.id);
        }
        else {
            history.push('/booking/' + order.id);
        }
        viewProduct({
            id: order.id,
            category: ProductType.Order
        });
    };
    var onMetasearchResultsRedirect = function (resultsId) {
        setIsRedirectFromMeta(true);
        history.replace('/results/' + resultsId);
    };
    var renderHelmet = function () {
        return (React.createElement(Helmet, null,
            React.createElement("title", null,
                t('Title:companyName'),
                " \u2013 ",
                t('Title:Find tickets'))));
    };
    if (isLoading) {
        return (React.createElement(React.Fragment, null,
            renderHelmet(),
            React.createElement(PromoLoader, { innerPromoLoader: true })));
    }
    return (React.createElement("div", { style: { flexBasis: '100%' } },
        renderHelmet(),
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/", exact: true, render: function () {
                    return searchFormURL ? ((window.location.href = searchFormURL)) : (React.createElement(OverrideComponent, { componentProps: { onSubmit: startSearch }, component: { InnerSearchFormPage: SearchForm } }));
                } }),
            React.createElement(Route, { path: "/restore/:orderId/:token", exact: true, render: function () { return (React.createElement(RestoreOrder, { onOrderReceived: onOrderRecieved })); } }),
            React.createElement(Route, { path: ['/find/:id?/:secret?', '/find/:id?/:secret?/submit'], exact: true, render: function (routeProps) {
                    if (routeProps.match.path.includes('submit')) {
                        return React.createElement(FindOrder, { onSuccess: onOrderRecieved, callSubmit: true });
                    }
                    else {
                        return React.createElement(FindOrder, { onSuccess: onOrderRecieved });
                    }
                } }),
            React.createElement(Route, { path: "/metasearch/:token", exact: true, render: function () { return (React.createElement(MetaRedirect, { onResultsRedirect: onMetasearchResultsRedirect, onSuccess: onOrderRecieved })); } }),
            React.createElement(Route, { path: "/results", exact: true, render: function () {
                    if (error) {
                        var isLeoFlightsNotFoundError = isTerminal && error.message.includes(flightsNotFoundError);
                        var header = isLeoFlightsNotFoundError ? error.message : error.name;
                        var body = isLeoFlightsNotFoundError ? null : error.message;
                        return (React.createElement(React.Fragment, null,
                            React.createElement(OverrideComponent, { componentProps: { onSubmit: startSearch }, component: { SearchForm: SearchForm } }),
                            React.createElement(ResultsError, { header: header, body: body })));
                    }
                    return !error && !isLoading ? React.createElement(Redirect, { to: "/" }) : null;
                } }),
            React.createElement(Route, { path: "/search/:fastSearch", exact: true, render: function (props) { return (React.createElement(FastSearch, { fastSearch: props.match.params.fastSearch, startSearch: startSearch, redirect: React.createElement(Redirect, { to: "/" }) })); } }),
            React.createElement(Route, { path: "/results/create-order/:flightIds+", render: function (props) { return (React.createElement(CreateOrder, { flightsIds: props.match.params.flightIds, redirectOnFail: React.createElement(Redirect, { to: '/' }), onSuccess: onOrderRecieved })); } }),
            React.createElement(Route, { path: "/results/:id(\\d+/?)+", render: function (props) {
                    if (!isLoading && typeof results === 'undefined' && !error) {
                        loadResults(props.match.params.id);
                        return null;
                    }
                    if (isLoading) {
                        return null;
                    }
                    return (React.createElement(React.Fragment, null,
                        React.createElement(OverrideComponent, { componentProps: {
                                order: order,
                                loading: loading,
                                renderFareLock: handleRenderFareLock,
                                showMetaWarning: isRedirectFromMeta,
                                onMetaWarningDismiss: dismissMetaWarning,
                                onOrderLoaded: handleOrderLoaded,
                                searchResults: results,
                                errors: error ? [error] : null,
                                startNewSearch: startSearch,
                                onProceed: onOrderRecieved
                            }, component: { Results: Results } })));
                } }),
            React.createElement(Route, { path: "/booking/:id(\\d+/?)+", render: function (props) { return (React.createElement(React.Fragment, null,
                    React.createElement(Helmet, null,
                        React.createElement("title", null,
                            t('Title:companyName'),
                            " \u2013 ",
                            t('Title:Checkout order'))),
                    React.createElement(OverrideComponent, { componentProps: {
                            startNewSearch: startSearch,
                            orderId: props.match.params.id,
                            order: order
                        }, component: {
                            Checkout: Checkout
                        } }))); } }),
            renderAdditionalRoutes ? renderAdditionalRoutes() : null,
            React.createElement(Page404, { onClick: function () { return (window.location.href = '/'); } }))));
};
export default leoCheckIsAuthenticated(withRouter(Inner));
