import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Money } from '../../../../../../Money';
import { format } from '../../../../../../utils';
import { useTheme } from '../../../../../../theme';
import { OrderCardContext } from '../../../../../../OrderCard/components/OrderCard';
var WaitPaymentText = function () {
    var _a, _b;
    var css = useTheme('LeoOrderCard').OrderStatus;
    var translateFunc = useTranslation('OrderCard').t;
    var t = function (translateKey) { return translateFunc(translateKey, { nsSeparator: '|' }); };
    var _c = useContext(OrderCardContext), order = _c.order, type = _c.type;
    if (type === 'review') {
        return (React.createElement(React.Fragment, null,
            t('Review:Warning!'),
            React.createElement("br", null),
            React.createElement(Money, { moneyClassName: css.leoPaymentText, money: (_a = order.priceToPay) === null || _a === void 0 ? void 0 : _a.total }),
            ' ',
            t('Review:should be paid before'),
            " ",
            format(order.timelimit, 'dd.MM.yyyy, HH:mm')));
    }
    return (React.createElement(React.Fragment, null,
        t('Account:Warning!'),
        " ",
        React.createElement(Money, { money: (_b = order.priceToPay) === null || _b === void 0 ? void 0 : _b.total }),
        " ",
        t('Account:should be paid before'),
        format(order.timelimit, 'dd.MM.yyyy, HH:mm')));
};
export default WaitPaymentText;
