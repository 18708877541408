import { __read } from "tslib";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import SeatTooltip from './SeatTooltip/SeatTooltip';
import { useTheme } from '../../../../theme';
import { SeatComfort } from '../../../../__generated__/globalTypes';
import { TABLET_MIDDLE_WIDTH } from '../../../../utils';
import SeatServiceSlide from './SeatServiceSlide/SeatServiceSlide';
import { useContext } from 'react';
import { SeatsRecommendContext } from '../../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import { TravellerFieldEnum } from '../../../../__generated__/graphql';
var Seat = React.memo(function (_a) {
    var _b, _c;
    var _d, _e, _f, _g;
    var seat = _a.seat, onSelect = _a.onSelect, isAvailable = _a.isAvailable, isSmall = _a.isSmall, occupiedBy = _a.occupiedBy, isConfirmed = _a.isConfirmed, passengers = _a.passengers, segmentId = _a.segmentId, passengerId = _a.passengerId, onClear = _a.onClear;
    var theme = useTheme('SeatMap').SeatStyles;
    var _h = __read(React.useState(false), 2), showSeatInfo = _h[0], setShowSeatInfo = _h[1];
    var isMobile = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    var seatRecommendContext = useContext(SeatsRecommendContext);
    var handleSelect = function () {
        if (isMobile) {
            setShowSeatInfo(true);
        }
        else {
            isAvailable && onSelect(seat, passengerId);
        }
    };
    var onClearHandler = function () {
        onClear();
        setShowSeatInfo(false);
    };
    var handleMobileSelect = function (seat, passengerIndex) {
        setShowSeatInfo(false);
        isAvailable && onSelect(seat, passengerIndex);
    };
    var preselectSeatForCurrentPassenger = seatRecommendContext.isOpen
        ? (_d = seatRecommendContext.rowsMap[segmentId]) === null || _d === void 0 ? void 0 : _d.seats[passengerId].seat.number
        : false;
    var isPreselect = seatRecommendContext.isOpen
        ? (_e = seatRecommendContext.rowsMap[segmentId]) === null || _e === void 0 ? void 0 : _e.seatsMap.hasOwnProperty(seat.number)
        : false;
    var seatLabel = seat.number;
    var shortPassengerName = false;
    if (occupiedBy) {
        var firstName = (_f = occupiedBy.values.find(function (value) { return value.type === TravellerFieldEnum.FirstName; })) === null || _f === void 0 ? void 0 : _f.value, lastName = (_g = occupiedBy.values.find(function (value) { return value.type === TravellerFieldEnum.LastName; })) === null || _g === void 0 ? void 0 : _g.value;
        if (firstName && lastName) {
            seatLabel = "".concat(firstName.charAt(0)).concat(lastName.charAt(0));
            shortPassengerName = true;
        }
        else {
            seatLabel = (parseInt(occupiedBy.id) + 1).toString();
        }
    }
    var button = (React.createElement(ButtonBase, { "data-seat-number": seat.number, className: cn(theme.seat, (_b = {},
            _b[theme.seat_small] = isSmall,
            _b[theme.seat_standart] = seat.service && seat.service.comfort === SeatComfort.Standart,
            _b[theme.seat_business] = seat.service && seat.service.comfort === SeatComfort.Comfort,
            _b[theme.seat_preferred] = seat.service && seat.service.comfort === SeatComfort.Preferred,
            _b[theme.seat_unexistent] = !seat.isExistent,
            _b[theme.seat_unavailable] = !isAvailable,
            _b[theme.seat_occupied] = occupiedBy,
            _b[theme.seat_confirmed] = isConfirmed,
            _b[theme.seat_recommendCurrent] = isPreselect && preselectSeatForCurrentPassenger === seat.number,
            _b[theme.seat_recommend] = isPreselect
        // [theme.male]: seat.isSeat && seat.occupiedBy === Gender.Male,
        // [theme.female]: props.seat.isSeat && props.seat.occupiedBy === Gender.Female
        ,
            _b)), disabled: !isAvailable, onClick: handleSelect }, seat.isExistent && (isAvailable || occupiedBy) && (React.createElement("div", { className: cn(theme.number, (_c = {}, _c[theme.number_name] = shortPassengerName, _c)) }, seatLabel))));
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: TABLET_MIDDLE_WIDTH },
            React.createElement(SeatTooltip, { seat: seat, service: seat.service, isRecommend: isPreselect, occupiedBy: occupiedBy, isAvailable: isAvailable }, button)),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
            button,
            ReactDOM.createPortal(React.createElement(SeatServiceSlide, { onClose: function () { return setShowSeatInfo(false); }, onSelect: handleMobileSelect, seat: seat, isOpen: showSeatInfo, passengers: passengers, segmentId: segmentId, onClear: onClearHandler }), document.querySelector('body')))));
});
export default Seat;
