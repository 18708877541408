import * as React from 'react';
import { useField } from 'react-final-form';
import Input from '../../../Input';
import { useTranslation } from 'react-i18next';
var Field = function (props) {
    var t = useTranslation('MilesRecovery').t;
    var PNRRegex = /(^[0-9БВГДКЛМНПРСТФХЦЖШBVGDKLMNPRSTFXCZW\s]+$)/;
    var field = useField(props.name, {
        type: props.type,
        validate: function (value) {
            if (!value) {
                return t('Required');
            }
            if (props.name === 'number' && !PNRRegex.test(value)) {
                return t('Invalid number');
            }
            return undefined;
        }
    });
    return (React.createElement(Input, { name: field.input.name, label: props.label, TextFieldProps: {
            onChange: field.input.onChange,
            error: !!props.errors || (field.meta.submitFailed && field.meta.error),
            helperText: props.errors || (field.meta.submitFailed && field.meta.error)
        } }));
};
export default Field;
