import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFlightsListSchedule } from './hooks';
import { useTheme } from '../../../../../theme';
import { ChevronRightIcon } from '../../../../../AircraftPopup/components/Icons';
import ScheduleFlight from '../../ScheduleFlight/ScheduleFlight';
import ResultsError from '../../../../../ResultsError';
import { isMonthAvailable } from '../../../../../Datepicker/utils';
import { addMonths, startOfMonth } from 'date-fns';
import FlightsFilter from '../../../../FlightsFilter';
import { getFilteredFlights } from '../../../../FlightsFilter/utils';
import { useOrderedFlights } from '../../../../FlightsFilter/hooks';
import { flightFilterFlightsConverter } from '../../../services/adapters';
var ScheduleList = function (props) {
    var _a;
    var currentLeg = props.currentLeg, onSelect = props.onSelect, currentSearchSegments = props.currentSearchSegments, legsByMonths = props.legsByMonths, setSelectedDate = props.setSelectedDate, selectedDate = props.selectedDate;
    var css = useTheme('Schedule').List;
    var t = useTranslation('Schedule').t;
    var _b = useFlightsListSchedule(setSelectedDate, selectedDate), goBack = _b.goBack, goFurther = _b.goFurther, currentMonthName = _b.currentMonthName;
    var flightsInThisMonth = legsByMonths.size ? __spreadArray([], __read(legsByMonths.values()), false)[0] : [];
    // flights filtration
    var _c = __read(React.useState({}), 2), filterValues = _c[0], setFilterValues = _c[1];
    var filteredFlights = React.useMemo(function () { return getFilteredFlights(flightFilterFlightsConverter(flightsInThisMonth), filterValues); }, [flightsInThisMonth, filterValues]);
    // flights sorting
    var orderedFlightsInThisMonth = useOrderedFlights(flightFilterFlightsConverter(filteredFlights));
    var prevMonthAvailable = isMonthAvailable(selectedDate, -1, startOfMonth(new Date()), addMonths(selectedDate, 1));
    return (React.createElement("div", null,
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.date },
                React.createElement("div", { className: cn(css.control, css.prev, (_a = {}, _a[css.notAvailable] = !prevMonthAvailable, _a)), onClick: goBack }, ChevronRightIcon),
                React.createElement("div", { className: css.monthName }, currentMonthName),
                React.createElement("div", { className: cn(css.control), onClick: goFurther }, ChevronRightIcon)),
            React.createElement(FlightsFilter, { className: css.filter, onChange: setFilterValues })),
        (orderedFlightsInThisMonth === null || orderedFlightsInThisMonth === void 0 ? void 0 : orderedFlightsInThisMonth.length) ? (orderedFlightsInThisMonth === null || orderedFlightsInThisMonth === void 0 ? void 0 : orderedFlightsInThisMonth.map(function (flight, index) { return (React.createElement(ScheduleFlight, { onSelect: onSelect, currentSearchSegments: currentSearchSegments, flight: flight, key: "".concat(index, "_").concat(flight.segments[0].segment.flightNumber, "_").concat(currentLeg, "_").concat(currentMonthName) })); })) : (React.createElement(ResultsError, { header: t("We couldn't find any flights for given dates"), body: t('Please select another date or modify your search.') }))));
};
export default ScheduleList;
