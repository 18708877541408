import { __assign } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import BaseCheckout, { PaymentResultMethodType } from '../../Checkout/components/Checkout/Checkout';
import { useHeader } from '../../Header/hooks';
import Page from '../Page/Page';
import SearchData from '../../Header/components/SearchData/SearchData';
import Routes from '../../Header/components/Routes/Routes';
import { getIsNew, getSearchParameters } from '../../Checkout/store/order/selectors';
import { ConsoleType } from '../Console/types';
import { useGraphQLClient } from '../../graphql-client';
import { GuardedApolloProvider } from '../../GuardedApolloProvider';
var Checkout = function (props) {
    var _a = useHeader(), isOpen = _a.isOpen, toggle = _a.toggle;
    var searchParams = useSelector(getSearchParameters);
    var isNew = useSelector(getIsNew);
    var consoleType = isNew ? ConsoleType.PASSENGERS : ConsoleType.SEARCH;
    var client = useGraphQLClient();
    return (React.createElement(GuardedApolloProvider, { client: client },
        React.createElement(Page, { consoleProps: {
                type: consoleType,
                onStartSearch: function (values) {
                    if (consoleType === ConsoleType.SEARCH) {
                        props.startNewSearch(values);
                    }
                    else {
                        console.log(values);
                    }
                }
            }, renderRoutes: function () {
                return searchParams ? (React.createElement("div", { onClick: toggle },
                    React.createElement(SearchData, { searchParameters: searchParams }))) : (React.createElement(Routes, null));
            }, onSubmit: props.startNewSearch, isOpen: isOpen },
            React.createElement(BaseCheckout, __assign({}, props, { paymentResultMethodType: PaymentResultMethodType.Snackbar, toogleHeader: toggle, isHeaderOpen: isOpen })))));
};
export default Checkout;
