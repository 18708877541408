import * as React from 'react';
import cn from 'classnames';
import Button from '../../Button';
import Segment from './Segment/Segment';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
initI18n('DesktopFlightInfo');
var FlightInfo = function (_a) {
    var _b;
    var flight = _a.flight, onButtonClick = _a.onButtonClick, _c = _a.buttonText, buttonText = _c === void 0 ? '' : _c, _d = _a.buttonType, buttonType = _d === void 0 ? 'common' : _d, renderPreFooter = _a.renderPreFooter;
    var css = useTheme('DesktopFlightInfo').DesktopFlightInfo;
    var getNextSegment = function (index) { return flight.segments[index + 1].segment; };
    return (React.createElement("div", { className: css.info },
        React.createElement("div", { className: css.segments }, flight.segments.map(function (_a, index) {
            var segment = _a.segment, transferDuration = _a.transferDuration;
            return (React.createElement(Segment, { key: index, segment: segment, transferDuration: transferDuration, nextSegment: transferDuration ? getNextSegment(index) : undefined }));
        })),
        renderPreFooter && renderPreFooter(),
        onButtonClick && (React.createElement("div", { className: css.footer },
            React.createElement(Button, { className: cn(css.button_default, (_b = {},
                    _b[css.button] = buttonType === 'common',
                    _b)), variant: buttonType, onClick: onButtonClick }, buttonText)))));
};
export default FlightInfo;
