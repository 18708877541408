import { __values } from "tslib";
export var flightFilterFlightsConverter = function (flights) {
    function getSegments(flight) {
        var e_1, _a;
        var convSegments = [];
        try {
            for (var _b = __values(flight.segments), _c = _b.next(); !_c.done; _c = _b.next()) {
                var segment = _c.value;
                var convSegment = {
                    flightNumber: segment.flightNumber,
                    operatingAirline: {
                        iata: segment.operatingAirline.iata
                    },
                    duration: segment.duration
                };
                convSegments.push(convSegment);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return convSegments;
    }
    return flights.map(function (flight) {
        var convFlight = {
            segments: getSegments(flight),
            source: flight
        };
        return convFlight;
    });
};
