import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useTheme } from '../../../../../theme';
import Button from '../../../../../Button/Button';
import Modal from '../../../../../Modal';
var Warning = function (_a) {
    var open = _a.open, onClose = _a.onClose, redirectURL = _a.redirectURL;
    var css = useTheme('Checkout').Warning;
    var t = useTranslation('Checkout').t;
    var onClick = useCallback(function () { return (window.location.href = redirectURL); }, [redirectURL]);
    return (React.createElement(Modal, { maxWidth: "sm", open: open, onClose: onClose },
        React.createElement("div", { className: css.wrapper },
            React.createElement("h1", { className: css.title }, t('Next step')),
            React.createElement("p", { className: css.text }, t('Now you will be redirected to the previous version of the site. There you can choose ancillary services.')),
            React.createElement("div", { className: css.footer },
                React.createElement(Button, { className: css.button, onClick: onClick }, t('Continue'))))));
};
export default Warning;
