import { __assign, __rest } from "tslib";
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPassengerState } from '../../../../Checkout/store/passengers/selectors';
import { getPassengerStateCompatible } from '../../../../Checkout/store/selectedServices/selectors';
import { getOrder, isBookedOrConfirmed } from '../../../../Checkout/store/order/selectors';
import OrderPassengersComponent from '../../../../OrderCard/components/OrderPassengers/OrderPassengers';
import SplitPassengersWrapper from '../../../SplitPassengers/SplitPassengersWrapper/SplitPassengersWrapper';
import { useTheme } from '../../../../theme';
var OrderPassengers = function (_a) {
    var refetchOrder = _a.refetchOrder, openOrder = _a.openOrder, props = __rest(_a, ["refetchOrder", "openOrder"]);
    var css = useTheme('OrderCard').OrderPassengers;
    var t = useTranslation('Checkout').t;
    var order = useSelector(getOrder);
    var passengers = useSelector(getPassengerStateCompatible);
    var passengerStateWithoutServices = useSelector(getPassengerState);
    var isBookedOrConfirm = useSelector(isBookedOrConfirmed);
    var renderTrigger = useCallback(function (onClick) { return (React.createElement("span", { className: css.control, onClick: onClick }, t('Split order'))); }, []);
    return (React.createElement(OrderPassengersComponent, __assign({}, props, { buttons: isBookedOrConfirm &&
            order.canSplit &&
            passengers.length > 1 && (React.createElement(SplitPassengersWrapper, { passengers: passengerStateWithoutServices, orderId: order.id, onSuccess: refetchOrder, openOrder: openOrder, renderTrigger: renderTrigger })) })));
};
export default OrderPassengers;
