import { __read } from "tslib";
import { useState } from 'react';
import { useToggleable } from '../../hooks';
export var useSnackbar = function () {
    var _a = useToggleable(false), isSnackbarOpen = _a.isOpen, openSnackBar = _a.open, closeSnackbar = _a.close;
    var _b = __read(useState(), 2), message = _b[0], setMessage = _b[1];
    var _c = __read(useState(), 2), isError = _c[0], setIsError = _c[1];
    var openHandler = function (message, isErrorMessage) {
        if (isErrorMessage === void 0) { isErrorMessage = false; }
        setIsError(isErrorMessage);
        setMessage(message);
        openSnackBar();
    };
    return {
        open: openHandler,
        close: closeSnackbar,
        isOpen: isSnackbarOpen,
        isError: isError,
        message: message
    };
};
