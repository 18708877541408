import { __assign, __awaiter, __generator, __read, __rest } from "tslib";
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Modal from '../../../Modal';
import Input from '../../../Input';
import Button from '../../../Button/Button';
import { useTheme } from '../../../theme';
import { useRetrieveBookingValidation } from '../../hooks';
var ClarifyDocumentModal = function (_a) {
    var onSubmit = _a.onSubmit, searchParams = _a.searchParams, props = __rest(_a, ["onSubmit", "searchParams"]);
    var t = useTranslation('RetrieveBooking').t;
    var css = useTheme('RetrieveBooking').ClarifyDocumentModal;
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read(useState(false), 2), ticketIsWrong = _c[0], setTicketIsWrong = _c[1];
    var ticketNumberValidation = useRetrieveBookingValidation().ticketNumberValidation;
    var handleSubmit = function (_a) {
        var extraSecret = _a.extraSecret;
        return __awaiter(void 0, void 0, void 0, function () {
            var result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setTicketIsWrong(false);
                        setIsLoading(true);
                        return [4 /*yield*/, onSubmit(__assign(__assign({}, searchParams), { extraSecret: extraSecret }))];
                    case 1:
                        result = _b.sent();
                        if (result) {
                            props.onClose();
                        }
                        else {
                            setTicketIsWrong(true);
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    return (React.createElement(Modal, __assign({}, props, { maxWidth: "sm" }),
        React.createElement("div", { className: css.content },
            React.createElement("h4", { className: css.header }, t('Enter ticket number')),
            React.createElement("p", { className: css.message },
                t('Please enter ticket number for passenger you would like to check-in'),
                "."),
            React.createElement(Form, { onSubmit: handleSubmit }, function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit, className: css.form },
                    React.createElement(Field, { name: "extraSecret", validate: function (value) {
                            if (!value) {
                                return t('Required field');
                            }
                            return ticketNumberValidation(value);
                        }, render: function (_a) {
                            var input = _a.input, meta = _a.meta;
                            return (React.createElement(Input, { disabled: isLoading, absoluteHintPosition: true, label: t('Ticket number'), TextFieldProps: __assign(__assign({}, input), { helperText: (ticketIsWrong && t('Passenger not found')) ||
                                        (((meta.modified && meta.touched) || meta.submitFailed) &&
                                            (meta.error || meta.submitError)), error: ticketIsWrong ||
                                        (((meta.modified && meta.touched) || meta.submitFailed) &&
                                            (!!meta.error || !!meta.submitError)) }) }));
                        } }),
                    React.createElement(Button, { type: "submit", isLoading: isLoading, className: css.submit }, t('Open'))));
            }))));
};
export default ClarifyDocumentModal;
