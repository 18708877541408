import { __assign, __rest } from "tslib";
import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Money } from '../../../../../Money';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useIsTerminal } from '../../../../../hooks';
var SeatTooltip = React.memo(function (_a) {
    var _b;
    var seat = _a.seat, props = __rest(_a, ["seat"]);
    var theme = useTheme('SeatMap').SeatTooltipStyles;
    var t = useTranslation('SeatMap').t;
    var isTerminal = useIsTerminal();
    var isSelected = props.occupiedBy || props.isSelectedByOtherOrderPassenger;
    var withInfo = ((_b = seat.restrictions) === null || _b === void 0 ? void 0 : _b.length) > 0;
    var renderTooltip = function (props) {
        var _a;
        var _b, _c, _d, _e;
        return (React.createElement(Tooltip, { classes: {
                tooltip: cn(theme.tooltip, (_a = {},
                    _a[theme.tooltip_withInfo] = withInfo,
                    _a[theme.tooltip_recommend] = props.isRecommend,
                    _a)),
                popper: theme.popper,
                tooltipPlacementBottom: theme.tooltip_bottom
            }, placement: "top", title: React.createElement("div", null,
                React.createElement("div", { className: theme.container },
                    React.createElement("div", null, props.seat.number),
                    !isSelected && (React.createElement("div", null, props.seat.price.amount === 0 && isTerminal ? (t('Free')) : (React.createElement(Money, { money: props.seat.price })))),
                    React.createElement("div", { className: theme.details },
                        React.createElement("span", null, (_b = props.service) === null || _b === void 0 ? void 0 :
                            _b.name,
                            isSelected && (React.createElement(React.Fragment, null,
                                ((_c = props.service) === null || _c === void 0 ? void 0 : _c.name) && ' - ',
                                ((_d = props.seat) === null || _d === void 0 ? void 0 : _d.price.amount) === 0 && isTerminal ? (t('Free')) : (React.createElement(Money, { money: (_e = props.seat) === null || _e === void 0 ? void 0 : _e.price }))))))),
                withInfo && (React.createElement("div", { className: theme.info },
                    React.createElement("div", { className: theme.info__title },
                        t('This seat is forbidden for'),
                        ":"),
                    seat.restrictions && seat.restrictions.length && (React.createElement("ul", null, seat.restrictions.map(function (restriction) { return (React.createElement("li", { key: restriction }, t("Restriction_".concat(restriction)))); })))))) }, props.children));
    };
    return props.isAvailable && seat.isAvailable && seat.isExistent
        ? renderTooltip(__assign({}, __assign({ seat: seat }, props)))
        : props.children;
});
export default SeatTooltip;
