import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useState, useMemo, useRef, useCallback } from 'react';
import cn from 'classnames';
import { differenceInHours } from 'date-fns';
import Message from './Message/Message';
import Enter from './Enter/Enter';
import { useTheme } from '../../../theme';
import { useToggleable } from '../../../hooks';
import Header from './Header/Header';
import { TerminalDB } from '../TerminalDB';
import TerminalForm from '../../TerminalForm/TerminalForm';
import Snackbar from '../../Snackbar/Snackbar';
import SupportModal from './SupportModal/SupportModal';
import { getMessagePagination } from '../utils';
var Reducer = function (state, action) {
    if (state === void 0) { state = []; }
    if (action.type === 'SEND') {
        return __spreadArray(__spreadArray([], __read(state), false), [
            {
                id: action.id,
                request: action.request,
                requestDate: new Date(),
                values: action.values
            }
        ], false);
    }
    else if (action.type === 'WRITE_RESPONSE') {
        return state.map(function (item) {
            if (item.id === action.id) {
                return __assign(__assign({}, item), { response: action.response, errorInResponse: action.errorInResponse, responseDate: action.responseDate });
            }
            return item;
        });
    }
    else if (action.type === 'HIDE') {
        return state.map(function (item) { return (__assign(__assign({}, item), { hidden: true })); });
    }
    else if (action.type === 'CLEAR_MEMORY') {
        return state.slice(10);
    }
    else if (action.type === 'INIT_STATE') {
        return action.messagesState;
    }
    return [];
};
var Terminal = function (props) {
    var _a;
    var _b;
    var _c = __read(useState(null), 2), terminalDB = _c[0], setTerminalDB = _c[1];
    var _d = __read(useState(null), 2), selectedRequest = _d[0], setSelectedRequest = _d[1];
    var _e = __read(useState(false), 2), isPaginationMessage = _e[0], setIsPaginationMessage = _e[1];
    var _f = __read(useState(false), 2), isHistoryModal = _f[0], setIsHistoryModal = _f[1];
    var _g = __read(useState(false), 2), isClearModal = _g[0], setIsClearModal = _g[1];
    var _h = __read(useState(false), 2), isNewMessage = _h[0], setIsNewMessage = _h[1];
    var _j = __read(useState(null), 2), extras = _j[0], setExtras = _j[1];
    var _k = __read(useState(false), 2), isPdfModal = _k[0], setIsPdfModal = _k[1];
    var topContainer = useRef();
    var enterContainer = useRef();
    var _l = __read(React.useReducer(Reducer, []), 2), state = _l[0], dispatch = _l[1];
    var _m = useToggleable(false), isBlocked = _m.isOpen, lock = _m.open, unlock = _m.close;
    var _o = __read(React.useState(null), 2), terminalFormData = _o[0], setTerminalFormData = _o[1];
    var _p = __read(React.useState(false), 2), terminalFormSuccessResult = _p[0], setTerminalFormResult = _p[1];
    var _q = __read(React.useState(true), 2), isSupportIndexedDB = _q[0], setIsSupportIndexedDB = _q[1];
    var clearMemory = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var messages, exceedsMemory;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, terminalDB.entries()];
                case 1:
                    messages = _a.sent();
                    exceedsMemory = messages.length > 120;
                    if (!exceedsMemory) return [3 /*break*/, 3];
                    return [4 /*yield*/, Promise.all(messages.map(function (_a, index) {
                            var _b = __read(_a, 2), key = _b[0], value = _b[1];
                            var message = value;
                            var differenceHours = differenceInHours(new Date(), message.responseDate);
                            if (differenceHours > 24 || (exceedsMemory && index <= 10)) {
                                return terminalDB.delete(key);
                            }
                            return null;
                        }))];
                case 2:
                    _a.sent();
                    dispatch({ type: 'CLEAR_MEMORY' });
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [terminalDB]);
    React.useEffect(function () {
        var terminalFactory = function () { return __awaiter(void 0, void 0, void 0, function () {
            var terminal, e_1, messages, exceedsMemory;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new TerminalDB()];
                    case 1:
                        terminal = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, terminal.init()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        setIsSupportIndexedDB(false);
                        return [3 /*break*/, 5];
                    case 5: return [4 /*yield*/, terminal.entries()];
                    case 6:
                        messages = _a.sent();
                        exceedsMemory = messages.length > 120;
                        return [4 /*yield*/, Promise.all(messages.map(function (_a, index) {
                                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                                var message = value;
                                var differenceHours = differenceInHours(new Date(), message.responseDate);
                                if (differenceHours > 24 || (exceedsMemory && index <= 10)) {
                                    return terminal.delete(key);
                                }
                                return null;
                            }))];
                    case 7:
                        _a.sent();
                        setTerminalDB(terminal);
                        return [2 /*return*/];
                }
            });
        }); };
        if (!terminalDB) {
            terminalFactory();
        }
        else {
            terminalDB.entries().then(function (messages) {
                var messagesState = messages.map(function (message) { return message[1]; });
                dispatch({ type: 'INIT_STATE', messagesState: messagesState });
            });
        }
    }, [terminalDB]);
    React.useEffect(function () {
        if (terminalFormData) {
            lock();
        }
        else {
            unlock();
        }
    }, [terminalFormData]);
    var onSendHandler = function (data, values) { return __awaiter(void 0, void 0, Promise, function () {
        var requestUppercase, id, request;
        return __generator(this, function (_a) {
            requestUppercase = data.trim().toUpperCase();
            setTimeout(function () {
                enterContainer.current.scrollIntoView({ block: 'end' });
            }, 0);
            id = state.length;
            lock();
            request = {
                id: id,
                request: requestUppercase,
                requestDate: new Date(),
                values: values
            };
            dispatch(__assign({ type: 'SEND' }, request));
            return [2 /*return*/, props.sendRequest(requestUppercase, values).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                    var responseObject, hasPagination;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                unlock();
                                responseObject = __assign(__assign({}, request), { response: response.json ? '' : response.message, errorInResponse: response.error, responseDate: new Date() });
                                if (response.json) {
                                    setTerminalFormData(response.message);
                                }
                                else {
                                    setTerminalFormData(null);
                                }
                                if (response.extras) {
                                    setExtras(response.extras);
                                }
                                else {
                                    setExtras(null);
                                }
                                return [4 /*yield*/, clearMemory()];
                            case 1:
                                _a.sent();
                                hasPagination = getMessagePagination(responseObject).hasPagination;
                                if (!hasPagination) {
                                    unlock();
                                }
                                dispatch(__assign({ type: 'WRITE_RESPONSE' }, responseObject));
                                setIsNewMessage(true);
                                return [4 /*yield*/, terminalDB.set(responseObject)];
                            case 2:
                                _a.sent();
                                return [2 /*return*/, response];
                        }
                    });
                }); })];
        });
    }); };
    var getHistoryCommand = React.useCallback(function () {
        var key = state.length;
        return function (direction) {
            if (direction === 'top') {
                if (key > 0) {
                    key--;
                    return state[key].request;
                }
            }
            else if (key < state.length - 1) {
                key++;
                return state[key].request;
            }
            return null;
        };
    }, [state]);
    var onSuccess = function () {
        setTerminalFormResult(true);
        setTerminalFormData(null);
        setTimeout(function () {
            setTerminalFormResult(false);
        }, 2000);
    };
    var theme = useTheme('Terminal').Terminal;
    var onClearTerminal = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var entries, IDBValues;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, terminalDB.entries()];
                case 1:
                    entries = _a.sent();
                    return [4 /*yield*/, Promise.all(entries.map(function (_a) {
                            var _b = __read(_a, 2), key = _b[0], value = _b[1];
                            return terminalDB.set(__assign(__assign({}, value), { hidden: true }), key);
                        }))];
                case 2:
                    IDBValues = _a.sent();
                    if (IDBValues.length) {
                        dispatch({ type: 'HIDE' });
                    }
                    unlock();
                    setIsPaginationMessage(false);
                    return [2 /*return*/, IDBValues];
            }
        });
    }); }, [terminalDB, unlock, dispatch]);
    var visibleMessages = useMemo(function () { return state.filter(function (message) { return !message.hidden; }); }, [state]);
    var isLastMessageEven = visibleMessages.length && visibleMessages.length % 2 !== 0;
    var messagesWithoutLastMessage = visibleMessages.slice(0, -1);
    var lastMessage = (_b = visibleMessages.slice(-1)) === null || _b === void 0 ? void 0 : _b[0];
    var setPaginationMessageHandler = function (isPagination) {
        setIsPaginationMessage(isPagination);
        if (!isPagination) {
            unlock();
            setIsNewMessage(false);
        }
    };
    var clearSelectedRequest = function () {
        setSelectedRequest('');
    };
    var onCloseSupportModal = function () { return setIsSupportIndexedDB(true); };
    var disabledEnter = isBlocked || isPaginationMessage || isHistoryModal || isClearModal || !!terminalFormData || isPdfModal;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { isLocked: isBlocked, messages: state, extras: extras, isHistoryModal: isHistoryModal, isClearModal: isClearModal, setHistoryModal: setIsHistoryModal, setClearModal: setIsClearModal, onSelectRequest: setSelectedRequest, onClose: props.onClose, onClear: onClearTerminal }),
        !!terminalFormData && (React.createElement(TerminalForm, { hostCommand: terminalFormData, onSumbit: function (values) {
                return onSendHandler(getHistoryCommand()('top'), values);
            }, onClose: function () { return setTerminalFormData(null); }, onSuccess: onSuccess })),
        React.createElement(Snackbar, { open: terminalFormSuccessResult, onClose: function () { return setTerminalFormResult(null); }, message: 'Success' }),
        React.createElement("div", { ref: topContainer }),
        messagesWithoutLastMessage.map(function (item) { return (React.createElement(Message, { className: theme.item, message: item, key: item.id })); }),
        React.createElement("div", { className: cn(theme.enterSection, (_a = {},
                _a[theme.enterSection_even] = isLastMessageEven,
                _a)), ref: enterContainer },
            lastMessage && (React.createElement(Message, { key: lastMessage.id, className: theme.item, message: lastMessage, onChangePagination: setPaginationMessageHandler, isLastMessage: isNewMessage, documents: extras === null || extras === void 0 ? void 0 : extras.documents, onOpenPdf: setIsPdfModal })),
            React.createElement(Enter, { disabled: disabledEnter, className: cn(theme.item, theme.item_enter), onEnterHandler: onSendHandler, getHistoryCommand: getHistoryCommand, selectedRequest: selectedRequest, clearSelectedRequest: clearSelectedRequest, topContainer: topContainer })),
        React.createElement(SupportModal, { open: !isSupportIndexedDB, onClose: onCloseSupportModal })));
};
export default Terminal;
