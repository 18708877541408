import { __assign } from "tslib";
import * as React from 'react';
import MediaQuery from '../../../../../MediaQuery/MediaQuery';
import cn from 'classnames';
import { SeatComfort } from '../../../../../__generated__/globalTypes';
import { findPassengerWithThisSeat } from '../../Seat/utils';
import { useTheme } from '../../../../../theme';
import Seat from '../../Seat/Seat';
import { getSeatAvailability } from './utils';
import Money from '../../../../../Money';
import { useConfig } from '../../../../../context';
import { useTranslation } from 'react-i18next';
import { useIsTerminal } from '../../../../../hooks';
var Parts = React.memo(function (_a) {
    var _b, _c, _d;
    var _e, _f, _g, _h, _j, _k;
    var part = _a.part, nextPartService = _a.nextPartService, prevPartService = _a.prevPartService, partIndex = _a.partIndex, passengerIndex = _a.passengerIndex, passengerId = _a.passengerId, serviceId = _a.serviceId, segmentId = _a.segmentId, isSeatMapWide = _a.isSeatMapWide, passengers = _a.passengers, services = _a.services, partService = _a.partService, isCheckinMode = _a.isCheckinMode, onSelect = _a.onSelect, newClass = _a.newClass, onClear = _a.onClear, isStatic = _a.isStatic;
    var theme = useTheme('SeatMap').SeatMapStyles;
    var t = useTranslation('SeatMap').t;
    var nextPartServiceId = nextPartService === null || nextPartService === void 0 ? void 0 : nextPartService.comfort;
    var prevPartServiceId = prevPartService === null || prevPartService === void 0 ? void 0 : prevPartService.comfort;
    var actualService = (_f = (_e = services === null || services === void 0 ? void 0 : services[segmentId]) === null || _e === void 0 ? void 0 : _e[passengerId]) === null || _f === void 0 ? void 0 : _f[(_g = part.seats[0]) === null || _g === void 0 ? void 0 : _g.rfisc];
    var iataCode = useConfig().global.companyInfo.iataCode;
    var isScat = iataCode === 'DV';
    var isTerminal = useIsTerminal();
    return (React.createElement("div", { className: cn(theme.row__rowPartWrp, (_b = {},
            _b[theme.row__seatWrapper_newClass] = newClass,
            _b[theme.row__rowPartWrp_aisle] = (_h = part.service) === null || _h === void 0 ? void 0 : _h.isAisle,
            _b[theme.row__rowPartWrp_static] = isStatic,
            _b)), key: partIndex },
        React.createElement(MediaQuery, { minWidth: 'tablet' },
            React.createElement("div", { className: cn(theme.row__seatWrapper, (_c = {},
                    _c[theme.row__seatWrapper_boundaryRight] = partService !== nextPartServiceId,
                    _c[theme.row__seatWrapper_boundaryLeft] = partService !== prevPartServiceId,
                    _c[theme.row__seatWrapper_service_business] = partService === SeatComfort.Comfort,
                    _c[theme.row__seatWrapper_service_preferred] = partService === SeatComfort.Preferred,
                    _c[theme.row__seatWrapper_service_standard] = partService === SeatComfort.Standart,
                    _c[theme.row__seatWrapper_service_business_wide] = partService === SeatComfort.Comfort && isSeatMapWide,
                    _c[theme.row__seatWrapper_service_preferred_wide] = partService === SeatComfort.Preferred && isSeatMapWide,
                    _c[theme.row__seatWrapper_service_standard_wide] = partService === SeatComfort.Standart && isSeatMapWide,
                    _c)), "data-service-id": serviceId })),
        newClass && actualService && (React.createElement("div", { className: cn(theme.row__serviceHeader, (_d = {},
                _d[theme.row__serviceHeader_comfort] = actualService.comfort === SeatComfort.Comfort,
                _d[theme.row__serviceHeader_preferred] = actualService.comfort === SeatComfort.Preferred,
                _d)) },
            React.createElement("div", { className: theme.row__serviceHeader__wrapper },
                t("SeatMap_".concat(actualService === null || actualService === void 0 ? void 0 : actualService.comfort)),
                " -",
                ' ',
                isTerminal && ((_j = part.service) === null || _j === void 0 ? void 0 : _j.minPrice.amount) === 0 ? (t('Free')) : (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: theme.row_serviceHeader_from }, t('from')),
                    React.createElement(Money, { money: (_k = part.service) === null || _k === void 0 ? void 0 : _k.minPrice })))))),
        part.seats.map(function (seat) {
            var _a, _b, _c, _d;
            var currentPassenger = passengers.find(function (passenger) { return passenger.id === passengerIndex.toString(10); });
            var selectedSeat = (_a = currentPassenger.seats) === null || _a === void 0 ? void 0 : _a.find(function (seat) { return seat.segmentId === segmentId; });
            // more than one service with this rfisc
            if (seat === null || seat === void 0 ? void 0 : seat.seatService) {
                var actualService_1 = (_c = (_b = services === null || services === void 0 ? void 0 : services[segmentId]) === null || _b === void 0 ? void 0 : _b[passengerId]) === null || _c === void 0 ? void 0 : _c[seat.rfisc];
                if (actualService_1 && !isScat && !isTerminal) {
                    seat.service = __assign({}, actualService_1);
                    seat.seatService = __assign({}, actualService_1);
                    seat.price = __assign({}, actualService_1.price);
                }
            }
            var hasConfirmedSeat = (_d = currentPassenger.seats) === null || _d === void 0 ? void 0 : _d.some(function (seat) { return (seat === null || seat === void 0 ? void 0 : seat.isConfirmed) && seat.segmentId === segmentId; });
            var isAvailable = getSeatAvailability({
                seat: seat,
                passenger: currentPassenger,
                segmentId: segmentId
            }).isAvailable;
            if (isCheckinMode) {
                if (hasConfirmedSeat) {
                    isAvailable = (selectedSeat === null || selectedSeat === void 0 ? void 0 : selectedSeat.row) === seat.number;
                }
                if (seat === null || seat === void 0 ? void 0 : seat.seatService) {
                    isAvailable = isAvailable && seat.seatService.canBeAdded;
                }
            }
            var occupiedBy = findPassengerWithThisSeat(seat, passengers, segmentId);
            return (React.createElement(Seat, { isSmall: isSeatMapWide, key: seat.number, seat: seat, isAvailable: isAvailable, occupiedBy: occupiedBy, isConfirmed: hasConfirmedSeat && (selectedSeat === null || selectedSeat === void 0 ? void 0 : selectedSeat.row) === seat.number, onSelect: hasConfirmedSeat && (selectedSeat === null || selectedSeat === void 0 ? void 0 : selectedSeat.row) === seat.number ? function () { return null; } : onSelect, passengers: passengers, segmentId: segmentId, passengerId: passengerIndex, onClear: onClear }));
        })));
});
export default Parts;
