import * as React from 'react';
import Calendar from '../../../Modules/Exchange/components/Exchange/SearchFlights/Calendar/Calendar';
import * as css from './SearchFlightsHeader.css';
import WeekCalendar from '../../../Modules/Exchange/components/Exchange/SearchFlights/WeekCalendar/WeekCalendar';
import { createLegs } from '../../../Results/utils';
import ResultsSegments from '../../ResultsSegments/ResultsSegments';
var SearchFlightsHeader = function (_a) {
    var currentLegNumber = _a.currentLegNumber, setSorting = _a.setSorting, showOnlyDirectFlights = _a.showOnlyDirectFlights, onlyDirectFlightToggle = _a.onlyDirectFlightToggle, flightNumber = _a.flightNumber, setFlightNumber = _a.setFlightNumber, searchParams = _a.searchParams, startNewSearch = _a.startNewSearch, sorting = _a.sorting, legs = _a.legs, setLeg = _a.setLeg, minDate = _a.minDate;
    var resultsLegs = React.useMemo(function () {
        return {
            setCurrentLeg: setLeg,
            legs: createLegs(searchParams),
            active: currentLegNumber
        };
    }, [legs]);
    return (React.createElement("div", null,
        React.createElement("div", { className: css.wrapper },
            React.createElement("div", { className: css.filters },
                React.createElement(ResultsSegments, { legs: resultsLegs })),
            React.createElement(Calendar, { searchParams: searchParams, startNewSearch: startNewSearch, legId: currentLegNumber })),
        React.createElement(WeekCalendar, { searchParams: searchParams, currentLeg: currentLegNumber, startNewSearch: startNewSearch, daysCount: 7, minDate: minDate })));
};
export default SearchFlightsHeader;
