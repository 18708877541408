import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import PaymentWrapper from '../../Payment/Components/PaymentWrapper/PaymentWrapper';
import { getLastExchange, getOrder } from '../../../Modules/Exchange/store/order/selectors';
import PricesDetails from '../../PricesDetails/PricesDetails';
import { useMutation } from '@apollo/react-hooks';
import * as UpdateOrderRequest from '../../../__queries__/UpdateOrder.graphql';
import { useEffect } from 'react';
import PromoLoader from '../../../PromoLoader/components/PromoLoader';
import { removeFlightIds } from '../../../Modules/Exchange/store/flightsToExchange/actions';
import { getFlightIdsToExchange } from '../../../Modules/Exchange/store/flightsToExchange/selectors';
import { setOrder } from '../../../Modules/Exchange/store/order/actions';
import DetalizationLine from '../../PricesDetails/DetalizationLine/DetalizationLine';
import * as priceDetailsTheme from '../../PricesDetails/PriceDetails.css';
import { useTranslation } from 'react-i18next';
import { clearFare } from '../../../Modules/Exchange/store/newFlights/actions';
var Payment = function (props) {
    var order = useSelector(getOrder);
    var lastRequest = useSelector(getLastExchange);
    var flightIdsToExchange = useSelector(getFlightIdsToExchange);
    var dispatch = useDispatch();
    var t = useTranslation('Exchange').t;
    var _a = __read(useMutation(UpdateOrderRequest.UpdateOrder, { variables: { id: order.id } }), 2), updateOrder = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.UpdateOrder) {
            dispatch(setOrder(data.UpdateOrder));
        }
    }, [data]);
    if (loading) {
        return React.createElement(PromoLoader, null);
    }
    return (React.createElement(PaymentWrapper, { mode: 'exchange', paymentMethods: props.paymentMethods, renderSummary: function () { return (React.createElement(PricesDetails, { prices: lastRequest.prices, renderAdditionalFields: function () { return (React.createElement(DetalizationLine, { total: true, className: priceDetailsTheme.item_first, price: lastRequest.prices.priceToPay, title: t('Residual amount') })); } })); }, aviaOrderId: order.id, orderId: lastRequest.id, redirectPathname: '/success', priceToPay: order.priceToPay, onSuccess: function () { return null; }, onExchangePaymentSuccess: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dispatch(removeFlightIds(flightIdsToExchange));
                        return [4 /*yield*/, updateOrder()];
                    case 1:
                        _a.sent();
                        props.successPayment();
                        props.goToOrder();
                        return [2 /*return*/];
                }
            });
        }); }, onExchangePaymentFail: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        batch(function () {
                            dispatch(removeFlightIds(flightIdsToExchange));
                            dispatch(clearFare());
                        });
                        return [4 /*yield*/, updateOrder()];
                    case 1:
                        _a.sent();
                        props.failedPayment();
                        props.goToOrder();
                        return [2 /*return*/];
                }
            });
        }); }, goToPrevStep: function () { return null; } }));
};
export default Payment;
