import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import cn from 'classnames';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import Input from '../../../Input';
import { codeSuccessIcon, successIcon } from '../../Icons';
import { emailRegex, MOBILE_MIN_WIDTH, phoneRegex } from '../../../utils';
import { Statuses } from '../../types';
import Timer from '../../../Timer';
import { clearPhoneNumber } from '../../../LoginPage/utils';
import { useTheme } from '../../../theme';
import * as AccountQueries from '../../../__queries__/Account.graphql';
import PhoneInput from '../../../PhoneInput';
import { LoginType } from '../../../__generated__/globalTypes';
export var InfoField = function (props) {
    var _a;
    var css = useTheme('Account').InfoField, t = useTranslation('Account').t;
    var _b = __read(useState(props.value), 2), inputValue = _b[0], setInputValue = _b[1], _c = __read(useState(false), 2), saveClicked = _c[0], setSaveClicked = _c[1], _d = __read(useState(false), 2), resendClicked = _d[0], setResendClicked = _d[1], _e = __read(useState(null), 2), error = _e[0], setError = _e[1], _f = __read(useState(null), 2), verificationError = _f[0], setVerificationError = _f[1];
    var _g = __read(useMutation(AccountQueries.SaveMethod), 1), saveMethod = _g[0];
    var _h = __read(useMutation(AccountQueries.ConfirmMethod), 1), confirmMethod = _h[0];
    var checkAccountExistence = useQuery(AccountQueries.AccountExistence, {
        skip: true
    });
    var valueConfirmed = props.status === Statuses.Success || (props.value === inputValue && props.confirmed), valueNeedToConfirm = props.value && props.value === inputValue && props.status < Statuses.Verification && !props.confirmed;
    var onSaveClick = function (fieldName, value) { return __awaiter(void 0, void 0, void 0, function () {
        var isAccountExistence, _a, data, errors, err_1;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setSaveClicked(true);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, checkAccountExistence.refetch({
                            params: { login: fieldName === LoginType.Phone ? clearPhoneNumber(value) : value, loginType: fieldName }
                        })];
                case 2:
                    isAccountExistence = _d.sent();
                    if ((_c = (_b = isAccountExistence === null || isAccountExistence === void 0 ? void 0 : isAccountExistence.data) === null || _b === void 0 ? void 0 : _b.AccountExistence) === null || _c === void 0 ? void 0 : _c.result) {
                        throw Error("".concat(fieldName, " is already in use by another account"));
                    }
                    return [4 /*yield*/, saveMethod({
                            variables: {
                                params: {
                                    loginType: fieldName,
                                    login: fieldName === LoginType.Phone ? clearPhoneNumber(value) : value
                                }
                            }
                        })];
                case 3:
                    _a = _d.sent(), data = _a.data, errors = _a.errors;
                    if (errors && errors.length) {
                        setError(errors[0]);
                    }
                    else if (data.SaveAuthMethod && data.SaveAuthMethod.result) {
                        props.setStep(props.fieldName, Statuses.Verification);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _d.sent();
                    setError(err_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var resendCode = function (login, loginType) {
        return saveMethod({
            variables: {
                params: {
                    login: loginType === LoginType.Phone ? clearPhoneNumber(login) : login,
                    loginType: loginType
                }
            }
        });
    };
    var validate = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var errors;
        return __generator(this, function (_a) {
            errors = {};
            if (values.hasOwnProperty('email')) {
                if (!values.email) {
                    errors.email = 'Required';
                }
                else if (!emailRegex.test(values.email)) {
                    errors.email = t('Please enter a valid email');
                }
            }
            if (values.hasOwnProperty('phone')) {
                if (!values.phone) {
                    errors.phone = 'Required';
                }
                else if (!phoneRegex.test(values.phone)) {
                    errors.phone = t('Please enter a valid phone');
                }
            }
            return [2 /*return*/, errors];
        });
    }); };
    var onSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (valueNeedToConfirm || (props.value !== inputValue && props.status < Statuses.Verification)) {
                        onSaveClick(props.fieldName, inputValue);
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    props.setStep(props.fieldName, Statuses.VerificationLoading);
                    return [4 /*yield*/, confirmMethod({
                            variables: {
                                params: {
                                    login: values.email ? values.email : clearPhoneNumber(values.phone),
                                    loginType: values.email ? LoginType.Email : LoginType.Phone,
                                    secureCode: values.email ? values.emailCode : values.phoneCode
                                }
                            }
                        })];
                case 2:
                    res = _a.sent();
                    if (res.data.ConfirmAuthMethod.result) {
                        props.setStep(props.fieldName, Statuses.VerificationSuccess);
                        setTimeout(function () {
                            props.refetchInfo();
                            props.setStep(props.fieldName, Statuses.Success);
                        }, 2000);
                    }
                    else {
                        props.setStep(props.fieldName, Statuses.VerificationError);
                        setVerificationError(Error(res.data.ConfirmAuthMethod.message));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    setVerificationError(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Form, { validate: validate, onSubmit: onSubmit, initialValues: (_a = {}, _a[props.fieldName.toLowerCase()] = props.value, _a), render: function (_a) {
            var _b;
            var handleSubmit = _a.handleSubmit, values = _a.values;
            return (React.createElement("form", { className: css.account__form, onSubmit: handleSubmit },
                React.createElement("div", { className: css.input_tooltip__wrapper },
                    React.createElement(Field, { name: props.fieldName.toLowerCase(), render: function (_a) {
                            var _b;
                            var input = _a.input, meta = _a.meta;
                            return (React.createElement("div", { className: cn(css.input, (_b = {},
                                    _b[css.opened__tooltip] = (!values[props.fieldName.toLowerCase()] && !saveClicked) ||
                                        (props.confirmed === false && !saveClicked),
                                    _b)) }, props.fieldName === LoginType.Phone ? (React.createElement(PhoneInput, __assign({}, input, { textFieldsProps: {
                                    label: t(props.fieldName),
                                    disabled: props.confirmed,
                                    error: (inputValue && !inputValue.endsWith('_') && meta.error) || error,
                                    InputProps: {
                                        value: inputValue || props.value,
                                        inputComponent: 'input',
                                        endAdornment: valueConfirmed ? (React.createElement("span", { className: css.indicator }, successIcon)) : valueNeedToConfirm ? (React.createElement("span", { className: css.input__button, onClick: function () {
                                                onSaveClick(props.fieldName, inputValue);
                                            } }, t('Confirm'))) : props.status === Statuses.default &&
                                            !meta.error &&
                                            meta.dirty ? (React.createElement("span", { className: css.input__button, onClick: function () {
                                                onSaveClick(props.fieldName, inputValue);
                                            } }, t('Save'))) : props.status === Statuses.Loading ? (React.createElement("span", { className: cn(css.indicator, css.loader) })) : null
                                    },
                                    helperText: (inputValue && !inputValue.endsWith('_') && meta.error) ||
                                        (error && t(error.message.replace('Network error: ', ''))),
                                    onChange: function (event) {
                                        input.onChange(event);
                                        setError(null);
                                        if (typeof event === 'string') {
                                            setInputValue(event);
                                        }
                                    }
                                } }))) : (React.createElement(Input, { TextFieldProps: {
                                    label: t(props.fieldName),
                                    disabled: props.confirmed,
                                    error: (inputValue && !inputValue.endsWith('_') && meta.error) || error,
                                    InputProps: {
                                        value: inputValue || props.value,
                                        inputComponent: 'input',
                                        className: css.inputBase,
                                        endAdornment: valueConfirmed ? (React.createElement("span", { className: css.indicator }, successIcon)) : valueNeedToConfirm ? (React.createElement("span", { className: css.input__button, onClick: function () {
                                                onSaveClick(props.fieldName, inputValue);
                                                setSaveClicked(true);
                                            } }, t('Confirm'))) : props.status === Statuses.default &&
                                            !meta.error &&
                                            meta.dirty ? (React.createElement("span", { className: css.input__button, onClick: function () {
                                                onSaveClick(props.fieldName, inputValue);
                                                setSaveClicked(true);
                                            } }, t('Save'))) : props.status === Statuses.Loading ? (React.createElement("span", { className: cn(css.indicator, css.loader) })) : null
                                    },
                                    helperText: (inputValue && !inputValue.endsWith('_') && meta.error) ||
                                        (error && t(error.message.replace('Network error: ', ''))),
                                    onChange: function (event) {
                                        input.onChange(event);
                                        setError(null);
                                        setInputValue(event.target.value);
                                    }
                                } }))));
                        } }),
                    ((!values[props.fieldName.toLowerCase()] && !saveClicked) ||
                        (props.confirmed === false && !saveClicked)) && (React.createElement("div", { className: cn(css.tooltip, (_b = {},
                            _b[css.phone__tooltip] = props.fieldName === LoginType.Phone,
                            _b[css.phone__confirm__tooltip] = props.fieldName === LoginType.Phone && !props.confirmed,
                            _b)) },
                        React.createElement("h1", null, props.tooltip.title),
                        React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
                            React.createElement("p", null, props.tooltip.message))))),
                props.status >= Statuses.Verification && (React.createElement(Field, { name: "".concat(props.fieldName.toLowerCase(), "Code"), type: 'text', render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        return (React.createElement("div", { className: css.confirm__input__wrapper },
                            React.createElement(Input, __assign({ className: css.confirm__input }, input, { TextFieldProps: {
                                    label: t('Confirmation code'),
                                    InputProps: {
                                        endAdornment: props.status === Statuses.VerificationSuccess ? (React.createElement("span", { className: css.indicator }, codeSuccessIcon)) : props.status === Statuses.VerificationLoading ? (React.createElement("span", { className: cn(css.indicator, css.loader) })) : meta.dirty ? (React.createElement("button", { className: css.input__button, type: "submit" }, t('Send'))) : null
                                    },
                                    helperText: (verificationError &&
                                        t(verificationError.message.replace('Network error: ', ''))) ||
                                        (!resendClicked && t(props.confirmationMsg)),
                                    error: props.status === Statuses.VerificationError || !!verificationError,
                                    onChange: function (event) {
                                        input.onChange(event);
                                        setVerificationError(null);
                                        props.setStep(props.fieldName, Statuses.Verification);
                                    }
                                } })),
                            React.createElement("div", { className: css.resendTimer },
                                React.createElement(Timer, { resendCode: function () {
                                        setResendClicked(true);
                                        return resendCode(inputValue, props.fieldName);
                                    } }))));
                    } }))));
        } }));
};
