import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Radio } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import cn from 'classnames';
import { parse } from 'date-fns';
import { useTheme, ThemeProvider } from '../../../../../theme';
import Datepicker from '../Datepicker/Datepicker';
import { format } from 'date-fns';
import { API_DATE_FORMAT } from '../../../../../utils';
import { getNewFlightSearchParams } from '../../../store/selectors';
var Choice = function (_a) {
    var items = _a.items, selectedKeys = _a.selectedKeys, onChange = _a.onChange, _b = _a.controlType, controlType = _b === void 0 ? 'checkbox' : _b;
    var theme = useTheme('Exchange').Choice;
    var cssCalendar = useTheme('Exchange').Calendar;
    var searchParameters = useSelector(getNewFlightSearchParams);
    var ALL_FLIGHT = 'ALL_FLIGHT';
    var selectedIds = selectedKeys.map(function (_a) {
        var key = _a.key;
        return key;
    });
    var isAllFlight = selectedIds.includes(ALL_FLIGHT);
    var onChangeHandler = function (event, checked, date) {
        var key = event.target.name;
        var formatDate = date ? format(date, API_DATE_FORMAT) : null;
        onChange({ key: key, date: formatDate }, checked);
    };
    var onChangeDate = function (key, date, checked) {
        var formatDate = format(date, API_DATE_FORMAT);
        if (isAllFlight) {
            onChange({ key: ALL_FLIGHT, value: { key: key, date: formatDate } }, checked);
        }
        else {
            onChange({ key: key, date: formatDate }, checked);
        }
    };
    var datepickerStyles = useMemo(function () { return ({
        Datepicker: {
            PopupDatepickerStyles: {
                block: cssCalendar.datepicker__modal
            },
            DayStyles: {
                day: cssCalendar.day,
                day_selected: cssCalendar.day_selected,
                day__inside: cssCalendar.day_inside,
                day_startPeriod: cssCalendar.day_startPeriod,
                day_endPeriod: cssCalendar.day_endPeriod,
                day_notAvailable: cssCalendar.day_notAvailable
            },
            MonthStyles: {
                month: cssCalendar.month_month
            }
        }
    }); }, []);
    var searchParams = useSelector(getNewFlightSearchParams);
    var selectedDates = useMemo(function () {
        return searchParams.segments.map(function (segment) { return parse(segment.date, API_DATE_FORMAT, new Date()); });
    }, [searchParams]);
    var filterDates = useMemo(function () {
        var _a;
        return (_a = items.filter(function (_a) {
            var key = _a.key;
            return key !== ALL_FLIGHT;
        })) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
            var date = _a.date;
            return date;
        });
    }, [items]);
    return (React.createElement("div", { className: theme.container }, items.map(function (item) {
        var _a;
        var _b, _c, _d;
        var isSelected = selectedIds.includes(item.key);
        var isLastSegment = item.key === '1';
        var selectedDate = isSelected ? (_b = selectedKeys.find(function (_a) {
            var key = _a.key;
            return key === item.key;
        })) === null || _b === void 0 ? void 0 : _b.date : item.date;
        var segment = isAllFlight && isSelected ? (_c = searchParameters.segments) === null || _c === void 0 ? void 0 : _c[item.key] : (_d = searchParameters.segments) === null || _d === void 0 ? void 0 : _d[0];
        var ControlComponent = controlType === 'checkbox' ? Checkbox : Radio;
        return (React.createElement(FormControlLabel, { control: React.createElement(ControlComponent, { name: item.key, checked: isSelected, classes: {
                    checked: theme.button_checked,
                    root: theme.button
                } }), key: item.key, label: React.createElement(React.Fragment, null,
                item.value,
                item.date && (React.createElement(ThemeProvider, { value: datepickerStyles },
                    React.createElement(Datepicker, { className: theme.labelDate, date: new Date(selectedDate), onSelect: function (date) { return onChangeDate(item.key, date, isSelected); }, selectedDates: selectedDates, minDate: isAllFlight && isLastSegment && selectedDates.length > 0
                            ? selectedDates[0]
                            : isLastSegment
                                ? filterDates[0]
                                : new Date(), highlightClassName: cssCalendar.day_highlight, segment: segment })))), onChange: function (event, selected) { return onChangeHandler(event, selected, item.date); }, checked: isSelected, classes: {
                root: cn(theme.label, (_a = {},
                    _a[theme.label_selected] = isSelected,
                    _a)),
                label: theme.labelInner
            } }));
    })));
};
export default Choice;
