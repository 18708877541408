import { getPassengerName } from '../../utils';
import { PASSENGER_FIELD_PREFIX, SEGMENT_FIELD_PREFIX } from './SsrModal/types';
import { TravellerFieldEnum } from '@websky/graphql';
export var passengersByPassengersIds = function (orderTravellers, ssrPassengersIds, t) {
    if (ssrPassengersIds.length > 1 && ssrPassengersIds.length === orderTravellers.length) {
        return t('All passengers');
    }
    var travellersById = new Map();
    orderTravellers.forEach(function (traveller) {
        travellersById.set(traveller.id, traveller);
    });
    return ssrPassengersIds
        .map(function (passId) {
        var traveller = travellersById.get(passId);
        var lastName = traveller.values.find(function (value) { return value.type === TravellerFieldEnum.LastName; }).value;
        var firstName = traveller.values.find(function (value) { return value.type === TravellerFieldEnum.FirstName; }).value;
        return getPassengerName(firstName, lastName);
    })
        .join(', ');
};
export var segmentsBySegmentsIds = function (orderSegments, ssrSegmentsIds, t) {
    if (ssrSegmentsIds.length === orderSegments.length) {
        return t('All route');
    }
    var segmentsById = new Map();
    orderSegments.forEach(function (segment) {
        segmentsById.set(segment.segment.id, segment);
    });
    return ssrSegmentsIds
        .map(function (segmentId) {
        var segment = segmentsById.get(segmentId);
        return "".concat(segment.segment.departure.airport.iata, " \u2014 ").concat(segment.segment.arrival.airport.iata);
    })
        .join(', ');
};
export var ssrDataToSsrInputAdapter = function (ssr) {
    return ssr.map(function (ssr) { return ({
        id: ssr.id,
        code: ssr.ssrCode,
        statusCode: ssr.status,
        travellerIds: ssr.travellerIds,
        segmentIds: ssr.segmentIds,
        description: ssr.ssrText
    }); });
};
export var buildSsrModalInitValues = function (isDeletingMode, deletingSsr, segments, travellers) {
    var initialValues = isDeletingMode
        ? {
            ssr: deletingSsr.ssrCode,
            comments: deletingSsr.ssrText,
            allFlights: segments.length > 1 && deletingSsr.segmentIds.length === segments.length,
            allPassengers: travellers.length > 1 && deletingSsr.travellerIds.length === travellers.length
        }
        : null;
    if (isDeletingMode) {
        if (!initialValues.allFlights) {
            deletingSsr.segmentIds.forEach(function (segmentId) {
                initialValues["".concat(SEGMENT_FIELD_PREFIX).concat(segmentId)] = true;
            });
        }
        if (!initialValues.allPassengers) {
            deletingSsr.travellerIds.forEach(function (travellerId) {
                initialValues["".concat(PASSENGER_FIELD_PREFIX).concat(travellerId)] = true;
            });
        }
    }
    return initialValues;
};
