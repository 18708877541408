import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Button from '../../Button/Button';
import { useTheme } from '../../theme';
import { Refresh } from '../../Icons';
import { fillOrder } from '../../Checkout/store/order/actions';
import * as FindOrderQuery from '../../__queries__/FindOrder.graphql';
import WarningModal from '../../WarningModal';
var RefreshButton = function (props) {
    var dispatch = useDispatch();
    var theme = useTheme('RefreshButton').RefreshButton;
    var t = useTranslation('').t;
    var _a = __read(React.useState(null), 2), requestError = _a[0], setRequestError = _a[1];
    var _b = __read(useMutation(FindOrderQuery.FindOrder), 2), findOrder = _b[0], isFindOrderLoading = _b[1].loading;
    var refreshOrder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, error_1;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, findOrder({
                            variables: {
                                params: {
                                    id: props.orderLocator
                                }
                            }
                        })];
                case 1:
                    _a = _c.sent(), data = _a.data, errors = _a.errors;
                    if (errors) {
                        setRequestError((_b = errors[0]) === null || _b === void 0 ? void 0 : _b.message);
                    }
                    else {
                        dispatch(fillOrder(data.FindOrder));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _c.sent();
                    setRequestError(error_1 === null || error_1 === void 0 ? void 0 : error_1.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onCloseWarningModal = function () {
        setRequestError(null);
    };
    if (props.renderTrigger) {
        return props.renderTrigger({
            onClick: refreshOrder,
            isLoading: isFindOrderLoading
        });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An unexpected error has occurred during the refresh process. Please try again later or contact a customer support service.'), errorMessage: requestError, onClose: onCloseWarningModal, onButtonClick: onCloseWarningModal, buttonText: t('OK, thank you'), isOpen: requestError !== null }),
        React.createElement(Button, { className: cn(theme.refreshBtn, props.className), variant: "common", onClick: refreshOrder, isLoading: isFindOrderLoading }, Refresh)));
};
export default RefreshButton;
