import { __assign } from "tslib";
import React from 'react';
import { InputType } from '../../../../Passenger/types';
import { useField } from 'react-final-form';
import Input from '../../../Input/Input';
import { DateMaskedInput } from '../../../../Passenger/components/Field/Date/Date';
import { Female, Male } from '../../../../Icons';
import Select from '../../../Select/Select';
import { Switch } from '../../../Switch/Switch';
import { TravellerFieldEnum } from '@websky/graphql';
import { NationalityWrapper } from './Components/NationalityWrapper/NationalityWrapper';
export var Field = function (_a) {
    var field = _a.field, className = _a.className, onChange = _a.onChange, onFocus = _a.onFocus, onBlur = _a.onBlur, disabled = _a.disabled;
    var fieldState = useField(field.name, {
        initialValue: field.defaultValue,
        type: field.input
    });
    var commonFieldProps = {
        success: field.success,
        warning: field.warning,
        label: field.placeholder,
        value: fieldState.input.value,
        name: field.name,
        className: className,
        disabled: disabled
    };
    var inputProps = {
        TextFieldProps: {
            onChange: function (e) {
                fieldState.input.onChange(e);
                onChange && onChange(field.name, e.target.value);
            },
            onBlur: function (e) {
                fieldState.input.onBlur(e);
                onBlur && onBlur();
            },
            onFocus: onFocus
        },
        absoluteHintPosition: true,
        variant: 'outlined'
    };
    if (field.input === InputType.Text) {
        return React.createElement(Input, __assign({}, commonFieldProps, inputProps));
    }
    if (field.input === InputType.Date) {
        return (React.createElement(Input, __assign({}, commonFieldProps, inputProps, { inputComponent: DateMaskedInput })));
    }
    if (field.type === TravellerFieldEnum.Nationality) {
        return (React.createElement(NationalityWrapper, { success: commonFieldProps.success, warning: commonFieldProps.warning, label: commonFieldProps.label, disabled: commonFieldProps.disabled, field: field, fieldState: fieldState }));
    }
    if (field.input === InputType.Switch) {
        var options = field.options;
        if (options) {
            options = options.map(function (option) { return (__assign(__assign({}, option), { icon: option.key === 'M' ? Male : Female })); });
        }
        return (React.createElement(Switch, __assign({}, commonFieldProps, { error: null, value: fieldState.input.value ? fieldState.input.value[0] : fieldState.meta.initial, onChange: function (value, event) {
                onChange && onChange(field.name, value);
                fieldState.input.onChange(event);
            }, onFocus: onFocus, onBlur: function () {
                fieldState.input.onBlur();
                onBlur && onBlur();
            }, options: options })));
    }
    if (field.input === InputType.Select) {
        var options = field.options;
        if (options) {
            options = options.map(function (option) { return (__assign(__assign({}, option), { icon: option.key === 'M' ? Male : Female })); });
        }
        return (React.createElement(Select, __assign({ label: null, name: null }, commonFieldProps, { error: null, onChange: function (value, event) {
                onChange && onChange(field.name, value);
                fieldState.input.onChange(event);
            }, onFocus: onFocus, onBlur: function () {
                fieldState.input.onBlur();
                onBlur && onBlur();
            }, options: options })));
    }
    return React.createElement(React.Fragment, null);
};
