import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
import Dialog from './Dialog/Dialog';
import { Money } from '../../Money';
import { initI18n } from '../../utils';
import { useTranslation } from 'react-i18next';
import { CurrencySymbols } from '../../enums';
import { useCallback, useState } from 'react';
import SimpleLoader from '../../SimpleLoader';
initI18n('Service');
var Service = function (props) {
    var _a;
    var _b = props.isCancelable, isCancelable = _b === void 0 ? true : _b, _c = props.dialogHeaderPrice, dialogHeaderPrice = _c === void 0 ? props.price : _c;
    var _d = __read(useState(false), 2), dialogIsMounted = _d[0], setDialogIsMounted = _d[1];
    var _e = __read(React.useState(false), 2), open = _e[0], setOpen = _e[1], theme = useTheme('Service').ServiceStyles;
    var t = useTranslation().t;
    var onClickHandler = useCallback(function () {
        if (props.onClick) {
            props.onClick();
        }
        else {
            // We don't want to render dialog and its content right in place.
            // We do lazy rendering instead, so dialog is rendered only when user clicks service card button.
            if (!dialogIsMounted) {
                setDialogIsMounted(true);
            }
            setOpen(true);
        }
    }, [props.onClick, setOpen]);
    var onCancel = useCallback(function (e) {
        e.stopPropagation();
        if (isCancelable && props.onCancel) {
            props.onCancel();
        }
    }, [props.onCancel, isCancelable]);
    var dialogClose = useCallback(function () { return setOpen(false); }, [setOpen]);
    return (React.createElement(React.Fragment, { key: props.label },
        React.createElement("div", { className: cn(theme.service, props.className, (_a = {},
                _a[theme.service_hasProducts] = props.hasProducts,
                _a[theme.service_readonly] = props.readonly,
                _a[theme.service_canAdd] = props.canAddProducts && !props.readonly,
                _a[theme.service_disabledAdd] = !props.canAddProducts && props.hasProducts && !props.readonly,
                _a)), onClick: onClickHandler },
            React.createElement("div", { className: theme.service__wrapper },
                React.createElement("div", { className: theme.icon }, props.icon),
                React.createElement("div", { className: theme.main },
                    React.createElement("div", { className: cn(theme.header, props.headerClassName) }, props.label),
                    props.description && React.createElement("div", { className: theme.desc }, props.description)),
                props.detailPrice && props.hasProducts && (React.createElement("div", { className: theme.selectedSeats__prices },
                    React.createElement("div", { className: theme.selectedSeats__prices__price },
                        React.createElement("span", null,
                            CurrencySymbols[props.detailPrice[0].currency],
                            props.detailPrice[0].amount)),
                    React.createElement("div", { className: theme.selectedSeats__prices__price },
                        React.createElement("span", null,
                            CurrencySymbols[props.detailPrice[1].currency],
                            props.detailPrice[1].amount))))),
            React.createElement("div", { className: theme.action },
                React.createElement("div", { className: theme.action__text }, props.renderActionContent ? (props.renderActionContent) : (React.createElement(React.Fragment, null,
                    !props.hasProducts ? (!props.minPrice || props.minPrice.amount === 0 ? (React.createElement("span", null, t('Service:free'))) : (React.createElement(React.Fragment, null,
                        React.createElement("span", null, t('Service:from')),
                        React.createElement(Money, { money: props.minPrice })))) : null,
                    props.hasProducts && React.createElement(Money, { moneyClassName: theme.totalPrice, money: props.price })))),
                (props.canAddProducts || !props.hasProducts || props.readonly || isCancelable) && (React.createElement("div", { className: theme.action__icon, onClick: props.hasProducts && !props.readonly ? onCancel : onClickHandler })))),
        props.children && dialogIsMounted && (React.createElement(Dialog, { isOpen: open, onClose: dialogClose, content: React.createElement("div", { className: theme.dialog__content },
                props.isLoading && React.createElement(SimpleLoader, null),
                props.children), dialogType: props.dialogType, dialogHeader: props.dialogHeader, onClear: props.onCancel, rules: props.rules, totalPrice: dialogHeaderPrice, dialogContentClassName: props.dialogContentClassName, dialogHeaderClassName: props.dialogHeaderClassName, dialogControlsClassName: props.dialogControlsClassName }))));
};
export default Service;
