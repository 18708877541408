import { __assign, __awaiter, __generator, __read, __spreadArray, __values } from "tslib";
import { useCallback, useEffect, useState } from 'react';
import { convertDocumentInfoCardToFormData, convertDocuments } from '../utils';
import { FormStages, TravellerFields } from '../types';
import { useQuery } from '@apollo/react-hooks';
import * as TravelCompanionsQueries from '../../__queries__/TravelCompanions.graphql';
import { TravellerTypes } from '../../Traveller/types';
import { clearPhoneNumber } from '../../LoginPage/utils';
import { InputType } from '../../Passenger/types';
import { validate } from '../../Passenger/utils';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
export var useTravellerFormData = function (_a) {
    var _b, _c;
    var traveller = _a.traveller, type = _a.type, submit = _a.onSubmit, fieldSetsAgr = _a.fieldSets;
    var t = useTranslation('Passenger').t;
    var _d = __read(useState((traveller && traveller.documents && traveller.documents.length > 0 && convertDocuments(traveller.documents)) ||
        []), 2), documentsList = _d[0], setDocumentsList = _d[1];
    var _e = __read(useState('none' || 'document'), 2), isOpenedModal = _e[0], setOpenedModal = _e[1];
    var _f = __read(useState(FormStages.Default), 2), stage = _f[0], setStage = _f[1];
    var _g = __read(useState(false), 2), openedErrorTooltip = _g[0], setOpenedErrorTooltip = _g[1];
    var documentsValidations = useQuery(TravelCompanionsQueries.DocumentsValidations).data;
    var documentValidators = (_c = (_b = documentsValidations === null || documentsValidations === void 0 ? void 0 : documentsValidations.DefaultTravellerProfile) === null || _b === void 0 ? void 0 : _b.documents) === null || _c === void 0 ? void 0 : _c[0];
    function isLoyaltyInfoCard(infoCard) {
        return infoCard && infoCard.hasOwnProperty('loyaltyAirline');
    }
    var submitDocList = useCallback(function (documents) {
        if (type !== TravellerTypes.CompanionEmpty) {
            submit({
                documents: documents.map(convertDocumentInfoCardToFormData)
            });
        }
    }, [type, submit]);
    var getDocuments = function (document, list) {
        if (list === 'documents' && !isLoyaltyInfoCard(document)) {
            var newListDocument = __spreadArray(__spreadArray([], __read(documentsList), false), [document], false);
            setDocumentsList(newListDocument);
            submitDocList(newListDocument);
        }
    };
    var updateDocList = function (document, list, index) {
        if (list === 'documents' && !isLoyaltyInfoCard(document)) {
            var newListDocument = __spreadArray(__spreadArray(__spreadArray([], __read(documentsList.slice(0, index)), false), [document], false), __read(documentsList.slice(index + 1)), false);
            setDocumentsList(newListDocument);
            submitDocList(newListDocument);
        }
    };
    useEffect(function () {
        if (type === TravellerTypes.CompanionEmpty) {
            setDocumentsList(convertDocuments(traveller.documents));
        }
    }, [traveller.documents]);
    var onSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setStage(FormStages.Loading);
                    return [4 /*yield*/, submit(__assign(__assign({}, values), { citizenship: values['nationality'], phone: values['phone'] && clearPhoneNumber(values['phone']), documents: documentsList.map(convertDocumentInfoCardToFormData), preferences: undefined }))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var onDelete = function (index, list) {
        if (list === 'documents') {
            setDocumentsList(__spreadArray(__spreadArray([], __read(documentsList.slice(0, index)), false), __read(documentsList.slice(index + 1)), false));
        }
    };
    var fieldSets = fieldSetsAgr
        ? fieldSetsAgr
        : {
            originalNames: ['firstName', 'lastName', 'middleName'],
            personInfo: ['birthDate', 'gender', 'nationality'],
            accountInfo: ['email', 'phone']
        };
    var validateForm = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var errors;
        return __generator(this, function (_a) {
            errors = {};
            traveller.values.forEach(function (field) {
                var fieldValue = values[field.name];
                var inputType = field.name === TravellerFields.BirthDate
                    ? InputType.Date
                    : field.name === TravellerFields.Gender
                        ? InputType.Switch
                        : field.name === TravellerFields.Nationality
                            ? InputType.Select
                            : field.name === TravellerFields.Phone
                                ? InputType.Phone
                                : InputType.Text;
                errors[field.name] = validate(fieldValue, values, inputType, field.validationRules);
                if (errors[field.name]) {
                    errors[field.name] = t(errors[field.name]);
                }
            });
            return [2 /*return*/, errors];
        });
    }); };
    var fieldValuesObject = React.useMemo(function () {
        var e_1, _a;
        var values = {};
        try {
            for (var _b = __values(traveller.values), _c = _b.next(); !_c.done; _c = _b.next()) {
                var field = _c.value;
                values[field.name] = field.value;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return values;
    }, [traveller]);
    return {
        stage: stage,
        setStage: setStage,
        validateForm: validateForm,
        fieldValuesObject: fieldValuesObject,
        onSubmit: onSubmit,
        setOpenedErrorTooltip: setOpenedErrorTooltip,
        openedErrorTooltip: openedErrorTooltip,
        fieldSets: fieldSets,
        documentsList: documentsList,
        updateDocList: updateDocList,
        documentValidators: documentValidators,
        getDocuments: getDocuments,
        setOpenedModal: setOpenedModal,
        isOpenedModal: isOpenedModal,
        onDelete: onDelete,
        documentsValidations: documentsValidations
    };
};
