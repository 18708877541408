import { LocationGroupType } from '../__generated__/globalTypes';
import i18n from 'i18next';
import { LocationType } from '../types';
export var filterGroupsForDirectionsDialog = function (groups) {
    return groups.filter(function (item) { return 'type' in item && item.type === LocationGroupType.Country; });
};
export var parseLocationGroups = function (groups, query, locationType) {
    // In case of no-results.
    if (groups && !groups.length) {
        return !!query.trim()
            ? [
                {
                    label: "".concat(i18n.t('LocationAutocomplete:Sorry, we do not fly'), " ").concat(locationType === LocationType.Departure
                        ? i18n.t('LocationAutocomplete:from')
                        : i18n.t('LocationAutocomplete:to')),
                    options: [
                        {
                            label: query,
                            value: null
                        }
                    ]
                }
            ]
            : [];
    }
    return groups.map(function (group) {
        var label;
        var options = group.cities.map(function (city) { return ({
            label: city.name,
            value: city
        }); });
        switch (group.type) {
            case LocationGroupType.Country:
                label = group.country.name;
                break;
            case LocationGroupType.Nearest:
                label = i18n.t('LocationAutocomplete:Nearby airports');
                break;
            case LocationGroupType.Popular:
                label = i18n.t('LocationAutocomplete:Popular airports');
                break;
            case LocationGroupType.AdditionalCities:
                label = i18n.t('LocationAutocomplete:Recent searches');
                break;
        }
        return {
            label: label,
            options: options,
            type: group.type
        };
    });
};
