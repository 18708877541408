import { __read } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';
import DownloadDocuments from '../../DownloadDocuments';
import { getPassengerLabels } from '../../Checkout/store/passengers/selectors';
var DownloadDocumentsWrapper = function (props) {
    var t = useTranslation('OrderCard').t;
    var passengerLabels = useSelector(getPassengerLabels);
    var passengers = Object.entries(passengerLabels).map(function (_a) {
        var _b = __read(_a, 2), id = _b[0], name = _b[1];
        return ({ id: id, name: name });
    });
    var downloadDocumentsHandler = function (values) {
        var travellerId = values.passengers === 'All' ? null : values.passengers;
        props.onDownloadClick(values.documents, travellerId);
    };
    return (React.createElement(DownloadDocuments, { passengers: passengers, isDocumentsDownloading: props.isDocumentsDownload, renderLoadButton: function (openModal) {
            return props.renderDownloadButton ? (props.renderDownloadButton(openModal)) : (React.createElement(Button, { onClick: openModal }, t('Download')));
        }, onDownloadDocuments: downloadDocumentsHandler }));
};
export default DownloadDocumentsWrapper;
