import { __read, __spreadArray } from "tslib";
import React, { useContext, useMemo } from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { OrderCardContext } from '../../OrderCard';
var OrderCities = function () {
    var css = useTheme('OrderCard').OrderPreview;
    var order = useContext(OrderCardContext).order;
    var cities = useMemo(function () {
        var _a, _b;
        var isRoundtrip = false;
        if (order.flight.segmentGroups.length === 2) {
            var firstSegments = order.flight.segmentGroups[0].segments;
            var lastSegments = order.flight.segmentGroups[1].segments;
            var firstCity = (_a = firstSegments[0].departure.airport.city) === null || _a === void 0 ? void 0 : _a.id;
            var lastCity = (_b = lastSegments[lastSegments.length - 1].departure.airport.city) === null || _b === void 0 ? void 0 : _b.id;
            if (firstCity === lastCity) {
                isRoundtrip = true;
            }
        }
        // if there and back then only the starting city and the ending city of the first flight are displayed
        if (isRoundtrip) {
            var segments = order.flight.segmentGroups[0].segments;
            var first = segments[0];
            var last = segments[segments.length - 1];
            return [first.departure.airport.city, last.arrival.airport.city];
        }
        else {
            var segments = order.flight.segmentGroups[0].segments;
            var first = segments[0];
            return __spreadArray([
                first.departure.airport.city
            ], __read(order.flight.segmentGroups
                .map(function (_a) {
                var segments = _a.segments;
                return segments[segments.length - 1];
            })
                .map(function (segment) { return segment.arrival.airport.city; })), false);
        }
    }, [order.flight.segmentGroups]);
    if (cities.length === 2) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.city }, cities[0].name),
            React.createElement("div", { className: cn(css.city, css.main_city) }, cities[1].name)));
    }
    else {
        return (React.createElement("div", { className: css.cities_chain }, cities.map(function (city, i) { return (React.createElement("div", { className: css.city, key: i }, city.name)); })));
    }
};
export default OrderCities;
