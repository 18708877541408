import React from 'react';
import Modal from '../../Modal';
import { useTheme } from '../../theme';
import CloseButton from '../../Modal/components/CloseButton/CloseButton';
import cn from 'classnames';
var FullScreenModal = function (props) {
    var _a, _b, _c, _d;
    var _e;
    var theme = useTheme('FullScreenModal').FullScreenModal;
    var headerAlignment = (_e = props.headerAlignment) !== null && _e !== void 0 ? _e : 'right';
    return (React.createElement(Modal, { className: props.className, classes: {
            paper: cn(theme.modal_paper, (_a = {}, _a[theme.modal_paper_collapsed] = props.headerType === 'collapsed', _a))
        }, scroll: "paper", fullScreen: true, fullWidth: true, open: props.open, closeButtonRenderer: props.closeButtonRenderer
            ? props.closeButtonRenderer
            : function (onClick) {
                var _a;
                return (React.createElement(CloseButton, { className: cn(theme.close, (_a = {},
                        _a[theme.close_fixed] = props.headerType === 'collapsed' || props.headerType === 'fixed',
                        _a)), isGray: true, onClick: onClick, isLoading: props.isClosing }));
            }, onClose: props.onClose, slideUp: true },
        React.createElement("div", { className: cn(theme.header, (_b = {},
                _b[theme.header_collapsed] = props.headerType === 'collapsed',
                _b[theme.header_left] = headerAlignment === 'left' && props.title,
                _b[theme.header_fixed] = props.headerType === 'fixed',
                _b)) },
            React.createElement("div", { className: theme.header__title }, props.title),
            React.createElement("div", { className: cn((_c = {},
                    _c[theme.header__content_left] = headerAlignment === 'left' && !props.title,
                    _c[theme.header__content_center] = headerAlignment === 'center',
                    _c)) }, props.header)),
        React.createElement("div", { className: cn((_d = {}, _d[theme.fixedHeaderContent] = props.headerType === 'fixed', _d)) }, props.children)));
};
export default FullScreenModal;
