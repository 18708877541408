import React, { useCallback } from 'react';
import MobileHandLuggageExcessCard from './MobileHandLuggageExcessCard';
import MobileHandLuggageExcessModal from './MobileHandLuggageExcessModal';
import { useToggleable } from '../../../../../hooks';
var MobileHandLuggageExcess = function (props) {
    var services = props.services, selected = props.selected, onToggle = props.onToggle, onSelect = props.onSelect, segments = props.segments, readonly = props.readonly;
    var _a = useToggleable(false), isOpen = _a.isOpen, open = _a.open, close = _a.close;
    var handlerClickCard = useCallback(function () {
        if (!readonly) {
            open();
        }
    }, [readonly, open]);
    var handlerToggleInner = useCallback(function () {
        if (props.selected) {
            onToggle(false);
        }
        else {
            open();
        }
    }, [onToggle, props.selected]);
    if (services.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MobileHandLuggageExcessCard, { readonly: readonly, services: services, onClick: handlerClickCard, selected: selected, onToggle: handlerToggleInner }),
        React.createElement(MobileHandLuggageExcessModal, { isOpen: isOpen, selected: selected, onClose: close, onToggle: onToggle, onSelect: onSelect, services: services, segments: segments })));
};
export default MobileHandLuggageExcess;
