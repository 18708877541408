import * as React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import MediaQuery from 'react-responsive';
import DocumentForm from '../../../DocumentForm';
import CompareFaresModal from '../../../Modal';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { SlideBottom } from '../../../index';
import { useTheme } from '../../../theme';
var InfoCardDialog = function (props) {
    var css = useTheme('InfoCard').InfoCard;
    function isDocumentInfoCard(infoCard) {
        return infoCard && infoCard.hasOwnProperty('docType');
    }
    var onSave = function (value) {
        if (isDocumentInfoCard(value)) {
            props.onSave(value, 'documents');
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: props.open, onClose: props.onClose },
                React.createElement(DialogContent, { className: css.modal__content }, props.type === 'document' && (React.createElement(DocumentForm, { onSave: onSave, nationality: props.nationality, validators: props.validators, document: props.document && isDocumentInfoCard(props.document) ? props.document : null, isChild: props.isChild }))))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(CompareFaresModal, { open: props.open, onClose: props.onClose, maxWidth: 'sm' },
                React.createElement(DialogContent, { className: css.modal__content }, props.type === 'document' && (React.createElement(DocumentForm, { onSave: onSave, nationality: props.nationality, validators: props.validators, document: props.document && isDocumentInfoCard(props.document) ? props.document : null, isChild: props.isChild })))))));
};
export default InfoCardDialog;
