var _a;
import { FareFamilyOptionsType } from '@websky/graphql';
import { Backpack, CheckedBaggage, ExchangeableChecked, Refundable, ServicesSeatSelection, Meal, Receipt } from '../../../Icons/Icons';
export var fareOptionsIcons = (_a = {},
    _a[FareFamilyOptionsType.CarryOn] = Backpack,
    _a[FareFamilyOptionsType.Baggage] = CheckedBaggage,
    _a[FareFamilyOptionsType.SeatRegistration] = ServicesSeatSelection,
    _a[FareFamilyOptionsType.Exchangeable] = ExchangeableChecked,
    _a[FareFamilyOptionsType.Refundable] = Refundable,
    _a[FareFamilyOptionsType.Meal] = Meal,
    _a[FareFamilyOptionsType.OnlineRegistration] = Receipt,
    _a);
