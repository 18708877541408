import * as React from 'react';
import cn from 'classnames';
import { InfoMessageTypeEnum } from '../../__generated__/graphql';
import { useTheme } from '../../theme';
import Button from '../../Button';
import { WarningRounded, Included, ArrowForward } from '../../Icons';
var Notification = function (_a) {
    var _b;
    var className = _a.className, title = _a.title, image = _a.image, status = _a.status, type = _a.type, infoMessage = _a.infoMessage, actionText = _a.actionText, onClickAction = _a.onClickAction;
    var css = useTheme('Notification').Notification;
    var isSuccess = ![InfoMessageTypeEnum.Info, InfoMessageTypeEnum.Warning, InfoMessageTypeEnum.Promo].includes(status);
    return (React.createElement("div", { className: cn(className, css.wrapper, (_b = {},
            _b[css.context] = type === 'context',
            _b[css.global] = type === 'global',
            _b[css.success] = isSuccess,
            _b[css.info] = status === InfoMessageTypeEnum.Info,
            _b[css.warning] = status === InfoMessageTypeEnum.Warning,
            _b)), "data-code": infoMessage.code },
        image ? (React.createElement("img", { className: css.image, src: image, alt: "" })) : (React.createElement("div", { className: css.icon }, isSuccess ? Included : WarningRounded)),
        React.createElement("div", { className: css.content },
            title && React.createElement("div", { className: css.title }, title),
            React.createElement("div", { className: css.text, dangerouslySetInnerHTML: { __html: infoMessage.text } })),
        actionText && (React.createElement(Button, { className: css.action, onClick: onClickAction },
            actionText,
            ArrowForward))));
};
export default Notification;
