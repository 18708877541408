import * as React from 'react';
import Link from '../../../../Link';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { useToggleable } from '../../../../hooks';
import Modal from '../../../../Modal';
import RecognitionPopupContent from '../RecognitionPopupContent/RecognitionPopupContent';
import { initI18n } from '../../../../utils';
import { useCallback } from 'react';
initI18n('DocumentRecognition');
var Trigger = function (props) {
    var traveller = props.traveller;
    var t = useTranslation('DocumentRecognition').t; // <-- translate function
    var css = useTheme('DocRecognition').Trigger;
    var text = props.text;
    var _a = useToggleable(false), isModalOpen = _a.isOpen, openModal = _a.open, closeModal = _a.close;
    var onConfirm = useCallback(function (fields) {
        closeModal();
        props.onConfirmScanFields(fields);
    }, [props.onConfirmScanFields]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Link, { className: css.trigger, action: props.isDisabled ? function () { } : openModal }, text !== null && text !== void 0 ? text : t('Scan docs button text')),
        React.createElement(Modal, { isRounded: true, scroll: 'paper', open: isModalOpen, onClose: closeModal },
            React.createElement(RecognitionPopupContent, { traveller: traveller, isModalOpen: isModalOpen, orderId: props.orderId, passengerId: props.passengerId, passengerType: props.passengerType, onConfirmScanFields: onConfirm }))));
};
export default Trigger;
