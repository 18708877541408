import * as React from 'react';
import Button from '@material-ui/core/Button/Button';
import cn from 'classnames';
import { addIcon, cancelIcon } from '../../Icons';
import { useTheme } from '../../../theme';
export var SocialButton = function (props) {
    var _a;
    var css = useTheme('Account').SocialButton;
    return (React.createElement("div", { className: css.social__buttons__wrapper },
        React.createElement(Button, { variant: "outlined", size: "large", color: "default", classes: {
                disabled: css.button__disabled
            }, disabled: true, className: cn((_a = {},
                _a[css.google] = props.buttonName === 'Google',
                _a[css.facebook] = props.buttonName === 'Facebook',
                _a[css.twitter] = props.buttonName === 'Vkontakte',
                _a[css.disabled] = !props.isActive,
                _a)) },
            React.createElement("span", { className: css.social__buttons__logo }, props.icon),
            props.buttonName),
        props.isActive ? (React.createElement("span", { className: css.social__buttons__control, onClick: function () {
                props.onRemoveAuth(props.id);
            } }, cancelIcon)) : (React.createElement("span", { className: cn(css.social__buttons__control, css.add__icon), onClick: function () {
                props.onAuthRedirect(props.buttonName);
            } }, addIcon))));
};
