import * as React from 'react';
import Money from '../../Money';
import { useTheme } from '../../theme';
import Tooltip from '../../Tooltip';
import { initI18n } from '../../utils';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
initI18n('LoyaltyBonus');
var LoyaltyBonus = function (props) {
    var theme = useTheme('LoyaltyBonusStyles');
    var t = useTranslation('LoyaltyBonus').t;
    return (React.createElement("div", { className: cn(theme.loyalty, props.className) },
        React.createElement("div", { className: theme.loyalty__header },
            React.createElement("div", { className: theme.loyalty__name }, t('LightJet Club')),
            React.createElement("div", { className: theme.loyalty__miles },
                "+",
                React.createElement(Money, { money: {
                        amount: props.miles,
                        currency: 'miles'
                    } }),
                t('miles')),
            React.createElement(Tooltip, { title: t('You will get for this flight') },
                React.createElement("div", { className: theme.loyalty__hint }))),
        React.createElement("div", { className: theme.loyalty__content }, t('Fly with us and get bonuses!'))));
};
export default LoyaltyBonus;
