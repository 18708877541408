import * as React from 'react';
import MediaQuery from 'react-responsive';
import MobileFlight from '../MobileFlight';
import { TABLET_MIN_WIDTH } from '../utils';
import DesktopFlightRoute from '../DesktopFlightRoute/components/DesktopFlightRoute';
var Flight = function (_a) {
    var flight = _a.flight, singleFlight = _a.singleFlight, onSelect = _a.onSelect, _b = _a.selectedFares, selectedFares = _b === void 0 ? [] : _b, hidePrice = _a.hidePrice, readonly = _a.readonly, isRecommended = _a.isRecommended, isSelected = _a.isSelected, canceled = _a.canceled, renderPrice = _a.renderPrice, paid = _a.paid, searchParameters = _a.searchParameters;
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
            React.createElement(DesktopFlightRoute, { selectedFares: selectedFares, flight: flight, isRecommended: isRecommended, singleFlight: singleFlight, onSelect: onSelect, isSelected: isSelected, canceled: canceled, hidePrice: hidePrice, readonly: readonly, searchParameters: searchParameters, renderPrice: renderPrice })),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement(MobileFlight, { renderPrice: renderPrice, showAirplane: singleFlight, isRecommended: isRecommended, isPreOpen: false, flight: flight, onSelect: onSelect, selectedFares: selectedFares, hidePrice: hidePrice, readonly: readonly, searchParameters: searchParameters }))));
};
export default Flight;
