import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../utils';
import ServiceWithSegments from '../../ServiceWithSegments/ServiceWithSegments';
import ServiceWrapper from './ServiceWrapper/ServiceWrapper';
import { getSegmentTitle, getSegmentsWithMeal } from '../utils';
import { useConfig } from '../../context';
initI18n('MealService');
var MealService = function (_a) {
    var _b = _a.selectedMeals, selectedMeals = _b === void 0 ? [] : _b, isLoading = _a.isLoading, mealRenderer = _a.mealRenderer, onMobileProceed = _a.onMobileProceed, onMobileBack = _a.onMobileBack, meals = _a.meals, segments = _a.segments;
    var t = useTranslation('MealPage').t;
    var segmentsWithMeal = getSegmentsWithMeal(meals);
    var firstSegmentWithMeal = __spreadArray([], __read(segmentsWithMeal), false).sort().shift();
    var isTerminal = useConfig().global.companyInfo.iataCode === 'TERMINAL';
    return (React.createElement(ServiceWithSegments, { title: isTerminal ? null : t('Feel the taste in flight'), initialSegmentId: firstSegmentWithMeal, isLoading: isLoading, onMobileProceed: onMobileProceed, onMobileBack: onMobileBack, enabledSegments: segmentsWithMeal, segments: segments.map(function (segment) { return ({
            id: segment.segmentId,
            title: getSegmentTitle(segment),
            isDisabled: !segmentsWithMeal.has(segment.segmentId),
            isChecked: selectedMeals.some(function (_a) {
                var segmentId = _a.segmentId;
                return segmentId === segment.segmentId;
            })
        }); }), segmentsPosition: isTerminal ? 'center' : null }, function (_a) {
        var activeSegmentId = _a.activeSegmentId;
        return (React.createElement(ServiceWrapper, { activeSegmentId: activeSegmentId, mealRenderer: mealRenderer, meals: meals, selectedMeals: selectedMeals }));
    }));
};
export default MealService;
