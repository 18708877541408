export var MobileStepbarMode;
(function (MobileStepbarMode) {
    MobileStepbarMode[MobileStepbarMode["Checkout"] = 0] = "Checkout";
    MobileStepbarMode[MobileStepbarMode["Checkin"] = 1] = "Checkin";
})(MobileStepbarMode || (MobileStepbarMode = {}));
export var CheckoutSteps;
(function (CheckoutSteps) {
    CheckoutSteps["Flights"] = "Flights";
    CheckoutSteps["Seats"] = "Seats";
    CheckoutSteps["Baggage"] = "Baggage";
    CheckoutSteps["Meal"] = "Meal";
    CheckoutSteps["Passengers"] = "Passengers";
    CheckoutSteps["Extras"] = "Extras";
    CheckoutSteps["Payment"] = "Payment";
})(CheckoutSteps || (CheckoutSteps = {}));
