import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { format } from '../../../../../utils';
import { isTicketCouponGuard } from '../utils';
var Document = function (props) {
    var _a;
    var t = useTranslation('OrderCard').t;
    var theme = useTheme('LeoOrderCard').OrderCoupons;
    var document = props.document;
    var coupons = document.coupons;
    var renderCoupon = useCallback(function (coupon) {
        return (React.createElement("div", { className: theme.coupon, key: coupon.id },
            React.createElement("div", null,
                format(coupon.segment.departure.date, props.dateFormat),
                " ",
                coupon.segment.departure.time),
            React.createElement("div", null,
                coupon.segment.marketingAirline.iata,
                "-",
                coupon.segment.flightNumber,
                "\u00A0",
                coupon.segment.departure.airport.iata,
                "\u2013",
                coupon.segment.arrival.airport.iata),
            React.createElement("div", null, isTicketCouponGuard(coupon)
                ? coupon.aviaPriceClass[0].fareCode
                : document.service.service.rfisc),
            React.createElement("div", null,
                React.createElement("span", { className: theme.code }, isTicketCouponGuard(coupon)
                    ? coupon.segment.statusCode
                    : document.service.products[0].statusCode),
                "\u00A0",
                React.createElement("span", null, isTicketCouponGuard(coupon)
                    ? coupon.segment.status
                    : document.service.products[0].status)),
            React.createElement("div", null,
                React.createElement("span", { className: theme.code }, coupon.statusCode),
                "\u00A0",
                React.createElement("span", null, coupon.status))));
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.coupon },
            React.createElement("div", { className: theme.type }, (_a = document.coupons[0]) === null || _a === void 0 ? void 0 : _a.type),
            React.createElement("div", { className: theme.code }, document.number),
            React.createElement("div", null),
            React.createElement("div", { className: theme.issueDate },
                t('Issue date'),
                ": ",
                format(document.issueDate, "".concat(props.dateFormat, " HH:mm:ss")))),
        coupons.map(renderCoupon)));
};
export default Document;
