import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as theme from './ChangePassword.css';
import { useSnackbar } from '../../../Snackbar/hooks';
import Button from '../../../../Button/Button';
import * as Queries from '../../../../__queries__/query.graphql';
import Modal2FA from '../../../Modal2FA/Modal2FA';
import { useToggleable } from '../../../../hooks';
import { leoPasswordRegExp as passwordRegExp } from '../../../../utils';
import Snackbar from '../../../Snackbar/Snackbar';
import Input from '../../../Input/Input';
var formReset = null;
var ChangePassword = function () {
    var t = useTranslation('Account').t;
    var _a = __read(useMutation(Queries.SendPasswordRestoreCode), 1), sendRestoreCode = _a[0];
    var _b = __read(useMutation(Queries.ChangePassword), 1), changePasswordMutation = _b[0];
    var _c = useToggleable(false), isCodeModalOpen = _c.isOpen, openCodeModal = _c.open, closeCodeModal = _c.close;
    var _d = __read(useState(), 2), passwords = _d[0], setPasswords = _d[1];
    var _e = __read(useState(false), 2), isSendingCode = _e[0], setIsSendingCode = _e[1];
    var _f = __read(useState(false), 2), isChangingPassword = _f[0], setIsChangingPassword = _f[1];
    var _g = useSnackbar(), openSnackbar = _g.open, closeSnackbar = _g.close, isSnackbarOpen = _g.isOpen, snackbarMessage = _g.message, isSnackbarErrorMessage = _g.isError;
    var passwordFormValidation = function (values) {
        var errors = {};
        if (values.newPassword !== values.repeatPassword) {
            errors['newPassword'] = t('Passwords mismatch');
            errors['repeatPassword'] = t('Passwords mismatch');
        }
        if (values.newPassword === values.oldPassword) {
            errors['newPassword'] = t('New and old password should be difference');
        }
        if (!passwordRegExp.test(values.newPassword)) {
            errors['newPassword'] = t('Password must contain minimum 8 characters at least one number, one lower and uppercase letter and at least one special char except /');
        }
        return errors;
    };
    var requiredValidation = function (value) {
        return value === undefined ? t('Required') : undefined;
    };
    var errorHandler = function (errorMessage) {
        if (errorMessage === void 0) { errorMessage = null; }
        var msg = errorMessage !== null && errorMessage !== void 0 ? errorMessage : t('An unexpected error has occurred. Please try again later or contact a customer support service');
        openSnackbar(msg, true);
    };
    var onSubmit = function (values, form) { return __awaiter(void 0, void 0, void 0, function () {
        var data, _a;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setIsSendingCode(true);
                    formReset = form.reset;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, sendRestoreCode({
                            variables: {
                                password: values.oldPassword
                            }
                        })];
                case 2:
                    data = (_d.sent()).data;
                    if ((_b = data === null || data === void 0 ? void 0 : data.SendPasswordRestoreCode) === null || _b === void 0 ? void 0 : _b.result) {
                        openCodeModal();
                        setPasswords({
                            oldPassword: values.oldPassword,
                            newPassword: values.newPassword
                        });
                    }
                    else {
                        errorHandler((_c = data === null || data === void 0 ? void 0 : data.SendPasswordRestoreCode) === null || _c === void 0 ? void 0 : _c.message);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    _a = _d.sent();
                    errorHandler();
                    return [3 /*break*/, 5];
                case 4:
                    setIsSendingCode(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onResendCode = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, _a;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, sendRestoreCode({
                            variables: {
                                password: passwords.oldPassword
                            }
                        })];
                case 1:
                    data = (_d.sent()).data;
                    if (!((_b = data === null || data === void 0 ? void 0 : data.SendPasswordRestoreCode) === null || _b === void 0 ? void 0 : _b.result)) {
                        errorHandler((_c = data === null || data === void 0 ? void 0 : data.SendPasswordRestoreCode) === null || _c === void 0 ? void 0 : _c.message);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    _a = _d.sent();
                    errorHandler();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var changePassword = function (code) { return __awaiter(void 0, void 0, void 0, function () {
        var data, _a;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setIsChangingPassword(true);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, changePasswordMutation({
                            variables: {
                                code: code,
                                oldPassword: passwords.oldPassword,
                                newPassword: passwords.newPassword
                            }
                        })];
                case 2:
                    data = (_d.sent()).data;
                    if ((_b = data === null || data === void 0 ? void 0 : data.ChangePassword) === null || _b === void 0 ? void 0 : _b.result) {
                        openSnackbar(t('Password has been changed'));
                        formReset === null || formReset === void 0 ? void 0 : formReset();
                    }
                    else {
                        errorHandler((_c = data === null || data === void 0 ? void 0 : data.ChangePassword) === null || _c === void 0 ? void 0 : _c.message);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    _a = _d.sent();
                    errorHandler();
                    return [3 /*break*/, 5];
                case 4:
                    setIsChangingPassword(false);
                    closeCodeModal();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var fields = [
        {
            name: 'oldPassword',
            title: t('Old password')
        },
        {
            name: 'newPassword',
            title: t('New password')
        },
        {
            name: 'repeatPassword',
            title: t('Repeat password')
        }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal2FA, { onSubmit: function (code) {
                changePassword(code.code);
            }, onResendCode: onResendCode, onClose: closeCodeModal, open: isCodeModalOpen, isLoading: isChangingPassword }),
        React.createElement(Snackbar, { open: isSnackbarOpen, onClose: closeSnackbar, message: snackbarMessage, error: isSnackbarErrorMessage }),
        React.createElement(Form, { onSubmit: onSubmit, validate: passwordFormValidation }, function (_a) {
            var handleSubmit = _a.handleSubmit;
            return (React.createElement("form", { onSubmit: handleSubmit },
                fields.map(function (inputField) { return (React.createElement(Field, { key: inputField.name, name: inputField.name, type: "password", validate: requiredValidation }, function (field) { return (React.createElement(Input, __assign({ className: theme.passwordField }, field, { label: inputField.title, TextFieldProps: __assign(__assign({}, field.input), { onChange: function (e) { return field.input.onChange(e); }, error: field.meta.touched && field.meta.error, helperText: field.meta.touched && field.meta.error }) }))); })); }),
                React.createElement(Button, { type: "submit", variant: "flat", size: "large", className: theme.changeButton, isLoading: isSendingCode }, t('Change'))));
        })));
};
export default ChangePassword;
