import { __extends, __values } from "tslib";
import { FilterAttrs } from '../types';
import { FlightFilterBase } from './FlightFilterBase';
var AirlineCodeFilter = /** @class */ (function (_super) {
    __extends(AirlineCodeFilter, _super);
    function AirlineCodeFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AirlineCodeFilter.prototype, "attr", {
        get: function () {
            return FilterAttrs.AIRLINE_CODE;
        },
        enumerable: false,
        configurable: true
    });
    AirlineCodeFilter.prototype.toString = function (filterValues) {
        return this.removeWhiteSpace(filterValues[this.attr]);
    };
    AirlineCodeFilter.prototype.getPreparedFilterValue = function (filterValues) {
        return this.getSplitStr(filterValues);
    };
    AirlineCodeFilter.prototype.check = function (flight, filterValue) {
        var e_1, _a;
        if (!flight || !((filterValue === null || filterValue === void 0 ? void 0 : filterValue.length) > 0)) {
            return true;
        }
        try {
            for (var _b = __values(flight.segments), _c = _b.next(); !_c.done; _c = _b.next()) {
                var segment = _c.value;
                if (filterValue.includes(segment.operatingAirline.iata.toLowerCase())) {
                    return true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    return AirlineCodeFilter;
}(FlightFilterBase));
export { AirlineCodeFilter };
