import { __assign } from "tslib";
import React from 'react';
import cn from 'classnames';
import { Checkbox } from '@material-ui/core';
import { useTheme } from '../../../../../theme';
import { usePassengerState } from '../../../hooks';
var PassengerBaggageRadio = function (_a) {
    var className = _a.className, passengerId = _a.passengerId, baggages = _a.baggages, segmentIds = _a.segmentIds, segmentId = _a.segmentId, disabled = _a.disabled, onChange = _a.onChange;
    var css = useTheme('UpgradeBaggageDialog').BaggagePassenger;
    var _b = usePassengerState(passengerId, baggages, segmentIds, segmentId), items = _b.items, selectedCount = _b.selectedCount;
    var onChangeHandler = function (e, checked) {
        onChange(items.map(function (item) { return (__assign(__assign({}, item), { count: checked ? 1 : 0 })); }));
    };
    return (React.createElement("div", { className: cn(css.checkbox_wrapper, className) },
        React.createElement(Checkbox, { classes: {
                root: css.checkbox_root,
                checked: css.checkbox_rootChecked
            }, checked: selectedCount > 0, disabled: disabled, onChange: onChangeHandler })));
};
export default PassengerBaggageRadio;
