import { __awaiter, __generator, __read } from "tslib";
import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Payment from '../../Payment';
import PromoLoader from '../../../../PromoLoader';
import * as OrderQueries from '../../../../__queries__/query.graphql';
import { usePaymentMethodsQuery } from '@websky/graphql';
import { useCallback, useEffect, useState } from 'react';
import { useToggleable } from '../../../../hooks';
import Modal2FA from '../../../Modal2FA/Modal2FA';
import { useDispatch } from 'react-redux';
import { fillOrder } from '../../../../Checkout/store/order/actions';
import { useOrder } from '../../../../__queries__/Order';
import Errors from './Errors/Errors';
var PaymentWrapper = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var orderId = props.orderId, aviaOrderId = props.aviaOrderId, renderSummary = props.renderSummary, _g = props.mode, mode = _g === void 0 ? 'aviaOrder' : _g, onExchangePaymentSuccess = props.onExchangePaymentSuccess, onExchangePaymentFail = props.onExchangePaymentFail;
    var _h = useToggleable(false), isTimelimitExpireModalOpen = _h.isOpen, openTimelimitExpireModal = _h.open, closeTimelimitExpireModal = _h.close;
    var _j = __read(useState(null), 2), secureAuthCode = _j[0], setSecureAuthCode = _j[1];
    var _k = __read(useState(false), 2), isUpdateOrderLoading = _k[0], setIsUpdateOrderLoading = _k[1];
    var _l = __read(useState(null), 2), cachedValues = _l[0], setCachedValues = _l[1];
    var _m = __read(useState(null), 2), errors = _m[0], setErrors = _m[1];
    var dispatch = useDispatch();
    var baseURL = document.location.href;
    var _o = usePaymentMethodsQuery({
        fetchPolicy: 'no-cache',
        variables: {
            withCard: true,
            withCredit: true,
            withGpay: true,
            withItransfer: true,
            withKaspi: true,
            parameters: {
                orderId: orderId,
                backRedirectUrl: "".concat(baseURL, "/successfull"),
                backRedirectUrlOnFail: "".concat(baseURL, "/failed")
            }
        },
        skip: !!props.paymentMethods
    }), paymentMethodsWrapper = _o.data, loading = _o.loading;
    var paymentMethods = useMemo(function () {
        if (props.paymentMethods) {
            return props.paymentMethods;
        }
        return paymentMethodsWrapper === null || paymentMethodsWrapper === void 0 ? void 0 : paymentMethodsWrapper.PaymentMethods;
    }, [props.paymentMethods, paymentMethodsWrapper]);
    var _p = useQuery(OrderQueries.OrderTimeLimitQuery, {
        fetchPolicy: 'no-cache',
        skip: mode === 'exchange' || !paymentMethods,
        variables: {
            id: orderId
        }
    }), paymentOrder = _p.data, orderLoading = _p.loading;
    var _q = __read(useMutation(OrderQueries.sendPaymentAuthCode), 1), sendAuthCode = _q[0];
    var _r = __read(useMutation(OrderQueries.CompletePayment), 2), completePayment = _r[0], _s = _r[1], completePaymentResponse = _s.data, completePaymentLoading = _s.loading;
    var refetchOrder = useOrder(aviaOrderId !== null && aviaOrderId !== void 0 ? aviaOrderId : orderId, { skip: true }).refetch;
    var handleStartPayment = useCallback(function (values) {
        setCachedValues(values);
        var selectedPaymentMethod = paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods[values.method];
        var completePaymentParams = {};
        if (!selectedPaymentMethod) {
            return;
        }
        completePaymentParams.transactionId = selectedPaymentMethod === null || selectedPaymentMethod === void 0 ? void 0 : selectedPaymentMethod.transactionId;
        completePaymentParams.secureCode = secureAuthCode;
        completePaymentParams.orderId = orderId;
        if (values.method === 'invoice') {
            completePaymentParams.additionalText = values.invoiceNumbers;
        }
        if (secureAuthCode || (selectedPaymentMethod === null || selectedPaymentMethod === void 0 ? void 0 : selectedPaymentMethod.secureCodeTimeLimitInSeconds) > 60) {
            completePayment({
                variables: completePaymentParams
            }).catch(function (e) {
                var _a;
                if ((_a = e === null || e === void 0 ? void 0 : e.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
                    var errors_1 = e.graphQLErrors.map(function (error) { return error === null || error === void 0 ? void 0 : error.message; });
                    setErrors(errors_1);
                }
            });
            setSecureAuthCode(null);
            return;
        }
        if ((selectedPaymentMethod === null || selectedPaymentMethod === void 0 ? void 0 : selectedPaymentMethod.secureCodeTimeLimitInSeconds) < 60) {
            sendAuthCode();
            openTimelimitExpireModal();
        }
    }, [paymentMethods, (_a = paymentOrder === null || paymentOrder === void 0 ? void 0 : paymentOrder.Order) === null || _a === void 0 ? void 0 : _a.timelimit, cachedValues, secureAuthCode, isTimelimitExpireModalOpen]);
    var updateOrder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var updatedOrder, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setIsUpdateOrderLoading(true);
                    return [4 /*yield*/, refetchOrder()];
                case 1:
                    updatedOrder = _a.sent();
                    dispatch(fillOrder(updatedOrder.data.Order));
                    return [3 /*break*/, 4];
                case 2:
                    e_1 = _a.sent();
                    console.warn(e_1);
                    return [3 /*break*/, 4];
                case 3:
                    setIsUpdateOrderLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCloseWarningModal = useCallback(function () {
        setErrors(null);
        if (mode === 'exchange') {
            onExchangePaymentFail === null || onExchangePaymentFail === void 0 ? void 0 : onExchangePaymentFail();
        }
        else {
            updateOrder().then(function () {
                location.href = "".concat(baseURL, "/failed");
            });
        }
    }, []);
    useEffect(function () {
        var _a;
        if (typeof ((_a = completePaymentResponse === null || completePaymentResponse === void 0 ? void 0 : completePaymentResponse.CompletePayment) === null || _a === void 0 ? void 0 : _a.result) !== 'undefined') {
            if (completePaymentResponse.CompletePayment.result) {
                if (onExchangePaymentSuccess) {
                    // if onExchangePaymentSuccess callback passed from exchange
                    console.log('onexchange');
                    onExchangePaymentSuccess();
                }
                else {
                    location.href = "".concat(baseURL, "/successfull");
                }
            }
            else {
                if (onExchangePaymentFail) {
                    console.log('onexchangefailed');
                    // if onExchangePaymentFail callback passed from exchange
                    onExchangePaymentFail();
                }
                else {
                    location.href = "".concat(baseURL, "/failed");
                }
                updateOrder();
            }
        }
    }, [completePaymentResponse]);
    useEffect(function () {
        if (secureAuthCode) {
            closeTimelimitExpireModal();
            handleStartPayment(cachedValues);
        }
    }, [secureAuthCode]);
    if (loading || orderLoading || completePaymentLoading || isUpdateOrderLoading) {
        return React.createElement(PromoLoader, null);
    }
    var paymentMethodWithTimelimitKey = Object.keys(paymentMethods || {}).find(function (key) {
        var _a;
        return (_a = paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods[key]) === null || _a === void 0 ? void 0 : _a.timelimit;
    });
    var timelimit = (_b = paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods[paymentMethodWithTimelimitKey]) === null || _b === void 0 ? void 0 : _b.timelimit;
    return (React.createElement(React.Fragment, null,
        React.createElement(Payment, { orderId: orderId, renderSummary: renderSummary, timelimit: timelimit !== null && timelimit !== void 0 ? timelimit : (_c = paymentOrder === null || paymentOrder === void 0 ? void 0 : paymentOrder.Order) === null || _c === void 0 ? void 0 : _c.timelimit, priceToPay: (_d = paymentOrder === null || paymentOrder === void 0 ? void 0 : paymentOrder.Order) === null || _d === void 0 ? void 0 : _d.priceToPay, flight: (_e = paymentOrder === null || paymentOrder === void 0 ? void 0 : paymentOrder.Order) === null || _e === void 0 ? void 0 : _e.flight, onError: null, onPaymentStart: handleStartPayment }),
        errors && (React.createElement(Errors, { mode: mode, errors: errors, orderId: orderId, onError: setErrors, transactionId: (_f = paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods[cachedValues === null || cachedValues === void 0 ? void 0 : cachedValues.method]) === null || _f === void 0 ? void 0 : _f.transactionId, paymentMethod: cachedValues === null || cachedValues === void 0 ? void 0 : cachedValues.method, handleCloseWarningModal: handleCloseWarningModal })),
        React.createElement(Modal2FA, { onSubmit: function (code) {
                setSecureAuthCode(code.code);
            }, onResendCode: sendAuthCode, onClose: closeTimelimitExpireModal, open: isTimelimitExpireModalOpen })));
};
export default PaymentWrapper;
