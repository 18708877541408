import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import { useState, useRef } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { ru, de, kk } from 'date-fns/locale';
import MediaQuery from 'react-responsive';
import { useTheme } from '../../../../../theme';
import { getLocale, MOBILE_MIN_WIDTH } from '../../../../../utils';
import { IconCalendar } from '../../../../../Icons';
import Modal from '../../../../../Modal';
import DatepickerComponent from '../SearchFlights/Calendar/Datepicker/Datepicker';
import { useAvailableDates } from '../../../../../hooks';
var Datepicker = function (_a) {
    var className = _a.className, date = _a.date, onSelect = _a.onSelect, segment = _a.segment, props = __rest(_a, ["className", "date", "onSelect", "segment"]);
    var theme = useTheme('Exchange').Datepicker;
    var locales = {
        ru: ru,
        de: de,
        kk: kk
    };
    var dates = useAvailableDates(segment).dates;
    var currentLocale = getLocale();
    var formatOptions = ['en'].includes(currentLocale) ? {} : { locale: locales[currentLocale] };
    var picker = useRef();
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var closeModal = function () { return setIsOpen(false); };
    var onClick = function (e) {
        var _a;
        e.preventDefault();
        if (isOpen && ((_a = picker === null || picker === void 0 ? void 0 : picker.current) === null || _a === void 0 ? void 0 : _a.contains(e.target))) {
            return;
        }
        setIsOpen(!isOpen);
    };
    var onSelectDate = function (date) {
        closeModal();
        onSelect === null || onSelect === void 0 ? void 0 : onSelect(date);
    };
    var datepickerProps = __assign({ openDate: date, selectedDates: [date], onSelect: onSelectDate, monthCount: 3, availableDates: (dates === null || dates === void 0 ? void 0 : dates.length) ? dates : [], highlightClassName: props.highlightClassName, popupDatepickerStyles: theme.monthsWrapperStyles_wrapper }, props);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(className, theme.wrapper), onClick: onClick },
            IconCalendar,
            React.createElement("span", null, format(date, 'd MMM', formatOptions)),
            React.createElement("div", { className: theme.picker },
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Modal, { open: isOpen, onClose: function () { return setIsOpen(false); } },
                        React.createElement(DatepickerComponent, __assign({}, datepickerProps)))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: isOpen, onClose: closeModal },
                React.createElement(DatepickerComponent, __assign({}, datepickerProps))))));
};
export default Datepicker;
