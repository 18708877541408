import { onError } from 'apollo-link-error';
import { SHOW_RECAPTCHA, captureEventSentry } from './utils';
export var APOLLO_ERRORS_EVENT = 'apollo-errors-event';
var ignoredOperations = new Set(['GetTravellerProfiles']);
var errorLink = onError(function (_a) {
    var networkError = _a.networkError, operation = _a.operation;
    if (networkError && networkError['statusCode']) {
        var statusCode = parseInt(networkError['statusCode']);
        if (statusCode === 429) {
            document.dispatchEvent(new CustomEvent(SHOW_RECAPTCHA));
        }
        else if (statusCode >= 500) {
            if (!ignoredOperations.has(operation.operationName)) {
                var event = new window.CustomEvent(APOLLO_ERRORS_EVENT, {
                    detail: {
                        operationName: operation.operationName,
                        message: "".concat(operation.operationName, ": ").concat(networkError.message ? networkError.message : "Server responded with code = ".concat(statusCode, "."))
                    }
                });
                captureEventSentry(event);
                document.dispatchEvent(event);
            }
        }
    }
});
export default errorLink;
