import { __assign } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { useTheme } from '../../../../theme';
import { Money } from '../../../../Money';
import { useTranslation } from 'react-i18next';
var TotalVoidRefund = function (props) {
    var theme = useTheme('ServicesCard').ServicesCard;
    var t = useTranslation('ServiceCard').t;
    var price = useMemo(function () {
        var total = null;
        var services = props.type === 'void'
            ? props.refundVoidServices.servicesForVoid
            : props.refundVoidServices.servicesForRefund;
        Object.values(services).forEach(function (voidRefundService) {
            if (!total) {
                total = __assign({}, voidRefundService.price);
            }
            else {
                total.amount += voidRefundService.price.amount;
            }
        });
        return total;
    }, [props.refundVoidServices, props.type]);
    if (!(price === null || price === void 0 ? void 0 : price.amount)) {
        return null;
    }
    return (React.createElement("div", { className: theme.totalCardPrice__wrapper },
        React.createElement("div", { className: theme.totalCardPrice },
            React.createElement("span", { className: theme.totalCardPrice__title }, t('Total')),
            React.createElement(Money, { moneyClassName: theme.totalCardPrice__money, money: price }))));
};
export default TotalVoidRefund;
