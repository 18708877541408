import { __assign, __read } from "tslib";
import * as React from 'react';
import MediaQuery from 'react-responsive';
import { connect, useSelector } from 'react-redux';
import cn from 'classnames';
// import Passenger from '../../../../../Passenger/index';
import Passenger from '../../../../../PassengerForm/components/PassengerForm';
import { InputType } from '../../../../../Passenger/types';
import Services from '../Services/Services';
import { useTheme } from '../../../../../theme';
import ServicePage from '../../ServicePage/ServicePage';
import memes from '../../../../../memes';
import { getPassengerLabels } from '../../../../store/passengers/selectors';
import { TravellerFieldEnum } from '../../../../../__generated__/globalTypes';
import { getIsTravellersDataFilled, getSegmentsForServices, getServices, isNew } from '../../../../store/order/selectors';
import { getPassengerName, MOBILE_MIN_WIDTH } from '../../../../../utils';
import { getPassengerStateCompatible } from '../../../../store/selectedServices/selectors';
import { getCurrency } from '../../../../../cache';
import { useIsTerminal } from '../../../../../hooks';
export var getInputType = memes(function (type) {
    var isTerminal = useIsTerminal();
    if (isTerminal) {
        switch (type) {
            case TravellerFieldEnum.FirstName:
            case TravellerFieldEnum.LastName:
                return InputType.Text;
        }
    }
    switch (type) {
        case TravellerFieldEnum.Gender:
            return InputType.Switch;
        case TravellerFieldEnum.Phone:
            return InputType.Phone;
        case TravellerFieldEnum.BirthDate:
        case TravellerFieldEnum.DocExpiryDate:
            return InputType.Date;
        case TravellerFieldEnum.LoyaltyAirline:
        case TravellerFieldEnum.DocType:
            return InputType.Select;
        case TravellerFieldEnum.Nationality:
        case TravellerFieldEnum.FirstName:
        case TravellerFieldEnum.LastName:
            return InputType.Autocomplete;
        default:
            return InputType.Text;
    }
});
var Passengers = function (_a) {
    var _b;
    var passengersState = _a.passengersState, isReadonly = _a.isReadonly, isAuthorized = _a.isAuthorized, fields = _a.fields, showServices = _a.showServices, services = _a.services, onFill = _a.onFill, labels = _a.labels, isInputsReadonly = _a.isInputsReadonly, inModal = _a.inModal;
    var theme = useTheme('Checkout').PassengersStyles;
    var segments = useSelector(getSegmentsForServices);
    var _c = __read(React.useState(0), 2), openedPassengerFormId = _c[0], setOpenedPassengerFormId = _c[1];
    var isTerminal = useIsTerminal();
    var onCorrectFormFilledHandler = function (passengerId) {
        setOpenedPassengerFormId(passengerId + 1);
    };
    return (React.createElement("div", { className: cn(theme.passengers, (_b = {}, _b[theme.passengers_readonly] = isReadonly, _b)) }, fields.map(function (passenger, passengerId) {
        var _a, _b;
        var firstName, lastName;
        var hasAvailableServices = services.some(function (service) {
            return service.allowedPassengers.some(function (allowedPassengers) {
                return allowedPassengers.some(function (allowedPassenger) { return allowedPassenger === passenger.id; });
            });
        });
        var selectPassengerId = passenger === null || passenger === void 0 ? void 0 : passenger.passengerId;
        var passengerFields = passenger.values.map(function (_a) {
            var name = _a.name, type = _a.type, validationRules = _a.validationRules, value = _a.value, isEditable = _a.isEditable;
            if (name === 'firstName') {
                firstName = value;
            }
            if (name === 'lastName') {
                lastName = value;
            }
            return {
                name: name,
                readonly: !isEditable,
                type: type,
                input: getInputType(type),
                placeholder: name,
                validations: validationRules,
                defaultValue: value
            };
        });
        var passengerLabel = firstName && lastName ? getPassengerName(firstName, lastName) : labels[passengerId];
        var currentCurrency = (_b = (_a = services.find(function (service) { var _a; return !!((_a = service === null || service === void 0 ? void 0 : service.price) === null || _a === void 0 ? void 0 : _a.currency); })) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.currency;
        if (!currentCurrency) {
            currentCurrency = getCurrency();
        }
        var servicesPrice = {
            amount: 0,
            currency: currentCurrency
        };
        passengersState[passengerId].services.forEach(function (selectedService) {
            var service = services.find(function (service) { return service.id === selectedService.serviceId; });
            if (service) {
                servicesPrice.amount +=
                    selectedService.count * selectedService.segmentIds.length * service.price.amount;
                servicesPrice.currency = service.price.currency;
            }
        });
        if (passengersState[passengerId].seats) {
            passengersState[passengerId].seats.forEach(function (seat) {
                if (seat.price) {
                    servicesPrice.amount += seat.price.amount;
                    servicesPrice.currency = seat.price.currency;
                }
            });
        }
        return (React.createElement(Passenger, { id: passengerId, key: passengerId, onFill: function (values, fieldValue) { return onFill(values, passengerId, fieldValue); }, passengerSupplierType: passenger.supplierTravellerType, fields: passengerFields, passengerType: passenger.type, selectPassengerId: selectPassengerId, isReadonly: isReadonly, isAuthorized: isAuthorized, servicesTotalPrice: servicesPrice, label: passengerLabel, isInputsReadonly: isInputsReadonly, onCorrectFilled: onCorrectFormFilledHandler, open: !isNew || inModal || passengerId <= openedPassengerFormId || isTerminal, services: showServices &&
                hasAvailableServices && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement(ServicePage, null, function (_a) {
                    var runServiceRequest = _a.runServiceRequest;
                    return (React.createElement(Services, { passengerId: passengerId, services: services, segments: segments, runServiceRequest: runServiceRequest }));
                }))) }));
    })));
};
var mapStateToProps = function (state, ownProps) {
    return __assign(__assign({}, ownProps), { labels: getPassengerLabels(state), passengersState: getPassengerStateCompatible(state), services: getServices(state), isReadonly: getIsTravellersDataFilled(state), isNew: isNew(state) });
};
export default connect(mapStateToProps)(Passengers);
