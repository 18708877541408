import * as React from 'react';
import css from '../PriceDetails.css';
import cn from 'classnames';
import { Money } from '../../../Money';
var DetalizationLine = function (props) {
    var _a;
    var total = props.total, fee = props.fee, className = props.className, children = props.children, price = props.price, title = props.title;
    var content = children !== null && children !== void 0 ? children : (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            title,
            ":"),
        React.createElement(Money, { money: price })));
    return React.createElement("div", { className: cn(css.item, (_a = {}, _a[css.item_fee] = fee, _a[css.item_total] = total, _a), className) }, content);
};
export default DetalizationLine;
