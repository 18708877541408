import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useState, useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import Button from '../../Button/Button';
import * as Queries from '../../__queries__/query.graphql';
import WarningModal from '../../WarningModal';
import { useTranslation } from 'react-i18next';
import ServiceCard from '../ServicesCard/ServicesCard';
import { ServiceType } from '../../Checkout/components/Checkout/OrderPaidServiceCards/types';
import Header from './Components/Header';
import { getGroupedAllSelectedServices, getOrder } from '../../Checkout/store/order/selectors';
import { useToggleable } from '../../hooks';
import { getItemsForVoidRefund, hasAtLeastOneProductToVoidRefund, selectCheckedProducts } from '../ServicesCard/utils';
import { checkIsAtLeastOneSelected, clear, setValueAll } from './utils';
import { Steps } from './types';
import { useUpdateOrderMutation } from '../../__queries__/Order';
import { fillOrder } from '../../Checkout/store/order/actions';
import FullScreenModal from '../FullScreenModal/FullScreenModal';
initI18n('ServiceCardVoid');
var ServiceCardVoid = function (props) {
    var setRefundOrderResult = props.setRefundOrderResult, openModal = props.openModal;
    var getRefundCalculcation = useQuery(Queries.GetGdsServicesRefundInfo, { skip: true }).refetch;
    var _a = __read(useMutation(Queries.CreateGdsServicesRefund), 1), createRefundOrder = _a[0];
    var dispatch = useDispatch();
    var order = useSelector(getOrder);
    var _b = __read(useState(), 2), calculation = _b[0], setCalculation = _b[1];
    var _c = __read(useState(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var _d = __read(useState(), 2), productsIdsByEmd = _d[0], setProductsIdsByEmd = _d[1];
    var _e = __read(useState(false), 2), isRequestError = _e[0], setIsRequestError = _e[1];
    var _f = useToggleable(false), isDialogOpen = _f.isOpen, openDialog = _f.open, closeDialog = _f.close;
    var theme = useTheme('ServicesCardVoid').ServicesCardVoid;
    var t = useTranslation('ServiceCardVoid').t;
    var _g = useSelector(getGroupedAllSelectedServices), baggage = _g.baggage, seats = _g.seats, meals = _g.meals, misc = _g.misc;
    var addProductsIdsByEmd = function (productsToAdd) {
        setProductsIdsByEmd(function (prevState) {
            var mergedProductsIdsBy = new Map(prevState);
            productsToAdd.forEach(function (productsIds, emd) {
                var prevProductsIds = mergedProductsIdsBy.get(emd);
                mergedProductsIdsBy.set(emd, new Set(__spreadArray(__spreadArray([], __read((prevProductsIds || [])), false), __read(productsIds), false)));
            });
            return mergedProductsIdsBy;
        });
    };
    var summary = useMemo(function () {
        var _a;
        var refund = null;
        var voidSum = null;
        calculation === null || calculation === void 0 ? void 0 : calculation.gdsServicesRefundInfo.forEach(function (item) {
            item.gdsServiceProductsRefundInfo.forEach(function (serivce) {
                if (serivce.refundable && !serivce.isVoid) {
                    if (refund === null) {
                        refund = __assign({}, serivce.sumToRefund);
                    }
                    else {
                        refund.amount += serivce.sumToRefund.amount;
                    }
                }
                if (serivce.isVoid) {
                    if (voidSum === null) {
                        voidSum = __assign({}, serivce.sumToRefund);
                    }
                    else {
                        voidSum.amount += serivce.sumToRefund.amount;
                    }
                }
            });
        });
        var result = {
            refund: refund,
            voidSum: voidSum,
            total: ((_a = calculation === null || calculation === void 0 ? void 0 : calculation.prices) === null || _a === void 0 ? void 0 : _a.priceToPay)
                ? __assign(__assign({}, calculation.prices.priceToPay), { amount: calculation.prices.priceToPay.amount * -1 }) : null
        };
        return result;
    }, [calculation]);
    var step = calculation ? Steps.Confirm : Steps.Select;
    var renderRefundVoidButton = useCallback(function () {
        var onClick = function () {
            openDialog();
            setCalculation(null);
            setIsLoading(false);
        };
        if (props.renderRefundVoidButton) {
            return props.renderRefundVoidButton({ onClick: onClick });
        }
        return (React.createElement(Button, { onClick: onClick, variant: 'outline' }, t('Void/Refund serv.')));
    }, [props.renderRefundVoidButton]);
    var calculate = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var productIds, result, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    productIds = selectCheckedProducts(values, productsIdsByEmd);
                    setIsLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, getRefundCalculcation({
                            params: {
                                orderId: order.id,
                                productIds: productIds
                            }
                        })];
                case 2:
                    result = (_b.sent()).data.GetGdsServicesRefundInfo;
                    setCalculation(result);
                    return [3 /*break*/, 5];
                case 3:
                    _a = _b.sent();
                    setIsRequestError(true);
                    closeDialog();
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var _h = __read(useUpdateOrderMutation(), 1), updateOrder = _h[0];
    var confirm = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var productIds, CreateGdsServicesRefund, priceToPay, _a, orderData;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    productIds = selectCheckedProducts(values, productsIdsByEmd);
                    setIsLoading(true);
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, 4, 7]);
                    return [4 /*yield*/, createRefundOrder({
                            variables: {
                                params: {
                                    orderId: order.id,
                                    productIds: productIds
                                }
                            }
                        })];
                case 2:
                    CreateGdsServicesRefund = (_e.sent()).data.CreateGdsServicesRefund;
                    priceToPay = (_b = CreateGdsServicesRefund === null || CreateGdsServicesRefund === void 0 ? void 0 : CreateGdsServicesRefund.prices) === null || _b === void 0 ? void 0 : _b.priceToPay;
                    if (priceToPay === null || priceToPay === void 0 ? void 0 : priceToPay.amount) {
                        setRefundOrderResult(__assign(__assign({}, priceToPay), { amount: priceToPay.amount * -1 }));
                    }
                    else {
                        setRefundOrderResult(null);
                    }
                    return [3 /*break*/, 7];
                case 3:
                    _a = _e.sent();
                    setIsRequestError(true);
                    return [3 /*break*/, 7];
                case 4: 
                // WEBSKY-3018: delay for updating services in the order
                return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 3000); })];
                case 5:
                    // WEBSKY-3018: delay for updating services in the order
                    _e.sent();
                    return [4 /*yield*/, updateOrder({ variables: { id: order.id } })];
                case 6:
                    orderData = _e.sent();
                    if ((_c = orderData === null || orderData === void 0 ? void 0 : orderData.data) === null || _c === void 0 ? void 0 : _c.UpdateOrder) {
                        dispatch(fillOrder((_d = orderData === null || orderData === void 0 ? void 0 : orderData.data) === null || _d === void 0 ? void 0 : _d.UpdateOrder));
                    }
                    closeDialog();
                    openModal();
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var baggageToVoidRefund = useMemo(function () { return getItemsForVoidRefund(baggage, props.servicesAvailability.Baggage); }, [
        props.servicesAvailability.Baggage,
        baggage
    ]);
    var seatsToVoidRefund = useMemo(function () { return getItemsForVoidRefund(seats, props.servicesAvailability.Seat); }, [
        props.servicesAvailability.Seat,
        seats
    ]);
    var mealsToVoidRefund = useMemo(function () { return getItemsForVoidRefund(meals, props.servicesAvailability.Meal); }, [
        props.servicesAvailability.Meal,
        meals
    ]);
    var miscToVoidRefund = useMemo(function () { return getItemsForVoidRefund(misc, props.servicesAvailability.Misc); }, [
        props.servicesAvailability.Misc,
        misc
    ]);
    var isBaggageToVoidRefund = hasAtLeastOneProductToVoidRefund(baggageToVoidRefund);
    var isSeatsToVoidRefund = hasAtLeastOneProductToVoidRefund(seatsToVoidRefund);
    var isMealsToVoidRefund = hasAtLeastOneProductToVoidRefund(mealsToVoidRefund);
    var isMiscToVoidRefund = hasAtLeastOneProductToVoidRefund(miscToVoidRefund);
    var isAtLeastOneProductRefundVoid = isBaggageToVoidRefund || isSeatsToVoidRefund || isMealsToVoidRefund || isMiscToVoidRefund;
    return (React.createElement(React.Fragment, null,
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), onClose: function () {
                setIsRequestError(false);
            }, onButtonClick: function () {
                setIsRequestError(false);
            }, buttonText: t('OK, thank you'), isOpen: isRequestError }),
        isDialogOpen && (React.createElement(Form, { onSubmit: step === Steps.Select ? calculate : confirm, mutators: {
                clear: clear,
                setValueAll: setValueAll
            }, render: function (_a) {
                var handleSubmit = _a.handleSubmit, values = _a.values, form = _a.form;
                var isAtLeastOneSelected = checkIsAtLeastOneSelected(values);
                var propsForCard = {
                    fullWidth: true,
                    exchangeVoidMode: true,
                    mutators: form.mutators,
                    values: values,
                    step: step,
                    isLoading: isLoading,
                    calculation: calculation,
                    setProductsIdsByEmd: addProductsIdsByEmd
                };
                return (React.createElement(FullScreenModal, { open: true, onClose: closeDialog, title: t('Refund/Void services'), header: React.createElement(Header, { step: step, isAtLeastOneSelected: isAtLeastOneSelected, onClear: form.mutators.clear, summary: summary, isLoading: isLoading, onSubmit: handleSubmit }), headerAlignment: 'right' },
                    React.createElement("div", { className: theme.cards },
                        React.createElement("div", { className: theme.cards__inner },
                            isSeatsToVoidRefund && (React.createElement(ServiceCard, __assign({ type: ServiceType.Seats, itemsToRefundVoid: seatsToVoidRefund }, propsForCard))),
                            isBaggageToVoidRefund && (React.createElement(ServiceCard, __assign({ type: ServiceType.Baggage, itemsToRefundVoid: baggageToVoidRefund }, propsForCard))),
                            isMealsToVoidRefund && (React.createElement(ServiceCard, __assign({ type: ServiceType.Meal, itemsToRefundVoid: mealsToVoidRefund }, propsForCard))),
                            isMiscToVoidRefund && (React.createElement(ServiceCard, __assign({ type: ServiceType.Misc, itemsToRefundVoid: miscToVoidRefund }, propsForCard)))))));
            } })),
        isAtLeastOneProductRefundVoid && renderRefundVoidButton()));
};
export default ServiceCardVoid;
