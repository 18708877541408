import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { contactsIcon, passengerIcon } from '../../../Icons';
import { useTranslation } from 'react-i18next';
import { OrderStatus } from '../../../../__generated__/globalTypes';
import { hideText } from '../../../../Passenger/utils';
var PassengersStep = function (_a) {
    var onChange = _a.onChange, order = _a.order, passengers = _a.passengers, nationalities = _a.nationalities;
    var css = useTheme('MobileStepbar').StepContentStyles;
    var t = useTranslation().t;
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.content },
            order.customer && (React.createElement("div", { className: css.content__item },
                React.createElement("div", { className: cn(css.item__row, css.segment__header) },
                    React.createElement("span", { className: css.content__icon }, contactsIcon),
                    React.createElement("span", null, t('MobileStepbar:Contact'))),
                React.createElement("div", null, order.customer.values.map(function (field) {
                    if (field.value) {
                        return (React.createElement("div", { key: field.type, className: css.passenger }, field.value));
                    }
                    return null;
                })))),
            passengers.map(function (passenger) {
                var passengerInfo = {};
                passenger.values.forEach(function (field) {
                    if (!passengerInfo.hasOwnProperty(field.name)) {
                        passengerInfo[field.name] = field.value;
                    }
                });
                return (React.createElement("div", { key: passenger.id, className: css.content__item },
                    React.createElement("div", { className: cn(css.item__row, css.segment__header) },
                        React.createElement("span", { className: css.content__icon }, passengerIcon),
                        React.createElement("span", null, passengerInfo.firstName || passengerInfo.lastName ? ("".concat(passengerInfo.firstName, " ").concat(passengerInfo.lastName)) : (React.createElement("span", null, "\u2014")))),
                    passengerInfo.gender && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: cn(css.item__row, css.passenger__info) },
                            t("MobileStepbar:".concat(passenger.type)),
                            ' ',
                            t("MobileStepbar:".concat(passengerInfo.gender)),
                            " \u2022 ",
                            passengerInfo.birthDate),
                        React.createElement("div", { className: cn(css.item__row, css.passenger__documents) },
                            nationalities && React.createElement("span", null, nationalities.get(passengerInfo.nationality)),
                            passengerInfo.docType &&
                                passengerInfo.docNumber &&
                                passengerInfo.docExpiryDate && (React.createElement("span", null,
                                t("Passenger:".concat(passengerInfo.docType)),
                                " \u2022",
                                ' ',
                                hideText(passengerInfo.docNumber),
                                " \u2022 ",
                                passengerInfo.docExpiryDate)))))));
            })),
        order.status !== OrderStatus.Booked && order.status !== OrderStatus.Confirmed && (React.createElement("div", { className: css.footer },
            React.createElement("span", { onClick: onChange }, t('MobileStepbar:Change'))))));
};
export default PassengersStep;
