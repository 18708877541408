import * as React from 'react';
import CheckoutAdditionalService from '../..';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { useSelector } from 'react-redux';
import { getMilesForOrder, isFFPUsed } from '../../../Checkout/store/order/selectors';
import { useCallback } from 'react';
import icons from '../../icons';
import { useConfig } from '../../../context';
var LoyaltyCard = function () {
    var t = useTranslation('Checkout').t;
    var css = useTheme('CheckoutAdditionalService').Loyalty;
    var isFFPProvidedToOrder = useSelector(isFFPUsed);
    var milesBonus = useSelector(getMilesForOrder);
    var _a = useConfig().global.companyInfo, loyaltyProgramAccountUrl = _a.loyaltyProgramAccountUrl, loyaltyProgramRegistrationUrl = _a.loyaltyProgramRegistrationUrl, loyaltyProgramName = _a.loyaltyProgramName;
    var handleViewStatusClick = useCallback(function () {
        var loyaltyUrl = loyaltyProgramAccountUrl !== null && loyaltyProgramAccountUrl !== void 0 ? loyaltyProgramAccountUrl : '/account/loyalty';
        window.open(loyaltyUrl, '_blank');
    }, [isFFPProvidedToOrder]);
    var handleRegisterClick = useCallback(function () {
        window.open(loyaltyProgramRegistrationUrl, '_blank');
    }, [isFFPProvidedToOrder]);
    return (React.createElement(CheckoutAdditionalService, { icon: icons.ffpIcon, className: css.loyalty, header: loyaltyProgramName, description: t('Start earning miles now to flight cheaper'), addButtonText: t('Register'), isSelected: !!milesBonus, addClick: handleRegisterClick, buttons: [{ text: t('View status'), action: handleViewStatusClick }] },
        React.createElement("p", { className: css.loyalty__awardedInfo },
            milesBonus,
            " ",
            t('miles will be awarded')),
        React.createElement("p", null, t('Miles will be credited to your account after the flight.'))));
};
export default LoyaltyCard;
