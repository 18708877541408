import classnames from 'classnames';
import { isFirstDayOfMonth } from 'date-fns';
import * as React from 'react';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { RouteType } from '../../../types';
import { Money as MoneyComponent } from '../../../../Money';
import { getProgressLevel } from '../../../utils';
import Tooltip from '../../../../Tooltip/Tooltip';
import { format, TABLET_MIN_WIDTH } from '../../../../utils';
import { useTheme } from '../../../../theme';
export var Day = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var t = useTranslation('PriceGraph').t;
    var day = props.date.getDate(), dayOfWeek = format(props.date, 'eeeee'), progress = props.price && props.maxPrice ? getProgressLevel(props.price.amount, props.maxPrice.amount) : 0;
    var theme = useTheme('PriceGraph').DayStyles;
    return (React.createElement("div", { className: classnames(theme.day, (_a = {}, _a[theme.day_notAvailable] = !props.price || !props.price.amount, _a), (_b = {}, _b[theme.outDate] = props.isOutDate, _b), (_c = {}, _c[theme.backDate] = props.isBackDate, _c), (_d = {},
            _d[theme.minPrice] = props.minPrice &&
                props.price &&
                props.price.amount === props.minPrice.amount &&
                props.price.amount,
            _d), (_e = {}, _e[theme.day_focused] = props.isFocused, _e)), onClick: function () {
            !props.isMobileMode &&
                props.onSelect &&
                props.price &&
                props.price.amount &&
                props.onSelect(props.date, props.routeType === RouteType.Return);
        } },
        props.price.amount > 0 && (React.createElement("div", { className: theme.price },
            t('from'),
            React.createElement(MoneyComponent, { money: props.price }))),
        isFirstDayOfMonth(props.date) && React.createElement("div", { className: theme.monthName }, format(props.date, 'LLLL')),
        React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH, values: window['testMediaQueryValues'] },
            React.createElement(Tooltip, { disableHoverListener: !props.price.amount, title: React.createElement("div", { className: classnames(theme.tooltip__text, (_f = {},
                        _f[theme.tooltip__text_return] = props.routeType === RouteType.Return,
                        _f)) },
                    React.createElement("div", { className: theme.tooltip__date }, format(props.date, 'd MMM, EEEEEE')),
                    React.createElement("div", null,
                        t('from'),
                        " ",
                        React.createElement(MoneyComponent, { moneyClassName: theme.tooltip__money, money: props.price }))), placement: "bottom", classes: {
                    tooltip: theme.tooltip,
                    popper: theme.popper
                } },
                React.createElement("div", { className: theme.bar },
                    React.createElement("div", { className: theme.progress, style: props.price && props.price.amount ? { height: "".concat(progress, "%") } : null })))),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement("div", { className: theme.bar },
                React.createElement("div", { className: theme.progress, style: props.price.amount ? { height: "".concat(progress, "%") } : null }))),
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.number }, day),
            React.createElement("div", { className: theme.dow }, dayOfWeek))));
};
