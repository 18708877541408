import { SET_OTHER_PASSENGER_TYPES } from './actions';
var initialState = [];
export var passengerTypesReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_OTHER_PASSENGER_TYPES:
            return action.payload;
        default:
            return state;
    }
};
