import { __read } from "tslib";
import { createSelector } from 'reselect';
export var getPassengers = function (refundState) {
    return Object.entries(refundState.passengers).map(function (_a) {
        var _b = __read(_a, 2), value = _b[1];
        return value;
    });
};
export var getPassengersWithChildren = createSelector(getPassengers, function (passengers) {
    return passengers.filter(function (passenger) { var _a; return ((_a = passenger.children) === null || _a === void 0 ? void 0 : _a.length) > 0; });
});
export var getSelectedPassengers = createSelector(getPassengers, function (passengers) {
    return passengers.filter(function (passenger) { return passenger.selected; });
});
export var getHasSelectedPassengers = createSelector(getSelectedPassengers, function (passengers) { return passengers.length > 0; });
var passengersWithChildrenIds = function (passengers) {
    return passengers
        .map(function (passenger) { return parseInt(passenger.id, 10); })
        .concat(Array.prototype.concat.apply([], passengers.map(function (passenger) { return passenger.children.map(function (children) { return parseInt(children.id, 10); }); })));
};
export var getSelectedPassengersWithChildrenIds = createSelector(getSelectedPassengers, passengersWithChildrenIds);
export var getPassengersWithChildrenIds = createSelector(getPassengers, passengersWithChildrenIds);
