import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Switch from '../../../Switch/components/Switch';
import { Money } from '../../../Money';
import { useTheme } from '../../../theme';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { useTranslation } from 'react-i18next';
var Additional = function (_a) {
    var _b, _c, _d, _e;
    var services = _a.services, header = _a.header, description = _a.description, baseOnSelect = _a.onSelect, hasProducts = _a.hasProducts, onToggle = _a.onToggle, type = _a.type, headerClassName = _a.headerClassName, numOfSegments = _a.numOfSegments, readonly = _a.readonly, _f = _a.fullScreen, fullScreen = _f === void 0 ? false : _f, _g = _a.variant, variant = _g === void 0 ? 'solid' : _g;
    var theme = useTheme('BaggageService').AdditionalStyles, totalPrice = { amount: 0, currency: null };
    var onSelect = readonly ? function () { return alert('readonly'); } : baseOnSelect;
    var t = useTranslation('BaggageService').t;
    var minPrice = null;
    var _h = __read(React.useState(hasProducts), 2), isOpen = _h[0], setOpen = _h[1];
    React.useEffect(function () {
        if (!isOpen) {
            setOpen(hasProducts);
        }
    }, [hasProducts]);
    services.forEach(function (service) {
        if (service.isSelected) {
            totalPrice.amount += service.baggage.price.amount;
            totalPrice.currency = service.baggage.price.currency;
        }
        if (!minPrice || service.baggage.price.amount < minPrice.amount) {
            minPrice = service.baggage.price;
        }
    });
    var toggleOpen = function () {
        if (readonly) {
            return;
        }
        var open = !isOpen;
        setOpen(open);
        onToggle(open);
    };
    return (React.createElement("div", { className: cn(theme.wrapper, (_b = {},
            _b[theme.wrapper_solid] = variant === 'solid',
            _b[theme.wrapper_outline] = variant === 'outline',
            _b[theme.wrapper_active] = hasProducts,
            _b[theme.wrapper_inDialog] = !fullScreen,
            _b)) },
        React.createElement("div", { className: theme.head, onClick: toggleOpen },
            React.createElement("div", { className: cn(theme.header, headerClassName, (_c = {},
                    _c[theme.not_selected] = !hasProducts,
                    _c)) }, header),
            React.createElement(MediaQuery, { minWidth: 'mobile' },
                React.createElement("div", { className: theme.switcher },
                    React.createElement(Switch, { label: React.createElement(React.Fragment, null,
                            !hasProducts && React.createElement("span", { className: theme.label__from }, t('from')),
                            React.createElement(Money, { moneyClassName: cn(theme.money, (_d = {},
                                    _d[theme.money_opened] = isOpen,
                                    _d)), money: hasProducts ? totalPrice : minPrice, multiplyBy: numOfSegments })), mainClassName: cn((_e = {}, _e[theme.checkbox_notActive] = !isOpen, _e)), labelClassName: theme.checkbox__label, switchPosition: "right", isActive: isOpen, isDisabled: readonly })))),
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement("div", { className: theme.desc }, description),
            React.createElement(Collapse, { in: isOpen },
                React.createElement("div", null, services.map(function (item, index) {
                    var _a;
                    var isSelected = item.isSelected;
                    return (React.createElement("div", { key: index, className: theme.item },
                        React.createElement("div", null, item.label),
                        React.createElement("div", { className: theme.itemprice },
                            React.createElement(Money, { moneyClassName: cn(theme.item_money, (_a = {},
                                    _a[theme.money_opened] = isOpen,
                                    _a)), money: item.baggage.price, multiplyBy: numOfSegments }),
                            type === 'checkbox' ? (React.createElement(Checkbox, { color: "primary", checked: isSelected, classes: {
                                    colorPrimary: theme.checkbox,
                                    checked: theme.checkbox_checked
                                }, onClick: function () { return onSelect(item.baggage.id, !isSelected); }, disabled: readonly })) : (React.createElement(Radio, { color: "primary", checked: isSelected, classes: {
                                    colorPrimary: theme.checkbox,
                                    checked: theme.checkbox_checked
                                }, onClick: function () { return onSelect(item.baggage.id); }, disabled: readonly })))));
                }))))));
};
export default Additional;
