import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { Button } from '../../../index';
var SelectButton = function (props) {
    var onClick = props.onClick;
    var t = useTranslation('DesktopFlight').t;
    var css = useTheme('DesktopFlightRoute').SelectButton;
    return (React.createElement("div", { className: css.wrapper },
        React.createElement(Button, { onClick: onClick, className: css.button }, t('Select'))));
};
export default SelectButton;
