import { __read } from "tslib";
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import * as React from 'react';
import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { initI18n, useIsMobile } from '../../utils';
import { AeroexpressIcon, transferLogo } from '../icons';
import Modal from '../../Modal';
import AdditionalServiceCard from '../../AdditionalServiceCard/components/AdditionalService';
import CardContent from './CardContent/CardContent';
import { useTheme } from '../../theme';
import { Money } from '../../index';
import Warning from '../../Checkout/components/Checkout/Upsale/Warning/Warning';
import { DisplayMode } from '../../AdditionalServices/components/types';
import { useAeroexpress } from './hooks';
import AeroexpressPopup from '../../AeroexpressPopup';
import { getAeroexpress, getOrderId, getTravellers } from '../../Checkout/store/order/selectors';
initI18n('Aeroexpress');
var Aeroexpress = function (_a) {
    var _b;
    var _c;
    var displayMode = _a.displayMode, isMobileStepbar = _a.isMobileStepbar, updateServices = _a.updateServices, isLoading = _a.isLoading;
    var css = useTheme('Aeroexpress').Aeroexpress;
    var t = useTranslation('Aeroexpress').t;
    var _d = __read(useState(false), 2), modalAeroexpress = _d[0], setModalAeroexpress = _d[1];
    var aeroexpress = useSelector(getAeroexpress);
    var orderId = useSelector(getOrderId);
    var travellers = useSelector(getTravellers);
    var selectedTrips = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.selectedTrips;
    var isMobile = useIsMobile();
    var isHorizontal = displayMode === DisplayMode.Horizontal;
    var openModal = function () { return setModalAeroexpress(true); };
    var closeModal = function () { return setModalAeroexpress(false); };
    useMemo(function () {
        closeModal();
    }, [selectedTrips]);
    var _e = useAeroexpress(updateServices, orderId), hasSelectedServices = _e.hasSelectedServices, tickets = _e.tickets, buttonText = _e.buttonText, price = _e.price, onClear = _e.onClear, showWarning = _e.showWarning, servicesRedirectURL = _e.servicesRedirectURL, closeWarning = _e.closeWarning;
    var service = {
        header: 'Transfer',
        subheader: t('Fast and convenient'),
        logo: isHorizontal ? null : React.createElement("div", { className: css.transferIcon }, transferLogo),
        price: price,
        active: hasSelectedServices,
        icon: AeroexpressIcon,
        content: isHorizontal ? (React.createElement(CardContent, { travellers: travellers, selectedServices: [] })) : (React.createElement(CardContent, { travellers: travellers, selectedServices: selectedTrips }))
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        isMobileStepbar ? (React.createElement("span", null, isLoading ? (React.createElement(CircularProgress, { size: 20, color: "inherit", classes: { root: css.stepbar__progress } })) : (selectedTrips === null || selectedTrips === void 0 ? void 0 : selectedTrips.length) ? (React.createElement("span", { className: css.stepbar__actions },
            React.createElement(Money, { money: price }),
            React.createElement("i", { onClick: ((_c = aeroexpress.selectedTrips) === null || _c === void 0 ? void 0 : _c.length) ? openModal : onClear, className: cn((_b = {},
                    _b[css.stepbar__action_downloadTickets] = tickets.length,
                    _b[css.stepbar__action_close] = !tickets.length,
                    _b)) }))) : (React.createElement("span", { onClick: openModal }, t('Add'))))) : (React.createElement(AdditionalServiceCard, { horizontal: displayMode === DisplayMode.Horizontal, service: service, isLoading: isLoading, buttonText: buttonText, onClick: openModal })),
        React.createElement(Modal, { classes: {
                root: css.aeroexpress_root,
                paper: css.aeroexpress_paper,
                scrollBody: css.scrollBody
            }, slideUp: isMobile, open: modalAeroexpress },
            React.createElement(AeroexpressPopup, { trips: aeroexpress.trips, selectedTrips: aeroexpress.selectedTrips, travellers: travellers, isLoading: isLoading, onClose: closeModal, onSubmit: updateServices }))));
};
export default Aeroexpress;
