import { format, parseISO, parse } from 'date-fns';
export var convertDocuments = function (documentsList) {
    return documentsList.map(function (document) {
        var docNumber = document.values.find(function (value) { return value.name === 'docNumber'; }).value;
        var docType = document.values.find(function (value) { return value.name === 'docType'; }).value;
        var options = document.values
            .find(function (value) { return value.name === 'docType'; })
            .validationRules[0].options.map(function (option) { return option.value; });
        var docExpiryDate = null;
        var docExpiryDateField = document.values.find(function (value) { return value.name === 'docExpiryDate'; });
        if (docExpiryDateField === null || docExpiryDateField === void 0 ? void 0 : docExpiryDateField.value) {
            try {
                docExpiryDate = format(parseISO(docExpiryDateField.value), 'dd.MM.yyyy');
            }
            catch (e) {
                try {
                    docExpiryDate = format(parse(docExpiryDateField.value, 'dd.MM.yyyy', new Date()), 'dd.mm.yyyy');
                }
                catch (e) {
                    docExpiryDate = format(parseISO(docExpiryDateField.value), 'dd-MM-yyyy');
                }
            }
        }
        return {
            docNumber: docNumber,
            docType: docType,
            docExpiryDate: docExpiryDate,
            options: options
        };
    });
};
export var convertDocumentInfoCardToFormData = function (document) {
    return {
        number: document.docNumber,
        type: document.docType,
        expiryDate: document.docExpiryDate
    };
};
