import * as React from 'react';
import { memo } from 'react';
import { useTheme } from '../../theme';
var Group = memo(function (_a) {
    var group = _a.group, optionRenderer = _a.optionRenderer;
    var css = useTheme().Group;
    return (React.createElement("div", { className: css.group },
        group.label && React.createElement("label", { className: css.group__label }, group.label),
        group.options.map(optionRenderer)));
});
export default Group;
