import * as React from 'react';
import { scheduleFlightToResultsFlightAdapter } from './services/adapters';
import FlightSelect from '../../../../FlightSelect/components/FlightSelect';
import WeekDays from './components/WeekDays/WeekDays';
import FlightDates from './components/FlightDates/FlightDates';
import { ThemeProvider, useTheme } from '../../../../theme';
import { useCalendarPopover } from './components/CalendarPopover/hooks';
import { useRef } from 'react';
import CalendarPopover from './components/CalendarPopover/CalendarPopover';
var ScheduleFlight = function (props) {
    var scheduleFLight = props.flight, onSelect = props.onSelect, currentSearchSegments = props.currentSearchSegments;
    var flight = scheduleFlightToResultsFlightAdapter(scheduleFLight);
    var css = useTheme('Schedule').ScheduleFlight;
    var _a = useCalendarPopover(), isOpen = _a.isOpen, open = _a.open, close = _a.close;
    var wrapperRef = useRef();
    var renderScheduleInfo = function () {
        return (React.createElement("div", { className: css.datesWrapper },
            React.createElement(WeekDays, { className: css.weekdays, weekdays: scheduleFLight.segments[0].segment.weekdays }),
            React.createElement(FlightDates, { className: css.scheduleDates, startSchedule: scheduleFLight.segments[0].segment.scheduleStartDate, endSchedule: scheduleFLight.segments[0].segment.scheduleEndDate })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: wrapperRef, onClick: open },
            React.createElement(ThemeProvider, { value: { FlightSelect: { FlightSelect: { segments: css.segments } } } },
                React.createElement(FlightSelect, { flight: flight, selectedFares: [], renderSlider: renderScheduleInfo, isSchedulePage: true })),
            React.createElement("div", { onClick: function (e) { return e.stopPropagation(); } },
                React.createElement(CalendarPopover, { datepickerProps: { onSelect: onSelect }, currentSearchSegments: currentSearchSegments, flightDates: scheduleFLight.segments[0].segment.datesData.map(function (_a) {
                        var date = _a.date;
                        return date;
                    }), anchorEl: wrapperRef.current, anchorOrigin: { horizontal: 'left', vertical: 'bottom' }, open: isOpen, onClose: close })))));
};
export default ScheduleFlight;
