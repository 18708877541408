import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { useTheme } from '../../../../../theme';
import * as Mutation from '../../../../../__queries__/CheckinCreateOrder.graphql';
import RetrieveBooking from '../../../../../RetrieveBooking';
import Modal from '../../../../../Modal';
import { fillCheckinOrder } from '../../../store/order/actions';
import CheckinRules from '../../../../../CheckinRules';
import * as CheckinRulesQuery from '../../../../../__queries__/CheckinRules.graphql';
import { CheckinGoal, reachGoal } from '../../../../../analytics';
import { CheckinOrderStatus } from '../../../../../__generated__/globalTypes';
import { Mode } from '../../../../../RetrieveBooking/components/RetrieveBooking';
import { useLocation } from 'react-router';
var infoIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 11V17H11V11H13ZM12 20C7.58999 20 3.99999 16.41 3.99999 12C3.99999 7.59 7.58999 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM13 7V9H11V7H13Z", fill: "#9A9A9A" })));
var Initial = function (_a) {
    var match = _a.match, history = _a.history, setCheckinOrder = _a.setCheckinOrder;
    var _b = __read(useState(false), 2), rulesModalIsOpen = _b[0], setRulesModalIsOpen = _b[1];
    var css = useTheme('Checkin').Inital;
    var t = useTranslation('Checkin').t;
    var params = match.params;
    var location = useLocation();
    var isAutoSubmit = location.pathname.includes('submit');
    var _c = __read(useMutation(Mutation.CheckinCreateOrder), 1), createCheckin = _c[0];
    var _d = __read(useLazyQuery(CheckinRulesQuery.CheckinRules), 2), getCheckin = _d[0], rules = _d[1].data;
    var order;
    var openModal = function () { return setRulesModalIsOpen(true); };
    var closeModal = function () { return setRulesModalIsOpen(false); };
    var getCheckinRules = function (orderId) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getCheckin({ variables: { id: orderId } })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var openCheckinPage = function () {
        if (order) {
            reachGoal(CheckinGoal.Create);
            setCheckinOrder(order);
            history.push("/".concat(order.id));
        }
    };
    var onSumbit = useCallback(function (id, secret, extraSecret) { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                    var variables, _a, data, errors, result_1, e_2;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                variables = { params: { id: id, secret: secret } };
                                if (extraSecret) {
                                    variables.params.extraSecret = extraSecret;
                                }
                                return [4 /*yield*/, createCheckin({ variables: variables })];
                            case 1:
                                _a = _b.sent(), data = _a.data, errors = _a.errors;
                                result_1 = !errors && (data === null || data === void 0 ? void 0 : data.CheckinCreateOrder);
                                resolve(data.CheckinCreateOrder);
                                if (result_1) {
                                    order = result_1;
                                    setTimeout(function () {
                                        if (order.status === CheckinOrderStatus.Confirmed) {
                                            openCheckinPage();
                                        }
                                        else if (!result_1.isBlockedForPayment) {
                                            getCheckinRules(order.id).then(openModal);
                                        }
                                    });
                                }
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _b.sent();
                                reject(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); })];
        });
    }); }, []);
    var acceptRules = useCallback(function () {
        closeModal();
        openCheckinPage();
    }, []);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement(RetrieveBooking, { mode: Mode.Checkin, className: css.form, title: t('Online check-in'), initialId: params.PNR, initialSecret: params.lastName, submitWithInitialValues: isAutoSubmit, onSubmit: onSumbit, idTitle: t('Ticket number or booking reference'), secretTitle: t('Passenger last name'), hideSecretHint: true }),
        React.createElement("div", { className: css.hint },
            React.createElement("h2", { className: css.hint__title }, t('How to find a booking?')),
            React.createElement("div", { className: css.hint__info },
                React.createElement("span", { className: css.hint__info__icon }, infoIcon),
                React.createElement("p", { className: css.hint__info__text }, t('Enter the passenger last name (in latin characters) and the booking code. \nYou can find it in the itinerary receipt sent to your email after booking.'))),
            React.createElement("div", { className: css.hint__image })),
        React.createElement(Modal, { open: rulesModalIsOpen, onClose: closeModal, maxWidth: "md", classes: {
                container: css.modal,
                paper: css.modal__paper
            } }, !!rules && React.createElement(CheckinRules, { onSumbit: acceptRules, rules: rules.CheckinRules }))));
};
export default connect(null, {
    setCheckinOrder: fillCheckinOrder
})(withRouter(Initial));
