import { __read } from "tslib";
import * as React from 'react';
import Login from './Login/Login';
import CreateAccount from './CreateAccount/CreateAccount';
import { useMutation, useQuery } from '@apollo/react-hooks';
import * as FfpDefaultAccount from '../../../../__queries__/FfpDefaultAccount.graphql';
import * as CreateFfpAccount from '../../../../__queries__/CreateFfpAccount.graphql';
import { useResponseErrorParser } from './hooks';
import { FORM_ERROR } from 'final-form';
import ForgotPassword from './ForgotPassword/ForgotPassword';
var LoyaltyConnect = function (props) {
    var _a = __read(React.useState('Login'), 2), type = _a[0], typeChange = _a[1];
    var parseResponse = useResponseErrorParser().parseResponse;
    var data = useQuery(FfpDefaultAccount.FfpDefaultAccount).data;
    var _b = __read(React.useState(false), 2), createAccountSuccess = _b[0], setCreateAccountSuccess = _b[1];
    var _c = __read(useMutation(CreateFfpAccount.CreateFfpAccount), 1), createAccountMutation = _c[0];
    var createAccountRequest = function (data) {
        return createAccountMutation({
            variables: {
                params: data
            }
        }).then(function (response) {
            var _a;
            var _b, _c;
            if (!((_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.CreateFfpAccount) === null || _c === void 0 ? void 0 : _c.result)) {
                // TODO: HACK fix it
                if (response.data.CreateFfpAccount.message === 'Сообщение программы: invalid FFP LK system') {
                    setCreateAccountSuccess(response.data.CreateFfpAccount.result);
                    setTimeout(function () {
                        typeChange('Login');
                    }, 2000);
                    return undefined;
                }
                return _a = {},
                    _a[FORM_ERROR] = parseResponse(response.data.CreateFfpAccount.message),
                    _a;
            }
            else {
                setCreateAccountSuccess(response.data.CreateFfpAccount.result);
            }
            setTimeout(function () {
                typeChange('Login');
            }, 2000);
            return undefined;
        });
    };
    var toResetPassword = function () {
        typeChange('Reset');
    };
    if (type === 'Login') {
        return (React.createElement(Login, { onLoyaltyCardSaved: props.onLoyaltyCardSaved, toCreateAccount: function () { return typeChange('Create'); }, toResetPassword: toResetPassword, queryType: props.authType }));
    }
    else if (type === 'Reset') {
        return React.createElement(ForgotPassword, { toLogin: function () { return typeChange('Login'); }, email: props.userInfo.email });
    }
    else {
        return (React.createElement(CreateAccount, { initialValues: data, onCreateAccount: createAccountRequest, success: createAccountSuccess, toLogin: function () { return typeChange('Login'); } }));
    }
};
export default LoyaltyConnect;
