import { __read } from "tslib";
import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { goToBeginIcon, goToEndIcon, goToNextIcon, goToPrevIcon } from '../../icons';
var Pagination = function (props) {
    var _a, _b, _c, _d;
    var theme = useTheme('EventsQueues').EventsQueues;
    var t = useTranslation('EventsQueues').t;
    var _e = __read(useState(String(props.currentIndex)), 2), pageInputValue = _e[0], setPageInputValue = _e[1];
    useEffect(function () {
        setPageInputValue(String(props.currentIndex));
    }, [props.currentIndex]);
    var isFirstPage = props.currentIndex === 1;
    var isLastPage = props.currentIndex === props.totalPages;
    var goToBeginHandler = useCallback(function () {
        if (!isFirstPage) {
            props.handleChange(1);
        }
    }, [isFirstPage]);
    var goToPrevHandler = useCallback(function () {
        if (!isFirstPage) {
            props.handleChange(props.currentIndex - 1);
        }
    }, [isFirstPage, props.currentIndex]);
    var goToNextHandler = useCallback(function () {
        if (!isLastPage) {
            props.handleChange(props.currentIndex + 1);
        }
    }, [isLastPage, props.currentIndex]);
    var goToEndHandler = useCallback(function () {
        if (!isLastPage) {
            props.handleChange(props.totalPages);
        }
    }, [isLastPage, props.totalPages]);
    var pageInputChangeHandler = function (event) {
        setPageInputValue(event.target.value);
    };
    var pageInputKeyUpHandler = function (event) {
        if (event.key === 'Enter') {
            submitInputValue();
        }
    };
    var submitInputValue = function () {
        var parsedValue = parseInt(pageInputValue, 10);
        if (Number.isInteger(parsedValue) && parsedValue > 0 && parsedValue <= props.totalPages) {
            props.handleChange(parsedValue);
            setPageInputValue(String(parsedValue));
        }
        else {
            props.handleChange(1);
            setPageInputValue('1');
        }
    };
    return (React.createElement("div", { className: theme.pagination },
        React.createElement("button", { type: "button", onClick: goToBeginHandler, className: cn(theme.pagination__button, (_a = {}, _a[theme.pagination__button_disabled] = isFirstPage, _a)) }, goToBeginIcon),
        React.createElement("button", { type: "button", onClick: goToPrevHandler, className: cn(theme.pagination__button, (_b = {}, _b[theme.pagination__button_disabled] = isFirstPage, _b)) }, goToPrevIcon),
        React.createElement("div", { className: theme.pagination__list },
            React.createElement("span", { className: theme.pagination__text }, t('Page')),
            React.createElement("input", { type: "text", onChange: pageInputChangeHandler, onBlur: submitInputValue, onKeyUp: pageInputKeyUpHandler, className: theme.pagination__input, value: pageInputValue }),
            React.createElement("span", { className: theme.pagination__text },
                t('of'),
                " ",
                props.totalPages)),
        React.createElement("button", { type: "button", onClick: goToNextHandler, className: cn(theme.pagination__button, (_c = {}, _c[theme.pagination__button_disabled] = isLastPage, _c)) }, goToNextIcon),
        React.createElement("button", { type: "button", onClick: goToEndHandler, className: cn(theme.pagination__button, (_d = {}, _d[theme.pagination__button_disabled] = isLastPage, _d)) }, goToEndIcon)));
};
export default Pagination;
