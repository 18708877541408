import { checkIsTerminal } from '../../../utils';
import { createSelector } from 'reselect';
import { MAX_SEGMENTS_NUMBER, MIN_SEGMENTS_NUMBER, MAX_SEGMENTS_NUMBER_TERMINAL } from './reducers';
var getSegmentsState = function (state) { return state.segments; };
export var isMultiCity = createSelector(getSegmentsState, function (state) { return state.isMultiCity; });
export var getSimpleRouteSegment = createSelector(getSegmentsState, function (state) { return state.simpleRouteSegment; });
export var getMultiCitySegments = createSelector(getSegmentsState, function (state) { return state.multiCitySegments; });
export var canAddSegment = createSelector(isMultiCity, getMultiCitySegments, function (isMultiCity, segments) {
    var isTerminal = checkIsTerminal();
    return isTerminal
        ? segments.length < MAX_SEGMENTS_NUMBER_TERMINAL
        : isMultiCity && segments.length < MAX_SEGMENTS_NUMBER;
});
export var canRemoveSegment = createSelector(isMultiCity, getMultiCitySegments, function (isMultiCity, segments) { return isMultiCity && segments.length > MIN_SEGMENTS_NUMBER; });
export var getSegments = createSelector(isMultiCity, getSimpleRouteSegment, getMultiCitySegments, function (isMultiCity, simpleRouteSegments, multiCitySegments) { return (isMultiCity ? multiCitySegments : [simpleRouteSegments]); });
export var makeGetSegment = function (index) {
    return createSelector(getSegments, function (segments) { return segments[index]; });
};
export var getSelectedDates = createSelector(isMultiCity, getSimpleRouteSegment, getMultiCitySegments, function (isMultiCity, simpleRouteSegments, multiCitySegments) {
    var result = [];
    if (isMultiCity) {
        multiCitySegments.forEach(function (segment) {
            if (segment.date || segment.dateBack) {
                result.push(segment.date || null);
                result.push(segment.dateBack || null);
            }
        });
    }
    else {
        var segment = simpleRouteSegments;
        if (segment.date || segment.dateBack) {
            result.push(segment.date || null);
            result.push(segment.dateBack || null);
        }
    }
    return result;
});
