import * as React from 'react';
import * as ReactDOM from 'react-dom';
import AdditionalMobile from '../../../AdditionalMobile/AdditionalMobile';
import { AdditionalTypes } from '../../../../types';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
var MobileHandLuggageExcessModal = function (props) {
    var isOpen = props.isOpen, onClose = props.onClose, services = props.services, selected = props.selected, onToggle = props.onToggle, onSelect = props.onSelect, segments = props.segments;
    var t = useTranslation('BaggageService').t;
    var availableSegments = useMemo(function () {
        return services[0].baggage.segmentsId.map(function (segmentIds) {
            return segments.find(function (segment) { return segmentIds.includes(segment.segmentId); });
        });
    }, [services, segments]);
    return ReactDOM.createPortal(React.createElement(AdditionalMobile, { isOpen: isOpen, onClose: onClose, services: services, header: t('BaggageService:Increasing the size of carry-on baggage'), type: AdditionalTypes.HandLuggageExcess, hasExcessBaggage: false, hasSportEquipment: false, hasHandLuggageExcess: selected, availableSegments: availableSegments, isAvailableForAllSegments: (availableSegments === null || availableSegments === void 0 ? void 0 : availableSegments.length) === segments.length, description: t('BaggageService:You can bring your ski, snowboard, surf equipment, bicycle, golf bag, etc.'), onSelect: onSelect, onToggle: onToggle, selectInputType: 'radio' }), document.body);
};
export default MobileHandLuggageExcessModal;
