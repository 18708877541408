import { __extends, __values } from "tslib";
import { FilterAttrs } from '../types';
import { FlightFilterBase } from './FlightFilterBase';
import * as dateFns from 'date-fns';
import { dateFormat } from '../const';
var backDateFormat = 'yyyy-MM-dd';
var PeriodFilter = /** @class */ (function (_super) {
    __extends(PeriodFilter, _super);
    function PeriodFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PeriodFilter.prototype, "attr", {
        get: function () {
            return FilterAttrs.PERIOD;
        },
        enumerable: false,
        configurable: true
    });
    PeriodFilter.prototype.toString = function (filterValues, convert) {
        var str = undefined;
        if (!!filterValues.BEGIN_PERIOD && !!filterValues.END_PERIOD) {
            str = "".concat(filterValues.BEGIN_PERIOD, " - ").concat(filterValues.END_PERIOD);
        }
        else if (!!filterValues.BEGIN_PERIOD) {
            str = "from ".concat(filterValues.BEGIN_PERIOD);
        }
        else if (!!filterValues.END_PERIOD) {
            str = "to ".concat(filterValues.END_PERIOD);
        }
        if (convert && !!str) {
            str = str.replace(/from/, convert('from'));
            str = str.replace(/to/, convert('to'));
        }
        return str;
    };
    PeriodFilter.prototype.getPreparedFilterValue = function (filterValues) {
        if (!filterValues.BEGIN_PERIOD && !filterValues.END_PERIOD) {
            return undefined;
        }
        var backDate = new Date();
        return {
            beginPeriod: filterValues.BEGIN_PERIOD
                ? dateFns.parse(filterValues.BEGIN_PERIOD, dateFormat, backDate)
                : undefined,
            endPeriod: filterValues.END_PERIOD
                ? dateFns.parse(filterValues.END_PERIOD, dateFormat, backDate)
                : undefined
        };
    };
    PeriodFilter.prototype.check = function (flight, filterValue) {
        var e_1, _a;
        if (!flight || !filterValue) {
            return true;
        }
        var backDate = new Date();
        try {
            for (var _b = __values(flight.segments), _c = _b.next(); !_c.done; _c = _b.next()) {
                var segment = _c.value;
                var segBeginDate = dateFns.parse(segment.scheduleStartDate, backDateFormat, backDate);
                var segEndDate = dateFns.parse(segment.scheduleEndDate, backDateFormat, backDate);
                if ((!filterValue.endPeriod || segBeginDate <= filterValue.endPeriod) &&
                    (!filterValue.beginPeriod || segEndDate >= filterValue.beginPeriod)) {
                    return true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    return PeriodFilter;
}(FlightFilterBase));
export { PeriodFilter };
