import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route, useHistory, useLocation } from 'react-router';
import { getSelectedPassengers, getSelectedPassengersWithChildrenIds, getPassengersWithChildrenIds } from '../../store/passengers/selectors';
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute';
import PassengersPage from '../Pages/Passengers';
import ReasonsPage from '../Pages/Reasons';
import RequestFormPage from '../Pages/Request';
import AddFilesPage from '../Pages/AddFiles';
import StepsButtons from '../StepsButtons/StepsButtons';
import CreateTicket from '../CreateTicket/CreateTicket';
import Ticket from '../Ticket/Ticket';
import { useConfirmRefundOrderMutation, useMobileStepsPositionClassNames, useRefundInfo, useRefuseOrderMutation } from './hooks';
import { getSelectedReason } from '../../store/reason/selectors';
import { createGetCreateRefundParams, isAutoRefundMode } from '../../store/selectors';
import { getActiveStep, getCanGoToNextStep } from '../../store/steps/selectors';
import { nextStep, setStep } from '../../store/steps/actions';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import Wrapper from '../Wrapper/Wrapper';
import { StepType, Unvoluntary } from '../../types';
import WarningModal from '../../../WarningModal';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import GetLastRefundTicket from '../GetLastRefundTicket/GetLastRefundTicket';
import { useIsTerminal, useToggleable } from '../../../hooks';
import RefundInfoModal from '../Modals/RefundInfoModal/RefundInfoModal';
import PromoLoader from '../../../PromoLoader';
import { getIsAutoModeAllowed } from '../../store/aviaOrder/selectors';
import { getIsDocUploadRequired } from '../../store/refundInfo/selectors';
import { useRenderers } from '../../../renderProps';
initI18n('Refund');
var Refund = function (props) {
    var t = useTranslation('Refund').t;
    var _a = useTheme('Refund'), css = _a.Refund, wrapperCss = _a.Wrapper;
    var _b = useToggleable(false), allowAccessToNewlyCreatedRefund = _b.isOpen, allowOpenNewTicket = _b.open;
    var _c = useToggleable(false), successConfirmModalOpen = _c.isOpen, openSuccessConfirmModal = _c.open, closeSuccessConfirmModal = _c.close;
    var selectedPassengers = useSelector(getSelectedPassengers);
    var currentStep = useSelector(getActiveStep);
    var selectedReason = useSelector(getSelectedReason);
    var isAutoMode = useSelector(isAutoRefundMode);
    var selectedPassengersWithChildrenIds = useSelector(getSelectedPassengersWithChildrenIds);
    var passengersWithChildrenIds = useSelector(getPassengersWithChildrenIds);
    var canGoToNextStep = useSelector(getCanGoToNextStep);
    var getRefundParamsSelector = createGetCreateRefundParams(props.orderId);
    var baseUrl = "/booking/".concat(props.orderId, "/refund");
    var location = useLocation();
    var history = useHistory();
    var isAutoModeAllowed = useSelector(getIsAutoModeAllowed);
    var isDocumentUploadRequired = useSelector(getIsDocUploadRequired);
    var _d = __read(useState(null), 2), refundOrder = _d[0], setRefundOrder = _d[1];
    var isTerminal = useIsTerminal();
    var _e = __read(useState(null), 2), requestErrors = _e[0], setRequestErrors = _e[1];
    useEffect(function () {
        if (location.pathname.includes('/refund')) {
            if (isTerminal) {
                dispatch(setStep({ additionalText: '', type: StepType.Reason, isActive: true }));
            }
            else {
                dispatch(setStep({ additionalText: '', type: StepType.Passengers, isActive: true }));
            }
        }
    }, [location.pathname]);
    var createRefundParams = useSelector(getRefundParamsSelector);
    var dispatch = useDispatch();
    var handleReturnToOrder = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, props.onReloadCheckoutOrder()];
                case 1:
                    _a.sent();
                    document.location.hash = "#/booking/".concat(props.orderId, "/passengers");
                    return [2 /*return*/];
            }
        });
    }); }, [document.location]);
    var handleCloseConfirmModal = useCallback(function () {
        closeSuccessConfirmModal();
        if (!isTerminal) {
            handleReturnToOrder();
        }
    }, [closeSuccessConfirmModal]);
    var goToNextStep = useCallback(function () { return dispatch(nextStep()); }, [currentStep]);
    var handleCreateNewRequest = useCallback(function () {
        history.push("".concat(baseUrl, "/new"));
        dispatch(setStep({ additionalText: '', type: StepType.Passengers, isActive: true }));
    }, []);
    var _f = useRefuseOrderMutation({
        refundId: null,
        onSuccessfulRefuse: handleCreateNewRequest
    }), refuseMutation = _f.mutation, refuseLoading = _f.loading;
    var _g = useConfirmRefundOrderMutation({
        refundId: null,
        onSuccessfulConfirm: function (refundOrder) {
            setRefundOrder(refundOrder);
            openSuccessConfirmModal();
        }
    }), confirmMutation = _g.mutation, confirmLoading = _g.loading;
    var setClassesToPassedSteps = useMobileStepsPositionClassNames().setClassesToPassedSteps;
    var handleRefuse = useCallback(function (id) {
        refuseMutation({ variables: { id: id } });
    }, []);
    var handleConfirm = useCallback(function (id) {
        confirmMutation({ variables: { id: id } });
    }, []);
    useEffect(function () { return setClassesToPassedSteps; }, [currentStep.type]);
    var _h = useRefundInfo({
        variables: {
            includePrices: !!(selectedPassengers.length &&
                isAutoModeAllowed &&
                ((isTerminal && currentStep.type !== StepType.Reason) ||
                    (!isTerminal && selectedReason === Unvoluntary.Unvoluntary))),
            isAuto: selectedReason !== Unvoluntary.Unvoluntary,
            orderId: props.orderId,
            passengers: isTerminal ? passengersWithChildrenIds : selectedPassengersWithChildrenIds
        },
        skip: !(currentStep.type === StepType.Reason || (isTerminal && currentStep.type === StepType.CreateTicket))
    }), reasons = _h.reasons, docsToFilesMap = _h.docks, prices = _h.prices, pricesLoading = _h.loading, error = _h.error;
    useEffect(function () {
        var _a;
        if (error && ((_a = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            var errors_1 = [];
            error.graphQLErrors.forEach(function (error) {
                if (error['debugMessage'] === 'There is already an open request for a refund.') {
                    history.push("".concat(baseUrl.replace('new/', ''), "/"));
                }
                else if (error['debugMessage'] === 'This operation is not available') {
                    history.push("".concat(baseUrl.replace('new', ''), "/"));
                }
                else {
                    errors_1.push(error === null || error === void 0 ? void 0 : error['message']);
                }
            });
            if (errors_1.length > 0) {
                setRequestErrors(errors_1);
            }
        }
    }, [error === null || error === void 0 ? void 0 : error.graphQLErrors]);
    var renderAdditionalRoutes = useRenderers().RefundAdditionalRoutes;
    var onWarningModalClose = useCallback(function () {
        setRequestErrors(null);
        history.goBack();
    }, []);
    return (React.createElement(React.Fragment, null, renderAdditionalRoutes === null || renderAdditionalRoutes === void 0 ? void 0 :
        renderAdditionalRoutes({ onClose: handleReturnToOrder }),
        requestErrors && (React.createElement(WarningModal, { title: t('Oops...'), content: t('An unexpected error has occurred during the refund process. Please try again later or contact a customer support service.'), errorMessage: requestErrors, onClose: onWarningModalClose, onButtonClick: onWarningModalClose, buttonText: t('OK, thank you'), isOpen: true })),
        React.createElement(ProtectedRoute, { isAccessible: !!props.refundOrder || allowAccessToNewlyCreatedRefund, path: '/booking/:orderId/refund', exact: true, redirectTo: "".concat(baseUrl, "/new"), render: function () { return (React.createElement(Wrapper, { onClose: handleReturnToOrder }, confirmLoading || refuseLoading ? (React.createElement(PromoLoader, { className: wrapperCss.promoLoader })) : (React.createElement(GetLastRefundTicket, { orderId: props.orderId }, function (lastRefundOrder) {
                allowOpenNewTicket();
                return (React.createElement(React.Fragment, null,
                    React.createElement(Ticket, { isLoading: confirmLoading || refuseLoading, onNewRequest: handleCreateNewRequest, isAutoMode: isAutoMode, refundOrder: refundOrder !== null && refundOrder !== void 0 ? refundOrder : lastRefundOrder, onConfirm: handleConfirm, onReject: handleRefuse }),
                    React.createElement(RefundInfoModal, { onAgree: handleCloseConfirmModal, renderText: function () {
                            return t('Successful refund. Confirmation and detalization have been sent to your e-mail');
                        }, renderAgreeText: function () { return t('Ok'); }, open: successConfirmModalOpen })));
            })))); } }),
        React.createElement(Route, { path: '/booking/:orderId/refund/new', exact: true, render: function () { return (React.createElement(Wrapper, { onClose: handleReturnToOrder, className: css.wrapper },
                React.createElement(PassengersPage, null),
                React.createElement(ReasonsPage, { isLoading: pricesLoading, reasonsGroups: reasons }),
                isDocumentUploadRequired && React.createElement(AddFilesPage, { docsToFilesMap: docsToFilesMap }),
                React.createElement(RequestFormPage, { isLoading: pricesLoading, prices: isAutoModeAllowed &&
                        (isTerminal || selectedReason === Unvoluntary.Unvoluntary) &&
                        prices }),
                currentStep.type !== StepType.CreateTicket && currentStep.type !== StepType.Ticket && (React.createElement(MediaQuery, { maxWidth: 'mobile' },
                    React.createElement(StepsButtons, { className: css.buttons, position: 'center', nextButton: {
                            children: t('Continue'),
                            onClick: goToNextStep,
                            disabled: !canGoToNextStep
                        } }))),
                currentStep.type === StepType.Ticket && createRefundParams && (React.createElement(CreateTicket, { onCreate: function (refundOrder) {
                        setRefundOrder(refundOrder);
                        allowOpenNewTicket();
                        openSuccessConfirmModal();
                        history.push(baseUrl);
                    }, onError: function (errors) {
                        if (isTerminal) {
                            setRequestErrors(errors.map(function (error) { return error.message; }));
                        }
                        else {
                            history.push(baseUrl);
                        }
                    }, params: createRefundParams })))); } })));
};
export default Refund;
