import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useOrder } from '../../../../../__queries__/Order';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import cn from 'classnames';
import { useCallback } from 'react';
import Passengers from '../routes/Passengers';
import Segments from '../routes/Segments';
import { useSelector } from 'react-redux';
import { getOrder, isOpenedExchange } from '../../../store/order/selectors';
import SearchFlights from '../routes/SearchFlights';
import { useTheme } from '../../../../../theme';
import Request from '../routes/Request';
import Selections from './Selections/Selections';
import { useTranslation } from 'react-i18next';
import Payment from '../routes/Payment';
import Reasons from '../routes/Reasons';
import { getNewFlightId } from '../../../store/newFlights/selectors';
import PromoLoader from '../../../../../PromoLoader';
import { ExareStatus } from '../../../../../__generated__/globalTypes';
import { getSelectedPassengerIds } from '../../../store/passengers/selectors';
import { getFlightsToExchange } from '../../../store/selectors';
import WarningModal from '../../../../../WarningModal';
import { CloseClear } from '../../../../../Icons';
import { initI18n } from '../../../../../utils';
import { OverrideComponent, useRenderers } from '../../../../../renderProps';
import { useIsTerminal } from '../../../../../hooks';
import CloseButton from '../../../../../Modal/components/CloseButton/CloseButton';
import PaymentResultMessage from '../PaymentResultMessage/PaymentResultMessage';
export var ExchangeStep;
(function (ExchangeStep) {
    ExchangeStep[ExchangeStep["Passengers"] = 0] = "Passengers";
    ExchangeStep[ExchangeStep["Segments"] = 1] = "Segments";
    ExchangeStep[ExchangeStep["NewFlights"] = 2] = "NewFlights";
    ExchangeStep[ExchangeStep["Request"] = 3] = "Request";
    ExchangeStep[ExchangeStep["Payment"] = 4] = "Payment";
    ExchangeStep[ExchangeStep["Reasons"] = 5] = "Reasons";
})(ExchangeStep || (ExchangeStep = {}));
var PaymentResult;
(function (PaymentResult) {
    PaymentResult["Success"] = "success";
    PaymentResult["Failed"] = "failed";
})(PaymentResult || (PaymentResult = {}));
initI18n('Exchange');
var Inner = function (props) {
    var _a, _b;
    var orderQuery = useOrder(props.orderId, { skip: true, fetchPolicy: 'no-cache' });
    var order = useSelector(getOrder);
    var match = useRouteMatch(), baseUrl = match.url;
    var history = useHistory();
    var location = useLocation();
    var isManualExchange = location.pathname.includes('manual');
    var openedExchange = useSelector(isOpenedExchange);
    var newFlightId = useSelector(getNewFlightId);
    var _c = __read(React.useState(false), 2), isLoading = _c[0], setLoading = _c[1];
    var _d = __read(React.useState(false), 2), isModalClosing = _d[0], setIsModalClosing = _d[1];
    var selectedPassengers = useSelector(getSelectedPassengerIds);
    var selectedFlights = useSelector(getFlightsToExchange);
    var _e = __read(React.useState(null), 2), requestErrors = _e[0], setRequestErrors = _e[1];
    var isTerminal = useIsTerminal();
    var renderAdditionalRoutes = useRenderers().ExchangeAdditionalRoutes;
    var _f = __read(React.useState(null), 2), paymentStatus = _f[0], setPaymentStatus = _f[1];
    var passengersPageURL = baseUrl.replace('exchange', 'passengers');
    var handleReturnToPassengers = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsModalClosing(true);
                    return [4 /*yield*/, (props === null || props === void 0 ? void 0 : props.onRefetchCheckoutOrder())];
                case 1:
                    _a.sent();
                    setIsModalClosing(false);
                    history.push(passengersPageURL);
                    return [2 /*return*/];
            }
        });
    }); };
    var t = useTranslation('Exchange').t;
    React.useEffect(function () {
        if (!order || order.id !== props.orderId) {
            setLoading(true);
            orderQuery.refetch().then(function (data) {
                props.setOrder(data.data.Order);
                setLoading(false);
            });
        }
    }, [order, paymentStatus]);
    var actionsAfterExchangeFinished = function (order) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    props.setOrder(order);
                    props.clearNewFlight();
                    if (!!isTerminal) return [3 /*break*/, 2];
                    return [4 /*yield*/, handleReturnToPassengers()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var refreshOrderAfterPayment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var order, error_1, polling_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    setLoading(true);
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, orderQuery.refetch()];
                case 2:
                    order = _e.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _e.sent();
                    setRequestErrors([error_1]);
                    return [3 /*break*/, 4];
                case 4:
                    if (!(((_d = (_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.data) === null || _a === void 0 ? void 0 : _a.Order) === null || _b === void 0 ? void 0 : _b.exareInfo) === null || _c === void 0 ? void 0 : _c.lastExchange) === null || _d === void 0 ? void 0 : _d.status) === ExareStatus.Finished)) return [3 /*break*/, 6];
                    return [4 /*yield*/, actionsAfterExchangeFinished(order.data.Order)];
                case 5:
                    _e.sent();
                    return [3 /*break*/, 7];
                case 6:
                    polling_1 = function (interval) { return __awaiter(void 0, void 0, void 0, function () {
                        var order;
                        var _a, _b, _c, _d;
                        return __generator(this, function (_e) {
                            switch (_e.label) {
                                case 0: return [4 /*yield*/, orderQuery.refetch()];
                                case 1:
                                    order = _e.sent();
                                    if (!(((_d = (_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.data) === null || _a === void 0 ? void 0 : _a.Order) === null || _b === void 0 ? void 0 : _b.exareInfo) === null || _c === void 0 ? void 0 : _c.lastExchange) === null || _d === void 0 ? void 0 : _d.status) === ExareStatus.Finished)) return [3 /*break*/, 3];
                                    return [4 /*yield*/, actionsAfterExchangeFinished(order.data.Order)];
                                case 2:
                                    _e.sent();
                                    return [3 /*break*/, 4];
                                case 3:
                                    setTimeout(function () { return polling_1(interval * 1.5); }, interval * 1.5);
                                    _e.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); };
                    setTimeout(function () { return polling_1(500); }, 500);
                    _e.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        if (paymentStatus === PaymentResult.Success) {
            refreshOrderAfterPayment();
        }
    }, [paymentStatus]);
    var setStep = function (newStep) {
        if (newStep === ExchangeStep.Reasons) {
            history.push("".concat(baseUrl, "/reasons"));
        }
        else if (newStep === ExchangeStep.Segments) {
            history.push("".concat(baseUrl, "/segments"));
        }
        else if (newStep === ExchangeStep.NewFlights) {
            history.push("".concat(baseUrl, "/flights"));
        }
        else if (newStep === ExchangeStep.Request) {
            history.push("".concat(baseUrl, "/request"));
        }
        else if (newStep === ExchangeStep.Passengers) {
            history.push("".concat(baseUrl, "/passengers"));
        }
        else if (newStep === ExchangeStep.Payment) {
            history.push("".concat(baseUrl, "/payment"));
        }
    };
    var onWarningModalClose = useCallback(function () {
        setRequestErrors(null);
    }, []);
    var theme = useTheme('Exchange').Inner;
    var FullScreenModalTheme = useTheme('FullScreenModal').FullScreenModal;
    var successPayment = function () { return setPaymentStatus(PaymentResult.Success); };
    var failedPayment = function () { return setPaymentStatus(PaymentResult.Failed); };
    var setExchangeSuccess = function () {
        history.push("".concat(baseUrl, "/payment/success"));
    };
    var setExchangeFailed = function () {
        history.push("".concat(baseUrl, "/payment/failed"));
    };
    return (React.createElement("div", { className: theme.exchange },
        React.createElement("div", { className: isTerminal ? FullScreenModalTheme.header : theme.header },
            isTerminal ? (React.createElement(CloseButton, { className: theme.closeButton, isGray: true, onClick: handleReturnToPassengers, isLoading: isModalClosing })) : (React.createElement("span", { onClick: handleReturnToPassengers, className: theme.closeIcon }, CloseClear)),
            React.createElement("div", { className: theme.header__inner },
                React.createElement("div", { className: cn(theme.header__text, (_a = {}, _a[FullScreenModalTheme.header__title] = isTerminal, _a)) }, t('Change ticket')),
                React.createElement(OverrideComponent, { componentProps: { setStep: setStep }, component: { ExchangeSelections: Selections } }))),
        React.createElement(OverrideComponent, { componentProps: {
                paymentStatus: paymentStatus,
                setPaymentStatus: setPaymentStatus
            }, component: { PaymentResultMessage: PaymentResultMessage } }),
        isLoading && React.createElement(PromoLoader, null),
        requestErrors && (React.createElement(WarningModal, { title: t('Oops...'), isOpen: true, content: t('An unexpected error has occurred. Please try again later or contact a customer support service.'), errorMessage: requestErrors, onClose: onWarningModalClose, onButtonClick: onWarningModalClose, buttonText: t('OK, thank you') })),
        order && (React.createElement("div", { hidden: isLoading, className: cn(theme.container, (_b = {}, _b[theme.manual] = isManualExchange, _b)) },
            React.createElement(Switch, null, renderAdditionalRoutes === null || renderAdditionalRoutes === void 0 ? void 0 :
                renderAdditionalRoutes(),
                React.createElement(Route, { path: "".concat(baseUrl, "/passengers"), render: function () {
                        return !openedExchange ? (React.createElement(Passengers, { setStep: setStep })) : (React.createElement(Redirect, { to: "".concat(baseUrl, "/request") }));
                    } }),
                React.createElement(Route, { path: "".concat(baseUrl, "/reasons"), render: function () {
                        return selectedPassengers.length ? (React.createElement(Reasons, { setStep: setStep })) : (React.createElement(Redirect, { to: "".concat(baseUrl, "/passengers") }));
                    } }),
                React.createElement(Route, { path: "".concat(baseUrl, "/segments"), render: function () {
                        return selectedPassengers.length ? (React.createElement(Segments, { setStep: setStep })) : (React.createElement(Redirect, { to: "".concat(baseUrl, "/passengers") }));
                    } }),
                React.createElement(Route, { path: "".concat(baseUrl, "/flights"), render: function () {
                        return selectedPassengers.length && selectedFlights.flightsByIds.length ? (React.createElement(SearchFlights, { setStep: setStep, setLoading: setLoading, onError: setRequestErrors })) : (React.createElement(Redirect, { to: "".concat(baseUrl, "/passengers") }));
                    } }),
                React.createElement(Route, { path: "".concat(baseUrl, "/request"), render: function () {
                        var _a;
                        return ((_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange) || newFlightId ? (React.createElement(Request, { setStep: setStep, clearSelectedFares: props.clearNewFlight, setExchangeSuccess: setExchangeSuccess, setExchangeFail: setExchangeFailed, refreshOrder: refreshOrderAfterPayment })) : (React.createElement(Redirect, { to: "".concat(baseUrl, "/passengers") }));
                    } }),
                React.createElement(Route, { exact: true, path: "".concat(baseUrl, "/payment"), render: function () { return (React.createElement(Payment, { setStep: setStep, successPayment: successPayment, failedPayment: failedPayment, setLoading: setLoading })); } }),
                React.createElement(Route, { path: "".concat(baseUrl, "/payment/success"), render: function () {
                        setPaymentStatus(PaymentResult.Success);
                        return React.createElement(Redirect, { to: "".concat(baseUrl, "/request") });
                    } }),
                React.createElement(Route, { path: "".concat(baseUrl, "/payment/failed"), render: function () {
                        setPaymentStatus(PaymentResult.Failed);
                        return React.createElement(Redirect, { to: "".concat(baseUrl, "/request") });
                    } }),
                React.createElement(Route, { render: function () { return React.createElement(Redirect, { to: "".concat(baseUrl, "/request") }); } }))))));
};
export default Inner;
