import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
var getHeader = function (theme) {
    var t = useTranslation('Datepicker').t;
    var headers = [];
    for (var i = 0; i < 7; i++) {
        headers.push(React.createElement("div", { key: i, className: "".concat(theme.day, " ").concat(theme.dayOfWeekName) },
            React.createElement("span", null, t("week_short_".concat(i)))));
    }
    return headers;
};
export var WeeklyHeader = function () {
    var theme = useTheme('Datepicker').WeeklyHeaderStyles;
    return React.createElement("div", null, getHeader(theme));
};
