import { useCallback } from 'react';
import { format } from '../../../../../utils';
import { addMonths } from 'date-fns';
export var useFlightsListSchedule = function (setSelectedDate, selectedDate) {
    var currentMonthName = format(selectedDate, 'LLLL yyyy');
    var goFurther = useCallback(function () {
        var nextMonth = addMonths(selectedDate, 1);
        setSelectedDate(new Date(nextMonth));
    }, [selectedDate]);
    var goBack = useCallback(function () {
        var nextMonth = addMonths(selectedDate, -1);
        setSelectedDate(new Date(nextMonth));
    }, [selectedDate]);
    return {
        goFurther: goFurther,
        goBack: goBack,
        currentMonthName: currentMonthName
    };
};
