import { __assign, __rest } from "tslib";
import React from 'react';
import cn from 'classnames';
import { Switch as SwitchComponent } from '../../Passenger/components/Field/Switch/Switch';
import * as css from './Switch.css';
export var Switch = function (_a) {
    var _b;
    var success = _a.success, warning = _a.warning, props = __rest(_a, ["success", "warning"]);
    return (React.createElement(SwitchComponent, __assign({ labelClassName: cn((_b = {}, _b[css.success] = success, _b[css.warning] = warning, _b)), activeLabelClassName: css.active }, props)));
};
