import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { useServicePageProps } from '../hooks';
import { useUpsaleWarning } from '../../../Checkout/components/Checkout/Upsale/hooks';
import { ServiceType } from '../../../Checkout/components/Checkout/OrderPaidServiceCards/types';
import ServicePage from '../../../Checkout/components/Checkout/ServicePage/ServicePage';
import ServiceCardDialog from '../../../Checkout/components/Checkout/OrderPaidServiceCards/components/ServiceCardDialog/ServiceCardDialog';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import { useSelector } from 'react-redux';
import { getSeatsMinPrice, getSegments, getServicesFallbackURL, isAllSegmentsAvailableOnlyOnCheckin, shouldRedirectToFallback } from '../../../Checkout/store/order/selectors';
import { useTranslation } from 'react-i18next';
import { getSeatsPrice } from '../../../Checkout/store/orderPrice/selectors';
import { useTheme } from '../../../theme';
import icons from '../../icons';
import { getPassengerStateCompatible } from '../../../Checkout/store/selectedServices/selectors';
var SeatsAdditionalService = function (_a) {
    var isCheckinAvailable = _a.isCheckinAvailable;
    var t = useTranslation('Checkout').t;
    var minPrice = useSelector(getSeatsMinPrice);
    var seatsIssuedPrice = useSelector(getSeatsPrice);
    var servicePageProps = useServicePageProps();
    var shouldRedirect = useSelector(shouldRedirectToFallback);
    var servicesRedirectURL = useSelector(getServicesFallbackURL);
    var isAllSegmentsAvailableOnCheckin = useSelector(isAllSegmentsAvailableOnlyOnCheckin);
    var _b = useUpsaleWarning(), showWarning = _b.showWarning, closeWarning = _b.closeWarning, toggleWarningIfOrderConfirmed = _b.toggleWarningIfOrderConfirmed;
    var handleClick = shouldRedirect
        ? toggleWarningIfOrderConfirmed
        : servicePageProps.toggleModal.bind(null, ServiceType.Seats);
    var segments = useSelector(getSegments);
    var passengers = useSelector(getPassengerStateCompatible);
    var _c = useTheme('CheckoutAdditionalService'), css = _c.Seats, commonStyles = _c.CheckoutAdditionalService;
    var isSelected = passengers.some(function (passenger) { var _a; return ((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.length) > 0; });
    var servicesBySegments = new Map();
    segments.forEach(function (segment) {
        passengers.forEach(function (passenger) {
            var _a;
            (_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.forEach(function (seatService) {
                if (seatService.segmentId === segment.id) {
                    if (servicesBySegments.has(segment)) {
                        servicesBySegments.set(segment, servicesBySegments.get(segment).concat(seatService));
                    }
                    else {
                        servicesBySegments.set(segment, [seatService]);
                    }
                }
            });
        });
    });
    var allSeatsConfirmed = passengers.every(function (passenger) { return passenger.seats.every(function (seat) { return seat.isConfirmed; }); });
    var buttons = [
        {
            text: t(allSeatsConfirmed ? 'More details' : 'Change'),
            action: servicePageProps.toggleModal.bind(null, ServiceType.Seats)
        }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckoutAdditionalService, { className: css.seats, showAddButton: !isAllSegmentsAvailableOnCheckin, icon: icons.seatIcon, addClick: showWarning ? toggleWarningIfOrderConfirmed : handleClick, header: t('Seats'), description: isAllSegmentsAvailableOnCheckin
                ? isCheckinAvailable
                    ? t('Seat selection is available in the online registration service')
                    : t('You can select seats using the online registration service after issuing tickets')
                : t('The top ones get the top seats!'), priceTotal: seatsIssuedPrice.amount ? seatsIssuedPrice : null, buttons: buttons, priceFrom: !isAllSegmentsAvailableOnCheckin ? minPrice : null, isSelected: isSelected },
            React.createElement("ul", { className: commonStyles.list }, __spreadArray([], __read(servicesBySegments.keys()), false).map(function (segment) { return (React.createElement("li", { className: commonStyles.list__item, key: segment.id },
                React.createElement("span", { className: commonStyles.list__cities },
                    segment.departure.airport.city.name,
                    " - ",
                    segment.arrival.airport.city.name),
                React.createElement("span", { className: commonStyles.list__services }, __spreadArray([], __read(servicesBySegments.get(segment)), false).map(function (seatItem, index) { return (React.createElement(React.Fragment, null,
                    seatItem.row,
                    seatItem.letter,
                    index + 1 !== servicesBySegments.get(segment).length && React.createElement(React.Fragment, null, ","),
                    "\u00A0")); })))); }))),
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        React.createElement(ServicePage, null, function (childrenProps) { return (React.createElement(ServiceCardDialog, __assign({ order: servicePageProps.order, openedModal: servicePageProps.openedModal, onClose: servicePageProps.toggleModal.bind(null, null), passengers: servicePageProps.passengers, passengerLabels: servicePageProps.passengerLabels }, childrenProps))); })));
};
export default SeatsAdditionalService;
