import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getNewFlightId, getSelectedFares } from '../../Modules/Exchange/store/newFlights/selectors';
import PromoLoader from '../../PromoLoader';
import SelectedFlights from './SelectedFlights/SelectedFlights';
import * as priceDetailsTheme from '../PricesDetails/PriceDetails.css';
import DetalizationLine from '../PricesDetails/DetalizationLine/DetalizationLine';
import PricesDetails from '../PricesDetails/PricesDetails';
import Button from '../../Button';
import { useTheme } from '../../theme';
import { useGetExchangePriceQuery } from '@websky/graphql';
import { getSelectedPassengerIds } from '../../Modules/Exchange/store/passengers/selectors';
import { getOrderId } from '../../Checkout/store/order/selectors';
import FlightSelect from '../../FlightSelect/components/FlightSelect';
import { useCalculateGdsServiceRefund } from '../../Modules/Exchange/components/Exchange/hooks';
var CreateRequest = function (props) {
    var t = useTranslation('Exchange').t;
    var css = useTheme('CreateRequest').CreateRequest;
    var orderId = useSelector(getOrderId);
    var flightId = useSelector(getNewFlightId);
    var selectedFares = useSelector(getSelectedFares);
    var passengerIds = useSelector(getSelectedPassengerIds);
    var exchangePriceParams = useMemo(function () {
        return {
            orderId: orderId,
            flightId: flightId,
            passengers: passengerIds.map(function (id) { return +id; })
        };
    }, [orderId, flightId, passengerIds]);
    var isZeroPrice = selectedFares.some(function (fare) { return fare.exarePrice.priceToPay.amount === 0; });
    var _a = useGetExchangePriceQuery({
        variables: { params: exchangePriceParams },
        skip: !isZeroPrice
    }), data = _a.data, loading = _a.loading;
    var _b = useCalculateGdsServiceRefund(passengerIds), gdsServicesRefund = _b.data, isGdsServicesRefund = _b.loading;
    var prices = React.useMemo(function () {
        if (selectedFares.length) {
            return {
                priceToPay: selectedFares[0].exarePrice.priceToPay,
                charges: {
                    amount: selectedFares[0].exarePrice.agencyChargesForExare.amount +
                        selectedFares[0].exarePrice.exchangeAirlinePenalty.amount,
                    currency: selectedFares[0].exarePrice.priceToPay.currency
                }
            };
        }
        return null;
    }, [selectedFares]);
    var renderSelectedFlights = useCallback(function () {
        if (data === null || data === void 0 ? void 0 : data.ExchangePrice) {
            return data.ExchangePrice.flightDirections.map(function (direction) {
                return direction.legs.map(function (flight, index) { return (React.createElement(FlightSelect, { key: index, flight: flight, orderId: orderId, flightId: flightId, selectedFares: [], isSelected: true })); });
            });
        }
        return null;
    }, [data === null || data === void 0 ? void 0 : data.ExchangePrice]);
    var onSendHandler = function () {
        props.send({}, true);
    };
    React.useEffect(function () {
        if (!isZeroPrice) {
            onSendHandler();
        }
    }, [isZeroPrice]);
    if (props.isSending || !isZeroPrice || loading) {
        return React.createElement(PromoLoader, null);
    }
    return (React.createElement("div", { className: css.createRequest },
        React.createElement(SelectedFlights, { variant: 'newTicket', selectedFlights: [], prices: prices, hideHeader: true, autoConfirmBackTo: true, type: 'flat', renderSelectedFlights: renderSelectedFlights, showRefundServicesInfo: true, selectedPassengers: passengerIds, goToFlights: props.goToNewRequest, gdsServicesRefund: gdsServicesRefund, isLoading: isGdsServicesRefund }),
        React.createElement(PricesDetails, { className: priceDetailsTheme.whiteBg, prices: data.ExchangePrice.exchangeVariantPriceInfo[0], renderAdditionalFields: function () { return (React.createElement(DetalizationLine, { total: true, className: priceDetailsTheme.item_first, price: data.ExchangePrice.exchangeVariantPriceInfo[0].priceToPay, title: t('Residual amount') })); } }),
        React.createElement("div", { className: css.footer },
            React.createElement(Button, { className: css.button, size: "large", onClick: onSendHandler }, t('Create exchange')))));
};
export default CreateRequest;
