import { __read } from "tslib";
import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { MenuItem, Select } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import Button from '../../../Button';
import { useSelector } from 'react-redux';
import { getTravellers } from '../../../Checkout/store/order/selectors';
import { OrderDocumentType } from '@websky/graphql';
var DownloadDocumentsPopup = function (_a) {
    var className = _a.className, passengers = _a.passengers, onDownloadDocuments = _a.onDownloadDocuments, isDocumentsDownloading = _a.isDocumentsDownloading;
    var css = useTheme('DownloadDocuments').DownloadDocumentsPopup;
    var t = useTranslation('OrderCard').t;
    var _b = __read(useState(null), 2), selectedPassengerId = _b[0], setSelectedPassengerId = _b[1];
    var travellers = useSelector(getTravellers);
    var isCanDownloadEmd = useMemo(function () {
        if (!selectedPassengerId) {
            return travellers.some(function (traveller) { return traveller.emds.length; });
        }
        var travellerEmds = travellers.find(function (traveller) { return traveller.id === selectedPassengerId; }).emds;
        return travellerEmds.length > 0;
    }, [travellers, selectedPassengerId]);
    var documents = isCanDownloadEmd
        ? [
            { label: t('All'), value: OrderDocumentType.All },
            { label: t('Itinerary Receipt'), value: OrderDocumentType.ItineraryReceipt },
            { label: t('EMD'), value: OrderDocumentType.EMD }
        ]
        : [{ label: t('Itinerary Receipt'), value: OrderDocumentType.ItineraryReceipt }];
    var isOnlyPassenger = passengers.length === 1;
    var isOnlyDocument = documents.length === 1;
    var initialPassengers = 'All';
    if (selectedPassengerId) {
        initialPassengers = selectedPassengerId;
    }
    else if (isOnlyPassenger) {
        initialPassengers = passengers[0].id;
    }
    var initialValues = {
        passengers: initialPassengers,
        documents: isOnlyDocument ? documents[0].value : 'All'
    };
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.title }, t('Load documents')),
        React.createElement(Form, { onSubmit: onDownloadDocuments, initialValues: initialValues, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: css.content },
                        React.createElement(Field, { name: "passengers", render: function (_a) {
                                var input = _a.input;
                                return (React.createElement(Select, { className: css.select, classes: {
                                        root: css.select_root,
                                        outlined: css.select_outlined
                                    }, variant: "outlined", value: input.value, disabled: isOnlyPassenger, onChange: function (e) {
                                        if (e.target.value === OrderDocumentType.All) {
                                            setSelectedPassengerId(null);
                                        }
                                        else {
                                            setSelectedPassengerId(e.target.value);
                                        }
                                        input.onChange(e);
                                    } },
                                    isOnlyPassenger ? (React.createElement(MenuItem, { classes: { root: css.option, selected: css.option_selected }, value: passengers[0].id }, passengers[0].name)) : (React.createElement(MenuItem, { classes: { root: css.option, selected: css.option_selected }, value: "All" }, t('All passengers'))),
                                    !isOnlyPassenger &&
                                        passengers.map(function (passenger) { return (React.createElement(MenuItem, { key: passenger.id, classes: { root: css.option, selected: css.option_selected }, value: passenger.id }, passenger.name)); })));
                            } }),
                        React.createElement(Field, { name: "documents", render: function (_a) {
                                var input = _a.input;
                                return (React.createElement(Select, { className: css.select, classes: {
                                        root: css.select_root,
                                        outlined: css.select_outlined
                                    }, variant: "outlined", value: input.value, onChange: input.onChange, disabled: isOnlyDocument }, isOnlyDocument ? (React.createElement(MenuItem, { classes: { root: css.option, selected: css.option_selected }, value: documents[0].value }, documents[0].label)) : (documents.map(function (document) { return (React.createElement(MenuItem, { key: document.value, classes: { root: css.option, selected: css.option_selected }, value: document.value }, document.label)); }))));
                            } })),
                    React.createElement("div", { className: css.footer },
                        React.createElement(Button, { className: css.button, type: "submit", isLoading: isDocumentsDownloading }, t('Load')))));
            } })));
};
export default DownloadDocumentsPopup;
