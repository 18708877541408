import React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
var PromoMessage = function (_a) {
    var className = _a.className;
    var css = useTheme('AdditionalBaggage').PromoMessage;
    var t = useTranslation('AdditionalBaggage').t;
    return React.createElement("div", { className: cn(css.root, className) }, t('Buy now and save 20-30%'));
};
export default PromoMessage;
