import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';
import { useSeatAvailability } from '../../hooks';
import SimpleLoader from '../../../../SimpleLoader';
import ServiceWithSegments from '../../../../ServiceWithSegments/ServiceWithSegments';
import FlightsList from '../FlightsList/FlightsList';
import { API_DATE_FORMAT, format } from '../../../../utils';
import { searchParametersToSegmentsAdapter } from '../../../../SegmentTabs/utils';
import FullScreenModal from '../../../FullScreenModal/FullScreenModal';
var SeatAvailabilityModal = function (_a) {
    var className = _a.className, isOpen = _a.isOpen, searchParameters = _a.searchParameters, setLoading = _a.setLoading, onClose = _a.onClose;
    var t = useTranslation('SeatAvailability').t;
    var rt = useTranslation().t;
    var _b = __read(useState(searchParameters.segments.map(function (segment) { return segment.date; })), 2), selectedDates = _b[0], setSelectedDates = _b[1];
    var _c = useSeatAvailability(searchParameters, selectedDates, isOpen), seatAvailability = _c.seatAvailability, loading = _c.loading, fetchErr = _c.error;
    useEffect(function () {
        setLoading === null || setLoading === void 0 ? void 0 : setLoading(loading);
    }, [loading]);
    var onChangeDate = function (leg) { return function (newDate) {
        var formatDate = format(newDate, API_DATE_FORMAT);
        setSelectedDates(function (state) { return state.map(function (date, index) { return (index === leg ? formatDate : date); }); });
    }; };
    var error = undefined;
    if (fetchErr) {
        error = {
            name: rt('It looks like something went wrong'),
            message: fetchErr.message
        };
    }
    return (React.createElement(React.Fragment, null,
        loading && React.createElement(SimpleLoader, null),
        React.createElement(FullScreenModal, { open: isOpen, onClose: onClose, title: t('Availability'), headerType: 'collapsed' },
            React.createElement(ServiceWithSegments, { title: null, sticky: true, segments: searchParametersToSegmentsAdapter(searchParameters.segments), onMobileBack: function () { return null; }, onMobileProceed: function () { return null; } }, function (_a) {
                var activeSegmentId = _a.activeSegmentId;
                var flights = (seatAvailability === null || seatAvailability === void 0 ? void 0 : seatAvailability[activeSegmentId].legs) || [];
                var initialDate = parse(selectedDates[activeSegmentId], API_DATE_FORMAT, new Date());
                return (React.createElement(FlightsList, { flights: flights, date: initialDate, selectedDates: selectedDates, segment: searchParameters.segments[activeSegmentId], onChangeDate: onChangeDate(+activeSegmentId), error: error }));
            }))));
};
export default SeatAvailabilityModal;
