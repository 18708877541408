import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Sorting } from '../../Results/enums';
import { Land, Paid, Takeoff, Watch } from '../../Icons';
import { useTheme } from '../../theme';
import { useEventListener } from '../../hooks';
var SORTING_TYPE_ICON_MAP = new Map([
    [Sorting.Price, Paid],
    [Sorting.FlightTime, Watch],
    [Sorting.Departure, Takeoff],
    [Sorting.Arrival, Land]
]);
var KEY_SORTING_TYPE_MAP = new Map([
    ['KeyC', Sorting.Price],
    ['KeyQ', Sorting.FlightTime],
    ['KeyD', Sorting.Departure],
    ['KeyA', Sorting.Arrival]
]);
var FlightsSortings = function (props) {
    var onSelect = props.onSelect, currentSort = props.currentSort;
    var css = useTheme('FlightsSortings').FlightsSortings;
    var t = useTranslation('Sortings').t;
    var changeSortHandler = function (event) {
        var keyboardEvent = event;
        if (document.activeElement.tagName === 'INPUT') {
            return;
        }
        if (keyboardEvent.shiftKey && KEY_SORTING_TYPE_MAP.has(keyboardEvent.code)) {
            onSelect(KEY_SORTING_TYPE_MAP.get(keyboardEvent.code));
        }
        keyboardEvent.stopPropagation();
    };
    useEventListener('keydown', changeSortHandler);
    return (React.createElement("div", { className: css.sortings },
        t('Sort'),
        "\u00A0",
        Array.from(SORTING_TYPE_ICON_MAP).map(function (_a) {
            var _b;
            var _c = __read(_a, 2), sortingType = _c[0], icon = _c[1];
            return (React.createElement("span", { key: sortingType, onClick: function () { return onSelect(sortingType); }, className: cn(css.sorting, (_b = {}, _b[css.active] = currentSort === sortingType, _b)) }, icon));
        })));
};
export default FlightsSortings;
