import { __assign, __read } from "tslib";
import * as React from 'react';
import { useState, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import { useTheme } from '../../theme';
import { FieldGroupType } from '../types';
import { Field } from './Field/Field';
import { TravellerFieldEnum } from '@websky/graphql';
import { initI18n } from '../../utils';
import Header from './Header/Header';
import MediaQuery from '../../MediaQuery/MediaQuery';
import Link from '../../Link';
import { useConfig } from '../../context';
import { hideText } from '../utils';
initI18n('Passenger');
var Passenger = function (props) {
    var _a, _b;
    var groups = (_a = {},
        _a[FieldGroupType.Identity] = [],
        _a[FieldGroupType.Loyalty] = [],
        _a[FieldGroupType.Citizenship] = [],
        _a[FieldGroupType.Contacts] = [],
        _a[FieldGroupType.Other] = [],
        _a);
    var _c = __read(useState(true), 2), isOpen = _c[0], setOpen = _c[1];
    var t = useTranslation('Passenger').t;
    var companyInfo = useConfig().global.companyInfo;
    var theme = useTheme('Passenger').PassengerStyles;
    // passanger card or account card
    var hasLoyaltyCard = useMemo(function () {
        var _a;
        var loyaltyFieldValue = (_a = props.fields.find(function (field) { return field.type === TravellerFieldEnum.LoyaltyNumber; })) === null || _a === void 0 ? void 0 : _a.defaultValue;
        return loyaltyFieldValue && loyaltyFieldValue.length > 0;
    }, [props.fields]);
    if (!props.isReadonly) {
        props.fields.forEach(function (originalField) {
            var _a;
            var fieldName = "passengers[".concat(props.id, "].").concat(originalField.name);
            var field = __assign(__assign({}, originalField), { name: fieldName, placeholder: t(originalField.placeholder), defaultValue: ((_a = props.inputsMask) === null || _a === void 0 ? void 0 : _a.includes(originalField.type))
                    ? hideText(originalField.defaultValue, 2.1)
                    : originalField.defaultValue });
            switch (field.type) {
                case TravellerFieldEnum.LastName:
                case TravellerFieldEnum.FirstName:
                case TravellerFieldEnum.MiddleName:
                case TravellerFieldEnum.BirthDate:
                case TravellerFieldEnum.Gender:
                    groups[FieldGroupType.Identity].push(field);
                    break;
                case TravellerFieldEnum.Nationality:
                case TravellerFieldEnum.DocType:
                case TravellerFieldEnum.DocNumber:
                case TravellerFieldEnum.DocExpiryDate:
                    groups[FieldGroupType.Citizenship].push(field);
                    break;
                case TravellerFieldEnum.LoyaltyNumber:
                case TravellerFieldEnum.LoyaltyAirline:
                    groups[FieldGroupType.Loyalty].push(field);
                    break;
                case TravellerFieldEnum.Phone:
                case TravellerFieldEnum.Email:
                    groups[FieldGroupType.Contacts].push(field);
                    break;
                default:
                    groups[FieldGroupType.Other].push(field);
            }
        });
    }
    var fieldGroups = [
        { type: FieldGroupType.Identity, fields: groups[FieldGroupType.Identity] },
        { type: FieldGroupType.Citizenship, fields: groups[FieldGroupType.Citizenship] },
        { type: FieldGroupType.Loyalty, fields: groups[FieldGroupType.Loyalty] },
        { type: FieldGroupType.Contacts, fields: groups[FieldGroupType.Contacts] },
        { type: FieldGroupType.Other, fields: groups[FieldGroupType.Other] }
    ];
    var renderFieldGroup = function (group) {
        var _a;
        return (!!group.fields.length && (React.createElement("div", { key: group.type, className: cn(theme.form, (_a = {},
                _a[theme.form_identy] = group.type === FieldGroupType.Identity,
                _a[theme.form_loyalty] = group.type === FieldGroupType.Loyalty,
                _a[theme.form_citizenship] = group.type === FieldGroupType.Citizenship,
                _a[theme.form_contacts] = group.type === FieldGroupType.Contacts,
                _a)) },
            React.createElement(MediaQuery, { maxWidth: "mobile" },
                React.createElement("span", { className: theme.group__title }, t(group.type))),
            group.fields.map(function (field, index) {
                var _a;
                return (React.createElement(Field, { disabled: props.isInputsReadonly || field.readonly, key: "".concat(field.name, "_").concat(index), field: field, className: cn(theme.form__field, (_a = {},
                        _a[theme.form__field_gender] = field.type === TravellerFieldEnum.Gender,
                        _a[theme.form__field_birthday] = field.type === TravellerFieldEnum.BirthDate,
                        _a)), passengerId: props.id, onTravellerSelect: props.onFill, passengerType: props.passengerType, loadTravellers: !props.isReadonly && props.isAuthorized, selectPassengerId: props.selectPassengerId }));
            }),
            group.type === FieldGroupType.Loyalty && !props.isInputsReadonly && !hasLoyaltyCard && (React.createElement("div", { className: cn(theme.form__field, theme.form__field_loyaltyCardHint) },
                React.createElement(Link, { target: "_blank", action: companyInfo.loyaltyProgramRegistrationUrl }, t("Don't have a card yet? Register now.")))))));
    };
    return (React.createElement("div", { className: cn(theme.passenger, (_b = {},
            _b[theme.short] = !isOpen,
            _b[theme.passenger_readonly] = props.isReadonly,
            _b[theme.withoutServices] = props.isReadonly && !props.services,
            _b)) },
        React.createElement(Header, { passengerSupplierType: props.passengerSupplierType, setOpen: setOpen, isOpen: isOpen, isReadonly: props.isReadonly, passengerId: props.id, fields: props.fields, passengerType: props.passengerType, hasServices: !!props.services, servicesTotalPrice: props.servicesTotalPrice, label: props.label }),
        props.services ? (React.createElement(Collapse, { in: isOpen, mountOnEnter: true },
            React.createElement("div", { className: theme.form__container },
                !props.isReadonly && fieldGroups.map(renderFieldGroup),
                props.services))) : (!props.isReadonly && (React.createElement(Collapse, { in: isOpen, mountOnEnter: true },
            React.createElement("div", { className: theme.form__container }, fieldGroups.map(renderFieldGroup)))))));
};
export default Passenger;
