import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme } from '../../../theme';
import { usePassengerNames } from './hooks';
var Passenger = function (props) {
    var _a, _b;
    var _c;
    var css = useTheme('Refund').Passenger;
    var _d = (_c = props === null || props === void 0 ? void 0 : props.checkboxProps) !== null && _c !== void 0 ? _c : {}, checked = _d.checked, restCheckboxProps = __rest(_d, ["checked"]);
    var passenger = props.passenger;
    var getPassengerName = usePassengerNames().getPassengerName;
    var handleSelect = useCallback(function (e) {
        e.stopPropagation();
        e.preventDefault();
        props.onPassengerSelect(props.passenger);
    }, [props.checked, props.passenger]);
    var passengerName = useMemo(function () {
        var _a;
        if ((_a = passenger.children) === null || _a === void 0 ? void 0 : _a.length) {
            return getPassengerName(passenger);
        }
        return getPassengerName(passenger);
    }, [props.passenger]);
    return (React.createElement(Card, __assign({}, props.cardProps, { elevation: 0 }),
        React.createElement(CardActionArea, { onClick: handleSelect, className: cn(css.passenger, (_a = {}, _a[css.active] = props.checked, _a)), classes: { focusHighlight: cn(css.passenger, css.active) } },
            React.createElement(FormControlLabel, { onClick: handleSelect, className: cn(css.label, (_b = {}, _b[css.active] = props.checked, _b)), control: React.createElement(Checkbox, __assign({ size: 'small', color: 'primary' }, restCheckboxProps, { checked: props.checked, classes: { root: css.checkbox, checked: cn(css.checkbox, css.active) } })), label: passengerName }))));
};
export default Passenger;
