import { __values } from "tslib";
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCheckinOrder, getCheckinSegments, getDeprecatedPassengersState, makeGetGdsServicesByType } from '../../../Modules/Checkin/store/order/selectors';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../__generated__/globalTypes';
import { checkoutSelectedServicesStateFactory } from '../../../Checkout/utils';
import { addService, removeService, saveServices } from '../../../Modules/Checkin/store/selectedServices/actions';
import { getSelectedServices } from '../../../Modules/Checkin/store/selectedServices/selectors';
import { getIsServicesSaving } from '../../../Modules/Checkin/store/servicesLoading/selectors';
import { useTranslation } from 'react-i18next';
import { getPassengerPricesMap } from '../../../CheckoutAdditionalService/utils';
import { PASSENGERS_TYPES } from '../../../CheckoutAdditionalService/components/hooks';
import { Money } from '../../../Money';
export var CheckinBusinessLoungeAdapter = function (_a) {
    var _b;
    var children = _a.children, onClose = _a.onClose;
    var t = useTranslation('Checkin').t;
    var passengers = useSelector(getDeprecatedPassengersState);
    var businessLoungeSelector = makeGetGdsServicesByType(OrderAdditionalServiceGdsServiceServiceType.BusinessLounge);
    var businessLoungeServices = useSelector(businessLoungeSelector);
    var checkinSelectedServices = useSelector(getSelectedServices);
    var segments = useSelector(getCheckinSegments);
    var order = useSelector(getCheckinOrder);
    var isLoading = useSelector(getIsServicesSaving);
    var dispatch = useDispatch();
    var minPrice = null;
    var totalPrice = (_b = order === null || order === void 0 ? void 0 : order.price) === null || _b === void 0 ? void 0 : _b.businessLounge;
    minPrice = businessLoungeServices
        .filter(function (service) { return service.price.amount !== 0; })
        .reduce(function (minPrice, current) {
        if (!minPrice) {
            return current.price;
        }
        if (minPrice.amount > current.price.amount) {
            return current.price;
        }
        else {
            return minPrice;
        }
    }, minPrice);
    var onSave = function (selectedServices) {
        var e_1, _a;
        var _b, _c;
        var _loop_1 = function (selectedVipService) {
            var relatedCheckinOrderService = businessLoungeServices.find(function (businessLoungeService) { return businessLoungeService.id === selectedVipService.serviceId; });
            var isSelected = !!((_c = (_b = checkinSelectedServices === null || checkinSelectedServices === void 0 ? void 0 : checkinSelectedServices[selectedVipService.passengerId]) === null || _b === void 0 ? void 0 : _b[selectedVipService.serviceId]) === null || _c === void 0 ? void 0 : _c[selectedVipService.segmentIds[0]]);
            if (selectedVipService.count === 1) {
                if (!isSelected) {
                    dispatch(addService({
                        segmentId: selectedVipService.segmentIds[0],
                        service: relatedCheckinOrderService,
                        passengerId: selectedVipService.passengerId
                    }));
                }
            }
            else {
                dispatch(removeService({
                    segmentId: selectedVipService.segmentIds[0],
                    service: relatedCheckinOrderService,
                    passengerId: selectedVipService.passengerId
                }));
            }
        };
        try {
            for (var selectedServices_1 = __values(selectedServices), selectedServices_1_1 = selectedServices_1.next(); !selectedServices_1_1.done; selectedServices_1_1 = selectedServices_1.next()) {
                var selectedVipService = selectedServices_1_1.value;
                _loop_1(selectedVipService);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (selectedServices_1_1 && !selectedServices_1_1.done && (_a = selectedServices_1.return)) _a.call(selectedServices_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        dispatch(saveServices());
        onClose();
    };
    var renderSubheader = function () {
        var passengersMap = getPassengerPricesMap(passengers, businessLoungeServices);
        return (React.createElement(React.Fragment, null, passengersMap.map(function (passenger) { return (React.createElement("span", null,
            t("Checkout:".concat(PASSENGERS_TYPES[passenger.type])),
            ": ",
            React.createElement(Money, { money: passenger.price }))); })));
    };
    var childrenProps = {
        passengers: passengers,
        onSave: onSave,
        onClose: onClose,
        subheader: renderSubheader(),
        header: t('Business lounge'),
        totalPrice: totalPrice,
        minPrice: minPrice,
        loading: isLoading,
        services: businessLoungeServices,
        segments: segments,
        selectedServices: checkoutSelectedServicesStateFactory(order)
    };
    return children(childrenProps);
};
export default CheckinBusinessLoungeAdapter;
