import * as React from 'react';
import cn from 'classnames';
import Button from '../../../../../Button';
import { useTheme } from '../../../../../theme';
var plusIcon = (React.createElement("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14 8.00009H0V6.00009H14V8.00009Z", fill: "white" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 9.15527e-05V14.0001H6L6 9.15527e-05H8Z", fill: "white" })));
var minusIcon = (React.createElement("svg", { width: "14", height: "2", viewBox: "0 0 14 2", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14 2H0V0H14V2Z", fill: "white" })));
var Actions = React.memo(function (_a) {
    var _b, _c, _d;
    var canRemoveSegment = _a.canRemoveSegment, showAddSegment = _a.showAddSegment, showRemoveSegment = _a.showRemoveSegment, onAddSegment = _a.onAddSegment, removeLastSegment = _a.removeLastSegment, isAlternativeView = _a.isAlternativeView;
    var css = useTheme('SearchForm').Actions;
    return (React.createElement("div", { className: cn(css.actions, (_b = {},
            _b[css.actions_alternative] = isAlternativeView,
            _b)) },
        React.createElement(Button, { className: cn(css.addSegment, (_c = {},
                _c[css.addSegment_alternative] = isAlternativeView,
                _c)), onClick: showAddSegment ? onAddSegment : null, disabled: !showAddSegment }, plusIcon),
        canRemoveSegment && (React.createElement(Button, { className: cn(css.removeSegment, (_d = {},
                _d[css.removeSegment_alternative] = isAlternativeView,
                _d)), onClick: removeLastSegment, disabled: !showRemoveSegment }, minusIcon))));
});
export default Actions;
