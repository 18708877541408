import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import ErrorBoundary from '../../../../../ErrorBoundary/ErrorBoundary';
import { useToggleable } from '../../../../../hooks';
import { Button } from '../../../../../index';
import WarningModal from '../../../../../WarningModal';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { useConfig } from '../../../../../context';
var CheckinErrorBoundary = function (props) {
    var _a = useToggleable(false), open = _a.open, close = _a.close, isOpen = _a.isOpen;
    var t = useTranslation('Checkin').t;
    var css = useTheme('Checkin').CheckinErrorBoundary;
    // const oldEngineURL
    var _b = useConfig().global, locale = _b.locale, reserveEngines = _b.reserveEngines;
    if (!(reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin)) {
        return React.createElement(React.Fragment, null, props.children);
    }
    var checkinUrl = "".concat(reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin, "?lastName=").concat(props.lastName, "&ticketNumber=").concat(props.ticketNumber, "&lang=").concat(locale);
    var fallbackRenderer = useCallback(function (error) {
        if (error) {
            open();
        }
        if (!error) {
            return null;
        }
        return (React.createElement(WarningModal, { className: css.modal, isOpen: !!error, title: t('Oops, something went wrong'), content: React.createElement("div", null,
                t("But don't worry, all of our systems have been duplicated several times. You can register on the backup version of the site."),
                React.createElement("div", { className: css.buttons },
                    React.createElement(Button, { className: css.button, onClick: function () { return window.location.reload(); } }, t('Try again')),
                    React.createElement(Button, { className: cn(css.button, css.active), onClick: function () { return (window.location.href = checkinUrl); } }, t('Go')))) }));
    }, [open, close, isOpen]);
    return React.createElement(ErrorBoundary, { renderFallback: fallbackRenderer }, props.children);
};
export default CheckinErrorBoundary;
