import { __read, __spreadArray, __values } from "tslib";
import { BaggageDirection } from '../BaggageList/types';
export var convertBaggageOnBaggageItem = function (baggages, selectedServices, order, segments) {
    var minAmount = (baggages === null || baggages === void 0 ? void 0 : baggages[0].price.amount) || 0;
    baggages === null || baggages === void 0 ? void 0 : baggages.forEach(function (baggage) {
        if (baggage.price.amount < minAmount) {
            minAmount = baggage.price.amount;
        }
    });
    return {
        baggage: baggages,
        selected: isSelected(baggages[0], selectedServices),
        priceFrom: {
            amount: minAmount,
            currency: baggages === null || baggages === void 0 ? void 0 : baggages[0].price.currency
        },
        servicesBySegments: getSelectedServicesBySegments(baggages, selectedServices, order, segments)
    };
};
export var getSelectedServicesBySegments = function (baggages, selectedServices, order, segments) {
    var selectedServicesMap = new Map();
    segments.forEach(function (segment) {
        var servicesBySegment = selectedServices.filter(function (service) {
            return baggages.some(function (baggage) { return baggage.id === service.serviceId; }) &&
                service.segmentIds.map(function (id) { return id[0]; }).includes(segment.id);
        });
        var segmentCount = servicesBySegment.reduce(function (count, service) { return +count + +service.count; }, 0);
        if (segmentCount > 0) {
            var direction = {
                arrival: segment.arrival.airport,
                departure: segment.departure.airport
            };
            selectedServicesMap.has(segmentCount)
                ? selectedServicesMap.set(segmentCount, __spreadArray(__spreadArray([], __read(selectedServicesMap.get(segmentCount)), false), [direction], false))
                : selectedServicesMap.set(segmentCount, [direction]);
        }
    });
    var selectedServicesWithCountAndDirections = [];
    __spreadArray([], __read(selectedServicesMap.entries()), false).forEach(function (_a) {
        var _b = __read(_a, 2), count = _b[0], directions = _b[1];
        selectedServicesWithCountAndDirections.push({
            count: count,
            directions: directions,
            allSegments: directions.length === order.flight.segments.length
        });
    });
    return selectedServicesWithCountAndDirections;
};
export var isSelected = function (baggage, selectedServices) {
    return calcCount(baggage, selectedServices) > 0;
};
export var calcCount = function (baggage, selectedServices) {
    return selectedServices.filter(function (el) { return el.serviceId === baggage.id; }).reduce(function (acc, el) { return acc + el.count; }, 0);
};
export var detectDirection = function (baggage, selectedServices, order) {
    var firstSegment = order.flight.segments[0];
    var lastSegment = order.flight.segments[order.flight.segments.length - 1];
    var allDirections = order === null || order === void 0 ? void 0 : order.travellers.map(function (traveller) {
        var services = selectedServices.filter(function (el) { return traveller.id === el.passengerId; });
        if (services.length === 0) {
            return null;
        }
        return detectDirectionForTraveller(baggage, services, firstSegment, lastSegment);
    });
    var existRoundTrip = allDirections.some(function (direction) { return direction === BaggageDirection.RoundTrip; });
    var existThere = allDirections.some(function (direction) { return direction === BaggageDirection.There; });
    var existReturn = allDirections.some(function (direction) { return direction === BaggageDirection.Return; });
    if (existRoundTrip) {
        return BaggageDirection.RoundTrip;
    }
    else if (existThere && existReturn) {
        return BaggageDirection.RoundTrip;
    }
    else if (existThere) {
        return BaggageDirection.There;
    }
    else if (existReturn) {
        return BaggageDirection.Return;
    }
    return null;
};
export var detectDirectionForTraveller = function (baggage, selectedServices, firstSegment, lastSegment) {
    var segmentIds = [];
    selectedServices
        .filter(function (service) { return baggage.id === service.serviceId; })
        .forEach(function (el) {
        return el.segmentIds.forEach(function (segmentId) {
            segmentIds.push(segmentId);
        });
    });
    if (segmentIds.length === 0) {
        return null;
    }
    var thereDirection = segmentIds.includes(firstSegment.segment.id);
    var returnDirection = false;
    if (firstSegment.segment.id !== lastSegment.segment.id) {
        returnDirection = segmentIds.includes(lastSegment.segment.id);
    }
    if (thereDirection && returnDirection) {
        return BaggageDirection.RoundTrip;
    }
    else if (thereDirection) {
        return BaggageDirection.There;
    }
    else if (returnDirection) {
        return BaggageDirection.Return;
    }
    return null;
};
export var factorySelectedServices = function (selectedServices) {
    var e_1, _a, e_2, _b, e_3, _c;
    var _d, _e;
    var result = [];
    var addedKeys = new Map();
    try {
        for (var _f = __values(Object.entries(selectedServices)), _g = _f.next(); !_g.done; _g = _f.next()) {
            var _h = __read(_g.value, 2), passengerId = _h[0], passengerSelectedServices = _h[1];
            try {
                for (var _j = (e_2 = void 0, __values(Object.entries(passengerSelectedServices))), _k = _j.next(); !_k.done; _k = _j.next()) {
                    var _l = __read(_k.value, 2), serviceId = _l[0], segments = _l[1];
                    try {
                        for (var _m = (e_3 = void 0, __values(Object.entries(segments))), _o = _m.next(); !_o.done; _o = _m.next()) {
                            var _p = __read(_o.value, 2), segmentId = _p[0], selectedService = _p[1];
                            var segmentIds = (_d = selectedServices[passengerId][serviceId][segmentId]) === null || _d === void 0 ? void 0 : _d.segmentIds;
                            var key = "".concat(passengerId, "-").concat(serviceId, "-").concat(segmentIds.join('-'));
                            if (!addedKeys.has(key)) {
                                result.push({
                                    passengerId: passengerId,
                                    segmentIds: (_e = selectedServices[passengerId][serviceId][segmentId]) === null || _e === void 0 ? void 0 : _e.segmentIds,
                                    serviceId: serviceId,
                                    count: selectedService.count
                                });
                                addedKeys.set(key, key);
                            }
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_o && !_o.done && (_c = _m.return)) _c.call(_m);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_k && !_k.done && (_b = _j.return)) _b.call(_j);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_g && !_g.done && (_a = _f.return)) _a.call(_f);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return result;
};
