import { createAction } from 'redux-actions';
export var ADD_SERVICE = 'ADD_SERVICE';
export var REMOVE_SERVICE = 'REMOVE_SERVICE';
export var SET_SELECTED_SERVICES = 'SET_SELECTED_SERVICES';
export var SAVE_SERVICES = 'SAVE_SERVICES';
export var CLEAR_SERVICE = 'CLEAR_SERVICE';
export var EQUALIZE_PASSENGER_BAGGAGE = 'EQUALIZE_PASSENGER_BAGGAGE';
export var REPLACE_SERVICE = 'REPLACE_SERVICE';
export var addService = createAction(ADD_SERVICE);
export var removeService = createAction(REMOVE_SERVICE);
export var clearService = createAction(CLEAR_SERVICE);
export var setSelectedServices = createAction(SET_SELECTED_SERVICES);
export var saveServices = createAction(SAVE_SERVICES);
export var replaceService = createAction(REPLACE_SERVICE);
export var equalizePassengerBaggage = createAction(EQUALIZE_PASSENGER_BAGGAGE);
