import { __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';
import MediaQuery from 'react-responsive';
import { useTheme } from '../../../../theme';
import { UserLoyaltyCardLevels } from '../../../../__generated__/globalTypes';
import Money from '../../../../Money';
import LoyaltyCard from '../LoyaltyCard/LoyaltyCard';
import { MOBILE_MIN_WIDTH, useIsMobile } from '../../../../utils';
import Modal from '../../../../Modal';
import MilesRecovery from '../../../../MilesRecovery/components/MilesRecovery';
import Button from '../../../../Button/Button';
var LoyaltyInfo = function (props) {
    var _a;
    var _b, _c;
    var t = useTranslation('Loyalty').t;
    var css = useTheme('Loyalty').Loyalty;
    var _d = __read(React.useState(false), 2), sureModalOpen = _d[0], setSureModalOpen = _d[1];
    var _e = __read(React.useState(false), 2), milesRecoveryOpen = _e[0], setMilesRecoveryOpen = _e[1];
    var milesPercentage = (props.ffpInfo.numberOfMiles / props.ffpInfo.currentLevelMaxMiles) * 100;
    var availableStatusIndex = props.ffpInfo.availableLevels.indexOf(props.ffpInfo.currentLevel);
    var nextLevel = availableStatusIndex < props.ffpInfo.availableLevels.length - 1
        ? props.ffpInfo.availableLevels[availableStatusIndex + 1]
        : null;
    var hideMilesRecovery = function () { return setMilesRecoveryOpen(false); };
    var openMilesRecovery = function () { return setMilesRecoveryOpen(true); };
    var openSureModal = function () { return setSureModalOpen(true); };
    var closeSureModal = function () { return setSureModalOpen(false); };
    var isMobile = useIsMobile();
    var onSureModalCallback = function () {
        var _a;
        (_a = props.sureModalCallback) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    return (React.createElement("div", { className: cn(css.card, (_a = {},
            _a[css.gold] = props.ffpInfo.currentLevel === UserLoyaltyCardLevels.gold,
            _a[css.silver] = props.ffpInfo.currentLevel === UserLoyaltyCardLevels.silver,
            _a[css.platinum] = props.ffpInfo.currentLevel === UserLoyaltyCardLevels.platinum,
            _a)) },
        React.createElement("div", { className: css.cardWrapper },
            React.createElement("div", { className: css.miles },
                React.createElement("div", null,
                    React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                        React.createElement("div", { className: css.milesStatus__header },
                            t('Your status'),
                            ":"),
                        React.createElement("div", { className: css.milesStatus__value }, props.ffpInfo.currentLevel)),
                    React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                        React.createElement("div", { className: css.milesStatus__header },
                            React.createElement(Money, { money: { amount: props.ffpInfo.numberOfMiles, currency: 'Miles' }, moneyClassName: css.milesStatus__miles }),
                            t('miles')))),
                React.createElement(LinearProgress, { classes: {
                        barColorPrimary: css.progress
                    }, variant: 'determinate', value: milesPercentage }),
                React.createElement("span", { className: css.miles__total },
                    t('Total'),
                    ":\u00A0",
                    React.createElement(Money, { money: { amount: props.ffpInfo.numberOfMiles, currency: 'Miles' }, showCurrency: false }),
                    "\u00A0",
                    t('miles'),
                    "."),
                nextLevel && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: css.miles__left },
                        t("Left for ".concat(nextLevel, " status")),
                        ":\u00A0",
                        React.createElement(Money, { money: { amount: props.ffpInfo.milesForNextLevel, currency: 'Miles' }, showCurrency: false }),
                        "\u00A0",
                        t('miles'),
                        " ",
                        t('or'),
                        ' ',
                        ((_b = props.ffpInfo) === null || _b === void 0 ? void 0 : _b.segmentsForNextLevel) > 1 ? (_c = props.ffpInfo) === null || _c === void 0 ? void 0 : _c.segmentsForNextLevel : 1,
                        ' ',
                        t('flights'),
                        ".")))),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement(LoyaltyCard, { cardNumber: props.ffpInfo.cardNumber, currentLevel: props.ffpInfo.currentLevel, loyaltyCardOwnerName: props.userName, loyaltyProgrammName: props.loyaltyName })),
            React.createElement("div", { className: css.links },
                props.links.map(function (_a, index) {
                    var url = _a.url, title = _a.title;
                    return (React.createElement("a", { href: url, target: "_blank", className: css.miles__link, key: index }, t(title)));
                }),
                React.createElement("span", { className: css.miles__link, onClick: openMilesRecovery }, t('Miles recovery')),
                React.createElement("span", { className: css.miles__link, onClick: openSureModal }, t('Exit and add another card')))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(LoyaltyCard, { cardNumber: props.ffpInfo.cardNumber, currentLevel: props.ffpInfo.currentLevel, loyaltyCardOwnerName: props.userName, loyaltyProgrammName: props.loyaltyName })),
        React.createElement(Modal, { classes: {
                paper: css.loyalty__paper,
                scrollBody: css.loyalty__body
            }, slideUp: isMobile, open: sureModalOpen, onClose: closeSureModal },
            React.createElement("div", { className: css.loyalty__modal },
                React.createElement("div", { className: css.loyalty__header }, t('You sure?')),
                React.createElement("div", { className: css.loyalty__text }, t('Delete card from your account?')),
                React.createElement("div", { className: css.loyalty__footer },
                    React.createElement(Button, { className: css.loyalty__footerCancel, onClick: closeSureModal }, t('No')),
                    React.createElement(Button, { onClick: onSureModalCallback }, t('Yes'))))),
        React.createElement(Modal, { maxWidth: "sm", open: milesRecoveryOpen, onClose: hideMilesRecovery },
            React.createElement(MilesRecovery, null))));
};
export default LoyaltyInfo;
