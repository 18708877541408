import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { getCheckinHasCompleted, getCheckinNotStarted, getCheckinOrder, getHasConfirmedAeroexpress, getSegments } from '../../../../store/order/selectors';
import * as CheckinAddTravellerMutation from '../../../../../../__queries__/CheckinAddTraveller.graphql';
import * as CheckinStartMutation from '../../../../../../__queries__/CheckinStart.graphql';
import * as CheckinRemoveTravellerMutation from '../../../../../../__queries__/CheckinRemoveTraveller.graphql';
import * as CheckinSetTravellersDataMutation from '../../../../../../__queries__/CheckinSetTravellersData.graphql';
import Segment from '../../../../../../Segment';
import { FlightClass, TravellerFieldEnum } from '../../../../../../__generated__/globalTypes';
import { useTheme } from '../../../../../../theme';
import MediaQuery from '../../../../../../MediaQuery/MediaQuery';
import MobileSegment from '../../../../../../MobileSegment/MobileSegment';
import SelectedPassenger from '../../../../../../SelectedPassenger';
import { fillCheckinOrder } from '../../../../store/order/actions';
import AddPassenger from '../../../../../../AddPassenger/AddPassenger';
import Button from '../../../../../../Button';
import WarningModal from '../../../../../../WarningModal';
import SimpleLoader from '../../../../../../SimpleLoader';
import { StatusMessage } from '../../../../../../index';
import RegisteredPassenger from './RegisteredPassenger/RegisteredPassenger';
import * as CheckinCancelMutation from '../../../../../../__queries__/CheckinCancel.graphql';
import Timelimit from './Timelimit/Timelimit';
import { MOBILE_MIN_WIDTH } from '../../../../../../utils';
import { useCheckinCancelModal, useSegmentSelect } from './hooks';
import SelectSegmentsModal from './SelectSegmentsModal/SelectSegmentsModal';
import { CheckinGoal, reachGoal } from '../../../../../../analytics';
import Modal from '../../../../../../Modal';
import VisaForm from './VisaForm/VisaForm';
import { useConfig } from '../../../../../../context';
import DownloadAeroexpress from './RegisteredPassenger/DownloadAeroexpress/DownloadAeroexpress';
var Passengers = function (_a) {
    var _b;
    var onCheckinStart = _a.onCheckinStart;
    var order = useSelector(getCheckinOrder);
    var isConfirmed = useSelector(getCheckinHasCompleted);
    var segments = useSelector(getSegments);
    var isNotStarted = useSelector(getCheckinNotStarted);
    var hasConfirmedAeroexpress = useSelector(getHasConfirmedAeroexpress);
    var dispatch = useDispatch();
    var _c = __read(useState(), 2), checkinStartError = _c[0], setCheckinStartError = _c[1];
    var _d = __read(useState(null), 2), cancellationError = _d[0], setCancellationError = _d[1];
    var _e = __read(useState(false), 2), isLoading = _e[0], setIsLoading = _e[1];
    var _f = __read(useState(false), 2), displayVisaForm = _f[0], setDisplayVisaForm = _f[1];
    var css = useTheme('Checkin').Passengers;
    var t = useTranslation('Checkin').t;
    var _g = __read(useMutation(CheckinStartMutation.CheckinStart), 1), startCheckin = _g[0];
    var _h = __read(useMutation(CheckinAddTravellerMutation.CheckinAddTraveller), 1), addTraveller = _h[0];
    var _j = __read(useMutation(CheckinRemoveTravellerMutation.CheckinRemoveTraveller), 1), removeTraveller = _j[0];
    var _k = __read(useMutation(CheckinCancelMutation.CheckinCancel), 1), cancelCheckin = _k[0];
    var _l = __read(useMutation(CheckinSetTravellersDataMutation.SetCheckinSetTravellersData), 1), setTravellerData = _l[0];
    var buttonIsDisabled = !order.travellers.length || isLoading;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _m = useCheckinCancelModal(), handleAgree = _m.handleAgree, showCheckinCancelModalIfNeeded = _m.showCheckinCancelModalIfNeeded, isCheckinCancelModalOpen = _m.isCheckinCancelModalOpen;
    var closeCheckinStartErrorModal = function () { return setCheckinStartError(null); };
    var closeCancellationErrorModal = function () { return setCancellationError(null); };
    var config = useConfig();
    var canDownloadBoardingPass = React.useMemo(function () { return order.segments.some(function (segment) { return segment.checkinInfo.airportHasDcs; }); }, [order.segments]);
    var onAddTraveller = useCallback(function (lastName, id, extraSecret) {
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, data, errors, result, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, addTraveller({
                                variables: {
                                    params: {
                                        id: id,
                                        secret: lastName,
                                        orderId: order.id,
                                        extraSecret: extraSecret
                                    }
                                }
                            })];
                    case 1:
                        _a = _b.sent(), data = _a.data, errors = _a.errors;
                        result = !errors && (data === null || data === void 0 ? void 0 : data.CheckinAddTraveller);
                        resolve(!!result);
                        if (result) {
                            reachGoal(CheckinGoal.AddTraveller);
                            dispatch(fillCheckinOrder(result));
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        reject(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    }, [order.id]);
    var onRemoveTraveller = useCallback(function (traveller) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, result, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, removeTraveller({
                            variables: {
                                params: {
                                    orderId: order.id,
                                    passengerId: traveller.id
                                }
                            }
                        })];
                case 2:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    result = !errors && (data === null || data === void 0 ? void 0 : data.CheckinRemoveTraveller);
                    if (errors) {
                        console.error(errors);
                    }
                    else if (result) {
                        reachGoal(CheckinGoal.RemoveTraveller);
                        dispatch(fillCheckinOrder(result));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _b.sent();
                    console.error(e_2);
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [order.id]);
    var onSaveLoyaltyNumber = useCallback(function (code, traveller) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, result, e_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, setTravellerData({
                            variables: {
                                params: {
                                    orderId: order.id,
                                    travellersData: [
                                        {
                                            id: traveller.id,
                                            values: [
                                                {
                                                    type: TravellerFieldEnum.LoyaltyNumber,
                                                    name: 'loyaltyNumber',
                                                    value: code
                                                },
                                                {
                                                    type: TravellerFieldEnum.LoyaltyAirline,
                                                    name: 'loyaltyAirline',
                                                    value: config.global.companyInfo.iataCode
                                                }
                                            ]
                                        }
                                    ]
                                }
                            }
                        })];
                case 2:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    result = !errors && (data === null || data === void 0 ? void 0 : data.CheckinSetTravellersData);
                    if (errors) {
                        console.error(errors);
                    }
                    else if (result) {
                        dispatch(fillCheckinOrder(result));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _b.sent();
                    console.error(e_3);
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [order.id]);
    var _o = useSegmentSelect(setIsLoading, setCheckinStartError, onCheckinStart), onStart = _o.onStart, isSegmentsSelectionOpen = _o.isSegmentsSelectionOpen, onSelect = _o.onSelect, selectedSegments = _o.selectedSegments;
    var onStartCheckin = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, result, e_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setDisplayVisaForm(false);
                    setIsLoading(true);
                    onStart();
                    if (segments.length > 1 && !selectedSegments.size) {
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, startCheckin({
                            variables: {
                                id: order.id
                            }
                        })];
                case 2:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    result = !errors && (data === null || data === void 0 ? void 0 : data.CheckinStart);
                    if (errors) {
                        setCheckinStartError(errors[0]);
                    }
                    else if (result) {
                        reachGoal(CheckinGoal.Start);
                        dispatch(fillCheckinOrder(result));
                        onCheckinStart();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_4 = _b.sent();
                    setCheckinStartError(e_4);
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [order.id, selectedSegments]);
    var onCheckinStartHandler = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var travellersWithVisa;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    travellersWithVisa = order.travellers.find(function (traveller) { return traveller.visaRequired && traveller.visaValues.length; });
                    if (!travellersWithVisa) return [3 /*break*/, 1];
                    setDisplayVisaForm(true);
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, onStartCheckin()];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [order.id]);
    var onCheckinCancel = useCallback(function (passengerId) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, result, e_5;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setCancellationError(null);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, cancelCheckin({
                            variables: {
                                params: {
                                    orderId: order.id,
                                    segmentIds: order.segments.map(function (segment) { return segment.id; }),
                                    travellerIds: [passengerId]
                                }
                            }
                        })];
                case 2:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    result = !errors && (data === null || data === void 0 ? void 0 : data.CheckinCancel);
                    if (errors) {
                        setCancellationError(errors[0]);
                    }
                    else if (result) {
                        showCheckinCancelModalIfNeeded(result);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_5 = _b.sent();
                    console.error(e_5);
                    setCancellationError(e_5);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, []);
    return order ? (React.createElement("div", { className: css.wrapper },
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An unexpected error has occurred during the start of the check-in process. Please try again later or contact a customer support service.'), isOpen: !!checkinStartError, onButtonClick: closeCheckinStartErrorModal, errorMessage: checkinStartError === null || checkinStartError === void 0 ? void 0 : checkinStartError.message, onClose: closeCheckinStartErrorModal, buttonText: t('OK, thank you') }),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('Unfortunately, check-in cancellation is prohibited for this flight. Please contact a customer support service for further details.'), onClose: closeCancellationErrorModal, onButtonClick: closeCancellationErrorModal, buttonText: t('OK, thank you'), isOpen: !!cancellationError }),
        React.createElement(SelectSegmentsModal, { fullScreen: isMobile, open: isSegmentsSelectionOpen, onSegmentSelect: onSelect, selectedSegments: selectedSegments, onConfirm: onStart }),
        displayVisaForm && React.createElement(VisaForm, { onVisaSubmitted: onStartCheckin }),
        React.createElement("div", { className: css.segments },
            !isConfirmed && React.createElement("h3", { className: css.title }, t('Choose passengers for check-in')),
            !isConfirmed && !isMobile && order.segments[0].checkinInfo.webCloseTime && (React.createElement(Timelimit, { timelimit: order.segments[0].checkinInfo.webCloseTime })),
            isConfirmed && (React.createElement(StatusMessage, { type: "success", title: t('Check-in Successful'), text: t(canDownloadBoardingPass
                    ? 'You can now download and print your boarding passes. You will need them at the airport.'
                    : 'Passengers:Please notice, you must receive your boarding passes at the airport') })),
            order.segments.map(function (segment, index) {
                var _a, _b, _c, _d, _e, _f;
                return (React.createElement("div", { key: index, className: css.segment },
                    React.createElement(MediaQuery, { minWidth: "mobile" },
                        React.createElement(Segment, { variant: "common", segment: segment, serviceClass: (_c = (_b = (_a = segment.fareFamilies[0]) === null || _a === void 0 ? void 0 : _a.fareFamily) === null || _b === void 0 ? void 0 : _b.category) !== null && _c !== void 0 ? _c : FlightClass.Economy })),
                    React.createElement(MediaQuery, { maxWidth: "mobile" },
                        React.createElement("div", null,
                            React.createElement(MobileSegment, { segment: segment, serviceClass: (_f = (_e = (_d = segment.fareFamilies[0]) === null || _d === void 0 ? void 0 : _d.fareFamily) === null || _e === void 0 ? void 0 : _e.category) !== null && _f !== void 0 ? _f : FlightClass.Economy })))));
            })),
        React.createElement("div", { className: css.passengers },
            isConfirmed && hasConfirmedAeroexpress && (React.createElement(DownloadAeroexpress, { travellers: order.travellers.map(function (traveller) { return ({
                    id: traveller.id
                }); }), orderId: order.id, setLoading: setIsLoading })),
            order.travellers.map(function (traveller, index) {
                return !isConfirmed ? (React.createElement(SelectedPassenger, { key: index, passenger: traveller, onDelete: isNotStarted &&
                        order.travellers.length > 1 &&
                        traveller.canBeDeleted &&
                        onRemoveTraveller, onSaveLoyalty: onSaveLoyaltyNumber })) : (React.createElement(RegisteredPassenger, { key: index, passenger: traveller, onCheckinCancel: onCheckinCancel, segments: order.segments }));
            }),
            isNotStarted && React.createElement(AddPassenger, { onSubmit: onAddTraveller })),
        React.createElement(Modal, { open: isCheckinCancelModalOpen, onClose: handleAgree, maxWidth: 'sm', classes: { scrollBody: css.checkinCancelModal } },
            React.createElement("div", { className: css.checkinCancelModal__content },
                React.createElement("div", { className: css.checkinCancelModal__text }, t('Checkin canceled, you will be redirected to the beginning', {})),
                React.createElement("div", { className: css.checkinCancelModal__buttons },
                    React.createElement(Button, { onClick: handleAgree }, t('OK'))))),
        !isConfirmed && (React.createElement("div", { className: css.toolbar },
            React.createElement(Button, { size: "large", variant: "flat", disabled: buttonIsDisabled, className: cn(css.toolbar__button, (_b = {},
                    _b[css.toolbar__button_disabled] = buttonIsDisabled,
                    _b)), onClick: !buttonIsDisabled ? onCheckinStartHandler : null }, t('Continue')))),
        isLoading && React.createElement(SimpleLoader, null))) : null;
};
export default Passengers;
