import { __read } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import SnackbarMaterial from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import * as theme from './Snackbar.css';
import { closeIcon } from '../../Modal/icons';
var headerHeightInPx = 50;
var Snackbar = function (props) {
    var _a, _b;
    var _c = __read(useState(false), 2), isSnackbarInFixedMode = _c[0], setIsSnackbarInFixedMode = _c[1];
    var handleScroll = function () {
        var scrollPosition = window.pageYOffset;
        if (scrollPosition > headerHeightInPx) {
            setIsSnackbarInFixedMode(true);
        }
        else {
            setIsSnackbarInFixedMode(false);
        }
    };
    useEffect(function () {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return function () {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (React.createElement(SnackbarMaterial, { open: props.open, onClose: props.onClose, classes: {
            root: cn(theme.snackbar, (_a = {},
                _a[theme.snackbar_error] = props.error,
                _a[theme.snackbar_fixed] = isSnackbarInFixedMode,
                _a)),
            anchorOriginTopRight: cn(theme.snackbar__topRight, (_b = {},
                _b[theme.snackbar__topRight_fixed] = isSnackbarInFixedMode,
                _b))
        }, anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
        }, action: React.createElement(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: props.onClose }, closeIcon), message: props.message }));
};
export default Snackbar;
