import { __read } from "tslib";
import { Tabs } from './types';
import { useEffect, useRef, useState } from 'react';
import { filterFareGroups } from '../Results/utils';
import { FlightClass } from '../__generated__/globalTypes';
import { convertFareFamilyToProduct, viewProduct } from '../analytics';
import { useConfig } from '../context';
export var useDesktopFlight = function (componentProps) {
    var _a, _b;
    var _c = __read(useState(null), 2), activeTab = _c[0], openTab = _c[1];
    var selectedFares = componentProps.selectedFares, flight = componentProps.flight, isPreOpen = componentProps.isPreOpen, onSelect = componentProps.onSelect;
    var lastSelectedFare = (_b = (_a = selectedFares === null || selectedFares === void 0 ? void 0 : selectedFares[selectedFares.length - 1]) === null || _a === void 0 ? void 0 : _a.fareGroupId) !== null && _b !== void 0 ? _b : null;
    var showAvailableFaresOnly = useConfig().Engine.showAvailableFaresOnly;
    // #fixme: вынести во Flight
    var economyFareGroups = filterFareGroups(flight.pricesForFareGroups, selectedFares !== null && selectedFares !== void 0 ? selectedFares : [], FlightClass.Economy, showAvailableFaresOnly);
    var businessFareGroups = filterFareGroups(flight.pricesForFareGroups, selectedFares !== null && selectedFares !== void 0 ? selectedFares : [], FlightClass.Business, showAvailableFaresOnly);
    var milesFareGroups = filterFareGroups(flight.pricesForFareGroups, selectedFares !== null && selectedFares !== void 0 ? selectedFares : [], FlightClass.Miles, showAvailableFaresOnly);
    var filteredPrices = economyFareGroups.concat(businessFareGroups);
    var flightBlock = useRef(null);
    useEffect(function () {
        activeTab
            ? setTimeout(function () {
                var _a;
                (_a = flightBlock === null || flightBlock === void 0 ? void 0 : flightBlock.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
            }, 120)
            : null;
    }, [activeTab]);
    useEffect(function () {
        if (isPreOpen) {
            if (economyFareGroups.some(function (fareGroup) { return fareGroup.prices; })) {
                openTab(Tabs.EconomyFares);
            }
            else if (businessFareGroups.some(function (fareGroup) { return fareGroup.prices; })) {
                openTab(Tabs.BusinessFares);
            }
        }
    }, [isPreOpen]);
    var handleFlightInfoTab = function () {
        openTab(activeTab !== Tabs.FlightInfo ? Tabs.FlightInfo : null);
    };
    var handleEconomyFareGroupsTab = function () {
        openTab(activeTab !== Tabs.EconomyFares ? Tabs.EconomyFares : null);
    };
    var handleBusinessFareGroupsTab = function () {
        openTab(activeTab !== Tabs.BusinessFares ? Tabs.BusinessFares : null);
    };
    var handleMilesFareGroupsTab = function () {
        openTab(activeTab !== Tabs.MilesFares ? Tabs.MilesFares : null);
    };
    var getFareGroupTabHandler = function (serviceClass) {
        return serviceClass === FlightClass.Business ? handleBusinessFareGroupsTab : handleEconomyFareGroupsTab;
    };
    // #fixme: this thing needs to be isolated
    var getOnFareSelect = function (group) {
        return function () {
            if (onSelect) {
                onSelect({
                    fareGroupId: group.fareFamily.id,
                    fareName: group.fareFamily.title,
                    prices: group.prices,
                    flightInfo: {
                        id: group.prices[0].flight.id,
                        segments: flight.segments
                    }
                });
                openTab(null);
                viewProduct(convertFareFamilyToProduct(group.fareFamily));
            }
        };
    };
    return {
        lastSelectedFare: lastSelectedFare,
        flightBlock: flightBlock,
        activeTab: activeTab,
        flight: flight,
        getFareGroupTabHandler: getFareGroupTabHandler,
        handleFlightInfoTab: handleFlightInfoTab,
        filteredPrices: filteredPrices,
        handleEconomyFareGroupsTab: handleEconomyFareGroupsTab,
        handleBusinessFareGroupsTab: handleBusinessFareGroupsTab,
        handleMilesFareGroupsTab: handleMilesFareGroupsTab,
        economyFareGroups: economyFareGroups,
        businessFareGroups: businessFareGroups,
        milesFareGroups: milesFareGroups,
        getOnFareSelect: getOnFareSelect
    };
};
