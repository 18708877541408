import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import Money from '../../Money';
import css from './PriceDetails.css';
import { useTranslation } from 'react-i18next';
import DetalizationLine from './DetalizationLine/DetalizationLine';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { getOrder } from '../../Checkout/store/order/selectors';
var isTaxRetention = function (element) {
    return element.hasOwnProperty('taxCode');
};
var PricesDetails = function (_a) {
    var prices = _a.prices, renderAdditionalFields = _a.renderAdditionalFields, className = _a.className;
    var priceDetails = prices.priceDetails;
    var t = useTranslation('Exchange').t;
    var order = useSelector(getOrder);
    var retentionByTypes = useMemo(function () {
        var byTypes = new Map();
        priceDetails.retention.elements.forEach(function (element) {
            var elementKey = "".concat(element.type, "_").concat(element.taxCode || '');
            var selectedElement = byTypes.get(elementKey);
            if (selectedElement) {
                var updateElement = __assign(__assign({}, selectedElement), { money: __assign(__assign({}, selectedElement.money), { amount: selectedElement.money.amount + element.money.amount }) });
                byTypes.set(elementKey, updateElement);
            }
            else {
                var passengerId = element.passengerId, otherProperties = __rest(element, ["passengerId"]);
                byTypes.set(elementKey, otherProperties);
            }
        });
        return byTypes;
    }, [priceDetails.retention.elements]);
    var alreadyPaid = prices.priceToPay.amount === 0 ? order.price.airticket : priceDetails.paid.sum;
    return (React.createElement("div", { className: cn(css.container, className) }, renderAdditionalFields === null || renderAdditionalFields === void 0 ? void 0 :
        renderAdditionalFields({ prices: prices }),
        React.createElement(DetalizationLine, { total: true, title: t('Already has been paid'), price: alreadyPaid }),
        React.createElement(DetalizationLine, { fee: true, title: t('Total fee'), price: priceDetails.retention.sum }),
        Array.from(retentionByTypes).map(function (_a, key) {
            var _b = __read(_a, 2), type = _b[0], element = _b[1];
            return (React.createElement(DetalizationLine, { key: key },
                React.createElement("div", null, isTaxRetention(element) ? "".concat(t('Fee'), " ").concat(t(element.taxCode)) : t(element.type)),
                React.createElement(Money, { money: element.money })));
        })));
};
export default PricesDetails;
