import { __assign, __read } from "tslib";
import { Tabs } from '../../types';
import { useDesktopFlight } from '../../hooks';
import { useToggleable } from '../../../hooks';
import { FareFamilyOptionsType, FareFeaturePaymentType, FlightClass } from '../../../__generated__/graphql';
import { initI18n } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
var OFFER_UPGRADE_IF_UNAVAILABLE = [
    FareFamilyOptionsType.Exchangeable,
    FareFamilyOptionsType.Baggage,
    FareFamilyOptionsType.Refundable
];
initI18n('DesktopFlight');
export var useDesktopFlightWithPopup = function (props) {
    var _a = useToggleable(false), isModalOpen = _a.isOpen, openModal = _a.open, closeModal = _a.close;
    var _b = __read(useState(), 2), initialTab = _b[0], setInitialTab = _b[1];
    var upgradeOptions = new Map();
    var desktopFlightData = useDesktopFlight(props);
    var t = useTranslation('DesktopFlight').t;
    // wrap openModal with function, which implement interface of getOnFareClickHandler
    // but call openModal instead of previous logic
    var handleModalOpen = function (serviceClass) { return openModal; };
    var openModalWithInitialTab = function (flightClass) {
        var initialTab = null;
        switch (flightClass) {
            case FlightClass.Economy:
                initialTab = Tabs.EconomyFares;
                break;
            case FlightClass.Business:
                initialTab = Tabs.BusinessFares;
                break;
            default:
                break;
        }
        setInitialTab(initialTab);
        openModal();
    };
    props.flight.pricesForFareGroups.forEach(function (priceForFareGroup, index) {
        var _a, _b;
        if (!priceForFareGroup.prices) {
            return;
        }
        var nextFare = props.flight.pricesForFareGroups[index + 1];
        if (!nextFare || !nextFare.fareFamily || desktopFlightData.lastSelectedFare || (priceForFareGroup === null || priceForFareGroup === void 0 ? void 0 : priceForFareGroup.fareFamily)) {
            return;
        }
        // collect all unavailable options on current fare
        var fareUnavailableServicesTypes = (_a = priceForFareGroup.fareFamily) === null || _a === void 0 ? void 0 : _a.options.filter(function (option) { return option.availability === FareFeaturePaymentType.NotAvailable; }).map(function (option) { return option.type; });
        // collect all available options on next fare
        // options which affected, listed in OFFER_UPGRADE_IF_UNAVAILABLE array
        var availableOptionsOnNextFare = Array.from(new Set((_b = nextFare.fareFamily) === null || _b === void 0 ? void 0 : _b.options.filter(function (option) {
            return option.availability !== FareFeaturePaymentType.NotAvailable &&
                fareUnavailableServicesTypes.includes(option.type) &&
                OFFER_UPGRADE_IF_UNAVAILABLE.includes(option.type);
        }).map(function (option) { return option.type; })));
        if (availableOptionsOnNextFare.length) {
            var upgradeOffer = t("Need_".concat(availableOptionsOnNextFare[0] === FareFamilyOptionsType.Baggage ? "m" : "f", " ")) +
                availableOptionsOnNextFare
                    .map(function (availableOptionOnNextSegment, index) {
                    switch (availableOptionOnNextSegment) {
                        case FareFamilyOptionsType.Baggage:
                            return t('baggage');
                        case FareFamilyOptionsType.Exchangeable:
                            return t('the ability to exchange ticket');
                        case FareFamilyOptionsType.Refundable:
                            return t('the ability to refund ticket');
                        default:
                            return '';
                    }
                })
                    .join(t('and'))
                    .concat('?');
            var upgradeOption = {
                canUpgradeTo: nextFare,
                optionsAvailableOnNextFare: availableOptionsOnNextFare,
                upgradeOffer: upgradeOffer
            };
            upgradeOptions.set(priceForFareGroup.fareFamily.id, upgradeOption);
        }
    });
    return __assign(__assign({}, desktopFlightData), { isModalOpen: isModalOpen, openModal: openModal, getFareGroupTabHandler: handleModalOpen, closeModal: closeModal, upgradeOptions: upgradeOptions, openModalWithInitialTab: openModalWithInitialTab, initialTab: initialTab });
};
