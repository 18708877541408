import { useToggleable } from '../../../../../../hooks';
import { useTheme } from '../../../../../../theme';
import { useMemo } from 'react';
export var useCalendarPopover = function () {
    var _a = useToggleable(false), open = _a.open, close = _a.close, isOpen = _a.isOpen, toggle = _a.toggle;
    return {
        open: open,
        toggle: toggle,
        close: close,
        isOpen: isOpen
    };
};
export var useDatepickerStyles = function () {
    var css = useTheme('Schedule').CalendarPopover;
    var datepickerStyles = useMemo(function () { return ({
        Datepicker: {
            WeeklyHeaderStyles: {
                dayOfWeekName: css.weekDay
            },
            DayStyles: {
                day: css.day,
                day_fromOtherMonth: css.fromOtherMonth,
                day_weekend: css.weekend,
                day_today: css.today
            },
            MonthsWrapperStyles: {
                nav_prev: css.calendarNav_prev,
                nav_next: css.calendarNav_next,
                wrapper: css.wrapper
            },
            MonthHeaderStyles: {
                monthHeader__select_month: css.monthName,
                monthHeader__select_year: css.year
            },
            MonthStyles: {
                month: css.month
            }
        }
    }); }, []);
    return datepickerStyles;
};
