import { __assign } from "tslib";
import * as React from 'react';
import WeekCalendarComponent from '../../../../../../Results/components/Results/Calendar/WeekCalendar/WeekCalendar';
import { useQuery } from '@apollo/react-hooks';
import * as MinPriceQuery from '../../../../../../__queries__/MinPrices.graphql';
import { API_DATE_FORMAT, format } from '../../../../../../utils';
import { parseISO, subDays } from 'date-fns';
import { lowestPricesConverter } from '../../utils';
import { useConfig } from '../../../../../../context';
var WeekCalendar = function (_a) {
    var _b, _c, _d;
    var searchParams = _a.searchParams, currentLeg = _a.currentLeg, startNewSearch = _a.startNewSearch, minDate = _a.minDate;
    var calendarDaysCount = (_b = useConfig()) === null || _b === void 0 ? void 0 : _b.Engine.calendarDaysCount;
    var daysOffset = Math.floor(calendarDaysCount / 2);
    var alternativePrices = useQuery(MinPriceQuery.MinPrices, {
        variables: {
            params: __assign(__assign({}, searchParams), { segments: searchParams.segments.map(function (segment) { return (__assign(__assign({}, segment), { date: format(subDays(parseISO(segment.date), daysOffset), API_DATE_FORMAT) })); }), daysCount: calendarDaysCount })
        }
    });
    return (React.createElement(WeekCalendarComponent, { searchParameters: searchParams, legId: currentLeg, hidePrice: true, startNewSearch: startNewSearch, alternativePrices: lowestPricesConverter((_d = (_c = alternativePrices === null || alternativePrices === void 0 ? void 0 : alternativePrices.data) === null || _c === void 0 ? void 0 : _c.FlightsMinPricesInPeriod) === null || _d === void 0 ? void 0 : _d.datesWithLowestPrices), minDate: minDate }));
};
export default WeekCalendar;
