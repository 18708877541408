import { __assign } from "tslib";
import * as React from 'react';
import i18n from 'i18next';
import Step from './Step/Step';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import StepTotal from './StepTotal/StepTotal';
import { useMemo } from 'react';
initI18n('Stepbar');
export var defaultSteps = {
    flights: {
        label: i18n.t('Stepbar:Flights')
    },
    seats: {
        label: i18n.t('Stepbar:Seats')
    },
    baggage: {
        label: i18n.t('Stepbar:Baggage')
    },
    meal: {
        label: i18n.t('Stepbar:Meal')
    },
    passengers: {
        label: i18n.t('Stepbar:Passengers')
    },
    // extras: {
    // 	label: i18n.t('Stepbar:Extras')
    // },
    payment: {
        label: i18n.t('Stepbar:Payment')
    }
};
var Stepbar = function (_a) {
    var _b = _a.steps, steps = _b === void 0 ? defaultSteps : _b, _c = _a.activeStep, activeStep = _c === void 0 ? 0 : _c;
    var css = useTheme('Stepbar').Stepbar;
    var stepsArray = useMemo(function () {
        return Object.values(steps).filter(function (step) { return !step.isHidden; });
    }, [steps]);
    return (React.createElement("div", { className: css.stepbar },
        React.createElement("div", { className: css.inner }, stepsArray.map(function (step, index) {
            var props = {
                isPassed: index < activeStep,
                isActive: index === activeStep,
                styles: {
                    width: "".concat(100 / stepsArray.length, "%")
                }
            };
            if (step.type === 'StepTotal') {
                return React.createElement(StepTotal, __assign({}, props, { key: index, step: step }));
            }
            else {
                return React.createElement(Step, __assign({}, props, { key: index, step: step, id: index + 1 }));
            }
        }))));
};
export default Stepbar;
