import { AviaPassengerType, SeatMapRestrictions } from '../../../../../__generated__/globalTypes';
export var getSeatAvailability = function (params) {
    var seat = params.seat, passenger = params.passenger, segmentId = params.segmentId;
    var isAllowedForSegment = seat.service && seat.service.allowedSegments.some(function (allowedSegment) { return allowedSegment[0] === segmentId; });
    var isAllowedForPassenger = seat.service && seat.service.allowedPassengers.some(function (allowedPassenger) { return allowedPassenger[0] === passenger.id; });
    var isAvailable = seat.isAvailable && seat.isExistent;
    if (seat.seatService) {
        isAvailable = isAvailable && isAllowedForSegment && isAllowedForPassenger;
    }
    if (seat.restrictions) {
        if (seat.restrictions.includes(SeatMapRestrictions.Infant) && passenger.type === AviaPassengerType.INS) {
            isAvailable = false;
        }
        if ((seat.restrictions.includes(SeatMapRestrictions.PassengersUnder18) &&
            passenger.type === AviaPassengerType.CLD) ||
            passenger.type === AviaPassengerType.INS) {
            isAvailable = false;
        }
    }
    return { isAvailable: isAvailable };
};
