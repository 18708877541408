import { __read } from "tslib";
import * as React from 'react';
import ScheduleTrigger from '../ScheduleTrigger/ScheduleTrigger';
import Modal from './ModalContent/ModalContent';
import { useScheduleTrigger } from '../ScheduleTrigger/hooks';
var Schedule = function (props) {
    var searchParameters = props.searchParameters, onStartSearch = props.onStartSearch;
    var _a = useScheduleTrigger(), isOpen = _a.isOpen, open = _a.open, close = _a.close;
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var defaultRenderTrigger = function () {
        return React.createElement(ScheduleTrigger, { isActive: isOpen, onClick: open, isLoading: loading });
    };
    return (React.createElement(React.Fragment, null,
        props.renderTrigger ? props.renderTrigger(open, loading) : defaultRenderTrigger(),
        searchParameters && (React.createElement(Modal, { searchParameters: searchParameters, onStartSearch: onStartSearch, isOpen: isOpen, onClose: close, setLoading: setLoading }))));
};
export default Schedule;
