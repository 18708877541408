import { __assign } from "tslib";
import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import ContactsComponent from '../../../Checkout/components/Checkout/Form/Contacts/Contacts';
import { TravellerFieldEnum } from '@websky/graphql';
import { validate } from '../../../Passenger/utils';
import { InputType } from '../../../Passenger/types';
var Contacts = function (props) {
    var values = useFormState().values;
    var getValidationRulesByType = function (type) { var _a; return (_a = props.customer.values.find(function (value) { return value.type === type; })) === null || _a === void 0 ? void 0 : _a.validationRules; };
    var customer = useMemo(function () {
        var passengerValues = values.passengers.find(function (passenger) {
            var phoneValid = !validate(passenger.phone, values, InputType.Phone, getValidationRulesByType(TravellerFieldEnum.Phone));
            var emailValid = !validate(passenger.email, values, InputType.Text, getValidationRulesByType(TravellerFieldEnum.Email));
            return passenger.phone && passenger.email && phoneValid && emailValid;
        });
        if (passengerValues) {
            return __assign(__assign({}, props.customer), { values: props.customer.values.map(function (value) {
                    if (value.type === TravellerFieldEnum.Phone) {
                        return __assign(__assign({}, value), { value: passengerValues.phone });
                    }
                    if (value.type === TravellerFieldEnum.Email) {
                        return __assign(__assign({}, value), { value: passengerValues.email });
                    }
                    return value;
                }) });
        }
        return props.customer;
    }, [props.customer, values]);
    return React.createElement(ContactsComponent, __assign({}, props, { customer: customer }));
};
export default Contacts;
