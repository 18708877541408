import { __assign } from "tslib";
import { InputType } from './types';
import { TravellerFieldEnum } from '../__generated__/globalTypes';
export var simpleValiation = {
    with: null,
    required: true,
    hidden: null,
    label: null,
    hint: null,
    placeholder: null,
    minDate: null,
    maxDate: null,
    maxLength: null,
    regExp: null,
    options: null
};
export var mainFields = [
    {
        name: 'lastName',
        type: TravellerFieldEnum.LastName,
        input: InputType.Text,
        placeholder: 'Last Name',
        validations: [simpleValiation]
    },
    {
        name: 'firstName',
        type: TravellerFieldEnum.FirstName,
        input: InputType.Text,
        placeholder: 'First Name',
        validations: [simpleValiation]
    },
    {
        name: 'birthday',
        type: TravellerFieldEnum.BirthDate,
        input: InputType.Date,
        placeholder: 'Birthday',
        validations: [simpleValiation]
    },
    {
        name: 'sex',
        type: TravellerFieldEnum.Gender,
        input: InputType.Switch,
        placeholder: 'Sex',
        validations: [
            __assign(__assign({}, simpleValiation), { options: [
                    {
                        value: 'M',
                        label: 'Male'
                    },
                    {
                        value: 'F',
                        label: 'Female'
                    }
                ] })
        ]
    },
    {
        name: 'phone',
        type: TravellerFieldEnum.Phone,
        input: InputType.Phone,
        placeholder: 'Phone number',
        validations: []
    }
];
