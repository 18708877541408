import React from 'react';
import { useTheme } from '../../../theme';
import { LockIcon } from '../../Icons';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { initI18n } from '../../../utils';
import { Choosed } from '../../../Icons';
initI18n('FareLock');
var DescriptionBlock = function (_a) {
    var _b;
    var className = _a.className, title = _a.title, description = _a.description, showConfirmedIcon = _a.showConfirmedIcon, freezeUntil = _a.freezeUntil;
    var css = useTheme('FareLock').DescriptionBlock;
    var t = useTranslation('FareLock').t;
    return (React.createElement("div", { className: cn(css.root, className) },
        React.createElement("div", { className: css.icon }, LockIcon),
        React.createElement("div", { className: css.title },
            showConfirmedIcon && React.createElement("span", { className: css.confirmedIcon }, Choosed), (_b = title()) !== null && _b !== void 0 ? _b : t('Fixation the cost of the air ticket')),
        React.createElement("div", { className: css.description }, description !== null && description !== void 0 ? description : t('No need to worry about the price increase or about the fact that all tickets will be sold out, now you can fix the ticket price for {{hours}} {{plural}}', {
            hours: freezeUntil,
            plural: t('hours', { count: freezeUntil })
        }))));
};
export default DescriptionBlock;
