import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { planeIcon } from '../../../Icons';
import { format } from '../../../../utils';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import { useState } from 'react';
import SlideBottom from '../../../../SlideBottom/components/SlideBottom';
import MobileSegmentInfo from '../../../../MobileSegmentInfo';
var FlightSegment = function (_a) {
    var _b;
    var segment = _a.segment, fareFamilyTitle = _a.fareFamilyTitle, isReturningSegment = _a.isReturningSegment, travellersCount = _a.travellersCount, price = _a.price;
    var css = useTheme('MobileStepbar').StepContentStyles;
    var _c = __read(useState(false), 2), isOpenedModal = _c[0], setOpenedModal = _c[1];
    var toggleSegmentModal = function () {
        setOpenedModal(function (prevState) { return !prevState; });
    };
    return (React.createElement("div", { className: css.content__item },
        React.createElement(SlideBottom, { isOpen: isOpenedModal, onClose: toggleSegmentModal, scrollable: true },
            React.createElement(MobileSegmentInfo, { segments: [segment], showFullInfo: true })),
        React.createElement("div", { className: cn(css.item__row, css.segment__header, css.segment__header_flights) },
            React.createElement("span", { className: cn(css.content__icon, (_b = {},
                    _b[css.content__icon_roundTrip] = isReturningSegment,
                    _b)) }, planeIcon),
            React.createElement("span", { className: css.header__text, onClick: toggleSegmentModal },
                segment.segment.departure.airport.city.name,
                " (",
                segment.segment.departure.airport.iata,
                ") -",
                ' ',
                segment.segment.arrival.airport.city.name,
                " (",
                segment.segment.arrival.airport.iata,
                ")")),
        React.createElement("div", { className: cn(css.item__row, css.segment__dates) },
            format(segment.segment.departure.date, 'dd MMM'),
            " \u2022 ",
            segment.segment.departure.time.substr(0, 5),
            ' ',
            "\u2014 ",
            segment.segment.arrival.time.substr(0, 5)),
        React.createElement("div", { className: cn(css.item__row, css.segment__price) },
            React.createElement("span", null,
                travellersCount > 1 && "".concat(travellersCount, " x "),
                fareFamilyTitle),
            React.createElement("span", { className: css.price__money },
                React.createElement(Money, { money: price })))));
};
export default FlightSegment;
