import { __assign, __read } from "tslib";
import * as React from 'react';
import { datesByFlightNumberFactory, legsByDatesMapFactory, legsByMonthFactory } from '../../../services/adapters';
import Calendar from '../../ScheduleTypes/Calendar/Calendar';
import List from '../../ScheduleTypes/List/List';
import ScheduleModal from '../../ScheduleModal/ScheduleModal';
import { useMemo, useState } from 'react';
import { useSchedule } from '../../../hooks';
import { API_DATE_FORMAT, format } from '../../../../../utils';
import { addDays, parse } from 'date-fns';
import { convertSearchParameters } from '../../../../../Results/utils';
import SimpleLoader from '../../../../../SimpleLoader';
import { searchParametersToSegmentsAdapter } from '../../../../../SegmentTabs/utils';
var ModalContent = function (_a) {
    var searchParameters = _a.searchParameters, onStartSearch = _a.onStartSearch, children = _a.children, isOpen = _a.isOpen, setLoading = _a.setLoading, onClose = _a.onClose;
    var _b = __read(useState(0), 2), currentLeg = _b[0], setCurrentLeg = _b[1];
    var _c = __read(useState(null), 2), selectedDate = _c[0], setSelectedDate = _c[1];
    var _d = useSchedule(searchParameters, isOpen, selectedDate), schedule = _d.schedule, loading = _d.loading, segments = _d.segments;
    var legsByDates = useMemo(function () { return legsByDatesMapFactory(schedule, currentLeg); }, [schedule, loading, currentLeg]);
    var datesByFlightNumber = useMemo(function () { return datesByFlightNumberFactory(schedule, currentLeg); }, [
        schedule,
        loading,
        currentLeg
    ]);
    var legsByMonths = useMemo(function () { return legsByMonthFactory(schedule, currentLeg); }, [schedule, loading, currentLeg]);
    React.useEffect(function () {
        setLoading(loading);
    }, [loading]);
    var handleSelect = function (date) {
        var searchSegments = [
            __assign(__assign({}, searchParameters.segments[currentLeg]), { date: format(date, API_DATE_FORMAT) })
        ];
        var daysOffset = 1;
        searchParameters.segments.forEach(function (segment, index) {
            if (index !== currentLeg) {
                searchSegments.push(__assign(__assign({}, segment), { date: format(addDays(date, daysOffset), API_DATE_FORMAT) }));
                daysOffset++;
            }
        });
        var newSearchParams = __assign(__assign({}, searchParameters), { segments: searchSegments });
        onStartSearch(convertSearchParameters(newSearchParams));
    };
    var initialDateFromSegment = parse(searchParameters.segments[0].date, API_DATE_FORMAT, new Date());
    return (React.createElement(React.Fragment, null,
        loading && React.createElement(SimpleLoader, null),
        React.createElement(ScheduleModal, { segments: searchParametersToSegmentsAdapter(segments !== null && segments !== void 0 ? segments : searchParameters.segments), currentLeg: currentLeg, setCurrentLeg: setCurrentLeg, renderSchedule: function (type) {
                return schedule && (React.createElement(React.Fragment, null, type === 'calendar' ? (React.createElement(Calendar, { openDate: Boolean(selectedDate) ? new Date(selectedDate) : initialDateFromSegment, onSelect: handleSelect, currentLeg: currentLeg, legsByDates: legsByDates, datesByFlightNumber: datesByFlightNumber, schedule: schedule, setSelectedDate: setSelectedDate })) : (React.createElement(List, { onSelect: handleSelect, currentSearchSegments: searchParameters.segments, currentLeg: currentLeg, schedule: schedule, legsByMonths: legsByMonths, selectedDate: selectedDate || initialDateFromSegment, setSelectedDate: setSelectedDate }))));
            }, open: isOpen, onClose: onClose, slideUp: true })));
};
export default ModalContent;
