import { __assign } from "tslib";
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme } from '../../../../theme';
import { Field, useFormState } from 'react-final-form';
import { getPassengerName } from '../../../../utils';
import { TravellerFieldEnum } from '@websky/graphql';
import { ALL_PASSENGERS_FIELD, PASSENGER_FIELD_PREFIX } from '../types';
import { AllItemsCheckbox } from './AllItemsCheckbox';
import { useTranslation } from 'react-i18next';
export var PassengersCheckboxes = function (_a) {
    var travellers = _a.travellers, isDeletingMode = _a.isDeletingMode;
    var theme = useTheme('Ssr').SsrModal;
    var t = useTranslation().t;
    var values = useFormState().values;
    var isNeedRenderAllTravellers = (!isDeletingMode && travellers.length > 1) || values[ALL_PASSENGERS_FIELD];
    var isNeedRenderTravellers = (!isDeletingMode && !values[ALL_PASSENGERS_FIELD]) ||
        (isDeletingMode && travellers.some(function (traveller) { return values["".concat(PASSENGER_FIELD_PREFIX).concat(traveller.id)]; }));
    return (React.createElement(React.Fragment, null,
        isNeedRenderAllTravellers && (React.createElement(AllItemsCheckbox, { name: ALL_PASSENGERS_FIELD, label: t('All passengers'), isDeletingMode: isDeletingMode })),
        isNeedRenderTravellers &&
            travellers.map(function (traveller) {
                var lastName = traveller.values.find(function (value) { return value.type === TravellerFieldEnum.LastName; }).value;
                var firstName = traveller.values.find(function (value) { return value.type === TravellerFieldEnum.FirstName; }).value;
                var travellerBirthDate = traveller.values.find(function (value) { return value.type === TravellerFieldEnum.BirthDate; }).value;
                var travellerType = traveller.supplierTravellerType;
                var label = (React.createElement("div", { className: theme.label },
                    ' ',
                    React.createElement("div", null, getPassengerName(firstName, lastName)),
                    React.createElement("div", null,
                        travellerType,
                        " ",
                        travellerBirthDate)));
                return (React.createElement("div", { key: traveller.id },
                    React.createElement(Field, { name: "".concat(PASSENGER_FIELD_PREFIX).concat(traveller.id), type: "checkbox", key: traveller.id }, function (_a) {
                        var input = _a.input;
                        if (isDeletingMode && !input.value) {
                            return null;
                        }
                        return (React.createElement(FormControlLabel, { className: theme.checkboxLabel, control: React.createElement(Checkbox, { disabled: isDeletingMode, color: "primary", classes: input.checked ? { checked: theme.checkboxLabel_checked } : null, checked: input.checked, inputProps: __assign({}, input) }), label: label }));
                    })));
            }),
        (isNeedRenderAllTravellers || isNeedRenderTravellers) && React.createElement("div", { className: theme.separationLine })));
};
