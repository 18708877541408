var _a;
import { createTheme } from '../utils';
import * as Modal from './components/Modal.css';
import * as CloseButton from './components/CloseButton/CloseButton.css';
export var defaultTheme = {
    Modal: Modal,
    CloseButton: CloseButton
};
export default defaultTheme;
export var useTheme = (_a = createTheme(defaultTheme), _a.useTheme), Provider = _a.Provider;
