import { get, set } from '../../cache';
export var useAuthToken = function () {
    var AUTH_TOKEN = 'AUTH_TOKEN_KEY';
    var token = get(AUTH_TOKEN);
    return {
        token: token,
        setToken: function (token) {
            set(AUTH_TOKEN, token);
        }
    };
};
