import { MAX_PASSENGERS_COUNT } from '../SearchForm/store/passengers/reducers';
import { PassengerType } from '../enums';
import { SPECIAL_CATEGORIES } from '../SearchForm/utils';
export var getPassengerChangeability = function (passenger, passengersConfig, totalCount) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    var canIncrease = true;
    var canDecrease = true;
    if (totalCount >= MAX_PASSENGERS_COUNT) {
        canIncrease = false;
    }
    if (totalCount <= 0 || passenger.count <= 0) {
        canDecrease = false;
    }
    var isSpecialCategoriesExists = SPECIAL_CATEGORIES.some(function (type) { return passengersConfig.hasOwnProperty(type); });
    var adultPassengersCount = ((_a = passengersConfig.ADT) === null || _a === void 0 ? void 0 : _a.count) +
        (((_b = passengersConfig[PassengerType.YoungAdult]) === null || _b === void 0 ? void 0 : _b.count) || 0) +
        (((_c = passengersConfig[PassengerType.RetireeM]) === null || _c === void 0 ? void 0 : _c.count) || 0) +
        (((_d = passengersConfig[PassengerType.RetireeF]) === null || _d === void 0 ? void 0 : _d.count) || 0);
    switch (passenger.type) {
        case PassengerType.Adult:
            if (passenger.count <= ((_e = passengersConfig.INF) === null || _e === void 0 ? void 0 : _e.count) ||
                passenger.count <= ((_f = passengersConfig.INS) === null || _f === void 0 ? void 0 : _f.count) ||
                (passenger.count <= 1 && !isSpecialCategoriesExists)) {
                canDecrease = false;
            }
            if (((_g = passengersConfig[PassengerType.RetireeF]) === null || _g === void 0 ? void 0 : _g.count) >= 1 ||
                ((_h = passengersConfig[PassengerType.YoungAdult]) === null || _h === void 0 ? void 0 : _h.count) >= 1 ||
                ((_j = passengersConfig[PassengerType.RetireeM]) === null || _j === void 0 ? void 0 : _j.count) >= 1) {
                canIncrease = false;
                canDecrease = false;
            }
            break;
        case PassengerType.InfantWithSeat:
        case PassengerType.Child:
            if (((_k = passengersConfig.ADT) === null || _k === void 0 ? void 0 : _k.count) <= 0 &&
                ((_l = passengersConfig[PassengerType.RetireeM]) === null || _l === void 0 ? void 0 : _l.count) <= 0 &&
                ((_m = passengersConfig[PassengerType.RetireeF]) === null || _m === void 0 ? void 0 : _m.count) <= 0 &&
                ((_o = passengersConfig[PassengerType.YoungAdult]) === null || _o === void 0 ? void 0 : _o.count) <= 0) {
                canIncrease = false;
            }
            break;
        case PassengerType.Infant:
            if (passenger.count >= adultPassengersCount) {
                canIncrease = false;
            }
            break;
        case PassengerType.RetireeF:
            if (((_p = passengersConfig[PassengerType.YoungAdult]) === null || _p === void 0 ? void 0 : _p.count) >= 1 || ((_q = passengersConfig.ADT) === null || _q === void 0 ? void 0 : _q.count) >= 1) {
                canDecrease = false;
                canIncrease = false;
            }
            break;
        case PassengerType.RetireeM:
            if (((_r = passengersConfig[PassengerType.YoungAdult]) === null || _r === void 0 ? void 0 : _r.count) >= 1 || ((_s = passengersConfig.ADT) === null || _s === void 0 ? void 0 : _s.count) >= 1) {
                canDecrease = false;
                canIncrease = false;
            }
            break;
        case PassengerType.YoungAdult:
            if (((_t = passengersConfig[PassengerType.RetireeF]) === null || _t === void 0 ? void 0 : _t.count) >= 1 ||
                ((_u = passengersConfig[PassengerType.RetireeM]) === null || _u === void 0 ? void 0 : _u.count) >= 1 ||
                ((_v = passengersConfig.ADT) === null || _v === void 0 ? void 0 : _v.count) >= 1) {
                canDecrease = false;
                canIncrease = false;
            }
            break;
    }
    return {
        canBeDecreased: canDecrease,
        canBeIncreased: canIncrease
    };
};
