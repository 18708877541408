import { createSelector } from 'reselect';
import i18n from 'i18next';
var getPassengersState = function (state) { return state.passengers; };
/**
 * Create an array of passengers info for displaying in the dropdown menu.
 */
export var getPassengers = createSelector(getPassengersState, function (passengers) {
    var result = [];
    for (var type in passengers) {
        if (passengers.hasOwnProperty(type)) {
            result.push({
                type: type,
                count: passengers[type].count,
                age: passengers[type].age
            });
        }
    }
    return result;
});
/**
 * Count a total number of the selected passengers.
 */
export var getTotalPassengersCount = createSelector(getPassengers, function (passengers) {
    return passengers.reduce(function (total, passenger) { return total + passenger.count; }, 0);
});
/**
 * Create a title for displaying total passengers number.
 */
export var getPassengersTitle = createSelector(getTotalPassengersCount, function (totalCount) {
    return "".concat(totalCount, " ").concat(i18n.t('SearchForm:passenger', {
        count: totalCount
    }));
});
