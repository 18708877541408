import { __assign } from "tslib";
import * as React from 'react';
import FlightSelect from '../../../FlightSelect/components/FlightSelect';
import { calculateTotalTaxes } from '../../../FlightSelect/components/FareSelect/utils';
var SearchFlights = function (props) {
    return (React.createElement(FlightSelect, { flight: __assign(__assign({}, props.flight), { pricesForFareGroups: props.flight.pricesForFareGroups.map(function (priceForFareGroup) {
                var newPrice = __assign({}, priceForFareGroup);
                newPrice.prices.map(function (priceGroup, index) {
                    var passengerFaresTotalPrice = calculateTotalTaxes(priceGroup.flight.fares[0].passengerFares).get('Total');
                    priceGroup.price = __assign({}, passengerFaresTotalPrice);
                    priceGroup.originalPrice = __assign({}, passengerFaresTotalPrice);
                });
                return newPrice;
            }) }), selectedFares: props.selectedFares, onSelect: props.onSelect, isResultsPage: true, hidePrice: true }));
};
export default SearchFlights;
