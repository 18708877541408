import React, { useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { useToggleable } from '../../../../hooks';
import Button from '../../../../Button';
import Modal from '../../../../Modal';
import { CloseClear } from '../../../../Icons';
var UnsavedModal = function (_a) {
    var _b;
    var className = _a.className, renderButton = _a.renderButton, onContinue = _a.onContinue, hasUnsaved = _a.hasUnsaved;
    var css = useTheme('Terminal').UnsavedModal;
    var t = useTranslation('Terminal').t;
    var _c = useToggleable(false), isOpen = _c.isOpen, open = _c.open, close = _c.close;
    var onClick = function () {
        if (hasUnsaved) {
            open();
        }
        else {
            onContinue === null || onContinue === void 0 ? void 0 : onContinue();
        }
    };
    var renderCloseButton = useCallback(function (onClick) { return (React.createElement(Button, { onClick: onClick, className: cn(css.close, css.modal) }, CloseClear)); }, []);
    return (React.createElement(React.Fragment, null, (_b = renderButton === null || renderButton === void 0 ? void 0 : renderButton(onClick)) !== null && _b !== void 0 ? _b : (React.createElement(Button, { className: css.close, onClick: onClick }, CloseClear)),
        React.createElement(Modal, { maxWidth: "xs", open: isOpen, onClose: close, closeButtonRenderer: renderCloseButton },
            React.createElement("div", { className: cn(className, css.container) },
                React.createElement("div", { className: css.title }, t('Unsaved changes')),
                React.createElement("span", { className: css.text }, t('You have unsaved changes, save or discard them to continue working correctly'))))));
};
export default UnsavedModal;
