import { __assign } from "tslib";
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { parse } from 'date-fns';
import { useTheme } from '../../../../theme';
import { API_DATE_FORMAT, format } from '../../../../utils';
import { Field, useFormState } from 'react-final-form';
import { ALL_FLIGHTS_FIELD, SEGMENT_FIELD_PREFIX } from '../types';
import { useTranslation } from 'react-i18next';
import { AllItemsCheckbox } from './AllItemsCheckbox';
export var FlightsCheckboxes = function (_a) {
    var segments = _a.segments, isDeletingMode = _a.isDeletingMode;
    var theme = useTheme('Ssr').SsrModal;
    var t = useTranslation().t;
    var values = useFormState().values;
    var isNeedRenderAllSegments = (!isDeletingMode && segments.length > 1) || values[ALL_FLIGHTS_FIELD];
    var isNeedRenderSegments = (!isDeletingMode && !values[ALL_FLIGHTS_FIELD]) ||
        (isDeletingMode && segments.some(function (segment) { return values["".concat(SEGMENT_FIELD_PREFIX).concat(segment.segment.id)]; }));
    return (React.createElement(React.Fragment, null,
        isNeedRenderAllSegments && (React.createElement(AllItemsCheckbox, { name: ALL_FLIGHTS_FIELD, label: t('All flights'), isDeletingMode: isDeletingMode })),
        isNeedRenderSegments &&
            segments.map(function (segment) {
                var departureDate = format(parse(segment.segment.departure.date, API_DATE_FORMAT, new Date()), 'd LLL');
                var departureIata = segment.segment.departure.airport.iata;
                var arrivalIata = segment.segment.arrival.airport.iata;
                var label = "".concat(departureDate, ", ").concat(departureIata, " \u2014 ").concat(arrivalIata);
                return (React.createElement("div", { key: segment.segment.id },
                    React.createElement(Field, { name: "".concat(SEGMENT_FIELD_PREFIX).concat(segment.segment.id), type: "checkbox", key: segment.segment.id }, function (_a) {
                        var input = _a.input;
                        if (isDeletingMode && !input.value) {
                            return null;
                        }
                        return (React.createElement(FormControlLabel, { className: theme.checkboxLabel, control: React.createElement(Checkbox, { disabled: isDeletingMode, color: "primary", classes: input.checked ? { checked: theme.checkboxLabel_checked } : null, checked: input.checked, inputProps: __assign({}, input) }), label: label }));
                    })));
            }),
        (isNeedRenderAllSegments || isNeedRenderSegments) && React.createElement("div", { className: theme.separationLine })));
};
