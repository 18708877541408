import { useCheckinStart } from '../../../Checkout/components/Checkout/hooks';
export var useOrderCardControls = function () {
    var _a = useCheckinStart(), isCheckinAvailable = _a.isCheckinAvailable, goToCheckin = _a.goToCheckin, checkinCreationInProgress = _a.checkinCreationInProgress, checkinRules = _a.checkinRules, isRulesModalOpen = _a.isRulesModalOpen, closeCheckinRulesModal = _a.closeCheckinRulesModal, acceptRules = _a.acceptRules;
    return {
        loading: checkinCreationInProgress,
        goToCheckin: goToCheckin,
        checkinRules: isCheckinAvailable ? checkinRules : null,
        isRulesModalOpen: isRulesModalOpen,
        closeCheckinRulesModal: closeCheckinRulesModal,
        acceptRules: acceptRules
    };
};
