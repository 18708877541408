import * as React from 'react';
import { useCallback } from 'react';
import TabButton from '../../Button/components/TabButton/TabButton';
import { useTheme } from '../../theme';
var ResultsSegments = function (props) {
    var legs = props.legs;
    var theme = useTheme('ResultsSegments').ResultsSegments;
    var handleClick = useCallback(function (leg) {
        if (legs.active > leg.id) {
            legs.setCurrentLeg(leg.id);
        }
    }, [legs.active, legs.setCurrentLeg]);
    return (React.createElement("div", null, legs.legs.map(function (leg) { return (React.createElement(TabButton, { onClick: function () { return handleClick(leg); }, inverted: true, disabled: legs.active < leg.id, active: legs.active === leg.id, className: theme.tabButton },
        leg.departure.iata,
        "-",
        leg.arrival.iata)); })));
};
export default ResultsSegments;
