import { genderTypes, isDigits } from '../helpers';
import { isValid, parse } from 'date-fns';
var LINEFEED = /\n/;
var LONG_MINUS_CHARS = /–/gm;
var WHITESPACE_GLOBAL = /\s/g;
export var validateGenderTypes = function (str) {
    if (Object.keys(genderTypes).every(function (gender) { return gender !== str; })) {
        return 'Incorrect gender.';
    }
    return '';
};
export var validateDocTypes = function (str) {
    if (str !== 'P') {
        return 'Incorrect document type.';
    }
    return '';
};
export var validateDocNumber = function (str) {
    if (!isDigits(str) && str.length !== 9 && str.length !== 10) {
        return 'Incorrect document type.';
    }
    return '';
};
export var validateDate = function (str, msg) {
    if (!isValid(parse(str.replace(WHITESPACE_GLOBAL, ''), 'ddMMMyy', new Date()))) {
        return msg;
    }
    return '';
};
// <КОД_КОММ> (КОД_СЕГМ) (Служ_код)-(ДОК)-(Код страны)-(Номер_док)-(Нац)-<ДАТ_РОЖД>-(ПОЛ)-(ДАТ_ИСТ)>-(Ф-И-О)-(Индикатор владельца паспорта)/(Ассоциация пассажира)
export var amadeusPassengersDataValidation = function (str) {
    var strWihoutLongMinus = str.replace(LONG_MINUS_CHARS, '-');
    var data = strWihoutLongMinus.split(LINEFEED) || [strWihoutLongMinus];
    var validations = data.map(function (command) {
        var commands = command.split('-');
        var docType = commands[1];
        var docNumber = commands[3];
        var birthDate = commands[5];
        var gender = commands[6];
        var expiryDate = commands[7];
        return [
            validateDate(birthDate, 'Incorrect birth date.'),
            validateDate(expiryDate, 'Incorrect document expiry date.'),
            validateGenderTypes(gender),
            validateDocTypes(docType),
            validateDocNumber(docNumber)
        ]
            .join(' ')
            .trim();
    });
    return validations.join(' ').trim();
};
