import * as Account from './components/Account/Account.css';
import * as DeleteAccountDialog from './components/DeleteAccountDialog/DeleteAccountDialog.css';
import * as InfoField from './components/InfoField/InfoField.css';
import * as SocialButton from './components/SocialButton/SocialButton.css';
export default {
    Account: Account,
    DeleteAccountDialog: DeleteAccountDialog,
    InfoField: InfoField,
    SocialButton: SocialButton
};
