import { __read } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
var SumCalculation = function (props) {
    var theme = useTheme('ManualActions').SumCalculation;
    var t = useTranslation('ManualActions').t;
    var renderCalculcations = useCallback(function () {
        return props.calculcations.map(function (calculcation) {
            return (React.createElement("div", { className: theme.calcTotal__list }, Object.entries(calculcation).map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return (React.createElement("div", { className: theme.calcTotal__item },
                    React.createElement("div", { className: theme.calcTotal__label },
                        t(key),
                        ":"),
                    React.createElement("div", { className: theme.calcTotal__value }, value)));
            })));
        });
    }, [props.calculcations]);
    return React.createElement("div", { className: theme.calcTotal }, renderCalculcations());
};
export default SumCalculation;
