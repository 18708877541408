import { __assign, __rest } from "tslib";
import * as React from 'react';
import Breadcrumbs from './Breadcrumbs';
import { useCallback } from 'react';
import { CloseClear } from '../../../Icons';
import { useTheme } from '../../../theme';
import { OverrideComponent } from '../../../renderProps';
var StepsBreadcrumbs = function (props) {
    var onPassengerClick = props.onPassengerClick, onRefundInfoClick = props.onRefundInfoClick, breadcrumbsOwn = __rest(props, ["onPassengerClick", "onRefundInfoClick"]);
    var css = useTheme('Refund').Breadcrumbs;
    var handlePassengersClick = useCallback(function () {
        if (props.onPassengerClick) {
            props.onPassengerClick();
        }
    }, [onPassengerClick]);
    var handleRefundInfoClick = useCallback(function () {
        if (props.onRefundInfoClick) {
            props.onRefundInfoClick();
        }
    }, [onRefundInfoClick]);
    var renderClickableArea = useCallback(function (type) {
        if (type === 'passenger' && onPassengerClick) {
            return (React.createElement("div", { className: css.close, onClick: handlePassengersClick }, CloseClear));
        }
        if (type === 'refundInfo' && onRefundInfoClick) {
            return (React.createElement("div", { className: css.close, onClick: handleRefundInfoClick }, CloseClear));
        }
        return null;
    }, [props]);
    return (React.createElement(OverrideComponent, { componentProps: __assign({ renderAfter: renderClickableArea }, breadcrumbsOwn), component: { RefundDesktopBreadcrumbs: Breadcrumbs } }));
};
export default StepsBreadcrumbs;
