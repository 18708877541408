import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { Money } from '../../../index';
import { FlightClass } from '../../../__generated__/graphql';
import { useTheme } from '../../../theme';
import { useCallback } from 'react';
import { calculateTotalTaxes } from './utils';
import { useTranslation } from 'react-i18next';
import FareInfo from './Components/FareInfo/FareInfo';
import { useIsTerminal } from '../../../hooks';
import { FareFeaturePaymentType } from '@websky/graphql';
import { fareOptionsIcons } from './Icons';
var FLIGHT_CLASS_CODE_MAP = new Map([
    [FlightClass.Economy, 'Y'],
    [FlightClass.Business, 'C'],
    [FlightClass.First, 'F']
]);
var FareSelect = function (props) {
    var _a;
    var t = useTranslation('FlightSelect').t;
    var fares = props.fares, onSelect = props.onSelect, onOpenModal = props.onOpenModal, flightClass = props.flightClass, isSelected = props.isSelected, hidePrice = props.hidePrice;
    var css = useTheme('FlightSelect').FareSelect;
    var isTerminal = useIsTerminal();
    var handleSelect = useCallback(function (fare) {
        if (!isSelected) {
            var select = onSelect(fare);
            select();
        }
    }, [fares, onSelect]);
    var renderPriceDetails = function (passengerFares, fareOptions) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.fareBasis },
                t('Fare basis'),
                " ",
                passengerFares[0].priceClasses[0].fareCode),
            React.createElement("div", { className: css.fareOptions }, fareOptions
                .filter(function (option) { return option.availability === FareFeaturePaymentType.Free; })
                .map(function (option) { return (React.createElement("span", { key: option.type }, fareOptionsIcons[option.type])); }))));
    };
    var renderTooltipContent = useCallback(function (fare) {
        var _a, _b, _c;
        var price = fare.prices[0].price.amount;
        var passengerFares = ((_b = (_a = fare.prices[0]) === null || _a === void 0 ? void 0 : _a.flight.fares.find(function (fare) {
            return fare.passengerFares.some(function (passengerFare) { return passengerFare.totalFare.amount === price; });
        })) === null || _b === void 0 ? void 0 : _b.passengerFares) || ((_c = fare.prices[0]) === null || _c === void 0 ? void 0 : _c.flight.fares[0].passengerFares);
        var prices = calculateTotalTaxes(passengerFares);
        return (React.createElement(React.Fragment, null,
            isTerminal && renderPriceDetails(passengerFares, fare.fareFamily.options),
            __spreadArray([], __read(prices.entries()), false).map(function (_a) {
                var _b;
                var _c = __read(_a, 2), key = _c[0], price = _c[1];
                return (React.createElement("div", { key: key },
                    React.createElement("div", { className: cn(css.fee, (_b = {}, _b[css.active] = key === 'Total', _b)) },
                        !(key === 'Total' || key === 'Tariff') && t('Fee'),
                        " ",
                        key,
                        " ",
                        React.createElement(Money, { money: price }))));
            })));
    }, [fares]);
    return (React.createElement("div", { onClick: onOpenModal, className: cn(css.wrapper, (_a = {},
            _a[css.selected] = isSelected,
            _a)) },
        React.createElement("span", { className: css.code }, FLIGHT_CLASS_CODE_MAP.get(flightClass)),
        fares
            .sort(function (a, b) { var _a, _b; return ((_a = a.prices[0]) === null || _a === void 0 ? void 0 : _a.price.amount) - ((_b = b.prices[0]) === null || _b === void 0 ? void 0 : _b.price.amount); })
            .map(function (fare, index) { return (React.createElement(FareInfo, { key: fare.fareFamily.title, fare: fare, isSelected: isSelected, handleSelect: handleSelect, renderTooltipContent: renderTooltipContent, fareIndex: index, hidePrice: hidePrice })); })));
};
export default FareSelect;
