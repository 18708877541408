import { validate } from '../Passenger/utils';
import { TravellerFields } from '../TravellerForm/types';
import i18n from 'i18next';
import { getPassengerName } from '../utils';
export var PASSENGER_TYPES = {
    ADT: 'Adult',
    CLD: 'Child',
    INF: 'Infant',
    INS: 'Infant with seat'
};
export var requiredFillData = function (values, passengerValues) {
    return passengerValues.some(function (field) {
        return !!validate(values[field.name], values, field.input, field.validations);
    });
};
export var getPassengerLabel = function (passengerValues, passengerType) {
    var firstName = passengerValues.hasOwnProperty(TravellerFields.FirstName)
        ? passengerValues[TravellerFields.FirstName]
        : null, lastName = passengerValues.hasOwnProperty(TravellerFields.LastName)
        ? passengerValues[TravellerFields.LastName]
        : null;
    if (firstName && lastName) {
        return "".concat(getPassengerName(firstName, lastName), ", ").concat(passengerType);
    }
    return "".concat(i18n.t('Passenger:Passenger'), ", ").concat(i18n.t("Passenger:".concat(passengerType)));
};
export var getPassengerDataLabel = function (passengerValues, fieldsMap) {
    var _a, _b, _c, _d, _e;
    var labels = {};
    if (passengerValues.hasOwnProperty(TravellerFields.Gender)) {
        labels[TravellerFields.Gender] =
            passengerValues[TravellerFields.Gender] === 'M' ? i18n.t('Passenger:Male') : i18n.t('Passenger:Female');
    }
    labels[TravellerFields.BirthDate] = passengerValues[TravellerFields.BirthDate];
    if (passengerValues.hasOwnProperty(TravellerFields.Nationality)) {
        labels[TravellerFields.Nationality] = (_c = (_b = (_a = fieldsMap[TravellerFields.Nationality].validations) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.options.find(function (option) { return option.value === passengerValues[TravellerFields.Nationality]; })) === null || _c === void 0 ? void 0 : _c.label;
    }
    if (passengerValues.hasOwnProperty('docType')) {
        var docType = (_e = (_d = fieldsMap['docType'].validations
            .find(function (validation) {
            return validation.with === null || validation.with.some(function (cond) { return passengerValues[cond.name] === cond.value; });
        })) === null || _d === void 0 ? void 0 : _d.options.find(function (option) { return option.value === passengerValues['docType']; })) === null || _e === void 0 ? void 0 : _e.value;
        labels['docType'] = i18n.t("Passenger:".concat(docType));
        if (labels['docType'] && passengerValues.hasOwnProperty('docNumber')) {
            labels['docNumber'] = passengerValues['docNumber'];
        }
    }
    if (passengerValues.hasOwnProperty('docExpiryDate')) {
        labels['docExpiryDate'] = passengerValues['docExpiryDate'];
    }
    return labels;
};
