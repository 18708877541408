import { __assign, __read, __spreadArray } from "tslib";
import { PriceBreakdownType } from './store/priceBreakdown/action';
import { OrderStatus, TravellerFieldEnum } from '../__generated__/globalTypes';
import { StepType } from './types';
import { getAvailableServices, getPaymentIsRequired } from './store/order/selectors';
import { Currency } from '../enums';
import { clearPhoneNumber } from '../LoginPage/utils';
import { seatMapHasAnySeats } from '../SeatMap/utils';
import { getRelatedSegments } from '../Modules/Checkin/utils';
import { capitalizeFirstLetter, removeTrailingSlash, setsAreEqual } from '../utils';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
export var parsePriceBreakdown = function (priceBreakdown) {
    var price = {
        totalPrice: {
            amount: 0,
            currency: Currency.USD
        },
        servicePrice: {
            amount: 0,
            currency: Currency.USD
        },
        upsalePrice: {
            amount: 0,
            currency: Currency.USD
        }
    };
    price.totalPrice = priceBreakdown.price;
    priceBreakdown.parts.forEach(function (part) {
        if (part.type === PriceBreakdownType.Service) {
            price.servicePrice.amount += part.price.amount;
            price.servicePrice.currency += part.price.currency;
            part.parts.forEach(function (part) {
                if (part.type === PriceBreakdownType.Upsale) {
                    price.upsalePrice.amount += part.price.amount;
                    price.upsalePrice.currency += part.price.currency;
                }
            });
        }
    });
    return price;
};
export var getGdsServiceMinPrice = function (order, gdsService, baggageType) {
    var _a, _b, _c, _d;
    var currency = (_c = (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(function (service) { var _a; return !!((_a = service === null || service === void 0 ? void 0 : service.price) === null || _a === void 0 ? void 0 : _a.currency); })) === null || _c === void 0 ? void 0 : _c.price.currency;
    return {
        amount: Math.min.apply(Math, __spreadArray([], __read((_d = order.additionalServices.gdsServices) === null || _d === void 0 ? void 0 : _d.services.filter(function (service) {
            if (baggageType) {
                return service.type === gdsService && service.baggageType === baggageType;
            }
            else {
                return service.type === gdsService;
            }
        }).map(function (service) { return (service === null || service === void 0 ? void 0 : service.price.amount) * service.allowedSegments[0].length; })), false)),
        currency: currency
    };
};
export var removeServicesStepsByTypes = function (steps, types) {
    return steps.filter(function (step) {
        return types.find(function (type) { return step.type !== type; });
    });
};
export var isStepAvailable = function (checkoutState, stepType) {
    var _a, _b, _c, _d, _e, _f, _g;
    var availableGdsServices = getAvailableServices(checkoutState);
    var segmentsIds = (_b = (_a = checkoutState.order.flight) === null || _a === void 0 ? void 0 : _a.segments) === null || _b === void 0 ? void 0 : _b.map(function (segment) { var _a; return (_a = segment === null || segment === void 0 ? void 0 : segment.segment) === null || _a === void 0 ? void 0 : _a.id; });
    var mealServices = (_f = (_e = (_d = (_c = checkoutState.order.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.filter(function (service) { return (service === null || service === void 0 ? void 0 : service.type) === OrderAdditionalServiceGdsServiceServiceType.Meal; })) !== null && _f !== void 0 ? _f : [];
    var hasAvailableMealServices = mealServices.some(function (meal) { var _a; return (_a = meal === null || meal === void 0 ? void 0 : meal.allowedSegments) === null || _a === void 0 ? void 0 : _a.some(function (groups) { return groups === null || groups === void 0 ? void 0 : groups.some(function (segment) { return segmentsIds.includes(segment); }); }); });
    switch (stepType) {
        case StepType.Seats:
            return ((seatMapHasAnySeats(checkoutState.order.flight.seatMap) &&
                availableGdsServices.hasOwnProperty(OrderAdditionalServiceGdsServiceServiceType.Seat)) ||
                ((_g = checkoutState.order.flight.seatMap.segments) === null || _g === void 0 ? void 0 : _g.some(function (segment) { return segment.isSeatsAvailableInCheckinOnly; })));
        case StepType.Meals:
            return (availableGdsServices.hasOwnProperty(OrderAdditionalServiceGdsServiceServiceType.Meal) &&
                hasAvailableMealServices);
        case StepType.Baggage:
            return availableGdsServices.hasOwnProperty(OrderAdditionalServiceGdsServiceServiceType.Baggage);
        case StepType.Payment:
            return getPaymentIsRequired(checkoutState);
    }
    var step = checkoutState.checkout.steps.find(function (step) { return step.type === stepType; });
    if (step.conditions) {
        if (step.conditions.bookedOnly && checkoutState.order.status !== OrderStatus.Booked) {
            return false;
        }
    }
    return true;
};
export var getNextStep = function (steps, currentIndex, checkoutState) {
    var stepsLength = steps.length;
    var nextIndex = currentIndex;
    while (nextIndex < stepsLength - 1) {
        if (isStepAvailable(checkoutState, steps[nextIndex + 1].type)) {
            return nextIndex + 1;
        }
        nextIndex += 1;
    }
    return currentIndex;
};
export var getPrevStep = function (steps, currentIndex, checkoutState) {
    var prevIndex = currentIndex;
    while (prevIndex > 0) {
        if (isStepAvailable(checkoutState, steps[prevIndex - 1].type)) {
            return prevIndex - 1;
        }
        prevIndex -= 1;
    }
    return currentIndex;
};
export var prepareBookingRequest = function (orderId, formValues) {
    return {
        params: {
            id: orderId,
            customer: {
                values: [
                    {
                        name: 'email',
                        value: formValues['customer'][0]['email']
                    },
                    {
                        name: 'phone',
                        value: clearPhoneNumber(formValues['customer'][0]['phone'])
                    }
                ]
            },
            travellers: formValues['passengers'].map(function (passenger, index) {
                var values = [];
                for (var fieldName in passenger) {
                    if (passenger.hasOwnProperty(fieldName)) {
                        if (fieldName !== 'type') {
                            values.push({
                                name: fieldName,
                                value: fieldName === 'phone'
                                    ? clearPhoneNumber(passenger[fieldName])
                                    : passenger[fieldName]
                            });
                        }
                    }
                }
                return {
                    id: index.toString(),
                    values: values
                };
            })
        }
    };
};
export var checkInternationalFlight = function (flight) {
    return flight.segments.some(function (segment) { return segment.segment.arrival.airport.country.id !== segment.segment.departure.airport.country.id; });
};
export var getServicesForBaggageIncrease = function (selectedService, baggageServices, passengersState) {
    var createNewServices = true;
    var baggage = selectedService.baggage, segmentId = selectedService.segmentId, passengerId = selectedService.passengerId, forAllSegments = selectedService.forAllSegments;
    var services = [];
    var baggageMap = new Map(baggageServices.map(function (baggage) { return [baggage.id, true]; }));
    var relatedSegments = getRelatedSegments(baggage.segmentsId, forAllSegments ? null : segmentId);
    passengersState[parseInt(passengerId)].services.forEach(function (selectedService) {
        if (baggageMap.has(selectedService.serviceId)) {
            if (selectedService.serviceId === baggage.id &&
                (forAllSegments || setsAreEqual(relatedSegments, new Set(selectedService.segmentIds)))) {
                // Do not create new services, cuz we already have one.
                createNewServices = false;
                services.push(__assign(__assign({}, selectedService), { count: selectedService.count + 1 }));
            }
            else {
                services.push(selectedService);
            }
        }
    });
    if (createNewServices) {
        services.push({
            serviceId: baggage.id,
            count: 1,
            segmentIds: __spreadArray([], __read(relatedSegments), false)
        });
    }
    return [
        {
            passengerId: passengerId,
            services: services
        }
    ];
};
export var getServicesForBaggageDecrease = function (selectedService, baggageServices, passengersState) {
    var baggage = selectedService.baggage, segmentId = selectedService.segmentId, passengerId = selectedService.passengerId, forAllSegments = selectedService.forAllSegments;
    var services = [];
    var relatedSegments = getRelatedSegments(baggage.segmentsId, forAllSegments ? null : segmentId);
    passengersState[parseInt(passengerId)].services.forEach(function (selectedService) {
        if (selectedService.serviceId === baggage.id &&
            (forAllSegments || setsAreEqual(relatedSegments, new Set(selectedService.segmentIds)))) {
            services.push(__assign(__assign({}, selectedService), { count: selectedService.count > 0 ? selectedService.count - 1 : 0 }));
        }
        else if (selectedService.serviceId !== baggage.id ||
            (selectedService.segmentIds[0] !== segmentId && !forAllSegments)) {
            services.push(selectedService);
        }
    });
    return [
        {
            passengerId: passengerId,
            services: services
        }
    ];
};
export var getServicesForBaggageReplace = function (oldBaggage, newBaggage, segmentId, passengerId, forAllSegments, baggageServices, passengersState) {
    var services = [];
    var relatedSegments = getRelatedSegments(newBaggage.segmentsId, forAllSegments ? null : segmentId);
    // Remove old baggage.
    passengersState[parseInt(passengerId)].services.forEach(function (service) {
        if (service.serviceId === oldBaggage.id &&
            (forAllSegments || setsAreEqual(relatedSegments, new Set(service.segmentIds)))) {
            services.push(__assign(__assign({}, service), { count: 0 }));
        }
        else {
            services.push(service);
        }
    });
    // Add new baggage for given segments.
    services.push({
        serviceId: newBaggage.id,
        count: 1,
        segmentIds: __spreadArray([], __read(relatedSegments), false)
    });
    return [
        {
            passengerId: passengerId,
            services: services
        }
    ];
};
export var getServicesForBaggageClear = function (passengerId, baggageServices, passengersState) {
    var newServices = [];
    var baggageServicesIds = baggageServices.map(function (service) { return service.id; });
    passengersState[parseInt(passengerId)].services.forEach(function (service) {
        if (baggageServicesIds.includes(service.serviceId)) {
            newServices.push(__assign(__assign({}, service), { count: 0 }));
        }
        else {
            newServices.push(__assign({}, service));
        }
    });
    return [
        {
            passengerId: passengerId,
            services: newServices
        }
    ];
};
export var getServicesForBaggageModeChange = function (passengerId, segments, baggageServices, passengersState) {
    var services = {};
    passengersState[parseInt(passengerId)].services.forEach(function (service) {
        if (!services[service.serviceId] || services[service.serviceId] < service.count) {
            services[service.serviceId] = service.count;
        }
    });
    var newServices = [];
    var _loop_1 = function (service) {
        if (services.hasOwnProperty(service)) {
            segments.forEach(function (_a) {
                var segmentId = _a.segmentId;
                newServices.push({
                    serviceId: service,
                    count: services[service],
                    segmentIds: [segmentId]
                });
            });
        }
    };
    for (var service in services) {
        _loop_1(service);
    }
    return [{ passengerId: passengerId, services: newServices }];
};
export var getBaggageCallbacks = function (runServiceRequest, baggageServices, passengers, segments) {
    var baggageRequestAdapter = function (passengers) {
        return runServiceRequest(passengers.map(function (_a) {
            var passengerId = _a.passengerId, services = _a.services;
            return ({
                passengerId: passengerId,
                setServices: services
            });
        }));
    };
    return {
        onIncrease: function (baggage, segmentId, passengerId, forAllSegments) {
            return baggageRequestAdapter(getServicesForBaggageIncrease({ baggage: baggage, segmentId: segmentId, passengerId: passengerId, forAllSegments: forAllSegments }, baggageServices, passengers));
        },
        onDecrease: function (baggage, segmentId, passengerId, forAllSegments) {
            return baggageRequestAdapter(getServicesForBaggageDecrease({ baggage: baggage, segmentId: segmentId, passengerId: passengerId, forAllSegments: forAllSegments }, baggageServices, passengers));
        },
        onReplace: function (oldBaggage, newBaggage, segmentId, passengerId, forAllSegments) {
            return baggageRequestAdapter(getServicesForBaggageReplace(oldBaggage, newBaggage, segmentId, passengerId, forAllSegments, baggageServices, passengers));
        },
        onModeChange: function (passengerId) {
            return baggageRequestAdapter(getServicesForBaggageModeChange(passengerId, segments, baggageServices, passengers));
        }
    };
};
export var getBaggageReduxCallbacks = function (passengers, baggage, segments, addServiceAction, removeServiceAction, setServiceAction) {
    return {
        onIncrease: function (baggage, segmentId, passengerId, forAllSegments) {
            if (!forAllSegments) {
                addServiceAction({
                    segmentId: segmentId,
                    passengerId: passengerId,
                    serviceId: baggage.id,
                    service: {
                        id: baggage.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                    },
                    allowedSegments: baggage.segmentsId
                });
            }
            else {
                var relatedSegments = getRelatedSegments(baggage.segmentsId, forAllSegments ? null : segmentId);
                relatedSegments.forEach(function (segmentId) {
                    addServiceAction({
                        segmentId: segmentId,
                        passengerId: passengerId,
                        serviceId: baggage.id,
                        service: {
                            id: baggage.id,
                            type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                        },
                        allowedSegments: baggage.segmentsId
                    });
                });
            }
        },
        onDecrease: function (baggage, segmentId, passengerId, forAllSegments) {
            if (!forAllSegments) {
                removeServiceAction({
                    segmentId: segmentId,
                    passengerId: passengerId,
                    serviceId: baggage.id,
                    service: {
                        id: baggage.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                    },
                    allowedSegments: baggage.segmentsId
                });
            }
            else {
                var relatedSegments = getRelatedSegments(baggage.segmentsId, forAllSegments ? null : segmentId);
                relatedSegments.forEach(function (segmentId) {
                    removeServiceAction({
                        segmentId: segmentId,
                        passengerId: passengerId,
                        serviceId: baggage.id,
                        service: {
                            id: baggage.id,
                            type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                        },
                        allowedSegments: baggage.segmentsId
                    });
                });
            }
        },
        onReplace: function (oldBaggage, newBaggage, segmentId, passengerId, forAllSegments) {
            var relatedSegments = getRelatedSegments(oldBaggage.segmentsId, forAllSegments ? null : segmentId), newRelatedSegments = getRelatedSegments(newBaggage.segmentsId, forAllSegments ? null : segmentId);
            if (forAllSegments) {
                relatedSegments.forEach(function (segmentId) {
                    removeServiceAction({
                        serviceId: oldBaggage.id,
                        passengerId: passengerId,
                        segmentId: segmentId
                    });
                });
            }
            else {
                removeServiceAction({
                    serviceId: oldBaggage.id,
                    passengerId: passengerId,
                    segmentId: segmentId
                });
            }
            newRelatedSegments.forEach(function (segmentId) {
                setServiceAction({
                    serviceId: newBaggage.id,
                    passengerId: passengerId,
                    segmentId: segmentId,
                    service: {
                        id: newBaggage.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                    },
                    allowedSegments: newBaggage.segmentsId
                });
            });
        },
        onModeChange: function (passengerId) {
            var passenger = passengers.find(function (passenger) { return passenger.id === passengerId; });
            var baggageIds = baggage.map(function (baggage) { return baggage.id; });
            var services = {};
            passenger.services.forEach(function (service) {
                if (baggageIds.includes(service.serviceId) &&
                    (!services[service.serviceId] || services[service.serviceId] < service.count)) {
                    services[service.serviceId] = service.count;
                }
            });
            var _loop_2 = function (service) {
                if (services.hasOwnProperty(service)) {
                    var baggageService_1 = baggage.find(function (baggage) { return baggage.id === service; });
                    segments.forEach(function (segment) {
                        setServiceAction({
                            passengerId: passengerId,
                            service: {
                                id: service,
                                type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                            },
                            count: services[service],
                            serviceId: service,
                            segmentId: segment.segmentId,
                            allowedSegments: baggageService_1.segmentsId
                        });
                    });
                }
            };
            for (var service in services) {
                _loop_2(service);
            }
        },
        onClear: function (passengerId, type) {
            var passenger = passengers.find(function (passenger) { return passenger.id === passengerId; });
            var baggageIds = baggage
                .filter(function (service) { return (type ? service.type === type : true); })
                .map(function (service) { return service.id; });
            passenger.services.forEach(function (selectedBaggage) {
                selectedBaggage.segmentIds.forEach(function (segmentId) {
                    if (baggageIds.includes(selectedBaggage.serviceId)) {
                        setServiceAction({
                            serviceId: selectedBaggage.serviceId,
                            count: 0,
                            passengerId: passengerId,
                            segmentId: segmentId,
                            service: {
                                id: selectedBaggage.serviceId,
                                type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                            },
                            allowedSegments: baggage.find(function (baggage) { return baggage.id === selectedBaggage.serviceId; })
                                .segmentsId
                        });
                    }
                });
            });
        }
    };
};
export var getMealReduxCallbacks = function (addServiceAction, removeServiceAction, deleteAllTypesOnSegmentAction) {
    return {
        onMealAdd: function (meal, segmentId, passengerId) {
            addServiceAction({
                passengerId: passengerId,
                segmentId: segmentId,
                serviceId: meal.id,
                allowedSegments: meal.allowedSegments,
                service: {
                    id: meal.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Meal
                }
            });
        },
        onMealRemove: function (meal, segmentId, passengerId) {
            removeServiceAction({
                passengerId: passengerId,
                segmentId: segmentId,
                serviceId: meal.id,
                allowedSegments: meal.allowedSegments
            });
        },
        onClear: function (meal, segmentId) {
            deleteAllTypesOnSegmentAction({
                segmentId: segmentId,
                serviceId: meal.id
            });
        }
    };
};
export var getSeatReduxCallbacks = function (passengers, setServiceAction, removeServiceAction) {
    return {
        setSeatCallback: function (seat, passengerId, segmentId) {
            // Выбрал ли пользователь место на этот сегмент ранее
            var selectedSeat = passengers
                .find(function (passenger) { return passenger.id === passengerId; })
                .seats.find(function (seat) { return seat.segmentId === segmentId; });
            var passengerOccupiedThisSeatBefore = passengers
                .filter(function (passenger) { return passenger.id !== passengerId; })
                .find(function (passenger) {
                var _a;
                return (_a = passenger === null || passenger === void 0 ? void 0 : passenger.seats) === null || _a === void 0 ? void 0 : _a.find(function (passengerSeat) {
                    return (passengerSeat === null || passengerSeat === void 0 ? void 0 : passengerSeat.segmentId) === segmentId && (passengerSeat === null || passengerSeat === void 0 ? void 0 : passengerSeat.number) === (seat === null || seat === void 0 ? void 0 : seat.number);
                });
            });
            if (passengerOccupiedThisSeatBefore) {
                removeServiceAction({
                    segmentId: segmentId,
                    passengerId: passengerOccupiedThisSeatBefore.id,
                    serviceId: seat.number
                });
            }
            if (selectedSeat) {
                removeServiceAction({
                    segmentId: segmentId,
                    passengerId: passengerId,
                    serviceId: selectedSeat.number
                });
            }
            if (seat) {
                setTimeout(function () {
                    var _a;
                    // setService request should be always after removeService
                    setServiceAction({
                        segmentId: segmentId,
                        passengerId: passengerId,
                        serviceId: seat.number,
                        service: {
                            letter: seat.letter,
                            row: seat.row,
                            id: (_a = seat.service) === null || _a === void 0 ? void 0 : _a.id,
                            deckId: null,
                            type: OrderAdditionalServiceGdsServiceServiceType.Seat,
                            rfisc: seat.rfisc,
                            price: seat.price,
                            number: seat.number,
                            segmentId: segmentId
                        }
                    });
                }, 0);
            }
        },
        setClearSeatCallback: function (seat, passengerId, segmentId) {
            removeServiceAction({
                segmentId: segmentId,
                passengerId: passengerId,
                serviceId: seat.number
            });
        },
        onSegmentClear: function (segmentId) {
            passengers.forEach(function (passenger) {
                var selectedSeat = passenger.seats.find(function (seat) { return parseInt(seat.segmentId) === segmentId; });
                if (selectedSeat) {
                    removeServiceAction({
                        segmentId: segmentId.toString(),
                        passengerId: passenger.id,
                        serviceId: selectedSeat.number
                    });
                }
            });
        }
    };
};
export var onClearPassengerMeals = function (meal, segmentId, passengers, selectedMeals) {
    // Remove all meal products of given type for each passenger on given segment.
    var passengersServices = [];
    passengers.forEach(function (passenger) {
        var products = [];
        selectedMeals.forEach(function (selectedMeal) {
            if (selectedMeal.passengerId === passenger.id) {
                products.push({
                    serviceId: selectedMeal.mealId,
                    segmentIds: [selectedMeal.segmentId],
                    count: selectedMeal.mealId === meal.id && selectedMeal.segmentId === segmentId ? 0 : selectedMeal.count
                });
            }
        });
        if (products.length) {
            passengersServices.push({
                passengerId: passenger.id,
                setServices: products
            });
        }
    });
    return passengersServices;
};
export var onMealAdd = function (meal, segmentId, passengerId, selectedMeals) {
    var hasFound = false;
    var newServices = selectedMeals
        .filter(function (selectedMeal) { return selectedMeal.passengerId === passengerId; })
        .map(function (selectedMeal) {
        var count = selectedMeal.count, isSameService = selectedMeal.mealId === meal.id && selectedMeal.segmentId === segmentId;
        if (isSameService) {
            hasFound = true;
        }
        return {
            serviceId: selectedMeal.mealId,
            segmentIds: [selectedMeal.segmentId],
            count: isSameService ? count + 1 : count,
            confirmedCount: selectedMeal.confirmedCount
        };
    });
    if (!hasFound) {
        newServices.push({
            serviceId: meal.id,
            count: 1,
            segmentIds: [segmentId],
            confirmedCount: 0
        });
    }
    return [{ passengerId: passengerId, setServices: newServices }];
};
export var onMealRemove = function (meal, segmentId, passengerId, selectedMeals) {
    var newServices = selectedMeals
        .filter(function (selectedMeal) { return selectedMeal.passengerId === passengerId; })
        .map(function (selectedMeal) { return ({
        serviceId: selectedMeal.mealId,
        count: selectedMeal.mealId === meal.id && selectedMeal.segmentId === segmentId && selectedMeal.count > 0
            ? selectedMeal.count - 1
            : selectedMeal.count,
        segmentIds: [selectedMeal.segmentId],
        confirmedCount: selectedMeal.confirmedCount
    }); });
    return [{ passengerId: passengerId, setServices: newServices }];
};
export var getPassengerCheckinRedirectURL = function (checkInURL, order) {
    var lastName = order.travellers[0].values.find(function (_a) {
        var type = _a.type;
        return type === TravellerFieldEnum.LastName;
    }).value;
    var PNR = order.locator;
    return "".concat(removeTrailingSlash(checkInURL), "#/").concat(PNR, "/").concat(lastName);
};
export var getOrderCheckinRedirectURL = function (checkInURL, orderId) {
    return "".concat(removeTrailingSlash(checkInURL), "#/").concat(orderId);
};
export var checkoutSelectedServicesStateFactory = function (order) {
    var selectedServices = {};
    order.travellers.forEach(function (traveller) {
        var _a, _b;
        var passengerServices = {};
        if ((_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services.length) {
            traveller.services.gdsServices.services.forEach(function (service) {
                var _a;
                var passengerService = {
                    segments: service.segmentIds,
                    number: service.count,
                    servicePayload: {
                        id: service.serviceId,
                        type: (_a = order.additionalServices.gdsServices.services.find(function (additionalService) { return additionalService.id === service.serviceId; })) === null || _a === void 0 ? void 0 : _a.type,
                        confirmedCount: service.confirmedCount
                    }
                };
                if (passengerServices[service.serviceId]) {
                    passengerServices[service.serviceId].push(passengerService);
                }
                else {
                    passengerServices[service.serviceId] = [passengerService];
                }
            });
        }
        if ((_b = traveller.services.seats) === null || _b === void 0 ? void 0 : _b.length) {
            traveller.services.seats.forEach(function (service) {
                var _a;
                var seatService = {
                    segments: [service.segment.id],
                    servicePayload: {
                        id: (_a = service.seat.seatService) === null || _a === void 0 ? void 0 : _a.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Seat,
                        letter: service.seat.letter,
                        rfisc: service.seat.rfisc,
                        price: service.seat.price,
                        row: service.row.toString(),
                        segmentId: service.segment.id,
                        number: "".concat(service.row).concat(service.letter),
                        isConfirmed: service.isConfirmed
                    },
                    number: 1
                };
                if (!passengerServices["".concat(service.row).concat(service.letter)]) {
                    passengerServices["".concat(service.row).concat(service.letter)] = [seatService];
                }
                else {
                    passengerServices["".concat(service.row).concat(service.letter)].push(seatService);
                }
            });
        }
        selectedServices[traveller.id] = passengerServices;
    });
    return selectedServices;
};
export var capitalizeTravellerName = function (values) {
    var valueTypesToCapitalize = [
        TravellerFieldEnum.FirstName,
        TravellerFieldEnum.LastName,
        TravellerFieldEnum.MiddleName
    ];
    return values.map(function (value) {
        var _a;
        if (valueTypesToCapitalize.includes(value.type)) {
            return __assign(__assign({}, value), { value: value.value ? capitalizeFirstLetter(value.value.toLowerCase()) : value.value });
        }
        if (value.type === TravellerFieldEnum.Email) {
            return __assign(__assign({}, value), { value: (_a = value.value) === null || _a === void 0 ? void 0 : _a.toLowerCase() });
        }
        return value;
    });
};
export var capitalizeTravellersNames = function (travellers) {
    return travellers.map(function (traveller) {
        return __assign(__assign({}, traveller), { values: capitalizeTravellerName(traveller.values) });
    });
};
var lowerCaseCustomerContacts = function (contacts) {
    return ((contacts === null || contacts === void 0 ? void 0 : contacts.map(function (contact) {
        if (contact.type === TravellerFieldEnum.Email) {
            return __assign(__assign({}, contact), { value: contact.value.toLowerCase() });
        }
        return contact;
    })) || []);
};
export var capitalizeOrderTravellers = function (order) {
    return __assign(__assign({}, order), { travellers: capitalizeTravellersNames(order.travellers), customer: __assign(__assign({}, order.customer), { contacts: lowerCaseCustomerContacts(order.customer.contacts) }), exareInfo: order.exareInfo
            ? __assign(__assign({}, order.exareInfo), { lastExchange: order.exareInfo.lastExchange
                    ? __assign(__assign({}, order.exareInfo.lastExchange), { travellers: capitalizeTravellersNames(order.exareInfo.lastExchange.travellers) }) : null, lastRefund: order.exareInfo.lastRefund
                    ? __assign(__assign({}, order.exareInfo.lastRefund), { travellers: capitalizeTravellersNames(order.exareInfo.lastRefund.travellers) }) : null }) : null });
};
