import { __awaiter, __generator, __read, __values } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import i18n from 'i18next';
import { subMonths, parseISO, isBefore, isAfter } from 'date-fns';
import { useQuery } from '@apollo/react-hooks';
import SearchFormDatepicker from '../../../../../../SearchFormDatepicker';
import Tooltip from '../../../../../../Tooltip/Tooltip';
import { useConfig } from '../../../../../../context';
import { useTheme } from '../../../../../../theme';
import { getMinPricesRequestVariables, getPrices } from '../../../../../utils';
import { safeStartOfMonth } from '../../../../../../PriceGraph/utils';
import { getDateWithoutTimezone } from '../../../../../../Engine/components/FastSearch/utils';
import Value from './Value/Value';
import { isDatesInReversedOrder, isSelectedOnlyDepartureDate, isSelectedOnlyReturnDate, swapConfigDates } from './utils';
import { CURRENCY_KEY } from '../../../../../../cache';
var _a = require('./query.graphql'), GetAvailableDates = _a.GetAvailableDates, FlightsMinPricesInPeriod = _a.FlightsMinPricesInPeriod;
export var DatepickerTab;
(function (DatepickerTab) {
    DatepickerTab["Outbound"] = "outbound";
    DatepickerTab["Return"] = "return";
})(DatepickerTab || (DatepickerTab = {}));
var startDate = new Date();
var validate = function (value, allValues, meta) {
    if (!meta.data.departure) {
        return i18n.t('SearchForm:Please select departure date');
    }
};
var lastVisibleError = '';
var Datepicker = function (_a) {
    var _b;
    var selectedDates = _a.selectedDates, onDateChange = _a.onDateChange, dateTo = _a.dateTo, _c = _a.dateBack, dateBack = _c === void 0 ? null : _c, _d = _a.withDateBack, withDateBack = _d === void 0 ? false : _d, clearDates = _a.clearDates, segmentId = _a.segmentId, locations = _a.locations, passengers = _a.passengers, showPrices = _a.showPrices, isMultiCity = _a.isMultiCity, onlySegment = _a.onlySegment;
    var css = useTheme('SearchForm').Datepicker;
    var hasSelectedDates = !!selectedDates.length;
    var _e = __read(useState(dateTo ? DatepickerTab.Return : DatepickerTab.Outbound), 2), routeType = _e[0], setRouteType = _e[1];
    var _f = __read(useState(dateTo), 2), openDate = _f[0], setOpenDate = _f[1];
    var _g = __read(useState([null, null]), 2), hoverDates = _g[0], setHoverDates = _g[1];
    var _h = __read(useState(true), 2), pricesLoading = _h[0], setPricesLoading = _h[1];
    var _j = __read(useState(false), 2), isRouteTypeSetManually = _j[0], setIsRouteTypeSetManually = _j[1];
    var _k = __read(useState(null), 2), minPricesTo = _k[0], setMinPricesTo = _k[1];
    var _l = __read(useState(null), 2), minPricesBack = _l[0], setMinPricesBack = _l[1];
    var _m = useConfig().SearchForm, showPriceGraph = _m.showPriceGraph, proMode = _m.proMode;
    var onHover = useCallback(function (hoverDate) {
        if (selectedDates.filter(function (date) { return !!date; }).length === 0) {
            if (isRouteTypeSetManually && routeType === DatepickerTab.Return) {
                setHoverDates([null, hoverDate]);
            }
            else {
                setHoverDates([hoverDate, null]);
            }
            return;
        }
        if (isSelectedOnlyReturnDate(selectedDates) && isAfter(hoverDate, selectedDates[1])) {
            setRouteType(DatepickerTab.Return);
            setHoverDates([null, hoverDate]);
            return;
        }
        if (isRouteTypeSetManually) {
            if (routeType === DatepickerTab.Outbound && isAfter(hoverDate, selectedDates[1])) {
                setRouteType(DatepickerTab.Return);
                setIsRouteTypeSetManually(false);
            }
            if (routeType === DatepickerTab.Return &&
                (isBefore(hoverDate, selectedDates[0]) || isBefore(hoverDate, selectedDates[1]))) {
                setRouteType(DatepickerTab.Outbound);
            }
        }
        else {
            if (isBefore(hoverDate, selectedDates[0])) {
                setRouteType(DatepickerTab.Outbound);
            }
            else {
                setRouteType(DatepickerTab.Return);
                setIsRouteTypeSetManually(false);
            }
        }
        if (!hoverDate) {
            setHoverDates([null, null]);
            return;
        }
        if (!selectedDates[0]) {
            setHoverDates([hoverDate, selectedDates[1] || null]);
            return;
        }
        if (selectedDates[0] && selectedDates[1]) {
            var isHoverDateInSelectedPeriod = isAfter(hoverDate, selectedDates[0]) && isBefore(hoverDate, selectedDates[1]);
            var isManuallySetOutboundRouteType = isRouteTypeSetManually && routeType === DatepickerTab.Outbound;
            if (isHoverDateInSelectedPeriod && !isManuallySetOutboundRouteType) {
                setHoverDates([selectedDates[0], hoverDate]);
            }
            else if (isBefore(hoverDate, selectedDates[0]) || isManuallySetOutboundRouteType) {
                setHoverDates([hoverDate, selectedDates[1]]);
            }
            else if (isAfter(hoverDate, selectedDates[1])) {
                setHoverDates([selectedDates[0], hoverDate]);
            }
            return;
        }
        if (selectedDates[0]) {
            if (isBefore(hoverDate, dateTo)) {
                setHoverDates([hoverDate, dateTo]);
            }
            else {
                setHoverDates([selectedDates[0], hoverDate]);
            }
            return;
        }
    }, [selectedDates, isRouteTypeSetManually, routeType]);
    var scheduleTo = useQuery(GetAvailableDates, {
        skip: !locations.departure || !locations.arrival || showPrices || showPriceGraph,
        variables: {
            departure: locations.departure ? locations.departure.iata : '',
            arrival: locations.arrival ? locations.arrival.iata : ''
        }
    }).data;
    var scheduleBack = useQuery(GetAvailableDates, {
        skip: !locations.departure || !locations.arrival || showPrices || showPriceGraph || routeType === 'outbound',
        variables: {
            departure: locations.arrival ? locations.arrival.iata : '',
            arrival: locations.departure ? locations.departure.iata : ''
        }
    }).data;
    var alternativeHighlighting = routeType === 'outbound'
        ? scheduleTo && scheduleTo.FlightsSchedule && scheduleTo.FlightsSchedule.flightProbability > 0.5
        : scheduleBack && scheduleBack.FlightsSchedule && scheduleBack.FlightsSchedule.flightProbability > 0.5;
    var refetchMinPrices = useQuery(FlightsMinPricesInPeriod, {
        skip: true
    }).refetch;
    var highlightingClassName;
    if (showPrices) {
        highlightingClassName = css.highlightedDate_withPrice;
    }
    else {
        highlightingClassName = alternativeHighlighting ? css.highlightedDate_lite : css.highlightedDate;
    }
    var getAvailableDatesFromSchedule = function () {
        if (routeType === 'outbound' && scheduleTo && scheduleTo.FlightsSchedule) {
            return scheduleTo.FlightsSchedule.datesData.map(function (scheduleItem) {
                return getDateWithoutTimezone(new Date(scheduleItem.date));
            });
        }
        else if (routeType === 'return' && scheduleBack && scheduleBack.FlightsSchedule) {
            return scheduleBack.FlightsSchedule.datesData.map(function (scheduleItem) {
                return getDateWithoutTimezone(new Date(scheduleItem.date));
            });
        }
        else {
            return [];
        }
    };
    var getAvailableDates = function () {
        var e_1, _a;
        var prices = routeType === 'outbound' ? minPricesTo : minPricesBack;
        var availableDates = [];
        if (showPrices) {
            if (!prices) {
                return [];
            }
            try {
                for (var _b = __values(Object.entries(prices)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 2), key = _d[0], value = _d[1];
                    if (value.amount) {
                        availableDates.push(getDateWithoutTimezone(new Date(key)));
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return availableDates;
        }
        else {
            return getAvailableDatesFromSchedule();
        }
    };
    var setDate = function (date, isBackDate) {
        var config = {};
        if (isSelectedOnlyReturnDate(selectedDates)) {
            if (isAfter(date, selectedDates[1])) {
                config.return = date;
            }
            else {
                config.departure = date;
                config.return = selectedDates[1];
            }
        }
        else {
            config.departure = isBackDate ? selectedDates[0] : date;
            config.return = isBackDate ? date : selectedDates[1];
        }
        if (isSelectedOnlyDepartureDate(selectedDates) && isBefore(date, selectedDates[0])) {
            config.departure = date;
            config.return = selectedDates[0];
        }
        if (isDatesInReversedOrder(selectedDates)) {
            config = swapConfigDates(config);
        }
        if (withDateBack && !proMode) {
            onDateChange(config.departure, false);
            onDateChange(config.return, true);
            if (!isBackDate && withDateBack && !config.return) {
                setRouteType(DatepickerTab.Return);
            }
        }
        else {
            onDateChange(date, isBackDate);
            if (proMode && !isBackDate) {
                setRouteType(DatepickerTab.Return);
            }
        }
        return config;
    };
    var handlerClear = useCallback(function () {
        setRouteType(DatepickerTab.Outbound);
        clearDates(segmentId);
    }, [clearDates]);
    if (!openDate) {
        setOpenDate((!withDateBack || proMode) && selectedDates.length
            ? (_b = selectedDates[selectedDates.length - 1]) !== null && _b !== void 0 ? _b : new Date()
            : new Date());
    }
    var clearOpenDate = function () {
        setOpenDate(!withDateBack && selectedDates.length ? selectedDates[selectedDates.length - 1] : new Date());
    };
    var onPricesEnd = function (date, direction) { return __awaiter(void 0, void 0, void 0, function () {
        var data, prices, pricesKeys, lastMonth, prevMonth;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setPricesLoading(true);
                    return [4 /*yield*/, refetchMinPrices({
                            params: getMinPricesRequestVariables(locations, passengers, date, routeType !== DatepickerTab.Outbound)
                        })];
                case 1:
                    data = (_a.sent()).data;
                    prices = getPrices(data);
                    if (routeType === DatepickerTab.Outbound) {
                        setMinPricesTo(prices);
                        if (direction === 'prev') {
                            pricesKeys = Object.keys(prices);
                            lastMonth = pricesKeys[pricesKeys.length - 1];
                            prevMonth = safeStartOfMonth(subMonths(parseISO(lastMonth), 1));
                            setOpenDate(prevMonth);
                        }
                    }
                    else {
                        setMinPricesBack(prices);
                    }
                    setPricesLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handlerClickDatepickerTabOutbound = useCallback(function () {
        setRouteType(DatepickerTab.Outbound);
    }, []);
    var handlerClickDatepickerTabReturn = useCallback(function () {
        if (!selectedDates[0]) {
            return;
        }
        setRouteType(DatepickerTab.Return);
    }, [selectedDates[0]]);
    var fetchPrices = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, minPricesToResp, pricesToLoading, _b, minPricesBackResp, pricesBackLoading;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!locations.arrival || !locations.departure || !showPrices) {
                        return [2 /*return*/];
                    }
                    setPricesLoading(true);
                    return [4 /*yield*/, refetchMinPrices({
                            params: getMinPricesRequestVariables(locations, passengers, dateTo, false)
                        })];
                case 1:
                    _a = _c.sent(), minPricesToResp = _a.data, pricesToLoading = _a.loading;
                    return [4 /*yield*/, refetchMinPrices({
                            params: getMinPricesRequestVariables(locations, passengers, dateTo, true)
                        })];
                case 2:
                    _b = _c.sent(), minPricesBackResp = _b.data, pricesBackLoading = _b.loading;
                    setMinPricesTo(getPrices(minPricesToResp));
                    setMinPricesBack(getPrices(minPricesBackResp));
                    setPricesLoading(pricesToLoading && pricesBackLoading);
                    return [2 /*return*/];
            }
        });
    }); };
    var onChangeLocalStorage = function (e) {
        if (e.key === CURRENCY_KEY && e.oldValue !== e.newValue) {
            fetchPrices();
        }
    };
    useEffect(function () {
        fetchPrices();
        window.addEventListener('storage', onChangeLocalStorage);
        return function () {
            window.removeEventListener('storage', onChangeLocalStorage);
        };
    }, [locations.departure && locations.departure.iata, locations.arrival && locations.arrival.iata]);
    useEffect(function () {
        if (withDateBack && selectedDates.length === 0 && routeType === DatepickerTab.Return) {
            setRouteType(DatepickerTab.Outbound);
        }
    }, [withDateBack]);
    useEffect(function () {
        if (selectedDates.length && !withDateBack) {
            setOpenDate(selectedDates[selectedDates.length - 1]);
        }
    }, [selectedDates, withDateBack]);
    var _o = __read(useMemo(function () {
        if (withDateBack && segmentId === 0) {
            switch (routeType) {
                case DatepickerTab.Outbound:
                    return [startDate, selectedDates[1]];
                case DatepickerTab.Return:
                    return [selectedDates[0], undefined];
                default:
                    return [startDate, undefined];
            }
        }
        else {
            return [startDate, undefined];
        }
    }, [routeType, startDate, selectedDates[0], selectedDates[1], withDateBack]), 2), minDate = _o[0], maxDate = _o[1];
    return (React.createElement(FormSpy, { subscription: {
            submitFailed: true,
            dirtySinceLastSubmit: true
        } }, function (formState) { return (React.createElement(Field, { name: "dates_".concat(segmentId), validate: validate, subscription: {
            data: true,
            error: true
        } }, function (fieldState) {
        var _a;
        var onSelect = function (date, isBackDate) {
            var config = setDate(date, isBackDate);
            formState.form.mutators.setFieldData(fieldState.input.name, config);
        };
        // We need this for filling out the search form from the outside.
        if ((dateTo || dateBack) && !fieldState.meta.data.departure) {
            var config = {};
            if (dateTo) {
                config.departure = dateTo;
            }
            if (dateBack) {
                config.return = dateBack;
            }
            formState.form.mutators.setFieldData(fieldState.input.name, config);
        }
        if (!dateTo || !dateBack) {
            var config = {};
            config.departure = dateTo;
            config.return = dateBack;
            formState.form.mutators.setFieldData(fieldState.input.name, {
                departure: dateTo,
                return: dateBack
            });
        }
        if (fieldState.meta.error) {
            lastVisibleError = fieldState.meta.error;
        }
        return (React.createElement(Tooltip, { title: lastVisibleError, placement: proMode ? 'bottom' : 'top', open: !!fieldState.meta.error && formState.submitFailed },
            React.createElement(SearchFormDatepicker, { monthCount: 3, showProgress: showPrices && pricesLoading, onSelect: onSelect, onHover: withDateBack ? onHover : null, hoverDates: hoverDates, onClear: handlerClear, clearOpenDate: clearOpenDate, openDate: openDate, selectedDates: selectedDates.filter(function (date) { return !!date; }), minDate: minDate, maxDate: maxDate, isClearable: hasSelectedDates, isDoneable: hasSelectedDates, inputClassName: css.input, routeType: routeType, inputFocusedClassName: css.input_focused, outsideClickIgnoreClass: css.dates.split(' ')[0], showPriceGraph: !pricesLoading && showPriceGraph, onPriceGraphPricesEnd: onPricesEnd, pricesTo: minPricesTo, pricesBack: minPricesBack, showPriceMatrix: false, datepickerAlternativeHighlighting: alternativeHighlighting, useThemeWithPrices: showPrices, highlightedDates: (_a = {},
                    _a[highlightingClassName] = getAvailableDates(),
                    _a), closeAfterSelectReturnDate: false, onClickOutboundDate: handlerClickDatepickerTabOutbound, onClickReturnDate: handlerClickDatepickerTabReturn, resetFormStateMutator: formState.form.mutators.resetSubmitForm, valueRenderer: function (isOpen, open, close) {
                    var _a, _b;
                    return (React.createElement(Value, { open: open, isOpen: isOpen, close: close, dateTo: (_a = hoverDates[0]) !== null && _a !== void 0 ? _a : dateTo, dateBack: (_b = hoverDates[1]) !== null && _b !== void 0 ? _b : dateBack, withDateBack: withDateBack, routeType: routeType, setRouteType: setRouteType, onClear: handlerClear, segmentId: segmentId, onDateSelect: onSelect, setIsRouteTypeSetManually: setIsRouteTypeSetManually, showClearIcon: !isMultiCity || onlySegment }));
                } })));
    })); }));
};
export default Datepicker;
