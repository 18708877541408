import { __read } from "tslib";
import * as React from 'react';
import { useTheme } from '../theme';
import { useState } from 'react';
import { SlideBottom } from '../index';
import { useTranslation } from 'react-i18next';
import { initI18n, MOBILE_MIN_WIDTH } from '../utils';
import { useMediaQuery } from 'react-responsive';
import AddPassengerForm from './components/AddPassengerForm/AddPassengerForm';
var addIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 13H17V11H13V7H11V11H7V13H11V17H13V13Z", fill: "currentColor" })));
initI18n('AddPassenger');
var AddPassenger = function (_a) {
    var onSubmit = _a.onSubmit;
    var _b = __read(useState(false), 2), isFormOpened = _b[0], setFormOpened = _b[1];
    var t = useTranslation('AddPassenger').t;
    var css = useTheme('AddPassenger').AddPassenger;
    var isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
    return (React.createElement("div", { className: css.wrapper },
        (!isFormOpened || isMobile) && (React.createElement("div", { className: css.addButton, onClick: function () { return setFormOpened(true); } },
            React.createElement("span", { className: css.addButton__icon }, addIcon),
            React.createElement("span", null, t('Add passenger')))),
        isFormOpened && !isMobile && (React.createElement("div", { className: css.form__wrapper },
            React.createElement(AddPassengerForm, { onSubmit: onSubmit, onSuccess: function () { return setFormOpened(false); } }))),
        React.createElement(SlideBottom, { isOpen: isFormOpened && isMobile, onClose: function () { return setFormOpened(false); } },
            React.createElement("div", { className: css.form__wrapper },
                React.createElement("div", { className: css.form__title }, t('Add passenger')),
                React.createElement(AddPassengerForm, { onSubmit: onSubmit, onSuccess: function () { return setFormOpened(false); } })))));
};
export default AddPassenger;
