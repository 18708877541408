import { __generator } from "tslib";
import { all, select, takeLatest } from 'redux-saga/effects';
import recentSearchesSagas from './recentSearches/sagas';
import { loadOtherPassengerTypesSaga } from './passengerTypes/sagas';
import { REDIRECT_TO_FAST_SEARCH, SUBMIT_FORM } from './actions';
import { getSearchParameters } from './selectors';
import { createFastSearchURL } from '../utils';
function submitFormSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(SUBMIT_FORM, function (_a) {
                    var searchParameters;
                    var onSubmit = _a.payload;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, select(getSearchParameters)];
                            case 1:
                                searchParameters = _b.sent();
                                onSubmit(searchParameters);
                                return [2 /*return*/];
                        }
                    });
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function redirectToFastSearchSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(REDIRECT_TO_FAST_SEARCH, function (_a) {
                    var searchParameters;
                    var fastSearchURL = _a.payload;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, select(getSearchParameters)];
                            case 1:
                                searchParameters = _b.sent();
                                window.location.href = "".concat(fastSearchURL, "#/search/").concat(createFastSearchURL(searchParameters));
                                return [2 /*return*/];
                        }
                    });
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default function rootSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([recentSearchesSagas(), submitFormSaga(), redirectToFastSearchSaga(), loadOtherPassengerTypesSaga()])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
