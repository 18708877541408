import { __assign, __awaiter, __generator, __read, __rest } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccountInfo } from './hooks';
import { useAuthToken } from '../LoginChangePassword/hooks';
import { UserPointOfSalesContext } from './pointOfSalesContext';
import { useIsTerminal } from '../../hooks';
var leoCheckIsAuthenticated = function (Compoment) { return function (_a) {
    var props = __rest(_a, []);
    var _b = useAuthToken(), token = _b.token, setToken = _b.setToken;
    var _c = useAccountInfo(), getCurrentUser = _c.getCurrentUser, isCurrentUserLoading = _c.isLoading;
    var isTerminal = useIsTerminal();
    var pathname = useLocation().pathname;
    var _d = __read(React.useState(null), 2), pointOfSales = _d[0], setPointOfSales = _d[1];
    var checkIsUserNotAuthenticated = function () { return __awaiter(void 0, void 0, void 0, function () {
        var currentUser;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, getCurrentUser()];
                case 1:
                    currentUser = _d.sent();
                    if (((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null) {
                        setToken(null);
                    }
                    else if ((_c = (_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.data) === null || _b === void 0 ? void 0 : _b.CurrentUser) === null || _c === void 0 ? void 0 : _c.pointOfSale) {
                        setPointOfSales(currentUser.data.CurrentUser.pointOfSale);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (isTerminal && token) {
            checkIsUserNotAuthenticated();
        }
    }, [token]);
    useEffect(function () {
        if (isTerminal && pathname !== '/login' && !token && !isCurrentUserLoading) {
            window.location.href = "#/login";
        }
    }, [pathname, token]);
    return (React.createElement(UserPointOfSalesContext.Provider, { value: pointOfSales },
        React.createElement(Compoment, __assign({}, props))));
}; };
export default leoCheckIsAuthenticated;
