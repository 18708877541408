import memes from '../memes';
export var getTemperaturePrefix = memes(function (temperature) {
    if (temperature > 0) {
        return '+';
    }
    else if (temperature < 0) {
        return '-';
    }
    return '';
});
