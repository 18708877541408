import { __assign } from "tslib";
import * as React from 'react';
import MobileStepbar from './components/MobileStepbar';
import { getOrder } from '../Checkout/store/order/selectors';
import { getPassengerNationalities } from '../Checkout/store/passengers/selectors';
import { connect } from 'react-redux';
import { convertCheckoutOrder } from './utils';
import { getTotalPriceToPay } from '../Checkout/store/orderPrice/selectors';
import { getPassengerStateCompatible } from '../Checkout/store/selectedServices/selectors';
var MobileStepbarWrapper = function (_a) {
    var order = _a.order, mode = _a.mode, passengers = _a.passengers, totalPrice = _a.totalPrice, currentStep = _a.currentStep, stepbarConfig = _a.stepbarConfig, onSetOrder = _a.onSetOrder, passengerNationalities = _a.passengerNationalities;
    return (React.createElement(MobileStepbar, { mode: mode, passengers: passengers, order: convertCheckoutOrder(order), currentStep: currentStep, steps: stepbarConfig, totalPrice: totalPrice, onSetOrder: onSetOrder, passengerNationalities: passengerNationalities }));
};
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { order: getOrder(state), totalPrice: getTotalPriceToPay(state), passengers: getPassengerStateCompatible(state), passengerNationalities: getPassengerNationalities(state) })); };
export default connect(mapStateToProps)(MobileStepbarWrapper);
