import React from 'react';
import { OrderStatus } from '../../../../__generated__/graphql';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../../utils';
initI18n('OrderCard');
var OrderStatusText = function (_a) {
    var status = _a.status;
    var t = useTranslation('OrderCard').t;
    switch (status) {
        case OrderStatus.Confirmed:
            return React.createElement(React.Fragment, null, t('Confirmed'));
        case OrderStatus.Booked:
            return React.createElement(React.Fragment, null, t('Booked'));
        case OrderStatus.Cancelled:
            return React.createElement(React.Fragment, null, t('Cancelled'));
        default:
            return null;
    }
};
export default OrderStatusText;
