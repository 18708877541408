export var coupons = [
    {
        coupon: 'BQS - MOW',
        date: '28.01.2021',
        flight: 'N4-721',
        fareBasis: 'WLTOW',
        nvb: '12.01.2020',
        nva: '28.01.2031',
        baggage: {
            count: 20,
            unit: 'kg'
        },
        status: 'F'
    },
    {
        coupon: 'MOW - SVX',
        date: '28.01.2021',
        flight: 'N4-755',
        fareBasis: 'WLTOW',
        nvb: '12.02.2020',
        nva: '28.02.2031',
        baggage: {
            count: 15,
            unit: 'kg'
        },
        status: 'F'
    }
];
export var airlineTaxes = [
    {
        code: 'VQ',
        amount: 100
    },
    {
        code: 'CQ',
        amount: 200
    }
];
export var agencyTaxes = [
    {
        code: 'BV',
        amount: 100
    },
    {
        code: 'BF',
        amount: 200
    }
];
export var currency = 'RUB';
export var refundCalculation1 = [
    {
        'Total ticket sum': 1000,
        'TFC Agency': 100
    }
];
export var refundCalculation2 = [
    {
        'Fare for refund': 1025,
        'Cancelation penalty': 100,
        'Special airlines sum': 0,
        'Agency commission': 0
    }
];
export var exchangeCalculation1 = refundCalculation1;
export var exchangeCalculation2 = [
    {
        'Previous ticket sum': 1000,
        'Total ticket sum': 1100
    },
    {
        'Exchange penalty': 200,
        'Special airlines ADC': -5,
        'Ticketing fee': 0
    }
];
