import * as ComponentStyles from './components/Component.css';
import * as SeatMapStyles from './components/SeatMap/SeatMap.css';
import * as SeatStyles from './components/SeatMap/Seat/Seat.css';
import * as SeatTooltipStyles from './components/SeatMap/Seat/SeatTooltip/SeatTooltip.css';
import * as AirplaneStyles from './components/Airplane/Airplane.css';
import * as PassengersStyles from './components/Passengers/Passengers.css';
import * as Passenger from './components/Passengers/Passenger/Passenger.css';
import * as AirplaneInfoStyles from './components/AirplaneInfo/AirplaneInfo.css';
import * as SeatsInfoStyles from './components/SeatsInfo/SeatsInfo.css';
import * as SeatMapDisclaimerStyles from './components/SeatMapDisclaimer/SeatMapDisclaimer.css';
import * as SeatServiceSlide from './components/SeatMap/Seat/SeatServiceSlide/SeatServiceSlide.css';
import * as SeatsRecommendPopup from './components/SeatsRecommend/SeatsRecommendPopup/SeatsRecommendPopup.css';
import * as Controls from './components/SeatMapService/Controls/Controls.css';
export default {
    ComponentStyles: ComponentStyles,
    SeatMapStyles: SeatMapStyles,
    SeatStyles: SeatStyles,
    SeatTooltipStyles: SeatTooltipStyles,
    AirplaneStyles: AirplaneStyles,
    PassengersStyles: PassengersStyles,
    Passenger: Passenger,
    AirplaneInfoStyles: AirplaneInfoStyles,
    SeatsInfoStyles: SeatsInfoStyles,
    SeatServiceSlide: SeatServiceSlide,
    SeatMapDisclaimerStyles: SeatMapDisclaimerStyles,
    SeatsRecommendPopup: SeatsRecommendPopup,
    Controls: Controls
};
