import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import Money from '../../Money';
import css from './ElementsPriceDetails.css';
import { useTranslation } from 'react-i18next';
var isTaxRetention = function (element) {
    return element.hasOwnProperty('taxCode');
};
var ElementsPriceDetails = function (props) {
    var t = useTranslation('Exchange').t;
    var detailsByTypes = useMemo(function () {
        var _a;
        var byTypes = new Map();
        (_a = props.elements.elements) === null || _a === void 0 ? void 0 : _a.forEach(function (element) {
            var elementKey = "".concat(element.type, "_").concat(element.taxCode || '');
            var selectedElement = byTypes.get(elementKey);
            if (selectedElement) {
                var updateElement = __assign(__assign({}, selectedElement), { money: __assign(__assign({}, selectedElement.money), { amount: selectedElement.money.amount + element.money.amount }) });
                byTypes.set(elementKey, updateElement);
            }
            else {
                var passengerId = element.passengerId, otherProperties = __rest(element, ["passengerId"]);
                byTypes.set(elementKey, otherProperties);
            }
        });
        return byTypes;
    }, [props.elements]);
    var details = Array.from(detailsByTypes);
    return (React.createElement(React.Fragment, null, details.map(function (_a, idx) {
        var _b = __read(_a, 2), type = _b[0], element = _b[1];
        return (React.createElement("div", { className: css.item, key: "".concat(idx).concat(element.type) },
            React.createElement("div", null, isTaxRetention(element) ? "".concat(t('Fee'), " ").concat(t(element.taxCode)) : t(element.type)),
            React.createElement(Money, { money: {
                    amount: Math.abs(element.money.amount),
                    currency: element.money.currency
                } })));
    })));
};
export default ElementsPriceDetails;
