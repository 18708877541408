import { __assign, __read } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { parse } from 'date-fns';
import Flight from '../../../../../Flight';
import { useTheme } from '../../../../../theme';
import { API_DATE_FORMAT } from '../../../../../utils';
import { Sorting } from '../../../../../Results/enums';
import { getSegments } from '../../../store/order/selectors';
import { sortFlights } from '../../../../../Results/components/Results/FlightsList/utils';
import ResultsError from '../../../../../ResultsError';
import { getSelectedFares } from '../../../store/newFlights/selectors';
import Header from './Header/Header';
import { OverrideComponent } from '../../../../../renderProps';
import SelectButton from '../../../../../DesktopFlightRoute/components/SelectButton/SelectButton';
import { getFlightsToExchange, getIsAllFlights } from '../../../store/selectors';
var SearchFlights = function (props) {
    var _a, _b;
    var theme = useTheme('Exchange').SearchFlights;
    var leg = (_a = props.resultsSearchParams) === null || _a === void 0 ? void 0 : _a.segments[props.currentLeg];
    var _c = __read(React.useState(Sorting.Price), 2), sorting = _c[0], setSorting = _c[1];
    var isAllFlights = useSelector(getIsAllFlights);
    var flightsToExchange = (_b = useSelector(getFlightsToExchange)) === null || _b === void 0 ? void 0 : _b.flightsToExchange;
    var selectedFlights = useSelector(getSelectedFares);
    var segments = useSelector(getSegments);
    var flights = React.useMemo(function () {
        var _a;
        if (!((_a = props.legs) === null || _a === void 0 ? void 0 : _a.length)) {
            return [];
        }
        return props.legs.filter(function (flight) {
            return flight.pricesForFareGroups.some(function (_a) {
                var prices = _a.prices;
                if (!prices) {
                    return false;
                }
                if (selectedFlights.length) {
                    var lastSelectedFare = selectedFlights[selectedFlights.length - 1];
                    var selectedMap_1 = {};
                    lastSelectedFare.fare.prices.forEach(function (price) { return (selectedMap_1[price.flight.id] = price.flight.id); });
                    return prices.some(function (price) { return selectedMap_1.hasOwnProperty(price.flight.id); });
                }
                else {
                    return true;
                }
            });
        });
    }, [props.legs, selectedFlights]);
    var t = useTranslation('Exchange').t;
    var sortedFlights = React.useMemo(function () {
        return sortFlights(flights ? flights : [], sorting);
    }, [props.legs, sorting]);
    var absoluteCurrentLeg = (isAllFlights ? 0 : +flightsToExchange[0].key) + props.currentLeg;
    var minDate = useMemo(function () {
        var _a, _b, _c;
        if (isAllFlights && absoluteCurrentLeg > 0) {
            return parse((_a = props.searchParams.segments) === null || _a === void 0 ? void 0 : _a[absoluteCurrentLeg - 1].date, API_DATE_FORMAT, new Date());
        }
        else if (((_b = props.searchParams.segments) === null || _b === void 0 ? void 0 : _b.length) && absoluteCurrentLeg > 0) {
            return parse((_c = segments[absoluteCurrentLeg - 1]) === null || _c === void 0 ? void 0 : _c.segments[0].arrival.date, API_DATE_FORMAT, new Date());
        }
        return new Date(new Date().setHours(0, 0, 0, 0));
    }, [isAllFlights, segments, props.searchParams, absoluteCurrentLeg]);
    return (React.createElement(React.Fragment, null, !props.loading && (React.createElement("div", null,
        React.createElement(OverrideComponent, { componentProps: {
                selectedFlights: selectedFlights,
                clearFares: props.clearFares,
                leg: leg,
                searchParams: props.searchParams,
                startNewSearch: props.startNewSearch,
                sortedFlights: sortedFlights,
                sorting: sorting,
                setSorting: setSorting,
                currentLegNumber: props.currentLeg,
                legs: props.legs,
                setLeg: props.setLeg,
                minDate: minDate
            }, component: {
                ExchangeSearchFlightsHeader: Header
            } }),
        !props.loading && !sortedFlights.length && (React.createElement(ResultsError, { header: t("We couldn't find any flights for given dates"), body: t('Please select another date or modify your search. If you wish to purchase a ticket for this particular date, please contact customer support.') })),
        React.createElement("div", { className: theme.flights }, !!sortedFlights.length &&
            sortedFlights.map(function (flight, key) {
                var _a;
                var flightPrice = (_a = props.prices.find(function (price) { return price.flightId === flight.pricesForFareGroups[0].prices[0].flight.id; })) === null || _a === void 0 ? void 0 : _a.priceToPay;
                var flightProps = {
                    flight: __assign(__assign({}, flight), { pricesForFareGroups: [
                            __assign(__assign({}, flight.pricesForFareGroups[0]), { prices: [
                                    __assign(__assign({}, flight.pricesForFareGroups[0].prices[0]), { price: flightPrice })
                                ] })
                        ] }),
                    onSelect: props.onFareSelect,
                    renderPrice: SelectButton,
                    isPreOpen: false,
                    isPreOpenAvailable: false,
                    hidePrice: true,
                    selectedFares: selectedFlights.map(function (selectedFlight) { return selectedFlight.fare; })
                };
                return (React.createElement(OverrideComponent, { componentProps: __assign({}, flightProps), key: "".concat(key, "_").concat(props.currentLeg), component: {
                        ExchangeFlight: Flight
                    } }));
            })),
        React.createElement(OverrideComponent, { componentProps: {}, component: { ExchangeSearchFlightBottom: function () { return null; } } })))));
};
export default SearchFlights;
