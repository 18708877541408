import React from 'react';
import cn from 'classnames';
import * as dateFns from 'date-fns';
import { ChevronRightIcon } from '../../../../AircraftPopup/components/Icons';
import { format } from '../../../../utils';
import { useTheme } from '../../../../theme';
import { addDays } from 'date-fns';
import { useToggleable } from '../../../../hooks';
import Datepicker from './Datepicker/Datepicker';
var DateSwitcher = function (_a) {
    var _b, _c;
    var className = _a.className, date = _a.date, availableDates = _a.availableDates, onChangeDate = _a.onChangeDate;
    var css = useTheme('SeatAvailability').DateSwitcher;
    var _d = useToggleable(false), isOpen = _d.isOpen, open = _d.open, close = _d.close;
    var onPrevDay = function () {
        onChangeDate === null || onChangeDate === void 0 ? void 0 : onChangeDate(addDays(date, -1));
    };
    var onNextDay = function () {
        onChangeDate === null || onChangeDate === void 0 ? void 0 : onChangeDate(addDays(date, 1));
    };
    var prevDayAvailable = false;
    var nextDayAvailable = false;
    if ((availableDates === null || availableDates === void 0 ? void 0 : availableDates.length) > 0) {
        prevDayAvailable = date > availableDates[0] && !dateFns.isToday(date);
        nextDayAvailable = date < availableDates[availableDates.length - 1];
    }
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: cn(css.arrow, css.arrow_prev, (_b = {}, _b[css.available] = !prevDayAvailable, _b)), onClick: onPrevDay }, ChevronRightIcon),
        React.createElement("div", { className: css.date, onClick: open }, format(date, 'dd MMM, yyyy')),
        React.createElement("div", { className: cn(css.arrow, (_c = {}, _c[css.available] = !nextDayAvailable, _c)), onClick: onNextDay }, ChevronRightIcon),
        React.createElement(Datepicker, { className: css.datepicker, isOpen: isOpen, minDate: new Date(), openDate: date, selectedDates: [date], availableDates: availableDates, onSelect: onChangeDate, onClose: close })));
};
export default DateSwitcher;
