import * as React from 'react';
import cn from 'classnames';
import Money from '../../../../../Money';
import { Chevron, CloseClear } from '../../../../../Icons';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
var DesktopPassenger = function (_a) {
    var _b;
    var passengerName = _a.passengerName, passengerAvatarText = _a.passengerAvatarText, seatNumber = _a.seatNumber, price = _a.price, onClick = _a.onClick, isActive = _a.isActive, onClearSeat = _a.onClearSeat, recommend = _a.recommend, confirmed = _a.confirmed;
    var theme = useTheme('SeatMap').Passenger;
    var t = useTranslation('SeatMap').t;
    return (React.createElement("div", { className: cn(theme.item, (_b = {}, _b[theme.item_active] = isActive, _b[theme.item_recommend] = recommend, _b)), onClick: onClick },
        React.createElement("div", { className: theme.main },
            React.createElement("div", { className: theme.avatar }, passengerAvatarText),
            React.createElement("div", { className: theme.passengerName }, passengerName)),
        seatNumber ? (React.createElement("div", { className: theme.payload },
            React.createElement("div", null, seatNumber),
            price && React.createElement(Money, { moneyClassName: theme.money, money: price }),
            recommend ? React.createElement("div", null, Chevron) : !confirmed && React.createElement("div", { onClick: onClearSeat }, CloseClear))) : (React.createElement("div", { className: cn(theme.payload, theme.select) }, t('Select')))));
};
export default DesktopPassenger;
