import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import Seats from '../routes/Seats';
import { getSeatMap, getTravellers } from '../../../../store/order/selectors';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../../../../__generated__/graphql';
import { useState } from 'react';
import { useServiceRequest } from '../../../../utils';
import { checkAllSeatsAreSelected } from '../../../../../../SeatMap/utils';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../../../utils';
import Modal from '../../../../../../Modal';
import SuggestionModalContent from '../../../../../../SuggestionModalContent/SuggestionModalContent';
import { SeatIcon } from '../../../../../../SuggestionModalContent/icons';
import { SlideBottom } from '../../../../../../index';
import WarningModal from '../../../../../../WarningModal';
import SimpleLoader from '../../../../../../SimpleLoader';
import { useTranslation } from 'react-i18next';
var SeatsReducer = function (state, actionVar) {
    var _a, _b;
    var _c, _d;
    if (actionVar.type && actionVar.type === 'SET_STATE') {
        return __assign({}, actionVar.payload);
    }
    var action = actionVar;
    var seatId = "".concat(action.passengerId, "_").concat(action.segmentId);
    if (state[seatId] && state[seatId].seat.number === ((_d = (_c = action.seat) === null || _c === void 0 ? void 0 : _c.seat) === null || _d === void 0 ? void 0 : _d.number)) {
        return __assign(__assign({}, state), (_a = {}, _a[seatId] = null, _a));
    }
    else {
        return __assign(__assign({}, state), (_b = {}, _b[seatId] = action.seat, _b));
    }
};
var PreselectedSeats = function (props) {
    var _a;
    var travellers = useSelector(getTravellers);
    var seatMap = useSelector(getSeatMap);
    var _b = __read(useState(null), 2), checkinError = _b[0], setCheckinError = _b[1];
    var _c = __read(useState(false), 2), requiredSeatsModaIsVisible = _c[0], setRequiredSeatsModalIsVisible = _c[1];
    var _d = __read(React.useReducer(SeatsReducer, {}), 2), state = _d[0], dispatch = _d[1];
    var _e = __read(useState(false), 2), isLoading = _e[0], setIsLoading = _e[1];
    var runServiceRequest = useServiceRequest().runServiceRequest;
    var t = useTranslation('Checkin').t;
    var closeRequiredSeatsModal = function () { return setRequiredSeatsModalIsVisible(false); };
    var closeWarningModal = function () { return setCheckinError(null); };
    React.useEffect(function () {
        var newState = {};
        travellers.forEach(function (traveller) {
            var _a, _b;
            (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.map(function (seat) {
                var _a;
                var key = "".concat(traveller.id, "_").concat((_a = seat.segment) === null || _a === void 0 ? void 0 : _a.id);
                newState[key] = seat;
            });
        });
        dispatch({
            type: 'SET_STATE',
            payload: newState
        });
    }, [travellers]);
    var setSeatCallback = function (seat, passengerId, segmentId) {
        if (seat) {
            travellers.map(function (traveller) {
                var _a;
                if (state && ((_a = state["".concat(traveller.id, "_").concat(segmentId)]) === null || _a === void 0 ? void 0 : _a.seat.number) === seat.number) {
                    dispatch({
                        passengerId: traveller.id,
                        segmentId: segmentId,
                        seat: null
                    });
                }
            });
            dispatch({
                seat: {
                    isConfirmed: true,
                    seat: seat,
                    row: parseInt(seat.row),
                    letter: seat.letter,
                    segment: {
                        id: segmentId
                    },
                    product: null
                },
                passengerId: passengerId,
                segmentId: segmentId
            });
        }
        else {
            dispatch({
                passengerId: passengerId,
                segmentId: segmentId,
                seat: null
            });
        }
    };
    var setClearSeatCallback = function (seat, passengerId, segmentId) {
        dispatch({
            passengerId: passengerId,
            segmentId: segmentId,
            seat: null
        });
    };
    var setOnClearSegmentCallback = function (segmentId) {
        travellers.map(function (traveller) {
            dispatch({
                passengerId: traveller.id,
                segmentId: segmentId.toString(),
                seat: null
            });
        });
    };
    var travellersWithSeats = React.useMemo(function () {
        return travellers.map(function (traveller) {
            var passengerSeats = [];
            seatMap.segments.forEach(function (segment) {
                if (!!state["".concat(traveller.id, "_").concat(segment.segmentsInfo.id)]) {
                    passengerSeats.push(state["".concat(traveller.id, "_").concat(segment.segmentsInfo.id)]);
                }
            });
            return __assign(__assign({}, traveller), { emds: null, tickets: null, services: __assign(__assign({}, traveller.services), { seats: __spreadArray([], __read(passengerSeats), false) }) });
        });
    }, [travellers, state]);
    var passengers = React.useMemo(function () {
        return travellersWithSeats.map(function (traveller) {
            var _a, _b;
            var passenger = {
                id: traveller.id,
                values: traveller.values,
                type: traveller.type,
                seats: (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.map(function (seat) {
                    var _a, _b;
                    return {
                        id: (_b = (_a = seat.seat) === null || _a === void 0 ? void 0 : _a.seatService) === null || _b === void 0 ? void 0 : _b.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Seat,
                        deckId: '0',
                        letter: seat.letter,
                        row: seat.row.toString(),
                        number: seat.seat.number,
                        price: seat.seat.price,
                        segmentId: seat.segment.id,
                        rfisc: seat.seat.rfisc
                    };
                })
            };
            return passenger;
        });
    }, [travellersWithSeats]);
    var onRequest = React.useCallback(function (passengers) { return __awaiter(void 0, void 0, void 0, function () {
        var order, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, runServiceRequest(passengers)];
                case 2:
                    order = _a.sent();
                    return [2 /*return*/, order];
                case 3:
                    e_1 = _a.sent();
                    setCheckinError(e_1);
                    console.error(e_1);
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [runServiceRequest]);
    var onProceed = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var allRequiredSeatsAreSelected, passengers_1, services_1, allRequiredSeatsAreSelected_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    allRequiredSeatsAreSelected = checkAllSeatsAreSelected(passengers, seatMap.segments.filter(function (segment) { return segment.availableSeatsCount > 0; }).length);
                    if (!(allRequiredSeatsAreSelected && !requiredSeatsModaIsVisible)) return [3 /*break*/, 2];
                    passengers_1 = travellersWithSeats.map(function (travellerWithSeats) {
                        return {
                            passengerId: travellerWithSeats.id,
                            seats: travellerWithSeats.services.seats,
                            setServices: null
                        };
                    });
                    return [4 /*yield*/, onRequest(passengers_1)];
                case 1:
                    services_1 = _a.sent();
                    if (services_1 && services_1.travellers) {
                        allRequiredSeatsAreSelected_1 = services_1.travellers.every(function (traveller) {
                            var _a;
                            return (traveller.type === 'INF' ||
                                ((_a = traveller.services.seats) === null || _a === void 0 ? void 0 : _a.length) >=
                                    services_1.seatMap.segments.filter(function (segment) { return segment.availableSeatsCount > 0; }).length);
                        });
                        if (allRequiredSeatsAreSelected_1) {
                            props.onProceed();
                        }
                        else {
                            setRequiredSeatsModalIsVisible(true);
                            setIsLoading(false);
                        }
                    }
                    return [3 /*break*/, 3];
                case 2:
                    setRequiredSeatsModalIsVisible(true);
                    setIsLoading(false);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [travellersWithSeats]);
    var seatsPriceToPay = React.useMemo(function () {
        var price = {
            amount: 0,
            currency: null
        };
        passengers.forEach(function (passenger) {
            passenger.seats.forEach(function (seat) {
                var _a, _b;
                price.amount += (_a = seat.price) === null || _a === void 0 ? void 0 : _a.amount;
                if ((_b = seat.price) === null || _b === void 0 ? void 0 : _b.currency) {
                    price.currency = seat.price.currency;
                }
            });
        });
        return price;
    }, [passengers]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Seats, { onProceed: onProceed, passengers: passengers, travellers: travellersWithSeats, setSeatCallback: setSeatCallback, setClearSeatCallback: setClearSeatCallback, isLoading: isLoading, setIsLoading: setIsLoading, seatsPriceToPay: seatsPriceToPay, onSegmentClear: setOnClearSegmentCallback }),
        React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
            React.createElement(Modal, { maxWidth: "sm", open: requiredSeatsModaIsVisible, onClose: closeRequiredSeatsModal, scroll: "paper" },
                React.createElement(SuggestionModalContent, { text: t('To complete online check-in you must choose seats for all passengers on all flight directions'), icon: SeatIcon, agreeButtonText: 'OK', onAgree: closeRequiredSeatsModal }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: requiredSeatsModaIsVisible, onClose: closeRequiredSeatsModal },
                React.createElement(SuggestionModalContent, { insideSlideBottom: true, icon: SeatIcon, text: t('To complete online check-in you must choose seats for all passengers on all flight directions'), agreeButtonText: 'OK', onAgree: closeRequiredSeatsModal }))),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred during the check-in process. Please try again later or contact a customer support service.'), errorMessage: (_a = checkinError === null || checkinError === void 0 ? void 0 : checkinError.message) !== null && _a !== void 0 ? _a : '', onClose: closeWarningModal, onButtonClick: closeWarningModal, buttonText: t('OK, thank you'), isOpen: !!checkinError }),
        isLoading && React.createElement(SimpleLoader, null)));
};
export default PreselectedSeats;
