import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import Dialog from '../../../../../../Service/components/Dialog/Dialog';
import { useTranslation } from 'react-i18next';
import SeatMap from '../../../../../../SeatMap/components/Component';
import BaggagePage from '../../../../../../BaggagePage/components/BaggagePage';
import { useDispatch, useSelector } from 'react-redux';
import { getBaggage, getIncludedBaggage, getIsOddOrderId, getMeals, getSegmentsForServices } from '../../../../../store/order/selectors';
import { setSplitTest, SplitTest } from '../../../../../../analytics';
import WarningModal from '../../../../../../WarningModal';
import { MOBILE_MIN_WIDTH } from '../../../../../../utils';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../../../../theme';
import { MealWrapper } from '../../../../../../MealPage/components/MealWrapper';
import MealService from '../../../../../../MealService';
import { ServiceType } from '../../types';
import { getBaggageReduxCallbacks, getMealReduxCallbacks, getSeatReduxCallbacks } from '../../../../../utils';
import { useContext, useMemo } from 'react';
import { getSelectedBaggage } from '../../../../../store/passengers/selectors';
import { ShowLoaderAfterBookingContext } from '../../../ServicePage/ServicePageCtx';
import SimpleLoader from '../../../../../../SimpleLoader';
import { isServicesSaving } from '../../../../../store/servicesIsSaving/selectors';
import { useConfig } from '../../../../../../context';
import { getSagaRequestErrors } from '../../../../../store/sagaRequestErrors/selectors';
import { setSagaRequestErrors } from '../../../../../store/sagaRequestErrors/actions';
import { useIsTerminal } from '../../../../../../hooks';
var ServiceCardDialog = function (_a) {
    var _b;
    var onClose = _a.onClose, openedModal = _a.openedModal, mealTotalPrice = _a.mealTotalPrice, selectedMeals = _a.selectedMeals, order = _a.order, passengers = _a.passengers, passengerLabels = _a.passengerLabels, addService = _a.addService, removeService = _a.removeService, setService = _a.setService, deleteAllTypesOnSegment = _a.deleteAllTypesOnSegment, addServiceWithoutRequest = _a.addServiceWithoutRequest, removeServiceWithoutRequest = _a.removeServiceWithoutRequest;
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').OrderPaidServiceCards;
    var segments = useSelector(getSegmentsForServices);
    var isOddOrderId = useSelector(getIsOddOrderId);
    var baggage = useSelector(getBaggage);
    var meals = useSelector(getMeals);
    var includedBaggage = useSelector(getIncludedBaggage);
    var selectedBaggage = useSelector(getSelectedBaggage);
    var isOrderServicesSaving = useSelector(isServicesSaving);
    var showLoaderafterBooking = useContext(ShowLoaderAfterBookingContext);
    var sagaRequestErrors = useSelector(getSagaRequestErrors);
    var dispatch = useDispatch();
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _c = useConfig().global, maxBaggage = _c.maxBaggage, maxBaggagePerPassenger = _c.maxBaggagePerPassenger;
    var isTerminal = useIsTerminal();
    var simpleBaggageSelector = isOddOrderId && (segments.length === 1 || isMobile);
    if (typeof maxBaggage === 'number' || typeof maxBaggagePerPassenger === 'number') {
        simpleBaggageSelector = false;
    }
    if (isTerminal) {
        simpleBaggageSelector = false;
    }
    setSplitTest(SplitTest.OneButtonBaggageSelector, simpleBaggageSelector);
    var fareFamiliesMap = new Map();
    order.flight.segmentGroups.forEach(function (segmentGroup) {
        var fareFamily = segmentGroup.fareFamily;
        if (!fareFamily) {
            return;
        }
        if (!fareFamiliesMap.has(fareFamily.id)) {
            fareFamiliesMap.set(fareFamily.id, {
                fareFamily: fareFamily,
                segmentIds: []
            });
        }
        segmentGroup.segments.forEach(function (segment) { return fareFamiliesMap.get(fareFamily.id).segmentIds.push(segment.id); });
    });
    var baggageCallbacks = useMemo(function () { return getBaggageReduxCallbacks(passengers, baggage, segments, addService, removeService, setService); }, [baggage, passengers, segments]);
    var mealCallbacks = isTerminal
        ? useMemo(function () {
            return getMealReduxCallbacks(addServiceWithoutRequest, removeServiceWithoutRequest, deleteAllTypesOnSegment);
        }, [addServiceWithoutRequest, removeServiceWithoutRequest, deleteAllTypesOnSegment])
        : useMemo(function () { return getMealReduxCallbacks(addService, removeService, deleteAllTypesOnSegment); }, [
            addService,
            removeService,
            deleteAllTypesOnSegment
        ]);
    var seatMapCallbacks = useMemo(function () { return getSeatReduxCallbacks(passengers, setService, removeService); }, [
        passengers,
        setService,
        removeService
    ]);
    var baggageStyles = useMemo(function () { return ({ BaggagePage: { BaggagePage: { baggage: css.baggagePage__baggage } } }); }, []);
    var closeWarningModalHandler = function () {
        dispatch(setSagaRequestErrors(null));
    };
    if (Boolean(sagaRequestErrors)) {
        return (React.createElement(WarningModal, { title: t('Oops...'), content: t(t('An unexpected error has occurred during the booking process. Please try again later or contact a customer support service')), errorMessage: sagaRequestErrors, onClose: closeWarningModalHandler, onButtonClick: closeWarningModalHandler, buttonText: t('OK, thank you'), isOpen: Boolean(sagaRequestErrors) }));
    }
    return (React.createElement(Dialog, { dialogType: 'fullscreen', isOpen: !!openedModal, dialogHeader: React.createElement(React.Fragment, null, t(openedModal)), totalPrice: openedModal === ServiceType.Meal && !isMobile && mealTotalPrice, withoutControls: true, dialogContentClassName: cn((_b = {},
            _b[css.baggage__dialogContent] = openedModal === ServiceType.Baggage,
            _b)), onClose: onClose, serviceType: openedModal, content: React.createElement(React.Fragment, null,
            showLoaderafterBooking && React.createElement(SimpleLoader, null),
            openedModal === ServiceType.Seats && (React.createElement(SeatMap, __assign({ passengers: passengers, showRecommendations: false, seatMap: order.flight.seatMap, fullScreen: false, onProceed: onClose, isLoading: isOrderServicesSaving }, seatMapCallbacks))),
            openedModal === ServiceType.Baggage && (React.createElement(ThemeProvider, { value: baggageStyles },
                React.createElement(BaggagePage, __assign({ simpleBaggageSelector: simpleBaggageSelector, baggage: __spreadArray(__spreadArray([], __read(includedBaggage), false), __read(baggage), false), segments: segments, passengers: passengers, passengerLabels: passengerLabels, selectedBaggage: selectedBaggage, isLoading: isOrderServicesSaving, hideBanner: isMobile }, baggageCallbacks)))),
            openedModal === ServiceType.Meal && (React.createElement("div", { className: css.meal__dialogContent },
                React.createElement(MealService, { meals: meals, segments: segments, selectedMeals: selectedMeals, isLoading: isOrderServicesSaving, onMobileBack: function () { return null; }, onMobileProceed: function () { return null; }, mealRenderer: function (meal, segmentId, isSingleMeal) { return (React.createElement(MealWrapper, __assign({ fullSize: isSingleMeal, key: meal.id, meal: meal, segmentId: segmentId, selectedMeals: selectedMeals, passengers: passengers.map(function (_a) {
                            var id = _a.id;
                            return ({
                                id: id,
                                name: passengerLabels[id]
                            });
                        }) }, mealCallbacks))); } })))) }));
};
export default ServiceCardDialog;
