import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import ServicePopup from './ServicePopup/ServicePopup';
import Header from './Header/Header';
import ServicesContainer from './ServicesContainer/ServicesContainer';
import { convertSelectedServicesToState, getSelectedServices } from '../utils';
import { initI18n } from '../../utils';
import { useServices } from './hooks';
import { extraServicesReducer } from './reducer';
import { useSelector } from 'react-redux';
import { getTravellers } from '../../Checkout/store/order/selectors';
import FullScreenModal from '../../LEO/FullScreenModal/FullScreenModal';
import { useTranslation } from 'react-i18next';
initI18n('ExtraServices');
var ExtraServicesModal = function (_a) {
    var passengers = _a.passengers, selectedServices = _a.selectedServices, gdsServices = _a.services, segments = _a.segments, onConfirm = _a.onConfirm, onClose = _a.onClose, open = _a.open;
    var _b = __read(React.useReducer(extraServicesReducer, selectedServices, function (selected) {
        return convertSelectedServicesToState(selected, gdsServices);
    }), 2), state = _b[0], dispatch = _b[1];
    var _c = __read(React.useState(null), 2), selectedService = _c[0], setService = _c[1];
    var travellers = useSelector(getTravellers);
    var t = useTranslation('ServiceCard').t;
    var travellersServices = React.useMemo(function () {
        return travellers.reduce(function (acc, traveller) {
            var _a;
            var _b, _c, _d;
            var travellerServices = ((_d = (_c = (_b = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.reduce(function (servicesAcc, service) {
                var _a, _b;
                if (servicesAcc.hasOwnProperty(service.serviceId)) {
                    return __assign(__assign({}, servicesAcc), (_a = {}, _a[service.serviceId] = __spreadArray(__spreadArray([], __read(servicesAcc[service.serviceId]), false), [service], false), _a));
                }
                return __assign(__assign({}, servicesAcc), (_b = {}, _b[service.serviceId] = [service], _b));
            }, {})) || {};
            return __assign(__assign({}, acc), (_a = {}, _a[traveller.id] = travellerServices, _a));
        }, {});
    }, [travellers]);
    React.useEffect(function () {
        dispatch({
            type: 'state',
            payload: convertSelectedServicesToState(selectedServices, gdsServices)
        });
    }, [selectedServices]);
    var onSaveServiceHandler = function (serviceId, selected) {
        selected.forEach(function (selected) {
            selected.segmentIds.forEach(function (segmentId) {
                dispatch({
                    type: 'set',
                    payload: {
                        serviceId: serviceId,
                        segmentId: segmentId,
                        count: selected.count,
                        passengerId: selected.passengerId
                    }
                });
            });
        });
        setService(null);
    };
    var onServiceClearHandler = function (serviceId) {
        dispatch({
            type: 'clear',
            payload: {
                serviceId: serviceId
            }
        });
    };
    var onClearAllHandler = function () {
        dispatch({
            type: 'clearAll',
            travellersServices: travellersServices
        });
    };
    var onConfirmHandler = function () {
        onConfirm(getSelectedServices(state, gdsServices));
    };
    var services = useServices(gdsServices, state, segments, passengers);
    return (React.createElement(FullScreenModal, { open: open, onClose: onClose, title: t('Extra services'), header: React.createElement(Header, { services: services, onClear: onClearAllHandler, onConfirm: onConfirmHandler, onClose: onClose }), headerAlignment: 'right' },
        React.createElement(ServicesContainer, { services: services, setService: setService, onClear: onServiceClearHandler, segments: segments, travellersServices: travellersServices }),
        selectedService && (React.createElement(ServicePopup, { servicePayload: selectedService, passengers: passengers.filter(function (passenger) {
                return selectedService.availablePassengers.includes(passenger.id);
            }), onClose: function () { return setService(null); }, segments: segments, onSave: onSaveServiceHandler, selected: selectedService ? state[selectedService.service.id] : null, travellersServices: travellersServices }))));
};
export default ExtraServicesModal;
