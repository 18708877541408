import { __assign, __read, __rest } from "tslib";
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { OrderStatus } from '@websky/graphql';
import { useTheme } from '../../../../theme';
import OrderControlsComponent from '../../../../OrderCard/components/OrderControls/OrderControls';
import { Button } from '../../../../index';
import Void from '../../../Void/Void';
import ServiceCardVoid from '../../../ServicesCardVoid/ServicesCardVoid';
import OrderCoupons from '../OrderCoupons/OrderCoupons';
import { orderTravellerOrderCouponAdapter } from '../OrderCoupons/adapters';
import { getOrder } from '../../../../Checkout/store/order/selectors';
import { useVoid } from '../../../Void/hooks';
import { fillOrder } from '../../../../Checkout/store/order/actions';
import { useCheckoutServicesCardsAvailability } from '../../../../CheckoutAdditionalService/components/hooks';
import VoidPopup from '../../../Exchange/VoidPopup/VoidPopup';
import DownloadDocumentsWrapper from '../../../DownloadDocuments/DownloadDocumentsWrapper';
import ResendTickets from '../../../ResendTickets';
import { useToggleable } from '../../../../hooks';
import RefundResultModal from '../../../ServicesCardVoid/Components/RefundResultModal/RefundResultModal';
var OrderControls = function (_a) {
    var exchangeAvailable = _a.exchangeAvailable, refundAvailable = _a.refundAvailable, onClick = _a.onClick, onDownloadTicket = _a.onDownloadTicket, isDocumentsDownloading = _a.isDocumentsDownloading, props = __rest(_a, ["exchangeAvailable", "refundAvailable", "onClick", "onDownloadTicket", "isDocumentsDownloading"]);
    var css = useTheme('LeoOrderCard').OrderControls;
    var t = useTranslation('OrderCard').t;
    var dispatch = useDispatch();
    var order = useSelector(getOrder);
    var voidProps = useVoid(order);
    var _b = __read(useState(), 2), refundOrderResult = _b[0], setRefundOrderResult = _b[1];
    var _c = useToggleable(false), isModalOpen = _c.isOpen, openModal = _c.open, closeModal = _c.close;
    var servicesAvailability = useCheckoutServicesCardsAvailability();
    var onClickHandler = useCallback(function (type) { return function () {
        onClick(type);
    }; }, [onClick]);
    var onDownloadTicketHandler = useCallback(function (documentType, travellerId) {
        onDownloadTicket(documentType, travellerId);
    }, [onDownloadTicket]);
    var isConfirmed = order.status === OrderStatus.Confirmed;
    var show = isConfirmed ||
        exchangeAvailable ||
        refundAvailable ||
        voidProps.isVoidAvailable ||
        voidProps.isGdsServicesVoidRefundAvailable;
    return (React.createElement(React.Fragment, null,
        React.createElement(OrderControlsComponent, __assign({}, props, { show: show, text: React.createElement(React.Fragment, null,
                isConfirmed && (React.createElement(React.Fragment, null,
                    React.createElement(DownloadDocumentsWrapper, { isDocumentsDownload: isDocumentsDownloading, onDownloadClick: onDownloadTicketHandler, renderDownloadButton: function (openModal) { return (React.createElement(Button, { size: "small", className: css.button, onClick: openModal }, t('Download'))); } }),
                    React.createElement(ResendTickets, { renderTrigger: function (handleClick) { return (React.createElement(Button, { size: "small", className: css.button, onClick: handleClick }, t('Mail'))); } }))),
                order.travellers.length > 0 && (React.createElement(OrderCoupons, { passengers: order.travellers.map(orderTravellerOrderCouponAdapter), couponsButtonRenderer: function (_a) {
                        var onClick = _a.onClick;
                        return (React.createElement(Button, { className: css.button, onClick: onClick }, t('Coupons info')));
                    } }))), buttons: React.createElement(React.Fragment, null,
                exchangeAvailable && (React.createElement(VoidPopup, { className: cn(css.button, css.button_outlined), goToExchange: onClickHandler('exchange') })),
                refundAvailable && (React.createElement(Button, { onClick: onClickHandler('refund'), className: cn(css.button, css.button_outlined), variant: "outline" }, t('Refund'))),
                React.createElement(Void, { open: voidProps.isModalOpen, isVoidAvailable: voidProps.isVoidAvailable, closeModal: voidProps.closeModal, openModal: voidProps.openModal, voidOrder: voidProps.voidOrder, onVoidFail: function () { return console.log('failed void'); }, onVoidSuccess: function (order) { return dispatch(fillOrder(order)); }, renderVoidButton: function (_a) {
                        var onClick = _a.onClick;
                        return (React.createElement(Button, { className: cn(css.button, css.button_outlined), onClick: onClick, variant: 'outline' }, t('Void')));
                    } }),
                voidProps.isGdsServicesVoidRefundAvailable && (React.createElement(ServiceCardVoid, { servicesAvailability: servicesAvailability, setRefundOrderResult: setRefundOrderResult, openModal: openModal, renderRefundVoidButton: function (_a) {
                        var onClick = _a.onClick;
                        return (React.createElement(Button, { className: cn(css.button, css.button_outlined), onClick: onClick, variant: 'outline' }, t('Void/Refund serv.')));
                    } }))) })),
        isModalOpen && React.createElement(RefundResultModal, { closeModal: closeModal, refundOrderResult: refundOrderResult })));
};
export default OrderControls;
