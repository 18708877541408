import { __assign, __read, __spreadArray } from "tslib";
import { GdsServiceProductStatus, OrderAdditionalServiceGdsServiceServiceType, TravellerFieldEnum } from '../../__generated__/globalTypes';
import { getPassengerName } from '../../utils';
import { deleteAllTypesOnSegment } from '../../Checkout/store/selectedServices/actions';
import { getCurrency } from '../../cache';
var selectPassengerName = function (id, passengers) {
    var lastName = passengers[id].values.find(function (value) { return value.type === TravellerFieldEnum.LastName; })
        .value;
    var firstName = passengers[id].values.find(function (value) { return value.type === TravellerFieldEnum.FirstName; })
        .value;
    return getPassengerName(firstName, lastName);
};
export var passengerNameById = function (id, passengers, linkedPassengerIds) {
    if (linkedPassengerIds === void 0) { linkedPassengerIds = null; }
    var basePassenger = selectPassengerName(id, passengers);
    var linkedPassengersNames = linkedPassengerIds === null || linkedPassengerIds === void 0 ? void 0 : linkedPassengerIds.map(function (passengerId) {
        return selectPassengerName(passengerId, passengers);
    }).join(', ');
    return (linkedPassengersNames === null || linkedPassengersNames === void 0 ? void 0 : linkedPassengersNames.length) ? "".concat(basePassenger, ", ").concat(linkedPassengersNames) : "".concat(basePassenger);
};
export var segmentBySegmentId = function (id, order) {
    var departure = order.flight.segments[id].segment.departure.airport.iata;
    var arrival = order.flight.segments[id].segment.arrival.airport.iata;
    return "".concat(departure, " \u2014 ").concat(arrival);
};
export var calculateSummary = function (services, order, flightSegments) {
    var price = null;
    var servicesIds = [];
    var orderServicesByIdMap = new Map();
    order.additionalServices.gdsServices.services.forEach(function (service) {
        orderServicesByIdMap.set(service.id, service);
    });
    Object.values(services).forEach(function (segments) {
        Object.entries(segments).forEach(function (_a) {
            var _b;
            var _c = __read(_a, 2), segment = _c[0], services = _c[1];
            (_b = services === null || services === void 0 ? void 0 : services.additional) === null || _b === void 0 ? void 0 : _b.forEach(function (service) {
                var serviceInfo = orderServicesByIdMap.get(service.serviceId);
                var unpaidCount = service.count - service.confirmedCount;
                if (!price) {
                    var amount = serviceInfo.price.amount * unpaidCount;
                    if (serviceInfo.type === OrderAdditionalServiceGdsServiceServiceType.Baggage &&
                        service.segmentIds.length === flightSegments.length) {
                        amount *= flightSegments.length;
                    }
                    price = __assign(__assign({}, serviceInfo.price), { amount: amount });
                }
                else {
                    if (serviceInfo.type === OrderAdditionalServiceGdsServiceServiceType.Baggage &&
                        service.segmentIds.length === flightSegments.length) {
                        price.amount += serviceInfo.price.amount * unpaidCount * flightSegments.length;
                    }
                    else {
                        price.amount += serviceInfo.price.amount * unpaidCount;
                    }
                }
                servicesIds.push(service.serviceId);
            });
        });
    });
    return {
        totalPrice: price,
        servicesIds: servicesIds
    };
};
export var deleteServicesByType = function (servicesIds, type, dispatch) {
    dispatch(deleteAllTypesOnSegment({
        type: type,
        serviceId: servicesIds,
        deleteOnAllSegments: true
    }));
};
export var isEmptyServices = function (services) {
    return !Object.entries(services).some(function (_a) {
        var _b = __read(_a, 2), passengerId = _b[0], segments = _b[1];
        return Object.entries(segments).some(function (_a) {
            var _b, _c;
            var _d = __read(_a, 2), segmentId = _d[0], services = _d[1];
            return ((_b = services === null || services === void 0 ? void 0 : services.additional) === null || _b === void 0 ? void 0 : _b.length) || ((_c = services === null || services === void 0 ? void 0 : services.included) === null || _c === void 0 ? void 0 : _c.length);
        });
    });
};
export var calculateShowClearValue = function (services, type) {
    var show = false;
    Object.values(services).forEach(function (passengerSegments) {
        Object.values(passengerSegments).forEach(function (segment) {
            var _a, _b;
            if (type === OrderAdditionalServiceGdsServiceServiceType.Seat) {
                if (!((_a = segment === null || segment === void 0 ? void 0 : segment.additional) === null || _a === void 0 ? void 0 : _a.every(function (service) { return service.isConfirmed; }))) {
                    show = true;
                }
            }
            else {
                if (!((_b = segment === null || segment === void 0 ? void 0 : segment.additional) === null || _b === void 0 ? void 0 : _b.every(function (service) { return service.count === service.confirmedCount; }))) {
                    show = true;
                }
            }
        });
    });
    return show;
};
export var isGdsServiceType = function (service) {
    return service.hasOwnProperty('serviceId');
};
export var isSeatServiceType = function (service) {
    var _a, _b;
    return (_b = (_a = service === null || service === void 0 ? void 0 : service.seat) === null || _a === void 0 ? void 0 : _a.seatService) === null || _b === void 0 ? void 0 : _b.hasOwnProperty('id');
};
export var isSeatServiceWithEmd = function (service) {
    var _a;
    return !!((_a = service.product) === null || _a === void 0 ? void 0 : _a.emdNumber);
};
export var selectProductsCanVoidExchange = function (service, countProducts) {
    var _a, _b, _c;
    if (countProducts === void 0) { countProducts = null; }
    if (isGdsServiceType(service) && (service === null || service === void 0 ? void 0 : service.products)) {
        var productsIds = (_c = (_b = (_a = service === null || service === void 0 ? void 0 : service.products) === null || _a === void 0 ? void 0 : _a.filter(function (product) { return product.status === GdsServiceProductStatus.Ticketed; })) === null || _b === void 0 ? void 0 : _b.sort(function (productA, productB) {
            if (productA.voidingTimeLimitInSeconds < productB.voidingTimeLimitInSeconds) {
                return -1;
            }
            if (productA.voidingTimeLimitInSeconds > productB.voidingTimeLimitInSeconds) {
                return -1;
            }
            return 0;
        })) === null || _c === void 0 ? void 0 : _c.map(function (product) { return product.id; });
        if (countProducts) {
            return productsIds.slice(0, countProducts);
        }
        var productId = productsIds === null || productsIds === void 0 ? void 0 : productsIds[0];
        return productId !== undefined ? [productId] : [];
    }
    if (isSeatServiceType(service) && (service === null || service === void 0 ? void 0 : service.product) && service.product.status === GdsServiceProductStatus.Ticketed) {
        return [service.product.id];
    }
    return [];
};
export var collectVoidRefundServices = function (cardServices, type) {
    var services = {};
    cardServices === null || cardServices === void 0 ? void 0 : cardServices.forEach(function (gdsInfo) {
        gdsInfo.gdsServiceProductsRefundInfo.forEach(function (s) {
            var _a, _b, _c, _d;
            var _e, _f, _g, _h;
            if ((type === 'refund' && s.refundable && !s.isVoid) || (type === 'void' && s.isVoid)) {
                if (services[s.product.emdNumber]) {
                    services[s.product.emdNumber] = __assign(__assign({}, services[s.product.emdNumber]), { services: __assign(__assign({}, services[s.product.emdNumber].services), (_a = {}, _a[gdsInfo.service.id] = __assign(__assign({}, (((_e = services[s.product.emdNumber].services) === null || _e === void 0 ? void 0 : _e[gdsInfo.service.id]) || {})), (_b = {}, _b[s.product.passengerId] = {
                            service: gdsInfo.service,
                            products: __spreadArray(__spreadArray([], __read((((_h = (_g = (_f = services[s.product.emdNumber].services) === null || _f === void 0 ? void 0 : _f[gdsInfo.service.id]) === null || _g === void 0 ? void 0 : _g[s.product.passengerId]) === null || _h === void 0 ? void 0 : _h.products) || [])), false), [
                                s.product
                            ], false)
                        }, _b)), _a)), price: __assign(__assign({}, services[s.product.emdNumber].price), { amount: services[s.product.emdNumber].price.amount + s.sumToRefund.amount }) });
                }
                else {
                    services[s.product.emdNumber] = {
                        services: (_c = {},
                            _c[gdsInfo.service.id] = (_d = {},
                                _d[s.product.passengerId] = {
                                    service: gdsInfo.service,
                                    products: [s.product]
                                },
                                _d),
                            _c),
                        price: s.sumToRefund
                    };
                }
            }
        });
    });
    return services;
};
export var selectCheckedProducts = function (values, productsIdsByEmd) {
    return Object.entries(values).reduce(function (acc, _a) {
        var _b = __read(_a, 2), emd = _b[0], isChecked = _b[1];
        if (isChecked) {
            return __spreadArray(__spreadArray([], __read(acc), false), __read(productsIdsByEmd.get(emd.replace(/'/g, ''))), false);
        }
        return acc;
    }, []);
};
export var collectExchangeVoidServices = function (calculation, servicesIds) {
    var _a;
    var cardServices = ((_a = calculation === null || calculation === void 0 ? void 0 : calculation.gdsServicesRefundInfo) === null || _a === void 0 ? void 0 : _a.filter(function (gdsInfo) { return servicesIds.includes(gdsInfo.service.id); })) || [];
    var servicesForRefund = collectVoidRefundServices(cardServices, 'refund');
    var servicesForVoid = collectVoidRefundServices(cardServices, 'void');
    return {
        servicesForRefund: servicesForRefund,
        servicesForVoid: servicesForVoid
    };
};
export var isExchangeVoidServicesEmpty = function (voidRefundServices) {
    return (!Object.keys(voidRefundServices.servicesForRefund).length &&
        !Object.keys(voidRefundServices.servicesForVoid).length);
};
var addProductToServicesByEmd = function (product, servicesByEmd, travellerId, segmentId, service) {
    var _a, _b, _c, _d, _e, _f;
    servicesByEmd[product.emdNumber] = servicesByEmd[product.emdNumber] || {};
    servicesByEmd[product.emdNumber][travellerId] = ((_a = servicesByEmd[product.emdNumber]) === null || _a === void 0 ? void 0 : _a[travellerId]) || {};
    servicesByEmd[product.emdNumber][travellerId][segmentId] =
        ((_c = (_b = servicesByEmd[product.emdNumber]) === null || _b === void 0 ? void 0 : _b[travellerId]) === null || _c === void 0 ? void 0 : _c[segmentId]) || {};
    servicesByEmd[product.emdNumber][travellerId][segmentId].additional =
        ((_f = (_e = (_d = servicesByEmd[product.emdNumber]) === null || _d === void 0 ? void 0 : _d[travellerId]) === null || _e === void 0 ? void 0 : _e[segmentId]) === null || _f === void 0 ? void 0 : _f.additional) || [];
    if (servicesByEmd[product.emdNumber][travellerId][segmentId].additional.length > 0) {
        var servicesWithUpdatedProducts = servicesByEmd[product.emdNumber][travellerId][segmentId].additional.map(function (s) {
            if (s.serviceId === service.serviceId) {
                var updatedService = __assign(__assign({}, s), { products: __spreadArray(__spreadArray([], __read(s.products), false), [product], false) });
                updatedService.count = updatedService.products.length;
                updatedService.confirmedCount = updatedService.products.filter(function (product) { return product.status === GdsServiceProductStatus.Ticketed; }).length;
                return updatedService;
            }
            return s;
        });
        servicesByEmd[product.emdNumber][travellerId][segmentId].additional = servicesWithUpdatedProducts;
    }
    else {
        var insertedService = __assign(__assign({}, service), { products: [product] });
        insertedService.count = insertedService.products.length;
        insertedService.confirmedCount = insertedService.products.filter(function (product) { return product.status === GdsServiceProductStatus.Ticketed; }).length;
        servicesByEmd[product.emdNumber][travellerId][segmentId].additional = [insertedService];
    }
    return servicesByEmd;
};
var addProductToproductsByEmdMap = function (productsByEmdMap, product) {
    var _a;
    productsByEmdMap.set(product.emdNumber, __spreadArray(__spreadArray([], __read(((_a = productsByEmdMap.get(product.emdNumber)) !== null && _a !== void 0 ? _a : [])), false), [
        parseInt(product.id, 10)
    ], false));
    return productsByEmdMap;
};
export var groupByEmd = function (services) {
    var servicesByEmd = {};
    var productsByEmdMap = new Map();
    Object.entries(services).forEach(function (_a) {
        var _b = __read(_a, 2), travellerId = _b[0], travellerSegments = _b[1];
        Object.entries(travellerSegments).forEach(function (_a) {
            var _b = __read(_a, 2), segmentId = _b[0], segmentServices = _b[1];
            segmentServices.additional.forEach(function (service) {
                if (isSeatServiceType(service) && isSeatServiceWithEmd(service)) {
                    var product = service.product;
                    servicesByEmd = addProductToServicesByEmd(product, servicesByEmd, travellerId, segmentId, service);
                    productsByEmdMap = addProductToproductsByEmdMap(productsByEmdMap, product);
                }
                if (isGdsServiceType(service)) {
                    service.products.forEach(function (product) {
                        if (product.emdNumber) {
                            servicesByEmd = addProductToServicesByEmd(product, servicesByEmd, travellerId, segmentId, service);
                            productsByEmdMap = addProductToproductsByEmdMap(productsByEmdMap, product);
                        }
                    });
                }
            });
        });
    });
    return {
        servicesByEmd: servicesByEmd,
        productsByEmdMap: productsByEmdMap
    };
};
export var groupSumToRefundVoidByEmd = function (calculation) {
    var emds = new Map();
    calculation.gdsServicesRefundInfo.forEach(function (refundInfo) {
        var _a;
        (_a = refundInfo.gdsServiceProductsRefundInfo) === null || _a === void 0 ? void 0 : _a.forEach(function (infoItem) {
            var _a;
            var emd = (_a = infoItem === null || infoItem === void 0 ? void 0 : infoItem.product) === null || _a === void 0 ? void 0 : _a.emdNumber;
            if (emd) {
                if (emds.has(emd)) {
                    emds.set(emd, __assign(__assign({}, emds.get(emd)), { amount: emds.get(emd).amount + infoItem.sumToRefund.amount }));
                }
                else {
                    emds.set(emd, infoItem.sumToRefund);
                }
            }
        });
    });
    return emds;
};
export var calculateMinPrice = function (additionalGdsServices, type) {
    return additionalGdsServices
        .filter(function (service) { return service.type === type; })
        .reduce(function (acc, service, index) {
        if (index === 0) {
            return service.price;
        }
        if (service.price.amount < acc.amount) {
            return service.price;
        }
        return acc;
    }, { amount: 0, currency: getCurrency() });
};
export var getItemsForVoidRefund = function (serviceCardServices, isServiceTypeAvailable) {
    var productsIds = [];
    var servicesIds = [];
    if (!isServiceTypeAvailable) {
        return {
            productsIds: productsIds,
            servicesIds: servicesIds
        };
    }
    Object.values(serviceCardServices).forEach(function (segment) {
        Object.values(segment).forEach(function (s) {
            var _a;
            (_a = s === null || s === void 0 ? void 0 : s.additional) === null || _a === void 0 ? void 0 : _a.forEach(function (service) {
                if (isGdsServiceType(service)) {
                    servicesIds.push(service.serviceId);
                }
                if (isSeatServiceType(service) && isSeatServiceWithEmd(service)) {
                    servicesIds.push(service.seat.seatService.id);
                }
                var _a = __read(selectProductsCanVoidExchange(service), 1), productId = _a[0];
                if (productId !== undefined) {
                    productsIds.push(productId);
                }
            });
        });
    });
    return {
        productsIds: productsIds,
        servicesIds: servicesIds
    };
};
export var hasAtLeastOneProductToVoidRefund = function (item) {
    var _a;
    return ((_a = item.productsIds) === null || _a === void 0 ? void 0 : _a.length) > 0;
};
