import { __assign } from "tslib";
import * as React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { useGraphQLClient } from '../../graphql-client';
import LoginPage from './LoginPage/LoginPage';
import { initI18n } from '../../utils';
initI18n('LoginPage');
export var Component = function (props) {
    var client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(LoginPage, __assign({}, props))));
};
Component.displayName = 'LoginPage';
export default Component;
