import { __assign } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getSegments } from '../../../../../store/order/selectors';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../../Modal';
import { Button } from '../../../../../../../index';
import { useTheme } from '../../../../../../../theme';
import { initI18n } from '../../../../../../../utils';
import MediaQuery from '../../../../../../../MediaQuery/MediaQuery';
import { SelectableSegmentDesktop, SelectableSegmentMobile } from '../../../../../../../SelectableSegment';
initI18n('SelectSegment');
var SelectSegmentsModal = function (props) {
    var onSelect = props.onSegmentSelect, selectedSegments = props.selectedSegments, onConfirm = props.onConfirm;
    var css = useTheme('Checkin').SelectSegmentsModal;
    var segments = useSelector(getSegments);
    var t = useTranslation('SelectSegment').t;
    var renderSegments = useCallback(function (segment) { return (React.createElement(React.Fragment, { key: segment.id },
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(SelectableSegmentDesktop, { segment: segment, onChange: onSelect, selected: selectedSegments.has(segment.id) })),
        React.createElement(MediaQuery, { maxWidth: 'mobile' },
            React.createElement(SelectableSegmentMobile, { segment: segment, onChange: onSelect, selected: selectedSegments.has(segment.id) })))); }, [selectedSegments, segments]);
    return (React.createElement(Modal, __assign({ classes: { paper: css.root, root: css.dialogRoot }, maxWidth: 'xs' }, props),
        React.createElement("div", { className: css.header }, t('Check-in for flights')),
        React.createElement("div", { className: css.content },
            segments.map(renderSegments),
            React.createElement(Button, { className: css.button, disabled: !selectedSegments.size, onClick: onConfirm }, t('Confirm')))));
};
export default SelectSegmentsModal;
