import i18n from 'i18next';
export var getLabel = function (miles) {
    var lastDigit = miles;
    if (miles < 100) {
        lastDigit = miles % 10;
    }
    else {
        lastDigit = miles % 100;
    }
    if (lastDigit === 1) {
        return i18n.t('PriceInMiles:miles_1');
    }
    if ((lastDigit >= 10 && lastDigit <= 20) || lastDigit === 0) {
        return i18n.t('PriceInMiles:miles_2');
    }
    return i18n.t('PriceInMiles:miles_3');
};
