import { __assign } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getPassengers, getTotalPassengersCount } from '../../store/passengers/selectors';
import { getPassengerTypes } from '../../store/passengerTypes/selectors';
import { PassengerType } from '../../../enums';
import { PassTypeAgeCategoryType } from '../../../__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import { MAX_PASSENGERS_COUNT } from '../../../SearchForm/store/passengers/reducers';
export var useSearchFormValidation = function (proMode) {
    var t = useTranslation('SearchForm').t;
    var passengers = useSelector(getPassengers);
    var totalPassengersCount = useSelector(getTotalPassengersCount);
    var passengerTypes = useSelector(getPassengerTypes);
    var passengerTypesMapByCode = React.useMemo(function () {
        var passengerTypesMap = new Map();
        passengerTypes.forEach(function (passengerType) {
            passengerTypesMap.set(passengerType.code, passengerType);
        });
        return passengerTypesMap;
    }, [passengerTypes]);
    var _a = React.useMemo(function () {
        return passengers.reduce(function (counter, passenger) {
            var _a, _b;
            var newCounter = __assign({}, counter);
            if (passenger.count > 0) {
                if (passenger.type === PassengerType.Adult ||
                    ((_a = passengerTypesMapByCode.get(passenger.type)) === null || _a === void 0 ? void 0 : _a.ageCategory) === PassTypeAgeCategoryType.Adult) {
                    newCounter.adultsCount = newCounter.adultsCount + passenger.count;
                }
                if (passenger.type === PassengerType.Infant ||
                    passenger.type === PassengerType.InfantWithSeat ||
                    ((_b = passengerTypesMapByCode.get(passenger.type)) === null || _b === void 0 ? void 0 : _b.ageCategory) === PassTypeAgeCategoryType.Infant) {
                    newCounter.allInfantsCount = newCounter.allInfantsCount + passenger.count;
                }
                if (passenger.type === PassengerType.InfantWithSeat) {
                    newCounter.infantsWithoutSeatCount = newCounter.infantsWithoutSeatCount + passenger.count;
                }
            }
            return newCounter;
        }, { adultsCount: 0, allInfantsCount: 0, infantsWithoutSeatCount: 0 });
    }, [passengers, passengerTypesMapByCode]), adultsCount = _a.adultsCount, allInfantsCount = _a.allInfantsCount, infantsWithoutSeatCount = _a.infantsWithoutSeatCount;
    var validate = function () {
        var errors = {};
        if (proMode && infantsWithoutSeatCount > adultsCount) {
            errors["passengers"] = t('For each adult must be only one infant without seat');
        }
        if (proMode && totalPassengersCount === 0) {
            errors["passengers"] = t('Please select passengers');
        }
        if (proMode && adultsCount < 1 && allInfantsCount > 0) {
            errors["passengers"] = t('Select at least one adult passenger. Wherein total number of passengers cannot exceed {{count}}', {
                count: MAX_PASSENGERS_COUNT
            });
        }
        return errors;
    };
    return {
        validate: validate
    };
};
