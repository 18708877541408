import { __assign, __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
import InputComponent from '../../Input/Input';
var Input = function (_a) {
    var _b, _c;
    var success = _a.success, warning = _a.warning, props = __rest(_a, ["success", "warning"]);
    var css = useTheme('LeoInput').LeoInput;
    return (React.createElement(InputComponent, __assign({}, props, { className: cn(css.root, props.className), labelClassName: cn(css.label, props.labelClassName, (_b = {},
            _b[css.label_success] = success,
            _b[css.label_warning] = warning,
            _b)), labelShrinkClassName: css.labelShrink, inputClassName: cn(props.inputClassName, css.input, (_c = {},
            _c[css.success] = success,
            _c[css.warning] = warning,
            _c)), inputDisabledClassName: cn(props.inputDisabledClassName, css.disabled), inputPropsClassName: cn(css.real_input, props.inputPropsClassName), inputErrorClassName: css.error, inputFocusedClassName: css.inputFocused, inputReadonlyClassName: css.inputReadonly, helperClassName: cn(css.helpText, props.helperClassName), labelDisabledClassName: css.labelDisabled, labelErrorClassName: css.labelError })));
};
export default Input;
