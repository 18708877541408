import React from 'react';
import PriceBlock from './PriceBlock/PriceBlock';
import Button from '../../../Button';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
var BaggageTotalPriceBlock = function (_a) {
    var _b;
    var min = _a.min, total = _a.total, onClickButton = _a.onClickButton;
    var css = useTheme('BaggageTotalPriceBlock').BaggageTotalPriceBlock;
    var t = useTranslation('AdditionalBaggage').t;
    var edit = !!(total === null || total === void 0 ? void 0 : total.amount);
    return (React.createElement("div", { className: css.root },
        React.createElement(PriceBlock, { className: css.price, total: total, min: min }),
        React.createElement(Button, { className: cn(css.control, (_b = {},
                _b[css.control__edit] = edit,
                _b)), onClick: onClickButton, size: 'small' }, t(edit ? 'Edit' : 'Add'))));
};
export default BaggageTotalPriceBlock;
