import { __assign } from "tslib";
import { useQuery } from '@apollo/react-hooks';
import * as SeatAvailability from '../../__queries__/SeatAvailability.graphql';
export var useSeatAvailability = function (searchParameters, dates, isOpen) {
    var _a;
    var _b = useQuery(SeatAvailability.SeatAvailability, {
        variables: {
            seatsCount: searchParameters.passengers.reduce(function (count, passenger) { return count + passenger.count; }, 0),
            segments: searchParameters.segments.map(function (segment, index) { return (__assign(__assign({}, segment), { date: (dates === null || dates === void 0 ? void 0 : dates[index]) || segment.date, arrival: { iata: segment.arrival.iata }, departure: { iata: segment.departure.iata } })); })
        },
        skip: !isOpen
    }), data = _b.data, error = _b.error, loading = _b.loading;
    return {
        seatAvailability: !error ? (_a = data === null || data === void 0 ? void 0 : data.GetAvailability) === null || _a === void 0 ? void 0 : _a.flightDirections : null,
        error: error,
        loading: loading
    };
};
