import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Traveller from '../../Traveller';
import { useState } from 'react';
import Loader from '../../Loader';
import * as TravelCompanionsQueries from '../../__queries__/TravelCompanions.graphql';
import * as AccountQueries from '../../__queries__/Account.graphql';
var TravelCompanions = function () {
    var _a, _b, _c;
    var _d = __read(useMutation(TravelCompanionsQueries.CreateNewCompanion), 1), addNewCompanion = _d[0];
    var getTravellerFields = useQuery(TravelCompanionsQueries.DefaultTravellerFields);
    var getCompanions = useQuery(AccountQueries.GetTravellers);
    var _e = __read(useMutation(TravelCompanionsQueries.DeleteTraveller), 1), deleteTravellerProfile = _e[0];
    var _f = __read(useMutation(TravelCompanionsQueries.UpdateTraveller), 1), updateTravellerProfile = _f[0], _g = __read(useState(false), 2), expanded = _g[0], setExpanded = _g[1];
    var onSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var res, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, addNewCompanion({
                            variables: {
                                params: {
                                    middleName: '',
                                    citizenship: values['nationality'],
                                    lastName: values['lastName'],
                                    firstName: values['firstName'],
                                    birthDate: values['birthDate'],
                                    email: values['email'],
                                    phone: values['phone'],
                                    gender: values['gender'],
                                    documents: values['documents'],
                                    loyaltyCards: values['loyaltyCards']
                                }
                            }
                        })];
                case 1:
                    res = _b.sent();
                    if (res && res.data.CreateTravellerProfile) {
                        getCompanions.refetch();
                        setTimeout(function () {
                            setExpanded(false);
                        }, 1000);
                        return [2 /*return*/, true];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/, false];
            }
        });
    }); };
    var updateTraveller = function (values, id) { return __awaiter(void 0, void 0, void 0, function () {
        var lastName, birthDate, citizenship, documents, email, firstName, gender, latinFirstName, latinLastName, latinMiddleName, loyaltyCards, middleName, phone, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    lastName = values.lastName, birthDate = values.birthDate, citizenship = values.citizenship, documents = values.documents, email = values.email, firstName = values.firstName, gender = values.gender, latinFirstName = values.latinFirstName, latinLastName = values.latinLastName, latinMiddleName = values.latinMiddleName, loyaltyCards = values.loyaltyCards, middleName = values.middleName, phone = values.phone;
                    return [4 /*yield*/, updateTravellerProfile({
                            variables: {
                                params: {
                                    id: id,
                                    newParameters: {
                                        latinMiddleName: latinMiddleName,
                                        latinFirstName: latinFirstName,
                                        lastName: lastName,
                                        birthDate: birthDate,
                                        citizenship: citizenship,
                                        documents: documents,
                                        email: email,
                                        firstName: firstName,
                                        gender: gender,
                                        latinLastName: latinLastName,
                                        loyaltyCards: loyaltyCards,
                                        middleName: middleName,
                                        phone: phone
                                    }
                                }
                            }
                        })];
                case 1:
                    res = _a.sent();
                    if (!res.data.UpdateTravellerProfile) return [3 /*break*/, 3];
                    return [4 /*yield*/, getCompanions.refetch()];
                case 2:
                    _a.sent();
                    // if changed only documents, then not change state expanded
                    if (!(Object.keys(values).length === 1 && typeof documents !== 'undefined')) {
                        setTimeout(function () {
                            setExpanded(false);
                        }, 1000);
                    }
                    return [2 /*return*/, true];
                case 3: return [2 /*return*/, false];
            }
        });
    }); };
    var deleteTraveller = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setExpanded(false);
                    return [4 /*yield*/, deleteTravellerProfile({
                            variables: {
                                id: id
                            }
                        })];
                case 1:
                    res = _a.sent();
                    if (res.data.DeleteTravellerProfile) {
                        getCompanions.refetch();
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null, !getCompanions.loading &&
        ((_b = (_a = getCompanions === null || getCompanions === void 0 ? void 0 : getCompanions.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.travellerProfiles) &&
        !getTravellerFields.loading &&
        ((_c = getTravellerFields.data) === null || _c === void 0 ? void 0 : _c.DefaultTravellerProfile) ? (React.createElement(React.Fragment, null,
        React.createElement(Traveller, { onSubmit: onSubmit, handleChange: function () {
                if (expanded === 0) {
                    setExpanded(false);
                }
                else {
                    setExpanded(0);
                }
            }, traveller: getTravellerFields.data.DefaultTravellerProfile, expanded: expanded === 0 }),
        getCompanions.data.CurrentUser.travellerProfiles.map(function (traveller, index) { return (React.createElement(Traveller, { key: traveller.id, expanded: index + 1 === expanded, handleChange: function () {
                if (index + 1 === expanded) {
                    setExpanded(false);
                }
                else {
                    setExpanded(index + 1);
                }
            }, onSubmit: function (values) { return updateTraveller(values, traveller.id); }, onDelete: deleteTraveller, traveller: traveller })); }))) : (React.createElement(Loader, null))));
};
export default TravelCompanions;
