import { __assign } from "tslib";
import { docTypes, genderTypes, isDigits, isStartFromDigits } from './../helpers';
import { format } from 'date-fns';
import { TravellerFieldEnum } from '../../../../__generated__/globalTypes';
import { TravellerFieldEnumPI, TravellerFieldAdditionalTypes } from '../../types';
var SLASH = /\//;
var DIGITS_PLUS_AND_SYMBOLS_AFTER = /\d+\+.+$/;
var DIGITS_AND_SYMBOLS_AFTER = /\d+.+$/;
var HASH_AND_SYMBOLS_AFTER = /\#+.+$/;
var HASH = /\#/;
var CONTACT_FIELD = /^[ДРФТАМЕHBFATME]#/g;
var ANY_CHARS_AT_START_EXCEPT_DIGITS = /^[^\d]+/g;
var SPECIFIC_COMMANDS = /^-|^–|^9/gm;
var AT_SIGN = /@/g;
var PASSENGER_NUMBER = /^П\d{1,2}/;
var LINEFEED = /\n/;
var getNameAndType = function (type) {
    return {
        name: type
            .split('')
            .map(function (e, idx) { return (idx === 0 ? e.toLowerCase() : e); })
            .join(''),
        type: type
    };
};
// (Ф И О)
export var getName = function (str, nameType, positionInCommand) {
    var name = str.split(' ')[positionInCommand];
    return __assign({ value: name || null }, getNameAndType(nameType));
};
// <ДАТ_РОЖД>(+ПОЛ)<:ГРЖ>
export var getBirthDate = function (str) {
    var citizenship = str.split(':')[1];
    var value = citizenship ? str.split(':')[0].slice(0, 6) : str.slice(0, 6);
    var month = format(new Date("".concat(value.slice(2, 4))), 'MMM');
    var yearFromStr = value.slice(4, 6);
    var year = +"20".concat(yearFromStr) > new Date().getFullYear() ? "19".concat(yearFromStr) : "20".concat(yearFromStr);
    value = isDigits(value) ? format(new Date("".concat(value.slice(0, 2)).concat(month).concat(year)), 'dd.MM.yyyy') : null;
    return __assign({ value: value }, getNameAndType(TravellerFieldEnum.BirthDate));
};
// <ДАТ_РОЖД>(+ПОЛ)<:ГРЖ>
export var getGender = function (str) {
    var citizenship = str.split(':')[1];
    var value = citizenship ? str.split(':')[0].split('+')[1] : str.split('+')[1];
    return __assign({ value: genderTypes[value] }, getNameAndType(TravellerFieldEnum.Gender));
};
// </ДАТ_ИСТ>
export var getDocExpiryDate = function (str) {
    var value = str.trim();
    var month = !!value && format(new Date("".concat(value.slice(2, 4))), 'MMM');
    value = !!value ? format(new Date("".concat(value.slice(0, 2)).concat(month).concat(value.slice(4, 6))), 'dd.MM.yyyy') : null;
    return __assign({ value: value }, getNameAndType(TravellerFieldEnum.DocExpiryDate));
};
// (ДОК<ДАТ>)
export var getDocNumber = function (str) {
    var value = str.split(HASH)[0].split('*')[0].replace(ANY_CHARS_AT_START_EXCEPT_DIGITS, '');
    return __assign({ value: value || null }, getNameAndType(TravellerFieldEnum.DocNumber));
};
export var getValueDependsOnType = function (str, type) {
    return __assign({ value: str || null }, getNameAndType(type));
};
// <П(ПАСС)>(ТИП)#(АДРЕСС)<*Комментарий>
export var getContactField = function (str) {
    var type = str.match(AT_SIGN) ? TravellerFieldEnum.Email : TravellerFieldEnum.Phone;
    var value = str.split(HASH)[1];
    value = value.split('*')[0] || value;
    return __assign({ value: value || null }, getNameAndType(type));
};
export var isContactField = function (str) { return !!str.replace(PASSENGER_NUMBER, '').match(CONTACT_FIELD); };
export var isHavePassNumb = function (str) { return !!str.match(PASSENGER_NUMBER); };
// -(ФИО)<ДАТ_РОЖД>(+ПОЛ)<:ГРЖ>/(ДОК)</ДАТ_ИСТ><*ККП><#КОД>/(ДОК_НА_ЛЬГОТУ)
export var sirenaPassengersData = function (str) {
    var strWithoutSpecificCommands = str.replace(SPECIFIC_COMMANDS, '');
    var data = strWithoutSpecificCommands.split(LINEFEED) || [strWithoutSpecificCommands];
    if (isContactField(data[0])) {
        return data.map(function (command) {
            var passengerIdx = isHavePassNumb(command) ? command.match(PASSENGER_NUMBER)[0].replace('П', '') : '1';
            return [getValueDependsOnType(passengerIdx, TravellerFieldEnumPI.PassengerIndex), getContactField(command)];
        });
    }
    else {
        return data.map(function (command) {
            // [(ФИО)<ДАТ_РОЖД>(+ПОЛ)<:ГРЖ>, (ДОК), </ДАТ_ИСТ><*ККП><#КОД>, (ДОК_НА_ЛЬГОТУ)]
            var passengersData = command.split(SLASH);
            // (ФИО)<ДАТ_РОЖД>(+ПОЛ)<:ГРЖ>
            var nameDateGenderNationalityStr = passengersData[0];
            // <:ГРЖ>
            var nationality = nameDateGenderNationalityStr.split(':')[1];
            // (Ф И О)
            var nameDateStr = (nationality
                ? nameDateGenderNationalityStr.split(':')[0]
                : nameDateGenderNationalityStr)
                .replace(DIGITS_PLUS_AND_SYMBOLS_AFTER, '')
                .trim();
            // <ДАТ_РОЖД>(+ПОЛ)<:ГРЖ>
            var dateAndGender = nameDateGenderNationalityStr.match(DIGITS_PLUS_AND_SYMBOLS_AFTER)[0];
            // (ДОК) || (ДОК)<*ККП><#КОД>
            var docData = passengersData[1].replace(' ', '');
            // </ДАТ_ИСТ><*ККП><#КОД>
            var expiryDate = isStartFromDigits(passengersData[2]) ? passengersData[2] : '';
            // <*ККП><#КОД>
            var passengerType = expiryDate ? expiryDate : docData.replace(HASH_AND_SYMBOLS_AFTER, '');
            var passengerTypeCode = expiryDate ? expiryDate.split('#')[1] : docData.split('#')[1];
            var privilegeDocument = expiryDate ? passengersData[3] : passengersData[2];
            return [
                getName(nameDateStr, TravellerFieldEnum.FirstName, 1),
                getName(nameDateStr, TravellerFieldEnum.LastName, 0),
                getName(nameDateStr, TravellerFieldEnum.MiddleName, 2),
                getBirthDate(dateAndGender),
                getGender(dateAndGender),
                getValueDependsOnType(nationality, TravellerFieldEnum.Nationality),
                getValueDependsOnType(docTypes[docData.replace(DIGITS_AND_SYMBOLS_AFTER, '')], TravellerFieldEnum.DocType),
                getDocNumber(docData),
                getDocExpiryDate(expiryDate),
                getValueDependsOnType(passengerType.split('*')[1] || '', TravellerFieldAdditionalTypes.PassengerType),
                getValueDependsOnType(passengerTypeCode || '', TravellerFieldAdditionalTypes.PassengerTypeCode),
                getValueDependsOnType(privilegeDocument || '', TravellerFieldAdditionalTypes.PrivilegeDocument)
            ];
        });
    }
};
