import { __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import Tooltip from '../../../../Tooltip';
import TooltipContent from '../TooltipContent/TooltipContent';
import { Button } from '../../../../index';
import cn from 'classnames';
import DisplayCameraError from '../DisplayCameraError/DisplayCameraError';
import CameraPhoto from 'jslib-html5-camera-photo';
import { useMutation } from '@apollo/react-hooks';
import * as GetPassengerInfoByMrz from '../../../../__queries__/GetPassengerInfoByMrz.graphql';
import { useGraphQLClient } from '../../../../graphql-client';
import { emptyDocFields } from '../../constants';
import RecognizeTooltip from '../RecognizeTooltip/RecognizeTooltip';
var Stages;
(function (Stages) {
    Stages["SCANING"] = "SCANING";
    Stages["RECOGNIZING"] = "RECOGNIZING";
})(Stages || (Stages = {}));
var ScanDocs = function (props) {
    var _a, _b;
    var t = useTranslation('DocumentRecognition').t;
    var _c = useTheme('DocRecognition'), contentCss = _c.RecognitionPopupContent, css = _c.ScanDocs;
    var client = useGraphQLClient();
    var _d = __read(useMutation(GetPassengerInfoByMrz.GetPassengerInfoByMrz, { client: client }), 2), getPassengerInfoByMrz = _d[0], loading = _d[1].loading;
    var _e = __read(useState(Stages.SCANING), 2), stage = _e[0], setStage = _e[1];
    var _f = __read(useState(null), 2), dataUri = _f[0], setDataUri = _f[1];
    var _g = __read(useState(null), 2), mediaStream = _g[0], setMediaStream = _g[1];
    var _h = __read(useState(''), 2), cameraError = _h[0], setCameraError = _h[1];
    var _j = __read(useState(''), 2), fileError = _j[0], setFileError = _j[1];
    var _k = __read(useState(''), 2), recognitionError = _k[0], setRecognitionError = _k[1];
    var videoRef = useRef(null);
    var fileRef = useRef(null);
    var _l = __read(useState(null), 2), libCameraPhoto = _l[0], setLibCameraPhoto = _l[1];
    var _m = __read(useState(false), 2), isCameraPermissionDenied = _m[0], setIsCameraPermissionDenied = _m[1];
    var enableStream = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _mediaStream, cameraStartError_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    _mediaStream = null;
                    return [4 /*yield*/, libCameraPhoto.startCamera()];
                case 1:
                    _mediaStream = _a.sent();
                    setMediaStream(_mediaStream);
                    setCameraError('');
                    return [3 /*break*/, 3];
                case 2:
                    cameraStartError_1 = _a.sent();
                    if (cameraStartError_1.message === 'Permission denied') {
                        setIsCameraPermissionDenied(true);
                    }
                    setCameraError(t('Camera Error Msg'));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [libCameraPhoto]);
    var cleanStream = useCallback(function () {
        try {
            if (mediaStream) {
                libCameraPhoto.stopCamera();
                setMediaStream(null);
            }
            // protect setState from component umonted error
            // when the component is umonted videoRef.current == null
            if (videoRef && videoRef.current) {
                setMediaStream(null);
                setCameraError('');
            }
        }
        catch (cameraStopError) {
            setCameraError(t('Camera Error Msg'));
        }
    }, [mediaStream, libCameraPhoto, videoRef]);
    var reader = useMemo(function () {
        var r = new FileReader();
        r.onloadend = function () {
            setDataUri(r.result);
            setStage(Stages.RECOGNIZING);
        };
        return r;
    }, []);
    var handleFileUploaded = useCallback(function (event) {
        goToTheBeginning();
        var file = event.target.files[0];
        var allowedFileTypes = event.target.getAttribute('accept').split(',');
        if (file && allowedFileTypes.includes(file.type)) {
            reader.readAsDataURL(file);
        }
        else {
            setFileError(t('File Error Msg'));
        }
    }, []);
    var resetFile = useCallback(function () {
        fileRef.current.value = null;
        setDataUri(null);
    }, []);
    var recognizeDocument = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var imageStr;
        return __generator(this, function (_a) {
            imageStr = String(dataUri);
            imageStr = imageStr.substr(imageStr.indexOf('base64,') + 7);
            getPassengerInfoByMrz({
                variables: { params: { image: imageStr, orderId: props.orderId } }
            }).then(function (response) {
                var fields = response.data.GetPassengerInfoByMrz;
                if (fields.length) {
                    props.onRecognitionFinished(fields);
                }
                else {
                    setRecognitionError(t('Backend Error Msg'));
                }
            }, function (response) {
                var _a, _b;
                var message = (_b = (_a = response.message) !== null && _a !== void 0 ? _a : response.errors[0].debugMessage) !== null && _b !== void 0 ? _b : t('Backend Error Msg');
                setRecognitionError(message);
            });
            return [2 /*return*/];
        });
    }); }, [dataUri]);
    var takePhoto = useCallback(function () {
        var config = {};
        var dataUri = libCameraPhoto.getDataUri(config);
        setDataUri(dataUri);
        setStage(Stages.RECOGNIZING);
    }, [libCameraPhoto]);
    var goToTheBeginning = useCallback(function () {
        setDataUri('');
        setStage(Stages.SCANING);
        setRecognitionError('');
        setFileError('');
    }, []);
    useEffect(function () {
        if (videoRef && videoRef.current) {
            setLibCameraPhoto(new CameraPhoto(videoRef.current));
        }
    }, [videoRef]);
    useEffect(function () {
        if (libCameraPhoto) {
            if (props.isModalOpen && !mediaStream) {
                enableStream();
            }
            return function () {
                if (!props.isModalOpen) {
                    cleanStream();
                }
            };
        }
        else {
            return function () { };
        }
    }, [libCameraPhoto, cleanStream, props.isModalOpen]);
    useEffect(function () {
        props.onRecognitionFinished(emptyDocFields);
        props.resetForm();
        if (dataUri) {
            recognizeDocument();
        }
    }, [dataUri]);
    return (React.createElement("div", { className: props.className },
        React.createElement("div", { className: css.top },
            React.createElement("div", { className: contentCss.title }, t('Scan docs title')),
            React.createElement(Tooltip, { title: React.createElement(TooltipContent, null) },
                React.createElement("div", { className: css.howScan }, t('How scan')))),
        React.createElement("div", { className: cn(css.scanWindow, (_a = {}, _a[css.scanWindow_video] = !dataUri && !cameraError, _a)) },
            React.createElement("video", { width: '100%', height: '100%', ref: videoRef, autoPlay: true, muted: true, playsInline: true, className: cn(css.video, (_b = {}, _b[css.video_hide] = !!dataUri, _b)) }),
            stage === Stages.SCANING && cameraError && React.createElement(DisplayCameraError, { errorMsg: cameraError }),
            stage === Stages.SCANING && fileError && React.createElement(DisplayCameraError, { errorMsg: fileError }),
            stage === Stages.RECOGNIZING && recognitionError && React.createElement(DisplayCameraError, { errorMsg: recognitionError }),
            dataUri && !recognitionError && React.createElement("img", { alt: "camera", src: String(dataUri) })),
        React.createElement("div", { className: cn(contentCss.bottomBlock, css.bottom) },
            React.createElement("input", { id: "upload-scan-input", ref: fileRef, hidden: true, type: 'file', onChange: handleFileUploaded, accept: 'image/x-png,image/png,image/gif,image/jpeg' }),
            React.createElement("label", { htmlFor: "upload-scan-input" },
                React.createElement("div", { className: css.uploadButton, onClick: resetFile }, t('Upload scan button text'))),
            !(dataUri && cameraError && !loading) && (React.createElement(React.Fragment, null, dataUri || fileError ? (React.createElement(Button, { onClick: goToTheBeginning, size: 'common', variant: 'flat', disabled: !libCameraPhoto, isLoading: loading }, t('Scan button text'))) : (React.createElement(RecognizeTooltip, { isCameraPermissionDenied: isCameraPermissionDenied },
                React.createElement(Button, { onClick: takePhoto, size: 'common', variant: 'flat', disabled: !mediaStream, isLoading: loading }, t('Recognize button text')))))))));
};
export default ScanDocs;
