import { __assign, __read } from "tslib";
import React, { useCallback, useMemo, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import TabButton from '../../../Button/components/TabButton/TabButton';
import Button from '../../../Button';
import { useTheme, ThemeProvider } from '../../../theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Popover } from '@material-ui/core';
import { useToggleable } from '../../../hooks';
import Input from '../../Input/Input';
import { FilterAttrs } from '.././types';
import { useFiltersActivity, useFieldValidators, useDataPickerTheme } from '.././hooks';
import { setNonStopFilterValue, flightFilters } from '.././utils';
import { initFilterValues } from '.././const';
import { useFilterDescription } from '.././hooks';
import DateSelector from '.././components/DateSelector/DateSelector';
import WeekDaysSelector from '.././components/WeekDaysSelector/WeekDaysSelector';
import { CloseClear } from '../../../Icons';
var FlightsFilter = function (props) {
    var _a, _b;
    var className = props.className, _c = props.initValues, initValues = _c === void 0 ? initFilterValues : _c, allowedFilters = props.allowedFilters, onChange = props.onChange;
    var css = useTheme('FlightsFilter').FlightsFilter;
    var dataPickerTheme = useDataPickerTheme();
    var t = useTranslation('FlightsFilter').t;
    var _d = __read(React.useState(initValues), 2), filterValues = _d[0], setFilterValues = _d[1];
    var rootRef = useRef(null);
    var _e = useToggleable(false), extFilterOpened = _e.isOpen, openExtFilter = _e.open, closeExtFilter = _e.close;
    var _f = useFiltersActivity(filterValues), nonStopFilterActived = _f.nonStopFilterActived, extFilterActived = _f.extFilterActived;
    var filterDesc = useFilterDescription(filterValues, extFilterActived);
    var extFiltersOpenHandler = useCallback(function () {
        openExtFilter();
    }, []);
    var extFiltersClearHandler = useCallback(function (e) {
        e.stopPropagation();
        var newValues = initFilterValues;
        setFilterValues(newValues);
        onChange === null || onChange === void 0 ? void 0 : onChange(newValues);
    }, []);
    var nonStopFilterHandler = useCallback(function () {
        var newValues = setNonStopFilterValue(filterValues, !nonStopFilterActived);
        setFilterValues(newValues);
        onChange === null || onChange === void 0 ? void 0 : onChange(newValues);
    }, [filterValues]);
    var onSubmitHandler = useCallback(function (values) {
        closeExtFilter();
        setFilterValues(values);
        onChange === null || onChange === void 0 ? void 0 : onChange(values);
    }, []);
    var _g = useFieldValidators(), validateAirlineCode = _g.validateAirlineCode, validateFlightNum = _g.validateFlightNum, validateDate = _g.validateDate, validateForm = _g.validateForm;
    var _h = useMemo(function () {
        if ((allowedFilters === null || allowedFilters === void 0 ? void 0 : allowedFilters.length) > 0) {
            return {
                allowedFilterIdx: new Set(allowedFilters),
                hasExtFilter: allowedFilters.some(function (f) { return f !== FilterAttrs.NON_STOP; })
            };
        }
        return {
            allowedFilterIdx: new Set(flightFilters.keys()),
            hasExtFilter: true
        };
    }, [allowedFilters]), allowedFilterIdx = _h.allowedFilterIdx, hasExtFilter = _h.hasExtFilter;
    return (React.createElement("div", { ref: rootRef, className: cn(css.root, className) },
        React.createElement(TabButton, { className: cn(css.tab, (_a = {}, _a[css.active] = extFilterActived, _a)), onClick: extFiltersOpenHandler, active: extFilterActived },
            React.createElement("span", { className: css.tabTitle }, filterDesc),
            extFilterActived && (React.createElement("span", { className: css.tabClose, onClick: extFiltersClearHandler }, CloseClear))),
        allowedFilterIdx.has(FilterAttrs.AIRLINE_CODE) && (React.createElement(TabButton, { className: cn(css.tab, (_b = {}, _b[css.active] = nonStopFilterActived, _b)), onClick: nonStopFilterHandler, active: nonStopFilterActived }, t('Nonstop'))),
        hasExtFilter && (React.createElement(Popover, { PaperProps: {
                classes: {
                    root: css.extFilterWrapper
                }
            }, anchorEl: rootRef.current, anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom'
            }, transformOrigin: {
                horizontal: 'right',
                vertical: 'top'
            }, open: extFilterOpened, onClose: closeExtFilter },
            React.createElement(Form, { onSubmit: onSubmitHandler, initialValues: filterValues, validate: validateForm }, function (_a) {
                var handleSubmit = _a.handleSubmit, submitting = _a.submitting, valid = _a.valid, pristine = _a.pristine;
                return (React.createElement("form", { className: css.form, onSubmit: handleSubmit },
                    React.createElement("div", { className: css.values },
                        allowedFilterIdx.has(FilterAttrs.AIRLINE_CODE) && (React.createElement(Field, { name: FilterAttrs.AIRLINE_CODE, validate: validateAirlineCode, render: function (_a) {
                                var input = _a.input, meta = _a.meta;
                                return (React.createElement(Input, { className: cn(css.form__input), variant: 'outlined', TextFieldProps: __assign(__assign(__assign({}, input), meta), { error: !meta.valid && meta.touched, helperText: !meta.valid && meta.touched ? meta.error : null }), label: t('Airlines code (one or more)') }));
                            } })),
                        allowedFilterIdx.has(FilterAttrs.FLIGHT_NUM) && (React.createElement(Field, { name: FilterAttrs.FLIGHT_NUM, validate: validateFlightNum, render: function (_a) {
                                var input = _a.input, meta = _a.meta;
                                return (React.createElement(Input, { className: cn(css.form__input), variant: 'outlined', TextFieldProps: __assign(__assign(__assign({}, input), meta), { error: !meta.valid && meta.touched, helperText: !meta.valid && meta.touched ? meta.error : null }), label: t('Flight number') }));
                            } })),
                        React.createElement(ThemeProvider, { value: dataPickerTheme },
                            (allowedFilterIdx.has(FilterAttrs.BEGIN_PERIOD) ||
                                allowedFilterIdx.has(FilterAttrs.PERIOD)) && (React.createElement(Field, { name: FilterAttrs.BEGIN_PERIOD, validate: validateDate, render: function (_a) {
                                    var input = _a.input, meta = _a.meta;
                                    return (React.createElement(DateSelector, { className: cn(css.form__input), label: t('Beginning of period'), initValue: input.value, onChange: input.onChange, onBlue: input.onBlur, error: !meta.valid && meta.touched, helperText: !meta.valid && meta.touched ? meta.error : null }));
                                } })),
                            (allowedFilterIdx.has(FilterAttrs.END_PERIOD) ||
                                allowedFilterIdx.has(FilterAttrs.PERIOD)) && (React.createElement(Field, { name: FilterAttrs.END_PERIOD, validate: validateDate, render: function (_a) {
                                    var input = _a.input, meta = _a.meta;
                                    return (React.createElement(DateSelector, { className: cn(css.form__input), label: t('End of period'), initValue: input.value, onChange: input.onChange, onBlue: input.onBlur, error: !meta.valid && meta.touched, helperText: !meta.valid && meta.touched ? meta.error : null }));
                                } }))),
                        allowedFilterIdx.has(FilterAttrs.WEEK_DAYS) && (React.createElement(Field, { name: FilterAttrs.WEEK_DAYS, render: function (_a) {
                                var input = _a.input;
                                return (React.createElement(WeekDaysSelector, { className: cn(css.form__input, css.weekDays), initValue: input.value, onChange: input.onChange }));
                            } }))),
                    React.createElement(Button, { className: css.submit, type: "submit", disabled: pristine || !valid || submitting }, t('Confirm'))));
            })))));
};
export default FlightsFilter;
