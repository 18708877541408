import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import QRCode from 'qrcode.react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import SwipeableViews from 'react-swipeable-views';
import { NavLink } from 'react-router-dom';
import { useTheme } from '../theme';
import { Button, SlideBottom, Tooltip } from '../index';
import Service from './components/Service/Service';
import { downloadArrowIcon, downloadIcon, passengerIcon, seatIcon } from './Icons';
import { OrderAdditionalServiceGdsServiceServiceType as ServiceType } from '../__generated__/globalTypes';
import { getLocale, initI18n, MOBILE_MIN_WIDTH, TABLET_MIDDLE_WIDTH } from '../utils';
import CancelModal from './components/CancelModal/CancelModal';
import Modal from '../Modal';
import SendToEmail from './components/SendToEmail/SendToEmail';
import SelectSegments from '../SelectSegments';
var qrCodeIcon = (React.createElement("svg", { width: "26", height: "26", viewBox: "0 0 26 26", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M13 3.04688V5.07812H10.9688V3.04688H13ZM10.9688 14.9805V17.875H13V14.9805H10.9688ZM17.0625 26V23.9688H15.0312V21.9375H13V26H17.0625ZM21.125 10.918H15.0312V12.9492H21.125V10.918ZM21.125 14.9805H23.9688V12.9492H21.125V14.9805ZM21.125 17.875V19.9062H26V14.9805H23.9688V17.875H21.125ZM15.0312 0H13V3.04688H15.0312V0ZM13 9.14062H15.0312V5.07812H13V7.10938H10.9688V12.9492H13V9.14062ZM0 10.918V14.9805H2.03125V12.9492H5.07812V10.918H0ZM15.0312 14.9805V12.9492H13V14.9805H15.0312ZM19.0938 17.0117H21.125V14.9805H19.0938V17.0117ZM23.9688 12.9492H26V10.918H23.9688V12.9492ZM17.0625 14.9805H15.0312V17.875H13V19.9062H17.0625V14.9805ZM10.9688 21.9375H13V19.9062H10.9688V21.9375ZM17.0625 19.9062V21.9375H21.125V19.9062H17.0625ZM23.1562 23.9688V21.9375H21.125V23.9688H23.1562ZM26 26V23.9688H23.1562V26H26ZM19.0938 26H21.125V23.9688H19.0938V26ZM9.14062 12.9492V10.918H7.10938V12.9492H5.07812V14.9805H10.9688V12.9492H9.14062ZM9.14062 9.14062H0V0H9.14062V9.14062ZM7.10938 2.03125H2.03125V7.10938H7.10938V2.03125ZM5.58594 3.55469H3.55469V5.58594H5.58594V3.55469ZM26 0V9.14062H16.8594V0H26ZM23.9688 2.03125H18.8906V7.10938H23.9688V2.03125ZM22.4453 3.55469H20.4141V5.58594H22.4453V3.55469ZM0 16.8594H9.14062V26H0V16.8594ZM2.03125 23.9688H7.10938V18.8906H2.03125V23.9688ZM3.55469 22.4453H5.58594V20.4141H3.55469V22.4453Z", fill: "#3290FF" })));
var listIcon = (React.createElement("svg", { width: "26", height: "26", viewBox: "0 0 26 26", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.08325 8.66602H4.33325V5.41602H1.08325V8.66602ZM4.33325 15.166H1.08325V11.916H4.33325V15.166ZM4.33325 21.666H1.08325V18.416H4.33325V21.666ZM24.9166 15.166H6.49992V11.916H24.9166V15.166ZM6.49992 21.666H24.9166V18.416H6.49992V21.666ZM6.49992 8.66602V5.41602H24.9166V8.66602H6.49992Z", fill: "#3290FF" })));
var Mode;
(function (Mode) {
    Mode[Mode["Services"] = 0] = "Services";
    Mode[Mode["Barcode"] = 1] = "Barcode";
})(Mode || (Mode = {}));
initI18n('RegisteredPassenger');
var RegisteredPassenger = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var name = _a.name, retryLink = _a.retryLink, onBoardingPassDownload = _a.onBoardingPassDownload, onAppleWalletPassDownload = _a.onAppleWalletPassDownload, barcode = _a.barcode, services = _a.services, seats = _a.seats, onCheckinCancel = _a.onCheckinCancel, onTicketsResend = _a.onTicketsResend, isCheckedIn = _a.isCheckedIn, segments = _a.segments;
    var isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
    var _k = __read(useState(Mode.Services), 2), mode = _k[0], setMode = _k[1];
    var _l = __read(useState(false), 2), boardingPassIsDownloading = _l[0], setBoardingPassIsDownloading = _l[1];
    var _m = __read(useState(false), 2), appleWalletPassIsDownloading = _m[0], setAppleWalletPassIsDownloading = _m[1];
    var _o = __read(useState(false), 2), isOpenedCancelModal = _o[0], setOpenedCancelModal = _o[1];
    var _p = __read(useState(false), 2), isCancelCheckinLoading = _p[0], setCancelCheckinLoading = _p[1];
    var _q = __read(useState(false), 2), isReceiptSendingInProgress = _q[0], setReceiptSendingInProgress = _q[1];
    var _r = __read(useState(false), 2), isBoardingPassSpecifyOpen = _r[0], setBoardingPassSpecifyOpen = _r[1];
    var _s = __read(useState(false), 2), ticketResendSpecifyOpen = _s[0], setTicketResendSpecifyOpen = _s[1];
    var _t = __read(useState(false), 2), isAppleWalletSpecifyOpen = _t[0], setAppleWalletSpecifyOpen = _t[1];
    var _u = __read(useState(null), 2), ticketResendEmail = _u[0], setTicketResendEmail = _u[1];
    var css = useTheme('RegisteredPassenger').RegisteredPassenger;
    var t = useTranslation('RegisteredPassenger').t;
    var currentLocale = getLocale();
    var passengerName = name
        .split(' ')
        .map(function (namePart) { var _a; return ((_a = namePart[0]) === null || _a === void 0 ? void 0 : _a.toUpperCase()) + namePart.substring(1); })
        .join(' ');
    var toggleBarcode = useCallback(function () { return setMode(mode === Mode.Barcode ? Mode.Services : Mode.Barcode); }, [mode]);
    var toggleCancelModal = useCallback(function () {
        setOpenedCancelModal(function (prevState) { return !prevState; });
    }, [isOpenedCancelModal]);
    var onCancelCheckin = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCancelCheckinLoading(true);
                    return [4 /*yield*/, onCheckinCancel()];
                case 1:
                    _a.sent();
                    setCancelCheckinLoading(false);
                    toggleCancelModal();
                    return [2 /*return*/];
            }
        });
    }); };
    var segmentsWithoutAstra = useMemo(function () { return segments.filter(function (segment) { return !segment.checkinInfo.airportHasDcs; }); }, [
        segments
    ]);
    var canDownloadBoardingPass = useMemo(function () { return segmentsWithoutAstra.length < segments.length; }, [
        segmentsWithoutAstra
    ]);
    var cancelAvailable = useMemo(function () {
        return segments.length === segments.filter(function (el) { return el.checkinInfo.cancelAvailable; }).length;
    }, [segments]);
    var onBoardingPassClick = useCallback(function (segmentId) { return __awaiter(void 0, void 0, void 0, function () {
        var hasSegmentWithAstra, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    hasSegmentWithAstra = segments.some(function (segment) { return segment.checkinInfo.airportHasDcs; });
                    if (segments.length > 1 && !segmentId && hasSegmentWithAstra) {
                        setBoardingPassSpecifyOpen(true);
                        return [2 /*return*/];
                    }
                    else {
                        setBoardingPassSpecifyOpen(false);
                    }
                    setBoardingPassIsDownloading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onBoardingPassDownload(segmentId)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 4];
                case 4:
                    setBoardingPassIsDownloading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [isBoardingPassSpecifyOpen]);
    var onAppleWalletPassClick = useCallback(function (segmentId) { return __awaiter(void 0, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (segments.length > 1 && !segmentId) {
                        setAppleWalletSpecifyOpen(true);
                        return [2 /*return*/];
                    }
                    else {
                        setAppleWalletSpecifyOpen(false);
                    }
                    setAppleWalletPassIsDownloading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onAppleWalletPassDownload(segmentId)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    console.error(e_2);
                    return [3 /*break*/, 4];
                case 4:
                    setAppleWalletPassIsDownloading(false);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var onResendTicketsClick = function (email, segmentId) { return __awaiter(void 0, void 0, void 0, function () {
        var e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (segments.length > 1 && !segmentId) {
                        setTicketResendSpecifyOpen(true);
                        setTicketResendEmail(email);
                        return [2 /*return*/];
                    }
                    else {
                        setTicketResendSpecifyOpen(false);
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    setReceiptSendingInProgress(true);
                    return [4 /*yield*/, onTicketsResend(email, segmentId)];
                case 2:
                    _a.sent();
                    setReceiptSendingInProgress(false);
                    setTicketResendSpecifyOpen(false);
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _a.sent();
                    console.error(e_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var seatService = services.find(function (_a) {
        var _b, _c, _d;
        var id = _a.id;
        return id === ((seats === null || seats === void 0 ? void 0 : seats.length) && ((_d = (_c = (_b = seats[0]) === null || _b === void 0 ? void 0 : _b.seat) === null || _c === void 0 ? void 0 : _c.seatService) === null || _d === void 0 ? void 0 : _d.id));
    });
    var seatServiceClass = seatService && seatService.serviceClass;
    var passengerServices = new Map();
    var hasMealService = false;
    services.forEach(function (service) {
        var _a, _b;
        if (service.type === ServiceType.Baggage || service.type === ServiceType.Meal) {
            if (service.type === ServiceType.Baggage) {
                var baggageService = service;
                if (!passengerServices.has(baggageService.baggageType)) {
                    passengerServices.set(baggageService.baggageType, {
                        type: baggageService.baggageType,
                        includedServices: (_a = {}, _a[baggageService.baggageWeight] = baggageService.count, _a)
                    });
                }
                else {
                    if (passengerServices
                        .get(baggageService.baggageType)
                        .includedServices.hasOwnProperty(baggageService.baggageWeight)) {
                        passengerServices.get(baggageService.baggageType).includedServices[baggageService.baggageWeight] += baggageService.count;
                    }
                    else {
                        passengerServices.get(baggageService.baggageType).includedServices[baggageService.baggageWeight] = baggageService.count;
                    }
                }
            }
            else {
                hasMealService = true;
                var mealService = service;
                if (!passengerServices.has(ServiceType.Meal)) {
                    passengerServices.set(ServiceType.Meal, {
                        type: ServiceType.Meal,
                        includedServices: (_b = {}, _b[mealService.name] = mealService.count, _b)
                    });
                }
                else {
                    if (passengerServices.get(ServiceType.Meal).includedServices.hasOwnProperty(mealService.name)) {
                        passengerServices.get(ServiceType.Meal).includedServices[mealService.name] += mealService.count;
                    }
                    else {
                        passengerServices.get(ServiceType.Meal).includedServices[mealService.name] = mealService.count;
                    }
                }
            }
        }
    });
    useEffect(function () {
        setMode(barcode && isMobile && !passengerServices.size ? Mode.Barcode : Mode.Services);
    }, [barcode, isMobile, passengerServices]);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.passenger },
                React.createElement(MediaQuery, { maxWidth: "mobile" },
                    React.createElement("span", { className: css.passenger__icon }, passengerIcon)),
                React.createElement("span", null, name)),
            React.createElement("div", { className: css.actions },
                !!(seats === null || seats === void 0 ? void 0 : seats.length) && (React.createElement("div", { className: css.seats },
                    React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                        React.createElement("span", { className: css.seats__icon }, seatIcon)),
                    React.createElement("span", null,
                        seats.map(function (seat) { return seat.seat.number; }).join(', '),
                        "\u00A0",
                        t(seatServiceClass)))),
                !!passengerServices.size && canDownloadBoardingPass && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    React.createElement("span", { className: cn(css.actions__barcode, (_b = {},
                            _b[css.actions__barcode_disabled] = !barcode,
                            _b)), onClick: !!barcode ? toggleBarcode : null }, mode ? listIcon : qrCodeIcon))),
                React.createElement(MediaQuery, { minWidth: +TABLET_MIDDLE_WIDTH + 1 },
                    React.createElement("div", { className: cn(css.actions__buttons, (_c = {},
                            _c[css.actions__buttons_withoutAstra] = !canDownloadBoardingPass,
                            _c)) },
                        canDownloadBoardingPass && onBoardingPassDownload && (React.createElement(Button, { variant: "outline", className: cn(css.downloadButton, (_d = {},
                                _d[css.downloadButton_loading] = boardingPassIsDownloading,
                                _d)), onClick: function () { return onBoardingPassClick(null); }, disabled: !isCheckedIn || boardingPassIsDownloading, children: React.createElement(React.Fragment, null,
                                React.createElement("span", { className: css.downloadIcon }, boardingPassIsDownloading ? (React.createElement("img", { src: "https://cdn.websky.aero/content/frontend/images/loader.png", alt: "loading" })) : (downloadIcon)),
                                t('Download boarding pass')) })),
                        canDownloadBoardingPass && (React.createElement("div", { className: cn(css.appleWallet, (_e = {},
                                _e[css.appleWallet_disabled] = !isCheckedIn || appleWalletPassIsDownloading,
                                _e)) },
                            React.createElement("img", { src: "https://cdn.websky.aero/content/frontend/images/apple-wallet-".concat(currentLocale, ".svg"), alt: "Add to Apple Wallet", onClick: !appleWalletPassIsDownloading && isCheckedIn
                                    ? function () { return onAppleWalletPassClick(null); }
                                    : null }))),
                        !canDownloadBoardingPass && (React.createElement("div", { className: css.actions__withoutAstraInfo }, t('You must receive the boarding pass at the airport on the registration desk.'))))))),
        !!passengerServices.size ||
            (!!barcode && (React.createElement(SwipeableViews, { index: mode, animateHeight: true, animateTransitions: false, disabled: true },
                !!passengerServices.size && (React.createElement("div", { className: cn(css.services, (_f = {},
                        _f[css.services_even] = passengerServices.size % 2 === 0,
                        _f)) },
                    Array.from(passengerServices.values()).map(function (value) {
                        if (value.type !== ServiceType.Meal) {
                            return React.createElement(Service, { service: value });
                        }
                        return null;
                    }),
                    hasMealService && React.createElement(Service, { service: passengerServices.get(ServiceType.Meal) }))),
                !!barcode && (React.createElement("div", { className: css.barcode },
                    React.createElement(QRCode, { value: barcode, size: 200, level: "M", renderAs: "svg" })))))),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
            React.createElement("div", { className: cn(css.actions__buttons, (_g = {},
                    _g[css.actions__buttons_withoutAstra] = !canDownloadBoardingPass,
                    _g)) },
                React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
                    React.createElement("div", { className: css.footerActions }, isCheckedIn ? (React.createElement(React.Fragment, null,
                        canDownloadBoardingPass && (React.createElement(SendToEmail, { changeLoadingStatus: segments.length === 1, onResendTickets: onResendTicketsClick })),
                        cancelAvailable && (React.createElement("div", { className: css.footerActions__cancel, onClick: toggleCancelModal }, t('Cancel check-in'))))) : (React.createElement(NavLink, { to: retryLink, target: "_blank", className: css.footerActions__retry }, t('Retry check-in'))))),
                React.createElement("div", { className: css.actionButtons__tickets },
                    canDownloadBoardingPass && onBoardingPassDownload && (React.createElement(Button, { variant: "outline", disabled: !isCheckedIn || boardingPassIsDownloading, className: cn(css.downloadButton, (_h = {},
                            _h[css.downloadButton_loading] = boardingPassIsDownloading,
                            _h)), onClick: function () { return onBoardingPassClick(null); }, children: React.createElement(React.Fragment, null,
                            React.createElement("span", { className: css.downloadIcon }, boardingPassIsDownloading ? (React.createElement("img", { src: "https://cdn.websky.aero/content/frontend/images/loader.png", alt: "loading" })) : (downloadIcon)),
                            t('Download')) })),
                    canDownloadBoardingPass && (React.createElement("div", { className: cn(css.appleWallet, (_j = {},
                            _j[css.appleWallet_disabled] = !isCheckedIn || appleWalletPassIsDownloading,
                            _j)) },
                        React.createElement("img", { src: "https://cdn.websky.aero/content/frontend/images/apple-wallet-".concat(currentLocale, ".svg"), alt: "Add to Apple Wallet", onClick: !appleWalletPassIsDownloading && isCheckedIn
                                ? function () { return onAppleWalletPassClick(null); }
                                : null }))),
                    !canDownloadBoardingPass && (React.createElement("div", { className: css.actions__withoutAstraInfo }, t('You must receive the boarding pass at the airport on the registration desk.')))))),
        React.createElement(SelectSegments, { title: t('Select flight for download'), isLoading: boardingPassIsDownloading, segments: segments, isOpen: isBoardingPassSpecifyOpen, onClose: function () { return setBoardingPassSpecifyOpen(false); }, renderButton: function (segment) {
                var canDownloadBoardingPass = segment.checkinInfo.airportHasDcs;
                return canDownloadBoardingPass ? (React.createElement("div", { className: css.selectSegment__downloadButton, onClick: function () { return onBoardingPassClick(segment.id); } }, downloadArrowIcon)) : (React.createElement(Tooltip, { title: t('Registration available only at airport') },
                    React.createElement("div", { className: cn(css.selectSegment__downloadButton, css.selectSegment__downloadButton_disabled), onClick: function () { return null; } }, downloadArrowIcon)));
            } }),
        React.createElement(SelectSegments, { title: t('Select flight for download'), isLoading: isReceiptSendingInProgress, segments: segments, isOpen: ticketResendSpecifyOpen, onClose: function () { return setTicketResendSpecifyOpen(false); }, renderButton: function (segment) {
                var canDownloadBoardingPass = segment.checkinInfo.airportHasDcs;
                return canDownloadBoardingPass ? (React.createElement(Button, { className: css.sendToEmailButton, onClick: function () { return onResendTicketsClick(ticketResendEmail, segment.id); } },
                    React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, t('Send to email')),
                    React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, t('Send')))) : (React.createElement(Tooltip, { title: t('Registration available only at airport') },
                    React.createElement(Button, { className: cn(css.sendToEmailButton, css.sendToEmailButton_disabled), onClick: function () { return null; } },
                        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, t('Send to email')),
                        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, t('Send')))));
            } }),
        React.createElement(SelectSegments, { isLoading: appleWalletPassIsDownloading, title: t('Select flight for download'), segments: segments, isOpen: isAppleWalletSpecifyOpen, onClose: function () { return setAppleWalletSpecifyOpen(false); }, renderButton: function (segment) {
                var canDownloadBoardingPass = segment.checkinInfo.airportHasDcs;
                return canDownloadBoardingPass ? (React.createElement("div", { className: css.appleWallet__download },
                    React.createElement("img", { src: "https://cdn.websky.aero/content/frontend/images/apple-wallet-".concat(currentLocale, ".svg"), alt: "Add to Apple Wallet", onClick: function () { return onAppleWalletPassClick(segment.id); } }))) : (React.createElement(Tooltip, { title: t('Registration available only at airport') },
                    React.createElement("div", { className: cn(css.appleWallet__download, css.appleWallet__download_disabled) },
                        React.createElement("img", { src: "https://cdn.websky.aero/content/frontend/images/apple-wallet-".concat(currentLocale, ".svg"), alt: "Add to Apple Wallet", onClick: function () { return null; } }))));
            } }),
        React.createElement(MediaQuery, { minWidth: +TABLET_MIDDLE_WIDTH + 1 },
            React.createElement("div", { className: css.footerActions }, isCheckedIn ? (React.createElement(React.Fragment, null,
                canDownloadBoardingPass && (React.createElement(SendToEmail, { changeLoadingStatus: segments.length === 1, onResendTickets: onResendTicketsClick })),
                cancelAvailable && (React.createElement("div", { className: css.footerActions__cancel, onClick: toggleCancelModal }, t('Cancel check-in'))))) : (React.createElement(NavLink, { to: retryLink, target: "_blank", className: css.footerActions__retry }, t('Retry check-in'))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: css.footerActions }, isCheckedIn ? (React.createElement(React.Fragment, null,
                canDownloadBoardingPass && (React.createElement(SendToEmail, { changeLoadingStatus: segments.length === 1, onResendTickets: onResendTicketsClick })),
                cancelAvailable && (React.createElement("div", { className: css.footerActions__cancel, onClick: toggleCancelModal }, t('Cancel check-in'))))) : (React.createElement(NavLink, { to: retryLink, target: "_blank", className: css.footerActions__retry }, t('Retry check-in'))))),
        React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
            React.createElement(Modal, { open: isOpenedCancelModal, onClose: toggleCancelModal, maxWidth: "xs", classes: { scrollBody: css.cancelModal } },
                React.createElement(CancelModal, { onClose: toggleCancelModal, onConfirm: onCancelCheckin, passengerName: passengerName, isLoading: isCancelCheckinLoading }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: isOpenedCancelModal, onClose: toggleCancelModal, noPadding: true },
                React.createElement(CancelModal, { onClose: toggleCancelModal, onConfirm: onCancelCheckin, passengerName: passengerName, isLoading: isCancelCheckinLoading })))));
};
export default RegisteredPassenger;
