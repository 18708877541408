import * as React from 'react';
export var Aircraft = (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" },
    React.createElement("path", { d: "M21 16v-2l-8-5V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-8 5v2l8-2.5V19l-2 1.5V22l3.5-1 3.5 1v-1.5L13 19v-5.5l8 2.5z" }),
    React.createElement("path", { d: "M0 0h24v24H0z", fill: "none" })));
export var Group = (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" },
    React.createElement("path", { d: "M0 0h24v24H0z", fill: "none" }),
    React.createElement("path", { d: "M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" })));
export var Person = (React.createElement("svg", { viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M7.00132 6C8.77015 6 10.2066 7.43643 10.2066 9.20525C10.2066 10.9741 8.77015 12.4105 7.00132 12.4105C5.2325 12.4105 3.79607 10.9741 3.79607 9.20525C3.79607 7.43643 5.2325 6 7.00132 6ZM0 18.7499V17.6815C0 15.3073 4.74853 14.0014 7.12279 14.0014C9.49705 14.0014 14.2456 15.3073 14.2456 17.6815V18.7499H0ZM24 6.75H14.25V8.25H24V6.75ZM14.25 9.75H24V11.25H14.25V9.75ZM21 12.75H14.25V14.25H21V12.75Z", fill: "white" })));
export var Settings = (React.createElement("svg", { viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M19.8837 10.5644L17.991 10.099C17.8773 9.74021 17.7309 9.39229 17.5544 9.05949C17.8386 8.58647\n\t\t\t 18.4357 7.59089 18.4357 7.59089C18.55 7.40066 18.5198 7.15706 18.363 7.00034L16.9959 5.63322C16.8392\n\t\t\t 5.47602 16.5961 5.44583 16.4054 5.56052L14.9344 6.44188C14.6035 6.26673 14.2574 6.12181 13.901\n\t\t\t 6.00899L13.4356 4.11635C13.3817 3.90108 13.1887 3.75 12.9668 3.75H11.0332C10.8113 3.75 10.6178\n\t\t\t 3.90108 10.5644 4.11632C10.5644 4.11632 10.242 5.43729 10.0994 6.0085C9.71942 6.12887 9.35217 6.28513\n\t\t\t 9.00235 6.47585L7.4771 5.56052C7.28638 5.44721 7.04375 5.47651 6.88655 5.63322L5.51943 7.00034C5.36271\n\t\t\t 7.15706 5.33249 7.40066 5.44673 7.59089L6.38994 9.16619C6.23792 9.46596 6.11047 9.77798 6.00899 10.0976L4.11635 10.5644C3.90108 10.6183 3.75 10.8113 3.75 11.0332V12.9668C3.75 13.1887 3.90108 13.3822 4.11632 13.4356L6.00944 13.901C6.12416 14.265 6.27331 14.6186 6.45413 14.958L5.61009 16.3647C5.49584 16.555 5.52607 16.7986 5.68279 16.9553L7.0499 18.3224C7.20617 18.4787 7.44974 18.5084 7.64046 18.3951C7.64046 18.3951 8.58695 17.8277 9.05053 17.5501C9.38617 17.729 9.73737 17.8768 10.0995 17.9915L10.5645 19.8836C10.6178 20.099 10.8113 20.25 11.0332 20.25H12.9668C13.1887 20.25 13.3817 20.0989 13.4356 19.8837L13.9006 17.9915C14.273 17.8735 14.6318 17.721 14.9731 17.5359C15.4494 17.8215 16.4053 18.3951 16.4053 18.3951C16.5956 18.5089 16.8392 18.4796 16.9959 18.3224L18.363 16.9553C18.5197 16.7986 18.55 16.555 18.4357 16.3647L17.5666 14.9169C17.738 14.5916 17.8801 14.2513 17.991 13.901L19.8836 13.4356C20.099 13.3817 20.25 13.1887 20.25 12.9668V11.0332C20.25 10.8113 20.099 10.6183 19.8837 10.5644ZM12 15.3838C10.1344 15.3838 8.61621 13.8656 8.61621 12C8.61621 10.1344 10.1344 8.61621 12 8.61621C13.8656 8.61621 15.3838 10.1344 15.3838 12C15.3838 13.8656 13.8656 15.3838 12 15.3838Z", fill: "white" })));
export var Star = (React.createElement("svg", { viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M11.0685 3.13645C11.3997 2.28795 12.6003 2.28795 12.9315 3.13645L14.6234 7.47067C14.7655 7.83477 15.1068 8.0827 15.497 8.10536L20.1419 8.37506C21.0512 8.42786 21.4222 9.56976 20.7176 10.147L17.1183 13.0954C16.816 13.343 16.6856 13.7442 16.7847 14.1223L17.9635 18.6232C18.1943 19.5043 17.2229 20.2101 16.4562 19.7183L12.5399 17.2063C12.2109 16.9953 11.7891 16.9953 11.4601 17.2063L7.54377 19.7183C6.77708 20.2101 5.80572 19.5043 6.0365 18.6232L7.21535 14.1223C7.31438 13.7442 7.18404 13.343 6.88167 13.0954L3.2824 10.147C2.57778 9.56976 2.9488 8.42786 3.85812 8.37506L8.50302 8.10536C8.89323 8.0827 9.23447 7.83477 9.3766 7.47067L11.0685 3.13645Z", fill: "white" })));
export var Support = (React.createElement("svg", { viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M12 1C7.03 1 3 5.03 3 10V17C3 18.66 4.34 20 6 20H9V12H5V10C5 6.13 8.13 3 12 3C15.87 3 19 6.13 19 10V12H15V20H19V21H12V23H18C19.66 23 21 21.66 21 20V10C21 5.03 16.97 1 12 1Z", fill: "white" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "3", y: "1", width: "18", height: "22" },
        React.createElement("path", { d: "M12 1C7.03 1 3 5.03 3 10V17C3 18.66 4.34 20 6 20H9V12H5V10C5 6.13 8.13 3 12 3C15.87 3 19 6.13 19 10V12H15V20H19V21H12V23H18C19.66 23 21 21.66 21 20V10C21 5.03 16.97 1 12 1Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var MenuIcon = (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { opacity: "0.5" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.5 9.33333V7H24.5V9.33333H3.5ZM3.5 15.1667H24.5V12.8333H3.5V15.1667ZM3.5 21H24.5V18.6667H3.5V21Z", fill: "white" }))));
export var AccountIcon = (React.createElement("svg", { viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { opacity: "0.5" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM6 15.98C7.29 17.92 9.5 19.2 12 19.2C14.5 19.2 16.71 17.92 18 15.98C17.97 13.99 13.99 12.9 12 12.9C10 12.9 6.03 13.99 6 15.98Z", fill: "white" }))));
export var LogoutIcon = (React.createElement("svg", { viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M6.41 15.59L5 17L0 12L5 7L6.41 8.41L3.83 11H13.5V13H3.83L6.41 15.59Z", fill: "white" }),
    React.createElement("path", { d: "M7 2H17V22H7", stroke: "white", strokeWidth: "2" })));
