import { __assign, __read, __spreadArray } from "tslib";
import React from 'react';
import cn from 'classnames';
import FlightSelect from '../../../../FlightSelect/components/FlightSelect';
import { useTheme } from '../../../../theme';
var FlightSeat = function (_a) {
    var flight = _a.flight;
    var css = useTheme('SeatAvailability').FlightSeat;
    var convertFlight = __assign(__assign({}, flight), { pricesForFareGroups: [], segments: flight.segments.map(function (segment) {
            return (__assign(__assign({}, segment), { transferDuration: null, segment: __assign(__assign({}, segment), { departure: __assign(__assign({}, segment.departure), { date: '' }), arrival: __assign(__assign({}, segment.arrival), { date: '' }) }) }));
        }) });
    var rbdInfoBySegments = flight.segments.map(function (segment) {
        return segment.rbdInfo.reduce(function (rbd, rbdSegment) { return __spreadArray(__spreadArray([], __read(rbd), false), [rbdSegment], false); }, []);
    });
    var rbdInfoStyles = rbdInfoBySegments.length > 1 ? { gridTemplateRows: "repeat(".concat(rbdInfoBySegments.length, ", 1fr)") } : {};
    var renderSeatAvailabilityInfo = function () {
        var _a;
        return (React.createElement("div", { className: cn(css.rbdInfo, (_a = {},
                _a[css.rbdInfo__offset] = rbdInfoBySegments.length > 1,
                _a)), style: rbdInfoStyles }, rbdInfoBySegments.map(function (rbdSegments, index) { return (React.createElement("div", { className: css.rbdInfo__row, key: index }, rbdSegments.map(function (rbd, index) { return (React.createElement("div", { className: css.rbdInfo__item, key: index },
            React.createElement("div", { className: css.rbdInfo__title }, rbd.rbd),
            React.createElement("div", { className: css.rbdInfo__count }, rbd.seatCount))); }))); })));
    };
    return (React.createElement(FlightSelect, { className: css.flight, flight: convertFlight, selectedFares: [], renderSlider: rbdInfoBySegments.length ? renderSeatAvailabilityInfo : null, isAvailabilityPage: true }));
};
export default FlightSeat;
