import { __values } from "tslib";
import { createSelector } from 'reselect';
import { getPassengers } from '../order/selectors';
import { TravellerFieldEnum } from '../../../../__generated__/graphql';
export var getSelectedPassengerIds = function (state) { return state.passengers; };
export var getFirstSelectedPassengerRequestFormInitialData = createSelector(getSelectedPassengerIds, getPassengers, function (selectedPassengersIds, passengers) {
    var e_1, _a, e_2, _b;
    var firstSelectedPassengerValues = {
        name: '',
        email: '',
        phone: ''
    };
    try {
        for (var passengers_1 = __values(passengers), passengers_1_1 = passengers_1.next(); !passengers_1_1.done; passengers_1_1 = passengers_1.next()) {
            var passenger = passengers_1_1.value;
            if (passenger.id !== selectedPassengersIds[0]) {
                continue;
            }
            try {
                for (var _c = (e_2 = void 0, __values(passenger.values)), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var _e = _d.value, type = _e.type, value = _e.value;
                    switch (type) {
                        case TravellerFieldEnum.FirstName:
                            firstSelectedPassengerValues.name = value;
                            break;
                        case TravellerFieldEnum.Email:
                            firstSelectedPassengerValues.email = value;
                            break;
                        case TravellerFieldEnum.Phone:
                            firstSelectedPassengerValues.phone = value;
                            break;
                        default:
                            break;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (passengers_1_1 && !passengers_1_1.done && (_a = passengers_1.return)) _a.call(passengers_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return firstSelectedPassengerValues;
});
