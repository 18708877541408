import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { planetIcon } from '../../InfoCard/Icons';
import Button from '../../Button/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '../../Input';
import { initI18n } from '../../utils';
import { useTranslation } from 'react-i18next';
import { DateMaskedInput } from '../../DateMaskedInput/DateMaskedInput';
import { DocTypes } from '../../__generated__/globalTypes';
import { useTheme } from '../../theme';
import { allowedDocuments } from '../../Passenger/components/Field/DocumentType/DocumentType';
import { InputType } from '../../Passenger/types';
import { validate } from '../../Passenger/utils';
var FormFields;
(function (FormFields) {
    FormFields["Type"] = "docType";
    FormFields["Number"] = "docNumber";
    FormFields["Expiry"] = "docExpiryDate";
})(FormFields || (FormFields = {}));
initI18n('DocumentCardForm');
initI18n('Passenger');
var notAvailableInfantDocuments = [DocTypes.Passport, DocTypes.ResidencePermit, DocTypes.MilitaryIDCard];
var DocumentForm = function (props) {
    var _a;
    var css = useTheme('DocumentForm').DocumentForm, _b = __read(useState(((_a = props.document) === null || _a === void 0 ? void 0 : _a.docType) || 'Passport'), 2), typeSelect = _b[0], setTypeSelect = _b[1], t = useTranslation('DocumentCardForm').t, tDocuments = useTranslation('Passenger').t;
    function isDocumentInfoCard(values) {
        return values && values.hasOwnProperty('docType');
    }
    var submitForm = function (values) {
        if (isDocumentInfoCard(values)) {
            props.onSave(values);
        }
    };
    var onChangeSelect = function (e) {
        if (typeof e.target.value === 'string') {
            setTypeSelect(e.target.value);
        }
    };
    var validateForm = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var errors;
        var _a;
        return __generator(this, function (_b) {
            errors = {};
            (_a = props.validators) === null || _a === void 0 ? void 0 : _a.values.forEach(function (field) {
                var fieldValue = values[field.name];
                var inputType = field.name === 'docType'
                    ? InputType.Select
                    : field.name === 'docExpiryDate'
                        ? InputType.Date
                        : InputType.Text;
                errors[field.name] = validate(fieldValue, __assign(__assign({}, values), { nationality: props.nationality }), inputType, field.validationRules);
                if (errors[field.name]) {
                    errors[field.name] = tDocuments(errors[field.name]);
                }
            });
            return [2 /*return*/, errors];
        });
    }); };
    var filterInfantOptions = function (options) {
        var _a;
        if (props.isChild) {
            return (_a = options
                .filter(function (_a) {
                var value = _a.value;
                return !notAvailableInfantDocuments.find(function (doc) { return doc === value; });
            })) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
                var value = _a.value;
                return value;
            });
        }
        return options.map(function (_a) {
            var value = _a.value;
            return value;
        });
    };
    var documentOptions = React.useMemo(function () {
        if (!props.validators) {
            return [];
        }
        var docType = props.validators.values.find(function (value) { return value.name === 'docType'; });
        var documentsForNationality = docType.validationRules.find(function (rule) {
            var _a;
            return (_a = rule.with) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.name === 'nationality' && item.value === props.nationality; });
        });
        if (documentsForNationality) {
            return filterInfantOptions(documentsForNationality.options);
        }
        else {
            return filterInfantOptions(docType.validationRules.find(function (rule) { return !rule.with; }).options);
        }
    }, [props.validators, props.nationality, props.isChild]);
    useEffect(function () {
        var _a;
        if (!((_a = props.document) === null || _a === void 0 ? void 0 : _a.docType) && (documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions[0])) {
            setTypeSelect(documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions[0]);
        }
    }, [documentOptions]);
    return (React.createElement("div", null,
        React.createElement("div", { className: css.title },
            React.createElement("h1", null,
                React.createElement("span", null, planetIcon),
                t('Document'))),
        React.createElement("div", { className: css.form },
            React.createElement(Form, { onSubmit: submitForm, validate: validateForm, initialValues: {
                    docType: (props.document && props.document.docType) || typeSelect,
                    docNumber: (props.document && props.document.docNumber) || '',
                    docExpiryDate: (props.document && props.document.docExpiryDate) || ''
                }, render: function (_a) {
                    var handleSubmit = _a.handleSubmit;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(Field, { name: FormFields.Type, render: function (_a) {
                                var input = _a.input;
                                return (React.createElement(FormControl, null,
                                    React.createElement(InputLabel, { classes: {
                                            root: css.select,
                                            focused: css.select__label__focused
                                        } }, t('Document type')),
                                    React.createElement(Select, { inputProps: { name: FormFields.Type }, onChange: function (e) {
                                            input.onChange(e);
                                            onChangeSelect(e);
                                        }, className: css.form__item, value: typeSelect || (props.document && props.document.docType) }, documentOptions.map(function (document) {
                                        if (props.nationality === 'RU' && allowedDocuments.ru[document]) {
                                            return (React.createElement(MenuItem, { className: css.select__item, key: document, value: document }, tDocuments(document)));
                                        }
                                        else if (props.nationality !== 'RU' &&
                                            allowedDocuments.other[document]) {
                                            return (React.createElement(MenuItem, { className: css.select__item, key: document, value: document }, tDocuments(document)));
                                        }
                                        else {
                                            return null;
                                        }
                                    }))));
                            } }),
                        React.createElement("div", { className: css.row__inputs },
                            React.createElement(Field, { name: FormFields.Number, render: function (_a) {
                                    var input = _a.input, meta = _a.meta;
                                    return (React.createElement(Input, __assign({}, input, { className: css.form__item, TextFieldProps: {
                                            label: t('Document number'),
                                            onChange: function (event) {
                                                input.onChange(event);
                                            },
                                            helperText: meta.touched && tDocuments(meta.error),
                                            error: meta.error && meta.touched
                                        } })));
                                } }),
                            React.createElement(Field, { name: FormFields.Expiry, render: function (_a) {
                                    var input = _a.input, meta = _a.meta;
                                    return (React.createElement(Input, __assign({}, input, { className: css.form__item, inputComponent: DateMaskedInput, TextFieldProps: {
                                            label: t('Expiry date'),
                                            onChange: function (event) {
                                                input.onChange(event);
                                            },
                                            helperText: meta.touched && tDocuments(meta.error),
                                            error: meta.error && meta.touched
                                        } })));
                                } })),
                        React.createElement("div", { className: css.footer },
                            React.createElement(Button, { type: 'submit' }, t('Save')))));
                } }))));
};
export default DocumentForm;
