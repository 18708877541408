import { combineReducers } from 'redux';
import passengers from './passengers/reducer';
import mode from './mode/reducers';
import steps from './steps/reducer';
import reason from './reason/reducer';
import documents from './documents/reducer';
import customer from './customer/reducer';
import refundInfo from './refundInfo/reducer';
import aviaOrder from './aviaOrder/reducer';
export default combineReducers({
    passengers: passengers,
    mode: mode,
    steps: steps,
    reason: reason,
    documents: documents,
    customer: customer,
    refundInfo: refundInfo,
    aviaOrder: aviaOrder
});
