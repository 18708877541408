import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import Payment from '../../../../../../Checkout/components/Checkout/routes/Payment';
import { getCheckinIsBlockedForPayment, getCheckinOrderId, getPriceToPay } from '../../../../store/order/selectors';
import { CheckinStep, useSteps } from '../../../../utils';
import { useRefetchCheckinOrder } from '../../../../../../__queries__/Order';
import SimpleLoader from '../../../../../../SimpleLoader';
import * as ResetOrderPaymentBlockMutation from '../../../../../../__queries__/ResetOrderPaymentBlock.graphql';
var PaymentPage = memo(function () {
    var _a = __read(useState(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var location = useLocation();
    var orderId = useSelector(getCheckinOrderId);
    var priceToPay = useSelector(getPriceToPay);
    var _b = useSteps(), previousStep = _b.previousStep, setStep = _b.setStep, goToNextStep = _b.goToNextStep;
    var refetch = useRefetchCheckinOrder(orderId);
    var _c = __read(useMutation(ResetOrderPaymentBlockMutation.ResetOrderPaymentBlock), 1), resetOrderPaymentBlock = _c[0];
    var checkinIsBlockedForPayment = useSelector(getCheckinIsBlockedForPayment);
    useEffect(function () {
        var refetchInterval = setInterval(function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, refetch()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, 60000 * 3);
        return function () { return clearInterval(refetchInterval); };
    }, []);
    var unlockOrder = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var orderIsUnlocked;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!checkinIsBlockedForPayment) return [3 /*break*/, 4];
                    setIsLoading(true);
                    return [4 /*yield*/, resetOrderPaymentBlock({
                            variables: {
                                id: orderId
                            }
                        })];
                case 1:
                    orderIsUnlocked = (_b = (_a = (_c.sent())) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.ResetOrderPaymentBlock;
                    if (!orderIsUnlocked) return [3 /*break*/, 3];
                    // Fetch new order info.
                    return [4 /*yield*/, refetch()];
                case 2:
                    // Fetch new order info.
                    _c.sent();
                    // Redirect to services page.
                    setStep(CheckinStep.Baggage);
                    _c.label = 3;
                case 3:
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    goToNextStep(CheckinStep.Passengers);
                    _c.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [checkinIsBlockedForPayment, setStep, goToNextStep]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Payment, { orderId: orderId, priceToPay: priceToPay, goToPrevStep: function () { return previousStep(CheckinStep.Payment); }, onSuccess: function () { return setStep(CheckinStep.Passengers); }, redirectPathname: location.pathname, onPaymentMethodsLoaded: refetch, onBackToOrder: unlockOrder }),
        isLoading && React.createElement(SimpleLoader, null)));
});
export default PaymentPage;
