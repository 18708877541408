import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
var RouteArrow = function (_a) {
    var _b;
    var timeInRoute = _a.timeInRoute, thin = _a.thin;
    var theme = useTheme('DesktopFlightRoute').RouteArrowStyles;
    return (React.createElement("div", { className: cn(theme.route, (_b = {},
            _b[theme.route_thin] = thin,
            _b)) },
        React.createElement("div", { className: theme.circle }),
        React.createElement("div", { className: theme.timeInRoute }, timeInRoute),
        React.createElement("div", { className: cn(theme.circle, theme.circle_right) })));
};
export default RouteArrow;
