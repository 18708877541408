import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form';
import cn from 'classnames';
import Input from '../../../Input';
import { capitalizeFirstLetter, initI18n } from '../../../utils';
import PhoneInput from '../../../PhoneInput';
import { useTheme } from '../../../theme';
import { InputType } from '../../../Passenger/types';
import { validate } from '../../../Passenger/utils';
import { escapedT, PAYMENT_COMMENT } from '../utils';
initI18n('Contacts');
var OtherContacts = memo(function (props) {
    var theme = useTheme('Contacts').ContactsStyles;
    var t = useTranslation('Contacts').t;
    var renderPhoneInput = function (phoneContact) {
        var phoneField = useField(phoneContact.name, {
            type: phoneContact.input,
            initialValue: phoneContact.defaultValue,
            validate: function (value) { return escapedT(validate(value, {}, InputType.Phone, phoneContact.validations)); }
        });
        if (props.isInputsReadonly && !phoneField.input.value) {
            return null;
        }
        return (React.createElement("div", { className: theme.field },
            React.createElement(PhoneInput, { disabled: props.isInputsReadonly, className: theme.field__contact, value: phoneField.input.value, textFieldsProps: __assign(__assign({}, phoneField.input), { variant: props.isMobile ? 'standard' : 'filled', disabled: props.isEmailChecking || props.isInputsReadonly, label: t('Phone'), type: 'tel', helperText: ((phoneField.meta.modified && phoneField.meta.touched) || phoneField.meta.submitFailed) &&
                        (phoneField.meta.error || phoneField.meta.submitError), error: ((phoneField.meta.modified && phoneField.meta.touched) || phoneField.meta.submitFailed) &&
                        (!!phoneField.meta.error || !!phoneField.meta.submitError) }) })));
    };
    var renderEmailInput = function (emailContact) {
        var emailField = useField(emailContact.name, {
            type: emailContact.input,
            initialValue: emailContact.defaultValue,
            validate: function (value) { return escapedT(validate(value, {}, InputType.Text, emailContact.validations)); }
        });
        if (props.isInputsReadonly && !emailField.input.value) {
            return null;
        }
        return (React.createElement("div", { className: theme.field },
            React.createElement(Input, { variant: props.isMobile ? 'standard' : 'filled', absoluteHintPosition: true, inputClassName: theme.field__contact, disabled: props.isEmailChecking || props.isInputsReadonly, TextFieldProps: __assign(__assign({}, emailField.input), { label: t('Email'), type: 'email', helperText: ((emailField.meta.modified && emailField.meta.touched) || emailField.meta.submitFailed) &&
                        (emailField.meta.error || emailField.meta.submitError), error: ((emailField.meta.modified && emailField.meta.touched) || emailField.meta.submitFailed) &&
                        (!!emailField.meta.error || !!emailField.meta.submitError), onChange: function (e) { return props.onChangeHandler(e, emailField); } }) })));
    };
    return (React.createElement(React.Fragment, null, __spreadArray([], __read(props.otherContacts), false).map(function (_a) {
        var _b = __read(_a, 2), contactsType = _b[0], contacts = _b[1];
        if (contactsType === PAYMENT_COMMENT) {
            return null;
        }
        return (React.createElement("div", { key: contactsType },
            React.createElement("div", { className: theme.contactsSubheader }, t(capitalizeFirstLetter(contactsType.toLowerCase()))),
            contacts.map(function (contact, index) { return (React.createElement("div", { key: index, className: cn(theme.fields, theme.fields__otherContacts) },
                contact.phone && renderPhoneInput(contact.phone),
                contact.email && renderEmailInput(contact.email))); })));
    })));
});
export default OtherContacts;
