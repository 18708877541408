import { __assign, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import Button from '../../Button/Button';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import Input from '../Input/Input';
import Modal from '../../Modal';
import CloseIcon from '../../CloseIcon/components/CloseIcon';
import Resend from '../../Contacts/components/Resend/Resend';
initI18n('Modal2FA');
var Modal2FA = function (props) {
    var css = useTheme('Modal2FA').Modal2FA;
    var t = useTranslation('Modal2FA').t;
    var _a = __read(useState(false), 2), codeSent = _a[0], setCodeSent = _a[1];
    return (React.createElement(Modal, { open: props.open, onClose: props.onClose, classes: { paper: css.modal__paper }, closeButtonRenderer: function (onClick) { return (React.createElement("div", { className: css.popup__close },
            React.createElement(CloseIcon, { onClick: onClick }))); } },
        React.createElement(Form, { onSubmit: props.onSubmit }, function (_a) {
            var handleSubmit = _a.handleSubmit;
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("h3", { className: css.header }, t('Verification code')),
                React.createElement(Field, { name: 'code', validate: function (value) {
                        if (!value || value.trim().length === 0) {
                            return t('Required');
                        }
                    }, render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        return (React.createElement(Input, __assign({}, input, { absoluteHintPosition: true, variant: 'outlined', TextFieldProps: {
                                type: 'text',
                                error: meta.touched && !meta.valid,
                                helperText: meta.touched && !meta.valid ? meta.error : null,
                                onChange: function (event) {
                                    input.onChange(event);
                                }
                            } })));
                    } }),
                React.createElement("div", { className: css.buttons },
                    React.createElement(Resend, { sendCode: function () {
                            setCodeSent(false);
                            props.onResendCode().then(function () {
                                setCodeSent(true);
                            });
                        }, codeSent: codeSent, labelClassName: css.label }),
                    React.createElement(Button, { type: "submit", variant: "flat", isLoading: props.isLoading, classes: {
                            root: css.sendButton
                        } }, t('Send')))));
        })));
};
export default Modal2FA;
