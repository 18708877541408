import { __assign, __read } from "tslib";
import * as React from 'react';
import { initI18n } from '../../utils';
import Location from '../../Location';
import FlightCard from '../../FlightCard';
import { getDurationString } from '../../Results/utils';
import { useTheme } from '../../theme';
import TransferInfo from '../../TransferInfo';
import { useCallback, useState } from 'react';
import AircraftPopup from '../../AircraftPopup/components/AircraftPopup';
import Modal from '../../Modal';
initI18n('FlightInfo');
var FlightInfo = function (_a) {
    var flight = _a.flight;
    var css = useTheme('FlightInfo').FlightInfo;
    var getNextSegment = function (index) { return flight.segments[index + 1].segment; };
    var _b = __read(useState(false), 2), aircraftModalOpen = _b[0], setAircraftModalOpen = _b[1];
    var _c = __read(useState(null), 2), aircraftId = _c[0], setAircraftId = _c[1];
    var handleAircraftModalOpen = useCallback(function (id) {
        setAircraftModalOpen(true);
        setAircraftId(id);
    }, [aircraftId]);
    return (React.createElement("div", null,
        flight.segments.map(function (_a, index) {
            var _b, _c, _d, _e;
            var transferDuration = _a.transferDuration, segment = _a.segment;
            return (React.createElement("div", { key: segment.flightNumber, className: css.segment },
                React.createElement("div", { className: css.segment__info },
                    React.createElement(Location, __assign({ className: css.segment__location, type: "departure" }, segment.departure)),
                    segment.aircraft && (React.createElement(FlightCard, { onAircarftInfoClick: handleAircraftModalOpen, className: css.segment__flight, airline: segment.operatingAirline, flightNumber: "".concat((_b = segment.marketingAirline) === null || _b === void 0 ? void 0 : _b.iata, "-").concat(segment.flightNumber), duration: segment.duration ? getDurationString(segment.duration) : null, aircraft: segment.aircraft, amenities: (_c = segment.flightInfo) === null || _c === void 0 ? void 0 : _c.amenities, markers: (_d = segment.flightInfo) === null || _d === void 0 ? void 0 : _d.markers, statistics: (_e = segment.flightInfo) === null || _e === void 0 ? void 0 : _e.statistics })),
                    React.createElement(Location, __assign({ className: css.segment__location, type: "arrival" }, segment.arrival))),
                transferDuration && (React.createElement(TransferInfo, { className: css.segment__transfer, segment: segment, nextSegment: getNextSegment(index), transferDuration: transferDuration, showStops: true }))));
        }),
        React.createElement(Modal, { open: aircraftModalOpen, onClose: function () { return setAircraftModalOpen(false); } },
            React.createElement(AircraftPopup, { id: aircraftId }))));
};
export default FlightInfo;
