import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import ActionButton from '../../../Button/components/ActionButton/ActionButton';
var StepsButtons = function (props) {
    var _a;
    var css = useTheme('Refund').StepsButtons;
    return (React.createElement("div", { className: cn(css.wrapper, props.className, (_a = {},
            _a[css.right] = props.position === 'right',
            _a[css.center] = props.position === 'center',
            _a[css.left] = props.position === 'left',
            _a)) },
        props.backButton && (React.createElement(ActionButton, __assign({}, __assign(__assign({ white: true }, props.backButton), { className: cn(css.backButton, css.button, props.backButton.className) })))),
        props.nextButton && (React.createElement(ActionButton, __assign({}, __assign(__assign({}, props.nextButton), { className: cn(css.button, props.nextButton.className) }))))));
};
export default StepsButtons;
