import { __assign } from "tslib";
import { TravellerFieldEnum } from '../__generated__/globalTypes';
export var getAvailableSegments = function (segments, passengers, servicesMap) {
    var availableSegments = [];
    var availableSegmentIds = Object.keys(servicesMap);
    segments
        .filter(function (segment) { return availableSegmentIds.indexOf(segment.id) !== -1; })
        .forEach(function (segment) {
        var availablePassengers = Object.keys(servicesMap[segment.id]);
        var passengersArray = [];
        passengers
            .filter(function (passenger) { return availablePassengers.indexOf(passenger.id) !== -1; })
            .forEach(function (passenger) {
            var _a, _b;
            var firstName = (_a = passenger.values.find(function (value) { return value.type === TravellerFieldEnum.FirstName; })) === null || _a === void 0 ? void 0 : _a.value, lastName = (_b = passenger.values.find(function (value) { return value.type === TravellerFieldEnum.LastName; })) === null || _b === void 0 ? void 0 : _b.value;
            passengersArray.push({
                passengerId: passenger.id,
                segmentId: segment.id,
                name: "".concat(firstName.toLowerCase(), " ").concat(lastName.toLowerCase()),
                serviceId: servicesMap[segment.id][passenger.id]
            });
        });
        availableSegments.push({
            segmentId: segment.id,
            passengers: passengersArray,
            direction: "".concat(segment.departure.airport.city.name, " - ").concat(segment.arrival.airport.city.name)
        });
    });
    return availableSegments;
};
export var computeServicesMap = function (services, selectedServices) {
    var servicesMap = {};
    services.forEach(function (service) {
        service.allowedSegments.forEach(function (segments) {
            var segment = segments[0];
            service.allowedPassengers.forEach(function (passengers) {
                var _a, _b;
                var _c;
                var passenger = passengers[0];
                var alreadySelectedService = (_c = Object.keys(selectedServices[passenger])) === null || _c === void 0 ? void 0 : _c.find(function (serviceId) {
                    var _a;
                    return (((_a = selectedServices[passenger][serviceId].find(function (selected) { return selected.segments[0] === segment; })) === null || _a === void 0 ? void 0 : _a.number) > 0 && services.map(function (service) { return service.id; }).includes(serviceId));
                });
                servicesMap = __assign(__assign({}, servicesMap), (_a = {}, _a[segment] = __assign(__assign({}, servicesMap[segment]), (_b = {}, _b[passenger] = typeof alreadySelectedService !== 'undefined' ? alreadySelectedService : service.id, _b)), _a));
            });
        });
    });
    return servicesMap;
};
export var getNewSelectedServices = function (serviceMap, selectedServicesState, allSegments) {
    var selectedServices = [];
    var selectedSegments = Object.keys(selectedServicesState);
    var availableSegments = Object.keys(serviceMap);
    if (allSegments) {
        var firstSegment_1 = selectedSegments[0];
        var selectedPassengers = Object.keys(selectedServicesState[firstSegment_1]);
        selectedPassengers.forEach(function (passenger) {
            selectedServices.push({
                count: selectedServicesState[firstSegment_1][passenger] ? 1 : 0,
                serviceId: serviceMap[firstSegment_1][passenger],
                segmentIds: availableSegments,
                passengerId: passenger
            });
        });
    }
    else {
        availableSegments.forEach(function (segment) {
            var passengers = Object.keys(serviceMap[segment]);
            passengers.forEach(function (passenger) {
                var _a;
                if ((_a = selectedServicesState[segment]) === null || _a === void 0 ? void 0 : _a.hasOwnProperty(passenger)) {
                    selectedServices.push({
                        serviceId: serviceMap[segment][passenger],
                        segmentIds: [segment],
                        count: selectedServicesState[segment][passenger] ? 1 : 0,
                        passengerId: passenger
                    });
                }
            });
        });
    }
    return selectedServices;
};
