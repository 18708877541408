import { __assign } from "tslib";
import React from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import BaggageItem from './BaggageItem/BaggageItem';
import SwipeableBaggageViews from './SwipeableBaggageViews/SwipeableBaggageViews';
var BaggageList = function (_a) {
    var items = _a.items, className = _a.className, itemsToView = _a.itemsToView;
    var css = useTheme('BaggageList').BaggageList;
    if (itemsToView) {
        return (React.createElement(SwipeableBaggageViews, { items: items, itemsToView: itemsToView }, function (item) { return React.createElement(BaggageItem, __assign({}, item, { key: item.baggage[0].id })); }));
    }
    return (React.createElement("div", { className: cn(css.root, className) }, items.map(function (item, i) { return (React.createElement(BaggageItem, __assign({}, item, { key: i }))); })));
};
export default BaggageList;
