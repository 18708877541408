import { __read } from "tslib";
import React, { useState } from 'react';
import cn from 'classnames';
import Button from '../../../Button';
import { useTheme } from '../../../theme';
import { useToggleable } from '../../../hooks';
import { SeatSelection } from '../../../Icons';
import SeatAvailabilityModal from './SeatAvailabilityModal/SeatAvailabilityModal';
var SeatAvailability = function (_a) {
    var className = _a.className, renderTrigger = _a.renderTrigger, searchParameters = _a.searchParameters;
    var css = useTheme('SeatAvailability').SeatAvailability;
    var _b = useToggleable(false), isOpen = _b.isOpen, open = _b.open, close = _b.close;
    var _c = __read(useState(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    return (React.createElement(React.Fragment, null,
        renderTrigger ? (renderTrigger(open, isLoading)) : (React.createElement(Button, { className: cn(className, css.button), variant: "flat", isLoading: isLoading, onClick: open }, SeatSelection)),
        searchParameters && isOpen && (React.createElement(SeatAvailabilityModal, { isOpen: isOpen, onClose: close, searchParameters: searchParameters, setLoading: setIsLoading }))));
};
export default SeatAvailability;
