import * as RegisteredPassenger from './RegisteredPassenger.css';
import * as Service from './components/Service/Service.css';
import * as CancelModal from './components/CancelModal/CancelModal.css';
import * as SendToEmail from './components/SendToEmail/SendToEmail.css';
export default {
    RegisteredPassenger: RegisteredPassenger,
    Service: Service,
    CancelModal: CancelModal,
    SendToEmail: SendToEmail
};
