import { __assign } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import OrderRefundStatus from '../../../../../Refund/Components/OrderRefundStatus/OrderRefundStatus';
import { ExareStatus } from '../../../../../__generated__/graphql';
import { useHistory } from 'react-router';
import { Button } from '../../../../../index';
import { ChevronRightIcon } from '../../../../../AircraftPopup/components/Icons';
import { useTheme } from '../../../../../theme';
var ExchangeCheckoutStatus = function (props) {
    var status = props.status, onGoToTicket = props.onGoToTicket, passengersNames = props.passengersNames, newBookingId = props.newBookingId;
    var theme = useTheme('Exchange').ExchangeCheckoutStatus;
    var history = useHistory();
    var t = useTranslation('Exchange').t;
    var titleRenderer = useCallback(function () {
        switch (status) {
            case ExareStatus.InProcess:
            case ExareStatus.Refused:
            case ExareStatus.Rejected:
            case ExareStatus.TimelimitExpired:
                return t('Request for changes');
            case ExareStatus.Finished:
                return (React.createElement("span", null,
                    t('Successfully create new booking'),
                    "\u00A0",
                    newBookingId,
                    "\u00A0",
                    t('for'),
                    "\u00A0 ",
                    passengersNames));
            default:
                return t('Request for changes');
        }
    }, [status, passengersNames]);
    var handleGoToNewBooking = useCallback(function () {
        history.push("/booking/".concat(newBookingId));
        window.location.reload();
    }, [newBookingId]);
    var renderStatus = status === ExareStatus.Finished && newBookingId
        ? useCallback(function () {
            return (React.createElement(Button, { onClick: handleGoToNewBooking },
                t('View new booking'),
                "\u00A0",
                newBookingId,
                React.createElement("span", { className: theme.icon }, ChevronRightIcon)));
        }, [status, newBookingId, handleGoToNewBooking])
        : null;
    return (React.createElement(OrderRefundStatus, __assign({}, props, { wrapperClassName: theme.wrapper, customTranslate: t, renderTitle: titleRenderer, onGoToTicket: onGoToTicket, renderStatus: renderStatus })));
};
export default ExchangeCheckoutStatus;
