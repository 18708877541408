import * as React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { useGraphQLClient } from '../../../graphql-client';
import Account from './Account/Account';
import Analytics from '../../../Engine/components/Analytics';
import { OverrideComponent } from '../../../renderProps';
export var Module = function () {
    var client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(Analytics, null),
        React.createElement(OverrideComponent, { componentProps: null, component: { Account: Account } })));
};
export default Module;
