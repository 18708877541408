import { __assign } from "tslib";
import * as React from 'react';
import { useField } from 'react-final-form';
import Input from '../../../../Input';
import css from './Field.css';
import { useTranslation } from 'react-i18next';
var Field = function (_a) {
    var name = _a.name, label = _a.label, password = _a.password, validate = _a.validate, error = _a.error;
    var t = useTranslation('LoginPage').t;
    var field = useField(name, {
        type: password ? 'password' : 'text',
        validate: validate
    });
    return (React.createElement(Input, { label: label, className: css.field, TextFieldProps: __assign(__assign({}, field.input), { error: (field.meta.error && field.meta.submitFailed) || !!error, helperText: (field.meta.error && field.meta.submitFailed && t(field.meta.error)) || error }) }));
};
export default Field;
