import * as React from 'react';
export var seatsIcon = (React.createElement("svg", { width: "160", height: "100", viewBox: "0 0 160 100", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M33.064 41.6714H30.5262C28.1786 41.6714 26.4867 43.9343 27.1423 46.1973L37.7803 82.4255C38.4147 84.5827 40.4027 86.0631 42.6445 86.0631H64.8721C67.0081 86.0631 68.7424 84.3289 68.7424 82.1928V78.8301H43.9558L33.064 41.6714Z", fill: "white" }),
    React.createElement("path", { d: "M71.144 73.0142H49.7835C46.8861 58.1676 39.1668 44.3785 37.5171 29.2569C37.3057 27.3958 35.7406 26 33.8795 26H33.8161C31.6166 26 29.9035 27.9034 30.1784 30.0818C32.1453 45.9223 40.5837 60.3459 42.8678 76.1231C43.312 79.1474 45.9133 81.3892 48.9799 81.3892H75.8179V77.6881C75.8179 75.1079 73.7242 73.0142 71.144 73.0142Z", fill: "#3D7CB4" }),
    React.createElement("path", { d: "M68.0239 73.0142H46.6634C43.766 58.1676 36.0466 44.3785 34.397 29.2569C34.1855 27.3958 32.6205 26 30.7594 26H30.696C28.4965 26 26.7834 27.9034 27.0583 30.0818C29.0252 45.9223 37.4636 60.3459 39.7477 76.1231C40.1918 79.1474 42.7932 81.3892 45.8598 81.3892H72.6978V77.6881C72.6978 75.1079 70.6041 73.0142 68.0239 73.0142Z", fill: "#CAE434" }),
    React.createElement("path", { d: "M40 99H64V86H44.4099L40 99Z", fill: "#32597C" }),
    React.createElement("path", { d: "M63.878 95.3145H41.4178C40.3815 95.3145 39.5566 96.1604 39.5566 97.1756V99.079H65.7391V97.1756C65.7391 96.1604 64.9143 95.3145 63.878 95.3145Z", fill: "white" }),
    React.createElement("path", { d: "M60.5785 62.6934H34.1846V67.2404H63.7297V65.8657C63.7297 64.1103 62.3127 62.6934 60.5785 62.6934Z", fill: "white" }),
    React.createElement("path", { d: "M39.3713 65.1711C39.408 62.625 37.3737 60.5314 34.8276 60.4947C32.2816 60.4581 30.1879 62.4924 30.1513 65.0384C30.1147 67.5845 32.1489 69.6781 34.695 69.7148C37.241 69.7514 39.3347 67.7171 39.3713 65.1711Z", fill: "white" }),
    React.createElement("path", { d: "M90.064 41.6714H87.5262C85.1786 41.6714 83.4867 43.9343 84.1423 46.1973L94.7803 82.4255C95.4147 84.5827 97.4027 86.0631 99.6445 86.0631H121.872C124.008 86.0631 125.742 84.3289 125.742 82.1928V78.8301H100.956L90.064 41.6714Z", fill: "white" }),
    React.createElement("path", { d: "M128.144 73.0142H106.784C103.886 58.1676 96.1668 44.3785 94.5171 29.2569C94.3057 27.3958 92.7406 26 90.8795 26H90.8161C88.6166 26 86.9035 27.9034 87.1784 30.0818C89.1453 45.9223 97.5837 60.3459 99.8678 76.1231C100.312 79.1474 102.913 81.3892 105.98 81.3892H132.818V77.6881C132.818 75.1079 130.724 73.0142 128.144 73.0142Z", fill: "#3D7CB4" }),
    React.createElement("path", { d: "M125.024 73.0142H103.663C100.766 58.1676 93.0466 44.3785 91.397 29.2569C91.1855 27.3958 89.6205 26 87.7594 26H87.696C85.4965 26 83.7834 27.9034 84.0583 30.0818C86.0252 45.9223 94.4636 60.3459 96.7477 76.1231C97.1918 79.1474 99.7932 81.3892 102.86 81.3892H129.698V77.6881C129.698 75.1079 127.604 73.0142 125.024 73.0142Z", fill: "#CAE434" }),
    React.createElement("path", { d: "M97 99H121V86H101.41L97 99Z", fill: "#32597C" }),
    React.createElement("path", { d: "M120.878 95.3145H98.4178C97.3815 95.3145 96.5566 96.1604 96.5566 97.1756V99.079H122.739V97.1756C122.739 96.1604 121.914 95.3145 120.878 95.3145Z", fill: "white" }),
    React.createElement("path", { d: "M117.578 62.6934H91.1846V67.2404H120.73V65.8657C120.73 64.1103 119.313 62.6934 117.578 62.6934Z", fill: "white" }),
    React.createElement("path", { d: "M96.3713 65.1711C96.408 62.625 94.3737 60.5314 91.8276 60.4947C89.2816 60.4581 87.1879 62.4924 87.1513 65.0384C87.1147 67.5845 89.1489 69.6781 91.695 69.7148C94.241 69.7514 96.3347 67.7171 96.3713 65.1711Z", fill: "white" })));
export var baggageIcon = (React.createElement("svg", { width: "160", height: "100", viewBox: "0 0 160 100", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M100.149 95.7284C100.149 97.482 101.582 98.8636 103.28 98.8636C104.978 98.8636 106.41 97.4288 106.41 95.7284C106.41 93.9749 104.978 92.5933 103.28 92.5933C101.582 92.5933 100.149 93.9749 100.149 95.7284Z", fill: "#231F20" }),
    React.createElement("path", { d: "M78.1299 95.7284C78.1299 97.482 79.5624 98.8636 81.2603 98.8636C83.0112 98.8636 84.3907 97.4288 84.3907 95.7284C84.3907 93.9749 82.9582 92.5933 81.2603 92.5933C79.5624 92.5933 78.1299 93.9749 78.1299 95.7284Z", fill: "#231F20" }),
    React.createElement("path", { d: "M86.395 46.6773H98.333C101.145 46.6773 103.374 44.3924 103.374 41.6292V21.5428C103.374 18.7264 101.092 16.4946 98.333 16.4946H86.395C83.5829 16.4946 81.3545 18.7796 81.3545 21.5428V41.6292C81.3545 44.3924 83.636 46.6773 86.395 46.6773ZM98.333 18.8327C99.8186 18.8327 101.039 20.0549 101.039 21.5428V41.6292C101.039 43.1171 99.8186 44.3393 98.333 44.3393H86.395C84.9093 44.3393 83.689 43.1171 83.689 41.6292V21.5428C83.689 20.0549 84.9093 18.8327 86.395 18.8327H98.333Z", fill: "#474747" }),
    React.createElement("path", { d: "M75.794 95.9927H108.584C112.298 95.9927 115.322 92.9638 115.322 89.2441V33.9268C115.322 30.2071 112.298 27.1782 108.584 27.1782H75.794C72.08 27.1782 69.0557 30.2071 69.0557 33.9268V89.2441C69.0557 92.9638 72.0269 95.9927 75.794 95.9927Z", fill: "url(#paint0_linear)" }),
    React.createElement("rect", { opacity: "0.41", width: "35.532", height: "5.922", rx: "2.961", transform: "matrix(1 0 0 -1 74.9717 59.1318)", fill: "url(#paint1_linear)" }),
    React.createElement("rect", { opacity: "0.41", width: "35.532", height: "5.922", rx: "2.961", transform: "matrix(1 0 0 -1 74.9717 78.3867)", fill: "url(#paint2_linear)" }),
    React.createElement("rect", { opacity: "0.41", width: "35.532", height: "5.922", rx: "2.961", transform: "matrix(1 0 0 -1 74.9717 68.7593)", fill: "url(#paint3_linear)" }),
    React.createElement("rect", { opacity: "0.41", width: "35.532", height: "5.922", rx: "2.961", transform: "matrix(1 0 0 -1 74.9717 88.0122)", fill: "url(#paint4_linear)" }),
    React.createElement("rect", { opacity: "0.41", width: "35.532", height: "5.922", rx: "2.961", transform: "matrix(1 0 0 -1 74.9717 49.5063)", fill: "url(#paint5_linear)" }),
    React.createElement("rect", { opacity: "0.41", width: "35.532", height: "5.922", rx: "2.961", transform: "matrix(1 0 0 -1 74.9717 39.5811)", fill: "url(#paint6_linear)" }),
    React.createElement("path", { d: "M57.8804 96.2215C57.8804 97.6604 56.705 98.8376 55.2683 98.8376C53.8317 98.8376 52.6562 97.6604 52.6562 96.2215C52.6562 94.7827 53.8317 93.6055 55.2683 93.6055C56.705 93.6055 57.8804 94.7827 57.8804 96.2215Z", fill: "#231F20" }),
    React.createElement("path", { d: "M76.2973 96.2215C76.2973 97.6604 75.1219 98.8376 73.6853 98.8376C72.2487 98.8376 71.0732 97.6604 71.0732 96.2215C71.0732 94.7827 72.2487 93.6055 73.6853 93.6055C75.0566 93.6055 76.2973 94.7827 76.2973 96.2215Z", fill: "#231F20" }),
    React.createElement("path", { d: "M69.2973 65.1959H59.3062C56.9553 65.1959 55.0615 63.2993 55.0615 60.9448V15.6217C55.0615 13.2672 56.9553 11.3706 59.3062 11.3706H69.2973C71.6482 11.3706 73.5419 13.2672 73.5419 15.6217V60.9448C73.5419 63.2339 71.6482 65.1959 69.2973 65.1959ZM59.3714 13.3326C58.1307 13.3326 57.0859 14.3791 57.0859 15.6217V60.9448C57.0859 62.1874 58.1307 63.2339 59.3714 63.2339H69.3626C70.6033 63.2339 71.6482 62.1874 71.6482 60.9448V15.6217C71.6482 14.3791 70.6033 13.3326 69.3626 13.3326H59.3714Z", fill: "#414141" }),
    React.createElement("path", { d: "M78.2778 96.1609H50.3771C47.1884 96.1609 44.6641 93.5661 44.6641 90.4392V43.335C44.6641 40.1415 47.2548 37.6133 50.3771 37.6133H78.2778C81.4665 37.6133 83.9908 40.208 83.9908 43.335V90.3727C83.9908 93.5662 81.4001 96.1609 78.2778 96.1609Z", fill: "url(#paint7_linear)" }),
    React.createElement("path", { d: "M47.9967 86.432C47.8774 88.7991 49.7645 90.7837 52.1346 90.7837H76.6711C79.0679 90.7837 80.9647 88.7561 80.805 86.3646L78.1448 46.518C77.9995 44.3418 76.192 42.6509 74.0109 42.6509H54.1435C51.9364 42.6509 50.1168 44.3811 50.0057 46.5854L47.9967 86.432Z", fill: "url(#paint8_linear)" }),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear", x1: "92.189", y1: "27.1782", x2: "92.189", y2: "95.9927", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FF9CC3" }),
            React.createElement("stop", { offset: "1", stopColor: "#CB5684" })),
        React.createElement("linearGradient", { id: "paint1_linear", x1: "17.766", y1: "0", x2: "17.766", y2: "5.922", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FF7DB0" }),
            React.createElement("stop", { offset: "1", stopColor: "#BC2962" })),
        React.createElement("linearGradient", { id: "paint2_linear", x1: "17.766", y1: "0", x2: "17.766", y2: "5.922", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FF7DB0" }),
            React.createElement("stop", { offset: "1", stopColor: "#BC2962" })),
        React.createElement("linearGradient", { id: "paint3_linear", x1: "17.766", y1: "0", x2: "17.766", y2: "5.922", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FF7DB0" }),
            React.createElement("stop", { offset: "1", stopColor: "#BC2962" })),
        React.createElement("linearGradient", { id: "paint4_linear", x1: "17.766", y1: "0", x2: "17.766", y2: "5.922", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FF7DB0" }),
            React.createElement("stop", { offset: "1", stopColor: "#BC2962" })),
        React.createElement("linearGradient", { id: "paint5_linear", x1: "17.766", y1: "0", x2: "17.766", y2: "5.922", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FF7DB0" }),
            React.createElement("stop", { offset: "1", stopColor: "#BC2962" })),
        React.createElement("linearGradient", { id: "paint6_linear", x1: "17.766", y1: "0", x2: "17.766", y2: "5.922", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FF7DB0" }),
            React.createElement("stop", { offset: "1", stopColor: "#BC2962" })),
        React.createElement("linearGradient", { id: "paint7_linear", x1: "63.8632", y1: "37.638", x2: "63.8632", y2: "94.2351", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FFEB35" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFC700" })),
        React.createElement("linearGradient", { id: "paint8_linear", x1: "64.0453", y1: "90.7837", x2: "64.0453", y2: "42.6509", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FFE601" }),
            React.createElement("stop", { offset: "1", stopColor: "#FFB800" })))));
export var mealIcon = (React.createElement("svg", { width: "160", height: "100", viewBox: "0 0 160 100", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#mealClip0)" },
        React.createElement("path", { d: "M48.6812 54.7968L35.9219 17.7086L38.4177 16.6445L51.2737 53.9842L48.6812 54.7968Z", fill: "white" }),
        React.createElement("mask", { id: "mealMask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "34", y: "40", width: "44", height: "62" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M77.0377 40.1682H34.4453L34.4453 43.2637H77.0377L77.0377 40.1682ZM55.4051 69.3962L54.9978 100.636L39.7858 100.846L36.1183 43.264H55.7458L55.7457 43.2675H75.3634L72.4948 101.022L54.9979 100.64L55.4051 69.3962Z", fill: "url(#mealPaint0_linear)" })),
        React.createElement("g", { mask: "url(#mealMask0)" },
            React.createElement("path", { d: "M75.3615 43.2671L72.493 101.022L54.9961 100.639L55.7437 43.2671H75.3615Z", fill: "url(#mealPaint1_linear)" }),
            React.createElement("path", { d: "M55.7447 43.2637L54.9967 100.636L39.7847 100.845L36.1172 43.2637H55.7447Z", fill: "#F5F5F5" }),
            React.createElement("path", { d: "M77.0377 40.1682H34.4453L34.4453 43.2637H77.0377L77.0377 40.1682Z", fill: "#F5F5F5" }),
            React.createElement("path", { d: "M74.0827 78.7447H38.0553L36.5703 54.8416H75.5776L74.0827 78.7447Z", fill: "#D61F27" }),
            React.createElement("g", { opacity: "0.1" },
                React.createElement("path", { d: "M75.3615 43.2671L72.493 101.022L54.9961 100.639L55.7437 43.2671H75.3615Z", fill: "url(#mealPaint2_linear)" }))),
        React.createElement("path", { d: "M89.5633 80.3775C89.6648 79.5106 90.421 78.736 91.6106 78.3395C90.3748 76.5781 91.0019 75.0011 92.3668 74.6414C93.3167 74.3832 94.5985 74.7244 95.7789 75.6835C95.8066 75.7112 95.8342 75.7389 95.8619 75.7573C95.8804 75.7204 95.8896 75.6835 95.8988 75.6466C96.4245 74.1896 97.3651 73.2397 98.3242 72.9907C99.6983 72.6218 101.026 73.71 100.814 75.8864C102.031 75.6559 103.083 75.9325 103.627 76.6242L106.43 75.8772C104.411 68.8132 100.279 66.8765 95.7789 68.0754L91.8688 69.1175C87.3316 70.3348 84.8601 74.0605 86.8705 81.0876L89.5633 80.3775Z", fill: "#ED2D24" }),
        React.createElement("path", { d: "M86.8616 81.0973C84.8512 74.0702 87.3227 70.3353 91.8599 69.118L95.7701 68.0759C100.27 66.8771 104.402 68.8137 106.421 75.8777L107.417 75.6103C106.394 72.0137 104.771 69.4777 102.613 68.0482C100.612 66.7295 98.1493 66.4067 95.5026 67.1076L91.5925 68.1497C88.9366 68.8598 87 70.363 85.9763 72.5025C84.8881 74.7987 84.8512 77.7774 85.8749 81.3556L86.8616 81.0973Z", fill: "#A61E22" }),
        React.createElement("path", { d: "M93.3542 71.8303C93.0499 70.7698 93.5017 70.1796 94.2118 69.9952C94.9219 69.8015 95.6136 70.0874 95.9271 71.1479C96.2315 72.2084 95.3185 73.8407 95.3185 73.8407C95.3185 73.8407 93.6585 72.8909 93.3542 71.8303Z", fill: "white" }),
        React.createElement("path", { d: "M88.4018 74.0396C87.7378 73.4217 87.8024 72.8315 88.2081 72.4165C88.6231 72.0015 89.2226 71.9093 89.8865 72.5272C90.5505 73.145 90.6151 74.6482 90.6151 74.6482C90.6151 74.6482 89.0658 74.6482 88.4018 74.0396Z", fill: "white" }),
        React.createElement("path", { d: "M101.1 70.6474C101.34 69.7898 100.971 69.3103 100.399 69.1627C99.8277 69.0059 99.2652 69.2365 99.0162 70.0941C98.7764 70.9518 99.5234 72.2705 99.5234 72.2705C99.5234 72.2705 100.851 71.5051 101.1 70.6474Z", fill: "white" }),
        React.createElement("path", { d: "M108.931 99.4113C108.423 99.2638 94.3322 97.2073 89.4353 99.688C89.5921 99.2361 89.4537 98.4707 89.0664 97.6868L124.746 80.2849L124.756 80.2941C126.194 82.9224 125.466 86.3622 122.791 88.3542C126.047 90.6597 125.539 94.6159 121.86 96.405C118.623 97.9819 116.17 96.7554 115.183 96.9214C114.805 99.4206 111.882 100.241 108.931 99.4113Z", fill: "#698E3D" }),
        React.createElement("path", { d: "M124.758 80.3049L89.0594 97.6791C88.6629 96.9045 88.1095 96.3419 87.6484 96.1759C92.6191 93.8336 99.3327 81.6605 99.5171 81.181C100.596 78.396 103.021 76.5977 105.299 77.7965C106.019 77.1325 106.471 74.4858 109.717 72.9089C113.396 71.1198 116.965 73.0841 116.928 76.9942C120.165 76.1089 123.448 77.5937 124.758 80.3049Z", fill: "#A8C538" }),
        React.createElement("path", { d: "M67.3573 99.9566C67.8645 99.809 81.9188 97.5497 86.8525 99.9658C86.6957 99.5232 86.8156 98.7485 87.203 97.9647L51.2926 81.0515L51.2833 81.0607C49.8816 83.7167 50.6562 87.138 53.3583 89.0931C50.1306 91.4447 50.7023 95.3917 54.4004 97.1347C57.6557 98.6655 60.0995 97.4021 61.0771 97.5589C61.4828 100.04 64.4247 100.823 67.3573 99.9566Z", fill: "#698E3D" }),
        React.createElement("path", { d: "M51.2734 81.0603C51.2734 81.0603 51.2734 81.0696 51.2734 81.0603L87.2023 97.955C87.5896 97.1804 88.1337 96.6086 88.5948 96.4334C83.5873 94.1556 76.7077 82.0748 76.5232 81.5952C75.4074 78.8194 72.9543 77.0672 70.6949 78.2845C69.9664 77.6298 69.4776 74.9923 66.2131 73.4614C62.5058 71.7185 58.9646 73.7381 59.0568 77.6482C55.8107 76.7998 52.5461 78.3399 51.2734 81.0603Z", fill: "#A8C538" }),
        React.createElement("path", { d: "M94.9672 75.3247C94.9672 80.6273 90.5407 84.9248 85.0813 84.9248C79.6219 84.9248 75.1953 80.6273 75.1953 75.3247C75.1953 70.0221 79.6219 65.7246 85.0813 65.7246C90.5407 65.7246 94.9672 70.0221 94.9672 75.3247Z", fill: "#EFA7A8" }),
        React.createElement("path", { d: "M87.3975 73.8226L87.3975 72.5684C87.3975 72.0796 86.9917 71.6831 86.4845 71.6831C85.9773 71.6831 85.5715 72.0796 85.5715 72.5684C85.5715 72.9096 85.6268 73.3062 85.2303 73.6105L83.7271 74.7909C83.3306 75.0952 83.2752 75.6578 83.5888 76.0359L85.3686 78.1846C85.6822 78.5627 86.2632 78.6273 86.6597 78.3229L88.5041 76.8843C88.9007 76.5708 88.9652 76.0174 88.6424 75.6301C87.5542 74.3298 87.3975 74.2376 87.3975 73.8226Z", fill: "#EA9696" }),
        React.createElement("path", { d: "M83.4029 69.3566L84.1222 68.8679C84.4081 68.6742 84.4726 68.2961 84.2698 68.0194C84.0669 67.7428 83.6796 67.6782 83.3937 67.8719C83.2 68.001 82.9971 68.1947 82.6559 68.1025L81.3833 67.752C81.0513 67.6598 80.7009 67.8442 80.6086 68.167L80.0738 69.9745C79.9815 70.2973 80.1752 70.6293 80.5072 70.7307L82.0657 71.1641C82.3977 71.2564 82.7481 71.0719 82.8403 70.7492C83.1816 69.6425 83.1631 69.5226 83.4029 69.3566Z", fill: "#EA9696" }),
        React.createElement("path", { d: "M80.7855 72.8071H77.9912C77.5393 72.8071 77.1797 73.1576 77.1797 73.6002L77.1797 77.2614C77.1797 77.6948 77.5393 78.0544 77.9912 78.0544H78.5814C79.0333 78.0544 79.393 77.704 79.393 77.2614C79.393 76.8279 79.7526 76.4683 80.2045 76.4683C80.4996 76.4683 81.6062 76.6158 81.6062 75.6752L81.6062 73.591C81.597 73.1576 81.2373 72.8071 80.7855 72.8071Z", fill: "#FACFCE" }),
        React.createElement("path", { d: "M82.8203 80.7474L82.8203 82.9054C82.8203 83.2558 83.1062 83.5325 83.4659 83.5325H86.463C86.8227 83.5325 87.1085 83.2558 87.1085 82.9054L87.1085 82.4535C87.1085 82.1123 86.8227 81.8264 86.463 81.8264C86.1033 81.8264 85.8175 81.5405 85.8175 81.1993C85.8175 80.9688 85.9373 80.1204 85.1719 80.1204H83.4659C83.1154 80.1204 82.8203 80.4062 82.8203 80.7474Z", fill: "#FACFCE" }),
        React.createElement("path", { d: "M90.5324 69.4493C90.5324 69.8735 90.182 70.2055 89.7486 70.2055H86.6315C86.1981 70.2055 85.8477 69.8643 85.8477 69.4493C85.8477 69.0343 86.1981 68.6931 86.6315 68.6931H89.7486C90.182 68.6931 90.5324 69.0251 90.5324 69.4493Z", fill: "#FACFCE" }),
        React.createElement("path", { d: "M93.0315 74.4418C93.0315 75.3456 92.2753 76.0833 91.3439 76.0833C90.4124 76.0833 89.6563 75.3548 89.6563 74.4418C89.6563 73.5381 90.4124 72.8003 91.3439 72.8003C92.2753 72.8095 93.0315 73.5381 93.0315 74.4418Z", fill: "#FACFCE" }),
        React.createElement("path", { d: "M90.5328 80.756C90.5328 81.2079 90.1547 81.5768 89.6844 81.5768C89.214 81.5768 88.8359 81.2079 88.8359 80.756C88.8359 80.3042 89.214 79.9353 89.6844 79.9353C90.1547 79.9353 90.5328 80.3042 90.5328 80.756Z", fill: "#FACFCE" }),
        React.createElement("path", { d: "M81.5026 80.7468C81.5026 81.1987 81.1245 81.5584 80.6634 81.5584C80.2023 81.5584 79.8242 81.1987 79.8242 80.7468C79.8242 80.2949 80.2023 79.9353 80.6634 79.9353C81.1245 79.9353 81.5026 80.2949 81.5026 80.7468Z", fill: "#FACFCE" }),
        React.createElement("path", { d: "M84.4979 72.2456C84.4979 72.5592 84.2397 72.8082 83.9169 72.8082C83.5941 72.8082 83.3359 72.5592 83.3359 72.2456C83.3359 71.9321 83.5941 71.6831 83.9169 71.6831C84.2397 71.6831 84.4979 71.9321 84.4979 72.2456Z", fill: "#FACFCE" }),
        React.createElement("path", { d: "M65.775 93.1119C65.1664 92.4756 65.0557 91.4058 65.5076 90.2346C63.3681 90.0687 62.5473 88.5747 63.1468 87.2928C63.5525 86.3983 64.6315 85.6236 66.1254 85.3378C66.1623 85.3285 66.1992 85.3285 66.2361 85.3193C66.2177 85.2824 66.1992 85.2548 66.1808 85.2179C65.3969 83.8807 65.2678 82.5527 65.6828 81.649C66.273 80.3579 67.9699 80.0351 69.5007 81.5752C70.1001 80.487 70.9854 79.8599 71.8615 79.8876L73.0788 77.2501C66.3468 74.2898 62.2246 76.2357 60.2787 80.4686L58.5819 84.1481C56.6176 88.4179 57.9179 92.6969 64.6038 95.6387L65.775 93.1119Z", fill: "#ED2D24" }),
        React.createElement("path", { d: "M64.6112 95.6495C57.9161 92.7077 56.625 88.4287 58.5893 84.1589L60.2861 80.4793C62.232 76.2465 66.3634 74.3098 73.0862 77.2609L73.5197 76.3294C70.0983 74.8263 67.1104 74.4482 64.6297 75.2044C62.3334 75.9052 60.5167 77.5929 59.3731 80.0736L57.6763 83.7531C56.5236 86.2523 56.4405 88.7054 57.4365 90.8541C58.5063 93.1596 60.7749 95.087 64.187 96.5809L64.6112 95.6495Z", fill: "#A61E22" }),
        React.createElement("path", { d: "M61.623 84.7306C60.6086 84.2879 60.4426 83.5594 60.7561 82.8954C61.0604 82.2314 61.7244 81.8717 62.7388 82.3144C63.7532 82.757 64.4172 84.5092 64.4172 84.5092C64.4172 84.5092 62.6282 85.1825 61.623 84.7306Z", fill: "white" }),
        React.createElement("path", { d: "M60.1575 89.9584C59.263 90.0783 58.848 89.6449 58.7835 89.0731C58.7281 88.4921 59.0417 87.9757 59.9454 87.8558C60.84 87.7359 62.0388 88.6489 62.0388 88.6489C62.0388 88.6489 61.0521 89.8478 60.1575 89.9584Z", fill: "white" }),
        React.createElement("path", { d: "M65.662 78.027C65.1548 77.2893 64.5554 77.2708 64.0666 77.612C63.587 77.9532 63.3934 78.5342 63.9006 79.272C64.4078 80.0097 65.9018 80.2772 65.9018 80.2772C65.9018 80.2772 66.16 78.7648 65.662 78.027Z", fill: "white" }),
        React.createElement("path", { d: "M79.1246 76.1567C79.1246 80.4081 75.5741 83.8479 71.2029 83.8479C66.8225 83.8479 63.2813 80.4081 63.2813 76.1567C63.2813 71.9054 66.8317 68.4656 71.2029 68.4656C75.5741 68.4656 79.1246 71.9054 79.1246 76.1567Z", fill: "#5C7635" }),
        React.createElement("path", { d: "M77.6944 76.157C77.6944 79.6429 74.7895 82.4648 71.1929 82.4648C67.5963 82.4648 64.6914 79.6429 64.6914 76.157C64.6914 72.6711 67.6056 69.8491 71.1929 69.8491C74.7895 69.8491 77.6944 72.6711 77.6944 76.157Z", fill: "#A1C53A" }),
        React.createElement("path", { d: "M73.8134 76.1596C73.8134 77.5614 72.6422 78.6865 71.2036 78.6865C69.7649 78.6865 68.5938 77.5522 68.5938 76.1596C68.5938 74.7579 69.7557 73.6328 71.1943 73.6328C72.6422 73.6328 73.8134 74.7579 73.8134 76.1596Z", fill: "white" }),
        React.createElement("path", { d: "M87.7857 70.0688L87.7857 101.027H57.9802C54.5589 101.027 52.2165 97.5411 53.5537 94.3872C58.7918 82.0021 71.9792 70.0688 87.7857 70.0688Z", fill: "#FCDB4E" }),
        React.createElement("path", { d: "M87.7866 74.2898L87.7866 101.034H60.7109C56.9206 101.034 54.6151 96.8929 56.5702 93.6468C63.044 82.8847 74.5992 74.2898 87.7866 74.2898Z", fill: "#FBB435" }),
        React.createElement("path", { d: "M87.7852 70.0669L87.7852 101.025H117.591C121.012 101.025 123.345 97.5392 122.017 94.3853C116.788 82.0001 103.601 70.0669 87.7852 70.0669Z", fill: "#FAD229" }),
        React.createElement("path", { d: "M87.7852 74.2898L87.7852 101.034H114.861C118.651 101.034 120.957 96.8929 119.002 93.6468C112.537 82.8847 100.973 74.2898 87.7852 74.2898Z", fill: "#FA9F17" }),
        React.createElement("path", { d: "M86.5392 86.0882C86.5392 86.8905 85.8844 87.5453 85.0821 87.5453C84.2798 87.5453 83.625 86.8905 83.625 86.0882C83.625 85.2859 84.2798 84.6311 85.0821 84.6311C85.8844 84.6311 86.5392 85.2859 86.5392 86.0882Z", fill: "#F58720" }),
        React.createElement("path", { d: "M75.6212 91.8987C75.6212 92.701 74.9664 93.3558 74.1641 93.3558C73.3618 93.3558 72.707 92.701 72.707 91.8987C72.707 91.0964 73.3618 90.4417 74.1641 90.4417C74.9664 90.4417 75.6212 91.0964 75.6212 91.8987Z", fill: "#F58720" }),
        React.createElement("path", { d: "M75.1588 83.6251C75.1588 84.1784 74.7069 84.6303 74.1536 84.6303C73.6003 84.6303 73.1484 84.1784 73.1484 83.6251C73.1484 83.0718 73.6003 82.6199 74.1536 82.6199C74.7069 82.6199 75.1588 83.0718 75.1588 83.6251Z", fill: "#F58720" }),
        React.createElement("path", { d: "M66.0495 97.0601C66.0495 97.6135 65.5976 98.0653 65.0443 98.0653C64.4909 98.0653 64.0391 97.6135 64.0391 97.0601C64.0391 96.5068 64.4909 96.0549 65.0443 96.0549C65.5976 96.0549 66.0495 96.5068 66.0495 97.0601Z", fill: "#F58720" }),
        React.createElement("path", { d: "M90.1094 93.6487C90.1094 94.451 90.7641 95.1058 91.5665 95.1058C92.3688 95.1058 93.0235 94.451 93.0235 93.6487C93.0235 92.8464 92.3688 92.1917 91.5665 92.1917C90.7641 92.1917 90.1094 92.8464 90.1094 93.6487Z", fill: "#F58720" }),
        React.createElement("path", { d: "M93.5 81.2876C93.5 82.09 94.1547 82.7447 94.9571 82.7447C95.7594 82.7447 96.4141 82.09 96.4141 81.2876C96.4141 80.4853 95.7594 79.8306 94.9571 79.8306C94.1547 79.8306 93.5 80.4853 93.5 81.2876Z", fill: "#F58720" }),
        React.createElement("path", { d: "M100.281 83.6251C100.281 84.1784 100.733 84.6303 101.286 84.6303C101.84 84.6303 102.292 84.1784 102.292 83.6251C102.292 83.0718 101.84 82.6199 101.286 82.6199C100.733 82.6199 100.281 83.0718 100.281 83.6251Z", fill: "#F58720" }),
        React.createElement("path", { d: "M109.402 97.0618C109.402 97.6152 109.854 98.067 110.408 98.067C110.961 98.067 111.413 97.6152 111.413 97.0618C111.413 96.5085 110.961 96.0566 110.408 96.0566C109.845 96.0566 109.402 96.5085 109.402 97.0618Z", fill: "#F58720" })),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "mealPaint0_linear", x1: "57.1805", y1: "48.7846", x2: "75.4933", y2: "49.3487", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#D9D9D9" }),
            React.createElement("stop", { offset: "1", stopColor: "white" })),
        React.createElement("linearGradient", { id: "mealPaint1_linear", x1: "57.1788", y1: "48.7852", x2: "75.4916", y2: "49.3494", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#D9D9D9" }),
            React.createElement("stop", { offset: "1", stopColor: "white" })),
        React.createElement("linearGradient", { id: "mealPaint2_linear", x1: "57.1788", y1: "48.7852", x2: "75.4916", y2: "49.3494", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#D9D9D9" }),
            React.createElement("stop", { offset: "1", stopColor: "white" })),
        React.createElement("clipPath", { id: "mealClip0" },
            React.createElement("rect", { width: "160", height: "100", fill: "white" })))));
