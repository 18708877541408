import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { Form } from 'react-final-form';
import Modal from '../../Modal';
import CloseIcon from '../../CloseIcon/components/CloseIcon';
import { useTranslation } from 'react-i18next';
import Button from '../../Button/Button';
import { useTheme } from '../../theme';
import Row from './Components/Row';
import Snackbar from '../Snackbar/Snackbar';
import { removeWhiteSpaces } from './utils';
var TerminalForm = function (props) {
    var _a;
    var theme = useTheme('TerminalForm').TerminalForm;
    var t = useTranslation().t;
    var _b = __read(React.useState(null), 2), error = _b[0], setError = _b[1];
    var formElements = null;
    try {
        formElements = (_a = JSON.parse(props.hostCommand)) === null || _a === void 0 ? void 0 : _a.form;
    }
    catch (e) {
        console.error(e);
    }
    if (!formElements) {
        return null;
    }
    var onSubmitForm = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var preparedValues, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    preparedValues = removeWhiteSpaces(values);
                    return [4 /*yield*/, props.onSumbit(preparedValues)];
                case 1:
                    response = _a.sent();
                    if (response.error) {
                        setError(response.message);
                    }
                    else {
                        props.onSuccess();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var initialValues = useMemo(function () {
        var initialValues = {};
        formElements.rows.forEach(function (row) {
            var _a;
            (_a = row.inputs) === null || _a === void 0 ? void 0 : _a.forEach(function (input) {
                if (input.value) {
                    initialValues[input.id] = input.value;
                }
            });
        });
        return initialValues;
    }, [formElements.rows]);
    return (React.createElement(Modal, { open: true, onClose: props.onClose, classes: { paper: theme.modal__paper }, closeButtonRenderer: function (onClick) { return (React.createElement("div", { className: theme.popup__close },
            React.createElement(CloseIcon, { onClick: onClick }))); } },
        React.createElement("div", null,
            React.createElement("div", { className: theme.header }, formElements.title),
            React.createElement("div", { className: theme.separationLine }),
            React.createElement(Snackbar, { error: true, open: !!error, onClose: function () { return setError(null); }, message: error }),
            React.createElement(Form, { onSubmit: onSubmitForm, validateOnBlur: true, initialValues: initialValues, render: function (_a) {
                    var handleSubmit = _a.handleSubmit, submitting = _a.submitting;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement("div", null, formElements.rows.map(function (row) {
                            var _a;
                            return (React.createElement(Row, { key: "".concat(row.title).concat(((_a = row.inputs) === null || _a === void 0 ? void 0 : _a.length) || ''), row: row, listData: formElements.listdata }));
                        })),
                        React.createElement("div", { className: theme.buttons },
                            React.createElement(Button, { variant: "flat", className: theme.cancelBtn, onClick: props.onClose }, t('Cancel')),
                            React.createElement(Button, { variant: "flat", isLoading: submitting, className: theme.sendBtn, type: "submit" }, t('Send')))));
                } }))));
};
export default TerminalForm;
