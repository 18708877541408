import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useContext } from 'react';
import MediaQuery from 'react-responsive';
import { getSegments, getTabs, seatMapAdapter } from '../../utils';
import { initI18n, TABLET_MIDDLE_WIDTH } from '../../../utils';
import { useTranslation } from 'react-i18next';
import SeatMapServiceContent from './Content/SeatMapServiceContent';
import { useTheme } from '../../../theme';
import Passengers from '../Passengers/Passengers';
import SeatsInfo from '../SeatsInfo/SeatsInfo';
import cn from 'classnames';
import { AviaPassengerType, OrderAdditionalServiceGdsServiceServiceType } from '../../../__generated__/globalTypes';
import { isSeatOccupied } from '../SeatMap/Seat/utils';
import { ModeContext } from '../../../context';
import { Mode } from '../../../types';
import SeatsRecommendation from '../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import SeatsRecommendPopup from '../SeatsRecommend/SeatsRecommendPopup/SeatsRecommendPopup';
import SegmentTabs from '../../../SegmentTabs/SegmentTabs';
import { useConfig } from '../../../context';
import { useRenderers } from '../../../renderProps';
initI18n('SeatMap');
var Component = function (props) {
    var _a;
    var _b, _c, _d, _e, _f, _g;
    var _h = __read(React.useState(0), 2), segmentIndex = _h[0], setSegment = _h[1];
    var preselectedIndex = props.passengers.findIndex(function (passenger) { return props.preSelectedPassenger === +passenger.id; });
    var _j = __read(React.useState(preselectedIndex !== -1 ? preselectedIndex : 0), 2), passengerIndex = _j[0], setPassenger = _j[1];
    var seatMapRef = React.createRef();
    var tabsRef = React.useRef();
    var mode = useContext(ModeContext);
    var isTerminal = ((_d = (_c = (_b = useConfig()) === null || _b === void 0 ? void 0 : _b.global) === null || _c === void 0 ? void 0 : _c.companyInfo) === null || _d === void 0 ? void 0 : _d.iataCode) === 'TERMINAL';
    var t = useTranslation('SeatMap').t;
    var segments = React.useMemo(function () { return getSegments(seatMapAdapter(props.seatMaps)); }, [props.seatMaps]);
    var servicesCount = (_e = segments[segmentIndex].services) === null || _e === void 0 ? void 0 : _e.length;
    var services = (_g = (_f = props.seatMaps.additionalServices.gdsServices) === null || _f === void 0 ? void 0 : _f.services) === null || _g === void 0 ? void 0 : _g.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Seat; }).filter(function (service) { return service.canBeAdded; });
    var servicesMap = React.useMemo(function () {
        var servicesMap = {};
        services.forEach(function (service) {
            service.allowedSegments.forEach(function (allowedSegments) {
                allowedSegments.forEach(function (allowedSegment) {
                    if (!servicesMap.hasOwnProperty(allowedSegment)) {
                        servicesMap[allowedSegment] = {};
                    }
                    service.allowedPassengers.forEach(function (allowedPassengers) {
                        allowedPassengers.forEach(function (allowedPassenger) {
                            if (!servicesMap[allowedSegment].hasOwnProperty(allowedPassenger)) {
                                servicesMap[allowedSegment][allowedPassenger] = {};
                            }
                            servicesMap[allowedSegment][allowedPassenger][service.rfisc] = service;
                        });
                    });
                });
            });
        });
        return servicesMap;
    }, [props.seatMaps]);
    var theme = useTheme('SeatMap').ComponentStyles;
    var onCancel = React.useCallback(function (passenger) {
        props.onSelect(typeof passenger === 'number' ? passenger : passengerIndex, null, segmentIndex);
    }, [props.onSelect, segmentIndex, passengerIndex]);
    var segmentTabs = React.useMemo(function () {
        return getTabs(segments, props.passengers, servicesMap).map(function (item) {
            var value = item.value, header = item.header, isAvailable = item.isAvailable, seatsCount = item.seatsCount;
            return {
                id: value.toString(),
                title: header,
                isDisabled: !isAvailable,
                isChecked: seatsCount > 0
            };
        });
    }, [props.passengers]);
    var selectNextPassengerWithoutSeat = function () {
        var currentSegmentId = segments[segmentIndex].segmentInfo.id;
        var nextPassengerWithoutSeat = props.passengers
            .filter(function (passenger) {
            return passenger.id !== props.passengers[passengerIndex].id && !(passenger.type === AviaPassengerType.INF);
        })
            .find(function (passenger, index) {
            return !passenger.seats || !passenger.seats.some(function (seat) { return seat.segmentId === currentSegmentId; });
        });
        if (nextPassengerWithoutSeat) {
            var nextPassengerWithoutSeatIndex = props.passengers.findIndex(function (passenger) { return nextPassengerWithoutSeat.id === passenger.id; });
            setPassenger(nextPassengerWithoutSeatIndex);
        }
    };
    var onSelectNextFlight = React.useCallback(function () {
        if (segmentIndex + 1 < props.seatMaps.segments.length) {
            setSegment(segmentIndex + 1);
            setPassenger(0);
        }
    }, [segmentIndex, props.seatMaps.segments]);
    var onSelectPrevFlight = React.useCallback(function () {
        if (segmentIndex > 0) {
            setSegment(segmentIndex - 1);
            setPassenger(0);
        }
    }, [segmentIndex]);
    var onClear = React.useCallback(function () {
        props.clearOnSegment(segmentIndex);
    }, [segmentIndex, props.clearOnSegment, props.passengers]);
    var onSelect = React.useCallback(function (seat, passengerId) { return __awaiter(void 0, void 0, void 0, function () {
        var currentPassenger, isOccupied;
        return __generator(this, function (_a) {
            currentPassenger = props.passengers.find(function (passenger) { return passenger.id === passengerId.toString(10); });
            isOccupied = isSeatOccupied(seat, currentPassenger, segments[segmentIndex].segmentInfo.id);
            if (!isOccupied) {
                props.onSelect(passengerId, seat, segmentIndex);
                selectNextPassengerWithoutSeat();
            }
            else {
                // onCancel();
                props.onCancelSeat(passengerId, seat, segmentIndex);
            }
            return [2 /*return*/];
        });
    }); }, [props.onSelect, segmentIndex, props.passengers]);
    var hideControls = React.useMemo(function () { return segments.every(function (segment) { return segment.isSeatsAvailableInCheckinOnly; }) && mode !== Mode.Checkin; }, [segments]);
    var SeatMapSegmentTabs = useRenderers().SeatMapSegmentTabs;
    var renderPassengers = React.useCallback(function () {
        var _a;
        return (React.createElement(Passengers, { hideDisclaimer: hideControls, hideNextButton: hideControls, hidePassengers: hideControls, onProceed: props.onProceed, seatMapRef: seatMapRef, passengers: (_a = props.passengers) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
                var type = _a.type;
                return type !== AviaPassengerType.INF;
            }), segmentId: segments[segmentIndex].segmentInfo.id, onSelectNextFlight: onSelectNextFlight, passengerIndex: passengerIndex, segmentIndex: segmentIndex, segmentsCount: segments.length, onChange: setPassenger, onCancel: onCancel, onClear: onClear }));
    }, [props.passengers, segmentIndex, passengerIndex]);
    var renderSeatsInfo = React.useCallback(function () {
        var _a;
        return segments[segmentIndex].services && (React.createElement(SeatsInfo, { seatsInfo: segments[segmentIndex].services, amenities: (_a = props.segments) === null || _a === void 0 ? void 0 : _a[segmentIndex].amenities }));
    }, [segments, segmentIndex]);
    var renderTabs = React.useCallback(function () {
        var SegmentTabsRenderer = SeatMapSegmentTabs !== null && SeatMapSegmentTabs !== void 0 ? SeatMapSegmentTabs : SegmentTabs;
        return (React.createElement("div", { ref: tabsRef, className: theme.segmentTabs },
            React.createElement(SegmentTabsRenderer, { title: !isTerminal ? t('Choose your seat') : null, activeSegment: segmentIndex.toString(), segments: segmentTabs, onSegmentClick: function (segment) { return setSegment(parseInt(segment)); } })));
    }, [segmentTabs, segmentIndex, SeatMapSegmentTabs, setSegment]);
    var seatMapRecommendEnabled = React.useMemo(function () {
        return props.seatRecommendEnabled && !hideControls;
    }, [props.seatRecommendEnabled, hideControls]);
    return (React.createElement("div", { className: cn(theme.wrapper, props.className, (_a = {},
            _a[theme.wrapper_threeServices] = servicesCount === 3,
            _a[theme.wrapper_fourServices] = servicesCount === 4,
            _a)) },
        React.createElement(SeatsRecommendation, { orderId: props.orderId, segments: props.segments, travellers: props.travellers, fillOrder: props.fillOrder, runServiceRequest: props.runServiceRequest, segmentIndex: segmentIndex, passengerIndex: passengerIndex, isEnabled: seatMapRecommendEnabled, setSeat: props.setSeatCallback, confirmRecommendedSeatsWithoutDialog: props.confirmRecommendedSeatsWithoutDialog, seatMap: props.seatMaps },
            React.createElement(SeatMapServiceContent, { services: servicesMap, fullScreen: props.fullScreen, segments: segments, onSelect: onSelect, passengers: props.passengers, tabsRef: tabsRef, seatMapRef: seatMapRef, onSelectNextFlight: onSelectNextFlight, passengerIndex: passengerIndex, segmentIndex: segmentIndex, swipeBetweenSegments: true, renderPassengers: renderPassengers, renderSeatsInfo: renderSeatsInfo, renderSegmentTabs: renderTabs, onProceed: props.onProceed, onBack: onSelectPrevFlight, onClear: onClear }),
            React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
                React.createElement(SeatsRecommendPopup, { passengerIndex: passengerIndex, segmentId: segmentIndex, services: servicesMap, setPassenger: setPassenger, passengers: props.passengers })))));
};
export default Component;
