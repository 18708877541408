import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import { useTheme } from '../theme';
import { amenitiesIcons, leafIcon } from './icons';
var Amenities = function (_a) {
    var amenities = _a.amenities, isEco = _a.isEco, className = _a.className;
    var css = useTheme('Amenities').Amenities;
    var t = useTranslation().t;
    return (React.createElement("div", { className: cn(css.amenities, className) },
        !!(amenities === null || amenities === void 0 ? void 0 : amenities.length)
            ? amenities
                .sort(function (a, b) { return (a.available && b.available ? -1 : 1); })
                .map(function (item) {
                var _a;
                return amenitiesIcons.hasOwnProperty(item.type) ? (React.createElement("span", { key: item.type, className: cn(css.amenities__item, (_a = {},
                        _a[css.amenities__item_unavailable] = !item.available,
                        _a)) },
                    React.createElement(Tooltip, { key: item.type, title: item.available
                            ? item.description
                            : t('DesktopFlightInfo:Service is unavailable on this flight') }, amenitiesIcons[item.type]))) : null;
            })
            : null,
        !!(amenities === null || amenities === void 0 ? void 0 : amenities.length) && isEco && (React.createElement(Tooltip, { title: t('DesktopFlightInfo:Eco-friendly') },
            React.createElement("span", { className: css.amenities__item }, leafIcon)))));
};
export default Amenities;
