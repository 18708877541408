import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../context';
import FullscreenDialog from '../../../FullscreenDialog/components/FullscreenDialog';
import { useTheme } from '../../../theme';
import { Money } from '../../../Money';
import Modal from '../../../Modal';
import { Button } from '../../../index';
import { initI18n } from '../../../utils';
import FullScreenModal from '../../../LEO/FullScreenModal/FullScreenModal';
import { ServiceType } from '../../../Checkout/components/Checkout/OrderPaidServiceCards/types';
initI18n('Service');
var Dialog = function (props) {
    var _a, _b, _c;
    var _d;
    var theme = useTheme('Service').DialogStyles;
    var t = useTranslation('Service').t;
    var isTerminal = useConfig().global.companyInfo.iataCode === 'TERMINAL';
    var headerWithoutTitle = (!!props.rules || !!((_d = props.totalPrice) === null || _d === void 0 ? void 0 : _d.amount)) && (React.createElement("div", { className: !isTerminal ? theme.header__rulesPrice : null },
        props.rules,
        React.createElement("span", { className: cn((_a = {},
                _a[theme.price_withRules] = !!props.rules && props.totalPrice && props.totalPrice.amount,
                _a)) }, props.totalPrice && !!props.totalPrice.amount && (React.createElement(React.Fragment, null,
            t('Total'),
            ":",
            React.createElement(Money, { money: props.totalPrice }))))));
    var header = (React.createElement("div", { className: cn(theme.header, props.dialogPopupHeaderClassName) },
        React.createElement("div", { className: theme.header__title }, props.dialogHeader),
        headerWithoutTitle));
    if (props.dialogType === 'fullscreen' && isTerminal) {
        return (React.createElement(FullScreenModal, { open: props.isOpen, onClose: props.onClose, title: props.dialogHeader, header: React.createElement("div", { className: theme.fullScreenModalHeaderWrapper }, headerWithoutTitle), headerType: props.serviceType === ServiceType.Baggage ? 'fixed' : 'collapsed' }, props.content));
    }
    return props.dialogType === 'fullscreen' ? (React.createElement(FullscreenDialog, { isOpen: props.isOpen, onClose: props.onClose, header: header, withoutHeader: props.withoutHeader, contentClassName: cn(theme.dialog__content, (_b = {}, _b[theme.dialog__content_hidden] = props.withoutHeader, _b), props.dialogContentClassName), headerClassName: cn(theme.dialog__header, (_c = {}, _c[theme.dialog__header_hidden] = props.withoutHeader, _c), props.dialogHeaderClassName), closeClassName: props.dialogCloseClassName, fadeTransition: true, keepMounted: true, rootDialogClassName: theme.dialog, mountOnEnter: props.mountOnEnter }, props.content)) : (React.createElement(Modal, { scroll: props.bodyScroll ? 'body' : 'paper', open: props.isOpen, onClose: props.onClose, maxWidth: false, classes: {
            root: theme.dialog,
            paper: cn(theme.paper, props.dialogClassName)
        }, keepMounted: true, mountOnEnter: props.mountOnEnter },
        props.dialogHeader && React.createElement("div", { className: theme.popupHeader }, header),
        props.content,
        !props.withoutControls && (React.createElement("div", { className: props.dialogControlsClassName },
            React.createElement(Button, { onClick: props.onClear, className: theme.dialog__button_clear }, t('Clear')),
            React.createElement(Button, { onClick: props.onClose, className: theme.dialog__button_done }, t('Done'))))));
};
export default Dialog;
