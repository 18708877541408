import { __assign, __read, __spreadArray, __values } from "tslib";
export var detectDifferentForReturnFlight = function (segments, selectedServices) {
    var e_1, _a;
    var groupedSelectedServices = selectedServices.reduce(function (acc, el) {
        var key = "".concat(el.passengerId, "_").concat(el.serviceId);
        var value = acc.get(key);
        if (!value) {
            value = [];
        }
        value.push(el);
        return acc.set(key, value);
    }, new Map());
    try {
        for (var _b = __values(groupedSelectedServices.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
            var group = _c.value;
            if (group.length !== segments.length) {
                return true;
            }
            for (var index in group) {
                var nextIndex = parseInt(index, 10) + 1;
                if (typeof group[nextIndex] === 'undefined') {
                    break;
                }
                if (group[index].segmentIds !== group[nextIndex].segmentIds) {
                    return true;
                }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return false;
};
export var joinServicesByRfics = function (baggage) {
    var servicesByRficsMap = new Map();
    baggage.forEach(function (baggage) {
        if (servicesByRficsMap.has(baggage.rfics)) {
            servicesByRficsMap.set(baggage.rfics, __spreadArray(__spreadArray([], __read(servicesByRficsMap.get(baggage.rfics)), false), [baggage], false));
        }
        else {
            servicesByRficsMap.set(baggage.rfics, [baggage]);
        }
    });
    var baggagesByRfics = [];
    servicesByRficsMap.forEach(function (baggages) {
        baggagesByRfics.push(baggages);
    });
    return baggagesByRfics;
};
export var joinSelectedServicesByRfics = function (selectedServices) {
    var selectedServiceMap = new Map();
    selectedServices.forEach(function (service) {
        var key = "".concat(service.confirmedCount, "_").concat(service.baggage.rfics);
        if (selectedServiceMap.has(key)) {
            selectedServiceMap.get(key).push(service);
        }
        else {
            selectedServiceMap.set(key, [service]);
        }
    });
    var selectedBaggageByRfics = [];
    selectedServiceMap.forEach(function (items) {
        selectedBaggageByRfics.push(items);
    });
    return selectedBaggageByRfics;
};
export var getRelatedSegments = function (groups, segmentId) {
    if (segmentId === void 0) { segmentId = null; }
    var result = new Set();
    if (segmentId === null) {
        groups.forEach(function (group) {
            group.forEach(function (segmentId) { return result.add(segmentId); });
        });
    }
    else {
        groups.some(function (group) {
            if (group.includes(segmentId)) {
                group.forEach(function (segmentId) { return result.add(segmentId); });
                return true;
            }
            return false;
        });
    }
    return result;
};
export var getMinPriceInBaggageGroup = function (baggages) {
    var price = {
        amount: baggages[0].price.amount,
        currency: baggages[0].price.currency
    };
    baggages.forEach(function (baggage) {
        if (baggage.price.amount < price.amount) {
            price.amount = baggage.price.amount;
        }
    });
    return price;
};
export var getMinAvailableBaggagePrice = function (baggage, passengerId) {
    var _a, _b;
    var avilableServicesPrices = baggage
        .filter(function (service) {
        if (!service.canBeAdded) {
            return false;
        }
        if (passengerId && !service.allowedPassengers.includes(passengerId)) {
            return false;
        }
        return true;
    })
        .map(function (service) { return service.price.amount; });
    var minPrice = Math.min.apply(Math, __spreadArray([], __read(avilableServicesPrices), false));
    var result = {};
    result.currency = (_b = (_a = baggage[0]) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.currency;
    result.amount = minPrice;
    return result;
};
export var getActualPriceInBaggageGroup = function (baggages) {
    var price = {
        amount: baggages[0].price.amount,
        currency: baggages[0].price.currency
    };
    var actualBaggage = null;
    baggages.forEach(function (bag) {
        if (isActualBaggage(bag, actualBaggage)) {
            actualBaggage = bag;
        }
    });
    if (actualBaggage) {
        price.amount = actualBaggage.price.amount;
    }
    return price;
};
export var getSegmentsInBaggageGroup = function (baggages) {
    var segmentsSet = new Set();
    baggages.forEach(function (baggage) {
        return baggage.segmentsId.forEach(function (segments) { return segments.forEach(function (segment) { return segmentsSet.add(segment); }); });
    });
    return segmentsSet;
};
export var getBaggageCountInSegment = function (services, segmentId) {
    var count = 0;
    services.forEach(function (service) {
        if (!segmentId || service.segmentIds.find(function (segmentGroup) { return segmentGroup.includes(segmentId); })) {
            count += service.count;
        }
    });
    return count;
};
export var getMaxBaggageCount = function (defaultMaxBaggage, bagLimitConfig, baggageType, selectedCount, selectedPassengerBaggageByTypeCount, selectedPassengerBaggageCount) {
    var maxBaggagePerPassengerByType = defaultMaxBaggage;
    var maxBaggagePerPassenger = defaultMaxBaggage;
    if (bagLimitConfig) {
        if (bagLimitConfig.baggageByTypeLimit) {
            var maxBaggageByType = bagLimitConfig.baggageByTypeLimit.find(function (i) { return i.baggageType === baggageType; });
            if (maxBaggageByType) {
                maxBaggagePerPassengerByType = maxBaggageByType.maxBaggagePerPassenger;
            }
        }
        if (bagLimitConfig.maxBaggagePerPassenger >= 0) {
            maxBaggagePerPassenger = bagLimitConfig.maxBaggagePerPassenger;
        }
    }
    maxBaggagePerPassengerByType -= selectedPassengerBaggageByTypeCount - selectedCount;
    maxBaggagePerPassenger -= selectedPassengerBaggageCount - selectedCount;
    return Math.min(maxBaggagePerPassengerByType, maxBaggagePerPassenger);
};
export var getMinBaggageCount = function (passenger, baggageId, segmentId) {
    var _a, _b;
    return (_b = (_a = passenger.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services.find(function (s) { return (!segmentId || s.segmentIds.includes(segmentId)) && s.serviceId === baggageId; })) === null || _b === void 0 ? void 0 : _b.confirmedCount;
};
export var isActualBaggage = function (currBaggage, actualBaggage) {
    return !actualBaggage || currBaggage.id > actualBaggage.id;
};
export var convertToBaggageAdapter = function (item, canBeAdded) {
    if (canBeAdded === void 0) { canBeAdded = false; }
    return item
        ? __assign(__assign({}, item), { id: item.serviceId, canBeAdded: canBeAdded }) : null;
};
export var updateSelectedItems = function (currCount, newCount, items, selectedItems, baggage) {
    var e_2, _a, e_3, _b;
    var dCount = newCount - currCount;
    var newSelectedItems = __spreadArray([], __read(selectedItems), false);
    var targetItems = new Map(); // key - segment id
    if (dCount > 0) {
        // ищем багаж с макс id, который можно добавлять
        items.forEach(function (item) {
            var _a;
            var canBeAdded = (_a = baggage.find(function (b) { return b.id === item.serviceId; })) === null || _a === void 0 ? void 0 : _a.canBeAdded;
            item.segmentIds.forEach(function (segmentId) {
                var targetItem = targetItems.get(segmentId);
                if (isActualBaggage(convertToBaggageAdapter(item, canBeAdded), convertToBaggageAdapter(targetItem))) {
                    targetItems.set(segmentId, item);
                }
            });
        });
        var _loop_1 = function (segmentId, targetItem) {
            var isNew = true;
            var item = targetItem;
            // ищем среди уже выбранных
            selectedItems.forEach(function (selectedItem) {
                if (selectedItem.segmentIds.includes(segmentId) && selectedItem.serviceId === targetItem.serviceId) {
                    item = selectedItem;
                    isNew = false;
                }
            });
            if (isNew) {
                item.count = (selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.length) > 0 ? dCount : newCount;
                newSelectedItems.push(item);
            }
            else {
                item.count = Number(item.count) + dCount;
            }
        };
        try {
            for (var targetItems_1 = __values(targetItems), targetItems_1_1 = targetItems_1.next(); !targetItems_1_1.done; targetItems_1_1 = targetItems_1.next()) {
                var _c = __read(targetItems_1_1.value, 2), segmentId = _c[0], targetItem = _c[1];
                _loop_1(segmentId, targetItem);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (targetItems_1_1 && !targetItems_1_1.done && (_a = targetItems_1.return)) _a.call(targetItems_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    else {
        // удаляемый багаж должен быть среди выделенных
        selectedItems.forEach(function (item) {
            var e_4, _a;
            try {
                for (var _b = __values(item.segmentIds), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var segmentId = _c.value;
                    var targetItem = targetItems.get(segmentId);
                    if (isActualBaggage(convertToBaggageAdapter(item, true), convertToBaggageAdapter(targetItem)) &&
                        item.count > 0) {
                        targetItems.set(segmentId, item);
                        break;
                    }
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_4) throw e_4.error; }
            }
        });
        try {
            for (var _d = __values(targetItems.values()), _e = _d.next(); !_e.done; _e = _d.next()) {
                var selectedItem = _e.value;
                selectedItem.count--;
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_b = _d.return)) _b.call(_d);
            }
            finally { if (e_3) throw e_3.error; }
        }
    }
    return newSelectedItems;
};
// sort ordering: selected services (price from min to max) then not selected services (price from min to max)
export var sortBySelectedByPrice = function (firstItem, secondItem, selectedServices) {
    var isFirstSelected = !!selectedServices.find(function (selectedService) { return selectedService.serviceId === firstItem[0].id; });
    var isSecondSelected = !!selectedServices.find(function (selectedService) { return selectedService.serviceId === secondItem[0].id; });
    if (isFirstSelected === false && isSecondSelected === true) {
        return 1;
    }
    else if (isFirstSelected === isSecondSelected) {
        if (firstItem[0].price.amount < secondItem[0].price.amount) {
            return -1;
        }
        else {
            return 1;
        }
    }
    else {
        return -1;
    }
};
export var getNotSavedBaggages = function (baggages, passenger, segmentId) {
    return baggages.filter(function (baggage, index) {
        var _a, _b;
        var isLastElement = index + 1 === baggages.length;
        if (!isLastElement &&
            ((_b = (_a = passenger.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(function (service) { var _a; return service.serviceId === baggage.id && ((_a = service.segmentIds) === null || _a === void 0 ? void 0 : _a.includes(segmentId)); }))) {
            return false;
        }
        return true;
    });
};
