import { __read, __spreadArray } from "tslib";
import { ADD_NEW_FLIGHT, CLEAR_FLIGHT, SET_LEG } from './actions';
export var newFlightsReducer = function (state, action) {
    if (state === void 0) { state = []; }
    if (action.type === ADD_NEW_FLIGHT) {
        return __spreadArray(__spreadArray([], __read(state), false), [action.payload], false);
    }
    else if (action.type === CLEAR_FLIGHT) {
        return [];
    }
    else if (action.type === SET_LEG) {
        return state.slice(0, action.payload);
    }
    return state;
};
