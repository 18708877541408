import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
var SaveOrderServices = require('../../../../__queries__/query.graphql').SaveOrderServices;
import { setBreakdownPrices } from '../../../store/priceBreakdown/action';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../../__generated__/globalTypes';
import { debounce } from '../../../../utils';
import { getOrderId, getSegmentsForServices, getServices, isBookedOrConfirmed } from '../../../store/order/selectors';
import { fillOrder } from '../../../store/order/actions';
import * as OrderPrice from '../../../../__queries__/OrderPrice.graphql';
import { setOrderPrice } from '../../../store/orderPrice/actions';
import { getBaggagePriceToPay, getMealsPriceToPay, getSeatsPriceToPay } from '../../../store/orderPrice/selectors';
import { getPassengerStateCompatible } from '../../../store/selectedServices/selectors';
import { addService, addServiceWithoutRequest, deleteAllTypesOnSegment, removeService, removeServiceWithoutRequest, setService } from '../../../store/selectedServices/actions';
import { ShowLoaderAfterBookingContext } from './ServicePageCtx';
var ServicePage = function (_a) {
    var orderId = _a.orderId, services = _a.services, segments = _a.segments, passengers = _a.passengers, children = _a.children, mealTotalPrice = _a.mealTotalPrice, baggageTotalPrice = _a.baggageTotalPrice, seatsTotalPrice = _a.seatsTotalPrice, addService = _a.addService, removeService = _a.removeService, setService = _a.setService, deleteAllTypesOnSegment = _a.deleteAllTypesOnSegment, addServiceWithoutRequest = _a.addServiceWithoutRequest, removeServiceWithoutRequest = _a.removeServiceWithoutRequest;
    var dispatch = useDispatch();
    var isOrderBookedOrConfirmed = useSelector(isBookedOrConfirmed);
    var _b = __read(useMutation(SaveOrderServices), 2), serviceRequest = _b[0], _c = _b[1], data = _c.data, loading = _c.loading;
    var orderPriceRequest = useQuery(OrderPrice.OrderPriceQuery, {
        skip: true
    });
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.SaveOrderServices) {
            dispatch(fillOrder(data.SaveOrderServices));
        }
    }, [data]);
    var selectedMeals = [];
    passengers.forEach(function (passenger) {
        if (passenger.services) {
            passenger.services.forEach(function (product) {
                var serviceObject = services.find(function (_a) {
                    var id = _a.id;
                    return id === product.serviceId;
                });
                if (serviceObject && serviceObject.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
                    selectedMeals.push({
                        passengerId: passenger.id,
                        segmentId: product.segmentIds[0],
                        mealId: product.serviceId,
                        count: product.count,
                        confirmedCount: product.confirmedCount
                    });
                }
            });
        }
    });
    var memoizedServiceRequest = React.useMemo(function () { return debounce(serviceRequest, 500); }, [orderId]);
    // #fixme: use `TravellerSelectedServices`
    var runServiceRequest = useCallback(function (passengersServices, onSuccess) { return __awaiter(void 0, void 0, void 0, function () {
        var services, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    services = passengersServices.map(function (passengerServices) {
                        // #fixme: сохраняются лишние допы.
                        var otherSelectedPassengerServices = passengers[parseInt(passengerServices.passengerId)].services.filter(function (selectedService) {
                            return passengerServices.setServices &&
                                !passengerServices.setServices.some(function (service) { return service.serviceId === selectedService.serviceId; });
                        });
                        /*if (passengerServices.setServices) {
                            setPassengerServices(parseInt(passengerServices.passengerId), [
                                ...otherSelectedPassengerServices,
                                ...passengerServices.setServices
                            ]);
                        }
        
                        if (passengerServices.seats !== undefined && setPassengerSeats) {
                            setPassengerSeats(parseInt(passengerServices.passengerId), passengerServices.seats);
                        }*/
                        return {
                            travellerId: passengerServices.passengerId,
                            gdsServices: passengerServices.setServices
                                ? {
                                    setServices: __spreadArray(__spreadArray([], __read(otherSelectedPassengerServices), false), __read(passengerServices.setServices), false).map(function (service) { return ({
                                        count: service.count,
                                        segmentIds: service.segmentIds,
                                        serviceId: service.serviceId
                                    }); })
                                }
                                : null,
                            seats: passengerServices.seats !== undefined
                                ? passengerServices.seats.map(function (seat) { return ({
                                    segmentId: seat.segment.id,
                                    letter: seat.letter,
                                    row: seat.row
                                }); })
                                : null
                        };
                    });
                    if (!isOrderBookedOrConfirmed) {
                        orderPriceRequest.refetch({ params: { id: orderId, services: services } }).then(function (results) {
                            var _a;
                            if ((_a = results === null || results === void 0 ? void 0 : results.data) === null || _a === void 0 ? void 0 : _a.OrderPrice) {
                                dispatch(setOrderPrice(results.data.OrderPrice));
                            }
                        });
                    }
                    if (!onSuccess) return [3 /*break*/, 2];
                    return [4 /*yield*/, serviceRequest({
                            variables: {
                                params: {
                                    id: orderId,
                                    services: services
                                }
                            }
                        })];
                case 1:
                    response = _a.sent();
                    if (response && response.errors) {
                        throw response.errors;
                    }
                    else if (response && response.data.SaveOrderServices) {
                        dispatch(fillOrder(response.data.SaveOrderServices));
                        onSuccess(response.data.SaveOrderServices);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    // #fixme: this is thing should be refactored
                    memoizedServiceRequest({
                        variables: {
                            params: {
                                id: orderId,
                                services: services
                            }
                        }
                    });
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [passengers, orderId]);
    return (React.createElement(ShowLoaderAfterBookingContext.Provider, { value: loading && isOrderBookedOrConfirmed }, children({
        showLoader: loading && isOrderBookedOrConfirmed,
        runServiceRequest: runServiceRequest,
        segments: segments,
        selectedMeals: selectedMeals,
        mealTotalPrice: mealTotalPrice,
        seatsTotalPrice: seatsTotalPrice,
        baggageTotalPrice: baggageTotalPrice,
        addService: addService,
        removeService: removeService,
        setService: setService,
        deleteAllTypesOnSegment: deleteAllTypesOnSegment,
        addServiceWithoutRequest: addServiceWithoutRequest,
        removeServiceWithoutRequest: removeServiceWithoutRequest
    })));
};
var mapStateToProps = function () {
    return function (state, ownProps) { return (__assign(__assign({}, ownProps), { orderId: getOrderId(state), segments: getSegmentsForServices(state), passengers: getPassengerStateCompatible(state), services: getServices(state), mealTotalPrice: getMealsPriceToPay(state), seatsTotalPrice: getSeatsPriceToPay(state), baggageTotalPrice: getBaggagePriceToPay(state) })); };
};
var mapDispatchToProps = {
    setBreakdownPrices: setBreakdownPrices,
    addService: addService,
    removeService: removeService,
    setService: setService,
    deleteAllTypesOnSegment: deleteAllTypesOnSegment,
    addServiceWithoutRequest: addServiceWithoutRequest,
    removeServiceWithoutRequest: removeServiceWithoutRequest
};
export default connect(mapStateToProps, mapDispatchToProps)(ServicePage);
