import { __read } from "tslib";
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useToggleable } from '../hooks';
import { emailRegex, initI18n, phoneRegex } from '../utils';
import * as SaveClientInfo from '../__queries__/SaveClientInfo.graphql';
initI18n('CustomerContactsForm');
export var useCustomerContactsValidation = function () {
    var t = useTranslation('CustomerContactsForm').t;
    var testEmail = function (value) {
        if (!value) {
            return t('Required');
        }
        else if (!emailRegex.test(value)) {
            return t('Please enter a valid email');
        }
        else {
            return undefined;
        }
    };
    var testPhone = function (value) {
        if (!value) {
            return t('Required');
        }
        else if (!phoneRegex.test(value)) {
            return t('Please enter a valid phone');
        }
        else {
            return undefined;
        }
    };
    return {
        testEmail: testEmail,
        testPhone: testPhone
    };
};
export var useCustomerContactsForm = function () {
    var _a = useToggleable(false), isContactsModalOpen = _a.isOpen, openContactsModal = _a.open, closeContactsModal = _a.close;
    var _b = __read(useMutation(SaveClientInfo.SaveClientInfo), 2), saveClientInfo = _b[0], loading = _b[1].loading;
    return {
        isContactsModalOpen: isContactsModalOpen,
        openContactsModal: openContactsModal,
        closeContactsModal: closeContactsModal,
        saveClientInfo: saveClientInfo,
        loading: loading
    };
};
