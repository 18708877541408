import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
import OrderPreview from './OrderPreview/OrderPreview';
import OrderStatus from './OrderStatus/OrderStatus';
import OrderBooking from './OrderBooking/OrderBooking';
import OrderPassengers from './OrderPassengers/OrderPassengers';
import OrderCardControls from './OrderCardControls/OrderCardControls';
import OrderControls from './OrderControls/OrderControls';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import { OrderStatus as OrderStatusType } from '../../__generated__/graphql';
import OrderTickets from './OrderTickets/OrderTickets';
import Button from '../../Button/Button';
import PaymentStatus from './PaymentStatus/PaymentStatus';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { OverrideComponent } from '../../renderProps';
import { useDownloadOrderDocument } from '../../Checkout/components/Checkout/hooks';
initI18n('OrderCard');
export var OrderCardContext = React.createContext(null);
var OrderCard = function (props) {
    var _a;
    var _b, _c;
    var type = props.type;
    var css = useTheme('OrderCard').OrderCard;
    var t = useTranslation('OrderCard').t;
    var onClickHandler = function () {
        if (typeof props.onClick === 'function') {
            props.onClick(props.order.id);
        }
    };
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _d = useDownloadOrderDocument(), isDocumentsDownloading = _d.loading, handleDownload = _d.handleDownload;
    var exchangeAvailable = (_b = props.order.exareInfo) === null || _b === void 0 ? void 0 : _b.exchangeAvailability;
    var refundAvailable = (_c = props.order.exareInfo) === null || _c === void 0 ? void 0 : _c.refundAvailability;
    var onOrderChangeClick = function (buttonType) {
        buttonType === 'exchange' ? props.goToExchange() : props.goToRefund();
    };
    return (React.createElement(OrderCardContext.Provider, { value: props },
        React.createElement("div", { className: cn(css.container, (_a = {},
                _a[css.type_review] = type === 'review',
                _a[css.type_account] = type === 'account',
                _a)), onClick: isMobile && type === 'account' ? onClickHandler : null },
            React.createElement(OrderPreview, { className: css.preview, onClick: props.onClick && onClickHandler }),
            React.createElement("div", { className: css.root },
                type === 'review' && (React.createElement(React.Fragment, null,
                    React.createElement(OverrideComponent, { componentProps: {}, component: {
                            OrderCardPaymentStatus: PaymentStatus
                        } }),
                    React.createElement(OverrideComponent, { componentProps: {
                            className: cn(css.item, css.status)
                        }, component: { OrderCardStatus: OrderStatus } }),
                    React.createElement(OverrideComponent, { componentProps: {
                            className: cn(css.item, css.booking),
                            onDownloadTicket: handleDownload
                        }, component: {
                            OrderCardBooking: OrderBooking
                        } }),
                    React.createElement(OrderCardControls, null),
                    React.createElement(OverrideComponent, { componentProps: {
                            className: cn(css.item, css.passengers),
                            refetchOrder: props.refetchOrder,
                            openOrder: props.openOrder
                        }, component: {
                            OrderCardPassengers: OrderPassengers
                        } }),
                    props.order.status === OrderStatusType.Confirmed && type === 'review' && (React.createElement(OrderTickets, { className: cn(css.item, css.tickets) })),
                    React.createElement(OverrideComponent, { componentProps: {
                            onClick: onOrderChangeClick,
                            exchangeAvailable: exchangeAvailable,
                            refundAvailable: refundAvailable,
                            onDownloadTicket: handleDownload,
                            show: exchangeAvailable || refundAvailable,
                            isDocumentsDownloading: isDocumentsDownloading
                        }, component: {
                            OrderCardControls: OrderControls
                        } }))),
                type === 'account' && (React.createElement(React.Fragment, null,
                    React.createElement(OrderBooking, { className: cn(css.item, css.booking) }),
                    React.createElement(OrderPassengers, { className: cn(css.item, css.passengers) }),
                    React.createElement(PaymentStatus, null),
                    React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                        React.createElement("div", { className: css.buttons },
                            React.createElement(Button, { variant: "flat", className: css.openOrder, onClick: onClickHandler }, t('Open'))))))))));
};
export default OrderCard;
