import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { planeIcon } from '../../Icons';
import { FlightClass, TemperatureUnits } from '../../../__generated__/globalTypes';
import { format, TABLET_MIN_WIDTH, TABLET_MIDDLE_WIDTH } from '../../../utils';
import MediaQuery from 'react-responsive';
import { getAbsoluteDate } from '../../../Engine/components/FastSearch/utils';
import { weatherIcons } from '../../../Weather/icons';
import { getTemperaturePrefix } from '../../../Weather/utils';
import { isSameDay } from 'date-fns';
var SegmentCommon = function (_a) {
    var _b, _c;
    var _d, _e, _f, _g;
    var segment = _a.segment, serviceClass = _a.serviceClass;
    var css = useTheme('Segment').SegmentCommon;
    var t = useTranslation('Segment').t;
    var currentDate = new Date();
    var fareFamily = (_d = segment.fareFamilies[0]) === null || _d === void 0 ? void 0 : _d.fareFamily;
    var isSameAirportCheckinOpenDate = isSameDay(new Date(segment.checkinInfo.airportOpenTime), new Date(segment.checkinInfo.airportCloseTime));
    return (React.createElement("div", { className: cn(css.wrapper, (_b = {},
            _b[css.wrapper_business] = serviceClass === FlightClass.Business,
            _b)) },
        React.createElement("div", { className: cn(css.header, (_c = {},
                _c[css.header_withWeather] = segment.departure.weather || segment.arrival.weather,
                _c)) },
            React.createElement("div", { className: css.cities },
                React.createElement("div", { className: css.city },
                    React.createElement("span", { className: css.city__airport }, segment.departure.airport.name),
                    React.createElement("span", { className: css.city__info },
                        React.createElement("span", { className: css.city__iata }, segment.departure.airport.iata),
                        React.createElement("span", { className: css.city__time }, segment.departure.time.substr(0, 5))),
                    segment.departure.weather && (React.createElement("span", { className: css.city__weather },
                        React.createElement("span", { className: css.weather__icon }, weatherIcons[segment.departure.weather && segment.departure.weather.type]),
                        getTemperaturePrefix(segment.departure.weather.temperature),
                        Math.abs(Math.round(segment.departure.weather.temperature)),
                        "\u00B0",
                        segment.departure.weather.measurement === TemperatureUnits.Fahrenheit && 'F'))),
                React.createElement("span", { className: css.plane__icon }, planeIcon),
                React.createElement("div", { className: css.city },
                    React.createElement("span", { className: css.city__airport }, segment.arrival.airport.name),
                    React.createElement("span", { className: css.city__info },
                        React.createElement("span", { className: css.city__iata }, segment.arrival.airport.iata),
                        React.createElement("span", { className: css.city__time }, segment.arrival.time.substr(0, 5))),
                    segment.arrival.weather && (React.createElement("span", { className: css.city__weather },
                        React.createElement("span", { className: css.weather__icon }, weatherIcons[segment.arrival.weather && segment.arrival.weather.type]),
                        getTemperaturePrefix(segment.arrival.weather.temperature),
                        Math.abs(Math.round(segment.arrival.weather.temperature)),
                        "\u00B0",
                        segment.arrival.weather.measurement === TemperatureUnits.Fahrenheit && 'F')))),
            React.createElement("div", { className: css.flightInfo },
                React.createElement(MediaQuery, { minWidth: +TABLET_MIDDLE_WIDTH + 1 },
                    React.createElement("div", { className: css.flightInfo__item },
                        React.createElement("span", { className: css.flightInfo__title }, t('Departure')),
                        React.createElement("span", { className: css.flightInfo__value }, format(segment.departure.date, "".concat(new Date(segment.departure.date).getFullYear() - currentDate.getFullYear() > 0
                            ? 'dd MMM yyyy'
                            : 'dd MMMM'))))),
                React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
                    React.createElement("div", { className: css.flightInfo__item },
                        React.createElement("span", { className: css.flightInfo__title }, t('Flight')),
                        React.createElement("span", { className: css.flightInfo__value },
                            segment.operatingAirline.iata,
                            "-",
                            segment.flightNumber))))),
        React.createElement("div", { className: css.footer },
            React.createElement("div", { className: css.footer__airline },
                React.createElement("span", null,
                    React.createElement("span", { className: css.airline__logo, style: {
                            backgroundImage: "url(".concat((_e = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _e === void 0 ? void 0 : _e.icon, ")")
                        } }),
                    React.createElement("span", { className: css.airline__name },
                        ((_f = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _f === void 0 ? void 0 : _f.name) && fareFamily.airline.name + ', ', (_g = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.title) === null || _g === void 0 ? void 0 :
                        _g.toLowerCase())),
                React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                    React.createElement("span", null,
                        t('Flight'),
                        ": ",
                        segment.operatingAirline.iata,
                        "-",
                        segment.flightNumber,
                        React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
                            ", ",
                            format(segment.departure.date, 'dd MMM yyyy'))))),
            React.createElement("div", null,
                segment.departure.terminal && (React.createElement("span", { className: css.footer__item },
                    React.createElement("span", null,
                        t('Terminal'),
                        React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 }, ": ")),
                    React.createElement("span", null, segment.departure.terminal))),
                React.createElement("span", { className: css.footer__item },
                    React.createElement("span", null, t('Boarding')),
                    React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 }, ": "),
                    React.createElement("span", null,
                        format(getAbsoluteDate(segment.checkinInfo.boardingOpenTime), 'HH:mm'),
                        " \u2014",
                        ' ',
                        format(getAbsoluteDate(segment.checkinInfo.boardingCloseTime), 'HH:mm'))),
                React.createElement("span", { className: css.footer__item },
                    React.createElement("span", null, t('Airport check-in')),
                    React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 }, ": "),
                    React.createElement("span", null,
                        format(getAbsoluteDate(segment.checkinInfo.airportOpenTime), isSameAirportCheckinOpenDate ? 'HH:mm' : 'dd.MM HH:mm'),
                        ' ',
                        "\u2014",
                        ' ',
                        format(getAbsoluteDate(segment.checkinInfo.airportCloseTime), isSameAirportCheckinOpenDate ? 'HH:mm' : 'dd.MM HH:mm')))))));
};
export default SegmentCommon;
