import { isContactField } from './../sirena/sirenaPassengersData';
import { genderTypes, docTypes, isStartFromDigits } from './../helpers';
import { isValid, parse } from 'date-fns';
import { isDigits } from '../helpers';
var SPECIFIC_COMMANDS = /^-|^9/gm;
var DIGITS_PLUS_AND_SYMBOLS_AFTER = /\d+\+.+$/;
var DIGITS_AND_SYMBOLS_AFTER = /\d+.+$/;
var ANY_CHARS_AT_START_EXCEPT_DIGITS = /^[^\d]+/g;
var HASH = /\#/;
var DIGITS = /\d+/;
var SLASH = /\//;
var LINEFEED = /\n/;
var AT_SIGN = /@/;
var MOBILE_PHONE = /\+\d{11}/;
var EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export var validateGenderTypes = function (str) {
    var citizenship = str.split(':')[1];
    var value = citizenship ? str.split(':')[0].split('+')[1] : str.split('+')[1];
    if (Object.keys(genderTypes).every(function (gender) { return gender !== value; })) {
        return 'Incorrect gender.';
    }
    return '';
};
export var validateDocTypes = function (str) {
    if (Object.keys(docTypes).every(function (el) { return el !== str.trim(); })) {
        return 'Incorrect document type.';
    }
    return '';
};
export var validateDocNumber = function (str) {
    var doc = str.split(HASH)[0].split('*')[0];
    var docType = doc.replace(DIGITS_AND_SYMBOLS_AFTER, '').trim();
    var docNumber = doc.replace(ANY_CHARS_AT_START_EXCEPT_DIGITS, '');
    if (docType === 'ПС' || docType === 'PS') {
        if (!isDigits(docNumber) || docNumber.length !== 10) {
            return 'Incorrect document number.';
        }
    }
    if (docType === 'ПСП' || docType === 'PSP') {
        if (!isDigits(docNumber) || docNumber.length !== 9) {
            return 'Incorrect document number.';
        }
    }
    return '';
};
export var validateBirthDate = function (str) {
    var fullDate = str.match(DIGITS)[0];
    var isValidLength = fullDate.length === 6;
    if (!isValidLength || !isValid(parse(fullDate, 'ddMMyy', new Date()))) {
        return 'Incorrect birth date.';
    }
    return '';
};
export var validateExpiryDate = function (str) {
    var isValidLength = str.length === 6;
    if (str && (!isValidLength || !isValid(parse(str, 'ddMMyy', new Date())))) {
        return 'Incorrect document expiry date.';
    }
    return '';
};
export var validatePhone = function (str) {
    if (!MOBILE_PHONE.test(str) || str.replace(ANY_CHARS_AT_START_EXCEPT_DIGITS, '').length > 15) {
        return 'Incorrect mobile number.';
    }
    return '';
};
export var validateEmail = function (str) {
    if (!EMAIL.test(str)) {
        return 'Incorrect e-mail.';
    }
    return '';
};
export var validateContactField = function (str) {
    if (!AT_SIGN.test(str)) {
        return validatePhone(str);
    }
    else {
        return validateEmail(str.split(HASH)[1].toLowerCase());
    }
};
export var sirenaPassengersDataValidation = function (str) {
    var strWithoutSpecificCommands = str.replace(SPECIFIC_COMMANDS, '');
    var data = strWithoutSpecificCommands.split(LINEFEED) || [strWithoutSpecificCommands];
    var validations = data.map(function (command) {
        if (isContactField(command)) {
            return validateContactField(command);
        }
        else {
            var passengersData = command.split(SLASH);
            // (ФИО)<ДАТ_РОЖД>(+ПОЛ)<:ГРЖ>
            var nameDateGenderNationalityStr = passengersData[0];
            // <ДАТ_РОЖД>(+ПОЛ)<:ГРЖ>
            var dateAndGender = nameDateGenderNationalityStr.match(DIGITS_PLUS_AND_SYMBOLS_AFTER)[0];
            // (ДОК) || (ДОК)<*ККП><#КОД>
            var docData = passengersData[1];
            // </ДАТ_ИСТ><*ККП><#КОД>
            var expiryDate = passengersData[2] && isStartFromDigits(passengersData[2]) ? passengersData[2].match(DIGITS)[0] : '';
            return [
                validateBirthDate(dateAndGender),
                validateGenderTypes(dateAndGender),
                validateDocTypes(docData.replace(DIGITS_AND_SYMBOLS_AFTER, '')),
                validateDocNumber(docData),
                validateExpiryDate(expiryDate)
            ]
                .join(' ')
                .trim();
        }
    });
    return validations.join(' ').trim();
};
