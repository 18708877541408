import { __read } from "tslib";
import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import * as GetAvailableDates from './SearchForm/components/SearchForm/Segments/Segment/Datepicker/query.graphql';
import { parse } from 'date-fns';
import { API_DATE_FORMAT, USER_AGENT_RULES } from './utils';
import { useConfig } from './context';
export var useIataCode = function () {
    var iataCode = useConfig().global.companyInfo.iataCode;
    return iataCode;
};
export var useIsTerminal = function () {
    return useIataCode() === 'TERMINAL';
};
export var useToggleable = function (initialState) {
    var _a = __read(useState(initialState), 2), isOpen = _a[0], setOpen = _a[1];
    var open = useCallback(function () {
        setOpen(true);
    }, [isOpen]);
    var close = useCallback(function () {
        setOpen(false);
    }, [isOpen]);
    var toggle = useCallback(function () {
        setOpen(!isOpen);
    }, [isOpen]);
    return {
        isOpen: isOpen,
        open: open,
        close: close,
        toggle: toggle,
        setOpen: setOpen
    };
};
export function useSelectable(initial) {
    var _a = __read(useState(initial), 2), active = _a[0], setActive = _a[1];
    var onSelect = useCallback(function (newActive) {
        setActive(newActive);
        return newActive;
    }, [active]);
    return {
        active: active,
        onSelect: onSelect
    };
}
export var useAvailableDates = function (segment) {
    var _a = useQuery(GetAvailableDates.GetAvailableDates, {
        variables: {
            departure: segment === null || segment === void 0 ? void 0 : segment.departure.iata,
            arrival: segment === null || segment === void 0 ? void 0 : segment.arrival.iata
        },
        skip: !segment
    }), availableDates = _a.data, refetch = _a.refetch, error = _a.error, loading = _a.loading;
    var dates = useMemo(function () {
        if (availableDates === null || availableDates === void 0 ? void 0 : availableDates.FlightsSchedule.datesData) {
            return availableDates === null || availableDates === void 0 ? void 0 : availableDates.FlightsSchedule.datesData.filter(function (date) { return date === null || date === void 0 ? void 0 : date.date; }).map(function (date) { return parse(date.date, API_DATE_FORMAT, new Date()); });
        }
        return [];
    }, [availableDates === null || availableDates === void 0 ? void 0 : availableDates.FlightsSchedule]);
    return {
        dates: dates,
        isLoading: loading,
        error: error,
        refetch: refetch
    };
};
var useBrowserName = function () {
    return useMemo(function () {
        var userAgent = window.navigator.userAgent;
        var browserInfo = userAgent !== '' &&
            USER_AGENT_RULES.reduce(function (matched, _a) {
                var _b = __read(_a, 2), browser = _b[0], regex = _b[1];
                if (matched) {
                    return matched;
                }
                var userAgentMatch = regex.exec(userAgent);
                return !!userAgentMatch && [browser, userAgentMatch];
            }, false);
        return browserInfo ? browserInfo[0] : null;
    }, []);
};
export var useIsEdgeBrowser = function () {
    var browserName = useBrowserName();
    return browserName === 'edge' || browserName === 'edge-chromium';
};
export var useEventListener = function (eventName, handler, element) {
    var savedHandler = useRef();
    useEffect(function () {
        var targetElement = (element === null || element === void 0 ? void 0 : element.current) || window;
        if (!(targetElement && targetElement.addEventListener)) {
            return function () { };
        }
        if (savedHandler.current !== handler) {
            savedHandler.current = handler;
        }
        var eventListener = function (event) {
            if (!!(savedHandler === null || savedHandler === void 0 ? void 0 : savedHandler.current)) {
                savedHandler.current(event);
            }
        };
        targetElement.addEventListener(eventName, eventListener);
        return function () {
            targetElement.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element, handler]);
};
