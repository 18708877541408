import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
export var useServices = function (services, state, segments, passengers) {
    var servicesWithPayload = React.useMemo(function () {
        return services.map(function (service) {
            var availableSegments = [];
            var availableSegmentLabels = [];
            service.allowedSegments.forEach(function (segmentIds) {
                segmentIds.forEach(function (segment) {
                    if (availableSegments.indexOf(segment) === -1) {
                        availableSegments.push(segment);
                        var segmentItem = segments.find(function (segmentItem) { return segmentItem.id === segment; });
                        availableSegmentLabels.push("".concat(segmentItem.departure.airport.iata, " - ").concat(segmentItem.arrival.airport.iata));
                    }
                });
            });
            var availablePassengers = [];
            var travellerTypesSet = new Set();
            service.allowedPassengers.forEach(function (passengerIds) {
                passengerIds.forEach(function (passengerId) {
                    if (availablePassengers.indexOf(passengerId) === -1) {
                        availablePassengers.push(passengerId);
                        var traveller = passengers.find(function (passenger) { return passenger.id === passengerId; });
                        travellerTypesSet.add(traveller.type);
                    }
                });
            });
            return {
                service: service,
                availablePassengers: availablePassengers,
                availableSegments: availableSegments,
                availableSegmentLabels: availableSegmentLabels,
                availablePassengerTypeLabels: __spreadArray([], __read(travellerTypesSet.keys()), false)
            };
        });
    }, [services]);
    var servicesWithCount = React.useMemo(function () {
        return servicesWithPayload
            .map(function (service) {
            var count = 0;
            if (state.hasOwnProperty(service.service.id)) {
                var selectedState_1 = state[service.service.id];
                var passengers_1 = Object.keys(selectedState_1);
                passengers_1.forEach(function (passengerId) {
                    var segments = Object.keys(selectedState_1[passengerId]);
                    segments.forEach(function (segmentId) { return (count += selectedState_1[passengerId][segmentId]); });
                });
            }
            return __assign(__assign({}, service), { count: count });
        })
            .sort(function (a, b) {
            return b.count - a.count;
        });
    }, [state, servicesWithPayload]);
    return servicesWithCount;
};
