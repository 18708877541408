import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../Tooltip';
var RecognizeTooltip = function (props) {
    var t = useTranslation('DocumentRecognition').t;
    if (props.isCameraPermissionDenied) {
        return (React.createElement(Tooltip, { title: t('Recognizing will be available after granting access to the camera') },
            React.createElement("div", null, props.children)));
    }
    return React.createElement(React.Fragment, null, props.children);
};
export default RecognizeTooltip;
