import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
var WING_HEIGHT = 6;
var Wings = React.memo(function (props) {
    var _a;
    var ref = React.useRef(null), wings = React.useRef(null);
    var _b = __read(React.useState(false), 2), hasWings = _b[0], setHasWings = _b[1];
    var theme = useTheme('SeatMap').SeatMapStyles;
    React.useEffect(function () {
        if (ref) {
            var wingSeats = ref.current.querySelectorAll('div[with-wings="true"]');
            if (!wingSeats.length) {
                setHasWings(false);
                return;
            }
            setHasWings(true);
            var firstSeat = wingSeats.item(0), count = wingSeats.length;
            wings.current.style.top = "".concat(firstSeat['offsetTop'], "px");
            if (count > WING_HEIGHT) {
                var rate = count / WING_HEIGHT;
                wings.current.style.transform = "scaleY(".concat(rate, ")");
            }
        }
    }, [ref]);
    return (React.createElement("div", { ref: ref },
        props.children,
        React.createElement("div", { ref: wings, className: cn(theme.wings, (_a = {}, _a[theme.wings_center] = !hasWings, _a)) })));
});
export default Wings;
