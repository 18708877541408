import React, { useMemo } from 'react';
import { useTheme } from '../../../theme';
import Included from './Included/Included';
import Upgrade from './Upgrade/Upgrade';
import BaggageTotalPriceBlock from '../../BaggageTotalPriceBlock';
import { useToggleable } from '../../../hooks';
import UpgradeModal from './UpgradeModal/UpgradeModal';
import { initI18n } from '../../../utils';
import { getMinPrice, getPriceToPay } from '../../../BaggagePage/utils';
import { joinSelectedServicesByRfics, joinServicesByRfics } from '../../UpgradeBaggageDialog/utils';
initI18n('IncludedBaggage');
var IncludedBaggage = function (props) {
    var css = useTheme('IncludedBaggage').IncludedBaggage;
    var _a = useToggleable(false), isOpenModal = _a.isOpen, openModal = _a.open, closeModal = _a.close;
    var priceToPay = useMemo(function () { return getPriceToPay(props.selectedServices, props.upgradeServices); }, [
        props.upgradeServices,
        props.selectedServices
    ]);
    var baggageByRfics = useMemo(function () { return joinServicesByRfics(props.upgradeServices); }, [props.upgradeServices]);
    var minPrice = useMemo(function () { return getMinPrice(props.upgradeServices); }, [props.upgradeServices]);
    var groupedByRficsIncludedBaggage = useMemo(function () {
        return joinSelectedServicesByRfics(props.includedBaggage);
    }, [props.includedBaggage]);
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.options },
            props.includedBaggage.length > 0 && (React.createElement(Included, { className: css.included_block, order: props.order, includedBaggage: groupedByRficsIncludedBaggage })),
            props.upgradeServices.length > 0 && (React.createElement(Upgrade, { className: css.upgrade_block, order: props.order, upgradeServices: baggageByRfics, upgradeMinPrice: minPrice, selectedServices: props.selectedServices, segments: props.segments }))),
        props.upgradeServices.length > 0 && (React.createElement("div", { className: css.footer },
            React.createElement(BaggageTotalPriceBlock, { total: priceToPay, min: minPrice, onClickButton: openModal }))),
        props.upgradeServices.length > 0 && (React.createElement(UpgradeModal, { open: isOpenModal, onClose: closeModal, passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, upgradeBaggage: props.upgradeServices, onConfirm: props.onUpgrade, disabled: props.isLoading || props.disabled, selectedServices: props.selectedServices }))));
};
export default IncludedBaggage;
