import { __assign, __read } from "tslib";
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import Input from '../../../Input/Input';
import MaskedInput from 'react-text-mask';
import { PopupDatepicker } from '../../../../Datepicker/components/PopupDatepicker';
import * as dateFns from 'date-fns';
import { dateFormat } from '../../const';
import * as icons from '../../../../Icons';
var DateMaskedInput = function (props) {
    var t = useTranslation('FlightsFilter').t;
    return React.createElement(MaskedInput, __assign({}, props, { mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/], placeholder: t('dd.mm.yy') }));
};
var DateSelector = function (props) {
    var className = props.className, label = props.label, initValue = props.initValue, onChange = props.onChange, onBlue = props.onBlue, error = props.error, helperText = props.helperText;
    var t = useTranslation('FlightsFilter').t;
    var css = useTheme('FlightsFilter').DateSelector;
    var _a = __read(useState(initValue), 2), selectedDate = _a[0], setSelectedDate = _a[1];
    var _b = __read(useState(false), 2), inputfocus = _b[0], setInputFocus = _b[1];
    var date = dateFns.parse(selectedDate, dateFormat, new Date());
    var validSelectedDate = dateFns.isValid(date) ? date : null;
    var datePickerSelectHandler = useCallback(function (date) {
        var dateAsStr = dateFns.format(date, dateFormat);
        setSelectedDate(dateAsStr);
        onChange === null || onChange === void 0 ? void 0 : onChange(dateAsStr);
    }, []);
    var inputSelectHandler = useCallback(function (e) {
        var date = e.currentTarget.value;
        setSelectedDate(date);
        onChange === null || onChange === void 0 ? void 0 : onChange(date);
    }, []);
    var inputOnFocus = useCallback(function () {
        setInputFocus(true);
    }, []);
    var inputOnBlue = useCallback(function () {
        setInputFocus(false);
        onBlue === null || onBlue === void 0 ? void 0 : onBlue();
    }, []);
    return (React.createElement(PopupDatepicker, { className: className, navButtons: [icons.Chevron, icons.Chevron], popupWrapperClassName: css.root, openDate: validSelectedDate, selectedDates: [validSelectedDate], onSelect: datePickerSelectHandler, inputRenderer: function (params) { return (React.createElement(Input, { TextFieldProps: {
                onFocus: inputOnFocus,
                onBlur: inputOnBlue,
                helperText: helperText
            }, label: t(label), defaultValue: selectedDate, value: selectedDate, labelShrink: !!selectedDate || inputfocus, error: error, inputProps: {
                onFocus: params.onFocus,
                onChange: inputSelectHandler
            }, inputComponent: DateMaskedInput })); } }));
};
export default DateSelector;
