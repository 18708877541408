import * as React from 'react';
import ProtectedRoute from '../../ProtectedRoute/ProtectedRoute';
import { useAuthToken } from '../LoginChangePassword/hooks';
import LoginPage from './LoginPage';
import Page from '../Page/Page';
import EventsQueues from '../EventsQueues/EventsQueues';
export var AdditionalRoutes = function (props) {
    var token = useAuthToken().token;
    return (React.createElement(React.Fragment, null,
        React.createElement(ProtectedRoute, { path: '/login', exact: true, isAccessible: !token, redirectTo: '/', render: function () { return React.createElement(LoginPage, null); } }),
        React.createElement(ProtectedRoute, { path: '/queues', exact: true, isAccessible: !!token, redirectTo: '/login', render: function () { return (React.createElement(Page, null,
                React.createElement(EventsQueues, null))); } })));
};
