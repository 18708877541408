import * as React from 'react';
import { useTheme } from '../../../../../../../theme';
import { diffInMinutesBetweenTimes } from '../../../../../../../DesktopFlightRoute/components/Segment/RouteInfo/utils';
export var Flight = function (props) {
    var theme = useTheme('Schedule').Day;
    var diffArrival1Departure2 = props.segmentIndex > 0
        ? diffInMinutesBetweenTimes(props.leg.segments[props.segmentIndex - 1].segment.arrival.time, props.segment.departure.time)
        : null;
    var diffDeparture1Departure2 = props.segmentIndex > 0
        ? diffInMinutesBetweenTimes(props.leg.segments[props.segmentIndex - 1].segment.departure.time, props.segment.departure.time)
        : null;
    var isNextDay = diffArrival1Departure2 > 0 || diffDeparture1Departure2 > 0;
    return (React.createElement("div", { className: theme.flightInfo__segment },
        React.createElement("div", { className: theme.flightInfo__time },
            props.segment.departure.time,
            React.createElement("span", { className: theme.nextDay }, props.segmentIndex > 0 && isNextDay ? '+1' : null)),
        React.createElement("div", { className: theme.flightInfo__flight },
            props.segment.marketingAirline.iata,
            "-",
            props.segment.flightNumber),
        props.segment.marketingAirline.iata !== props.segment.operatingAirline.iata && (React.createElement("div", { className: theme.flightInfo__flight },
            props.segment.operatingAirline.iata,
            "-",
            props.segment.operatingAirlineFlightNumber))));
};
