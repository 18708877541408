export var isFareGroupAvailable = function (group, lastSelectedFare) {
    var _a;
    var canCombineWithPreviousSelectedFare = true;
    if (lastSelectedFare) {
        var lastSelectedFareFlightsIds_1 = lastSelectedFare.prices.map(function (price) { return price.flight.id; });
        canCombineWithPreviousSelectedFare = (_a = group === null || group === void 0 ? void 0 : group.prices) === null || _a === void 0 ? void 0 : _a.some(function (price) {
            return lastSelectedFareFlightsIds_1.includes(price.flight.id);
        });
    }
    return group.prices && canCombineWithPreviousSelectedFare;
};
export var findIndexFirstAvailableFareGroup = function (fareGroups, lastSelectedFare) {
    var index = fareGroups.findIndex(function (group) { return isFareGroupAvailable(group, lastSelectedFare); });
    return index === -1 ? null : index;
};
export var roundAvailableSliderIndex = function (sliderIndex, slidesToScroll) {
    if (sliderIndex < slidesToScroll || !sliderIndex) {
        return 0;
    }
    var offset = 0;
    while ((sliderIndex - offset) % slidesToScroll !== 0) {
        offset++;
    }
    return sliderIndex - offset;
};
