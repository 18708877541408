import OrderCard from './components/OrderCard.css';
import OrderPreview from './components/OrderPreview/OrderPreview.css';
import OrderStatus from './components/OrderStatus/OrderStatus.css';
import OrderBooking from './components/OrderBooking/OrderBooking.css';
import OrderPassengers from './components/OrderPassengers/OrderPassengers.css';
import OrderControls from './components/OrderControls/OrderControls.css';
import * as OrderCardControls from './components/OrderCardControls/OrderCardControls.css';
import * as OrderTickets from './components/OrderTickets/OrderTickets.css';
import * as CommonItem from './components/CommonItem/CommonItem.css';
import * as PaymentStatus from './components/PaymentStatus/PaymentStatus.css';
import PassengersModal from './components/OrderPassengers/PassengersModal/PassengersModal.css';
export default {
    OrderCard: OrderCard,
    OrderPreview: OrderPreview,
    OrderStatus: OrderStatus,
    OrderBooking: OrderBooking,
    OrderPassengers: OrderPassengers,
    OrderCardControls: OrderCardControls,
    OrderTickets: OrderTickets,
    PassengersModal: PassengersModal,
    CommonItem: CommonItem,
    PaymentStatus: PaymentStatus,
    OrderControls: OrderControls
};
