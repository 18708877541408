import * as React from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { format, TABLET_MIN_WIDTH } from '../../../../../../utils';
import SelectedFlights from '../../Request/SelectedFlights/SelectedFlights';
import Calendar from '../Calendar/Calendar';
import WeekCalendar from '../WeekCalendar/WeekCalendar';
import Sort from '../Sort/Sort';
import { useTheme } from '../../../../../../theme';
import Disclaimer from '../../Disclaimer/Disclaimer';
import { isAutoMode } from '../../../../store/order/selectors';
var planeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8 22H10L15 13.5789H20.5C21.33 13.5789 22 12.8737 22 12C22 11.1263 21.33 10.4211 20.5 10.4211H15L10 2H8L10.5 10.4211L5 10.4211L3.5 8.31579H2L3 12L2 15.6842H3.5L5 13.5789L10.5 13.5789L8 22Z", fill: "currentColor" })));
var Header = function (_a) {
    var selectedFlights = _a.selectedFlights, clearFares = _a.clearFares, leg = _a.leg, currentLegNumber = _a.currentLegNumber, searchParams = _a.searchParams, startNewSearch = _a.startNewSearch, sortedFlights = _a.sortedFlights, sorting = _a.sorting, setSorting = _a.setSorting, minDate = _a.minDate;
    var theme = useTheme('Exchange').SearchFlights;
    var t = useTranslation('Exchange').t;
    var autoMode = useSelector(isAutoMode);
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH },
            React.createElement(React.Fragment, null,
                selectedFlights.length > 0 && (React.createElement(SelectedFlights, { prices: null, selectedFlights: selectedFlights.map(function (flights) { return flights.fare.flightInfo; }), goToFlights: clearFares })),
                React.createElement("div", { className: theme.header__wrapper },
                    leg && (React.createElement("div", { className: theme.header },
                        React.createElement("div", { className: theme.leg }, currentLegNumber + 1),
                        React.createElement("span", null,
                            t('Select new flight'),
                            "\u00A0",
                            leg.departure.city.name),
                        planeIcon,
                        React.createElement("span", null, leg.arrival.city.name))),
                    React.createElement(Calendar, { searchParams: searchParams, startNewSearch: startNewSearch, legId: currentLegNumber })),
                React.createElement("div", { className: theme.weekCalendar },
                    React.createElement(WeekCalendar, { searchParams: searchParams, currentLeg: currentLegNumber, startNewSearch: startNewSearch, minDate: minDate })),
                sortedFlights.length > 2 && (React.createElement(Sort, { onSelect: setSorting, currentSort: sorting, onFilterChange: function () { }, onlyDirect: false, showDirectFlightsSwitch: false })),
                !!sortedFlights.length && (React.createElement("div", { className: theme.notices },
                    !autoMode && React.createElement(Disclaimer, { type: "price" }),
                    React.createElement(Disclaimer, { type: "time" }))))),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement("div", null,
                React.createElement("div", { className: theme.header }, t('Select new flight')),
                leg && (React.createElement("div", { className: theme.route },
                    React.createElement("div", null,
                        leg.departure.city.name,
                        " \u2013 ",
                        leg.arrival.city.name),
                    React.createElement("div", null, format(leg.date, 'dd MMM, EE')))),
                React.createElement("div", { className: theme.controls },
                    sortedFlights.length > 2 && (React.createElement(Sort, { onSelect: setSorting, currentSort: sorting, onFilterChange: function () { }, onlyDirect: false, showDirectFlightsSwitch: false })),
                    React.createElement(Calendar, { searchParams: searchParams, startNewSearch: startNewSearch, legId: currentLegNumber }))))));
};
export default Header;
