import { __assign } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { memo } from 'react';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import { isConfirmed as isConfirmedSelector } from '../../../../Checkout/store/order/selectors';
var Taxes = function (props) {
    var css = useTheme('Payment').Payment;
    var t = useTranslation('Payment').t;
    var isConfirmed = useSelector(isConfirmedSelector);
    var flight = props.flight, totalPriceToPay = props.totalPriceToPay;
    var tariff = null;
    var taxes = [];
    if (!(flight === null || flight === void 0 ? void 0 : flight.fares)) {
        return null;
    }
    flight.fares.forEach(function (fare) {
        fare === null || fare === void 0 ? void 0 : fare.passengerFares.forEach(function (passFare) {
            if (!tariff) {
                tariff = __assign(__assign({}, passFare.baseFare), { amount: passFare.baseFare.amount * passFare.count });
            }
            else {
                tariff.amount += passFare.baseFare.amount * passFare.count;
            }
            taxes = taxes.concat(passFare.taxes.map(function (tax) {
                return __assign(__assign({}, tax), { price: __assign(__assign({}, tax.price), { amount: tax.price.amount * passFare.count }) });
            }));
        });
    });
    var aggTaxes = {};
    taxes.forEach(function (tax) {
        if (aggTaxes[tax.code]) {
            aggTaxes[tax.code].amount += tax.price.amount;
        }
        else {
            aggTaxes[tax.code] = tax.price;
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", null,
            React.createElement("span", null,
                t('Total'),
                ":"),
            React.createElement(Money, { money: totalPriceToPay })),
        !isConfirmed && (React.createElement("div", { className: css.tariff },
            React.createElement("div", null,
                React.createElement("span", null,
                    t('Tariff'),
                    ":"),
                React.createElement(Money, { moneyClassName: css.money, money: tariff })),
            Object.keys(aggTaxes).map(function (taxKey, index) { return (React.createElement("div", { key: "".concat(taxKey, "_").concat(index) },
                React.createElement("span", null, "".concat(t('Fee'), " ").concat(taxKey, ":")),
                React.createElement(Money, { money: aggTaxes[taxKey], moneyClassName: css.money }))); })))));
};
export default memo(Taxes);
