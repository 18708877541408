import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../theme';
import SegmentTabComponent from './components/SegmentTab/SegmentTab';
var SegmentTabs = function (props) {
    var _a, _b;
    var css = useTheme('SegmentTabs').SegmentTabs;
    return (React.createElement("div", { className: cn(css.wrapper, (_a = {},
            _a[css.wrapper_sticky] = props.sticky,
            _a)) },
        React.createElement("div", { className: css.inner },
            React.createElement("div", { className: css.title }, props.title),
            !props.isOnlyTitle && (React.createElement("div", { className: cn(css.segments, props.className, (_b = {},
                    _b[css.left] = props.position === 'left',
                    _b[css.right] = props.position === 'right',
                    _b)) }, props.segments.map(function (segment, index) {
                var _a;
                return (React.createElement(SegmentTabComponent, { key: segment.id, index: index + 1, scrollToActive: props.scrollToActive, isActive: segment.id === props.activeSegment, isCompact: (_a = props.isCompact) !== null && _a !== void 0 ? _a : props.segments.length > 2, onClick: props.onSegmentClick.bind(null, segment.id), segment: segment, className: props.classNameTab }));
            }))),
            props.renderRightBlock && props.renderRightBlock())));
};
export default SegmentTabs;
