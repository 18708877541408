import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import Modal from '../../../../../../../Modal';
import { Form } from 'react-final-form';
import VisaFormComponent from '../../../../../../../VisaForm/components/VisaForm';
import Button from '../../../../../../../Button';
import { useTheme } from '../../../../../../../theme';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import * as Mutation from '../../../../../../../__queries__/CheckinSetTravellersData.graphql';
import { fillCheckinOrder } from '../../../../../store/order/actions';
import { useSelector, useDispatch } from 'react-redux';
import { getTravellersWithVisa, getCheckinOrderId } from '../../../../../store/order/selectors';
var VisaForm = function (_a) {
    var onVisaSubmitted = _a.onVisaSubmitted;
    var travellersWithVisa = useSelector(getTravellersWithVisa);
    var orderId = useSelector(getCheckinOrderId);
    var dispatch = useDispatch();
    var _b = __read(useMutation(Mutation.SetCheckinSetTravellersData), 1), checkinSetTravellersData = _b[0];
    var _c = __read(React.useState(false), 2), isLoading = _c[0], setLoading = _c[1];
    var theme = useTheme('Checkin').VisaForm;
    var t = useTranslation('Checkin').t;
    var sendTravellersData = function (travellers) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, results;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, checkinSetTravellersData({
                        variables: {
                            params: {
                                travellersData: travellers,
                                orderId: orderId
                            }
                        }
                    })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    results = !errors && (data === null || data === void 0 ? void 0 : data.CheckinSetTravellersData);
                    if (results) {
                        dispatch(fillCheckinOrder(results));
                        setLoading(false);
                        onVisaSubmitted();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onSubmit = React.useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var travellersData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    travellersData = travellersWithVisa.map(function (traveller) { return ({
                        id: traveller.id,
                        values: traveller.values.map(function (value) { return ({
                            type: value.type,
                            name: value.name,
                            value: value.value
                        }); }),
                        visaValues: traveller.visaValues.map(function (value) { return ({
                            type: value.type,
                            name: value.name,
                            value: values['travellers'][traveller.id][value.name]
                        }); })
                    }); });
                    return [4 /*yield*/, sendTravellersData(travellersData)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, travellersWithVisa);
    return (React.createElement(Modal, { maxWidth: "md", open: true, classes: {
            paper: theme.modal__paper
        } },
        React.createElement("div", { className: theme.wrapper },
            React.createElement("div", { className: theme.header }, t('Please, enter your visa details')),
            React.createElement(Form, { onSubmit: onSubmit }, function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", null,
                        travellersWithVisa.map(function (traveller) { return (React.createElement(VisaFormComponent, { key: traveller.id, traveller: traveller })); }),
                        React.createElement("div", { className: theme.button },
                            React.createElement(Button, { type: "submit", variant: "flat", isLoading: isLoading }, t('Save'))))));
            }))));
};
export default VisaForm;
