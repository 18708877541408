import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import FullScreenModal from '../../../LEO/FullScreenModal/FullScreenModal';
import { useTheme } from '../../../theme';
import { useState } from 'react';
var WrapperModal = function (props) {
    var t = useTranslation('Refund').t;
    var css = useTheme('Refund').Wrapper;
    var _a = __read(useState(false), 2), isClosing = _a[0], setIsClosing = _a[1];
    var closeModal = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsClosing(true);
                    return [4 /*yield*/, props.onClose()];
                case 1:
                    _a.sent();
                    setIsClosing(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(FullScreenModal, { open: true, onClose: closeModal, title: t('Refund ticket'), header: props.steps, headerAlignment: 'left', isClosing: isClosing },
        React.createElement("div", { className: css.fullScreenContent }, props.children)));
};
export default WrapperModal;
