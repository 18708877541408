import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { Field, Form } from 'react-final-form';
import Button from '../../../../Button/Button';
import Modal from '../../../../Modal';
import { useToggleable } from '../../../../hooks';
import { DateMaskedInput } from '../../../../DateMaskedInput/DateMaskedInput';
import { usePointOfSales } from '../../../leoCheckIsAuthenticated/pointOfSalesContext';
import CloseIcon from '../../../../CloseIcon/components/CloseIcon';
import { inputRequiredValidate, numberValidate, requiredNumberValidate } from '../../../EventsQueues/utils';
import Input from '../../../Input/Input';
var formatValues = function (values) {
    var formattedValues = {};
    Object.entries(values).forEach(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        formattedValues[key] = value === null || value === void 0 ? void 0 : value.trim();
    });
    formattedValues.queueNumber = values.queueNumber ? +values.queueNumber : null;
    return formattedValues;
};
var QueueForm = function (_a) {
    var header = _a.header, openModalRenderer = _a.openModalRenderer, _b = _a.inputs, inputs = _b === void 0 ? [] : _b, disabledInputs = _a.disabledInputs, buttonText = _a.buttonText, initialValues = _a.initialValues, onSubmit = _a.onSubmit;
    var theme = useTheme('EventsQueues').QueueForm;
    var t = useTranslation('EventsQueues').t;
    var _c = useToggleable(false), isOpen = _c.isOpen, open = _c.open, close = _c.close;
    var _d = __read(useState(false), 2), isAddInProgress = _d[0], setIsAddInProgress = _d[1];
    var _e = __read(useState(false), 2), isAddedSuccessfully = _e[0], setIsAddedSuccessfully = _e[1];
    var _f = __read(useState(null), 2), requestError = _f[0], setRequestError = _f[1];
    var posCredentials = usePointOfSales();
    var onSubmitHandler = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var formattedValues, _a, status, error, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsAddInProgress(true);
                    setRequestError(null);
                    formattedValues = formatValues(values);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onSubmit(formattedValues)];
                case 2:
                    _a = _b.sent(), status = _a.status, error = _a.error;
                    if (status === 'success') {
                        setIsAddInProgress(false);
                        setIsAddedSuccessfully(true);
                        setTimeout(function () {
                            close();
                        }, 1000);
                    }
                    else if (status === 'error') {
                        setRequestError(error);
                        setIsAddInProgress(false);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    setIsAddInProgress(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var inputFields = __spreadArray([
        { label: t('IATA ID Group / Agency*'), name: 'agency', isRequired: true, isReadOnly: true },
        {
            label: t('IATA ID Group / POS*'),
            name: 'pos',
            isRequired: true,
            isReadOnly: !(posCredentials === null || posCredentials === void 0 ? void 0 : posCredentials.isAgencySupervisor)
        },
        {
            label: t('Queue Number*'),
            name: 'queueNumber',
            validate: requiredNumberValidate
        },
        { label: t('Category'), name: 'category', validate: numberValidate }
    ], __read(inputs), false);
    var onFormChange = function () {
        setRequestError(null);
    };
    var onAddToQueueClick = function () {
        setRequestError(null);
        setIsAddedSuccessfully(false);
        open();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: isOpen, classes: { paper: theme.modal__paper }, onClose: close, closeButtonRenderer: function (onClick) { return (React.createElement("div", { className: theme.popup__close },
                React.createElement(CloseIcon, { onClick: onClick }))); } },
            React.createElement("div", { className: theme.header }, header),
            React.createElement(Form, { initialValues: initialValues, onSubmit: onSubmitHandler }, function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit, className: theme.form, onChange: onFormChange },
                    inputFields.map(function (inputField) {
                        var fieldProps = {};
                        var inputProps = {};
                        if (inputField.name === 'deadline') {
                            inputProps = __assign(__assign({}, inputProps), { inputComponent: DateMaskedInput });
                        }
                        if (inputField.isRequired) {
                            fieldProps = __assign(__assign({}, fieldProps), { validate: inputRequiredValidate });
                        }
                        if (inputField.validate) {
                            fieldProps = __assign(__assign({}, fieldProps), { validate: inputField.validate });
                        }
                        var disabled = disabledInputs === null || disabledInputs === void 0 ? void 0 : disabledInputs.includes(inputField.name);
                        return (React.createElement(Field, __assign({ key: inputField.name, name: inputField.name }, fieldProps), function (field) { return (React.createElement(Input, __assign({ className: theme.field, label: inputField.label, variant: 'outlined', disabled: disabled || inputField.isReadOnly, TextFieldProps: __assign(__assign({}, field.input), { error: field.meta.error && field.meta.submitFailed, helperText: field.meta.error &&
                                    field.meta.submitFailed &&
                                    t(field.meta.error) }) }, inputProps))); }));
                    }),
                    React.createElement("div", { className: theme.error }, requestError),
                    React.createElement("div", { className: theme.controls },
                        React.createElement(Button, { type: "submit", variant: "flat", isSuccess: isAddedSuccessfully, isLoading: isAddInProgress, className: theme.addBtn }, buttonText !== null && buttonText !== void 0 ? buttonText : t('Add')))));
            })),
        openModalRenderer ? (openModalRenderer({ onClick: onAddToQueueClick })) : (React.createElement(Button, { onClick: onAddToQueueClick }, t('Add')))));
};
export default QueueForm;
