import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { seatIcon } from '../../../Icons';
import { useTheme } from '../../../../theme';
import { getPassengersNames } from '../../../utils';
var SeatsStep = function (_a) {
    var onChange = _a.onChange, order = _a.order, passengers = _a.passengers;
    var css = useTheme('MobileStepbar').StepContentStyles;
    var t = useTranslation().t;
    var passengerNames = getPassengersNames(passengers);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.content }, order.segments.map(function (segment, segmentIndex) { return (React.createElement("div", { key: segmentIndex, className: css.content__item },
            React.createElement("div", { className: cn(css.item__row, css.segment__header) },
                React.createElement("span", { className: css.content__icon }, seatIcon),
                React.createElement("span", { className: css.header__text },
                    segment.segment.departure.airport.city.name,
                    " (",
                    segment.segment.departure.airport.iata,
                    ") - ",
                    segment.segment.arrival.airport.city.name,
                    " (",
                    segment.segment.arrival.airport.iata,
                    ")")),
            React.createElement("div", null, passengers.map(function (passenger) {
                var _a;
                var seat = (_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.find(function (seat) { return seat.segmentId === segment.segment.id; });
                return (React.createElement("div", { key: passenger.id, className: css.passenger },
                    React.createElement("span", null, passengerNames[passenger.id]),
                    React.createElement("span", { className: css.passenger__seat }, (seat === null || seat === void 0 ? void 0 : seat.number) || t('MobileStepbar:Not selected'))));
            })))); })),
        React.createElement("div", { className: css.footer },
            React.createElement("span", { onClick: onChange }, t('MobileStepbar:Change')))));
};
export default SeatsStep;
