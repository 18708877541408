import { __assign, __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import CloseIcon from '../../../CloseIcon/components/CloseIcon';
import { useTheme } from '../../../theme';
var CloseButton = function (props) {
    var css = useTheme('Modal').CloseButton;
    var onClick = props.onClick, className = props.className, closeIconProps = __rest(props, ["onClick", "className"]);
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        React.createElement(CloseIcon, __assign({ onClick: onClick }, closeIconProps))));
};
export default CloseButton;
