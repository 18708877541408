import * as React from 'react';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { FareFamilyOptionsType, FareFeaturePaymentType, FlightClass } from '../../__generated__/graphql';
import { useTheme } from '../../theme';
import Link from '../../Link';
import * as Icons from '../../Icons';
import { useToggleable } from '../../hooks';
import CompareFares from '../../CompareFares/components/CompareFares';
import Modal from '../../Modal';
import FareGroup from '../../FareGroup';
import { TABLET_MIN_WIDTH } from '../../utils';
import FareConditions from '../../FareConditions/components/Component';
import CloseIcon from '../../CloseIcon/components/CloseIcon';
var iconsMap = new Map([
    [FareFamilyOptionsType.CarryOn, Icons.Case],
    [FareFamilyOptionsType.Baggage, Icons.CheckedBaggage],
    [FareFamilyOptionsType.SeatRegistration, Icons.SeatSelection],
    [FareFamilyOptionsType.Meal, Icons.Meal],
    [FareFamilyOptionsType.Refundable, Icons.Refundable],
    [FareFamilyOptionsType.Exchangeable, Icons.Exchangeable]
]);
var seatImages = new Map([
    [FlightClass.Economy, 'https://cdn.websky.aero/content/ZZ/images/Economy.png'],
    [FlightClass.Business, 'https://cdn.websky.aero/content/ZZ/images/Business.png']
]);
var SelectedFare = function (props) {
    var theme = useTheme('SelectedFare').SelectedFare;
    var _a = useToggleable(false), open = _a.open, isOpen = _a.isOpen, close = _a.close;
    var _b = useToggleable(false), openFareRules = _b.open, isFareRulesOpen = _b.isOpen, closeFareRules = _b.close;
    var fare = props.selectedFare;
    var t = useTranslation('FlightInfoModalContent').t;
    var orderId = props.orderId, flightId = props.flightId;
    return (React.createElement("div", { className: cn(theme.selectedFare, props.className) },
        React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH },
            seatImages.has(fare.category) && (React.createElement("img", { className: theme.image, src: seatImages.get(fare.category), alt: fare.title })),
            React.createElement("div", { className: theme.content },
                React.createElement("h4", { className: theme.title },
                    fare.title,
                    React.createElement("div", { className: theme.controls },
                        fare.airline.iata && (React.createElement(React.Fragment, null,
                            React.createElement(Link, { className: theme.link, action: open }, t('Compare fares')))),
                        React.createElement(Link, { className: theme.link, action: openFareRules }, t('Fare rules')))),
                React.createElement("ul", { className: theme.features__list }, fare.options
                    .slice(0, 9)
                    .filter(function (option) { return iconsMap.has(option.type); })
                    .map(function (option) { return (React.createElement("li", { className: theme.features__item, key: option.title },
                    React.createElement("span", { className: theme.serviceIcon }, iconsMap.get(option.type)),
                    option.title,
                    " ",
                    option.value,
                    React.createElement("span", { className: theme.icon },
                        option.availability === FareFeaturePaymentType.Free && Icons.Included,
                        option.availability === FareFeaturePaymentType.NotAvailable &&
                            Icons.NotIncluded,
                        option.availability === FareFeaturePaymentType.Pay && Icons.Paid))); }))),
            React.createElement(Modal, { open: isOpen, onClose: close },
                React.createElement(CompareFares, { code: fare.airline.iata }))),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement(FareGroup, { className: theme.fareGroup, fare: props.selectedFare, price: null, isDisabled: false, variant: 'white', showUnavailableOptions: true })),
        React.createElement(Modal, { open: isFareRulesOpen, onClose: closeFareRules, closeButtonRenderer: function (onClick) { return React.createElement(CloseIcon, { onClick: onClick, className: theme.popup__close }); } },
            React.createElement(FareConditions, { flightId: flightId, orderId: orderId }))));
};
export default SelectedFare;
