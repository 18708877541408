import { __assign, __rest } from "tslib";
import * as React from 'react';
import FlightSelect from '../../FlightSelect/components/FlightSelect';
import FlightsListControls from '../FlightsListControls/FlightsListControls';
import LeoResults from '../Results/Results';
import Checkout from '../Checkout/Checkout';
import { AdditionalRoutes } from '../LoginPage/AdditionalRoutes';
import PaymentWrapper from '../Payment/Components/PaymentWrapper/PaymentWrapper';
import IndexPage from '../IndexPage/IndexPage';
import AdapterForLeoSelectedFlight from '../AdapterForLeoSelectedFlight/AdapterForLeoSelectedFlight';
import VoidPopup from '../Exchange/VoidPopup/VoidPopup';
import SearchFlightsHeader from '../Exchange/SearchFlightsHeader/SearchFlightsHeader';
import SearchFlights from '../Exchange/SearchFlights/SearchFlights';
import CreateRequest from '../Exchange/CreateRequest';
import RequestFrom from '../Exchange/RequestForm/RequestForm';
import Passengers from '../Exchange/Passengers/Passengers';
import Request from '../Exchange/Request/Request';
import Selections from '../Exchange/Selections/Selections';
import Disclaimer from '../../Results/components/Results/Disclaimer/Disclaimer';
import PaymentResultMessage from '../../Modules/Exchange/components/Exchange/PaymentResultMessage/PaymentResultMessage';
import { PaymentResultMethodType } from '../../Modules/Exchange/components/Exchange/PaymentResultMessage/PaymentResultMessage';
import RulesButton from '../FareGroup/RulesButton/RulesButton';
import Breadcrumbs from '../../Refund/Components/Breadcrumbs/Breadcrumbs';
import Account from '../Account/Account';
import TravellerForm from '../Account/TravellerForm/TravellerForm';
import PassengerControls from '../PassengerControls/PassengerControls';
import { withRouter } from 'react-router-dom';
import ExchangeRoutes from '../ExchangeRoutes/ExchangeRoutes';
import RefundAdditionalRoutes from '../RefundAdditionalRoutes/RefundAdditionalRoutes';
import ManualLink from '../ManualLink/ManualLink';
import Payment from '../Exchange/Payment/Payment';
import Page from '../Page/Page';
import DownloadDocumentsWrapper from '../DownloadDocuments/DownloadDocumentsWrapper';
import WrapperModal from '../Refund/WrapperModal/WrapperModal';
import Contacts from '../Checkout/Contacts/Contacts';
import OrderStatus from '../OrderCard/components/OrderStatus/OrderStatus';
import OrderBooking from '../OrderCard/components/OrderBooking/OrderBooking';
import PassengersModal from '../OrderCard/components/OrderPassengers/PassengersModal/PassengersModal';
import PaymentStatus from '../OrderCard/components/PaymentStatus/PaymentStatus';
import OrderControls from '../OrderCard/components/OrderControls/OrderControls';
import OrderPassengers from '../OrderCard/components/OrderPassengers/OrderPassengers';
import CloseIcon from '../CloseIcon/CloseIcon';
import ExchangeServiceCard from '../Exchange/ExchangeServiceCard/ExchangeServiceCard';
import SegmentTabs from '../SeatMap/SegmentTabs/SegmentTabs';
export var leoRenderers = {
    ResultsFlightListFlight: FlightSelect,
    ResultsFlightListSortings: function () { return null; },
    ResultsRenderControls: FlightsListControls,
    Results: LeoResults,
    CheckoutWrapperRenderer: Checkout,
    renderInnerRoutes: AdditionalRoutes,
    InnerSearchFormPage: IndexPage,
    CheckoutPaymentWrapper: PaymentWrapper,
    ExchangeAdditionalRoutes: ExchangeRoutes,
    RefundAdditionalRoutes: RefundAdditionalRoutes,
    ExchangeHeaderLinks: ManualLink,
    RefundHeaderLink: ManualLink,
    SelectedFare: AdapterForLeoSelectedFlight,
    FareGroupGridRulesButton: RulesButton,
    PassengersFlightListFlight: FlightSelect,
    CheckoutGoToExchangeButton: VoidPopup,
    ExchangeSearchFlightsHeader: SearchFlightsHeader,
    ExchangeFlight: SearchFlights,
    ExchangePayment: Payment,
    ExchangeCreateRequest: CreateRequest,
    ExchangeRequestForm: RequestFrom,
    ExchangePassengersCommonSelect: Passengers,
    PassengersToolbarCheckbox: function () { return null; },
    ThanksBanner: function () { return null; },
    PaymentResultMessage: function (props) { return (React.createElement(PaymentResultMessage, __assign({}, props, { paymentResultMethodType: PaymentResultMethodType.Snackbar }))); },
    ExchangeRequest: Request,
    ExchangeSelections: Selections,
    PassengerControls: PassengerControls,
    ExchangeSearchFlightBottom: function () { return React.createElement(Disclaimer, { type: "time" }); },
    RefundDesktopBreadcrumbs: function (props) {
        var renderAfter = props.renderAfter, withoutRenderAfter = __rest(props, ["renderAfter"]);
        return React.createElement(Breadcrumbs, __assign({}, withoutRenderAfter));
    },
    Account: withRouter(Account),
    PersonalPageTravellerForm: TravellerForm,
    renderStepsButtons: function () { return null; },
    SearchForm: function (props) { return React.createElement(Page, { onSubmit: props.onSubmit, isOpen: true }); },
    DownloadDocumentsWrapper: DownloadDocumentsWrapper,
    CheckoutContactsForm: Contacts,
    OrderCardStatus: OrderStatus,
    OrderCardBooking: OrderBooking,
    OrderCardPassengerModal: PassengersModal,
    OrderCardPaymentStatus: PaymentStatus,
    OrderCardControls: OrderControls,
    OrderCardPassengers: OrderPassengers,
    WrapperModal: WrapperModal,
    renderAdditionalServiceCloseButton: CloseIcon,
    ExchangeServiceCard: ExchangeServiceCard,
    SeatMapSegmentTabs: SegmentTabs
};
