import { __assign, __read } from "tslib";
import { useQuery } from '@apollo/react-hooks';
import { addMonths, differenceInCalendarDays, endOfMonth, format, getDate, getDaysInMonth, parse, parseISO } from 'date-fns';
import { AviaPassengerType, PassTypeAgeCategoryType } from '../__generated__/globalTypes';
import { PassengerType } from '../enums';
import { getDateWithoutTimezone } from '../Engine/components/FastSearch/utils';
import GetAccountInfoGraphql from '../__queries__/Account.graphql';
import { getCurrency } from '../cache';
export var SPECIAL_CATEGORIES = [PassengerType.YoungAdult, PassengerType.RetireeF, PassengerType.RetireeM];
export var setFieldDataMutator = function (args, state) {
    var _a = __read(args, 2), name = _a[0], data = _a[1];
    var field = state.fields[name];
    if (field) {
        field.data = __assign(__assign({}, field.data), data);
    }
};
export var triggerValidation = function (args, state, _a) {
    var changeValue = _a.changeValue;
    Object.keys(state.fields).forEach(function (field) {
        changeValue(state, field, function (value) { return value; });
    });
    if (!state.formState.valid) {
        state.formState.submitFailed = true;
    }
};
export var resetSubmitForm = function (args, state) {
    state.formState.submitFailed = false;
};
export var convertPassengersSearchParameters = function (passengers) {
    var result = {};
    passengers.forEach(function (passenger) {
        if (passenger.extendedPassengerType) {
            result[passenger.extendedPassengerType] = {
                count: passenger.count,
                age: null
            };
            if (passenger.count > 0 &&
                !passengers.find(function (otherPassenger) {
                    return otherPassenger.passengerType === passenger.passengerType &&
                        !otherPassenger.extendedPassengerType;
                })) {
                result[passenger.passengerType] = {
                    count: 0,
                    age: null
                };
            }
        }
        else {
            result[passenger.passengerType] = {
                count: passenger.count,
                age: null
            };
        }
    });
    return result;
};
export var convertSegmentsSearchParameters = function (segments) {
    var simpleRouteSegment = {
        departure: null,
        arrival: null,
        date: null,
        dateBack: null
    };
    var isRT = segments.length === 2 &&
        segments[0].arrival.iata === segments[1].departure.iata &&
        segments[0].departure.iata === segments[1].arrival.iata;
    var isMultiCity = segments.length > 1 && !isRT;
    if (!isMultiCity) {
        simpleRouteSegment.departure = segments[0].departure;
        simpleRouteSegment.arrival = segments[0].arrival;
        simpleRouteSegment.date = getDateWithoutTimezone(new Date(segments[0].date));
        simpleRouteSegment.dateBack = null;
        if (isRT) {
            simpleRouteSegment.dateBack = getDateWithoutTimezone(new Date(segments[1].date));
        }
    }
    return {
        isMultiCity: isMultiCity,
        simpleRouteSegment: simpleRouteSegment,
        multiCitySegments: isMultiCity
            ? segments.map(function (segment) { return ({
                departure: segment.departure,
                arrival: segment.arrival,
                date: getDateWithoutTimezone(new Date(segment.date)),
                dateBack: null
            }); })
            : []
    };
};
export var createSearchParameters = function (passengersState, segmentsState, promoCode, redeemMiles, passengerTypes) {
    var _a;
    var passengers = [];
    var _loop_1 = function (passengerType) {
        var isSpecialType = SPECIAL_CATEGORIES.some(function (type) { return type === passengerType; });
        if (passengersState.hasOwnProperty(passengerType) && ((_a = passengersState[passengerType]) === null || _a === void 0 ? void 0 : _a.count)) {
            if (Object.values(PassengerType).includes(passengerType)) {
                passengers.push({
                    passengerType: !isSpecialType ? passengerType : AviaPassengerType.ADT,
                    extendedPassengerType: isSpecialType ? passengerType : null,
                    count: passengersState[passengerType].count
                });
            }
            else {
                var typeInfo = passengerTypes.find(function (type) { return type.code === passengerType; });
                passengers.push({
                    passengerType: ageCategoryToPassengerTypeAdapter(typeInfo.ageCategory),
                    count: passengersState[passengerType].count,
                    extendedPassengerType: passengerType
                });
            }
        }
    };
    for (var passengerType in passengersState) {
        _loop_1(passengerType);
    }
    console.log(getCurrency());
    return {
        promotionCode: promoCode,
        currency: getCurrency(),
        ffpMode: redeemMiles,
        segments: createFlightRoutes(segmentsState.isMultiCity ? segmentsState.multiCitySegments : [segmentsState.simpleRouteSegment]).map(function (segment) {
            var date;
            try {
                date = format(segment.date, 'yyyy-MM-dd');
            }
            catch (e) { }
            return {
                date: date !== null && date !== void 0 ? date : null,
                departure: {
                    iata: segment.departure ? segment.departure.iata : ''
                },
                arrival: {
                    iata: segment.arrival ? segment.arrival.iata : ''
                }
            };
        }),
        passengers: passengers
    };
};
export var createFlightRoutes = function (segments) {
    var newSegments = [];
    segments.forEach(function (segment) {
        newSegments.push({
            departure: segment.departure,
            arrival: segment.arrival,
            date: segment.date
        });
        if (segment.dateBack) {
            newSegments.push({
                departure: segment.arrival,
                arrival: segment.departure,
                date: segment.dateBack
            });
        }
    });
    return newSegments;
};
export var daysLeftBeforeMonthEnd = function (from) {
    return getDaysInMonth(from) - getDate(from) + 1;
};
export var passengerTypeToAviaPassengerTypeAdapter = function (passenger) {
    var type;
    var extendedPassengerType = null;
    switch (passenger.type) {
        case PassengerType.Adult:
            type = AviaPassengerType.ADT;
            break;
        case PassengerType.Child:
            type = AviaPassengerType.CLD;
            break;
        case PassengerType.Infant:
            type = AviaPassengerType.INF;
            break;
        case PassengerType.InfantWithSeat:
            type = AviaPassengerType.INS;
            break;
        case PassengerType.YoungAdult:
            extendedPassengerType = PassengerType.YoungAdult;
            type = AviaPassengerType.ADT;
            break;
        case PassengerType.RetireeM:
            extendedPassengerType = PassengerType.RetireeM;
            type = AviaPassengerType.ADT;
            break;
        case PassengerType.RetireeF:
            extendedPassengerType = PassengerType.RetireeF;
            type = AviaPassengerType.ADT;
            break;
        default:
            type = passenger.type;
            break;
    }
    return {
        passengerType: type,
        extendedPassengerType: extendedPassengerType,
        count: passenger.count
    };
};
export var ageCategoryToPassengerTypeAdapter = function (ageCategory) {
    switch (ageCategory) {
        case PassTypeAgeCategoryType.Adult:
            return AviaPassengerType.ADT;
        case PassTypeAgeCategoryType.Child:
            return AviaPassengerType.CLD;
        case PassTypeAgeCategoryType.Infant:
            return AviaPassengerType.INF;
        default:
            return ageCategory;
    }
};
export var getMinPricesRequestVariables = function (locations, passengers, date, replaceLocations) {
    if (replaceLocations === void 0) { replaceLocations = false; }
    var segments;
    if (replaceLocations) {
        segments = {
            date: format(new Date(), 'yyyy-MM-dd'),
            departure: { iata: locations.arrival && locations.arrival.iata },
            arrival: { iata: locations.departure && locations.departure.iata }
        };
    }
    else {
        segments = {
            date: format(new Date(), 'yyyy-MM-dd'),
            departure: { iata: locations.departure && locations.departure.iata },
            arrival: { iata: locations.arrival && locations.arrival.iata }
        };
    }
    return {
        passengers: passengers
            .map(passengerTypeToAviaPassengerTypeAdapter)
            .filter(function (passenger) { return passenger.count !== null; }),
        daysCount: differenceInCalendarDays(endOfMonth(addMonths(new Date(), 12)), new Date()),
        segments: [segments]
    };
};
export var getPrices = function (flightsMinPricesResponse) {
    var pricesObject = {};
    if (flightsMinPricesResponse && flightsMinPricesResponse.FlightsMinPricesInPeriod) {
        flightsMinPricesResponse.FlightsMinPricesInPeriod.datesWithLowestPrices.forEach(function (minPrice) {
            if (minPrice) {
                pricesObject[parse(minPrice.date, 'yyyy-MM-dd', new Date()).toISOString()] = __assign({}, minPrice.price);
            }
        });
    }
    return pricesObject;
};
export var createFastSearchURL = function (parameters) {
    var result = '';
    parameters.segments.forEach(function (segment) {
        result += "".concat(segment.departure.iata).concat(segment.arrival.iata).concat(format(parseISO(segment.date), 'yyyyMMdd'));
    });
    parameters.passengers.forEach(function (_a) {
        var passengerType = _a.passengerType, count = _a.count;
        if (count) {
            result += "".concat(passengerType).concat(count);
        }
    });
    if (parameters.promotionCode) {
        result += "PROMO".concat(parameters.promotionCode);
    }
    return result;
};
export var useAccountInfo = function (skip) {
    var _a = useQuery(GetAccountInfoGraphql.GetAccountInfo, { skip: skip }), currentUser = _a.data, loading = _a.loading, refetch = _a.refetch;
    return {
        currentUser: currentUser,
        isLoading: loading,
        refetch: refetch
    };
};
