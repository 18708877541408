import { __read } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { useCreateRefundOrder } from '../Refund/hooks';
import PromoLoader from '../../../PromoLoader';
import { useTheme } from '../../../theme';
var CreateTicket = function (props) {
    var params = props.params;
    var css = useTheme('Refund').Wrapper;
    var _a = __read(useCreateRefundOrder(), 2), createRefund = _a[0], _b = _a[1], loading = _b.loading, data = _b.data, error = _b.error;
    useEffect(function () {
        createRefund({ variables: { params: params } });
    }, []);
    useEffect(function () {
        var _a;
        if (((_a = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) && props.onError) {
            props.onError(error.graphQLErrors.map(function (gqlError) { return new Error(gqlError.message); }));
        }
    }, [error === null || error === void 0 ? void 0 : error.graphQLErrors]);
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.CreateRefund) {
            props.onCreate(data.CreateRefund);
        }
    }, [data === null || data === void 0 ? void 0 : data.CreateRefund]);
    if (!(data === null || data === void 0 ? void 0 : data.CreateRefund) || loading) {
        return React.createElement(PromoLoader, { className: css.promoLoader });
    }
    return null;
};
export default CreateTicket;
