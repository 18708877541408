import { __read } from "tslib";
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Datepicker } from '../../../../../Datepicker';
import Day from './Day/Day';
import { API_DATE_FORMAT, format } from '../../../../../utils';
import { ThemeProvider } from '../../../../../theme';
import { useDatepickerStyles } from './hooks';
import { parse } from 'date-fns';
import { ChevronRightIcon } from '../../../../../AircraftPopup/components/Icons';
var DEPARTURE_TIME_FORMAT = 'HH:mm';
var ScheduleCalendar = function (props) {
    var _a;
    var datepickerTheme = useDatepickerStyles();
    var currentLeg = props.currentLeg, onSelect = props.onSelect, openDate = props.openDate;
    var legsByDates = props.legsByDates, datesByFlightNumber = props.datesByFlightNumber;
    var _b = __read(useState(null), 2), highlightedFlight = _b[0], setHighlightedFlight = _b[1];
    var highlightedDates = useMemo(function () { var _a, _b; return (_b = (_a = datesByFlightNumber.get(highlightedFlight)) === null || _a === void 0 ? void 0 : _a.map(function (date) { return parse(date, API_DATE_FORMAT, new Date()); })) !== null && _b !== void 0 ? _b : []; }, [datesByFlightNumber, highlightedFlight]);
    var renderDay = useCallback(function (date, onSelect) {
        var _a, _b, _c;
        return (React.createElement(Day, { highlightedFlight: highlightedFlight, onFlightHover: setHighlightedFlight, date: date, onSelect: onSelect, legsToday: (_c = (_b = (_a = props.legsByDates
                .get(format(date, API_DATE_FORMAT))) === null || _a === void 0 ? void 0 : _a.map(function (legsIndex) {
                // TODO: memo
                return props.schedule.flightDirections[currentLeg].legs[legsIndex];
            })) === null || _b === void 0 ? void 0 : _b.sort(function (firstLeg, secondLeg) {
                var firstDate = parse(firstLeg.segments[0].segment.departure.time, DEPARTURE_TIME_FORMAT, new Date());
                var secondDate = parse(secondLeg.segments[0].segment.departure.time, DEPARTURE_TIME_FORMAT, new Date());
                return firstDate.getTime() - secondDate.getTime();
            })) !== null && _c !== void 0 ? _c : [] }));
    }, [highlightedFlight, setHighlightedFlight, legsByDates, datesByFlightNumber, currentLeg]);
    return (React.createElement(ThemeProvider, { value: datepickerTheme },
        React.createElement(Datepicker, { navButtons: [React.createElement("div", null, ChevronRightIcon), React.createElement("div", null, ChevronRightIcon)], openDate: openDate, dayRenderer: renderDay, minDate: new Date(), onSelect: onSelect, highlightedDates: (_a = {}, _a[datepickerTheme.Datepicker.DayStyles.day_highlighted] = highlightedDates, _a), setSelectedDate: props.setSelectedDate })));
};
export default ScheduleCalendar;
