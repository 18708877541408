import { __read } from "tslib";
import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { getExchangeType } from '../../../store/exchangeType/selectors';
import SearchResultsAdapter from '../SearchResultsAdapter/SearchResultsAdapter';
import { getFlightsToExchange, getNewFlightSearchParams, getSelectedPassengers } from '../../../store/selectors';
import { addFare, clearFare, setLeg } from '../../../store/newFlights/actions';
import SearchFlightsComponent from '../SearchFlights/SearchFlights';
import { ExchangeStep } from '../Inner/Inner';
import { setFareFamily } from '../../../store/fareFamily/actions';
import { getOrder } from '../../../store/order/selectors';
import { exchangeVariantPriceInfoConverter, searchParamsConverter } from '../utils';
import { getSelectedReason } from '../../../store/reason/selectors';
import { Reasons } from '../../../store/types';
import { useIsTerminal } from '../../../../../hooks';
var SearchFlights = function (props) {
    var exchangeType = useSelector(getExchangeType);
    var searchParams = useSelector(getNewFlightSearchParams);
    var order = useSelector(getOrder);
    var passengers = useSelector(getSelectedPassengers);
    var flightToExchange = useSelector(getFlightsToExchange);
    var selectedReason = useSelector(getSelectedReason);
    var isTerminal = useIsTerminal();
    var _a = __read(React.useState(searchParams), 2), searchParamsState = _a[0], setSearchParams = _a[1];
    React.useEffect(function () {
        setSearchParams(searchParams);
    }, [searchParams]);
    var onSelectFare = function (flight, leg, legsCount) {
        props.addFare(flight);
        if (leg === legsCount - 1) {
            props.setStep(ExchangeStep.Request);
        }
    };
    var exchangeParams = React.useMemo(function () {
        var params = {
            orderId: order.id,
            passengers: passengers.map(function (passenger) { return parseInt(passenger.id); }),
            segmentParams: flightToExchange.flightsByIds.map(function (flight, key) { return ({
                segmentGroupId: flight.groupId,
                date: searchParamsState.segments[key].date
            }); })
        };
        if (isTerminal) {
            params.isCompelled = selectedReason === Reasons.Unvoluntary;
        }
        return params;
    }, [order, passengers, flightToExchange, searchParamsState]);
    return (React.createElement(SearchResultsAdapter, { exchangeType: exchangeType, exchangeParams: exchangeParams, onSelect: onSelectFare, setSearchParams: setSearchParams, setLoading: props.setLoading, clearFares: props.clearFares, setLeg: props.setLeg, onError: props.onError }, function (data, onSelect) {
        var _a, _b, _c, _d, _e, _f;
        return (React.createElement(SearchFlightsComponent, { legs: (_b = (_a = data.results) === null || _a === void 0 ? void 0 : _a.GetExchangeVariants) === null || _b === void 0 ? void 0 : _b.flightDirections[data.currentLeg].legs, loading: data.loading, currentLeg: data.currentLeg, onFareSelect: onSelect, searchParams: searchParamsState, startNewSearch: function (searchParameters) {
                setSearchParams(searchParameters);
            }, resultsSearchParams: searchParamsConverter((_d = (_c = data.results) === null || _c === void 0 ? void 0 : _c.GetExchangeVariants) === null || _d === void 0 ? void 0 : _d.searchParameters), prices: exchangeVariantPriceInfoConverter((_f = (_e = data.results) === null || _e === void 0 ? void 0 : _e.GetExchangeVariants) === null || _f === void 0 ? void 0 : _f.exchangeVariantPriceInfo), clearFares: data.clearFares, setLeg: data.setLeg }));
    }));
};
export default connect(null, {
    addFare: addFare,
    setFareFamily: setFareFamily,
    clearFares: clearFare,
    setLeg: setLeg
})(SearchFlights);
