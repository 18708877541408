import * as React from 'react';
import BaggagePassenger from '../../BaggagePassenger/BaggagePassenger';
import { useTheme } from '../../../../../theme';
var AllFlight = function (props) {
    var commonTheme = useTheme('UpgradeBaggageDialog').UpgradeBaggageDialog;
    return (React.createElement("div", { className: commonTheme.body }, props.passengers.map(function (passenger, i) {
        var _a, _b;
        var label = props.passengerLabels[passenger.id];
        var number = i + 1;
        return (React.createElement(BaggagePassenger, { forAllSegments: true, passengerId: passenger.id, name: label, number: number, key: passenger.id, includedSegment: true, baggageByRfics: props.upgradeBaggages, mobileSelectedServiceId: props.mobileSelectedServiceId, selectedServices: ((_b = (_a = passenger.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) || [] }));
    })));
};
export default AllFlight;
