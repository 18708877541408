import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { addFlightIds, removeFlightIds } from '../../../store/flightsToExchange/actions';
import { connect, useSelector } from 'react-redux';
import { getSegments } from '../../../store/order/selectors';
import { getFlightIdsToExchange } from '../../../store/flightsToExchange/selectors';
import { ExchangeStep } from '../Inner/Inner';
import CommonSelect from '../CommonSelect/CommonSelect';
import { flightIcon } from '../icons';
import { useTranslation } from 'react-i18next';
import { ALL_FLIGHT } from '../../../store/flightsToExchange/reducers';
import { format } from 'date-fns';
import { API_DATE_FORMAT } from '../../../../../utils';
import { isAfter } from 'date-fns';
import { useIsTerminal } from '../../../../../hooks';
var Segments = function (props) {
    var segments = useSelector(getSegments);
    var selectedSegments = useSelector(getFlightIdsToExchange);
    var isTerminal = useIsTerminal();
    var t = useTranslation('Exchange').t;
    var items = React.useMemo(function () {
        var items = [];
        if (segments.length > 1) {
            items.push({
                key: ALL_FLIGHT,
                value: t('Whole flight')
            });
        }
        segments.forEach(function (segment) {
            var departureAirport = segment.segments[0].departure.airport;
            var arrivalAirport = segment.segments[segment.segments.length - 1].arrival.airport;
            var segmentTitle = isTerminal
                ? "".concat(departureAirport.iata, " - ").concat(arrivalAirport.iata)
                : "".concat(departureAirport.city.name, " - ").concat(arrivalAirport.city.name);
            items.push({
                key: segment.groupId,
                value: segmentTitle,
                date: new Date(segment.segments[0].departure.date)
            });
        });
        return items;
    }, [segments]);
    var onChangeHandler = function (value, selected) {
        var isAllFlight = value.key === 'ALL_FLIGHT';
        if (selected) {
            if (isAllFlight) {
                if (value.value) {
                    var flights_1 = new Map();
                    selectedSegments.map(function (segment) {
                        if (segment.key === value.value.key) {
                            flights_1.set(segment.key, { key: segment.key, date: value.value.date });
                        }
                        else {
                            flights_1.set(segment.key, segment);
                        }
                    });
                    var flightDates = __spreadArray([], __read(flights_1.values()), false).filter(function (_a) {
                        var key = _a.key;
                        return key !== ALL_FLIGHT;
                    });
                    if (isAfter(new Date(flightDates[0].date), new Date(flightDates[1].date))) {
                        var key = flightDates[1].key;
                        flights_1.set(key, __assign(__assign({}, flights_1.get(key)), { date: flightDates[0].date }));
                    }
                    props.addFlightIds(__spreadArray([], __read(flights_1.values()), false));
                }
                else {
                    var flights = items.map(function (flight) { return ({
                        key: flight.key,
                        date: (flight === null || flight === void 0 ? void 0 : flight.date) ? format(flight === null || flight === void 0 ? void 0 : flight.date, API_DATE_FORMAT) : null
                    }); });
                    props.addFlightIds(flights);
                }
            }
            else {
                props.addFlightIds([value]);
            }
        }
        else {
            if (isAllFlight) {
                var flights = selectedSegments.map(function (flight) { return ({
                    key: flight.key,
                    date: flight === null || flight === void 0 ? void 0 : flight.date
                }); });
                props.removeFlightIds(flights);
            }
            else {
                var selectedSegmentsKeys = selectedSegments.map(function (_a) {
                    var key = _a.key;
                    return key;
                });
                if (selectedSegmentsKeys.includes('ALL_FLIGHT')) {
                    props.addFlightIds([value]);
                }
                else {
                    props.removeFlightIds([value]);
                }
            }
        }
    };
    var onNextHandler = function () {
        props.setStep(ExchangeStep.NewFlights);
    };
    React.useEffect(function () {
        if (segments.length === 1) {
            onChangeHandler({ key: 'ALL_FLIGHT' }, true);
        }
    }, [segments]);
    return (React.createElement(CommonSelect, { name: t('Select flights'), items: items, onChange: onChangeHandler, onNextHandler: onNextHandler, selectedKeys: selectedSegments, icon: flightIcon }));
};
export default connect(null, {
    addFlightIds: addFlightIds,
    removeFlightIds: removeFlightIds
})(Segments);
