import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTheme } from '../theme';
import { minusIcon, plusIcon } from './Icons';
var Counter = function (props) {
    var _a, _b, _c;
    var _d, _e, _f, _g;
    var theme = useTheme('Counter').Counter;
    var _h = __read(useState(0), 2), antiBlinkingValue = _h[0], setAntiBlinkingValue = _h[1];
    var canInc = React.useMemo(function () {
        return (props.maxValue === undefined || props.value < props.maxValue) && !props.readonly;
    }, [props.value, props.maxValue, props.readonly]);
    var canDec = React.useMemo(function () {
        return (props.value > 0 && props.minValue === undefined) || props.value > props.minValue;
    }, [props.value, props.minValue]);
    useEffect(function () {
        if (typeof props.value !== 'undefined' && antiBlinkingValue !== props.value) {
            setAntiBlinkingValue(props.value);
        }
    }, [props.value]);
    return (React.createElement("div", { className: cn(theme.counter, (_d = props.classes) === null || _d === void 0 ? void 0 : _d.root) },
        React.createElement("button", { type: "button", className: cn(theme.button, (_e = props.classes) === null || _e === void 0 ? void 0 : _e.minus, (_a = {},
                _a[theme.button_disabled] = !canDec,
                _a[theme.button_readonly] = props.readonly || (props.showOnlyIncreaseButtonWhenValueIsNull && props.value === 0),
                _a)), onClick: canDec ? props.onDecrease : function () { } }, minusIcon),
        React.createElement("div", { className: cn(theme.value, props.valueClassName, (_f = props.classes) === null || _f === void 0 ? void 0 : _f.value, (_b = {},
                _b[theme.value_zero] = props.value === 0,
                _b[theme.value_hidden] = props.showOnlyIncreaseButtonWhenValueIsNull && props.value === 0,
                _b)) }, antiBlinkingValue),
        React.createElement("button", { type: "button", className: cn(theme.button, (_g = props.classes) === null || _g === void 0 ? void 0 : _g.plus, (_c = {},
                _c[theme.button_disabled] = !canInc,
                _c[theme.button_readonly] = props.readonly,
                _c)), onClick: canInc ? props.onIncrease : function () { } }, plusIcon)));
};
export default Counter;
