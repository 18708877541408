import { __read } from "tslib";
import * as React from 'react';
import Input from '../../../Input';
import ServicesList from '../ServicesList/ServicesList';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { CloseClear } from '../../../Icons';
var searchIcon = (React.createElement("svg", { width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.71 14.7666H15.5L20.49 19.7666L19 21.2566L14 16.2666V15.4766L13.73 15.1966C12.59 16.1766 11.11 16.7666 9.5 16.7666C5.91 16.7666 3 13.8566 3 10.2666C3 6.6766 5.91 3.7666 9.5 3.7666C13.09 3.7666 16 6.6766 16 10.2666C16 11.8766 15.41 13.3566 14.43 14.4966L14.71 14.7666ZM5 10.2666C5 12.7566 7.01 14.7666 9.5 14.7666C11.99 14.7666 14 12.7566 14 10.2666C14 7.7766 11.99 5.7666 9.5 5.7666C7.01 5.7666 5 7.7766 5 10.2666Z", fill: "#808080" })));
var ServicesContainer = function (_a) {
    var services = _a.services, setService = _a.setService, onClear = _a.onClear, segments = _a.segments, travellersServices = _a.travellersServices;
    var _b = __read(React.useState(''), 2), searchText = _b[0], setSearchText = _b[1];
    var theme = useTheme('ExtraServices').ServicesContainer;
    var t = useTranslation('ExtraServices').t;
    var onChangeHandler = function (e) {
        var _a;
        setSearchText((_a = e.target.value) === null || _a === void 0 ? void 0 : _a.toLowerCase());
    };
    var onClearSearch = function () { return setSearchText(''); };
    var filteredServices = React.useMemo(function () {
        return services.filter(function (service) {
            return (!searchText ||
                service.service.name.toLowerCase().includes(searchText) ||
                service.service.rfisc.toLowerCase().includes(searchText));
        });
    }, [searchText, services]);
    return (React.createElement("div", { className: theme.container },
        React.createElement("div", { className: theme.search },
            React.createElement(Input, { variant: 'outlined', className: theme.search__input, placeholder: t('Search service'), startAdornment: React.createElement("div", { className: theme.adornment }, searchIcon), endAdornment: searchText.length > 0 && (React.createElement("div", { className: theme.clear, onClick: onClearSearch }, CloseClear)), TextFieldProps: {
                    onChange: onChangeHandler,
                    value: searchText
                }, inputClassName: theme.search__input_inner })),
        React.createElement("div", null,
            React.createElement("div", { className: theme.list },
                React.createElement(ServicesList, { services: filteredServices, setService: setService, onClear: onClear, segments: segments, travellersServices: travellersServices })))));
};
export default ServicesContainer;
