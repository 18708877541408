import * as React from 'react';
import Counter from '../../../Counter/Counter';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import { MAX_COUNTER_VALUE_FREE, MIN_COUNTER_VALUE } from '../../utils';
var BaggageCounter = React.memo(function (props) {
    var _a;
    var theme = useTheme('BaggageService').BaggageServiceStyles;
    var baggage = props.selectedBaggage.hasOwnProperty(props.service.id) &&
        props.selectedBaggage[props.service.id].hasOwnProperty(props.segment.segmentId)
        ? props.selectedBaggage[props.service.id][props.segment.segmentId]
        : null;
    return (React.createElement("div", { className: cn(theme.service__counter, (_a = {},
            _a[theme.notSelected] = !baggage,
            _a)) },
        React.createElement(Counter, { minValue: (baggage === null || baggage === void 0 ? void 0 : baggage.confirmedCount) > 0 ? baggage.confirmedCount : MIN_COUNTER_VALUE, maxValue: typeof props.maxValue === 'number' ? props.maxValue : MAX_COUNTER_VALUE_FREE, readonly: (props.sameBaggageForEachFlight && parseInt(props.segment.segmentId) > 0) || props.readonly, valueClassName: props.sameBaggageForEachFlight &&
                parseInt(props.segment.segmentId) > 0 &&
                baggage &&
                baggage.count > 0
                ? theme.value_highlighted
                : '', value: baggage ? baggage.count : 0, onIncrease: function () {
                return props.onBaggageIncrease(props.segment.segmentId, props.service.id, props.sameBaggageForEachFlight);
            }, onDecrease: function () {
                return props.onBaggageDecrease(props.segment.segmentId, baggage ? baggage.serviceId : null, props.sameBaggageForEachFlight);
            } })));
});
export default BaggageCounter;
