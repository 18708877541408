import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { getSelectedFares } from '../../../store/newFlights/selectors';
import { ExareStatus } from '../../../../../__generated__/graphql';
import { setLastExchange } from '../../../store/order/actions';
import { isAutoMode } from '../../../store/order/selectors';
import { OverrideComponent } from '../../../../../renderProps';
import Inner from './Inner/Inner';
import { getOrder } from '../../../store/order/selectors';
import { getSelectedPassengerIds } from '../../../store/passengers/selectors';
var Request = function (_a) {
    var setLastExchange = _a.setLastExchange, goToPayment = _a.goToPayment, isNewRequest = _a.isNewRequest, goToFlights = _a.goToFlights, setExchangeSuccess = _a.setExchangeSuccess, setExchangeFail = _a.setExchangeFail, refreshOrder = _a.refreshOrder, goToNewRequest = _a.goToNewRequest;
    var selectedFlight = useSelector(getSelectedFares);
    var selectedPassengers = useSelector(getSelectedPassengerIds);
    var order = useSelector(getOrder);
    var autoMode = useSelector(isAutoMode);
    var priceToPay = selectedFlight[0].exarePrice.priceToPay;
    var setExchangeOrder = function (order, openRequest) {
        setLastExchange(order);
        if (order.status === ExareStatus.AwaitingPayment) {
            if (!openRequest) {
                goToPayment();
            }
        }
        else if (order.status === ExareStatus.Finished) {
            if (priceToPay.amount) {
                setExchangeSuccess();
            }
            else {
                refreshOrder();
            }
        }
        else {
            setExchangeFail();
        }
    };
    var prices = React.useMemo(function () {
        var _a, _b;
        if (autoMode) {
            return {
                priceToPay: priceToPay,
                charges: {
                    amount: ((_a = selectedFlight[0].exarePrice.agencyChargesForExare) === null || _a === void 0 ? void 0 : _a.amount) +
                        ((_b = selectedFlight[0].exarePrice.exchangeAirlinePenalty) === null || _b === void 0 ? void 0 : _b.amount),
                    currency: priceToPay.currency
                }
            };
        }
        return null;
    }, [autoMode, selectedFlight]);
    return (React.createElement(OverrideComponent, { componentProps: {
            prices: prices,
            selectedFlights: selectedFlight,
            goToFlights: goToFlights,
            goToPayment: goToPayment,
            setExchange: setExchangeOrder,
            isNewRequest: isNewRequest,
            order: order,
            selectedPassengers: selectedPassengers,
            goToNewRequest: goToNewRequest
        }, component: { ExchangeRequest: Inner } }));
};
export default connect(null, {
    setLastExchange: setLastExchange
})(Request);
