import { __assign, __read } from "tslib";
import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import cn from 'classnames';
import Fade from '@material-ui/core/Fade';
import { useTheme } from '../../theme';
import { closeIcon } from '../Icons';
var SlideUp = React.forwardRef(function (props, ref) { return (React.createElement(Slide, __assign({ direction: "up" }, props, { ref: ref, children: props.children }))); });
var FadeTransition = React.forwardRef(function (props, ref) { return (React.createElement(Fade, __assign({}, props, { ref: ref, children: props.children }))); });
var FullscreenDialog = function (_a) {
    var mountOnEnter = _a.mountOnEnter, isOpen = _a.isOpen, children = _a.children, header = _a.header, headerClassName = _a.headerClassName, closeClassName = _a.closeClassName, contentClassName = _a.contentClassName, fadeTransition = _a.fadeTransition, keepMounted = _a.keepMounted, onClose = _a.onClose, rootDialogClassName = _a.rootDialogClassName, withoutHeader = _a.withoutHeader;
    var theme = useTheme('FullscreenDialog').FullscreenDialog;
    var _b = __read(useState(!mountOnEnter), 2), dialogIsMounted = _b[0], setDialogIsMounted = _b[1];
    useEffect(function () {
        if (isOpen && !dialogIsMounted) {
            setDialogIsMounted(true);
        }
    }, [isOpen, dialogIsMounted]);
    return (React.createElement(Dialog, { open: isOpen, TransitionComponent: !fadeTransition ? SlideUp : FadeTransition, fullScreen: true, onClose: onClose, classes: {
            root: cn(theme.withoutPadding, rootDialogClassName)
        }, keepMounted: keepMounted },
        !withoutHeader && (React.createElement("div", { className: cn(theme.header, headerClassName) },
            header,
            React.createElement("div", { className: cn(theme.close, closeClassName), onClick: onClose }, closeIcon))),
        dialogIsMounted && React.createElement("div", { className: cn(theme.content, contentClassName) }, children)));
};
export default FullscreenDialog;
