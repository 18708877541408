import { __read } from "tslib";
import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import * as CreateExchangeRequest from '../../../../../__queries__/CreateExchange.graphql';
import { useSelector } from 'react-redux';
import { getOrder, isAutoMode } from '../../../store/order/selectors';
import { getFlightsToExchange, getSelectedPassengers } from '../../../store/selectors';
import { getNewFlightId, getSelectedFares } from '../../../store/newFlights/selectors';
import CreateRequest from './CreateRequest';
import { getSelectedFlightsInfo } from '../utils';
import { OverrideComponent } from '../../../../../renderProps';
import { getSelectedReason } from '../../../store/reason/selectors';
import { Reasons } from '../../../store/types';
import WarningModal from '../../../../../WarningModal';
import { useIsTerminal } from '../../../../../hooks';
var CreateRequestWrapper = function (props) {
    var _a;
    var _b = __read(useMutation(CreateExchangeRequest.CreateExchange), 1), createRequest = _b[0];
    var order = useSelector(getOrder);
    var passengers = useSelector(getSelectedPassengers);
    var flightToExchange = (_a = useSelector(getFlightsToExchange)) === null || _a === void 0 ? void 0 : _a.flightsByIds;
    var newFlightId = useSelector(getNewFlightId);
    var selectedFares = useSelector(getSelectedFares);
    var autoMode = useSelector(isAutoMode);
    var selectedReason = useSelector(getSelectedReason);
    var _c = __read(React.useState(false), 2), isSending = _c[0], setSending = _c[1];
    var _d = __read(React.useState(), 2), requestError = _d[0], setRequestError = _d[1];
    var isTerminal = useIsTerminal();
    var t = useTranslation('Exchange').t;
    var sendRequest = function (params, openRequest) {
        if (isTerminal && !flightToExchange.length) {
            return new Promise(function () { return false; });
        }
        setSending(true);
        var requestParameters = {
            orderId: order.id,
            passengers: passengers.map(function (passenger) { return parseInt(passenger.id); }),
            email: params['email'],
            phone: params['phone'],
            comment: "".concat(params['comment'] || '').concat(!autoMode ? ".\n".concat(getSelectedFlightsInfo(selectedFares)) : ''),
            name: params['name'],
            segmentGroupId: parseInt(flightToExchange[0].groupId),
            newFlightId: newFlightId
        };
        if (isTerminal) {
            requestParameters.isCompelled = selectedReason === Reasons.Unvoluntary;
        }
        return createRequest({
            variables: {
                params: requestParameters
            }
        })
            .then(function (data) {
            setSending(false);
            if (data.data.CreateExchange) {
                props.onOrderCreated(data.data.CreateExchange, openRequest);
                return true;
            }
            return false;
        })
            .catch(function (e) {
            setSending(false);
            setRequestError(e);
            return false;
        });
    };
    var clearCreateRequestError = function () {
        setRequestError(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(WarningModal, { title: t('Oops...'), content: '', errorMessage: requestError === null || requestError === void 0 ? void 0 : requestError.message, onClose: clearCreateRequestError, onButtonClick: clearCreateRequestError, buttonText: t('Close'), isOpen: !!requestError }),
        React.createElement(OverrideComponent, { componentProps: {
                isSending: isSending || props.isLoading,
                send: sendRequest,
                autoMode: autoMode,
                order: order,
                goToNewRequest: props.goToNewRequest
            }, component: {
                ExchangeCreateRequest: CreateRequest
            } })));
};
export default CreateRequestWrapper;
