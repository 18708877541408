import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Link from '../../../../../../Link';
import { infoIcon } from '../../../../../../Warnings/Icons';
import Service from '../../../../../../Service';
import { useConfig } from '../../../../../../context';
import { useTheme } from '../../../../../../theme';
import { getSelectedBaggage, makeHasAnySelectedBaggage } from '../../../../../store/passengers/selectors';
import { getBaggage, getBaggageMinPrice } from '../../../../../store/order/selectors';
import { Currency } from '../../../../../../enums';
import BaggageServiceWrapper from '../../../../../../BaggagePage/components/BaggageServiceWrapper/BaggageServiceWrapper';
import { getPassengerStateCompatible } from '../../../../../store/selectedServices/selectors';
import { useMemo } from 'react';
import { getBaggageReduxCallbacks } from '../../../../../utils';
var baggageIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.286 6.00012H9.51658C8.41118 6.00012 7.51491 6.93465 7.51514 8.08779V25.9872C7.51514 27.1403 8.41141 28.0751 9.51682 28.0751H9.78557V29.5906C9.78557 29.8692 10.0017 30.0952 10.2695 30.0952H12.5583C12.8252 30.0952 13.0422 29.8695 13.0422 29.5906V28.0751H20.2915V29.5906C20.2915 29.8692 20.5079 30.0952 20.7754 30.0952H23.0644C23.3313 30.0952 23.5481 29.8695 23.5481 29.5906V28.0751H23.8165C24.9217 28.0751 25.8182 27.1405 25.8182 25.9872V8.08767C25.8182 6.93477 24.9219 6 23.8165 6H22.0472V3.72408C22.0472 2.40597 21.0186 1.33334 19.7548 1.33334H13.5783C12.3142 1.33334 11.286 2.40597 11.286 3.72408V6.00012ZM20.5192 6H12.8145V3.72408C12.8145 3.28471 13.1574 2.92716 13.5786 2.92716H19.7551C20.1764 2.92716 20.5192 3.28471 20.5192 3.72408V6ZM10 11C10 10.4477 10.4477 10 11 10H22.3333C22.8856 10 23.3333 10.4477 23.3333 11C23.3333 11.5523 22.8856 12 22.3333 12H11C10.4477 12 10 11.5523 10 11ZM10 15C10 14.4477 10.4477 14 11 14H22.3333C22.8856 14 23.3333 14.4477 23.3333 15C23.3333 15.5523 22.8856 16 22.3333 16H11C10.4477 16 10 15.5523 10 15ZM11 18C10.4477 18 10 18.4477 10 19C10 19.5523 10.4477 20 11 20H22.3333C22.8856 20 23.3333 19.5523 23.3333 19C23.3333 18.4477 22.8856 18 22.3333 18H11ZM10 23C10 22.4477 10.4477 22 11 22H22.3333C22.8856 22 23.3333 22.4477 23.3333 23C23.3333 23.5523 22.8856 24 22.3333 24H11C10.4477 24 10 23.5523 10 23Z", fill: "white" })));
var BaggageServiceCard = function (props) {
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').Services;
    var companyInfo = useConfig().global.companyInfo;
    var hasProducts = useSelector(makeHasAnySelectedBaggage(parseInt(props.passengerId)));
    var minPrice = useSelector(getBaggageMinPrice);
    var passengers = useSelector(getPassengerStateCompatible);
    var selectedBaggages = useSelector(getSelectedBaggage);
    var allBaggage = useSelector(getBaggage);
    var selectedBaggage = selectedBaggages[props.passengerId];
    var passengerTotalPrice = {
        currency: Currency.RUB,
        amount: 0
    };
    var baggage = allBaggage.filter(function (_a) {
        var id = _a.id, price = _a.price, allowedPassengers = _a.allowedPassengers;
        // Calculate total price of selected baggage for given passenger.
        var segments = selectedBaggage[id];
        if (segments) {
            for (var segmentId in segments) {
                if (segments.hasOwnProperty(segmentId)) {
                    var selectedBaggage_1 = segments[segmentId];
                    passengerTotalPrice.amount += price.amount * selectedBaggage_1.count;
                    passengerTotalPrice.currency = price.currency;
                }
            }
        }
        // Check if this baggage is allowed for given passenger.
        return allowedPassengers.includes(props.passengerId);
    });
    var baggageCallbacks = useMemo(function () {
        return getBaggageReduxCallbacks(passengers, baggage, props.segments, props.addService, props.removeService, props.setService);
    }, [passengers, baggage, props.segments, props.addService, props.removeService, props.setService]);
    return (React.createElement(Service, { icon: baggageIcon, hasProducts: hasProducts, minPrice: minPrice, price: passengerTotalPrice, dialogControlsClassName: css.baggageService__controls, onCancel: function () { return props.onClear(props.passengerId); }, canAddProducts: true, rules: companyInfo.baggage && (React.createElement(Link, { className: css.baggageService__rules, target: "_blank", action: "".concat(companyInfo.baggage) },
            infoIcon,
            t('Baggage rules'))), label: t('Baggage'), dialogHeader: "".concat(t('Baggage'), ", ").concat(props.passengerName), className: props.className, onClick: props.onCardClick, isLoading: props.isLoading },
        React.createElement(BaggageServiceWrapper, __assign({ passengerId: props.passengerId, totalPrice: passengerTotalPrice, segments: props.segments, baggage: __spreadArray(__spreadArray([], __read(props.includedBaggage), false), __read(baggage), false), selectedBaggage: selectedBaggage, fullScreen: false, passengerName: props.passengerName, readonly: props.readonly, simpleBaggageSelector: props.simpleBaggageSelector }, baggageCallbacks))));
};
export default BaggageServiceCard;
