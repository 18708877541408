import { __generator } from "tslib";
import { put, takeLatest, call } from 'redux-saga/effects';
import { setOtherPassengerTypes, LOAD_OTHER_PASSENGER_TYPES } from './actions';
import { createClient } from '../../../graphql-client';
import { getLocale } from '../../../utils';
var PassengerTypeCodes = require('../../../__queries__/PassengerTypeCodes.graphql').PassengerTypeCodes;
function loadPassengersTypes() {
    var client = createClient();
    return client.query({
        query: PassengerTypeCodes
    });
}
export function loadOtherPassengerTypesSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(LOAD_OTHER_PASSENGER_TYPES, function () {
                    var types, currentLocale, codeWithNames;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, call(loadPassengersTypes)];
                            case 1:
                                types = _a.sent();
                                currentLocale = getLocale();
                                codeWithNames = types.data.PassengerTypeCodes.map(function (type) {
                                    var _a, _b, _c;
                                    return {
                                        code: (_a = type.code.find(function (codeItem) { return codeItem.lang === 'en'; })) === null || _a === void 0 ? void 0 : _a.value,
                                        name: ((_b = type.name.find(function (nameItem) { return nameItem.lang === currentLocale; })) === null || _b === void 0 ? void 0 : _b.value) ||
                                            ((_c = type.name.find(function (nameItem) { return nameItem.lang === 'en'; })) === null || _c === void 0 ? void 0 : _c.value),
                                        ageCategory: type.ageCategory
                                    };
                                });
                                return [4 /*yield*/, put(setOtherPassengerTypes(codeWithNames))];
                            case 2:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
