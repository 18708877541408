import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentStatusEnum } from '../../../../__generated__/graphql';
import { useTheme } from '../../../../theme';
import { useToggleable } from '../../../../hooks';
import Modal from '../../../../Modal';
import CloseIcon from '../../../../CloseIcon/components/CloseIcon';
import Transaction from './Transaction/Transaction';
import { isBefore, parseISO } from 'date-fns';
var DEFAULT_DATE_FORMAT = 'dd.LL.yyyy HH:mm';
var OrderTransactions = function (_a) {
    var transactionsList = _a.transactionsList, _b = _a.dateFormat, dateFormat = _b === void 0 ? DEFAULT_DATE_FORMAT : _b, transactionButtonRenderer = _a.transactionButtonRenderer;
    var theme = useTheme('LeoOrderCard').OrderTransactions;
    var t = useTranslation('OrderCard').t;
    var _c = useToggleable(false), isOpen = _c.isOpen, open = _c.open, close = _c.close;
    var onShowClick = function () {
        open();
    };
    var orderedTransactionsList = useMemo(function () {
        return transactionsList
            .filter(function (transaction) { return transaction.paymentStatus !== PaymentStatusEnum.None; })
            .sort(function (first, second) { return (isBefore(parseISO(first.paymentDate), parseISO(second.paymentDate)) ? 1 : -1); });
    }, [transactionsList]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: isOpen, onClose: close, classes: { paper: theme.modal__paper }, closeButtonRenderer: function (onClick) { return (React.createElement("div", { className: theme.popup__close },
                React.createElement(CloseIcon, { onClick: onClick }))); } },
            React.createElement("div", { className: theme.header }, t('Transactions')),
            React.createElement("div", { className: theme.content },
                React.createElement("table", { className: theme.table },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: theme.column__date },
                                React.createElement("div", null, t('Date'))),
                            React.createElement("th", { className: theme.column__product },
                                React.createElement("div", null, t('Product'))),
                            React.createElement("th", { className: theme.column__payment },
                                React.createElement("div", null, t('Payment'))),
                            React.createElement("th", { className: theme.column__status },
                                React.createElement("div", null, t('Status'))),
                            React.createElement("th", { className: theme.column__totalSum },
                                React.createElement("div", null, t('Amount'))),
                            React.createElement("th", { className: theme.column__totalSum },
                                React.createElement("div", null, t('Total'))))),
                    React.createElement("tbody", null, orderedTransactionsList.map(function (transaction) { return (React.createElement(Transaction, { key: transaction.id, transaction: transaction, dateFormat: dateFormat })); }))))),
        transactionButtonRenderer ? (transactionButtonRenderer({ onClick: onShowClick })) : (React.createElement("div", { className: theme.showBtn, onClick: onShowClick }, t('Transactions')))));
};
export default OrderTransactions;
