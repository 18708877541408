import * as React from 'react';
import MediaQuery from 'react-responsive';
import { TABLET_MIN_WIDTH } from '../utils';
import { DesktopSummaryFlight, MobileSummaryFlight } from '.';
var Flight = function (_a) {
    var flight = _a.flight, price = _a.price, fareFamily = _a.fareFamily, isOpenByDefault = _a.isOpenByDefault, isReturnFlight = _a.isReturnFlight, onChange = _a.onChange, className = _a.className, orderId = _a.orderId, isWidePrice = _a.isWidePrice;
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
            React.createElement(DesktopSummaryFlight, { flight: flight, price: price, fareFamily: fareFamily, isOpenByDefault: isOpenByDefault, isReturnFlight: isReturnFlight, onChange: onChange, className: className, orderId: orderId, isWidePrice: isWidePrice })),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement(MobileSummaryFlight, { useFlatMobileSegment: true, flight: flight, price: price, fareFamily: fareFamily, isOpenByDefault: isOpenByDefault, isReturnFlight: isReturnFlight, onChange: onChange, orderId: orderId }))));
};
export default Flight;
