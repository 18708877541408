import * as React from 'react';
import { useTheme } from '../theme';
import { initI18n } from '../../utils';
import { useTranslation } from 'react-i18next';
initI18n('Loader');
var Loader = function () {
    var css = useTheme().Loader, t = useTranslation('Loader').t;
    return (React.createElement("div", { className: css.loader__content },
        React.createElement("div", { className: css.loader },
            React.createElement("div", { className: css.loader_spinner })),
        ' ',
        t('loading'),
        "..."));
};
export default Loader;
