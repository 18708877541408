import * as React from 'react';
import Gateway from '../Gateway';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { useCallback, useEffect } from 'react';
import SimpleLoader from '../../../../SimpleLoader';
var Iframe = function (_a) {
    var _b;
    var type = _a.type, frameInfo = _a.frameInfo, onClick = _a.onClick, _c = _a.isPreOpened, isPreOpened = _c === void 0 ? false : _c, children = _a.children, headerTitle = _a.headerTitle, gatewayTitle = _a.gatewayTitle, onPaymentFrameLoaded = _a.onPaymentFrameLoaded, isLoading = _a.isLoading;
    var css = useTheme('PaymentForm').Card;
    var t = useTranslation('PaymentForm').t;
    var frameRef = React.useRef();
    // Ipad fix
    var isIpad = navigator.userAgent.indexOf('iPad') !== -1;
    (_b = frameRef === null || frameRef === void 0 ? void 0 : frameRef.current) === null || _b === void 0 ? void 0 : _b.setAttribute('allowpaymentrequest', '');
    var interval = setInterval(function () {
        try {
            if (frameRef.current && !isIpad && frameRef.current.contentWindow) {
                if (frameRef.current.contentWindow.location.hash) {
                    if (frameRef.current.contentWindow.location.hash.endsWith('failed')) {
                        window.location = frameRef.current.contentWindow.location;
                    }
                    else {
                        window.location = frameRef.current.contentWindow.location;
                    }
                    clearInterval(interval);
                }
            }
        }
        catch (e) { }
    }, 100);
    var handleOnClick = useCallback(function () {
        if (isIpad) {
            window.location.href = frameInfo.frameUrl;
        }
        else {
            onClick({
                type: type,
                title: isPreOpened ? t('Payment') : headerTitle !== null && headerTitle !== void 0 ? headerTitle : t('Pay by credit or debit card'),
                content: (React.createElement(React.Fragment, null,
                    isLoading && React.createElement(SimpleLoader, null),
                    React.createElement("iframe", { onLoad: onPaymentFrameLoaded, ref: frameRef, src: frameInfo.frameUrl, sandbox: "allow-same-origin allow-popups allow-top-navigation allow-forms allow-scripts", className: css.iframe, height: frameInfo.height })))
            });
        }
    }, [onClick, frameInfo]);
    useEffect(function () {
        if (isPreOpened) {
            handleOnClick();
        }
    }, [isPreOpened]);
    return React.createElement(Gateway, { onClick: handleOnClick, header: children, type: gatewayTitle !== null && gatewayTitle !== void 0 ? gatewayTitle : t('Credit or debit card') });
};
export default Iframe;
