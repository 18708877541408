import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import StepsBreadcrumbs from '../Breadcrumbs/StepsBreadcrumbs';
import { StepType } from '../../types';
import { useSteps } from '../Refund/hooks';
import { useSelector } from 'react-redux';
import { getSelectedPassengers } from '../../store/passengers/selectors';
import { getSelectedReason } from '../../store/reason/selectors';
import { useIsTerminal } from '../../../hooks';
import { OverrideComponent } from '../../../renderProps';
import WrapperModal from './WrapperModal/WrapperModal';
var hideRefundInfoStepsCore = [StepType.Passengers];
var hideRefundInfoStepsTerminal = __spreadArray(__spreadArray([], __read(hideRefundInfoStepsCore), false), [StepType.Reason], false);
var Wrapper = function (props) {
    var selectedPassengers = useSelector(getSelectedPassengers);
    var selectedReason = useSelector(getSelectedReason);
    var isTerminal = useIsTerminal();
    var _a = useSteps(), currentStep = _a.currentStep, handleMobileCloseClick = _a.handleMobileCloseClick;
    var renderSteps = (React.createElement(MediaQuery, { minWidth: 'mobile' },
        React.createElement(StepsBreadcrumbs, { refundInfo: (!isTerminal && !hideRefundInfoStepsCore.includes(currentStep.type)) ||
                (isTerminal && !hideRefundInfoStepsTerminal.includes(currentStep.type))
                ? {
                    reason: selectedReason,
                    isVoluntary: true
                }
                : null, passengers: selectedPassengers, onPassengerClick: handleMobileCloseClick(StepType.Passengers), onRefundInfoClick: handleMobileCloseClick(StepType.Reason) })));
    return (React.createElement(OverrideComponent, { componentProps: {
            onClose: props.onClose,
            steps: renderSteps,
            renderTitle: props.renderTitle,
            className: props.className,
            children: props.children
        }, component: { WrapperModal: WrapperModal } }));
};
export default Wrapper;
