import * as React from 'react';
import { useTheme } from '../theme';
import CardHeader from '@material-ui/core/CardHeader';
var ModalHeading = function (_a) {
    var heading = _a.heading;
    var css = useTheme().ModalHeading;
    return (React.createElement(CardHeader, { classes: {
            root: css.header,
            title: css.heading
        }, title: React.createElement("span", null, heading) }));
};
export default ModalHeading;
