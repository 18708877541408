import { __read } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import PromoLoader from '../../../PromoLoader';
import * as RestoreOrderQueries from '../../../__queries__/RestoreOrder.graphql';
var RestoreOrderComponent = function (props) {
    var _a = useParams(), orderId = _a.orderId, token = _a.token;
    var history = useHistory();
    var _b = __read(useMutation(RestoreOrderQueries.RestoreOrder, { fetchPolicy: 'no-cache' }), 1), resoterOrder = _b[0];
    useEffect(function () {
        // call restore order once if there is same id/token
        resoterOrder({ variables: { accessToken: token, id: orderId } })
            .then(function (_a) {
            var data = _a.data;
            if (data === null || data === void 0 ? void 0 : data.RestoreOrder) {
                props.onOrderReceived(data.RestoreOrder, false);
            }
            else {
                history.push("/find/".concat(orderId));
            }
        })
            .catch(function () {
            history.push("/find/".concat(orderId));
        });
    }, [orderId, token]);
    return React.createElement(PromoLoader, null);
};
export default RestoreOrderComponent;
