import React from 'react';
import BaggageCount from '../BaggageCount/BaggageCount';
import BaggageDirection from '../BaggageDirection/BaggageDirection';
import { useTheme } from '../../../../theme';
import Baggage from '../../../Baggage';
import Money from '../../../../Money';
import { useTranslation } from 'react-i18next';
var BaggageItem = function (_a) {
    var baggage = _a.baggage, servicesBySegments = _a.servicesBySegments, realIconSize = _a.realIconSize, priceFrom = _a.priceFrom;
    var css = useTheme('BaggageList').BaggageList;
    var t = useTranslation('AdditionalBaggage').t;
    return (React.createElement("div", { className: css.item },
        React.createElement(Baggage, { baggage: baggage[0], realIconSize: realIconSize }),
        servicesBySegments.length > 0 && (React.createElement("div", { className: css.counts }, servicesBySegments === null || servicesBySegments === void 0 ? void 0 : servicesBySegments.map(function (service) {
            return (React.createElement("div", { className: css.count, key: service.directions[0].departure.id },
                React.createElement(BaggageCount, { className: css.baggage_count, count: service.count }),
                React.createElement(BaggageDirection, { className: css.baggage_direction, allSegments: service.allSegments, directions: service.directions })));
        }))),
        servicesBySegments.length === 0 && (React.createElement("div", { className: css.price },
            t('from'),
            " ",
            React.createElement(Money, { money: priceFrom })))));
};
export default BaggageItem;
