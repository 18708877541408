import * as React from 'react';
import { useEffect } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import cn from 'classnames';
import { Money } from '../../Money';
import { getMinPrices } from '../../Results/utils';
import { useTheme } from '../../theme';
import { MobileSegmentFlat } from '../../MobileSegmentInfo';
import { useTranslation } from 'react-i18next';
import { MobileSegmentType } from '../../MobileSegmentInfo/components/MobileSegmentFlat/MobileSegmentFlat';
import Modal from '../../Modal';
import DesktopFlightModalContent from '../../DesktopFlight/components/DesktopFlightModalContent/DesktopFlightModalContent';
import { useDesktopFlightWithPopup } from '../../DesktopFlight/components/DesktopFlightPopup/hooks';
import PriceInMiles from '../../PriceInMiles/PriceInMiles';
var Flight = function (props) {
    var _a;
    var _b;
    var t = useTranslation('FlightPrice').t;
    var showAirplane = props.showAirplane, flight = props.flight, _c = props.selectedFares, selectedFares = _c === void 0 ? [] : _c, isPreOpen = props.isPreOpen, isRecommended = props.isRecommended, searchParameters = props.searchParameters, parentPriceRenderer = props.renderPrice;
    var lastSelectedFare = selectedFares.length ? selectedFares[selectedFares.length - 1].fareGroupId : null;
    var _d = useDesktopFlightWithPopup(props), upgradeOptions = _d.upgradeOptions, economyFareGroups = _d.economyFareGroups, businessFareGroups = _d.businessFareGroups, milesFareGroups = _d.milesFareGroups, getOnFareSelect = _d.getOnFareSelect, isModalOpen = _d.isModalOpen, openModal = _d.openModal, closeModal = _d.closeModal;
    var popupCss = useTheme('DesktopFlight').DesktopFlightPopup;
    var prices = getMinPrices(flight.pricesForFareGroups, false, lastSelectedFare);
    var theme = useTheme('MobileFlight').FlightStyles;
    useEffect(function () {
        if (isPreOpen) {
            closeModal();
        }
    }, [isPreOpen]);
    var totalPriceInMiles = prices[0].priceInMiles;
    var renderPrice = function () {
        return parentPriceRenderer ? (parentPriceRenderer({ onClick: openModal })) : (React.createElement(ButtonBase, { className: theme.price, onClick: openModal },
            !props.hidePrice && (React.createElement("div", { className: theme.price__text },
                React.createElement("span", null, t('Select from')),
                prices[0].priceInMiles ? (React.createElement(PriceInMiles, { amount: totalPriceInMiles, moneyClassName: cn(theme.price__money, theme.price__miles), currencyClassName: theme.currency })) : (React.createElement(Money, { moneyClassName: theme.price__money, money: prices[0].price || prices[1].price })))),
            props.hidePrice && (React.createElement("div", { className: theme.price__text },
                React.createElement("span", null, t('Select'))))));
    };
    return (React.createElement("div", { className: cn(theme.flight, (_a = {}, _a[theme.flight_withAircraft] = showAirplane, _a)) },
        React.createElement("div", { className: theme.segments },
            React.createElement(MobileSegmentFlat, { showAirplaneInfo: showAirplane, type: isRecommended && MobileSegmentType.Recommended, segments: flight.segments })),
        !props.readonly && renderPrice(),
        prices[0].priceInMiles && (React.createElement("div", { className: theme.miles__taxes },
            t('taxes and fees'),
            ":",
            React.createElement(Money, { money: prices[0].price || prices[1].price }))),
        React.createElement(Modal, { isRounded: true, maxWidth: 'md', open: isModalOpen, onClose: closeModal, classes: { paper: popupCss.paper, scrollBody: popupCss.scrollBody }, fullScreen: true },
            React.createElement(DesktopFlightModalContent, { lastSelectedFare: (_b = flight.pricesForFareGroups.find(function (fareGroup) { var _a; return fareGroup && ((_a = fareGroup.fareFamily) === null || _a === void 0 ? void 0 : _a.id) === lastSelectedFare; })) === null || _b === void 0 ? void 0 : _b.fareFamily, upgradeOptions: upgradeOptions, flight: flight, economyFares: economyFareGroups, businessFares: businessFareGroups, milesFares: milesFareGroups, getOnFareSelect: getOnFareSelect, hidePrice: props.hidePrice, searchParameters: searchParameters }))));
};
export default Flight;
