import * as React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useGraphQLClient } from '../../graphql-client';
import Account from './Account/Account';
import { initI18n } from '../../utils';
import { ApolloProvider } from '@apollo/react-hooks';
initI18n('Account');
export var Component = function () {
    var client = useGraphQLClient();
    var t = useTranslation('Title').t;
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(Helmet, null,
            React.createElement("title", null,
                t('Title:companyName'),
                " \u2013 ",
                t('Account'))),
        React.createElement(Account, null)));
};
export default Component;
