import * as React from 'react';
import { useTheme } from '../../../theme';
import { Button } from '../../../index';
import { useTranslation } from 'react-i18next';
var CancelModal = function (_a) {
    var onClose = _a.onClose, onConfirm = _a.onConfirm, passengerName = _a.passengerName, isLoading = _a.isLoading;
    var css = useTheme('RegisteredPassenger').CancelModal;
    var t = useTranslation('RegisteredPassenger').t;
    return (React.createElement("div", { className: css.content },
        React.createElement("div", { className: css.text }, t('You are going to cancel online check-in for passenger {{passenger}}. Are you sure about this?', {
            passenger: passengerName
        })),
        React.createElement("div", { className: css.buttons },
            React.createElement(Button, { onClick: onConfirm, className: css.cancelButton, isLoading: isLoading }, t('Yes')),
            !isLoading && (React.createElement(Button, { onClick: onClose, className: css.closeButton }, t('No'))))));
};
export default CancelModal;
