import { createSelector } from 'reselect';
import { isStepAvailable } from '../../utils';
import { getShowServicesBeforeBooking } from '../order/selectors';
import { StepType } from '../../types';
import { useConfig } from '../../../context';
var getState = function (state) { return state; };
export var getSteps = createSelector(getState, getShowServicesBeforeBooking, useConfig, function (state, showServicesBeforeBooking, config) {
    return state.checkout.steps.filter(function (step) {
        var skipServices = !showServicesBeforeBooking &&
            (step.type === StepType.Baggage || step.type === StepType.Meals || step.type === StepType.Seats);
        if (config.global.companyInfo.iataCode === 'N4' && step.type === StepType.Meals) {
            skipServices = true;
        }
        return !skipServices && isStepAvailable(state, step.type);
    });
});
