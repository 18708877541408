import { __values } from "tslib";
import i18n from 'i18next';
import { isAfter, isBefore, isValid, parse, parseISO } from 'date-fns';
import { InputType } from './types';
import { clearPhoneNumber } from '../LoginPage/utils';
import { decapitalizeFirstLetter } from '../utils';
import { TravellerFields } from '../TravellerForm/types';
var passengerTypeLabels = {
    ADT: 'Adult',
    CLD: 'Child',
    INF: 'Infant',
    INS: 'Infant with seat'
};
export var fieldsArrayToMap = function (fields) {
    var map = {};
    fields.map(function (field) {
        if (!map.hasOwnProperty(field.name)) {
            map[field.name] = field;
        }
    });
    return map;
};
export var hideText = function (text, divider) {
    if (divider === void 0) { divider = 1.5; }
    if (!text) {
        return '';
    }
    var numOfVisibleChars = Math.floor(text.length / divider);
    var numOfHiddenChars = text.length - numOfVisibleChars;
    return text.split('').fill('*', 0, numOfHiddenChars).join('');
};
export var getAdditionalLabel = function (values, fieldsMap, passengerType) {
    var labels = [];
    var personalInfo = '';
    if (values[TravellerFields.FirstName]) {
        personalInfo = values[TravellerFields.FirstName];
        if (values[TravellerFields.LastName]) {
            personalInfo += " ".concat(values[TravellerFields.LastName]);
        }
    }
    else {
        personalInfo = i18n.t("Passenger:".concat(passengerTypeLabels[passengerType]));
    }
    if (values[TravellerFields.BirthDate]) {
        personalInfo += ", ".concat(values[TravellerFields.BirthDate]);
    }
    labels.push(personalInfo);
    if (values.hasOwnProperty('loyaltyName') && fieldsMap['loyaltyName'].options) {
        var option = fieldsMap['loyaltyName'].validations[0].options.find(function (option) { return option.value === values['loyaltyName']; });
        if (option) {
            labels.push(option.value);
        }
    }
    if (values.hasOwnProperty('nationality') && fieldsMap['nationality'].validations[0].options) {
        var option = fieldsMap['nationality'].validations[0].options.find(function (option) { return option.value === values['nationality']; });
        if (option) {
            labels.push(option.label);
        }
    }
    if (values.hasOwnProperty('docType') &&
        values.hasOwnProperty('docNumber') &&
        fieldsMap['docType'].validations[0].options) {
        fieldsMap['docType'].validations.forEach(function (validation) {
            var option = validation.options.find(function (option) { return option.value === values['docType']; });
            if (option) {
                labels.push(i18n.t("Passenger:".concat(option.label)));
                labels.push(hideText(values['docNumber']));
            }
        });
    }
    return labels.join(' - ');
};
export var validateDate = function (value, rule, format) {
    if (format === void 0) { format = 'dd.MM.yyyy'; }
    var date = parse(value, format, new Date());
    if (!isValid(date)) {
        return "".concat(i18n.t("Enter date in ".concat(format, " format")));
    }
    if (rule.maxDate && isAfter(date, parseISO(rule.maxDate))) {
        return "".concat(i18n.t('Incorrect date'));
    }
    if (rule.minDate && isBefore(date, parseISO(rule.minDate))) {
        return "".concat(i18n.t('Incorrect date'));
    }
};
export var validate = function (value, allValues, inputType, validations) {
    var e_1, _a;
    if (validations === void 0) { validations = []; }
    if (validations && validations.length) {
        var _loop_1 = function (rule) {
            if (!rule.with ||
                rule.with.every(function (_a) {
                    var name = _a.name, value = _a.value;
                    return new RegExp("^".concat(value, "$")).test(allValues[decapitalizeFirstLetter(name)]);
                })) {
                // Check if field is required.
                if (rule.required && !(value === null || value === void 0 ? void 0 : value.trim().length)) {
                    return { value: 'Required' };
                }
                if (value) {
                    // Check that date is valid.
                    if (inputType === InputType.Date) {
                        var error = validateDate(value, rule);
                        if (error) {
                            return { value: error };
                        }
                    }
                    if (rule.regExp) {
                        var cleanValue_1 = inputType === InputType.Phone ? clearPhoneNumber(value) : value;
                        var failedRule = rule.regExp.find(function (_a) {
                            var pattern = _a.pattern;
                            return !new RegExp(pattern).test(cleanValue_1);
                        });
                        if (failedRule) {
                            return { value: inputType === InputType.Phone ? 'Incorrect phone number' : failedRule.error };
                        }
                    }
                }
            }
        };
        try {
            // Run through all validation rules.
            for (var validations_1 = __values(validations), validations_1_1 = validations_1.next(); !validations_1_1.done; validations_1_1 = validations_1.next()) {
                var rule = validations_1_1.value;
                var state_1 = _loop_1(rule);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (validations_1_1 && !validations_1_1.done && (_a = validations_1.return)) _a.call(validations_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
};
export var fieldIsRequired = function (allValues, validations) {
    var e_2, _a;
    if (validations === void 0) { validations = []; }
    if (validations === null || validations === void 0 ? void 0 : validations.length) {
        try {
            for (var validations_2 = __values(validations), validations_2_1 = validations_2.next(); !validations_2_1.done; validations_2_1 = validations_2.next()) {
                var rule = validations_2_1.value;
                if ((!rule.with ||
                    rule.with.every(function (_a) {
                        var name = _a.name, value = _a.value;
                        return new RegExp("^".concat(value, "$")).test(allValues[decapitalizeFirstLetter(name)]);
                    })) &&
                    rule.required) {
                    return true;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (validations_2_1 && !validations_2_1.done && (_a = validations_2.return)) _a.call(validations_2);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    return false;
};
