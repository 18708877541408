import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '../theme';
import SimpleLoader from '../SimpleLoader';
import SegmentTabs from '../SegmentTabs/SegmentTabs';
import MediaQuery from '../MediaQuery/MediaQuery';
import MobileToolbar from '../MobileToolbar';
import { initI18n, scrollTo } from '../utils';
import { useTranslation } from 'react-i18next';
initI18n('ServiceWithSegments');
var ServiceWithSegments = function (_a) {
    var children = _a.children, sticky = _a.sticky, isLoading = _a.isLoading, title = _a.title, segments = _a.segments, enabledSegments = _a.enabledSegments, onMobileProceed = _a.onMobileProceed, onMobileBack = _a.onMobileBack, onSegmentChange = _a.onSegmentChange, _b = _a.initialSegmentId, initialSegmentId = _b === void 0 ? '0' : _b, renderRightBlock = _a.renderRightBlock, propsSegmentsPosition = _a.segmentsPosition;
    var css = useTheme('ServiceWithSegments').ServiceWithSegments;
    var _c = __read(useState(initialSegmentId), 2), activeSegmentId = _c[0], setActiveSegmentId = _c[1];
    var activeSegment = segments.find(function (_a) {
        var id = _a.id;
        return id === activeSegmentId;
    });
    var t = useTranslation('ServiceWithSegments').t;
    var nextSegmentId = useMemo(function () {
        var segmentsIds;
        if (enabledSegments) {
            segmentsIds = __spreadArray([], __read(enabledSegments), false);
        }
        else {
            segmentsIds = segments.map(function (segment) { return segment.id; });
        }
        return segmentsIds
            .filter(function (segmentId) { return segments.find(function (segment) { return parseInt(segment.id, 10) === parseInt(segmentId, 10); }); })
            .sort()
            .find(function (segmentId) { return parseInt(segmentId, 10) > parseInt(activeSegmentId, 10); });
    }, [enabledSegments, activeSegmentId]);
    var hasRightBlockRenderer = !!renderRightBlock;
    var segmentsBlockPosition;
    if (hasRightBlockRenderer) {
        segmentsBlockPosition = 'center';
    }
    else {
        segmentsBlockPosition = segments.map(function (segment) { return segment.title; }).join('').length > 45 ? 'right' : 'center';
    }
    var onContinue = function () {
        if (typeof nextSegmentId !== 'undefined') {
            setActiveSegmentId(nextSegmentId);
        }
        else {
            onMobileProceed();
        }
        scrollTo(0);
    };
    useEffect(function () { return onSegmentChange && onSegmentChange(activeSegmentId); }, [onSegmentChange, activeSegmentId]);
    var onBack = function () {
        var prevSegmentId = __spreadArray([], __read(enabledSegments), false).sort()
            .reverse()
            .find(function (segmentId) { return parseInt(segmentId) < parseInt(activeSegmentId); });
        if (typeof prevSegmentId !== 'undefined') {
            setActiveSegmentId(prevSegmentId);
        }
        else {
            onMobileBack();
        }
        scrollTo(0);
    };
    var continueButtonText = typeof nextSegmentId !== 'undefined' ? t('Next flight') : undefined;
    return (React.createElement("div", { className: css.service },
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement(MediaQuery, { minWidth: "mobile" },
            React.createElement(SegmentTabs, { renderRightBlock: renderRightBlock, position: propsSegmentsPosition !== null && propsSegmentsPosition !== void 0 ? propsSegmentsPosition : segmentsBlockPosition, title: title, segments: segments, sticky: sticky !== null && sticky !== void 0 ? sticky : true, onSegmentClick: setActiveSegmentId, activeSegment: activeSegmentId })),
        React.createElement("div", { className: css.wrapper },
            React.createElement(MediaQuery, { maxWidth: "mobile" },
                React.createElement("div", { className: css.title }, activeSegment.title)),
            children({
                activeSegmentId: activeSegmentId
            })),
        React.createElement(MediaQuery, { maxWidth: "mobile" },
            React.createElement(MobileToolbar, { onBack: onBack, onContinue: onContinue, continueButtonText: continueButtonText }))));
};
export default ServiceWithSegments;
