import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { Switch } from '../../../../../index';
import { useTheme } from '../../../../../theme';
import LoyaltyCard from './LoyaltyCard/LoyaltyCard';
import { setRedeemMiles } from '../../../../store/redeemMiles/actions';
import { getRedeemMiles } from '../../../../store/selectors';
var PayByMiles = function () {
    var theme = useTheme('SearchForm').PayByMiles;
    var t = useTranslation('SearchForm').t;
    var form = useForm();
    var dispatch = useDispatch();
    var isActive = useSelector(getRedeemMiles);
    var clearLoyalty = function () {
        dispatch(setRedeemMiles(false));
    };
    var onSetLoyalty = function (card, logged) {
        dispatch(setRedeemMiles(!!card));
        if (logged) {
            try {
                form.submit();
            }
            catch (e) {
                return;
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(LoyaltyCard, { setLoyalty: onSetLoyalty }, function (requestLoyaltyCard) {
            return (React.createElement(Switch, { mainClassName: theme.switchWrapper, labelClassName: theme.switchBox, label: t('Pay by miles'), isActive: isActive, onClick: isActive ? clearLoyalty : requestLoyaltyCard }));
        })));
};
export default PayByMiles;
