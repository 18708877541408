import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import Modal from '../../../../../Modal';
import Button from '../../../../../Button/Button';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
var successIcon = (React.createElement("svg", { width: "36", height: "36", viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("circle", { cx: "18", cy: "18", r: "18", fill: "#5FCA43" }),
    React.createElement("path", { d: "M11.1738 18.5147L15.5796 23.25L25.3488 12.75", stroke: "white", strokeWidth: "2.1", strokeLinecap: "round" })));
var Dialog = function (props) {
    var _a = props.icon, icon = _a === void 0 ? successIcon : _a, className = props.className, openProp = props.open, onAccept = props.onAccept, onCancel = props.onCancel, children = props.children, text = props.text, cancelText = props.cancelText, acceptText = props.acceptText, restProps = __rest(props, ["icon", "className", "open", "onAccept", "onCancel", "children", "text", "cancelText", "acceptText"]);
    var _b = __read(React.useState(openProp), 2), isOpen = _b[0], setOpen = _b[1];
    var _c = __read(React.useState(false), 2), isLoading = _c[0], setLoading = _c[1];
    var openHandler = function () { return setOpen(true); };
    var closeHandler = function () { return setOpen(false); };
    var onAcceptHandler = function () {
        var accept = onAccept();
        if (typeof accept === 'object' && typeof accept['then'] === 'function') {
            setLoading(true);
            accept.then(function (data) { });
        }
        else {
            closeHandler();
        }
    };
    var onCancelHandler = function () {
        onCancel();
        closeHandler();
    };
    var theme = useTheme('Exchange').Dialog;
    return (React.createElement(React.Fragment, null,
        children &&
            children({
                open: openHandler,
                close: closeHandler,
                isOpen: isOpen
            }),
        React.createElement(Modal, __assign({ maxWidth: 'sm', open: isOpen, onClose: closeHandler, classes: {
                paper: cn(theme.paper, className)
            } }, restProps),
            icon,
            text,
            React.createElement("div", { className: theme.controls },
                cancelText && !isLoading && (React.createElement(Button, { onClick: onCancelHandler, className: cn(theme.button, theme.cancel) }, cancelText)),
                React.createElement(Button, { onClick: onAcceptHandler, className: theme.button, isLoading: isLoading }, acceptText)))));
};
export default Dialog;
