import React from 'react';
import { useTheme } from '../../theme';
import Menu from './Menu/Menu';
import Routes from './Routes/Routes';
import Logo from './Logo/Logo';
import Collapse from '@material-ui/core/Collapse';
import cn from 'classnames';
var Header = function (props) {
    var _a;
    var css = useTheme('Header').Header;
    var showMenu = (_a = props.showMenu) !== null && _a !== void 0 ? _a : true;
    return (React.createElement("div", { className: cn(css.root, props.className) },
        React.createElement("div", { className: css.body },
            React.createElement("div", { className: css.logo_wrapper }, props.renderLogo ? props.renderLogo() : React.createElement(Logo, null)),
            React.createElement("div", null, props.renderRoutes ? props.renderRoutes() : React.createElement(Routes, null)),
            showMenu && React.createElement("div", { className: css.menu_wrapper }, props.renderMenu ? props.renderMenu() : React.createElement(Menu, null))),
        React.createElement(Collapse, { in: props.isOpen }, props.renderSearchForm
            ? props.renderSearchForm()
            : 'DefaultForm' /* TODO replace with form component */)));
};
export default Header;
