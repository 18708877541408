import { __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { Currency, CurrencySymbols } from '../../enums';
import { splitMoney, truncDecimal } from '../../Results/utils';
import { useTheme } from '../../theme';
import { useIsTerminal } from '../../hooks';
export var Money = function (_a) {
    var _b;
    var _c, _d;
    var money = _a.money, moneyClassName = _a.moneyClassName, amountClassName = _a.amountClassName, currencyClassName = _a.currencyClassName, _e = _a.multiplyBy, multiplyBy = _e === void 0 ? 1 : _e, amountRenderer = _a.amountRenderer, currencyRenderer = _a.currencyRenderer, _f = _a.showCurrencyBeforeAmount, showCurrencyBeforeAmount = _f === void 0 ? false : _f, _g = _a.allowRounding, allowRounding = _g === void 0 ? true : _g, restProps = __rest(_a, ["money", "moneyClassName", "amountClassName", "currencyClassName", "multiplyBy", "amountRenderer", "currencyRenderer", "showCurrencyBeforeAmount", "allowRounding"]);
    var css = useTheme('Money').Money;
    var isTerminal = useIsTerminal();
    var amount = isTerminal && (money === null || money === void 0 ? void 0 : money.hasOwnProperty('amount')) ? truncDecimal(money.amount, 2) : money === null || money === void 0 ? void 0 : money.amount;
    var needToRound = allowRounding && !isTerminal && ((_c = amount === null || amount === void 0 ? void 0 : amount.toString(10)) === null || _c === void 0 ? void 0 : _c.length) >= 6 && amount % 1 !== 0;
    var showCurrency;
    if (isTerminal) {
        showCurrency = false;
    }
    else {
        showCurrency = (_d = restProps === null || restProps === void 0 ? void 0 : restProps.showCurrency) !== null && _d !== void 0 ? _d : true;
    }
    return (React.createElement("span", { className: cn(css.money, moneyClassName) },
        money &&
            showCurrency &&
            showCurrencyBeforeAmount &&
            money.currency !== Currency.USD &&
            (currencyRenderer ? (currencyRenderer(CurrencySymbols.USD)) : (React.createElement("span", { className: cn((_b = {}, _b[css.currency_before] = showCurrencyBeforeAmount, _b), currencyClassName) }, CurrencySymbols[money.currency]))),
        money &&
            showCurrency &&
            !showCurrencyBeforeAmount &&
            money.currency === Currency.USD &&
            (currencyRenderer ? (currencyRenderer(CurrencySymbols.USD)) : (React.createElement("span", { className: currencyClassName }, CurrencySymbols.USD))),
        amountRenderer ? (amountRenderer(amount)) : (React.createElement("span", { className: amountClassName }, money &&
            splitMoney(needToRound ? Math.floor(amount) * multiplyBy : amount * multiplyBy, 2).join(' '))),
        money &&
            showCurrency &&
            !showCurrencyBeforeAmount &&
            money.currency !== Currency.USD &&
            (currencyRenderer ? (currencyRenderer(CurrencySymbols.USD)) : (React.createElement("span", { className: currencyClassName },
                " ",
                CurrencySymbols[money.currency])))));
};
export default Money;
