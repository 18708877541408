import { __assign } from "tslib";
import React, { useMemo } from 'react';
import { LoginType } from '../../../../../__generated__/graphql';
import ContactsWidget from '../../../../../Contacts/components/Contacts';
import PassengersModalComponent from '../../../../../OrderCard/components/OrderPassengers/PassengersModal/PassengersModal';
import { buildContactFields } from '../../../../../Contacts/components/utils';
import { useIsTerminal } from '../../../../../hooks';
var PassengersModal = function (props) {
    var isTerminal = useIsTerminal();
    var _a = useMemo(function () { return buildContactFields(props.order.customer, isTerminal); }, [props.order.customer, props.order.travellers.length]), otherContacts = _a.otherContacts, mainContacts = _a.mainContacts;
    return (React.createElement(PassengersModalComponent, __assign({}, props, { passengerAdditional: React.createElement(ContactsWidget, { open: true, isInputsReadonly: true, sendCode: function () { }, toggleOpen: function () { }, submitForm: function () { }, unlockForm: function () { }, loginType: LoginType.Email, mainContacts: mainContacts, otherContacts: otherContacts }), hideCustomerContacts: true })));
};
export default PassengersModal;
