import * as React from 'react';
import { format } from '../../../../../../utils';
import { useTheme } from '../../../../../../theme';
import Modal from '../../../../../../Modal';
import { Flight } from './Components/Flight';
import { WeekdaysSchedule } from './Components/WeekdaysSchedule';
import { StopsInfo } from './Components/StopsInfo';
var AllFlightsModal = function (props) {
    var theme = useTheme('Schedule').Day;
    if (!props.open) {
        return null;
    }
    return (React.createElement(Modal, { open: props.open, onClose: props.onClose, isRounded: true, classes: { root: theme.allFlightsModal, paper: theme.allFlightsModal__paper } },
        React.createElement("div", { className: theme.allFlightsModal__header }, format(props.date, 'd LLLL, EEEEEE')),
        React.createElement("div", null, props.legsToday.map(function (leg, legIndex) {
            return (React.createElement("div", { className: theme.flightInfo, key: legIndex, onClick: function () { return props.onSelect(props.date); } },
                React.createElement("div", { className: theme.flights },
                    React.createElement("div", null, leg.segments.map(function (_a, segmentIndex) {
                        var segment = _a.segment;
                        return (React.createElement(Flight, { key: segmentIndex, leg: leg, segment: segment, segmentIndex: segmentIndex }));
                    })),
                    React.createElement(WeekdaysSchedule, { leg: leg })),
                React.createElement(StopsInfo, { leg: leg })));
        }))));
};
export default AllFlightsModal;
