import React from 'react';
import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CommonCard from '../../components/CommonCard/CommonCard';
import { useTheme } from '../../../../theme';
import { getGroupedAllSelectedServices, getSegments } from '../../../../Checkout/store/order/selectors';
import { getOrder } from '../../../../Checkout/store/order/selectors';
import { OrderAdditionalServiceGdsServiceServiceType } from '../../../../__generated__/globalTypes';
import { calculateMinPrice, calculateShowClearValue, calculateSummary, deleteServicesByType, isEmptyServices } from '../../utils';
export var useMiscServiceCard = function () {
    var misc = useSelector(getGroupedAllSelectedServices).misc;
    var order = useSelector(getOrder);
    var segments = useSelector(getSegments);
    var t = useTranslation('ServiceCard').t;
    var theme = useTheme('ServicesCard').Misc;
    var dispatch = useDispatch();
    var _a = useMemo(function () { return calculateSummary(misc, order, segments); }, [misc, order, segments]), totalPrice = _a.totalPrice, servicesIds = _a.servicesIds;
    var clearAll = useCallback(function () {
        deleteServicesByType(servicesIds, OrderAdditionalServiceGdsServiceServiceType.Misc, dispatch);
    }, [servicesIds]);
    var isShowClearAll = useMemo(function () { return calculateShowClearValue(misc, OrderAdditionalServiceGdsServiceServiceType.Misc); }, [misc]);
    var isEmpty = useMemo(function () { return isEmptyServices(misc); }, [misc]);
    var minPrice = useMemo(function () {
        return calculateMinPrice(order.additionalServices.gdsServices.services, OrderAdditionalServiceGdsServiceServiceType.Misc);
    }, [order.additionalServices.gdsServices.services]);
    return {
        Content: isEmpty
            ? null
            : function () { return React.createElement(CommonCard, { order: order, services: misc, backgroundClassName: theme.backgroundColor }); },
        EmptyContent: function () { return React.createElement("div", null, t('Add more comfort to your flight!')); },
        contentClassName: theme.content,
        description: '',
        headerProps: {
            title: t('Extra services'),
            icon: null,
            backgroundClassName: theme.backgroundColor
        },
        totalPrice: totalPrice,
        minPrice: minPrice,
        clearAll: clearAll,
        isShowClearAll: isShowClearAll
    };
};
