import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
var Header = function (_a) {
    var _b;
    var headerClassName = _a.headerClassName, title = _a.title, info = _a.info, onClose = _a.onClose, renderCloseButton = _a.renderCloseButton;
    var css = useTheme('AdditionalServiceWrapper').Header;
    return (React.createElement("div", { className: cn(headerClassName, css.header) },
        onClose && React.createElement(React.Fragment, null, (_b = renderCloseButton === null || renderCloseButton === void 0 ? void 0 : renderCloseButton(onClose)) !== null && _b !== void 0 ? _b : React.createElement("div", { className: css.close, onClick: onClose })),
        React.createElement("div", { className: css.header__wrapper },
            React.createElement("div", { className: css.title }, title),
            info && React.createElement("div", { className: css.info }, info))));
};
export default Header;
