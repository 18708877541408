import { __assign, __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { Select as SelectComponent } from '../../Passenger/components/Field/Select/Select';
import * as css from './Select.css';
var Select = function (_a) {
    var _b, _c;
    var success = _a.success, warning = _a.warning, props = __rest(_a, ["success", "warning"]);
    return (React.createElement(SelectComponent, __assign({}, props, { inputClassName: cn(props.inputClassName, css.real_input, (_b = {},
            _b[css.success] = success,
            _b[css.warning] = warning,
            _b)), inputLabelClassName: cn(props.inputLabelClassName, (_c = {},
            _c[css.label_success] = success,
            _c[css.label_warning] = warning,
            _c)), inputDisableClassName: cn(props.inputDisableClassName, css.disabled), inputErrorClassName: cn(props.inputErrorClassName, css.error), className: cn(props.className, css.select) })));
};
export default Select;
