import { __assign } from "tslib";
import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Slider from 'react-slick';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import Paper from '@material-ui/core/Paper/Paper';
import AircraftPopupCarousel from './Carousel/AircraftPopupCarousel';
import FlightClassCard from './FlightClass/FlightClass';
import { MOBILE_MIN_WIDTH } from '../../utils';
import ModalHeading from '../../ModalHeading';
import { useTheme } from '../../theme';
import * as AircraftInfoQuery from '../../__queries__/AircraftInfo.graphql';
var AircraftPopup = function (_a) {
    var _b, _c, _d;
    var id = _a.id;
    var css = useTheme('AircraftPopup').AircraftPopup;
    var _e = useQuery(AircraftInfoQuery.AircraftInfo, {
        variables: { id: id }
    }), data = _e.data, loading = _e.loading;
    var sliderSettings = {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: MOBILE_MIN_WIDTH,
                settings: {
                    slidesToShow: 1.4
                }
            }
        ]
    };
    var paramsLists = [[], []];
    (_c = (_b = data === null || data === void 0 ? void 0 : data.AircraftInfo) === null || _b === void 0 ? void 0 : _b.parameters) === null || _c === void 0 ? void 0 : _c.forEach(function (param, id) {
        if (id % 2 === 0) {
            paramsLists[0].push(param);
        }
        else {
            paramsLists[1].push(param);
        }
    });
    return (React.createElement(Paper, { classes: { root: css.aircraftPopup } },
        loading && (React.createElement("div", null,
            React.createElement("div", { className: css.loaderWrp },
                React.createElement(CircularProgress, { classes: { colorPrimary: css.loader } })))),
        !loading && data && data.AircraftInfo && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.headerWrp },
                React.createElement(ModalHeading, { heading: data.AircraftInfo.title })),
            React.createElement("div", null,
                React.createElement(AircraftPopupCarousel, { items: data.AircraftInfo.images })),
            React.createElement("div", { className: css.flightClasses },
                React.createElement(Slider, __assign({}, sliderSettings), data.AircraftInfo.cabins.map(function (cabin, i) { return (React.createElement(FlightClassCard, { key: i, flightClass: cabin })); }))),
            React.createElement(Paper, { classes: { root: css.divided } },
                React.createElement("div", null, data.AircraftInfo.description)),
            React.createElement("div", { className: css.listWrp }, paramsLists.map(function (list) {
                return (React.createElement(List, { classes: { root: css.list } }, list.map(function (parameter, i) { return (React.createElement(ListItem, { key: i, classes: { root: css.listItem } },
                    React.createElement("span", { className: css.listItemName },
                        parameter.title,
                        ":"),
                    React.createElement("span", { className: css.listItemValue }, parameter.value))); })));
            })),
            ((_d = data.AircraftInfo) === null || _d === void 0 ? void 0 : _d.scheme) && (React.createElement("img", { src: data.AircraftInfo.scheme, alt: data.AircraftInfo.title, className: css.seatMap }))))));
};
export default AircraftPopup;
