import * as React from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ModalButton } from '../../../../Button';
import PriceBlock from './PriceBlock/PriceBlock';
import { getDisabled, getPassengers, getPrice, getSelectedServices, getService } from '../../store/selector';
import { useMemo } from 'react';
var BaggageFooter = function (_a) {
    var onConfirm = _a.onConfirm, onCancel = _a.onCancel;
    var theme = useTheme('UpgradeBaggageDialog').BaggageFooter;
    var t = useTranslation('IncludedBaggage').t;
    var disabled = useSelector(getDisabled);
    var price = useSelector(getPrice);
    var services = useSelector(getService);
    var selectedServices = useSelector(getSelectedServices);
    var passengers = useSelector(getPassengers);
    var hideClear = useMemo(function () {
        var count = 0;
        var included = 0;
        services.forEach(function (service) {
            selectedServices.forEach(function (_a) {
                var _b, _c, _d, _e;
                var serviceId = _a.serviceId, passengerId = _a.passengerId, serviceCount = _a.count;
                if (service.id === serviceId) {
                    var passenger = passengers.find(function (passenger) { return passenger.id === passengerId; });
                    var confirmedCount = ((_e = (_d = (_c = (_b = passenger.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.find(function (selectedService) { return selectedService.serviceId === serviceId; })) === null || _e === void 0 ? void 0 : _e.confirmedCount) || 0;
                    count += serviceCount;
                    included += confirmedCount;
                }
            });
        });
        return count === included;
    }, [services, selectedServices, passengers]);
    return (React.createElement("div", { className: theme.root },
        React.createElement("div", { className: theme.row },
            price && React.createElement(PriceBlock, { hideClear: hideClear }),
            React.createElement("div", { className: theme.buttons_row },
                React.createElement(ModalButton, { color: 'secondary', size: 'small', className: theme.cancel_btn, onClick: onCancel }, t('Cancel')),
                React.createElement(ModalButton, { className: theme.confirm_btn, size: 'small', onClick: onConfirm, disabled: disabled }, t('Confirm'))))));
};
export default BaggageFooter;
