import { __read } from "tslib";
import * as React from 'react';
import { memo, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import * as Cookie from 'js-cookie';
import Loader from '../../../../../Loader';
import * as LogoutQuery from '../../../../../__queries__/Logout.graphql';
import { GRAPHQL_KEY } from '../../../../../cache';
var Logout = memo(function () {
    var _a = __read(useMutation(LogoutQuery.Logout), 1), logoutMutation = _a[0];
    var history = useHistory();
    useEffect(function () {
        Cookie.remove(GRAPHQL_KEY);
        logoutMutation().then(function () {
            history.replace('/account');
            window.location.reload();
        });
    }, []);
    return React.createElement(Loader, null);
});
export default Logout;
