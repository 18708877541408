export var PaymentType;
(function (PaymentType) {
    PaymentType["Gpay"] = "Gpay";
    PaymentType["Card"] = "Card";
    PaymentType["Credit"] = "Credit";
    PaymentType["Itransfer"] = "Itransfer";
    PaymentType["Kaspi"] = "Kaspi";
    PaymentType["Cash"] = "Cash";
    PaymentType["Invoice"] = "Invoice";
})(PaymentType || (PaymentType = {}));
