import { __awaiter, __generator, __read } from "tslib";
import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { isAfter, parseISO } from 'date-fns';
import { useToggleable } from '../../hooks';
import { OrderStatus } from '../../__generated__/globalTypes';
var VoidOrder = require('../../__queries__/query.graphql').VoidOrder;
export var useVoid = function (order) {
    var VOID_TIMELIMIT = 60;
    var _a = useToggleable(false), isOpen = _a.isOpen, open = _a.open, close = _a.close;
    var _b = __read(useMutation(VoidOrder), 1), voiding = _b[0];
    var isVoidAvailable = useMemo(function () { return order.exareInfo.voidingTimelimitInSeconds > VOID_TIMELIMIT && order.status === OrderStatus.Confirmed; }, [order.exareInfo.voidingTimelimitInSeconds, order.status]);
    var handleOpen = useCallback(function () {
        if (isVoidAvailable) {
            open();
        }
    }, [isVoidAvailable]);
    var voidOrder = function (onSuccess, onFail) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!isAfter(parseISO(order.exareInfo.voidingTimelimit), new Date())) return [3 /*break*/, 5];
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, voiding({
                            variables: {
                                orderId: order.id
                            }
                        })];
                case 2:
                    data = _c.sent();
                    if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.VoidOrder) {
                        onSuccess((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.VoidOrder);
                    }
                    else {
                        onFail();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _c.sent();
                    onFail();
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 6];
                case 5:
                    onFail();
                    _c.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var isGdsServicesVoidRefundAvailable = order.exareInfo.gdsServicesRefundAutoMode && order.exareInfo.gdsServicesRefundAvailability;
    return {
        isVoidAvailable: isVoidAvailable,
        isModalOpen: isOpen,
        closeModal: close,
        openModal: handleOpen,
        voidOrder: voidOrder,
        isGdsServicesVoidRefundAvailable: isGdsServicesVoidRefundAvailable
    };
};
