import { amadeusPassengersData } from './amadeus/amadeusPassengersData';
import { sirenaPassengersDataValidation } from './sirena/sirenaPassengersDataValidation';
import { amadeusPassengersDataValidation } from './amadeus/amadeusPassengersDataValidation';
import { sirenaPassengersData } from './sirena/sirenaPassengersData';
var DIGITS_PLUS_AND_SYMBOL_AFTER = /\d+\+.{1,2}/;
var SPECIFIC_COMMANDS_SIRENA = /^-|^9/gm;
var SPECIFIC_COMMANDS_AMADEUS = /^SR DOCS/gm;
export var isSirena = function (str) {
    // check specific chars in the beginning of string and birthdate and + gender
    if (str.match(SPECIFIC_COMMANDS_SIRENA) ||
        !str.match(SPECIFIC_COMMANDS_AMADEUS) ||
        str.match(DIGITS_PLUS_AND_SYMBOL_AFTER)) {
        return true;
    }
    return false;
};
export var passengersData = function (str) {
    if (isSirena(str)) {
        return sirenaPassengersData(str);
    }
    else {
        return amadeusPassengersData(str);
    }
};
export var syntaxValidationPD = function (_a) {
    var str = _a.str, citiesIata = _a.citiesIata, passengerTypeCodes = _a.passengerTypeCodes;
    if (isSirena(str)) {
        return sirenaPassengersDataValidation(str);
    }
    else {
        return amadeusPassengersDataValidation(str);
    }
};
