var MAX_MB_SIZE = 5;
var AVAILABLE_FORMATS = ['.jpg', '.pdf', '.doc', '.docx', '.png'];
export var useFileValidation = function () {
    var validate = function (file) {
        if (file.size / 1000000 > MAX_MB_SIZE) {
            return false;
        }
        return AVAILABLE_FORMATS.some(function (availableFormat) { return file.name.toLowerCase().includes(availableFormat); });
    };
    return {
        validate: validate
    };
};
export var useFileConverter = function () {
    var validate = useFileValidation().validate;
    var toBase64 = function (file) {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            if (!validate(file)) {
                reject('Wrong format');
            }
            reader.onload = function () {
                var replacedResult = reader.result.replace(/^data.+base64,/gm, '');
                resolve({ name: file.name, content: replacedResult });
            };
            reader.onerror = function () { return reject('error while converting file to base64'); };
        });
    };
    return {
        toBase64: toBase64
    };
};
