import * as Inital from './components/Checkin/Initial/Initial.css';
import * as Passengers from './components/Checkin/Order/Passengers/Passengers.css';
import * as Checkin from './components/Checkin/Checkin.css';
import * as Order from './components/Checkin/Order/Order.css';
import * as SelectSegmentsModal from './components/Checkin/Order/Passengers/SelectSegmentsModal/SelectSegmentsModal.css';
import * as VisaForm from './components/Checkin/Order/Passengers/VisaForm/VisaForm.css';
import * as CheckinErrorBoundary from './components/Checkin/CheckinErrorBoundary/CheckinErrorBoundary.css';
import * as DownloadAeroexpress from './components/Checkin/Order/Passengers/RegisteredPassenger/DownloadAeroexpress/DownloadAeroexpress.css';
export default {
    Inital: Inital,
    Passengers: Passengers,
    Checkin: Checkin,
    SelectSegmentsModal: SelectSegmentsModal,
    Order: Order,
    VisaForm: VisaForm,
    CheckinErrorBoundary: CheckinErrorBoundary,
    DownloadAeroexpress: DownloadAeroexpress
};
