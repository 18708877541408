import { __read, __spreadArray } from "tslib";
export var getCategories = function (meals, segmentId) {
    var categories = new Set();
    meals.forEach(function (meal) {
        if (meal.category && meal.segmentsId.indexOf(segmentId) !== -1 && !categories.has(meal.category)) {
            categories.add(meal.category);
        }
    });
    return __spreadArray([], __read(categories), false);
};
export var getSelectedProductsBySegments = function (selectedProducts, segments) {
    return segments.map(function (segment, index) {
        if (selectedProducts.length <= index) {
            return [];
        }
        var products = [];
        for (var meal in selectedProducts[segment.segmentId].meals) {
            if (selectedProducts[segment.segmentId].meals.hasOwnProperty(meal) &&
                selectedProducts[segment.segmentId].meals[meal].count > 0) {
                products.push(selectedProducts[segment.segmentId].meals[meal]);
            }
        }
        return products;
    });
};
export var getSegmentsWithMeal = function (meals) {
    var segmentsWithMeal = new Set();
    meals.forEach(function (meal) { return meal.segmentsId.forEach(function (segmentId) { return segmentsWithMeal.add(segmentId); }); });
    return segmentsWithMeal;
};
export var getSegmentTitle = function (segment) {
    var isTerminal = window.__webskyEngineConfig.global.companyInfo.iataCode === 'TERMINAL';
    return isTerminal
        ? "".concat(segment.departure.iata, " \u2014 ").concat(segment.arrival.iata)
        : "".concat(segment.departure.name, " \u2014 ").concat(segment.arrival.name);
};
