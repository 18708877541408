import { format } from 'date-fns';
import { AviaPassengerType } from '../../../__generated__/globalTypes';
import { SPECIAL_CATEGORIES } from '../../../SearchForm/utils';
export var getIATA = function (data) {
    return data.substr(0, 3);
};
export var isDateGuard = function (date) {
    return !!date.getTime;
};
export var getAbsoluteDate = function (isoString) {
    return isoString.substr(0, 19);
};
export var getDateWithoutTimezone = function (date) {
    if (isDateGuard(date)) {
        var dateWithoutTimezone = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        dateWithoutTimezone.setMilliseconds(0);
        return dateWithoutTimezone;
    }
    else {
        var newDate = new Date(date);
        var dateWithoutTimezone = new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
        dateWithoutTimezone.setMilliseconds(0);
        return dateWithoutTimezone;
    }
};
export var getDate = function (data) {
    var year = data.substr(0, 4), month = data.substr(4, 2), day = data.substr(6, 2);
    return getDateWithoutTimezone("".concat(year, "-").concat(month, "-").concat(day));
};
export var getPassenger = function (data) {
    var type = data.substr(0, 3), count = parseInt(data.substr(3, 1)), isExtendedPassengerType = SPECIAL_CATEGORIES.includes(type);
    return {
        passengerType: isExtendedPassengerType ? AviaPassengerType.ADT : type,
        extendedPassengerType: isExtendedPassengerType ? type : null,
        count: count
    };
};
export var isPassengerNext = function (data) {
    return /^(ADT|CLD|INF|INS|МЛА|SCM|SCF)(.)*\d/.test(data);
};
export var extractPromoCode = function (fastSearch) {
    var matchedResult = fastSearch.match(/PROMO([a-zA-Zа-яА-Я0-9]{1,10})$/);
    return matchedResult ? matchedResult[matchedResult.length - 1] : matchedResult;
};
export var isPayByMiles = function (fastSearch) { return /PAYBYMILES$/.test(fastSearch); };
export var fastSearchParse = function (fastSearch) {
    var str = fastSearch;
    var segments = [], passengers = [];
    while (!isPassengerNext(str) && str.length) {
        var departure = getIATA(str), arrival = getIATA(str.substr(3)), date = getDate(str.substr(6));
        segments.push({
            arrival: {
                iata: arrival
            },
            departure: {
                iata: departure
            },
            date: format(date, 'yyyy-MM-dd')
        });
        str = str.substr(14);
    }
    while (isPassengerNext(str)) {
        passengers.push(getPassenger(str));
        str = str.substr(4);
    }
    if (!passengers.length) {
        passengers.push({
            passengerType: AviaPassengerType.ADT,
            count: 1
        });
    }
    var result = {
        segments: segments,
        passengers: passengers
    };
    if (extractPromoCode(fastSearch)) {
        result.promotionCode = extractPromoCode(fastSearch);
    }
    if (isPayByMiles(fastSearch)) {
        result.ffpMode = true;
    }
    return result;
};
export var isFastSearchCorrect = function (fastSearch) {
    return /^([A-Z]{6}\d{8})+((((ADT|МЛА)\d)|((SCF|SCM)\d)+)((CLD|INF|INS)\d)*)?((PROMO([a-zA-Zа-яА-Я0-9]{1,10}))|(PAYBYMILES))?$/.test(fastSearch);
};
