import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ThanksBanner from '../ThanksBanner/ThanksBanner';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../../utils';
import { Form } from '../Form/Form';
import { StepType } from '../../../types';
import { ThemeProvider, useTheme } from '../../../../theme';
import { setStep } from '../../../store/checkout/actions';
import { setPassengerValues } from '../../../store/passengers/actions';
import Actualization from '../Actualization/Actualization';
import { fillOrder } from '../../../store/order/actions';
import { getIsFareLockConfirmed, getIsFareLockSelected, getIsTravellersDataFilled, isBooked, isBookedOrConfirmed, isCancelled, isConfirmed, isConfirmedOrCancelled, isNew, isReadonly } from '../../../store/order/selectors';
import * as BookOrderQuery from '../../../../__queries__/BookOrder.graphql';
import { checkInternationalFlight, prepareBookingRequest } from '../../../utils';
import WarningModal from '../../../../WarningModal';
import Warnings from '../../../../Warnings';
import { addProduct, OrderGoal, ProductType, reachGoal } from '../../../../analytics';
import MobileToolbar from '../../../../MobileToolbar';
import { getPassengerStateCompatible, hasOrderSelectedServices } from '../../../store/selectedServices/selectors';
import { Aeroexpress, Baggage, Extras, Insurance, Loyalty, Meal, Seats } from '../../../../CheckoutAdditionalService/components';
import { useCheckoutServicesCardsAvailability } from '../../../../CheckoutAdditionalService/components/hooks';
import Modal from '../../../../Modal';
import PassengersToolbar from '../Form/PassengersToolbar/PassengersToolbar';
import { PromoMessages } from '../../../../PromoMessage/PromoMessage';
import * as CheckinInfoQuery from '../../../../__queries__/CheckinInfo.graphql';
import OrderRefundStatus from '../../../../Refund/Components/OrderRefundStatus/OrderRefundStatus';
import SimpleLoader from '../../../../SimpleLoader';
import OrderCard from '../../../../OrderCard';
import { useFareLock } from '../../../../FareLock/hooks';
import FareLock from '../../../../FareLock';
import { getPassengerName } from '../../../../Modules/Exchange/components/Exchange/utils';
import ExchangeCheckoutStatus from '../../../../Modules/Exchange/components/Exchange/ExchangeCheckoutStatus/ExchangeCheckoutStatus';
import PriorityBoarding from '../../../../CheckoutAdditionalService/components/PriorityBoarding/PriorityBoarding';
import SelectedFlight from '../SelectedFlight/SelectedFlight';
import { ActualizationStatus, FlightSegmentStatus } from '../../../../__generated__/globalTypes';
import { WarningRounded } from '../../../../Icons';
import { OverrideComponent } from '../../../../renderProps';
import AdditionalOrder from '../AdditionalOrder/AdditionalOrder';
import CapsuleHotel from '../../../../CheckoutAdditionalService/components/CapsuleHotel/CapsuleHotel';
import Notification from '../../../../Notification';
import ServiceCard from '../../../../LEO/ServicesCard/ServicesCard';
import { ServiceType } from '../OrderPaidServiceCards/types';
import { Ssr } from '../../../../LEO/Ssr/Ssr';
import { useIsTerminal } from '../../../../hooks';
import AdditionalBusiness from '../../../../AdditionalBusiness';
initI18n('Checkout');
var Passengers = function (_a) {
    var _b;
    var _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var order = _a.order, isBookedOrConfirmed = _a.isBookedOrConfirmed, isBooked = _a.isBooked, isNew = _a.isNew, passengers = _a.passengers, setBookingError = _a.setBookingError, bookingError = _a.bookingError, isCancelled = _a.isCancelled, setStep = _a.setStep, setPassengerValues = _a.setPassengerValues, backToResults = _a.backToResults, currentStep = _a.currentStep, _o = _a.showServiceCards, showServiceCardsProps = _o === void 0 ? true : _o, fillOrder = _a.fillOrder, isReadonly = _a.isReadonly, isConfirmed = _a.isConfirmed, isConfirmedOrCancelled = _a.isConfirmedOrCancelled, goToPrevStep = _a.goToPrevStep, goToExchange = _a.goToExchange, goToRefund = _a.goToRefund, refetchOrder = _a.refetchOrder, openOrder = _a.openOrder, hasOrderSelectedServices = _a.hasOrderSelectedServices, userInfo = _a.userInfo, refetchUser = _a.refetchUser;
    var css = useTheme('Checkout').CheckoutStyles;
    var _p = __read(useState(null), 2), innerFormValues = _p[0], setInnerFormValues = _p[1];
    var _q = __read(useState(false), 2), isDetailsOpen = _q[0], setDetailsOpen = _q[1];
    var _r = __read(useState(false), 2), showActualizationResults = _r[0], setShowActualizationResults = _r[1];
    var _s = __read(useMutation(BookOrderQuery.BookOrder), 2), bookOrder = _s[0], loading = _s[1].loading;
    // const [cancelOrder] = useMutation<CancelOrderResponse, CancelOrderVariables>(CancelOrderQuery.CancelOrder);
    var servicesAvailability = useCheckoutServicesCardsAvailability();
    var isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    var noAvailableServices = Object.keys(servicesAvailability).every(function (key) { return servicesAvailability[key] === false; });
    var isFareLockSelected = useSelector(getIsFareLockSelected);
    var isFareLockConfirmed = useSelector(getIsFareLockConfirmed);
    var t = useTranslation('Checkout').t;
    var isTerminal = useIsTerminal();
    var passengersNames = (_e = (_d = (_c = order === null || order === void 0 ? void 0 : order.exareInfo) === null || _c === void 0 ? void 0 : _c.lastExchange) === null || _d === void 0 ? void 0 : _d.travellers.map(function (traveller) { return getPassengerName(traveller); })) === null || _e === void 0 ? void 0 : _e.join(', ');
    var detailsStyles = useMemo(function () { return ({
        DesktopSummaryFlight: {
            SummaryFlight: {
                flight: css.desktopFlight__flight
            }
        },
        Passenger: {
            PassengerStyles: {
                passenger: css.form__passenger,
                header__text: css.form__passengerHeaderText
            }
        }
    }); }, [order.id]);
    var getCheckinInfoResponse = useQuery(CheckinInfoQuery.CheckinInfo, {
        skip: !isConfirmedOrCancelled || isTerminal,
        variables: {
            params: {
                aviaOrderId: order.id
            }
        }
    }).data;
    var closeDetails = useCallback(function () {
        setDetailsOpen(false);
    }, [isDetailsOpen]);
    var clearError = function () { return setBookingError(null); };
    var startBooking = function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    reachGoal(OrderGoal.Book);
                    return [4 /*yield*/, bookOrder({ variables: prepareBookingRequest(order.id, formValues) })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (errors && errors.length) {
                        setBookingError(errors[0]);
                        reachGoal(OrderGoal.BookingError, {
                            error: errors[0]
                        });
                    }
                    else if (data && data.BookOrder) {
                        fillOrder(data.BookOrder);
                        addProduct({
                            id: data.BookOrder.id,
                            category: ProductType.Order,
                            price: data.BookOrder.priceToPay.total.amount
                        });
                        reachGoal(OrderGoal.Booked);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    order.travellers.forEach(function (traveller, index) {
                        var passengerValues = traveller.values.map(function (value) {
                            return {
                                name: value.name,
                                type: value.type,
                                value: formValues['passengers'][index][value.name]
                            };
                        });
                        setPassengerValues(index, passengerValues);
                    });
                    setBookingError(error_1);
                    reachGoal(OrderGoal.BookingError, {
                        error: error_1
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onFormSubmit = useCallback(function (values) {
        order.travellers.forEach(function (traveller, index) {
            var passengerValues = traveller.values.map(function (value) {
                return {
                    name: value.name,
                    type: value.type,
                    value: values['passengers'][index][value.name]
                };
            });
            setPassengerValues(index, passengerValues);
        });
        if (order.actualizationResult.status !== ActualizationStatus.Success) {
            setShowActualizationResults(true);
            setInnerFormValues(values);
            return;
        }
        startBooking(values);
    }, [order.travellers, startBooking]);
    // const onCancelOrder = async () => {
    // 	try {
    // 		setIsLoading(true);
    // 		const { data } = await cancelOrder({ variables: { id: order.id } });
    //
    // 		if (data && data.CancelOrder) {
    // 			fillOrder(data.CancelOrder);
    // 			setIsLoading(false);
    // 		}
    // 	} catch (e) {
    // 		console.error(e);
    // 		setIsLoading(false);
    // 	}
    // };
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    useEffect(function () {
        // if there is no available services in mobile, redirect to payment
        if (Object.keys(servicesAvailability).every(function (key) { return servicesAvailability[key] === false; }) &&
            isBooked &&
            isMobile) {
            setStep(StepType.Payment);
        }
    }, [isBooked]);
    var handlePriceChangedConfirmed = useCallback(function () {
        startBooking(innerFormValues);
    }, [startBooking, innerFormValues]);
    var _t = useFareLock(order, fillOrder), handleChangeFareLock = _t.handleChangeFareLock, isSelected = _t.isSelected, price = _t.price, fareLockService = _t.fareLockService;
    var renderFlights = useCallback(function () { return React.createElement(SelectedFlight, { orderId: order.id, flight: order.flight, confirmed: false }); }, [order.flight]);
    var renderForm = useCallback(function (inModal) {
        if (inModal) {
            console.log('h1');
            return (React.createElement(OverrideComponent, { componentProps: {
                    showToolbar: false,
                    showServices: false,
                    currentStep: currentStep,
                    customer: order.customer,
                    passengers: order.travellers,
                    onFormSubmit: onFormSubmit,
                    isInternationalFlight: checkInternationalFlight(order.flight),
                    userInfo: userInfo,
                    refetchUser: refetchUser,
                    inModal: true
                }, component: { renderCheckoutForm: Form } }));
        }
        else {
            if (isBooked) {
                if (isFareLockSelected && !isFareLockConfirmed) {
                    console.log('h2');
                    return (React.createElement(React.Fragment, null,
                        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                            isBooked && (React.createElement(MobileToolbar, { onBack: goToPrevStep, onContinue: function () { return setStep(StepType.Payment); } })),
                            isConfirmed && React.createElement(PassengersToolbar, { currentStep: currentStep })),
                        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, isBookedOrConfirmed && (isTravellersDataFilled || isFareLockSelected) && (React.createElement(PassengersToolbar, { currentStep: currentStep })))));
                }
                console.log('h3');
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css.form },
                        React.createElement(OverrideComponent, { componentProps: {
                                showServices: false,
                                showContacts: false,
                                showForm: !isTravellersDataFilled,
                                currentStep: currentStep,
                                customer: order.customer,
                                passengers: order.travellers,
                                onFormSubmit: onFormSubmit,
                                isInternationalFlight: checkInternationalFlight(order.flight),
                                userInfo: userInfo,
                                refetchUser: refetchUser
                            }, component: { renderCheckoutForm: Form } }))));
            }
            if (isNew || isConfirmed) {
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css.form },
                        React.createElement(OverrideComponent, { componentProps: {
                                showContacts: isNew,
                                showForm: isNew || !isTravellersDataFilled,
                                showServices: false,
                                currentStep: currentStep,
                                customer: order.customer,
                                passengers: order.travellers,
                                onFormSubmit: onFormSubmit,
                                isInternationalFlight: checkInternationalFlight(order.flight),
                                userInfo: userInfo,
                                refetchUser: refetchUser
                            }, component: { renderCheckoutForm: Form } }))));
            }
            return null;
        }
    }, [order.status, isFareLockSelected, isFareLockConfirmed, currentStep, isTravellersDataFilled]);
    var canceledFlights = React.useMemo(function () {
        return order.flight.segmentGroups.filter(function (group) {
            return group.segments.some(function (segment) { return segment.status === FlightSegmentStatus.Canceled; });
        });
    }, [order.flight]);
    var isShowFlightsAndServiceCards = (isBookedOrConfirmed || (isTerminal && isCancelled)) && isTravellersDataFilled;
    var isShowServiceCards = showServiceCardsProps &&
        ((!noAvailableServices && !isCancelled) || (isTerminal && isCancelled && hasOrderSelectedServices));
    return (React.createElement("div", { className: cn(css.checkout, (_b = {},
            _b[css.checkout_margined] = isCancelled,
            _b)) },
        loading ? React.createElement(SimpleLoader, null) : null,
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An unexpected error has occurred during the booking process. Please try again later or contact a customer support service.'), errorMessage: bookingError ? bookingError.message.replace('GraphQL error: ', '') : '', onClose: clearError, onButtonClick: clearError, buttonText: t('OK, thank you'), isOpen: !!bookingError }),
        React.createElement("div", null,
            isConfirmed && (React.createElement(OverrideComponent, { componentProps: { orderId: order.id }, component: { ThanksBanner: ThanksBanner } })),
            isConfirmed && canceledFlights.length > 0 && (React.createElement("div", { className: css.notice },
                WarningRounded,
                ' ',
                t('Attention, times have changed for flight {{route}}', {
                    route: "".concat(canceledFlights[0].segments[0].departure.airport.city.name, " - ").concat(canceledFlights[0].segments[canceledFlights[0].segments.length - 1].arrival.airport.city
                        .name)
                }))),
            order.splittedOrdersIds.map(function (orderId) {
                return React.createElement(AdditionalOrder, { orderId: orderId, key: orderId, isNew: true });
            }),
            order.parentOrderId && React.createElement(AdditionalOrder, { orderId: order.parentOrderId, isNew: false }),
            !isReadonly && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { className: css.passengers__header },
                    React.createElement("h1", { className: css.passengers__header__title }, t('Passengers'))))),
            React.createElement(OrderRefundStatus, { show: isConfirmed && !!order.exareInfo.lastRefund, status: (_f = order.exareInfo.lastRefund) === null || _f === void 0 ? void 0 : _f.status, onGoToTicket: goToRefund, passengersNames: passengersNames }),
            React.createElement(ExchangeCheckoutStatus, { show: isConfirmed && !order.exareInfo.lastRefund && isConfirmed && !!order.exareInfo.lastExchange, newBookingId: order === null || order === void 0 ? void 0 : order.splittedOrdersIds[0], status: (_g = order.exareInfo.lastExchange) === null || _g === void 0 ? void 0 : _g.status, passengersNames: passengersNames, onGoToTicket: goToExchange }),
            isConfirmedOrCancelled && (React.createElement("div", { className: css.paidOrderWrapper },
                React.createElement(OrderCard, { goToRefund: goToRefund, goToExchange: goToExchange, order: order, isCheckinOpen: (_h = getCheckinInfoResponse === null || getCheckinInfoResponse === void 0 ? void 0 : getCheckinInfoResponse.CheckinInfo) === null || _h === void 0 ? void 0 : _h.isAvailable, type: 'review', refetchOrder: refetchOrder, openOrder: openOrder }))),
            isBooked && (React.createElement("div", { className: css.summary__wrp },
                !isTerminal && (React.createElement(React.Fragment, null,
                    React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                        React.createElement("h3", { className: css.summary__header }, t('Review your trip'))))),
                order.messages && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css.messages }, order.messages.map(function (message, index) { return (React.createElement(Notification, { key: index, className: css.messages_item, title: message.title, image: message.image, status: message.type, infoMessage: message, type: 'context' })); })))),
                !isConfirmed && isFareLockSelected && (React.createElement(FareLock, { freezeUntil: fareLockService.freezeUntil, price: price, onChange: handleChangeFareLock, isActive: isSelected, confirmed: isSelected || isFareLockConfirmed, addedToOrder: true })),
                React.createElement("div", { className: css.summary },
                    React.createElement(OrderCard, { goToRefund: goToRefund, goToExchange: goToExchange, order: order, isCheckinOpen: ((_j = getCheckinInfoResponse === null || getCheckinInfoResponse === void 0 ? void 0 : getCheckinInfoResponse.CheckinInfo) === null || _j === void 0 ? void 0 : _j.isAvailable) || true, type: 'review', refetchOrder: refetchOrder, openOrder: openOrder })))),
            isTerminal && (isConfirmedOrCancelled || isBooked) && (React.createElement(Ssr, { orderId: order.id, ssr: order.ssrData, travellers: order.travellers, segments: order.flight.segments })),
            isShowFlightsAndServiceCards && (React.createElement(React.Fragment, null,
                renderFlights(),
                isShowServiceCards && (React.createElement("div", { className: css.checkoutServices },
                    servicesAvailability.Seat &&
                        (isTerminal ? (React.createElement(ServiceCard, { fullWidth: true, type: ServiceType.Seats })) : (React.createElement(Seats, { isCheckinAvailable: (_k = getCheckinInfoResponse === null || getCheckinInfoResponse === void 0 ? void 0 : getCheckinInfoResponse.CheckinInfo) === null || _k === void 0 ? void 0 : _k.isAvailable }))),
                    servicesAvailability.Baggage &&
                        (isTerminal ? React.createElement(ServiceCard, { fullWidth: true, type: ServiceType.Baggage }) : React.createElement(Baggage, null)),
                    servicesAvailability.Meal &&
                        (isTerminal ? React.createElement(ServiceCard, { fullWidth: true, type: ServiceType.Meal }) : React.createElement(Meal, null)),
                    (servicesAvailability.Aeroexpress || servicesAvailability.Insurance) && (React.createElement(React.Fragment, null,
                        servicesAvailability.Insurance && React.createElement(Insurance, null),
                        servicesAvailability.Aeroexpress && React.createElement(Aeroexpress, null),
                        (((_l = order === null || order === void 0 ? void 0 : order.ffpInfo) === null || _l === void 0 ? void 0 : _l.bonusForFlight) || ((_m = order === null || order === void 0 ? void 0 : order.ffpInfo) === null || _m === void 0 ? void 0 : _m.ffpSupported)) && (React.createElement(Loyalty, null)))),
                    servicesAvailability.BusinessLounge && React.createElement(AdditionalBusiness, null),
                    servicesAvailability.PriorityBoarding && React.createElement(PriorityBoarding, null),
                    servicesAvailability.BusinessLounge && React.createElement(CapsuleHotel, null),
                    servicesAvailability.Misc &&
                        (isTerminal ? React.createElement(ServiceCard, { fullWidth: true, type: ServiceType.Misc }) : React.createElement(Extras, null)))))),
            !isReadonly && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { className: css.passengers__header },
                    React.createElement("h1", { className: css.passengers__header__title }, t('Passengers'))))),
            isNew && (React.createElement(ThemeProvider, { value: {
                    PromoMessage: {
                        PromoMessage: {
                            wrapper: css.promoMessageWrapper
                        }
                    }
                } },
                order.messages && (React.createElement("div", { className: css.messages }, order.messages.map(function (message, index) { return (React.createElement(Notification, { key: index, className: css.messages_item, title: message.title, image: message.image, status: message.type, infoMessage: message, type: 'context' })); }))),
                React.createElement(PromoMessages, null))),
            React.createElement(ThemeProvider, { value: detailsStyles },
                React.createElement(Modal, { open: isDetailsOpen, onClose: closeDetails, classes: { root: css.detailsModal, paper: css.detailsModal__paper } },
                    React.createElement("div", { className: css.detailsModal__header }, t('Details')),
                    React.createElement("div", { className: css.detailsModal__content }, renderForm(true))),
                React.createElement("br", null),
                renderForm()),
            !isBookedOrConfirmed && order.warnings && !!order.warnings.length && (React.createElement(Warnings, { warnings: order.warnings }))),
        !isBookedOrConfirmed && (React.createElement(Actualization, { startBooking: handlePriceChangedConfirmed, orderId: order.id, open: showActualizationResults, onClose: function () { return setShowActualizationResults(false); }, onSetOrder: fillOrder, actualizationInfo: order.actualizationResult, backToResults: backToResults, showOkMessage: false }))));
};
var mapStateToProps = function (state, ownProps) { return (__assign(__assign({}, ownProps), { passengers: getPassengerStateCompatible(state), isCancelled: isCancelled(state), isReadonly: isReadonly(state), isBooked: isBooked(state), isNew: isNew(state), isBookedOrConfirmed: isBookedOrConfirmed(state), isConfirmed: isConfirmed(state), isConfirmedOrCancelled: isConfirmedOrCancelled(state), hasOrderSelectedServices: hasOrderSelectedServices(state) })); };
var mapDispatchToProps = {
    setPassengerValues: setPassengerValues,
    setStep: setStep,
    fillOrder: fillOrder
};
export default connect(mapStateToProps, mapDispatchToProps)(Passengers);
