import { __read } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Modal from '../../../Modal';
import { Button } from '../../../index';
import { useTheme } from '../../../theme';
import { getExchangeAvailability, getSplitAvailability } from '../../../Checkout/store/order/selectors';
var VoidPopup = function (_a) {
    var className = _a.className, goToExchange = _a.goToExchange;
    var _b = __read(React.useState(false), 2), open = _b[0], setOpen = _b[1];
    var isExchangeAvailable = useSelector(getExchangeAvailability);
    var isCanSplit = useSelector(getSplitAvailability);
    var onOpen = function () { return setOpen(true); };
    var onClose = function () { return setOpen(false); };
    var t = useTranslation('Exchange').t;
    var css = useTheme('VoidPopup').VoidPopup;
    return (React.createElement(React.Fragment, null,
        isExchangeAvailable && (React.createElement(Button, { onClick: onOpen, className: cn(css.exchange, className) }, t('Change'))),
        React.createElement(Modal, { isRounded: true, open: open, onClose: onClose, maxWidth: "sm", classes: {
                paper: css.dialog
            } },
            React.createElement("div", { className: css.header }, t('Exchange ticket')),
            React.createElement("div", null, isCanSplit
                ? "".concat(t('Click Continue if you are going exchange the tickets for all passengers in the PNR.'), "\n\t\t\t\t\t\t\t   ").concat(t('If you want to make a exchange for some passengers, return to the PNR and provide the Split previously.'))
                : t('Click Continue if you are going exchange the tickets for all passengers in the PNR.')),
            React.createElement("div", { className: css.control },
                React.createElement(Button, { onClick: goToExchange, variant: "flat" }, t('Continue'))))));
};
export default VoidPopup;
