import { __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import { arrowIcon, infoIcon } from '../Icons';
import { useTheme } from '../../../theme';
var Warning = function (_a) {
    var _b;
    var warning = _a.warning;
    var t = useTranslation('Warnings').t;
    var theme = useTheme('MealService').Warning;
    var _c = __read(useState(false), 2), warningIsOpen = _c[0], setWarningIsOpen = _c[1];
    var toggleWarning = function () { return setWarningIsOpen(function (state) { return !state; }); };
    return (React.createElement("div", { className: theme.warning },
        React.createElement("div", { className: theme.warning__trigger, onClick: toggleWarning },
            React.createElement("span", { className: theme.warning__title },
                infoIcon,
                " ",
                t('Clarification')),
            React.createElement("span", { className: cn(theme.warning__arrow, (_b = {},
                    _b[theme.warning__arrow_rotated] = warningIsOpen,
                    _b)) }, arrowIcon)),
        React.createElement(Collapse, { in: warningIsOpen },
            React.createElement("div", { className: theme.warning__text }, t(warning)))));
};
export default Warning;
