import * as React from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { getFlightsToExchange } from '../../../Modules/Exchange/store/selectors';
import { useTheme } from '../../../theme';
import { ExchangeStep } from '../../../Modules/Exchange/components/Exchange/Inner/Inner';
import Chip from '../../../Modules/Exchange/components/Exchange/Inner/Selections/Chip/Chip';
import { getSelectedReason } from '../../../Modules/Exchange/store/reason/selectors';
import { useTranslation } from 'react-i18next';
import { getNewFlightId } from '../../../Modules/Exchange/store/newFlights/selectors';
var Selections = function (props) {
    var location = useLocation();
    var t = useTranslation('Exchange').t;
    var selectedSegments = useSelector(getFlightsToExchange);
    var selectedReason = useSelector(getSelectedReason);
    var isConfirmedExchange = !useSelector(getNewFlightId);
    var theme = useTheme('Exchange').Selections;
    var toSegments = function () { return props.setStep(ExchangeStep.Segments); };
    var toReasons = function () { return props.setStep(ExchangeStep.Reasons); };
    var isFlightPage = location.pathname.indexOf('segments') >= 0;
    var isReasonsPage = location.pathname.indexOf('reasons') >= 0;
    var isRequestPage = location.pathname.indexOf('request') >= 0;
    var isPaymentPage = location.pathname.indexOf('payment') >= 0;
    var isReadOnly = isRequestPage && isConfirmedExchange;
    if (isPaymentPage) {
        return null;
    }
    return (React.createElement("div", { className: theme.wrapper },
        !isReasonsPage && (React.createElement(Chip, { className: theme.chip, label: t(selectedReason), onClick: toReasons, type: "passenger", readonly: isReadOnly })),
        !isFlightPage &&
            !isReasonsPage &&
            (selectedSegments === null || selectedSegments === void 0 ? void 0 : selectedSegments.flightsByIds.map(function (segment) {
                return (React.createElement(Chip, { label: "".concat(segment.segments[0].departure.airport.iata, " - ").concat(segment.segments[segment.segments.length - 1].arrival.airport.iata), onClick: toSegments, key: "s_".concat(segment.groupId), type: "flight", readonly: isReadOnly, className: theme.chip }));
            }))));
};
export default Selections;
