import { __assign } from "tslib";
import * as React from 'react';
import { useCallback, useContext, useMemo } from 'react';
import Slider from 'react-slick';
import { ThemeProvider, useTheme } from '../../theme';
import { useFareGroupsMap, useIsFullScreenFareModal } from '../hooks';
import FareSelect from './FareSelect/FareSelect';
import { useDesktopFlightWithPopup } from '../../DesktopFlight/components/DesktopFlightPopup/hooks';
import Modal from '../../Modal';
import DesktopFlightModalContent from '../../DesktopFlight/components/DesktopFlightModalContent/DesktopFlightModalContent';
import SegmentInfo from './SegmentInfo/SegmentInfo';
import TotalDuration from '../../DesktopFlightRoute/components/TotalDuration/TotalDuration';
import cn from 'classnames';
import { RenderersProvider } from '../../renderProps';
import { useTranslation } from 'react-i18next';
import FareConditions from '../../FareConditions/components/Component';
import { useIsTerminal } from '../../hooks';
import { ActualizedPriceContext } from '../../Results/utils';
var FlightSelect = function (props) {
    var _a, _b;
    var _c, _d;
    var t = useTranslation('FlightSelect').t;
    var renderRouteInfo = props.renderRouteInfo, slideRenderer = props.renderSlide, flightId = props.flightId, sliderRenderer = props.renderSlider;
    var popupCss = useTheme('DesktopFlight').DesktopFlightPopup;
    var css = useTheme('FlightSelect').FlightSelect;
    var isTerminal = useIsTerminal();
    var actualizationInProgress = useContext(ActualizedPriceContext).actualizationInProgress;
    var isFullScreenFareModal = useIsFullScreenFareModal();
    // hook from <DesktopFlightWithPopup /> component which provide all needed data
    var _e = useDesktopFlightWithPopup(props), getOnFareSelect = _e.getOnFareSelect, flight = _e.flight, lastSelectedFare = _e.lastSelectedFare, businessFareGroups = _e.businessFareGroups, economyFareGroups = _e.economyFareGroups, milesFareGroups = _e.milesFareGroups, openModal = _e.openModal, isModalOpen = _e.isModalOpen, closeModal = _e.closeModal, upgradeOptions = _e.upgradeOptions, openModalWithInitialTab = _e.openModalWithInitialTab, initialTab = _e.initialTab;
    var lastSegment = flight.segments[flight.segments.length - 1].segment;
    var _f = useFareGroupsMap(flight, props.selectedFares), fareGroupsMap = _f.fareGroupMap, nonEmptyFlightClassesCount = _f.nonEmptyFlightClassesCount;
    var sliderSettings = useMemo(function () { return ({
        slidesToShow: nonEmptyFlightClassesCount <= 2 ? nonEmptyFlightClassesCount : 2,
        slidesToScroll: nonEmptyFlightClassesCount <= 2 ? nonEmptyFlightClassesCount : 2,
        adaptiveHeight: true
    }); }, []);
    var modalContentStyles = useMemo(function () { return ({
        DesktopFlight: {
            ModalContent: {
                fareGroup__button_right: css.modal__button_right
            }
        },
        Modal: {
            Modal: {
                scrollBody: css.modal__scrollBody
            }
        }
    }); }, []);
    var renderTotalDuration = useCallback(function () { return React.createElement(TotalDuration, { lastSegment: lastSegment, flight: flight }); }, [
        lastSegment,
        flight
    ]);
    var renderInfo = renderRouteInfo !== null && renderRouteInfo !== void 0 ? renderRouteInfo : useCallback(function (segments) {
        return segments.map(function (segment, key) { return (React.createElement(SegmentInfo, { isSingleSegment: flight.segments.length === 1, segment: segment, key: segment.segment.id, isSelected: props.isSelected, isShowDateColumn: !props.isResultsPage && !props.isSchedulePage && !props.isAvailabilityPage, isShowPlaneInfo: !props.isAvailabilityPage, first: key === 0, last: key === flight.segments.length - 1, stops: key > 0 ? flight.segments[key - 1].segment.stops : [], singleFlight: false, totalDuration: key === flight.segments.length - 1 && renderTotalDuration(), nextDay: key >= 1 && flight.segments[key - 1].segment.departure.date !== segment.segment.arrival.date })); });
    }, [renderRouteInfo, flight]);
    var renderSlide = slideRenderer !== null && slideRenderer !== void 0 ? slideRenderer : useCallback(function (fareFamilies, flightClass, isSelected) {
        return (React.createElement(FareSelect, { flightClass: flightClass, isSelected: isSelected, onOpenModal: function () {
                openModalWithInitialTab(flightClass);
            }, key: flightClass, fares: fareFamilies, onSelect: getOnFareSelect, hidePrice: props.hidePrice }));
    }, [props.flight, props.selectedFares, props.hidePrice]);
    var renderSlider = useCallback(function (isSelected) {
        var slides = [];
        fareGroupsMap.forEach(function (value, key) {
            if (!value.length) {
                return;
            }
            slides.push(renderSlide(value, key, isSelected));
        });
        if (sliderRenderer) {
            return sliderRenderer({
                flight: flight,
                orderId: props.orderId,
                isSelected: props.isSelected,
                flightId: props.flightId,
                slides: slides
            });
        }
        return (React.createElement("div", { className: css.slider__wrapper },
            React.createElement(Slider, __assign({}, sliderSettings, { slidesToShow: slides.length < 2 ? 1 : sliderSettings.slidesToShow }), slides)));
    }, [props.flight, props.selectedFares, sliderRenderer]);
    var renderCity = useCallback(function (props) {
        var _a;
        return (React.createElement("span", { className: cn(css.city, (_a = {}, _a[css.withoutTerminal] = !props.terminal, _a)) },
            props.iata,
            props.terminal && (React.createElement(React.Fragment, null,
                ", ",
                isTerminal ? null : t('Terminal'),
                "\u00A0",
                props.terminal))));
    }, []);
    var renderSegmentsStatus = useCallback(function () {
        var isPriceActualized = props.flight.segments.some(function (segment) { var _a; return !((_a = segment.segment) === null || _a === void 0 ? void 0 : _a.status); });
        return (React.createElement("div", { className: cn(css.selected, css.selected__status) }, isPriceActualized || actualizationInProgress ? (React.createElement("div", { className: css.segmentStatus }, actualizationInProgress ? t('Updating price') : t('Price updated'))) : (props.flight.segments.map(function (_a) {
            var segment = _a.segment;
            return (React.createElement("div", { className: css.segmentStatus },
                isTerminal && (React.createElement("span", { className: css.segmentStatusCode },
                    segment.statusCode,
                    "\u00A0")),
                React.createElement("span", null, segment.status)));
        }))));
    }, [props.flight.segments, actualizationInProgress]);
    var renderPlaceForDuration = useCallback(function () {
        var _a;
        var _b, _c;
        if (!(flight.segments.length > 1 || ((_b = lastSegment.stops) === null || _b === void 0 ? void 0 : _b.length) > 0)) {
            return null;
        }
        var isMultiLine = flight.segments.length > 1 && ((_c = lastSegment.stops) === null || _c === void 0 ? void 0 : _c.length) > 0;
        return React.createElement("div", { className: cn(css.duration, (_a = {}, _a[css.duration_multiline] = isMultiLine, _a)) });
    }, [flight.segments, lastSegment.stops]);
    return (React.createElement(React.Fragment, null,
        React.createElement(RenderersProvider, { value: { renderInfoUnderTime: renderCity } },
            React.createElement("div", { className: cn(css.flight, props.className), onClick: props.isSelected && openModal },
                React.createElement("div", { className: cn(css.segments, (_a = {},
                        _a[css.segments_withoutPlaneInfo] = props.isAvailabilityPage,
                        _a)) },
                    renderInfo(flight.segments),
                    renderPlaceForDuration()),
                props.isSelected && renderSegmentsStatus(),
                renderSlider(props.isSelected)),
            React.createElement(ThemeProvider, { value: modalContentStyles },
                React.createElement(Modal, { isRounded: true, scroll: !isFullScreenFareModal ? 'paper' : 'body', fullScreen: isFullScreenFareModal, open: isModalOpen, onClose: closeModal, classes: {
                        paper: cn(popupCss.paper, (_b = {},
                            _b[css.fareConditionsModal] = props.isSelected,
                            _b)),
                        scrollBody: popupCss.scrollBody
                    } }, props.isSelected ? (React.createElement(FareConditions, { flightId: (_c = flight['id']) !== null && _c !== void 0 ? _c : flightId, orderId: props.orderId })) : (React.createElement(DesktopFlightModalContent, { lastSelectedFare: (_d = props.flight.pricesForFareGroups.find(function (fareGroup) {
                        return fareGroup.fareFamily && fareGroup.fareFamily.id === lastSelectedFare;
                    })) === null || _d === void 0 ? void 0 : _d.fareFamily, flight: flight, economyFares: economyFareGroups, businessFares: businessFareGroups, milesFares: milesFareGroups, getOnFareSelect: getOnFareSelect, upgradeOptions: upgradeOptions, activeTab: initialTab, hidePrice: props.hidePrice })))))));
};
export default FlightSelect;
