import SeatAvailability from './components/SeatAvailability.css';
import SeatAvailabilityModal from './components/SeatAvailabilityModal/SeatAvailabilityModal.css';
import FlightsList from './components/FlightsList/FlightsList.css';
import FlightSeat from './components/FlightSeat/FlightSeat.css';
import DateSwitcher from './components/DateSwitcher/DateSwitcher.css';
import Datepicker from './components/DateSwitcher/Datepicker/Datepicker.css';
export default {
    SeatAvailability: SeatAvailability,
    SeatAvailabilityModal: SeatAvailabilityModal,
    FlightsList: FlightsList,
    FlightSeat: FlightSeat,
    DateSwitcher: DateSwitcher,
    Datepicker: Datepicker
};
