import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { useDispatch, useSelector } from 'react-redux';
import { getAeroexpress, getOrderId, getTravellers } from '../../../Checkout/store/order/selectors';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { currentCurrency, format, useIsMobile } from '../../../utils';
import icons from '../../icons';
import { useOrderServices } from '../../../__queries__/Order';
import Modal from '../../../Modal';
import { useAeroexpress } from '../../../Aeroexpress/components/hooks';
import { parse } from 'date-fns';
import { getAeroexpressPrice } from '../../../Checkout/store/orderPrice/selectors';
import AeroexpressPopup from '../../../AeroexpressPopup';
import { fillOrder } from '../../../Checkout/store/order/actions';
var AeroexpressAdditionalService = function () {
    var _a, _b, _c;
    var t = useTranslation('Checkout').t;
    var _d = useTheme('CheckoutAdditionalService'), cardStyles = _d.Aeroexpress, commonStyles = _d.CheckoutAdditionalService;
    var css = useTheme('Aeroexpress').Aeroexpress;
    var _e = __read(useState(false), 2), aeroexpressPopup = _e[0], setAeroexpressPopup = _e[1];
    var dispatch = useDispatch();
    var aeroexpress = useSelector(getAeroexpress);
    var isSelected = ((_a = useSelector(getAeroexpressPrice)) === null || _a === void 0 ? void 0 : _a.amount) > 0;
    var orderId = useSelector(getOrderId);
    var travellers = useSelector(getTravellers);
    var selectedTrips = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.selectedTrips;
    var isMobile = useIsMobile();
    var minPrice = {
        amount: (_c = (_b = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.trips) === null || _b === void 0 ? void 0 : _b.map(function (trip) { return trip.price.amount; }).sort()[0]) !== null && _c !== void 0 ? _c : 0,
        currency: currentCurrency
    };
    var selectedTripsPrice = selectedTrips.reduce(function (price, selectedTrip) { return ({ amount: selectedTrip.price.amount + price.amount, currency: currentCurrency }); }, {
        amount: 0,
        currency: currentCurrency
    });
    var openModal = function () { return setAeroexpressPopup(true); };
    var closeModal = function () { return setAeroexpressPopup(false); };
    var _f = __read(useOrderServices(), 2), saveOrderServicesMutation = _f[0], loading = _f[1].loading;
    var selectAeroexpress = function (ids) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, saveOrderServicesMutation({
                            variables: {
                                params: {
                                    id: orderId,
                                    aeroexpress: { selectedTrips: __spreadArray([], __read(ids), false) }
                                }
                            }
                        })];
                case 1:
                    data = (_a.sent()).data;
                    dispatch(fillOrder(data === null || data === void 0 ? void 0 : data.SaveOrderServices));
                    closeModal();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _g = useAeroexpress(selectAeroexpress, orderId), showWarning = _g.showWarning, servicesRedirectURL = _g.servicesRedirectURL, closeWarning = _g.closeWarning, buttonText = _g.buttonText;
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckoutAdditionalService, { isLoading: loading, className: cardStyles.aeroexpress, header: t('Aeroexpress'), isSelected: isSelected, priceFrom: minPrice, priceTotal: selectedTripsPrice, description: t('Aeroexpress:No worries about traffic jams, arrival at the airport strictly on schedule'), addClick: openModal, icon: icons.transferIcon, buttons: [
                {
                    action: openModal,
                    isLoading: loading,
                    text: buttonText
                },
                !loading && {
                    action: function () { return open('https://aeroexpress.ru', '_blank'); },
                    text: t('Aeroexpress:View schedule')
                }
            ] },
            React.createElement("ul", { className: commonStyles.list }, aeroexpress.selectedTrips.map(function (selectedTrip) { return (React.createElement("li", { key: selectedTrip.id, className: commonStyles.list__item },
                React.createElement("span", { className: commonStyles.list__cities },
                    format(parse(selectedTrip.departureDate, 'HH:mm dd.MM.yyyy', new Date()), 'dd.MM.yyyy'),
                    "\u00A0",
                    t("Aeroexpress:".concat(selectedTrip.toStation.direction))),
                React.createElement("span", { className: commonStyles.list__services }, t("Aeroexpress:".concat(selectedTrip.class))))); }))),
        React.createElement(Modal, { open: aeroexpressPopup, slideUp: isMobile, classes: {
                root: css.aeroexpress_root,
                paper: css.aeroexpress_paper,
                scrollBody: css.scrollBody
            } },
            React.createElement(AeroexpressPopup, { trips: aeroexpress.trips, selectedTrips: aeroexpress.selectedTrips, travellers: travellers, isLoading: loading, onClose: closeModal, onSubmit: selectAeroexpress })),
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning })));
};
export default AeroexpressAdditionalService;
