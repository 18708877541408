import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import Button from '../../Button';
import { useTheme } from '../theme';
import { initI18n } from '../../utils';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import Field from './Field/Field';
import { useMutation } from '@apollo/react-hooks';
import * as RequestFfpMiles from '../../__queries__/RequestFfpMilesForOrder.graphql';
import * as FindOrder from '../../__queries__/FindOrder.graphql';
initI18n('MilesRecovery');
var MilesRecovery = function (props) {
    var theme = useTheme().MilesRecoveryStyles;
    var t = useTranslation('MilesRecovery').t;
    var _a = __read(useMutation(RequestFfpMiles.RequestFfpMilesForOrder), 1), requestMutation = _a[0];
    var _b = __read(useMutation(FindOrder.FindOrder), 1), findOrder = _b[0];
    var _c = __read(React.useState(''), 2), success = _c[0], setSuccessMessage = _c[1];
    var onSubmit = function (values) {
        var request = function () { return __awaiter(void 0, void 0, void 0, function () {
            var order, requestMiles;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, findOrder({
                            variables: {
                                params: {
                                    id: values['number'].replace(/\s+/g, ''),
                                    secret: values['lastName']
                                }
                            }
                        })];
                    case 1:
                        order = _d.sent();
                        if (!((_b = (_a = order === null || order === void 0 ? void 0 : order.data) === null || _a === void 0 ? void 0 : _a.FindOrder) === null || _b === void 0 ? void 0 : _b.id)) return [3 /*break*/, 3];
                        return [4 /*yield*/, requestMutation({
                                variables: {
                                    orderId: order.data.FindOrder.id
                                }
                            })];
                    case 2:
                        requestMiles = _d.sent();
                        if ((_c = requestMiles === null || requestMiles === void 0 ? void 0 : requestMiles.data) === null || _c === void 0 ? void 0 : _c.RequestFfpMilesForOrder) {
                            if (!requestMiles.data.RequestFfpMilesForOrder.result) {
                                return [2 /*return*/, {
                                        error: requestMiles.data.RequestFfpMilesForOrder.message
                                    }];
                            }
                            setSuccessMessage(requestMiles.data.RequestFfpMilesForOrder.message);
                        }
                        return [3 /*break*/, 4];
                    case 3: return [2 /*return*/, {
                            error: t('Order not found')
                        }];
                    case 4: return [2 /*return*/, undefined];
                }
            });
        }); };
        return request();
    };
    return (React.createElement("div", { className: theme.wrapper },
        React.createElement("div", { className: theme.header }, t('Miles recovery')),
        React.createElement("div", { className: theme.about }, t('Your can get miles for flights your travelled before you signed in loyalty program')),
        success && React.createElement("div", { className: theme.success }, success),
        !success && (React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
                var handleSubmit = _a.handleSubmit, submitting = _a.submitting, submitErrors = _a.submitErrors;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: theme.form },
                        React.createElement(Field, { type: "text", name: "number", label: t('PNR or ticket number'), errors: submitErrors === null || submitErrors === void 0 ? void 0 : submitErrors.error }),
                        React.createElement(Field, { type: "text", name: "lastName", label: t('Passenger surname') })),
                    React.createElement("div", { className: theme.buttons },
                        React.createElement(Button, { isLoading: submitting, type: "submit" }, t('Create')))));
            } }))));
};
export default MilesRecovery;
