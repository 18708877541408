export var getMessagePagination = function (message, hasMessageValues) {
    var _a;
    var messageByLines = ((_a = message === null || message === void 0 ? void 0 : message.response) === null || _a === void 0 ? void 0 : _a.split('\n')) || [];
    var linesPerPage = Math.floor((window.innerHeight - 150 - (hasMessageValues ? 50 : 0)) / 14.85);
    var totalPage = Math.ceil(messageByLines.length / linesPerPage);
    return {
        hasPagination: messageByLines.length > linesPerPage,
        messageByLines: messageByLines,
        linesPerPage: linesPerPage,
        totalPage: totalPage
    };
};
export var onChangeInputWithSelectionRange = function (callback) { return function (e) {
    var input = e.currentTarget;
    var start = input.selectionStart;
    var end = input.selectionEnd;
    callback(e);
    setTimeout(function () {
        input.setSelectionRange(start, end);
    }, 0);
}; };
