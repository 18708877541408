import * as React from 'react';
import BaggagePassenger from '../../BaggagePassenger/BaggagePassenger';
import BaggageSegment from '../../BaggageSegment/BaggageSegment';
import { useTheme } from '../../../../../theme';
var BySegment = function (props) {
    var commonTheme = useTheme('UpgradeBaggageDialog').UpgradeBaggageDialog;
    return (React.createElement("div", { className: commonTheme.body }, props.segments.map(function (segment) {
        return (React.createElement(React.Fragment, { key: segment.id },
            React.createElement(BaggageSegment, { segment: segment, baggageByRfics: props.upgradeBaggages, baggageTab: props.baggageTab, mobileSelectedServiceId: props.mobileSelectedServiceId }),
            props.passengers.map(function (passenger, i) {
                var _a, _b, _c;
                var key = "".concat(segment.id, "_").concat(passenger.id);
                var label = props.passengerLabels[passenger.id];
                var number = i + 1;
                var includedSegment = (_a = props.upgradeBaggages[0][0].segmentsId) === null || _a === void 0 ? void 0 : _a.some(function (segments) {
                    return segments.includes(segment.id);
                });
                return (React.createElement(BaggagePassenger, { forAllSegments: false, passengerId: passenger.id, baggageByRfics: props.upgradeBaggages, name: label, number: number, key: key, includedSegment: includedSegment, segmentId: segment.id, mobileSelectedServiceId: props.mobileSelectedServiceId, selectedServices: ((_c = (_b = passenger === null || passenger === void 0 ? void 0 : passenger.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) || [] }));
            })));
    })));
};
export default BySegment;
